// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdatePaymentMethodSchema
 */
export interface UpdatePaymentMethodSchema  {
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentMethodSchema
     */
    cardLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentMethodSchema
     */
    cardId: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePaymentMethodSchema
     */
    primary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentMethodSchema
     */
    paymentToken?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdatePaymentMethodSchema
     */
    isHsaOrFsa?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdatePaymentMethodSchema
     */
    oldCardId?: string;
}

export function UpdatePaymentMethodSchemaFromJSON(json: any): UpdatePaymentMethodSchema {
    return {
        'cardLabel': !exists(json, 'card_label') ? undefined : json['card_label'],
        'cardId': json['card_id'],
        'primary': !exists(json, 'primary') ? undefined : json['primary'],
        'paymentToken': !exists(json, 'payment_token') ? undefined : json['payment_token'],
        'isHsaOrFsa': !exists(json, 'is_hsa_or_fsa') ? undefined : json['is_hsa_or_fsa'],
        'oldCardId': !exists(json, 'old_card_id') ? undefined : json['old_card_id'],
    };
}

export function UpdatePaymentMethodSchemaToJSON(value?: UpdatePaymentMethodSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'card_label': value.cardLabel,
        'card_id': value.cardId,
        'primary': value.primary,
        'payment_token': value.paymentToken,
        'is_hsa_or_fsa': value.isHsaOrFsa,
        'old_card_id': value.oldCardId,
    };
}


