import NativeApp from "models/NativeApp";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useMarkUserNotificationAsRead from "shared/features/notificationCenter/useMarkUserNotificationAsRead";
import { updateNativeAppState } from "shared/state/nativeApp";
import { State } from "@types";
import useGetMemberEnrolledDevices from "shared/features/devices/useGetMemberEnrolledDevices";
import { getUser } from "shared/features/user";
import useGetNotifications from "shared/features/notificationCenter/useGetNotifications";
import { GetUserPushNotificationsStateEnum } from "shared/fetch/src/apis/UserPushNotificationsApi";

const NativeAppState: React.FunctionComponent = () => {
  const isLoggedIn = useSelector((state: State) => !!state.auth.refreshToken);
  const user = useSelector(getUser);
  const { memberEnrolledDevices } = useGetMemberEnrolledDevices({
    // @ts-ignore: Object is possibly 'null'.
    id: user?.id?.toString(),
  });
  const executedCheckForStoredActions = useRef(false);
  const executedUpdateBadgeCount = useRef(false);

  const dispatch = useDispatch();
  const { markUserNotificationAsRead } = useMarkUserNotificationAsRead();
  const { counts } = useGetNotifications(
    GetUserPushNotificationsStateEnum.Sent
  );

  const updateNativeAppStateEventHandler = (e: any) => {
    const isApp: boolean = e.detail.isApp;
    const deviceId: string = e.detail.deviceId;
    const deviceName: string = e.detail.deviceName;
    const biometricEnrollmentStatus: string =
      e.detail.biometricEnrollmentStatus;
    const pushNotificationEnrollmentStatus: string =
      e.detail.pushNotificationEnrollmentStatus;
    const osName: string = e.detail.osName;
    const osVersion: string = e.detail.osVersion;
    const appVersion: string = e.detail.appVersion;
    const canUsePullToRefresh = e.detail.canUsePullToRefresh;
    const deviceNotificationPermissions =
      e.detail.deviceNotificationPermissions;
    const devicePermissionsPromptStatus =
      e.detail.devicePermissionsPromptStatus;

    dispatch(
      updateNativeAppState({
        isApp,
        deviceId,
        deviceName,
        biometricEnrollmentStatus,
        pushNotificationEnrollmentStatus,
        osName,
        osVersion,
        appVersion,
        canUsePullToRefresh,
        deviceNotificationPermissions,
        devicePermissionsPromptStatus,
      })
    );
  };

  const dispatchStoredActionHandler = (e: any) => {
    const value = e.detail.value;
    const actionType = e.detail.actionType;
    if (actionType === "mark_as_read") {
      markUserNotificationAsRead({ notificationId: value });
    }
  };

  useEffect(() => {
    NativeApp.updateNativeAppState();
  }, []);

  useEffect(() => {
    if (isLoggedIn && memberEnrolledDevices?.length) {
      if (!executedCheckForStoredActions.current) {
        NativeApp.checkForStoredActions("mark_as_read");
      }
      executedCheckForStoredActions.current = true;
    }
  }, [isLoggedIn, memberEnrolledDevices?.length]);

  useEffect(() => {
    if (isLoggedIn && counts?.sent && counts?.sent > 0) {
      if (!executedUpdateBadgeCount.current) {
        NativeApp.updateBadgeCount(counts?.sent);
      }
      executedUpdateBadgeCount.current = true;
    }
  }, [isLoggedIn, counts?.sent]);

  useEffect(() => {
    window.addEventListener(
      "updateNativeAppState",
      updateNativeAppStateEventHandler
    );
    window.addEventListener(
      "dispatchStoredAction",
      dispatchStoredActionHandler
    );
    return () => {
      window.removeEventListener(
        "updateNativeAppState",
        updateNativeAppStateEventHandler
      );
      window.removeEventListener(
        "dispatchStoredAction",
        dispatchStoredActionHandler
      );
    };
  }, []);

  return null;
};
export default NativeAppState;
