// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    AcknowledgeAndRespondToComponentsRequestBody,
    AcknowledgeAndRespondToComponentsRequestBodyFromJSON,
    AcknowledgeAndRespondToComponentsRequestBodyToJSON,
} from '../models/AcknowledgeAndRespondToComponentsRequestBody'
import {
    AcknowledgeComponentsRequestBody,
    AcknowledgeComponentsRequestBodyFromJSON,
    AcknowledgeComponentsRequestBodyToJSON,
} from '../models/AcknowledgeComponentsRequestBody'
import {
    BillingComponent,
    BillingComponentFromJSON,
    BillingComponentToJSON,
} from '../models/BillingComponent'
import {
    Charge,
    ChargeFromJSON,
    ChargeToJSON,
} from '../models/Charge'
import {
    ComponentDetailsOutput,
    ComponentDetailsOutputFromJSON,
    ComponentDetailsOutputToJSON,
} from '../models/ComponentDetailsOutput'
import {
    ComponentSubmitRequestBody,
    ComponentSubmitRequestBodyFromJSON,
    ComponentSubmitRequestBodyToJSON,
} from '../models/ComponentSubmitRequestBody'
import {
    ComponentsAcknowledgedResponse,
    ComponentsAcknowledgedResponseFromJSON,
    ComponentsAcknowledgedResponseToJSON,
} from '../models/ComponentsAcknowledgedResponse'
import {
    ComponentsCancellationPolicyResponse,
    ComponentsCancellationPolicyResponseFromJSON,
    ComponentsCancellationPolicyResponseToJSON,
} from '../models/ComponentsCancellationPolicyResponse'
import {
    ComponentsEditChargeResponse,
    ComponentsEditChargeResponseFromJSON,
    ComponentsEditChargeResponseToJSON,
} from '../models/ComponentsEditChargeResponse'
import {
    DeclineVisitRequestBody,
    DeclineVisitRequestBodyFromJSON,
    DeclineVisitRequestBodyToJSON,
} from '../models/DeclineVisitRequestBody'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    MarkComponentsAsViewedSchema,
    MarkComponentsAsViewedSchemaFromJSON,
    MarkComponentsAsViewedSchemaToJSON,
} from '../models/MarkComponentsAsViewedSchema'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    ReferralComponentDetailsOutput,
    ReferralComponentDetailsOutputFromJSON,
    ReferralComponentDetailsOutputToJSON,
} from '../models/ReferralComponentDetailsOutput'
import {
    SelfScheduleAppointmentComponent,
    SelfScheduleAppointmentComponentFromJSON,
    SelfScheduleAppointmentComponentToJSON,
} from '../models/SelfScheduleAppointmentComponent'
import {
    TimeSlotsFetchResponse,
    TimeSlotsFetchResponseFromJSON,
    TimeSlotsFetchResponseToJSON,
} from '../models/TimeSlotsFetchResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpdateComponentRequestBody,
    UpdateComponentRequestBodyFromJSON,
    UpdateComponentRequestBodyToJSON,
} from '../models/UpdateComponentRequestBody'


export interface AcknowledgeAndRespondToComponentRequest {
    id: string;
}

export interface AcknowledgeAndRespondToComponentsRequest {
    acknowledgeAndRespondToComponentsRequestBody?: AcknowledgeAndRespondToComponentsRequestBody;
}

export interface AcknowledgeComponentRequest {
    id: string;
}

export interface AcknowledgeComponentsRequest {
    acknowledgeComponentsRequestBody?: AcknowledgeComponentsRequestBody;
}

export interface AppointmentCancellationPolicyOfComponentByIDRequest {
    id: string;
}

export interface DeclineVisitRequest {
    id: string;
    declineVisitRequestBody?: DeclineVisitRequestBody;
}

export interface FetchComponentRequest {
    id: string;
}

export interface GetBookableSlotsForComponentRequest {
    id: string;
    startDate: string;
    endDate: string;
    page?: string;
    staffGlobalId?: string;
    timezone?: string;
    clinicId?: string;
    schedulingType?: GetBookableSlotsForComponentSchedulingTypeEnum;
}

export interface MarkBillingComponentAsRequestedRequest {
    id: string;
}

export interface MarkComponentAsPaidRequest {
    id: string;
}

export interface MarkComponentAsViewedRequest {
    id: string;
}

export interface MarkComponentsAsViewedRequest {
    markComponentsAsViewedSchema?: MarkComponentsAsViewedSchema;
}

export interface RespondToComponentByIDRequest {
    id: string;
}

export interface SelfScheduleAppointmentComponentByEpisodeIDRequest {
    id: string;
}

export interface SubmitComponentRequest {
    id: string;
    componentSubmitRequestBody?: ComponentSubmitRequestBody;
}

export interface UpdateChargeRequest {
    id: string;
    charge?: Charge;
}

export interface UpdateComponentRequest {
    id: string;
    updateComponentRequestBody?: UpdateComponentRequestBody;
}


/**
 * Acknowledge and Respond to component by ID
 * Acknowledge and Respond to component by ID
 */
function acknowledgeAndRespondToComponentRaw<T>(requestParameters: AcknowledgeAndRespondToComponentRequest, requestConfig: runtime.TypedQueryConfig<T, ComponentDetailsOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling acknowledgeAndRespondToComponent.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/components/{id}/acknowledge_and_respond`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ComponentDetailsOutputFromJSON(body), text);
    }

    return config;
}

/**
* Acknowledge and Respond to component by ID
* Acknowledge and Respond to component by ID
*/
export function acknowledgeAndRespondToComponent<T>(requestParameters: AcknowledgeAndRespondToComponentRequest, requestConfig?: runtime.TypedQueryConfig<T, ComponentDetailsOutput>): QueryConfig<T> {
    return acknowledgeAndRespondToComponentRaw(requestParameters, requestConfig);
}

/**
 * Acknowledge and respond to components by IDs
 * Acknowledge and respond to components by IDs
 */
function acknowledgeAndRespondToComponentsRaw<T>(requestParameters: AcknowledgeAndRespondToComponentsRequest, requestConfig: runtime.TypedQueryConfig<T, ComponentsAcknowledgedResponse> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/components/acknowledge_and_respond`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || AcknowledgeAndRespondToComponentsRequestBodyToJSON(requestParameters.acknowledgeAndRespondToComponentsRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ComponentsAcknowledgedResponseFromJSON(body), text);
    }

    return config;
}

/**
* Acknowledge and respond to components by IDs
* Acknowledge and respond to components by IDs
*/
export function acknowledgeAndRespondToComponents<T>(requestParameters: AcknowledgeAndRespondToComponentsRequest, requestConfig?: runtime.TypedQueryConfig<T, ComponentsAcknowledgedResponse>): QueryConfig<T> {
    return acknowledgeAndRespondToComponentsRaw(requestParameters, requestConfig);
}

/**
 * Acknowledge component by ID
 * Acknowledge component by ID
 */
function acknowledgeComponentRaw<T>(requestParameters: AcknowledgeComponentRequest, requestConfig: runtime.TypedQueryConfig<T, ComponentDetailsOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling acknowledgeComponent.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/components/{id}/acknowledge`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ComponentDetailsOutputFromJSON(body), text);
    }

    return config;
}

/**
* Acknowledge component by ID
* Acknowledge component by ID
*/
export function acknowledgeComponent<T>(requestParameters: AcknowledgeComponentRequest, requestConfig?: runtime.TypedQueryConfig<T, ComponentDetailsOutput>): QueryConfig<T> {
    return acknowledgeComponentRaw(requestParameters, requestConfig);
}

/**
 * Acknowledge components by IDs
 * Acknowledge components by IDs
 */
function acknowledgeComponentsRaw<T>(requestParameters: AcknowledgeComponentsRequest, requestConfig: runtime.TypedQueryConfig<T, ComponentsAcknowledgedResponse> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/components/acknowledge`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || AcknowledgeComponentsRequestBodyToJSON(requestParameters.acknowledgeComponentsRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ComponentsAcknowledgedResponseFromJSON(body), text);
    }

    return config;
}

/**
* Acknowledge components by IDs
* Acknowledge components by IDs
*/
export function acknowledgeComponents<T>(requestParameters: AcknowledgeComponentsRequest, requestConfig?: runtime.TypedQueryConfig<T, ComponentsAcknowledgedResponse>): QueryConfig<T> {
    return acknowledgeComponentsRaw(requestParameters, requestConfig);
}

/**
 * Fetch cancellation policy for the appointment associated with the component
 */
function appointmentCancellationPolicyOfComponentByIDRaw<T>(requestParameters: AppointmentCancellationPolicyOfComponentByIDRequest, requestConfig: runtime.TypedQueryConfig<T, ComponentsCancellationPolicyResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling appointmentCancellationPolicyOfComponentByID.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/components/{id}/cancellation_policy`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ComponentsCancellationPolicyResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch cancellation policy for the appointment associated with the component
*/
export function appointmentCancellationPolicyOfComponentByID<T>(requestParameters: AppointmentCancellationPolicyOfComponentByIDRequest, requestConfig?: runtime.TypedQueryConfig<T, ComponentsCancellationPolicyResponse>): QueryConfig<T> {
    return appointmentCancellationPolicyOfComponentByIDRaw(requestParameters, requestConfig);
}

/**
 * Member declines Component by ID
 * Member declines Component by ID
 */
function declineVisitRaw<T>(requestParameters: DeclineVisitRequest, requestConfig: runtime.TypedQueryConfig<T, ReferralComponentDetailsOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling declineVisit.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/components/{id}/decline`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || DeclineVisitRequestBodyToJSON(requestParameters.declineVisitRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ReferralComponentDetailsOutputFromJSON(body), text);
    }

    return config;
}

/**
* Member declines Component by ID
* Member declines Component by ID
*/
export function declineVisit<T>(requestParameters: DeclineVisitRequest, requestConfig?: runtime.TypedQueryConfig<T, ReferralComponentDetailsOutput>): QueryConfig<T> {
    return declineVisitRaw(requestParameters, requestConfig);
}

/**
 * Fetch a component by ID
 * Fetch a component by ID
 */
function fetchComponentRaw<T>(requestParameters: FetchComponentRequest, requestConfig: runtime.TypedQueryConfig<T, ComponentDetailsOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchComponent.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/components/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ComponentDetailsOutputFromJSON(body), text);
    }

    return config;
}

/**
* Fetch a component by ID
* Fetch a component by ID
*/
export function fetchComponent<T>(requestParameters: FetchComponentRequest, requestConfig?: runtime.TypedQueryConfig<T, ComponentDetailsOutput>): QueryConfig<T> {
    return fetchComponentRaw(requestParameters, requestConfig);
}

/**
 * Fetch all bookable slots for given component
 * Fetch all bookable slots for given component
 */
function getBookableSlotsForComponentRaw<T>(requestParameters: GetBookableSlotsForComponentRequest, requestConfig: runtime.TypedQueryConfig<T, TimeSlotsFetchResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getBookableSlotsForComponent.');
    }

    if (requestParameters.startDate === null || requestParameters.startDate === undefined) {
        throw new runtime.RequiredError('startDate','Required parameter requestParameters.startDate was null or undefined when calling getBookableSlotsForComponent.');
    }

    if (requestParameters.endDate === null || requestParameters.endDate === undefined) {
        throw new runtime.RequiredError('endDate','Required parameter requestParameters.endDate was null or undefined when calling getBookableSlotsForComponent.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.page !== undefined) {
        queryParameters['page'] = requestParameters.page;
    }


    if (requestParameters.startDate !== undefined) {
        queryParameters['start_date'] = requestParameters.startDate;
    }


    if (requestParameters.endDate !== undefined) {
        queryParameters['end_date'] = requestParameters.endDate;
    }


    if (requestParameters.staffGlobalId !== undefined) {
        queryParameters['staff_global_id'] = requestParameters.staffGlobalId;
    }


    if (requestParameters.timezone !== undefined) {
        queryParameters['timezone'] = requestParameters.timezone;
    }


    if (requestParameters.clinicId !== undefined) {
        queryParameters['clinic_id'] = requestParameters.clinicId;
    }


    if (requestParameters.schedulingType !== undefined) {
        queryParameters['scheduling_type'] = requestParameters.schedulingType;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/components/{id}/slots`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(TimeSlotsFetchResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch all bookable slots for given component
* Fetch all bookable slots for given component
*/
export function getBookableSlotsForComponent<T>(requestParameters: GetBookableSlotsForComponentRequest, requestConfig?: runtime.TypedQueryConfig<T, TimeSlotsFetchResponse>): QueryConfig<T> {
    return getBookableSlotsForComponentRaw(requestParameters, requestConfig);
}

/**
 * Mark a billing component as requested by ID
 * Mark a billing component as requested by ID
 */
function markBillingComponentAsRequestedRaw<T>(requestParameters: MarkBillingComponentAsRequestedRequest, requestConfig: runtime.TypedQueryConfig<T, BillingComponent> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markBillingComponentAsRequested.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/components/{id}/mark_as_requested`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(BillingComponentFromJSON(body), text);
    }

    return config;
}

/**
* Mark a billing component as requested by ID
* Mark a billing component as requested by ID
*/
export function markBillingComponentAsRequested<T>(requestParameters: MarkBillingComponentAsRequestedRequest, requestConfig?: runtime.TypedQueryConfig<T, BillingComponent>): QueryConfig<T> {
    return markBillingComponentAsRequestedRaw(requestParameters, requestConfig);
}

/**
 * Mark a component as paid by ID
 * Mark a component as paid by ID
 */
function markComponentAsPaidRaw<T>(requestParameters: MarkComponentAsPaidRequest, requestConfig: runtime.TypedQueryConfig<T, BillingComponent> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markComponentAsPaid.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/components/{id}/mark_as_paid`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(BillingComponentFromJSON(body), text);
    }

    return config;
}

/**
* Mark a component as paid by ID
* Mark a component as paid by ID
*/
export function markComponentAsPaid<T>(requestParameters: MarkComponentAsPaidRequest, requestConfig?: runtime.TypedQueryConfig<T, BillingComponent>): QueryConfig<T> {
    return markComponentAsPaidRaw(requestParameters, requestConfig);
}

/**
 * Mark a component as viewed by ID
 * Mark a component as viewed by ID
 */
function markComponentAsViewedRaw<T>(requestParameters: MarkComponentAsViewedRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markComponentAsViewed.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/components/{id}/viewed`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Mark a component as viewed by ID
* Mark a component as viewed by ID
*/
export function markComponentAsViewed<T>(requestParameters: MarkComponentAsViewedRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return markComponentAsViewedRaw(requestParameters, requestConfig);
}

/**
 * Mark components as viewed by ID\'s
 * Mark components as viewed by ID\'s
 */
function markComponentsAsViewedRaw<T>(requestParameters: MarkComponentsAsViewedRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/components/viewed`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || MarkComponentsAsViewedSchemaToJSON(requestParameters.markComponentsAsViewedSchema),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Mark components as viewed by ID\'s
* Mark components as viewed by ID\'s
*/
export function markComponentsAsViewed<T>(requestParameters: MarkComponentsAsViewedRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return markComponentsAsViewedRaw(requestParameters, requestConfig);
}

/**
 * Respond to component by ID
 * Respond to component by ID
 */
function respondToComponentByIDRaw<T>(requestParameters: RespondToComponentByIDRequest, requestConfig: runtime.TypedQueryConfig<T, ComponentDetailsOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling respondToComponentByID.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/components/{id}/start_response`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ComponentDetailsOutputFromJSON(body), text);
    }

    return config;
}

/**
* Respond to component by ID
* Respond to component by ID
*/
export function respondToComponentByID<T>(requestParameters: RespondToComponentByIDRequest, requestConfig?: runtime.TypedQueryConfig<T, ComponentDetailsOutput>): QueryConfig<T> {
    return respondToComponentByIDRaw(requestParameters, requestConfig);
}

/**
 * Get SelfScheduleAppointmentComponent By Episode Id
 * Get SelfScheduleAppointmentComponent By Episode Id
 */
function selfScheduleAppointmentComponentByEpisodeIDRaw<T>(requestParameters: SelfScheduleAppointmentComponentByEpisodeIDRequest, requestConfig: runtime.TypedQueryConfig<T, SelfScheduleAppointmentComponent> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling selfScheduleAppointmentComponentByEpisodeID.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/components/by_episode_id/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(SelfScheduleAppointmentComponentFromJSON(body), text);
    }

    return config;
}

/**
* Get SelfScheduleAppointmentComponent By Episode Id
* Get SelfScheduleAppointmentComponent By Episode Id
*/
export function selfScheduleAppointmentComponentByEpisodeID<T>(requestParameters: SelfScheduleAppointmentComponentByEpisodeIDRequest, requestConfig?: runtime.TypedQueryConfig<T, SelfScheduleAppointmentComponent>): QueryConfig<T> {
    return selfScheduleAppointmentComponentByEpisodeIDRaw(requestParameters, requestConfig);
}

/**
 * Submit component by ID
 * Submit component by ID
 */
function submitComponentRaw<T>(requestParameters: SubmitComponentRequest, requestConfig: runtime.TypedQueryConfig<T, ComponentDetailsOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling submitComponent.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/components/{id}/submit`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || ComponentSubmitRequestBodyToJSON(requestParameters.componentSubmitRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ComponentDetailsOutputFromJSON(body), text);
    }

    return config;
}

/**
* Submit component by ID
* Submit component by ID
*/
export function submitComponent<T>(requestParameters: SubmitComponentRequest, requestConfig?: runtime.TypedQueryConfig<T, ComponentDetailsOutput>): QueryConfig<T> {
    return submitComponentRaw(requestParameters, requestConfig);
}

/**
 * Edit the dollar amount of a charge billing card
 */
function updateChargeRaw<T>(requestParameters: UpdateChargeRequest, requestConfig: runtime.TypedQueryConfig<T, ComponentsEditChargeResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCharge.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["ctm"]];
    const config: QueryConfig<T> = {
        url: `/v1/components/{id}/edit_charge`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PATCH',
            headers: headerParameters,
        },
        body: queryParameters || ChargeToJSON(requestParameters.charge),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ComponentsEditChargeResponseFromJSON(body), text);
    }

    return config;
}

/**
* Edit the dollar amount of a charge billing card
*/
export function updateCharge<T>(requestParameters: UpdateChargeRequest, requestConfig?: runtime.TypedQueryConfig<T, ComponentsEditChargeResponse>): QueryConfig<T> {
    return updateChargeRaw(requestParameters, requestConfig);
}

/**
 * Update a component by ID
 * Update a component by ID
 */
function updateComponentRaw<T>(requestParameters: UpdateComponentRequest, requestConfig: runtime.TypedQueryConfig<T, ComponentDetailsOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateComponent.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/components/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PATCH',
            headers: headerParameters,
        },
        body: queryParameters || UpdateComponentRequestBodyToJSON(requestParameters.updateComponentRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ComponentDetailsOutputFromJSON(body), text);
    }

    return config;
}

/**
* Update a component by ID
* Update a component by ID
*/
export function updateComponent<T>(requestParameters: UpdateComponentRequest, requestConfig?: runtime.TypedQueryConfig<T, ComponentDetailsOutput>): QueryConfig<T> {
    return updateComponentRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum GetBookableSlotsForComponentSchedulingTypeEnum {
    VirtualVisitReferral = 'virtual_visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    SelfSchedule = 'self_schedule'
}
