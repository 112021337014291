// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberServiceLinesSchema
 */
export interface MemberServiceLinesSchema  {
    /**
     * 
     * @type {string}
     * @memberof MemberServiceLinesSchema
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof MemberServiceLinesSchema
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MemberServiceLinesSchema
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof MemberServiceLinesSchema
     */
    careAccessDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberServiceLinesSchema
     */
    fullDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberServiceLinesSchema
     */
    abbreviation?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberServiceLinesSchema
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberServiceLinesSchema
     */
    backgroundColor?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberServiceLinesSchema
     */
    serviceType?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberServiceLinesSchema
     */
    assetName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MemberServiceLinesSchema
     */
    methods?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof MemberServiceLinesSchema
     */
    hasMessaging?: boolean;
}

export function MemberServiceLinesSchemaFromJSON(json: any): MemberServiceLinesSchema {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'careAccessDescription': !exists(json, 'care_access_description') ? undefined : json['care_access_description'],
        'fullDescription': !exists(json, 'full_description') ? undefined : json['full_description'],
        'abbreviation': !exists(json, 'abbreviation') ? undefined : json['abbreviation'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'backgroundColor': !exists(json, 'background_color') ? undefined : json['background_color'],
        'serviceType': !exists(json, 'service_type') ? undefined : json['service_type'],
        'assetName': !exists(json, 'asset_name') ? undefined : json['asset_name'],
        'methods': !exists(json, 'methods') ? undefined : json['methods'],
        'hasMessaging': !exists(json, 'has_messaging') ? undefined : json['has_messaging'],
    };
}

export function MemberServiceLinesSchemaToJSON(value?: MemberServiceLinesSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'care_access_description': value.careAccessDescription,
        'full_description': value.fullDescription,
        'abbreviation': value.abbreviation,
        'color': value.color,
        'background_color': value.backgroundColor,
        'service_type': value.serviceType,
        'asset_name': value.assetName,
        'methods': value.methods,
        'has_messaging': value.hasMessaging,
    };
}


