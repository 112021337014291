import palette from "../palette";

export default {
  root: {
    "&:focus-visible": {
      // ensure high-contrast mode still has an indicator
      outline: "2px transparent solid !important",
      // white outline with blue outline around it to handle both light and dark modes
      boxShadow: `0 0 0 2px ${palette.white}, 0 0 0 4px ${palette.links.primaryHover} !important`,
    },
  },
};
