// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     MemberAccountInfo,
    MemberAccountInfoFromJSON,
    MemberAccountInfoToJSON,
} from './MemberAccountInfo';
import {
     MemberUpdateAccountInfoRequestBodyAllOf,
    MemberUpdateAccountInfoRequestBodyAllOfFromJSON,
    MemberUpdateAccountInfoRequestBodyAllOfToJSON,
} from './MemberUpdateAccountInfoRequestBodyAllOf';
/**
 * @type MemberUpdateAccountInfoRequestBody
 * @export
 */
export interface MemberUpdateAccountInfoRequestBody extends MemberAccountInfo, MemberUpdateAccountInfoRequestBodyAllOf {
}

export function MemberUpdateAccountInfoRequestBodyFromJSON(json: any): MemberUpdateAccountInfoRequestBody {
    return {
        ...MemberAccountInfoFromJSON(json),
        ...MemberUpdateAccountInfoRequestBodyAllOfFromJSON(json),
    };
}

export function MemberUpdateAccountInfoRequestBodyToJSON(value?: MemberUpdateAccountInfoRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...MemberAccountInfoToJSON(value),
        ...MemberUpdateAccountInfoRequestBodyAllOfToJSON(value),
    };
}

