// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FetchLoginStatsSuccessResponse
 */
export interface FetchLoginStatsSuccessResponse  {
    /**
     * 
     * @type {Date}
     * @memberof FetchLoginStatsSuccessResponse
     */
    lastActivityAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof FetchLoginStatsSuccessResponse
     */
    failedLogins?: number;
    /**
     * 
     * @type {number}
     * @memberof FetchLoginStatsSuccessResponse
     */
    successfulLogins?: number;
}

export function FetchLoginStatsSuccessResponseFromJSON(json: any): FetchLoginStatsSuccessResponse {
    return {
        'lastActivityAt': !exists(json, 'last_activity_at') ? undefined : new Date(json['last_activity_at']),
        'failedLogins': !exists(json, 'failed_logins') ? undefined : json['failed_logins'],
        'successfulLogins': !exists(json, 'successful_logins') ? undefined : json['successful_logins'],
    };
}

export function FetchLoginStatsSuccessResponseToJSON(value?: FetchLoginStatsSuccessResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'last_activity_at': value.lastActivityAt === undefined ? undefined : value.lastActivityAt.toISOString(),
        'failed_logins': value.failedLogins,
        'successful_logins': value.successfulLogins,
    };
}


