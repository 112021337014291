// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CapacityFetchResponseSlotsPerClinic,
    CapacityFetchResponseSlotsPerClinicFromJSON,
    CapacityFetchResponseSlotsPerClinicToJSON,
} from './CapacityFetchResponseSlotsPerClinic';
import {
    ProviderSummarySchema,
    ProviderSummarySchemaFromJSON,
    ProviderSummarySchemaToJSON,
} from './ProviderSummarySchema';


/**
 * 
 * @export
 * @interface CapacityFetchResponseResults
 */
export interface CapacityFetchResponseResults  {
    /**
     * 
     * @type {ProviderSummarySchema}
     * @memberof CapacityFetchResponseResults
     */
    provider: ProviderSummarySchema;
    /**
     * 
     * @type {Array<CapacityFetchResponseSlotsPerClinic>}
     * @memberof CapacityFetchResponseResults
     */
    slotsPerClinic?: Array<CapacityFetchResponseSlotsPerClinic>;
}

export function CapacityFetchResponseResultsFromJSON(json: any): CapacityFetchResponseResults {
    return {
        'provider': ProviderSummarySchemaFromJSON(json['provider']),
        'slotsPerClinic': !exists(json, 'slots_per_clinic') ? undefined : (json['slots_per_clinic'] as Array<any>).map(CapacityFetchResponseSlotsPerClinicFromJSON),
    };
}

export function CapacityFetchResponseResultsToJSON(value?: CapacityFetchResponseResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'provider': ProviderSummarySchemaToJSON(value.provider),
        'slots_per_clinic': value.slotsPerClinic === undefined ? undefined : (value.slotsPerClinic as Array<any>).map(CapacityFetchResponseSlotsPerClinicToJSON),
    };
}


