import React, { FunctionComponent, useState } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import Button, { Link } from "components/Button";
import UrgentModal from "components/EOCTimeline/UrgentModal";
import Box from "components/Box";
import getYear from "date-fns/getYear";
import List from "components/List";
import ListItem from "components/List/ListItem";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme?.palette?.appBackground?.main,
    boxShadow: theme?.shadows[2],
    position: "fixed",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0.5rem 1rem",
    flexWrap: "nowrap",
    bottom: 0,
    right: 0,
    left: 0,
    zIndex: 1100,

    "& > div": {
      display: "inline-block",
      paddingTop: "0.5rem",
      paddingBottom: "0.5rem",
      textAlign: "center",
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
    },
    "& a": {
      textDecoration: "underline",
    },
    "& a, span, li": {
      fontSize: theme.typography.bodySmall?.fontSize,
      color: theme.palette.text.secondary,
      padding: "0.1rem",
    },
    "& li": {
      marginLeft: "3px",
    },
  },
  callUsButton: {
    paddingRight: theme?.spacing(0.5),
    paddingLeft: theme?.spacing(0.5),
    color: theme?.palette?.appBackground?.medGrey,
  },
  footerList: {
    display: "flex",
    whiteSpace: "nowrap",
    paddingLeft: "40px",
    marginBottom: "16px",
  },
}));

interface IMembersFooter {
  clinicContact?: string;
}

const MembersFooter: FunctionComponent<IMembersFooter> = ({
  clinicContact,
}: IMembersFooter): JSX.Element => {
  const [openUrgent, setOpenUrgent] = useState(false);
  const classes = useStyles();
  const baseURL = "https://crossoverhealth.com";
  const year = getYear(new Date());
  return (
    <>
      <UrgentModal
        open={openUrgent}
        toggle={() => setOpenUrgent(!openUrgent)}
        emergencyPhoneContact={clinicContact}
      />
      <Box
        component="nav"
        display={{ xs: "none", sm: "none", md: "flex" }}
        data-e2e="members-footer"
        data-testid="members-footer"
        className={classes.root}
      >
        <List className={classes.footerList} ariaLabel="Footer Navigation">
          <ListItem aria-label="Copyright">© {year} Crossover Health</ListItem>
          <ListItem aria-label="Separator" aria-hidden="true">
            |
          </ListItem>
          <ListItem aria-label="Terms and Conditions">
            <Link
              href={`${baseURL}/legal/#terms_and_conditions`}
              title="Terms & Conditions"
              target="_blank"
            >
              Terms &amp; Conditions
            </Link>
          </ListItem>
          <ListItem aria-label="Separator" aria-hidden="true">
            |
          </ListItem>
          <ListItem aria-label="Privacy Policy">
            <Link
              href={`${baseURL}/legal/#notice_of_privacy_practices`}
              title="Privacy Policy"
              target="_blank"
            >
              Privacy Policy
            </Link>
          </ListItem>
          <ListItem aria-label="Separator" aria-hidden="true">
            |
          </ListItem>
          <ListItem aria-label="California Consumers">
            <Link
              href={`${baseURL}/legal/#notice_to_california_consumers`}
              title="California Consumers"
              target="_blank"
            >
              California Consumers
            </Link>
          </ListItem>
          <ListItem aria-label="Separator" aria-hidden="true">
            |
          </ListItem>
          <ListItem aria-label="Consent(s)">
            <Link
              href={`${baseURL}/universal-consent/`}
              title="Consents"
              target="_blank"
            >
              Consent(s)
            </Link>
          </ListItem>
        </List>

        <List className={classes.footerList} ariaLabel="Footer Navigation">
          <ListItem aria-label={`Call us at ${clinicContact}`}>
            <Button
              className={classes.callUsButton}
              onClick={() => setOpenUrgent(!openUrgent)}
              data-testid="urgent-call-button"
            >
              {`Call us at ${clinicContact}`}
            </Button>
          </ListItem>
          <ListItem aria-label="Separator" aria-hidden="true">
            |
          </ListItem>
          <ListItem aria-label="Tech Support">
            <Link
              href="https://answers.crossoverhealth.com/"
              title="Support"
              target="_blank"
            >
              Tech Support
            </Link>
          </ListItem>
        </List>
      </Box>
    </>
  );
};

export default MembersFooter;
