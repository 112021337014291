// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ComponentsCancellationPolicyResponse
 */
export interface ComponentsCancellationPolicyResponse  {
    /**
     * 
     * @type {string}
     * @memberof ComponentsCancellationPolicyResponse
     */
    cancellationPolicy?: string;
}

export function ComponentsCancellationPolicyResponseFromJSON(json: any): ComponentsCancellationPolicyResponse {
    return {
        'cancellationPolicy': !exists(json, 'cancellation_policy') ? undefined : json['cancellation_policy'],
    };
}

export function ComponentsCancellationPolicyResponseToJSON(value?: ComponentsCancellationPolicyResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'cancellation_policy': value.cancellationPolicy,
    };
}


