import React from "react";
import Box from "components/Box";
import Grid from "components/Grid";
import Skeleton from "./";
import CardSkeleton from "./CardSkeleton";

const PrimaryContent = () => (
  <>
    <Skeleton width="80%" height={60} />
    <Skeleton width="70%" />
    <Skeleton width="70%" />
    <Skeleton width="70%" />
  </>
);
const SecondaryContent = () => (
  <div style={{ paddingTop: 16 }}>
    <Skeleton width="80%" />
    <Skeleton width="70%" />
    <Skeleton width="70%" />
    <Skeleton width="70%" />
  </div>
);

interface ConversationSkeletonProps {
  className?: string;
}

const ConversationSkeleton: React.FC<ConversationSkeletonProps> = (props) => (
  <Box className={props.className}>
    <CardSkeleton>
      <Skeleton width="50%" height={75} />
      <Skeleton width="80%" />
      <Skeleton width="80%" />
      <Skeleton width="80%" />
    </CardSkeleton>
    <CardSkeleton>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <PrimaryContent />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SecondaryContent />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SecondaryContent />
        </Grid>
      </Grid>
    </CardSkeleton>
    <CardSkeleton>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <PrimaryContent />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SecondaryContent />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SecondaryContent />
        </Grid>
      </Grid>
    </CardSkeleton>
  </Box>
);

export default ConversationSkeleton;
