import React from "react";
import makeStyles from "@mui/styles/makeStyles";

import PhotoId from "styles/images/insurance-tool/photo-id.png";
import Typography from "components/Typography";
import { selectFeatures } from "shared/features/featureFlags/selectors";

const useStyles = makeStyles(({ spacing, palette }) => ({
  insuranceCTAContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#E3F0FB",
    padding: `${spacing(2)} ${spacing(3)}`,
    border: `1px solid ${palette.text.strokes}`,
    borderRadius: 10,
    marginTop: 20,
    color: palette.appBackground?.darkBlue,
  },
  photoIdContainer: {
    marginLeft: spacing(3),
    display: "flex",
  },

  insuranceIdImg: {
    height: "4rem",
  },
  benefitsTabLink: {
    cursor: "pointer",
    color: "inherit",
  },
}));

export default function InsuranceCTALandingPage() {
  const classes = useStyles();
  const featureFlags = selectFeatures();
  const hasInsuranceOnboarding = featureFlags.hasInsuranceOnboarding();

  return hasInsuranceOnboarding ? (
    <div className={classes.insuranceCTAContainer} data-e2e="insurance-cta">
      <Typography color="inherit" appearance="smallBody">
        Please update or provide your insurance details in the{" "}
        <a className={classes.benefitsTabLink} href={"/account/benefits"}>
          benefits tab
        </a>{" "}
        to keep your account up-to-date.
      </Typography>
      <div className={classes.photoIdContainer}>
        <img
          alt="insurance id"
          className={classes.insuranceIdImg}
          src={PhotoId}
        />
      </div>
    </div>
  ) : null;
}
