import * as React from "react";
import RouteAccess from "components/Access/RouteAccess";
import { isValidUrlPath } from "features/cms/pages/utils/isValidUrlPath";
import uniqueId from "lodash/uniqueId";
import { loadLazy } from "utils/loadLazy";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";
import { Route } from "react-router";

const LazyToolsPage = loadLazy(() => import("features/cms/pages/Tools"));
const LazyCMSItemPage = loadLazy(
  () => import("features/cms/pages/CMSItemPage")
);
const LazyCMSListPage = loadLazy(
  () => import("features/cms/pages/CMSListPage")
);
const LazyServiceLines = loadLazy(
  () => import("features/cms/pages/ServiceLines")
);
const LazyAddServiceLine = loadLazy(
  () => import("features/cms/pages/ServiceLines/AddServiceLine")
);
const LazyServiceLineItemPage = loadLazy(
  () => import("features/cms/pages/ServiceLines/Details/ServiceLineItemPage")
);
const LazyAddCommonQuestion = loadLazy(
  () => import("./pages/CommonQuestions/AddCommonQuestionCard")
);
const LazyAddQuestionSet = loadLazy(
  () => import("features/cms/pages/QuestionSets/Details/AddQuestionSet")
);

export default () => [
  <Route
    key={"/tools"}
    path="/tools"
    element={<RouteAccess to={PermissionsEnum.CmsToolsRead} />}
  >
    <Route index element={<LazyToolsPage />} />
  </Route>,
  <Route
    key={"/question_sets/new"}
    path="/tools/automations/question_sets/new"
    element={<RouteAccess to={PermissionsEnum.CmsToolsRead} />}
  >
    <Route index element={<LazyAddQuestionSet />} />
  </Route>,
  <Route
    key={"/common_questions/new"}
    path="/tools/automations/common_questions/new"
    element={<RouteAccess to={PermissionsEnum.CmsToolsRead} />}
  >
    <Route index element={<LazyAddCommonQuestion />} />
  </Route>,
  <Route
    key={uniqueId()}
    path="tools/:contentTypeCategoryId/:cmsContentTypeId"
    element={
      <RouteAccess
        to={PermissionsEnum.CmsToolsRead}
        validate={isValidUrlPath}
        isListPage
      />
    }
  >
    <Route index element={<LazyCMSListPage />} />
  </Route>,
  <Route
    key={uniqueId()}
    path="tools/:contentTypeCategoryId/:cmsContentTypeId/:cmsItemId"
    element={
      <RouteAccess
        to={PermissionsEnum.CmsToolsRead}
        validate={isValidUrlPath}
        isListPage={false}
      />
    }
  >
    <Route index element={<LazyCMSItemPage />} />
  </Route>,
  <Route
    key={uniqueId()}
    path="tools/:contentTypeCategoryId/service_lines"
    element={<RouteAccess to={PermissionsEnum.CmsToolsRead} isListPage />}
  >
    <Route index element={<LazyServiceLines />} />
  </Route>,
  <Route
    key={uniqueId()}
    path="tools/automations/service_lines/new"
    element={
      <RouteAccess to={PermissionsEnum.CmsToolsRead} isListPage={false} />
    }
  >
    <Route index element={<LazyAddServiceLine />} />
  </Route>,
  <Route
    key={uniqueId()}
    path="tools/:contentTypeCategoryId/service_lines/:cmsItemId"
    element={
      <RouteAccess to={PermissionsEnum.CmsToolsRead} isListPage={false} />
    }
  >
    <Route index element={<LazyServiceLineItemPage />} />
  </Route>,
];
