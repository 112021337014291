import { DragEvent, useCallback, useEffect, useRef, useState } from "react";

const useDroppable = (fileHandler: (file: File) => void, isModal?: boolean) => {
  const node = useRef<HTMLElement | null>(null);
  const [isDragActive, setDragActive] = useState(false);
  const [dragEnterCounter, setDragEnterCounter] = useState(0);

  const dragEnterListener = (e: any) => {
    const event = e as DragEvent<HTMLElement>;
    event.preventDefault();
    setDragEnterCounter((prev) => prev + 1);
  };

  const dragOverListener = (e: any) => {
    const event = e as DragEvent<HTMLElement>;
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
    event.dataTransfer.effectAllowed = "copy";
  };

  const dragLeaveListener = (e: any) => {
    const event = e as DragEvent<HTMLElement>;
    event.preventDefault();
    setDragEnterCounter((prev) => prev - 1);
  };

  const dropListener = (e: any) => {
    const event = e as DragEvent<HTMLElement>;
    event.preventDefault();
    // extract file
    const { files } = event.dataTransfer;
    if (files.length) {
      fileHandler(files[0]);
    }
    setDragEnterCounter((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const addEventListeners = () => {
    if (node.current) {
      node.current.addEventListener("dragenter", dragEnterListener);
      node.current.addEventListener("dragover", dragOverListener);
      node.current.addEventListener("dragleave", dragLeaveListener);
      node.current.addEventListener("drop", dropListener);
    }
  };

  const setNodeRef = useCallback((element: HTMLElement | null) => {
    node.current = element;
    if (isModal) {
      addEventListeners();
    }
  }, []);

  useEffect(() => {
    setDragActive(dragEnterCounter > 0);
  }, [dragEnterCounter]);

  // attach drag event listeners
  useEffect(() => {
    addEventListeners();

    return () => {
      node.current?.removeEventListener("dragenter", dragEnterListener);
      node.current?.removeEventListener("dragover", dragOverListener);
      node.current?.removeEventListener("dragleave", dragLeaveListener);
      node.current?.removeEventListener("drop", dropListener);
    };
  }, [node.current]);

  return {
    isDragActive,
    setDropZoneNodeRef: setNodeRef,
  };
};

export default useDroppable;
