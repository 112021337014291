// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OutputMemberSearchSchemaProps,
    OutputMemberSearchSchemaPropsFromJSON,
    OutputMemberSearchSchemaPropsToJSON,
} from './OutputMemberSearchSchemaProps';


/**
 * 
 * @export
 * @interface ListMembersResponse
 */
export interface ListMembersResponse  {
    /**
     * 
     * @type {string}
     * @memberof ListMembersResponse
     */
    prevUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ListMembersResponse
     */
    nextUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof ListMembersResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ListMembersResponse
     */
    last?: number;
    /**
     * 
     * @type {number}
     * @memberof ListMembersResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<OutputMemberSearchSchemaProps>}
     * @memberof ListMembersResponse
     */
    results: Array<OutputMemberSearchSchemaProps>;
}

export function ListMembersResponseFromJSON(json: any): ListMembersResponse {
    return {
        'prevUrl': !exists(json, 'prev_url') ? undefined : json['prev_url'],
        'nextUrl': !exists(json, 'next_url') ? undefined : json['next_url'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'results': (json['results'] as Array<any>).map(OutputMemberSearchSchemaPropsFromJSON),
    };
}

export function ListMembersResponseToJSON(value?: ListMembersResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'prev_url': value.prevUrl,
        'next_url': value.nextUrl,
        'page': value.page,
        'last': value.last,
        'count': value.count,
        'results': (value.results as Array<any>).map(OutputMemberSearchSchemaPropsToJSON),
    };
}


