import NativeApp from "../../models/NativeApp";

export const handleAttachments = (isApp: boolean, fileUrl: string) => {
  if (isApp) {
    NativeApp.downloadFile(fileUrl);
  }
};

export const getAuthorizedAssetUrl = (src: string, accessToken?: string) => {
  const separator = src?.includes?.("?") ? "&" : "?";

  // if already authorized, do not add the access_token again
  const alreadyAuthorized = src?.includes?.("access_token=");
  if (!alreadyAuthorized) {
    src += `${separator}access_token=${accessToken}`;
  }
  return src;
};
