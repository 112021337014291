import React from "react";
import { isBefore } from "date-fns";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { ReferralComponentCoreStateEnum } from "shared/api/src/models/ReferralComponentCore";
import { selectLoggedInUser } from "shared/features/user/selectors";

import detailLinks from "./DetailLinks";

interface IProps {
  state: ReferralComponentCoreStateEnum;
  componentId?: string | number;
  episodeId?: string | number;
  memberId?: string | number;
  visitStartAt?: Date;
  isInternalVisit?: boolean;
  canReschedule?: boolean;
  canVisitBeCancelled?: boolean;
}

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    layout: {
      display: "grid",
      gridGap: spacing(1),
      gridTemplateColumns: "max-content max-content 1fr",
    },
  })
);

const ActionLinks = ({
  state,
  componentId,
  episodeId,
  memberId,
  visitStartAt,
  isInternalVisit,
  canReschedule,
  canVisitBeCancelled,
}: IProps) => {
  const user = selectLoggedInUser();
  const classes = useStyles();
  const accessingUserType = user?.ctm ? "ctm" : "member";

  if (!state || !componentId || !episodeId) {
    return null;
  }

  const to = `/members/${memberId}/conversations/${episodeId}/component/${componentId}`;

  const linkProps = {
    to,
    accessingUserType,
    state,
    pastVisit: visitStartAt && isBefore(visitStartAt, new Date()),
    internalVisit: isInternalVisit,
    memberId,
    episodeId,
    componentId,
    canReschedule,
    canVisitBeCancelled,
  };

  return (
    <div className={classes.layout}>
      {detailLinks.map((DetailLink: any, index: number) => (
        <DetailLink key={index} {...linkProps} />
      ))}
    </div>
  );
};

export default ActionLinks;
