import React, { FC } from "react";
import { useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { selectLoggedInUser } from "shared/features/user/selectors";

import Typography from "components/Typography";
import Box from "components/Box";
import MarketingCard from "components/MarketingCard";
import BeWellImage from "styles/images/marketing/be-well.png";

const useStyles = makeStyles(() => ({
  intro: {
    fontSize: "0.83125rem",
    lineHeight: "1.080625rem",
    padding: "10px 0px",
  },
}));

interface IProps {
  hasTwoCards?: boolean;
}

const BeWellTile: FC<IProps> = ({ hasTwoCards }) => {
  const classes = useStyles();
  const user = selectLoggedInUser();
  const theme = useTheme();

  const Copy = () => (
    <Box mt={1.5} pr={5}>
      <Typography color="textPrimary" className={classes.intro}>
        Get the most out of your membership. Crossover providers have built a 
        place for members to engage in good health—and you’ve got open access. 
        You can get a free fitness assessment, take classes for exercise, nutrition 
        or stress, play videos or podcasts to learn about topics you care about, 
        and use our tools for a healthy lifestyle.
      </Typography>
      <Typography color="textPrimary" className={classes.intro} pt={1}>
        What moves you? Find out.
      </Typography>
    </Box>
  );

  const BEWELLURL = `https://be-well.crossoverhealth.com/?utm_source=platform&utm_campaign=be_well&utm_medium=tile&utm_term=${user?.employer}+${user?.efId}`;

  return (
    <MarketingCard
      renderCopy={Copy}
      title="Be Well"
      buttonText="Take a Look"
      onButtonClick={() => window?.open(BEWELLURL, "_blank")}
      imgSrc={BeWellImage}
      openNewTab
      linkTo={BEWELLURL}
      dataTestIdPrefix="be-well"
      backgroundColor="#CAD8E4"
      buttonColor={theme?.palette?.text?.primary}
      textColor={theme?.palette?.text?.primary}
      hasTwoCards={hasTwoCards}
    />
  );
};

export default BeWellTile;
