// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ElationChartInfo
 */
export interface ElationChartInfo  {
    /**
     * First name of patient stored in Elation
     * @type {string}
     * @memberof ElationChartInfo
     */
    firstName?: string;
    /**
     * Last name of patient stored in Elation
     * @type {string}
     * @memberof ElationChartInfo
     */
    lastName?: string;
    /**
     * Date of birth of patient stored in Elation
     * @type {string}
     * @memberof ElationChartInfo
     */
    dob?: string;
    /**
     * Sex of patient assigned at birth stored in Elation
     * @type {string}
     * @memberof ElationChartInfo
     */
    sex?: string;
}

export function ElationChartInfoFromJSON(json: any): ElationChartInfo {
    return {
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
    };
}

export function ElationChartInfoToJSON(value?: ElationChartInfo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'first_name': value.firstName,
        'last_name': value.lastName,
        'dob': value.dob,
        'sex': value.sex,
    };
}


