import React from "react";
import { useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import clsx from "clsx";

import Container from "components/Container";
import DependentAccessDisclaimer from "components/Access/DependentAccessDisclaimer";
import DashboardWelcome from "components/LandingPage/WelcomeMessage/DashboardWelcome";
import Box from "components/Box";
import Grid from "components/Grid";
import Heading from "components/DynamicHeadings";

import { useStyles } from "pages/LandingPage/styles";
import Visits from "pages/LandingPage/Visits";
import { HeroHeader } from "templates/Header";
import theme from "styles/mui";

import { getCurrentUser } from "shared/features/user/selectors";
import { getUser } from "shared/features/user";
import { getFeatures } from "shared/features/featureFlags/selectors";

const DependentAccessDashboard = () => {
  const user = useSelector(getUser);
  const member = useSelector(getCurrentUser);
  const classes = useStyles();
  const isScreenSmallerOrWider = useMediaQuery(theme.breakpoints.up("sm"));
  const featureFlags = useSelector(getFeatures);
  const hasCreatedEoc = true;
  const hasAffinity = featureFlags.hasAffinity();
  const message = `For minor account, please call your clinic at ${member?.clinicContact} to gain access to medical record(s).`;

  return (
    <section data-testid="dashboard-with-conversations">
      <HeroHeader
        size="medium"
        color="light-blue"
        headerHeights={{ smallScreen: "10px", largeScreen: "120px" }}
      >
        <Container
          maxWidth="xl"
          className={clsx(
            classes.headerContainer,
            hasAffinity && classes.headerContainerAffinity,
            !isScreenSmallerOrWider ? classes.headerMobile : ""
          )}
        >
          <Box
            pt={3}
            className={
              hasAffinity
                ? classes.dashboardContainerAffinity
                : classes.dashboardContainer
            }
          >
            <DashboardWelcome
              activeEpisodesLength={0}
              hasCreatedEoc={hasCreatedEoc}
              // @ts-ignore: Object is possibly 'null'.
              user={user}
            />
          </Box>
        </Container>
      </HeroHeader>
      <Heading.LevelBoundary>
        <Container
          maxWidth="xl"
          className={clsx(classes.container, classes.dependentContainer)}
        >
          <Grid container spacing={2} className={classes.episodeContainer}>
            <Grid item lg={8} md={12}>
              <Visits
                // @ts-ignore: Object is possibly 'null'.
                id={user?.id}
              />
            </Grid>
            <Grid item lg={4} md={12} className={classes.disclaimerText}>
              <DependentAccessDisclaimer message={message} />
            </Grid>
          </Grid>
        </Container>
      </Heading.LevelBoundary>
    </section>
  );
};

export default DependentAccessDashboard;
