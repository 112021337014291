// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutboundScreeningObjectDetailScreeningTemplate
 */
export interface OutboundScreeningObjectDetailScreeningTemplate  {
    /**
     * 
     * @type {number}
     * @memberof OutboundScreeningObjectDetailScreeningTemplate
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OutboundScreeningObjectDetailScreeningTemplate
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundScreeningObjectDetailScreeningTemplate
     */
    date?: string;
}

export function OutboundScreeningObjectDetailScreeningTemplateFromJSON(json: any): OutboundScreeningObjectDetailScreeningTemplate {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'date': !exists(json, 'date') ? undefined : json['date'],
    };
}

export function OutboundScreeningObjectDetailScreeningTemplateToJSON(value?: OutboundScreeningObjectDetailScreeningTemplate): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'title': value.title,
        'date': value.date,
    };
}


