// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlotProviderObject,
    SlotProviderObjectFromJSON,
    SlotProviderObjectToJSON,
} from './SlotProviderObject';


/**
 * 
 * @export
 * @interface NextAvailableSlotsFetchResponse
 */
export interface NextAvailableSlotsFetchResponse  {
    /**
     * 
     * @type {Array<SlotProviderObject>}
     * @memberof NextAvailableSlotsFetchResponse
     */
    results: Array<SlotProviderObject>;
}

export function NextAvailableSlotsFetchResponseFromJSON(json: any): NextAvailableSlotsFetchResponse {
    return {
        'results': (json['results'] as Array<any>).map(SlotProviderObjectFromJSON),
    };
}

export function NextAvailableSlotsFetchResponseToJSON(value?: NextAvailableSlotsFetchResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': (value.results as Array<any>).map(SlotProviderObjectToJSON),
    };
}


