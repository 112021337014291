// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatientScreening
 */
export interface PatientScreening  {
    /**
     * 
     * @type {string}
     * @memberof PatientScreening
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientScreening
     */
    patientId?: string;
    /**
     * 
     * @type {number}
     * @memberof PatientScreening
     */
    templateId?: number;
    /**
     * 
     * @type {object}
     * @memberof PatientScreening
     */
    data?: object;
    /**
     * 
     * @type {number}
     * @memberof PatientScreening
     */
    checkinId?: number;
    /**
     * 
     * @type {number}
     * @memberof PatientScreening
     */
    appointmentId?: number;
    /**
     * 
     * @type {number}
     * @memberof PatientScreening
     */
    templateInteractionId?: number;
    /**
     * 
     * @type {string}
     * @memberof PatientScreening
     */
    status?: PatientScreeningStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PatientScreening
     */
    triggeredBy?: number;
    /**
     * 
     * @type {Date}
     * @memberof PatientScreening
     */
    triggeredAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatientScreening
     */
    completedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatientScreening
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PatientScreening
     */
    updatedAt?: Date;
}

export function PatientScreeningFromJSON(json: any): PatientScreening {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'patientId': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'templateId': !exists(json, 'template_id') ? undefined : json['template_id'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'checkinId': !exists(json, 'checkin_id') ? undefined : json['checkin_id'],
        'appointmentId': !exists(json, 'appointment_id') ? undefined : json['appointment_id'],
        'templateInteractionId': !exists(json, 'template_interaction_id') ? undefined : json['template_interaction_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'triggeredBy': !exists(json, 'triggered_by') ? undefined : json['triggered_by'],
        'triggeredAt': !exists(json, 'triggered_at') ? undefined : new Date(json['triggered_at']),
        'completedAt': !exists(json, 'completed_at') ? undefined : new Date(json['completed_at']),
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
    };
}

export function PatientScreeningToJSON(value?: PatientScreening): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'patient_id': value.patientId,
        'template_id': value.templateId,
        'data': value.data,
        'checkin_id': value.checkinId,
        'appointment_id': value.appointmentId,
        'template_interaction_id': value.templateInteractionId,
        'status': value.status,
        'triggered_by': value.triggeredBy,
        'triggered_at': value.triggeredAt === undefined ? undefined : value.triggeredAt.toISOString(),
        'completed_at': value.completedAt === undefined ? undefined : value.completedAt.toISOString(),
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    };
}

/**
* @export
* @enum {string}
*/
export enum PatientScreeningStatusEnum {
    Active = 'active',
    Complete = 'complete',
    ApptCancel = 'appt_cancel'
}


