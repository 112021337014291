import { useInfiniteQuery } from "react-query";
import { useEffect } from "react";
import reduce from "lodash/reduce";
import { getQueryString } from "shared/state/queue";
import { queryClient } from "shared/state/StateManagement";
import {
  GetMyQueueOrderEnum,
  fetchEpisodeListsApi,
} from "shared/fetch/src/apis/EpisodeListsApi";

export interface IRequest {
  filterNames: string[];
  values: any[];
  filter: string;
  order?: GetMyQueueOrderEnum;
}

export const filteredQueueEpisodesQueryKey = (request: IRequest) => [
  "queueEpisodes",
  request.filter,
  "names",
  request.filterNames,
  "values",
  request.values,
  "order",
  request.order,
];

let fetching = false;

const fetchQueueEpisodes = (request: IRequest, pageParam: number | string) => {
  const { filterNames, values, order } = request;
  return fetchEpisodeListsApi.getMyQueue({
    ...getQueryString(filterNames, values, order),
    page: pageParam.toString(),
  });
};

export const usePrefetchQueueEpisodes = () => {
  return (requests: any[]) => {
    requests.map((request: any) => {
      return queryClient.prefetchInfiniteQuery(
        filteredQueueEpisodesQueryKey(request),
        ({ pageParam = 1 }) => {
          return fetchQueueEpisodes(request, pageParam);
        }
      );
    });
  };
};

export default (request: IRequest) => {
  const {
    data,
    isLoading: isFetching,
    fetchNextPage: fetchNextEpisodesPage,
    hasNextPage,
    refetch,
    isFetchingNextPage,
  } = useInfiniteQuery(
    filteredQueueEpisodesQueryKey(request),
    ({ pageParam = 1 }) => {
      return fetchQueueEpisodes(request, pageParam);
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.page === lastPage.last) {
          return false;
        }

        return (lastPage?.page || 0) + 1;
      },
      keepPreviousData: true,
      refetchInterval: 10000,
      enabled: !!request.filter,
    }
  );

  const fetchNextPage = () => {
    if (!fetching && hasNextPage) {
      fetching = true;
      fetchNextEpisodesPage();
    }
  };

  const filteredQueueEpisodes = reduce(
    data?.pages,
    (acc: any, { results: fetchedActiveEpisodes = [] }) => {
      return [...acc, ...fetchedActiveEpisodes];
    },
    []
  );

  useEffect(() => {
    fetching = false;
  }, [filteredQueueEpisodes.length]);

  const count = data?.pages?.[0]?.count;

  return {
    filteredQueueEpisodes,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    count,
    refetch,
  };
};
