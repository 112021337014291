// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContentTypeItem,
    ContentTypeItemFromJSON,
    ContentTypeItemToJSON,
} from './ContentTypeItem';


/**
 * 
 * @export
 * @interface ContentTypeSchema
 */
export interface ContentTypeSchema  {
    /**
     * 
     * @type {string}
     * @memberof ContentTypeSchema
     */
    contentTypeCategoryId: string;
    /**
     * 
     * @type {string}
     * @memberof ContentTypeSchema
     */
    contentTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof ContentTypeSchema
     */
    contentTypeLabel: string;
    /**
     * 
     * @type {Array<ContentTypeItem>}
     * @memberof ContentTypeSchema
     */
    contentTypeItems: Array<ContentTypeItem>;
}

export function ContentTypeSchemaFromJSON(json: any): ContentTypeSchema {
    return {
        'contentTypeCategoryId': json['content_type_category_id'],
        'contentTypeId': json['content_type_id'],
        'contentTypeLabel': json['content_type_label'],
        'contentTypeItems': (json['content_type_items'] as Array<any>).map(ContentTypeItemFromJSON),
    };
}

export function ContentTypeSchemaToJSON(value?: ContentTypeSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'content_type_category_id': value.contentTypeCategoryId,
        'content_type_id': value.contentTypeId,
        'content_type_label': value.contentTypeLabel,
        'content_type_items': (value.contentTypeItems as Array<any>).map(ContentTypeItemToJSON),
    };
}


