import {
  PDFViewerFile,
  PDFViewerMode,
  PDFViewerSideNavigationState,
} from "./utils";
import clsx from "clsx";
import Alert from "components/Alert";
import React, { MutableRefObject, useMemo, useState } from "react";
import Typography from "components/Typography";
import PDFViewerToolbar from "./PDFViewerToolbar";
import PDFViewerSkeleton from "./PDFViewerSkeleton";
import { Page, pdfjs, Document, Outline } from "react-pdf";
import usePDFViewerController from "./usePDFViewerController";
import CircularProgress from "components/Progress/CircularProgress";
import { ButtonBase } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import "react-pdf/dist/esm/Page/AnnotationLayer.css";

// ensure that the PDF worker source is pointing to the full path relative to the domain
// otherwise pdfjs will try to construct it's own source
// pdfjs worker could also be loaded from an external location if found beneficial
// i.e: anytime the react-pdf package is updated we need to manually update the file in /public but loading it from the external link would not require the additional manual work
// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    root: {
      width: "100%",
      height: "100%",
      "& .react-pdf__message": {
        width: "100%",
        height: "100%",
      },
    },
    rootWithAlert: {
      width: "100%",
      height: "calc(100% - 58px)",
    },
    pdfWrapper: {
      display: "grid",
      backgroundColor: "#202124",
      gridTemplateAreas: `"toolbar toolbar" "sidebar pages"`,
      gridTemplateColumns: "236px calc(100% - 236px)",
      gridTemplateRows: "64px auto",
      height: "100%",
      width: "100%",

      "&.sidebar-hidden": {
        gridTemplateAreas: `"toolbar" "pages"`,
        gridTemplateColumns: "100%",
      },
    },
    toolbar: {
      gridArea: "toolbar",
      top: 0,
    },
    sideBar: {
      gridArea: "sidebar",
      paddingBottom: spacing(2),
      overflow: "hidden",
      borderRightWidth: 1,
      borderRightStyle: "dotted",
      borderRightColor: "#4d4d50",
      "&.is-hidden": {
        visibility: "none",
        display: "none",
      },
    },
    outlineContainer: {
      height: "100%",
      paddingTop: spacing(2),
      overflowY: "auto",
      overflowX: "hidden",
    },
    previewContainer: {
      height: "100%",
      display: "flex",
      rowGap: spacing(3),
      flexDirection: "column",
      width: "calc(100% + 24px)",
      overflowY: "auto",
      overflowX: "hidden",
    },
    pagesContainer: {
      gridArea: "pages",
      overflow: "auto",
      marginLeft: spacing(1),
      marginRight: spacing(1),

      "&::-webkit-scrollbar-corner": {
        backgroundColor: "#202124",
      },
      "&::-webkit-scrollbar": {
        width: 16,
        height: 16,
        backgroundColor: "#202124",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: 12,
        backgroundColor: "#797a7c",
        border: "4px solid #202124",
        backgroundClip: "padding-clip",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#202124",
      },
    },
    pdfPage: {
      width: "fit-content",
      overflow: "hidden",
      marginLeft: "auto",
      marginRight: "auto",
      marginBottom: spacing(1),
      "&:last-of-type": {
        marginBottom: spacing(2),
      },
      "& canvas": {
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    thumbnailWrapper: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      marginRight: 8,
      opacity: 0.75,
      "&.active_page": {
        opacity: 1,
        "& $thumbnailButton": {
          borderWidth: 3,
          borderStyle: "solid",
          borderColor: palette.appBackground?.blue,
        },
      },
      "&:first-of-type": {
        marginTop: spacing(3),
      },
    },
    thumbnailButton: {
      width: 128,
      height: "auto",
      overflow: "hidden",
      "& img": {
        width: "100%",
      },
    },
    thumbnailCaption: {
      marginTop: spacing(1),
      color: palette.white,
    },
  })
);

interface PDFViewerProps {
  title: string;
  isLoading?: boolean;
  fileDownloadUrl?: string;
  viewMode?: PDFViewerMode;
  /** can be a url, a module/import, File from a form input, or an object for an advanced setting*/
  file: string | File | PDFViewerFile;
  isLargeFile?: boolean;
  /** attach access token in the request headers */
  withAccessToken?: boolean;
  /** optimize viewer for display only, i.e: hides print & download  */
  isUploadPreview?: boolean;
  /** Certain zoom functionalities will only be available when the wrapper/parent element has a fixed height value.
   *  Ref object to direct parent
   * */
  containerRef?: MutableRefObject<HTMLElement | null>;
}

const PDFViewer: React.FC<PDFViewerProps> = ({
  file,
  title,
  isLargeFile,
  containerRef,
  fileDownloadUrl,
  withAccessToken,
  isUploadPreview = false,
  viewMode = PDFViewerMode.VERTICAL,
}) => {
  const [sourceError, setSourceError] = useState(false);
  const classes = useStyles();
  let isFileObject = false;

  if (typeof file === "object") {
    isFileObject = true;
  }

  const memoizedFile = useMemo(() => file, [file, title]);

  const viewController = usePDFViewerController({
    viewMode,
    isUploadPreview,
    withAccessToken,
    file: memoizedFile,
    isLargeFile,
  });

  const containerViewBoxHeight = containerRef?.current?.clientHeight
    ? containerRef.current.clientHeight - 72
    : undefined;

  const {
    scale,
    fileProxy,
    pageCount,
    activePage,
    thumbnails,
    rotationAngle,
    pdfHasOutline,
    isLoadingPreview,
    selectedViewMode,
    pageCanvasRef,
    pagesContainerRef,
    activeThumbnailRef,
    sideNavigationState,
    onItemClick,
    onOutlineError,
    onDocumentScroll,
    onDocumentLoadSuccess,
    setActivePage,
  } = viewController;

  return (
    <Document
      aria-label={title}
      rotate={rotationAngle}
      className={isLargeFile ? classes.rootWithAlert : classes.root}
      error={<PDFViewerSkeleton isError />}
      noData={<PDFViewerSkeleton isNoData />}
      loading={<PDFViewerSkeleton isLoading={!sourceError} />}
      file={isFileObject ? memoizedFile : fileProxy}
      onLoadSuccess={onDocumentLoadSuccess}
      onSourceError={() => setSourceError(true)}
    >
      <div
        className={clsx(classes.pdfWrapper, {
          "sidebar-hidden":
            sideNavigationState === PDFViewerSideNavigationState.CLOSED,
        })}
      >
        <PDFViewerToolbar
          title={title}
          className={classes.toolbar}
          viewController={viewController}
          fileDownloadUrl={fileDownloadUrl}
          isLargeFile={isLargeFile}
          hasPageFitZoom={!Number.isNaN(containerViewBoxHeight)}
        />

        <div
          className={clsx(classes.sideBar, {
            "is-hidden":
              sideNavigationState === PDFViewerSideNavigationState.CLOSED,
          })}
        >
          {sideNavigationState === PDFViewerSideNavigationState.OUTLINE && (
            <div className={classes.outlineContainer}>
              <Outline
                onItemClick={onItemClick}
                onLoadError={onOutlineError}
                onLoadSuccess={(outline: any) => {
                  if (!Boolean(outline)) {
                    onOutlineError();
                  }
                }}
              />
              {!pdfHasOutline && (
                <Alert severity="info">
                  <Typography variant="inherit" color="textSecondary">
                    No outline contents found in document
                  </Typography>
                </Alert>
              )}
            </div>
          )}

          {sideNavigationState === PDFViewerSideNavigationState.PREVIEW && (
            <div className={classes.previewContainer}>
              {thumbnails.map((thumbnailLink, index) => (
                <div
                  key={index}
                  data-preview-id={index + 1}
                  ref={
                    activePage === index + 1 ? activeThumbnailRef : undefined
                  }
                  className={clsx(classes.thumbnailWrapper, {
                    active_page: activePage === index + 1,
                  })}
                >
                  <ButtonBase
                    className={classes.thumbnailButton}
                    aria-label={`Go to page ${index + 1}`}
                    onClick={() =>
                      isLargeFile
                        ? setActivePage(index + 1)
                        : onItemClick({ pageNumber: index + 1 })
                    }
                  >
                    <img
                      src={thumbnailLink}
                      alt={`Thumbnail of page ${index + 1}`}
                    />
                  </ButtonBase>
                  <Typography
                    component="div"
                    color="textPrimary"
                    className={classes.thumbnailCaption}
                  >
                    {index + 1}
                  </Typography>
                </div>
              ))}
              {isLoadingPreview && (
                <Typography color="textSecondary" align="center" mt={2} mr={3}>
                  <CircularProgress color="inherit" />
                </Typography>
              )}
            </div>
          )}
        </div>

        <div
          ref={pagesContainerRef}
          onScroll={onDocumentScroll}
          className={classes.pagesContainer}
        >
          {selectedViewMode === PDFViewerMode.SINGLE_PAGE && (
            <Page
              pageNumber={activePage}
              canvasRef={pageCanvasRef}
              className={classes.pdfPage}
              scale={scale === 0 ? 1 : scale / 100}
              height={scale === 0 ? containerViewBoxHeight : undefined}
              error={<PDFViewerSkeleton isError isPage />}
              noData={<PDFViewerSkeleton isNoData isPage />}
            />
          )}

          {(selectedViewMode === PDFViewerMode.HORIZONTAL ||
            selectedViewMode === PDFViewerMode.VERTICAL) && (
            <>
              {isLargeFile ? (
                <Page
                  pageNumber={activePage}
                  className={classes.pdfPage}
                  scale={scale === 0 ? 1 : scale / 100}
                  height={scale === 0 ? containerViewBoxHeight : undefined}
                  canvasRef={pageCanvasRef}
                  error={<PDFViewerSkeleton isError isPage />}
                  noData={<PDFViewerSkeleton isNoData isPage />}
                />
              ) : (
                Array.from(new Array(pageCount), (_el, index) => (
                  <Page
                    pageNumber={index + 1}
                    key={`page_${index + 1}`}
                    className={classes.pdfPage}
                    scale={scale === 0 ? 1 : scale / 100}
                    height={scale === 0 ? containerViewBoxHeight : undefined}
                    canvasRef={index === 0 ? pageCanvasRef : undefined}
                    error={<PDFViewerSkeleton isError isPage />}
                    noData={<PDFViewerSkeleton isNoData isPage />}
                  />
                ))
              )}
            </>
          )}
        </div>
      </div>
    </Document>
  );
};

export default React.memo(PDFViewer);
