// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnrollBiometricsRequestBody
 */
export interface EnrollBiometricsRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof EnrollBiometricsRequestBody
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof EnrollBiometricsRequestBody
     */
    publicKey: string;
    /**
     * 
     * @type {string}
     * @memberof EnrollBiometricsRequestBody
     */
    deviceName?: string;
}

export function EnrollBiometricsRequestBodyFromJSON(json: any): EnrollBiometricsRequestBody {
    return {
        'deviceId': json['device_id'],
        'publicKey': json['public_key'],
        'deviceName': !exists(json, 'device_name') ? undefined : json['device_name'],
    };
}

export function EnrollBiometricsRequestBodyToJSON(value?: EnrollBiometricsRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'device_id': value.deviceId,
        'public_key': value.publicKey,
        'device_name': value.deviceName,
    };
}


