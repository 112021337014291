// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InputWorkspaceDraftSchema,
    InputWorkspaceDraftSchemaFromJSON,
    InputWorkspaceDraftSchemaToJSON,
} from '../models/InputWorkspaceDraftSchema'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    OutputWorkspaceDraftSchema,
    OutputWorkspaceDraftSchemaFromJSON,
    OutputWorkspaceDraftSchemaToJSON,
} from '../models/OutputWorkspaceDraftSchema'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    WorkspaceDraftDataSchema,
    WorkspaceDraftDataSchemaFromJSON,
    WorkspaceDraftDataSchemaToJSON,
} from '../models/WorkspaceDraftDataSchema'


export interface CreateWorkspaceDraftRequest {
    id: string;
    inputWorkspaceDraftSchema?: InputWorkspaceDraftSchema;
}

export interface DeleteWorkspaceDraftRequest {
    id: string;
}

export interface FetchEpisodeWorkspaceDraftRequest {
    id: string;
}

export interface FetchWorkspaceDraftRequest {
    id: string;
}

export interface SubmitWorkspaceDraftRequest {
    id: string;
}

export interface UpdateWorkspaceDraftRequest {
    id: string;
    inputWorkspaceDraftSchema?: InputWorkspaceDraftSchema;
}


/**
 * Create a workspace draft
 * Create a workspace draft
 */
function createWorkspaceDraftRaw<T>(requestParameters: CreateWorkspaceDraftRequest, requestConfig: runtime.TypedQueryConfig<T, OutputWorkspaceDraftSchema> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createWorkspaceDraft.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{id}/workspace_drafts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || InputWorkspaceDraftSchemaToJSON(requestParameters.inputWorkspaceDraftSchema),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(OutputWorkspaceDraftSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Create a workspace draft
* Create a workspace draft
*/
export function createWorkspaceDraft<T>(requestParameters: CreateWorkspaceDraftRequest, requestConfig?: runtime.TypedQueryConfig<T, OutputWorkspaceDraftSchema>): QueryConfig<T> {
    return createWorkspaceDraftRaw(requestParameters, requestConfig);
}

/**
 * Discard workspace draft
 * Discard workspace draft
 */
function deleteWorkspaceDraftRaw<T>(requestParameters: DeleteWorkspaceDraftRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteWorkspaceDraft.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/workspace_drafts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Discard workspace draft
* Discard workspace draft
*/
export function deleteWorkspaceDraft<T>(requestParameters: DeleteWorkspaceDraftRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteWorkspaceDraftRaw(requestParameters, requestConfig);
}

/**
 * Fetch active Episode workspace draft
 * Fetch active workspace draft
 */
function fetchEpisodeWorkspaceDraftRaw<T>(requestParameters: FetchEpisodeWorkspaceDraftRequest, requestConfig: runtime.TypedQueryConfig<T, OutputWorkspaceDraftSchema> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchEpisodeWorkspaceDraft.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{id}/workspace_draft`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(OutputWorkspaceDraftSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Fetch active Episode workspace draft
* Fetch active workspace draft
*/
export function fetchEpisodeWorkspaceDraft<T>(requestParameters: FetchEpisodeWorkspaceDraftRequest, requestConfig?: runtime.TypedQueryConfig<T, OutputWorkspaceDraftSchema>): QueryConfig<T> {
    return fetchEpisodeWorkspaceDraftRaw(requestParameters, requestConfig);
}

/**
 * Fetch workspace draft
 * Fetch WorkSpace Draft
 */
function fetchWorkspaceDraftRaw<T>(requestParameters: FetchWorkspaceDraftRequest, requestConfig: runtime.TypedQueryConfig<T, OutputWorkspaceDraftSchema> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchWorkspaceDraft.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/workspace_drafts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(OutputWorkspaceDraftSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Fetch workspace draft
* Fetch WorkSpace Draft
*/
export function fetchWorkspaceDraft<T>(requestParameters: FetchWorkspaceDraftRequest, requestConfig?: runtime.TypedQueryConfig<T, OutputWorkspaceDraftSchema>): QueryConfig<T> {
    return fetchWorkspaceDraftRaw(requestParameters, requestConfig);
}

/**
 * Submit workspace draft
 * Submit workspace draft
 */
function submitWorkspaceDraftRaw<T>(requestParameters: SubmitWorkspaceDraftRequest, requestConfig: runtime.TypedQueryConfig<T, OutputWorkspaceDraftSchema> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling submitWorkspaceDraft.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/workspace_drafts/{id}/submit`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(OutputWorkspaceDraftSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Submit workspace draft
* Submit workspace draft
*/
export function submitWorkspaceDraft<T>(requestParameters: SubmitWorkspaceDraftRequest, requestConfig?: runtime.TypedQueryConfig<T, OutputWorkspaceDraftSchema>): QueryConfig<T> {
    return submitWorkspaceDraftRaw(requestParameters, requestConfig);
}

/**
 * Update workspace draft
 * Update workspace draft
 */
function updateWorkspaceDraftRaw<T>(requestParameters: UpdateWorkspaceDraftRequest, requestConfig: runtime.TypedQueryConfig<T, OutputWorkspaceDraftSchema> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateWorkspaceDraft.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/workspace_drafts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PATCH',
            headers: headerParameters,
        },
        body: queryParameters || InputWorkspaceDraftSchemaToJSON(requestParameters.inputWorkspaceDraftSchema),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(OutputWorkspaceDraftSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Update workspace draft
* Update workspace draft
*/
export function updateWorkspaceDraft<T>(requestParameters: UpdateWorkspaceDraftRequest, requestConfig?: runtime.TypedQueryConfig<T, OutputWorkspaceDraftSchema>): QueryConfig<T> {
    return updateWorkspaceDraftRaw(requestParameters, requestConfig);
}

