import { UpdateAppointmentVideoConferenceRequest, updateAppointmentVideoConference } from "shared/api/src/apis/VideoConferenceApi";
import { AppointmentOutput } from "shared/fetch/src/models/AppointmentOutput";

export const updateAppointmentVideoConferenceMutation = (
  payload: UpdateAppointmentVideoConferenceRequest
) =>
  updateAppointmentVideoConference(
    payload,

    {
      transform: (responseBody: AppointmentOutput) => ({
        appointmentVideoConference: responseBody,
      }),
      update: {
        appointmentVideoConference: (prev: any, next: any) => next,
      },
    }
  );
