// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ComponentInputCore
 */
export interface ComponentInputCore  {
    /**
     * 
     * @type {string}
     * @memberof ComponentInputCore
     */
    type?: ComponentInputCoreTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ComponentInputCore
     */
    body?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ComponentInputCore
     */
    acknowledgeAndRespond?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ComponentInputCore
     */
    recipientId?: string;
}

export function ComponentInputCoreFromJSON(json: any): ComponentInputCore {
    return {
        'type': !exists(json, 'type') ? undefined : json['type'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'acknowledgeAndRespond': !exists(json, 'acknowledge_and_respond') ? undefined : json['acknowledge_and_respond'],
        'recipientId': !exists(json, 'recipient_id') ? undefined : json['recipient_id'],
    };
}

export function ComponentInputCoreToJSON(value?: ComponentInputCore): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'type': value.type,
        'body': value.body,
        'acknowledge_and_respond': value.acknowledgeAndRespond,
        'recipient_id': value.recipientId,
    };
}

/**
* @export
* @enum {string}
*/
export enum ComponentInputCoreTypeEnum {
    MemberMessage = 'member_message',
    CtmMessage = 'ctm_message'
}


