// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberChargeSchema
 */
export interface MemberChargeSchema  {
    /**
     * 
     * @type {number}
     * @memberof MemberChargeSchema
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberChargeSchema
     */
    appointmentId?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberChargeSchema
     */
    paymentMethodId?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberChargeSchema
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberChargeSchema
     */
    item?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberChargeSchema
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberChargeSchema
     */
    chargeDate?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberChargeSchema
     */
    paidAt?: string;
}

export function MemberChargeSchemaFromJSON(json: any): MemberChargeSchema {
    return {
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'appointmentId': !exists(json, 'appointment_id') ? undefined : json['appointment_id'],
        'paymentMethodId': !exists(json, 'payment_method_id') ? undefined : json['payment_method_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'item': !exists(json, 'item') ? undefined : json['item'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'chargeDate': !exists(json, 'charge_date') ? undefined : json['charge_date'],
        'paidAt': !exists(json, 'paid_at') ? undefined : json['paid_at'],
    };
}

export function MemberChargeSchemaToJSON(value?: MemberChargeSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'amount': value.amount,
        'appointment_id': value.appointmentId,
        'payment_method_id': value.paymentMethodId,
        'status': value.status,
        'item': value.item,
        'description': value.description,
        'charge_date': value.chargeDate,
        'paid_at': value.paidAt,
    };
}


