export const activeCmsContentTypes = [
  "Common Questions",
  "Diagnoses",
  "Question Sets",
  "Service Lines",
];

export const filterOutInactiveContentTypes = (
  menu: any[],
  activeContentTypes: string[]
) => {
  menu?.forEach((topLevelHeading) => {
    topLevelHeading.contentTypes = topLevelHeading.contentTypes.filter(
      (contentType: any) => {
        return activeContentTypes.includes(contentType.label);
      }
    );
  });
  return menu;
};
