// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PushNotificationCategoryResponse,
    PushNotificationCategoryResponseFromJSON,
    PushNotificationCategoryResponseToJSON,
} from './PushNotificationCategoryResponse';


/**
 * 
 * @export
 * @interface PushNotificationCategoriesResponse
 */
export interface PushNotificationCategoriesResponse  {
    /**
     * 
     * @type {Array<PushNotificationCategoryResponse>}
     * @memberof PushNotificationCategoriesResponse
     */
    results?: Array<PushNotificationCategoryResponse>;
}

export function PushNotificationCategoriesResponseFromJSON(json: any): PushNotificationCategoriesResponse {
    return {
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(PushNotificationCategoryResponseFromJSON),
    };
}

export function PushNotificationCategoriesResponseToJSON(value?: PushNotificationCategoriesResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(PushNotificationCategoryResponseToJSON),
    };
}


