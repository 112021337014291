/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UserSettings,
    UserSettingsFromJSON,
    UserSettingsToJSON,
} from '../models/UserSettings'
import {
    UserSettingsResponse,
    UserSettingsResponseFromJSON,
    UserSettingsResponseToJSON,
} from '../models/UserSettingsResponse'


export interface GetUserSettingsRequest {
    id: string;
    userSettings?: UserSettings;
}
export interface PutUserSettingRequest {
    id: string;
    userSettings?: UserSettings;
}


export interface IUserSettingsApi {
      getUserSettings: (requestParameters: GetUserSettingsRequest) => Promise<UserSettingsResponse>
      putUserSetting: (requestParameters: PutUserSettingRequest) => Promise<UserSettingsResponse>
      getUserSettingsWithResponse: (requestParameters: GetUserSettingsRequest) => Promise<{ value: UserSettingsResponse, response: any}>
      putUserSettingWithResponse: (requestParameters: PutUserSettingRequest) => Promise<{ value: UserSettingsResponse, response: any}>
}

/**
 * no description
 */
export class UserSettingsApi extends runtime.BaseAPI {

    /**
     * Get user settings values
     * Get user settings
     */
    getUserSettingsRaw = async (requestParameters: GetUserSettingsRequest): Promise<{ runtime: runtime.ApiResponse<UserSettingsResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserSettings.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/user_settings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: UserSettingsToJSON(requestParameters.userSettings),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => UserSettingsResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get user settings values
     * Get user settings
     */
    getUserSettings = async (requestParameters: GetUserSettingsRequest): Promise<UserSettingsResponse> => {
        const { runtime } = await this.getUserSettingsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get user settings values
     * Get user settings
     */
    getUserSettingsWithResponse = async (requestParameters: GetUserSettingsRequest): Promise<{ value: UserSettingsResponse, response: any}> => {
        const { runtime, response } = await this.getUserSettingsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update or Create user settings values
     * Update or Create user settings
     */
    putUserSettingRaw = async (requestParameters: PutUserSettingRequest): Promise<{ runtime: runtime.ApiResponse<UserSettingsResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putUserSetting.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/user_settings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserSettingsToJSON(requestParameters.userSettings),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => UserSettingsResponseFromJSON(jsonValue)), response };
    }

    /**
     * Update or Create user settings values
     * Update or Create user settings
     */
    putUserSetting = async (requestParameters: PutUserSettingRequest): Promise<UserSettingsResponse> => {
        const { runtime } = await this.putUserSettingRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update or Create user settings values
     * Update or Create user settings
     */
    putUserSettingWithResponse = async (requestParameters: PutUserSettingRequest): Promise<{ value: UserSettingsResponse, response: any}> => {
        const { runtime, response } = await this.putUserSettingRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchUserSettingsApi: IUserSettingsApi  = new UserSettingsApi();
