import { uniqBy } from "lodash";
import { State } from "..";
import { WorkspaceDraftDataSchemaVisitReferral } from "shared/fetch/src/models/WorkspaceDraftDataSchemaVisitReferral";

// Visits
export const WS_SET_VISITS = "WS_SET_VISITS";
export const WS_ADD_VISIT = "WS_ADD_VISIT";
export const WS_REMOVE_VISIT = "WS_REMOVE_VISIT";
export const WS_CLEAR_VISITS = "WS_CLEAR_VISITS";
export const WS_UPDATE_VISIT = "WS_UPDATE_VISIT";

const updateVisitsState =
  ({ state, episodeId }: any) =>
  (update: any) => ({
    ...state,
    [episodeId]: [...update],
  });

const clearVisitsState =
  ({ state, episodeId }: any) =>
  () => ({
    ...state,
    [episodeId]: null,
  });

export const visits = (state = {} as State, action: any) => {
  const episodeId = action.episodeId;
  const updater = updateVisitsState({
    state,
    episodeId,
  });

  const clearAll = clearVisitsState({
    state,
    episodeId,
  });

  if (!episodeId) {
    return state;
  }

  switch (action.type) {
    case WS_SET_VISITS:
      if (!!state[episodeId]?.length) {
        return state;
      }
      return updater(action.payload);

    case WS_CLEAR_VISITS:
      return clearAll();

    case WS_ADD_VISIT:
      return updater(
        uniqBy(
          [...(state?.[episodeId] || []), action.payload],
          "appointmentType"
        )
      );

    case WS_UPDATE_VISIT:
      const updatedVisits = state[episodeId]?.filter(
        (visit: any) =>
          visit.clinicId !== action?.payload?.clinicId &&
          visit.appointmentType !== action?.payload?.appointmentType
      );

      return updater(
        uniqBy([...(updatedVisits || []), action.payload], "clinicId")
      );

    case WS_REMOVE_VISIT:
      const updatedState = state[episodeId]?.filter(
        (visit: any) => visit.appointmentType !== action.payload
      );
      return updatedState.length > 0
        ? updater(uniqBy(updatedState, "appointmentType"))
        : clearAll();

    default:
      return state;
  }
};

export const setVisits = (
  payload: WorkspaceDraftDataSchemaVisitReferral[] | null,
  episodeId: string
) => ({
  type: WS_SET_VISITS,
  payload,
  episodeId,
});

export const AddVisit = (
  visit: WorkspaceDraftDataSchemaVisitReferral,
  episodeId: string
) => ({
  type: WS_ADD_VISIT,
  payload: visit,
  episodeId,
});

export const updateVisit = (
  visit: WorkspaceDraftDataSchemaVisitReferral,
  episodeId: string
) => ({
  type: WS_UPDATE_VISIT,
  payload: visit,
  episodeId,
});

export const removeVisit = (
  appointmentType: number | string,
  episodeId: string
) => ({
  type: WS_REMOVE_VISIT,
  payload: appointmentType,
  episodeId,
});

export const clearVisits = (episodeId: string) => ({
  type: WS_CLEAR_VISITS,
  episodeId,
});
