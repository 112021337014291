// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OutputMemberSearchSchemaPropsPrimaryAccountHolderAddress,
    OutputMemberSearchSchemaPropsPrimaryAccountHolderAddressFromJSON,
    OutputMemberSearchSchemaPropsPrimaryAccountHolderAddressToJSON,
} from './OutputMemberSearchSchemaPropsPrimaryAccountHolderAddress';


/**
 * 
 * @export
 * @interface OutputMemberSearchSchemaPropsPrimaryAccountHolder
 */
export interface OutputMemberSearchSchemaPropsPrimaryAccountHolder  {
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaPropsPrimaryAccountHolder
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaPropsPrimaryAccountHolder
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaPropsPrimaryAccountHolder
     */
    email?: string;
    /**
     * 
     * @type {OutputMemberSearchSchemaPropsPrimaryAccountHolderAddress}
     * @memberof OutputMemberSearchSchemaPropsPrimaryAccountHolder
     */
    address?: OutputMemberSearchSchemaPropsPrimaryAccountHolderAddress;
}

export function OutputMemberSearchSchemaPropsPrimaryAccountHolderFromJSON(json: any): OutputMemberSearchSchemaPropsPrimaryAccountHolder {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'address': !exists(json, 'address') ? undefined : OutputMemberSearchSchemaPropsPrimaryAccountHolderAddressFromJSON(json['address']),
    };
}

export function OutputMemberSearchSchemaPropsPrimaryAccountHolderToJSON(value?: OutputMemberSearchSchemaPropsPrimaryAccountHolder): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'id': value.id,
        'email': value.email,
        'address': OutputMemberSearchSchemaPropsPrimaryAccountHolderAddressToJSON(value.address),
    };
}


