import { CmsNavigationSchemaHierarchy } from "shared/api/src/models/CmsNavigationSchemaHierarchy";
import { ContentTypeItems } from "shared/api/src/models/ContentTypeItems";

export const isValidUrlPath = (
  menuHierarchyItems: CmsNavigationSchemaHierarchy[],
  location: string,
  paramPositionOne: number,
  paramPositionTwo: number
) => {
  const pathArray = location?.split("/");
  const cmsContentTypeId = pathArray[pathArray.length - paramPositionOne];
  const contentTypeCategoryId = pathArray[pathArray.length - paramPositionTwo];

  const formattedMenuHierarchyItemsData = menuHierarchyItems?.reduce(
    (accumulator: any, currentValue: CmsNavigationSchemaHierarchy) => {
      // @ts-ignore: Object is possibly 'null'.
      accumulator[currentValue.id] = {
        // @ts-ignore: Object is possibly 'null'.
        value: currentValue.contentTypes.reduce(
          (acc: any, curr: ContentTypeItems) => {
            // @ts-ignore: Object is possibly 'null'.
            acc[curr.id] = curr.id;
            return acc;
          },
          {}
        ),
      };
      return accumulator;
    },
    {}
  );

  return (
    !!formattedMenuHierarchyItemsData &&
    !!formattedMenuHierarchyItemsData[contentTypeCategoryId] &&
    !!formattedMenuHierarchyItemsData[contentTypeCategoryId].value[
      cmsContentTypeId
    ]
  );
};
