import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { selectLoggedInUser } from "shared/features/user/selectors";
import { Link } from "components/Button";
import useConsumerController from "../utils/useConsumerController";

const useStyles = makeStyles(() => ({
  link: {
    fontSize: 13,
    minWidth: "unset",
    marginBottom: "1px",
    padding: 0,
    "&:hover": {
      color: "white",
      textDecoration: "underline",
    },
    textDecoration: "underline",
  },
}));

const useConsumerMessage = () => {
  const classes = useStyles();
  const user = selectLoggedInUser();

  const {
    timeDiffMessage,
    handleNavigation,
    isExpiryDateValid,
    cardExpiresAt,
    isConsumerMemberType,
    hasAboveThirtyDays,
    // @ts-ignore: Object is possibly 'null'.
  } = useConsumerController(user?.id?.toString());

  if (
    user?.ctm ||
    !isConsumerMemberType ||
    !isExpiryDateValid ||
    hasAboveThirtyDays
  ) {
    return {};
  }

  return {
    message: (
      <>
        <span data-testid="consumer-message">
          Your credit card will expire in {timeDiffMessage}. Please{" "}
          <Link
            onClick={handleNavigation}
            className={classes.link}
            data-pendo="consumer-membership-banner-link"
          >
            click here
          </Link>{" "}
          to update your payment method.
        </span>
      </>
    ),
    orderDate: cardExpiresAt,
  };
};

export default useConsumerMessage;
