// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PermissionSchema,
    PermissionSchemaFromJSON,
    PermissionSchemaToJSON,
} from './PermissionSchema';


/**
 * 
 * @export
 * @interface RoleSchemaProps
 */
export interface RoleSchemaProps  {
    /**
     * 
     * @type {Array<PermissionSchema>}
     * @memberof RoleSchemaProps
     */
    permissions: Array<PermissionSchema>;
}

export function RoleSchemaPropsFromJSON(json: any): RoleSchemaProps {
    return {
        'permissions': (json['permissions'] as Array<any>).map(PermissionSchemaFromJSON),
    };
}

export function RoleSchemaPropsToJSON(value?: RoleSchemaProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'permissions': (value.permissions as Array<any>).map(PermissionSchemaToJSON),
    };
}


