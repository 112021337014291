import React from "react";
import User from "models/User";
import { selectLoggedInUser } from "shared/features/user/selectors";

const usePatientIneligibilityMessage = () => {
  const user = selectLoggedInUser();
  const isUserEligible = !User.isUserIneligible(user);

  if (isUserEligible) {
    return {};
  }

  return {
    message: (
      <span>
        Your account is ineligible. You can review all past Conversations but
        you can't create new ones or message the team.
      </span>
    ),
    orderDate: null,
  };
};

export default usePatientIneligibilityMessage;
