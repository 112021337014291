import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { ContentTypeProps } from "containers/Layout/CmsMenuNavigationHierarchy";
import MenuItem from "components/Menu/MenuItem";
import kebabCase from "lodash/kebabCase";
import { useLocation, useNavigate } from "react-router";
import Menu from "components/Menu";

interface IProps {
  anchorEl: HTMLElement;
  handleClose: () => void;
  cmsSubMenuHierarchyItems: ContentTypeProps[];
}

interface UseStylesProps {
  length: number;
}

const useStyles: any = makeStyles({
  paper: (props: UseStylesProps) => ({
    marginTop: "1%",
    marginLeft: `${props.length > 5 ? "5%" : "10px"}`,
  }),
  list: (props: UseStylesProps) => ({
    display: "grid",
    gridTemplateColumns: `repeat(${props.length > 5 ? 2 : 1}, 1fr)`,
    color: "rgba(43,57,73, 0.7)",
  }),
  selected: {
    color: "#0B665C !important",
  },
});

const SubMenuHierarchyComponent = ({
  anchorEl,
  handleClose,
  cmsSubMenuHierarchyItems,
}: IProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const props = { length: cmsSubMenuHierarchyItems.length };
  const classes = useStyles(props);
  return (
    <Menu
      id="user-dropdownmenu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      // getContentAnchorEl={null}
      classes={{
        paper: classes.paper,
        list: classes.list,
      }}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      data-testid="tools-menu-dropdown"
    >
      {cmsSubMenuHierarchyItems.map(
        (cmsSubMenuHierarchyItem: any, index: number) => {
          return (
            <MenuItem
              classes={{
                selected: classes.selected,
              }}
              selected={pathname === cmsSubMenuHierarchyItem.pathname}
              key={index}
              data-e2e={`cms-submenu-link-${kebabCase(
                cmsSubMenuHierarchyItem.label
              )}`}
              onClick={() => {
                handleClose();
                navigate(cmsSubMenuHierarchyItem.pathname, {
                  state: { prevPath: pathname },
                });
              }}
            >
              {cmsSubMenuHierarchyItem.label}
            </MenuItem>
          );
        }
      )}
    </Menu>
  );
};

export default SubMenuHierarchyComponent;
