// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InputSubTypeSchemaProps
 */
export interface InputSubTypeSchemaProps  {
    /**
     * 
     * @type {string}
     * @memberof InputSubTypeSchemaProps
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof InputSubTypeSchemaProps
     */
    helperText?: string;
    /**
     * 
     * @type {string}
     * @memberof InputSubTypeSchemaProps
     */
    precopy?: string;
}

export function InputSubTypeSchemaPropsFromJSON(json: any): InputSubTypeSchemaProps {
    return {
        'key': json['key'],
        'helperText': !exists(json, 'helper_text') ? undefined : json['helper_text'],
        'precopy': !exists(json, 'precopy') ? undefined : json['precopy'],
    };
}

export function InputSubTypeSchemaPropsToJSON(value?: InputSubTypeSchemaProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'key': value.key,
        'helper_text': value.helperText,
        'precopy': value.precopy,
    };
}


