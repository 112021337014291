// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface XopPodOutputSchema
 */
export interface XopPodOutputSchema  {
    /**
     * 
     * @type {string}
     * @memberof XopPodOutputSchema
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof XopPodOutputSchema
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof XopPodOutputSchema
     */
    careTeamType?: string;
    /**
     * 
     * @type {string}
     * @memberof XopPodOutputSchema
     */
    careTeamName?: string;
    /**
     * 
     * @type {string}
     * @memberof XopPodOutputSchema
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof XopPodOutputSchema
     */
    coreCareTeam?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XopPodOutputSchema
     */
    displaySubtitle?: string;
}

export function XopPodOutputSchemaFromJSON(json: any): XopPodOutputSchema {
    return {
        'id': json['id'],
        'type': json['type'],
        'careTeamType': !exists(json, 'care_team_type') ? undefined : json['care_team_type'],
        'careTeamName': !exists(json, 'care_team_name') ? undefined : json['care_team_name'],
        'name': json['name'],
        'coreCareTeam': !exists(json, 'core_care_team') ? undefined : json['core_care_team'],
        'displaySubtitle': !exists(json, 'display_subtitle') ? undefined : json['display_subtitle'],
    };
}

export function XopPodOutputSchemaToJSON(value?: XopPodOutputSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'type': value.type,
        'care_team_type': value.careTeamType,
        'care_team_name': value.careTeamName,
        'name': value.name,
        'core_care_team': value.coreCareTeam,
        'display_subtitle': value.displaySubtitle,
    };
}


