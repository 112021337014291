import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { clearSnackbar } from "shared/state/ui/snackbar";
import { useTheme, useMediaQuery, Theme, Portal } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { State } from "@types";
import SnackbarContent from "components/Snackbar/SnackbarContent";
import Snackbar from "components/Snackbar";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    anchorOriginBottomLeft: {
      bottom: "70px",
      [theme.breakpoints.only("xs")]: {
        bottom: "85px",
      },
    },
    text: {
      color: theme.palette.text.primary,
      fontSize: theme.typography?.bodySmall?.fontSize,
    },
  })
);

export default function Toast() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { anchorOriginBottomLeft, text } = useStyles();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const vertical = "bottom";
  const horizontal = matches ? "left" : "center";

  const { message, open, appearance, width } = useSelector(
    (state: State) => state.ui.snackbar
  );

  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <Portal>
      <Snackbar
        open={open}
        className={anchorOriginBottomLeft}
        autoHideDuration={20000}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
        transitionDuration={0}
        ContentProps={{
          "aria-labelledby": "message-id",
        }}
      >
        <SnackbarContent
          style={{ width }}
          onClose={handleClose}
          appearance={appearance}
          hasCloseButton
          message={
            <span className={text} id="message-id">
              {message}
            </span>
          }
        />
      </Snackbar>
    </Portal>
  );
}
