import React, { FC } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

import BeWellTile from "components/BeWellTile";
import PreventiveEocMarketing from "../PreventiveEocMarketing";
import { EpisodeSummary } from "shared/fetch/src/models/EpisodeSummary";

interface IProps {
  preventiveEpisode: EpisodeSummary;
  preventiveEocMarketing: {
    title: string;
    buttonText: string;
  };
}

const useStyles = makeStyles(({ breakpoints, spacing }) =>
  createStyles({
    twoTiles: {
      width: "100%",
      display: "grid",
      gridGap: spacing(2),
      gridTemplateColumns: "repeat(2, 1fr)",

      [breakpoints.down("md")]: {
        gridTemplateColumns: "1fr",
        width: "100%",
      },
    },
  })
);

const BeWellAndMentalHealthCard: FC<IProps> = ({
  preventiveEpisode,
  preventiveEocMarketing,
}) => {
  const classes = useStyles();
  return (
    <div className={classes.twoTiles} data-testid="be-well-mental-health">
      <PreventiveEocMarketing
        preventiveEpisode={preventiveEpisode}
        {...preventiveEocMarketing}
        hasTwoCards
      />
      <BeWellTile hasTwoCards />
    </div>
  );
};

export default BeWellAndMentalHealthCard;
