// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     ComponentInputCore,
    ComponentInputCoreFromJSON,
    ComponentInputCoreToJSON,
} from './ComponentInputCore';
import {
     DocumentArrayInput,
    DocumentArrayInputFromJSON,
    DocumentArrayInputToJSON,
} from './DocumentArrayInput';
/**
 * @type ComponentInput
 * @export
 */
export interface ComponentInput extends ComponentInputCore, DocumentArrayInput {
}

export function ComponentInputFromJSON(json: any): ComponentInput {
    return {
        ...ComponentInputCoreFromJSON(json),
        ...DocumentArrayInputFromJSON(json),
    };
}

export function ComponentInputToJSON(value?: ComponentInput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...ComponentInputCoreToJSON(value),
        ...DocumentArrayInputToJSON(value),
    };
}

