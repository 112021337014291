// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NetworkCareTeamFiltered,
    NetworkCareTeamFilteredFromJSON,
    NetworkCareTeamFilteredToJSON,
} from './NetworkCareTeamFiltered';


/**
 * 
 * @export
 * @interface NetworkCareTeamsOutputFiltered
 */
export interface NetworkCareTeamsOutputFiltered  {
    /**
     * 
     * @type {Array<NetworkCareTeamFiltered>}
     * @memberof NetworkCareTeamsOutputFiltered
     */
    teams: Array<NetworkCareTeamFiltered>;
}

export function NetworkCareTeamsOutputFilteredFromJSON(json: any): NetworkCareTeamsOutputFiltered {
    return {
        'teams': (json['teams'] as Array<any>).map(NetworkCareTeamFilteredFromJSON),
    };
}

export function NetworkCareTeamsOutputFilteredToJSON(value?: NetworkCareTeamsOutputFiltered): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'teams': (value.teams as Array<any>).map(NetworkCareTeamFilteredToJSON),
    };
}


