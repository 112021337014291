import { PrintJsOptions } from ".";
import { addHeader, Browser, cleanUp } from "./functions";

const createBlobAndPrint = (
  params: PrintJsOptions,
  printFrame: HTMLIFrameElement,
  data: BlobPart
) => {
  // Pass response or base64 data to a blob and create a local object url
  const localPdf = new window.Blob([data], { type: "application/pdf" });
  const localPdfUrl = window.URL.createObjectURL(localPdf);
  // Set iframe src with pdf document url
  printFrame.setAttribute("src", localPdfUrl);
  Printer.send(params, printFrame);
};

export const printImage = (
  params: PrintJsOptions,
  printFrame: HTMLIFrameElement
) => {
  // Create printable element (container)
  const printableElement = document.createElement("div");

  // Create the image element and append it to the printable container
  const img = document.createElement("img");
  img.setAttribute("style", params.imageStyle || "");

  // Set image src with the file url
  img.src = params.printable;

  // The following block is for Firefox, which for some reason requires the image's src to be fully qualified in
  // order to print it
  if (Browser.isFirefox()) {
    const fullyQualifiedSrc = img.src;
    img.src = fullyQualifiedSrc;
  }

  // Create the image wrapper
  const imageWrapper = document.createElement("div");

  // Append image to the wrapper element
  imageWrapper.appendChild(img);

  // Append wrapper to the printable element
  printableElement.appendChild(imageWrapper);

  // Check if we are adding a print header
  if (params.header) {
    addHeader(printableElement, params);
  }

  // Print image
  Printer.send(params, printFrame, printableElement);
};

export const printPdf = (
  params: PrintJsOptions,
  printFrame: HTMLIFrameElement
) => {
  // Check if we have base64 data
  if (params.base64) {
    const bytesArray = Uint8Array.from(atob(params.printable), (c) =>
      c.charCodeAt(0)
    );
    createBlobAndPrint(params, printFrame, bytesArray);
    return;
  }

  // Format pdf url
  params.printable = /^(blob|http|\/\/)/i.test(params.printable)
    ? params.printable
    : window.location.origin +
      (params.printable.charAt(0) !== "/"
        ? "/" + params.printable
        : params.printable);

  // Get the file through a http request (Preload)
  const req = new window.XMLHttpRequest();
  req.responseType = "arraybuffer";

  req.addEventListener("error", () => {
    cleanUp(params);
    // Since we don't have a pdf document available, we will stop the print job
    if (params.onError) {
      params.onError(req.statusText, req);
    }
  });

  req.addEventListener("load", () => {
    // Check for errors
    if ([200, 201].indexOf(req.status) === -1) {
      cleanUp(params);
      if (params.onError) {
        params.onError(req.statusText, req);
      }
      // Since we don't have a pdf document available, we will stop the print job
      return;
    }

    // Print requested document
    createBlobAndPrint(params, printFrame, req.response);
  });

  req.open("GET", params.printable, true);
  req.send();
};

const Printer = {
  send: (
    params: PrintJsOptions,
    printFrame: HTMLIFrameElement,
    printableElement?: HTMLElement
  ) => {
    // Append iframe element to document body
    document.getElementsByTagName("body")[0].appendChild(printFrame);

    // Get iframe element
    const iframeElement = document.getElementById(
      params.frameId!
    ) as HTMLIFrameElement;

    // Wait for iframe to load all content
    iframeElement.onload = () => {
      if (params.type === "pdf") {
        // Add a delay for Firefox. In my tests, 1000ms was sufficient but 100ms was not
        if (Browser.isFirefox() || Browser.isSafari()) {
          setTimeout(() => performPrint(params, iframeElement), 1000);
        } else {
          performPrint(params, iframeElement);
        }
        return;
      }

      // images
      const printDocument =
        iframeElement.contentWindow?.document || iframeElement.contentDocument;

      // Append printable element to the iframe body
      if (printableElement) {
        printDocument?.body.appendChild(printableElement);
      }

      // Add custom style
      if (params.type === "image" && params.style) {
        // Create style element
        const style = document.createElement("style");
        style.innerHTML = params.style;

        // Append style element to iframe's head
        printDocument?.head.appendChild(style);
      }

      // If printing images, wait for them to load inside the iframe
      const images = printDocument?.getElementsByTagName("img");

      if (images && images.length > 0) {
        loadIframeImages(Array.from(images)).then(() =>
          performPrint(params, iframeElement)
        );
      } else {
        performPrint(params, iframeElement);
      }
    };
  },
};

const performPrint = (
  params: PrintJsOptions,
  iframeElement: HTMLIFrameElement
) => {
  try {
    iframeElement.focus();

    // If Edge or IE, try catch with execCommand
    if (Browser.isEdge() || Browser.isIE()) {
      try {
        iframeElement.contentWindow?.document.execCommand(
          "print",
          false,
          undefined
        );
      } catch (e) {
        iframeElement.contentWindow?.print();
      }
    } else {
      // Other browsers
      iframeElement.contentWindow?.print();
    }
  } catch (error) {
    if (params.onError) {
      params.onError(error as string);
    }
  } finally {
    if (Browser.isFirefox()) {
      // Move the iframe element off-screen and make it invisible
      iframeElement.style.visibility = "hidden";
      iframeElement.style.left = "-1px";
    }

    // window.print() is async and in some browsers we risk unloading
    // the iframe before print is done, a small delay seems to fix it
    setTimeout(() => cleanUp(params), 200);
  }
};

const loadIframeImages = (images: HTMLImageElement[]) => {
  const promises = images.map((image) => {
    if (image.src && image.src !== window.location.href) {
      return loadIframeImage(image);
    }
    return undefined;
  });

  return Promise.all(promises);
};

const loadIframeImage = (image: HTMLImageElement) => {
  return new Promise((resolve) => {
    const pollImage = () => {
      if (
        !image ||
        typeof image.naturalWidth === "undefined" ||
        image.naturalWidth === 0 ||
        !image.complete
      ) {
        setTimeout(pollImage, 500);
      } else {
        resolve(true);
      }
    };
    pollImage();
  });
};
