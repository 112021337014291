import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from "redux";
import { QueryConfig, QueryResponse, RequestSuccessAction } from "redux-query";
import { State } from "shared/types/state";
import { setPatientAccessGrantToken } from "shared/state/ui/member";
import { Entities } from "shared/types/state";

const patientAccessMiddleware: Middleware =
  (api: MiddlewareAPI<Dispatch<AnyAction>, State>) =>
  (next: Dispatch) =>
  (action: AnyAction | RequestSuccessAction) => {
    switch ((action as AnyAction).type) {
      case "@@query/REQUEST_SUCCESS":
        const queryAction = action as QueryResponse;
        const patientAccessGrant =
          queryAction?.responseHeaders?.["x-xo-patient-access-grant"];
        if (patientAccessGrant) {
          api.dispatch(setPatientAccessGrantToken(patientAccessGrant));
        }
        break;

      case "@@query/REQUEST_ASYNC":
      case "@@query/MUTATE_ASYNC":
        const queryRequestAction = action as QueryConfig<Entities>;
        const token = api.getState().ui.member.accessGrantToken;
        if (
          token &&
          queryRequestAction.options &&
          queryRequestAction.options.headers
        ) {
          queryRequestAction.options.headers[
            "X-XO-Patient-Access-Grant"
          ] = `${token}`;
        }
        break;
    }

    // eslint-disable-next-line no-console
    const original = console.warn;
    // eslint-disable-next-line no-console
    console.warn = () => null;
    const nextAction = next(action as AnyAction);
    // eslint-disable-next-line no-console
    console.warn = original;
    return nextAction;
  };

export default patientAccessMiddleware;
