// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     RoleOutputCoreSchema,
    RoleOutputCoreSchemaFromJSON,
    RoleOutputCoreSchemaToJSON,
} from './RoleOutputCoreSchema';
import {
     StoredPermissionSchema,
    StoredPermissionSchemaFromJSON,
    StoredPermissionSchemaToJSON,
} from './StoredPermissionSchema';
import {
     StoredRoleSchemaExample,
    StoredRoleSchemaExampleFromJSON,
    StoredRoleSchemaExampleToJSON,
} from './StoredRoleSchemaExample';
import {
     StoredRoleSchemaProps,
    StoredRoleSchemaPropsFromJSON,
    StoredRoleSchemaPropsToJSON,
} from './StoredRoleSchemaProps';
/**
 * @type StoredRoleSchema
 * @export
 */
export interface StoredRoleSchema extends RoleOutputCoreSchema, StoredRoleSchemaExample, StoredRoleSchemaProps {
}

export function StoredRoleSchemaFromJSON(json: any): StoredRoleSchema {
    return {
        ...RoleOutputCoreSchemaFromJSON(json),
        ...StoredRoleSchemaExampleFromJSON(json),
        ...StoredRoleSchemaPropsFromJSON(json),
    };
}

export function StoredRoleSchemaToJSON(value?: StoredRoleSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...RoleOutputCoreSchemaToJSON(value),
        ...StoredRoleSchemaExampleToJSON(value),
        ...StoredRoleSchemaPropsToJSON(value),
    };
}

