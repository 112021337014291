// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AvatarDeletionSuccessResponse
 */
export interface AvatarDeletionSuccessResponse  {
    /**
     * 
     * @type {string}
     * @memberof AvatarDeletionSuccessResponse
     */
    avatarUrl?: string;
}

export function AvatarDeletionSuccessResponseFromJSON(json: any): AvatarDeletionSuccessResponse {
    return {
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
    };
}

export function AvatarDeletionSuccessResponseToJSON(value?: AvatarDeletionSuccessResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'avatar_url': value.avatarUrl,
    };
}


