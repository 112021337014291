import { Entities } from "redux-query";
import isEqual from "lodash/isEqual";
import { actionableItemsRedirectUrl } from "shared/api/src/apis/ActionableItemsApi";
import { ActionableItemDetails } from "shared/fetch/src/models/ActionableItemDetails";
import { ActionableItemsDetailsOutput } from "shared/fetch/src/models/ActionableItemsDetailsOutput";

const fetchActionableItemsRedirectUrlRequest = () => {
  return actionableItemsRedirectUrl<Entities>({
    // NOTE: in prod, use pagination params
    transform: (body: ActionableItemsDetailsOutput) => ({
      actionableItems: body.results || [],
    }),
    update: {
      actionableItems: (
        prev: ActionableItemDetails[],
        next: ActionableItemDetails[]
      ) => {
        if (isEqual(prev, next)) {
          return prev;
        }

        return next;
      },
    },
    force: true,
  });
};

export default fetchActionableItemsRedirectUrlRequest;
