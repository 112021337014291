import React from "react";
import { useMatch, useLocation } from "react-router";
import { useSelector } from "react-redux";
import ActionableItemsMenuButton from "./ActionableItemsMenuButton";
import UserMenuButton from "./UserMenuButton";
import { NavRoute } from "./navigationBarRoutes";
import Logo from "styles/images/logo/logo.png";
import LogoDark from "styles/images/logo/logo-dark.png";
import MetaLogoDark from "styles/images/logo/xo_meta_dark.png";
import MetaLogo from "styles/images/logo/xo_meta.png";
import VitellaLogoDark from "styles/images/logo/xo_vitella_dark.png";
import VitellaLogo from "styles/images/logo/xo_vitella.png";
import { JsonUser as User } from "shared/api/src/models/JsonUser";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import MuiAppBar from "components/AppBar";
import { getCurrentUser, hasPermission } from "shared/features/user/selectors";
import CTMDrawer from "components/CTMDrawer/Drawer";
import { renameKeys } from "utils/renameKeys";
import focusFirstElement from "utils/focusFirstElement";
import MemberSearchField from "features/memberSearch/components/MemberSearchField";
import ButtonBase from "components/Button/ButtonBase";
import { Link } from "components/Button/";
import Hidden from "components/Hidden";
import Toolbar from "components/Toolbar";
import NavigationTabs from "./NavigationTabs";
import clsx from "clsx";
import Typography from "components/Typography";
import { NativeRouteNavigate } from "components/NativeBackButton";
import DraftReplySafeLink from "components/Button/DraftReplySafeLink";
import { selectNativeAppState } from "shared/state/nativeApp/selectors";
import useDraftSafeNavigate from "hooks/useDraftSafeNavigate";
import useMobileCheck, {
  useMobileAccountUpdates,
  useSmallScreenCheck,
} from "hooks/useMobileCheck";
import { selectFeatures } from "shared/features/featureFlags/selectors";
import MobileNotificationBell from "./MobileNotificationBell";
import useAccess from "hooks/useAccess";
import { isDependantAccess } from "shared/utils/isDependentAccess";
import MoreOptionsMenu from "components/EOCTimeline/MoreOptionsMenu";
import { ActionableItemDetails } from "shared/fetch/src/models/ActionableItemDetails";
import { CmsNavigationSchemaHierarchy } from "shared/api/src/models/CmsNavigationSchemaHierarchy";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      position: "sticky",
      top: 0,
      width: "100%",
      zIndex: 2,
      boxShadow: `2px 2px 6px ${theme.palette.boxShadowLightGray}`,

      "& .MuiAppBar-root": {
        position: "relative",
        backgroundColor: "white",
        zIndex: 2,
        minHeight: 56,
        boxShadow: `2px 2px 6px ${theme.palette.boxShadowLightGray}`,
      },
      "& .MuiToolbar-root": {
        minHeight: "100%",
        paddingLeft: 0,
        paddingRight: 0,
        marginLeft: 0,
        justifyContent: "space-between",
      },
      "& .MuiToolbar-root > div": {
        display: "flex",
        alignSelf: "stretch",
        alignItems: "center",
      },
      "& .MuiTabs-root": {
        alignSelf: "stretch",
        height: "100%",
      },
      "& .MuiTabs-root .MuiTab-root": {
        [theme.breakpoints.down("md")]: {
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    rootMobile: {
      backgroundColor: theme.palette?.appBackground?.darkBlue,
      boxShadow: "none",

      "& .MuiAppBar-root": {
        backgroundColor: theme.palette?.appBackground?.darkBlue,
        boxShadow: "none",
      },
    },
    headerContainer: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      height: "100%",
    },
    header: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingLeft: 12,
      paddingRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
      minHeight: 60,
    },
    headerTitle: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      color: "white",
    },
    headerTitleText: {
      fontSize: "16px",
      fontWeight: "bold",
    },
    leftSection: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    mobileNav: {
      position: "relative",
      zIndex: 1,
    },
    menuButton: {
      marginLeft: -30,
    },
    bellContainer: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    moreIconContainer: {
      display: "flex",
      alignItems: "center",
      paddingRight: "16px",
    },
    navigationTabsContainer: {
      marginLeft: theme.spacing(4),
      height: "100%",
    },
    activationHeader: {
      padding: "18px 0px 15px 0px",
      display: "flex",
      justifyContent: "center",
    },
    skipLink: {
      color: theme.palette.text.primary,
      fontSize: theme.typography?.bodySmall?.fontSize,
      position: "relative",
      top: "-9999px",
      left: "-9999px",
      width: "1px",
      height: "1px",
      // and the &:focus styling makes the button visible when it is focused
      "&:focus": {
        top: "0px",
        left: "0px",
        // center the text inside the faux button
        padding: "3px 16px 9px 14px",
        marginRight: theme.spacing(2),
        border: `2px solid ${theme.palette.yellowHover}`,
        borderRadius: "20px",
        whiteSpace: "nowrap",
        display: "inline-block",
        width: "126px",
        height: "32px",
        margin: "0",
      },
    },
  })
);

const LogoImage = ({ src }: { src: string }) => (
  <img height="30px" src={src} alt="Go to Home Page" />
);

const DashboardButton = () => {
  const theme = useTheme();
  const isScreenSmallerOrWider = useMediaQuery(theme.breakpoints.up("sm"));
  const safeNavigate = useDraftSafeNavigate();
  // @ts-ignore: Object is possibly 'null'.
  const user: User = useSelector(getCurrentUser);

  const getImage = () => {
    if (isScreenSmallerOrWider && user?.logo === "xop") {
      return Logo;
    } else if (isScreenSmallerOrWider && user?.logo === "meta") {
      return MetaLogo;
    } else if (!isScreenSmallerOrWider && user?.logo === "meta") {
      return MetaLogoDark;
    } else if (isScreenSmallerOrWider && user?.logo === "vitella") {
      return VitellaLogo;
    } else if (!isScreenSmallerOrWider && user?.logo === "vitella") {
      return VitellaLogoDark;
    } else {
      return LogoDark;
    }
  };

  return (
    <ButtonBase
      component={DraftReplySafeLink}
      onClick={(e: any) => {
        e.preventDefault();
        safeNavigate("/");
      }}
      to="/"
      role="link"
      data-testid="to-home-page-nav-logo"
      id="go-to-home-page"
    >
      <LogoImage src={getImage()} />
    </ButtonBase>
  );
};

const HeaderTitle = ({ headerTitle }: { headerTitle: string }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isScreenSmallerOrWider = useMediaQuery(theme.breakpoints.up("sm"));

  if (isScreenSmallerOrWider) {
    return <DashboardButton />;
  }

  return (
    <div className={classes.headerTitle}>
      <Typography
        className={classes.headerTitleText}
        data-testid="header-title"
      >
        {headerTitle}
      </Typography>
    </div>
  );
};

const MobileAppButton = ({ user }: { user: User }) => {
  const { pathname } = useLocation();
  const { isApp, pageTitle } = selectNativeAppState();
  const showAccountUpdates = useMobileAccountUpdates();
  const isSmallScreen = useSmallScreenCheck();
  const hasHealthRecordsAccess = useAccess({
    to: PermissionsEnum.HealthRecordsRead,
  });
  const featureFlags = selectFeatures();

  switch (pathname) {
    case "/care-history":
      return <HeaderTitle headerTitle="Care History" />;
    case "/health-records":
      return (
        hasHealthRecordsAccess && <HeaderTitle headerTitle="Health Records" />
      );
    case "/providers":
      return <HeaderTitle headerTitle="Meet Your Team" />;
    case "/notifications":
      return (
        <>
          <NativeRouteNavigate />
          <HeaderTitle headerTitle="Notifications" />
        </>
      );
    case "/":
      return <DashboardButton />;
    case "/account":
      if (isApp) {
        return showAccountUpdates ? (
          <NativeRouteNavigate />
        ) : (
          <DashboardButton />
        );
      } else {
        return <DashboardButton />;
      }
    default:
      if (isApp) {
        return <NativeRouteNavigate />;
      } else {
        if (
          !user?.ctm &&
          pathname?.match(/\/members\/\d+\/conversations\/\d+$/) &&
          featureFlags?.hasMemberInlineMessaging()
        ) {
          return isSmallScreen ? (
            <NativeRouteNavigate />
          ) : (
            <HeaderTitle headerTitle={pageTitle || ""} />
          );
        } else {
          return <DashboardButton />;
        }
      }
  }
};

interface AppBarProps {
  user: User;
  hasXOLogoOnly?: boolean;
  dispatch?: any;
  actionableItems: ActionableItemDetails[];
  userNav?: NavRoute[];
  cmsMenuHierarchyItems?: CmsNavigationSchemaHierarchy[];
}

const AppBar = (props: AppBarProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const isDrawerMenuEnabled = true;
  const isMobileTabBarEnabled = true;
  const hasCmsToolsReadPermission = useSelector(
    hasPermission(PermissionsEnum.CmsToolsRead)
  );
  const theme = useTheme();
  const isScreenWidthMediumOrWider = useMediaQuery(theme.breakpoints.up("md"));
  const isScreenSmallerOrWider = useMediaQuery(theme.breakpoints.up("sm"));
  const isMemberSearchVisible = props?.user?.ctm;
  const isMobile = useMobileCheck();
  const isLogoVisible = isMemberSearchVisible
    ? isScreenWidthMediumOrWider
    : true;

  const { pathname } = useLocation();

  const featureFlags = selectFeatures();
  const hasMobileAccountPageUpdates = featureFlags?.hasMobileAccountUpdates();

  const match = useMatch("/members/:memberId/conversations/:episodeId/*");
  const episodeId = match?.params?.episodeId;

  const isTimelinePage = pathname?.match(/\/members\/\d+\/conversations\/\d+$/);
  const hasMemberInlineMessaging = featureFlags.hasMemberInlineMessaging();
  const hideAccountMenuProfile =
    (pathname.includes("/account") ||
      (isTimelinePage && !props?.user?.ctm && hasMemberInlineMessaging)) &&
    isMobile &&
    hasMobileAccountPageUpdates;

  const { isApp } = selectNativeAppState();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { userNav, cmsMenuHierarchyItems } = props;

  const mobileMenuDrawerWithCms = userNav?.map((route: NavRoute) => {
    if (route.name === "Tools") {
      return {
        ...route,
        items: cmsMenuHierarchyItems?.map(
          (cmsMenuHierarchyItemRoute: CmsNavigationSchemaHierarchy) => {
            return renameKeys(cmsMenuHierarchyItemRoute, {
              contentTypes: "items",
            });
          }
        ),
      };
    }
    return route;
  });

  const mobileMenuDrawerItems = hasCmsToolsReadPermission
    ? mobileMenuDrawerWithCms
    : userNav?.filter((tab: NavRoute) => tab.name !== "Tools");

  if (!props?.user?.ctm && isDrawerMenuEnabled) {
    const baseURL = "https://crossoverhealth.com";
    mobileMenuDrawerItems?.push({
      name: "More",
      items: [
        {
          name: "Terms & Conditions",
          pathname: `${baseURL}/terms-and-conditions`,
          isExternal: true,
        },
        {
          name: "Privacy Policy",
          pathname: `${baseURL}/privacy-policy`,
          isExternal: true,
        },
        {
          name: "Consent(s)",
          pathname: `${baseURL}/universal-consent/`,
          isExternal: true,
        },
        {
          id: "call-us",
          name: "Call Us",
          pathname: `${baseURL}/universal-consent/`,
          data: props?.user?.clinicContact,
        },
        {
          name: "Tech Support",
          pathname: `https://answers.crossoverhealth.com/`,
          isExternal: true,
        },
      ],
    } as any);
  }

  if (props?.hasXOLogoOnly) {
    return (
      <div className={clsx(classes.root, classes.activationHeader)}>
        <LogoImage src={Logo} />
      </div>
    );
  }

  return (
    <>
      <div
        className={clsx(
          classes.root,
          !isScreenSmallerOrWider && classes.rootMobile
        )}
      >
        <MuiAppBar color="default">
          <Toolbar>
            <div className={classes.headerContainer}>
              <div className={classes.header}>
                <div className={classes.leftSection}>
                  <Hidden only={["xs"]}>
                    <Link
                      aria-label="Skip to Content"
                      className={classes.skipLink}
                      onClick={() => focusFirstElement("main-container-div")}
                      focusRipple
                    >
                      Skip to Content
                    </Link>
                  </Hidden>
                  {/* @ts-ignore: Object is possibly 'null'. */}
                  {isLogoVisible && <MobileAppButton user={props?.user} />}
                  <Hidden only={["xs", "sm", "md", "lg"]}>
                    <div className={classes.navigationTabsContainer}>
                      <NavigationTabs userNav={userNav} />
                    </div>
                  </Hidden>
                </div>
                <div
                  style={{
                    width: isMemberSearchVisible ? "100%" : "auto",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                    marginRight: 0,
                  }}
                >
                  {isMemberSearchVisible && (
                    <div
                      style={{
                        width: isScreenWidthMediumOrWider ? 220 : "100%",
                        margin: "auto 0",
                        marginRight: theme.spacing(2),
                        backgroundColor: !isScreenSmallerOrWider
                          ? "white"
                          : "none",
                        borderRadius: 10,
                      }}
                    >
                      <MemberSearchField user={props?.user} />
                    </div>
                  )}

                  <div
                    style={{
                      borderLeft: isScreenSmallerOrWider
                        ? `solid 1px ${theme.palette?.strokes?.divider}`
                        : "none",
                      paddingLeft: theme.spacing(1),
                      height: "100%",
                      display: "flex",
                      flexDirection: "row",
                      margin: "auto 0",
                    }}
                  >
                    {!hideAccountMenuProfile && (
                      <UserMenuButton
                        user={props.user}
                        dispatch={props.dispatch}
                      />
                    )}
                    {/* This notificaction bell shows in the web app */}
                    {!props?.user?.ctm &&
                      !isApp &&
                      !(isTimelinePage && hasMemberInlineMessaging) &&
                      !isDependantAccess() && (
                        <div className={classes.bellContainer}>
                          <ActionableItemsMenuButton
                            actionableItems={props.actionableItems}
                          />
                        </div>
                      )}
                    {/* This notification bell shows in the native mobile app */}
                    {!props?.user?.ctm &&
                      isApp &&
                      featureFlags.hasNotificationCenter() &&
                      !isTimelinePage &&
                      !pathname.includes("/notifications") &&
                      !isDependantAccess() && (
                        <div className={classes.bellContainer}>
                          <MobileNotificationBell />
                        </div>
                      )}
                    {!props?.user?.ctm &&
                      isMobile &&
                      isTimelinePage &&
                      hasMemberInlineMessaging &&
                      !isDependantAccess() && (
                        <div className={classes.moreIconContainer}>
                          <MoreOptionsMenu
                            isMobile
                            episodeId={episodeId}
                            user={props?.user}
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </Toolbar>
        </MuiAppBar>
        {isMobileTabBarEnabled && (
          <Hidden only={["xs", "xl"]}>
            <div className={classes.mobileNav}>
              <NavigationTabs
                userNav={userNav}
                centered={!isScreenWidthMediumOrWider}
                data-e2e="navigation-tabs-top"
              />
            </div>
          </Hidden>
        )}
      </div>
      <CTMDrawer
        open={open}
        onClose={handleDrawerClose}
        items={mobileMenuDrawerItems}
      />
    </>
  );
};

export default AppBar;
