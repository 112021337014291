import { useQuery } from "react-query";
import {
  MemberConversationListSummariesRequest,
  fetchEpisodeListsApi,
} from "shared/fetch/src/apis/EpisodeListsApi";

export const fetchConversationsQueryKey = (
  requestParams: MemberConversationListSummariesRequest
) => [
  "conversations",
  "id",
  requestParams.id,
  "ifModifiedSince",
  requestParams.ifModifiedSince,
  "pageSize",
  requestParams.pageSize,
  "page",
  requestParams.page,
  "state",
  requestParams.state,
];

export const useFetchConversations = (
  requestParameters: MemberConversationListSummariesRequest
) => {
  const {
    data: conversations,
    isLoading: isPending,
    refetch: refresh,
    isRefetching,
  } = useQuery(
    fetchConversationsQueryKey(requestParameters),
    () =>
      fetchEpisodeListsApi.memberConversationListSummaries(requestParameters),
    {
      keepPreviousData: true,
    }
  );

  return {
    conversations: conversations || { results: [] },
    isPending,
    refresh,
    isRefetching,
    paginationMetadata: {
      page: conversations?.page,
      count: conversations?.last,
    },
  };
};
