// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InviteDependentRequestBody
 */
export interface InviteDependentRequestBody  {
    /**
     * Email of the dependent to invite
     * @type {string}
     * @memberof InviteDependentRequestBody
     */
    email: string;
    /**
     * Resend the invite email. If the email doesn\'t match the previous invite, no new invite will be sent. Default: false.
     * @type {boolean}
     * @memberof InviteDependentRequestBody
     */
    resend?: boolean;
}

export function InviteDependentRequestBodyFromJSON(json: any): InviteDependentRequestBody {
    return {
        'email': json['email'],
        'resend': !exists(json, 'resend') ? undefined : json['resend'],
    };
}

export function InviteDependentRequestBodyToJSON(value?: InviteDependentRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'email': value.email,
        'resend': value.resend,
    };
}


