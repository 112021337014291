// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfigResponseApp
 */
export interface ConfigResponseApp  {
    /**
     * 
     * @type {string}
     * @memberof ConfigResponseApp
     */
    title1?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigResponseApp
     */
    title2?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigResponseApp
     */
    title3?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigResponseApp
     */
    title4?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigResponseApp
     */
    helpHTML?: string;
}

export function ConfigResponseAppFromJSON(json: any): ConfigResponseApp {
    return {
        'title1': !exists(json, 'title1') ? undefined : json['title1'],
        'title2': !exists(json, 'title2') ? undefined : json['title2'],
        'title3': !exists(json, 'title3') ? undefined : json['title3'],
        'title4': !exists(json, 'title4') ? undefined : json['title4'],
        'helpHTML': !exists(json, 'helpHTML') ? undefined : json['helpHTML'],
    };
}

export function ConfigResponseAppToJSON(value?: ConfigResponseApp): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title1': value.title1,
        'title2': value.title2,
        'title3': value.title3,
        'title4': value.title4,
        'helpHTML': value.helpHTML,
    };
}


