import React from "react";
import Grid from "@mui/material/Grid";
import Skeleton from "./";

const FiltersSkeleton = () => (
  <Grid container spacing={2}>
    <Grid item>
      <Skeleton width={50} height={36} />
    </Grid>
    <Grid item>
      <Skeleton width={50} height={36} />
    </Grid>
    <Grid item>
      <Skeleton width={50} height={36} />
    </Grid>
  </Grid>
);

export default FiltersSkeleton;
