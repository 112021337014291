import { useMutation, useQueryClient } from "react-query";
import { userNotificationQueryKey } from "./useGetNotifications";
import { selectLoggedInUser } from "shared/features/user/selectors";
import {
  GetUserPushNotificationsStateEnum,
  MarkUserPushNotificationAsReadRequest,
  fetchUserPushNotificationsApi,
} from "shared/fetch/src/apis/UserPushNotificationsApi";

const useMarkUserNotificationAsRead = () => {
  const user = selectLoggedInUser();
  const userId = user?.id?.toString() || "";

  const queryClient = useQueryClient();

  const { mutate } = useMutation(
    (request: MarkUserPushNotificationAsReadRequest) => {
      return fetchUserPushNotificationsApi
        .markUserPushNotificationAsRead(request)
        .finally(() => {
          queryClient.invalidateQueries(
            userNotificationQueryKey({
              id: userId,
              state: GetUserPushNotificationsStateEnum.Read,
            })
          );
          queryClient.invalidateQueries(
            userNotificationQueryKey({
              id: userId,
              state: GetUserPushNotificationsStateEnum.Sent,
            })
          );
        });
    }
  );

  return { markUserNotificationAsRead: mutate };
};

export default useMarkUserNotificationAsRead;
