import cloneDeep from "lodash/cloneDeep";
import { WorkspaceDraftDataSchema } from "shared/api/src/models/WorkspaceDraftDataSchema";
import { WorkspaceDraftDataSchemaItem } from "shared/api/src/models/WorkspaceDraftDataSchemaItem";
import { WorkspaceDraftOutputCoreSchema } from "shared/api/src/models/WorkspaceDraftOutputCoreSchema";
import { ComponentOutput } from "shared/fetch/src/models/ComponentOutput";

// Actions
export const SET_WORKSPACE_PATH = "SET_WORKSPACE_PATH";
export const SET_WORKSPACE_VISIBLE = "SET_WORKSPACE_VISIBLE";
export const SET_WORKSPACE_SUBMITTED = "SET_WORKSPACE_SUBMITTED";
export const SET_ACKNOWLEDGE_AND_RESPOND = "SET_ACKNOWLEDGE_AND_RESPOND";
export const SET_EDITING_DRAFT = "SET_EDITING_DRAFT";
export const SET_WORKSPACE_DOCKED = "SET_WORKSPACE_DOCKED";
export const SET_SCROLL_TO_BOTTOM = "SET_SCROLL_TO_BOTTOM";
export const SET_REPLY_BOX_FOCUS = " SET_REPLY_BOX_FOCUS";

export interface UIDraft {
  data?: WorkspaceDraftDataSchemaItem[];
  id?: string;
  episodeId?: string;
  ctmId?: string;
  status?: string;
  unacknowledgedComponents?: ComponentOutput[];
}

export enum WorkspaceSubnavEnum {
  MEMBER = "ctm_message",
  TEAM = "team_message",
  TODO = "to_do",
  TASK = "task",
  ROLE = "role",
  SPECIALIST = "specialist",
  FACILITY = "facility",
  CENTER = "center_visit_referral",
  INDIVIDUAL = "individual",
  VISIT = "visit",
  REASONS = "reason",
  DIAGNOSIS = "diagnosis",
  CONVERSATION = "conversation",
  TEST = "test",
  ORDERS = "orders",
  QUESTION_SETS = "question_set",
  CARE_PLAN = "care_plan",
  STATUS_UPDATE = "status_update",
  SUMMARY = "summary",
  ARCHIVE = "archive",
  REMOTE = "remote",
  ROLES = "roles",
  ONLINE_VISIT_REFERRAL = "virtual_visit_referral",
  CALCULATOR = "calculator",
  DEFAULT = "default",
  CTM_MESSAGE = "ctm_message",
}

export enum WorkspaceTabEnum {
  COMMUNICATE = "communicate",
  AUTOMATE = "automate",
  ORDER = "order",
  DOCUMENT = "document",
  REFER = "refer",
  EDUCATE = "educate",
  FINALIZE = "finalize",
  SETTINGS = "settings",
  ARCHIVE = "archive",
  MESSAGE = "ctm_message",
  ACKNOWLEDGE = "acknowledge",
  QUESTION_SET = "question_set",
  CALCULATOR = "calculator",
}

export const WorkspaceCategoryBySubnav = {
  [WorkspaceSubnavEnum.MEMBER]: WorkspaceTabEnum.COMMUNICATE,
  [WorkspaceSubnavEnum.TEAM]: WorkspaceTabEnum.COMMUNICATE,
  [WorkspaceSubnavEnum.TODO]: WorkspaceTabEnum.COMMUNICATE,
  [WorkspaceSubnavEnum.QUESTION_SETS]: WorkspaceTabEnum.AUTOMATE,
  [WorkspaceSubnavEnum.CARE_PLAN]: WorkspaceTabEnum.AUTOMATE,
  [WorkspaceSubnavEnum.STATUS_UPDATE]: WorkspaceTabEnum.AUTOMATE,
  [WorkspaceSubnavEnum.CALCULATOR]: WorkspaceTabEnum.AUTOMATE,
  [WorkspaceSubnavEnum.REASONS]: WorkspaceTabEnum.DOCUMENT,
  [WorkspaceSubnavEnum.DIAGNOSIS]: WorkspaceTabEnum.DOCUMENT,
  [WorkspaceSubnavEnum.CONVERSATION]: WorkspaceTabEnum.DOCUMENT,
  [WorkspaceSubnavEnum.TEST]: WorkspaceTabEnum.DOCUMENT,
  [WorkspaceSubnavEnum.ORDERS]: WorkspaceTabEnum.DOCUMENT,
  [WorkspaceSubnavEnum.ROLE]: WorkspaceTabEnum.REFER,
  [WorkspaceSubnavEnum.SPECIALIST]: WorkspaceTabEnum.REFER,
  [WorkspaceSubnavEnum.FACILITY]: WorkspaceTabEnum.REFER,
  [WorkspaceSubnavEnum.CENTER]: WorkspaceTabEnum.REFER,
  [WorkspaceSubnavEnum.REMOTE]: WorkspaceTabEnum.REFER,
  [WorkspaceSubnavEnum.ONLINE_VISIT_REFERRAL]: WorkspaceTabEnum.REFER,
  [WorkspaceSubnavEnum.SUMMARY]: WorkspaceTabEnum.FINALIZE,
  [WorkspaceSubnavEnum.ARCHIVE]: WorkspaceTabEnum.FINALIZE,
  [WorkspaceSubnavEnum.ROLES]: WorkspaceTabEnum.SETTINGS,
};

export const getCategorizedDraft = (draft: UIDraft) => {
  const updatedDraft = cloneDeep(draft);

  // if draft is an array, convert it to a categorized object
  if (draft && draft?.data?.length) {
    (updatedDraft.data as any) = draft.data.reduce(
      (
        acc: Partial<WorkspaceDraftDataSchemaCategorized>,
        component: WorkspaceDraftDataSchemaItem
      ) => {
        const componentType = component?.type || null;

        if (componentType) {
          const category =
            componentType && WorkspaceCategoryBySubnav[componentType];
          const getDerivedStatus = () => {
            let status = null;

            // if communicate and no message, its unstarted
            if (category === WorkspaceTabEnum.COMMUNICATE && !component?.body) {
              status = "unstarted";
            }
            // prefer draft status. if any components are in draft, the category is in draft
            else if (
              component.status === "draft" ||
              (acc[category] && acc[category].status === "draft")
            ) {
              status = "draft";
            } else if (acc[category] && !!acc[category].status) {
              // if there are no components in draft, check for compiled components
              status = acc[category].status;
            } else {
              status = component.status || null;
            }

            return status;
          };
          const existingComponents = acc[category] ? acc[category].data : [];
          acc[category] = {
            data: [...existingComponents, component],
            status: getDerivedStatus(),
          };
        }

        return acc;
      },
      {}
    );
  }

  return updatedDraft as unknown as WorkspaceDraftOutputCoreSchemaCategorized;
};

export const getUncategorizedDraft = (
  draft: WorkspaceDraftOutputCoreSchemaCategorized
) => {
  const updatedDraft = cloneDeep(draft);

  if (draft && draft?.data) {
    const { data } = updatedDraft;
    let newData: WorkspaceDraftDataSchemaItem[] = [];

    Object.keys(data).forEach((key) => {
      newData = [...newData, ...data[key].data];
    });

    (updatedDraft as any).data = newData;
  }

  return updatedDraft as WorkspaceDraftOutputCoreSchema;
};

export type WorkspaceDraftDataSchemaDataCategorized = {
  [key in WorkspaceTabEnum]: {
    data: WorkspaceDraftDataSchemaItem[];
    status: "draft" | "compiled";
  };
};

export interface WorkspaceDraftDataSchemaCategorized {
  data: WorkspaceDraftDataSchemaCategorized;
}

// We categorize our workspace draft `data` on the front end for consistency and to
// enable us to reduce the amount of times we need to map/reduce/iterate that data.
// We use this interface in place of `WorkspaceDraftOutputCoreSchema` to do that.
export interface WorkspaceDraftOutputCoreSchemaCategorized
  extends WorkspaceDraftOutputCoreSchema {
  data: WorkspaceDraftDataSchemaCategorized;
}

export enum WorkspacePageEnum {
  DEFAULT = "default",
  DETAIL = "detail",
  DRAFT = "draft",
  REVIEW = "review",
  ADDED_TO_BUNDLE = "added_to_bundle",
}

// add tabs as they are completed, in UI appearance order
export const WorkspaceSubnavs = {
  communicate: [WorkspaceSubnavEnum.MEMBER],
  automate: [WorkspaceSubnavEnum.QUESTION_SETS],
  order: [] as any,
  document: [
    WorkspaceSubnavEnum.REASONS,
    WorkspaceSubnavEnum.DIAGNOSIS,
    WorkspaceSubnavEnum.VISIT,
    WorkspaceSubnavEnum.TEST,
    WorkspaceSubnavEnum.ORDERS,
  ],
  refer: [] as any,
  educate: [] as any,
  finalize: [WorkspaceSubnavEnum.SUMMARY, WorkspaceSubnavEnum.ARCHIVE],
  settings: [WorkspaceSubnavEnum.ROLES],
  archive: [WorkspaceSubnavEnum.DEFAULT],
  acknowledge: [WorkspaceSubnavEnum.DEFAULT],
};

export type WorkspaceTab = WorkspaceTabEnum | null;
export type WorkspaceSubnav = WorkspaceSubnavEnum | null;
export type WorkspacePage = WorkspacePageEnum | null;
export interface WorkspacePath {
  tab?: WorkspaceTab;
  subnav?: WorkspaceSubnav;
  page?: WorkspacePage;
}

export const reducer = (
  state: State = getInitialWorkspaceState(),
  action: any
): State => {
  if (action && action.type) {
    const {
      editingDraft,
      path,
      submitted,
      type,
      visible,
      docked,
      scrollToBottom,
      replyBoxFocus,
    } = action;

    switch (type) {
      case SET_WORKSPACE_PATH:
        const { subnav, tab, page } = path;
        const hasTab = tab === null || !!tab;
        const hasSubnav = subnav === null || !!subnav;
        const isNewTab = hasTab && tab !== state.path.tab;
        const isNewPage = (page === null || !!page) && page !== state.path.page;
        const isNewSubnav = hasSubnav && subnav !== state.path.subnav;
        const update = JSON.parse(JSON.stringify(state));

        // only update if we are on a new tab or subnav-- prevent excess re-renders
        if (isNewTab) {
          update.path.tab = tab;
        }

        if (isNewSubnav) {
          update.path.subnav = subnav;
        }

        // if any args exist, make sure the workspace is shown
        if (isNewTab || isNewSubnav) {
          update.visible = true;
        }

        // if no args, hide the workspace
        if (tab === null && subnav === null) {
          update.visible = false;
        }

        // navigate to the default tab if we have specified a tab but no subnav
        if (tab && !subnav) {
          update.path.subnav = WorkspaceSubnavs[tab][0];
        }

        if (isNewPage) {
          update.path.page = page;
        }

        if (!page) {
          update.path.page = WorkspacePageEnum.DEFAULT;
        }

        return update;
      case SET_WORKSPACE_VISIBLE:
        return Object.assign({}, state, {
          visible,
        });
      case SET_WORKSPACE_SUBMITTED:
        return Object.assign({}, state, {
          submitted,
          path: {
            tab: submitted ? null : state.path.tab,
            subnav: submitted ? null : state.path.subnav,
          },
          visible: submitted ? false : state.visible,
        });
      case SET_EDITING_DRAFT:
        return Object.assign({}, state, {
          editingDraft,
        });
      case SET_WORKSPACE_DOCKED:
        return Object.assign({}, state, {
          docked,
        });
      case SET_REPLY_BOX_FOCUS:
        return Object.assign({}, state, {
          replyBoxFocus,
        });
      case SET_SCROLL_TO_BOTTOM:
        return Object.assign({}, state, {
          scrollToBottom,
        });
      default:
        return state;
    }
  }

  return state;
};

export const setWorkspacePath = (path: Partial<WorkspacePath>) => {
  return { type: SET_WORKSPACE_PATH, path };
};

export const setWorkspaceVisible = (visible: boolean) => {
  return { type: SET_WORKSPACE_VISIBLE, visible };
};

export const setWorkspaceSubmitted = (submitted: boolean) => {
  return { type: SET_WORKSPACE_SUBMITTED, submitted };
};

export const setEditingDraft = (editingDraft: UIDraft) => {
  return { type: SET_EDITING_DRAFT, editingDraft };
};

export const setWorkspaceDocked = (docked: boolean) => {
  return { type: SET_WORKSPACE_DOCKED, docked };
};

export const setReplyBoxFocus = (replyBoxFocus: boolean) => {
  return { type: SET_REPLY_BOX_FOCUS, replyBoxFocus };
};

export const setScrollToBottom = (scrollToBottom: boolean) => {
  return { type: SET_SCROLL_TO_BOTTOM, scrollToBottom };
};

export interface State {
  path: WorkspacePath;
  visible: boolean;
  submitted: boolean;
  editingDraft: WorkspaceDraftDataSchema;
  docked: boolean;
  scrollToBottom: boolean;
  replyBoxFocus: boolean;
}

export function getInitialWorkspaceState(): State {
  return {
    editingDraft: {},
    path: {
      tab: null,
      subnav: null,
      page: null,
    },
    visible: false,
    submitted: false,
    docked: true,
    scrollToBottom: false,
    replyBoxFocus: false,
  };
}
