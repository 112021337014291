// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AnnotatedInputServiceMenuDocumentListProps
 */
export interface AnnotatedInputServiceMenuDocumentListProps  {
    /**
     * 
     * @type {boolean}
     * @memberof AnnotatedInputServiceMenuDocumentListProps
     */
    enabled?: boolean;
}

export function AnnotatedInputServiceMenuDocumentListPropsFromJSON(json: any): AnnotatedInputServiceMenuDocumentListProps {
    return {
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
    };
}

export function AnnotatedInputServiceMenuDocumentListPropsToJSON(value?: AnnotatedInputServiceMenuDocumentListProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'enabled': value.enabled,
    };
}


