// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Calculator,
    CalculatorFromJSON,
    CalculatorToJSON,
} from './Calculator';


/**
 * 
 * @export
 * @interface ListCalculatorsResponse
 */
export interface ListCalculatorsResponse  {
    /**
     * 
     * @type {number}
     * @memberof ListCalculatorsResponse
     */
    count: number;
    /**
     * 
     * @type {Array<Calculator>}
     * @memberof ListCalculatorsResponse
     */
    results: Array<Calculator>;
}

export function ListCalculatorsResponseFromJSON(json: any): ListCalculatorsResponse {
    return {
        'count': json['count'],
        'results': (json['results'] as Array<any>).map(CalculatorFromJSON),
    };
}

export function ListCalculatorsResponseToJSON(value?: ListCalculatorsResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'results': (value.results as Array<any>).map(CalculatorToJSON),
    };
}


