// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TimestampedItemSchema
 */
export interface TimestampedItemSchema  {
    /**
     * 
     * @type {Date}
     * @memberof TimestampedItemSchema
     */
    readonly createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof TimestampedItemSchema
     */
    readonly updatedAt: Date;
}

export function TimestampedItemSchemaFromJSON(json: any): TimestampedItemSchema {
    return {
        'createdAt': new Date(json['created_at']),
        'updatedAt': new Date(json['updated_at']),
    };
}

export function TimestampedItemSchemaToJSON(value?: TimestampedItemSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
    };
}


