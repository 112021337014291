import { createSelector } from "reselect";
import { State } from "shared/types/state";
import { ActionableItemDetails } from "shared/api/src/models/ActionableItemDetails";
import { ActionableItemSummary } from "shared/api/src/models/ActionableItemSummary";
import { useSelector } from "react-redux";

export const changeUTCtoLocalTime = (
  toDosToChangeTime: ActionableItemSummary[] | ActionableItemDetails[]
) => {
  toDosToChangeTime?.forEach(
    (toDo: ActionableItemSummary | ActionableItemDetails) => {
      const dateOnToDoDisplayLabel =
        toDo?.todoDisplayLabel?.match(/<([^<>]+)>/g);
      const dateOnNotificationDisplayLabel =
        toDo?.notificationCenterDisplayLabel?.match(/<([^<>]+)>/g);
      // parsing of date on to do display label
      if (dateOnToDoDisplayLabel) {
        const date = new Date(
          dateOnToDoDisplayLabel[0].slice(1, -1)
        ).toLocaleString(undefined, {
          weekday: undefined,
          year: "numeric",
          month: "short",
          day: "numeric",
        });

        toDo.todoDisplayLabel = toDo?.todoDisplayLabel?.replace(
          dateOnToDoDisplayLabel[0],
          `on ${date}`
        );

        // parsing of date on notification display label
      } else if (dateOnNotificationDisplayLabel) {
        const date = new Date(
          dateOnNotificationDisplayLabel[0].slice(1, -1)
        ).toLocaleString(undefined, {
          weekday: undefined,
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        toDo.notificationCenterDisplayLabel =
          toDo?.notificationCenterDisplayLabel?.replace(
            dateOnNotificationDisplayLabel[0],
            `on ${date}`
          );
      }
    }
  );
};

export const getActionableItems = (state: State) => {
  // TODO: we may want to move this logic outside of the selectors
  const toDosToChangeTime = state.entities.actionableItems?.filter(
    (toDo: ActionableItemSummary | ActionableItemDetails) =>
      toDo.notificationCenterDisplayLabel?.includes("<") ||
      toDo.todoDisplayLabel?.includes("<")
  );

  toDosToChangeTime && changeUTCtoLocalTime(toDosToChangeTime);

  return state.entities && state.entities.actionableItems
    ? state.entities.actionableItems
    : [];
};

export const selectLatestActionableItems = () =>
  useSelector(getActionableItems);

export const getActionableItemsByStatus = (
  actionableItems: ActionableItemDetails[],
  status: string
) => {
  return actionableItems.filter(
    (ai: ActionableItemDetails) => ai.state === status
  );
};

export const getActiveActionableItems = createSelector(
  getActionableItems,
  (actionableItems: ActionableItemDetails[]) =>
    getActionableItemsByStatus(actionableItems, "active")
);

export const getResolvedActionableItems = (state: State) =>
  getActionableItemsByStatus(getActionableItems(state), "resolved");

export const getAcknowledgedPendingActionableItems = (state: State) =>
  getActionableItemsByStatus(getActionableItems(state), "acknowledged_pending");

export const getActionableItemById = (state: State, id: string) =>
  getActionableItems(state).find((ai: ActionableItemDetails) => ai.id === id);

export const getActionableItemsByEpisode = (state: State, episodeId: string) =>
  getActionableItems(state).filter(
    (ai: ActionableItemDetails) => ai.episodeId === episodeId
  );
