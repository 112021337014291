// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CalculatorJsonBody,
    CalculatorJsonBodyFromJSON,
    CalculatorJsonBodyToJSON,
} from './CalculatorJsonBody';


/**
 * 
 * @export
 * @interface WorkspaceDraftDataSchemaCalculatorBase
 */
export interface WorkspaceDraftDataSchemaCalculatorBase  {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDraftDataSchemaCalculatorBase
     */
    calculatorId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceDraftDataSchemaCalculatorBase
     */
    timeSensitive?: boolean;
    /**
     * 
     * @type {CalculatorJsonBody}
     * @memberof WorkspaceDraftDataSchemaCalculatorBase
     */
    jsonBody: CalculatorJsonBody;
}

export function WorkspaceDraftDataSchemaCalculatorBaseFromJSON(json: any): WorkspaceDraftDataSchemaCalculatorBase {
    return {
        'calculatorId': !exists(json, 'calculator_id') ? undefined : json['calculator_id'],
        'timeSensitive': !exists(json, 'time_sensitive') ? undefined : json['time_sensitive'],
        'jsonBody': CalculatorJsonBodyFromJSON(json['json_body']),
    };
}

export function WorkspaceDraftDataSchemaCalculatorBaseToJSON(value?: WorkspaceDraftDataSchemaCalculatorBase): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'calculator_id': value.calculatorId,
        'time_sensitive': value.timeSensitive,
        'json_body': CalculatorJsonBodyToJSON(value.jsonBody),
    };
}


