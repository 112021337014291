import Typography from "components/Typography";
import React from "react";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import {
  IAppointmentRow,
  useAppointmentRowGridStyles,
} from "../AppointmentRow";

const useStyles = makeStyles(({ palette, spacing }) => ({
  appointmentColumnBorder: {
    borderBottom: `1px solid ${palette?.text?.strokes}`,
  },
  expandedMemberDetails: {
    paddingLeft: spacing(1),
  },
  expandedMemberDetailsPaddingBottom: {
    paddingBottom: spacing(2),
  },
  employerDetailsColumn: {
    display: "flex",
    flexDirection: "column",
  },
}));

const ExpandedMemberDetails: React.FC<IAppointmentRow> = ({
  appointment,
  isLastRow,
}) => {
  const gridClasses = useAppointmentRowGridStyles();
  const classes = useStyles();

  return (
    <>
      <div
        className={clsx(
          gridClasses.appointmentGrid,
          classes.expandedMemberDetails
        )}
      >
        <Typography
          appearance="smallBody"
          color="textSecondary"
          fontWeightBold
          style={{ gridColumn: "2 / 3" }}
        >
          Employer & Employee ID
        </Typography>
        <Typography
          appearance="smallBody"
          color="textSecondary"
          fontWeightBold
          style={{ gridColumn: "3 / 4" }}
        >
          Insurance ID
        </Typography>
      </div>
      <div
        className={clsx(
          gridClasses.appointmentGrid,
          classes.expandedMemberDetails,
          classes.expandedMemberDetailsPaddingBottom
        )}
      >
        <div
          style={{ gridColumn: "2 / 3" }}
          className={classes.employerDetailsColumn}
        >
          <Typography appearance="smallBody" color="textSecondary">
            {appointment?.employer}
          </Typography>
          <Typography appearance="smallBody" color="textSecondary">
            {appointment?.employeeId}
          </Typography>
        </div>
        <Typography
          style={{ gridColumn: "3 / 4" }}
          appearance="smallBody"
          color="textSecondary"
          fontWeightBold
        >
          {appointment?.insuranceId}
        </Typography>
      </div>
      <div
        className={clsx(
          gridClasses.appointmentGrid,
          classes.expandedMemberDetails
        )}
      >
        <Typography
          style={{ gridColumn: "2 / 3" }}
          appearance="smallBody"
          color="textSecondary"
          fontWeightBold
        >
          XOID
        </Typography>
        <Typography
          style={{ gridColumn: "3 / 4" }}
          appearance="smallBody"
          color="textSecondary"
          fontWeightBold
        >
          Chief Complaints
        </Typography>
      </div>
      <div
        className={clsx(
          gridClasses.appointmentGrid,
          classes.expandedMemberDetails,
          classes.expandedMemberDetailsPaddingBottom,
          !isLastRow && classes.appointmentColumnBorder
        )}
      >
        <Typography
          style={{ gridColumn: "2 / 3" }}
          appearance="smallBody"
          color="textSecondary"
        >
          {appointment?.xoid}
        </Typography>
        <Typography
          style={{ gridColumn: "3 / 4" }}
          appearance="smallBody"
          color="textSecondary"
        >
          {appointment?.notes}
        </Typography>
      </div>
    </>
  );
};

export default ExpandedMemberDetails;
