import React, { FunctionComponent, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Header from "templates/Header";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Container from "components/Container";
import EOCItemContainer from "components/EOCItemContainer";
import EOCLineItemTable from "components/EOCLineItemTable";
import Grid from "components/Grid";
import Skeleton from "components/Skeleton";
import HeadsUpNotifications from "./HeadsUpNotifications";
import clsx from "clsx";
import { animateUpUseStyles } from "utils/animateUpStyle";
import EocFilters from "components/EocFilters";
import useGetUserSettings from "shared/features/queueFiltering/useGetUserSettings";
import { getUser } from "shared/features/user";
import useGetFilteredQueueEpisodes, {
  usePrefetchQueueEpisodes,
} from "shared/features/queue/useGetFilteredQueueEpisodes";
import useGetYourFilteredQueueEpisodes from "shared/features/queue/useGetYourFilteredEpisodes";
import { CtmFilterSetting } from "shared/state/ui/settings";
import useConversationSortOrder from "hooks/useConversationSortOrder";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import values from "lodash/values";
import { GetMyQueueOrderEnum } from "shared/fetch/src/apis/EpisodeListsApi";

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    header: {
      position: "relative",
      zIndex: 0,
    },
    container: {
      maxWidth: "1400px",
    },
    gridItemTwo: {
      padding: spacing(2),
      width: "100%",
      marginTop: spacing(-2.4),
    },
    gridItemHeadsUp: {
      padding: spacing(2),
      width: "100%",
      marginTop: spacing(3.5),
    },
    gridContainer: {
      paddingTop: spacing(4),
      justifyContent: "center",
      alignItems: "space-around",
    },
    gridItem: {
      alignSelf: "center",
      padding: "0px 5px",
      marginBottom: "50px",
    },
    tabs: {
      paddingBottom: spacing(3),
    },
    ["@media screen and (max-width: 1000px)"]: {
      gridItem: {
        minWidth: "300px",
        alignSelf: "unset",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
    },
  })
);

const indexedFilterNames = {
  all: 0,
  role: 1,
  ctm: 2,
  you: 3,
};

const allFilters = {
  all: {
    name: [""],
    state: "all",
    values: [""],
  },
  role: {
    name: ["yourRole"],
    state: "role",
    values: ["true"],
  },
  ctm: {
    name: ["ownedByCtmIdsAndPodIds"],
    state: "ctm",
    values: [""],
  },
  you: {
    name: ["yours"],
    state: "you",
    values: ["true"],
  },
};

export const CTMQueueContainer: FunctionComponent = (): JSX.Element => {
  const [isInitialLoad, setIsInitialLoad] = React.useState(true);
  const parentDiv = useRef();
  const executed = useRef(false);
  const { animateUp } = animateUpUseStyles();
  const classes = useStyles();
  const { sortOrder, sortOrderLabel, onSortOrderChange } =
    useConversationSortOrder(GetMyQueueOrderEnum.ASC);

  const prefetchEpisodes = usePrefetchQueueEpisodes();

  const user = useSelector(getUser);
  const { userSettings, isLoading } = useGetUserSettings({
    id: user?.id?.toString() || "",
  });
  const userRole = user?.guilds?.[0];

  const [selectedProviders, setSelectedProviders] = useState([]);

  const [activeFilter, setActiveFilter] = useState(
    userSettings?.activeFilters?.queue?.toLowerCase() || "all"
  );

  React.useEffect(() => {
    if (
      !isLoading &&
      !userSettings?.activeFilters?.queue?.toLowerCase() &&
      !executed.current
    ) {
      executed.current = true;
      setCurrentFilter(allFilters.all);
    } else if (userSettings?.activeFilters?.queue) {
      const filter =
        indexedFilters[
          indexedFilterNames[userSettings?.activeFilters?.queue?.toLowerCase()]
        ];
      if (filter?.state === "ctm") {
        // @ts-ignore: Object is possibly 'null'.
        setSelectedProviders(userSettings?.filters?.queue?.ctm);
      }
      setCurrentFilter(filter);
      setActiveFilter(filter?.state);
    }
  }, [isLoading, userSettings?.activeFilters?.queue?.toLowerCase()]);

  const [currentFilter, setCurrentFilter] = React.useState<any>({});

  const {
    filteredQueueEpisodes: allEpisodes,
    isFetching: isPendingAllEpisodes,
  } = useGetFilteredQueueEpisodes({
    filterNames: currentFilter.name,
    values: currentFilter.values,
    filter: currentFilter.state,
    order: sortOrder,
  });

  React.useEffect(() => {
    if (userSettings) {
      setIsInitialLoad(false);
    }
  }, [userSettings]);

  const indexedFilters = {
    0: {
      name: [""],
      state: "all",
      values: [""],
    },
    1: {
      name: ["yourRole"],
      state: "role",
      values: ["true"],
    },
    2: {
      name: ["ownedByCtmIdsAndPodIds"],
      state: "ctm",
      values: [selectedProviders],
    },
    3: {
      name: ["yours"],
      state: "you",
      values: ["true"],
    },
  };

  const { yourFilteredQueueEpisodes } = useGetYourFilteredQueueEpisodes({
    filterNames: indexedFilters[3].name,
    // @ts-ignore: Object is possibly 'null'.
    values: indexedFilters[3].values,
    // @ts-ignore: Object is possibly 'null'.
    filter: activeFilter !== "you" ? indexedFilters[3].state : null,
    order: sortOrder,
  });

  React.useEffect(() => {
    if (!executed.current && activeFilter) {
      prefetchEpisodes(
        map(values(indexedFilters), ({ name, state, values: innerValues }) => ({
          filterNames: name,
          filter: state,
          values: innerValues,
          order: sortOrder,
        }))
      );

      executed.current = true;
    }
  }, [activeFilter]);

  React.useEffect(() => {
    const filter = indexedFilters[indexedFilterNames[activeFilter]];
    if (filter) {
      setCurrentFilter(filter);
    }
  }, [selectedProviders]);

  const indexedTitles = {
    0: "All Queues",
    1: `${userRole} Queue with AI`,
    2: "CTM Queues",
    3: "Your Queue",
  };

  const handleFiltersChange = (
    filter: any,
    index: any,
    providers?: CtmFilterSetting[]
  ) => {
    setActiveFilter(filter);
    const indexedFilter = cloneDeep(indexedFilters[index]);

    if (filter === "ctm") {
      // @ts-ignore: Object is possibly 'null'.
      setSelectedProviders(providers);
      indexedFilter.values = [providers];
    }
    setCurrentFilter(indexedFilter);
  };

  if (isInitialLoad || isLoading) {
    return <Skeleton appearance="page" />;
  }

  return (
    // @ts-ignore: Object is possibly 'null'.
    <div ref={parentDiv}>
      <Header className={clsx(classes.header, animateUp)} color="navy">
        <Container className={classes.container}>
          <Grid container className={classes.gridContainer}>
            <Grid item md={8} className={classes.gridItemTwo}>
              <div className={classes.tabs}>
                <EocFilters
                  onChange={handleFiltersChange}
                  view="queue"
                  activeFilterIndex={indexedFilterNames[activeFilter]}
                  episodes={allEpisodes}
                  yourEpisodes={yourFilteredQueueEpisodes}
                />
              </div>
              <EOCItemContainer
                isLoading={isPendingAllEpisodes}
                heading={
                  indexedTitles[indexedFilterNames[activeFilter]] ||
                  "Member Action Items"
                }
                message={
                  indexedFilterNames[activeFilter] === 2
                    ? "There are no results for the selected CTMs"
                    : "No Pending Action Items"
                }
                itemCount={allEpisodes?.length}
                sortOrder={sortOrderLabel}
                selectedTab={indexedFilterNames[activeFilter]}
                onSortOrderChange={onSortOrderChange}
              >
                <EOCLineItemTable episodes={allEpisodes} returnTo="Queue" />
              </EOCItemContainer>
            </Grid>
            <Grid item md={3} className={classes.gridItemHeadsUp}>
              <HeadsUpNotifications />
            </Grid>
          </Grid>
        </Container>
      </Header>
    </div>
  );
};

export default CTMQueueContainer;
