// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiagnosesListItem
 */
export interface DiagnosesListItem  {
    /**
     * 
     * @type {string}
     * @memberof DiagnosesListItem
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosesListItem
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosesListItem
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof DiagnosesListItem
     */
    xoCommon: boolean;
    /**
     * 
     * @type {string}
     * @memberof DiagnosesListItem
     */
    friendlyName?: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosesListItem
     */
    overview: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosesListItem
     */
    category: string;
    /**
     * 
     * @type {number}
     * @memberof DiagnosesListItem
     */
    version: number;
}

export function DiagnosesListItemFromJSON(json: any): DiagnosesListItem {
    return {
        'type': json['type'],
        'code': json['code'],
        'name': json['name'],
        'xoCommon': json['xo_common'],
        'friendlyName': !exists(json, 'friendly_name') ? undefined : json['friendly_name'],
        'overview': json['overview'],
        'category': json['category'],
        'version': json['version'],
    };
}

export function DiagnosesListItemToJSON(value?: DiagnosesListItem): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'type': value.type,
        'code': value.code,
        'name': value.name,
        'xo_common': value.xoCommon,
        'friendly_name': value.friendlyName,
        'overview': value.overview,
        'category': value.category,
        'version': value.version,
    };
}


