import React, { FunctionComponent } from "react";
import { EpisodeSummary } from "shared/fetch/src/models/EpisodeSummary";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Box from "components/Box";
import EOCLineItemContainer from "./EOCLineItemContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eocLineItemTable: {
      borderBottom: `1px solid ${theme.palette?.strokes?.tertiary}`,
      marginBottom: theme.spacing(5),
    },
  })
);

interface IProps {
  episodes: EpisodeSummary[];
  returnTo?: string;
  currentPath?: string;
}

const EOCLineItemTable: FunctionComponent<IProps> = (props) => {
  const { episodes } = props;
  const classes = useStyles(props);
  const timeSensitiveEpisodes = episodes.filter(
    (episode) => episode.timeSensitive
  );
  const nonTimeSensitiveEpisodes = episodes.filter(
    (episode) => !episode.timeSensitive
  );
  const allEpisodes = [...timeSensitiveEpisodes, ...nonTimeSensitiveEpisodes];

  const eocs = allEpisodes.map((eoc, index) => {
    return (
      <EOCLineItemContainer
        index={index}
        key={eoc.id}
        eoc={eoc}
        returnTo={props.returnTo}
        currentPath={props?.currentPath}
      />
    );
  });

  return (
    <Box width="100%" className={classes.eocLineItemTable}>
      {eocs}
    </Box>
  );
};

export default EOCLineItemTable;
