const TOGGLE_UI_FILTER = "TOGGLE_UI_FILTER";

export type View = "queue" | "episodes" | "proactive";
export type Filter =
  | null
  | "all"
  | "role"
  | "ctm"
  | "you"
  | "team"
  | "archived";

type FilterBy = "ctm";

export interface CtmFilterSetting {
  id: string;
  podId?: string;
}

export interface UiFilter {
  ctm: CtmFilterSetting[];
}

export interface SettingsState {
  filters: {
    [V in View]: {
      ctm: CtmFilterSetting[];
    };
  };
  activeFilters: {
    [V in View]: Filter;
  };
  isCtmReplyBoxDockedRight: boolean;
}

interface SettingsReducerAction {
  view: View;
  filter: FilterBy;
  value: CtmFilterSetting;
  type: string;
}

export const settingsReducer = (
  state: SettingsState = getInitialSettingsState(),
  action: SettingsReducerAction
): SettingsState => {
  if (action && action.type) {
    const { type, view, filter, value } = action;

    switch (type) {
      case TOGGLE_UI_FILTER:
        const currentFilter = state?.filters?.[view]?.[filter];
        const exists = JSON.stringify(currentFilter).includes(
          JSON.stringify(filter)
        );

        if (!exists) {
          return Object.assign({}, state, {
            filters: {
              ...state.filters,
              [view]: {
                ...state.filters[view],
                [filter]: currentFilter.length
                  ? [currentFilter, value]
                  : [value],
              },
            },
          });
        }

        return Object.assign({}, state, {
          filters: {
            ...state.filters,
            [view]: {
              ...state.filters[view],
              [filter]: currentFilter.filter((v) => v !== value),
            },
          },
        });
      default:
        return state;
    }
  }

  return state;
};

export const toggleUiFilter = (view: View, filter: FilterBy, value: string) => {
  return { type: TOGGLE_UI_FILTER, view, filter, value };
};

export const getInitialSettingsState = (): SettingsState => {
  return {
    filters: {
      queue: {
        ctm: [],
      },
      episodes: {
        ctm: [],
      },
      proactive: {
        ctm: [],
      },
    },
    activeFilters: {
      queue: null,
      episodes: null,
      proactive: null,
    },
    isCtmReplyBoxDockedRight: true,
  };
};
