// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ListSubscriptionOptionsOutput
 */
export interface ListSubscriptionOptionsOutput  {
    /**
     * 
     * @type {string}
     * @memberof ListSubscriptionOptionsOutput
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ListSubscriptionOptionsOutput
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ListSubscriptionOptionsOutput
     */
    price?: string;
    /**
     * 
     * @type {string}
     * @memberof ListSubscriptionOptionsOutput
     */
    stripeProductId?: string;
}

export function ListSubscriptionOptionsOutputFromJSON(json: any): ListSubscriptionOptionsOutput {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'price': !exists(json, 'price') ? undefined : json['price'],
        'stripeProductId': !exists(json, 'stripe_product_id') ? undefined : json['stripe_product_id'],
    };
}

export function ListSubscriptionOptionsOutputToJSON(value?: ListSubscriptionOptionsOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'description': value.description,
        'price': value.price,
        'stripe_product_id': value.stripeProductId,
    };
}


