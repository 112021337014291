import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { State } from "@types";
import NativeApp from "models/NativeApp";
import getAuthInfoForBiometricLogin from "shared/features/auth/getAuthInfoForBiometricLogin";
import useGetChallengeStringBiometrics from "shared/features/auth/useGetChallengeStringForBiometrics";
import LinearProgress from "components/Progress/LinearProgress";
import useQueryParams from "hooks/useQueryParams";

const InitiateBiometricSignin: React.FunctionComponent = () => {
  const isLoggedIn = useSelector((state: State) => !!state.auth.refreshToken);
  const { challenge, isPending } = useGetChallengeStringBiometrics();
  const { queryParams } = useQueryParams();
  const biometricSignin = () => {
    getAuthInfoForBiometricLogin(queryParams?.deepLink?.toString()).then(
      (authInfo) => {
        NativeApp.postMessage(
          JSON.stringify({
            type: "NEW_BIOMETRIC_SIGNIN",
            payload: {
              ...authInfo,
              challenge,
            },
          })
        );
        return;
      }
    );
    return;
  };

  useEffect(() => {
    if (window.ReactNativeWebView?.isApp && !isLoggedIn && !isPending) {
      biometricSignin();
    }
    return;
  }, [isPending, challenge]);

  return <LinearProgress />;
};
export default InitiateBiometricSignin;
