// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
    MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';

/**
 * @type UserInfo
 * @export
 */
export type UserInfo = CTMInfo | MemberInfo;


export function UserInfoFromJSON(json: any): UserInfo {
  switch (json.type) {
  case 'member': return MemberInfoFromJSON(json);
  case 'ctm': return CTMInfoFromJSON(json);
  default: throw new Error("Unexpected type value.");
  }
}

export function UserInfoToJSON(value?: UserInfo): any {
    return value;
}

