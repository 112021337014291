// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     NetworkProviderAllOf,
    NetworkProviderAllOfFromJSON,
    NetworkProviderAllOfToJSON,
} from './NetworkProviderAllOf';
import {
     Provider,
    ProviderFromJSON,
    ProviderToJSON,
} from './Provider';
/**
 * @type NetworkProvider
 * @export
 */
export interface NetworkProvider extends NetworkProviderAllOf, Provider {
}

export function NetworkProviderFromJSON(json: any): NetworkProvider {
    return {
        ...NetworkProviderAllOfFromJSON(json),
        ...ProviderFromJSON(json),
    };
}

export function NetworkProviderToJSON(value?: NetworkProvider): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...NetworkProviderAllOfToJSON(value),
        ...ProviderToJSON(value),
    };
}

