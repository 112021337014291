import { useMutation } from "redux-query-react";
import { QueryConfig, Entities } from "redux-query";
import { updateEpisodeOwner } from "shared/api/src/apis/EpisodesOfCareApi";
import { EpisodeOwnerRequestBody } from "shared/fetch/src/models/EpisodeOwnerRequestBody";
import { EpisodeSummary } from "shared/fetch/src/models/EpisodeSummary";

interface IRequest {
  episodeId: string;
  owner: EpisodeOwnerRequestBody;
}

export const useUpdateEpisodeOwner = () => {
  const queryConfig = ({ episodeId, owner }: IRequest): any =>
    updateEpisodeOwner(
      {
        episodeId,
        episodeOwnerRequestBody: owner,
      },
      {
        transform: (episode: any) => {
          return {
            episodeRoles: episode?.roles || [],
            memberActiveEpisodes: episode,
            ctmEpisodes: episode,
            preventiveEpisodes: episode,
            filteredEpisodes: episode,
          } as any;
        },
        update: {
          episodeRoles: (prev: any, next: any) => ({
            ...prev,
            ...next,
          }),
          memberActiveEpisodes: (prev: any, next: any) => {
            const index = prev?.activeEpisodes?.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            if (index > -1) {
              const updatedEpisodes = [...prev.activeEpisodes];
              updatedEpisodes[index] = next;

              return {
                ...prev,
                activeEpisodes: updatedEpisodes,
              };
            }

            return prev;
          },
          // Conversations page
          ctmEpisodes: (prev: any, next: any) => {
            const youIndex = prev?.you?.episodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            const teamIndex = prev?.team?.episodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            const archivedIndex = prev?.archived?.episodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            const roleIndex = prev?.role?.episodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            const yourEpisodesIndex = prev?.yourEpisodes?.episodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            const actionItemsIndex = prev?.actionItems?.episodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            const allIndex = prev?.all?.episodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            const ctmIndex = prev?.ctm?.episodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );

            const updates = { ...prev };

            if (youIndex > -1) {
              const youUpdates = [...prev.you.episodes];
              youUpdates[youIndex] = next;
              updates.you.episodes = youUpdates;
            }

            if (teamIndex > -1) {
              const teamUpdates = [...prev.team.episodes];
              teamUpdates[teamIndex] = next;
              updates.team.episodes = teamUpdates;
            }

            if (archivedIndex > -1) {
              const archivedUpdates = [...prev.archived.episodes];
              archivedUpdates[archivedIndex] = next;
              updates.archived.episodes = archivedUpdates;
            }

            if (roleIndex > -1) {
              const roleUpdates = [...prev.role.episodes];
              roleUpdates[roleIndex] = next;
              updates.role.episodes = roleUpdates;
            }

            if (yourEpisodesIndex > -1) {
              const yourEpisodesUpdates = [...prev.yourEpisodes.episodes];
              yourEpisodesUpdates[yourEpisodesIndex] = next;
              updates.yourEpisodes.episodes = yourEpisodesUpdates;
            }

            if (actionItemsIndex > -1) {
              const actionItemsUpdates = [...prev.actionItems.episodes];
              actionItemsUpdates[actionItemsIndex] = next;
              updates.actionItems.episodes = actionItemsUpdates;
            }

            if (allIndex > -1) {
              const allUpdates = [...prev.all.episodes];
              allUpdates[allIndex] = next;
              updates.all.episodes = allUpdates;
            }

            if (ctmIndex > -1) {
              const ctmUpdates = [...prev.ctm.episodes];
              ctmUpdates[ctmIndex] = next;
              updates.ctm.episodes = ctmUpdates;
            }

            if (
              youIndex > -1 ||
              teamIndex > -1 ||
              archivedIndex > -1 ||
              roleIndex > -1 ||
              yourEpisodesIndex > -1 ||
              actionItemsIndex > -1 ||
              allIndex > -1 ||
              ctmIndex > -1
            ) {
              return {
                ...updates,
              };
            }

            return prev;
          },
          // Proactive page
          preventiveEpisodes: (prev: any, next: any) => {
            const youIndex = prev?.you?.episodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            const teamIndex = prev?.team?.episodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            const roleIndex = prev?.role?.episodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            const yourEpisodesIndex = prev?.yourEpisodes?.episodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            const actionItemsIndex = prev?.actionItems?.episodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            const allIndex = prev?.all?.episodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            const ctmIndex = prev?.ctm?.episodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );

            const updates = { ...prev };

            if (youIndex > -1) {
              const youUpdates = [...prev.you.episodes];
              youUpdates[youIndex] = next;
              updates.you.episodes = youUpdates;
            }

            if (teamIndex > -1) {
              const teamUpdates = [...prev.team.episodes];
              teamUpdates[teamIndex] = next;
              updates.team.episodes = teamUpdates;
            }

            if (roleIndex > -1) {
              const roleUpdates = [...prev.role.episodes];
              roleUpdates[roleIndex] = next;
              updates.role.episodes = roleUpdates;
            }

            if (yourEpisodesIndex > -1) {
              const yourEpisodesUpdates = [...prev.yourEpisodes.episodes];
              yourEpisodesUpdates[yourEpisodesIndex] = next;
              updates.yourEpisodes.episodes = yourEpisodesUpdates;
            }

            if (actionItemsIndex > -1) {
              const actionItemsUpdates = [...prev.actionItems.episodes];
              actionItemsUpdates[actionItemsIndex] = next;
              updates.actionItems.episodes = actionItemsUpdates;
            }

            if (allIndex > -1) {
              const allUpdates = [...prev.all.episodes];
              allUpdates[allIndex] = next;
              updates.all.episodes = allUpdates;
            }

            if (ctmIndex > -1) {
              const ctmUpdates = [...prev.ctm.episodes];
              ctmUpdates[ctmIndex] = next;
              updates.ctm.episodes = ctmUpdates;
            }

            if (
              youIndex > -1 ||
              teamIndex > -1 ||
              roleIndex > -1 ||
              yourEpisodesIndex > -1 ||
              actionItemsIndex > -1 ||
              allIndex > -1 ||
              ctmIndex > -1
            ) {
              return {
                ...updates,
              };
            }

            return prev;
          },
          // Queue
          filteredEpisodes: (prev: any, next: any) => {
            const youIndex = prev?.you?.activeEpisodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            const roleIndex = prev?.role?.activeEpisodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            const yourEpisodesIndex = prev?.yourEpisodes?.activeEpisodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            const actionItemsIndex = prev?.actionItems?.activeEpisodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            const allIndex = prev?.all?.activeEpisodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );
            const ctmIndex = prev?.ctm?.activeEpisodes.findIndex(
              (episode: EpisodeSummary) => episode.id === next?.id
            );

            const updates = { ...prev };

            if (youIndex > -1) {
              const youUpdates = [...prev.you.activeEpisodes];
              youUpdates[youIndex] = next;
              updates.you.activeEpisodes = youUpdates;
            }

            if (roleIndex > -1) {
              const roleUpdates = [...prev.role.activeEpisodes];
              roleUpdates[roleIndex] = next;
              updates.role.activeEpisodes = roleUpdates;
            }

            if (yourEpisodesIndex > -1) {
              const yourEpisodesUpdates = [...prev.yourEpisodes.activeEpisodes];
              yourEpisodesUpdates[yourEpisodesIndex] = next;
              updates.yourEpisodes.activeEpisodes = yourEpisodesUpdates;
            }

            if (actionItemsIndex > -1) {
              const actionItemsUpdates = [...prev.actionItems.activeEpisodes];
              actionItemsUpdates[actionItemsIndex] = next;
              updates.actionItems.activeEpisodes = actionItemsUpdates;
            }

            if (allIndex > -1) {
              const allUpdates = [...prev.all.activeEpisodes];
              allUpdates[allIndex] = next;
              updates.all.activeEpisodes = allUpdates;
            }

            if (ctmIndex > -1) {
              const ctmUpdates = [...prev.ctm.activeEpisodes];
              ctmUpdates[ctmIndex] = next;
              updates.ctm.activeEpisodes = ctmUpdates;
            }

            if (
              youIndex > -1 ||
              roleIndex > -1 ||
              yourEpisodesIndex > -1 ||
              actionItemsIndex > -1 ||
              allIndex > -1 ||
              ctmIndex > -1
            ) {
              return {
                ...updates,
              };
            }

            return prev;
          },
        },
      }
    );

  const [, updateOwner] = useMutation(
    (episodeId: string, owner: EpisodeOwnerRequestBody) => {
      return queryConfig({ episodeId, owner }) as QueryConfig<Entities>;
    }
  );

  return { updateOwner };
};
