// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SynopsisSite,
    SynopsisSiteFromJSON,
    SynopsisSiteToJSON,
} from './SynopsisSite';


/**
 * 
 * @export
 * @interface Synopsis
 */
export interface Synopsis  {
    /**
     * 
     * @type {Array<SynopsisSite>}
     * @memberof Synopsis
     */
    sites?: Array<SynopsisSite>;
}

export function SynopsisFromJSON(json: any): Synopsis {
    return {
        'sites': !exists(json, 'sites') ? undefined : (json['sites'] as Array<any>).map(SynopsisSiteFromJSON),
    };
}

export function SynopsisToJSON(value?: Synopsis): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'sites': value.sites === undefined ? undefined : (value.sites as Array<any>).map(SynopsisSiteToJSON),
    };
}


