// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     CompactComponentReference,
    CompactComponentReferenceFromJSON,
    CompactComponentReferenceToJSON,
} from './CompactComponentReference';
import {
     WorkspaceDraftDataSchemaBase,
    WorkspaceDraftDataSchemaBaseFromJSON,
    WorkspaceDraftDataSchemaBaseToJSON,
} from './WorkspaceDraftDataSchemaBase';
import {
     WorkspaceDraftDataSchemaDiagnosisBase,
    WorkspaceDraftDataSchemaDiagnosisBaseFromJSON,
    WorkspaceDraftDataSchemaDiagnosisBaseToJSON,
} from './WorkspaceDraftDataSchemaDiagnosisBase';
/**
 * @type WorkspaceDraftDataSchemaDiagnosis
 * @export
 */
export interface WorkspaceDraftDataSchemaDiagnosis extends WorkspaceDraftDataSchemaBase, WorkspaceDraftDataSchemaDiagnosisBase {
}

export function WorkspaceDraftDataSchemaDiagnosisFromJSON(json: any): WorkspaceDraftDataSchemaDiagnosis {
    return {
        ...WorkspaceDraftDataSchemaBaseFromJSON(json),
        ...WorkspaceDraftDataSchemaDiagnosisBaseFromJSON(json),
    };
}

export function WorkspaceDraftDataSchemaDiagnosisToJSON(value?: WorkspaceDraftDataSchemaDiagnosis): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...WorkspaceDraftDataSchemaBaseToJSON(value),
        ...WorkspaceDraftDataSchemaDiagnosisBaseToJSON(value),
    };
}

