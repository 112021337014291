// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiagnosesListItem,
    DiagnosesListItemFromJSON,
    DiagnosesListItemToJSON,
} from './DiagnosesListItem';


/**
 * 
 * @export
 * @interface DiagnosesSchema
 */
export interface DiagnosesSchema  {
    /**
     * 
     * @type {string}
     * @memberof DiagnosesSchema
     */
    contentTypeCategoryId: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosesSchema
     */
    contentTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof DiagnosesSchema
     */
    label: string;
    /**
     * 
     * @type {Array<DiagnosesListItem>}
     * @memberof DiagnosesSchema
     */
    items: Array<DiagnosesListItem>;
}

export function DiagnosesSchemaFromJSON(json: any): DiagnosesSchema {
    return {
        'contentTypeCategoryId': json['content_type_category_id'],
        'contentTypeId': json['content_type_id'],
        'label': json['label'],
        'items': (json['items'] as Array<any>).map(DiagnosesListItemFromJSON),
    };
}

export function DiagnosesSchemaToJSON(value?: DiagnosesSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'content_type_category_id': value.contentTypeCategoryId,
        'content_type_id': value.contentTypeId,
        'label': value.label,
        'items': (value.items as Array<any>).map(DiagnosesListItemToJSON),
    };
}


