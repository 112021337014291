import { useSelector } from "react-redux";
import { useRequest } from "redux-query-react";
import { fetchEpisodeRoles } from "shared/api/src/apis/EpisodesOfCareApi";
import { EpisodeRolesOutputSchema } from "shared/fetch/src/models/EpisodeRolesOutputSchema";

import { State } from "shared/types/state";

export const useGetEpisodeRoles = (episodeId: string) => {
  const episodeRoles: EpisodeRolesOutputSchema | undefined = useSelector(
    (state: State) => state.entities.episodeRoles
  );

  const queryConfig = () =>
    fetchEpisodeRoles(
      { episodeId },
      {
        transform: (body: EpisodeRolesOutputSchema) => ({
          episodeRoles: body,
        }),
        update: {
          episodeRoles: (
            prev: EpisodeRolesOutputSchema,
            next: EpisodeRolesOutputSchema
          ) => next,
        },
        force: true,
      }
    );
  const [{ isPending }] = useRequest(queryConfig() as any);

  return {
    episodeRoles,
    isPending,
  };
};
