import React, { useState, useCallback } from "react";

import Button from "components/Button/index";
import useWithAccessProps from "hooks/useWithAccessProps";
import ConsentForCareModal from "components/ServiceMenu/SubmitEOC/ConsentForCareModal";
import ConsentDeniedModal from "components/ServiceMenu/SubmitEOC/ConsentDeniedModal";
import { selectLoggedInUser } from "shared/features/user/selectors";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";

const ButtonWithAccess = (props: any) => {
  const { onClick, ...accessProps } = useWithAccessProps(props);
  const loggedInUser = selectLoggedInUser();

  const [showConsentModal, setShowConsentModal] = useState(false);
  const [showDeniedModal, setShowDeniedModal] = useState(false);

  const toggleShowConsentModal = useCallback(() => {
    setShowConsentModal(!showConsentModal);
  }, [showConsentModal]);

  const toggleDeniedModal = useCallback(() => {
    setShowDeniedModal(!showDeniedModal);
  }, [showDeniedModal]);

  const handleConsent = (e: any) => {
    setShowConsentModal(false);
    setShowDeniedModal(false);

    onClick && onClick(e);
  };

  const handleNoConsent = useCallback(() => {
    setShowDeniedModal(true);
  }, []);

  const handleButtonClick = (e: any) => {
    if (
      !props?.isCancelOrDeclineButton &&
      !props?.hideConsentModal &&
      !loggedInUser.ctm &&
      !loggedInUser.hasCreatedEoc &&
      props?.access === PermissionsEnum.EpisodesUpdate
    ) {
      setShowConsentModal(true);
    } else {
      onClick && onClick(e);
    }
  };

  const WrapperComponent = props.BaseComponent || Button;

  return (
    <>
      <WrapperComponent
        {...accessProps}
        style={props.style}
        isLoading={props.isLoading || false}
        onClick={handleButtonClick}
      />
      <ConsentForCareModal
        open={showConsentModal}
        toggle={toggleShowConsentModal}
        consentAffirmed={handleConsent}
        noConsent={handleNoConsent}
      />
      <ConsentDeniedModal open={showDeniedModal} toggle={toggleDeniedModal} />
    </>
  );
};

export default ButtonWithAccess;
