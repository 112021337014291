/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DiagnosesListItem,
    DiagnosesListItemFromJSON,
    DiagnosesListItemToJSON,
} from '../models/DiagnosesListItem'
import {
    DiagnosesSchema,
    DiagnosesSchemaFromJSON,
    DiagnosesSchemaToJSON,
} from '../models/DiagnosesSchema'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface GetDiagnosisByIdRequest {
    id: string;
}
export interface GetDiagnosisListRequest {
    q?: string;
}
export interface UpdateDiagnosisByIdRequest {
    id: string;
    diagnosesListItem?: DiagnosesListItem;
}


export interface IDiagnosesApi {
      getDiagnosisById: (requestParameters: GetDiagnosisByIdRequest) => Promise<DiagnosesListItem>
      getDiagnosisList: (requestParameters: GetDiagnosisListRequest) => Promise<DiagnosesSchema>
      updateDiagnosisById: (requestParameters: UpdateDiagnosisByIdRequest) => Promise<DiagnosesListItem>
      getDiagnosisByIdWithResponse: (requestParameters: GetDiagnosisByIdRequest) => Promise<{ value: DiagnosesListItem, response: any}>
      getDiagnosisListWithResponse: (requestParameters: GetDiagnosisListRequest) => Promise<{ value: DiagnosesSchema, response: any}>
      updateDiagnosisByIdWithResponse: (requestParameters: UpdateDiagnosisByIdRequest) => Promise<{ value: DiagnosesListItem, response: any}>
}

/**
 * no description
 */
export class DiagnosesApi extends runtime.BaseAPI {

    /**
     * Retrieve one diagnosis
     * Fetch diagnosis by id
     */
    getDiagnosisByIdRaw = async (requestParameters: GetDiagnosisByIdRequest): Promise<{ runtime: runtime.ApiResponse<DiagnosesListItem>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getDiagnosisById.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/diagnosis/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => DiagnosesListItemFromJSON(jsonValue)), response };
    }

    /**
     * Retrieve one diagnosis
     * Fetch diagnosis by id
     */
    getDiagnosisById = async (requestParameters: GetDiagnosisByIdRequest): Promise<DiagnosesListItem> => {
        const { runtime } = await this.getDiagnosisByIdRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Retrieve one diagnosis
     * Fetch diagnosis by id
     */
    getDiagnosisByIdWithResponse = async (requestParameters: GetDiagnosisByIdRequest): Promise<{ value: DiagnosesListItem, response: any}> => {
        const { runtime, response } = await this.getDiagnosisByIdRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Retrieve all diagnoses
     * Fetch all diagnoses
     */
    getDiagnosisListRaw = async (requestParameters: GetDiagnosisListRequest): Promise<{ runtime: runtime.ApiResponse<DiagnosesSchema>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/diagnoses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => DiagnosesSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Retrieve all diagnoses
     * Fetch all diagnoses
     */
    getDiagnosisList = async (requestParameters: GetDiagnosisListRequest): Promise<DiagnosesSchema> => {
        const { runtime } = await this.getDiagnosisListRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Retrieve all diagnoses
     * Fetch all diagnoses
     */
    getDiagnosisListWithResponse = async (requestParameters: GetDiagnosisListRequest): Promise<{ value: DiagnosesSchema, response: any}> => {
        const { runtime, response } = await this.getDiagnosisListRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update the diagnosis\' detail information
     * Update the diagnosis\' detail information
     */
    updateDiagnosisByIdRaw = async (requestParameters: UpdateDiagnosisByIdRequest): Promise<{ runtime: runtime.ApiResponse<DiagnosesListItem>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateDiagnosisById.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/diagnosis/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: DiagnosesListItemToJSON(requestParameters.diagnosesListItem),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => DiagnosesListItemFromJSON(jsonValue)), response };
    }

    /**
     * Update the diagnosis\' detail information
     * Update the diagnosis\' detail information
     */
    updateDiagnosisById = async (requestParameters: UpdateDiagnosisByIdRequest): Promise<DiagnosesListItem> => {
        const { runtime } = await this.updateDiagnosisByIdRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update the diagnosis\' detail information
     * Update the diagnosis\' detail information
     */
    updateDiagnosisByIdWithResponse = async (requestParameters: UpdateDiagnosisByIdRequest): Promise<{ value: DiagnosesListItem, response: any}> => {
        const { runtime, response } = await this.updateDiagnosisByIdRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchDiagnosesApi: IDiagnosesApi  = new DiagnosesApi();
