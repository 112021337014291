// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OutboundScreeningObjectDetail,
    OutboundScreeningObjectDetailFromJSON,
    OutboundScreeningObjectDetailToJSON,
} from './OutboundScreeningObjectDetail';


/**
 * 
 * @export
 * @interface OutboundScreeningsDetailResponse
 */
export interface OutboundScreeningsDetailResponse  {
    /**
     * 
     * @type {Array<OutboundScreeningObjectDetail>}
     * @memberof OutboundScreeningsDetailResponse
     */
    results?: Array<OutboundScreeningObjectDetail>;
}

export function OutboundScreeningsDetailResponseFromJSON(json: any): OutboundScreeningsDetailResponse {
    return {
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(OutboundScreeningObjectDetailFromJSON),
    };
}

export function OutboundScreeningsDetailResponseToJSON(value?: OutboundScreeningsDetailResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(OutboundScreeningObjectDetailToJSON),
    };
}


