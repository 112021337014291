// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputMemberSearchSchemaPropsPrimaryAccountHolderAddress
 */
export interface OutputMemberSearchSchemaPropsPrimaryAccountHolderAddress  {
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaPropsPrimaryAccountHolderAddress
     */
    primaryAccountHolderStreet?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaPropsPrimaryAccountHolderAddress
     */
    primaryAccountHolderCity?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaPropsPrimaryAccountHolderAddress
     */
    primaryAccountHolderState?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaPropsPrimaryAccountHolderAddress
     */
    primaryAccountHolderZip?: string;
}

export function OutputMemberSearchSchemaPropsPrimaryAccountHolderAddressFromJSON(json: any): OutputMemberSearchSchemaPropsPrimaryAccountHolderAddress {
    return {
        'primaryAccountHolderStreet': !exists(json, 'primary_account_holder_street') ? undefined : json['primary_account_holder_street'],
        'primaryAccountHolderCity': !exists(json, 'primary_account_holder_city') ? undefined : json['primary_account_holder_city'],
        'primaryAccountHolderState': !exists(json, 'primary_account_holder_state') ? undefined : json['primary_account_holder_state'],
        'primaryAccountHolderZip': !exists(json, 'primary_account_holder_zip') ? undefined : json['primary_account_holder_zip'],
    };
}

export function OutputMemberSearchSchemaPropsPrimaryAccountHolderAddressToJSON(value?: OutputMemberSearchSchemaPropsPrimaryAccountHolderAddress): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'primary_account_holder_street': value.primaryAccountHolderStreet,
        'primary_account_holder_city': value.primaryAccountHolderCity,
        'primary_account_holder_state': value.primaryAccountHolderState,
        'primary_account_holder_zip': value.primaryAccountHolderZip,
    };
}


