/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthenticateWithChallenge,
    AuthenticateWithChallengeFromJSON,
    AuthenticateWithChallengeToJSON,
} from '../models/AuthenticateWithChallenge'
import {
    BiometricAuthenticationSuccess,
    BiometricAuthenticationSuccessFromJSON,
    BiometricAuthenticationSuccessToJSON,
} from '../models/BiometricAuthenticationSuccess'
import {
    ChallengeStringResponse,
    ChallengeStringResponseFromJSON,
    ChallengeStringResponseToJSON,
} from '../models/ChallengeStringResponse'
import {
    EnrollBiometricsRequestBody,
    EnrollBiometricsRequestBodyFromJSON,
    EnrollBiometricsRequestBodyToJSON,
} from '../models/EnrollBiometricsRequestBody'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UnenrollBiometicsRequestBody,
    UnenrollBiometicsRequestBodyFromJSON,
    UnenrollBiometicsRequestBodyToJSON,
} from '../models/UnenrollBiometicsRequestBody'


export interface BiometricLoginRequest {
    authenticateWithChallenge?: AuthenticateWithChallenge;
}
export interface EnrollDeviceBiometricsRequest {
    enrollBiometricsRequestBody?: EnrollBiometricsRequestBody;
}
export interface UnenrollDeviceBiometricsRequest {
    unenrollBiometicsRequestBody?: UnenrollBiometicsRequestBody;
}


export interface IBiometricsApi {
      biometricLogin: (requestParameters: BiometricLoginRequest) => Promise<BiometricAuthenticationSuccess>
      enrollDeviceBiometrics: (requestParameters: EnrollDeviceBiometricsRequest) => Promise<void>
      getChallengeStringBiometrics: () => Promise<ChallengeStringResponse>
      unenrollDeviceBiometrics: (requestParameters: UnenrollDeviceBiometricsRequest) => Promise<void>
      biometricLoginWithResponse: (requestParameters: BiometricLoginRequest) => Promise<{ value: BiometricAuthenticationSuccess, response: any}>
      enrollDeviceBiometricsWithResponse: (requestParameters: EnrollDeviceBiometricsRequest) => Promise<{ value: void, response: any}>
      getChallengeStringBiometricsWithResponse: () => Promise<{ value: ChallengeStringResponse, response: any}>
      unenrollDeviceBiometricsWithResponse: (requestParameters: UnenrollDeviceBiometricsRequest) => Promise<{ value: void, response: any}>
}

/**
 * no description
 */
export class BiometricsApi extends runtime.BaseAPI {

    /**
     * Login with biometric using authenticated challenge
     * Login with biometric
     */
    biometricLoginRaw = async (requestParameters: BiometricLoginRequest): Promise<{ runtime: runtime.ApiResponse<BiometricAuthenticationSuccess>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/oauth2/authenticate_with_challenge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AuthenticateWithChallengeToJSON(requestParameters.authenticateWithChallenge),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => BiometricAuthenticationSuccessFromJSON(jsonValue)), response };
    }

    /**
     * Login with biometric using authenticated challenge
     * Login with biometric
     */
    biometricLogin = async (requestParameters: BiometricLoginRequest): Promise<BiometricAuthenticationSuccess> => {
        const { runtime } = await this.biometricLoginRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Login with biometric using authenticated challenge
     * Login with biometric
     */
    biometricLoginWithResponse = async (requestParameters: BiometricLoginRequest): Promise<{ value: BiometricAuthenticationSuccess, response: any}> => {
        const { runtime, response } = await this.biometricLoginRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Enroll Device in Biometrics
     * Enroll Device in Biometrics
     */
    enrollDeviceBiometricsRaw = async (requestParameters: EnrollDeviceBiometricsRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/enroll_biometrics`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EnrollBiometricsRequestBodyToJSON(requestParameters.enrollBiometricsRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Enroll Device in Biometrics
     * Enroll Device in Biometrics
     */
    enrollDeviceBiometrics = async (requestParameters: EnrollDeviceBiometricsRequest): Promise<void> => {
        const { runtime } = await this.enrollDeviceBiometricsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Enroll Device in Biometrics
     * Enroll Device in Biometrics
     */
    enrollDeviceBiometricsWithResponse = async (requestParameters: EnrollDeviceBiometricsRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.enrollDeviceBiometricsRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Get Challenge String for Biometrics
     * Get Challenge String for Biometrics
     */
    getChallengeStringBiometricsRaw = async (): Promise<{ runtime: runtime.ApiResponse<ChallengeStringResponse>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/challenge_string`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ChallengeStringResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get Challenge String for Biometrics
     * Get Challenge String for Biometrics
     */
    getChallengeStringBiometrics = async (): Promise<ChallengeStringResponse> => {
        const { runtime } = await this.getChallengeStringBiometricsRaw();
        return await runtime.value();
    }

    /**
     * Get Challenge String for Biometrics
     * Get Challenge String for Biometrics
     */
    getChallengeStringBiometricsWithResponse = async (): Promise<{ value: ChallengeStringResponse, response: any}> => {
        const { runtime, response } = await this.getChallengeStringBiometricsRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Unenroll Device from Biometrics authentication
     * Unenroll Device from Biometrics
     */
    unenrollDeviceBiometricsRaw = async (requestParameters: UnenrollDeviceBiometricsRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/unenroll_biometrics`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnenrollBiometicsRequestBodyToJSON(requestParameters.unenrollBiometicsRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Unenroll Device from Biometrics authentication
     * Unenroll Device from Biometrics
     */
    unenrollDeviceBiometrics = async (requestParameters: UnenrollDeviceBiometricsRequest): Promise<void> => {
        const { runtime } = await this.unenrollDeviceBiometricsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Unenroll Device from Biometrics authentication
     * Unenroll Device from Biometrics
     */
    unenrollDeviceBiometricsWithResponse = async (requestParameters: UnenrollDeviceBiometricsRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.unenrollDeviceBiometricsRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

}

export const fetchBiometricsApi: IBiometricsApi  = new BiometricsApi();
