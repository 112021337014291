// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
    UserInfo,
    UserInfoFromJSON,
    UserInfoToJSON,
} from './UserInfo';


/**
 * 
 * @export
 * @interface ActionableItemSummary
 */
export interface ActionableItemSummary  {
    /**
     * 
     * @type {string}
     * @memberof ActionableItemSummary
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemSummary
     */
    url: string;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemSummary
     */
    state: ActionableItemSummaryStateEnum;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemSummary
     */
    actionType: ActionableItemSummaryActionTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof ActionableItemSummary
     */
    acknowledgedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ActionableItemSummary
     */
    resolvedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemSummary
     */
    todoDisplayLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemSummary
     */
    notificationCenterDisplayLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemSummary
     */
    ctmStateDisplayLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemSummary
     */
    queueCopyDisplayLabel?: string;
    /**
     * 
     * @type {CTMInfo}
     * @memberof ActionableItemSummary
     */
    owner?: CTMInfo;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemSummary
     */
    ownershipStatus?: string;
    /**
     * 
     * @type {CTMInfo}
     * @memberof ActionableItemSummary
     */
    resolved?: CTMInfo;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemSummary
     */
    timelineEventId: string;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemSummary
     */
    episodeId: string;
    /**
     * 
     * @type {UserInfo}
     * @memberof ActionableItemSummary
     */
    createdBy?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemSummary
     */
    componentId: string;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemSummary
     */
    memberId?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemSummary
     */
    componentUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemSummary
     */
    memberCurrentUsState?: string;
}

export function ActionableItemSummaryFromJSON(json: any): ActionableItemSummary {
    return {
        'id': json['id'],
        'url': json['url'],
        'state': json['state'],
        'actionType': json['action_type'],
        'acknowledgedAt': !exists(json, 'acknowledged_at') ? undefined : new Date(json['acknowledged_at']),
        'resolvedAt': !exists(json, 'resolved_at') ? undefined : new Date(json['resolved_at']),
        'todoDisplayLabel': !exists(json, 'todo_display_label') ? undefined : json['todo_display_label'],
        'notificationCenterDisplayLabel': !exists(json, 'notification_center_display_label') ? undefined : json['notification_center_display_label'],
        'ctmStateDisplayLabel': !exists(json, 'ctm_state_display_label') ? undefined : json['ctm_state_display_label'],
        'queueCopyDisplayLabel': !exists(json, 'queue_copy_display_label') ? undefined : json['queue_copy_display_label'],
        'owner': !exists(json, 'owner') ? undefined : CTMInfoFromJSON(json['owner']),
        'ownershipStatus': !exists(json, 'ownership_status') ? undefined : json['ownership_status'],
        'resolved': !exists(json, 'resolved') ? undefined : CTMInfoFromJSON(json['resolved']),
        'timelineEventId': json['timeline_event_id'],
        'episodeId': json['episode_id'],
        'createdBy': !exists(json, 'created_by') ? undefined : UserInfoFromJSON(json['created_by']),
        'componentId': json['component_id'],
        'memberId': !exists(json, 'member_id') ? undefined : json['member_id'],
        'componentUrl': !exists(json, 'component_url') ? undefined : json['component_url'],
        'memberCurrentUsState': !exists(json, 'member_current_us_state') ? undefined : json['member_current_us_state'],
    };
}

export function ActionableItemSummaryToJSON(value?: ActionableItemSummary): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'url': value.url,
        'state': value.state,
        'action_type': value.actionType,
        'acknowledged_at': value.acknowledgedAt === undefined ? undefined : value.acknowledgedAt.toISOString(),
        'resolved_at': value.resolvedAt === undefined ? undefined : value.resolvedAt.toISOString(),
        'todo_display_label': value.todoDisplayLabel,
        'notification_center_display_label': value.notificationCenterDisplayLabel,
        'ctm_state_display_label': value.ctmStateDisplayLabel,
        'queue_copy_display_label': value.queueCopyDisplayLabel,
        'owner': CTMInfoToJSON(value.owner),
        'ownership_status': value.ownershipStatus,
        'resolved': CTMInfoToJSON(value.resolved),
        'timeline_event_id': value.timelineEventId,
        'episode_id': value.episodeId,
        'created_by': UserInfoToJSON(value.createdBy),
        'component_id': value.componentId,
        'member_id': value.memberId,
        'component_url': value.componentUrl,
        'member_current_us_state': value.memberCurrentUsState,
    };
}

/**
* @export
* @enum {string}
*/
export enum ActionableItemSummaryStateEnum {
    Active = 'active',
    AcknowledgedPending = 'acknowledged_pending',
    Resolved = 'resolved'
}
/**
* @export
* @enum {string}
*/
export enum ActionableItemSummaryActionTypeEnum {
    MemberMessage = 'member_message',
    CtmMessage = 'ctm_message',
    EpisodeCreated = 'episode_created',
    VirtualVisitReferral = 'virtual_visit_referral',
    QuestionSet = 'question_set',
    Calculator = 'calculator',
    CenterVisitReferral = 'center_visit_referral',
    VisitReferral = 'visit_referral',
    MarketingMessage = 'marketing_message',
    Billing = 'billing',
    SelfScheduleAppointment = 'self_schedule_appointment'
}


