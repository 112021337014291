// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LanguageCodeOutputSchemaResults,
    LanguageCodeOutputSchemaResultsFromJSON,
    LanguageCodeOutputSchemaResultsToJSON,
} from './LanguageCodeOutputSchemaResults';


/**
 * 
 * @export
 * @interface LanguageCodeOutputSchema
 */
export interface LanguageCodeOutputSchema  {
    /**
     * 
     * @type {Array<LanguageCodeOutputSchemaResults>}
     * @memberof LanguageCodeOutputSchema
     */
    results?: Array<LanguageCodeOutputSchemaResults>;
}

export function LanguageCodeOutputSchemaFromJSON(json: any): LanguageCodeOutputSchema {
    return {
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(LanguageCodeOutputSchemaResultsFromJSON),
    };
}

export function LanguageCodeOutputSchemaToJSON(value?: LanguageCodeOutputSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(LanguageCodeOutputSchemaResultsToJSON),
    };
}


