// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CMSListItemSchema,
    CMSListItemSchemaFromJSON,
    CMSListItemSchemaToJSON,
} from './CMSListItemSchema';
import {
    CmsServiceLineItem,
    CmsServiceLineItemFromJSON,
    CmsServiceLineItemToJSON,
} from './CmsServiceLineItem';


/**
 * 
 * @export
 * @interface CMSListSchema
 */
export interface CMSListSchema  {
    /**
     * 
     * @type {string}
     * @memberof CMSListSchema
     */
    contentTypeCategoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof CMSListSchema
     */
    contentTypeId?: string;
    /**
     * 
     * @type {string}
     * @memberof CMSListSchema
     */
    contentTypeLabel?: string;
    /**
     * 
     * @type {number}
     * @memberof CMSListSchema
     */
    totalContentTypeItems?: number;
    /**
     * 
     * @type {Array<CmsServiceLineItem>}
     * @memberof CMSListSchema
     */
    results?: Array<CmsServiceLineItem>;
    /**
     * 
     * @type {Array<CMSListItemSchema>}
     * @memberof CMSListSchema
     */
    contentTypeItems?: Array<CMSListItemSchema>;
}

export function CMSListSchemaFromJSON(json: any): CMSListSchema {
    return {
        'contentTypeCategoryId': !exists(json, 'content_type_category_id') ? undefined : json['content_type_category_id'],
        'contentTypeId': !exists(json, 'content_type_id') ? undefined : json['content_type_id'],
        'contentTypeLabel': !exists(json, 'content_type_label') ? undefined : json['content_type_label'],
        'totalContentTypeItems': !exists(json, 'total_content_type_items') ? undefined : json['total_content_type_items'],
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(CmsServiceLineItemFromJSON),
        'contentTypeItems': !exists(json, 'content_type_items') ? undefined : (json['content_type_items'] as Array<any>).map(CMSListItemSchemaFromJSON),
    };
}

export function CMSListSchemaToJSON(value?: CMSListSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'content_type_category_id': value.contentTypeCategoryId,
        'content_type_id': value.contentTypeId,
        'content_type_label': value.contentTypeLabel,
        'total_content_type_items': value.totalContentTypeItems,
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(CmsServiceLineItemToJSON),
        'content_type_items': value.contentTypeItems === undefined ? undefined : (value.contentTypeItems as Array<any>).map(CMSListItemSchemaToJSON),
    };
}


