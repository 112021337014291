// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EligibilitiesFoundResponseResults
 */
export interface EligibilitiesFoundResponseResults  {
    /**
     * 
     * @type {string}
     * @memberof EligibilitiesFoundResponseResults
     */
    externalId?: string;
    /**
     * 
     * @type {number}
     * @memberof EligibilitiesFoundResponseResults
     */
    efId?: number;
    /**
     * 
     * @type {number}
     * @memberof EligibilitiesFoundResponseResults
     */
    clinicId?: number;
    /**
     * 
     * @type {string}
     * @memberof EligibilitiesFoundResponseResults
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof EligibilitiesFoundResponseResults
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof EligibilitiesFoundResponseResults
     */
    middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof EligibilitiesFoundResponseResults
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof EligibilitiesFoundResponseResults
     */
    dob?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EligibilitiesFoundResponseResults
     */
    xopEligible?: boolean;
}

export function EligibilitiesFoundResponseResultsFromJSON(json: any): EligibilitiesFoundResponseResults {
    return {
        'externalId': !exists(json, 'external_id') ? undefined : json['external_id'],
        'efId': !exists(json, 'ef_id') ? undefined : json['ef_id'],
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'middleName': !exists(json, 'middle_name') ? undefined : json['middle_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'xopEligible': !exists(json, 'xop_eligible') ? undefined : json['xop_eligible'],
    };
}

export function EligibilitiesFoundResponseResultsToJSON(value?: EligibilitiesFoundResponseResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'external_id': value.externalId,
        'ef_id': value.efId,
        'clinic_id': value.clinicId,
        'email': value.email,
        'first_name': value.firstName,
        'middle_name': value.middleName,
        'last_name': value.lastName,
        'dob': value.dob,
        'xop_eligible': value.xopEligible,
    };
}


