// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CalculatorComponentOutputPropsJsonBody,
    CalculatorComponentOutputPropsJsonBodyFromJSON,
    CalculatorComponentOutputPropsJsonBodyToJSON,
} from './CalculatorComponentOutputPropsJsonBody';
import {
    CalculatorScoreOutput,
    CalculatorScoreOutputFromJSON,
    CalculatorScoreOutputToJSON,
} from './CalculatorScoreOutput';
import {
    CompactComponentReference,
    CompactComponentReferenceFromJSON,
    CompactComponentReferenceToJSON,
} from './CompactComponentReference';
import {
    CtmMessageComponentOutput,
    CtmMessageComponentOutputFromJSON,
    CtmMessageComponentOutputToJSON,
} from './CtmMessageComponentOutput';
import {
    MarketingMessageComponentOutput,
    MarketingMessageComponentOutputFromJSON,
    MarketingMessageComponentOutputToJSON,
} from './MarketingMessageComponentOutput';
import {
    PatientScreening,
    PatientScreeningFromJSON,
    PatientScreeningToJSON,
} from './PatientScreening';


/**
 * 
 * @export
 * @interface CalculatorComponentOutputProps
 */
export interface CalculatorComponentOutputProps  {
    /**
     * 
     * @type {string}
     * @memberof CalculatorComponentOutputProps
     */
    assignmentType?: CalculatorComponentOutputPropsAssignmentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CalculatorComponentOutputProps
     */
    state: CalculatorComponentOutputPropsStateEnum;
    /**
     * 
     * @type {Array<CompactComponentReference>}
     * @memberof CalculatorComponentOutputProps
     */
    parentComponents?: Array<CompactComponentReference>;
    /**
     * 
     * @type {CalculatorComponentOutputPropsJsonBody}
     * @memberof CalculatorComponentOutputProps
     */
    jsonBody?: CalculatorComponentOutputPropsJsonBody;
    /**
     * 
     * @type {CtmMessageComponentOutput}
     * @memberof CalculatorComponentOutputProps
     */
    messageComponent?: CtmMessageComponentOutput;
    /**
     * 
     * @type {PatientScreening}
     * @memberof CalculatorComponentOutputProps
     */
    patientScreening?: PatientScreening;
    /**
     * 
     * @type {MarketingMessageComponentOutput}
     * @memberof CalculatorComponentOutputProps
     */
    marketingMessageComponent?: MarketingMessageComponentOutput;
    /**
     * 
     * @type {string}
     * @memberof CalculatorComponentOutputProps
     */
    submittedAt?: string;
    /**
     * 
     * @type {Array<CalculatorScoreOutput>}
     * @memberof CalculatorComponentOutputProps
     */
    results?: Array<CalculatorScoreOutput>;
    /**
     * 
     * @type {string}
     * @memberof CalculatorComponentOutputProps
     */
    userFacingName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CalculatorComponentOutputProps
     */
    timeSensitive?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CalculatorComponentOutputProps
     */
    timeSensitiveReasons?: Array<string>;
}

export function CalculatorComponentOutputPropsFromJSON(json: any): CalculatorComponentOutputProps {
    return {
        'assignmentType': !exists(json, 'assignment_type') ? undefined : json['assignment_type'],
        'state': json['state'],
        'parentComponents': !exists(json, 'parent_components') ? undefined : (json['parent_components'] as Array<any>).map(CompactComponentReferenceFromJSON),
        'jsonBody': !exists(json, 'json_body') ? undefined : CalculatorComponentOutputPropsJsonBodyFromJSON(json['json_body']),
        'messageComponent': !exists(json, 'message_component') ? undefined : CtmMessageComponentOutputFromJSON(json['message_component']),
        'patientScreening': !exists(json, 'patient_screening') ? undefined : PatientScreeningFromJSON(json['patient_screening']),
        'marketingMessageComponent': !exists(json, 'marketing_message_component') ? undefined : MarketingMessageComponentOutputFromJSON(json['marketing_message_component']),
        'submittedAt': !exists(json, 'submitted_at') ? undefined : json['submitted_at'],
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(CalculatorScoreOutputFromJSON),
        'userFacingName': !exists(json, 'user_facing_name') ? undefined : json['user_facing_name'],
        'timeSensitive': !exists(json, 'time_sensitive') ? undefined : json['time_sensitive'],
        'timeSensitiveReasons': !exists(json, 'time_sensitive_reasons') ? undefined : json['time_sensitive_reasons'],
    };
}

export function CalculatorComponentOutputPropsToJSON(value?: CalculatorComponentOutputProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'assignment_type': value.assignmentType,
        'state': value.state,
        'parent_components': value.parentComponents === undefined ? undefined : (value.parentComponents as Array<any>).map(CompactComponentReferenceToJSON),
        'json_body': CalculatorComponentOutputPropsJsonBodyToJSON(value.jsonBody),
        'message_component': CtmMessageComponentOutputToJSON(value.messageComponent),
        'patient_screening': PatientScreeningToJSON(value.patientScreening),
        'marketing_message_component': MarketingMessageComponentOutputToJSON(value.marketingMessageComponent),
        'submitted_at': value.submittedAt,
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(CalculatorScoreOutputToJSON),
        'user_facing_name': value.userFacingName,
        'time_sensitive': value.timeSensitive,
        'time_sensitive_reasons': value.timeSensitiveReasons,
    };
}

/**
* @export
* @enum {string}
*/
export enum CalculatorComponentOutputPropsAssignmentTypeEnum {
    Adhoc = 'adhoc',
    Checkin = 'checkin'
}
/**
* @export
* @enum {string}
*/
export enum CalculatorComponentOutputPropsStateEnum {
    UnreadPendingResponse = 'unread_pending_response',
    ReadPendingResponse = 'read_pending_response',
    DraftMember = 'draft_member',
    ResponsesSentPendingAck = 'responses_sent_pending_ack',
    AcknowledgedPendingResponse = 'acknowledged_pending_response',
    Resolved = 'resolved',
    ResponsesSentByCtmPendingAck = 'responses_sent_by_ctm_pending_ack',
    CompletedByCtmPendingAck = 'completed_by_ctm_pending_ack',
    CompletedByCtmResolved = 'completed_by_ctm_resolved',
    CompletedByCtmAcknowledgedPendingResponse = 'completed_by_ctm_acknowledged_pending_response'
}


