import LinkHeader from "http-link-header";
import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from "redux";
import {
  QueryResponse,
  RequestSuccessAction,
  requestAsync,
  updateEntities,
} from "redux-query";
import URI from "urijs";
import { State } from "shared/types/state";
import { getActionableItems } from "./";
import { ActionableItemSummaryStateEnum } from "shared/api/src/models/ActionableItemSummary";
import { fetchActionableItemsRedirectUrlRequest } from "./queryConfigs";
import isEqual from "lodash/isEqual";

const resolveActionableItemsMiddleware: Middleware =
  (api: MiddlewareAPI<Dispatch<AnyAction>, State>) =>
  (next: Dispatch) =>
  (action: AnyAction | RequestSuccessAction) => {
    // each successful redux-query will pass through this middleware and if it has any Link headers,
    // the actionable items described in those headers will be "resolved" within our redux store.
    if ((action as AnyAction).type === "@@query/REQUEST_SUCCESS") {
      const queryAction = action as QueryResponse;
      const linkHeader = queryAction?.responseHeaders?.link;

      const links =
        linkHeader &&
        LinkHeader.parse(linkHeader)
          .refs.filter((ref) => ref.rel === "actionable-item")
          .map((ref) => URI.parse(ref?.uri)?.path?.slice(1));

      const state = api.getState();
      const actionableItems = getActionableItems(state);
      let updatedActionableItems = [...actionableItems];
      const updatedActionableItemUrls = actionableItems.map((ai) => ai.url);

      // if we have link headers to resolve some AIs, map over them and resolve as appropriate
      links &&
        links.map((link) => {
          // Each link will contain the id of each actionable item that needs to be resolved.
          // Presumably, each UI that shows actionableItems will display them based on what
          // state they are in, therefore resolving them in the normalized data should cause
          // all UIs that they appear in to update once this action has been dispatched.
          // STUB: dispatch(resolveActionableItem(link));
          const index = updatedActionableItemUrls.indexOf(link as any);

          if (index > -1) {
            updatedActionableItems[index].state =
              ActionableItemSummaryStateEnum.Resolved;
          }

          return link;
        });

      // if we have a response that contains actionable items, update our redux store to update our UIs
      if (
        (queryAction?.entities &&
          queryAction?.entities?.component?.actionableItems) ||
        queryAction?.entities?.actionResponse?.actionableItems
      ) {
        // temp fix because nested structures coming back snake_case from api
        const component =
          queryAction.entities?.component ||
          queryAction.entities?.actionResponse;

        const { timelineEventId, episodeId } = component;

        component.actionableItems.map((updatedAi: any) => {
          const { id } = updatedAi;
          updatedActionableItems = updatedActionableItems.map((ai) => {
            if (
              id === ai.id &&
              episodeId === ai.episodeId &&
              timelineEventId === ai.timelineEventId
            ) {
              return Object.assign({}, ai, updatedAi);
            }

            return ai;
          });
        });
      }

      if (!isEqual) {
        api.dispatch(
          updateEntities({
            actionableItems: () => updatedActionableItems,
          })
        );
      }
    }

    // update our AIs ONLY when components/AI states change from a mutation (as opposed to any slice of state)
    // also update when we mark a component as viewed
    if (
      (action as AnyAction).type === "@@query/MUTATE_SUCCESS" &&
      (action?.entities?.actionResponse ||
        action?.entities?.component ||
        action?.url?.match(/v1\/components\/\d+\/viewed/) ||
        action?.url?.match(/v1\/components\/\d+\/acknowledge/) ||
        action?.url?.match(/v1\/components\/\d+\/acknowledge_and_respond/))
    ) {
      api.dispatch(requestAsync(fetchActionableItemsRedirectUrlRequest()));
    }
    return next(action as AnyAction);
  };

export default resolveActionableItemsMiddleware;
