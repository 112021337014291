// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonUserInsurance
 */
export interface JsonUserInsurance  {
    /**
     * 
     * @type {number}
     * @memberof JsonUserInsurance
     */
    id?: number;
    /**
     * Insurance Carrier or plan name
     * @type {string}
     * @memberof JsonUserInsurance
     */
    insurancePlan?: string;
    /**
     * Insurance Policy Holder First name and Last name
     * @type {string}
     * @memberof JsonUserInsurance
     */
    insuranceHolder?: string;
    /**
     * Member ID number
     * @type {string}
     * @memberof JsonUserInsurance
     */
    insuranceMemberid?: string;
}

export function JsonUserInsuranceFromJSON(json: any): JsonUserInsurance {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'insurancePlan': !exists(json, 'insurance_plan') ? undefined : json['insurance_plan'],
        'insuranceHolder': !exists(json, 'insurance_holder') ? undefined : json['insurance_holder'],
        'insuranceMemberid': !exists(json, 'insurance_memberid') ? undefined : json['insurance_memberid'],
    };
}

export function JsonUserInsuranceToJSON(value?: JsonUserInsurance): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'insurance_plan': value.insurancePlan,
        'insurance_holder': value.insuranceHolder,
        'insurance_memberid': value.insuranceMemberid,
    };
}


