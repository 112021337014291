export const getMessageSubject = (
  subject: string,
  subjectFreetext: string
): string => {
  if (subject !== "something_else") {
    const subjectReplaced = subject?.replace(/_/gi, " ");
    return (
      subjectReplaced?.charAt(0)?.toUpperCase() + subjectReplaced?.slice(1)
    );
  } else {
    return subjectFreetext.replace(/_/gi, " ");
  }
};
