// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';


/**
 * 
 * @export
 * @interface AcknowledgeableComponentProps
 */
export interface AcknowledgeableComponentProps  {
    /**
     * 
     * @type {Date}
     * @memberof AcknowledgeableComponentProps
     */
    acknowledgedAt?: Date;
    /**
     * 
     * @type {CTMInfo}
     * @memberof AcknowledgeableComponentProps
     */
    acknowledger?: CTMInfo;
}

export function AcknowledgeableComponentPropsFromJSON(json: any): AcknowledgeableComponentProps {
    return {
        'acknowledgedAt': !exists(json, 'acknowledged_at') ? undefined : new Date(json['acknowledged_at']),
        'acknowledger': !exists(json, 'acknowledger') ? undefined : CTMInfoFromJSON(json['acknowledger']),
    };
}

export function AcknowledgeableComponentPropsToJSON(value?: AcknowledgeableComponentProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'acknowledged_at': value.acknowledgedAt === undefined ? undefined : value.acknowledgedAt.toISOString(),
        'acknowledger': CTMInfoToJSON(value.acknowledger),
    };
}


