// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberSubscription,
    MemberSubscriptionFromJSON,
    MemberSubscriptionToJSON,
} from './MemberSubscription';


/**
 * 
 * @export
 * @interface ListMemberSubscription
 */
export interface ListMemberSubscription  {
    /**
     * 
     * @type {Array<MemberSubscription>}
     * @memberof ListMemberSubscription
     */
    results?: Array<MemberSubscription>;
}

export function ListMemberSubscriptionFromJSON(json: any): ListMemberSubscription {
    return {
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(MemberSubscriptionFromJSON),
    };
}

export function ListMemberSubscriptionToJSON(value?: ListMemberSubscription): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(MemberSubscriptionToJSON),
    };
}


