/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    MemberServiceLinesSchema,
    MemberServiceLinesSchemaFromJSON,
    MemberServiceLinesSchemaToJSON,
} from '../models/MemberServiceLinesSchema'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    ServiceLineGroupCore,
    ServiceLineGroupCoreFromJSON,
    ServiceLineGroupCoreToJSON,
} from '../models/ServiceLineGroupCore'
import {
    ServiceLineGroupSchema,
    ServiceLineGroupSchemaFromJSON,
    ServiceLineGroupSchemaToJSON,
} from '../models/ServiceLineGroupSchema'
import {
    ServiceLineGroupsArrayResponse,
    ServiceLineGroupsArrayResponseFromJSON,
    ServiceLineGroupsArrayResponseToJSON,
} from '../models/ServiceLineGroupsArrayResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface DeleteServiceLineGroupRequest {
    id: string;
}
export interface FetchMemberServiceLinesRequest {
    id: string;
}
export interface PostServiceLineGroupRequest {
    serviceLineGroupCore?: ServiceLineGroupCore;
}
export interface PutServiceLineGroupRequest {
    id: string;
    serviceLineGroupCore?: ServiceLineGroupCore;
}


export interface IServiceLinesApi {
      deleteServiceLineGroup: (requestParameters: DeleteServiceLineGroupRequest) => Promise<ServiceLineGroupSchema>
      fetchMemberServiceLines: (requestParameters: FetchMemberServiceLinesRequest) => Promise<MemberServiceLinesSchema>
      getServiceLineGroups: () => Promise<ServiceLineGroupsArrayResponse>
      postServiceLineGroup: (requestParameters: PostServiceLineGroupRequest) => Promise<ServiceLineGroupSchema>
      putServiceLineGroup: (requestParameters: PutServiceLineGroupRequest) => Promise<ServiceLineGroupSchema>
      deleteServiceLineGroupWithResponse: (requestParameters: DeleteServiceLineGroupRequest) => Promise<{ value: ServiceLineGroupSchema, response: any}>
      fetchMemberServiceLinesWithResponse: (requestParameters: FetchMemberServiceLinesRequest) => Promise<{ value: MemberServiceLinesSchema, response: any}>
      getServiceLineGroupsWithResponse: () => Promise<{ value: ServiceLineGroupsArrayResponse, response: any}>
      postServiceLineGroupWithResponse: (requestParameters: PostServiceLineGroupRequest) => Promise<{ value: ServiceLineGroupSchema, response: any}>
      putServiceLineGroupWithResponse: (requestParameters: PutServiceLineGroupRequest) => Promise<{ value: ServiceLineGroupSchema, response: any}>
}

/**
 * no description
 */
export class ServiceLinesApi extends runtime.BaseAPI {

    /**
     * Delete a service line group
     */
    deleteServiceLineGroupRaw = async (requestParameters: DeleteServiceLineGroupRequest): Promise<{ runtime: runtime.ApiResponse<ServiceLineGroupSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteServiceLineGroup.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/service_line_groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ServiceLineGroupSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Delete a service line group
     */
    deleteServiceLineGroup = async (requestParameters: DeleteServiceLineGroupRequest): Promise<ServiceLineGroupSchema> => {
        const { runtime } = await this.deleteServiceLineGroupRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Delete a service line group
     */
    deleteServiceLineGroupWithResponse = async (requestParameters: DeleteServiceLineGroupRequest): Promise<{ value: ServiceLineGroupSchema, response: any}> => {
        const { runtime, response } = await this.deleteServiceLineGroupRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch service lines based on Access plans, their extended network and service line group
     */
    fetchMemberServiceLinesRaw = async (requestParameters: FetchMemberServiceLinesRequest): Promise<{ runtime: runtime.ApiResponse<MemberServiceLinesSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchMemberServiceLines.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/service_lines`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberServiceLinesSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Fetch service lines based on Access plans, their extended network and service line group
     */
    fetchMemberServiceLines = async (requestParameters: FetchMemberServiceLinesRequest): Promise<MemberServiceLinesSchema> => {
        const { runtime } = await this.fetchMemberServiceLinesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch service lines based on Access plans, their extended network and service line group
     */
    fetchMemberServiceLinesWithResponse = async (requestParameters: FetchMemberServiceLinesRequest): Promise<{ value: MemberServiceLinesSchema, response: any}> => {
        const { runtime, response } = await this.fetchMemberServiceLinesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * View the list of service line groups
     */
    getServiceLineGroupsRaw = async (): Promise<{ runtime: runtime.ApiResponse<ServiceLineGroupsArrayResponse>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/service_line_groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ServiceLineGroupsArrayResponseFromJSON(jsonValue)), response };
    }

    /**
     * View the list of service line groups
     */
    getServiceLineGroups = async (): Promise<ServiceLineGroupsArrayResponse> => {
        const { runtime } = await this.getServiceLineGroupsRaw();
        return await runtime.value();
    }

    /**
     * View the list of service line groups
     */
    getServiceLineGroupsWithResponse = async (): Promise<{ value: ServiceLineGroupsArrayResponse, response: any}> => {
        const { runtime, response } = await this.getServiceLineGroupsRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Create a service line group
     */
    postServiceLineGroupRaw = async (requestParameters: PostServiceLineGroupRequest): Promise<{ runtime: runtime.ApiResponse<ServiceLineGroupSchema>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/service_line_groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceLineGroupCoreToJSON(requestParameters.serviceLineGroupCore),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ServiceLineGroupSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Create a service line group
     */
    postServiceLineGroup = async (requestParameters: PostServiceLineGroupRequest): Promise<ServiceLineGroupSchema> => {
        const { runtime } = await this.postServiceLineGroupRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Create a service line group
     */
    postServiceLineGroupWithResponse = async (requestParameters: PostServiceLineGroupRequest): Promise<{ value: ServiceLineGroupSchema, response: any}> => {
        const { runtime, response } = await this.postServiceLineGroupRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update a service line group
     */
    putServiceLineGroupRaw = async (requestParameters: PutServiceLineGroupRequest): Promise<{ runtime: runtime.ApiResponse<ServiceLineGroupSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putServiceLineGroup.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/service_line_groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ServiceLineGroupCoreToJSON(requestParameters.serviceLineGroupCore),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ServiceLineGroupSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Update a service line group
     */
    putServiceLineGroup = async (requestParameters: PutServiceLineGroupRequest): Promise<ServiceLineGroupSchema> => {
        const { runtime } = await this.putServiceLineGroupRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update a service line group
     */
    putServiceLineGroupWithResponse = async (requestParameters: PutServiceLineGroupRequest): Promise<{ value: ServiceLineGroupSchema, response: any}> => {
        const { runtime, response } = await this.putServiceLineGroupRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchServiceLinesApi: IServiceLinesApi  = new ServiceLinesApi();
