import { CmsNavigationSchemaHierarchy } from "shared/api/src/models/CmsNavigationSchemaHierarchy";
import { ContentTypeItems } from "shared/api/src/models/ContentTypeItems";

export const getCmsMenuHierarchyWithPath = (
  data: CmsNavigationSchemaHierarchy[]
) => {
  const cmsMenuHierarchyWithPath = data?.map((menuItem: any) => {
    const contentTypeCategoryId = menuItem.id;
    return {
      ...menuItem,
      contentTypes: menuItem.contentTypes.map(
        (contentType: ContentTypeItems) => {
          const contentTypeId = contentType.id;
          return {
            ...contentType,
            pathname: `/tools/${contentTypeCategoryId}/${contentTypeId}`,
          };
        }
      ),
    };
  });
  return { cmsMenuHierarchyWithPath };
};
