// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceLineGroupCore
 */
export interface ServiceLineGroupCore  {
    /**
     * 
     * @type {string}
     * @memberof ServiceLineGroupCore
     */
    description: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ServiceLineGroupCore
     */
    serviceLines?: Array<string>;
}

export function ServiceLineGroupCoreFromJSON(json: any): ServiceLineGroupCore {
    return {
        'description': json['description'],
        'serviceLines': !exists(json, 'service_lines') ? undefined : json['service_lines'],
    };
}

export function ServiceLineGroupCoreToJSON(value?: ServiceLineGroupCore): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'description': value.description,
        'service_lines': value.serviceLines,
    };
}


