import React from "react";
import Tooltip from "components/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import { ClassNameMap } from '@mui/styles';

interface MAPIProps {
  tooltipText: string;
  useStyles?: () => ClassNameMap;
}

const IconWithTooltip = ({ tooltipText, useStyles }: MAPIProps) => {
  const classes = useStyles && useStyles();
  return (
    <Tooltip data-testid="map-info-tooltip" title={tooltipText}>
      <ErrorIcon
        data-testid="exclamation-icon-with-tooltip"
        className={classes?.textIcon || ""}
      />
    </Tooltip>
  );
};

export default IconWithTooltip;
