// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuestionSetsListItem
 */
export interface QuestionSetsListItem  {
    /**
     * 
     * @type {string}
     * @memberof QuestionSetsListItem
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionSetsListItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionSetsListItem
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionSetsListItem
     */
    totalQuestions: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionSetsListItem
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionSetsListItem
     */
    sqsId: string;
}

export function QuestionSetsListItemFromJSON(json: any): QuestionSetsListItem {
    return {
        'type': !exists(json, 'type') ? undefined : json['type'],
        'id': json['id'],
        'name': json['name'],
        'totalQuestions': json['total_questions'],
        'category': json['category'],
        'sqsId': json['sqs_id'],
    };
}

export function QuestionSetsListItemToJSON(value?: QuestionSetsListItem): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'type': value.type,
        'id': value.id,
        'name': value.name,
        'total_questions': value.totalQuestions,
        'category': value.category,
        'sqs_id': value.sqsId,
    };
}


