// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberPaymentHistory,
    MemberPaymentHistoryFromJSON,
    MemberPaymentHistoryToJSON,
} from './MemberPaymentHistory';


/**
 * 
 * @export
 * @interface ListMemberPaymentHistory
 */
export interface ListMemberPaymentHistory  {
    /**
     * 
     * @type {string}
     * @memberof ListMemberPaymentHistory
     */
    prevUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ListMemberPaymentHistory
     */
    nextUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof ListMemberPaymentHistory
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ListMemberPaymentHistory
     */
    last?: number;
    /**
     * 
     * @type {number}
     * @memberof ListMemberPaymentHistory
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof ListMemberPaymentHistory
     */
    unpaidItemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ListMemberPaymentHistory
     */
    paidItemsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof ListMemberPaymentHistory
     */
    estimatedItemsCount?: number;
    /**
     * 
     * @type {Array<MemberPaymentHistory>}
     * @memberof ListMemberPaymentHistory
     */
    results?: Array<MemberPaymentHistory>;
}

export function ListMemberPaymentHistoryFromJSON(json: any): ListMemberPaymentHistory {
    return {
        'prevUrl': !exists(json, 'prev_url') ? undefined : json['prev_url'],
        'nextUrl': !exists(json, 'next_url') ? undefined : json['next_url'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'unpaidItemsCount': !exists(json, 'unpaid_items_count') ? undefined : json['unpaid_items_count'],
        'paidItemsCount': !exists(json, 'paid_items_count') ? undefined : json['paid_items_count'],
        'estimatedItemsCount': !exists(json, 'estimated_items_count') ? undefined : json['estimated_items_count'],
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(MemberPaymentHistoryFromJSON),
    };
}

export function ListMemberPaymentHistoryToJSON(value?: ListMemberPaymentHistory): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'prev_url': value.prevUrl,
        'next_url': value.nextUrl,
        'page': value.page,
        'last': value.last,
        'count': value.count,
        'unpaid_items_count': value.unpaidItemsCount,
        'paid_items_count': value.paidItemsCount,
        'estimated_items_count': value.estimatedItemsCount,
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(MemberPaymentHistoryToJSON),
    };
}


