import React, { FunctionComponent } from "react";
import Asset from "../../Asset";
import Box from "../../Box";
import { eocRootPath } from "containers/Routing";
import ButtonWithAccess from "components/Button/ButtonWithAccess";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";

const NewEpisodeCta: FunctionComponent<{}> = (): JSX.Element => {
  return (
    <Box ml={-2} mt={1} pb={2} data-testid="create-eoc">
      <ButtonWithAccess
        access={PermissionsEnum.EpisodesCreate}
        color="tertiary"
        to={eocRootPath}
        title="Start a new conversation"
        fontWeightBold
      >
        Start a new conversation
        <Asset name="icon-plus" className="icon icon-plus" />
      </ButtonWithAccess>
    </Box>
  );
};

export default NewEpisodeCta;
