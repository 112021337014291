// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StripePaymentSource
 */
export interface StripePaymentSource  {
    /**
     * City/District/Suburb/Town/Village.
     * @type {string}
     * @memberof StripePaymentSource
     */
    addressCity?: string;
    /**
     * Billing address country, if provided when creating card.
     * @type {string}
     * @memberof StripePaymentSource
     */
    addressCountry?: string;
    /**
     * Address line 1 (Street address/PO Box/Company name).
     * @type {string}
     * @memberof StripePaymentSource
     */
    addressLine1?: string;
    /**
     * If `address_line1` was provided, results of the check: `pass`, `fail`, `unavailable`, or `unchecked`.
     * @type {string}
     * @memberof StripePaymentSource
     */
    addressLine1Check?: string;
    /**
     * Address line 2 (Apartment/Suite/Unit/Building).
     * @type {string}
     * @memberof StripePaymentSource
     */
    addressLine2?: string;
    /**
     * State/County/Province/Region.
     * @type {string}
     * @memberof StripePaymentSource
     */
    addressState?: string;
    /**
     * ZIP or postal code.
     * @type {string}
     * @memberof StripePaymentSource
     */
    addressZip?: string;
    /**
     * If `address_zip` was provided, results of the check: `pass`, `fail`, `unavailable`, or `unchecked`.
     * @type {string}
     * @memberof StripePaymentSource
     */
    addressZipCheck?: string;
    /**
     * A set of available payout methods for this card. Only values from this set should be passed as the `method` when creating a payout.
     * @type {Array<string>}
     * @memberof StripePaymentSource
     */
    availablePayoutMethods?: Array<StripePaymentSourceAvailablePayoutMethodsEnum>;
    /**
     * Card brand. Can be `American Express`, `Diners Club`, `Discover`, `JCB`, `MasterCard`, `UnionPay`, `Visa`, or `Unknown`.
     * @type {string}
     * @memberof StripePaymentSource
     */
    brand?: string;
    /**
     * Two-letter ISO code representing the country of the card. You could use this attribute to get a sense of the international breakdown of cards you\'ve collected.
     * @type {string}
     * @memberof StripePaymentSource
     */
    country?: string;
    /**
     * Three-letter [ISO code for currency](https://stripe.com/docs/payouts). Only applicable on accounts (not customers or recipients). The card can be used as a transfer destination for funds in this currency.
     * @type {string}
     * @memberof StripePaymentSource
     */
    currency?: string;
    /**
     * If a CVC was provided, results of the check: `pass`, `fail`, `unavailable`, or `unchecked`. A result of unchecked indicates that CVC was provided but hasn\'t been checked yet. Checks are typically performed when attaching a card to a Customer object, or when creating a charge. For more details, see [Check if a card is valid without a charge](https://support.stripe.com/questions/check-if-a-card-is-valid-without-a-charge).
     * @type {string}
     * @memberof StripePaymentSource
     */
    cvcCheck?: string;
    /**
     * Whether this card is the default external account for its currency.
     * @type {boolean}
     * @memberof StripePaymentSource
     */
    defaultForCurrency?: boolean;
    /**
     * A high-level description of the type of cards issued in this range. (For internal use only and not typically available in standard API requests.)
     * @type {string}
     * @memberof StripePaymentSource
     */
    description?: string;
    /**
     * (For tokenized numbers only.) The last four digits of the device account number.
     * @type {string}
     * @memberof StripePaymentSource
     */
    dynamicLast4?: string;
    /**
     * Two-digit number representing the card\'s expiration month.
     * @type {number}
     * @memberof StripePaymentSource
     */
    expMonth?: number;
    /**
     * Four-digit number representing the card\'s expiration year.
     * @type {number}
     * @memberof StripePaymentSource
     */
    expYear?: number;
    /**
     * Uniquely identifies this particular card number. You can use this attribute to check whether two customers who’ve signed up with you are using the same card number, for example. For payment methods that tokenize card information (Apple Pay, Google Pay), the tokenized number might be provided instead of the underlying card number.  *Starting May 1, 2021, card fingerprint in India for Connect will change to allow two fingerprints for the same card --- one for India and one for the rest of the world.*
     * @type {string}
     * @memberof StripePaymentSource
     */
    fingerprint?: string;
    /**
     * Card funding type. Can be `credit`, `debit`, `prepaid`, or `unknown`.
     * @type {string}
     * @memberof StripePaymentSource
     */
    funding?: string;
    /**
     * Unique identifier for the object.
     * @type {string}
     * @memberof StripePaymentSource
     */
    id?: string;
    /**
     * Issuer identification number of the card. (For internal use only and not typically available in standard API requests.)
     * @type {string}
     * @memberof StripePaymentSource
     */
    iin?: string;
    /**
     * The name of the card\'s issuing bank. (For internal use only and not typically available in standard API requests.)
     * @type {string}
     * @memberof StripePaymentSource
     */
    issuer?: string;
    /**
     * The last four digits of the card.
     * @type {string}
     * @memberof StripePaymentSource
     */
    last4?: string;
    /**
     * Set of [key-value pairs](https://stripe.com/docs/api/metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format.
     * @type {{ [key: string]: string; }}
     * @memberof StripePaymentSource
     */
    metadata?: { [key: string]: string; };
    /**
     * Cardholder name.
     * @type {string}
     * @memberof StripePaymentSource
     */
    name?: string;
    /**
     * String representing the object\'s type. Objects of the same type share the same value.
     * @type {string}
     * @memberof StripePaymentSource
     */
    object?: StripePaymentSourceModelObjectEnum;
    /**
     * For external accounts, possible values are `new` and `errored`. If a transfer fails, the status is set to `errored` and transfers are stopped until account details are updated.
     * @type {string}
     * @memberof StripePaymentSource
     */
    status?: string;
    /**
     * If the card number is tokenized, this is the method that was used. Can be `android_pay` (includes Google Pay), `apple_pay`, `masterpass`, `visa_checkout`, or null.
     * @type {string}
     * @memberof StripePaymentSource
     */
    tokenizationMethod?: string;
}

export function StripePaymentSourceFromJSON(json: any): StripePaymentSource {
    return {
        'addressCity': !exists(json, 'address_city') ? undefined : json['address_city'],
        'addressCountry': !exists(json, 'address_country') ? undefined : json['address_country'],
        'addressLine1': !exists(json, 'address_line1') ? undefined : json['address_line1'],
        'addressLine1Check': !exists(json, 'address_line1_check') ? undefined : json['address_line1_check'],
        'addressLine2': !exists(json, 'address_line2') ? undefined : json['address_line2'],
        'addressState': !exists(json, 'address_state') ? undefined : json['address_state'],
        'addressZip': !exists(json, 'address_zip') ? undefined : json['address_zip'],
        'addressZipCheck': !exists(json, 'address_zip_check') ? undefined : json['address_zip_check'],
        'availablePayoutMethods': !exists(json, 'available_payout_methods') ? undefined : json['available_payout_methods'],
        'brand': !exists(json, 'brand') ? undefined : json['brand'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'cvcCheck': !exists(json, 'cvc_check') ? undefined : json['cvc_check'],
        'defaultForCurrency': !exists(json, 'default_for_currency') ? undefined : json['default_for_currency'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'dynamicLast4': !exists(json, 'dynamic_last4') ? undefined : json['dynamic_last4'],
        'expMonth': !exists(json, 'exp_month') ? undefined : json['exp_month'],
        'expYear': !exists(json, 'exp_year') ? undefined : json['exp_year'],
        'fingerprint': !exists(json, 'fingerprint') ? undefined : json['fingerprint'],
        'funding': !exists(json, 'funding') ? undefined : json['funding'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'iin': !exists(json, 'iin') ? undefined : json['iin'],
        'issuer': !exists(json, 'issuer') ? undefined : json['issuer'],
        'last4': !exists(json, 'last4') ? undefined : json['last4'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'object': !exists(json, 'object') ? undefined : json['object'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'tokenizationMethod': !exists(json, 'tokenization_method') ? undefined : json['tokenization_method'],
    };
}

export function StripePaymentSourceToJSON(value?: StripePaymentSource): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'address_city': value.addressCity,
        'address_country': value.addressCountry,
        'address_line1': value.addressLine1,
        'address_line1_check': value.addressLine1Check,
        'address_line2': value.addressLine2,
        'address_state': value.addressState,
        'address_zip': value.addressZip,
        'address_zip_check': value.addressZipCheck,
        'available_payout_methods': value.availablePayoutMethods,
        'brand': value.brand,
        'country': value.country,
        'currency': value.currency,
        'cvc_check': value.cvcCheck,
        'default_for_currency': value.defaultForCurrency,
        'description': value.description,
        'dynamic_last4': value.dynamicLast4,
        'exp_month': value.expMonth,
        'exp_year': value.expYear,
        'fingerprint': value.fingerprint,
        'funding': value.funding,
        'id': value.id,
        'iin': value.iin,
        'issuer': value.issuer,
        'last4': value.last4,
        'metadata': value.metadata,
        'name': value.name,
        'object': value.object,
        'status': value.status,
        'tokenization_method': value.tokenizationMethod,
    };
}

/**
* @export
* @enum {string}
*/
export enum StripePaymentSourceAvailablePayoutMethodsEnum {
    Instant = 'instant',
    Standard = 'standard'
}
/**
* @export
* @enum {string}
*/
export enum StripePaymentSourceModelObjectEnum {
    Card = 'card'
}


