import { useMutation } from "redux-query-react";
import { QueryConfig, Entities } from "redux-query";
import { updateEpisodeRoles } from "shared/api/src/apis/EpisodesOfCareApi";

interface IRequest {
  episodeId: string;
  roles: string[];
}

export const useUpdateEpisodeRoles = () => {
  const queryConfig = ({ episodeId, roles }: IRequest): any =>
    updateEpisodeRoles(
      {
        episodeId,
        updateEpisodeRolesRequestBody: { roles },
      },
      {
        transform: (episodeRoles: any) => ({
          episodeRoles,
        }),
        update: {
          episodeRoles: (prev: any, next: any) => ({
            ...prev,
            ...next,
          }),
        },
      }
    );

  const [, updateRoles] = useMutation((episodeId: string, roles: string[]) => {
    return queryConfig({ episodeId, roles }) as QueryConfig<Entities>;
  });

  return { updateRoles };
};
