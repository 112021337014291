/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AllFeatureFlags,
    AllFeatureFlagsFromJSON,
    AllFeatureFlagsToJSON,
} from '../models/AllFeatureFlags'
import {
    CreateFeatureFlagInput,
    CreateFeatureFlagInputFromJSON,
    CreateFeatureFlagInputToJSON,
} from '../models/CreateFeatureFlagInput'
import {
    CreateFeatureFlagOutput,
    CreateFeatureFlagOutputFromJSON,
    CreateFeatureFlagOutputToJSON,
} from '../models/CreateFeatureFlagOutput'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    OutputFeatureFlagResultsArraySchema,
    OutputFeatureFlagResultsArraySchemaFromJSON,
    OutputFeatureFlagResultsArraySchemaToJSON,
} from '../models/OutputFeatureFlagResultsArraySchema'
import {
    SetFeatureFlagInput,
    SetFeatureFlagInputFromJSON,
    SetFeatureFlagInputToJSON,
} from '../models/SetFeatureFlagInput'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface CreateFeatureFlagRequest {
    createFeatureFlagInput?: CreateFeatureFlagInput;
}
export interface DeleteFeatureFlagByIdRequest {
    id: string;
}
export interface FetchAllFeatureFlagsRequest {
    type?: string;
}
export interface FetchFeatureFlagsForAUserRequest {
    id: string;
}
export interface SetFeatureFlagRequest {
    setFeatureFlagInput?: SetFeatureFlagInput;
}


export interface IFeatureFlagsApi {
      createFeatureFlag: (requestParameters: CreateFeatureFlagRequest) => Promise<void>
      deleteFeatureFlagById: (requestParameters: DeleteFeatureFlagByIdRequest) => Promise<void>
      fetchAllFeatureFlags: (requestParameters: FetchAllFeatureFlagsRequest) => Promise<AllFeatureFlags>
      fetchFeatureFlags: () => Promise<OutputFeatureFlagResultsArraySchema>
      fetchFeatureFlagsForAUser: (requestParameters: FetchFeatureFlagsForAUserRequest) => Promise<OutputFeatureFlagResultsArraySchema>
      fetchGlobalFeatureFlags: () => Promise<OutputFeatureFlagResultsArraySchema>
      setFeatureFlag: (requestParameters: SetFeatureFlagRequest) => Promise<void>
      createFeatureFlagWithResponse: (requestParameters: CreateFeatureFlagRequest) => Promise<{ value: void, response: any}>
      deleteFeatureFlagByIdWithResponse: (requestParameters: DeleteFeatureFlagByIdRequest) => Promise<{ value: void, response: any}>
      fetchAllFeatureFlagsWithResponse: (requestParameters: FetchAllFeatureFlagsRequest) => Promise<{ value: AllFeatureFlags, response: any}>
      fetchFeatureFlagsWithResponse: () => Promise<{ value: OutputFeatureFlagResultsArraySchema, response: any}>
      fetchFeatureFlagsForAUserWithResponse: (requestParameters: FetchFeatureFlagsForAUserRequest) => Promise<{ value: OutputFeatureFlagResultsArraySchema, response: any}>
      fetchGlobalFeatureFlagsWithResponse: () => Promise<{ value: OutputFeatureFlagResultsArraySchema, response: any}>
      setFeatureFlagWithResponse: (requestParameters: SetFeatureFlagRequest) => Promise<{ value: void, response: any}>
}

/**
 * no description
 */
export class FeatureFlagsApi extends runtime.BaseAPI {

    /**
     * Create Feature Flag
     * Creates a Feature Flag and returns (201) and a Feature Flag id
     */
    createFeatureFlagRaw = async (requestParameters: CreateFeatureFlagRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/feature_flags`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateFeatureFlagInputToJSON(requestParameters.createFeatureFlagInput),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Create Feature Flag
     * Creates a Feature Flag and returns (201) and a Feature Flag id
     */
    createFeatureFlag = async (requestParameters: CreateFeatureFlagRequest): Promise<void> => {
        const { runtime } = await this.createFeatureFlagRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Create Feature Flag
     * Creates a Feature Flag and returns (201) and a Feature Flag id
     */
    createFeatureFlagWithResponse = async (requestParameters: CreateFeatureFlagRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.createFeatureFlagRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Delete a Feature Flag by ID
     */
    deleteFeatureFlagByIdRaw = async (requestParameters: DeleteFeatureFlagByIdRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteFeatureFlagById.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/feature_flags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Delete a Feature Flag by ID
     */
    deleteFeatureFlagById = async (requestParameters: DeleteFeatureFlagByIdRequest): Promise<void> => {
        const { runtime } = await this.deleteFeatureFlagByIdRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Delete a Feature Flag by ID
     */
    deleteFeatureFlagByIdWithResponse = async (requestParameters: DeleteFeatureFlagByIdRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.deleteFeatureFlagByIdRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Fetch all feature flags
     * Fetch all feature flags
     */
    fetchAllFeatureFlagsRaw = async (requestParameters: FetchAllFeatureFlagsRequest): Promise<{ runtime: runtime.ApiResponse<AllFeatureFlags>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/all_feature_flags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AllFeatureFlagsFromJSON(jsonValue)), response };
    }

    /**
     * Fetch all feature flags
     * Fetch all feature flags
     */
    fetchAllFeatureFlags = async (requestParameters: FetchAllFeatureFlagsRequest): Promise<AllFeatureFlags> => {
        const { runtime } = await this.fetchAllFeatureFlagsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch all feature flags
     * Fetch all feature flags
     */
    fetchAllFeatureFlagsWithResponse = async (requestParameters: FetchAllFeatureFlagsRequest): Promise<{ value: AllFeatureFlags, response: any}> => {
        const { runtime, response } = await this.fetchAllFeatureFlagsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch feature flags
     * Fetch feature flags
     */
    fetchFeatureFlagsRaw = async (): Promise<{ runtime: runtime.ApiResponse<OutputFeatureFlagResultsArraySchema>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/feature_flags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OutputFeatureFlagResultsArraySchemaFromJSON(jsonValue)), response };
    }

    /**
     * Fetch feature flags
     * Fetch feature flags
     */
    fetchFeatureFlags = async (): Promise<OutputFeatureFlagResultsArraySchema> => {
        const { runtime } = await this.fetchFeatureFlagsRaw();
        return await runtime.value();
    }

    /**
     * Fetch feature flags
     * Fetch feature flags
     */
    fetchFeatureFlagsWithResponse = async (): Promise<{ value: OutputFeatureFlagResultsArraySchema, response: any}> => {
        const { runtime, response } = await this.fetchFeatureFlagsRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch feature flags of a user
     * Fetch feature flags of a user
     */
    fetchFeatureFlagsForAUserRaw = async (requestParameters: FetchFeatureFlagsForAUserRequest): Promise<{ runtime: runtime.ApiResponse<OutputFeatureFlagResultsArraySchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchFeatureFlagsForAUser.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/feature_flags/user/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OutputFeatureFlagResultsArraySchemaFromJSON(jsonValue)), response };
    }

    /**
     * Fetch feature flags of a user
     * Fetch feature flags of a user
     */
    fetchFeatureFlagsForAUser = async (requestParameters: FetchFeatureFlagsForAUserRequest): Promise<OutputFeatureFlagResultsArraySchema> => {
        const { runtime } = await this.fetchFeatureFlagsForAUserRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch feature flags of a user
     * Fetch feature flags of a user
     */
    fetchFeatureFlagsForAUserWithResponse = async (requestParameters: FetchFeatureFlagsForAUserRequest): Promise<{ value: OutputFeatureFlagResultsArraySchema, response: any}> => {
        const { runtime, response } = await this.fetchFeatureFlagsForAUserRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch global enabled feature flags
     * Fetch global feature flags
     */
    fetchGlobalFeatureFlagsRaw = async (): Promise<{ runtime: runtime.ApiResponse<OutputFeatureFlagResultsArraySchema>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/global_feature_flags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OutputFeatureFlagResultsArraySchemaFromJSON(jsonValue)), response };
    }

    /**
     * Fetch global enabled feature flags
     * Fetch global feature flags
     */
    fetchGlobalFeatureFlags = async (): Promise<OutputFeatureFlagResultsArraySchema> => {
        const { runtime } = await this.fetchGlobalFeatureFlagsRaw();
        return await runtime.value();
    }

    /**
     * Fetch global enabled feature flags
     * Fetch global feature flags
     */
    fetchGlobalFeatureFlagsWithResponse = async (): Promise<{ value: OutputFeatureFlagResultsArraySchema, response: any}> => {
        const { runtime, response } = await this.fetchGlobalFeatureFlagsRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update enabled attribute for feature flag
     * Update enabled attribute for feature flag
     */
    setFeatureFlagRaw = async (requestParameters: SetFeatureFlagRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/feature_flags`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SetFeatureFlagInputToJSON(requestParameters.setFeatureFlagInput),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Update enabled attribute for feature flag
     * Update enabled attribute for feature flag
     */
    setFeatureFlag = async (requestParameters: SetFeatureFlagRequest): Promise<void> => {
        const { runtime } = await this.setFeatureFlagRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update enabled attribute for feature flag
     * Update enabled attribute for feature flag
     */
    setFeatureFlagWithResponse = async (requestParameters: SetFeatureFlagRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.setFeatureFlagRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

}

export const fetchFeatureFlagsApi: IFeatureFlagsApi  = new FeatureFlagsApi();
