import { useInfiniteQuery, useQuery } from "react-query";
import { useEffect } from "react";
import reduce from "lodash/reduce";
import { ListMembersRequest, fetchMemberSearchResultsApi } from "shared/fetch/src/apis/MemberSearchResultsApi";
import {
  FetchPatientQuickListForStaffUserRequest,
  fetchAccountsApi,
} from "shared/fetch/src/apis/AccountsApi";

export const patientQuickListForStaffUserQueryKey = (id: string | number) => [
  "patientQuickListForStaffUser",
  id.toString(),
];

export const memberSearchResultsQueryKey = (request: ListMembersRequest) => [
  "memberSearchResults",
  request,
];

let fetching = false;

export default (request: ListMembersRequest) => {
  const {
    data,
    isFetching,
    fetchNextPage: fetchNextPageOriginal,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    memberSearchResultsQueryKey(request),
    ({ pageParam = 1 }) => {
      return fetchMemberSearchResultsApi.listMembers({
        ...request,
        page: pageParam,
      });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.page === lastPage.last) {
          return false;
        }

        return (lastPage?.page || 0) + 1;
      },
      keepPreviousData: true,
    }
  );

  const fetchNextPage = () => {
    if (!fetching && hasNextPage) {
      fetching = true;
      fetchNextPageOriginal();
    }
  };

  const memberSearchResults = reduce(
    data?.pages,
    (acc: any, { results: fetchedMemberSearchResults = [] }) => {
      return [...acc, ...fetchedMemberSearchResults];
    },
    []
  );

  useEffect(() => {
    fetching = false;
  }, [memberSearchResults.length]);

  const count = data?.pages?.[0]?.count;

  return {
    memberSearchResults,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    count,
  };
};

export const usePatientQuickListForStaffUser = (
  { id }: FetchPatientQuickListForStaffUserRequest,
  enabled: boolean
) => {
  const query = useQuery(
    patientQuickListForStaffUserQueryKey(id),
    () =>
      fetchAccountsApi.fetchPatientQuickListForStaffUser({
        id,
      }),
    {
      enabled: !!id && enabled,
    }
  );

  return {
    ...query,
  };
};
