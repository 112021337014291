import React from "react";
import classes from "../index.module.css";
import CssTypography from "components/CssTypography";
import { OutputMemberSearchSchemaPropsPrimaryAccountHolderAddress } from "shared/fetch/src/models/OutputMemberSearchSchemaPropsPrimaryAccountHolderAddress";

interface IProps {
  primaryAccountHolderAddress:
    | OutputMemberSearchSchemaPropsPrimaryAccountHolderAddress
    | false;
}

const PrimaryAccountHolderAddressSection: React.FC<IProps> = ({
  primaryAccountHolderAddress,
}) => {
  return (
    <>
      {primaryAccountHolderAddress && (
        <div
          className={classes.address}
          data-testid={"primary-account-address"}
        >
          <CssTypography appearance="bodyEmphasized">
            Home address
          </CssTypography>
          <CssTypography
            appearance="smallBody"
            className={classes.addressCaption}
          >
            This is the primary account holder's address. Member addresses can
            be updated in Account & Settings.
          </CssTypography>
          {primaryAccountHolderAddress && (
            <CssTypography appearance="body" className={classes.addressText}>
              {primaryAccountHolderAddress.primaryAccountHolderStreet}{" "}
              {primaryAccountHolderAddress.primaryAccountHolderCity},{" "}
              {primaryAccountHolderAddress.primaryAccountHolderState}{" "}
              {primaryAccountHolderAddress.primaryAccountHolderZip}
            </CssTypography>
          )}
        </div>
      )}
    </>
  );
};

export default PrimaryAccountHolderAddressSection;
