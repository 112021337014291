import React, { FunctionComponent } from "react";
import { Hidden } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import Box from "components/Box";
import Typography from "components/Typography";
import Avatar from "components/Avatar";

interface IProps {
  title: string;
  label: string;
  description?: string[];
  alt: string;
  src: string;
}

const useStyles = makeStyles(({ spacing, typography, palette }) => ({
  root: {
    marginBottom: spacing(0.5),
    display: "flex",
  },
  customAvatar: {
    width: 20,
    height: 20,
    fontSize: typography.caption.fontSize,
    color: palette.white,
  },
}));

const InformationLine: FunctionComponent<IProps> = ({
  title,
  label,
  alt,
  description,
  src,
}) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography
        color="textSecondary"
        appearance="smallBody"
        component="dt"
        style={{ marginRight: "4px" }}
      >
        {title}:
      </Typography>
      <Box component="dd" display="flex">
        <Hidden smDown>
          <Avatar
            className={classes.customAvatar}
            alt={alt}
            src={src}
            containerStyle={{
              marginLeft: 0,
              marginRight: 4,
            }}
            isPresentational
          />
        </Hidden>
        <Typography appearance="smallBody" component="span" color="textPrimary">
          {label}{" "}
          {description?.length && (
            <Typography
              appearance="smallBody"
              color="textSecondary"
              component="span"
            >
              | {description.join(" ")}
            </Typography>
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default InformationLine;
