// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RevokeProxyRequestBody
 */
export interface RevokeProxyRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof RevokeProxyRequestBody
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof RevokeProxyRequestBody
     */
    consentedDate: string;
    /**
     * 
     * @type {string}
     * @memberof RevokeProxyRequestBody
     */
    consentType: RevokeProxyRequestBodyConsentTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RevokeProxyRequestBody
     */
    consentRequired: boolean;
    /**
     * 
     * @type {string}
     * @memberof RevokeProxyRequestBody
     */
    email?: string;
}

export function RevokeProxyRequestBodyFromJSON(json: any): RevokeProxyRequestBody {
    return {
        'documentId': !exists(json, 'document_id') ? undefined : json['document_id'],
        'consentedDate': json['consented_date'],
        'consentType': json['consent_type'],
        'consentRequired': json['consent_required'],
        'email': !exists(json, 'email') ? undefined : json['email'],
    };
}

export function RevokeProxyRequestBodyToJSON(value?: RevokeProxyRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'document_id': value.documentId,
        'consented_date': value.consentedDate,
        'consent_type': value.consentType,
        'consent_required': value.consentRequired,
        'email': value.email,
    };
}

/**
* @export
* @enum {string}
*/
export enum RevokeProxyRequestBodyConsentTypeEnum {
    Attachment = 'attachment',
    Verbal = 'verbal'
}


