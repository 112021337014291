// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionDetails,
    QuestionDetailsFromJSON,
    QuestionDetailsToJSON,
} from './QuestionDetails';


/**
 * 
 * @export
 * @interface QuestionSetDetailsSchema
 */
export interface QuestionSetDetailsSchema  {
    /**
     * 
     * @type {number}
     * @memberof QuestionSetDetailsSchema
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionSetDetailsSchema
     */
    sqsId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionSetDetailsSchema
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionSetDetailsSchema
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionSetDetailsSchema
     */
    displayLabel: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionSetDetailsSchema
     */
    forBoth: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionSetDetailsSchema
     */
    female: number;
    /**
     * 
     * @type {number}
     * @memberof QuestionSetDetailsSchema
     */
    male: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionSetDetailsSchema
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionSetDetailsSchema
     */
    total: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionSetDetailsSchema
     */
    version?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionSetDetailsSchema
     */
    serviceLines: Array<string>;
    /**
     * 
     * @type {Array<QuestionDetails>}
     * @memberof QuestionSetDetailsSchema
     */
    questions: Array<QuestionDetails>;
}

export function QuestionSetDetailsSchemaFromJSON(json: any): QuestionSetDetailsSchema {
    return {
        'id': json['id'],
        'sqsId': json['sqs_id'],
        'category': json['category'],
        'title': json['title'],
        'displayLabel': json['display_label'],
        'forBoth': json['for_both'],
        'female': json['female'],
        'male': json['male'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'total': json['total'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'serviceLines': json['service_lines'],
        'questions': (json['questions'] as Array<any>).map(QuestionDetailsFromJSON),
    };
}

export function QuestionSetDetailsSchemaToJSON(value?: QuestionSetDetailsSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'sqs_id': value.sqsId,
        'category': value.category,
        'title': value.title,
        'display_label': value.displayLabel,
        'for_both': value.forBoth,
        'female': value.female,
        'male': value.male,
        'type': value.type,
        'total': value.total,
        'version': value.version,
        'service_lines': value.serviceLines,
        'questions': (value.questions as Array<any>).map(QuestionDetailsToJSON),
    };
}


