// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberPaymentHistory
 */
export interface MemberPaymentHistory  {
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentHistory
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentHistory
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentHistory
     */
    item?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentHistory
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentHistory
     */
    dateOfService?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentHistory
     */
    visitType?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentHistory
     */
    provider?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentHistory
     */
    clinicName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentHistory
     */
    amount?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentHistory
     */
    receiptNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentHistory
     */
    chargeDate?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentHistory
     */
    cycleBeginDate?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentHistory
     */
    cycleEndDate?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberPaymentHistory
     */
    subscriptionCharge?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentHistory
     */
    membershipPeriod?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentHistory
     */
    planName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentHistory
     */
    chargeType?: string;
}

export function MemberPaymentHistoryFromJSON(json: any): MemberPaymentHistory {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'item': !exists(json, 'item') ? undefined : json['item'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'dateOfService': !exists(json, 'date_of_service') ? undefined : json['date_of_service'],
        'visitType': !exists(json, 'visit_type') ? undefined : json['visit_type'],
        'provider': !exists(json, 'provider') ? undefined : json['provider'],
        'clinicName': !exists(json, 'clinic_name') ? undefined : json['clinic_name'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'receiptNumber': !exists(json, 'receipt_number') ? undefined : json['receipt_number'],
        'chargeDate': !exists(json, 'charge_date') ? undefined : json['charge_date'],
        'cycleBeginDate': !exists(json, 'cycle_begin_date') ? undefined : json['cycle_begin_date'],
        'cycleEndDate': !exists(json, 'cycle_end_date') ? undefined : json['cycle_end_date'],
        'subscriptionCharge': !exists(json, 'subscription_charge') ? undefined : json['subscription_charge'],
        'membershipPeriod': !exists(json, 'membership_period') ? undefined : json['membership_period'],
        'planName': !exists(json, 'plan_name') ? undefined : json['plan_name'],
        'chargeType': !exists(json, 'charge_type') ? undefined : json['charge_type'],
    };
}

export function MemberPaymentHistoryToJSON(value?: MemberPaymentHistory): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'description': value.description,
        'item': value.item,
        'status': value.status,
        'date_of_service': value.dateOfService,
        'visit_type': value.visitType,
        'provider': value.provider,
        'clinic_name': value.clinicName,
        'amount': value.amount,
        'receipt_number': value.receiptNumber,
        'charge_date': value.chargeDate,
        'cycle_begin_date': value.cycleBeginDate,
        'cycle_end_date': value.cycleEndDate,
        'subscription_charge': value.subscriptionCharge,
        'membership_period': value.membershipPeriod,
        'plan_name': value.planName,
        'charge_type': value.chargeType,
    };
}


