// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Dependent
 */
export interface Dependent  {
    /**
     * This is the eligibility id and is not shown when the member is registered
     * @type {number}
     * @memberof Dependent
     */
    id?: number;
    /**
     * First name of the dependent
     * @type {string}
     * @memberof Dependent
     */
    firstName?: string;
    /**
     * Last name of the dependent
     * @type {string}
     * @memberof Dependent
     */
    lastName?: string;
    /**
     * First, Last and Preferred name of the dependent
     * @type {string}
     * @memberof Dependent
     */
    legalAndPreferredName?: string;
    /**
     * 
     * @type {number}
     * @memberof Dependent
     */
    efId?: number;
    /**
     * 
     * @type {string}
     * @memberof Dependent
     */
    employeeId?: string;
    /**
     * 
     * @type {string}
     * @memberof Dependent
     */
    dob?: string;
    /**
     * Status of the dependent, pending, unregistered, unlinked (limbo), or registered
     * @type {string}
     * @memberof Dependent
     */
    registrationStatus?: DependentRegistrationStatusEnum;
    /**
     * nil by default unless a minor
     * @type {boolean}
     * @memberof Dependent
     */
    minor?: boolean;
    /**
     * \'member\' if the minor will soon be 18, else \'aoc\' if the minor will soon hit age of consent, else nil. Should not be present on non-minor dependents.
     * @type {string}
     * @memberof Dependent
     */
    agingOut?: string;
    /**
     * if the dependent has registered, their email address, otherwise nil
     * @type {string}
     * @memberof Dependent
     */
    email?: string;
    /**
     * if the dependent has registered, their user_id, otherwise nil
     * @type {number}
     * @memberof Dependent
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof Dependent
     */
    preferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof Dependent
     */
    genderIdentity?: string;
    /**
     * 
     * @type {string}
     * @memberof Dependent
     */
    sexualOrientation?: string;
    /**
     * 
     * @type {string}
     * @memberof Dependent
     */
    pronouns?: string;
    /**
     * 
     * @type {string}
     * @memberof Dependent
     */
    sexAtBirth?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Dependent
     */
    hearing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Dependent
     */
    vision?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Dependent
     */
    race?: string;
    /**
     * 
     * @type {string}
     * @memberof Dependent
     */
    ethnicity?: string;
    /**
     * 
     * @type {string}
     * @memberof Dependent
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Dependent
     */
    ageOfConsent?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Dependent
     */
    metAgeOfConsent?: boolean;
}

export function DependentFromJSON(json: any): Dependent {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'legalAndPreferredName': !exists(json, 'legal_and_preferred_name') ? undefined : json['legal_and_preferred_name'],
        'efId': !exists(json, 'ef_id') ? undefined : json['ef_id'],
        'employeeId': !exists(json, 'employee_id') ? undefined : json['employee_id'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'registrationStatus': !exists(json, 'registration_status') ? undefined : json['registration_status'],
        'minor': !exists(json, 'minor') ? undefined : json['minor'],
        'agingOut': !exists(json, 'aging_out') ? undefined : json['aging_out'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'preferredName': !exists(json, 'preferred_name') ? undefined : json['preferred_name'],
        'genderIdentity': !exists(json, 'gender_identity') ? undefined : json['gender_identity'],
        'sexualOrientation': !exists(json, 'sexual_orientation') ? undefined : json['sexual_orientation'],
        'pronouns': !exists(json, 'pronouns') ? undefined : json['pronouns'],
        'sexAtBirth': !exists(json, 'sex_at_birth') ? undefined : json['sex_at_birth'],
        'hearing': !exists(json, 'hearing') ? undefined : json['hearing'],
        'vision': !exists(json, 'vision') ? undefined : json['vision'],
        'race': !exists(json, 'race') ? undefined : json['race'],
        'ethnicity': !exists(json, 'ethnicity') ? undefined : json['ethnicity'],
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'ageOfConsent': !exists(json, 'age_of_consent') ? undefined : json['age_of_consent'],
        'metAgeOfConsent': !exists(json, 'met_age_of_consent') ? undefined : json['met_age_of_consent'],
    };
}

export function DependentToJSON(value?: Dependent): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'legal_and_preferred_name': value.legalAndPreferredName,
        'ef_id': value.efId,
        'employee_id': value.employeeId,
        'dob': value.dob,
        'registration_status': value.registrationStatus,
        'minor': value.minor,
        'aging_out': value.agingOut,
        'email': value.email,
        'user_id': value.userId,
        'preferred_name': value.preferredName,
        'gender_identity': value.genderIdentity,
        'sexual_orientation': value.sexualOrientation,
        'pronouns': value.pronouns,
        'sex_at_birth': value.sexAtBirth,
        'hearing': value.hearing,
        'vision': value.vision,
        'race': value.race,
        'ethnicity': value.ethnicity,
        'avatar_url': value.avatarUrl,
        'age_of_consent': value.ageOfConsent,
        'met_age_of_consent': value.metAgeOfConsent,
    };
}

/**
* @export
* @enum {string}
*/
export enum DependentRegistrationStatusEnum {
    Pending = 'pending',
    Unregistered = 'unregistered',
    Registered = 'registered',
    Unlinked = 'unlinked'
}


