import { useMutation, useQueryClient } from "react-query";
import { episodeQueryKey } from "shared/features/episodes/useFetchEpisode";
import { fetchActionableItemsApi } from "shared/fetch/src/apis/ActionableItemsApi";
import { UpdateCTMAIOwnerSchema } from "shared/fetch/src/models/UpdateCTMAIOwnerSchema";

interface IRequest {
  episodeId: string;
  owner: UpdateCTMAIOwnerSchema;
}

export const useUpdateBulkAiOwnership = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ episodeId, owner }: IRequest) =>
      fetchActionableItemsApi.updateCTMAIOwner({
        episodeId,
        updateEpisodeCTMAIOwnerSchema: owner,
      }),
    {
      onSettled: (data: any) => {
        queryClient.invalidateQueries(
          episodeQueryKey(data.results[0].episodeId)
        );
      },
    }
  );
  return { updateBulkAiOwnership: mutation.mutate };
};
