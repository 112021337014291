// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiscountCoupon,
    DiscountCouponFromJSON,
    DiscountCouponToJSON,
} from './DiscountCoupon';


/**
 * 
 * @export
 * @interface FetchStripeCoupon
 */
export interface FetchStripeCoupon  {
    /**
     * 
     * @type {DiscountCoupon}
     * @memberof FetchStripeCoupon
     */
    results?: DiscountCoupon;
}

export function FetchStripeCouponFromJSON(json: any): FetchStripeCoupon {
    return {
        'results': !exists(json, 'results') ? undefined : DiscountCouponFromJSON(json['results']),
    };
}

export function FetchStripeCouponToJSON(value?: FetchStripeCoupon): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': DiscountCouponToJSON(value.results),
    };
}


