import React from "react";
import Box from "components/Box";
import Container from "components/Container";
import Grid from "components/Grid";

import Skeleton from "./";
import CardSkeleton from "./CardSkeleton";

const PrimaryContent = () => (
  <>
    <Skeleton width="80%" height={60} />
    <Skeleton width="70%" />
    <Skeleton width="70%" />
    <Skeleton width="70%" />
  </>
);
const SecondaryContent = () => (
  <div style={{ paddingTop: 16 }}>
    <Skeleton width="80%" />
    <Skeleton width="70%" />
    <Skeleton width="70%" />
    <Skeleton width="70%" />
  </div>
);

const PageSkeleton = () => (
  <div style={{ width: "100%" }}>
    <div
      style={{
        height: 180,
        width: "100%",
        backgroundColor: "rgba(43, 57, 73, 0.1)",
        position: "absolute",
      }}
    />
    <Container style={{ position: "relative", zIndex: 1 }}>
      <Box pt={3}>
        <CardSkeleton>
          <Skeleton width="50%" height={75} />
          <Skeleton width="80%" />
          <Skeleton width="80%" />
          <Skeleton width="80%" />
        </CardSkeleton>
        <CardSkeleton>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <PrimaryContent />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SecondaryContent />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SecondaryContent />
            </Grid>
          </Grid>
        </CardSkeleton>
        <CardSkeleton>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <PrimaryContent />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SecondaryContent />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SecondaryContent />
            </Grid>
          </Grid>
        </CardSkeleton>
      </Box>
    </Container>
  </div>
);

export default PageSkeleton;
