import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import useGetAccountInfo from "shared/features/memberProfile/useGetAccountInfo";
import useGetMemberSubscription from "shared/features/memberProfile/useGetMemberSubscription";
import { getDaysDifference, isValidDate } from "utils/durationConverter";

const useConsumerController = (userId: string) => {
  const navigate = useNavigate();
  const membershipUrl = "/account/membership";

  const { accountInfo } =
    useGetAccountInfo({
      id: userId,
    }) || {};

  const isConsumerMemberType = accountInfo?.membershipType === "consumer";
  const { memberSubscription, getSubscription } =
    useGetMemberSubscription({
      id: userId,
    }) || {};

  const subscriptionStatus = memberSubscription?.results?.[0]?.status;
  const subscriptionCard =
    memberSubscription?.results?.[0]?.customer?.defaultSource;
  const cardExpiresAt = new Date(
    subscriptionCard?.expYear!,
    subscriptionCard?.expMonth!
  );

  const timeDiffMessage = useMemo(
    () => getDaysDifference(cardExpiresAt),
    [cardExpiresAt]
  );

  const handleNavigation = () => {
    navigate(membershipUrl);
  };

  useEffect(() => {
    if (isConsumerMemberType) {
      getSubscription();
    }
  }, []);

  return {
    timeDiffMessage,
    handleNavigation,
    cardExpiresAt,
    isConsumerMemberType,
    isExpiryDateValid: isValidDate(cardExpiresAt),
    hasAboveThirtyDays: Number(timeDiffMessage.split(" ")[0]) > 30,
    isPastDue: subscriptionStatus === "past_due",
  };
};

export default useConsumerController;
