import React, { FunctionComponent } from "react";
import { LandingPage } from "../../pages/LandingPage";
import { CTMQueueContainer } from "../../pages/CTMQueueContainer";
import Access from "components/Access";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";

const Default: FunctionComponent<{}> = () => {
  return (
    <>
      <Access to={PermissionsEnum.MemberHomepageRead}>
        <div data-testid="default-member-homepage">
          <LandingPage />
        </div>
      </Access>
      <Access to={PermissionsEnum.CtmEpisodesRead}>
        <div data-testid="default-ctm-homepage">
          <CTMQueueContainer />
        </div>
      </Access>
    </>
  );
};

export default Default;
