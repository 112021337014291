// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     AcknowledgeableComponentProps,
    AcknowledgeableComponentPropsFromJSON,
    AcknowledgeableComponentPropsToJSON,
} from './AcknowledgeableComponentProps';
import {
     ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';
import {
     Appointment,
    AppointmentFromJSON,
    AppointmentToJSON,
} from './Appointment';
import {
     CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
     CompactComponentReference,
    CompactComponentReferenceFromJSON,
    CompactComponentReferenceToJSON,
} from './CompactComponentReference';
import {
     ComponentActionDetails,
    ComponentActionDetailsFromJSON,
    ComponentActionDetailsToJSON,
} from './ComponentActionDetails';
import {
     ComponentCore,
    ComponentCoreFromJSON,
    ComponentCoreToJSON,
} from './ComponentCore';
import {
     ComponentDetailsCoreCreatorInfo,
    ComponentDetailsCoreCreatorInfoFromJSON,
    ComponentDetailsCoreCreatorInfoToJSON,
} from './ComponentDetailsCoreCreatorInfo';
import {
     ComponentDetailsCoreResolverInfo,
    ComponentDetailsCoreResolverInfoFromJSON,
    ComponentDetailsCoreResolverInfoToJSON,
} from './ComponentDetailsCoreResolverInfo';
import {
     DocumentOutputSchema,
    DocumentOutputSchemaFromJSON,
    DocumentOutputSchemaToJSON,
} from './DocumentOutputSchema';
import {
     MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';
import {
     SelfScheduleAppointmentComponentOutputProps,
    SelfScheduleAppointmentComponentOutputPropsFromJSON,
    SelfScheduleAppointmentComponentOutputPropsToJSON,
} from './SelfScheduleAppointmentComponentOutputProps';
import {
     UserInfo,
    UserInfoFromJSON,
    UserInfoToJSON,
} from './UserInfo';
import {
     VisitType,
    VisitTypeFromJSON,
    VisitTypeToJSON,
} from './VisitType';
/**
 * @type SelfScheduleAppointmentComponent
 * @export
 */
export interface SelfScheduleAppointmentComponent extends AcknowledgeableComponentProps, ComponentCore, SelfScheduleAppointmentComponentOutputProps {
}

export function SelfScheduleAppointmentComponentFromJSON(json: any): SelfScheduleAppointmentComponent {
    return {
        ...AcknowledgeableComponentPropsFromJSON(json),
        ...ComponentCoreFromJSON(json),
        ...SelfScheduleAppointmentComponentOutputPropsFromJSON(json),
    };
}

export function SelfScheduleAppointmentComponentToJSON(value?: SelfScheduleAppointmentComponent): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...AcknowledgeableComponentPropsToJSON(value),
        ...ComponentCoreToJSON(value),
        ...SelfScheduleAppointmentComponentOutputPropsToJSON(value),
    };
}

