import React, { useMemo } from "react";
import { selectLoggedInUser } from "../../../shared/features/user/selectors";
import useGetAdultDependents from "shared/features/dependents/useGetAdultDependents";
import { JsonUser as User } from "shared/fetch/src/models/JsonUser";;
import { reduce } from "lodash";
import { DependentRegistrationStatusEnum as DepRegStatus } from "shared/fetch/src/models/Dependent";
import { formatDate } from "./useAocLevelUpBanner";

const useMinorLevelUpBanner = () => {
  const user: User = selectLoggedInUser();
  const { adultDependents: dependents, isLoading } = useGetAdultDependents({
    id: user?.id?.toString() || "",
  });
  const minorDetails = useMemo(() => {
    const levelUpMinor = dependents?.results?.filter(
      (dependent) =>
        !!dependent?.minor &&
        !!dependent?.agingOut &&
        dependent?.agingOut === "member" &&
        dependent.registrationStatus === DepRegStatus.Registered
    );

    if (!levelUpMinor?.length) {
      return false;
    }

    const names = reduce(
      levelUpMinor,
      function (result, minor, idx) {
        const nameToUse = !!minor.preferredName
          ? minor.preferredName
          : minor.firstName;
        if (
          !!levelUpMinor?.length &&
          idx === levelUpMinor?.length - 1 &&
          levelUpMinor.length !== 1
        ) {
          result += `and ${nameToUse}'s `;
        } else {
          result += `${nameToUse}'s `;
        }
        return result;
      },
      ""
    );

    return {
      names,
      formattedDate: formatDate(levelUpMinor?.[0]?.dob!),
    };
  }, [isLoading, dependents]);

  if (isLoading || !minorDetails) {
    return {};
  }

  return {
    message: (
      <span data-testid="minor-banner-text">
        Your access to {minorDetails.names} will expire on{" "}
        {minorDetails.formattedDate} due to your minor reaching the age of
        adulthood. You can invite your dependents aged 18 and older to create
        their own account via the dependents tab in Accounts & Settings.
      </span>
    ),
    orderDate: null,
  };
};
export default useMinorLevelUpBanner;
