// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LanguageCodeOutputSchemaResults
 */
export interface LanguageCodeOutputSchemaResults  {
    /**
     * 
     * @type {string}
     * @memberof LanguageCodeOutputSchemaResults
     */
    isoCode?: string;
    /**
     * 
     * @type {string}
     * @memberof LanguageCodeOutputSchemaResults
     */
    language?: string;
}

export function LanguageCodeOutputSchemaResultsFromJSON(json: any): LanguageCodeOutputSchemaResults {
    return {
        'isoCode': !exists(json, 'iso_code') ? undefined : json['iso_code'],
        'language': !exists(json, 'language') ? undefined : json['language'],
    };
}

export function LanguageCodeOutputSchemaResultsToJSON(value?: LanguageCodeOutputSchemaResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'iso_code': value.isoCode,
        'language': value.language,
    };
}


