// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     ActionableItemDetailsCore,
    ActionableItemDetailsCoreFromJSON,
    ActionableItemDetailsCoreToJSON,
} from './ActionableItemDetailsCore';
import {
     ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';
import {
     CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
     UserInfo,
    UserInfoFromJSON,
    UserInfoToJSON,
} from './UserInfo';
/**
 * @type ActionableItemDetails
 * @export
 */
export interface ActionableItemDetails extends ActionableItemDetailsCore, ActionableItemSummary {
}

export function ActionableItemDetailsFromJSON(json: any): ActionableItemDetails {
    return {
        ...ActionableItemDetailsCoreFromJSON(json),
        ...ActionableItemSummaryFromJSON(json),
    };
}

export function ActionableItemDetailsToJSON(value?: ActionableItemDetails): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...ActionableItemDetailsCoreToJSON(value),
        ...ActionableItemSummaryToJSON(value),
    };
}

