import { combineReducers } from "redux";

export const SET_ACTIVE_CALCULATOR = "SET_ACTIVE_CALCULATOR";
export const REMOVE_ACTIVE_CALCULATOR = "REMOVE_ACTIVE_CALCULATOR";

export const initialCalculatorState = () => ({
  calculator: {},
});

const updateCalculatorState =
  ({ state, episodeId }: any) =>
  (update: any) => ({
    ...state,
    [episodeId]: { ...update },
  });

export const calculator = (state = {} as State, action: any) => {
  const episodeId = action.episodeId;
  const updater = updateCalculatorState({
    state,
    episodeId,
  });

  if (!episodeId) {
    return state;
  }

  switch (action.type) {
    case SET_ACTIVE_CALCULATOR:
      return updater(action.payload);

    case REMOVE_ACTIVE_CALCULATOR:
      return updater(null);

    default:
      return state;
  }
};

export const setActiveCalculator = (body: string, episodeId: string) => ({
  type: SET_ACTIVE_CALCULATOR,
  payload: body,
  episodeId,
});

export const removeCalculator = (
  calculatorId: number | string,
  episodeId: string
) => ({
  type: REMOVE_ACTIVE_CALCULATOR,
  payload: calculatorId,
  episodeId,
});

export const reducer = combineReducers({
  calculator,
});

export interface State {
  calculator: {
    [episodeId: string]: any;
  };
}
