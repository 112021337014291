// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OutboundScreeningObjectDetailScreeningTemplate,
    OutboundScreeningObjectDetailScreeningTemplateFromJSON,
    OutboundScreeningObjectDetailScreeningTemplateToJSON,
} from './OutboundScreeningObjectDetailScreeningTemplate';


/**
 * 
 * @export
 * @interface OutboundScreeningObjectDetail
 */
export interface OutboundScreeningObjectDetail  {
    /**
     * 
     * @type {number}
     * @memberof OutboundScreeningObjectDetail
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof OutboundScreeningObjectDetail
     */
    screeningTemplateName?: string;
    /**
     * 
     * @type {string}
     * @memberof OutboundScreeningObjectDetail
     */
    status?: string;
    /**
     * 
     * @type {number}
     * @memberof OutboundScreeningObjectDetail
     */
    patientScreeningId?: number;
    /**
     * 
     * @type {number}
     * @memberof OutboundScreeningObjectDetail
     */
    screeningTemplateId?: number;
    /**
     * 
     * @type {OutboundScreeningObjectDetailScreeningTemplate}
     * @memberof OutboundScreeningObjectDetail
     */
    screeningTemplate?: OutboundScreeningObjectDetailScreeningTemplate;
    /**
     * 
     * @type {Date}
     * @memberof OutboundScreeningObjectDetail
     */
    createdAt?: Date;
}

export function OutboundScreeningObjectDetailFromJSON(json: any): OutboundScreeningObjectDetail {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'screeningTemplateName': !exists(json, 'screening_template_name') ? undefined : json['screening_template_name'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'patientScreeningId': !exists(json, 'patient_screening_id') ? undefined : json['patient_screening_id'],
        'screeningTemplateId': !exists(json, 'screening_template_id') ? undefined : json['screening_template_id'],
        'screeningTemplate': !exists(json, 'screening_template') ? undefined : OutboundScreeningObjectDetailScreeningTemplateFromJSON(json['screening_template']),
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
    };
}

export function OutboundScreeningObjectDetailToJSON(value?: OutboundScreeningObjectDetail): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'screening_template_name': value.screeningTemplateName,
        'status': value.status,
        'patient_screening_id': value.patientScreeningId,
        'screening_template_id': value.screeningTemplateId,
        'screening_template': OutboundScreeningObjectDetailScreeningTemplateToJSON(value.screeningTemplate),
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
    };
}


