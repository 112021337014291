
import { useMutation } from "react-query";
import { AcknowledgeAndRespondToComponentsRequest, fetchComponentsApi } from "shared/fetch/src/apis/ComponentsApi";

const useAcknowledgeAndRespond = () => {
  const mutation = useMutation(
    (requestParameters: AcknowledgeAndRespondToComponentsRequest) => {
      return fetchComponentsApi.acknowledgeAndRespondToComponents(
        requestParameters
      );
    }
  );

  return { acknowledgeAndRespond: mutation.mutate };
};

export default useAcknowledgeAndRespond;
