// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
    CompactComponentReference,
    CompactComponentReferenceFromJSON,
    CompactComponentReferenceToJSON,
} from './CompactComponentReference';
import {
    DocumentOutputSchema,
    DocumentOutputSchemaFromJSON,
    DocumentOutputSchemaToJSON,
} from './DocumentOutputSchema';
import {
    RecipientOptionSchema,
    RecipientOptionSchemaFromJSON,
    RecipientOptionSchemaToJSON,
} from './RecipientOptionSchema';


/**
 * 
 * @export
 * @interface MemberMessageComponentOutputCore
 */
export interface MemberMessageComponentOutputCore  {
    /**
     * 
     * @type {string}
     * @memberof MemberMessageComponentOutputCore
     */
    state: MemberMessageComponentOutputCoreStateEnum;
    /**
     * 
     * @type {Array<CompactComponentReference>}
     * @memberof MemberMessageComponentOutputCore
     */
    parentComponents?: Array<CompactComponentReference>;
    /**
     * 
     * @type {Array<DocumentOutputSchema>}
     * @memberof MemberMessageComponentOutputCore
     */
    documentInfo?: Array<DocumentOutputSchema>;
    /**
     * 
     * @type {Array<CTMInfo>}
     * @memberof MemberMessageComponentOutputCore
     */
    ccCtms?: Array<CTMInfo>;
    /**
     * 
     * @type {RecipientOptionSchema}
     * @memberof MemberMessageComponentOutputCore
     */
    recipient?: RecipientOptionSchema;
}

export function MemberMessageComponentOutputCoreFromJSON(json: any): MemberMessageComponentOutputCore {
    return {
        'state': json['state'],
        'parentComponents': !exists(json, 'parent_components') ? undefined : (json['parent_components'] as Array<any>).map(CompactComponentReferenceFromJSON),
        'documentInfo': !exists(json, 'document_info') ? undefined : (json['document_info'] as Array<any>).map(DocumentOutputSchemaFromJSON),
        'ccCtms': !exists(json, 'cc_ctms') ? undefined : (json['cc_ctms'] as Array<any>).map(CTMInfoFromJSON),
        'recipient': !exists(json, 'recipient') ? undefined : RecipientOptionSchemaFromJSON(json['recipient']),
    };
}

export function MemberMessageComponentOutputCoreToJSON(value?: MemberMessageComponentOutputCore): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'state': value.state,
        'parent_components': value.parentComponents === undefined ? undefined : (value.parentComponents as Array<any>).map(CompactComponentReferenceToJSON),
        'document_info': value.documentInfo === undefined ? undefined : (value.documentInfo as Array<any>).map(DocumentOutputSchemaToJSON),
        'cc_ctms': value.ccCtms === undefined ? undefined : (value.ccCtms as Array<any>).map(CTMInfoToJSON),
        'recipient': RecipientOptionSchemaToJSON(value.recipient),
    };
}

/**
* @export
* @enum {string}
*/
export enum MemberMessageComponentOutputCoreStateEnum {
    Unacknowledged = 'unacknowledged',
    AcknowledgedPendingResponse = 'acknowledged_pending_response',
    Resolved = 'resolved',
    ResolvedWithReply = 'resolved_with_reply'
}


