// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentObjectDetailsAddedBy,
    DocumentObjectDetailsAddedByFromJSON,
    DocumentObjectDetailsAddedByToJSON,
} from './DocumentObjectDetailsAddedBy';


/**
 * 
 * @export
 * @interface DocumentObjectDetails
 */
export interface DocumentObjectDetails  {
    /**
     * 
     * @type {string}
     * @memberof DocumentObjectDetails
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentObjectDetails
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentObjectDetails
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentObjectDetails
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof DocumentObjectDetails
     */
    componentId?: number;
    /**
     * 
     * @type {Date}
     * @memberof DocumentObjectDetails
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof DocumentObjectDetails
     */
    url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentObjectDetails
     */
    unverified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DocumentObjectDetails
     */
    mime?: string;
    /**
     * 
     * @type {DocumentObjectDetailsAddedBy}
     * @memberof DocumentObjectDetails
     */
    addedBy?: DocumentObjectDetailsAddedBy;
    /**
     * 
     * @type {string}
     * @memberof DocumentObjectDetails
     */
    thumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentObjectDetails
     */
    docType?: DocumentObjectDetailsDocTypeEnum;
}

export function DocumentObjectDetailsFromJSON(json: any): DocumentObjectDetails {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'componentId': !exists(json, 'component_id') ? undefined : json['component_id'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'url': !exists(json, 'url') ? undefined : json['url'],
        'unverified': !exists(json, 'unverified') ? undefined : json['unverified'],
        'mime': !exists(json, 'mime') ? undefined : json['mime'],
        'addedBy': !exists(json, 'added_by') ? undefined : DocumentObjectDetailsAddedByFromJSON(json['added_by']),
        'thumbnailUrl': !exists(json, 'thumbnail_url') ? undefined : json['thumbnail_url'],
        'docType': !exists(json, 'doc_type') ? undefined : json['doc_type'],
    };
}

export function DocumentObjectDetailsToJSON(value?: DocumentObjectDetails): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'title': value.title,
        'name': value.name,
        'size': value.size,
        'component_id': value.componentId,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'url': value.url,
        'unverified': value.unverified,
        'mime': value.mime,
        'added_by': DocumentObjectDetailsAddedByToJSON(value.addedBy),
        'thumbnail_url': value.thumbnailUrl,
        'doc_type': value.docType,
    };
}

/**
* @export
* @enum {string}
*/
export enum DocumentObjectDetailsDocTypeEnum {
    Consent = 'consent'
}


