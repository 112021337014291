import makeStyles from "@mui/styles/makeStyles";
import Avatar from "components/Avatar";
import Skeleton from "components/Skeleton";
import Typography from "components/Typography";
import React from "react";
import useGetDailySchedule from "shared/features/dailySchedule/useGetDailySchedule";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import formatDate from "utils/formatDate";
import { selectLoggedInUser } from "shared/features/user/selectors";
import { useSearchParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppointmentRow from "../components/AppointmentRow";
import TempDatePicker from "../components/TempDatePicker";
import Asset from "components/Asset";
import { selectFeatures } from "shared/features/featureFlags/selectors";

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  wrapper: {
    display: "flex",
    height: "100%",
  },
  page: {
    backgroundColor: palette?.white,
    padding: spacing(2),
    width: "100%",
    maxWidth: "902px",
    height: "100%",
    overflowY: "scroll",
  },
  // FOR TEMPORARY LEFT NAV
  leftNav: {
    width: "48px",
    backgroundColor: palette?.appBackground?.darkBlue,
  },
  // FOR TEMPORARY SIDEBAR
  sidebar: {
    width: "253px",
    backgroundColor: palette?.appBackground?.main,
  },
  providerHeaderRow: {
    display: "flex",
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    paddingLeft: spacing(0.5),
    marginTop: spacing(2),
    marginBottom: spacing(0.5),
    alignItems: "center",
    borderRadius: 8,
    backgroundColor: palette?.appBackground?.lightestGray,
  },
  providerName: {
    paddingLeft: spacing(1),
  },
  arrow: {
    paddingLeft: spacing(0.5),
    fill: palette?.text?.secondary,
    height: spacing(3),
    width: spacing(3),
  },
  arrowSmall: {
    fill: palette?.text?.secondary,
    height: spacing(2.5),
    width: spacing(2.5),
  },
  appointmentColumnHeaders: {
    display: "grid",
    gridTemplateColumns: "1.2fr 2.4fr 1.9fr 1.7fr 0.5fr 0.7fr",
    gap: "16px",
    [breakpoints.down("lg")]: {
      gap: "12px",
    },
    paddingTop: spacing(1),
    paddingBottom: spacing(1),
    paddingLeft: spacing(1),
    borderBottom: `1px solid ${palette?.text?.strokes}`,
  },
  timeColumnHeader: {
    paddingLeft: spacing(3.5),
  },
  visitTypeColumnHeader: {
    paddingLeft: spacing(2),
  },
  emptyStateContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    alignItems: "center",
  },
  emptyStateText: {
    paddingTop: spacing(4),
  },
  noAppointmentsImage: {
    width: "275px",
  },
}));

interface IProviderHeaderRow {
  name: string | undefined;
  avatarUrl?: string | undefined;
}

const ProviderHeaderRow: React.FC<IProviderHeaderRow> = ({
  name,
  avatarUrl,
}) => {
  const classes = useStyles();
  return (
    <div
      data-testid="ds-provider-header-row"
      className={classes.providerHeaderRow}
    >
      <KeyboardArrowUpIcon className={classes.arrow} />
      <Avatar size="forty" alt={name} src={avatarUrl} />
      <Typography
        appearance="body"
        color="textPrimary"
        className={classes.providerName}
      >
        {name}
      </Typography>
    </div>
  );
};

const AppointmentColumnHeaders: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.appointmentColumnHeaders}>
      <div>
        <Typography
          className={classes.timeColumnHeader}
          appearance="miniCaption"
          color="textSecondary"
        >
          TIME
        </Typography>
      </div>
      <Typography appearance="miniCaption" color="textSecondary">
        MEMBER
      </Typography>
      <Typography appearance="miniCaption" color="textSecondary">
        VISIT TYPE
      </Typography>
      <Typography appearance="miniCaption" color="textSecondary">
        PAYMENT & INSURANCE
      </Typography>
    </div>
  );
};

const NoAppointments: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.emptyStateContainer}>
      <Typography
        className={classes.emptyStateText}
        color="textPrimary"
        appearance="body"
      >
        There are no appointments for this date.
      </Typography>
      <Asset
        className={classes.noAppointmentsImage}
        name="no-appointments-with-background"
      />
    </div>
  );
};

const DailyScheduleContainer: React.FC = () => {
  const classes = useStyles();
  const user = selectLoggedInUser();
  const features = selectFeatures();
  const defaultClinic = user?.defaultSite;
  const scheduleDate = formatDate(new Date(), "yyyyMMdd") as string;
  const [searchParams, setSearchParams] = useSearchParams();
  const isDesktopView = useMediaQuery("@media (min-width: 992px)");

  // set up default search params
  React.useEffect(() => {
    if (!searchParams?.get("date")) {
      searchParams.set("date", scheduleDate);
    }
    if (!searchParams?.get("site")) {
      //@ts-ignore
      searchParams.set("site", defaultClinic);
    }
    setSearchParams(searchParams);
  }, []);

  const { schedule, isLoading } = useGetDailySchedule({
    //@ts-ignore
    site: searchParams.get("site") || defaultClinic,
    date: searchParams.get("date") || scheduleDate,
  });

  const handleDateChange = (selectedDate: string) => {
    searchParams.set("date", selectedDate);
    setSearchParams(searchParams);
  };

  if (!features.hasDailyScheduleXop()) {
    return null;
  }

  if (isLoading) {
    return <Skeleton />;
  }
  return (
    <div className={classes.wrapper}>
      {/* TEMPORARY LEFT NAV PLACEHOLDER */}
      <div className={classes.leftNav} />
      {/* TEMPORARY SIDEBAR PLACEHOLDER, ONLY VISIBLE BY DEFAULT IN DESKTOP VIEW */}
      {isDesktopView && <div className={classes.sidebar} />}
      <div data-testid="ds-container" className={classes.page}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingBottom: "16px",
          }}
        >
          {/* TEMPORARY DATE PICKER FOR TESTING */}
          <TempDatePicker
            date={searchParams.get("date") || scheduleDate}
            handleChange={handleDateChange}
          />
        </div>
        {schedule && schedule?.length > 0 ? (
          <div data-testid="ds-content">
            {schedule?.map((provider) => (
              <>
                <ProviderHeaderRow
                  name={provider?.name}
                  avatarUrl={provider?.avatarUrl}
                  key={`provider-${provider?.id}`}
                />
                {provider?.appointments &&
                  provider?.appointments?.length > 0 && (
                    <AppointmentColumnHeaders />
                  )}
                {provider?.appointments?.map((appointment, i) => (
                  <AppointmentRow
                    appointment={appointment}
                    isLastRow={
                      !!provider?.appointments?.length &&
                      i === provider?.appointments?.length - 1
                    }
                    key={`appointment-${appointment?.id}`}
                  />
                ))}
              </>
            ))}
          </div>
        ) : (
          <NoAppointments />
        )}
      </div>
    </div>
  );
};

export default DailyScheduleContainer;
