import { useSelector } from "react-redux";
import { PermissionsEnum } from "shared/api/src/models/PermissionsEnum";
import { getUser } from "shared/features/user";

export type AccessToType = PermissionsEnum;

const checkAccess = (permissions: PermissionsEnum[], to: AccessToType) => {
  return permissions.indexOf(to) !== -1;
};

export const useCheckAccess = ({
  to,
  memberPermissionsToCheck,
}: {
  to: PermissionsEnum[];
  memberPermissionsToCheck: PermissionsEnum[];
}) =>
  to.filter((perm) => {
    return useAccess({ to: perm, memberPermissionsToCheck });
  }).length > 0;

export const useGetCurrentUserPermissions = () => {
  const { permissions = [] } = useSelector(getUser) || {};
  return permissions;
};

interface UseAccessProps {
  to: PermissionsEnum;
  memberOnly?: boolean;
  ctmOnly?: boolean;
  memberPermissionsToCheck?: PermissionsEnum[];
}

const useAccess = ({
  to,
  memberPermissionsToCheck,
  memberOnly = false,
  ctmOnly = false,
}: UseAccessProps): boolean => {
  const { permissions = [], ctm } = useSelector(getUser) || {};
  const hasAccessWithExtraPermissions =
    // @ts-ignore: Object is possibly 'null'.
    memberPermissionsToCheck?.length > 0
      ? // @ts-ignore: Object is possibly 'null'.
        checkAccess(memberPermissionsToCheck, to)
      : true;
  const hasAccess =
    checkAccess(permissions, to) && hasAccessWithExtraPermissions;
  return (
    hasAccess &&
    (!memberOnly || (memberOnly && !ctm)) &&
    (!ctmOnly || (ctmOnly && !!ctm))
  );
};

export default useAccess;
