// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
    EpisodeSummaryCoreOutputMarketingMessage,
    EpisodeSummaryCoreOutputMarketingMessageFromJSON,
    EpisodeSummaryCoreOutputMarketingMessageToJSON,
} from './EpisodeSummaryCoreOutputMarketingMessage';
import {
    MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';
import {
    RoleOutputCoreSchema,
    RoleOutputCoreSchemaFromJSON,
    RoleOutputCoreSchemaToJSON,
} from './RoleOutputCoreSchema';


/**
 * 
 * @export
 * @interface EpisodeSummaryCoreOutput
 */
export interface EpisodeSummaryCoreOutput  {
    /**
     * 
     * @type {string}
     * @memberof EpisodeSummaryCoreOutput
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof EpisodeSummaryCoreOutput
     */
    state: EpisodeSummaryCoreOutputStateEnum;
    /**
     * 
     * @type {string}
     * @memberof EpisodeSummaryCoreOutput
     */
    category: EpisodeSummaryCoreOutputCategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof EpisodeSummaryCoreOutput
     */
    serviceLine: string;
    /**
     * 
     * @type {string}
     * @memberof EpisodeSummaryCoreOutput
     */
    serviceSubtype: string;
    /**
     * 
     * @type {MemberInfo}
     * @memberof EpisodeSummaryCoreOutput
     */
    memberInfo: MemberInfo;
    /**
     * 
     * @type {Date}
     * @memberof EpisodeSummaryCoreOutput
     */
    addedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EpisodeSummaryCoreOutput
     */
    archivedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EpisodeSummaryCoreOutput
     */
    needsActionSince?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof EpisodeSummaryCoreOutput
     */
    consultation?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EpisodeSummaryCoreOutput
     */
    consultationSystemGenerated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EpisodeSummaryCoreOutput
     */
    resourceUrl: string;
    /**
     * 
     * @type {number}
     * @memberof EpisodeSummaryCoreOutput
     */
    totalMessages?: number;
    /**
     * 
     * @type {EpisodeSummaryCoreOutputMarketingMessage}
     * @memberof EpisodeSummaryCoreOutput
     */
    marketingMessage?: EpisodeSummaryCoreOutputMarketingMessage;
    /**
     * 
     * @type {boolean}
     * @memberof EpisodeSummaryCoreOutput
     */
    timeSensitive?: boolean;
    /**
     * 
     * @type {CTMInfo}
     * @memberof EpisodeSummaryCoreOutput
     */
    owner?: CTMInfo;
    /**
     * 
     * @type {Array<RoleOutputCoreSchema>}
     * @memberof EpisodeSummaryCoreOutput
     */
    roles?: Array<RoleOutputCoreSchema>;
    /**
     * 
     * @type {Array<string>}
     * @memberof EpisodeSummaryCoreOutput
     */
    badges?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof EpisodeSummaryCoreOutput
     */
    activeCtmAiCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EpisodeSummaryCoreOutput
     */
    ctmAiOwnershipBulkAssignable?: boolean;
    /**
     * 
     * @type {number}
     * @memberof EpisodeSummaryCoreOutput
     */
    xopPodId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof EpisodeSummaryCoreOutput
     */
    cctAssigned?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EpisodeSummaryCoreOutput
     */
    subject?: EpisodeSummaryCoreOutputSubjectEnum;
    /**
     * 
     * @type {string}
     * @memberof EpisodeSummaryCoreOutput
     */
    subjectFreetext?: string;
    /**
     * 
     * @type {string}
     * @memberof EpisodeSummaryCoreOutput
     */
    initialRecipientType?: EpisodeSummaryCoreOutputInitialRecipientTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof EpisodeSummaryCoreOutput
     */
    isVisit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EpisodeSummaryCoreOutput
     */
    virtualUrgentCare?: boolean;
}

export function EpisodeSummaryCoreOutputFromJSON(json: any): EpisodeSummaryCoreOutput {
    return {
        'id': json['id'],
        'state': json['state'],
        'category': json['category'],
        'serviceLine': json['service_line'],
        'serviceSubtype': json['service_subtype'],
        'memberInfo': MemberInfoFromJSON(json['member_info']),
        'addedAt': !exists(json, 'added_at') ? undefined : new Date(json['added_at']),
        'archivedAt': !exists(json, 'archived_at') ? undefined : new Date(json['archived_at']),
        'needsActionSince': !exists(json, 'needs_action_since') ? undefined : new Date(json['needs_action_since']),
        'consultation': !exists(json, 'consultation') ? undefined : json['consultation'],
        'consultationSystemGenerated': !exists(json, 'consultation_system_generated') ? undefined : json['consultation_system_generated'],
        'resourceUrl': json['resource_url'],
        'totalMessages': !exists(json, 'total_messages') ? undefined : json['total_messages'],
        'marketingMessage': !exists(json, 'marketing_message') ? undefined : EpisodeSummaryCoreOutputMarketingMessageFromJSON(json['marketing_message']),
        'timeSensitive': !exists(json, 'time_sensitive') ? undefined : json['time_sensitive'],
        'owner': !exists(json, 'owner') ? undefined : CTMInfoFromJSON(json['owner']),
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] as Array<any>).map(RoleOutputCoreSchemaFromJSON),
        'badges': !exists(json, 'badges') ? undefined : json['badges'],
        'activeCtmAiCount': !exists(json, 'active_ctm_ai_count') ? undefined : json['active_ctm_ai_count'],
        'ctmAiOwnershipBulkAssignable': !exists(json, 'ctm_ai_ownership_bulk_assignable') ? undefined : json['ctm_ai_ownership_bulk_assignable'],
        'xopPodId': !exists(json, 'xop_pod_id') ? undefined : json['xop_pod_id'],
        'cctAssigned': !exists(json, 'cct_assigned') ? undefined : json['cct_assigned'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'subjectFreetext': !exists(json, 'subject_freetext') ? undefined : json['subject_freetext'],
        'initialRecipientType': !exists(json, 'initial_recipient_type') ? undefined : json['initial_recipient_type'],
        'isVisit': !exists(json, 'is_visit') ? undefined : json['is_visit'],
        'virtualUrgentCare': !exists(json, 'virtual_urgent_care') ? undefined : json['virtual_urgent_care'],
    };
}

export function EpisodeSummaryCoreOutputToJSON(value?: EpisodeSummaryCoreOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'state': value.state,
        'category': value.category,
        'service_line': value.serviceLine,
        'service_subtype': value.serviceSubtype,
        'member_info': MemberInfoToJSON(value.memberInfo),
        'added_at': value.addedAt === undefined ? undefined : value.addedAt.toISOString(),
        'archived_at': value.archivedAt === undefined ? undefined : value.archivedAt.toISOString(),
        'needs_action_since': value.needsActionSince === undefined ? undefined : value.needsActionSince.toISOString(),
        'consultation': value.consultation,
        'consultation_system_generated': value.consultationSystemGenerated,
        'resource_url': value.resourceUrl,
        'total_messages': value.totalMessages,
        'marketing_message': EpisodeSummaryCoreOutputMarketingMessageToJSON(value.marketingMessage),
        'time_sensitive': value.timeSensitive,
        'owner': CTMInfoToJSON(value.owner),
        'roles': value.roles === undefined ? undefined : (value.roles as Array<any>).map(RoleOutputCoreSchemaToJSON),
        'badges': value.badges,
        'active_ctm_ai_count': value.activeCtmAiCount,
        'ctm_ai_ownership_bulk_assignable': value.ctmAiOwnershipBulkAssignable,
        'xop_pod_id': value.xopPodId,
        'cct_assigned': value.cctAssigned,
        'subject': value.subject,
        'subject_freetext': value.subjectFreetext,
        'initial_recipient_type': value.initialRecipientType,
        'is_visit': value.isVisit,
        'virtual_urgent_care': value.virtualUrgentCare,
    };
}

/**
* @export
* @enum {string}
*/
export enum EpisodeSummaryCoreOutputStateEnum {
    Unstarted = 'unstarted',
    Draft = 'draft',
    Active = 'active',
    Archived = 'archived',
    Deleted = 'deleted',
    Suspended = 'suspended'
}
/**
* @export
* @enum {string}
*/
export enum EpisodeSummaryCoreOutputCategoryEnum {
    Clinical = 'clinical',
    Preventive = 'preventive',
    Messaging = 'messaging'
}
/**
* @export
* @enum {string}
*/
export enum EpisodeSummaryCoreOutputSubjectEnum {
    MedicationQuestion = 'medication_question',
    SchedulingQuestion = 'scheduling_question',
    VisitFollowUp = 'visit_follow_up',
    TestResults = 'test_results',
    Referral = 'referral',
    Imaging = 'imaging',
    Immunizations = 'immunizations',
    MedicalRecords = 'medical_records',
    CovidConcerns = 'covid_concerns',
    VisionServices = 'vision_services',
    SomethingElse = 'something_else',
    PrescriptionRenewal = 'prescription_renewal',
    PaymentQuestions = 'payment_questions',
    HealthMaintenanceReminder = 'health_maintenance_reminder',
    Outreach = 'outreach'
}
/**
* @export
* @enum {string}
*/
export enum EpisodeSummaryCoreOutputInitialRecipientTypeEnum {
    Ctm = 'ctm',
    CareTeam = 'care_team'
}


