import palette from "../palette";

export default {
  root: {
    borderRadius: 4,
  },
  standardSuccess: {
    backgroundColor: palette.success.main,
    color: palette.text.primary,
  },
  standardError: {
    backgroundColor: palette.danger.main,
    color: palette.text.primary,
  },
  standardWarning: {
    backgroundColor: "#FFE0B2",
    color: palette.text.primary,
  },
  standardInfo: {
    backgroundColor: palette.lightBackground.main,
    color: palette.text.primary,
  },
};
