import React from "react";
import Typography from "components/Typography";
import Grid from "components/Grid";
import Popover from "components/Popover";
import { SelfScheduleAppointmentComponent } from "shared/fetch/src/models/SelfScheduleAppointmentComponent";
import makeStyles from '@mui/styles/makeStyles';
import BuildingIcon from "@mui/icons-material/Apartment";
import VideocamIcon from "@mui/icons-material/Videocam";

interface IProps {
  visit?: SelfScheduleAppointmentComponent;
  onMouseLeave: () => void;
  anchorEl: HTMLElement | null;
}

const useStyles = makeStyles(({ spacing, palette }) => ({
  icon: {
    marginRight: spacing(1),
  },
  firstRow: {
    marginTop: spacing(1),
    marginRight: spacing(1),
    marginLeft: spacing(1),
  },
  secondRow: {
    marginBottom: spacing(1),
    marginRight: spacing(1),
    marginLeft: spacing(1),
  },
  popover: {
    pointerEvents: "none",
  },
  popoverBackground: {
    backgroundColor: palette.text.secondary,
    color: palette.white,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const EOCVisitDetailsPopover = ({ visit, onMouseLeave, anchorEl }: IProps) => {
  const provider = visit?.provider;
  const visitType = visit?.visitType;
  const isVirtualVisit = visitType?.method === "video";
  const visitTypeText = isVirtualVisit ? "Virtual" : "In Person";
  const open = Boolean(anchorEl);

  const classes = useStyles();

  const VisitTypeIcon = () => {
    return isVirtualVisit ? (
      <VideocamIcon className={classes.icon} fontSize="small" />
    ) : (
      <BuildingIcon fontSize="small" className={classes.icon} />
    );
  };

  return (
    <Popover
      id={open ? "visit-details-popover" : undefined}
      data-testid="visit-details-popover"
      className={classes.popover}
      open={open}
      anchorEl={anchorEl}
      onMouseLeave={onMouseLeave}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      PaperProps={{ elevation: 0 }}
    >
      <Grid container direction="column" className={classes.popoverBackground}>
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.firstRow}
        >
          <Grid item>{VisitTypeIcon()}</Grid>
          <Grid item zeroMinWidth>
            <Typography noWrap appearance="smallBody">
              {visitTypeText} visit
            </Typography>
          </Grid>
        </Grid>
        <Grid item zeroMinWidth className={classes.secondRow}>
          <Typography noWrap appearance="smallBody">
            Provider: {provider?.legalName}
          </Typography>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default EOCVisitDetailsPopover;
