import { SelfScheduleAppointmentComponentByEpisodeIDRequest, fetchComponentsApi } from "shared/fetch/src/apis/ComponentsApi";
import { useQuery } from "react-query";

//Note: this query does not automatically fetch data; instead, trigger the request by calling refetch().
//See components/EOCLineItemTable/EOCMemberStatus component for example implementation.
const selfScheduleAppointmentByEpisodeIdQueryKey = (id: string | number) => [
  "appointmentByEpisodeId",
  id.toString(),
];
const useGetSelfScheduleAppointmentByEpisodeId = (
  request: SelfScheduleAppointmentComponentByEpisodeIDRequest
) => {
  const {
    data,
    isLoading: isPending,
    refetch,
  } = useQuery(
    selfScheduleAppointmentByEpisodeIdQueryKey(request.id),
    () =>
      fetchComponentsApi.selfScheduleAppointmentComponentByEpisodeID(request),
    {
      enabled: false,
    }
  );

  return {
    data,
    isPending,
    refetch,
  };
};

export default useGetSelfScheduleAppointmentByEpisodeId;
