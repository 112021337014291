import React from "react";
import Button from "components/Button";
import { ReferralComponentCoreStateEnum } from "shared/fetch/src/models/ReferralComponentCore";
import { Appointment } from "shared/fetch/src/models/Appointment";
import { selectLoggedInUser } from "shared/features/user/selectors";
import { CheckInButton } from "components/ScheduledVisit/Member/CheckIn";
import { useNavigate } from "react-router";
import { selectFeatures } from "shared/features/featureFlags/selectors";
import { useMediaQuery, useTheme } from "@mui/material";

const ctmStateSwitchTree = (
  state: ReferralComponentCoreStateEnum,
  mobile?: boolean
) => {
  switch (state) {
    case ReferralComponentCoreStateEnum.CheckInOpened:
    case ReferralComponentCoreStateEnum.CheckInInProgress:
    case ReferralComponentCoreStateEnum.CheckInIncomplete:
      return { action: "fastTrackCheckin", label: "Fast track" };
    case ReferralComponentCoreStateEnum.CheckInCompletedNotAcknowledged:
      return {
        action: "acknowledgeCheckIn",
        label: !mobile ? "Acknowledge check in" : "Ackd check in",
      };
    default:
      return { action: "", label: "" };
  }
};
interface IProps {
  visit: Appointment;
  color?: string;
  hasChevron?: boolean;
}

const CTMAction = ({
  state,
  to,
}: {
  state: ReferralComponentCoreStateEnum;
  to: string;
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { action, label } = ctmStateSwitchTree(state, !!isMobileScreen);
  if (!action) {
    return null;
  }

  const handleClick = () => {
    navigate(to, {
      state: {
        action,
      },
    });
  };
  return (
    <Button
      onClick={handleClick}
      color={"primary"}
      name={label}
      data-testid={`ctm-action-${action}`}
    >
      {label}
    </Button>
  );
};

export default function CheckInActionButton({
  visit,
  color,
  hasChevron,
}: IProps) {
  const user = selectLoggedInUser();
  const featureFlags = selectFeatures();
  const hasCheckin = featureFlags.hasCheckin();
  const memberId = visit?.patient?.id;
  if (!visit) {
    return null;
  }
  const { componentId, episodeId, state } = visit;
  const to = `/members/${memberId}/conversations/${episodeId}/component/${componentId}`;

  if (user?.ctm) {
    return (
      <CTMAction
        state={state as ReferralComponentCoreStateEnum}
        to={
          state ===
          ReferralComponentCoreStateEnum.CheckInCompletedNotAcknowledged
            ? `${to}?modal=calculators`
            : to
        }
      />
    );
  }

  return (
    <CheckInButton
      color={color}
      hasChevron={hasChevron}
      hasCheckin={hasCheckin}
      componentId={componentId as any}
      appointmentId={visit?.id || ""}
      memberId={memberId?.toString() || ""}
      isInsideAttachmentsAndAccommodationsBorder
      isVisitEligibleForCheckin={!!visit?.eligibleForCheckin}
      componentState={state as ReferralComponentCoreStateEnum}
    />
  );
}
