// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RoleOutputCoreSchema
 */
export interface RoleOutputCoreSchema  {
    /**
     * 
     * @type {string}
     * @memberof RoleOutputCoreSchema
     */
    readonly id: string;
    /**
     * 
     * @type {string}
     * @memberof RoleOutputCoreSchema
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleOutputCoreSchema
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleOutputCoreSchema
     */
    displayLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleOutputCoreSchema
     */
    backgroundColor?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleOutputCoreSchema
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof RoleOutputCoreSchema
     */
    abbreviation?: string;
}

export function RoleOutputCoreSchemaFromJSON(json: any): RoleOutputCoreSchema {
    return {
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'displayLabel': !exists(json, 'display_label') ? undefined : json['display_label'],
        'backgroundColor': !exists(json, 'background_color') ? undefined : json['background_color'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'abbreviation': !exists(json, 'abbreviation') ? undefined : json['abbreviation'],
    };
}

export function RoleOutputCoreSchemaToJSON(value?: RoleOutputCoreSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'description': value.description,
        'display_label': value.displayLabel,
        'background_color': value.backgroundColor,
        'color': value.color,
        'abbreviation': value.abbreviation,
    };
}


