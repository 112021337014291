// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     InsuranceCoverageDetails,
    InsuranceCoverageDetailsFromJSON,
    InsuranceCoverageDetailsToJSON,
} from './InsuranceCoverageDetails';
import {
     InsuranceCoverageDetailsInsurances,
    InsuranceCoverageDetailsInsurancesFromJSON,
    InsuranceCoverageDetailsInsurancesToJSON,
} from './InsuranceCoverageDetailsInsurances';
import {
     PatientProfileInput,
    PatientProfileInputFromJSON,
    PatientProfileInputToJSON,
} from './PatientProfileInput';
/**
 * @type PatientProfileOutput
 * @export
 */
export interface PatientProfileOutput extends InsuranceCoverageDetails, PatientProfileInput {
}

export function PatientProfileOutputFromJSON(json: any): PatientProfileOutput {
    return {
        ...InsuranceCoverageDetailsFromJSON(json),
        ...PatientProfileInputFromJSON(json),
    };
}

export function PatientProfileOutputToJSON(value?: PatientProfileOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...InsuranceCoverageDetailsToJSON(value),
        ...PatientProfileInputToJSON(value),
    };
}

