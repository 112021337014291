// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SlotSeriesOutput
 */
export interface SlotSeriesOutput  {
    /**
     * 
     * @type {Date}
     * @memberof SlotSeriesOutput
     */
    startAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SlotSeriesOutput
     */
    endAt?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof SlotSeriesOutput
     */
    daysActive?: Array<SlotSeriesOutputDaysActiveEnum>;
    /**
     * 
     * @type {string}
     * @memberof SlotSeriesOutput
     */
    visibility?: SlotSeriesOutputVisibilityEnum;
    /**
     * 
     * @type {string}
     * @memberof SlotSeriesOutput
     */
    appointmentType?: string;
    /**
     * 
     * @type {string}
     * @memberof SlotSeriesOutput
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof SlotSeriesOutput
     */
    clinicId?: string;
    /**
     * 
     * @type {number}
     * @memberof SlotSeriesOutput
     */
    maxPatients?: number;
    /**
     * 
     * @type {number}
     * @memberof SlotSeriesOutput
     */
    maxOverbook?: number;
}

export function SlotSeriesOutputFromJSON(json: any): SlotSeriesOutput {
    return {
        'startAt': !exists(json, 'start_at') ? undefined : new Date(json['start_at']),
        'endAt': !exists(json, 'end_at') ? undefined : new Date(json['end_at']),
        'daysActive': !exists(json, 'days_active') ? undefined : json['days_active'],
        'visibility': !exists(json, 'visibility') ? undefined : json['visibility'],
        'appointmentType': !exists(json, 'appointment_type') ? undefined : json['appointment_type'],
        'providerId': !exists(json, 'provider_id') ? undefined : json['provider_id'],
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'maxPatients': !exists(json, 'max_patients') ? undefined : json['max_patients'],
        'maxOverbook': !exists(json, 'max_overbook') ? undefined : json['max_overbook'],
    };
}

export function SlotSeriesOutputToJSON(value?: SlotSeriesOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'start_at': value.startAt === undefined ? undefined : value.startAt.toISOString(),
        'end_at': value.endAt === undefined ? undefined : value.endAt.toISOString(),
        'days_active': value.daysActive,
        'visibility': value.visibility,
        'appointment_type': value.appointmentType,
        'provider_id': value.providerId,
        'clinic_id': value.clinicId,
        'max_patients': value.maxPatients,
        'max_overbook': value.maxOverbook,
    };
}

/**
* @export
* @enum {string}
*/
export enum SlotSeriesOutputDaysActiveEnum {
    M = 'M',
    T = 'T',
    W = 'W',
    Th = 'Th',
    F = 'F'
}
/**
* @export
* @enum {string}
*/
export enum SlotSeriesOutputVisibilityEnum {
    Internal = 'internal',
    Accessible = 'accessible',
    Hold = 'hold'
}


