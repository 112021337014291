// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppointmentCancelationInfoResponse
 */
export interface AppointmentCancelationInfoResponse  {
    /**
     * 
     * @type {string}
     * @memberof AppointmentCancelationInfoResponse
     */
    cancelType?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentCancelationInfoResponse
     */
    fee?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentCancelationInfoResponse
     */
    duration?: string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentCancelationInfoResponse
     */
    lateCanceledOrNoShowAppointmentCount?: number;
}

export function AppointmentCancelationInfoResponseFromJSON(json: any): AppointmentCancelationInfoResponse {
    return {
        'cancelType': !exists(json, 'cancel_type') ? undefined : json['cancel_type'],
        'fee': !exists(json, 'fee') ? undefined : json['fee'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'lateCanceledOrNoShowAppointmentCount': !exists(json, 'late_canceled_or_no_show_appointment_count') ? undefined : json['late_canceled_or_no_show_appointment_count'],
    };
}

export function AppointmentCancelationInfoResponseToJSON(value?: AppointmentCancelationInfoResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'cancel_type': value.cancelType,
        'fee': value.fee,
        'duration': value.duration,
        'late_canceled_or_no_show_appointment_count': value.lateCanceledOrNoShowAppointmentCount,
    };
}


