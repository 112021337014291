// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompactComponentReference,
    CompactComponentReferenceFromJSON,
    CompactComponentReferenceToJSON,
} from './CompactComponentReference';
import {
    DocumentOutputSchema,
    DocumentOutputSchemaFromJSON,
    DocumentOutputSchemaToJSON,
} from './DocumentOutputSchema';


/**
 * 
 * @export
 * @interface CtmMessagingEpisodeCreatedComponentOutputProps
 */
export interface CtmMessagingEpisodeCreatedComponentOutputProps  {
    /**
     * 
     * @type {string}
     * @memberof CtmMessagingEpisodeCreatedComponentOutputProps
     */
    state: CtmMessagingEpisodeCreatedComponentOutputPropsStateEnum;
    /**
     * 
     * @type {boolean}
     * @memberof CtmMessagingEpisodeCreatedComponentOutputProps
     */
    responseRequired?: boolean;
    /**
     * 
     * @type {Array<DocumentOutputSchema>}
     * @memberof CtmMessagingEpisodeCreatedComponentOutputProps
     */
    documentInfo?: Array<DocumentOutputSchema>;
    /**
     * 
     * @type {Array<CompactComponentReference>}
     * @memberof CtmMessagingEpisodeCreatedComponentOutputProps
     */
    childComponents?: Array<CompactComponentReference>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CtmMessagingEpisodeCreatedComponentOutputProps
     */
    ccCtmIds?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CtmMessagingEpisodeCreatedComponentOutputProps
     */
    ccCtmNames?: Array<string>;
}

export function CtmMessagingEpisodeCreatedComponentOutputPropsFromJSON(json: any): CtmMessagingEpisodeCreatedComponentOutputProps {
    return {
        'state': json['state'],
        'responseRequired': !exists(json, 'response_required') ? undefined : json['response_required'],
        'documentInfo': !exists(json, 'document_info') ? undefined : (json['document_info'] as Array<any>).map(DocumentOutputSchemaFromJSON),
        'childComponents': !exists(json, 'child_components') ? undefined : (json['child_components'] as Array<any>).map(CompactComponentReferenceFromJSON),
        'ccCtmIds': !exists(json, 'cc_ctm_ids') ? undefined : json['cc_ctm_ids'],
        'ccCtmNames': !exists(json, 'cc_ctm_names') ? undefined : json['cc_ctm_names'],
    };
}

export function CtmMessagingEpisodeCreatedComponentOutputPropsToJSON(value?: CtmMessagingEpisodeCreatedComponentOutputProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'state': value.state,
        'response_required': value.responseRequired,
        'document_info': value.documentInfo === undefined ? undefined : (value.documentInfo as Array<any>).map(DocumentOutputSchemaToJSON),
        'child_components': value.childComponents === undefined ? undefined : (value.childComponents as Array<any>).map(CompactComponentReferenceToJSON),
        'cc_ctm_ids': value.ccCtmIds,
        'cc_ctm_names': value.ccCtmNames,
    };
}

/**
* @export
* @enum {string}
*/
export enum CtmMessagingEpisodeCreatedComponentOutputPropsStateEnum {
    Unread = 'unread',
    Resolved = 'resolved',
    ResolvedWithReply = 'resolved_with_reply'
}


