// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberPushNotificationArrayResponseCounts,
    MemberPushNotificationArrayResponseCountsFromJSON,
    MemberPushNotificationArrayResponseCountsToJSON,
} from './MemberPushNotificationArrayResponseCounts';
import {
    UserPushNotificationOutput,
    UserPushNotificationOutputFromJSON,
    UserPushNotificationOutputToJSON,
} from './UserPushNotificationOutput';


/**
 * 
 * @export
 * @interface MemberPushNotificationArrayResponse
 */
export interface MemberPushNotificationArrayResponse  {
    /**
     * 
     * @type {string}
     * @memberof MemberPushNotificationArrayResponse
     */
    prevUrl: string;
    /**
     * 
     * @type {string}
     * @memberof MemberPushNotificationArrayResponse
     */
    nextUrl: string;
    /**
     * 
     * @type {number}
     * @memberof MemberPushNotificationArrayResponse
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof MemberPushNotificationArrayResponse
     */
    last?: number;
    /**
     * 
     * @type {MemberPushNotificationArrayResponseCounts}
     * @memberof MemberPushNotificationArrayResponse
     */
    counts: MemberPushNotificationArrayResponseCounts;
    /**
     * 
     * @type {Array<UserPushNotificationOutput>}
     * @memberof MemberPushNotificationArrayResponse
     */
    results: Array<UserPushNotificationOutput>;
}

export function MemberPushNotificationArrayResponseFromJSON(json: any): MemberPushNotificationArrayResponse {
    return {
        'prevUrl': json['prev_url'],
        'nextUrl': json['next_url'],
        'page': json['page'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'counts': MemberPushNotificationArrayResponseCountsFromJSON(json['counts']),
        'results': (json['results'] as Array<any>).map(UserPushNotificationOutputFromJSON),
    };
}

export function MemberPushNotificationArrayResponseToJSON(value?: MemberPushNotificationArrayResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'prev_url': value.prevUrl,
        'next_url': value.nextUrl,
        'page': value.page,
        'last': value.last,
        'counts': MemberPushNotificationArrayResponseCountsToJSON(value.counts),
        'results': (value.results as Array<any>).map(UserPushNotificationOutputToJSON),
    };
}


