import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";
import { DeleteConsentRequest, fetchConsentApi } from "shared/fetch/src/apis/ConsentApi";

const useDeleteConsent = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const deleteConsentMutation = useMutation((request: DeleteConsentRequest) => {
    return fetchConsentApi
      .deleteConsent(request)
      .then(() => {
        dispatch(showSnackbar("Consent successfully deleted.", "success"));
      })
      .catch(() => {
        dispatch(
          showSnackbar("Error deleting consent. Please try again.", "danger")
        );
      })
      .finally(() => {
        queryClient.invalidateQueries({ queryKey: ["memberConsents"] });
      });
  });
  return deleteConsentMutation;
};

export default useDeleteConsent;
