import React from "react";
import { Link } from "react-router-dom";

import { ReferralComponentCoreStateEnum } from "shared/api/src/models/ReferralComponentCore";

import withProps from "hocs/withProps";

import Typography from "components/Typography";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import queryActionTree, {
  CANCEL,
  DECLINE,
  VISIT_DID_NOT_TAKE_PLACE,
} from "./queryActionTree";

interface ComponentDetailStateLinkProps {
  to: string;
  accessingUserType: "ctm" | "member";
  state: ReferralComponentCoreStateEnum;
  pastVisit?: boolean;
  internalVisit?: boolean;
  memberId?: number;
  componentId?: number;
  episodeId?: number;
  canReschedule?: boolean;
  canVisitBeCancelled?: boolean;
}

interface ComponentDetailLinkProps {
  to: string;
  action: string;
  content: string;
  dataTestId?: string;
  shouldRender?: boolean;
}

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    root: {
      color: palette?.links?.secondary,
      "&:hover": {
        color: palette?.links?.secondaryHover,
      },
    },
  })
);

const ComponentDetailLink = ({
  to,
  action,
  content,
  dataTestId,
  shouldRender,
}: ComponentDetailLinkProps) => {
  if (!shouldRender) {
    return null;
  }
  const classes = useStyles();

  return (
    <Link data-testid={dataTestId} to={to} state={{ action }}>
      <Typography
        component="span"
        textDecorationUnderline
        appearance="smallBody"
        className={classes.root}
      >
        {content}
      </Typography>
    </Link>
  );
};

const RescheduleLink = withProps(
  ({
    memberId,
    componentId,
    episodeId,
    canReschedule,
  }: ComponentDetailStateLinkProps) => ({
    shouldRender: canReschedule,
    dataTestId: "reschedule-link",
    action: "schedule",
    content: "Reschedule",
    to: `/members/${memberId}/conversations/${episodeId}/component/${componentId}?reschedule-${componentId}`,
  })
)(ComponentDetailLink);

const CancelLink = withProps(
  ({
    accessingUserType,
    state,
    pastVisit,
    internalVisit,
    memberId,
    componentId,
    episodeId,
    canVisitBeCancelled,
  }: ComponentDetailStateLinkProps) => ({
    shouldRender: canVisitBeCancelled && queryActionTree({
      accessingUserType,
      state,
      action: CANCEL,
      // @ts-ignore: Object is possibly 'null'.
      pastVisit,
      // @ts-ignore: Object is possibly 'null'.
      internalVisit,
    }),
    dataTestId: "cancel-link",
    action: "cancel",
    content: "Cancel",
    to: `/members/${memberId}/conversations/${episodeId}/component/${componentId}?cancel-${componentId}`,
  })
)(ComponentDetailLink);

const DeclineLink = withProps(
  ({
    accessingUserType,
    state,
    pastVisit,
    internalVisit,
  }: ComponentDetailStateLinkProps) => ({
    shouldRender: queryActionTree({
      accessingUserType,
      state,
      action: DECLINE,
      // @ts-ignore: Object is possibly 'null'.
      pastVisit,
      // @ts-ignore: Object is possibly 'null'.
      internalVisit,
    }),
    dataTestId: "decline-link",
    action: "decline",
    content: "Decline",
  })
)(ComponentDetailLink);

const VisitDidNotTakePlaceLink = withProps(
  ({
    accessingUserType,
    state,
    pastVisit,
    internalVisit,
  }: ComponentDetailStateLinkProps) => ({
    shouldRender: queryActionTree({
      accessingUserType,
      state,
      action: VISIT_DID_NOT_TAKE_PLACE,
      // @ts-ignore: Object is possibly 'null'.
      pastVisit,
      // @ts-ignore: Object is possibly 'null'.
      internalVisit,
    }),
    dataTestId: "visit-did-not-take-place-link",
    action: "visitDidNotTakePlace",
    content: "Visit didn't take place",
  })
)(ComponentDetailLink);

export default [
  RescheduleLink,
  DeclineLink,
  CancelLink,
  VisitDidNotTakePlaceLink,
];
