import React from "react";
import { Heading } from "@tenon-io/tenon-ui";
import CssTypography from "components/CssTypography";
import Typography from "../Typography";
import whatInput from "what-input";

interface IProps {
  className?: string;
  gutterBottom?: boolean;
  color?:
    | "initial"
    | "inherit"
    | "primary"
    | "secondary"
    | "textPrimary"
    | "textSecondary"
    | "error";
  appearance?: "body" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "smallBody";
  fontWeightBold?: boolean;
  fontWeightNormal?: boolean;
  domRef?: React.RefObject<HTMLElement>;
}

const H = (props: IProps & any) => {
  return props.cssType ? (
    <CssTypography
      Component={Heading.H}
      tabIndex={-1}
      data-whatintent={whatInput.ask("intent")}
      {...props}
    />
  ) : (
    <Typography
      component={Heading.H}
      tabIndex={-1}
      data-whatintent={whatInput.ask("intent")}
      {...props}
    />
  );
};

H.displayName = "Heading.H";

// @ts-ignore: Object is possibly 'null'.
const DynamicHeading = (): void => null;

DynamicHeading.LevelBoundary = Heading.LevelBoundary;
DynamicHeading.H = H;

export default DynamicHeading;
