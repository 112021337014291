// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Appointment,
    AppointmentFromJSON,
    AppointmentToJSON,
} from './Appointment';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
    CtmMessageComponentDetailsOutput,
    CtmMessageComponentDetailsOutputFromJSON,
    CtmMessageComponentDetailsOutputToJSON,
} from './CtmMessageComponentDetailsOutput';
import {
    ReferralComponentCoreDeclineReason,
    ReferralComponentCoreDeclineReasonFromJSON,
    ReferralComponentCoreDeclineReasonToJSON,
} from './ReferralComponentCoreDeclineReason';
import {
    ReferralComponentCoreVisitType,
    ReferralComponentCoreVisitTypeFromJSON,
    ReferralComponentCoreVisitTypeToJSON,
} from './ReferralComponentCoreVisitType';


/**
 * 
 * @export
 * @interface ReferralComponentCore
 */
export interface ReferralComponentCore  {
    /**
     * 
     * @type {ReferralComponentCoreVisitType}
     * @memberof ReferralComponentCore
     */
    visitType?: ReferralComponentCoreVisitType;
    /**
     * 
     * @type {string}
     * @memberof ReferralComponentCore
     */
    referralReason?: string;
    /**
     * 
     * @type {CTMInfo}
     * @memberof ReferralComponentCore
     */
    provider?: CTMInfo;
    /**
     * 
     * @type {string}
     * @memberof ReferralComponentCore
     */
    state?: ReferralComponentCoreStateEnum;
    /**
     * 
     * @type {Appointment}
     * @memberof ReferralComponentCore
     */
    appointment?: Appointment;
    /**
     * 
     * @type {ReferralComponentCoreDeclineReason}
     * @memberof ReferralComponentCore
     */
    declineReason?: ReferralComponentCoreDeclineReason;
    /**
     * 
     * @type {CtmMessageComponentDetailsOutput}
     * @memberof ReferralComponentCore
     */
    messageComponent?: CtmMessageComponentDetailsOutput;
}

export function ReferralComponentCoreFromJSON(json: any): ReferralComponentCore {
    return {
        'visitType': !exists(json, 'visit_type') ? undefined : ReferralComponentCoreVisitTypeFromJSON(json['visit_type']),
        'referralReason': !exists(json, 'referral_reason') ? undefined : json['referral_reason'],
        'provider': !exists(json, 'provider') ? undefined : CTMInfoFromJSON(json['provider']),
        'state': !exists(json, 'state') ? undefined : json['state'],
        'appointment': !exists(json, 'appointment') ? undefined : AppointmentFromJSON(json['appointment']),
        'declineReason': !exists(json, 'decline_reason') ? undefined : ReferralComponentCoreDeclineReasonFromJSON(json['decline_reason']),
        'messageComponent': !exists(json, 'message_component') ? undefined : CtmMessageComponentDetailsOutputFromJSON(json['message_component']),
    };
}

export function ReferralComponentCoreToJSON(value?: ReferralComponentCore): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'visit_type': ReferralComponentCoreVisitTypeToJSON(value.visitType),
        'referral_reason': value.referralReason,
        'provider': CTMInfoToJSON(value.provider),
        'state': value.state,
        'appointment': AppointmentToJSON(value.appointment),
        'decline_reason': ReferralComponentCoreDeclineReasonToJSON(value.declineReason),
        'message_component': CtmMessageComponentDetailsOutputToJSON(value.messageComponent),
    };
}

/**
* @export
* @enum {string}
*/
export enum ReferralComponentCoreStateEnum {
    UnreadPending = 'unread_pending',
    ReadPending = 'read_pending',
    Declined = 'declined',
    ResolvedDeclinedAckd = 'resolved_declined_ackd',
    Scheduled = 'scheduled',
    ResolvedCompleted = 'resolved_completed',
    MissedNoShow = 'missed_no_show',
    CanceledByMember = 'canceled_by_member',
    ResolvedCanceledAckd = 'resolved_canceled_ackd',
    CanceledByCtm = 'canceled_by_ctm',
    RescheduledPending = 'rescheduled_pending',
    ResolvedCanceledViewed = 'resolved_canceled_viewed',
    DeclinedAckdPending = 'declined_ackd_pending',
    CanceledAckdPending = 'canceled_ackd_pending',
    CheckInOpened = 'check_in_opened',
    CheckInInProgress = 'check_in_in_progress',
    CheckInCompleted = 'check_in_completed',
    CheckInIncomplete = 'check_in_incomplete',
    CheckInClosed = 'check_in_closed',
    CheckInFastTrackedConsentNeeded = 'check_in_fast_tracked_consent_needed',
    CheckInFastTrackedOutstandingItemsWithoutConsent = 'check_in_fast_tracked_outstanding_items_without_consent',
    CheckInCompletedNotAcknowledged = 'check_in_completed_not_acknowledged'
}


