// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ComponentActionDetails
 */
export interface ComponentActionDetails  {
    /**
     * 
     * @type {string}
     * @memberof ComponentActionDetails
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentActionDetails
     */
    display: string;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentActionDetails
     */
    primary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ComponentActionDetails
     */
    href: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentActionDetails
     */
    method?: string;
}

export function ComponentActionDetailsFromJSON(json: any): ComponentActionDetails {
    return {
        'name': json['name'],
        'display': json['display'],
        'primary': !exists(json, 'primary') ? undefined : json['primary'],
        'href': json['href'],
        'method': !exists(json, 'method') ? undefined : json['method'],
    };
}

export function ComponentActionDetailsToJSON(value?: ComponentActionDetails): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'display': value.display,
        'primary': value.primary,
        'href': value.href,
        'method': value.method,
    };
}


