// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MessageRecipient,
    MessageRecipientFromJSON,
    MessageRecipientToJSON,
} from './MessageRecipient';
import {
    XopPodOutputSchema,
    XopPodOutputSchemaFromJSON,
    XopPodOutputSchemaToJSON,
} from './XopPodOutputSchema';


/**
 * 
 * @export
 * @interface MemberMessageRecipientsResponse
 */
export interface MemberMessageRecipientsResponse  {
    /**
     * 
     * @type {MessageRecipient}
     * @memberof MemberMessageRecipientsResponse
     */
    defaultRecipient?: MessageRecipient;
    /**
     * 
     * @type {XopPodOutputSchema}
     * @memberof MemberMessageRecipientsResponse
     */
    defaultCareTeam?: XopPodOutputSchema;
    /**
     * 
     * @type {Array<MessageRecipient>}
     * @memberof MemberMessageRecipientsResponse
     */
    recipients?: Array<MessageRecipient>;
    /**
     * 
     * @type {XopPodOutputSchema}
     * @memberof MemberMessageRecipientsResponse
     */
    careTeam?: XopPodOutputSchema;
}

export function MemberMessageRecipientsResponseFromJSON(json: any): MemberMessageRecipientsResponse {
    return {
        'defaultRecipient': !exists(json, 'default_recipient') ? undefined : MessageRecipientFromJSON(json['default_recipient']),
        'defaultCareTeam': !exists(json, 'default_care_team') ? undefined : XopPodOutputSchemaFromJSON(json['default_care_team']),
        'recipients': !exists(json, 'recipients') ? undefined : (json['recipients'] as Array<any>).map(MessageRecipientFromJSON),
        'careTeam': !exists(json, 'care_team') ? undefined : XopPodOutputSchemaFromJSON(json['care_team']),
    };
}

export function MemberMessageRecipientsResponseToJSON(value?: MemberMessageRecipientsResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'default_recipient': MessageRecipientToJSON(value.defaultRecipient),
        'default_care_team': XopPodOutputSchemaToJSON(value.defaultCareTeam),
        'recipients': value.recipients === undefined ? undefined : (value.recipients as Array<any>).map(MessageRecipientToJSON),
        'care_team': XopPodOutputSchemaToJSON(value.careTeam),
    };
}


