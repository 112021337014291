import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import useEnrollDevicePushNotifications from "shared/features/devices/useEnrollDevicePushNotifications";
import useUnenrollDevicePushNotifications from "shared/features/devices/useUnenrollDevicePushNotifications";
import useGetMemberEnrolledDevices from "shared/features/devices/useGetMemberEnrolledDevices";
import { showSnackbar } from "shared/state/ui/snackbar";
import { State } from "@types";
import { selectNativeAppState } from "shared/state/nativeApp/selectors";
import { getUser } from "shared/features/user";
import { MemberEnrolledDeviceProfile } from "shared/api/src/models/MemberEnrolledDeviceProfile";
import NativeApp from "models/NativeApp";
import { EnrollPushNotificationRequestBodyOperatingSystemNameEnum } from "shared/fetch/src/models/EnrollPushNotificationRequestBody";
import { selectFeatures } from "shared/features/featureFlags/selectors";

export const PUSH_NOTIFICATIONS_ENABLED = "enabled";
export const PUSH_NOTIFICATION_DECLINED = "declined";
export const DEVICE_NOTIFICATION_PERMISSIONS_DENIED = "denied";
export const DEVICE_NOTIFICATION_PERMISSIONS_GRANTED = "granted";
export const HAS_SEEN_DEVICE_PERMISSIONS_PROMPT = "true";

const PushNotificationEnrollment: React.FunctionComponent = () => {
  const isLoggedIn = useSelector((state: State) => !!state.auth.refreshToken);
  const user = useSelector(getUser);
  const { isApp } = selectNativeAppState();
  const { enrollDevicePushNotifications: enroll } =
    useEnrollDevicePushNotifications(true, user?.id?.toString()!);
  const { enrollDevicePushNotifications: updatePushToken } =
    useEnrollDevicePushNotifications(false, user?.id?.toString()!);
  const { unenrollDevicePushNotifications } =
    useUnenrollDevicePushNotifications();
  const { memberEnrolledDevices } = useGetMemberEnrolledDevices({
    // @ts-ignore: Object is possibly 'null'.
    id: user?.id?.toString(),
  });
  const dispatch = useDispatch();
  const executed = useRef(false);
  const featureFlags = selectFeatures();

  const enrollPushNotificationsEventHandler = (e: any) => {
    const deviceId: string = e.detail.deviceId;
    const devicePushToken: string = e.detail.devicePushToken;
    const operatingSystemName: EnrollPushNotificationRequestBodyOperatingSystemNameEnum =
      e.detail.operatingSystemName;
    const pushNotificationCategoryId: string =
      e.detail.pushNotificationCategoryId;

    enroll({
      enrollPushNotificationRequestBody: {
        deviceId,
        devicePushToken,
        operatingSystemName,
        ...(pushNotificationCategoryId !== "undefined"
          ? {
              pushNotificationCategoryId,
            }
          : {}),
      },
    });
  };

  const unenrollPushNotificationsEventHandler = (e: any) => {
    const deviceId: string = e.detail.deviceId;
    unenrollDevicePushNotifications({
      unenrollDevicePushNotificationsRequestBody: {
        deviceId,
      },
    });
  };

  const getEnrolledDevicesIds = (
    devices = [] as MemberEnrolledDeviceProfile[]
  ): string[] => devices.map((d) => d.deviceId) || [];

  const updatePushTokenEventHandler = (e: any) => {
    const deviceId: string = e.detail.deviceId;
    const devicePushToken: string = e.detail.devicePushToken;
    const operatingSystemName: EnrollPushNotificationRequestBodyOperatingSystemNameEnum =
      e.detail.operatingSystemName;
    const enrolledDevicesIds = getEnrolledDevicesIds(memberEnrolledDevices);
    if (enrolledDevicesIds.includes(deviceId)) {
      updatePushToken({
        enrollPushNotificationRequestBody: {
          deviceId,
          devicePushToken,
          operatingSystemName,
        },
      });
    }
  };

  const pushEnrollmentErrorEventHandler = () => {
    dispatch(
      showSnackbar(
        "There was an issue enrolling your device, please try again later.",
        "danger"
      )
    );
  };

  useEffect(() => {
    window.addEventListener(
      "enrollPushNotifications",
      enrollPushNotificationsEventHandler
    );
    return () => {
      window.removeEventListener(
        "enrollPushNotifications",
        enrollPushNotificationsEventHandler
      );
    };
  }, []);

  useEffect(() => {
    window.addEventListener(
      "unenrollPushNotifications",
      unenrollPushNotificationsEventHandler
    );

    return () => {
      window.removeEventListener(
        "unenrollPushNotifications",
        unenrollPushNotificationsEventHandler
      );
    };
  }, []);

  useEffect(() => {
    window.addEventListener(
      "pushEnrollmentError",
      pushEnrollmentErrorEventHandler
    );
    return () => {
      window.removeEventListener(
        "pushEnrollmentError",
        pushEnrollmentErrorEventHandler
      );
    };
  }, []);

  useEffect(() => {
    window.addEventListener("updatePushToken", updatePushTokenEventHandler);

    return () => {
      window.removeEventListener(
        "updatePushToken",
        updatePushTokenEventHandler
      );
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn && isApp && memberEnrolledDevices?.length) {
      if (!executed.current) {
        NativeApp.pushTokenFetch();
      }
      executed.current = true;
    }
  }, [isLoggedIn, isApp, memberEnrolledDevices?.length]);

  useEffect(() => {
    if (isLoggedIn && isApp && featureFlags?.hasDisableBiometricLogin()) {
      NativeApp.pushRegistration();
    }
  }, [isLoggedIn, isApp]);

  return null;
};
export default PushNotificationEnrollment;
