// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EpisodeQueueSummaryLastCtmUpdater
 */
export interface EpisodeQueueSummaryLastCtmUpdater  {
    /**
     * 
     * @type {string}
     * @memberof EpisodeQueueSummaryLastCtmUpdater
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EpisodeQueueSummaryLastCtmUpdater
     */
    truncatedName?: string;
    /**
     * 
     * @type {string}
     * @memberof EpisodeQueueSummaryLastCtmUpdater
     */
    legalAndPreferredName?: string;
}

export function EpisodeQueueSummaryLastCtmUpdaterFromJSON(json: any): EpisodeQueueSummaryLastCtmUpdater {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'truncatedName': !exists(json, 'truncated_name') ? undefined : json['truncated_name'],
        'legalAndPreferredName': !exists(json, 'legal_and_preferred_name') ? undefined : json['legal_and_preferred_name'],
    };
}

export function EpisodeQueueSummaryLastCtmUpdaterToJSON(value?: EpisodeQueueSummaryLastCtmUpdater): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'truncated_name': value.truncatedName,
        'legal_and_preferred_name': value.legalAndPreferredName,
    };
}


