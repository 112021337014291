// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InsuranceCoverageDetailsInsurancesInsurance1,
    InsuranceCoverageDetailsInsurancesInsurance1FromJSON,
    InsuranceCoverageDetailsInsurancesInsurance1ToJSON,
} from './InsuranceCoverageDetailsInsurancesInsurance1';
import {
    InsuranceCoverageDetailsInsurancesInsurance2,
    InsuranceCoverageDetailsInsurancesInsurance2FromJSON,
    InsuranceCoverageDetailsInsurancesInsurance2ToJSON,
} from './InsuranceCoverageDetailsInsurancesInsurance2';
import {
    InsuranceCoverageDetailsInsurancesInsurance3,
    InsuranceCoverageDetailsInsurancesInsurance3FromJSON,
    InsuranceCoverageDetailsInsurancesInsurance3ToJSON,
} from './InsuranceCoverageDetailsInsurancesInsurance3';


/**
 * 
 * @export
 * @interface InsuranceCoverageDetailsInsurances
 */
export interface InsuranceCoverageDetailsInsurances  {
    /**
     * 
     * @type {InsuranceCoverageDetailsInsurancesInsurance1}
     * @memberof InsuranceCoverageDetailsInsurances
     */
    insurance1?: InsuranceCoverageDetailsInsurancesInsurance1;
    /**
     * 
     * @type {InsuranceCoverageDetailsInsurancesInsurance2}
     * @memberof InsuranceCoverageDetailsInsurances
     */
    insurance2?: InsuranceCoverageDetailsInsurancesInsurance2;
    /**
     * 
     * @type {InsuranceCoverageDetailsInsurancesInsurance3}
     * @memberof InsuranceCoverageDetailsInsurances
     */
    insurance3?: InsuranceCoverageDetailsInsurancesInsurance3;
}

export function InsuranceCoverageDetailsInsurancesFromJSON(json: any): InsuranceCoverageDetailsInsurances {
    return {
        'insurance1': !exists(json, 'insurance_1') ? undefined : InsuranceCoverageDetailsInsurancesInsurance1FromJSON(json['insurance_1']),
        'insurance2': !exists(json, 'insurance_2') ? undefined : InsuranceCoverageDetailsInsurancesInsurance2FromJSON(json['insurance_2']),
        'insurance3': !exists(json, 'insurance_3') ? undefined : InsuranceCoverageDetailsInsurancesInsurance3FromJSON(json['insurance_3']),
    };
}

export function InsuranceCoverageDetailsInsurancesToJSON(value?: InsuranceCoverageDetailsInsurances): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'insurance_1': InsuranceCoverageDetailsInsurancesInsurance1ToJSON(value.insurance1),
        'insurance_2': InsuranceCoverageDetailsInsurancesInsurance2ToJSON(value.insurance2),
        'insurance_3': InsuranceCoverageDetailsInsurancesInsurance3ToJSON(value.insurance3),
    };
}


