/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    DeleteTimelineEventRequestBody,
    DeleteTimelineEventRequestBodyFromJSON,
    DeleteTimelineEventRequestBodyToJSON,
} from '../models/DeleteTimelineEventRequestBody'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    TimelineEventOutput,
    TimelineEventOutputFromJSON,
    TimelineEventOutputToJSON,
} from '../models/TimelineEventOutput'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface AcknowledgeCalculatorsRequest {
    timelineEventId: string;
}
export interface DeleteTimelineEventRequest {
    id: string;
    deleteTimelineEventRequestBody?: DeleteTimelineEventRequestBody;
}
export interface FetchTimelineEventRequest {
    id: string;
}


export interface ITimelineEventsApi {
      acknowledgeCalculators: (requestParameters: AcknowledgeCalculatorsRequest) => Promise<TimelineEventOutput>
      deleteTimelineEvent: (requestParameters: DeleteTimelineEventRequest) => Promise<void>
      fetchTimelineEvent: (requestParameters: FetchTimelineEventRequest) => Promise<TimelineEventOutput>
      acknowledgeCalculatorsWithResponse: (requestParameters: AcknowledgeCalculatorsRequest) => Promise<{ value: TimelineEventOutput, response: any}>
      deleteTimelineEventWithResponse: (requestParameters: DeleteTimelineEventRequest) => Promise<{ value: void, response: any}>
      fetchTimelineEventWithResponse: (requestParameters: FetchTimelineEventRequest) => Promise<{ value: TimelineEventOutput, response: any}>
}

/**
 * no description
 */
export class TimelineEventsApi extends runtime.BaseAPI {

    /**
     * Acknowledge all calculators for timeline event
     */
    acknowledgeCalculatorsRaw = async (requestParameters: AcknowledgeCalculatorsRequest): Promise<{ runtime: runtime.ApiResponse<TimelineEventOutput>, response: any}> => {
        if (requestParameters.timelineEventId === null || requestParameters.timelineEventId === undefined) {
            throw new runtime.RequiredError('timelineEventId','Required parameter requestParameters.timelineEventId was null or undefined when calling acknowledgeCalculators.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/timeline_events/{timeline_event_id}/acknowledge_calculators`.replace(`{${"timeline_event_id"}}`, encodeURIComponent(String(requestParameters.timelineEventId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => TimelineEventOutputFromJSON(jsonValue)), response };
    }

    /**
     * Acknowledge all calculators for timeline event
     */
    acknowledgeCalculators = async (requestParameters: AcknowledgeCalculatorsRequest): Promise<TimelineEventOutput> => {
        const { runtime } = await this.acknowledgeCalculatorsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Acknowledge all calculators for timeline event
     */
    acknowledgeCalculatorsWithResponse = async (requestParameters: AcknowledgeCalculatorsRequest): Promise<{ value: TimelineEventOutput, response: any}> => {
        const { runtime, response } = await this.acknowledgeCalculatorsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Delete a timeline event
     * Delete timeline event
     */
    deleteTimelineEventRaw = async (requestParameters: DeleteTimelineEventRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTimelineEvent.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/timeline_events/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteTimelineEventRequestBodyToJSON(requestParameters.deleteTimelineEventRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Delete a timeline event
     * Delete timeline event
     */
    deleteTimelineEvent = async (requestParameters: DeleteTimelineEventRequest): Promise<void> => {
        const { runtime } = await this.deleteTimelineEventRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Delete a timeline event
     * Delete timeline event
     */
    deleteTimelineEventWithResponse = async (requestParameters: DeleteTimelineEventRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.deleteTimelineEventRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Fetch a timeline event
     * Fetch timeline event
     */
    fetchTimelineEventRaw = async (requestParameters: FetchTimelineEventRequest): Promise<{ runtime: runtime.ApiResponse<TimelineEventOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchTimelineEvent.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/timeline_events/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => TimelineEventOutputFromJSON(jsonValue)), response };
    }

    /**
     * Fetch a timeline event
     * Fetch timeline event
     */
    fetchTimelineEvent = async (requestParameters: FetchTimelineEventRequest): Promise<TimelineEventOutput> => {
        const { runtime } = await this.fetchTimelineEventRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch a timeline event
     * Fetch timeline event
     */
    fetchTimelineEventWithResponse = async (requestParameters: FetchTimelineEventRequest): Promise<{ value: TimelineEventOutput, response: any}> => {
        const { runtime, response } = await this.fetchTimelineEventRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchTimelineEventsApi: ITimelineEventsApi  = new TimelineEventsApi();
