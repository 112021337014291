// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DiscountCoupon,
    DiscountCouponFromJSON,
    DiscountCouponToJSON,
} from './DiscountCoupon';


/**
 * 
 * @export
 * @interface StripeDiscount
 */
export interface StripeDiscount  {
    /**
     * 
     * @type {string}
     * @memberof StripeDiscount
     */
    id?: string;
    /**
     * 
     * @type {DiscountCoupon}
     * @memberof StripeDiscount
     */
    coupon?: DiscountCoupon;
    /**
     * The ID of the customer associated with this discount.
     * @type {string}
     * @memberof StripeDiscount
     */
    customer?: string;
    /**
     * If the coupon has a duration of repeating, the date that this discount will end. If the coupon has a duration of once or forever, this attribute will be null.
     * @type {number}
     * @memberof StripeDiscount
     */
    end?: number;
    /**
     * Date that the coupon was applied.
     * @type {number}
     * @memberof StripeDiscount
     */
    start?: number;
    /**
     * 
     * @type {string}
     * @memberof StripeDiscount
     */
    subscription?: string;
}

export function StripeDiscountFromJSON(json: any): StripeDiscount {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'coupon': !exists(json, 'coupon') ? undefined : DiscountCouponFromJSON(json['coupon']),
        'customer': !exists(json, 'customer') ? undefined : json['customer'],
        'end': !exists(json, 'end') ? undefined : json['end'],
        'start': !exists(json, 'start') ? undefined : json['start'],
        'subscription': !exists(json, 'subscription') ? undefined : json['subscription'],
    };
}

export function StripeDiscountToJSON(value?: StripeDiscount): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'coupon': DiscountCouponToJSON(value.coupon),
        'customer': value.customer,
        'end': value.end,
        'start': value.start,
        'subscription': value.subscription,
    };
}


