import React, { useState } from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Avatar from "components/Avatar";
import clsx from "clsx";
import ProviderModal from "features/selfSchedule/enhancements/components/ProviderModal";

interface IProps {
  altName: string;
  avatarUrl: string;
  hasBorder?: boolean;
  canClick?: boolean;
  providerId?: string;
}

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    providerAvatar: {
      height: "40px",
      width: "40px",
    },
    withBorder: {
      border: `1px solid ${palette?.secondary?.main}`,
    },
  })
);

const ProviderAvatar = ({
  altName,
  avatarUrl,
  hasBorder,
  canClick,
  providerId,
}: IProps) => {
  const classes = useStyles();
  const [showProviderModal, setShowProviderModal] = useState<boolean>(false);
  const clickHandler = () => setShowProviderModal(!showProviderModal);

  return (
    <>
      <Avatar
        className={clsx(classes.providerAvatar, {
          [classes.withBorder]: !!hasBorder,
        })}
        containerStyle={{ marginLeft: 0 }}
        alt={altName}
        src={avatarUrl}
        size="l"
        data-testid={`provider-avatar-${providerId}`}
        onClick={canClick ? clickHandler : undefined}
      />
      {providerId && (
        <ProviderModal
          id={providerId}
          open={showProviderModal}
          onClose={() => setShowProviderModal(false)}
        />
      )}
    </>
  );
};

export default ProviderAvatar;
