// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientClinicsSchemaClinics,
    ClientClinicsSchemaClinicsFromJSON,
    ClientClinicsSchemaClinicsToJSON,
} from './ClientClinicsSchemaClinics';


/**
 * 
 * @export
 * @interface ClientClinicsSchema
 */
export interface ClientClinicsSchema  {
    /**
     * Employer ef_id
     * @type {string}
     * @memberof ClientClinicsSchema
     */
    efId?: string;
    /**
     * Employer name
     * @type {string}
     * @memberof ClientClinicsSchema
     */
    name?: string;
    /**
     * 
     * @type {Array<ClientClinicsSchemaClinics>}
     * @memberof ClientClinicsSchema
     */
    clinics?: Array<ClientClinicsSchemaClinics>;
}

export function ClientClinicsSchemaFromJSON(json: any): ClientClinicsSchema {
    return {
        'efId': !exists(json, 'ef_id') ? undefined : json['ef_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'clinics': !exists(json, 'clinics') ? undefined : (json['clinics'] as Array<any>).map(ClientClinicsSchemaClinicsFromJSON),
    };
}

export function ClientClinicsSchemaToJSON(value?: ClientClinicsSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'ef_id': value.efId,
        'name': value.name,
        'clinics': value.clinics === undefined ? undefined : (value.clinics as Array<any>).map(ClientClinicsSchemaClinicsToJSON),
    };
}


