// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberClinic,
    MemberClinicFromJSON,
    MemberClinicToJSON,
} from './MemberClinic';


/**
 * 
 * @export
 * @interface MemberSearchDetails
 */
export interface MemberSearchDetails  {
    /**
     * The member\'s ID.
     * @type {string}
     * @memberof MemberSearchDetails
     */
    id?: string;
    /**
     * The member\'s first name.
     * @type {string}
     * @memberof MemberSearchDetails
     */
    firstName?: string;
    /**
     * The member\'s last name.
     * @type {string}
     * @memberof MemberSearchDetails
     */
    lastName?: string;
    /**
     * The member\'s preferred name.
     * @type {string}
     * @memberof MemberSearchDetails
     */
    preferredName?: string;
    /**
     * The member\'s phone number.
     * @type {string}
     * @memberof MemberSearchDetails
     */
    phoneNumber?: string;
    /**
     * The member\'s date of birth.
     * @type {string}
     * @memberof MemberSearchDetails
     */
    dob?: string;
    /**
     * The member\'s sex.
     * @type {string}
     * @memberof MemberSearchDetails
     */
    sex?: string;
    /**
     * The member\'s age.
     * @type {string}
     * @memberof MemberSearchDetails
     */
    age?: string;
    /**
     * The member\'s employee ID.
     * @type {string}
     * @memberof MemberSearchDetails
     */
    employeeId?: string;
    /**
     * 
     * @type {MemberClinic}
     * @memberof MemberSearchDetails
     */
    defaultClinic?: MemberClinic;
    /**
     * The members\'s extra registration id
     * @type {string}
     * @memberof MemberSearchDetails
     */
    extraRegistrationId?: string;
}

export function MemberSearchDetailsFromJSON(json: any): MemberSearchDetails {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'preferredName': !exists(json, 'preferred_name') ? undefined : json['preferred_name'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'age': !exists(json, 'age') ? undefined : json['age'],
        'employeeId': !exists(json, 'employee_id') ? undefined : json['employee_id'],
        'defaultClinic': !exists(json, 'default_clinic') ? undefined : MemberClinicFromJSON(json['default_clinic']),
        'extraRegistrationId': !exists(json, 'extra_registration_id') ? undefined : json['extra_registration_id'],
    };
}

export function MemberSearchDetailsToJSON(value?: MemberSearchDetails): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'preferred_name': value.preferredName,
        'phone_number': value.phoneNumber,
        'dob': value.dob,
        'sex': value.sex,
        'age': value.age,
        'employee_id': value.employeeId,
        'default_clinic': MemberClinicToJSON(value.defaultClinic),
        'extra_registration_id': value.extraRegistrationId,
    };
}


