import { fetchEpisodeWorkspaceDraft } from "shared/api/src/apis/WorkspaceDraftsApi";
import { OutputWorkspaceDraftSchema, OutputWorkspaceDraftSchemaFromJSON } from "shared/api/src/models/OutputWorkspaceDraftSchema";
import { ComponentDetailsOutputFromJSON } from "shared/fetch/src/models/ComponentDetailsOutput";
import { UIDraft } from "../ui/workspace";
import { queryClient } from "../StateManagement";
import { fetchComponentDetailsQueryKey } from "shared/features/components/useGetComponentDetails";

export const fetchDraftRequest = (id: string) =>
  fetchEpisodeWorkspaceDraft(
    {
      id,
    },
    {
      transform: (body: OutputWorkspaceDraftSchema) => {
        return {
          draft: body as unknown as UIDraft,
        };
      },
      update: {
        draft: (_prev: UIDraft, next: UIDraft) => next,
      },
      force: true,
    }
  );

export const actionRequest = (
  url: string,
  method: string,
  bodyRequest?: any
) => {
  return {
    url,
    options: {
      method,

      headers: {},
    },
    meta: {
      authType: ["oauth"],
    },
    transform: (body: any) => {
      const parsedOutput = ComponentDetailsOutputFromJSON(body);
      queryClient.invalidateQueries(
        fetchComponentDetailsQueryKey({ id: parsedOutput.id })
      );
      return {
        actionResponse: parsedOutput,
        component: { [parsedOutput.id]: parsedOutput },
        draft: body.workspaceDraft,
      };
    },
    body: bodyRequest,
    update: {
      actionResponse: (_prev: any, next: any) => next, // this assumes that the  actionResponse is allows a `ComponentDetailsOutput`
      component: (prev: any, next: any) => ({ ...prev, ...next }),
      draft: (prev: any, next: any) =>
        next ? OutputWorkspaceDraftSchemaFromJSON(next) : prev,
    },
  };
};
