import palette from "../palette";

export default {
  contained: {
    "&:hover, &:active": {
      boxShadow: "none",
    },
    boxShadow: "none",
    paddingLeft: 24,
    paddingRight: 24,
    width: "auto",
    minHeight: "auto",
    borderRadius: 20,
    color: (props: any) => {
      if (!props.color) {
        return palette.text.primary;
      }
    },
    "&:disabled": {
      background: palette.text.strokes,
      color: palette.text.primary,
      opacity: 1,
    },
  },
  containedPrimary: {
    color: palette.text.primary,
    "&:hover": {
      color: palette.darkestGreyHover,
    },
  },
  outlinedSecondary: {
    border: `1px solid ${palette.primary.main}`,
    color: "white",
    "&:hover": {
      borderColor: palette?.primary?.dark,
      backgroundColor: "rgba(255,255,255, .10)",
    },
  },
  outlined: {
    "&:hover": {
      boxShadow: "none",
    },
    boxShadow: "none",
    paddingLeft: 24,
    paddingRight: 24,
    width: "auto",
    minHeight: "auto",
    borderRadius: 20,
  },
  outlinedPrimary: {
    border: `1px solid ${palette.primary.main}`,
    color: palette.text.primary,
    "&:hover": {
      backgroundColor: palette?.primary?.dark,
      border: `1px solid ${palette?.primary?.dark}`,
      color: palette.darkestGreyHover,
    },
  },
  textPrimary: {
    "&:hover": {
      color: palette?.primary?.dark,
      backgroundColor: "transparent",
    },
  },
  text: {
    padding: "auto",
  },
  sizeLarge: {
    paddingLeft: 28,
    paddingRight: 28,
  },
};
