// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberPaymentMethods
 */
export interface MemberPaymentMethods  {
    /**
     * 
     * @type {number}
     * @memberof MemberPaymentMethods
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentMethods
     */
    cardId?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentMethods
     */
    cardLabel?: string;
    /**
     * 
     * @type {Date}
     * @memberof MemberPaymentMethods
     */
    deletedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MemberPaymentMethods
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MemberPaymentMethods
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentMethods
     */
    cardBrand?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentMethods
     */
    url?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberPaymentMethods
     */
    primary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberPaymentMethods
     */
    last4?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberPaymentMethods
     */
    cardAccountType?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MemberPaymentMethods
     */
    expMonth?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberPaymentMethods
     */
    expYear?: number;
}

export function MemberPaymentMethodsFromJSON(json: any): MemberPaymentMethods {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'cardId': !exists(json, 'card_id') ? undefined : json['card_id'],
        'cardLabel': !exists(json, 'card_label') ? undefined : json['card_label'],
        'deletedAt': !exists(json, 'deleted_at') ? undefined : new Date(json['deleted_at']),
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'cardBrand': !exists(json, 'card_brand') ? undefined : json['card_brand'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'primary': !exists(json, 'primary') ? undefined : json['primary'],
        'last4': !exists(json, 'last4') ? undefined : json['last4'],
        'cardAccountType': !exists(json, 'card_account_type') ? undefined : json['card_account_type'],
        'expMonth': !exists(json, 'exp_month') ? undefined : json['exp_month'],
        'expYear': !exists(json, 'exp_year') ? undefined : json['exp_year'],
    };
}

export function MemberPaymentMethodsToJSON(value?: MemberPaymentMethods): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'card_id': value.cardId,
        'card_label': value.cardLabel,
        'deleted_at': value.deletedAt === undefined ? undefined : value.deletedAt.toISOString(),
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'card_brand': value.cardBrand,
        'url': value.url,
        'primary': value.primary,
        'last4': value.last4,
        'card_account_type': value.cardAccountType,
        'exp_month': value.expMonth,
        'exp_year': value.expYear,
    };
}


