// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentOutputSchema
 */
export interface DocumentOutputSchema  {
    /**
     * 
     * @type {string}
     * @memberof DocumentOutputSchema
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentOutputSchema
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentOutputSchema
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof DocumentOutputSchema
     */
    size: number;
    /**
     * 
     * @type {Date}
     * @memberof DocumentOutputSchema
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof DocumentOutputSchema
     */
    url: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentOutputSchema
     */
    unverified?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DocumentOutputSchema
     */
    componentId?: number;
    /**
     * 
     * @type {string}
     * @memberof DocumentOutputSchema
     */
    mime: string;
    /**
     * 
     * @type {string}
     * @memberof DocumentOutputSchema
     */
    thumbnailUrl?: string;
}

export function DocumentOutputSchemaFromJSON(json: any): DocumentOutputSchema {
    return {
        'id': json['id'],
        'title': json['title'],
        'name': json['name'],
        'size': json['size'],
        'createdAt': new Date(json['created_at']),
        'url': json['url'],
        'unverified': !exists(json, 'unverified') ? undefined : json['unverified'],
        'componentId': !exists(json, 'component_id') ? undefined : json['component_id'],
        'mime': json['mime'],
        'thumbnailUrl': !exists(json, 'thumbnail_url') ? undefined : json['thumbnail_url'],
    };
}

export function DocumentOutputSchemaToJSON(value?: DocumentOutputSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'title': value.title,
        'name': value.name,
        'size': value.size,
        'created_at': value.createdAt.toISOString(),
        'url': value.url,
        'unverified': value.unverified,
        'component_id': value.componentId,
        'mime': value.mime,
        'thumbnail_url': value.thumbnailUrl,
    };
}


