// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputMemberSearchSchemaPropsEpisodes
 */
export interface OutputMemberSearchSchemaPropsEpisodes  {
    /**
     * 
     * @type {number}
     * @memberof OutputMemberSearchSchemaPropsEpisodes
     */
    id?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputMemberSearchSchemaPropsEpisodes
     */
    serviceLines?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof OutputMemberSearchSchemaPropsEpisodes
     */
    lastUpdatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof OutputMemberSearchSchemaPropsEpisodes
     */
    originalServiceLine?: string;
}

export function OutputMemberSearchSchemaPropsEpisodesFromJSON(json: any): OutputMemberSearchSchemaPropsEpisodes {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'serviceLines': !exists(json, 'service_lines') ? undefined : json['service_lines'],
        'lastUpdatedAt': !exists(json, 'last_updated_at') ? undefined : new Date(json['last_updated_at']),
        'originalServiceLine': !exists(json, 'original_service_line') ? undefined : json['original_service_line'],
    };
}

export function OutputMemberSearchSchemaPropsEpisodesToJSON(value?: OutputMemberSearchSchemaPropsEpisodes): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'service_lines': value.serviceLines,
        'last_updated_at': value.lastUpdatedAt === undefined ? undefined : value.lastUpdatedAt.toISOString().substr(0,10),
        'original_service_line': value.originalServiceLine,
    };
}


