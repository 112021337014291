// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EpisodeCreationInputProps
 */
export interface EpisodeCreationInputProps  {
    /**
     * 
     * @type {string}
     * @memberof EpisodeCreationInputProps
     */
    memberId: string;
    /**
     * 
     * @type {string}
     * @memberof EpisodeCreationInputProps
     */
    serviceSubtype?: string;
    /**
     * 
     * @type {string}
     * @memberof EpisodeCreationInputProps
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof EpisodeCreationInputProps
     */
    recipientId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EpisodeCreationInputProps
     */
    draft?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EpisodeCreationInputProps
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof EpisodeCreationInputProps
     */
    subjectFreetext?: string;
    /**
     * 
     * @type {string}
     * @memberof EpisodeCreationInputProps
     */
    recipientPodId?: string;
    /**
     * 
     * @type {string}
     * @memberof EpisodeCreationInputProps
     */
    memberCurrentUsState?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EpisodeCreationInputProps
     */
    ccCtmIds?: Array<string>;
}

export function EpisodeCreationInputPropsFromJSON(json: any): EpisodeCreationInputProps {
    return {
        'memberId': json['member_id'],
        'serviceSubtype': !exists(json, 'service_subtype') ? undefined : json['service_subtype'],
        'body': json['body'],
        'recipientId': !exists(json, 'recipient_id') ? undefined : json['recipient_id'],
        'draft': !exists(json, 'draft') ? undefined : json['draft'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'subjectFreetext': !exists(json, 'subject_freetext') ? undefined : json['subject_freetext'],
        'recipientPodId': !exists(json, 'recipient_pod_id') ? undefined : json['recipient_pod_id'],
        'memberCurrentUsState': !exists(json, 'member_current_us_state') ? undefined : json['member_current_us_state'],
        'ccCtmIds': !exists(json, 'cc_ctm_ids') ? undefined : json['cc_ctm_ids'],
    };
}

export function EpisodeCreationInputPropsToJSON(value?: EpisodeCreationInputProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'member_id': value.memberId,
        'service_subtype': value.serviceSubtype,
        'body': value.body,
        'recipient_id': value.recipientId,
        'draft': value.draft,
        'subject': value.subject,
        'subject_freetext': value.subjectFreetext,
        'recipient_pod_id': value.recipientPodId,
        'member_current_us_state': value.memberCurrentUsState,
        'cc_ctm_ids': value.ccCtmIds,
    };
}


