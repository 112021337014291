// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PushNotificationCategoryResponse
 */
export interface PushNotificationCategoryResponse  {
    /**
     * 
     * @type {number}
     * @memberof PushNotificationCategoryResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PushNotificationCategoryResponse
     */
    categoryName: string;
    /**
     * 
     * @type {string}
     * @memberof PushNotificationCategoryResponse
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PushNotificationCategoryResponse
     */
    enrolled?: boolean;
}

export function PushNotificationCategoryResponseFromJSON(json: any): PushNotificationCategoryResponse {
    return {
        'id': json['id'],
        'categoryName': json['category_name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'enrolled': !exists(json, 'enrolled') ? undefined : json['enrolled'],
    };
}

export function PushNotificationCategoryResponseToJSON(value?: PushNotificationCategoryResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'category_name': value.categoryName,
        'description': value.description,
        'enrolled': value.enrolled,
    };
}


