// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatientInsuranceObject
 */
export interface PatientInsuranceObject  {
    /**
     * 
     * @type {string}
     * @memberof PatientInsuranceObject
     */
    medicalPlan: string;
    /**
     * 
     * @type {string}
     * @memberof PatientInsuranceObject
     */
    relationship: string;
    /**
     * 
     * @type {string}
     * @memberof PatientInsuranceObject
     */
    medicalPolicy: string;
    /**
     * 
     * @type {string}
     * @memberof PatientInsuranceObject
     */
    insurancePlan: string;
    /**
     * 
     * @type {string}
     * @memberof PatientInsuranceObject
     */
    insuranceHolder: string;
    /**
     * 
     * @type {string}
     * @memberof PatientInsuranceObject
     */
    insuranceMemberid: string;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof PatientInsuranceObject
     */
    insurance: { [key: string]: object; };
    /**
     * 
     * @type {Date}
     * @memberof PatientInsuranceObject
     */
    dateRefreshed: Date;
}

export function PatientInsuranceObjectFromJSON(json: any): PatientInsuranceObject {
    return {
        'medicalPlan': json['medical_plan'],
        'relationship': json['relationship'],
        'medicalPolicy': json['medical_policy'],
        'insurancePlan': json['insurance_plan'],
        'insuranceHolder': json['insurance_holder'],
        'insuranceMemberid': json['insurance_memberid'],
        'insurance': json['insurance'],
        'dateRefreshed': new Date(json['date_refreshed']),
    };
}

export function PatientInsuranceObjectToJSON(value?: PatientInsuranceObject): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'medical_plan': value.medicalPlan,
        'relationship': value.relationship,
        'medical_policy': value.medicalPolicy,
        'insurance_plan': value.insurancePlan,
        'insurance_holder': value.insuranceHolder,
        'insurance_memberid': value.insuranceMemberid,
        'insurance': value.insurance,
        'date_refreshed': value.dateRefreshed.toISOString(),
    };
}


