// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    WorkspaceDraftDataSchemaCTMMessage,
    WorkspaceDraftDataSchemaCTMMessageFromJSON,
    WorkspaceDraftDataSchemaCTMMessageToJSON,
} from './WorkspaceDraftDataSchemaCTMMessage';
import {
    WorkspaceDraftDataSchemaCalculator,
    WorkspaceDraftDataSchemaCalculatorFromJSON,
    WorkspaceDraftDataSchemaCalculatorToJSON,
} from './WorkspaceDraftDataSchemaCalculator';
import {
    WorkspaceDraftDataSchemaCenterVisitReferral,
    WorkspaceDraftDataSchemaCenterVisitReferralFromJSON,
    WorkspaceDraftDataSchemaCenterVisitReferralToJSON,
} from './WorkspaceDraftDataSchemaCenterVisitReferral';
import {
    WorkspaceDraftDataSchemaDiagnosis,
    WorkspaceDraftDataSchemaDiagnosisFromJSON,
    WorkspaceDraftDataSchemaDiagnosisToJSON,
} from './WorkspaceDraftDataSchemaDiagnosis';
import {
    WorkspaceDraftDataSchemaQuestionSet,
    WorkspaceDraftDataSchemaQuestionSetFromJSON,
    WorkspaceDraftDataSchemaQuestionSetToJSON,
} from './WorkspaceDraftDataSchemaQuestionSet';
import {
    WorkspaceDraftDataSchemaVirtualVisitReferral,
    WorkspaceDraftDataSchemaVirtualVisitReferralFromJSON,
    WorkspaceDraftDataSchemaVirtualVisitReferralToJSON,
} from './WorkspaceDraftDataSchemaVirtualVisitReferral';
import {
    WorkspaceDraftDataSchemaVisitReferral,
    WorkspaceDraftDataSchemaVisitReferralFromJSON,
    WorkspaceDraftDataSchemaVisitReferralToJSON,
} from './WorkspaceDraftDataSchemaVisitReferral';

/**
 * @type WorkspaceDraftDataSchemaItem
 * @export
 */
export type WorkspaceDraftDataSchemaItem = WorkspaceDraftDataSchemaCTMMessage | WorkspaceDraftDataSchemaCalculator | WorkspaceDraftDataSchemaCenterVisitReferral | WorkspaceDraftDataSchemaDiagnosis | WorkspaceDraftDataSchemaQuestionSet | WorkspaceDraftDataSchemaVirtualVisitReferral | WorkspaceDraftDataSchemaVisitReferral;


export function WorkspaceDraftDataSchemaItemFromJSON(json: any): WorkspaceDraftDataSchemaItem {
  switch (json.type) {
  case 'ctm_message': return WorkspaceDraftDataSchemaCTMMessageFromJSON(json);
  case 'virtual_visit_referral': return WorkspaceDraftDataSchemaVirtualVisitReferralFromJSON(json);
  case 'visit_referral': return WorkspaceDraftDataSchemaVisitReferralFromJSON(json);
  case 'center_visit_referral': return WorkspaceDraftDataSchemaCenterVisitReferralFromJSON(json);
  case 'question_set': return WorkspaceDraftDataSchemaQuestionSetFromJSON(json);
  case 'calculator': return WorkspaceDraftDataSchemaCalculatorFromJSON(json);
  case 'diagnosis': return WorkspaceDraftDataSchemaDiagnosisFromJSON(json);
  default: throw new Error("Unexpected type value.");
  }
}

export function WorkspaceDraftDataSchemaItemToJSON(value?: WorkspaceDraftDataSchemaItem): any {
    if (value === undefined) {
        return undefined;
    }

    switch (value.type) {
    case 'ctm_message': return WorkspaceDraftDataSchemaCTMMessageToJSON(<WorkspaceDraftDataSchemaCTMMessage>value);
    case 'virtual_visit_referral': return WorkspaceDraftDataSchemaVirtualVisitReferralToJSON(<WorkspaceDraftDataSchemaVirtualVisitReferral>value);
    case 'visit_referral': return WorkspaceDraftDataSchemaVisitReferralToJSON(<WorkspaceDraftDataSchemaVisitReferral>value);
    case 'center_visit_referral': return WorkspaceDraftDataSchemaCenterVisitReferralToJSON(<WorkspaceDraftDataSchemaCenterVisitReferral>value);
    case 'question_set': return WorkspaceDraftDataSchemaQuestionSetToJSON(<WorkspaceDraftDataSchemaQuestionSet>value);
    case 'calculator': return WorkspaceDraftDataSchemaCalculatorToJSON(<WorkspaceDraftDataSchemaCalculator>value);
    case 'diagnosis': return WorkspaceDraftDataSchemaDiagnosisToJSON(<WorkspaceDraftDataSchemaDiagnosis>value);
    default: throw new Error("Unexpected type value.");
    }
}

