import { useMutation } from "react-query";
import {
  fetchPatientAccountApi,
  V1PatientPostRequest,
} from "shared/fetch/src/apis/PatientAccountApi";
import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";

const useActivatePatientAccount = (minorFirstName?: string | undefined) => {
  const dispatch = useDispatch();

  const activatePatientAccount = useMutation(
    (request: V1PatientPostRequest) =>
      fetchPatientAccountApi.v1PatientPost(request),
    {
      onSuccess: (_response, request) => {
        if (minorFirstName) {
          dispatch(
            showSnackbar(
              `Success! ${minorFirstName}'s account is activated and nested as a proxy account`,
              "success"
            )
          );
        } else {
          if (!request?.greenPlusPatientRequestBody?.email) {
            dispatch(
              showSnackbar(
                "Account successfully activated. The teen does not have access to their account until the reset password flow is completed.",
                "success"
              )
            );
          } else {
            dispatch(
              showSnackbar(
                "Account successfully activated and create password link sent!",
                "success"
              )
            );
          }
        }
      },
      onError: () => {
        dispatch(
          showSnackbar(
            "Error activating account, please contact the support team.",
            "danger"
          )
        );
      },
    }
  );
  return activatePatientAccount;
};

export default useActivatePatientAccount;
