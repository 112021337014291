import React from "react";
import Typography from "components/Typography";
import { CTMInfo } from "shared/fetch/src/models/CTMInfo";

export default function VisitCardProviderName({
  provider,
}: {
  provider: CTMInfo;
}) {
  return (
    <Typography
      appearance="smallBody"
      component="span"
      data-testid="details"
      fontWeightNormal
    >
      {provider?.name}
    </Typography>
  );
}
