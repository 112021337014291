// temporary date picker until shared XOCal header is available:
import React from "react";
import { addDays, addWeeks, subWeeks } from "date-fns";
import MenuItem from "components/Menu/MenuItem";
import TextField from "components/TextField";
import formatDate from "utils/formatDate";

interface ITempDatePicker {
  date: string;
  handleChange: (selectedDate: string) => void;
}

const TempDatePicker: React.FC<ITempDatePicker> = ({ date, handleChange }) => {
  // Generate dates starting two weeks before today and ending two weeks after today
  const today = new Date();
  const startDate = subWeeks(today, 2);
  const endDate = addWeeks(today, 2);
  const generateDates = (start: any, end: any) => {
    const dates = [];
    let currentDate = start;
    while (currentDate <= end) {
      dates.push(currentDate);
      currentDate = addDays(currentDate, 1);
    }
    return dates;
  };

  const dateOptions = generateDates(startDate, endDate);

  return (
    <div style={{ width: "40%" }}>
      <TextField
        select
        fullWidth
        variant="filled"
        label="Select a date"
        SelectProps={{
          value: date,
        }}
      >
        {dateOptions.map((dateOption) => {
          const value = formatDate(dateOption, "yyyyMMdd") as string;
          const label = formatDate(dateOption, "MM/dd/yyyy");
          return (
            <MenuItem
              key={value}
              value={value}
              onClick={() => {
                handleChange(value);
              }}
            >
              {label}
            </MenuItem>
          );
        })}
      </TextField>
    </div>
  );
};

export default TempDatePicker;
