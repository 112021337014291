// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ComponentDetailsCoreResolverInfo
 */
export interface ComponentDetailsCoreResolverInfo  {
    /**
     * 
     * @type {string}
     * @memberof ComponentDetailsCoreResolverInfo
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetailsCoreResolverInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetailsCoreResolverInfo
     */
    legalName?: string;
}

export function ComponentDetailsCoreResolverInfoFromJSON(json: any): ComponentDetailsCoreResolverInfo {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'legalName': !exists(json, 'legal_name') ? undefined : json['legal_name'],
    };
}

export function ComponentDetailsCoreResolverInfoToJSON(value?: ComponentDetailsCoreResolverInfo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'legal_name': value.legalName,
    };
}


