import React from "react";
import { selectLoggedInUser } from "shared/features/user/selectors";
import useConsumerController from "../utils/useConsumerController";

const useConsumerPastDueMsg = () => {
  const user = selectLoggedInUser();

  const { isConsumerMemberType, isPastDue } = useConsumerController(
    // @ts-ignore: Object is possibly 'null'.
    user?.id?.toString()
  );

  if (user?.ctm || !isConsumerMemberType || !isPastDue) {
    return {};
  }

  return {
    message: (
      <>
        <span data-testid="past-due-message">
          We weren’t able to charge your default payment method for your
          membership. Please update your payment method and make a payment today
          to avoid an interruption of your membership.
        </span>
      </>
    ),
    orderDate: null,
  };
};

export default useConsumerPastDueMsg;
