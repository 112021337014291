// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthorizeUserCookieRequestBody
 */
export interface AuthorizeUserCookieRequestBody  {
    /**
     * 
     * @type {boolean}
     * @memberof AuthorizeUserCookieRequestBody
     */
    cookiesAcknowledged?: boolean;
}

export function AuthorizeUserCookieRequestBodyFromJSON(json: any): AuthorizeUserCookieRequestBody {
    return {
        'cookiesAcknowledged': !exists(json, 'cookies_acknowledged') ? undefined : json['cookies_acknowledged'],
    };
}

export function AuthorizeUserCookieRequestBodyToJSON(value?: AuthorizeUserCookieRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'cookies_acknowledged': value.cookiesAcknowledged,
    };
}


