/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CTMBioProfile,
    CTMBioProfileFromJSON,
    CTMBioProfileToJSON,
} from '../models/CTMBioProfile'
import {
    ContactProviderOutput,
    ContactProviderOutputFromJSON,
    ContactProviderOutputToJSON,
} from '../models/ContactProviderOutput'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
} from '../models/InlineResponse200'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    MemberNetworkOutput,
    MemberNetworkOutputFromJSON,
    MemberNetworkOutputToJSON,
} from '../models/MemberNetworkOutput'
import {
    NetworkCareTeamFiltered,
    NetworkCareTeamFilteredFromJSON,
    NetworkCareTeamFilteredToJSON,
} from '../models/NetworkCareTeamFiltered'
import {
    NetworkCareTeamsOutputFiltered,
    NetworkCareTeamsOutputFilteredFromJSON,
    NetworkCareTeamsOutputFilteredToJSON,
} from '../models/NetworkCareTeamsOutputFiltered'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface GetCareTeamRequest {
    id: string;
    xopPodId?: string;
}
export interface GetContactsRequest {
    id: string;
    xopPodId?: string;
}
export interface GetExtendedCareTeamRequest {
    id: string;
    xopPodId?: string;
}
export interface GetMemberNetworkByPodIdRequest {
    id: string;
    podId: string;
    hasProviders?: boolean;
}
export interface GetNetworkRequest {
    id: string;
    xopPodId?: string;
    networkCategory?: GetNetworkNetworkCategoryEnum;
}
export interface XopCtmProfileRequest {
    id: string;
}


export interface IMeetYourCareTeamApi {
      getCareTeam: (requestParameters: GetCareTeamRequest) => Promise<NetworkCareTeamFiltered>
      getContacts: (requestParameters: GetContactsRequest) => Promise<ContactProviderOutput>
      getExtendedCareTeam: (requestParameters: GetExtendedCareTeamRequest) => Promise<NetworkCareTeamsOutputFiltered>
      getMemberNetworkByPodId: (requestParameters: GetMemberNetworkByPodIdRequest) => Promise<InlineResponse200>
      getNetwork: (requestParameters: GetNetworkRequest) => Promise<MemberNetworkOutput>
      xopCtmProfile: (requestParameters: XopCtmProfileRequest) => Promise<CTMBioProfile>
      getCareTeamWithResponse: (requestParameters: GetCareTeamRequest) => Promise<{ value: NetworkCareTeamFiltered, response: any}>
      getContactsWithResponse: (requestParameters: GetContactsRequest) => Promise<{ value: ContactProviderOutput, response: any}>
      getExtendedCareTeamWithResponse: (requestParameters: GetExtendedCareTeamRequest) => Promise<{ value: NetworkCareTeamsOutputFiltered, response: any}>
      getMemberNetworkByPodIdWithResponse: (requestParameters: GetMemberNetworkByPodIdRequest) => Promise<{ value: InlineResponse200, response: any}>
      getNetworkWithResponse: (requestParameters: GetNetworkRequest) => Promise<{ value: MemberNetworkOutput, response: any}>
      xopCtmProfileWithResponse: (requestParameters: XopCtmProfileRequest) => Promise<{ value: CTMBioProfile, response: any}>
}

/**
 * no description
 */
export class MeetYourCareTeamApi extends runtime.BaseAPI {

    /**
     * Fetch Member Care Team
     * Fetch Member Care Team
     */
    getCareTeamRaw = async (requestParameters: GetCareTeamRequest): Promise<{ runtime: runtime.ApiResponse<NetworkCareTeamFiltered>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCareTeam.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.xopPodId !== undefined) {
            queryParameters['xop_pod_id'] = requestParameters.xopPodId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/my_care_team`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => NetworkCareTeamFilteredFromJSON(jsonValue)), response };
    }

    /**
     * Fetch Member Care Team
     * Fetch Member Care Team
     */
    getCareTeam = async (requestParameters: GetCareTeamRequest): Promise<NetworkCareTeamFiltered> => {
        const { runtime } = await this.getCareTeamRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch Member Care Team
     * Fetch Member Care Team
     */
    getCareTeamWithResponse = async (requestParameters: GetCareTeamRequest): Promise<{ value: NetworkCareTeamFiltered, response: any}> => {
        const { runtime, response } = await this.getCareTeamRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch Member Contacts
     * Fetch Member Contacts
     */
    getContactsRaw = async (requestParameters: GetContactsRequest): Promise<{ runtime: runtime.ApiResponse<ContactProviderOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getContacts.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.xopPodId !== undefined) {
            queryParameters['xop_pod_id'] = requestParameters.xopPodId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/my_contacts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ContactProviderOutputFromJSON(jsonValue)), response };
    }

    /**
     * Fetch Member Contacts
     * Fetch Member Contacts
     */
    getContacts = async (requestParameters: GetContactsRequest): Promise<ContactProviderOutput> => {
        const { runtime } = await this.getContactsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch Member Contacts
     * Fetch Member Contacts
     */
    getContactsWithResponse = async (requestParameters: GetContactsRequest): Promise<{ value: ContactProviderOutput, response: any}> => {
        const { runtime, response } = await this.getContactsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch Member Extended Care Team
     * Fetch Member Extended Care Team
     */
    getExtendedCareTeamRaw = async (requestParameters: GetExtendedCareTeamRequest): Promise<{ runtime: runtime.ApiResponse<NetworkCareTeamsOutputFiltered>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getExtendedCareTeam.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.xopPodId !== undefined) {
            queryParameters['xop_pod_id'] = requestParameters.xopPodId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/my_extended_care_team`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => NetworkCareTeamsOutputFilteredFromJSON(jsonValue)), response };
    }

    /**
     * Fetch Member Extended Care Team
     * Fetch Member Extended Care Team
     */
    getExtendedCareTeam = async (requestParameters: GetExtendedCareTeamRequest): Promise<NetworkCareTeamsOutputFiltered> => {
        const { runtime } = await this.getExtendedCareTeamRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch Member Extended Care Team
     * Fetch Member Extended Care Team
     */
    getExtendedCareTeamWithResponse = async (requestParameters: GetExtendedCareTeamRequest): Promise<{ value: NetworkCareTeamsOutputFiltered, response: any}> => {
        const { runtime, response } = await this.getExtendedCareTeamRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch Member Network by Pod ID
     * Fetch Member Network by Pod ID
     */
    getMemberNetworkByPodIdRaw = async (requestParameters: GetMemberNetworkByPodIdRequest): Promise<{ runtime: runtime.ApiResponse<InlineResponse200>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMemberNetworkByPodId.');
        }

                        if (requestParameters.podId === null || requestParameters.podId === undefined) {
            throw new runtime.RequiredError('podId','Required parameter requestParameters.podId was null or undefined when calling getMemberNetworkByPodId.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.hasProviders !== undefined) {
            queryParameters['has_providers'] = requestParameters.hasProviders;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/network/{pod_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"pod_id"}}`, encodeURIComponent(String(requestParameters.podId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue)), response };
    }

    /**
     * Fetch Member Network by Pod ID
     * Fetch Member Network by Pod ID
     */
    getMemberNetworkByPodId = async (requestParameters: GetMemberNetworkByPodIdRequest): Promise<InlineResponse200> => {
        const { runtime } = await this.getMemberNetworkByPodIdRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch Member Network by Pod ID
     * Fetch Member Network by Pod ID
     */
    getMemberNetworkByPodIdWithResponse = async (requestParameters: GetMemberNetworkByPodIdRequest): Promise<{ value: InlineResponse200, response: any}> => {
        const { runtime, response } = await this.getMemberNetworkByPodIdRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch Member Network
     * Fetch Member Network
     */
    getNetworkRaw = async (requestParameters: GetNetworkRequest): Promise<{ runtime: runtime.ApiResponse<MemberNetworkOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNetwork.');
        }

                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.xopPodId !== undefined) {
            queryParameters['xop_pod_id'] = requestParameters.xopPodId;
        }

        if (requestParameters.networkCategory !== undefined) {
            queryParameters['network_category'] = requestParameters.networkCategory;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/network`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberNetworkOutputFromJSON(jsonValue)), response };
    }

    /**
     * Fetch Member Network
     * Fetch Member Network
     */
    getNetwork = async (requestParameters: GetNetworkRequest): Promise<MemberNetworkOutput> => {
        const { runtime } = await this.getNetworkRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch Member Network
     * Fetch Member Network
     */
    getNetworkWithResponse = async (requestParameters: GetNetworkRequest): Promise<{ value: MemberNetworkOutput, response: any}> => {
        const { runtime, response } = await this.getNetworkRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch CTM profile
     */
    xopCtmProfileRaw = async (requestParameters: XopCtmProfileRequest): Promise<{ runtime: runtime.ApiResponse<CTMBioProfile>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling xopCtmProfile.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/xop_ctm_profiles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CTMBioProfileFromJSON(jsonValue)), response };
    }

    /**
     * Fetch CTM profile
     */
    xopCtmProfile = async (requestParameters: XopCtmProfileRequest): Promise<CTMBioProfile> => {
        const { runtime } = await this.xopCtmProfileRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch CTM profile
     */
    xopCtmProfileWithResponse = async (requestParameters: XopCtmProfileRequest): Promise<{ value: CTMBioProfile, response: any}> => {
        const { runtime, response } = await this.xopCtmProfileRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetNetworkNetworkCategoryEnum {
    Contacts = 'my_contacts',
    CareTeam = 'my_care_team',
    ExtendedTeams = 'my_extended_teams'
}

export const fetchMeetYourCareTeamApi: IMeetYourCareTeamApi  = new MeetYourCareTeamApi();
