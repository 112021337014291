import React, { useMemo } from "react";
import { selectLoggedInUser } from "../../../shared/features/user/selectors";
import useGetAdultDependents from "shared/features/dependents/useGetAdultDependents";
import { JsonUser as User } from "shared/api/src/models/JsonUser";
import { reduce } from "lodash";
import { DependentRegistrationStatusEnum as DepRegStatus } from "shared/fetch/src/models/Dependent";

export const formatDate = (date: string) => {
  const splitDate = date.split("-");
  const currentYear = new Date().getFullYear();
  const agingupDate = `${splitDate[1]}-${splitDate[2]}-${currentYear}`;
  return new Date(agingupDate).toLocaleDateString("en-us", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};
const useAocLevelUpBanner = () => {
  const user: User = selectLoggedInUser();
  const { adultDependents: dependents, isLoading } = useGetAdultDependents({
    id: user?.id?.toString() || "",
  });
  const minorDetails = useMemo(() => {
    const levelUpMinor = dependents?.results?.filter(
      (dependent) =>
        !!dependent?.minor &&
        !!dependent?.agingOut &&
        dependent?.agingOut === "aoc" &&
        dependent.registrationStatus === DepRegStatus.Registered
    );

    if (!levelUpMinor?.length) {
      return false;
    }

    const names = reduce(
      levelUpMinor,
      function (result, minor, idx) {
        const nameToUse = !!minor.preferredName
          ? minor.preferredName
          : minor.firstName;
        if (
          !!levelUpMinor?.length &&
          idx === levelUpMinor?.length - 1 &&
          levelUpMinor.length !== 1
        ) {
          result += `and ${nameToUse}'s `;
        } else {
          result += `${nameToUse}'s `;
        }
        return result;
      },
      ""
    );

    return {
      names,
      ageOfConsent: levelUpMinor?.[0]?.ageOfConsent,
      formattedDate: formatDate(levelUpMinor?.[0]?.dob!),
    };
  }, [isLoading, dependents]);

  if (isLoading || !minorDetails) {
    return {};
  }

  return {
    message: (
      <span data-testid="aoc-banner-text">
        Your access to {minorDetails.names} account will expire on{" "}
        {minorDetails?.formattedDate} due to minor privacy laws. Please call
        your clinic to request proxy access.{" "}
        <a
          href="https://crossoverhealth.com/legal/minors"
          style={{ color: "white", textDecoration: "underline" }}
        >
          See here
        </a>{" "}
        for proxy information.
      </span>
    ),
    orderDate: null,
  };
};
export default useAocLevelUpBanner;
