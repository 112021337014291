import React from "react";
import ButtonBase from "components/Button/ButtonBase";
import Menu from "components/Menu";
import MenuItem from "components/Menu/MenuItem";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import useFeatureFlagsForUser from "shared/features/featureFlags/useFeatureFlagsForUser";
import { getFeatures } from "shared/features/featureFlags/selectors";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useMenuAnchorElement } from "components/Menu/useMenuAnchorElement";
import { useFetchEpisode } from "shared/features/episodes/useFetchEpisode";
import UrgentModal from "../UrgentModal";
import YourTeamModal from "../YourTeamModal";
import classes from "./index.module.css";
import { JsonUser as User } from "shared/fetch/src/models/JsonUser";
import { WorkspaceTabEnum, setWorkspacePath } from "shared/state/ui/workspace";
import { useDispatch } from "react-redux";
import useUpdateVirtualCareTypeMutation from "shared/features/episodes/useUpdateVirtualCareTypeMutation";
import { UpdateVirtualCareTypeRequestBodyVucEventEnum } from "shared/fetch/src/models/UpdateVirtualCareTypeRequestBody";

interface IProps {
  episodeId: string | undefined;
  isMobile: boolean;
  user: User;
  hasCtmInlineMessaging?: boolean;
  isArchived?: boolean;
  isPreventive?: boolean;
  hasArchiveHistory?: boolean;
}

const MoreOptionsMenu: React.FC<IProps> = ({
  episodeId,
  isMobile,
  user,
  hasCtmInlineMessaging = false,
  isArchived,
  isPreventive,
  hasArchiveHistory,
}) => {
  const [openUrgent, setOpenUrgent] = useState(false);
  const [openYourTeam, setOpenYourTeam] = useState(false);
  const [isEpisodeVUC, setIsEpisodeVUC] = useState(false);

  const dispatch = useDispatch();

  const { data } = useFetchEpisode({
    // @ts-ignore: Object is possibly 'null'.
    id: episodeId?.toString(),
  });

  const { mutate: updateVirtualCareType } = useUpdateVirtualCareTypeMutation();
  const episode = data;

  useFeatureFlagsForUser(episode?.memberInfo?.id?.toString() ?? "");
  const featureFlags = useSelector(getFeatures);
  const hasVirtualUrgentCare = featureFlags.hasVirtualUrgentCare();

  const urgentHelpClick = () => {
    setOpenUrgent(true);
    onMenuClose();
  };
  const yourTeamClick = () => {
    setOpenYourTeam(true);
    onMenuClose();
  };

  const archiveConversationClick = () => {
    const path = {
      tab: WorkspaceTabEnum.ARCHIVE,
    };
    dispatch(setWorkspacePath(path));
    onMenuClose();
  };

  const { anchorElement, onAnchorElementClick, onMenuClose } =
    useMenuAnchorElement();

  const onVUCChange = () => {
    const request = {
      id: episodeId!,
      updateVirtualCareTypeRequestBody: {
        vucEvent: isEpisodeVUC
          ? UpdateVirtualCareTypeRequestBodyVucEventEnum.Unmarked
          : UpdateVirtualCareTypeRequestBodyVucEventEnum.Marked,
      },
    };
    updateVirtualCareType(request);
    onMenuClose();
  };

  //Implementing this way instead passing down as a prop from higher up in the tree because AppBar.tsx uses the
  //MoreOptionsMenu without any data about the episode besides its ID.
  useEffect(() => {
    if (episode) {
      setIsEpisodeVUC(!!episode?.virtualUrgentCare);
    }
  }, [episode]);
  return (
    <div>
      {!!episode?.urgentPhoneNumber && (
        <UrgentModal
          open={openUrgent}
          toggle={() => setOpenUrgent(!openUrgent)}
          emergencyPhoneContact={episode?.urgentPhoneNumber}
        />
      )}
      <YourTeamModal
        open={openYourTeam}
        toggle={() => setOpenYourTeam(!openYourTeam)}
        careTeamParticipants={episode?.careTeamParticipants}
        user={user}
      />
      <ButtonBase
        data-testId="timeline-header-more-options-button"
        onClick={onAnchorElementClick}
        aria-label="Click to see more options related to this conversation"
        className={classes.actionButton}
      >
        <MoreHorizIcon
          className={isMobile ? classes.moreIconMobile : classes.moreIcon}
        />
      </ButtonBase>
      <Menu
        id="timeline-header-more-options-menu"
        anchorEl={anchorElement}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={onMenuClose}
        // getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        data-testId="timeline-header-more-options-menu"
      >
        <MenuItem onClick={yourTeamClick} data-e2e="timeline-header-your-team">
          {user?.ctm ? "Teammates" : "Your Team"}
        </MenuItem>
        {user.ctm && hasCtmInlineMessaging && !isArchived && (
          <MenuItem
            onClick={archiveConversationClick}
            data-e2e="timeline-header-your-team"
          >
            Archive conversation
          </MenuItem>
        )}
        {user.ctm &&
          hasVirtualUrgentCare &&
          !hasArchiveHistory &&
          !isPreventive && (
            <MenuItem
              onClick={onVUCChange}
              data-e2e={`timeline-header-${
                isEpisodeVUC ? "unmark" : "mark"
              }-vuc`}
            >
              {isEpisodeVUC ? "Unmark" : "Mark"} conversation as VUC
            </MenuItem>
          )}
        {!user?.ctm && (
          <MenuItem
            onClick={urgentHelpClick}
            data-e2e="timeline-header-urgent-help"
          >
            Urgent Help
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default MoreOptionsMenu;
