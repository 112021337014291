import { useQuery } from "react-query";
import { DeleteDocumentRequest, fetchDocumentsApi } from "shared/fetch/src/apis/DocumentsApi";

export const memberDeleteDocumentQueryKey = (id?: string | number) => [
  "memberDeleteDocument",
  id?.toString(),
];

const useDeleteDocument = (request: DeleteDocumentRequest) => {
  const query = useQuery(
    memberDeleteDocumentQueryKey(request.id),
    () => fetchDocumentsApi.deleteDocument(request),
    {
      enabled: !!request.id && request.id !== undefined,
      keepPreviousData: true,
    }
  );
  return query;
};

export default useDeleteDocument;
