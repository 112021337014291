/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    MemberAccountInfoOutput,
    MemberAccountInfoOutputFromJSON,
    MemberAccountInfoOutputToJSON,
} from '../models/MemberAccountInfoOutput'
import {
    MemberUpdateAccountInfoRequestBody,
    MemberUpdateAccountInfoRequestBodyFromJSON,
    MemberUpdateAccountInfoRequestBodyToJSON,
} from '../models/MemberUpdateAccountInfoRequestBody'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface PartialUpdateMemberAccountInfoRequest {
    id: string;
    memberUpdateAccountInfoRequestBody?: MemberUpdateAccountInfoRequestBody;
}


export interface IUpdateMemberAccountInfoApi {
      partialUpdateMemberAccountInfo: (requestParameters: PartialUpdateMemberAccountInfoRequest) => Promise<MemberAccountInfoOutput>
      partialUpdateMemberAccountInfoWithResponse: (requestParameters: PartialUpdateMemberAccountInfoRequest) => Promise<{ value: MemberAccountInfoOutput, response: any}>
}

/**
 * no description
 */
export class UpdateMemberAccountInfoApi extends runtime.BaseAPI {

    /**
     * Partial update the member\'s account information
     * Partial update the member\'s account information
     */
    partialUpdateMemberAccountInfoRaw = async (requestParameters: PartialUpdateMemberAccountInfoRequest): Promise<{ runtime: runtime.ApiResponse<MemberAccountInfoOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling partialUpdateMemberAccountInfo.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: MemberUpdateAccountInfoRequestBodyToJSON(requestParameters.memberUpdateAccountInfoRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberAccountInfoOutputFromJSON(jsonValue)), response };
    }

    /**
     * Partial update the member\'s account information
     * Partial update the member\'s account information
     */
    partialUpdateMemberAccountInfo = async (requestParameters: PartialUpdateMemberAccountInfoRequest): Promise<MemberAccountInfoOutput> => {
        const { runtime } = await this.partialUpdateMemberAccountInfoRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Partial update the member\'s account information
     * Partial update the member\'s account information
     */
    partialUpdateMemberAccountInfoWithResponse = async (requestParameters: PartialUpdateMemberAccountInfoRequest): Promise<{ value: MemberAccountInfoOutput, response: any}> => {
        const { runtime, response } = await this.partialUpdateMemberAccountInfoRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchUpdateMemberAccountInfoApi: IUpdateMemberAccountInfoApi  = new UpdateMemberAccountInfoApi();
