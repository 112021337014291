// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     AnnotatedInputServiceMenuDocumentListProps,
    AnnotatedInputServiceMenuDocumentListPropsFromJSON,
    AnnotatedInputServiceMenuDocumentListPropsToJSON,
} from './AnnotatedInputServiceMenuDocumentListProps';
import {
     InputServiceLineSchema,
    InputServiceLineSchemaFromJSON,
    InputServiceLineSchemaToJSON,
} from './InputServiceLineSchema';
import {
     InputServiceMenuDocumentSchema,
    InputServiceMenuDocumentSchemaFromJSON,
    InputServiceMenuDocumentSchemaToJSON,
} from './InputServiceMenuDocumentSchema';
/**
 * @type AnnotatedInputServiceMenuDocument
 * @export
 */
export interface AnnotatedInputServiceMenuDocument extends AnnotatedInputServiceMenuDocumentListProps, InputServiceMenuDocumentSchema {
}

export function AnnotatedInputServiceMenuDocumentFromJSON(json: any): AnnotatedInputServiceMenuDocument {
    return {
        ...AnnotatedInputServiceMenuDocumentListPropsFromJSON(json),
        ...InputServiceMenuDocumentSchemaFromJSON(json),
    };
}

export function AnnotatedInputServiceMenuDocumentToJSON(value?: AnnotatedInputServiceMenuDocument): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...AnnotatedInputServiceMenuDocumentListPropsToJSON(value),
        ...InputServiceMenuDocumentSchemaToJSON(value),
    };
}

