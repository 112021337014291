import { useSelector } from "react-redux";
import { State } from "shared/types/state";
import { State as WorkspaceState } from "./index";

export const getWorkspace = (
  state: State
): WorkspaceState | null | undefined => {
  return state?.ui?.workspace;
};

export const selectWorkspace = () => useSelector(getWorkspace);
