// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GreenPlusResponseBody
 */
export interface GreenPlusResponseBody  {
    /**
     * 
     * @type {number}
     * @memberof GreenPlusResponseBody
     */
    id: number;
}

export function GreenPlusResponseBodyFromJSON(json: any): GreenPlusResponseBody {
    return {
        'id': json['id'],
    };
}

export function GreenPlusResponseBodyToJSON(value?: GreenPlusResponseBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
    };
}


