// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserPushNotificationOutput
 */
export interface UserPushNotificationOutput  {
    /**
     * 
     * @type {string}
     * @memberof UserPushNotificationOutput
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserPushNotificationOutput
     */
    state: UserPushNotificationOutputStateEnum;
    /**
     * 
     * @type {string}
     * @memberof UserPushNotificationOutput
     */
    notificationMessage: string;
    /**
     * 
     * @type {string}
     * @memberof UserPushNotificationOutput
     */
    assetType?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPushNotificationOutput
     */
    link?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserPushNotificationOutput
     */
    sentAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserPushNotificationOutput
     */
    readAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserPushNotificationOutput
     */
    senderFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPushNotificationOutput
     */
    senderAvatarUrl?: string;
}

export function UserPushNotificationOutputFromJSON(json: any): UserPushNotificationOutput {
    return {
        'id': json['id'],
        'state': json['state'],
        'notificationMessage': json['notification_message'],
        'assetType': !exists(json, 'asset_type') ? undefined : json['asset_type'],
        'link': !exists(json, 'link') ? undefined : json['link'],
        'sentAt': new Date(json['sent_at']),
        'readAt': !exists(json, 'read_at') ? undefined : new Date(json['read_at']),
        'senderFullName': !exists(json, 'sender_full_name') ? undefined : json['sender_full_name'],
        'senderAvatarUrl': !exists(json, 'sender_avatar_url') ? undefined : json['sender_avatar_url'],
    };
}

export function UserPushNotificationOutputToJSON(value?: UserPushNotificationOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'state': value.state,
        'notification_message': value.notificationMessage,
        'asset_type': value.assetType,
        'link': value.link,
        'sent_at': value.sentAt.toISOString(),
        'read_at': value.readAt === undefined ? undefined : value.readAt.toISOString(),
        'sender_full_name': value.senderFullName,
        'sender_avatar_url': value.senderAvatarUrl,
    };
}

/**
* @export
* @enum {string}
*/
export enum UserPushNotificationOutputStateEnum {
    Read = 'read',
    Sent = 'sent'
}


