// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalculatorInitialAnswersResponse
 */
export interface CalculatorInitialAnswersResponse  {
    /**
     * 
     * @type {object}
     * @memberof CalculatorInitialAnswersResponse
     */
    surveyAnswers?: object;
}

export function CalculatorInitialAnswersResponseFromJSON(json: any): CalculatorInitialAnswersResponse {
    return {
        'surveyAnswers': !exists(json, 'survey_answers') ? undefined : json['survey_answers'],
    };
}

export function CalculatorInitialAnswersResponseToJSON(value?: CalculatorInitialAnswersResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'survey_answers': value.surveyAnswers,
    };
}


