// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberAccountInfoOutputAllOfAssignedPcp,
    MemberAccountInfoOutputAllOfAssignedPcpFromJSON,
    MemberAccountInfoOutputAllOfAssignedPcpToJSON,
} from './MemberAccountInfoOutputAllOfAssignedPcp';
import {
    MemberAccountInfoOutputAllOfPrimaryAccountHolder,
    MemberAccountInfoOutputAllOfPrimaryAccountHolderFromJSON,
    MemberAccountInfoOutputAllOfPrimaryAccountHolderToJSON,
} from './MemberAccountInfoOutputAllOfPrimaryAccountHolder';
import {
    MemberDefaultClinicInfo,
    MemberDefaultClinicInfoFromJSON,
    MemberDefaultClinicInfoToJSON,
} from './MemberDefaultClinicInfo';
import {
    MemberServiceLineDetails,
    MemberServiceLineDetailsFromJSON,
    MemberServiceLineDetailsToJSON,
} from './MemberServiceLineDetails';
import {
    PermissionsEnum,
    PermissionsEnumFromJSON,
    PermissionsEnumToJSON,
} from './PermissionsEnum';
import {
    PodObjectDetails,
    PodObjectDetailsFromJSON,
    PodObjectDetailsToJSON,
} from './PodObjectDetails';


/**
 * 
 * @export
 * @interface MemberAccountInfoOutputAllOf
 */
export interface MemberAccountInfoOutputAllOf  {
    /**
     * 
     * @type {string}
     * @memberof MemberAccountInfoOutputAllOf
     */
    sexAtBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAccountInfoOutputAllOf
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAccountInfoOutputAllOf
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAccountInfoOutputAllOf
     */
    legalAndPreferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAccountInfoOutputAllOf
     */
    beneficiary?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAccountInfoOutputAllOf
     */
    employer?: string;
    /**
     * 
     * @type {Date}
     * @memberof MemberAccountInfoOutputAllOf
     */
    becomesIneligibleAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof MemberAccountInfoOutputAllOf
     */
    status?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberAccountInfoOutputAllOf
     */
    locked?: boolean;
    /**
     * 
     * @type {Array<PermissionsEnum>}
     * @memberof MemberAccountInfoOutputAllOf
     */
    permissions?: Array<PermissionsEnum>;
    /**
     * 
     * @type {Array<PodObjectDetails>}
     * @memberof MemberAccountInfoOutputAllOf
     */
    podFilter?: Array<PodObjectDetails>;
    /**
     * 
     * @type {Array<MemberServiceLineDetails>}
     * @memberof MemberAccountInfoOutputAllOf
     */
    memberServiceLineDetails?: Array<MemberServiceLineDetails>;
    /**
     * 
     * @type {Array<PodObjectDetails>}
     * @memberof MemberAccountInfoOutputAllOf
     */
    activePods?: Array<PodObjectDetails>;
    /**
     * 
     * @type {MemberAccountInfoOutputAllOfAssignedPcp}
     * @memberof MemberAccountInfoOutputAllOf
     */
    assignedPcp?: MemberAccountInfoOutputAllOfAssignedPcp;
    /**
     * 
     * @type {string}
     * @memberof MemberAccountInfoOutputAllOf
     */
    xoid?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAccountInfoOutputAllOf
     */
    patientId?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAccountInfoOutputAllOf
     */
    elationDeepLink?: string;
    /**
     * 
     * @type {Array<object>}
     * @memberof MemberAccountInfoOutputAllOf
     */
    allergies?: Array<object>;
    /**
     * 
     * @type {string}
     * @memberof MemberAccountInfoOutputAllOf
     */
    corePodName?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberAccountInfoOutputAllOf
     */
    activeEpisodeCount?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberAccountInfoOutputAllOf
     */
    membershipType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberAccountInfoOutputAllOf
     */
    aetnaMember?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MemberAccountInfoOutputAllOf
     */
    bcbsndMember?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MemberAccountInfoOutputAllOf
     */
    hasNestedBadge?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberAccountInfoOutputAllOf
     */
    extraRegistrationId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberAccountInfoOutputAllOf
     */
    minor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MemberAccountInfoOutputAllOf
     */
    dependent?: boolean;
    /**
     * 
     * @type {MemberAccountInfoOutputAllOfPrimaryAccountHolder}
     * @memberof MemberAccountInfoOutputAllOf
     */
    primaryAccountHolder?: MemberAccountInfoOutputAllOfPrimaryAccountHolder;
    /**
     * 
     * @type {number}
     * @memberof MemberAccountInfoOutputAllOf
     */
    centersCount?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberAccountInfoOutputAllOf
     */
    xoPhysicianGlobalId?: string;
    /**
     * 
     * @type {MemberDefaultClinicInfo}
     * @memberof MemberAccountInfoOutputAllOf
     */
    defaultClinic?: MemberDefaultClinicInfo;
    /**
     * 
     * @type {string}
     * @memberof MemberAccountInfoOutputAllOf
     */
    relationshipCode?: string;
}

export function MemberAccountInfoOutputAllOfFromJSON(json: any): MemberAccountInfoOutputAllOf {
    return {
        'sexAtBirth': !exists(json, 'sex_at_birth') ? undefined : json['sex_at_birth'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'legalName': !exists(json, 'legal_name') ? undefined : json['legal_name'],
        'legalAndPreferredName': !exists(json, 'legal_and_preferred_name') ? undefined : json['legal_and_preferred_name'],
        'beneficiary': !exists(json, 'beneficiary') ? undefined : json['beneficiary'],
        'employer': !exists(json, 'employer') ? undefined : json['employer'],
        'becomesIneligibleAt': !exists(json, 'becomes_ineligible_at') ? undefined : new Date(json['becomes_ineligible_at']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'locked': !exists(json, 'locked') ? undefined : json['locked'],
        'permissions': !exists(json, 'permissions') ? undefined : (json['permissions'] as Array<any>).map(PermissionsEnumFromJSON),
        'podFilter': !exists(json, 'pod_filter') ? undefined : (json['pod_filter'] as Array<any>).map(PodObjectDetailsFromJSON),
        'memberServiceLineDetails': !exists(json, 'member_service_line_details') ? undefined : (json['member_service_line_details'] as Array<any>).map(MemberServiceLineDetailsFromJSON),
        'activePods': !exists(json, 'active_pods') ? undefined : (json['active_pods'] as Array<any>).map(PodObjectDetailsFromJSON),
        'assignedPcp': !exists(json, 'assigned_pcp') ? undefined : MemberAccountInfoOutputAllOfAssignedPcpFromJSON(json['assigned_pcp']),
        'xoid': !exists(json, 'xoid') ? undefined : json['xoid'],
        'patientId': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'elationDeepLink': !exists(json, 'elation_deep_link') ? undefined : json['elation_deep_link'],
        'allergies': !exists(json, 'allergies') ? undefined : json['allergies'],
        'corePodName': !exists(json, 'core_pod_name') ? undefined : json['core_pod_name'],
        'activeEpisodeCount': !exists(json, 'active_episode_count') ? undefined : json['active_episode_count'],
        'membershipType': !exists(json, 'membership_type') ? undefined : json['membership_type'],
        'aetnaMember': !exists(json, 'aetna_member') ? undefined : json['aetna_member'],
        'bcbsndMember': !exists(json, 'bcbsnd_member') ? undefined : json['bcbsnd_member'],
        'hasNestedBadge': !exists(json, 'has_nested_badge') ? undefined : json['has_nested_badge'],
        'extraRegistrationId': !exists(json, 'extra_registration_id') ? undefined : json['extra_registration_id'],
        'minor': !exists(json, 'minor') ? undefined : json['minor'],
        'dependent': !exists(json, 'dependent') ? undefined : json['dependent'],
        'primaryAccountHolder': !exists(json, 'primary_account_holder') ? undefined : MemberAccountInfoOutputAllOfPrimaryAccountHolderFromJSON(json['primary_account_holder']),
        'centersCount': !exists(json, 'centers_count') ? undefined : json['centers_count'],
        'xoPhysicianGlobalId': !exists(json, 'xo_physician_global_id') ? undefined : json['xo_physician_global_id'],
        'defaultClinic': !exists(json, 'default_clinic') ? undefined : MemberDefaultClinicInfoFromJSON(json['default_clinic']),
        'relationshipCode': !exists(json, 'relationship_code') ? undefined : json['relationship_code'],
    };
}

export function MemberAccountInfoOutputAllOfToJSON(value?: MemberAccountInfoOutputAllOf): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'sex_at_birth': value.sexAtBirth,
        'email': value.email,
        'legal_name': value.legalName,
        'legal_and_preferred_name': value.legalAndPreferredName,
        'beneficiary': value.beneficiary,
        'employer': value.employer,
        'becomes_ineligible_at': value.becomesIneligibleAt === undefined ? undefined : value.becomesIneligibleAt.toISOString().substr(0,10),
        'status': value.status,
        'locked': value.locked,
        'permissions': value.permissions === undefined ? undefined : (value.permissions as Array<any>).map(PermissionsEnumToJSON),
        'pod_filter': value.podFilter === undefined ? undefined : (value.podFilter as Array<any>).map(PodObjectDetailsToJSON),
        'member_service_line_details': value.memberServiceLineDetails === undefined ? undefined : (value.memberServiceLineDetails as Array<any>).map(MemberServiceLineDetailsToJSON),
        'active_pods': value.activePods === undefined ? undefined : (value.activePods as Array<any>).map(PodObjectDetailsToJSON),
        'assigned_pcp': MemberAccountInfoOutputAllOfAssignedPcpToJSON(value.assignedPcp),
        'xoid': value.xoid,
        'patient_id': value.patientId,
        'elation_deep_link': value.elationDeepLink,
        'allergies': value.allergies,
        'core_pod_name': value.corePodName,
        'active_episode_count': value.activeEpisodeCount,
        'membership_type': value.membershipType,
        'aetna_member': value.aetnaMember,
        'bcbsnd_member': value.bcbsndMember,
        'has_nested_badge': value.hasNestedBadge,
        'extra_registration_id': value.extraRegistrationId,
        'minor': value.minor,
        'dependent': value.dependent,
        'primary_account_holder': MemberAccountInfoOutputAllOfPrimaryAccountHolderToJSON(value.primaryAccountHolder),
        'centers_count': value.centersCount,
        'xo_physician_global_id': value.xoPhysicianGlobalId,
        'default_clinic': MemberDefaultClinicInfoToJSON(value.defaultClinic),
        'relationship_code': value.relationshipCode,
    };
}


