// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    AppointmentOutput,
    AppointmentOutputFromJSON,
    AppointmentOutputToJSON,
} from '../models/AppointmentOutput'
import {
    CreateAppointmentVideoConference,
    CreateAppointmentVideoConferenceFromJSON,
    CreateAppointmentVideoConferenceToJSON,
} from '../models/CreateAppointmentVideoConference'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpdateAppointmentVideoConference,
    UpdateAppointmentVideoConferenceFromJSON,
    UpdateAppointmentVideoConferenceToJSON,
} from '../models/UpdateAppointmentVideoConference'


export interface CreateAppointmentVideoConferenceRequest {
    id: string;
    createAppointmentVideoConference?: CreateAppointmentVideoConference;
}

export interface UpdateAppointmentVideoConferenceRequest {
    id: string;
    updateAppointmentVideoConference: UpdateAppointmentVideoConference;
}


/**
 * Find Appointment by id and create the VideoConference instance associated with it
 * Find Appointment by id and create the VideoConference instance associated with it
 */
function createAppointmentVideoConferenceRaw<T>(requestParameters: CreateAppointmentVideoConferenceRequest, requestConfig: runtime.TypedQueryConfig<T, AppointmentOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createAppointmentVideoConference.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/appointments/{id}/video_conference`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || CreateAppointmentVideoConferenceToJSON(requestParameters.createAppointmentVideoConference),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AppointmentOutputFromJSON(body), text);
    }

    return config;
}

/**
* Find Appointment by id and create the VideoConference instance associated with it
* Find Appointment by id and create the VideoConference instance associated with it
*/
export function createAppointmentVideoConference<T>(requestParameters: CreateAppointmentVideoConferenceRequest, requestConfig?: runtime.TypedQueryConfig<T, AppointmentOutput>): QueryConfig<T> {
    return createAppointmentVideoConferenceRaw(requestParameters, requestConfig);
}

/**
 * Find Appointment by id and update the VideoConference instance associated with it
 * Find Appointment by id and update the VideoConference instance associated with it
 */
function updateAppointmentVideoConferenceRaw<T>(requestParameters: UpdateAppointmentVideoConferenceRequest, requestConfig: runtime.TypedQueryConfig<T, AppointmentOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateAppointmentVideoConference.');
    }

    if (requestParameters.updateAppointmentVideoConference === null || requestParameters.updateAppointmentVideoConference === undefined) {
        throw new runtime.RequiredError('updateAppointmentVideoConference','Required parameter requestParameters.updateAppointmentVideoConference was null or undefined when calling updateAppointmentVideoConference.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/appointments/{id}/video_conference`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || UpdateAppointmentVideoConferenceToJSON(requestParameters.updateAppointmentVideoConference),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AppointmentOutputFromJSON(body), text);
    }

    return config;
}

/**
* Find Appointment by id and update the VideoConference instance associated with it
* Find Appointment by id and update the VideoConference instance associated with it
*/
export function updateAppointmentVideoConference<T>(requestParameters: UpdateAppointmentVideoConferenceRequest, requestConfig?: runtime.TypedQueryConfig<T, AppointmentOutput>): QueryConfig<T> {
    return updateAppointmentVideoConferenceRaw(requestParameters, requestConfig);
}

