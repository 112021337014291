// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Appointment,
    AppointmentFromJSON,
    AppointmentToJSON,
} from './Appointment';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
    CompactComponentReference,
    CompactComponentReferenceFromJSON,
    CompactComponentReferenceToJSON,
} from './CompactComponentReference';
import {
    DocumentOutputSchema,
    DocumentOutputSchemaFromJSON,
    DocumentOutputSchemaToJSON,
} from './DocumentOutputSchema';
import {
    VisitType,
    VisitTypeFromJSON,
    VisitTypeToJSON,
} from './VisitType';


/**
 * 
 * @export
 * @interface SelfScheduleAppointmentComponentOutputProps
 */
export interface SelfScheduleAppointmentComponentOutputProps  {
    /**
     * 
     * @type {boolean}
     * @memberof SelfScheduleAppointmentComponentOutputProps
     */
    timeSensitive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SelfScheduleAppointmentComponentOutputProps
     */
    state: SelfScheduleAppointmentComponentOutputPropsStateEnum;
    /**
     * 
     * @type {Array<CompactComponentReference>}
     * @memberof SelfScheduleAppointmentComponentOutputProps
     */
    parentComponents?: Array<CompactComponentReference>;
    /**
     * 
     * @type {Appointment}
     * @memberof SelfScheduleAppointmentComponentOutputProps
     */
    appointment: Appointment;
    /**
     * 
     * @type {CTMInfo}
     * @memberof SelfScheduleAppointmentComponentOutputProps
     */
    provider?: CTMInfo;
    /**
     * 
     * @type {Array<DocumentOutputSchema>}
     * @memberof SelfScheduleAppointmentComponentOutputProps
     */
    documentInfo?: Array<DocumentOutputSchema>;
    /**
     * 
     * @type {VisitType}
     * @memberof SelfScheduleAppointmentComponentOutputProps
     */
    visitType?: VisitType;
}

export function SelfScheduleAppointmentComponentOutputPropsFromJSON(json: any): SelfScheduleAppointmentComponentOutputProps {
    return {
        'timeSensitive': !exists(json, 'time_sensitive') ? undefined : json['time_sensitive'],
        'state': json['state'],
        'parentComponents': !exists(json, 'parent_components') ? undefined : (json['parent_components'] as Array<any>).map(CompactComponentReferenceFromJSON),
        'appointment': AppointmentFromJSON(json['appointment']),
        'provider': !exists(json, 'provider') ? undefined : CTMInfoFromJSON(json['provider']),
        'documentInfo': !exists(json, 'document_info') ? undefined : (json['document_info'] as Array<any>).map(DocumentOutputSchemaFromJSON),
        'visitType': !exists(json, 'visit_type') ? undefined : VisitTypeFromJSON(json['visit_type']),
    };
}

export function SelfScheduleAppointmentComponentOutputPropsToJSON(value?: SelfScheduleAppointmentComponentOutputProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'time_sensitive': value.timeSensitive,
        'state': value.state,
        'parent_components': value.parentComponents === undefined ? undefined : (value.parentComponents as Array<any>).map(CompactComponentReferenceToJSON),
        'appointment': AppointmentToJSON(value.appointment),
        'provider': CTMInfoToJSON(value.provider),
        'document_info': value.documentInfo === undefined ? undefined : (value.documentInfo as Array<any>).map(DocumentOutputSchemaToJSON),
        'visit_type': VisitTypeToJSON(value.visitType),
    };
}

/**
* @export
* @enum {string}
*/
export enum SelfScheduleAppointmentComponentOutputPropsStateEnum {
    Scheduled = 'scheduled',
    ResolvedCompleted = 'resolved_completed',
    MissedNoShow = 'missed_no_show',
    CanceledByMember = 'canceled_by_member',
    ResolvedCanceledAckd = 'resolved_canceled_ackd',
    CanceledByCtm = 'canceled_by_ctm',
    ResolvedCanceledViewed = 'resolved_canceled_viewed',
    RescheduledPending = 'rescheduled_pending',
    CanceledAckdPending = 'canceled_ackd_pending',
    Declined = 'declined',
    CheckInOpened = 'check_in_opened',
    CheckInInProgress = 'check_in_in_progress',
    CheckInCompleted = 'check_in_completed',
    CheckInIncomplete = 'check_in_incomplete',
    CheckInClosed = 'check_in_closed',
    CheckInFastTrackedConsentNeeded = 'check_in_fast_tracked_consent_needed',
    CheckInFastTrackedOutstandingItemsWithoutConsent = 'check_in_fast_tracked_outstanding_items_without_consent',
    CheckInCompletedNotAcknowledged = 'check_in_completed_not_acknowledged'
}


