// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     WorkspaceDraftDataSchema,
    WorkspaceDraftDataSchemaFromJSON,
    WorkspaceDraftDataSchemaToJSON,
} from './WorkspaceDraftDataSchema';
import {
     WorkspaceDraftDataSchemaItem,
    WorkspaceDraftDataSchemaItemFromJSON,
    WorkspaceDraftDataSchemaItemToJSON,
} from './WorkspaceDraftDataSchemaItem';
/**
 * @type InputWorkspaceDraftSchema
 * @export
 */
export interface InputWorkspaceDraftSchema extends WorkspaceDraftDataSchema {
}

export function InputWorkspaceDraftSchemaFromJSON(json: any): InputWorkspaceDraftSchema {
    return {
        ...WorkspaceDraftDataSchemaFromJSON(json),
    };
}

export function InputWorkspaceDraftSchemaToJSON(value?: InputWorkspaceDraftSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...WorkspaceDraftDataSchemaToJSON(value),
    };
}

