import { useMemo, ReactElement } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDraftState } from "shared/state/ui/member/selectors";
import { displayConfirmationsModal } from "shared/state/ui/confirmationsDialog";
import { useLocation, useNavigate, NavigateOptions } from "react-router";

interface OverridesProps {
  className?: string;
  title?: ReactElement | string;
  content?: ReactElement | string;
}

interface SafeNavigate {
  (to: string, options?: NavigateOptions | OverridesProps): Promise<void>;
  (to: number): Promise<void>;
}

const useDraftSafeNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const hasDraft = useSelector(getDraftState);

  const createConfirmationFunc = useMemo(
    () => (link: string | number) => () => {
      if (typeof link === "string") {
        navigate(link);
      } else if (typeof link === "number") {
        navigate(link);
      }
    },
    []
  );

  return ((link: unknown, options: unknown) => {
    if (link === location.pathname) {
      return Promise.resolve();
    } else if (!hasDraft) {
      if (typeof link === "string") {
        navigate(link, options as NavigateOptions);
      } else if (typeof link === "number") {
        navigate(link as number);
      }
    } else {
      dispatch(
        displayConfirmationsModal({
          onConfirm: createConfirmationFunc(link as string),
          overrides: options ? (options as OverridesProps) : undefined,
        })
      );
    }
    return Promise.resolve();
  }) as SafeNavigate;
};

export default useDraftSafeNavigate;
