// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     CompactComponentReference,
    CompactComponentReferenceFromJSON,
    CompactComponentReferenceToJSON,
} from './CompactComponentReference';
import {
     WorkspaceDraftDataSchema,
    WorkspaceDraftDataSchemaFromJSON,
    WorkspaceDraftDataSchemaToJSON,
} from './WorkspaceDraftDataSchema';
import {
     WorkspaceDraftDataSchemaItem,
    WorkspaceDraftDataSchemaItemFromJSON,
    WorkspaceDraftDataSchemaItemToJSON,
} from './WorkspaceDraftDataSchemaItem';
import {
     WorkspaceDraftOutputCoreSchema,
    WorkspaceDraftOutputCoreSchemaFromJSON,
    WorkspaceDraftOutputCoreSchemaToJSON,
} from './WorkspaceDraftOutputCoreSchema';
/**
 * @type OutputWorkspaceDraftSchema
 * @export
 */
export interface OutputWorkspaceDraftSchema extends WorkspaceDraftDataSchema, WorkspaceDraftOutputCoreSchema {
}

export function OutputWorkspaceDraftSchemaFromJSON(json: any): OutputWorkspaceDraftSchema {
    return {
        ...WorkspaceDraftDataSchemaFromJSON(json),
        ...WorkspaceDraftOutputCoreSchemaFromJSON(json),
    };
}

export function OutputWorkspaceDraftSchemaToJSON(value?: OutputWorkspaceDraftSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...WorkspaceDraftDataSchemaToJSON(value),
        ...WorkspaceDraftOutputCoreSchemaToJSON(value),
    };
}

