// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthenticateWithChallenge
 */
export interface AuthenticateWithChallenge  {
    /**
     * 
     * @type {string}
     * @memberof AuthenticateWithChallenge
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateWithChallenge
     */
    signature: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateWithChallenge
     */
    challenge: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateWithChallenge
     */
    responseType?: string;
    /**
     * 
     * @type {number}
     * @memberof AuthenticateWithChallenge
     */
    clientId?: number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateWithChallenge
     */
    redirectUrl: string;
    /**
     * 
     * @type {number}
     * @memberof AuthenticateWithChallenge
     */
    codeChallenge: number;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateWithChallenge
     */
    codeChallengeMethod?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthenticateWithChallenge
     */
    scope?: string;
}

export function AuthenticateWithChallengeFromJSON(json: any): AuthenticateWithChallenge {
    return {
        'deviceId': json['device_id'],
        'signature': json['signature'],
        'challenge': json['challenge'],
        'responseType': !exists(json, 'response_type') ? undefined : json['response_type'],
        'clientId': !exists(json, 'client_id') ? undefined : json['client_id'],
        'redirectUrl': json['redirect_url'],
        'codeChallenge': json['code_challenge'],
        'codeChallengeMethod': !exists(json, 'code_challenge_method') ? undefined : json['code_challenge_method'],
        'scope': !exists(json, 'scope') ? undefined : json['scope'],
    };
}

export function AuthenticateWithChallengeToJSON(value?: AuthenticateWithChallenge): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'device_id': value.deviceId,
        'signature': value.signature,
        'challenge': value.challenge,
        'response_type': value.responseType,
        'client_id': value.clientId,
        'redirect_url': value.redirectUrl,
        'code_challenge': value.codeChallenge,
        'code_challenge_method': value.codeChallengeMethod,
        'scope': value.scope,
    };
}


