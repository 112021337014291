// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountActivationRequestBody
 */
export interface AccountActivationRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof AccountActivationRequestBody
     */
    client: string;
    /**
     * 
     * @type {string}
     * @memberof AccountActivationRequestBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof AccountActivationRequestBody
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof AccountActivationRequestBody
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof AccountActivationRequestBody
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof AccountActivationRequestBody
     */
    dob: string;
    /**
     * 
     * @type {string}
     * @memberof AccountActivationRequestBody
     */
    ssnLast4: string;
    /**
     * 
     * @type {string}
     * @memberof AccountActivationRequestBody
     */
    employeeId: string;
    /**
     * 
     * @type {string}
     * @memberof AccountActivationRequestBody
     */
    relationship: string;
    /**
     * 
     * @type {string}
     * @memberof AccountActivationRequestBody
     */
    gender: string;
    /**
     * 
     * @type {string}
     * @memberof AccountActivationRequestBody
     */
    workEmail: string;
    /**
     * 
     * @type {string}
     * @memberof AccountActivationRequestBody
     */
    cellPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountActivationRequestBody
     */
    preferredLanguage?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AccountActivationRequestBody
     */
    interests?: Array<string>;
}

export function AccountActivationRequestBodyFromJSON(json: any): AccountActivationRequestBody {
    return {
        'client': json['client'],
        'email': json['email'],
        'password': json['password'],
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'dob': json['dob'],
        'ssnLast4': json['ssn_last4'],
        'employeeId': json['employee_id'],
        'relationship': json['relationship'],
        'gender': json['gender'],
        'workEmail': json['work_email'],
        'cellPhone': !exists(json, 'cell_phone') ? undefined : json['cell_phone'],
        'preferredLanguage': !exists(json, 'preferred_language') ? undefined : json['preferred_language'],
        'interests': !exists(json, 'interests') ? undefined : json['interests'],
    };
}

export function AccountActivationRequestBodyToJSON(value?: AccountActivationRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'client': value.client,
        'email': value.email,
        'password': value.password,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'dob': value.dob,
        'ssn_last4': value.ssnLast4,
        'employee_id': value.employeeId,
        'relationship': value.relationship,
        'gender': value.gender,
        'work_email': value.workEmail,
        'cell_phone': value.cellPhone,
        'preferred_language': value.preferredLanguage,
        'interests': value.interests,
    };
}


