import palette from "../palette";
import typography from "../typography";

export default {
  tooltip: {
    backgroundColor: palette.text.secondary,
    fontSize: typography.bodySmall.fontSize,
    padding: 10,
    borderRadius: 2,
  },
};
