// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProxyMinorRequestBody
 */
export interface ProxyMinorRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof ProxyMinorRequestBody
     */
    documentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyMinorRequestBody
     */
    consentedDate: string;
    /**
     * 
     * @type {string}
     * @memberof ProxyMinorRequestBody
     */
    consentType: ProxyMinorRequestBodyConsentTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProxyMinorRequestBody
     */
    consentRequired: boolean;
}

export function ProxyMinorRequestBodyFromJSON(json: any): ProxyMinorRequestBody {
    return {
        'documentId': !exists(json, 'document_id') ? undefined : json['document_id'],
        'consentedDate': json['consented_date'],
        'consentType': json['consent_type'],
        'consentRequired': json['consent_required'],
    };
}

export function ProxyMinorRequestBodyToJSON(value?: ProxyMinorRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'document_id': value.documentId,
        'consented_date': value.consentedDate,
        'consent_type': value.consentType,
        'consent_required': value.consentRequired,
    };
}

/**
* @export
* @enum {string}
*/
export enum ProxyMinorRequestBodyConsentTypeEnum {
    Attachment = 'attachment',
    Verbal = 'verbal'
}


