// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserInfo,
    UserInfoFromJSON,
    UserInfoToJSON,
} from './UserInfo';


/**
 * 
 * @export
 * @interface Consent
 */
export interface Consent  {
    /**
     * 
     * @type {string}
     * @memberof Consent
     */
    configCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Consent
     */
    siteCode?: string;
    /**
     * 
     * @type {string}
     * @memberof Consent
     */
    addedBy?: string;
    /**
     * 
     * @type {string}
     * @memberof Consent
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof Consent
     */
    signedOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Consent
     */
    recordedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Consent
     */
    documentUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof Consent
     */
    htmlUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Consent
     */
    archived?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Consent
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof Consent
     */
    documentId?: number;
    /**
     * 
     * @type {string}
     * @memberof Consent
     */
    mime?: string;
    /**
     * 
     * @type {string}
     * @memberof Consent
     */
    consentType?: ConsentConsentTypeEnum;
    /**
     * 
     * @type {UserInfo}
     * @memberof Consent
     */
    authorizer?: UserInfo;
    /**
     * 
     * @type {UserInfo}
     * @memberof Consent
     */
    minor?: UserInfo;
}

export function ConsentFromJSON(json: any): Consent {
    return {
        'configCode': !exists(json, 'config_code') ? undefined : json['config_code'],
        'siteCode': !exists(json, 'site_code') ? undefined : json['site_code'],
        'addedBy': !exists(json, 'added_by') ? undefined : json['added_by'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'signedOn': !exists(json, 'signed_on') ? undefined : new Date(json['signed_on']),
        'recordedAt': !exists(json, 'recorded_at') ? undefined : new Date(json['recorded_at']),
        'documentUrl': !exists(json, 'document_url') ? undefined : json['document_url'],
        'htmlUrl': !exists(json, 'html_url') ? undefined : json['html_url'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'documentId': !exists(json, 'document_id') ? undefined : json['document_id'],
        'mime': !exists(json, 'mime') ? undefined : json['mime'],
        'consentType': !exists(json, 'consent_type') ? undefined : json['consent_type'],
        'authorizer': !exists(json, 'authorizer') ? undefined : UserInfoFromJSON(json['authorizer']),
        'minor': !exists(json, 'minor') ? undefined : UserInfoFromJSON(json['minor']),
    };
}

export function ConsentToJSON(value?: Consent): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'config_code': value.configCode,
        'site_code': value.siteCode,
        'added_by': value.addedBy,
        'name': value.name,
        'signed_on': value.signedOn === undefined ? undefined : value.signedOn.toISOString(),
        'recorded_at': value.recordedAt === undefined ? undefined : value.recordedAt.toISOString(),
        'document_url': value.documentUrl,
        'html_url': value.htmlUrl,
        'archived': value.archived,
        'id': value.id,
        'document_id': value.documentId,
        'mime': value.mime,
        'consent_type': value.consentType,
        'authorizer': UserInfoToJSON(value.authorizer),
        'minor': UserInfoToJSON(value.minor),
    };
}

/**
* @export
* @enum {string}
*/
export enum ConsentConsentTypeEnum {
    Attachment = 'attachment',
    Verbal = 'verbal'
}


