// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     XopQuestionCoreSchema,
    XopQuestionCoreSchemaFromJSON,
    XopQuestionCoreSchemaToJSON,
} from './XopQuestionCoreSchema';
import {
     XopQuestionDetailSchema,
    XopQuestionDetailSchemaFromJSON,
    XopQuestionDetailSchemaToJSON,
} from './XopQuestionDetailSchema';
/**
 * @type XopQuestionSchema
 * @export
 */
export interface XopQuestionSchema extends XopQuestionCoreSchema, XopQuestionDetailSchema {
}

export function XopQuestionSchemaFromJSON(json: any): XopQuestionSchema {
    return {
        ...XopQuestionCoreSchemaFromJSON(json),
        ...XopQuestionDetailSchemaFromJSON(json),
    };
}

export function XopQuestionSchemaToJSON(value?: XopQuestionSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...XopQuestionCoreSchemaToJSON(value),
        ...XopQuestionDetailSchemaToJSON(value),
    };
}

