// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     CalculatorJsonBody,
    CalculatorJsonBodyFromJSON,
    CalculatorJsonBodyToJSON,
} from './CalculatorJsonBody';
import {
     WorkspaceDraftDataSchemaBase,
    WorkspaceDraftDataSchemaBaseFromJSON,
    WorkspaceDraftDataSchemaBaseToJSON,
} from './WorkspaceDraftDataSchemaBase';
import {
     WorkspaceDraftDataSchemaCalculatorBase,
    WorkspaceDraftDataSchemaCalculatorBaseFromJSON,
    WorkspaceDraftDataSchemaCalculatorBaseToJSON,
} from './WorkspaceDraftDataSchemaCalculatorBase';
/**
 * @type WorkspaceDraftDataSchemaCalculator
 * @export
 */
export interface WorkspaceDraftDataSchemaCalculator extends WorkspaceDraftDataSchemaBase, WorkspaceDraftDataSchemaCalculatorBase {
}

export function WorkspaceDraftDataSchemaCalculatorFromJSON(json: any): WorkspaceDraftDataSchemaCalculator {
    return {
        ...WorkspaceDraftDataSchemaBaseFromJSON(json),
        ...WorkspaceDraftDataSchemaCalculatorBaseFromJSON(json),
    };
}

export function WorkspaceDraftDataSchemaCalculatorToJSON(value?: WorkspaceDraftDataSchemaCalculator): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...WorkspaceDraftDataSchemaBaseToJSON(value),
        ...WorkspaceDraftDataSchemaCalculatorBaseToJSON(value),
    };
}

