// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConfigResponseAssetsLogoOnWhite,
    ConfigResponseAssetsLogoOnWhiteFromJSON,
    ConfigResponseAssetsLogoOnWhiteToJSON,
} from './ConfigResponseAssetsLogoOnWhite';


/**
 * 
 * @export
 * @interface ConfigResponseAssets
 */
export interface ConfigResponseAssets  {
    /**
     * 
     * @type {ConfigResponseAssetsLogoOnWhite}
     * @memberof ConfigResponseAssets
     */
    logoOnWhite?: ConfigResponseAssetsLogoOnWhite;
    /**
     * 
     * @type {ConfigResponseAssetsLogoOnWhite}
     * @memberof ConfigResponseAssets
     */
    logoOnSplash?: ConfigResponseAssetsLogoOnWhite;
    /**
     * 
     * @type {ConfigResponseAssetsLogoOnWhite}
     * @memberof ConfigResponseAssets
     */
    logoOnSmallSidebar?: ConfigResponseAssetsLogoOnWhite;
    /**
     * 
     * @type {ConfigResponseAssetsLogoOnWhite}
     * @memberof ConfigResponseAssets
     */
    logoOnLargeSidebar?: ConfigResponseAssetsLogoOnWhite;
}

export function ConfigResponseAssetsFromJSON(json: any): ConfigResponseAssets {
    return {
        'logoOnWhite': !exists(json, 'logo_on_white') ? undefined : ConfigResponseAssetsLogoOnWhiteFromJSON(json['logo_on_white']),
        'logoOnSplash': !exists(json, 'logo_on_splash') ? undefined : ConfigResponseAssetsLogoOnWhiteFromJSON(json['logo_on_splash']),
        'logoOnSmallSidebar': !exists(json, 'logo_on_small_sidebar') ? undefined : ConfigResponseAssetsLogoOnWhiteFromJSON(json['logo_on_small_sidebar']),
        'logoOnLargeSidebar': !exists(json, 'logo_on_large_sidebar') ? undefined : ConfigResponseAssetsLogoOnWhiteFromJSON(json['logo_on_large_sidebar']),
    };
}

export function ConfigResponseAssetsToJSON(value?: ConfigResponseAssets): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'logo_on_white': ConfigResponseAssetsLogoOnWhiteToJSON(value.logoOnWhite),
        'logo_on_splash': ConfigResponseAssetsLogoOnWhiteToJSON(value.logoOnSplash),
        'logo_on_small_sidebar': ConfigResponseAssetsLogoOnWhiteToJSON(value.logoOnSmallSidebar),
        'logo_on_large_sidebar': ConfigResponseAssetsLogoOnWhiteToJSON(value.logoOnLargeSidebar),
    };
}


