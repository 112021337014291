// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ComponentInput,
    ComponentInputFromJSON,
    ComponentInputToJSON,
} from './ComponentInput';


/**
 * 
 * @export
 * @interface AddTimelineEventRequestBody
 */
export interface AddTimelineEventRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof AddTimelineEventRequestBody
     */
    responseType?: AddTimelineEventRequestBodyResponseTypeEnum;
    /**
     * 
     * @type {Array<ComponentInput>}
     * @memberof AddTimelineEventRequestBody
     */
    components: Array<ComponentInput>;
    /**
     * 
     * @type {string}
     * @memberof AddTimelineEventRequestBody
     */
    memberCurrentUsState?: string;
}

export function AddTimelineEventRequestBodyFromJSON(json: any): AddTimelineEventRequestBody {
    return {
        'responseType': !exists(json, 'response_type') ? undefined : json['response_type'],
        'components': (json['components'] as Array<any>).map(ComponentInputFromJSON),
        'memberCurrentUsState': !exists(json, 'member_current_us_state') ? undefined : json['member_current_us_state'],
    };
}

export function AddTimelineEventRequestBodyToJSON(value?: AddTimelineEventRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'response_type': value.responseType,
        'components': (value.components as Array<any>).map(ComponentInputToJSON),
        'member_current_us_state': value.memberCurrentUsState,
    };
}

/**
* @export
* @enum {string}
*/
export enum AddTimelineEventRequestBodyResponseTypeEnum {
    Summary = 'summary',
    Detail = 'detail'
}


