// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteTimelineEventRequestBody
 */
export interface DeleteTimelineEventRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof DeleteTimelineEventRequestBody
     */
    reason: DeleteTimelineEventRequestBodyReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof DeleteTimelineEventRequestBody
     */
    reasonFreetext?: string;
}

export function DeleteTimelineEventRequestBodyFromJSON(json: any): DeleteTimelineEventRequestBody {
    return {
        'reason': json['reason'],
        'reasonFreetext': !exists(json, 'reason_freetext') ? undefined : json['reason_freetext'],
    };
}

export function DeleteTimelineEventRequestBodyToJSON(value?: DeleteTimelineEventRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'reason': value.reason,
        'reason_freetext': value.reasonFreetext,
    };
}

/**
* @export
* @enum {string}
*/
export enum DeleteTimelineEventRequestBodyReasonEnum {
    CodeOrange = 'Code Orange',
    SentMessageToTheWrongMember = 'Sent message to the wrong member',
    MessageContainsTheWrongInformationOrAttachment = 'Message contains the wrong information or attachment',
    DuplicateMessage = 'Duplicate message',
    MessageSentInError = 'Message sent in error',
    Other = 'Other'
}


