// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    ListMembersResponse,
    ListMembersResponseFromJSON,
    ListMembersResponseToJSON,
} from '../models/ListMembersResponse'
import {
    MemberSearchResults,
    MemberSearchResultsFromJSON,
    MemberSearchResultsToJSON,
} from '../models/MemberSearchResults'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface ListMembersRequest {
    q: string;
    format: ListMembersFormatEnum;
    page?: string;
}

export interface MemberSearchRequest {
    efId: string;
    q: string;
}


/**
 * List members (both quick search field and full search)
 * List members
 */
function listMembersRaw<T>(requestParameters: ListMembersRequest, requestConfig: runtime.TypedQueryConfig<T, ListMembersResponse> = {}): QueryConfig<T> {
    if (requestParameters.q === null || requestParameters.q === undefined) {
        throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling listMembers.');
    }

    if (requestParameters.format === null || requestParameters.format === undefined) {
        throw new runtime.RequiredError('format','Required parameter requestParameters.format was null or undefined when calling listMembers.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.q !== undefined) {
        queryParameters['q'] = requestParameters.q;
    }


    if (requestParameters.format !== undefined) {
        queryParameters['format'] = requestParameters.format;
    }


    if (requestParameters.page !== undefined) {
        queryParameters['page'] = requestParameters.page;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/members`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ListMembersResponseFromJSON(body), text);
    }

    return config;
}

/**
* List members (both quick search field and full search)
* List members
*/
export function listMembers<T>(requestParameters: ListMembersRequest, requestConfig?: runtime.TypedQueryConfig<T, ListMembersResponse>): QueryConfig<T> {
    return listMembersRaw(requestParameters, requestConfig);
}

/**
 * This endpoint provides a search for members using (first_name, last_name, preferred_name, Dob)
 * Members Search Endpoint
 */
function memberSearchRaw<T>(requestParameters: MemberSearchRequest, requestConfig: runtime.TypedQueryConfig<T, MemberSearchResults> = {}): QueryConfig<T> {
    if (requestParameters.efId === null || requestParameters.efId === undefined) {
        throw new runtime.RequiredError('efId','Required parameter requestParameters.efId was null or undefined when calling memberSearch.');
    }

    if (requestParameters.q === null || requestParameters.q === undefined) {
        throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling memberSearch.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.q !== undefined) {
        queryParameters['q'] = requestParameters.q;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/members/search/{ef_id}`.replace(`{${"ef_id"}}`, encodeURIComponent(String(requestParameters.efId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(MemberSearchResultsFromJSON(body), text);
    }

    return config;
}

/**
* This endpoint provides a search for members using (first_name, last_name, preferred_name, Dob)
* Members Search Endpoint
*/
export function memberSearch<T>(requestParameters: MemberSearchRequest, requestConfig?: runtime.TypedQueryConfig<T, MemberSearchResults>): QueryConfig<T> {
    return memberSearchRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum ListMembersFormatEnum {
    Quick = 'quick',
    Full = 'full'
}
