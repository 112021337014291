// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LabInfo
 */
export interface LabInfo  {
    /**
     * 
     * @type {number}
     * @memberof LabInfo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LabInfo
     */
    title?: string;
    /**
     * 
     * @type {Date}
     * @memberof LabInfo
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof LabInfo
     */
    pdfLink?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LabInfo
     */
    viewed?: boolean;
}

export function LabInfoFromJSON(json: any): LabInfo {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'pdfLink': !exists(json, 'pdf_link') ? undefined : json['pdf_link'],
        'viewed': !exists(json, 'viewed') ? undefined : json['viewed'],
    };
}

export function LabInfoToJSON(value?: LabInfo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'title': value.title,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'pdf_link': value.pdfLink,
        'viewed': value.viewed,
    };
}


