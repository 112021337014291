// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CapacityFetchResponseSlotsCount,
    CapacityFetchResponseSlotsCountFromJSON,
    CapacityFetchResponseSlotsCountToJSON,
} from './CapacityFetchResponseSlotsCount';


/**
 * 
 * @export
 * @interface CapacityFetchResponseSlotsPerClinic
 */
export interface CapacityFetchResponseSlotsPerClinic  {
    /**
     * 
     * @type {string}
     * @memberof CapacityFetchResponseSlotsPerClinic
     */
    clinicName?: string;
    /**
     * 
     * @type {string}
     * @memberof CapacityFetchResponseSlotsPerClinic
     */
    clinicId: string;
    /**
     * 
     * @type {string}
     * @memberof CapacityFetchResponseSlotsPerClinic
     */
    nextDateWithSlots?: string;
    /**
     * 
     * @type {Array<CapacityFetchResponseSlotsCount>}
     * @memberof CapacityFetchResponseSlotsPerClinic
     */
    slotsCount?: Array<CapacityFetchResponseSlotsCount>;
}

export function CapacityFetchResponseSlotsPerClinicFromJSON(json: any): CapacityFetchResponseSlotsPerClinic {
    return {
        'clinicName': !exists(json, 'clinic_name') ? undefined : json['clinic_name'],
        'clinicId': json['clinic_id'],
        'nextDateWithSlots': !exists(json, 'next_date_with_slots') ? undefined : json['next_date_with_slots'],
        'slotsCount': !exists(json, 'slots_count') ? undefined : (json['slots_count'] as Array<any>).map(CapacityFetchResponseSlotsCountFromJSON),
    };
}

export function CapacityFetchResponseSlotsPerClinicToJSON(value?: CapacityFetchResponseSlotsPerClinic): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'clinic_name': value.clinicName,
        'clinic_id': value.clinicId,
        'next_date_with_slots': value.nextDateWithSlots,
        'slots_count': value.slotsCount === undefined ? undefined : (value.slotsCount as Array<any>).map(CapacityFetchResponseSlotsCountToJSON),
    };
}


