import { Middleware, Dispatch, AnyAction } from "redux";
import {
  RequestAsyncAction,
  MutateAsyncAction,
  QueryConfig,
} from "redux-query";
import { getStub } from "shared/stubs";

let hasHostMocking = false;

const getHostMockUrl = (url: string) => `http://localhost:8080${url}`;

export const setHostMocking = (value: boolean) => (hasHostMocking = value);

export const stubRequest: Middleware = () => (next: Dispatch) => (
  action: AnyAction | RequestAsyncAction | MutateAsyncAction
) => {
  if (
    action.type === "@@query/REQUEST_ASYNC" ||
    action.type === "@@query/MUTATE_ASYNC"
  ) {
    const queryAction = action as QueryConfig<unknown>;

    if (hasHostMocking) {
      queryAction.url = getHostMockUrl(queryAction.url);
    }

    const stubDetails = getStub(queryAction.url);

    if (stubDetails) {
      queryAction.url = "/stub" + queryAction.url;
      if (hasHostMocking) {
        queryAction.url = getHostMockUrl(queryAction.url);
      }
      const opts = queryAction.options || {};
      opts.method = "POST";
      queryAction.body = stubDetails;
      queryAction.options = opts;
    }
  }

  return next(action);
};
