// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NetworkCareTeam,
    NetworkCareTeamFromJSON,
    NetworkCareTeamToJSON,
} from './NetworkCareTeam';


/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200  {
    /**
     * 
     * @type {NetworkCareTeam}
     * @memberof InlineResponse200
     */
    results?: NetworkCareTeam;
}

export function InlineResponse200FromJSON(json: any): InlineResponse200 {
    return {
        'results': !exists(json, 'results') ? undefined : NetworkCareTeamFromJSON(json['results']),
    };
}

export function InlineResponse200ToJSON(value?: InlineResponse200): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': NetworkCareTeamToJSON(value.results),
    };
}


