// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    BillingComponent,
    BillingComponentFromJSON,
    BillingComponentToJSON,
} from './BillingComponent';
import {
    CalculatorComponent,
    CalculatorComponentFromJSON,
    CalculatorComponentToJSON,
} from './CalculatorComponent';
import {
    CenterVisitReferralComponentOutput,
    CenterVisitReferralComponentOutputFromJSON,
    CenterVisitReferralComponentOutputToJSON,
} from './CenterVisitReferralComponentOutput';
import {
    CtmEpisodeCreatedComponentOutput,
    CtmEpisodeCreatedComponentOutputFromJSON,
    CtmEpisodeCreatedComponentOutputToJSON,
} from './CtmEpisodeCreatedComponentOutput';
import {
    CtmMessageComponentDetailsOutput,
    CtmMessageComponentDetailsOutputFromJSON,
    CtmMessageComponentDetailsOutputToJSON,
} from './CtmMessageComponentDetailsOutput';
import {
    CtmMessagingEpisodeCreatedComponentOutput,
    CtmMessagingEpisodeCreatedComponentOutputFromJSON,
    CtmMessagingEpisodeCreatedComponentOutputToJSON,
} from './CtmMessagingEpisodeCreatedComponentOutput';
import {
    DiagnosisComponentDetailsOutput,
    DiagnosisComponentDetailsOutputFromJSON,
    DiagnosisComponentDetailsOutputToJSON,
} from './DiagnosisComponentDetailsOutput';
import {
    EpisodeCreatedComponentDetailsOutput,
    EpisodeCreatedComponentDetailsOutputFromJSON,
    EpisodeCreatedComponentDetailsOutputToJSON,
} from './EpisodeCreatedComponentDetailsOutput';
import {
    MarketingMessageComponentOutput,
    MarketingMessageComponentOutputFromJSON,
    MarketingMessageComponentOutputToJSON,
} from './MarketingMessageComponentOutput';
import {
    MemberMessageComponentDetailsOutput,
    MemberMessageComponentDetailsOutputFromJSON,
    MemberMessageComponentDetailsOutputToJSON,
} from './MemberMessageComponentDetailsOutput';
import {
    MessagingEpisodeCreatedComponentDetailsOutput,
    MessagingEpisodeCreatedComponentDetailsOutputFromJSON,
    MessagingEpisodeCreatedComponentDetailsOutputToJSON,
} from './MessagingEpisodeCreatedComponentDetailsOutput';
import {
    QuestionSetComponentOutput,
    QuestionSetComponentOutputFromJSON,
    QuestionSetComponentOutputToJSON,
} from './QuestionSetComponentOutput';
import {
    SelfScheduleAppointmentComponent,
    SelfScheduleAppointmentComponentFromJSON,
    SelfScheduleAppointmentComponentToJSON,
} from './SelfScheduleAppointmentComponent';
import {
    VirtualVisitReferralComponentDetailsOutput,
    VirtualVisitReferralComponentDetailsOutputFromJSON,
    VirtualVisitReferralComponentDetailsOutputToJSON,
} from './VirtualVisitReferralComponentDetailsOutput';
import {
    VisitReferralComponentDetailsOutput,
    VisitReferralComponentDetailsOutputFromJSON,
    VisitReferralComponentDetailsOutputToJSON,
} from './VisitReferralComponentDetailsOutput';

/**
 * @type ComponentDetailsOutput
 * @export
 */
export type ComponentDetailsOutput = BillingComponent | CalculatorComponent | CenterVisitReferralComponentOutput | CtmEpisodeCreatedComponentOutput | CtmMessageComponentDetailsOutput | CtmMessagingEpisodeCreatedComponentOutput | DiagnosisComponentDetailsOutput | EpisodeCreatedComponentDetailsOutput | MarketingMessageComponentOutput | MemberMessageComponentDetailsOutput | MessagingEpisodeCreatedComponentDetailsOutput | QuestionSetComponentOutput | SelfScheduleAppointmentComponent | VirtualVisitReferralComponentDetailsOutput | VisitReferralComponentDetailsOutput;


export function ComponentDetailsOutputFromJSON(json: any): ComponentDetailsOutput {
  switch (json.type) {
  case 'episode_created': return EpisodeCreatedComponentDetailsOutputFromJSON(json);
  case 'member_message': return MemberMessageComponentDetailsOutputFromJSON(json);
  case 'ctm_message': return CtmMessageComponentDetailsOutputFromJSON(json);
  case 'diagnosis': return DiagnosisComponentDetailsOutputFromJSON(json);
  case 'virtual_visit_referral': return VirtualVisitReferralComponentDetailsOutputFromJSON(json);
  case 'ctm_episode_created': return CtmEpisodeCreatedComponentOutputFromJSON(json);
  case 'ctm_messaging_episode_created': return CtmMessagingEpisodeCreatedComponentOutputFromJSON(json);
  case 'question_set': return QuestionSetComponentOutputFromJSON(json);
  case 'center_visit_referral': return CenterVisitReferralComponentOutputFromJSON(json);
  case 'visit_referral': return VisitReferralComponentDetailsOutputFromJSON(json);
  case 'calculator': return CalculatorComponentFromJSON(json);
  case 'billing': return BillingComponentFromJSON(json);
  case 'marketing_message': return MarketingMessageComponentOutputFromJSON(json);
  case 'self_schedule_appointment': return SelfScheduleAppointmentComponentFromJSON(json);
  case 'messaging_episode_created': return MessagingEpisodeCreatedComponentDetailsOutputFromJSON(json);
  default: throw new Error("Unexpected type value.");
  }
}

export function ComponentDetailsOutputToJSON(value?: ComponentDetailsOutput): any {
    if (value === undefined) {
        return undefined;
    }

    switch (value.type) {
    case 'episode_created': return EpisodeCreatedComponentDetailsOutputToJSON(<EpisodeCreatedComponentDetailsOutput>value);
    case 'member_message': return MemberMessageComponentDetailsOutputToJSON(<MemberMessageComponentDetailsOutput>value);
    case 'ctm_message': return CtmMessageComponentDetailsOutputToJSON(<CtmMessageComponentDetailsOutput>value);
    case 'diagnosis': return DiagnosisComponentDetailsOutputToJSON(<DiagnosisComponentDetailsOutput>value);
    case 'virtual_visit_referral': return VirtualVisitReferralComponentDetailsOutputToJSON(<VirtualVisitReferralComponentDetailsOutput>value);
    case 'ctm_episode_created': return CtmEpisodeCreatedComponentOutputToJSON(<CtmEpisodeCreatedComponentOutput>value);
    case 'ctm_messaging_episode_created': return CtmMessagingEpisodeCreatedComponentOutputToJSON(<CtmMessagingEpisodeCreatedComponentOutput>value);
    case 'question_set': return QuestionSetComponentOutputToJSON(<QuestionSetComponentOutput>value);
    case 'center_visit_referral': return CenterVisitReferralComponentOutputToJSON(<CenterVisitReferralComponentOutput>value);
    case 'visit_referral': return VisitReferralComponentDetailsOutputToJSON(<VisitReferralComponentDetailsOutput>value);
    case 'calculator': return CalculatorComponentToJSON(<CalculatorComponent>value);
    case 'billing': return BillingComponentToJSON(<BillingComponent>value);
    case 'marketing_message': return MarketingMessageComponentOutputToJSON(<MarketingMessageComponentOutput>value);
    case 'self_schedule_appointment': return SelfScheduleAppointmentComponentToJSON(<SelfScheduleAppointmentComponent>value);
    case 'messaging_episode_created': return MessagingEpisodeCreatedComponentDetailsOutputToJSON(<MessagingEpisodeCreatedComponentDetailsOutput>value);
    default: throw new Error("Unexpected type value.");
    }
}

