// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
    MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';


/**
 * 
 * @export
 * @interface EpisodeOutputCoreAllOf
 */
export interface EpisodeOutputCoreAllOf  {
    /**
     * 
     * @type {string}
     * @memberof EpisodeOutputCoreAllOf
     */
    urgentPhoneNumber?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EpisodeOutputCoreAllOf
     */
    billable?: boolean;
    /**
     * 
     * @type {Array<CTMInfo>}
     * @memberof EpisodeOutputCoreAllOf
     */
    careTeamParticipants?: Array<CTMInfo>;
    /**
     * 
     * @type {MemberInfo}
     * @memberof EpisodeOutputCoreAllOf
     */
    lastMemberUpdater?: MemberInfo;
    /**
     * 
     * @type {CTMInfo}
     * @memberof EpisodeOutputCoreAllOf
     */
    owner?: CTMInfo;
    /**
     * 
     * @type {CTMInfo}
     * @memberof EpisodeOutputCoreAllOf
     */
    lastCtmUpdater?: CTMInfo;
}

export function EpisodeOutputCoreAllOfFromJSON(json: any): EpisodeOutputCoreAllOf {
    return {
        'urgentPhoneNumber': !exists(json, 'urgent_phone_number') ? undefined : json['urgent_phone_number'],
        'billable': !exists(json, 'billable') ? undefined : json['billable'],
        'careTeamParticipants': !exists(json, 'care_team_participants') ? undefined : (json['care_team_participants'] as Array<any>).map(CTMInfoFromJSON),
        'lastMemberUpdater': !exists(json, 'last_member_updater') ? undefined : MemberInfoFromJSON(json['last_member_updater']),
        'owner': !exists(json, 'owner') ? undefined : CTMInfoFromJSON(json['owner']),
        'lastCtmUpdater': !exists(json, 'last_ctm_updater') ? undefined : CTMInfoFromJSON(json['last_ctm_updater']),
    };
}

export function EpisodeOutputCoreAllOfToJSON(value?: EpisodeOutputCoreAllOf): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'urgent_phone_number': value.urgentPhoneNumber,
        'billable': value.billable,
        'care_team_participants': value.careTeamParticipants === undefined ? undefined : (value.careTeamParticipants as Array<any>).map(CTMInfoToJSON),
        'last_member_updater': MemberInfoToJSON(value.lastMemberUpdater),
        'owner': CTMInfoToJSON(value.owner),
        'last_ctm_updater': CTMInfoToJSON(value.lastCtmUpdater),
    };
}


