import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import Box from "../../Box";
import { eocRootPath } from "containers/Routing";
import { getUser } from "shared/features/user";
import Typography from "components/Typography";
import LinkWithAccess from "components/Button/LinkWithAccess";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      paddingLeft: theme.spacing(2),
    },
    link: {
      fontWeight: "normal",
      textDecoration: "underline",
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      padding: 0,
    },
    text: {
      margin: "auto 0",
    },
  })
);

const SelfSchedulingActions: FunctionComponent<{}> = (): JSX.Element => {
  const classes = useStyles();
  const user = useSelector(getUser);

  return (
    <Box
      ml={-2}
      mt={1.25}
      mb={3}
      data-testid="self-schedule-create-eoc-btn"
      className={classes.mainContainer}
    >
      <LinkWithAccess
        access={PermissionsEnum.EpisodesCreate}
        button={false}
        color="primary"
        to={eocRootPath}
        title="Start a new conversation"
        className={classes.link}
      >
        Get care now
      </LinkWithAccess>
      <Typography className={classes.text}>or</Typography>
      <LinkWithAccess
        access={PermissionsEnum.SelfScheduleCreate}
        button={false}
        color="primary"
        to={`/members/${user?.id}/self-schedule`}
        title="Start a new conversation"
        className={classes.link}
        data-e2e="schedule-a-visit"
      >
        schedule a visit
      </LinkWithAccess>
    </Box>
  );
};

export default SelfSchedulingActions;
