// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberCentersObject,
    MemberCentersObjectFromJSON,
    MemberCentersObjectToJSON,
} from './MemberCentersObject';
import {
    MemberServicesObject,
    MemberServicesObjectFromJSON,
    MemberServicesObjectToJSON,
} from './MemberServicesObject';
import {
    ProvidersObject,
    ProvidersObjectFromJSON,
    ProvidersObjectToJSON,
} from './ProvidersObject';


/**
 * 
 * @export
 * @interface MemberServicesAndCentersObject
 */
export interface MemberServicesAndCentersObject  {
    /**
     * 
     * @type {string}
     * @memberof MemberServicesAndCentersObject
     */
    prevUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberServicesAndCentersObject
     */
    nextUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberServicesAndCentersObject
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberServicesAndCentersObject
     */
    last?: number;
    /**
     * 
     * @type {Array<MemberCentersObject>}
     * @memberof MemberServicesAndCentersObject
     */
    centers?: Array<MemberCentersObject>;
    /**
     * 
     * @type {Array<MemberServicesObject>}
     * @memberof MemberServicesAndCentersObject
     */
    services?: Array<MemberServicesObject>;
    /**
     * 
     * @type {Array<ProvidersObject>}
     * @memberof MemberServicesAndCentersObject
     */
    providerGroups?: Array<ProvidersObject>;
}

export function MemberServicesAndCentersObjectFromJSON(json: any): MemberServicesAndCentersObject {
    return {
        'prevUrl': !exists(json, 'prev_url') ? undefined : json['prev_url'],
        'nextUrl': !exists(json, 'next_url') ? undefined : json['next_url'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'centers': !exists(json, 'centers') ? undefined : (json['centers'] as Array<any>).map(MemberCentersObjectFromJSON),
        'services': !exists(json, 'services') ? undefined : (json['services'] as Array<any>).map(MemberServicesObjectFromJSON),
        'providerGroups': !exists(json, 'provider_groups') ? undefined : (json['provider_groups'] as Array<any>).map(ProvidersObjectFromJSON),
    };
}

export function MemberServicesAndCentersObjectToJSON(value?: MemberServicesAndCentersObject): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'prev_url': value.prevUrl,
        'next_url': value.nextUrl,
        'page': value.page,
        'last': value.last,
        'centers': value.centers === undefined ? undefined : (value.centers as Array<any>).map(MemberCentersObjectToJSON),
        'services': value.services === undefined ? undefined : (value.services as Array<any>).map(MemberServicesObjectToJSON),
        'provider_groups': value.providerGroups === undefined ? undefined : (value.providerGroups as Array<any>).map(ProvidersObjectToJSON),
    };
}


