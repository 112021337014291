// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ComponentDetailsOutput,
    ComponentDetailsOutputFromJSON,
    ComponentDetailsOutputToJSON,
} from './ComponentDetailsOutput';


/**
 * 
 * @export
 * @interface TimelineEventOutputProps
 */
export interface TimelineEventOutputProps  {
    /**
     * 
     * @type {Array<ComponentDetailsOutput>}
     * @memberof TimelineEventOutputProps
     */
    components?: Array<ComponentDetailsOutput>;
}

export function TimelineEventOutputPropsFromJSON(json: any): TimelineEventOutputProps {
    return {
        'components': !exists(json, 'components') ? undefined : (json['components'] as Array<any>).map(ComponentDetailsOutputFromJSON),
    };
}

export function TimelineEventOutputPropsToJSON(value?: TimelineEventOutputProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'components': value.components === undefined ? undefined : (value.components as Array<any>).map(ComponentDetailsOutputToJSON),
    };
}


