import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";
import { storeToken } from "../auth/utils";
import {
  MEMBER_ACCESS_ID,
  MEMBER_ACCESS_TOKEN_KEY,
  setMemberAccessToken,
  setMemberAccessUser,
} from "../auth";
import { removeToken } from "../auth/utils";
import {
  GrantAccessToMemberRequest,
  fetchPatientAccessApi,
} from "shared/fetch/src/apis/PatientAccessApi";

const useGetPatientAccess = () => {
  const dispatch = useDispatch();

  const getPatientAccess = useMutation(
    (request: GrantAccessToMemberRequest) =>
      fetchPatientAccessApi.grantAccessToMember(request),
    {
      onSuccess: (data) => {
        storeToken(MEMBER_ACCESS_TOKEN_KEY, data.memberAccessToken);
        dispatch(
          setMemberAccessToken({
            memberAccessToken: data.memberAccessToken,
          })
        );

        dispatch(
          setMemberAccessUser({
            memberAccessUser: data.member,
          })
        );
        storeToken(MEMBER_ACCESS_ID, `${data?.member?.id}`);
      },
      onError: () => {
        removeToken(MEMBER_ACCESS_TOKEN_KEY);
        removeToken(MEMBER_ACCESS_ID);
        dispatch(
          showSnackbar(
            "Error Accessing Account, please contact the support team.",
            "danger"
          )
        );
      },
    }
  );
  return getPatientAccess;
};

export default useGetPatientAccess;
