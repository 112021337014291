// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     DisplayPropertiesSchema,
    DisplayPropertiesSchemaFromJSON,
    DisplayPropertiesSchemaToJSON,
} from './DisplayPropertiesSchema';
import {
     ExtendedDisplayPropertiesSchemaProps,
    ExtendedDisplayPropertiesSchemaPropsFromJSON,
    ExtendedDisplayPropertiesSchemaPropsToJSON,
} from './ExtendedDisplayPropertiesSchemaProps';
/**
 * @type ExtendedDisplayPropertiesSchema
 * @export
 */
export interface ExtendedDisplayPropertiesSchema extends DisplayPropertiesSchema, ExtendedDisplayPropertiesSchemaProps {
}

export function ExtendedDisplayPropertiesSchemaFromJSON(json: any): ExtendedDisplayPropertiesSchema {
    return {
        ...DisplayPropertiesSchemaFromJSON(json),
        ...ExtendedDisplayPropertiesSchemaPropsFromJSON(json),
    };
}

export function ExtendedDisplayPropertiesSchemaToJSON(value?: ExtendedDisplayPropertiesSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...DisplayPropertiesSchemaToJSON(value),
        ...ExtendedDisplayPropertiesSchemaPropsToJSON(value),
    };
}

