// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     WorkspaceDraftDataSchemaBase,
    WorkspaceDraftDataSchemaBaseFromJSON,
    WorkspaceDraftDataSchemaBaseToJSON,
} from './WorkspaceDraftDataSchemaBase';
import {
     WorkspaceDraftDataSchemaReferralBase,
    WorkspaceDraftDataSchemaReferralBaseFromJSON,
    WorkspaceDraftDataSchemaReferralBaseToJSON,
} from './WorkspaceDraftDataSchemaReferralBase';
/**
 * @type WorkspaceDraftDataSchemaVisitReferral
 * @export
 */
export interface WorkspaceDraftDataSchemaVisitReferral extends WorkspaceDraftDataSchemaBase, WorkspaceDraftDataSchemaReferralBase {
}

export function WorkspaceDraftDataSchemaVisitReferralFromJSON(json: any): WorkspaceDraftDataSchemaVisitReferral {
    return {
        ...WorkspaceDraftDataSchemaBaseFromJSON(json),
        ...WorkspaceDraftDataSchemaReferralBaseFromJSON(json),
    };
}

export function WorkspaceDraftDataSchemaVisitReferralToJSON(value?: WorkspaceDraftDataSchemaVisitReferral): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...WorkspaceDraftDataSchemaBaseToJSON(value),
        ...WorkspaceDraftDataSchemaReferralBaseToJSON(value),
    };
}

