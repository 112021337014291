import React, { FunctionComponent, useEffect, useRef } from "react";
import DialogTitleComponent, {
  DialogTitleProps,
} from "@mui/material/DialogTitle";
import clsx from "clsx";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "components/Typography";
import Asset from "components/Asset";
import Button from "components/Button";

interface IProps extends Omit<DialogTitleProps, "disableTypography"> {
  onClose?: () => void;
}

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  close: {
    width: "15px",
  },
}));

const DialogTitle: FunctionComponent<IProps> = (props) => {
  const { onClose } = props;
  const classes = useStyles(props);
  const closeModalButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (onClose) {
      const node = closeModalButtonRef.current;
      if (node) {
        node.focus();
      }
    }
  }, []);

  return (
    <DialogTitleComponent
      {...(props as any)}
      className={clsx(props.className, classes.root)}
    >
      <Typography appearance="h4">{props.children}</Typography>
      {onClose ? (
        <Button
          onClick={onClose}
          color="link-secondary"
          aria-label="Close dialog"
          ref={closeModalButtonRef}
        >
          <Asset
            name="icon-close"
            className={`icon icon-close ${classes.close}`}
          />
        </Button>
      ) : null}
    </DialogTitleComponent>
  );
};

export default DialogTitle;
