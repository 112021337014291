/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccountActivationRequestBody,
    AccountActivationRequestBodyFromJSON,
    AccountActivationRequestBodyToJSON,
} from '../models/AccountActivationRequestBody'
import {
    AccountActivationResponse,
    AccountActivationResponseFromJSON,
    AccountActivationResponseToJSON,
} from '../models/AccountActivationResponse'
import {
    AccountActivationResponse2,
    AccountActivationResponse2FromJSON,
    AccountActivationResponse2ToJSON,
} from '../models/AccountActivationResponse2'
import {
    AuthorizeUserCookieRequestBody,
    AuthorizeUserCookieRequestBodyFromJSON,
    AuthorizeUserCookieRequestBodyToJSON,
} from '../models/AuthorizeUserCookieRequestBody'
import {
    AvatarDeletionSuccessResponse,
    AvatarDeletionSuccessResponseFromJSON,
    AvatarDeletionSuccessResponseToJSON,
} from '../models/AvatarDeletionSuccessResponse'
import {
    AvatarUploadSuccessResponse,
    AvatarUploadSuccessResponseFromJSON,
    AvatarUploadSuccessResponseToJSON,
} from '../models/AvatarUploadSuccessResponse'
import {
    EmailVerificationSuccessResponse,
    EmailVerificationSuccessResponseFromJSON,
    EmailVerificationSuccessResponseToJSON,
} from '../models/EmailVerificationSuccessResponse'
import {
    FetchLoginStatsSuccessResponse,
    FetchLoginStatsSuccessResponseFromJSON,
    FetchLoginStatsSuccessResponseToJSON,
} from '../models/FetchLoginStatsSuccessResponse'
import {
    FetchPatientQuickListSuccessResponse,
    FetchPatientQuickListSuccessResponseFromJSON,
    FetchPatientQuickListSuccessResponseToJSON,
} from '../models/FetchPatientQuickListSuccessResponse'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    JsonUser,
    JsonUserFromJSON,
    JsonUserToJSON,
} from '../models/JsonUser'
import {
    MinorAccountActivationRequestBody,
    MinorAccountActivationRequestBodyFromJSON,
    MinorAccountActivationRequestBodyToJSON,
} from '../models/MinorAccountActivationRequestBody'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    PasswordResetSuccessResponse,
    PasswordResetSuccessResponseFromJSON,
    PasswordResetSuccessResponseToJSON,
} from '../models/PasswordResetSuccessResponse'
import {
    PasswordUpdateSuccessResponse,
    PasswordUpdateSuccessResponseFromJSON,
    PasswordUpdateSuccessResponseToJSON,
} from '../models/PasswordUpdateSuccessResponse'
import {
    ResetUserPasswordRequestBody,
    ResetUserPasswordRequestBodyFromJSON,
    ResetUserPasswordRequestBodyToJSON,
} from '../models/ResetUserPasswordRequestBody'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UnauthorizedSessionResponse,
    UnauthorizedSessionResponseFromJSON,
    UnauthorizedSessionResponseToJSON,
} from '../models/UnauthorizedSessionResponse'
import {
    UpdateUserPasswordRequestBody,
    UpdateUserPasswordRequestBodyFromJSON,
    UpdateUserPasswordRequestBodyToJSON,
} from '../models/UpdateUserPasswordRequestBody'
import {
    UpdateUserRequestBody,
    UpdateUserRequestBodyFromJSON,
    UpdateUserRequestBodyToJSON,
} from '../models/UpdateUserRequestBody'
import {
    UserLookupRequestBody,
    UserLookupRequestBodyFromJSON,
    UserLookupRequestBodyToJSON,
} from '../models/UserLookupRequestBody'
import {
    VerifyUserByEmailRequestBody,
    VerifyUserByEmailRequestBodyFromJSON,
    VerifyUserByEmailRequestBodyToJSON,
} from '../models/VerifyUserByEmailRequestBody'


export interface AccountActivationRequest {
    accountActivationRequestBody?: AccountActivationRequestBody;
}
export interface AuthorizeUserCookieRequest {
    id: number;
    authorizeUserCookieRequestBody?: AuthorizeUserCookieRequestBody;
}
export interface DeleteUserAvatarRequest {
    id: number;
}
export interface FetchPatientQuickListForStaffUserRequest {
    id: number;
}
export interface FetchUserRequest {
    id: number;
}
export interface FetchUserLoginstatsRequest {
    id: number;
}
export interface ResetuserPasswordRequest {
    resetUserPasswordRequestBody?: ResetUserPasswordRequestBody;
}
export interface SendResetUserPasswordEmailRequest {
    id: number;
}
export interface UpdateUserRequest {
    id: number;
    updateUserRequestBody?: UpdateUserRequestBody;
}
export interface UpdateUserPasswordRequest {
    updateUserPasswordRequestBody?: UpdateUserPasswordRequestBody;
}
export interface UploadAvatarRequest {
    id: number;
    file: Blob;
}
export interface UserLookupRequest {
    userLookupRequestBody?: UserLookupRequestBody;
}
export interface VerifyUserByEmailRequest {
    verifyUserByEmailRequestBody?: VerifyUserByEmailRequestBody;
}
export interface XOPlatformAccountActivationRequest {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    dob: string;
    ssnLast4: string;
    employeeId: string;
    workEmail: string;
    file: Blob;
    efId: string;
    cellPhone?: string;
    cellTexts?: boolean;
    preferredLanguage?: string;
    stateCode?: string;
    subscriberId?: string;
    consents?: Array<string>;
    extraRegistrationId?: string;
    consentId?: number;
}
export interface XOPlatformAccountActivationMinorRequest {
    minorAccountActivationRequestBody?: MinorAccountActivationRequestBody;
}


export interface IAccountsApi {
      accountActivation: (requestParameters: AccountActivationRequest) => Promise<AccountActivationResponse>
      authorizeUserCookie: (requestParameters: AuthorizeUserCookieRequest) => Promise<JsonUser>
      deleteUserAvatar: (requestParameters: DeleteUserAvatarRequest) => Promise<AvatarDeletionSuccessResponse>
      fetchPatientQuickListForStaffUser: (requestParameters: FetchPatientQuickListForStaffUserRequest) => Promise<FetchPatientQuickListSuccessResponse>
      fetchUser: (requestParameters: FetchUserRequest) => Promise<JsonUser>
      fetchUserFromToken: () => Promise<JsonUser>
      fetchUserLoginstats: (requestParameters: FetchUserLoginstatsRequest) => Promise<FetchLoginStatsSuccessResponse>
      patchUser: () => Promise<JsonUser>
      resetuserPassword: (requestParameters: ResetuserPasswordRequest) => Promise<PasswordResetSuccessResponse>
      sendResetUserPasswordEmail: (requestParameters: SendResetUserPasswordEmailRequest) => Promise<void>
      updateUser: (requestParameters: UpdateUserRequest) => Promise<JsonUser>
      updateUserPassword: (requestParameters: UpdateUserPasswordRequest) => Promise<PasswordUpdateSuccessResponse>
      uploadAvatar: (requestParameters: UploadAvatarRequest) => Promise<AvatarUploadSuccessResponse>
      userLookup: (requestParameters: UserLookupRequest) => Promise<void>
      verifyUserByEmail: (requestParameters: VerifyUserByEmailRequest) => Promise<EmailVerificationSuccessResponse>
      xOPlatformAccountActivation: (requestParameters: XOPlatformAccountActivationRequest) => Promise<AccountActivationResponse>
      xOPlatformAccountActivationMinor: (requestParameters: XOPlatformAccountActivationMinorRequest) => Promise<AccountActivationResponse2>
      accountActivationWithResponse: (requestParameters: AccountActivationRequest) => Promise<{ value: AccountActivationResponse, response: any}>
      authorizeUserCookieWithResponse: (requestParameters: AuthorizeUserCookieRequest) => Promise<{ value: JsonUser, response: any}>
      deleteUserAvatarWithResponse: (requestParameters: DeleteUserAvatarRequest) => Promise<{ value: AvatarDeletionSuccessResponse, response: any}>
      fetchPatientQuickListForStaffUserWithResponse: (requestParameters: FetchPatientQuickListForStaffUserRequest) => Promise<{ value: FetchPatientQuickListSuccessResponse, response: any}>
      fetchUserWithResponse: (requestParameters: FetchUserRequest) => Promise<{ value: JsonUser, response: any}>
      fetchUserFromTokenWithResponse: () => Promise<{ value: JsonUser, response: any}>
      fetchUserLoginstatsWithResponse: (requestParameters: FetchUserLoginstatsRequest) => Promise<{ value: FetchLoginStatsSuccessResponse, response: any}>
      patchUserWithResponse: () => Promise<{ value: JsonUser, response: any}>
      resetuserPasswordWithResponse: (requestParameters: ResetuserPasswordRequest) => Promise<{ value: PasswordResetSuccessResponse, response: any}>
      sendResetUserPasswordEmailWithResponse: (requestParameters: SendResetUserPasswordEmailRequest) => Promise<{ value: void, response: any}>
      updateUserWithResponse: (requestParameters: UpdateUserRequest) => Promise<{ value: JsonUser, response: any}>
      updateUserPasswordWithResponse: (requestParameters: UpdateUserPasswordRequest) => Promise<{ value: PasswordUpdateSuccessResponse, response: any}>
      uploadAvatarWithResponse: (requestParameters: UploadAvatarRequest) => Promise<{ value: AvatarUploadSuccessResponse, response: any}>
      userLookupWithResponse: (requestParameters: UserLookupRequest) => Promise<{ value: void, response: any}>
      verifyUserByEmailWithResponse: (requestParameters: VerifyUserByEmailRequest) => Promise<{ value: EmailVerificationSuccessResponse, response: any}>
      xOPlatformAccountActivationWithResponse: (requestParameters: XOPlatformAccountActivationRequest) => Promise<{ value: AccountActivationResponse, response: any}>
      xOPlatformAccountActivationMinorWithResponse: (requestParameters: XOPlatformAccountActivationMinorRequest) => Promise<{ value: AccountActivationResponse2, response: any}>
}

/**
 * no description
 */
export class AccountsApi extends runtime.BaseAPI {

    /**
     * Activate a new user account
     */
    accountActivationRaw = async (requestParameters: AccountActivationRequest): Promise<{ runtime: runtime.ApiResponse<AccountActivationResponse>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AccountActivationRequestBodyToJSON(requestParameters.accountActivationRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AccountActivationResponseFromJSON(jsonValue)), response };
    }

    /**
     * Activate a new user account
     */
    accountActivation = async (requestParameters: AccountActivationRequest): Promise<AccountActivationResponse> => {
        const { runtime } = await this.accountActivationRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Activate a new user account
     */
    accountActivationWithResponse = async (requestParameters: AccountActivationRequest): Promise<{ value: AccountActivationResponse, response: any}> => {
        const { runtime, response } = await this.accountActivationRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Authorize user cookie acknowledgement
     */
    authorizeUserCookieRaw = async (requestParameters: AuthorizeUserCookieRequest): Promise<{ runtime: runtime.ApiResponse<JsonUser>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling authorizeUserCookie.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: AuthorizeUserCookieRequestBodyToJSON(requestParameters.authorizeUserCookieRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => JsonUserFromJSON(jsonValue)), response };
    }

    /**
     * Authorize user cookie acknowledgement
     */
    authorizeUserCookie = async (requestParameters: AuthorizeUserCookieRequest): Promise<JsonUser> => {
        const { runtime } = await this.authorizeUserCookieRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Authorize user cookie acknowledgement
     */
    authorizeUserCookieWithResponse = async (requestParameters: AuthorizeUserCookieRequest): Promise<{ value: JsonUser, response: any}> => {
        const { runtime, response } = await this.authorizeUserCookieRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Delete a user avatar
     */
    deleteUserAvatarRaw = async (requestParameters: DeleteUserAvatarRequest): Promise<{ runtime: runtime.ApiResponse<AvatarDeletionSuccessResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserAvatar.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/users/{id}/avatar`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AvatarDeletionSuccessResponseFromJSON(jsonValue)), response };
    }

    /**
     * Delete a user avatar
     */
    deleteUserAvatar = async (requestParameters: DeleteUserAvatarRequest): Promise<AvatarDeletionSuccessResponse> => {
        const { runtime } = await this.deleteUserAvatarRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Delete a user avatar
     */
    deleteUserAvatarWithResponse = async (requestParameters: DeleteUserAvatarRequest): Promise<{ value: AvatarDeletionSuccessResponse, response: any}> => {
        const { runtime, response } = await this.deleteUserAvatarRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch a user\'s patient search quick list
     */
    fetchPatientQuickListForStaffUserRaw = async (requestParameters: FetchPatientQuickListForStaffUserRequest): Promise<{ runtime: runtime.ApiResponse<FetchPatientQuickListSuccessResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchPatientQuickListForStaffUser.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/users/{id}/patient-quick-list`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => FetchPatientQuickListSuccessResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch a user\'s patient search quick list
     */
    fetchPatientQuickListForStaffUser = async (requestParameters: FetchPatientQuickListForStaffUserRequest): Promise<FetchPatientQuickListSuccessResponse> => {
        const { runtime } = await this.fetchPatientQuickListForStaffUserRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch a user\'s patient search quick list
     */
    fetchPatientQuickListForStaffUserWithResponse = async (requestParameters: FetchPatientQuickListForStaffUserRequest): Promise<{ value: FetchPatientQuickListSuccessResponse, response: any}> => {
        const { runtime, response } = await this.fetchPatientQuickListForStaffUserRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch user by ID
     */
    fetchUserRaw = async (requestParameters: FetchUserRequest): Promise<{ runtime: runtime.ApiResponse<JsonUser>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchUser.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => JsonUserFromJSON(jsonValue)), response };
    }

    /**
     * Fetch user by ID
     */
    fetchUser = async (requestParameters: FetchUserRequest): Promise<JsonUser> => {
        const { runtime } = await this.fetchUserRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch user by ID
     */
    fetchUserWithResponse = async (requestParameters: FetchUserRequest): Promise<{ value: JsonUser, response: any}> => {
        const { runtime, response } = await this.fetchUserRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch user from token
     */
    fetchUserFromTokenRaw = async (): Promise<{ runtime: runtime.ApiResponse<JsonUser>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/users/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => JsonUserFromJSON(jsonValue)), response };
    }

    /**
     * Fetch user from token
     */
    fetchUserFromToken = async (): Promise<JsonUser> => {
        const { runtime } = await this.fetchUserFromTokenRaw();
        return await runtime.value();
    }

    /**
     * Fetch user from token
     */
    fetchUserFromTokenWithResponse = async (): Promise<{ value: JsonUser, response: any}> => {
        const { runtime, response } = await this.fetchUserFromTokenRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch a user\'s login stats
     */
    fetchUserLoginstatsRaw = async (requestParameters: FetchUserLoginstatsRequest): Promise<{ runtime: runtime.ApiResponse<FetchLoginStatsSuccessResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchUserLoginstats.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/users/{id}/login_stats`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => FetchLoginStatsSuccessResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch a user\'s login stats
     */
    fetchUserLoginstats = async (requestParameters: FetchUserLoginstatsRequest): Promise<FetchLoginStatsSuccessResponse> => {
        const { runtime } = await this.fetchUserLoginstatsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch a user\'s login stats
     */
    fetchUserLoginstatsWithResponse = async (requestParameters: FetchUserLoginstatsRequest): Promise<{ value: FetchLoginStatsSuccessResponse, response: any}> => {
        const { runtime, response } = await this.fetchUserLoginstatsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch user from token
     */
    patchUserRaw = async (): Promise<{ runtime: runtime.ApiResponse<JsonUser>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/users/me/confirm_login`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => JsonUserFromJSON(jsonValue)), response };
    }

    /**
     * Fetch user from token
     */
    patchUser = async (): Promise<JsonUser> => {
        const { runtime } = await this.patchUserRaw();
        return await runtime.value();
    }

    /**
     * Fetch user from token
     */
    patchUserWithResponse = async (): Promise<{ value: JsonUser, response: any}> => {
        const { runtime, response } = await this.patchUserRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Reset user password
     */
    resetuserPasswordRaw = async (requestParameters: ResetuserPasswordRequest): Promise<{ runtime: runtime.ApiResponse<PasswordResetSuccessResponse>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/user/password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetUserPasswordRequestBodyToJSON(requestParameters.resetUserPasswordRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => PasswordResetSuccessResponseFromJSON(jsonValue)), response };
    }

    /**
     * Reset user password
     */
    resetuserPassword = async (requestParameters: ResetuserPasswordRequest): Promise<PasswordResetSuccessResponse> => {
        const { runtime } = await this.resetuserPasswordRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Reset user password
     */
    resetuserPasswordWithResponse = async (requestParameters: ResetuserPasswordRequest): Promise<{ value: PasswordResetSuccessResponse, response: any}> => {
        const { runtime, response } = await this.resetuserPasswordRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Send Reset password email
     */
    sendResetUserPasswordEmailRaw = async (requestParameters: SendResetUserPasswordEmailRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendResetUserPasswordEmail.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/user/{id}/send-reset-password-email`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Send Reset password email
     */
    sendResetUserPasswordEmail = async (requestParameters: SendResetUserPasswordEmailRequest): Promise<void> => {
        const { runtime } = await this.sendResetUserPasswordEmailRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Send Reset password email
     */
    sendResetUserPasswordEmailWithResponse = async (requestParameters: SendResetUserPasswordEmailRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.sendResetUserPasswordEmailRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Update user PCP, email, insurance, or clinic
     */
    updateUserRaw = async (requestParameters: UpdateUserRequest): Promise<{ runtime: runtime.ApiResponse<JsonUser>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUser.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserRequestBodyToJSON(requestParameters.updateUserRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => JsonUserFromJSON(jsonValue)), response };
    }

    /**
     * Update user PCP, email, insurance, or clinic
     */
    updateUser = async (requestParameters: UpdateUserRequest): Promise<JsonUser> => {
        const { runtime } = await this.updateUserRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update user PCP, email, insurance, or clinic
     */
    updateUserWithResponse = async (requestParameters: UpdateUserRequest): Promise<{ value: JsonUser, response: any}> => {
        const { runtime, response } = await this.updateUserRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update user password
     */
    updateUserPasswordRaw = async (requestParameters: UpdateUserPasswordRequest): Promise<{ runtime: runtime.ApiResponse<PasswordUpdateSuccessResponse>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/user/update_password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateUserPasswordRequestBodyToJSON(requestParameters.updateUserPasswordRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => PasswordUpdateSuccessResponseFromJSON(jsonValue)), response };
    }

    /**
     * Update user password
     */
    updateUserPassword = async (requestParameters: UpdateUserPasswordRequest): Promise<PasswordUpdateSuccessResponse> => {
        const { runtime } = await this.updateUserPasswordRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update user password
     */
    updateUserPasswordWithResponse = async (requestParameters: UpdateUserPasswordRequest): Promise<{ value: PasswordUpdateSuccessResponse, response: any}> => {
        const { runtime, response } = await this.updateUserPasswordRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Upload user avatar
     */
    uploadAvatarRaw = async (requestParameters: UploadAvatarRequest): Promise<{ runtime: runtime.ApiResponse<AvatarUploadSuccessResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadAvatar.');
        }

                        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadAvatar.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/users/{id}/avatar`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AvatarUploadSuccessResponseFromJSON(jsonValue)), response };
    }

    /**
     * Upload user avatar
     */
    uploadAvatar = async (requestParameters: UploadAvatarRequest): Promise<AvatarUploadSuccessResponse> => {
        const { runtime } = await this.uploadAvatarRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Upload user avatar
     */
    uploadAvatarWithResponse = async (requestParameters: UploadAvatarRequest): Promise<{ value: AvatarUploadSuccessResponse, response: any}> => {
        const { runtime, response } = await this.uploadAvatarRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Check user email availability
     */
    userLookupRaw = async (requestParameters: UserLookupRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/user/lookup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserLookupRequestBodyToJSON(requestParameters.userLookupRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Check user email availability
     */
    userLookup = async (requestParameters: UserLookupRequest): Promise<void> => {
        const { runtime } = await this.userLookupRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Check user email availability
     */
    userLookupWithResponse = async (requestParameters: UserLookupRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.userLookupRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Verify user by email
     */
    verifyUserByEmailRaw = async (requestParameters: VerifyUserByEmailRequest): Promise<{ runtime: runtime.ApiResponse<EmailVerificationSuccessResponse>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/user/email`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: VerifyUserByEmailRequestBodyToJSON(requestParameters.verifyUserByEmailRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EmailVerificationSuccessResponseFromJSON(jsonValue)), response };
    }

    /**
     * Verify user by email
     */
    verifyUserByEmail = async (requestParameters: VerifyUserByEmailRequest): Promise<EmailVerificationSuccessResponse> => {
        const { runtime } = await this.verifyUserByEmailRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Verify user by email
     */
    verifyUserByEmailWithResponse = async (requestParameters: VerifyUserByEmailRequest): Promise<{ value: EmailVerificationSuccessResponse, response: any}> => {
        const { runtime, response } = await this.verifyUserByEmailRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * XO Platform Activate a new user account
     */
    xOPlatformAccountActivationRaw = async (requestParameters: XOPlatformAccountActivationRequest): Promise<{ runtime: runtime.ApiResponse<AccountActivationResponse>, response: any}> => {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling xOPlatformAccountActivation.');
        }

                        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling xOPlatformAccountActivation.');
        }

                        if (requestParameters.firstName === null || requestParameters.firstName === undefined) {
            throw new runtime.RequiredError('firstName','Required parameter requestParameters.firstName was null or undefined when calling xOPlatformAccountActivation.');
        }

                        if (requestParameters.lastName === null || requestParameters.lastName === undefined) {
            throw new runtime.RequiredError('lastName','Required parameter requestParameters.lastName was null or undefined when calling xOPlatformAccountActivation.');
        }

                        if (requestParameters.dob === null || requestParameters.dob === undefined) {
            throw new runtime.RequiredError('dob','Required parameter requestParameters.dob was null or undefined when calling xOPlatformAccountActivation.');
        }

                        if (requestParameters.ssnLast4 === null || requestParameters.ssnLast4 === undefined) {
            throw new runtime.RequiredError('ssnLast4','Required parameter requestParameters.ssnLast4 was null or undefined when calling xOPlatformAccountActivation.');
        }

                        if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
            throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling xOPlatformAccountActivation.');
        }

                        if (requestParameters.workEmail === null || requestParameters.workEmail === undefined) {
            throw new runtime.RequiredError('workEmail','Required parameter requestParameters.workEmail was null or undefined when calling xOPlatformAccountActivation.');
        }

                        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling xOPlatformAccountActivation.');
        }

                        if (requestParameters.efId === null || requestParameters.efId === undefined) {
            throw new runtime.RequiredError('efId','Required parameter requestParameters.efId was null or undefined when calling xOPlatformAccountActivation.');
        }

                                                                                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.email !== undefined) {
            formParams.append('email', requestParameters.email as any);
        }

        if (requestParameters.password !== undefined) {
            formParams.append('password', requestParameters.password as any);
        }

        if (requestParameters.firstName !== undefined) {
            formParams.append('first_name', requestParameters.firstName as any);
        }

        if (requestParameters.lastName !== undefined) {
            formParams.append('last_name', requestParameters.lastName as any);
        }

        if (requestParameters.dob !== undefined) {
            formParams.append('dob', requestParameters.dob as any);
        }

        if (requestParameters.ssnLast4 !== undefined) {
            formParams.append('ssn_last4', requestParameters.ssnLast4 as any);
        }

        if (requestParameters.employeeId !== undefined) {
            formParams.append('employee_id', requestParameters.employeeId as any);
        }

        if (requestParameters.workEmail !== undefined) {
            formParams.append('work_email', requestParameters.workEmail as any);
        }

        if (requestParameters.cellPhone !== undefined) {
            formParams.append('cell_phone', requestParameters.cellPhone as any);
        }

        if (requestParameters.cellTexts !== undefined) {
            formParams.append('cell_texts', requestParameters.cellTexts as any);
        }

        if (requestParameters.preferredLanguage !== undefined) {
            formParams.append('preferred_language', requestParameters.preferredLanguage as any);
        }

        if (requestParameters.stateCode !== undefined) {
            formParams.append('state_code', requestParameters.stateCode as any);
        }

        if (requestParameters.subscriberId !== undefined) {
            formParams.append('subscriber_id', requestParameters.subscriberId as any);
        }

        if (requestParameters.consents) {
            formParams.append('consents', requestParameters.consents.join(runtime.COLLECTION_FORMATS["csv"]));
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.extraRegistrationId !== undefined) {
            formParams.append('extra_registration_id', requestParameters.extraRegistrationId as any);
        }

        if (requestParameters.efId !== undefined) {
            formParams.append('ef_id', requestParameters.efId as any);
        }

        if (requestParameters.consentId !== undefined) {
            formParams.append('consent_id', requestParameters.consentId as any);
        }


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/sign_up`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AccountActivationResponseFromJSON(jsonValue)), response };
    }

    /**
     * XO Platform Activate a new user account
     */
    xOPlatformAccountActivation = async (requestParameters: XOPlatformAccountActivationRequest): Promise<AccountActivationResponse> => {
        const { runtime } = await this.xOPlatformAccountActivationRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * XO Platform Activate a new user account
     */
    xOPlatformAccountActivationWithResponse = async (requestParameters: XOPlatformAccountActivationRequest): Promise<{ value: AccountActivationResponse, response: any}> => {
        const { runtime, response } = await this.xOPlatformAccountActivationRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * XO Platform Activate a new minor user account
     */
    xOPlatformAccountActivationMinorRaw = async (requestParameters: XOPlatformAccountActivationMinorRequest): Promise<{ runtime: runtime.ApiResponse<AccountActivationResponse2>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/sign_up_dependent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MinorAccountActivationRequestBodyToJSON(requestParameters.minorAccountActivationRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AccountActivationResponse2FromJSON(jsonValue)), response };
    }

    /**
     * XO Platform Activate a new minor user account
     */
    xOPlatformAccountActivationMinor = async (requestParameters: XOPlatformAccountActivationMinorRequest): Promise<AccountActivationResponse2> => {
        const { runtime } = await this.xOPlatformAccountActivationMinorRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * XO Platform Activate a new minor user account
     */
    xOPlatformAccountActivationMinorWithResponse = async (requestParameters: XOPlatformAccountActivationMinorRequest): Promise<{ value: AccountActivationResponse2, response: any}> => {
        const { runtime, response } = await this.xOPlatformAccountActivationMinorRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchAccountsApi: IAccountsApi  = new AccountsApi();
