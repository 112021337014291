/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InputWorkspaceDraftSchema,
    InputWorkspaceDraftSchemaFromJSON,
    InputWorkspaceDraftSchemaToJSON,
} from '../models/InputWorkspaceDraftSchema'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    OutputWorkspaceDraftSchema,
    OutputWorkspaceDraftSchemaFromJSON,
    OutputWorkspaceDraftSchemaToJSON,
} from '../models/OutputWorkspaceDraftSchema'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    WorkspaceDraftDataSchema,
    WorkspaceDraftDataSchemaFromJSON,
    WorkspaceDraftDataSchemaToJSON,
} from '../models/WorkspaceDraftDataSchema'


export interface CreateWorkspaceDraftRequest {
    id: string;
    inputWorkspaceDraftSchema?: InputWorkspaceDraftSchema;
}
export interface DeleteWorkspaceDraftRequest {
    id: string;
}
export interface FetchEpisodeWorkspaceDraftRequest {
    id: string;
}
export interface FetchWorkspaceDraftRequest {
    id: string;
}
export interface SubmitWorkspaceDraftRequest {
    id: string;
}
export interface UpdateWorkspaceDraftRequest {
    id: string;
    inputWorkspaceDraftSchema?: InputWorkspaceDraftSchema;
}


export interface IWorkspaceDraftsApi {
      createWorkspaceDraft: (requestParameters: CreateWorkspaceDraftRequest) => Promise<OutputWorkspaceDraftSchema>
      deleteWorkspaceDraft: (requestParameters: DeleteWorkspaceDraftRequest) => Promise<void>
      fetchEpisodeWorkspaceDraft: (requestParameters: FetchEpisodeWorkspaceDraftRequest) => Promise<OutputWorkspaceDraftSchema>
      fetchWorkspaceDraft: (requestParameters: FetchWorkspaceDraftRequest) => Promise<OutputWorkspaceDraftSchema>
      submitWorkspaceDraft: (requestParameters: SubmitWorkspaceDraftRequest) => Promise<OutputWorkspaceDraftSchema>
      updateWorkspaceDraft: (requestParameters: UpdateWorkspaceDraftRequest) => Promise<OutputWorkspaceDraftSchema>
      createWorkspaceDraftWithResponse: (requestParameters: CreateWorkspaceDraftRequest) => Promise<{ value: OutputWorkspaceDraftSchema, response: any}>
      deleteWorkspaceDraftWithResponse: (requestParameters: DeleteWorkspaceDraftRequest) => Promise<{ value: void, response: any}>
      fetchEpisodeWorkspaceDraftWithResponse: (requestParameters: FetchEpisodeWorkspaceDraftRequest) => Promise<{ value: OutputWorkspaceDraftSchema, response: any}>
      fetchWorkspaceDraftWithResponse: (requestParameters: FetchWorkspaceDraftRequest) => Promise<{ value: OutputWorkspaceDraftSchema, response: any}>
      submitWorkspaceDraftWithResponse: (requestParameters: SubmitWorkspaceDraftRequest) => Promise<{ value: OutputWorkspaceDraftSchema, response: any}>
      updateWorkspaceDraftWithResponse: (requestParameters: UpdateWorkspaceDraftRequest) => Promise<{ value: OutputWorkspaceDraftSchema, response: any}>
}

/**
 * no description
 */
export class WorkspaceDraftsApi extends runtime.BaseAPI {

    /**
     * Create a workspace draft
     * Create a workspace draft
     */
    createWorkspaceDraftRaw = async (requestParameters: CreateWorkspaceDraftRequest): Promise<{ runtime: runtime.ApiResponse<OutputWorkspaceDraftSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createWorkspaceDraft.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{id}/workspace_drafts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InputWorkspaceDraftSchemaToJSON(requestParameters.inputWorkspaceDraftSchema),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OutputWorkspaceDraftSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Create a workspace draft
     * Create a workspace draft
     */
    createWorkspaceDraft = async (requestParameters: CreateWorkspaceDraftRequest): Promise<OutputWorkspaceDraftSchema> => {
        const { runtime } = await this.createWorkspaceDraftRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Create a workspace draft
     * Create a workspace draft
     */
    createWorkspaceDraftWithResponse = async (requestParameters: CreateWorkspaceDraftRequest): Promise<{ value: OutputWorkspaceDraftSchema, response: any}> => {
        const { runtime, response } = await this.createWorkspaceDraftRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Discard workspace draft
     * Discard workspace draft
     */
    deleteWorkspaceDraftRaw = async (requestParameters: DeleteWorkspaceDraftRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteWorkspaceDraft.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/workspace_drafts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Discard workspace draft
     * Discard workspace draft
     */
    deleteWorkspaceDraft = async (requestParameters: DeleteWorkspaceDraftRequest): Promise<void> => {
        const { runtime } = await this.deleteWorkspaceDraftRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Discard workspace draft
     * Discard workspace draft
     */
    deleteWorkspaceDraftWithResponse = async (requestParameters: DeleteWorkspaceDraftRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.deleteWorkspaceDraftRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Fetch active Episode workspace draft
     * Fetch active workspace draft
     */
    fetchEpisodeWorkspaceDraftRaw = async (requestParameters: FetchEpisodeWorkspaceDraftRequest): Promise<{ runtime: runtime.ApiResponse<OutputWorkspaceDraftSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchEpisodeWorkspaceDraft.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{id}/workspace_draft`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OutputWorkspaceDraftSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Fetch active Episode workspace draft
     * Fetch active workspace draft
     */
    fetchEpisodeWorkspaceDraft = async (requestParameters: FetchEpisodeWorkspaceDraftRequest): Promise<OutputWorkspaceDraftSchema> => {
        const { runtime } = await this.fetchEpisodeWorkspaceDraftRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch active Episode workspace draft
     * Fetch active workspace draft
     */
    fetchEpisodeWorkspaceDraftWithResponse = async (requestParameters: FetchEpisodeWorkspaceDraftRequest): Promise<{ value: OutputWorkspaceDraftSchema, response: any}> => {
        const { runtime, response } = await this.fetchEpisodeWorkspaceDraftRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch workspace draft
     * Fetch WorkSpace Draft
     */
    fetchWorkspaceDraftRaw = async (requestParameters: FetchWorkspaceDraftRequest): Promise<{ runtime: runtime.ApiResponse<OutputWorkspaceDraftSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchWorkspaceDraft.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/workspace_drafts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OutputWorkspaceDraftSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Fetch workspace draft
     * Fetch WorkSpace Draft
     */
    fetchWorkspaceDraft = async (requestParameters: FetchWorkspaceDraftRequest): Promise<OutputWorkspaceDraftSchema> => {
        const { runtime } = await this.fetchWorkspaceDraftRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch workspace draft
     * Fetch WorkSpace Draft
     */
    fetchWorkspaceDraftWithResponse = async (requestParameters: FetchWorkspaceDraftRequest): Promise<{ value: OutputWorkspaceDraftSchema, response: any}> => {
        const { runtime, response } = await this.fetchWorkspaceDraftRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Submit workspace draft
     * Submit workspace draft
     */
    submitWorkspaceDraftRaw = async (requestParameters: SubmitWorkspaceDraftRequest): Promise<{ runtime: runtime.ApiResponse<OutputWorkspaceDraftSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling submitWorkspaceDraft.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/workspace_drafts/{id}/submit`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OutputWorkspaceDraftSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Submit workspace draft
     * Submit workspace draft
     */
    submitWorkspaceDraft = async (requestParameters: SubmitWorkspaceDraftRequest): Promise<OutputWorkspaceDraftSchema> => {
        const { runtime } = await this.submitWorkspaceDraftRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Submit workspace draft
     * Submit workspace draft
     */
    submitWorkspaceDraftWithResponse = async (requestParameters: SubmitWorkspaceDraftRequest): Promise<{ value: OutputWorkspaceDraftSchema, response: any}> => {
        const { runtime, response } = await this.submitWorkspaceDraftRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update workspace draft
     * Update workspace draft
     */
    updateWorkspaceDraftRaw = async (requestParameters: UpdateWorkspaceDraftRequest): Promise<{ runtime: runtime.ApiResponse<OutputWorkspaceDraftSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateWorkspaceDraft.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/workspace_drafts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: InputWorkspaceDraftSchemaToJSON(requestParameters.inputWorkspaceDraftSchema),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OutputWorkspaceDraftSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Update workspace draft
     * Update workspace draft
     */
    updateWorkspaceDraft = async (requestParameters: UpdateWorkspaceDraftRequest): Promise<OutputWorkspaceDraftSchema> => {
        const { runtime } = await this.updateWorkspaceDraftRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update workspace draft
     * Update workspace draft
     */
    updateWorkspaceDraftWithResponse = async (requestParameters: UpdateWorkspaceDraftRequest): Promise<{ value: OutputWorkspaceDraftSchema, response: any}> => {
        const { runtime, response } = await this.updateWorkspaceDraftRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchWorkspaceDraftsApi: IWorkspaceDraftsApi  = new WorkspaceDraftsApi();
