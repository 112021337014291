// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConfigResponseApp,
    ConfigResponseAppFromJSON,
    ConfigResponseAppToJSON,
} from './ConfigResponseApp';
import {
    ConfigResponseAppointments,
    ConfigResponseAppointmentsFromJSON,
    ConfigResponseAppointmentsToJSON,
} from './ConfigResponseAppointments';
import {
    ConfigResponseAssets,
    ConfigResponseAssetsFromJSON,
    ConfigResponseAssetsToJSON,
} from './ConfigResponseAssets';
import {
    ConfigResponseLinks,
    ConfigResponseLinksFromJSON,
    ConfigResponseLinksToJSON,
} from './ConfigResponseLinks';
import {
    ConfigResponseSignin,
    ConfigResponseSigninFromJSON,
    ConfigResponseSigninToJSON,
} from './ConfigResponseSignin';
import {
    ConfigResponseSignup,
    ConfigResponseSignupFromJSON,
    ConfigResponseSignupToJSON,
} from './ConfigResponseSignup';


/**
 * 
 * @export
 * @interface ConfigResponse
 */
export interface ConfigResponse  {
    /**
     * 
     * @type {ConfigResponseApp}
     * @memberof ConfigResponse
     */
    app?: ConfigResponseApp;
    /**
     * 
     * @type {ConfigResponseLinks}
     * @memberof ConfigResponse
     */
    links?: ConfigResponseLinks;
    /**
     * 
     * @type {ConfigResponseSignin}
     * @memberof ConfigResponse
     */
    signin?: ConfigResponseSignin;
    /**
     * 
     * @type {ConfigResponseSignup}
     * @memberof ConfigResponse
     */
    signup?: ConfigResponseSignup;
    /**
     * 
     * @type {ConfigResponseAssets}
     * @memberof ConfigResponse
     */
    assets?: ConfigResponseAssets;
    /**
     * 
     * @type {ConfigResponseAppointments}
     * @memberof ConfigResponse
     */
    appointments?: ConfigResponseAppointments;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ConfigResponse
     */
    replaceableVisits?: { [key: string]: string; };
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ConfigResponse
     */
    providerTypeVisitReplacementCode?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof ConfigResponse
     */
    token?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ConfigResponse
     */
    clients?: { [key: string]: string; };
}

export function ConfigResponseFromJSON(json: any): ConfigResponse {
    return {
        'app': !exists(json, 'app') ? undefined : ConfigResponseAppFromJSON(json['app']),
        'links': !exists(json, 'links') ? undefined : ConfigResponseLinksFromJSON(json['links']),
        'signin': !exists(json, 'signin') ? undefined : ConfigResponseSigninFromJSON(json['signin']),
        'signup': !exists(json, 'signup') ? undefined : ConfigResponseSignupFromJSON(json['signup']),
        'assets': !exists(json, 'assets') ? undefined : ConfigResponseAssetsFromJSON(json['assets']),
        'appointments': !exists(json, 'appointments') ? undefined : ConfigResponseAppointmentsFromJSON(json['appointments']),
        'replaceableVisits': !exists(json, 'replaceable_visits') ? undefined : json['replaceable_visits'],
        'providerTypeVisitReplacementCode': !exists(json, 'provider_type_visit_replacement_code') ? undefined : json['provider_type_visit_replacement_code'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'clients': !exists(json, 'clients') ? undefined : json['clients'],
    };
}

export function ConfigResponseToJSON(value?: ConfigResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'app': ConfigResponseAppToJSON(value.app),
        'links': ConfigResponseLinksToJSON(value.links),
        'signin': ConfigResponseSigninToJSON(value.signin),
        'signup': ConfigResponseSignupToJSON(value.signup),
        'assets': ConfigResponseAssetsToJSON(value.assets),
        'appointments': ConfigResponseAppointmentsToJSON(value.appointments),
        'replaceable_visits': value.replaceableVisits,
        'provider_type_visit_replacement_code': value.providerTypeVisitReplacementCode,
        'token': value.token,
        'clients': value.clients,
    };
}


