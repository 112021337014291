// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
    CompactComponentReference,
    CompactComponentReferenceFromJSON,
    CompactComponentReferenceToJSON,
} from './CompactComponentReference';
import {
    DocumentOutputSchema,
    DocumentOutputSchemaFromJSON,
    DocumentOutputSchemaToJSON,
} from './DocumentOutputSchema';


/**
 * 
 * @export
 * @interface CtmMessageComponentOutputProps
 */
export interface CtmMessageComponentOutputProps  {
    /**
     * 
     * @type {string}
     * @memberof CtmMessageComponentOutputProps
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CtmMessageComponentOutputProps
     */
    state: CtmMessageComponentOutputPropsStateEnum;
    /**
     * 
     * @type {string}
     * @memberof CtmMessageComponentOutputProps
     */
    body?: string;
    /**
     * 
     * @type {Date}
     * @memberof CtmMessageComponentOutputProps
     */
    addedAt?: Date;
    /**
     * 
     * @type {Array<DocumentOutputSchema>}
     * @memberof CtmMessageComponentOutputProps
     */
    documentInfo?: Array<DocumentOutputSchema>;
    /**
     * 
     * @type {Array<CompactComponentReference>}
     * @memberof CtmMessageComponentOutputProps
     */
    parentComponents?: Array<CompactComponentReference>;
    /**
     * 
     * @type {boolean}
     * @memberof CtmMessageComponentOutputProps
     */
    responseRequired: boolean;
    /**
     * 
     * @type {Array<CTMInfo>}
     * @memberof CtmMessageComponentOutputProps
     */
    ccCtms?: Array<CTMInfo>;
    /**
     * 
     * @type {Array<CompactComponentReference>}
     * @memberof CtmMessageComponentOutputProps
     */
    childComponents?: Array<CompactComponentReference>;
}

export function CtmMessageComponentOutputPropsFromJSON(json: any): CtmMessageComponentOutputProps {
    return {
        'id': json['id'],
        'state': json['state'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'addedAt': !exists(json, 'added_at') ? undefined : new Date(json['added_at']),
        'documentInfo': !exists(json, 'document_info') ? undefined : (json['document_info'] as Array<any>).map(DocumentOutputSchemaFromJSON),
        'parentComponents': !exists(json, 'parent_components') ? undefined : (json['parent_components'] as Array<any>).map(CompactComponentReferenceFromJSON),
        'responseRequired': json['response_required'],
        'ccCtms': !exists(json, 'cc_ctms') ? undefined : (json['cc_ctms'] as Array<any>).map(CTMInfoFromJSON),
        'childComponents': !exists(json, 'child_components') ? undefined : (json['child_components'] as Array<any>).map(CompactComponentReferenceFromJSON),
    };
}

export function CtmMessageComponentOutputPropsToJSON(value?: CtmMessageComponentOutputProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'state': value.state,
        'body': value.body,
        'added_at': value.addedAt === undefined ? undefined : value.addedAt.toISOString(),
        'document_info': value.documentInfo === undefined ? undefined : (value.documentInfo as Array<any>).map(DocumentOutputSchemaToJSON),
        'parent_components': value.parentComponents === undefined ? undefined : (value.parentComponents as Array<any>).map(CompactComponentReferenceToJSON),
        'response_required': value.responseRequired,
        'cc_ctms': value.ccCtms === undefined ? undefined : (value.ccCtms as Array<any>).map(CTMInfoToJSON),
        'child_components': value.childComponents === undefined ? undefined : (value.childComponents as Array<any>).map(CompactComponentReferenceToJSON),
    };
}

/**
* @export
* @enum {string}
*/
export enum CtmMessageComponentOutputPropsStateEnum {
    Unread = 'unread',
    UnreadPendingResponse = 'unread_pending_response',
    ReadPendingResponse = 'read_pending_response',
    ReadResponseStarted = 'read_response_started',
    Resolved = 'resolved',
    ResolvedWithReply = 'resolved_with_reply'
}


