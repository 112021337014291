import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";
import { useDispatch } from "react-redux";
import {
  CreateNotesOnAppointmentRequest,
  fetchAppointmentNotesApi,
} from "shared/fetch/src/apis/AppointmentNotesApi";
import { AppointmentOutput } from "shared/fetch/src/models/AppointmentOutput";
import { showSnackbar } from "shared/state/ui/snackbar";
import { getFetchSlotQueryKey } from "../xocal/useGetSlot";

const useCreateVisitNotes = (
  isXOCal?: boolean,
  config?: UseMutationOptions<
    AppointmentOutput,
    Error,
    CreateNotesOnAppointmentRequest
  >
): UseMutationResult<
  AppointmentOutput,
  Error,
  CreateNotesOnAppointmentRequest
> => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const mutationFn = (requestParameters: CreateNotesOnAppointmentRequest) => {
    return fetchAppointmentNotesApi.createNotesOnAppointment(
      requestParameters
    ) as Promise<AppointmentOutput>;
  };

  const defaultConfig: UseMutationOptions<
    AppointmentOutput,
    Error,
    CreateNotesOnAppointmentRequest
  > = {
    onSuccess: (data, variables, context) => {
      dispatch(showSnackbar("Success! You added a visit note."));

      if (isXOCal) {
        queryClient.invalidateQueries(
          getFetchSlotQueryKey({ id: data.slotId?.toString() })
        );
      } else {
        queryClient.invalidateQueries(["visits", data.patientId.toString()]);
      }

      if (config?.onSuccess) {
        config.onSuccess(data, variables, context);
      }
    },
    onError: () => {
      dispatch(showSnackbar("Error! Failed to add a visit note."));
    },
    ...config,
  };

  return useMutation<AppointmentOutput, Error, CreateNotesOnAppointmentRequest>(
    mutationFn,
    defaultConfig
  );
};

export default useCreateVisitNotes;
