import React from "react";
import clsx from "clsx";
import SnackbarContentComponent, {
  SnackbarContentProps,
} from "@mui/material/SnackbarContent";
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

interface IProps extends SnackbarContentProps {
  /** The appearance to display. */
  appearance?: "success" | "danger" | "warning";
  /** Determines if a close button should be automatically rendered. */
  hasCloseButton?: boolean;
  /** Handler for closing the snackbar. */
  onClose?: (event: any) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    width: "364px",
    minHeight: "44px",
    border: "1px solid",
    borderRadius: "4px",
    boxShadow: "2px 4px 10px rgba(135, 160, 182, 0.3)",
    zIndex: 100,
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  success: {
    color: "#0B665C",
    backgroundColor: "#E3F4DD",
  },
  danger: {
    color: "#CA4004",
    backgroundColor: "#F8ECEF",
  },
  warning: {
    color: "#FFE9C9",
    backgroundColor: "#FFE9C9",
  },
  icon: {
    width: "20px",
    height: "20px",
    fill: theme.palette.text.secondary,
    opacity: "0.7",
    position: "absolute",
    marginTop: theme.spacing(1),
  },
}));

const SnackbarContent = React.forwardRef<HTMLDivElement, IProps>(
  (props, ref) => {
    const classes = useStyles(props);
    const {
      appearance,
      className,
      hasCloseButton,
      onClose,
      action,
      style = {},
      ...otherProps
    } = props;

    let updatedAction = action;
    if (hasCloseButton) {
      updatedAction = [
        ...(Array.isArray(action) ? action : [action]),
        <IconButton
          key="close"
          aria-label="close"
          color="inherit"
          onClick={(e) => {
            if (onClose) {
              onClose(e);
            }
          }}
          size="large"
        >
          <CancelIcon className={classes.icon} />
        </IconButton>,
      ];
    }

    return (
      <SnackbarContentComponent
        ref={ref}
        className={clsx(classes.content, classes[appearance!], className)}
        style={style}
        action={updatedAction}
        {...otherProps}
      />
    );
  }
);

SnackbarContent.displayName = "SnackbarContent";

export default SnackbarContent;
