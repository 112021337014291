import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import AvatarComponent, { AvatarProps } from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useBreakpointMatch } from "utils/useBreakpointMatch";
import { useAuthorizedUrl } from "shared/features/auth/useAuthorizedUrl";
import { Link } from "react-router-dom";
import { JsonUser } from "shared/api/src/models/JsonUser";
import { getUser } from "shared/features/user";
import ButtonBase from "components/Button/ButtonBase";
import { TypographyAppearance } from "components/Typography";
import { a11yClick } from "utils/a11yClick";
import IneligibleMemberBadgeIcon from "./IneligibleMemberBadgeIcon";
import AvatarLabelAndDescriptions from "./AvatarLabelAndDescriptions";

interface IProps extends AvatarProps {
  containerStyle?: React.CSSProperties;
  size?: "small" | "medium" | "large" | "xl" | "forty" | {};
  label?: string | JSX.Element;
  descriptions?: string[] | JSX.Element;
  linkTo?: string;
  labelAppearance?: TypographyAppearance;
  descriptionsAppearance?: TypographyAppearance;
  showBadge?: boolean;
  badgeContent?: React.ReactNode;
  isBold?: boolean;
  isTimeline?: boolean;
  isQueue?: boolean;
  displayText?: string;
  returnTo?: string;
  isPresentational?: boolean; // pass to avatars that are direct children of elements with their own semantics (links, buttons, etc.)
  isBottomDisplay?: boolean;
}
export interface BadgeIconProps {
  size: string | number;
}

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    avatar: {
      backgroundColor: palette?.appBackground?.darkGrey,
    },
    small: {
      width: 32,
      height: 32,
      fontSize: "0.8rem",
      color: "white",
    },
    forty: {
      width: 40,
      height: 40,
      fontSize: "0.8rem",
      color: "white",
    },
    medium: {
      width: 45,
      height: 45,
      fontSize: "1.25rem",
      color: "white",
    },
    large: {
      width: 75,
      height: 75,
      fontSize: "1.8rem",
      color: "white",
    },
    xl: {
      width: 100,
      height: 100,
      fontSize: "2.4rem",
      color: "white",
    },
    xxl: {
      width: 125,
      height: 125,
      fontSize: "2.4rem",
      color: "white",
    },
    xxxl: {
      width: 150,
      height: 150,
      fontSize: "3rem",
      color: "white",
    },
    container: {
      display: "flex",
      justifyContent: "flex-start",
      marginLeft: ({ isQueue }: IProps) => (isQueue ? 13 : 10),
    },
    text: {
      display: "flex",
      flexDirection: "column",
      marginLeft: ({ isQueue }: IProps) => (isQueue ? 4 : 8),
      textAlign: "left",
      alignSelf: "center",
      width: "100%",
      marginRight: 13,
    },
  })
);

const getInitialsFromText = (text?: string) => {
  const parts = text?.split(" ") ?? [];
  const initials = [];
  if (parts.length > 0) {
    initials.push(parts[0][0]);
  }
  if (parts.length > 1) {
    initials.push(parts[1][0]);
  }
  return initials.join("");
};

const Avatar: FunctionComponent<IProps> = (props) => {
  const { onBreakpointMatch } = useBreakpointMatch();
  const {
    containerStyle,
    size = "medium",
    alt,
    children,
    className,
    descriptions,
    descriptionsAppearance = "body",
    src,
    showBadge,
    label,
    labelAppearance = "body",
    linkTo,
    onClick,
    badgeContent = <IneligibleMemberBadgeIcon size={36} />,
    isBold,
    isTimeline,
    isQueue,
    displayText,
    returnTo,
    isPresentational,
    isBottomDisplay,
    ...rest
  } = props;

  const classes = useStyles({ isQueue });
  // @ts-ignore: Object is possibly 'null'.
  const currentUser: JsonUser = useSelector(getUser);
  let sizeClassName = typeof size === "string" ? classes[size] : "medium";

  if (typeof size === "object") {
    onBreakpointMatch(size, (x: any) => (sizeClassName = classes[x]));
  }

  // if the avatar has a child element (usually an image) keep the child element
  // if the avatar lacks a child element, show initials as the avatar display
  let updatedChildren = children;
  if (!updatedChildren) {
    updatedChildren = (
      // however, initials displayed in the avatar are redundant--the full name is display to the
      // a11y tree, so we put aria-hidden on this text to remove the initials from the a11y tree
      <span aria-hidden>{getInitialsFromText(displayText || alt)}</span>
    );
  }
  const to = returnTo ? `${linkTo}?b=${returnTo}` : linkTo;
  const isWrappedInLinkForCtms = linkTo && !!currentUser?.ctm;

  const avatarUrl = useAuthorizedUrl(src);
  const authorisedURL = src?.includes("?access_token") ? src : avatarUrl;

  // wrap a <Link> around the element if the currentUser is a CTM and there is a `linkTo` prop
  // if the user is not a ctm the content is unchanged
  // why would we only do this when the user is a ctm? not sure
  const conditionallyWrapInLink = (content: JSX.Element) =>
    isWrappedInLinkForCtms ? (
      // @ts-ignore: Object is possibly 'null'.
      <ButtonBase // name is misleading, we are rendering an anchor element to the dom
        component={Link}
        to={to}
        role={isPresentational || !Boolean(to) ? "presentation" : "link"}
        data-e2e="avatar-link"
        onClick={(e: any) => e.stopPropagation()}
      >
        {content}
      </ButtonBase>
    ) : (
      content
    );

  return conditionallyWrapInLink(
    <div
      className={classes.container}
      style={containerStyle}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (onClick) {
          e.stopPropagation();
          onClick(e);
        }
      }}
      onKeyPress={(evt) => {
        if (a11yClick(evt)) {
          if (onClick) {
            evt.stopPropagation();
            onClick(evt as any);
          }
        }
      }}
      role={isPresentational || !Boolean(onClick) ? "presentation" : "link"}
    >
      <Badge
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        invisible={!showBadge}
        badgeContent={badgeContent}
        data-testid="avatar-badge"
        component="div"
      >
        <AvatarComponent
          aria-hidden={isPresentational}
          alt={isPresentational ? "" : alt}
          className={clsx(classes.avatar, sizeClassName, className)}
          src={authorisedURL ?? ""}
          {...rest}
        >
          {updatedChildren}
        </AvatarComponent>
      </Badge>

      {(label || descriptions) && (
        <AvatarLabelAndDescriptions
          label={label}
          descriptions={descriptions}
          labelAppearance={labelAppearance}
          isTimeline={isTimeline}
          isBold={isBold}
          descriptionsAppearance={descriptionsAppearance}
          isQueue={isQueue}
          isBottomDisplay={isBottomDisplay}
        />
      )}
    </div>
  );
};

export default Avatar;
