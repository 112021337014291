import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State as ApplicationState } from "shared/types/state";
import { getUser } from "shared/features/user";

export const SET_COMPONENT_IDENTIFIER = "SET_COMPONENT_IDENTIFIER";
export const SET_IS_TO_SCROLL = "SET_IS_TO_SCROLL";
export const CLEAR_SCROLL_RESTORATION = "CLEAR_SCROLL_RESTORATION";

export interface State {
  componentIdentifier?: string;
  isToScroll?: boolean;
}

export const getInitialScrollRestorationState = (): State => ({
  componentIdentifier: undefined,
  isToScroll: false,
});

export const scrollRestoration = (
  state: State = getInitialScrollRestorationState(),
  action: any
): State => {
  if (action && action.type) {
    const { type } = action;
    switch (type) {
      case SET_COMPONENT_IDENTIFIER:
        return { ...state, ...action.payload };
      case SET_IS_TO_SCROLL:
        return { ...state, ...action.payload };
      case CLEAR_SCROLL_RESTORATION:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  }
  return state;
};

// actions

export const clearScrollRestoration = () => ({
  type: CLEAR_SCROLL_RESTORATION,
  payload: getInitialScrollRestorationState(),
});

export const setIsToScroll = () => ({
  type: SET_IS_TO_SCROLL,
  payload: {
    isToScroll: true,
  },
});

export const setComponentIdentifier = (id: string) => ({
  type: SET_COMPONENT_IDENTIFIER,
  payload: {
    componentIdentifier: id,
  },
});

// selector
export const selectScrollRestoration = () =>
  useSelector((state: ApplicationState) => state.ui.scrollRestoration);
// custom hook

export const useScrollRestoration = (id?: string, isToWait?: boolean) => {
  const scrollState = selectScrollRestoration();
  const [component, setComponent] = useState();
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  useEffect(() => {
    if (
      user?.ctm &&
      id &&
      scrollState.componentIdentifier &&
      scrollState.componentIdentifier === id &&
      scrollState.isToScroll &&
      !isToWait &&
      component
    ) {
      //@ts-ignore : Object is possibly null
      component.scrollIntoView();
      dispatch(clearScrollRestoration());
    }
  }, [component, isToWait]);

  return {
    setComponent,
  };
};
