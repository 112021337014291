/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ConfigResponse,
    ConfigResponseFromJSON,
    ConfigResponseToJSON,
} from '../models/ConfigResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    Synopsis,
    SynopsisFromJSON,
    SynopsisToJSON,
} from '../models/Synopsis'




export interface IConfigApi {
      getConfig: () => Promise<ConfigResponse>
      getSynopsis: () => Promise<Synopsis>
      getConfigWithResponse: () => Promise<{ value: ConfigResponse, response: any}>
      getSynopsisWithResponse: () => Promise<{ value: Synopsis, response: any}>
}

/**
 * no description
 */
export class ConfigApi extends runtime.BaseAPI {

    /**
     * Returns the config for the app
     * Returns the config for the app
     */
    getConfigRaw = async (): Promise<{ runtime: runtime.ApiResponse<ConfigResponse>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ConfigResponseFromJSON(jsonValue)), response };
    }

    /**
     * Returns the config for the app
     * Returns the config for the app
     */
    getConfig = async (): Promise<ConfigResponse> => {
        const { runtime } = await this.getConfigRaw();
        return await runtime.value();
    }

    /**
     * Returns the config for the app
     * Returns the config for the app
     */
    getConfigWithResponse = async (): Promise<{ value: ConfigResponse, response: any}> => {
        const { runtime, response } = await this.getConfigRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get Synopsis
     */
    getSynopsisRaw = async (): Promise<{ runtime: runtime.ApiResponse<Synopsis>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/synopsis`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => SynopsisFromJSON(jsonValue)), response };
    }

    /**
     * Get Synopsis
     */
    getSynopsis = async (): Promise<Synopsis> => {
        const { runtime } = await this.getSynopsisRaw();
        return await runtime.value();
    }

    /**
     * Get Synopsis
     */
    getSynopsisWithResponse = async (): Promise<{ value: Synopsis, response: any}> => {
        const { runtime, response } = await this.getSynopsisRaw();
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchConfigApi: IConfigApi  = new ConfigApi();
