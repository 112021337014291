// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActivityLog
 */
export interface ActivityLog  {
    /**
     * 
     * @type {string}
     * @memberof ActivityLog
     */
    action?: string;
    /**
     * 
     * @type {Date}
     * @memberof ActivityLog
     */
    loggedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ActivityLog
     */
    actor?: string;
}

export function ActivityLogFromJSON(json: any): ActivityLog {
    return {
        'action': !exists(json, 'action') ? undefined : json['action'],
        'loggedAt': !exists(json, 'logged_at') ? undefined : new Date(json['logged_at']),
        'actor': !exists(json, 'actor') ? undefined : json['actor'],
    };
}

export function ActivityLogToJSON(value?: ActivityLog): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'action': value.action,
        'logged_at': value.loggedAt === undefined ? undefined : value.loggedAt.toISOString(),
        'actor': value.actor,
    };
}


