// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DailyScheduleResponseCovidRiskScore
 */
export interface DailyScheduleResponseCovidRiskScore  {
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseCovidRiskScore
     */
    range?: string;
}

export function DailyScheduleResponseCovidRiskScoreFromJSON(json: any): DailyScheduleResponseCovidRiskScore {
    return {
        'range': !exists(json, 'range') ? undefined : json['range'],
    };
}

export function DailyScheduleResponseCovidRiskScoreToJSON(value?: DailyScheduleResponseCovidRiskScore): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'range': value.range,
    };
}


