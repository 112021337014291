import React, { FunctionComponent } from "react";
import { ActionableItemSummary } from "shared/fetch/src/models/ActionableItemSummary";
import UnclaimedIcon from "@mui/icons-material/RadioButtonUnchecked";
import OwnedByYouIcon from "@mui/icons-material/CheckCircle";
import OwnedByCtmIcon from "@mui/icons-material/RadioButtonChecked";
import OwnedByMultipleIcon from "@mui/icons-material/Group";
import Tooltip from "components/Tooltip";
import { ButtonBase, Theme, useTheme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import clsx from "clsx";
import { Link } from "components/Button";

interface IProps {
  actionableItems: ActionableItemSummary[];
  userId: any;
  toggleModal?: () => void;
  isDetailPage?: boolean;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      marginRight: theme.spacing(1),
      padding: "0",
      minWidth: "auto",
    },
  })
);

const ActionableItemOwnershipIcon: FunctionComponent<IProps> = ({
  actionableItems,
  userId,
  toggleModal,
  isDetailPage = false,
  className,
}) => {
  const toggleAiOwnerModal = (e: any) => {
    e.stopPropagation();
    // @ts-ignore: Object is possibly 'null'.
    toggleModal();
  };

  const classes = useStyles();
  const { palette } = useTheme();
  const noOwnerMessage = "Select to Claim AI";
  const multipleOwnerMessage =
    "Multiple AI owners and/or AI statuses. Go to detail page to change owner.";
  const activeItems = actionableItems?.filter(
    (item) => item.state === "active" || item.state === "acknowledged_pending"
  );

  if (!actionableItems || activeItems?.length === 0) {
    return <></>;
  }

  let aIOwnershipIcon;
  let aIOwnershipStatus = "";

  if (activeItems?.every((item) => !item.owner?.id)) {
    //All actionable items are unclaimed
    aIOwnershipIcon = (
      <Tooltip
        placement="bottom-start"
        title={noOwnerMessage}
        aria-label={noOwnerMessage}
      >
        <ButtonBase
          onClick={toggleModal && toggleAiOwnerModal}
          className={clsx(classes.iconContainer)}
        >
          <UnclaimedIcon
            data-e2e={`select-ai-owner-button`}
            htmlColor={palette.text.secondary}
            role="button"
          />
        </ButtonBase>
      </Tooltip>
    );
  } else if (
    activeItems?.every((item) => item.owner?.id === userId.toString())
  ) {
    //All actionable items are owned by the user
    aIOwnershipIcon = (
      <ButtonBase
        onClick={toggleModal && toggleAiOwnerModal}
        component={Link}
        className={clsx(classes.iconContainer)}
        aria-label="All actionable items in this conversation are owned by you. Click to change owners."
      >
        <OwnedByYouIcon
          data-e2e={`select-ai-owner-button`}
          htmlColor={palette.primary.main}
          role="button"
        />
      </ButtonBase>
    );
  } else {
    const owners = activeItems?.map((a) => a.owner?.id);
    if (owners?.every((owner) => owner === owners[0])) {
      //All actionable items are owned by another CTM
      aIOwnershipIcon = (
        <ButtonBase
          onClick={toggleModal && toggleAiOwnerModal}
          component={Link}
          className={clsx(classes.iconContainer)}
          aria-label="Click to change actionable item owners."
        >
          <OwnedByCtmIcon
            data-e2e={`select-ai-owner-button`}
            htmlColor={palette.primary.main}
            role="button"
          />
        </ButtonBase>
      );
    } else {
      //Actionable items have multiple owners and/or statuses
      aIOwnershipIcon = (
        <Tooltip
          placement="right-end"
          title={multipleOwnerMessage}
          aria-label={multipleOwnerMessage}
        >
          <OwnedByMultipleIcon
            data-e2e={`select-ai-owner-button`}
            role="button"
            htmlColor={palette.text.secondary}
            className={clsx(className, classes.iconContainer)}
          />
        </Tooltip>
      );
    }
  }

  if (isDetailPage) {
    if (activeItems?.length > 0) {
      if (
        activeItems.every(
          (item: ActionableItemSummary) =>
            item?.owner?.id === actionableItems[0]?.owner?.id
        )
      ) {
        aIOwnershipStatus = `${actionableItems[0].ownershipStatus} | `;
      } else {
        aIOwnershipStatus = "Multiple AI owners | ";
      }
    }
  }
  return (
    <>
      {aIOwnershipIcon}
      {aIOwnershipStatus}
    </>
  );
};

export default ActionableItemOwnershipIcon;
