import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import HeroOrange from "../../styles/images/hero/bg-orange.jpg";
import HeroGray from "../../styles/images/hero/bg-gray.jpg";
import HeroGreen from "../../styles/images/hero/bg-green-2.jpg";
import Box from "components/Box";
import { Palette } from '@mui/material/styles';

export type HeaderColor =
  | "orange"
  | "gray"
  | "green"
  | "navy"
  | "dark-blue"
  | "dark"
  | "darkestGray"
  | "darkGray"
  | "background"
  | "light-blue";

export type HeaderSize = "" | "small" | "medium" | "large";

const DEFAULT_HEADER_HEIGHT = "200px";

interface IHeaderHeights {
  smallScreen: string;
  largeScreen: string;
}

interface IHeaderProps {
  color: HeaderColor;
  size?: HeaderSize;
  className?: string;
  style?: any;
  disableBackgroundImage?: boolean;
  height?: string;
  backgroundColor?: string;
  backgroundClassName?: any;
  headerHeights?: IHeaderHeights;
  isEmptyEOC?: boolean;
}

function getBackgroundImage(color: HeaderColor): string {
  switch (color) {
    case "orange":
      return HeroOrange;
    case "green":
      return HeroGreen;
    case "navy":
    case "dark":
    case "darkestGray":
    case "darkGray":
    case "dark-blue":
    case "background":
    case "light-blue":
      // @ts-ignore: Object is possibly 'null'.
      return null;
    default:
      return HeroGray;
  }
}

function getBackgroundColor(color: HeaderColor, palette: Palette): string {
  switch (color) {
    case "navy":
      // @ts-ignore: Object is possibly 'null'.
      return palette?.appBackground?.navy;
    case "darkestGray":
      // @ts-ignore: Object is possibly 'null'.
      return palette?.appBackground?.darkestGray;
    case "dark":
    case "dark-blue":
      // @ts-ignore: Object is possibly 'null'.
      return palette?.appBackground?.darkBlue;
    case "darkGray":
      // @ts-ignore: Object is possibly 'null'.
      return palette?.appBackground?.darkGrey;
    case "background":
      // @ts-ignore: Object is possibly 'null'.
      return palette?.appBackground?.main;
    case "light-blue":
      // @ts-ignore: Object is possibly 'null'.
      return palette?.appBackground?.lightBlue;
    default:
      // @ts-ignore: Object is possibly 'null'.
      return null;
  }
}
// @ts-ignore: Object is possibly 'null'.
const useStyle = makeStyles((theme: Theme) => ({
  root: (props: IHeaderProps) => ({
    position: "relative",
    width: "100%",
    backgroundPosition: props.disableBackgroundImage ? null : "left top",
    backgroundRepeat: props.disableBackgroundImage ? null : "repeat-x",
    backgroundImage: props.disableBackgroundImage
      ? null
      : `url(${getBackgroundImage(props.color)})`,
    height: `${props.size === "large" ? "375px" : props.height}`,
    [theme.breakpoints.down('md')]: {
      height: `${props.size === "large" ? "425px" : props.height}`,
    },
    backgroundColor: getBackgroundColor(props.color, theme?.palette),
  }),
}));

const useStyleHeroHeader = makeStyles((theme: Theme) => ({
  root: () => ({
    position: "relative",
  }),

  backgroundImage: (props: IHeaderProps) => ({
    position: "absolute",
    width: "100%",
    top: 0,
    minHeight: "100%",
    backgroundPosition: "left top",
    backgroundRepeat: "repeat-x",
    backgroundImage: `url(${getBackgroundImage(props.color)})`,
    backgroundSize: "cover",
    // @ts-ignore: Object is possibly 'null'.
    height: props.headerHeights.largeScreen,
    [theme.breakpoints.down('md')]: {
      // @ts-ignore: Object is possibly 'null'.
      height: props.headerHeights.smallScreen,
    },
    backgroundColor: getBackgroundColor(props.color, theme?.palette),
  }),
  headerContent: (props: IHeaderProps) => ({
    // @ts-ignore: Object is possibly 'null'.
    minHeight: props.headerHeights.largeScreen,
    [theme.breakpoints.down('md')]: {
      // @ts-ignore: Object is possibly 'null'.
      minHeight: props.headerHeights.smallScreen,
    },
  }),
}));

const getHeaderHeights = (props: IHeaderProps): IHeaderHeights => {
  if (props.size === "large") {
    return { largeScreen: "275px", smallScreen: "275px" };
  }

  if (props.isEmptyEOC) {
    return { largeScreen: "142px", smallScreen: "142px" };
  }

  return (
    props.headerHeights || {
      largeScreen: props.height || DEFAULT_HEADER_HEIGHT,
      smallScreen: props.height || DEFAULT_HEADER_HEIGHT,
    }
  );
};

export const HeroHeader: FunctionComponent<IHeaderProps> = (props) => {
  const classes = useStyleHeroHeader({
    ...props,
    headerHeights: getHeaderHeights(props),
  });

  return (
    <Box className={clsx(classes.root, props.className)} style={props.style}>
      <div
        className={clsx(classes.backgroundImage, props.backgroundClassName)}
      />
      <Box position="relative" className={classes.headerContent}>
        {props.children}
      </Box>
    </Box>
  );
};

const Header: FunctionComponent<IHeaderProps> = (props) => {
  const height = props.color === "gray" ? "160px" : "180px";
  const classes = useStyle({ ...props, height });
  return (
    <Box className={clsx(classes.root, props.className)} style={props.style}>
      {props.children}
    </Box>
  );
};

export default Header;
