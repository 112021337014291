// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountActivationResponse2
 */
export interface AccountActivationResponse2  {
    /**
     * 
     * @type {string}
     * @memberof AccountActivationResponse2
     */
    employeeId?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountActivationResponse2
     */
    verificationEmail?: string;
}

export function AccountActivationResponse2FromJSON(json: any): AccountActivationResponse2 {
    return {
        'employeeId': !exists(json, 'employee_id') ? undefined : json['employee_id'],
        'verificationEmail': !exists(json, 'verification_email') ? undefined : json['verification_email'],
    };
}

export function AccountActivationResponse2ToJSON(value?: AccountActivationResponse2): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'employee_id': value.employeeId,
        'verification_email': value.verificationEmail,
    };
}


