// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CTMInfoProps
 */
export interface CTMInfoProps  {
    /**
     * 
     * @type {Array<string>}
     * @memberof CTMInfoProps
     */
    guilds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CTMInfoProps
     */
    staffGlobalId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CTMInfoProps
     */
    isPcp?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CTMInfoProps
     */
    truncatedName?: string;
    /**
     * 
     * @type {string}
     * @memberof CTMInfoProps
     */
    careTeamName?: string;
    /**
     * 
     * @type {number}
     * @memberof CTMInfoProps
     */
    podId?: number;
    /**
     * 
     * @type {string}
     * @memberof CTMInfoProps
     */
    roleAbbreviation?: string;
    /**
     * 
     * @type {string}
     * @memberof CTMInfoProps
     */
    clinicName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CTMInfoProps
     */
    clinics?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CTMInfoProps
     */
    clinicUsState?: string;
    /**
     * 
     * @type {string}
     * @memberof CTMInfoProps
     */
    corePodName?: string;
}

export function CTMInfoPropsFromJSON(json: any): CTMInfoProps {
    return {
        'guilds': !exists(json, 'guilds') ? undefined : json['guilds'],
        'staffGlobalId': !exists(json, 'staff_global_id') ? undefined : json['staff_global_id'],
        'isPcp': !exists(json, 'is_pcp') ? undefined : json['is_pcp'],
        'truncatedName': !exists(json, 'truncated_name') ? undefined : json['truncated_name'],
        'careTeamName': !exists(json, 'care_team_name') ? undefined : json['care_team_name'],
        'podId': !exists(json, 'pod_id') ? undefined : json['pod_id'],
        'roleAbbreviation': !exists(json, 'role_abbreviation') ? undefined : json['role_abbreviation'],
        'clinicName': !exists(json, 'clinic_name') ? undefined : json['clinic_name'],
        'clinics': !exists(json, 'clinics') ? undefined : json['clinics'],
        'clinicUsState': !exists(json, 'clinic_us_state') ? undefined : json['clinic_us_state'],
        'corePodName': !exists(json, 'core_pod_name') ? undefined : json['core_pod_name'],
    };
}

export function CTMInfoPropsToJSON(value?: CTMInfoProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'guilds': value.guilds,
        'staff_global_id': value.staffGlobalId,
        'is_pcp': value.isPcp,
        'truncated_name': value.truncatedName,
        'care_team_name': value.careTeamName,
        'pod_id': value.podId,
        'role_abbreviation': value.roleAbbreviation,
        'clinic_name': value.clinicName,
        'clinics': value.clinics,
        'clinic_us_state': value.clinicUsState,
        'core_pod_name': value.corePodName,
    };
}


