import React from "react";

import Grid from "components/Grid";
import Hidden from "components/Hidden";
import Box from "components/Box";

import { useStyles } from "pages/LandingPage/styles";
import {
  LandingPageHealthRecordsHotLinks,
  LandingPagePendingPaymentsBalance,
  LandingPageTertiaryCTA,
} from "pages/LandingPage/Common";
import InsuranceCTALandingPage from "./InsuranceCTALandingPage";
import AfterHoursWidget from "components/AfterHoursWidget";

interface ConversationsLayoutProps {
  children: React.ReactNode;
}

const ConversationsLayout: React.FC<ConversationsLayoutProps> = ({
  children,
}) => {
  const classes = useStyles();

  const SideInfoComponents = () => (
    <React.Fragment>
      <div className={classes.afterHoursWidgetDesktop}>
        <AfterHoursWidget />
      </div>
      <LandingPageHealthRecordsHotLinks />
      <LandingPageTertiaryCTA />
      <LandingPagePendingPaymentsBalance />
      <InsuranceCTALandingPage />
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.episodeContainer}>
        <Grid item lg={8} md={12}>
          {children}
        </Grid>
        <Hidden lgDown>
          <Grid item lg={4} md={12}>
            <SideInfoComponents />
          </Grid>
        </Hidden>
      </Grid>

      <Hidden lgUp>
        <Box mt={6} mb={3}>
          <SideInfoComponents />
        </Box>
      </Hidden>
    </React.Fragment>
  );
};

export default ConversationsLayout;
