// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppointmentNoteOutput,
    AppointmentNoteOutputFromJSON,
    AppointmentNoteOutputToJSON,
} from './AppointmentNoteOutput';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
    MemberClinic,
    MemberClinicFromJSON,
    MemberClinicToJSON,
} from './MemberClinic';
import {
    MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';
import {
    VideoConferenceOutput,
    VideoConferenceOutputFromJSON,
    VideoConferenceOutputToJSON,
} from './VideoConferenceOutput';


/**
 * 
 * @export
 * @interface Appointment
 */
export interface Appointment  {
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    id: string;
    /**
     * 
     * @type {boolean}
     * @memberof Appointment
     */
    internal?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    clinicId?: number;
    /**
     * 
     * @type {MemberClinic}
     * @memberof Appointment
     */
    clinic?: MemberClinic;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    visitType?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    visitTypeFull?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    reason?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    appointmentType?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    apptGeneralType?: string;
    /**
     * 
     * @type {CTMInfo}
     * @memberof Appointment
     */
    provider?: CTMInfo;
    /**
     * 
     * @type {MemberInfo}
     * @memberof Appointment
     */
    patient?: MemberInfo;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    duration?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    disposition?: AppointmentDispositionEnum;
    /**
     * 
     * @type {Date}
     * @memberof Appointment
     */
    dispositionAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Appointment
     */
    startAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Appointment
     */
    endAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    visitLink?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    patientCellPhone?: string;
    /**
     * 
     * @type {number}
     * @memberof Appointment
     */
    slotId?: number;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    cancelReason?: string;
    /**
     * 
     * @type {VideoConferenceOutput}
     * @memberof Appointment
     */
    videoConference?: VideoConferenceOutput;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    method?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    episodeId?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    componentId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Appointment
     */
    xopReferral?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof Appointment
     */
    accommodations?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Appointment
     */
    eligibleForCheckin?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Appointment
     */
    eligibleForApptBanner?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Appointment
     */
    checkInCompleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    serviceSubtypeLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    subjectFreetext?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    episodeCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof Appointment
     */
    componentType?: AppointmentComponentTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Appointment
     */
    canReschedule?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Appointment
     */
    canVisitBeCancelled?: boolean;
    /**
     * 
     * @type {Array<AppointmentNoteOutput>}
     * @memberof Appointment
     */
    appointmentNotes?: Array<AppointmentNoteOutput>;
}

export function AppointmentFromJSON(json: any): Appointment {
    return {
        'id': json['id'],
        'internal': !exists(json, 'internal') ? undefined : json['internal'],
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'clinic': !exists(json, 'clinic') ? undefined : MemberClinicFromJSON(json['clinic']),
        'visitType': !exists(json, 'visit_type') ? undefined : json['visit_type'],
        'visitTypeFull': !exists(json, 'visit_type_full') ? undefined : json['visit_type_full'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'appointmentType': !exists(json, 'appointment_type') ? undefined : json['appointment_type'],
        'apptGeneralType': !exists(json, 'appt_general_type') ? undefined : json['appt_general_type'],
        'provider': !exists(json, 'provider') ? undefined : CTMInfoFromJSON(json['provider']),
        'patient': !exists(json, 'patient') ? undefined : MemberInfoFromJSON(json['patient']),
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'disposition': !exists(json, 'disposition') ? undefined : json['disposition'],
        'dispositionAt': !exists(json, 'disposition_at') ? undefined : new Date(json['disposition_at']),
        'startAt': !exists(json, 'start_at') ? undefined : new Date(json['start_at']),
        'endAt': !exists(json, 'end_at') ? undefined : new Date(json['end_at']),
        'visitLink': !exists(json, 'visit_link') ? undefined : json['visit_link'],
        'patientCellPhone': !exists(json, 'patient_cell_phone') ? undefined : json['patient_cell_phone'],
        'slotId': !exists(json, 'slot_id') ? undefined : json['slot_id'],
        'cancelReason': !exists(json, 'cancel_reason') ? undefined : json['cancel_reason'],
        'videoConference': !exists(json, 'video_conference') ? undefined : VideoConferenceOutputFromJSON(json['video_conference']),
        'method': !exists(json, 'method') ? undefined : json['method'],
        'episodeId': !exists(json, 'episode_id') ? undefined : json['episode_id'],
        'componentId': !exists(json, 'component_id') ? undefined : json['component_id'],
        'xopReferral': !exists(json, 'xop_referral') ? undefined : json['xop_referral'],
        'accommodations': !exists(json, 'accommodations') ? undefined : json['accommodations'],
        'eligibleForCheckin': !exists(json, 'eligible_for_checkin') ? undefined : json['eligible_for_checkin'],
        'eligibleForApptBanner': !exists(json, 'eligible_for_appt_banner') ? undefined : json['eligible_for_appt_banner'],
        'checkInCompleted': !exists(json, 'check_in_completed') ? undefined : json['check_in_completed'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'serviceSubtypeLabel': !exists(json, 'service_subtype_label') ? undefined : json['service_subtype_label'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'subjectFreetext': !exists(json, 'subject_freetext') ? undefined : json['subject_freetext'],
        'episodeCategory': !exists(json, 'episode_category') ? undefined : json['episode_category'],
        'componentType': !exists(json, 'component_type') ? undefined : json['component_type'],
        'canReschedule': !exists(json, 'can_reschedule') ? undefined : json['can_reschedule'],
        'canVisitBeCancelled': !exists(json, 'can_visit_be_cancelled') ? undefined : json['can_visit_be_cancelled'],
        'appointmentNotes': !exists(json, 'appointment_notes') ? undefined : (json['appointment_notes'] as Array<any>).map(AppointmentNoteOutputFromJSON),
    };
}

export function AppointmentToJSON(value?: Appointment): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'internal': value.internal,
        'clinic_id': value.clinicId,
        'clinic': MemberClinicToJSON(value.clinic),
        'visit_type': value.visitType,
        'visit_type_full': value.visitTypeFull,
        'reason': value.reason,
        'appointment_type': value.appointmentType,
        'appt_general_type': value.apptGeneralType,
        'provider': CTMInfoToJSON(value.provider),
        'patient': MemberInfoToJSON(value.patient),
        'duration': value.duration,
        'disposition': value.disposition,
        'disposition_at': value.dispositionAt === undefined ? undefined : value.dispositionAt.toISOString(),
        'start_at': value.startAt === undefined ? undefined : value.startAt.toISOString(),
        'end_at': value.endAt === undefined ? undefined : value.endAt.toISOString(),
        'visit_link': value.visitLink,
        'patient_cell_phone': value.patientCellPhone,
        'slot_id': value.slotId,
        'cancel_reason': value.cancelReason,
        'video_conference': VideoConferenceOutputToJSON(value.videoConference),
        'method': value.method,
        'episode_id': value.episodeId,
        'component_id': value.componentId,
        'xop_referral': value.xopReferral,
        'accommodations': value.accommodations,
        'eligible_for_checkin': value.eligibleForCheckin,
        'eligible_for_appt_banner': value.eligibleForApptBanner,
        'check_in_completed': value.checkInCompleted,
        'state': value.state,
        'service_subtype_label': value.serviceSubtypeLabel,
        'subject': value.subject,
        'subject_freetext': value.subjectFreetext,
        'episode_category': value.episodeCategory,
        'component_type': value.componentType,
        'can_reschedule': value.canReschedule,
        'can_visit_be_cancelled': value.canVisitBeCancelled,
        'appointment_notes': value.appointmentNotes === undefined ? undefined : (value.appointmentNotes as Array<any>).map(AppointmentNoteOutputToJSON),
    };
}

/**
* @export
* @enum {string}
*/
export enum AppointmentDispositionEnum {
    Complete = 'complete',
    Cancel = 'cancel',
    NoShow = 'no_show',
    LateCancel = 'late_cancel'
}
/**
* @export
* @enum {string}
*/
export enum AppointmentComponentTypeEnum {
    CenterVisitReferralComponent = 'CenterVisitReferralComponent',
    VirtualVisitReferralComponent = 'VirtualVisitReferralComponent',
    SelfScheduleAppointmentComponent = 'SelfScheduleAppointmentComponent'
}


