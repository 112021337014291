// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionDetails,
    QuestionDetailsFromJSON,
    QuestionDetailsToJSON,
} from './QuestionDetails';


/**
 * 
 * @export
 * @interface QuestionSetRequestBody
 */
export interface QuestionSetRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof QuestionSetRequestBody
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionSetRequestBody
     */
    category: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof QuestionSetRequestBody
     */
    serviceLines: Array<string>;
    /**
     * 
     * @type {Array<QuestionDetails>}
     * @memberof QuestionSetRequestBody
     */
    questions: Array<QuestionDetails>;
}

export function QuestionSetRequestBodyFromJSON(json: any): QuestionSetRequestBody {
    return {
        'title': json['title'],
        'category': json['category'],
        'serviceLines': json['service_lines'],
        'questions': (json['questions'] as Array<any>).map(QuestionDetailsFromJSON),
    };
}

export function QuestionSetRequestBodyToJSON(value?: QuestionSetRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'category': value.category,
        'service_lines': value.serviceLines,
        'questions': (value.questions as Array<any>).map(QuestionDetailsToJSON),
    };
}


