// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     PermissionSchema,
    PermissionSchemaFromJSON,
    PermissionSchemaToJSON,
} from './PermissionSchema';
import {
     TimestampedItemSchema,
    TimestampedItemSchemaFromJSON,
    TimestampedItemSchemaToJSON,
} from './TimestampedItemSchema';
/**
 * @type StoredPermissionSchema
 * @export
 */
export interface StoredPermissionSchema extends PermissionSchema, TimestampedItemSchema {
}

export function StoredPermissionSchemaFromJSON(json: any): StoredPermissionSchema {
    return {
        ...PermissionSchemaFromJSON(json),
        ...TimestampedItemSchemaFromJSON(json),
    };
}

export function StoredPermissionSchemaToJSON(value?: StoredPermissionSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...PermissionSchemaToJSON(value),
        ...TimestampedItemSchemaToJSON(value),
    };
}

