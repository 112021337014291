// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionSetSearchResultsSchema,
    QuestionSetSearchResultsSchemaFromJSON,
    QuestionSetSearchResultsSchemaToJSON,
} from './QuestionSetSearchResultsSchema';


/**
 * 
 * @export
 * @interface XopQuestionSetSearchOutputSchema
 */
export interface XopQuestionSetSearchOutputSchema  {
    /**
     * 
     * @type {number}
     * @memberof XopQuestionSetSearchOutputSchema
     */
    count?: number;
    /**
     * 
     * @type {Array<QuestionSetSearchResultsSchema>}
     * @memberof XopQuestionSetSearchOutputSchema
     */
    results: Array<QuestionSetSearchResultsSchema>;
}

export function XopQuestionSetSearchOutputSchemaFromJSON(json: any): XopQuestionSetSearchOutputSchema {
    return {
        'count': !exists(json, 'count') ? undefined : json['count'],
        'results': (json['results'] as Array<any>).map(QuestionSetSearchResultsSchemaFromJSON),
    };
}

export function XopQuestionSetSearchOutputSchemaToJSON(value?: XopQuestionSetSearchOutputSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'results': (value.results as Array<any>).map(QuestionSetSearchResultsSchemaToJSON),
    };
}


