/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EnrollPushNotificationRequestBody,
    EnrollPushNotificationRequestBodyFromJSON,
    EnrollPushNotificationRequestBodyToJSON,
} from '../models/EnrollPushNotificationRequestBody'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    MemberPushNotificationArrayResponse,
    MemberPushNotificationArrayResponseFromJSON,
    MemberPushNotificationArrayResponseToJSON,
} from '../models/MemberPushNotificationArrayResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    PushNotificationCategoriesResponse,
    PushNotificationCategoriesResponseFromJSON,
    PushNotificationCategoriesResponseToJSON,
} from '../models/PushNotificationCategoriesResponse'
import {
    PushNotificationCategoryResponse,
    PushNotificationCategoryResponseFromJSON,
    PushNotificationCategoryResponseToJSON,
} from '../models/PushNotificationCategoryResponse'
import {
    PushNotificationOptOutRequestBody,
    PushNotificationOptOutRequestBodyFromJSON,
    PushNotificationOptOutRequestBodyToJSON,
} from '../models/PushNotificationOptOutRequestBody'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UnenrollDevicePushNotificationsRequestBody,
    UnenrollDevicePushNotificationsRequestBodyFromJSON,
    UnenrollDevicePushNotificationsRequestBodyToJSON,
} from '../models/UnenrollDevicePushNotificationsRequestBody'
import {
    UpdateDeviceRequestBody,
    UpdateDeviceRequestBodyFromJSON,
    UpdateDeviceRequestBodyToJSON,
} from '../models/UpdateDeviceRequestBody'
import {
    UserPushNotificationOutput,
    UserPushNotificationOutputFromJSON,
    UserPushNotificationOutputToJSON,
} from '../models/UserPushNotificationOutput'


export interface ClearsAllReadNotificationsForAUserRequest {
    id: string;
}
export interface DeletePushNotificationOptOutRequest {
    deviceId: string;
    pushNotificationCategoryId: string;
}
export interface EnrollDevicePushNotificationRequest {
    enrollPushNotificationRequestBody?: EnrollPushNotificationRequestBody;
}
export interface GetEnrolledDevicePushNotificationCategoriesRequest {
    deviceId: string;
}
export interface GetUserPushNotificationsRequest {
    id: string;
    page?: string;
    pageSize?: string;
    state?: GetUserPushNotificationsStateEnum;
}
export interface MarkUserPushNotificationAsReadRequest {
    notificationId: string;
}
export interface MarksAllNotificationsAsReadForAUserRequest {
    id: string;
}
export interface PushNotificationOptOutRequest {
    deviceId: string;
    pushNotificationOptOutRequestBody?: PushNotificationOptOutRequestBody;
}
export interface UnenrollDevicePushNotificationsRequest {
    unenrollDevicePushNotificationsRequestBody?: UnenrollDevicePushNotificationsRequestBody;
}
export interface UpdateEnrolledDeviceRequest {
    deviceId: string;
    updateDeviceRequestBody?: UpdateDeviceRequestBody;
}


export interface IUserPushNotificationsApi {
      clearsAllReadNotificationsForAUser: (requestParameters: ClearsAllReadNotificationsForAUserRequest) => Promise<void>
      deletePushNotificationOptOut: (requestParameters: DeletePushNotificationOptOutRequest) => Promise<PushNotificationCategoryResponse>
      enrollDevicePushNotification: (requestParameters: EnrollDevicePushNotificationRequest) => Promise<void>
      getEnrolledDevicePushNotificationCategories: (requestParameters: GetEnrolledDevicePushNotificationCategoriesRequest) => Promise<PushNotificationCategoriesResponse>
      getUserPushNotifications: (requestParameters: GetUserPushNotificationsRequest) => Promise<MemberPushNotificationArrayResponse>
      markUserPushNotificationAsRead: (requestParameters: MarkUserPushNotificationAsReadRequest) => Promise<UserPushNotificationOutput>
      marksAllNotificationsAsReadForAUser: (requestParameters: MarksAllNotificationsAsReadForAUserRequest) => Promise<void>
      pushNotificationOptOut: (requestParameters: PushNotificationOptOutRequest) => Promise<PushNotificationCategoryResponse>
      unenrollDevicePushNotifications: (requestParameters: UnenrollDevicePushNotificationsRequest) => Promise<void>
      updateEnrolledDevice: (requestParameters: UpdateEnrolledDeviceRequest) => Promise<void>
      clearsAllReadNotificationsForAUserWithResponse: (requestParameters: ClearsAllReadNotificationsForAUserRequest) => Promise<{ value: void, response: any}>
      deletePushNotificationOptOutWithResponse: (requestParameters: DeletePushNotificationOptOutRequest) => Promise<{ value: PushNotificationCategoryResponse, response: any}>
      enrollDevicePushNotificationWithResponse: (requestParameters: EnrollDevicePushNotificationRequest) => Promise<{ value: void, response: any}>
      getEnrolledDevicePushNotificationCategoriesWithResponse: (requestParameters: GetEnrolledDevicePushNotificationCategoriesRequest) => Promise<{ value: PushNotificationCategoriesResponse, response: any}>
      getUserPushNotificationsWithResponse: (requestParameters: GetUserPushNotificationsRequest) => Promise<{ value: MemberPushNotificationArrayResponse, response: any}>
      markUserPushNotificationAsReadWithResponse: (requestParameters: MarkUserPushNotificationAsReadRequest) => Promise<{ value: UserPushNotificationOutput, response: any}>
      marksAllNotificationsAsReadForAUserWithResponse: (requestParameters: MarksAllNotificationsAsReadForAUserRequest) => Promise<{ value: void, response: any}>
      pushNotificationOptOutWithResponse: (requestParameters: PushNotificationOptOutRequest) => Promise<{ value: PushNotificationCategoryResponse, response: any}>
      unenrollDevicePushNotificationsWithResponse: (requestParameters: UnenrollDevicePushNotificationsRequest) => Promise<{ value: void, response: any}>
      updateEnrolledDeviceWithResponse: (requestParameters: UpdateEnrolledDeviceRequest) => Promise<{ value: void, response: any}>
}

/**
 * no description
 */
export class UserPushNotificationsApi extends runtime.BaseAPI {

    /**
     * Clears all read notifications for a user
     * Clears all read notifications for a user
     */
    clearsAllReadNotificationsForAUserRaw = async (requestParameters: ClearsAllReadNotificationsForAUserRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clearsAllReadNotificationsForAUser.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/user_push_notifications/{id}/clear_notifications`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Clears all read notifications for a user
     * Clears all read notifications for a user
     */
    clearsAllReadNotificationsForAUser = async (requestParameters: ClearsAllReadNotificationsForAUserRequest): Promise<void> => {
        const { runtime } = await this.clearsAllReadNotificationsForAUserRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Clears all read notifications for a user
     * Clears all read notifications for a user
     */
    clearsAllReadNotificationsForAUserWithResponse = async (requestParameters: ClearsAllReadNotificationsForAUserRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.clearsAllReadNotificationsForAUserRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Deletes user opt out record
     */
    deletePushNotificationOptOutRaw = async (requestParameters: DeletePushNotificationOptOutRequest): Promise<{ runtime: runtime.ApiResponse<PushNotificationCategoryResponse>, response: any}> => {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling deletePushNotificationOptOut.');
        }

                        if (requestParameters.pushNotificationCategoryId === null || requestParameters.pushNotificationCategoryId === undefined) {
            throw new runtime.RequiredError('pushNotificationCategoryId','Required parameter requestParameters.pushNotificationCategoryId was null or undefined when calling deletePushNotificationOptOut.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/enrolled_devices/{device_id}/push_notification_category_id/{push_notification_category_id}`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"push_notification_category_id"}}`, encodeURIComponent(String(requestParameters.pushNotificationCategoryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => PushNotificationCategoryResponseFromJSON(jsonValue)), response };
    }

    /**
     * Deletes user opt out record
     */
    deletePushNotificationOptOut = async (requestParameters: DeletePushNotificationOptOutRequest): Promise<PushNotificationCategoryResponse> => {
        const { runtime } = await this.deletePushNotificationOptOutRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Deletes user opt out record
     */
    deletePushNotificationOptOutWithResponse = async (requestParameters: DeletePushNotificationOptOutRequest): Promise<{ value: PushNotificationCategoryResponse, response: any}> => {
        const { runtime, response } = await this.deletePushNotificationOptOutRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Enroll Device for Push notification
     * Enroll Device in for Push notification
     */
    enrollDevicePushNotificationRaw = async (requestParameters: EnrollDevicePushNotificationRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/enroll_push_notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EnrollPushNotificationRequestBodyToJSON(requestParameters.enrollPushNotificationRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Enroll Device for Push notification
     * Enroll Device in for Push notification
     */
    enrollDevicePushNotification = async (requestParameters: EnrollDevicePushNotificationRequest): Promise<void> => {
        const { runtime } = await this.enrollDevicePushNotificationRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Enroll Device for Push notification
     * Enroll Device in for Push notification
     */
    enrollDevicePushNotificationWithResponse = async (requestParameters: EnrollDevicePushNotificationRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.enrollDevicePushNotificationRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Get Enrolled Device Push Notification Categories
     */
    getEnrolledDevicePushNotificationCategoriesRaw = async (requestParameters: GetEnrolledDevicePushNotificationCategoriesRequest): Promise<{ runtime: runtime.ApiResponse<PushNotificationCategoriesResponse>, response: any}> => {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling getEnrolledDevicePushNotificationCategories.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/enrolled_devices/{device_id}/push_notification_categories`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => PushNotificationCategoriesResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get Enrolled Device Push Notification Categories
     */
    getEnrolledDevicePushNotificationCategories = async (requestParameters: GetEnrolledDevicePushNotificationCategoriesRequest): Promise<PushNotificationCategoriesResponse> => {
        const { runtime } = await this.getEnrolledDevicePushNotificationCategoriesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get Enrolled Device Push Notification Categories
     */
    getEnrolledDevicePushNotificationCategoriesWithResponse = async (requestParameters: GetEnrolledDevicePushNotificationCategoriesRequest): Promise<{ value: PushNotificationCategoriesResponse, response: any}> => {
        const { runtime, response } = await this.getEnrolledDevicePushNotificationCategoriesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * View paginated list of a member\'s push notifications
     * View paginated list of a member\'s push notifications
     */
    getUserPushNotificationsRaw = async (requestParameters: GetUserPushNotificationsRequest): Promise<{ runtime: runtime.ApiResponse<MemberPushNotificationArrayResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserPushNotifications.');
        }

                                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/user_push_notifications`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberPushNotificationArrayResponseFromJSON(jsonValue)), response };
    }

    /**
     * View paginated list of a member\'s push notifications
     * View paginated list of a member\'s push notifications
     */
    getUserPushNotifications = async (requestParameters: GetUserPushNotificationsRequest): Promise<MemberPushNotificationArrayResponse> => {
        const { runtime } = await this.getUserPushNotificationsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * View paginated list of a member\'s push notifications
     * View paginated list of a member\'s push notifications
     */
    getUserPushNotificationsWithResponse = async (requestParameters: GetUserPushNotificationsRequest): Promise<{ value: MemberPushNotificationArrayResponse, response: any}> => {
        const { runtime, response } = await this.getUserPushNotificationsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Mark user push notification as read
     * Mark user push notification as read
     */
    markUserPushNotificationAsReadRaw = async (requestParameters: MarkUserPushNotificationAsReadRequest): Promise<{ runtime: runtime.ApiResponse<UserPushNotificationOutput>, response: any}> => {
        if (requestParameters.notificationId === null || requestParameters.notificationId === undefined) {
            throw new runtime.RequiredError('notificationId','Required parameter requestParameters.notificationId was null or undefined when calling markUserPushNotificationAsRead.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/user_push_notifications/mark_as_read/{notification_id}`.replace(`{${"notification_id"}}`, encodeURIComponent(String(requestParameters.notificationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => UserPushNotificationOutputFromJSON(jsonValue)), response };
    }

    /**
     * Mark user push notification as read
     * Mark user push notification as read
     */
    markUserPushNotificationAsRead = async (requestParameters: MarkUserPushNotificationAsReadRequest): Promise<UserPushNotificationOutput> => {
        const { runtime } = await this.markUserPushNotificationAsReadRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Mark user push notification as read
     * Mark user push notification as read
     */
    markUserPushNotificationAsReadWithResponse = async (requestParameters: MarkUserPushNotificationAsReadRequest): Promise<{ value: UserPushNotificationOutput, response: any}> => {
        const { runtime, response } = await this.markUserPushNotificationAsReadRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Marks all notifications as read for a user
     * Marks all notifications as read for a user
     */
    marksAllNotificationsAsReadForAUserRaw = async (requestParameters: MarksAllNotificationsAsReadForAUserRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling marksAllNotificationsAsReadForAUser.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/user_push_notifications/{id}/mark_all_as_read`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Marks all notifications as read for a user
     * Marks all notifications as read for a user
     */
    marksAllNotificationsAsReadForAUser = async (requestParameters: MarksAllNotificationsAsReadForAUserRequest): Promise<void> => {
        const { runtime } = await this.marksAllNotificationsAsReadForAUserRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Marks all notifications as read for a user
     * Marks all notifications as read for a user
     */
    marksAllNotificationsAsReadForAUserWithResponse = async (requestParameters: MarksAllNotificationsAsReadForAUserRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.marksAllNotificationsAsReadForAUserRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Opt out of push notification
     * Opt out
     */
    pushNotificationOptOutRaw = async (requestParameters: PushNotificationOptOutRequest): Promise<{ runtime: runtime.ApiResponse<PushNotificationCategoryResponse>, response: any}> => {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling pushNotificationOptOut.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/enrolled_devices/{device_id}/push_notification_category`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PushNotificationOptOutRequestBodyToJSON(requestParameters.pushNotificationOptOutRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => PushNotificationCategoryResponseFromJSON(jsonValue)), response };
    }

    /**
     * Opt out of push notification
     * Opt out
     */
    pushNotificationOptOut = async (requestParameters: PushNotificationOptOutRequest): Promise<PushNotificationCategoryResponse> => {
        const { runtime } = await this.pushNotificationOptOutRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Opt out of push notification
     * Opt out
     */
    pushNotificationOptOutWithResponse = async (requestParameters: PushNotificationOptOutRequest): Promise<{ value: PushNotificationCategoryResponse, response: any}> => {
        const { runtime, response } = await this.pushNotificationOptOutRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Unenroll Device push notifications
     * Unenroll Device push notifications
     */
    unenrollDevicePushNotificationsRaw = async (requestParameters: UnenrollDevicePushNotificationsRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/unenroll_push_notification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UnenrollDevicePushNotificationsRequestBodyToJSON(requestParameters.unenrollDevicePushNotificationsRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Unenroll Device push notifications
     * Unenroll Device push notifications
     */
    unenrollDevicePushNotifications = async (requestParameters: UnenrollDevicePushNotificationsRequest): Promise<void> => {
        const { runtime } = await this.unenrollDevicePushNotificationsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Unenroll Device push notifications
     * Unenroll Device push notifications
     */
    unenrollDevicePushNotificationsWithResponse = async (requestParameters: UnenrollDevicePushNotificationsRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.unenrollDevicePushNotificationsRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Update enrolled device record
     * Update enrolled device
     */
    updateEnrolledDeviceRaw = async (requestParameters: UpdateEnrolledDeviceRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling updateEnrolledDevice.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/enrolled_devices/{device_id}`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDeviceRequestBodyToJSON(requestParameters.updateDeviceRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Update enrolled device record
     * Update enrolled device
     */
    updateEnrolledDevice = async (requestParameters: UpdateEnrolledDeviceRequest): Promise<void> => {
        const { runtime } = await this.updateEnrolledDeviceRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update enrolled device record
     * Update enrolled device
     */
    updateEnrolledDeviceWithResponse = async (requestParameters: UpdateEnrolledDeviceRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.updateEnrolledDeviceRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetUserPushNotificationsStateEnum {
    Read = 'read',
    Sent = 'sent'
}

export const fetchUserPushNotificationsApi: IUserPushNotificationsApi  = new UserPushNotificationsApi();
