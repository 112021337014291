// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberClinic,
    MemberClinicFromJSON,
    MemberClinicToJSON,
} from './MemberClinic';


/**
 * 
 * @export
 * @interface MemberClinicsResults
 */
export interface MemberClinicsResults  {
    /**
     * 
     * @type {Array<MemberClinic>}
     * @memberof MemberClinicsResults
     */
    closestClinics?: Array<MemberClinic>;
    /**
     * 
     * @type {Array<MemberClinic>}
     * @memberof MemberClinicsResults
     */
    otherClinics?: Array<MemberClinic>;
    /**
     * This field is related to out of state scheduling, with it we can make distinction between having no clinics in the state or we do have clinics but there is no available slots
     * @type {boolean}
     * @memberof MemberClinicsResults
     */
    hasClinicsWithNoAvailability?: boolean;
}

export function MemberClinicsResultsFromJSON(json: any): MemberClinicsResults {
    return {
        'closestClinics': !exists(json, 'closest_clinics') ? undefined : (json['closest_clinics'] as Array<any>).map(MemberClinicFromJSON),
        'otherClinics': !exists(json, 'other_clinics') ? undefined : (json['other_clinics'] as Array<any>).map(MemberClinicFromJSON),
        'hasClinicsWithNoAvailability': !exists(json, 'has_clinics_with_no_availability') ? undefined : json['has_clinics_with_no_availability'],
    };
}

export function MemberClinicsResultsToJSON(value?: MemberClinicsResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'closest_clinics': value.closestClinics === undefined ? undefined : (value.closestClinics as Array<any>).map(MemberClinicToJSON),
        'other_clinics': value.otherClinics === undefined ? undefined : (value.otherClinics as Array<any>).map(MemberClinicToJSON),
        'has_clinics_with_no_availability': value.hasClinicsWithNoAvailability,
    };
}


