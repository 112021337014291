import { useQuery } from "react-query";
import {
  GetCheckinRequest,
  fetchCheckinApi,
} from "shared/fetch/src/apis/CheckinApi";

export const getCheckinByAppointmentIdQueryKey = (id: string | number) => [
  "getCheckinByAppointment",
  id?.toString(),
];

export default (request: GetCheckinRequest) => {
  const { data, isLoading, refetch } = useQuery(
    getCheckinByAppointmentIdQueryKey(request.id),
    () => fetchCheckinApi.getCheckin(request),
    {
      enabled: !!request.id && request.id !== "undefined",
    }
  );

  return {
    data,
    isLoading,
    refetch,
  };
};
