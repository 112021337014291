// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    AddTimelineEventRequestBody,
    AddTimelineEventRequestBodyFromJSON,
    AddTimelineEventRequestBodyToJSON,
} from '../models/AddTimelineEventRequestBody'
import {
    AppointmentSuccessfullyAssignedResponse,
    AppointmentSuccessfullyAssignedResponseFromJSON,
    AppointmentSuccessfullyAssignedResponseToJSON,
} from '../models/AppointmentSuccessfullyAssignedResponse'
import {
    ArchiveEpisodeOfCareInfoBody,
    ArchiveEpisodeOfCareInfoBodyFromJSON,
    ArchiveEpisodeOfCareInfoBodyToJSON,
} from '../models/ArchiveEpisodeOfCareInfoBody'
import {
    ArchiveEpisodeOfCareRequestBody,
    ArchiveEpisodeOfCareRequestBodyFromJSON,
    ArchiveEpisodeOfCareRequestBodyToJSON,
} from '../models/ArchiveEpisodeOfCareRequestBody'
import {
    CTMInfoArrayResponse1,
    CTMInfoArrayResponse1FromJSON,
    CTMInfoArrayResponse1ToJSON,
} from '../models/CTMInfoArrayResponse1'
import {
    CalculatorInitialAnswersResponse,
    CalculatorInitialAnswersResponseFromJSON,
    CalculatorInitialAnswersResponseToJSON,
} from '../models/CalculatorInitialAnswersResponse'
import {
    CompleteCalculatorRequestBody,
    CompleteCalculatorRequestBodyFromJSON,
    CompleteCalculatorRequestBodyToJSON,
} from '../models/CompleteCalculatorRequestBody'
import {
    ComponentDetailsOutput,
    ComponentDetailsOutputFromJSON,
    ComponentDetailsOutputToJSON,
} from '../models/ComponentDetailsOutput'
import {
    CreateOrAssignEpisodeToAnAppointmentRequestBody,
    CreateOrAssignEpisodeToAnAppointmentRequestBodyFromJSON,
    CreateOrAssignEpisodeToAnAppointmentRequestBodyToJSON,
} from '../models/CreateOrAssignEpisodeToAnAppointmentRequestBody'
import {
    EpisodeCreationInput,
    EpisodeCreationInputFromJSON,
    EpisodeCreationInputToJSON,
} from '../models/EpisodeCreationInput'
import {
    EpisodeOutput,
    EpisodeOutputFromJSON,
    EpisodeOutputToJSON,
} from '../models/EpisodeOutput'
import {
    EpisodeOwnerRequestBody,
    EpisodeOwnerRequestBodyFromJSON,
    EpisodeOwnerRequestBodyToJSON,
} from '../models/EpisodeOwnerRequestBody'
import {
    EpisodeRolesOutputSchema,
    EpisodeRolesOutputSchemaFromJSON,
    EpisodeRolesOutputSchemaToJSON,
} from '../models/EpisodeRolesOutputSchema'
import {
    EpisodeSummary,
    EpisodeSummaryFromJSON,
    EpisodeSummaryToJSON,
} from '../models/EpisodeSummary'
import {
    EpisodesRolesUpdatedResponse,
    EpisodesRolesUpdatedResponseFromJSON,
    EpisodesRolesUpdatedResponseToJSON,
} from '../models/EpisodesRolesUpdatedResponse'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InternalServerErrorResponse,
    InternalServerErrorResponseFromJSON,
    InternalServerErrorResponseToJSON,
} from '../models/InternalServerErrorResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    ListCalculatorsResponse,
    ListCalculatorsResponseFromJSON,
    ListCalculatorsResponseToJSON,
} from '../models/ListCalculatorsResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    ServiceUnavailableResponse,
    ServiceUnavailableResponseFromJSON,
    ServiceUnavailableResponseToJSON,
} from '../models/ServiceUnavailableResponse'
import {
    TimelineEpisodeOutput,
    TimelineEpisodeOutputFromJSON,
    TimelineEpisodeOutputToJSON,
} from '../models/TimelineEpisodeOutput'
import {
    TimelineEpisodeTimelineEventsOutput,
    TimelineEpisodeTimelineEventsOutputFromJSON,
    TimelineEpisodeTimelineEventsOutputToJSON,
} from '../models/TimelineEpisodeTimelineEventsOutput'
import {
    TimelineEventOutput,
    TimelineEventOutputFromJSON,
    TimelineEventOutputToJSON,
} from '../models/TimelineEventOutput'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpdateConsultationRequestBody,
    UpdateConsultationRequestBodyFromJSON,
    UpdateConsultationRequestBodyToJSON,
} from '../models/UpdateConsultationRequestBody'
import {
    UpdateEpisodeRolesRequestBody,
    UpdateEpisodeRolesRequestBodyFromJSON,
    UpdateEpisodeRolesRequestBodyToJSON,
} from '../models/UpdateEpisodeRolesRequestBody'
import {
    UpdateQuestionsForEpisodeRequestBody,
    UpdateQuestionsForEpisodeRequestBodyFromJSON,
    UpdateQuestionsForEpisodeRequestBodyToJSON,
} from '../models/UpdateQuestionsForEpisodeRequestBody'
import {
    UpdateQuestionsForEpisodeResponse,
    UpdateQuestionsForEpisodeResponseFromJSON,
    UpdateQuestionsForEpisodeResponseToJSON,
} from '../models/UpdateQuestionsForEpisodeResponse'
import {
    UpdateVirtualCareTypeRequestBody,
    UpdateVirtualCareTypeRequestBodyFromJSON,
    UpdateVirtualCareTypeRequestBodyToJSON,
} from '../models/UpdateVirtualCareTypeRequestBody'
import {
    XopQuestionSearchOutputSchema,
    XopQuestionSearchOutputSchemaFromJSON,
    XopQuestionSearchOutputSchemaToJSON,
} from '../models/XopQuestionSearchOutputSchema'
import {
    XopQuestionSetSearchOutputSchema,
    XopQuestionSetSearchOutputSchemaFromJSON,
    XopQuestionSetSearchOutputSchemaToJSON,
} from '../models/XopQuestionSetSearchOutputSchema'


export interface AddTimelineEventRequest {
    id: string;
    addTimelineEventRequestBody?: AddTimelineEventRequestBody;
}

export interface ArchiveEpisodeOfCareRequest {
    id: string;
    archiveEpisodeOfCareRequestBody?: ArchiveEpisodeOfCareRequestBody;
}

export interface CreateCtmCompletedCalculatorRequest {
    episodeId: string;
    calculatorId: string;
    completeCalculatorRequestBody: CompleteCalculatorRequestBody;
}

export interface CreateOrAssignEpisodeToAnAppointmentRequest {
    createOrAssignEpisodeToAnAppointmentRequestBody?: CreateOrAssignEpisodeToAnAppointmentRequestBody;
}

export interface FetchArchiveEpisodeOfCareInfoRequest {
    id: string;
}

export interface FetchEpisodeConsultationHistoryRequest {
    id: string;
}

export interface FetchEpisodeOfCareRequest {
    id: string;
    page?: string;
    memberId?: string;
}

export interface FetchEpisodeOfCareTimelineEventsRequest {
    id: string;
    page?: string;
    order?: string;
}

export interface FetchEpisodeRolesRequest {
    episodeId: string;
}

export interface GetActiveEpisodesPortalRequest {
    id: string;
    yourRole?: string;
}

export interface GetInitialCalculatorAnswersRequest {
    episodeId: string;
    calculatorId: string;
}

export interface ListCalculatorsRequest {
    episodeId: string;
    q?: string;
}

export interface SearchAvailableQuestionSetsForEpisodeRequest {
    episodeId: string;
    q?: string;
}

export interface SearchCommonQuestionsRequest {
    episodeId: string;
    q?: string;
}

export interface StartEpisodeOfCareRequest {
    episodeCreationInput: EpisodeCreationInput;
}

export interface UnarchiveEpisodeRequest {
    episodeId: string;
}

export interface UpdateConsultationRequest {
    id: string;
    updateConsultationRequestBody?: UpdateConsultationRequestBody;
}

export interface UpdateEpisodeOwnerRequest {
    episodeId: string;
    episodeOwnerRequestBody?: EpisodeOwnerRequestBody;
}

export interface UpdateEpisodeRolesRequest {
    episodeId: string;
    updateEpisodeRolesRequestBody?: UpdateEpisodeRolesRequestBody;
}

export interface UpdateQuestionsForEpisodeRequest {
    episodeId: string;
    updateQuestionsForEpisodeRequestBody: UpdateQuestionsForEpisodeRequestBody;
}

export interface UpdateVirtualCareTypeRequest {
    id: string;
    updateVirtualCareTypeRequestBody?: UpdateVirtualCareTypeRequestBody;
}


/**
 * Add a timeline event to an episode
 * Add a timeline event
 */
function addTimelineEventRaw<T>(requestParameters: AddTimelineEventRequest, requestConfig: runtime.TypedQueryConfig<T, TimelineEventOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addTimelineEvent.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{id}/timeline_event`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || AddTimelineEventRequestBodyToJSON(requestParameters.addTimelineEventRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(TimelineEventOutputFromJSON(body), text);
    }

    return config;
}

/**
* Add a timeline event to an episode
* Add a timeline event
*/
export function addTimelineEvent<T>(requestParameters: AddTimelineEventRequest, requestConfig?: runtime.TypedQueryConfig<T, TimelineEventOutput>): QueryConfig<T> {
    return addTimelineEventRaw(requestParameters, requestConfig);
}

/**
 * Archive an episode of care
 * Archive an episode of care
 */
function archiveEpisodeOfCareRaw<T>(requestParameters: ArchiveEpisodeOfCareRequest, requestConfig: runtime.TypedQueryConfig<T, EpisodeOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling archiveEpisodeOfCare.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["ctm.episodes.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{id}/archive`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || ArchiveEpisodeOfCareRequestBodyToJSON(requestParameters.archiveEpisodeOfCareRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EpisodeOutputFromJSON(body), text);
    }

    return config;
}

/**
* Archive an episode of care
* Archive an episode of care
*/
export function archiveEpisodeOfCare<T>(requestParameters: ArchiveEpisodeOfCareRequest, requestConfig?: runtime.TypedQueryConfig<T, EpisodeOutput>): QueryConfig<T> {
    return archiveEpisodeOfCareRaw(requestParameters, requestConfig);
}

/**
 * Create ctm completed calculator for episode
 * Create ctm completed calculator for episode
 */
function createCtmCompletedCalculatorRaw<T>(requestParameters: CreateCtmCompletedCalculatorRequest, requestConfig: runtime.TypedQueryConfig<T, ComponentDetailsOutput> = {}): QueryConfig<T> {
    if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
        throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling createCtmCompletedCalculator.');
    }

    if (requestParameters.calculatorId === null || requestParameters.calculatorId === undefined) {
        throw new runtime.RequiredError('calculatorId','Required parameter requestParameters.calculatorId was null or undefined when calling createCtmCompletedCalculator.');
    }

    if (requestParameters.completeCalculatorRequestBody === null || requestParameters.completeCalculatorRequestBody === undefined) {
        throw new runtime.RequiredError('completeCalculatorRequestBody','Required parameter requestParameters.completeCalculatorRequestBody was null or undefined when calling createCtmCompletedCalculator.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{episode_id}/calculators/{calculator_id}/complete`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))).replace(`{${"calculator_id"}}`, encodeURIComponent(String(requestParameters.calculatorId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || CompleteCalculatorRequestBodyToJSON(requestParameters.completeCalculatorRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ComponentDetailsOutputFromJSON(body), text);
    }

    return config;
}

/**
* Create ctm completed calculator for episode
* Create ctm completed calculator for episode
*/
export function createCtmCompletedCalculator<T>(requestParameters: CreateCtmCompletedCalculatorRequest, requestConfig?: runtime.TypedQueryConfig<T, ComponentDetailsOutput>): QueryConfig<T> {
    return createCtmCompletedCalculatorRaw(requestParameters, requestConfig);
}

/**
 * Creates an episode of care from an appointment_id or updates exiting one
 * Creates an episode of care from an appointment_id or updates exiting one
 */
function createOrAssignEpisodeToAnAppointmentRaw<T>(requestParameters: CreateOrAssignEpisodeToAnAppointmentRequest, requestConfig: runtime.TypedQueryConfig<T, AppointmentSuccessfullyAssignedResponse> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `/v1/episodes/create_or_assign_episode_to_an_appointment`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || CreateOrAssignEpisodeToAnAppointmentRequestBodyToJSON(requestParameters.createOrAssignEpisodeToAnAppointmentRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AppointmentSuccessfullyAssignedResponseFromJSON(body), text);
    }

    return config;
}

/**
* Creates an episode of care from an appointment_id or updates exiting one
* Creates an episode of care from an appointment_id or updates exiting one
*/
export function createOrAssignEpisodeToAnAppointment<T>(requestParameters: CreateOrAssignEpisodeToAnAppointmentRequest, requestConfig?: runtime.TypedQueryConfig<T, AppointmentSuccessfullyAssignedResponse>): QueryConfig<T> {
    return createOrAssignEpisodeToAnAppointmentRaw(requestParameters, requestConfig);
}

/**
 * Archived episode info
 * Archived episode info
 */
function fetchArchiveEpisodeOfCareInfoRaw<T>(requestParameters: FetchArchiveEpisodeOfCareInfoRequest, requestConfig: runtime.TypedQueryConfig<T, ArchiveEpisodeOfCareInfoBody> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchArchiveEpisodeOfCareInfo.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["ctm.episodes.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{id}/archive_info`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ArchiveEpisodeOfCareInfoBodyFromJSON(body), text);
    }

    return config;
}

/**
* Archived episode info
* Archived episode info
*/
export function fetchArchiveEpisodeOfCareInfo<T>(requestParameters: FetchArchiveEpisodeOfCareInfoRequest, requestConfig?: runtime.TypedQueryConfig<T, ArchiveEpisodeOfCareInfoBody>): QueryConfig<T> {
    return fetchArchiveEpisodeOfCareInfoRaw(requestParameters, requestConfig);
}

/**
 * Fetch episode consultation history
 */
function fetchEpisodeConsultationHistoryRaw<T>(requestParameters: FetchEpisodeConsultationHistoryRequest, requestConfig: runtime.TypedQueryConfig<T, Array<object>> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchEpisodeConsultationHistory.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{id}/consultation_history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Fetch episode consultation history
*/
export function fetchEpisodeConsultationHistory<T>(requestParameters: FetchEpisodeConsultationHistoryRequest, requestConfig?: runtime.TypedQueryConfig<T, Array<object>>): QueryConfig<T> {
    return fetchEpisodeConsultationHistoryRaw(requestParameters, requestConfig);
}

/**
 * Fetch Episode message subjects
 * Fetch Episode message subjects
 */
function fetchEpisodeMessageSubjectsRaw<T>( requestConfig: runtime.TypedQueryConfig<T, Array<string>> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/episode/message_subjects`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Fetch Episode message subjects
* Fetch Episode message subjects
*/
export function fetchEpisodeMessageSubjects<T>( requestConfig?: runtime.TypedQueryConfig<T, Array<string>>): QueryConfig<T> {
    return fetchEpisodeMessageSubjectsRaw( requestConfig);
}

/**
 * Fetch an episode by ID
 * Fetch an episode of care
 */
function fetchEpisodeOfCareRaw<T>(requestParameters: FetchEpisodeOfCareRequest, requestConfig: runtime.TypedQueryConfig<T, TimelineEpisodeOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchEpisodeOfCare.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.page !== undefined) {
        queryParameters['page'] = requestParameters.page;
    }


    if (requestParameters.memberId !== undefined) {
        queryParameters['member_id'] = requestParameters.memberId;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(TimelineEpisodeOutputFromJSON(body), text);
    }

    return config;
}

/**
* Fetch an episode by ID
* Fetch an episode of care
*/
export function fetchEpisodeOfCare<T>(requestParameters: FetchEpisodeOfCareRequest, requestConfig?: runtime.TypedQueryConfig<T, TimelineEpisodeOutput>): QueryConfig<T> {
    return fetchEpisodeOfCareRaw(requestParameters, requestConfig);
}

/**
 * Fetch an episode Timeline events by episode ID
 * Fetch timeline events for an episode of care
 */
function fetchEpisodeOfCareTimelineEventsRaw<T>(requestParameters: FetchEpisodeOfCareTimelineEventsRequest, requestConfig: runtime.TypedQueryConfig<T, TimelineEpisodeTimelineEventsOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchEpisodeOfCareTimelineEvents.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.page !== undefined) {
        queryParameters['page'] = requestParameters.page;
    }


    if (requestParameters.order !== undefined) {
        queryParameters['order'] = requestParameters.order;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{id}/timeline_events`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(TimelineEpisodeTimelineEventsOutputFromJSON(body), text);
    }

    return config;
}

/**
* Fetch an episode Timeline events by episode ID
* Fetch timeline events for an episode of care
*/
export function fetchEpisodeOfCareTimelineEvents<T>(requestParameters: FetchEpisodeOfCareTimelineEventsRequest, requestConfig?: runtime.TypedQueryConfig<T, TimelineEpisodeTimelineEventsOutput>): QueryConfig<T> {
    return fetchEpisodeOfCareTimelineEventsRaw(requestParameters, requestConfig);
}

/**
 * Fetch roles of an episode
 * Fetch roles of an episode
 */
function fetchEpisodeRolesRaw<T>(requestParameters: FetchEpisodeRolesRequest, requestConfig: runtime.TypedQueryConfig<T, EpisodeRolesOutputSchema> = {}): QueryConfig<T> {
    if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
        throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling fetchEpisodeRoles.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{episode_id}/roles`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EpisodeRolesOutputSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Fetch roles of an episode
* Fetch roles of an episode
*/
export function fetchEpisodeRoles<T>(requestParameters: FetchEpisodeRolesRequest, requestConfig?: runtime.TypedQueryConfig<T, EpisodeRolesOutputSchema>): QueryConfig<T> {
    return fetchEpisodeRolesRaw(requestParameters, requestConfig);
}

/**
 * Get active episodes
 */
function getActiveEpisodesPortalRaw<T>(requestParameters: GetActiveEpisodesPortalRequest, requestConfig: runtime.TypedQueryConfig<T, CTMInfoArrayResponse1> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getActiveEpisodesPortal.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.yourRole !== undefined) {
        queryParameters['your_role'] = requestParameters.yourRole;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "ctm"]];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/active-episodes-for-portal`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CTMInfoArrayResponse1FromJSON(body), text);
    }

    return config;
}

/**
* Get active episodes
*/
export function getActiveEpisodesPortal<T>(requestParameters: GetActiveEpisodesPortalRequest, requestConfig?: runtime.TypedQueryConfig<T, CTMInfoArrayResponse1>): QueryConfig<T> {
    return getActiveEpisodesPortalRaw(requestParameters, requestConfig);
}

/**
 * Get available initial answers for a calculator
 * Get initial answers for an initial calculator
 */
function getInitialCalculatorAnswersRaw<T>(requestParameters: GetInitialCalculatorAnswersRequest, requestConfig: runtime.TypedQueryConfig<T, CalculatorInitialAnswersResponse> = {}): QueryConfig<T> {
    if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
        throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling getInitialCalculatorAnswers.');
    }

    if (requestParameters.calculatorId === null || requestParameters.calculatorId === undefined) {
        throw new runtime.RequiredError('calculatorId','Required parameter requestParameters.calculatorId was null or undefined when calling getInitialCalculatorAnswers.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{episode_id}/calculators/{calculator_id}/initial_answers`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))).replace(`{${"calculator_id"}}`, encodeURIComponent(String(requestParameters.calculatorId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CalculatorInitialAnswersResponseFromJSON(body), text);
    }

    return config;
}

/**
* Get available initial answers for a calculator
* Get initial answers for an initial calculator
*/
export function getInitialCalculatorAnswers<T>(requestParameters: GetInitialCalculatorAnswersRequest, requestConfig?: runtime.TypedQueryConfig<T, CalculatorInitialAnswersResponse>): QueryConfig<T> {
    return getInitialCalculatorAnswersRaw(requestParameters, requestConfig);
}

/**
 * Get calculators by CTM service lines
 * Get available calculators for episode
 */
function listCalculatorsRaw<T>(requestParameters: ListCalculatorsRequest, requestConfig: runtime.TypedQueryConfig<T, ListCalculatorsResponse> = {}): QueryConfig<T> {
    if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
        throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling listCalculators.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.q !== undefined) {
        queryParameters['q'] = requestParameters.q;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{episode_id}/calculators`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ListCalculatorsResponseFromJSON(body), text);
    }

    return config;
}

/**
* Get calculators by CTM service lines
* Get available calculators for episode
*/
export function listCalculators<T>(requestParameters: ListCalculatorsRequest, requestConfig?: runtime.TypedQueryConfig<T, ListCalculatorsResponse>): QueryConfig<T> {
    return listCalculatorsRaw(requestParameters, requestConfig);
}

/**
 * Fetch available question sets by CTM service lines
 * Search available question sets for episode
 */
function searchAvailableQuestionSetsForEpisodeRaw<T>(requestParameters: SearchAvailableQuestionSetsForEpisodeRequest, requestConfig: runtime.TypedQueryConfig<T, XopQuestionSetSearchOutputSchema> = {}): QueryConfig<T> {
    if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
        throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling searchAvailableQuestionSetsForEpisode.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.q !== undefined) {
        queryParameters['q'] = requestParameters.q;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{episode_id}/question_sets`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(XopQuestionSetSearchOutputSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Fetch available question sets by CTM service lines
* Search available question sets for episode
*/
export function searchAvailableQuestionSetsForEpisode<T>(requestParameters: SearchAvailableQuestionSetsForEpisodeRequest, requestConfig?: runtime.TypedQueryConfig<T, XopQuestionSetSearchOutputSchema>): QueryConfig<T> {
    return searchAvailableQuestionSetsForEpisodeRaw(requestParameters, requestConfig);
}

/**
 * Fetch common questions by query, filtered by sex
 * Search available common questions for episode
 */
function searchCommonQuestionsRaw<T>(requestParameters: SearchCommonQuestionsRequest, requestConfig: runtime.TypedQueryConfig<T, XopQuestionSearchOutputSchema> = {}): QueryConfig<T> {
    if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
        throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling searchCommonQuestions.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.q !== undefined) {
        queryParameters['q'] = requestParameters.q;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{episode_id}/common_questions`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(XopQuestionSearchOutputSchemaFromJSON(body), text);
    }

    return config;
}

/**
* Fetch common questions by query, filtered by sex
* Search available common questions for episode
*/
export function searchCommonQuestions<T>(requestParameters: SearchCommonQuestionsRequest, requestConfig?: runtime.TypedQueryConfig<T, XopQuestionSearchOutputSchema>): QueryConfig<T> {
    return searchCommonQuestionsRaw(requestParameters, requestConfig);
}

/**
 * Start an episode of care
 * Start an episode of care
 */
function startEpisodeOfCareRaw<T>(requestParameters: StartEpisodeOfCareRequest, requestConfig: runtime.TypedQueryConfig<T, EpisodeOutput> = {}): QueryConfig<T> {
    if (requestParameters.episodeCreationInput === null || requestParameters.episodeCreationInput === undefined) {
        throw new runtime.RequiredError('episodeCreationInput','Required parameter requestParameters.episodeCreationInput was null or undefined when calling startEpisodeOfCare.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.create"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || EpisodeCreationInputToJSON(requestParameters.episodeCreationInput),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EpisodeOutputFromJSON(body), text);
    }

    return config;
}

/**
* Start an episode of care
* Start an episode of care
*/
export function startEpisodeOfCare<T>(requestParameters: StartEpisodeOfCareRequest, requestConfig?: runtime.TypedQueryConfig<T, EpisodeOutput>): QueryConfig<T> {
    return startEpisodeOfCareRaw(requestParameters, requestConfig);
}

/**
 * Update an archived episode\'s status to active
 * Update an archived episode to be unarchived
 */
function unarchiveEpisodeRaw<T>(requestParameters: UnarchiveEpisodeRequest, requestConfig: runtime.TypedQueryConfig<T, EpisodeOutput> = {}): QueryConfig<T> {
    if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
        throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling unarchiveEpisode.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["ctm.episodes.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{episode_id}/unarchive`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EpisodeOutputFromJSON(body), text);
    }

    return config;
}

/**
* Update an archived episode\'s status to active
* Update an archived episode to be unarchived
*/
export function unarchiveEpisode<T>(requestParameters: UnarchiveEpisodeRequest, requestConfig?: runtime.TypedQueryConfig<T, EpisodeOutput>): QueryConfig<T> {
    return unarchiveEpisodeRaw(requestParameters, requestConfig);
}

/**
 * Update consultation flag for an episode of care
 * Update consultation flag for an episode of care
 */
function updateConsultationRaw<T>(requestParameters: UpdateConsultationRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateConsultation.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["ctm.episodes.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{id}/update_consultation`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || UpdateConsultationRequestBodyToJSON(requestParameters.updateConsultationRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Update consultation flag for an episode of care
* Update consultation flag for an episode of care
*/
export function updateConsultation<T>(requestParameters: UpdateConsultationRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updateConsultationRaw(requestParameters, requestConfig);
}

/**
 * Update owner assigned to an episode
 * Update owner of an episode
 */
function updateEpisodeOwnerRaw<T>(requestParameters: UpdateEpisodeOwnerRequest, requestConfig: runtime.TypedQueryConfig<T, EpisodeSummary> = {}): QueryConfig<T> {
    if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
        throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling updateEpisodeOwner.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.write", "ctm"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{episode_id}/owner`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || EpisodeOwnerRequestBodyToJSON(requestParameters.episodeOwnerRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EpisodeSummaryFromJSON(body), text);
    }

    return config;
}

/**
* Update owner assigned to an episode
* Update owner of an episode
*/
export function updateEpisodeOwner<T>(requestParameters: UpdateEpisodeOwnerRequest, requestConfig?: runtime.TypedQueryConfig<T, EpisodeSummary>): QueryConfig<T> {
    return updateEpisodeOwnerRaw(requestParameters, requestConfig);
}

/**
 * Update roles assigned to an episode
 * Update roles of an episode
 */
function updateEpisodeRolesRaw<T>(requestParameters: UpdateEpisodeRolesRequest, requestConfig: runtime.TypedQueryConfig<T, EpisodesRolesUpdatedResponse> = {}): QueryConfig<T> {
    if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
        throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling updateEpisodeRoles.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{episode_id}/roles`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || UpdateEpisodeRolesRequestBodyToJSON(requestParameters.updateEpisodeRolesRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EpisodesRolesUpdatedResponseFromJSON(body), text);
    }

    return config;
}

/**
* Update roles assigned to an episode
* Update roles of an episode
*/
export function updateEpisodeRoles<T>(requestParameters: UpdateEpisodeRolesRequest, requestConfig?: runtime.TypedQueryConfig<T, EpisodesRolesUpdatedResponse>): QueryConfig<T> {
    return updateEpisodeRolesRaw(requestParameters, requestConfig);
}

/**
 * Update questions collection based on changed source question sets
 * Update questions collection
 */
function updateQuestionsForEpisodeRaw<T>(requestParameters: UpdateQuestionsForEpisodeRequest, requestConfig: runtime.TypedQueryConfig<T, UpdateQuestionsForEpisodeResponse> = {}): QueryConfig<T> {
    if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
        throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling updateQuestionsForEpisode.');
    }

    if (requestParameters.updateQuestionsForEpisodeRequestBody === null || requestParameters.updateQuestionsForEpisodeRequestBody === undefined) {
        throw new runtime.RequiredError('updateQuestionsForEpisodeRequestBody','Required parameter requestParameters.updateQuestionsForEpisodeRequestBody was null or undefined when calling updateQuestionsForEpisode.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{episode_id}/questions/update`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || UpdateQuestionsForEpisodeRequestBodyToJSON(requestParameters.updateQuestionsForEpisodeRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(UpdateQuestionsForEpisodeResponseFromJSON(body), text);
    }

    return config;
}

/**
* Update questions collection based on changed source question sets
* Update questions collection
*/
export function updateQuestionsForEpisode<T>(requestParameters: UpdateQuestionsForEpisodeRequest, requestConfig?: runtime.TypedQueryConfig<T, UpdateQuestionsForEpisodeResponse>): QueryConfig<T> {
    return updateQuestionsForEpisodeRaw(requestParameters, requestConfig);
}

/**
 * Update virtual_care_type for an episode of care
 * Update virtual_care_type for an episode of care
 */
function updateVirtualCareTypeRaw<T>(requestParameters: UpdateVirtualCareTypeRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateVirtualCareType.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["ctm.episodes.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{id}/virtual_urgent_care_history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || UpdateVirtualCareTypeRequestBodyToJSON(requestParameters.updateVirtualCareTypeRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Update virtual_care_type for an episode of care
* Update virtual_care_type for an episode of care
*/
export function updateVirtualCareType<T>(requestParameters: UpdateVirtualCareTypeRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return updateVirtualCareTypeRaw(requestParameters, requestConfig);
}

