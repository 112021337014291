// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     CTMInfoProps,
    CTMInfoPropsFromJSON,
    CTMInfoPropsToJSON,
} from './CTMInfoProps';
import {
     CompactUserSchema,
    CompactUserSchemaFromJSON,
    CompactUserSchemaToJSON,
} from './CompactUserSchema';
/**
 * @type CTMInfo
 * @export
 */
export interface CTMInfo extends CTMInfoProps, CompactUserSchema {
}

export function CTMInfoFromJSON(json: any): CTMInfo {
    return {
        ...CTMInfoPropsFromJSON(json),
        ...CompactUserSchemaFromJSON(json),
    };
}

export function CTMInfoToJSON(value?: CTMInfo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...CTMInfoPropsToJSON(value),
        ...CompactUserSchemaToJSON(value),
    };
}

