import React, { FunctionComponent } from "react";
import AlertComponent, { AlertProps } from '@mui/material/Alert';
import SuccessIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import { useTheme, Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const warningIcon = (iconColor?: string) => {
  const { palette } = useTheme<Theme>();
  return (
    <ErrorIcon
      htmlColor={iconColor ? iconColor : palette?.appBackground?.medGrey}
    />
  );
};
const successIcon = () => {
  const { palette } = useTheme<Theme>();
  return <SuccessIcon htmlColor={palette?.appBackground?.medGrey} />;
};
const errorIcon = () => {
  const { palette } = useTheme<Theme>();
  return <WarningIcon htmlColor={palette?.appBackground?.medGrey} />;
};
const infoIcon = () => {
  const { palette } = useTheme<Theme>();
  return <InfoIcon htmlColor={palette?.appBackground?.medGrey} />;
};

export type AlertSeverity = "error" | "warning" | "success" | "info";

interface IProps extends AlertProps {
  severity?: AlertSeverity;
  iconColor?: string;
}

const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    root: {
      "& ul": {
        paddingLeft: spacing(2),
      },
      "&:focus": {
        border: `2px solid ${palette?.links?.primaryHover}`,
      },
      "&:focus-visible": {
        border: `2px solid ${palette?.links?.primaryHover}`,
      },
    },
    message: {
      display: "inline-flex",
      alignItems: "center",
    },
    icon: {
      marginTop: "auto",
      marginBottom: "auto",
    },
    standardWarning: {
      backgroundColor: "#FFE9C9",
    },
  })
);

const Alert: FunctionComponent<IProps> = ({ iconColor, ...props }) => {
  const classes = useStyles();

  return (
    <AlertComponent
      classes={classes}
      tabIndex={0}
      iconMapping={{
        error: errorIcon(),
        info: infoIcon(),
        success: successIcon(),
        warning: warningIcon(iconColor),
      }}
      {...props}
    />
  );
};

export default Alert;
