import { ReactElement } from "react";
export const SHOW_CONFIRMATIONS_DIALOG = "SHOW_CONFIRMATIONS_DIALOG";
export const HIDE_CONFIRMATIONS_DIALOG = "HIDE_CONFIRMATIONS_DIALOG";
export const SET_CONFIRMATIONS_DIALOG_OVERRIDES =
  "SET_CONFIRMATIONS_DIALOG_OVERRIDES";
export const RESET_CONFIRMATIONS_DIALOG_OVERRIDES =
  "RESET_CONFIRMATIONS_DIALOG_OVERRIDES";

export const confirmationsDialog = (
  state: State = getInitialConfirmationsDialogState(),
  action: any
): State => {
  if (action && action.type) {
    const { type } = action;
    switch (type) {
      case SHOW_CONFIRMATIONS_DIALOG:
        return Object.assign({}, state, {
          ...action?.payload,
          overrides: { ...state.overrides, ...action?.payload?.overrides },
          open: true,
        });

      case HIDE_CONFIRMATIONS_DIALOG:
        return {
          ...state,
          open: false,
        };

      case SET_CONFIRMATIONS_DIALOG_OVERRIDES:
        return Object.assign({}, state, {
          overrides: { ...state.overrides, ...action?.payload },
        });

      case RESET_CONFIRMATIONS_DIALOG_OVERRIDES:
        return Object.assign({}, state, {
          overrides: null,
        });

      default:
        return state;
    }
  }
  return state;
};

export const displayConfirmationsModal = (payload: IProps) => {
  return {
    type: SHOW_CONFIRMATIONS_DIALOG,
    payload,
  };
};

export const hideConfirmationsModal = () => {
  return {
    type: HIDE_CONFIRMATIONS_DIALOG,
  };
};

export const setConfirmationsModalOverrides = (overrides: Overrides) => {
  return {
    type: SET_CONFIRMATIONS_DIALOG_OVERRIDES,
    payload: overrides,
  };
};

export const resetConfirmationsModalOverrides = () => {
  return {
    type: RESET_CONFIRMATIONS_DIALOG_OVERRIDES,
  };
};

interface Overrides {
  title?: ReactElement | string;
  content?: ReactElement | string;
  maxWidth?: any;
  confirmButtonLabel?: any;
  cancelButtonLabel?: any;
  className?: string;
  appearance?: string;
  extraContent?: ReactElement | string;
}

export interface State {
  open: boolean;
  hasOnCancelLabel?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  overrides?: Overrides;
}

export const getInitialConfirmationsDialogState = (): State => {
  return {
    open: false,
    hasOnCancelLabel: true,
  };
};

interface IProps {
  hasOnCancelLabel?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  overrides?: Overrides;
}
