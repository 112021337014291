// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDeviceRequestBody
 */
export interface UpdateDeviceRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceRequestBody
     */
    devicePushToken?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceRequestBody
     */
    operatingSystemName?: UpdateDeviceRequestBodyOperatingSystemNameEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceRequestBody
     */
    publicKey?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceRequestBody
     */
    deviceName?: string;
}

export function UpdateDeviceRequestBodyFromJSON(json: any): UpdateDeviceRequestBody {
    return {
        'devicePushToken': !exists(json, 'device_push_token') ? undefined : json['device_push_token'],
        'operatingSystemName': !exists(json, 'operating_system_name') ? undefined : json['operating_system_name'],
        'publicKey': !exists(json, 'public_key') ? undefined : json['public_key'],
        'deviceName': !exists(json, 'device_name') ? undefined : json['device_name'],
    };
}

export function UpdateDeviceRequestBodyToJSON(value?: UpdateDeviceRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'device_push_token': value.devicePushToken,
        'operating_system_name': value.operatingSystemName,
        'public_key': value.publicKey,
        'device_name': value.deviceName,
    };
}

/**
* @export
* @enum {string}
*/
export enum UpdateDeviceRequestBodyOperatingSystemNameEnum {
    Android = 'android',
    Ios = 'ios',
    Windows = 'windows'
}


