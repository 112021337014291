import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "react-query";
import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";
import {
  DeleteAppointmentNoteRequest,
  fetchAppointmentNotesApi,
} from "shared/fetch/src/apis/AppointmentNotesApi";
import { getFetchSlotQueryKey } from "../xocal/useGetSlot";

type DeleteNoteMutationFn = (
  requestParameters: DeleteAppointmentNoteRequest
) => Promise<void>;

const useDeleteVisitNote = (
  config?: UseMutationOptions<void, Error, DeleteAppointmentNoteRequest>
): UseMutationResult<void, Error, DeleteAppointmentNoteRequest> => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const mutationFn: DeleteNoteMutationFn = (
    requestParameters: DeleteAppointmentNoteRequest
  ) => {
    return fetchAppointmentNotesApi.deleteAppointmentNote(
      requestParameters
    ) as Promise<void>;
  };

  const defaultConfig: UseMutationOptions<
    void,
    Error,
    DeleteAppointmentNoteRequest
  > = {
    onSuccess: () => {
      dispatch(showSnackbar("Success! The visit note has been deleted."));
      queryClient.invalidateQueries(getFetchSlotQueryKey({}));
    },
    onError: () => {
      dispatch(showSnackbar("Failed to delete the note.", "danger"));
    },
    ...config,
  };

  return useMutation<void, Error, DeleteAppointmentNoteRequest>(
    mutationFn,
    defaultConfig
  );
};

export default useDeleteVisitNote;
