// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CTMBioProfileSection,
    CTMBioProfileSectionFromJSON,
    CTMBioProfileSectionToJSON,
} from './CTMBioProfileSection';


/**
 * 
 * @export
 * @interface CTMBioProfile
 */
export interface CTMBioProfile  {
    /**
     * 
     * @type {string}
     * @memberof CTMBioProfile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CTMBioProfile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CTMBioProfile
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof CTMBioProfile
     */
    avatarUrl?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CTMBioProfile
     */
    guilds: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CTMBioProfile
     */
    careTeams?: Array<string>;
    /**
     * 
     * @type {Array<CTMBioProfileSection>}
     * @memberof CTMBioProfile
     */
    profileSections: Array<CTMBioProfileSection>;
}

export function CTMBioProfileFromJSON(json: any): CTMBioProfile {
    return {
        'id': json['id'],
        'name': json['name'],
        'legalName': !exists(json, 'legal_name') ? undefined : json['legal_name'],
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'guilds': json['guilds'],
        'careTeams': !exists(json, 'care_teams') ? undefined : json['care_teams'],
        'profileSections': (json['profile_sections'] as Array<any>).map(CTMBioProfileSectionFromJSON),
    };
}

export function CTMBioProfileToJSON(value?: CTMBioProfile): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'legal_name': value.legalName,
        'avatar_url': value.avatarUrl,
        'guilds': value.guilds,
        'care_teams': value.careTeams,
        'profile_sections': (value.profileSections as Array<any>).map(CTMBioProfileSectionToJSON),
    };
}


