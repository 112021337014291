import React, { FunctionComponent } from "react";
import { BadgeIconProps } from ".";

const IneligibleMemberBadgeIcon: FunctionComponent<BadgeIconProps> = (
  props
) => {
  const { size } = props;
  const svgSize = Number(size);
  return (
    <svg
      width={svgSize}
      height={svgSize}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16" cy="16" r="16" fill="#6B7480" stroke="white" />
      <path
        d="M13.3333 9.0404C14.0505 7.92929 15.303 7.20202 16.7172 7.20202C18.9495 7.20202 20.7576 9.0101 20.7576 11.2424C20.7576 12.6566 20.0303 13.9091 18.9192 14.6263L13.3333 9.0404ZM24.798 20.5051C24.7778 19.3939 24.1616 18.3737 23.1717 17.8586C22.6263 17.5758 22.0303 17.3131 21.3838 17.0909L24.798 20.5051ZM26 24.5657L7.43434 6L6 7.42424L14.9798 16.404C13.1515 16.6364 11.5556 17.202 10.2626 17.8687C9.25253 18.3838 8.63636 19.4242 8.63636 20.5556V23.3636H21.9394L24.5758 26L26 24.5657Z"
        fill="white"
      />
    </svg>
  );
};

export default IneligibleMemberBadgeIcon;
