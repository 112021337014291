import React, { FunctionComponent } from "react";
import { Autocomplete as AutocompleteComponent } from '@mui/material';
import { AutocompleteProps } from '@mui/material/Autocomplete';

const Autocomplete: FunctionComponent<
  AutocompleteProps<any, boolean, boolean, boolean>
> = (props) => {
  const { options, ...rest } = props;
  return <AutocompleteComponent options={options || []} {...rest} />;
};

export default Autocomplete;
