// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCenterAndPcpRequestBody
 */
export interface UpdateCenterAndPcpRequestBody  {
    /**
     * ID of new core pod
     * @type {string}
     * @memberof UpdateCenterAndPcpRequestBody
     */
    corePodId: string;
    /**
     * staff_global_id of new PCP
     * @type {string}
     * @memberof UpdateCenterAndPcpRequestBody
     */
    ctmGlobalId?: string;
    /**
     * Reason for declining Crossover PCP
     * @type {string}
     * @memberof UpdateCenterAndPcpRequestBody
     */
    pcpDeclineReason?: UpdateCenterAndPcpRequestBodyPcpDeclineReasonEnum;
}

export function UpdateCenterAndPcpRequestBodyFromJSON(json: any): UpdateCenterAndPcpRequestBody {
    return {
        'corePodId': json['core_pod_id'],
        'ctmGlobalId': !exists(json, 'ctm_global_id') ? undefined : json['ctm_global_id'],
        'pcpDeclineReason': !exists(json, 'pcp_decline_reason') ? undefined : json['pcp_decline_reason'],
    };
}

export function UpdateCenterAndPcpRequestBodyToJSON(value?: UpdateCenterAndPcpRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'core_pod_id': value.corePodId,
        'ctm_global_id': value.ctmGlobalId,
        'pcp_decline_reason': value.pcpDeclineReason,
    };
}

/**
* @export
* @enum {string}
*/
export enum UpdateCenterAndPcpRequestBodyPcpDeclineReasonEnum {
    CommunityProvider = 'dr-community-provider',
    Declined = 'dr-declined'
}


