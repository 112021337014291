import palette from "../palette";

export default {
  root: {
    "&.Mui-disabled": {
      color: palette.text.secondary,
    },
    "&.MuiFormLabel-root.Mui-focused": {
      color: palette.text.link,
    },
    "&.MuiFormLabel-root.Mui-error": {
      color: `${palette.error?.main} !important`,
    },
  },
};
