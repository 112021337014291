// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';
import {
    CompactComponentReference,
    CompactComponentReferenceFromJSON,
    CompactComponentReferenceToJSON,
} from './CompactComponentReference';
import {
    ComponentDetailsCoreCreatorInfo,
    ComponentDetailsCoreCreatorInfoFromJSON,
    ComponentDetailsCoreCreatorInfoToJSON,
} from './ComponentDetailsCoreCreatorInfo';
import {
    ComponentDetailsCoreResolverInfo,
    ComponentDetailsCoreResolverInfoFromJSON,
    ComponentDetailsCoreResolverInfoToJSON,
} from './ComponentDetailsCoreResolverInfo';
import {
    DocumentObjectDetails,
    DocumentObjectDetailsFromJSON,
    DocumentObjectDetailsToJSON,
} from './DocumentObjectDetails';
import {
    OutputWorkspaceDraftSchema,
    OutputWorkspaceDraftSchemaFromJSON,
    OutputWorkspaceDraftSchemaToJSON,
} from './OutputWorkspaceDraftSchema';


/**
 * 
 * @export
 * @interface ComponentDetailsCore
 */
export interface ComponentDetailsCore  {
    /**
     * 
     * @type {string}
     * @memberof ComponentDetailsCore
     */
    body?: string;
    /**
     * 
     * @type {Array<DocumentObjectDetails>}
     * @memberof ComponentDetailsCore
     */
    documents?: Array<DocumentObjectDetails>;
    /**
     * 
     * @type {ComponentDetailsCoreCreatorInfo}
     * @memberof ComponentDetailsCore
     */
    creatorInfo?: ComponentDetailsCoreCreatorInfo;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetailsCore
     */
    resourceUrl?: string;
    /**
     * 
     * @type {Array<ActionableItemSummary>}
     * @memberof ComponentDetailsCore
     */
    actionableItems?: Array<ActionableItemSummary>;
    /**
     * 
     * @type {OutputWorkspaceDraftSchema}
     * @memberof ComponentDetailsCore
     */
    workspaceDraft?: OutputWorkspaceDraftSchema;
    /**
     * 
     * @type {ComponentDetailsCoreResolverInfo}
     * @memberof ComponentDetailsCore
     */
    resolverInfo?: ComponentDetailsCoreResolverInfo;
    /**
     * 
     * @type {Array<CompactComponentReference>}
     * @memberof ComponentDetailsCore
     */
    childComponents?: Array<CompactComponentReference>;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetailsCore
     */
    baseSiblingId?: string;
}

export function ComponentDetailsCoreFromJSON(json: any): ComponentDetailsCore {
    return {
        'body': !exists(json, 'body') ? undefined : json['body'],
        'documents': !exists(json, 'documents') ? undefined : (json['documents'] as Array<any>).map(DocumentObjectDetailsFromJSON),
        'creatorInfo': !exists(json, 'creator_info') ? undefined : ComponentDetailsCoreCreatorInfoFromJSON(json['creator_info']),
        'resourceUrl': !exists(json, 'resource_url') ? undefined : json['resource_url'],
        'actionableItems': !exists(json, 'actionable_items') ? undefined : (json['actionable_items'] as Array<any>).map(ActionableItemSummaryFromJSON),
        'workspaceDraft': !exists(json, 'workspace_draft') ? undefined : OutputWorkspaceDraftSchemaFromJSON(json['workspace_draft']),
        'resolverInfo': !exists(json, 'resolver_info') ? undefined : ComponentDetailsCoreResolverInfoFromJSON(json['resolver_info']),
        'childComponents': !exists(json, 'child_components') ? undefined : (json['child_components'] as Array<any>).map(CompactComponentReferenceFromJSON),
        'baseSiblingId': !exists(json, 'base_sibling_id') ? undefined : json['base_sibling_id'],
    };
}

export function ComponentDetailsCoreToJSON(value?: ComponentDetailsCore): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'body': value.body,
        'documents': value.documents === undefined ? undefined : (value.documents as Array<any>).map(DocumentObjectDetailsToJSON),
        'creator_info': ComponentDetailsCoreCreatorInfoToJSON(value.creatorInfo),
        'resource_url': value.resourceUrl,
        'actionable_items': value.actionableItems === undefined ? undefined : (value.actionableItems as Array<any>).map(ActionableItemSummaryToJSON),
        'workspace_draft': OutputWorkspaceDraftSchemaToJSON(value.workspaceDraft),
        'resolver_info': ComponentDetailsCoreResolverInfoToJSON(value.resolverInfo),
        'child_components': value.childComponents === undefined ? undefined : (value.childComponents as Array<any>).map(CompactComponentReferenceToJSON),
        'base_sibling_id': value.baseSiblingId,
    };
}


