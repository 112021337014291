// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccessPlan
 */
export interface AccessPlan  {
    /**
     * 
     * @type {string}
     * @memberof AccessPlan
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AccessPlan
     */
    planType?: string;
}

export function AccessPlanFromJSON(json: any): AccessPlan {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'planType': !exists(json, 'plan_type') ? undefined : json['plan_type'],
    };
}

export function AccessPlanToJSON(value?: AccessPlan): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'plan_type': value.planType,
    };
}


