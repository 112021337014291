// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     DisplayPropertiesSchema,
    DisplayPropertiesSchemaFromJSON,
    DisplayPropertiesSchemaToJSON,
} from './DisplayPropertiesSchema';
import {
     FiltersSchema,
    FiltersSchemaFromJSON,
    FiltersSchemaToJSON,
} from './FiltersSchema';
import {
     InputSubTypeSchemaProps,
    InputSubTypeSchemaPropsFromJSON,
    InputSubTypeSchemaPropsToJSON,
} from './InputSubTypeSchemaProps';
/**
 * @type InputSubTypeSchema
 * @export
 */
export interface InputSubTypeSchema extends DisplayPropertiesSchema, FiltersSchema, InputSubTypeSchemaProps {
}

export function InputSubTypeSchemaFromJSON(json: any): InputSubTypeSchema {
    return {
        ...DisplayPropertiesSchemaFromJSON(json),
        ...FiltersSchemaFromJSON(json),
        ...InputSubTypeSchemaPropsFromJSON(json),
    };
}

export function InputSubTypeSchemaToJSON(value?: InputSubTypeSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...DisplayPropertiesSchemaToJSON(value),
        ...FiltersSchemaToJSON(value),
        ...InputSubTypeSchemaPropsToJSON(value),
    };
}

