import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from "redux";
import { RequestAsyncAction, MutateAsyncAction } from "redux-query";
import { State } from "shared/types/state";
import ovrTimelineEvent from "shared/stubs/ovrTimelineEvent";
import questionSetTimelineEvent from "shared/stubs/questionSetTimelineEvent";
import referralAi from "shared/stubs/referralChipData";

export const devMiddleware: Middleware = (
  _api: MiddlewareAPI<Dispatch<AnyAction>, State>
) => (next: Dispatch) => (
  action: AnyAction | RequestAsyncAction | MutateAsyncAction
) => {
  const anyAction = action as any;
  const { url } = anyAction;

  // change this line out to some specifier of what action you want to change, ie
  // `action.hasOwnProperty("entities")` to modify that entry before it is stored
  const modificationCondition =
    anyAction?.entities?.episode?.timelineEvents?.length ||
    anyAction?.entities?.timelineEvent ||
    anyAction?.entities?.component;

  const isDev = !!process.env.REACT_APP_FF_MM;
  const isAIEnabled = !!process.env.REACT_APP_FF_AIS;
  const isWorkSpaceDraftEndpoint = url?.includes(
    "/v1/episodes/1/workspace_draft"
  );
  if (
    isAIEnabled &&
    action.type === "@@query/REQUEST_SUCCESS" &&
    isWorkSpaceDraftEndpoint
  ) {
    anyAction?.entities?.draft?.unacknowledgedComponents.push(referralAi); //  eslint-disable-line
  }

  if (
    action.type === "@@query/REQUEST_SUCCESS" &&
    modificationCondition &&
    isDev
  ) {
    const isEpisodeEndpoint = url.includes("/v1/episodes");
    const isTimelineEventEndpoint = url.includes("/v1/timeline_events");

    // currently using this action to slide a fake "timelineEvent" into the store
    // so that our stubbed data has a proper id to pull from

    if (
      anyAction?.entities?.episode?.timelineEvents?.length ||
      anyAction?.entities?.timelineEvent
    ) {
      const timelineEvent =
        anyAction?.entities?.episode?.timelineEvents?.[0] ||
        anyAction?.entities?.timelineEvent;
      const newId = "1000000";

      const devTimelineEvent = JSON.parse(
        JSON.stringify(
          Object.assign({}, timelineEvent, {
            eventType: "message_bundle",
            id: newId,
            addedAt: new Date().toISOString,
            createdBy: {
              id: "11",
              dob: "1980-01-01",
              type: "ctm",
              avatarUrl: "/images/image-placeholder.jpg",
              name: "Mike Demo, MD",
              guilds: ["Primary Care"],
            },
          })
        )
      );

      // get our diagnosis component stubbed
      devTimelineEvent.components.push(
        Object.assign({}, devTimelineEvent.components[0], {
          type: "diagnosis",
          id: newId + 1,
        })
      );

      // get our visit component stubbed
      devTimelineEvent.components.push(
        Object.assign({}, devTimelineEvent.components[0], {
          type: "visit",
          id: newId + 2,
        })
      );

      // get our visit component stubbed
      devTimelineEvent.components.push(
        Object.assign({}, devTimelineEvent.components[0], {
          type: "question_set",
          id: newId + 3,
        })
      );

      if (isEpisodeEndpoint) {
        anyAction.entities.episode.timelineEvents.unshift(devTimelineEvent);
        anyAction.entities.episode.timelineEvents.unshift(ovrTimelineEvent);
        anyAction.entities.episode.timelineEvents.unshift(
          questionSetTimelineEvent
        );
      }

      if (isTimelineEventEndpoint) {
        anyAction.entities.timelineEvent = devTimelineEvent;
      }
    }
  }

  return next(action);
};
