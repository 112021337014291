import palette from "../palette";

export default {
  root: {
    transition: "color 0.15s ease-in-out",
  },
  underlineAlways: {
    "&:hover": {
      textDecoration: "underline",
    },
  },
  textPrimary: {
    color: palette.links.primary,
  },
  underlineHover: {
    "&:hover": {
      textDecoration: "underline",
    },
    "&.MuiTypography-colorTextSecondary:hover": {
      color: palette.primary.dark,
    },
  },
};
