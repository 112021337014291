export default {
  id: "23491",
  episodeId: "33",
  eventType: "virtual_visit_referral",
  addedAt: "2019-09-27T14:43:26Z",
  viewedAt: null,
  actionsResolvedAt: null,
  createdBy: {
    id: "23",
    legalName: "Dr. Jane Doe",
    type: "ctm",
    avatarUrl:
      "https://upload.wikimedia.org/wikipedia/en/thumb/3/3b/SpongeBob_SquarePants_character.svg/1200px-SpongeBob_SquarePants_character.svg.png",
  },
  components: [
    {
      id: "124",
      timelineEventId: "321",
      episodeId: "33",
      type: "ctm_message",
      body: "Why, hello there.",
      addedAt: "2019-09-27T15:43:26Z",
      resolvedAt: "2019-09-27T15:43:26Z",
      viewedAt: "2019-09-27T15:43:26Z",
      responseRequired: true,
      viewer: {
        id: 22,
        name: "Jane Test",
        type: "member",
      },
      documentInfo: [],
    },
    {
      id: "125",
      state: "scheduled",
      timelineEventId: "321",
      episodeId: "12",
      type: "virtual_visit_referral",
      addedAt: "2019-09-27T16:43:26Z",
      resolvedAt: "2019-09-27T16:43:26Z",
      viewedAt: null,
      viewer: null,
      documentInfo: [],
      visitType: {
        id: "1",
        method: "video",
        name: "video",
      },
      body: "",
      appointment: {
        declineReason: "I hate going to the doctor.",
        visitType: "video",
        duration: 30,
        disposition: null,
        visitLink: "https://lmgtfy.com/?q=how+to+schedule+a+zoom+meeting",
        startAt: "2020-09-27T16:43:26Z",
        endAt: "2020-09-27T17:13:26Z",
        provider: {
          id: "23",
          name: "Dr. Jane Doe",
          type: "ctm",
        },
      },
    },
  ],
};
