import clsx from "clsx";
import React from "react";
import Alert from "components/Alert";
import Typography from "components/Typography";
import useLoadHTMLContent from "hooks/useLoadHTMLContent";
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from "components/Progress/CircularProgress";

const useStyles = makeStyles(({ palette, typography, spacing }: Theme) => ({
  wrapper: {
    width: "100%",

    "& h2": typography.h2,
    "& h3": typography.h3,
    "& h4": typography.h4,
    "& p": {
      marginBottom: spacing(1),
      color: palette.text.secondary,
      ...typography.body1,
    },
    "& li": {
      color: palette.text.secondary,
      ...typography.body1,
    },
    "& hr": {
      border: "none",
      borderTopWidth: 1,
      borderTopStyle: "solid",
      borderTopColor: palette.strokes?.borderLight,
    },
  },
  loader: {
    gridGap: 16,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

interface IHTMLViewerProps {
  url: string;
  className?: string;
  errorMessage?: string;
  loadingMessage?: string;
}

const HTMLViewer = ({
  url,
  className,
  errorMessage = "Failed to load",
  loadingMessage = "Loading...",
}: IHTMLViewerProps) => {
  const classes = useStyles();
  const { htmlContent, hasError, isLoading } = useLoadHTMLContent({
    link: url,
  });

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <CircularProgress />
        <Typography color="textSecondary">{loadingMessage}</Typography>
      </div>
    );
  }

  if (hasError) {
    return <Alert severity="error">{errorMessage}</Alert>;
  }

  return (
    <div
      className={clsx(className, classes.wrapper)}
      dangerouslySetInnerHTML={{ __html: htmlContent! }}
    />
  );
};

export default HTMLViewer;
