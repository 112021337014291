// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface XopQuestionDetailSchema
 */
export interface XopQuestionDetailSchema  {
    /**
     * 
     * @type {string}
     * @memberof XopQuestionDetailSchema
     */
    answer?: string;
    /**
     * 
     * @type {string}
     * @memberof XopQuestionDetailSchema
     */
    qsAbbreviation?: string;
    /**
     * 
     * @type {string}
     * @memberof XopQuestionDetailSchema
     */
    sourceQuestionSetId?: string;
    /**
     * 
     * @type {number}
     * @memberof XopQuestionDetailSchema
     */
    questionSetVersion?: number;
}

export function XopQuestionDetailSchemaFromJSON(json: any): XopQuestionDetailSchema {
    return {
        'answer': !exists(json, 'answer') ? undefined : json['answer'],
        'qsAbbreviation': !exists(json, 'qs_abbreviation') ? undefined : json['qs_abbreviation'],
        'sourceQuestionSetId': !exists(json, 'source_question_set_id') ? undefined : json['source_question_set_id'],
        'questionSetVersion': !exists(json, 'question_set_version') ? undefined : json['question_set_version'],
    };
}

export function XopQuestionDetailSchemaToJSON(value?: XopQuestionDetailSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'answer': value.answer,
        'qs_abbreviation': value.qsAbbreviation,
        'source_question_set_id': value.sourceQuestionSetId,
        'question_set_version': value.questionSetVersion,
    };
}


