// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkspaceDraftDataSchemaItem,
    WorkspaceDraftDataSchemaItemFromJSON,
    WorkspaceDraftDataSchemaItemToJSON,
} from './WorkspaceDraftDataSchemaItem';


/**
 * 
 * @export
 * @interface WorkspaceDraftDataSchema
 */
export interface WorkspaceDraftDataSchema  {
    /**
     * 
     * @type {Array<WorkspaceDraftDataSchemaItem>}
     * @memberof WorkspaceDraftDataSchema
     */
    data?: Array<WorkspaceDraftDataSchemaItem>;
}

export function WorkspaceDraftDataSchemaFromJSON(json: any): WorkspaceDraftDataSchema {
    return {
        'data': !exists(json, 'data') ? undefined : (json['data'] as Array<any>).map(WorkspaceDraftDataSchemaItemFromJSON),
    };
}

export function WorkspaceDraftDataSchemaToJSON(value?: WorkspaceDraftDataSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'data': value.data === undefined ? undefined : (value.data as Array<any>).map(WorkspaceDraftDataSchemaItemToJSON),
    };
}


