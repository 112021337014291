import {
  PDFViewerMode,
  PDFViewerFile,
  IPDFDocumentProxy,
  PDFViewerSideNavigationState,
  PDFViewerControllerResponse,
} from "./utils";
import { State } from "shared/types";
import { useSelector } from "react-redux";
import {
  UIEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useMobileCheck from "hooks/useMobileCheck";
import Print, { PrintableTypesEnum } from "utils/Print";
import "utils/Print/index.scss";

interface PDFViewerControllerProps {
  file: string | File | PDFViewerFile;
  isLargeFile?: boolean;
  viewMode?: PDFViewerMode;
  withAccessToken?: boolean;
  isUploadPreview: boolean;
}

const usePDFViewerController = ({
  file,
  isLargeFile,
  isUploadPreview,
  withAccessToken = false,
  viewMode = PDFViewerMode.VERTICAL,
}: PDFViewerControllerProps): PDFViewerControllerResponse => {
  const isMobile = useMobileCheck();
  const printOrDownloadEnabled = !isUploadPreview && !isMobile;
  // used integers to avoid floating number issue with fractions, i:e 100 = 1
  const [scale, setScale] = useState(100);
  const [pageCount, setPageCount] = useState<number>(0);
  const [thumbnails, setThumbnails] = useState<string[]>([]);
  const [isLoadingPreview, setLoadingPreview] = useState(false);
  const [PDFDocumentProxy, setPDFDocumentProxy] = useState<any>();
  const [activePage, setActivePage] = useState<number>(0);
  const [printTaskInProgress, setPrintTaskInProgress] = useState(false);
  const [rotationAngle, setRotationAngle] = useState<number | undefined>(
    undefined
  );
  const [fullWidthScale, setFullWidthScale] = useState<undefined | number>(
    undefined
  );

  const [pdfHasOutline, setPDFHasOutline] = useState(true);
  const [selectedViewMode, setViewMode] = useState(viewMode);
  const [sideNavigationState, setSideNavigationState] =
    useState<PDFViewerSideNavigationState>(
      PDFViewerSideNavigationState.PREVIEW
    );

  const activeThumbnailRef = useRef<HTMLDivElement>(null);
  const pageCanvasRef = useRef<HTMLCanvasElement>(null);
  const pagesContainerRef = useRef<HTMLDivElement>(null);

  const accessToken = useSelector(
    (state: State) => state.auth.accessToken
  ) as string;

  const headers = {};
  if (withAccessToken) {
    Object.defineProperty(headers, "Authorization", {
      value: `Bearer ${accessToken}`,
      enumerable: true,
    });
  }

  const fileProxy = useMemo(
    () => ({
      url: file as string,
      httpHeaders: headers,
      withCredentials: false,
    }),
    [file, accessToken]
  );

  const print = () => {
    if (printOrDownloadEnabled && !printTaskInProgress) {
      setPrintTaskInProgress(() => true);
      let printable = file as string;
      // the parent component does not re-render every time the access token is refreshed to avoid unnecessary re-fetching & re-rendering
      // thus the access token included in the file url has to be updated manually to avoid unauthorized access error
      if (typeof file === "string" && file.includes("access_token=")) {
        const pathname = new URL(file, location.origin).pathname;
        printable = `${pathname}?access_token=${accessToken}`;
      }
      Print({
        printable,
        showModal: true,
        type: PrintableTypesEnum.pdf,
        onError: () => setPrintTaskInProgress(() => false),
        onPrintDialogClose: () => setPrintTaskInProgress(() => false),
      });
    }
  };

  /**
   * get page default height/width to compute fit_page and fit_width scale factors
   * page_fit: whole page is visible without vertical/horizontal scrolling
   * page_width: page scales to fill whole available width
   * note: scale factors should be expressed in percentages not fractions
   */
  const computeFullWidthScale = useCallback(
    async (document: IPDFDocumentProxy) => {
      try {
        if (pagesContainerRef.current) {
          const pageData = await document.getPage(1);
          const { viewBox }: { viewBox: number[] } = pageData.getViewport(1);
          const [, , viewBoxWidth] = viewBox; // [x, y, width, height]
          const pageContainerWidth = pagesContainerRef.current.clientWidth;
          const pageWidthScale = (pageContainerWidth / viewBoxWidth) * 100;
          if (!Number.isNaN(pageWidthScale)) {
            setFullWidthScale(pageWidthScale);
          }
        }
      } catch (_error) {
        // catch unhandled errors
      }
    },
    [pagesContainerRef]
  );

  const onDocumentLoadSuccess = (document: any) => {
    const numberOfPages = Number(document?.numPages || 0);
    setPDFDocumentProxy(document);
    setPageCount(numberOfPages);
    setActivePage(1);
    // for better UX hide the page preview section on load to focus the content
    if (numberOfPages === 1) {
      setSideNavigationState(PDFViewerSideNavigationState.CLOSED);
    }
    computeFullWidthScale(document);
  };

  /**
   * @function
   * handle internal link, bookmark, goTo page actions
   * @fires handleDocumentScroll
   * */
  const onItemClick = (props: Partial<IPDFDocumentProxy>) => {
    const { pageNumber } = props;
    if (!pageNumber) {
      return;
    }
    // page canvas height plus bottom spacing
    const pageHeight = (pageCanvasRef.current?.clientHeight || 0) + 8;
    // scroll past (n - 1) pages plus 1 pixel to reach the desired page
    const scrollHeight = pageHeight * (pageNumber - 1) + 1;
    pagesContainerRef.current?.scrollTo({
      top: scrollHeight,
      behavior: "smooth",
    });
  };

  /**
   * Listen to scroll events on the ***pages container*** element and set the
   * visible page as the active page. Triggers the preview sidebar to scroll.
   */
  const handleDocumentScroll: UIEventHandler<HTMLDivElement> = (event) => {
    //for large pdfs: disable scrolling in page container or active page will always reset to 1
    //when user attempts to scroll through pages.
    if (!isLargeFile) {
      // page canvas height plus spacing between pages
      const pageHeight = (pageCanvasRef.current?.clientHeight || 0) + 8;
      // how much is the page container scrolled
      const scrollTop = event?.currentTarget.scrollTop;
      const inViewPage = Math.ceil(scrollTop / pageHeight);
      inViewPage && setActivePage(() => inViewPage);
    }
  };

  const interceptPrintCommand = (event: KeyboardEvent) => {
    if (
      event.key === "p" &&
      (event.ctrlKey || event.metaKey) &&
      !event.altKey
    ) {
      print();
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  };

  useEffect(() => {
    /**
     * Given that preview images are stored in an unordered array, generating previews
     * synchronously ensures that they are in the correct order without performing an sorting thereafter.
     * On the downside, the operation appears slow on the UI as we wait for all preview to be ready before displaying any.
     * Doing it the Async way with Promises is better as each preview is added in the UI when it's ready
     * but then an extra sorting step is needed to make sure they keep the correct order
     *  */
    (async () => {
      try {
        if (PDFDocumentProxy) {
          setLoadingPreview(true);
          const pages = PDFDocumentProxy.numPages as Number;

          for (let i = 0; i < pages; i++) {
            const proxyPage = await PDFDocumentProxy.getPage(i + 1);
            const canvas = document.createElement("canvas");
            // A4 Ratio applied, around 3:2
            const viewport = proxyPage.getViewport({ scale: 3 / 2 });
            const canvasContext = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            await proxyPage.render({ viewport, canvasContext }).promise;
            const previewImage = canvas.toDataURL();
            setThumbnails((previewImages) => [...previewImages, previewImage]);
          }
          setLoadingPreview(false);
        }
      } catch (_error) {
        /**
         * catch PDFjs internal unhandled errors like "RenderingCancelledException"
         * no way to pass down the error to <Document/> or <Page/> in index.tsx
         * we'll just make sure the app doesn't crash as these errors occurs when closing the viewer
         * */
      }
    })();
  }, [PDFDocumentProxy]);

  // keep active page sidebar preview in viewport
  useEffect(() => {
    if (activeThumbnailRef.current) {
      activeThumbnailRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeThumbnailRef, activePage]);

  // keep fullWidthScale refreshed
  useEffect(() => {
    computeFullWidthScale(PDFDocumentProxy);
  }, [sideNavigationState, PDFDocumentProxy]);

  useEffect(() => {
    if (isMobile && fullWidthScale) {
      setSideNavigationState(PDFViewerSideNavigationState.CLOSED);
      setScale(fullWidthScale);
    }
  }, [isMobile, fullWidthScale]);

  // Intercept Cmd/Ctrl + P in all browsers
  useEffect(() => {
    window.addEventListener("keydown", interceptPrintCommand, true);
    return () => {
      window.removeEventListener("keydown", interceptPrintCommand, true);
    };
  }, [interceptPrintCommand]);

  return {
    scale,
    fileProxy,
    pageCount,
    activePage,
    thumbnails,
    pdfHasOutline,
    rotationAngle,
    fullWidthScale,
    selectedViewMode,
    isLoadingPreview,
    sideNavigationState,
    printOrDownloadEnabled,
    pageCanvasRef,
    pagesContainerRef,
    activeThumbnailRef,
    print,
    onItemClick,
    setScale,
    setViewMode,
    setPageCount,
    setActivePage,
    setRotationAngle,
    onDocumentLoadSuccess,
    setSideNavigationState,
    onDocumentScroll: handleDocumentScroll,
    onOutlineError: () => setPDFHasOutline(() => false),
  };
};

export default usePDFViewerController;
