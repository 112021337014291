import React, { SyntheticEvent } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import { getDraftState } from "shared/state/ui/member/selectors";

import useDraftSafeNavigate from "hooks/useDraftSafeNavigate";
import { selectLoggedInUser } from "shared/features/user/selectors";
import { useSelfScheduleModalProps } from "features/selfSchedule/pages/Utils";

type IProps = {
  [key in string | number]: any;
} & {
  to: string;
  onClick?: (e: SyntheticEvent) => void;
};

const DraftReplySafeLink = ({ to, onClick, component, ...rest }: IProps) => {
  const navigate = useDraftSafeNavigate();
  const user = selectLoggedInUser();
  const location = useLocation();
  const { overrides } = useSelfScheduleModalProps();

  const isSchedulePage = location?.pathname?.includes("self-schedule");

  const Wrapper = component || Link;

  const hasDraft = useSelector(getDraftState);

  const toWithGuard = user?.ctm || !hasDraft ? to : "";

  const onClickHandler = (e: any) => {
    !!onClick && onClick(e);
    navigate(to, isSchedulePage ? overrides : undefined);
  };

  return <Wrapper onClick={onClickHandler} to={toWithGuard} {...rest} />;
};

export default DraftReplySafeLink;
