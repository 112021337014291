import isValid from "date-fns/isValid";
import parseDoB from "shared/utils/parseDoB";
import enUS from "date-fns/locale/en-US";
import enZA from "date-fns/locale/en-ZA";
import isSameDay from "date-fns/isSameDay";
import { format, utcToZonedTime } from "date-fns-tz";

interface OptionsProps {
  fallbackDate?: Date | string;
  showAsTodayIfSameDay?: boolean;
  isDoB?: boolean;
  invalidateDateFallback?: Date | string;
  tz?: string;
}

const formatDate = (
  date: Date | string,
  formatString: string = "yyyy-MM-dd",
  options?: OptionsProps
) => {
  const fallbackDate = options?.fallbackDate || date;
  const showAsTodayIfSameDay = options?.showAsTodayIfSameDay;
  const invalidateDateFallback = options?.invalidateDateFallback;
  const tz = options?.tz;

  if (!isValid(new Date(date))) {
    // Note: Passing invalidateDateFallback as an empty string means this will return "--/--/----"
    return invalidateDateFallback || "--/--/----";
  }

  const dateToFormat = typeof date === "string" ? parseDoB(date) : date;

  const timezone = tz || Intl.DateTimeFormat().resolvedOptions().timeZone;
  const parsedDate = utcToZonedTime(new Date(dateToFormat), timezone);

  const timeZoneName = format(parsedDate, "zzzz", { timeZone: timezone });
  const isAfricanTimezone =
    timeZoneName === "East Africa Time" ||
    timeZoneName === "Central Africa Time" ||
    timeZoneName === "West Africa Time";
  const localeCode = isAfricanTimezone ? enZA : enUS;

  if (showAsTodayIfSameDay && isSameDay(new Date(), new Date(parsedDate))) {
    const parsedTempDate = new Date(parsedDate);
    return `Today ${format(parsedTempDate, "@ h:mmaaa")}`;
  }

  if (isValid(parsedDate)) {
    return format(parsedDate, formatString, {
      timeZone: timezone,
      locale: localeCode,
    });
  }

  return fallbackDate;
};

export default formatDate;
