// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum SlotVisibilityEnum {
    Internal = 'internal',
    Accessible = 'accessible',
    Hold = 'hold'
}

export function SlotVisibilityEnumFromJSON(json: any): SlotVisibilityEnum {
    return json as SlotVisibilityEnum;
}

export function SlotVisibilityEnumToJSON(value?: SlotVisibilityEnum): any {
    return value as any;
}

