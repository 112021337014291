import { ReferralComponentCoreStateEnum } from "shared/fetch/src/models/ReferralComponentCore";

interface IProps {
  hasCheckIn: boolean;
  isVisitEligibleForCheckIn: boolean;
  uiElementKey:
    | "subHeading"
    | "primaryCtaButton"
    | "belowButtonCopy"
    | "closedCheckInFooter";
  componentState: ReferralComponentCoreStateEnum;
}

const useScheduledVisitMemberCheckInStateController = ({
  hasCheckIn,
  isVisitEligibleForCheckIn,
  uiElementKey,
  componentState,
}: IProps) => {
  const defaultMemberUi = {
    subHeading: "Save time and check in online today!",
    primaryCtaButton: "Check in",
    belowButtonCopy:
      "Check in now and you'll be ready to go at your visit time.",
  };
  const resolveMemberUi = {
    primaryCtaButton: "Resolve check in",
    belowButtonCopy: "There are a few items that need your attention.",
  };

  const checkInScheduledVisitMemberUi = {
    [ReferralComponentCoreStateEnum.Scheduled]: defaultMemberUi,
    [ReferralComponentCoreStateEnum.UnreadPending]: defaultMemberUi,
    [ReferralComponentCoreStateEnum.CheckInInProgress]: defaultMemberUi,
    [ReferralComponentCoreStateEnum.CheckInOpened]: defaultMemberUi,
    [ReferralComponentCoreStateEnum.CheckInIncomplete]: defaultMemberUi,
    [ReferralComponentCoreStateEnum.CheckInFastTrackedConsentNeeded]: defaultMemberUi,
    [ReferralComponentCoreStateEnum.CheckInFastTrackedOutstandingItemsWithoutConsent]: resolveMemberUi,
    [ReferralComponentCoreStateEnum.CheckInClosed]: {
      closedCheckInFooter:
        "If you have any questions, please call or message your Care Team",
    },
  };

  const label = checkInScheduledVisitMemberUi[componentState]?.[uiElementKey];
  let shouldElementRender =
    hasCheckIn && isVisitEligibleForCheckIn && Boolean(label);

  if (componentState === "check_in_closed") {
    shouldElementRender = hasCheckIn && Boolean(label);
  }

  return { shouldElementRender, label };
};

export default useScheduledVisitMemberCheckInStateController;
