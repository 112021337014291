// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompactComponentReference,
    CompactComponentReferenceFromJSON,
    CompactComponentReferenceToJSON,
} from './CompactComponentReference';


/**
 * 
 * @export
 * @interface BillingComponentOutputProps
 */
export interface BillingComponentOutputProps  {
    /**
     * 
     * @type {string}
     * @memberof BillingComponentOutputProps
     */
    state: BillingComponentOutputPropsStateEnum;
    /**
     * 
     * @type {Array<CompactComponentReference>}
     * @memberof BillingComponentOutputProps
     */
    parentComponents?: Array<CompactComponentReference>;
    /**
     * 
     * @type {string}
     * @memberof BillingComponentOutputProps
     */
    amount?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingComponentOutputProps
     */
    serviceProviderFullName?: string;
    /**
     * 
     * @type {string}
     * @memberof BillingComponentOutputProps
     */
    serviceSubtype?: string;
}

export function BillingComponentOutputPropsFromJSON(json: any): BillingComponentOutputProps {
    return {
        'state': json['state'],
        'parentComponents': !exists(json, 'parent_components') ? undefined : (json['parent_components'] as Array<any>).map(CompactComponentReferenceFromJSON),
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'serviceProviderFullName': !exists(json, 'service_provider_full_name') ? undefined : json['service_provider_full_name'],
        'serviceSubtype': !exists(json, 'service_subtype') ? undefined : json['service_subtype'],
    };
}

export function BillingComponentOutputPropsToJSON(value?: BillingComponentOutputProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'state': value.state,
        'parent_components': value.parentComponents === undefined ? undefined : (value.parentComponents as Array<any>).map(CompactComponentReferenceToJSON),
        'amount': value.amount,
        'service_provider_full_name': value.serviceProviderFullName,
        'service_subtype': value.serviceSubtype,
    };
}

/**
* @export
* @enum {string}
*/
export enum BillingComponentOutputPropsStateEnum {
    Unpaid = 'unpaid',
    PaymentRequested = 'payment_requested',
    MemberViewed = 'member_viewed',
    Paid = 'paid'
}


