// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EnrollPushNotificationRequestBody
 */
export interface EnrollPushNotificationRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof EnrollPushNotificationRequestBody
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof EnrollPushNotificationRequestBody
     */
    devicePushToken?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrollPushNotificationRequestBody
     */
    pushNotificationCategoryId?: string;
    /**
     * 
     * @type {string}
     * @memberof EnrollPushNotificationRequestBody
     */
    operatingSystemName?: EnrollPushNotificationRequestBodyOperatingSystemNameEnum;
}

export function EnrollPushNotificationRequestBodyFromJSON(json: any): EnrollPushNotificationRequestBody {
    return {
        'deviceId': json['device_id'],
        'devicePushToken': !exists(json, 'device_push_token') ? undefined : json['device_push_token'],
        'pushNotificationCategoryId': !exists(json, 'push_notification_category_id') ? undefined : json['push_notification_category_id'],
        'operatingSystemName': !exists(json, 'operating_system_name') ? undefined : json['operating_system_name'],
    };
}

export function EnrollPushNotificationRequestBodyToJSON(value?: EnrollPushNotificationRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'device_id': value.deviceId,
        'device_push_token': value.devicePushToken,
        'push_notification_category_id': value.pushNotificationCategoryId,
        'operating_system_name': value.operatingSystemName,
    };
}

/**
* @export
* @enum {string}
*/
export enum EnrollPushNotificationRequestBodyOperatingSystemNameEnum {
    Android = 'android',
    Ios = 'ios',
    Windows = 'windows'
}


