import React from "react";
import Box from "components/Box";
import Container from "components/Container";

import Skeleton from "./";
import CardSkeleton from "./CardSkeleton";

const ComponentSkeleton = () => (
  <div style={{ width: "100%" }}>
    <div
      style={{
        height: 180,
        width: "100%",
        backgroundColor: "rgba(43, 57, 73, 0.1)",
        position: "absolute",
      }}
    />
    <Container style={{ position: "relative", zIndex: 1, marginTop: 28 }}>
      <Box pt={3}>
        <CardSkeleton style={{ height: 336 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "spaceBetween",
              marginBottom: 56,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: "0 0 33.33%",
                maxWidth: "33.33%",
              }}
            >
              <Skeleton width="60%" height={32} style={{ marginBottom: 4 }} />
              <Skeleton width="65%" height={26} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: "0 0 33.33%",
                maxWidth: "33.33%",
              }}
            >
              <Skeleton width={250} height={60} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: "0 0 33.33%",
                maxWidth: "33.33%",
              }}
            >
              <Skeleton width={250} height={60} />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "spaceBetween" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: "0 0 50%",
                maxWidth: "50%",
              }}
            >
              <Skeleton width="60%" height={26} style={{ marginBottom: 2 }} />
              <Skeleton width="90%" height={26} />
              <Skeleton width="90%" height={26} />
              <Skeleton width="90%" height={70} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flex: "0 0 50%",
                maxWidth: "50%",
              }}
            >
              <Skeleton width="90%" height={26} />
            </div>
          </div>
        </CardSkeleton>
      </Box>
    </Container>
  </div>
);

export default ComponentSkeleton;
