import {
  MEMBER_ACCESS_ID,
  MEMBER_ACCESS_TOKEN_KEY,
} from "shared/features/auth";
import { getTokenSync } from "shared/features/auth/utils";

export const isDependantAccess = () => {
  const memberAccessToken = getTokenSync(MEMBER_ACCESS_TOKEN_KEY);
  const memberAccessId = getTokenSync(MEMBER_ACCESS_ID);

  return memberAccessToken || memberAccessId ? true : false;
};
