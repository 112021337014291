// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatientBenefitPlansObject
 */
export interface PatientBenefitPlansObject  {
    /**
     * 
     * @type {Array<string>}
     * @memberof PatientBenefitPlansObject
     */
    plans?: Array<string>;
}

export function PatientBenefitPlansObjectFromJSON(json: any): PatientBenefitPlansObject {
    return {
        'plans': !exists(json, 'plans') ? undefined : json['plans'],
    };
}

export function PatientBenefitPlansObjectToJSON(value?: PatientBenefitPlansObject): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'plans': value.plans,
    };
}


