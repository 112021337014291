// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HoursOfOperation
 */
export interface HoursOfOperation  {
    /**
     * 
     * @type {Array<string>}
     * @memberof HoursOfOperation
     */
    M?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof HoursOfOperation
     */
    T?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof HoursOfOperation
     */
    W?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof HoursOfOperation
     */
    th?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof HoursOfOperation
     */
    F?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof HoursOfOperation
     */
    S?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof HoursOfOperation
     */
    su?: Array<string>;
}

export function HoursOfOperationFromJSON(json: any): HoursOfOperation {
    return {
        'M': !exists(json, 'M') ? undefined : json['M'],
        'T': !exists(json, 'T') ? undefined : json['T'],
        'W': !exists(json, 'W') ? undefined : json['W'],
        'th': !exists(json, 'Th') ? undefined : json['Th'],
        'F': !exists(json, 'F') ? undefined : json['F'],
        'S': !exists(json, 'S') ? undefined : json['S'],
        'su': !exists(json, 'Su') ? undefined : json['Su'],
    };
}

export function HoursOfOperationToJSON(value?: HoursOfOperation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'M': value.M,
        'T': value.T,
        'W': value.W,
        'Th': value.th,
        'F': value.F,
        'S': value.S,
        'Su': value.su,
    };
}


