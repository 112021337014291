import React, { FunctionComponent } from "react";
import ChipComponent, { ChipProps } from "@mui/material/Chip";
import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  colorPrimary: {
    "&:focus": {
      // ensure that the MUI emphasized colors are not applied to the "focused" chip element to maintain color continuity in the UI
      background: theme.palette.primary.main,
    },
  },
}));

const Chip: FunctionComponent<ChipProps> = (props) => {
  const classes = useStyles();
  const remainingProps = { ...props };
  remainingProps.classes && delete remainingProps.classes;
  return (
    <ChipComponent
      classes={{
        ...props.classes,
        ...classes,
      }}
      {...remainingProps}
    />
  );
};

export default Chip;
