import { useSelector } from "react-redux";
import { useRequest } from "redux-query-react";
import { State } from "shared/types";
import getProvider from "./queryConfigs/getProvider";

const useGetProvider = (providerID: string) => {
  const provider = useSelector(
    (state: State) => state.entities.networkProvider?.[providerID]
  );
  const contacts = useSelector(
    (state: State) => state.entities.networkContacts
  );

  const isProviderContact =
    !!providerID &&
    contacts?.providers?.some((prov) => prov.id.toString() === providerID);

  const [{ isPending }] = useRequest(getProvider(providerID));

  return {
    isProviderContact,
    provider,
    isPending,
  };
};

export default useGetProvider;
