// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HoursOfOperation,
    HoursOfOperationFromJSON,
    HoursOfOperationToJSON,
} from './HoursOfOperation';


/**
 * 
 * @export
 * @interface MemberDefaultClinicInfo
 */
export interface MemberDefaultClinicInfo  {
    /**
     * 
     * @type {string}
     * @memberof MemberDefaultClinicInfo
     */
    clinicId?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDefaultClinicInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDefaultClinicInfo
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDefaultClinicInfo
     */
    timezone?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDefaultClinicInfo
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberDefaultClinicInfo
     */
    usState?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberDefaultClinicInfo
     */
    virtual?: boolean;
    /**
     * 
     * @type {HoursOfOperation}
     * @memberof MemberDefaultClinicInfo
     */
    staffHours?: HoursOfOperation;
    /**
     * 
     * @type {HoursOfOperation}
     * @memberof MemberDefaultClinicInfo
     */
    memberHours?: HoursOfOperation;
}

export function MemberDefaultClinicInfoFromJSON(json: any): MemberDefaultClinicInfo {
    return {
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'usState': !exists(json, 'us_state') ? undefined : json['us_state'],
        'virtual': !exists(json, 'virtual') ? undefined : json['virtual'],
        'staffHours': !exists(json, 'staff_hours') ? undefined : HoursOfOperationFromJSON(json['staff_hours']),
        'memberHours': !exists(json, 'member_hours') ? undefined : HoursOfOperationFromJSON(json['member_hours']),
    };
}

export function MemberDefaultClinicInfoToJSON(value?: MemberDefaultClinicInfo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'clinic_id': value.clinicId,
        'name': value.name,
        'phone': value.phone,
        'timezone': value.timezone,
        'address': value.address,
        'us_state': value.usState,
        'virtual': value.virtual,
        'staff_hours': HoursOfOperationToJSON(value.staffHours),
        'member_hours': HoursOfOperationToJSON(value.memberHours),
    };
}


