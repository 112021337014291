import React, { FunctionComponent } from "react";
import CircularProgressComponent, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";

const CircularProgress: FunctionComponent<CircularProgressProps> = (props) => {
  return <CircularProgressComponent {...props} />;
};

export default CircularProgress;
