import { JsonUser } from "shared/api/src/models/JsonUser";
import { IFeatureGetter } from "shared/state/featureFlags";

// returns true if:
// user is a member && member_inline_messaging feature flag is on
// user is a ctm && ctm_inline_messaging feature flag is on
export default function hasInlineMessaging(
  user: JsonUser,
  features: IFeatureGetter
) {
  const isMember = !user?.ctm;
  const result =
    (features.hasMemberInlineMessaging() && isMember) ||
    (features.hasCtmInlineMessaging() && user?.ctm);
  return result;
}
