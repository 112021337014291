import superagentInterface from "redux-query-interface-superagent";

const networkInterface = (
  url: string,
  method: string,
  { body, headers, credentials }: any = {}
) => {
  return superagentInterface(url, method, { body, headers, credentials });
};

export default networkInterface;
