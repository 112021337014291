// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberReceiptPaymentDetails
 */
export interface MemberReceiptPaymentDetails  {
    /**
     * 
     * @type {string}
     * @memberof MemberReceiptPaymentDetails
     */
    totalAmount?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberReceiptPaymentDetails
     */
    payDate?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberReceiptPaymentDetails
     */
    receiptNumber?: string;
}

export function MemberReceiptPaymentDetailsFromJSON(json: any): MemberReceiptPaymentDetails {
    return {
        'totalAmount': !exists(json, 'total_amount') ? undefined : json['total_amount'],
        'payDate': !exists(json, 'pay_date') ? undefined : json['pay_date'],
        'receiptNumber': !exists(json, 'receipt_number') ? undefined : json['receipt_number'],
    };
}

export function MemberReceiptPaymentDetailsToJSON(value?: MemberReceiptPaymentDetails): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'total_amount': value.totalAmount,
        'pay_date': value.payDate,
        'receipt_number': value.receiptNumber,
    };
}


