// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberNetworkContacts,
    MemberNetworkContactsFromJSON,
    MemberNetworkContactsToJSON,
} from './MemberNetworkContacts';
import {
    NetworkType,
    NetworkTypeFromJSON,
    NetworkTypeToJSON,
} from './NetworkType';


/**
 * 
 * @export
 * @interface ContactProviderOutput
 */
export interface ContactProviderOutput  {
    /**
     * 
     * @type {NetworkType}
     * @memberof ContactProviderOutput
     */
    type?: NetworkType;
    /**
     * 
     * @type {MemberNetworkContacts}
     * @memberof ContactProviderOutput
     */
    contacts?: MemberNetworkContacts;
}

export function ContactProviderOutputFromJSON(json: any): ContactProviderOutput {
    return {
        'type': !exists(json, 'type') ? undefined : NetworkTypeFromJSON(json['type']),
        'contacts': !exists(json, 'contacts') ? undefined : MemberNetworkContactsFromJSON(json['contacts']),
    };
}

export function ContactProviderOutputToJSON(value?: ContactProviderOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'type': NetworkTypeToJSON(value.type),
        'contacts': MemberNetworkContactsToJSON(value.contacts),
    };
}


