import typography from "../typography";
import palette from "../palette";

export default {
  paper: {
    borderRadius: 5,
    boxShadow: `2px 2px 6px ${palette.boxShadowLightGray}`,
    minWidth: 160,
  },
  list: {
    "& a": {
      color: "inherit",
      display: "flex",
      width: "100%",
      alignItems: "center",
      justifyContent: "space-between",
      "& *:first-child": {
        paddingRight: 8,
      },
    },
    "& .MuiListItem-root.Mui-focusVisible": {
      backgroundColor: "rgba(0, 0, 0, 0.14)",
    },
    "& .MuiListItem-button:hover": {
      backgroundColor: "#f3f6f8",
    },
    "& .MuiMenuItem-root": {
      fontSize: typography.body2.fontSize,
    },
  },
};
