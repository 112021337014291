// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OOONotificationsArray
 */
export interface OOONotificationsArray  {
    /**
     * 
     * @type {Date}
     * @memberof OOONotificationsArray
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OOONotificationsArray
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof OOONotificationsArray
     */
    clinicId?: number;
    /**
     * 
     * @type {string}
     * @memberof OOONotificationsArray
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof OOONotificationsArray
     */
    startDate?: string;
    /**
     * 
     * @type {string}
     * @memberof OOONotificationsArray
     */
    endDate?: string;
}

export function OOONotificationsArrayFromJSON(json: any): OOONotificationsArray {
    return {
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'providerId': !exists(json, 'provider_id') ? undefined : json['provider_id'],
        'startDate': !exists(json, 'start_date') ? undefined : json['start_date'],
        'endDate': !exists(json, 'end_date') ? undefined : json['end_date'],
    };
}

export function OOONotificationsArrayToJSON(value?: OOONotificationsArray): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'clinic_id': value.clinicId,
        'provider_id': value.providerId,
        'start_date': value.startDate,
        'end_date': value.endDate,
    };
}


