// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GenericCMSListItemFields
 */
export interface GenericCMSListItemFields  {
    /**
     * 
     * @type {string}
     * @memberof GenericCMSListItemFields
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GenericCMSListItemFields
     */
    value: string;
}

export function GenericCMSListItemFieldsFromJSON(json: any): GenericCMSListItemFields {
    return {
        'name': json['name'],
        'value': json['value'],
    };
}

export function GenericCMSListItemFieldsToJSON(value?: GenericCMSListItemFields): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'value': value.value,
    };
}


