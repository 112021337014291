/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    FetchCTMHeadsUpResponse,
    FetchCTMHeadsUpResponseFromJSON,
    FetchCTMHeadsUpResponseToJSON,
} from '../models/FetchCTMHeadsUpResponse'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface ClearHeadsUpNotificationRequest {
    notificationIds: string;
}
export interface ClearHeadsUpNotificationsRequest {
    id: string;
}
export interface DeleteHeadsUpNotificationOptOutRequest {
    id: string;
}
export interface FetchCTMHeadsUpRequest {
    id: string;
    page?: string;
    state?: FetchCTMHeadsUpStateEnum;
}
export interface FetchCTMHeadsUpNotificationTypesRequest {
    userId: string;
}
export interface HeadsUpNotificationOptOutRequest {
    id: string;
    notificationType: HeadsUpNotificationOptOutNotificationTypeEnum;
    componentType?: string;
}
export interface ResolveHeadsUpNotificationRequest {
    id: string;
}
export interface ResolveHeadsUpNotificationsRequest {
    id: string;
}


export interface IHeadsUpApi {
      clearHeadsUpNotification: (requestParameters: ClearHeadsUpNotificationRequest) => Promise<void>
      clearHeadsUpNotifications: (requestParameters: ClearHeadsUpNotificationsRequest) => Promise<void>
      deleteHeadsUpNotificationOptOut: (requestParameters: DeleteHeadsUpNotificationOptOutRequest) => Promise<void>
      fetchCTMHeadsUp: (requestParameters: FetchCTMHeadsUpRequest) => Promise<FetchCTMHeadsUpResponse>
      fetchCTMHeadsUpNotificationTypes: (requestParameters: FetchCTMHeadsUpNotificationTypesRequest) => Promise<Array<object>>
      headsUpNotificationOptOut: (requestParameters: HeadsUpNotificationOptOutRequest) => Promise<void>
      resolveHeadsUpNotification: (requestParameters: ResolveHeadsUpNotificationRequest) => Promise<void>
      resolveHeadsUpNotifications: (requestParameters: ResolveHeadsUpNotificationsRequest) => Promise<void>
      clearHeadsUpNotificationWithResponse: (requestParameters: ClearHeadsUpNotificationRequest) => Promise<{ value: void, response: any}>
      clearHeadsUpNotificationsWithResponse: (requestParameters: ClearHeadsUpNotificationsRequest) => Promise<{ value: void, response: any}>
      deleteHeadsUpNotificationOptOutWithResponse: (requestParameters: DeleteHeadsUpNotificationOptOutRequest) => Promise<{ value: void, response: any}>
      fetchCTMHeadsUpWithResponse: (requestParameters: FetchCTMHeadsUpRequest) => Promise<{ value: FetchCTMHeadsUpResponse, response: any}>
      fetchCTMHeadsUpNotificationTypesWithResponse: (requestParameters: FetchCTMHeadsUpNotificationTypesRequest) => Promise<{ value: Array<object>, response: any}>
      headsUpNotificationOptOutWithResponse: (requestParameters: HeadsUpNotificationOptOutRequest) => Promise<{ value: void, response: any}>
      resolveHeadsUpNotificationWithResponse: (requestParameters: ResolveHeadsUpNotificationRequest) => Promise<{ value: void, response: any}>
      resolveHeadsUpNotificationsWithResponse: (requestParameters: ResolveHeadsUpNotificationsRequest) => Promise<{ value: void, response: any}>
}

/**
 * no description
 */
export class HeadsUpApi extends runtime.BaseAPI {

    /**
     * Clear heads up notifications
     */
    clearHeadsUpNotificationRaw = async (requestParameters: ClearHeadsUpNotificationRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.notificationIds === null || requestParameters.notificationIds === undefined) {
            throw new runtime.RequiredError('notificationIds','Required parameter requestParameters.notificationIds was null or undefined when calling clearHeadsUpNotification.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/heads_up_notifications/{notification_ids}/clear`.replace(`{${"notification_ids"}}`, encodeURIComponent(String(requestParameters.notificationIds))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Clear heads up notifications
     */
    clearHeadsUpNotification = async (requestParameters: ClearHeadsUpNotificationRequest): Promise<void> => {
        const { runtime } = await this.clearHeadsUpNotificationRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Clear heads up notifications
     */
    clearHeadsUpNotificationWithResponse = async (requestParameters: ClearHeadsUpNotificationRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.clearHeadsUpNotificationRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Clear all heads up notifications
     */
    clearHeadsUpNotificationsRaw = async (requestParameters: ClearHeadsUpNotificationsRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling clearHeadsUpNotifications.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/heads_up_notifications/{id}/clear_all`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Clear all heads up notifications
     */
    clearHeadsUpNotifications = async (requestParameters: ClearHeadsUpNotificationsRequest): Promise<void> => {
        const { runtime } = await this.clearHeadsUpNotificationsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Clear all heads up notifications
     */
    clearHeadsUpNotificationsWithResponse = async (requestParameters: ClearHeadsUpNotificationsRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.clearHeadsUpNotificationsRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Deletes user opt out record
     */
    deleteHeadsUpNotificationOptOutRaw = async (requestParameters: DeleteHeadsUpNotificationOptOutRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteHeadsUpNotificationOptOut.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/heads_up_notification_opt_outs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Deletes user opt out record
     */
    deleteHeadsUpNotificationOptOut = async (requestParameters: DeleteHeadsUpNotificationOptOutRequest): Promise<void> => {
        const { runtime } = await this.deleteHeadsUpNotificationOptOutRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Deletes user opt out record
     */
    deleteHeadsUpNotificationOptOutWithResponse = async (requestParameters: DeleteHeadsUpNotificationOptOutRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.deleteHeadsUpNotificationOptOutRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Fetch component activity log
     */
    fetchCTMHeadsUpRaw = async (requestParameters: FetchCTMHeadsUpRequest): Promise<{ runtime: runtime.ApiResponse<FetchCTMHeadsUpResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchCTMHeadsUp.');
        }

                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/care_team_members/{id}/heads_up_notifications`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => FetchCTMHeadsUpResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch component activity log
     */
    fetchCTMHeadsUp = async (requestParameters: FetchCTMHeadsUpRequest): Promise<FetchCTMHeadsUpResponse> => {
        const { runtime } = await this.fetchCTMHeadsUpRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch component activity log
     */
    fetchCTMHeadsUpWithResponse = async (requestParameters: FetchCTMHeadsUpRequest): Promise<{ value: FetchCTMHeadsUpResponse, response: any}> => {
        const { runtime, response } = await this.fetchCTMHeadsUpRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch CTM heads_up notification type configuration
     */
    fetchCTMHeadsUpNotificationTypesRaw = async (requestParameters: FetchCTMHeadsUpNotificationTypesRequest): Promise<{ runtime: runtime.ApiResponse<Array<object>>, response: any}> => {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling fetchCTMHeadsUpNotificationTypes.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/heads_up_notifications/{user_id}/notification_types`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse<any>(response), response };
    }

    /**
     * Fetch CTM heads_up notification type configuration
     */
    fetchCTMHeadsUpNotificationTypes = async (requestParameters: FetchCTMHeadsUpNotificationTypesRequest): Promise<Array<object>> => {
        const { runtime } = await this.fetchCTMHeadsUpNotificationTypesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch CTM heads_up notification type configuration
     */
    fetchCTMHeadsUpNotificationTypesWithResponse = async (requestParameters: FetchCTMHeadsUpNotificationTypesRequest): Promise<{ value: Array<object>, response: any}> => {
        const { runtime, response } = await this.fetchCTMHeadsUpNotificationTypesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Opt out of a heads up notification type
     */
    headsUpNotificationOptOutRaw = async (requestParameters: HeadsUpNotificationOptOutRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling headsUpNotificationOptOut.');
        }

                        if (requestParameters.notificationType === null || requestParameters.notificationType === undefined) {
            throw new runtime.RequiredError('notificationType','Required parameter requestParameters.notificationType was null or undefined when calling headsUpNotificationOptOut.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.notificationType !== undefined) {
            queryParameters['notification_type'] = requestParameters.notificationType;
        }

        if (requestParameters.componentType !== undefined) {
            queryParameters['component_type'] = requestParameters.componentType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/care_team_members/{id}/heads_up_notification_opt_outs`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Opt out of a heads up notification type
     */
    headsUpNotificationOptOut = async (requestParameters: HeadsUpNotificationOptOutRequest): Promise<void> => {
        const { runtime } = await this.headsUpNotificationOptOutRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Opt out of a heads up notification type
     */
    headsUpNotificationOptOutWithResponse = async (requestParameters: HeadsUpNotificationOptOutRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.headsUpNotificationOptOutRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Mark heads up notification as viewed
     */
    resolveHeadsUpNotificationRaw = async (requestParameters: ResolveHeadsUpNotificationRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling resolveHeadsUpNotification.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/heads_up_notifications/{id}/viewed`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Mark heads up notification as viewed
     */
    resolveHeadsUpNotification = async (requestParameters: ResolveHeadsUpNotificationRequest): Promise<void> => {
        const { runtime } = await this.resolveHeadsUpNotificationRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Mark heads up notification as viewed
     */
    resolveHeadsUpNotificationWithResponse = async (requestParameters: ResolveHeadsUpNotificationRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.resolveHeadsUpNotificationRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Mark all heads up notifications as viewed
     */
    resolveHeadsUpNotificationsRaw = async (requestParameters: ResolveHeadsUpNotificationsRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling resolveHeadsUpNotifications.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/heads_up_notifications/{id}/view_all`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Mark all heads up notifications as viewed
     */
    resolveHeadsUpNotifications = async (requestParameters: ResolveHeadsUpNotificationsRequest): Promise<void> => {
        const { runtime } = await this.resolveHeadsUpNotificationsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Mark all heads up notifications as viewed
     */
    resolveHeadsUpNotificationsWithResponse = async (requestParameters: ResolveHeadsUpNotificationsRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.resolveHeadsUpNotificationsRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

}

/**
    * @export
    * @enum {string}
    */
export enum FetchCTMHeadsUpStateEnum {
    Active = 'active',
    Resolved = 'resolved'
}
/**
    * @export
    * @enum {string}
    */
export enum HeadsUpNotificationOptOutNotificationTypeEnum {
    Scheduled = 'visit_scheduled',
    Rescheduled = 'visit_rescheduled'
}

export const fetchHeadsUpApi: IHeadsUpApi  = new HeadsUpApi();
