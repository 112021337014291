// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     CompactUserSchema,
    CompactUserSchemaFromJSON,
    CompactUserSchemaToJSON,
} from './CompactUserSchema';
import {
     MemberInfoProps,
    MemberInfoPropsFromJSON,
    MemberInfoPropsToJSON,
} from './MemberInfoProps';
import {
     MemberInfoPropsFullAddress,
    MemberInfoPropsFullAddressFromJSON,
    MemberInfoPropsFullAddressToJSON,
} from './MemberInfoPropsFullAddress';
import {
     UserInfo,
    UserInfoFromJSON,
    UserInfoToJSON,
} from './UserInfo';
/**
 * @type MemberInfo
 * @export
 */
export interface MemberInfo extends CompactUserSchema, MemberInfoProps {
}

export function MemberInfoFromJSON(json: any): MemberInfo {
    return {
        ...CompactUserSchemaFromJSON(json),
        ...MemberInfoPropsFromJSON(json),
    };
}

export function MemberInfoToJSON(value?: MemberInfo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...CompactUserSchemaToJSON(value),
        ...MemberInfoPropsToJSON(value),
    };
}

