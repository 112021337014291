// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';


/**
 * 
 * @export
 * @interface CTMInfoArrayResponse
 */
export interface CTMInfoArrayResponse  {
    /**
     * 
     * @type {Array<CTMInfo>}
     * @memberof CTMInfoArrayResponse
     */
    results?: Array<CTMInfo>;
}

export function CTMInfoArrayResponseFromJSON(json: any): CTMInfoArrayResponse {
    return {
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(CTMInfoFromJSON),
    };
}

export function CTMInfoArrayResponseToJSON(value?: CTMInfoArrayResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(CTMInfoToJSON),
    };
}


