export const isValidPhoneNumber = (phoneNumber: string) => {
  const validUSPhoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  // TODO: BE doesn't seem to accept international phone numbers as valid phone numbers.
  // Update validation after discussion
  let value = phoneNumber;
  if (!value) {
    return false;
  }

  if (value && !value.match(validUSPhoneNum)) {
    value = value.replace(/-|\s|\.|\(|\)/g, "");
  }

  const isValid = validUSPhoneNum.test(value);
  return isValid;
};

export const isValidZipCode = (code: string): boolean => {
  // TODO: Validation checks for 5 digits or 32010-0040 might want to change this in the future.
  const validUSZipCode = /^\d{5}$|^\d{5}-\d{4}$/;
  return validUSZipCode.test(code);
};

export const isValidEmail = (email: string): boolean => {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

export const isAlphabets = (input: string): boolean => {
  const validAlphabets = /^[a-zA-Z ]+$/;
  return validAlphabets.test(input);
};

export const isNumbersWithDashes = (input: string): boolean => {
  const numbersWithDashes = /^[0-9-]+$/;
  return numbersWithDashes.test(input);
};

export const isAlphaNumericWithDashes = (input: string): boolean => {
  const alphaNumbersWithDashes = /^[a-zA-Z0-9-]+$/;
  return alphaNumbersWithDashes.test(input);
};
