import { useMutation } from "redux-query-react";
import { QueryConfig, Entities } from "redux-query";
import { JsonUser as User } from "shared/api/src/models/JsonUser";
import isEqual from "lodash/isEqual";
import { patchUser } from "shared/api/src/apis/AccountsApi";

export const usePartialUpdateUser = () => {
  const queryConfig = () =>
    patchUser({
      transform: (body: User) => ({ user: body }),
      update: {
        user: (prev: User, next: User) => {
          if (isEqual(prev, next)) {
            return prev;
          }
          return next;
        },
      },
    });

  const [, partiallyUpdateUser] = useMutation(() => {
    return queryConfig() as QueryConfig<Entities>;
  });

  return { partiallyUpdateUser };
};
