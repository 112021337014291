// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UnpaidCharge
 */
export interface UnpaidCharge  {
    /**
     * 
     * @type {number}
     * @memberof UnpaidCharge
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UnpaidCharge
     */
    patientId?: string;
    /**
     * 
     * @type {number}
     * @memberof UnpaidCharge
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof UnpaidCharge
     */
    appointmentId?: number;
    /**
     * 
     * @type {string}
     * @memberof UnpaidCharge
     */
    paymentMethodId?: string;
    /**
     * 
     * @type {string}
     * @memberof UnpaidCharge
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof UnpaidCharge
     */
    item?: string;
    /**
     * 
     * @type {string}
     * @memberof UnpaidCharge
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof UnpaidCharge
     */
    chargeDate?: string;
    /**
     * 
     * @type {Date}
     * @memberof UnpaidCharge
     */
    paidAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UnpaidCharge
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UnpaidCharge
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof UnpaidCharge
     */
    componentId?: number;
    /**
     * 
     * @type {string}
     * @memberof UnpaidCharge
     */
    receiptNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof UnpaidCharge
     */
    chargeType?: string;
    /**
     * 
     * @type {string}
     * @memberof UnpaidCharge
     */
    data?: string;
    /**
     * 
     * @type {number}
     * @memberof UnpaidCharge
     */
    episodeId?: number;
    /**
     * 
     * @type {string}
     * @memberof UnpaidCharge
     */
    appointmentName?: string;
}

export function UnpaidChargeFromJSON(json: any): UnpaidCharge {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'patientId': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'appointmentId': !exists(json, 'appointment_id') ? undefined : json['appointment_id'],
        'paymentMethodId': !exists(json, 'payment_method_id') ? undefined : json['payment_method_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'item': !exists(json, 'item') ? undefined : json['item'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'chargeDate': !exists(json, 'charge_date') ? undefined : json['charge_date'],
        'paidAt': !exists(json, 'paid_at') ? undefined : new Date(json['paid_at']),
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'componentId': !exists(json, 'component_id') ? undefined : json['component_id'],
        'receiptNumber': !exists(json, 'receipt_number') ? undefined : json['receipt_number'],
        'chargeType': !exists(json, 'charge_type') ? undefined : json['charge_type'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'episodeId': !exists(json, 'episode_id') ? undefined : json['episode_id'],
        'appointmentName': !exists(json, 'appointment_name') ? undefined : json['appointment_name'],
    };
}

export function UnpaidChargeToJSON(value?: UnpaidCharge): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'patient_id': value.patientId,
        'amount': value.amount,
        'appointment_id': value.appointmentId,
        'payment_method_id': value.paymentMethodId,
        'status': value.status,
        'item': value.item,
        'description': value.description,
        'charge_date': value.chargeDate,
        'paid_at': value.paidAt === undefined ? undefined : value.paidAt.toISOString(),
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'component_id': value.componentId,
        'receipt_number': value.receiptNumber,
        'charge_type': value.chargeType,
        'data': value.data,
        'episode_id': value.episodeId,
        'appointment_name': value.appointmentName,
    };
}


