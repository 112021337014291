import { useSelector } from "react-redux";
import { State } from "shared/types/state";
import { getAuthorizedUrl } from "shared/features/auth/selectors";
import { isDependantAccess } from "shared/utils/isDependentAccess";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";

export const getUser = (state: State) => {
  return !isDependantAccess() && !state.auth.memberAccessUser
    ? state.entities.user
    : state.auth.memberAccessUser;
};

export const getCurrentUser = (state: State) => state.entities.user;

export const selectLoggedInUser = () => useSelector(getUser) || {};

export const getAuthorizedAvatarUrl = (state: State) => {
  const url = getUser(state)?.avatarUrl;
  return getAuthorizedUrl(state, url as any);
};

export const hasPermission =
  (permission: PermissionsEnum) => (state: State) => {
    const user = getUser(state);
    const hasUserPermission = user?.permissions?.includes(permission) ?? false;
    return hasUserPermission;
  };
