// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UserSettings,
    UserSettingsFromJSON,
    UserSettingsToJSON,
} from '../models/UserSettings'
import {
    UserSettingsResponse,
    UserSettingsResponseFromJSON,
    UserSettingsResponseToJSON,
} from '../models/UserSettingsResponse'


export interface GetUserSettingsRequest {
    id: string;
    userSettings?: UserSettings;
}

export interface PutUserSettingRequest {
    id: string;
    userSettings?: UserSettings;
}


/**
 * Get user settings values
 * Get user settings
 */
function getUserSettingsRaw<T>(requestParameters: GetUserSettingsRequest, requestConfig: runtime.TypedQueryConfig<T, UserSettingsResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getUserSettings.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/user_settings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters || UserSettingsToJSON(requestParameters.userSettings),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(UserSettingsResponseFromJSON(body), text);
    }

    return config;
}

/**
* Get user settings values
* Get user settings
*/
export function getUserSettings<T>(requestParameters: GetUserSettingsRequest, requestConfig?: runtime.TypedQueryConfig<T, UserSettingsResponse>): QueryConfig<T> {
    return getUserSettingsRaw(requestParameters, requestConfig);
}

/**
 * Update or Create user settings values
 * Update or Create user settings
 */
function putUserSettingRaw<T>(requestParameters: PutUserSettingRequest, requestConfig: runtime.TypedQueryConfig<T, UserSettingsResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling putUserSetting.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/user_settings`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || UserSettingsToJSON(requestParameters.userSettings),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(UserSettingsResponseFromJSON(body), text);
    }

    return config;
}

/**
* Update or Create user settings values
* Update or Create user settings
*/
export function putUserSetting<T>(requestParameters: PutUserSettingRequest, requestConfig?: runtime.TypedQueryConfig<T, UserSettingsResponse>): QueryConfig<T> {
    return putUserSettingRaw(requestParameters, requestConfig);
}

