// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface OutputSubTypeSchemaProps
 */
export interface OutputSubTypeSchemaProps  {
    /**
     * 
     * @type {string}
     * @memberof OutputSubTypeSchemaProps
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof OutputSubTypeSchemaProps
     */
    precopy: string;
    /**
     * 
     * @type {string}
     * @memberof OutputSubTypeSchemaProps
     */
    helperText: string;
}

export function OutputSubTypeSchemaPropsFromJSON(json: any): OutputSubTypeSchemaProps {
    return {
        'key': json['key'],
        'precopy': json['precopy'],
        'helperText': json['helper_text'],
    };
}

export function OutputSubTypeSchemaPropsToJSON(value?: OutputSubTypeSchemaProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'key': value.key,
        'precopy': value.precopy,
        'helper_text': value.helperText,
    };
}


