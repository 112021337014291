// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';


/**
 * 
 * @export
 * @interface EpisodeSummaryCoreAllOf
 */
export interface EpisodeSummaryCoreAllOf  {
    /**
     * 
     * @type {Array<ActionableItemSummary>}
     * @memberof EpisodeSummaryCoreAllOf
     */
    actionableItems?: Array<ActionableItemSummary>;
    /**
     * 
     * @type {boolean}
     * @memberof EpisodeSummaryCoreAllOf
     */
    cctAssigned?: boolean;
}

export function EpisodeSummaryCoreAllOfFromJSON(json: any): EpisodeSummaryCoreAllOf {
    return {
        'actionableItems': !exists(json, 'actionable_items') ? undefined : (json['actionable_items'] as Array<any>).map(ActionableItemSummaryFromJSON),
        'cctAssigned': !exists(json, 'cct_assigned') ? undefined : json['cct_assigned'],
    };
}

export function EpisodeSummaryCoreAllOfToJSON(value?: EpisodeSummaryCoreAllOf): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'actionable_items': value.actionableItems === undefined ? undefined : (value.actionableItems as Array<any>).map(ActionableItemSummaryToJSON),
        'cct_assigned': value.cctAssigned,
    };
}


