// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActiveEpisodesForPortalResponse
 */
export interface ActiveEpisodesForPortalResponse  {
    /**
     * 
     * @type {number}
     * @memberof ActiveEpisodesForPortalResponse
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ActiveEpisodesForPortalResponse
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof ActiveEpisodesForPortalResponse
     */
    createdAt?: string;
}

export function ActiveEpisodesForPortalResponseFromJSON(json: any): ActiveEpisodesForPortalResponse {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'createdAt': !exists(json, 'created_at') ? undefined : json['created_at'],
    };
}

export function ActiveEpisodesForPortalResponseToJSON(value?: ActiveEpisodesForPortalResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'label': value.label,
        'created_at': value.createdAt,
    };
}


