// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InputServiceLineSchema,
    InputServiceLineSchemaFromJSON,
    InputServiceLineSchemaToJSON,
} from './InputServiceLineSchema';


/**
 * 
 * @export
 * @interface InputServiceMenuDocumentSchema
 */
export interface InputServiceMenuDocumentSchema  {
    /**
     * 
     * @type {string}
     * @memberof InputServiceMenuDocumentSchema
     */
    headline: string;
    /**
     * 
     * @type {Array<InputServiceLineSchema>}
     * @memberof InputServiceMenuDocumentSchema
     */
    serviceMenu: Array<InputServiceLineSchema>;
    /**
     * 
     * @type {Array<InputServiceLineSchema>}
     * @memberof InputServiceMenuDocumentSchema
     */
    other?: Array<InputServiceLineSchema>;
}

export function InputServiceMenuDocumentSchemaFromJSON(json: any): InputServiceMenuDocumentSchema {
    return {
        'headline': json['headline'],
        'serviceMenu': (json['service_menu'] as Array<any>).map(InputServiceLineSchemaFromJSON),
        'other': !exists(json, 'other') ? undefined : (json['other'] as Array<any>).map(InputServiceLineSchemaFromJSON),
    };
}

export function InputServiceMenuDocumentSchemaToJSON(value?: InputServiceMenuDocumentSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'headline': value.headline,
        'service_menu': (value.serviceMenu as Array<any>).map(InputServiceLineSchemaToJSON),
        'other': value.other === undefined ? undefined : (value.other as Array<any>).map(InputServiceLineSchemaToJSON),
    };
}


