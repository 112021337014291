// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlataImportLogSchema,
    SlataImportLogSchemaFromJSON,
    SlataImportLogSchemaToJSON,
} from './SlataImportLogSchema';


/**
 * 
 * @export
 * @interface SlataImportLogsResponse
 */
export interface SlataImportLogsResponse  {
    /**
     * 
     * @type {Array<SlataImportLogSchema>}
     * @memberof SlataImportLogsResponse
     */
    results?: Array<SlataImportLogSchema>;
}

export function SlataImportLogsResponseFromJSON(json: any): SlataImportLogsResponse {
    return {
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(SlataImportLogSchemaFromJSON),
    };
}

export function SlataImportLogsResponseToJSON(value?: SlataImportLogsResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(SlataImportLogSchemaToJSON),
    };
}


