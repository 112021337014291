/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    ListSubscriptionOptionsOutput,
    ListSubscriptionOptionsOutputFromJSON,
    ListSubscriptionOptionsOutputToJSON,
} from '../models/ListSubscriptionOptionsOutput'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'




export interface IMemberSubscriptionApi {
      getSubscriptions: () => Promise<Array<ListSubscriptionOptionsOutput>>
      getSubscriptionsWithResponse: () => Promise<{ value: Array<ListSubscriptionOptionsOutput>, response: any}>
}

/**
 * no description
 */
export class MemberSubscriptionApi extends runtime.BaseAPI {

    /**
     * Returns all potential subscription options in Stripe for display in the FE
     * Returns subscriptions from Stripe
     */
    getSubscriptionsRaw = async (): Promise<{ runtime: runtime.ApiResponse<Array<ListSubscriptionOptionsOutput>>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/subscriptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ListSubscriptionOptionsOutputFromJSON)), response };
    }

    /**
     * Returns all potential subscription options in Stripe for display in the FE
     * Returns subscriptions from Stripe
     */
    getSubscriptions = async (): Promise<Array<ListSubscriptionOptionsOutput>> => {
        const { runtime } = await this.getSubscriptionsRaw();
        return await runtime.value();
    }

    /**
     * Returns all potential subscription options in Stripe for display in the FE
     * Returns subscriptions from Stripe
     */
    getSubscriptionsWithResponse = async (): Promise<{ value: Array<ListSubscriptionOptionsOutput>, response: any}> => {
        const { runtime, response } = await this.getSubscriptionsRaw();
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchMemberSubscriptionApi: IMemberSubscriptionApi  = new MemberSubscriptionApi();
