// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GenericCMSListItemFields,
    GenericCMSListItemFieldsFromJSON,
    GenericCMSListItemFieldsToJSON,
} from './GenericCMSListItemFields';


/**
 * 
 * @export
 * @interface GenericCMSListItem
 */
export interface GenericCMSListItem  {
    /**
     * 
     * @type {string}
     * @memberof GenericCMSListItem
     */
    type?: string;
    /**
     * 
     * @type {Array<GenericCMSListItemFields>}
     * @memberof GenericCMSListItem
     */
    fields: Array<GenericCMSListItemFields>;
}

export function GenericCMSListItemFromJSON(json: any): GenericCMSListItem {
    return {
        'type': !exists(json, 'type') ? undefined : json['type'],
        'fields': (json['fields'] as Array<any>).map(GenericCMSListItemFieldsFromJSON),
    };
}

export function GenericCMSListItemToJSON(value?: GenericCMSListItem): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'type': value.type,
        'fields': (value.fields as Array<any>).map(GenericCMSListItemFieldsToJSON),
    };
}


