import { JsonUser as User } from "shared/api/src/models/JsonUser";
import { fetchUserFromToken } from "shared/api/src/apis/AccountsApi";
import isEqual from "lodash/isEqual";

const fetchUserFromTokenRequest = () => {
  return fetchUserFromToken({
    transform: (body: User) => ({ user: body }),
    update: {
      user: (prev: User, next: User) => {
        if (isEqual(prev, next)) {
          return prev;
        }

        return next;
      },
    },
  });
};

export default fetchUserFromTokenRequest;
