export const isEmailValid = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const isDateValid = (dob: string) => {
  return dob !== "Invalid Date";
};

export const isValidPhoneNumber = (phoneNumber: string) => {
  const validUSPhoneNum = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  let value = phoneNumber;
  if (!value) {
    return false;
  }

  if (value && !value.match(validUSPhoneNum)) {
    value = value.replace(/-|\s|\.|\(|\)/g, "");
  }

  const isValid = validUSPhoneNum.test(value);
  return isValid;
};
