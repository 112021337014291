// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateFeatureFlagInput
 */
export interface CreateFeatureFlagInput  {
    /**
     * 
     * @type {string}
     * @memberof CreateFeatureFlagInput
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateFeatureFlagInput
     */
    efId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateFeatureFlagInput
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateFeatureFlagInput
     */
    clinicId?: string;
}

export function CreateFeatureFlagInputFromJSON(json: any): CreateFeatureFlagInput {
    return {
        'name': json['name'],
        'efId': !exists(json, 'ef_id') ? undefined : json['ef_id'],
        'enabled': json['enabled'],
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
    };
}

export function CreateFeatureFlagInputToJSON(value?: CreateFeatureFlagInput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'ef_id': value.efId,
        'enabled': value.enabled,
        'clinic_id': value.clinicId,
    };
}


