import React, { forwardRef } from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import Tab from "components/Tabs/Tab";
import Badge from "components/Badge";

interface IProps {
  onClick: Function;
  index: number;
  active: boolean;
  label: string;
  episodesCount: number;
  view: string;
}

interface IStyleProps {
  active: boolean;
}

const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    root: {
      // height: 25,
      display: "flex",
      flexDirection: "row",
      '& [class*="MuiTab-root"]': {
        backgroundColor: ({ active }: IStyleProps) =>
          active ? palette.secondary.main : palette?.lightGrayBackdrop,
      },
    },
    container: {
      height: 25,
      display: "flex",
      flexDirection: "row",
    },
    containerActive: {
      backgroundColor: palette.primary.main,
      color: "white",
    },
    tabActive: {
      color: palette.primary.main,
      "& .MuiTab-root": {
        backgroundColor: palette.white,
      },
    },
    badge: {
      marginLeft: spacing(-0.5),
      marginTop: spacing(1),
    },
    badgeContent: {
      height: 16,
      minWidth: 16,
      fontSize: "9.2px",
      fontWeight: 700,
      lineHeight: "9.2px",
    },
  })
);

const BadgeFilterTab = forwardRef<HTMLDivElement, IProps>(
  ({ onClick, index, label, episodesCount, view, active }, ref) => {
    const classes = useStyles({ active });

    const showBadge = episodesCount > 0 && view === "queue";
    const testId = label?.toLowerCase();

    return (
      <div ref={ref} className={classes.root}>
        <Tab
          aria-label={`${label} tab`}
          value={index}
          label={label}
          onClick={() => onClick(index)}
          tabIndex={0}
          data-testid={`${testId}-filter`}
          className={classes.root}
        />
        {showBadge && (
          <Badge
            color="primary"
            badgeContent={episodesCount}
            data-e2e="episodes-indicator-badge"
            className={classes.badge}
            classes={{ badge: classes.badgeContent }}
          />
        )}
      </div>
    );
  }
);

BadgeFilterTab.displayName = "BadgeFilterTab";

export default BadgeFilterTab;
