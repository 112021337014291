// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportingMappingObject,
    ReportingMappingObjectFromJSON,
    ReportingMappingObjectToJSON,
} from './ReportingMappingObject';


/**
 * 
 * @export
 * @interface MemberAppointmentTypesSchema
 */
export interface MemberAppointmentTypesSchema  {
    /**
     * appointment type id
     * @type {number}
     * @memberof MemberAppointmentTypesSchema
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberAppointmentTypesSchema
     */
    uses?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberAppointmentTypesSchema
     */
    careDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAppointmentTypesSchema
     */
    iconName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAppointmentTypesSchema
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAppointmentTypesSchema
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAppointmentTypesSchema
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAppointmentTypesSchema
     */
    method?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAppointmentTypesSchema
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAppointmentTypesSchema
     */
    receiptCtg?: string;
    /**
     * 
     * @type {ReportingMappingObject}
     * @memberof MemberAppointmentTypesSchema
     */
    reportingMapping?: ReportingMappingObject;
    /**
     * 
     * @type {string}
     * @memberof MemberAppointmentTypesSchema
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAppointmentTypesSchema
     */
    modality?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAppointmentTypesSchema
     */
    type?: string;
}

export function MemberAppointmentTypesSchemaFromJSON(json: any): MemberAppointmentTypesSchema {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'uses': !exists(json, 'uses') ? undefined : json['uses'],
        'careDescription': !exists(json, 'care_description') ? undefined : json['care_description'],
        'iconName': !exists(json, 'icon_name') ? undefined : json['icon_name'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'method': !exists(json, 'method') ? undefined : json['method'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'receiptCtg': !exists(json, 'receipt_ctg') ? undefined : json['receipt_ctg'],
        'reportingMapping': !exists(json, 'reporting_mapping') ? undefined : ReportingMappingObjectFromJSON(json['reporting_mapping']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'modality': !exists(json, 'modality') ? undefined : json['modality'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function MemberAppointmentTypesSchemaToJSON(value?: MemberAppointmentTypesSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'uses': value.uses,
        'care_description': value.careDescription,
        'icon_name': value.iconName,
        'name': value.name,
        'note': value.note,
        'color': value.color,
        'method': value.method,
        'label': value.label,
        'receipt_ctg': value.receiptCtg,
        'reporting_mapping': ReportingMappingObjectToJSON(value.reportingMapping),
        'description': value.description,
        'modality': value.modality,
        'type': value.type,
    };
}


