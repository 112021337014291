import { CreateAppointmentVideoConferenceRequest, createAppointmentVideoConference } from "shared/api/src/apis/VideoConferenceApi";
import { AppointmentOutput } from "shared/api/src/models/AppointmentOutput";

export const createAppointmentVideoConferenceMutation = (
  payload: CreateAppointmentVideoConferenceRequest
) =>
  createAppointmentVideoConference(
    payload,

    {
      transform: (responseBody: AppointmentOutput) => ({
        appointmentVideoConference: responseBody,
      }),
      update: {
        appointmentVideoConference: (prev: any, next: any) => next,
      },
    }
  );
