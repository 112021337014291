import React from "react";
import Box from "components/Box";
import Container from "components/Container";
import Grid from "components/Grid";

import Skeleton from ".";
import CardSkeleton from "./CardSkeleton";
import palette from "styles/mui/palette";

const InlineMessageSkeleton = () => (
  <div style={{ width: "100%" }}>
    <Container
      style={{
        position: "relative",
        zIndex: 1,
        paddingRight: 0,
        paddingLeft: 0,
        marginTop: "-20px",
      }}
    >
      <Box pt={2}>
        <CardSkeleton style={{ marginTop: 0 }}>
          <Grid
            container
            style={{ borderBottom: `1px solid ${palette.strokes.divider}` }}
          >
            <Grid item xs={12} sm={4}>
              <Skeleton width="80%" height={40} />
              <Skeleton width="40%" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Skeleton width="80%" height={40} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Skeleton width="80%" height={40} />
            </Grid>
          </Grid>

          <div style={{ paddingTop: "10px" }}>
            <Skeleton width="10%" />
            <Skeleton width="80%" />
            <Skeleton width="80%" />
          </div>
        </CardSkeleton>
      </Box>
    </Container>
  </div>
);

export default InlineMessageSkeleton;
