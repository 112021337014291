/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    ListMembersResponse,
    ListMembersResponseFromJSON,
    ListMembersResponseToJSON,
} from '../models/ListMembersResponse'
import {
    MemberSearchResults,
    MemberSearchResultsFromJSON,
    MemberSearchResultsToJSON,
} from '../models/MemberSearchResults'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface ListMembersRequest {
    q: string;
    format: ListMembersFormatEnum;
    page?: string;
}
export interface MemberSearchRequest {
    efId: string;
    q: string;
}


export interface IMemberSearchResultsApi {
      listMembers: (requestParameters: ListMembersRequest) => Promise<ListMembersResponse>
      memberSearch: (requestParameters: MemberSearchRequest) => Promise<MemberSearchResults>
      listMembersWithResponse: (requestParameters: ListMembersRequest) => Promise<{ value: ListMembersResponse, response: any}>
      memberSearchWithResponse: (requestParameters: MemberSearchRequest) => Promise<{ value: MemberSearchResults, response: any}>
}

/**
 * no description
 */
export class MemberSearchResultsApi extends runtime.BaseAPI {

    /**
     * List members (both quick search field and full search)
     * List members
     */
    listMembersRaw = async (requestParameters: ListMembersRequest): Promise<{ runtime: runtime.ApiResponse<ListMembersResponse>, response: any}> => {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling listMembers.');
        }

                        if (requestParameters.format === null || requestParameters.format === undefined) {
            throw new runtime.RequiredError('format','Required parameter requestParameters.format was null or undefined when calling listMembers.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.format !== undefined) {
            queryParameters['format'] = requestParameters.format;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ListMembersResponseFromJSON(jsonValue)), response };
    }

    /**
     * List members (both quick search field and full search)
     * List members
     */
    listMembers = async (requestParameters: ListMembersRequest): Promise<ListMembersResponse> => {
        const { runtime } = await this.listMembersRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * List members (both quick search field and full search)
     * List members
     */
    listMembersWithResponse = async (requestParameters: ListMembersRequest): Promise<{ value: ListMembersResponse, response: any}> => {
        const { runtime, response } = await this.listMembersRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * This endpoint provides a search for members using (first_name, last_name, preferred_name, Dob)
     * Members Search Endpoint
     */
    memberSearchRaw = async (requestParameters: MemberSearchRequest): Promise<{ runtime: runtime.ApiResponse<MemberSearchResults>, response: any}> => {
        if (requestParameters.efId === null || requestParameters.efId === undefined) {
            throw new runtime.RequiredError('efId','Required parameter requestParameters.efId was null or undefined when calling memberSearch.');
        }

                        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling memberSearch.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/search/{ef_id}`.replace(`{${"ef_id"}}`, encodeURIComponent(String(requestParameters.efId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberSearchResultsFromJSON(jsonValue)), response };
    }

    /**
     * This endpoint provides a search for members using (first_name, last_name, preferred_name, Dob)
     * Members Search Endpoint
     */
    memberSearch = async (requestParameters: MemberSearchRequest): Promise<MemberSearchResults> => {
        const { runtime } = await this.memberSearchRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * This endpoint provides a search for members using (first_name, last_name, preferred_name, Dob)
     * Members Search Endpoint
     */
    memberSearchWithResponse = async (requestParameters: MemberSearchRequest): Promise<{ value: MemberSearchResults, response: any}> => {
        const { runtime, response } = await this.memberSearchRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

/**
    * @export
    * @enum {string}
    */
export enum ListMembersFormatEnum {
    Quick = 'quick',
    Full = 'full'
}

export const fetchMemberSearchResultsApi: IMemberSearchResultsApi  = new MemberSearchResultsApi();
