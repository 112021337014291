import { useQuery } from "react-query";
import {
  GetDailyScheduleRequest,
  fetchDailyScheduleApi,
} from "shared/fetch/src/apis/DailyScheduleApi";

export const getDailyScheduleQueryKey = (
  requestParameters: GetDailyScheduleRequest
) => {
  return ["dailySchedule", requestParameters.site, requestParameters.date];
};

const useGetDailySchedule = (requestParameters: GetDailyScheduleRequest) => {
  const { data, isLoading } = useQuery(
    getDailyScheduleQueryKey(requestParameters),
    () => fetchDailyScheduleApi.getDailySchedule(requestParameters)
  );

  return {
    schedule: data?.list,
    isLoading,
  };
};

export default useGetDailySchedule;
