// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResetUserPasswordRequestBody
 */
export interface ResetUserPasswordRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof ResetUserPasswordRequestBody
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof ResetUserPasswordRequestBody
     */
    password: string;
}

export function ResetUserPasswordRequestBodyFromJSON(json: any): ResetUserPasswordRequestBody {
    return {
        'code': json['code'],
        'password': json['password'],
    };
}

export function ResetUserPasswordRequestBodyToJSON(value?: ResetUserPasswordRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'code': value.code,
        'password': value.password,
    };
}


