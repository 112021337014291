import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";
import { selectNativeAppState } from "shared/state/nativeApp/selectors";
import {
  UnenrollDevicePushNotificationsRequest,
  fetchUserPushNotificationsApi,
} from "shared/fetch/src/apis/UserPushNotificationsApi";

const useUnenrollDevicePushNotifications = () => {
  const { deviceId } = selectNativeAppState();

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (request: UnenrollDevicePushNotificationsRequest) => {
      return fetchUserPushNotificationsApi
        .unenrollDevicePushNotifications(request)
        .then(() => {
          queryClient.invalidateQueries([
            "pushNotificationCategories",
            deviceId,
          ]);

          dispatch(
            showSnackbar("Device has been unenrolled from push notifications.")
          );
        })
        .catch(() => {
          dispatch(
            showSnackbar(
              "There was an issue unenrolling your device, please try again later.",
              "danger"
            )
          );
        });
    }
  );
  return { unenrollDevicePushNotifications: mutate };
};

export default useUnenrollDevicePushNotifications;
