import { useQuery } from "react-query";
import { GetLabXOPRequest, fetchLabsApi } from "shared/fetch/src/apis/LabsApi";

export const memberLabXOPQueryKey = (id?: string | number, labId?: string) => [
  "memberLabXOP",
  id?.toString(),
  labId,
];

const useGetMemberLabXOP = (request: GetLabXOPRequest) => {
  const query = useQuery(
    memberLabXOPQueryKey(request.id, request.labId),
    () => fetchLabsApi.getLabXOP(request),
    {
      notifyOnChangeProps: ["data"],
      enabled: Boolean(request.id?.length) && Boolean(request.labId?.length),
      staleTime: 300000,
      // cacheTime: 600000,
    }
  );
  return query;
};

export default useGetMemberLabXOP;
