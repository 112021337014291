// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfigResponseSignup
 */
export interface ConfigResponseSignup  {
    /**
     * 
     * @type {string}
     * @memberof ConfigResponseSignup
     */
    headline?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigResponseSignup
     */
    subhead?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigResponseSignup
     */
    button?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigResponseSignup
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigResponseSignup
     */
    forcePatientSubhead?: string;
}

export function ConfigResponseSignupFromJSON(json: any): ConfigResponseSignup {
    return {
        'headline': !exists(json, 'headline') ? undefined : json['headline'],
        'subhead': !exists(json, 'subhead') ? undefined : json['subhead'],
        'button': !exists(json, 'button') ? undefined : json['button'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'forcePatientSubhead': !exists(json, 'force_patient_subhead') ? undefined : json['force_patient_subhead'],
    };
}

export function ConfigResponseSignupToJSON(value?: ConfigResponseSignup): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'headline': value.headline,
        'subhead': value.subhead,
        'button': value.button,
        'type': value.type,
        'force_patient_subhead': value.forcePatientSubhead,
    };
}


