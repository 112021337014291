// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ICD10Code,
    ICD10CodeFromJSON,
    ICD10CodeToJSON,
} from './ICD10Code';


/**
 * 
 * @export
 * @interface ListICD10CodeResponse
 */
export interface ListICD10CodeResponse  {
    /**
     * 
     * @type {number}
     * @memberof ListICD10CodeResponse
     */
    count?: number;
    /**
     * 
     * @type {Array<ICD10Code>}
     * @memberof ListICD10CodeResponse
     */
    results: Array<ICD10Code>;
}

export function ListICD10CodeResponseFromJSON(json: any): ListICD10CodeResponse {
    return {
        'count': !exists(json, 'count') ? undefined : json['count'],
        'results': (json['results'] as Array<any>).map(ICD10CodeFromJSON),
    };
}

export function ListICD10CodeResponseToJSON(value?: ListICD10CodeResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'results': (value.results as Array<any>).map(ICD10CodeToJSON),
    };
}


