// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';
import {
     CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
     EpisodeSummaryCoreAllOf,
    EpisodeSummaryCoreAllOfFromJSON,
    EpisodeSummaryCoreAllOfToJSON,
} from './EpisodeSummaryCoreAllOf';
import {
     EpisodeSummaryCoreOutput,
    EpisodeSummaryCoreOutputFromJSON,
    EpisodeSummaryCoreOutputToJSON,
} from './EpisodeSummaryCoreOutput';
import {
     EpisodeSummaryCoreOutputMarketingMessage,
    EpisodeSummaryCoreOutputMarketingMessageFromJSON,
    EpisodeSummaryCoreOutputMarketingMessageToJSON,
} from './EpisodeSummaryCoreOutputMarketingMessage';
import {
     MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';
import {
     RoleOutputCoreSchema,
    RoleOutputCoreSchemaFromJSON,
    RoleOutputCoreSchemaToJSON,
} from './RoleOutputCoreSchema';
/**
 * @type EpisodeSummaryCore
 * @export
 */
export interface EpisodeSummaryCore extends EpisodeSummaryCoreAllOf, EpisodeSummaryCoreOutput {
}

export function EpisodeSummaryCoreFromJSON(json: any): EpisodeSummaryCore {
    return {
        ...EpisodeSummaryCoreAllOfFromJSON(json),
        ...EpisodeSummaryCoreOutputFromJSON(json),
    };
}

export function EpisodeSummaryCoreToJSON(value?: EpisodeSummaryCore): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...EpisodeSummaryCoreAllOfToJSON(value),
        ...EpisodeSummaryCoreOutputToJSON(value),
    };
}

