/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CheckinOutput,
    CheckinOutputFromJSON,
    CheckinOutputToJSON,
} from '../models/CheckinOutput'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    OutstandingItems,
    OutstandingItemsFromJSON,
    OutstandingItemsToJSON,
} from '../models/OutstandingItems'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpdateCheckin,
    UpdateCheckinFromJSON,
    UpdateCheckinToJSON,
} from '../models/UpdateCheckin'


export interface CompleteCheckinRequest {
    id: string;
}
export interface FastTrackCheckinRequest {
    id: string;
}
export interface GetCheckinRequest {
    id: string;
}
export interface GetOrCreateXoCheckinRequest {
    id: string;
}
export interface ListOutstandingCheckinItemsRequest {
    id: string;
}
export interface UpdateCheckinStepRequest {
    id: string;
    updateCheckin: UpdateCheckin;
}
export interface XOAcknowledgeCheckinRequest {
    id: string;
}


export interface ICheckinApi {
      completeCheckin: (requestParameters: CompleteCheckinRequest) => Promise<CheckinOutput>
      fastTrackCheckin: (requestParameters: FastTrackCheckinRequest) => Promise<CheckinOutput>
      getCheckin: (requestParameters: GetCheckinRequest) => Promise<CheckinOutput>
      getOrCreateXoCheckin: (requestParameters: GetOrCreateXoCheckinRequest) => Promise<CheckinOutput>
      listOutstandingCheckinItems: (requestParameters: ListOutstandingCheckinItemsRequest) => Promise<OutstandingItems>
      updateCheckinStep: (requestParameters: UpdateCheckinStepRequest) => Promise<CheckinOutput>
      xOAcknowledgeCheckin: (requestParameters: XOAcknowledgeCheckinRequest) => Promise<CheckinOutput>
      completeCheckinWithResponse: (requestParameters: CompleteCheckinRequest) => Promise<{ value: CheckinOutput, response: any}>
      fastTrackCheckinWithResponse: (requestParameters: FastTrackCheckinRequest) => Promise<{ value: CheckinOutput, response: any}>
      getCheckinWithResponse: (requestParameters: GetCheckinRequest) => Promise<{ value: CheckinOutput, response: any}>
      getOrCreateXoCheckinWithResponse: (requestParameters: GetOrCreateXoCheckinRequest) => Promise<{ value: CheckinOutput, response: any}>
      listOutstandingCheckinItemsWithResponse: (requestParameters: ListOutstandingCheckinItemsRequest) => Promise<{ value: OutstandingItems, response: any}>
      updateCheckinStepWithResponse: (requestParameters: UpdateCheckinStepRequest) => Promise<{ value: CheckinOutput, response: any}>
      xOAcknowledgeCheckinWithResponse: (requestParameters: XOAcknowledgeCheckinRequest) => Promise<{ value: CheckinOutput, response: any}>
}

/**
 * no description
 */
export class CheckinApi extends runtime.BaseAPI {

    /**
     * completes checkin
     * completes checkin
     */
    completeCheckinRaw = async (requestParameters: CompleteCheckinRequest): Promise<{ runtime: runtime.ApiResponse<CheckinOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling completeCheckin.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/appointments/{id}/complete_checkin`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CheckinOutputFromJSON(jsonValue)), response };
    }

    /**
     * completes checkin
     * completes checkin
     */
    completeCheckin = async (requestParameters: CompleteCheckinRequest): Promise<CheckinOutput> => {
        const { runtime } = await this.completeCheckinRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * completes checkin
     * completes checkin
     */
    completeCheckinWithResponse = async (requestParameters: CompleteCheckinRequest): Promise<{ value: CheckinOutput, response: any}> => {
        const { runtime, response } = await this.completeCheckinRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Forces the checkin by a CTM
     * Forces the checkin by a CTM
     */
    fastTrackCheckinRaw = async (requestParameters: FastTrackCheckinRequest): Promise<{ runtime: runtime.ApiResponse<CheckinOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fastTrackCheckin.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/{id}/fast_track_checkin`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CheckinOutputFromJSON(jsonValue)), response };
    }

    /**
     * Forces the checkin by a CTM
     * Forces the checkin by a CTM
     */
    fastTrackCheckin = async (requestParameters: FastTrackCheckinRequest): Promise<CheckinOutput> => {
        const { runtime } = await this.fastTrackCheckinRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Forces the checkin by a CTM
     * Forces the checkin by a CTM
     */
    fastTrackCheckinWithResponse = async (requestParameters: FastTrackCheckinRequest): Promise<{ value: CheckinOutput, response: any}> => {
        const { runtime, response } = await this.fastTrackCheckinRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * get checkin
     * get checkin
     */
    getCheckinRaw = async (requestParameters: GetCheckinRequest): Promise<{ runtime: runtime.ApiResponse<CheckinOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCheckin.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/appointments/{id}/checkin`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CheckinOutputFromJSON(jsonValue)), response };
    }

    /**
     * get checkin
     * get checkin
     */
    getCheckin = async (requestParameters: GetCheckinRequest): Promise<CheckinOutput> => {
        const { runtime } = await this.getCheckinRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * get checkin
     * get checkin
     */
    getCheckinWithResponse = async (requestParameters: GetCheckinRequest): Promise<{ value: CheckinOutput, response: any}> => {
        const { runtime, response } = await this.getCheckinRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get or create checkin for an appointment
     * Get or create checkin for an appointment
     */
    getOrCreateXoCheckinRaw = async (requestParameters: GetOrCreateXoCheckinRequest): Promise<{ runtime: runtime.ApiResponse<CheckinOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOrCreateXoCheckin.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/{id}/checkin`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CheckinOutputFromJSON(jsonValue)), response };
    }

    /**
     * Get or create checkin for an appointment
     * Get or create checkin for an appointment
     */
    getOrCreateXoCheckin = async (requestParameters: GetOrCreateXoCheckinRequest): Promise<CheckinOutput> => {
        const { runtime } = await this.getOrCreateXoCheckinRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get or create checkin for an appointment
     * Get or create checkin for an appointment
     */
    getOrCreateXoCheckinWithResponse = async (requestParameters: GetOrCreateXoCheckinRequest): Promise<{ value: CheckinOutput, response: any}> => {
        const { runtime, response } = await this.getOrCreateXoCheckinRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * List Checkin outstanding items (consents, calculators) by a CTM
     * List Checkin outstanding items by a CTM
     */
    listOutstandingCheckinItemsRaw = async (requestParameters: ListOutstandingCheckinItemsRequest): Promise<{ runtime: runtime.ApiResponse<OutstandingItems>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling listOutstandingCheckinItems.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/appointments/{id}/fast_track_checkin_outstanding_items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OutstandingItemsFromJSON(jsonValue)), response };
    }

    /**
     * List Checkin outstanding items (consents, calculators) by a CTM
     * List Checkin outstanding items by a CTM
     */
    listOutstandingCheckinItems = async (requestParameters: ListOutstandingCheckinItemsRequest): Promise<OutstandingItems> => {
        const { runtime } = await this.listOutstandingCheckinItemsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * List Checkin outstanding items (consents, calculators) by a CTM
     * List Checkin outstanding items by a CTM
     */
    listOutstandingCheckinItemsWithResponse = async (requestParameters: ListOutstandingCheckinItemsRequest): Promise<{ value: OutstandingItems, response: any}> => {
        const { runtime, response } = await this.listOutstandingCheckinItemsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * updates checkin
     * updates checkin
     */
    updateCheckinStepRaw = async (requestParameters: UpdateCheckinStepRequest): Promise<{ runtime: runtime.ApiResponse<CheckinOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCheckinStep.');
        }

                        if (requestParameters.updateCheckin === null || requestParameters.updateCheckin === undefined) {
            throw new runtime.RequiredError('updateCheckin','Required parameter requestParameters.updateCheckin was null or undefined when calling updateCheckinStep.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/appointments/{id}/checkin`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCheckinToJSON(requestParameters.updateCheckin),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CheckinOutputFromJSON(jsonValue)), response };
    }

    /**
     * updates checkin
     * updates checkin
     */
    updateCheckinStep = async (requestParameters: UpdateCheckinStepRequest): Promise<CheckinOutput> => {
        const { runtime } = await this.updateCheckinStepRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * updates checkin
     * updates checkin
     */
    updateCheckinStepWithResponse = async (requestParameters: UpdateCheckinStepRequest): Promise<{ value: CheckinOutput, response: any}> => {
        const { runtime, response } = await this.updateCheckinStepRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Acknowledge a checkin by CTM
     * Acknowledge a checkin by CTM
     */
    xOAcknowledgeCheckinRaw = async (requestParameters: XOAcknowledgeCheckinRequest): Promise<{ runtime: runtime.ApiResponse<CheckinOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling xOAcknowledgeCheckin.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/{id}/check_in_acknowledge`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CheckinOutputFromJSON(jsonValue)), response };
    }

    /**
     * Acknowledge a checkin by CTM
     * Acknowledge a checkin by CTM
     */
    xOAcknowledgeCheckin = async (requestParameters: XOAcknowledgeCheckinRequest): Promise<CheckinOutput> => {
        const { runtime } = await this.xOAcknowledgeCheckinRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Acknowledge a checkin by CTM
     * Acknowledge a checkin by CTM
     */
    xOAcknowledgeCheckinWithResponse = async (requestParameters: XOAcknowledgeCheckinRequest): Promise<{ value: CheckinOutput, response: any}> => {
        const { runtime, response } = await this.xOAcknowledgeCheckinRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchCheckinApi: ICheckinApi  = new CheckinApi();
