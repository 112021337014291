// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ReportingMappingObjectElse,
    ReportingMappingObjectElseFromJSON,
    ReportingMappingObjectElseToJSON,
} from './ReportingMappingObjectElse';


/**
 * 
 * @export
 * @interface ReportingMappingObject
 */
export interface ReportingMappingObject  {
    /**
     * 
     * @type {ReportingMappingObjectElse}
     * @memberof ReportingMappingObject
     */
    _else?: ReportingMappingObjectElse;
}

export function ReportingMappingObjectFromJSON(json: any): ReportingMappingObject {
    return {
        '_else': !exists(json, 'else') ? undefined : ReportingMappingObjectElseFromJSON(json['else']),
    };
}

export function ReportingMappingObjectToJSON(value?: ReportingMappingObject): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'else': ReportingMappingObjectElseToJSON(value._else),
    };
}


