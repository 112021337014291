// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VideoConferenceOutput
 */
export interface VideoConferenceOutput  {
    /**
     * 
     * @type {string}
     * @memberof VideoConferenceOutput
     */
    url?: string;
    /**
     * 
     * @type {string}
     * @memberof VideoConferenceOutput
     */
    passcode?: string;
}

export function VideoConferenceOutputFromJSON(json: any): VideoConferenceOutput {
    return {
        'url': !exists(json, 'url') ? undefined : json['url'],
        'passcode': !exists(json, 'passcode') ? undefined : json['passcode'],
    };
}

export function VideoConferenceOutputToJSON(value?: VideoConferenceOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'url': value.url,
        'passcode': value.passcode,
    };
}


