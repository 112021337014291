import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(({ spacing, palette }: Theme) =>
  createStyles({
    connectionText: {
      marginTop: 40,
    },
    headerMarginBottom20: {
      marginBottom: 20,
    },
    fontNormal: {
      fontWeight: "normal",
    },
    icon: {
      color: "#0B665C",
      marginRight: 10,
    },
    marginTop10: {
      marginTop: 10,
    },
    missedBtnGroup: {
      marginTop: 25,
    },
    missedRescheduleButton: {
      marginRight: spacing(3),
    },
    rescheduleButton: {
      marginRight: spacing(3),
    },
    tipsParagraph: {
      "& a.tips-link": {
        textDecoration: "underline",
        cursor: "pointer",
        "&:hover": {
          textDecoration: "underline",
          color: "rgb(141, 44, 2)",
        },
      },
    },
    zoomInfoContainer: {
      display: "flex",
      flexDirection: "column",
      marginBottom: 16,
    },
    checkinCopy: {
      marginTop: spacing(2),
      marginBottom: spacing(2),
      color: palette.text.primary,
    },
    checkinHeader: {
      marginBottom: spacing(1),
    },
    checkinFooter: {
      marginTop: spacing(1),
    },
    checkinButtonWithBorder: {
      marginTop: spacing(1.5),
      marginBottom: spacing(3),
    },
    textColor: {
      color: palette.text.primary,
    },
  })
);

export default useStyles;
