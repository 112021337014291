// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StripeProduct,
    StripeProductFromJSON,
    StripeProductToJSON,
} from './StripeProduct';


/**
 * 
 * @export
 * @interface StripePlan
 */
export interface StripePlan  {
    /**
     * 
     * @type {boolean}
     * @memberof StripePlan
     */
    active?: boolean;
    /**
     * 
     * @type {number}
     * @memberof StripePlan
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof StripePlan
     */
    amountDecimal?: string;
    /**
     * 
     * @type {string}
     * @memberof StripePlan
     */
    billingScheme?: string;
    /**
     * Time at which the object was created. Measured in seconds since the Unix epoch.
     * @type {number}
     * @memberof StripePlan
     */
    created?: number;
    /**
     * Three-letter [ISO currency code](https://www.iso.org/iso-4217-currency-codes.html), in lowercase. Must be a [supported currency](https://stripe.com/docs/currencies).
     * @type {string}
     * @memberof StripePlan
     */
    currency?: string;
    /**
     * Unique identifier for the object.
     * @type {string}
     * @memberof StripePlan
     */
    id?: string;
    /**
     * The frequency at which a subscription is billed. One of `day`, `week`, `month` or `year`.
     * @type {string}
     * @memberof StripePlan
     */
    interval?: string;
    /**
     * 
     * @type {StripeProduct}
     * @memberof StripePlan
     */
    product?: StripeProduct;
}

export function StripePlanFromJSON(json: any): StripePlan {
    return {
        'active': !exists(json, 'active') ? undefined : json['active'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'amountDecimal': !exists(json, 'amount_decimal') ? undefined : json['amount_decimal'],
        'billingScheme': !exists(json, 'billing_scheme') ? undefined : json['billing_scheme'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'interval': !exists(json, 'interval') ? undefined : json['interval'],
        'product': !exists(json, 'product') ? undefined : StripeProductFromJSON(json['product']),
    };
}

export function StripePlanToJSON(value?: StripePlan): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'active': value.active,
        'amount': value.amount,
        'amount_decimal': value.amountDecimal,
        'billing_scheme': value.billingScheme,
        'created': value.created,
        'currency': value.currency,
        'id': value.id,
        'interval': value.interval,
        'product': StripeProductToJSON(value.product),
    };
}


