// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    XopQuestionCoreSchema,
    XopQuestionCoreSchemaFromJSON,
    XopQuestionCoreSchemaToJSON,
} from './XopQuestionCoreSchema';


/**
 * 
 * @export
 * @interface XopQuestionSetCoreOutputSchema
 */
export interface XopQuestionSetCoreOutputSchema  {
    /**
     * 
     * @type {string}
     * @memberof XopQuestionSetCoreOutputSchema
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof XopQuestionSetCoreOutputSchema
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof XopQuestionSetCoreOutputSchema
     */
    category?: string;
    /**
     * 
     * @type {number}
     * @memberof XopQuestionSetCoreOutputSchema
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof XopQuestionSetCoreOutputSchema
     */
    version?: number;
    /**
     * 
     * @type {Array<XopQuestionCoreSchema>}
     * @memberof XopQuestionSetCoreOutputSchema
     */
    questions?: Array<XopQuestionCoreSchema>;
}

export function XopQuestionSetCoreOutputSchemaFromJSON(json: any): XopQuestionSetCoreOutputSchema {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'questions': !exists(json, 'questions') ? undefined : (json['questions'] as Array<any>).map(XopQuestionCoreSchemaFromJSON),
    };
}

export function XopQuestionSetCoreOutputSchemaToJSON(value?: XopQuestionSetCoreOutputSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'title': value.title,
        'category': value.category,
        'count': value.count,
        'version': value.version,
        'questions': value.questions === undefined ? undefined : (value.questions as Array<any>).map(XopQuestionCoreSchemaToJSON),
    };
}


