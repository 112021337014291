// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchEligibilityRequestBody
 */
export interface SearchEligibilityRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof SearchEligibilityRequestBody
     */
    workEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchEligibilityRequestBody
     */
    employeeId?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchEligibilityRequestBody
     */
    ssn?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchEligibilityRequestBody
     */
    dob: string;
    /**
     * 
     * @type {string}
     * @memberof SearchEligibilityRequestBody
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof SearchEligibilityRequestBody
     */
    employer?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchEligibilityRequestBody
     */
    subscriberId?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchEligibilityRequestBody
     */
    extraRegistrationId?: string;
}

export function SearchEligibilityRequestBodyFromJSON(json: any): SearchEligibilityRequestBody {
    return {
        'workEmail': !exists(json, 'work_email') ? undefined : json['work_email'],
        'employeeId': !exists(json, 'employee_id') ? undefined : json['employee_id'],
        'ssn': !exists(json, 'ssn') ? undefined : json['ssn'],
        'dob': json['dob'],
        'lastName': json['last_name'],
        'employer': !exists(json, 'employer') ? undefined : json['employer'],
        'subscriberId': !exists(json, 'subscriber_id') ? undefined : json['subscriber_id'],
        'extraRegistrationId': !exists(json, 'extra_registration_id') ? undefined : json['extra_registration_id'],
    };
}

export function SearchEligibilityRequestBodyToJSON(value?: SearchEligibilityRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'work_email': value.workEmail,
        'employee_id': value.employeeId,
        'ssn': value.ssn,
        'dob': value.dob,
        'last_name': value.lastName,
        'employer': value.employer,
        'subscriber_id': value.subscriberId,
        'extra_registration_id': value.extraRegistrationId,
    };
}


