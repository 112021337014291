import React, { FC } from "react";
import { useTheme, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import clsx from "clsx";
import FlagIcon from "@mui/icons-material/Flag";
import Typography from "components/Typography";

interface IProps {
  lengthOfTime: string;
  timeUnit: string;
  styleName?: string;
  timeSensitive?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    timeSinceContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    lengthOfTime: {
      fontWeight: "bold",
      margin: 0,
      fontSize: 19,
      lineHeight: "22px",
    },
    timeUnit: {
      fontWeight: "bold",
      fontSize: 14,
      margin: 0,
      textAlign: "center",
    },
  })
);

const EOCTimeSince: FC<IProps> = ({
  lengthOfTime,
  timeUnit,
  timeSensitive,
  styleName = "",
}) => {
  const classes = useStyles();
  const { palette } = useTheme<Theme>();
  return (
    <section className={classes.timeSinceContainer}>
      <Typography
        variant="body1"
        className={clsx(classes.lengthOfTime, styleName)}
      >
        <span>{lengthOfTime}</span>
        {timeSensitive && (
          <FlagIcon
            fontSize="small"
            titleAccess="Time time sensitive flag"
            htmlColor={palette.redHue}
          />
        )}
      </Typography>
      <Typography className={clsx(classes.timeUnit, styleName)}>
        {timeUnit}
      </Typography>
    </section>
  );
};

export default EOCTimeSince;
