import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "react-query";
import { getUser } from "shared/features/user";
import { updateEntities } from "redux-query";
import {
  setMemberUserFeatureFlags,
  setLoggedInUserFeatureFlags,
  clearMemberUserFeaturesFlags,
} from "shared/state/featureFlags";
import { State } from "shared/types";
import isEqual from "lodash/isEqual";
import { fetchFeatureFlagsApi } from "shared/fetch/src/apis/FeatureFlagsApi";

interface IOptions {
  ctmOnly?: boolean;
  clearOnUnmount?: boolean;
}

export default (memberId: string, { ctmOnly = true }: IOptions = {}) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const currentMemberFeatures = useSelector(
    (state: State) => state.featureFlags.memberUserFeatureFlags
  );

  if (ctmOnly && !(user?.admin || user?.ctm)) {
    return {};
  }

  const { data, isLoading } = useUserFeatureFlags(memberId);

  if (data?.results && !isEqual(data.results, currentMemberFeatures)) {
    dispatch(setMemberUserFeatureFlags(data.results, memberId));
  }

  useEffect(() => {
    return () => {
      dispatch(clearMemberUserFeaturesFlags());
    };
  }, []);

  return { featureFlags: data?.results || [], isPending: isLoading };
};

export const featureFlagForUserKey = (id: string | number) => [
  "featureFlags",
  id.toString(),
];

export const featureFlagKey = ["featureFlags"];

export const useUserFeatureFlags = (memberId: string) => {
  return useQuery(
    featureFlagForUserKey(memberId),
    () => fetchFeatureFlagsApi.fetchFeatureFlagsForAUser({ id: memberId }),
    {
      cacheTime: 15 * (1000 * 60),
      staleTime: 10 * (1000 * 60),
    }
  );
};

export const useFeatureFlags = () => {
  const dispatch = useDispatch();
  return useQuery(
    featureFlagKey,
    () =>
      fetchFeatureFlagsApi.fetchFeatureFlags().then((data) => {
        dispatch(
          updateEntities({
            featureFlags: (_prev: any) => data?.results,
          })
        );
        if (data?.results) {
          dispatch(setLoggedInUserFeatureFlags(data.results));
        }
        return data;
      }),
    { staleTime: 10 * (1000 * 60), cacheTime: 15 * (1000 * 60) }
  );
};
