import React from "react";
import { GridProps } from "@mui/material/Grid";
import { sizes } from "utils/useBreakpointMatch";

export interface ICardItemProps extends GridProps {
  leftDividerVisibleUp?: sizes | sizes[];
  bottomDividerVisibleDown?: sizes | sizes[];
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

const CardItem = (props: ICardItemProps) => {
  return <>{props.children}</>;
};

export default CardItem;
