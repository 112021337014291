// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MinorTransitionRequestBody
 */
export interface MinorTransitionRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof MinorTransitionRequestBody
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof MinorTransitionRequestBody
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof MinorTransitionRequestBody
     */
    primaryMemberWorkEmail: string;
    /**
     * 
     * @type {string}
     * @memberof MinorTransitionRequestBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof MinorTransitionRequestBody
     */
    cellPhone: string;
    /**
     * 
     * @type {string}
     * @memberof MinorTransitionRequestBody
     */
    dob: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MinorTransitionRequestBody
     */
    consents: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MinorTransitionRequestBody
     */
    password: string;
}

export function MinorTransitionRequestBodyFromJSON(json: any): MinorTransitionRequestBody {
    return {
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'primaryMemberWorkEmail': json['primary_member_work_email'],
        'email': json['email'],
        'cellPhone': json['cell_phone'],
        'dob': json['dob'],
        'consents': json['consents'],
        'password': json['password'],
    };
}

export function MinorTransitionRequestBodyToJSON(value?: MinorTransitionRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'first_name': value.firstName,
        'last_name': value.lastName,
        'primary_member_work_email': value.primaryMemberWorkEmail,
        'email': value.email,
        'cell_phone': value.cellPhone,
        'dob': value.dob,
        'consents': value.consents,
        'password': value.password,
    };
}


