// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteDocumentRequestBody
 */
export interface DeleteDocumentRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof DeleteDocumentRequestBody
     */
    reason: DeleteDocumentRequestBodyReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof DeleteDocumentRequestBody
     */
    reasonFreetext?: string;
}

export function DeleteDocumentRequestBodyFromJSON(json: any): DeleteDocumentRequestBody {
    return {
        'reason': json['reason'],
        'reasonFreetext': !exists(json, 'reason_freetext') ? undefined : json['reason_freetext'],
    };
}

export function DeleteDocumentRequestBodyToJSON(value?: DeleteDocumentRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'reason': value.reason,
        'reason_freetext': value.reasonFreetext,
    };
}

/**
* @export
* @enum {string}
*/
export enum DeleteDocumentRequestBodyReasonEnum {
    CodeOrange = 'Code Orange',
    UploadedToTheWrongMember = 'Uploaded to the wrong member',
    TheDocumentContainsTheWrongInformation = 'The document contains the wrong information',
    DuplicateDocument = 'Duplicate document',
    DocumentUploadedInError = 'Document uploaded in error',
    Other = 'Other'
}


