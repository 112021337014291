import React from "react";
import useFetchVisits from "shared/features/visits/useFetchVisits";
import Skeleton from "../../Skeleton";
import Visit from "../Visit";
import Typography from "../../Typography";
import { Appointment } from "shared/fetch/src/models/Appointment";
import { MemberAppointmentListSummariesStatusEnum } from "shared/fetch/src/apis/MemberAppointmentsApi";
import { selectLoggedInUser } from "shared/features/user/selectors";
import NoVisits from "../Visit/NoVisits";
import { makeStyles } from "@mui/styles";

interface IProps {
  id: number;
}

const useStyles = makeStyles(() => ({
  visitList: {
    padding: 0,
    "& li": {
      listStyle: "none",
    },
  },
}));

export const hasUpcomingVisits = (id: number) => {
  const { visitCount }: any = useFetchVisits(id, {
    status: MemberAppointmentListSummariesStatusEnum.Upcoming,
    relevant: false,
  });
  if (visitCount?.upcoming !== 0) {
    return true;
  }
  return false;
};

const DashboardVisits = ({ id }: IProps) => {
  const classes = useStyles();
  const user = selectLoggedInUser();
  const isCtm = Boolean(user?.ctm);
  const { visits, isLoading, visitCount }: any = useFetchVisits(id, {
    pageSize: 5,
    status: MemberAppointmentListSummariesStatusEnum.Upcoming,
    relevant: false,
  });

  if (isLoading) {
    return <Skeleton appearance="component" />;
  }

  return (
    <>
      <Typography
        appearance="smallBody"
        mb={1}
        color="textSecondary"
        id="upcoming_visits"
      >
        UPCOMING VISITS ({visitCount?.upcoming})
      </Typography>
      {visits.length === 0 ? (
        <NoVisits />
      ) : (
        <ul className={classes.visitList}>
          {visits.map((visit: Appointment, index: number) => {
            return (
              <li key={index}>
                <Visit visit={visit} isCtm={isCtm} />
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default DashboardVisits;
