import { ActionableItemSummary } from "shared/fetch/src/models/ActionableItemSummary";

export const getToDo = (actionableItem: ActionableItemSummary[]) => {
  if (!actionableItem) {
    return [];
  }
  const allToDoList = actionableItem.map((value) => value.todoDisplayLabel);
  const toDoCount = {};
  const orderedToDo: string[] = [];
  const visits: string[] = [];

  allToDoList.reverse().forEach((value) => {
    if (value?.includes("Upcoming visit")) {
      // if the value includes the string 'Upcoming visit' we have to do different logic to pluralize
      visits.push(value);
    } else {
      // for all other values
      if (value && !orderedToDo.includes(value)) {
        orderedToDo.push(value);
      }
      if (toDoCount[value!] !== undefined) {
        toDoCount[value!] += 1;
      } else {
        toDoCount[value!] = 1;
      }
    }
  });

  const toDo: string[] = [];
  // if we have more than one upcoming visit, we will just write "Upcoming visits"
  if (visits.length > 1 && !orderedToDo.includes("Upcoming visits")) {
    toDo.push("Upcoming visits");
  } else if (visits.length === 1) {
    // else if there is only one visit we will provide the label with the date
    orderedToDo.unshift(visits[0]);
  }
  // the rest of the to-dos can be pluralized the typical way
  orderedToDo.forEach((value) => {
    if (toDoCount[value] > 1 && !value.endsWith("s")) {
      toDo.push(`${value}s`);
    } else {
      toDo.push(value);
    }
    if (toDo.length > 3) {
      return;
    }
  });
  return toDo;
};
