import { isEmpty } from "lodash";
import { State } from "..";
import { QuestionSetJsonBodySchema } from "shared/api/src/models/QuestionSetJsonBodySchema";
import { WorkspaceDraftDataSchemaQuestionSet } from "shared/api/src/models/WorkspaceDraftDataSchemaQuestionSet";
import { XopQuestionSchema } from "shared/api/src/models/XopQuestionSchema";

// Question sets
export const WS_SET_QUESTION_SETS = "WS_SET_QUESTION_SETS";
export const WS_UPDATE_QUESTION_SETS = "WS_UPDATE_QUESTION_SETS";
export const WS_REMOVE_QUESTION_SET = "WS_REMOVE_QUESTION_SET";
export const WS_CLEAR_QUESTION_SETS = "WS_CLEAR_QUESTION_SETS";

export const WS_EDIT_QUESTION = "WS_EDIT_QUESTION";

const updateQuestionSetState =
  ({ state, episodeId, type }: any) =>
  (update: any) => ({
    ...state,
    [episodeId]: {
      type,
      status: "compiled",
      body: "",
      ...update,
    },
  });

const clearQuestionSet =
  ({ state, episodeId }: any) =>
  () => ({
    ...state,
    [episodeId]: {},
  });

interface IQuestionPayload {
  question: XopQuestionSchema;
  index: number;
}

export const questionSets = (state = {} as State, action: any) => {
  const episodeId = action.episodeId;
  const updater = updateQuestionSetState({
    state,
    episodeId,
    type: "question_set",
  });

  const clearAll = clearQuestionSet({
    state,
    episodeId,
  });

  if (!episodeId) {
    return state;
  }

  switch (action.type) {
    case WS_SET_QUESTION_SETS:
      if (!isEmpty(state[episodeId])) {
        return state;
      }
      return updater(action.payload);

    case WS_CLEAR_QUESTION_SETS:
      return clearAll();

    case WS_UPDATE_QUESTION_SETS:
      return updater({
        jsonBody: {
          ...state[episodeId]?.jsonBody,
          ...action.payload,
        },
      });

    case WS_EDIT_QUESTION:
      const { question, index } = action.payload;
      const currentQuestion = state[episodeId];
      const questions = [
        ...currentQuestion.slice(0, index),
        question,
        ...currentQuestion.slice(index + 1, currentQuestion.length),
      ];
      return updater({
        jsonBody: {
          ...state[episodeId]?.jsonBody,
          questions,
        },
      });

    default:
      return state;
  }
};

export const setQuestionSets = (
  payload: WorkspaceDraftDataSchemaQuestionSet | null,
  episodeId: string
) => ({
  type: WS_SET_QUESTION_SETS,
  payload,
  episodeId,
});

export const updateQuestionSets = (
  questionSetBody: QuestionSetJsonBodySchema,
  episodeId: string
) => ({
  type: WS_UPDATE_QUESTION_SETS,
  payload: questionSetBody,
  episodeId,
});

export const editQuestionSetQuestions = (
  question: IQuestionPayload,
  episodeId: string
) => ({
  type: WS_EDIT_QUESTION,
  payload: question,
  episodeId,
});

export const clearQuestionSets = (episodeId: string) => ({
  type: WS_CLEAR_QUESTION_SETS,
  episodeId,
});
