// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberProvidersResults,
    MemberProvidersResultsFromJSON,
    MemberProvidersResultsToJSON,
} from './MemberProvidersResults';


/**
 * 
 * @export
 * @interface MemberProviders
 */
export interface MemberProviders  {
    /**
     * 
     * @type {Array<MemberProvidersResults>}
     * @memberof MemberProviders
     */
    results?: Array<MemberProvidersResults>;
}

export function MemberProvidersFromJSON(json: any): MemberProviders {
    return {
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(MemberProvidersResultsFromJSON),
    };
}

export function MemberProvidersToJSON(value?: MemberProviders): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(MemberProvidersResultsToJSON),
    };
}


