// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppointmentNoteOutput,
    AppointmentNoteOutputFromJSON,
    AppointmentNoteOutputToJSON,
} from './AppointmentNoteOutput';
import {
    AppointmentPatientInfoObject,
    AppointmentPatientInfoObjectFromJSON,
    AppointmentPatientInfoObjectToJSON,
} from './AppointmentPatientInfoObject';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
    CheckinOutput,
    CheckinOutputFromJSON,
    CheckinOutputToJSON,
} from './CheckinOutput';
import {
    PatientScreening,
    PatientScreeningFromJSON,
    PatientScreeningToJSON,
} from './PatientScreening';
import {
    VideoConferenceOutput,
    VideoConferenceOutputFromJSON,
    VideoConferenceOutputToJSON,
} from './VideoConferenceOutput';


/**
 * 
 * @export
 * @interface AppointmentOutput
 */
export interface AppointmentOutput  {
    /**
     * 
     * @type {number}
     * @memberof AppointmentOutput
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentOutput
     */
    clinicId: number;
    /**
     * 
     * @type {number}
     * @memberof AppointmentOutput
     */
    slotId?: number;
    /**
     * 
     * @type {Date}
     * @memberof AppointmentOutput
     */
    startAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof AppointmentOutput
     */
    endAt: Date;
    /**
     * 
     * @type {number}
     * @memberof AppointmentOutput
     */
    patientId: number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentOutput
     */
    patientStatus?: AppointmentOutputPatientStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof AppointmentOutput
     */
    disposition?: AppointmentOutputDispositionEnum;
    /**
     * 
     * @type {Date}
     * @memberof AppointmentOutput
     */
    dispositionAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof AppointmentOutput
     */
    reason?: string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentOutput
     */
    lastUpdatedBy?: number;
    /**
     * 
     * @type {Date}
     * @memberof AppointmentOutput
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof AppointmentOutput
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof AppointmentOutput
     */
    providerId: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentOutput
     */
    cancelReason?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentOutput
     */
    conflicted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AppointmentOutput
     */
    ehrSyncStatus?: AppointmentOutputEhrSyncStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof AppointmentOutput
     */
    accommodations?: Array<string>;
    /**
     * 
     * @type {CheckinOutput}
     * @memberof AppointmentOutput
     */
    checkin?: CheckinOutput;
    /**
     * 
     * @type {Array<AppointmentNoteOutput>}
     * @memberof AppointmentOutput
     */
    appointmentNotes?: Array<AppointmentNoteOutput>;
    /**
     * 
     * @type {AppointmentPatientInfoObject}
     * @memberof AppointmentOutput
     */
    patientInfo?: AppointmentPatientInfoObject;
    /**
     * 
     * @type {Array<PatientScreening>}
     * @memberof AppointmentOutput
     */
    currentPatientScreenings?: Array<PatientScreening>;
    /**
     * 
     * @type {VideoConferenceOutput}
     * @memberof AppointmentOutput
     */
    videoConference?: VideoConferenceOutput;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentOutput
     */
    xopReferral?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AppointmentOutput
     */
    method?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentOutput
     */
    episodeId?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentOutput
     */
    componentId?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentOutput
     */
    componentType?: AppointmentOutputComponentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AppointmentOutput
     */
    patientCellPhone?: string;
    /**
     * 
     * @type {CTMInfo}
     * @memberof AppointmentOutput
     */
    provider?: CTMInfo;
}

export function AppointmentOutputFromJSON(json: any): AppointmentOutput {
    return {
        'id': json['id'],
        'clinicId': json['clinic_id'],
        'slotId': !exists(json, 'slot_id') ? undefined : json['slot_id'],
        'startAt': new Date(json['start_at']),
        'endAt': new Date(json['end_at']),
        'patientId': json['patient_id'],
        'patientStatus': !exists(json, 'patient_status') ? undefined : json['patient_status'],
        'disposition': !exists(json, 'disposition') ? undefined : json['disposition'],
        'dispositionAt': !exists(json, 'disposition_at') ? undefined : new Date(json['disposition_at']),
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'lastUpdatedBy': !exists(json, 'last_updated_by') ? undefined : json['last_updated_by'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'providerId': json['provider_id'],
        'cancelReason': !exists(json, 'cancel_reason') ? undefined : json['cancel_reason'],
        'conflicted': !exists(json, 'conflicted') ? undefined : json['conflicted'],
        'ehrSyncStatus': !exists(json, 'ehr_sync_status') ? undefined : json['ehr_sync_status'],
        'accommodations': !exists(json, 'accommodations') ? undefined : json['accommodations'],
        'checkin': !exists(json, 'checkin') ? undefined : CheckinOutputFromJSON(json['checkin']),
        'appointmentNotes': !exists(json, 'appointment_notes') ? undefined : (json['appointment_notes'] as Array<any>).map(AppointmentNoteOutputFromJSON),
        'patientInfo': !exists(json, 'patient_info') ? undefined : AppointmentPatientInfoObjectFromJSON(json['patient_info']),
        'currentPatientScreenings': !exists(json, 'current_patient_screenings') ? undefined : (json['current_patient_screenings'] as Array<any>).map(PatientScreeningFromJSON),
        'videoConference': !exists(json, 'video_conference') ? undefined : VideoConferenceOutputFromJSON(json['video_conference']),
        'xopReferral': !exists(json, 'xop_referral') ? undefined : json['xop_referral'],
        'method': !exists(json, 'method') ? undefined : json['method'],
        'episodeId': !exists(json, 'episode_id') ? undefined : json['episode_id'],
        'componentId': !exists(json, 'component_id') ? undefined : json['component_id'],
        'componentType': !exists(json, 'component_type') ? undefined : json['component_type'],
        'patientCellPhone': !exists(json, 'patient_cell_phone') ? undefined : json['patient_cell_phone'],
        'provider': !exists(json, 'provider') ? undefined : CTMInfoFromJSON(json['provider']),
    };
}

export function AppointmentOutputToJSON(value?: AppointmentOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'clinic_id': value.clinicId,
        'slot_id': value.slotId,
        'start_at': value.startAt.toISOString(),
        'end_at': value.endAt.toISOString(),
        'patient_id': value.patientId,
        'patient_status': value.patientStatus,
        'disposition': value.disposition,
        'disposition_at': value.dispositionAt === undefined ? undefined : value.dispositionAt.toISOString(),
        'reason': value.reason,
        'last_updated_by': value.lastUpdatedBy,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'provider_id': value.providerId,
        'cancel_reason': value.cancelReason,
        'conflicted': value.conflicted,
        'ehr_sync_status': value.ehrSyncStatus,
        'accommodations': value.accommodations,
        'checkin': CheckinOutputToJSON(value.checkin),
        'appointment_notes': value.appointmentNotes === undefined ? undefined : (value.appointmentNotes as Array<any>).map(AppointmentNoteOutputToJSON),
        'patient_info': AppointmentPatientInfoObjectToJSON(value.patientInfo),
        'current_patient_screenings': value.currentPatientScreenings === undefined ? undefined : (value.currentPatientScreenings as Array<any>).map(PatientScreeningToJSON),
        'video_conference': VideoConferenceOutputToJSON(value.videoConference),
        'xop_referral': value.xopReferral,
        'method': value.method,
        'episode_id': value.episodeId,
        'component_id': value.componentId,
        'component_type': value.componentType,
        'patient_cell_phone': value.patientCellPhone,
        'provider': CTMInfoToJSON(value.provider),
    };
}

/**
* @export
* @enum {string}
*/
export enum AppointmentOutputPatientStatusEnum {
    CheckedIn = 'checked_in',
    InRoom = 'in_room'
}
/**
* @export
* @enum {string}
*/
export enum AppointmentOutputDispositionEnum {
    Complete = 'complete',
    Cancel = 'cancel',
    NoShow = 'no_show',
    LateCancel = 'late_cancel'
}
/**
* @export
* @enum {string}
*/
export enum AppointmentOutputEhrSyncStatusEnum {
    Pending = 'pending',
    Success = 'success',
    PatientNotInPractice = 'patient_not_in_practice',
    OtherError = 'other_error',
    SkippedPatientCreation = 'skipped_patient_creation'
}
/**
* @export
* @enum {string}
*/
export enum AppointmentOutputComponentTypeEnum {
    CenterVisitReferralComponent = 'CenterVisitReferralComponent',
    VirtualVisitReferralComponent = 'VirtualVisitReferralComponent',
    SelfScheduleAppointmentComponent = 'SelfScheduleAppointmentComponent'
}


