// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     QuestionSetJsonBodySchema,
    QuestionSetJsonBodySchemaFromJSON,
    QuestionSetJsonBodySchemaToJSON,
} from './QuestionSetJsonBodySchema';
import {
     WorkspaceDraftDataSchemaBase,
    WorkspaceDraftDataSchemaBaseFromJSON,
    WorkspaceDraftDataSchemaBaseToJSON,
} from './WorkspaceDraftDataSchemaBase';
import {
     WorkspaceDraftDataShemaQuestionSetBase,
    WorkspaceDraftDataShemaQuestionSetBaseFromJSON,
    WorkspaceDraftDataShemaQuestionSetBaseToJSON,
} from './WorkspaceDraftDataShemaQuestionSetBase';
/**
 * @type WorkspaceDraftDataSchemaQuestionSet
 * @export
 */
export interface WorkspaceDraftDataSchemaQuestionSet extends WorkspaceDraftDataSchemaBase, WorkspaceDraftDataShemaQuestionSetBase {
}

export function WorkspaceDraftDataSchemaQuestionSetFromJSON(json: any): WorkspaceDraftDataSchemaQuestionSet {
    return {
        ...WorkspaceDraftDataSchemaBaseFromJSON(json),
        ...WorkspaceDraftDataShemaQuestionSetBaseFromJSON(json),
    };
}

export function WorkspaceDraftDataSchemaQuestionSetToJSON(value?: WorkspaceDraftDataSchemaQuestionSet): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...WorkspaceDraftDataSchemaBaseToJSON(value),
        ...WorkspaceDraftDataShemaQuestionSetBaseToJSON(value),
    };
}

