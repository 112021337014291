import { useMutation, useQueryClient } from "react-query";
import { memberLabsListQueryKey } from "./useGetLabsList";
import {
  MarkLabAsViewedRequest,
  fetchLabsApi,
} from "shared/fetch/src/apis/LabsApi";

interface IEMarkLabAsViewedRequest
  extends Pick<MarkLabAsViewedRequest, keyof MarkLabAsViewedRequest> {
  page: number;
}

const useMarkLabAsViewedMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (request: IEMarkLabAsViewedRequest) =>
      fetchLabsApi.markLabAsViewed(request),
    {
      onSuccess: (_response, request) => {
        queryClient.invalidateQueries(
          memberLabsListQueryKey(request.id, request.page.toString())
        );
      },
    }
  );

  return mutation;
};

export default useMarkLabAsViewedMutation;
