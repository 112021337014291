// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClinicProviderConfigSchema,
    ClinicProviderConfigSchemaFromJSON,
    ClinicProviderConfigSchemaToJSON,
} from './ClinicProviderConfigSchema';


/**
 * 
 * @export
 * @interface ClinicProviderConfigFetchResponse
 */
export interface ClinicProviderConfigFetchResponse  {
    /**
     * 
     * @type {Array<ClinicProviderConfigSchema>}
     * @memberof ClinicProviderConfigFetchResponse
     */
    results: Array<ClinicProviderConfigSchema>;
}

export function ClinicProviderConfigFetchResponseFromJSON(json: any): ClinicProviderConfigFetchResponse {
    return {
        'results': (json['results'] as Array<any>).map(ClinicProviderConfigSchemaFromJSON),
    };
}

export function ClinicProviderConfigFetchResponseToJSON(value?: ClinicProviderConfigFetchResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': (value.results as Array<any>).map(ClinicProviderConfigSchemaToJSON),
    };
}


