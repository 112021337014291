// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AvatarUploadSuccessResponse
 */
export interface AvatarUploadSuccessResponse  {
    /**
     * 
     * @type {string}
     * @memberof AvatarUploadSuccessResponse
     */
    avatarUrl?: string;
}

export function AvatarUploadSuccessResponseFromJSON(json: any): AvatarUploadSuccessResponse {
    return {
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
    };
}

export function AvatarUploadSuccessResponseToJSON(value?: AvatarUploadSuccessResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'avatar_url': value.avatarUrl,
    };
}


