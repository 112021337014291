/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    EmployersResponse,
    EmployersResponseFromJSON,
    EmployersResponseToJSON,
} from '../models/EmployersResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'




export interface IEmployersApi {
      getEmployers: () => Promise<EmployersResponse>
      getEmployersWithResponse: () => Promise<{ value: EmployersResponse, response: any}>
}

/**
 * no description
 */
export class EmployersApi extends runtime.BaseAPI {

    /**
     * Get list of employers
     * Gets a list of employers
     */
    getEmployersRaw = async (): Promise<{ runtime: runtime.ApiResponse<EmployersResponse>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/employers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => EmployersResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get list of employers
     * Gets a list of employers
     */
    getEmployers = async (): Promise<EmployersResponse> => {
        const { runtime } = await this.getEmployersRaw();
        return await runtime.value();
    }

    /**
     * Get list of employers
     * Gets a list of employers
     */
    getEmployersWithResponse = async (): Promise<{ value: EmployersResponse, response: any}> => {
        const { runtime, response } = await this.getEmployersRaw();
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchEmployersApi: IEmployersApi  = new EmployersApi();
