import React from "react";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { ContentTypeProps } from "containers/Layout/CmsMenuNavigationHierarchy";
import MuiAppBar from "components/AppBar";
import {
  SubMenuHierarchyComponent,
  CmsMenuHierarchyItemsTabs,
} from "features/cms/components";
import { useMenuAnchorElement } from "components/Menu/useMenuAnchorElement";
import { JsonUser as User } from "shared/fetch/src/models/JsonUser";;
import Hidden from "components/Hidden";
import Toolbar from "components/Toolbar";

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      position: "sticky",
      top: 0,
      width: "100%",
      "& .MuiAppBar-root": {
        position: "relative",
        backgroundColor: "#ffffff",
        zIndex: 2,
        height: 60,
        marginTop: 10,
      },
      "& .MuiToolbar-root": {
        minHeight: "100%",
        paddingLeft: 16,
        paddingRight: 16,
        marginLeft: theme.spacing(29),
        justifyContent: "flex-start",
      },
      "& .MuiToolbar-root > div": {
        display: "flex",
        alignSelf: "stretch",
        alignItems: "center",
      },
      "& .MuiTabs-root": {
        alignSelf: "stretch",
      },
      "& .MuiTab-wrapper": {
        flexDirection: "row-reverse",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        verticalAlign: "middle",
        paddingLeft: 5,
      },
      "& .MuiSvgIcon-root": {
        marginLeft: 10,
      },
    },
    mobileNav: {
      position: "relative",
      zIndex: 1,
    },
  })
);

interface Props {
  user: User;
  cmsMenuHierarchyItems: CmsMenuHierarchyItemsProps[];
}

export interface CmsMenuHierarchyItemsProps {
  id: string;
  label: string;
  contentTypes: ContentTypeProps[];
  active?: boolean;
}

const CmsMenuHierarchy: React.FunctionComponent<Props> = (
  props
): JSX.Element => {
  const classes = useStyles();
  const [defaultMenuItem, setDefaultMenuItem] = React.useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = React.useState("");
  const [cmsSubMenuHierarchyItems, setCmsSubMenuHierarchyItems] =
    React.useState([]);

  const { anchorElement, onAnchorElementClick, onMenuClose } =
    useMenuAnchorElement();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    cmsSubMenuHierarchyItemsList: ContentTypeProps[]
  ) => {
    onAnchorElementClick(event);
    // @ts-ignore: Object is possibly 'null'.
    setCmsSubMenuHierarchyItems(cmsSubMenuHierarchyItemsList);
  };

  const handleMenuItemSelection = (menuItem: string) => {
    setSelectedMenuItem(menuItem);
    setDefaultMenuItem(true);
  };

  const handleClose = () => {
    onMenuClose();
    setDefaultMenuItem(false);
    setCmsSubMenuHierarchyItems([]);
  };

  return (
    <Hidden only={["xs", "sm", "md", "lg"]}>
      <div className={classes.root}>
        <MuiAppBar color="default" elevation={0}>
          <Toolbar>
            <div>
              <CmsMenuHierarchyItemsTabs
                cmsMenuHierarchyItems={props.cmsMenuHierarchyItems}
                handleClick={handleClick}
                defaultMenuItem={defaultMenuItem}
                selectedMenuItem={selectedMenuItem}
                handleMenuItemSelection={handleMenuItemSelection}
              />
            </div>
          </Toolbar>
        </MuiAppBar>
      </div>
      <SubMenuHierarchyComponent
        // @ts-ignore: Object is possibly 'null'.
        anchorEl={anchorElement}
        handleClose={handleClose}
        cmsSubMenuHierarchyItems={cmsSubMenuHierarchyItems}
      />
    </Hidden>
  );
};

export default CmsMenuHierarchy;
