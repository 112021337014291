// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberUpdateAccountInfoRequestBodyAllOf
 */
export interface MemberUpdateAccountInfoRequestBodyAllOf  {
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    dob?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    cellPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    workPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    preferredLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    preferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    genderIdentity?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    sexualOrientation?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    pronouns?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    hearing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    vision?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    race?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    ethnicity?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    sexAtBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    mailingAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    mailingCity?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    mailingState?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    mailingZip?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    zip?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    serviceLines?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    emergencyName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    emergencyRelationship?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    emergencyPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    timezone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    cellTexts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    consented?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof MemberUpdateAccountInfoRequestBodyAllOf
     */
    podFilter?: Array<number>;
}

export function MemberUpdateAccountInfoRequestBodyAllOfFromJSON(json: any): MemberUpdateAccountInfoRequestBodyAllOf {
    return {
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'cellPhone': !exists(json, 'cell_phone') ? undefined : json['cell_phone'],
        'workPhone': !exists(json, 'work_phone') ? undefined : json['work_phone'],
        'preferredLanguage': !exists(json, 'preferred_language') ? undefined : json['preferred_language'],
        'preferredName': !exists(json, 'preferred_name') ? undefined : json['preferred_name'],
        'genderIdentity': !exists(json, 'gender_identity') ? undefined : json['gender_identity'],
        'sexualOrientation': !exists(json, 'sexual_orientation') ? undefined : json['sexual_orientation'],
        'pronouns': !exists(json, 'pronouns') ? undefined : json['pronouns'],
        'hearing': !exists(json, 'hearing') ? undefined : json['hearing'],
        'vision': !exists(json, 'vision') ? undefined : json['vision'],
        'race': !exists(json, 'race') ? undefined : json['race'],
        'ethnicity': !exists(json, 'ethnicity') ? undefined : json['ethnicity'],
        'sexAtBirth': !exists(json, 'sex_at_birth') ? undefined : json['sex_at_birth'],
        'mailingAddress': !exists(json, 'mailing_address') ? undefined : json['mailing_address'],
        'mailingCity': !exists(json, 'mailing_city') ? undefined : json['mailing_city'],
        'mailingState': !exists(json, 'mailing_state') ? undefined : json['mailing_state'],
        'mailingZip': !exists(json, 'mailing_zip') ? undefined : json['mailing_zip'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'serviceLines': !exists(json, 'service_lines') ? undefined : json['service_lines'],
        'emergencyName': !exists(json, 'emergency_name') ? undefined : json['emergency_name'],
        'emergencyRelationship': !exists(json, 'emergency_relationship') ? undefined : json['emergency_relationship'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'emergencyPhone': !exists(json, 'emergency_phone') ? undefined : json['emergency_phone'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'cellTexts': !exists(json, 'cell_texts') ? undefined : json['cell_texts'],
        'consented': !exists(json, 'consented') ? undefined : json['consented'],
        'podFilter': !exists(json, 'pod_filter') ? undefined : json['pod_filter'],
    };
}

export function MemberUpdateAccountInfoRequestBodyAllOfToJSON(value?: MemberUpdateAccountInfoRequestBodyAllOf): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'first_name': value.firstName,
        'last_name': value.lastName,
        'dob': value.dob,
        'gender': value.gender,
        'cell_phone': value.cellPhone,
        'work_phone': value.workPhone,
        'preferred_language': value.preferredLanguage,
        'preferred_name': value.preferredName,
        'gender_identity': value.genderIdentity,
        'sexual_orientation': value.sexualOrientation,
        'pronouns': value.pronouns,
        'hearing': value.hearing,
        'vision': value.vision,
        'race': value.race,
        'ethnicity': value.ethnicity,
        'sex_at_birth': value.sexAtBirth,
        'mailing_address': value.mailingAddress,
        'mailing_city': value.mailingCity,
        'mailing_state': value.mailingState,
        'mailing_zip': value.mailingZip,
        'address': value.address,
        'city': value.city,
        'state': value.state,
        'zip': value.zip,
        'service_lines': value.serviceLines,
        'emergency_name': value.emergencyName,
        'emergency_relationship': value.emergencyRelationship,
        'email': value.email,
        'emergency_phone': value.emergencyPhone,
        'timezone': value.timezone,
        'cell_texts': value.cellTexts,
        'consented': value.consented,
        'pod_filter': value.podFilter,
    };
}


