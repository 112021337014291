// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CalculatorComponent,
    CalculatorComponentFromJSON,
    CalculatorComponentToJSON,
} from './CalculatorComponent';


/**
 * 
 * @export
 * @interface CheckinOutput
 */
export interface CheckinOutput  {
    /**
     * 
     * @type {string}
     * @memberof CheckinOutput
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof CheckinOutput
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof CheckinOutput
     */
    clinicId?: number;
    /**
     * 
     * @type {number}
     * @memberof CheckinOutput
     */
    patientId?: number;
    /**
     * 
     * @type {number}
     * @memberof CheckinOutput
     */
    appointmentId?: number;
    /**
     * 
     * @type {string}
     * @memberof CheckinOutput
     */
    lastStep?: string;
    /**
     * 
     * @type {Array<CalculatorComponent>}
     * @memberof CheckinOutput
     */
    calculators?: Array<CalculatorComponent>;
    /**
     * 
     * @type {object}
     * @memberof CheckinOutput
     */
    data?: object;
    /**
     * 
     * @type {Date}
     * @memberof CheckinOutput
     */
    lastCompletedCheckinUpdatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CheckinOutput
     */
    status?: CheckinOutputStatusEnum;
}

export function CheckinOutputFromJSON(json: any): CheckinOutput {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'user_id') ? undefined : json['user_id'],
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'patientId': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'appointmentId': !exists(json, 'appointment_id') ? undefined : json['appointment_id'],
        'lastStep': !exists(json, 'last_step') ? undefined : json['last_step'],
        'calculators': !exists(json, 'calculators') ? undefined : (json['calculators'] as Array<any>).map(CalculatorComponentFromJSON),
        'data': !exists(json, 'data') ? undefined : json['data'],
        'lastCompletedCheckinUpdatedAt': !exists(json, 'last_completed_checkin_updated_at') ? undefined : new Date(json['last_completed_checkin_updated_at']),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function CheckinOutputToJSON(value?: CheckinOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'user_id': value.userId,
        'clinic_id': value.clinicId,
        'patient_id': value.patientId,
        'appointment_id': value.appointmentId,
        'last_step': value.lastStep,
        'calculators': value.calculators === undefined ? undefined : (value.calculators as Array<any>).map(CalculatorComponentToJSON),
        'data': value.data,
        'last_completed_checkin_updated_at': value.lastCompletedCheckinUpdatedAt === undefined ? undefined : value.lastCompletedCheckinUpdatedAt.toISOString(),
        'status': value.status,
    };
}

/**
* @export
* @enum {string}
*/
export enum CheckinOutputStatusEnum {
    Done = 'done',
    New = 'new',
    ForcedNew = 'forced_new',
    ForcedDone = 'forced_done',
    Pending = 'pending',
    Active = 'active'
}


