// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContactProvider,
    ContactProviderFromJSON,
    ContactProviderToJSON,
} from './ContactProvider';


/**
 * 
 * @export
 * @interface MemberNetworkContacts
 */
export interface MemberNetworkContacts  {
    /**
     * 
     * @type {number}
     * @memberof MemberNetworkContacts
     */
    count?: number;
    /**
     * 
     * @type {Array<ContactProvider>}
     * @memberof MemberNetworkContacts
     */
    providers?: Array<ContactProvider>;
}

export function MemberNetworkContactsFromJSON(json: any): MemberNetworkContacts {
    return {
        'count': !exists(json, 'count') ? undefined : json['count'],
        'providers': !exists(json, 'providers') ? undefined : (json['providers'] as Array<any>).map(ContactProviderFromJSON),
    };
}

export function MemberNetworkContactsToJSON(value?: MemberNetworkContacts): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'providers': value.providers === undefined ? undefined : (value.providers as Array<any>).map(ContactProviderToJSON),
    };
}


