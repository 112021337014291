import { useQuery } from "react-query";
import { fetchConsentApi } from "shared/fetch/src/apis/ConsentApi";

const useFetchConsentsConfig = () => {
  const query = useQuery(
    "fetchConsentsConfig",
    () => fetchConsentApi.fetchConsentsConfig(),
    { keepPreviousData: true, staleTime: 300000, cacheTime: 360000 }
  );
  return query;
};

export default useFetchConsentsConfig;
