// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkspaceDraftDataSchemaCTMMessageBase
 */
export interface WorkspaceDraftDataSchemaCTMMessageBase  {
    /**
     * 
     * @type {boolean}
     * @memberof WorkspaceDraftDataSchemaCTMMessageBase
     */
    responseRequired?: boolean;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkspaceDraftDataSchemaCTMMessageBase
     */
    acknowledgeAndRespond?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkspaceDraftDataSchemaCTMMessageBase
     */
    acknowledge?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof WorkspaceDraftDataSchemaCTMMessageBase
     */
    documentIds?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WorkspaceDraftDataSchemaCTMMessageBase
     */
    ccCtmIds?: Array<string>;
}

export function WorkspaceDraftDataSchemaCTMMessageBaseFromJSON(json: any): WorkspaceDraftDataSchemaCTMMessageBase {
    return {
        'responseRequired': !exists(json, 'response_required') ? undefined : json['response_required'],
        'acknowledgeAndRespond': !exists(json, 'acknowledge_and_respond') ? undefined : json['acknowledge_and_respond'],
        'acknowledge': !exists(json, 'acknowledge') ? undefined : json['acknowledge'],
        'documentIds': !exists(json, 'document_ids') ? undefined : json['document_ids'],
        'ccCtmIds': !exists(json, 'cc_ctm_ids') ? undefined : json['cc_ctm_ids'],
    };
}

export function WorkspaceDraftDataSchemaCTMMessageBaseToJSON(value?: WorkspaceDraftDataSchemaCTMMessageBase): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'response_required': value.responseRequired,
        'acknowledge_and_respond': value.acknowledgeAndRespond,
        'acknowledge': value.acknowledge,
        'document_ids': value.documentIds,
        'cc_ctm_ids': value.ccCtmIds,
    };
}


