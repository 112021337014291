// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OutputServiceLineSchema,
    OutputServiceLineSchemaFromJSON,
    OutputServiceLineSchemaToJSON,
} from './OutputServiceLineSchema';


/**
 * 
 * @export
 * @interface OutputServiceMenuDocumentSchema
 */
export interface OutputServiceMenuDocumentSchema  {
    /**
     * 
     * @type {string}
     * @memberof OutputServiceMenuDocumentSchema
     */
    headline: string;
    /**
     * 
     * @type {Array<OutputServiceLineSchema>}
     * @memberof OutputServiceMenuDocumentSchema
     */
    serviceMenu: Array<OutputServiceLineSchema>;
    /**
     * 
     * @type {Array<OutputServiceLineSchema>}
     * @memberof OutputServiceMenuDocumentSchema
     */
    other?: Array<OutputServiceLineSchema>;
}

export function OutputServiceMenuDocumentSchemaFromJSON(json: any): OutputServiceMenuDocumentSchema {
    return {
        'headline': json['headline'],
        'serviceMenu': (json['service_menu'] as Array<any>).map(OutputServiceLineSchemaFromJSON),
        'other': !exists(json, 'other') ? undefined : (json['other'] as Array<any>).map(OutputServiceLineSchemaFromJSON),
    };
}

export function OutputServiceMenuDocumentSchemaToJSON(value?: OutputServiceMenuDocumentSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'headline': value.headline,
        'service_menu': (value.serviceMenu as Array<any>).map(OutputServiceLineSchemaToJSON),
        'other': value.other === undefined ? undefined : (value.other as Array<any>).map(OutputServiceLineSchemaToJSON),
    };
}


