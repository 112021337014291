import {
  IConfiguration,
  getAuthorizationEndpointUrl,
  getRedirectUri,
  storeCodeVerifier,
} from "./utils";
import { createCodeChallenge, createCodeVerifier } from "./index";

let called = false;

export const reset = () => {
  called = false;
};

const configuration: IConfiguration = {
  authorizationEndpointUrl: getAuthorizationEndpointUrl(),
  clientId: process.env.REACT_APP_CLIENT_ID,
  window,
  webRoot: "",
};

const getAuthInfoForBiometricLogin = (deepLink: string | null | undefined) => {
  if (called) {
    return Promise.resolve();
  }

  called = true;

  const codeVerifier = createCodeVerifier();

  return createCodeChallenge(codeVerifier)
    .then((codeChallenge) => {
      storeCodeVerifier(codeVerifier);
      const authInfo = {
        client_id: configuration.clientId,
        response_type: "code",
        scope: "foobar",
        redirect_uri: getRedirectUri(deepLink ? deepLink : "/", true),
        code_challenge: codeChallenge,
        code_challenge_method: "S256",
      };
      return authInfo;
    })
    .catch((_e) => {
      reset();
    });
};

export default getAuthInfoForBiometricLogin;
