// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionSetJsonBodySchema,
    QuestionSetJsonBodySchemaFromJSON,
    QuestionSetJsonBodySchemaToJSON,
} from './QuestionSetJsonBodySchema';


/**
 * 
 * @export
 * @interface UpdateQuestionsForEpisodeResponse
 */
export interface UpdateQuestionsForEpisodeResponse  {
    /**
     * 
     * @type {QuestionSetJsonBodySchema}
     * @memberof UpdateQuestionsForEpisodeResponse
     */
    jsonBody: QuestionSetJsonBodySchema;
}

export function UpdateQuestionsForEpisodeResponseFromJSON(json: any): UpdateQuestionsForEpisodeResponse {
    return {
        'jsonBody': QuestionSetJsonBodySchemaFromJSON(json['json_body']),
    };
}

export function UpdateQuestionsForEpisodeResponseToJSON(value?: UpdateQuestionsForEpisodeResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'json_body': QuestionSetJsonBodySchemaToJSON(value.jsonBody),
    };
}


