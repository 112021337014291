import React, { FC } from "react";
import { useTheme, Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from "react-router";
import clsx from "clsx";
import { EpisodeSummary } from "shared/fetch/src/models/EpisodeSummary";

import Box from "components/Box";
import MarketingCard from "components/MarketingCard";
import Typography from "components/Typography";
import PreventiveMental from "styles/images/marketing/preventive_mental.png";

const useStyles = makeStyles((theme: Theme) => ({
  preventiveIntro: {
    padding: "10px 0px",
    color: theme?.palette?.white,
  },
}));

interface IProps {
  title: string;
  buttonText: string;
  preventiveEpisode: EpisodeSummary;
  shouldRenderUTDTile?: boolean;
  hasTwoCards?: boolean;
}

const PreventiveEocMarketing: FC<IProps> = ({
  title,
  buttonText,
  preventiveEpisode,
  hasTwoCards,
}) => {
  const classes = useStyles();
  const timelineUrl = `/members/${preventiveEpisode?.memberInfo?.id}/conversations/${preventiveEpisode?.id}`;
  const navigate = useNavigate();

  const theme = useTheme();
  const Copy = () => (
    <Box mt={1.5} pr={5}>
      <Typography
        appearance="smallBody"
        className={clsx(classes.preventiveIntro)}
      >
        Our mental health therapists are available to listen and help with any
        of life’s challenges. No matter what you are dealing with, you are not
        alone—many others have taken the first step to improve their overall
        wellbeing, too.
      </Typography>

      <Typography
        appearance="smallBody"
        className={clsx(classes.preventiveIntro)}
      >
        Find a healthier path forward today, starting with a few private, online
        questions about how you are feeling.
      </Typography>
    </Box>
  );

  return (
    <MarketingCard
      renderCopy={Copy}
      title={title}
      buttonText={buttonText}
      onButtonClick={() => navigate(timelineUrl)}
      imgSrc={PreventiveMental}
      linkTo={timelineUrl}
      dataTestIdPrefix="preventive"
      backgroundColor={theme.palette?.accent?.main ?? ""}
      buttonColor={theme.palette?.accent?.main ?? ""}
      textColor={theme.palette?.white}
      hasTwoCards={hasTwoCards}
    />
  );
};

export default PreventiveEocMarketing;
