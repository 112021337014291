// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     PermissionSchema,
    PermissionSchemaFromJSON,
    PermissionSchemaToJSON,
} from './PermissionSchema';
import {
     RoleOutputCoreSchema,
    RoleOutputCoreSchemaFromJSON,
    RoleOutputCoreSchemaToJSON,
} from './RoleOutputCoreSchema';
import {
     RoleSchemaExample,
    RoleSchemaExampleFromJSON,
    RoleSchemaExampleToJSON,
} from './RoleSchemaExample';
import {
     RoleSchemaProps,
    RoleSchemaPropsFromJSON,
    RoleSchemaPropsToJSON,
} from './RoleSchemaProps';
/**
 * @type RoleSchema
 * @export
 */
export interface RoleSchema extends RoleOutputCoreSchema, RoleSchemaExample, RoleSchemaProps {
}

export function RoleSchemaFromJSON(json: any): RoleSchema {
    return {
        ...RoleOutputCoreSchemaFromJSON(json),
        ...RoleSchemaExampleFromJSON(json),
        ...RoleSchemaPropsFromJSON(json),
    };
}

export function RoleSchemaToJSON(value?: RoleSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...RoleOutputCoreSchemaToJSON(value),
        ...RoleSchemaExampleToJSON(value),
        ...RoleSchemaPropsToJSON(value),
    };
}

