import React, { ReactNode, useEffect, useState } from "react";
import { List, AutoSizer, ListRowProps } from "react-virtualized";
import ComboBox from "..";
import Typography from "components/Typography";
import { Paper, Popper, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import MenuList from "components/Menu/MenuList";
import { createFilterOptions } from "@mui/material/useAutocomplete";
import { AutocompleteRenderInputParams } from "@mui/material/Autocomplete";
import Checkbox from "components/Checkbox";
import Chip from "components/Chip";
import CircularProgress from "components/Progress/CircularProgress";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import Popover from "components/Popover";
import ContactCard from "./ContactCard";
import { CTMInfo } from "shared/fetch/src/models/CTMInfo";

interface IProps {
  isPcpFirstNamedOption?: boolean;
  unclaimedUser?: any;
  youUser?: CTMInfo[];
  ctmList: CTMInfo[];
  isPending?: boolean;
  onChange: (event: any, value: any) => any;
  renderInput: (params: AutocompleteRenderInputParams) => ReactNode;
  value?: any;
  multiple?: boolean;
  getOptionDisabled?: (option: any) => boolean;
  dropdownWidth?: number;
  dropdownHeight?: number;
  anchorEl?: any;
  hasPopover?: any;
  isDocked?: boolean;
  closePopover?: () => {};
}

const useStyles = makeStyles((theme: Theme) => ({
  autoSizer: {
    height: "auto !important",
    width: "auto !important",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(-2),
    marginLeft: theme.spacing(-2),
    "& .MuiCheckbox-root": {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(-1),
    },
    "& .MuiCheckbox-colorSecondary.Mui-checked ": {
      color: theme.palette.primary.main,
    },
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  noneContainer: {
    display: "flex",
    alignItems: "center",
    paddingBottom: theme.spacing(1),
    marginBottom: theme.spacing(-2),
    marginLeft: theme.spacing(-0.5),
  },
  noneAvatarContainer: {
    padding: 0,
    margin: 0,
  },
  noneInfo: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1.5),
  },
  spinner: {
    marginLeft: theme.spacing(2),
    width: "30px",
    height: "30px",
  },
  icon: {
    marginLeft: theme.spacing(1.5),
    marginRight: theme.spacing(0.5),
    width: 32,
    height: 32,
    fill: theme.palette.text.secondary,
  },
  chip: {
    borderRadius: "0 !important",
    marginTop: theme.spacing(0.5),
    marginBottom: 0,
    backgroundColor: "#DFE1E4",
    color: "#2B3949",
    "& .MuiChip-deleteIcon": {
      color: theme.palette.text.secondary,
    },
  },
  clearIcon: {
    color: theme.palette.text.secondary,
  },
  clearIconHidden: {
    "& .MuiAutocomplete-clearIndicatorDirty": {
      visibility: "hidden !important",
    },
  },
  listbox: {
    width: "95%",
    marginLeft: "15px",
    paddingBottom: theme.spacing(1.5),
    overflow: "hidden",
    "& .MuiAutocomplete-listbox": {
      maxHeight: ({ maxHeightNonMobile }: any) => maxHeightNonMobile,
      overflow: "hidden",
    },
    ["@media screen and (max-width: 1000px)"]: {
      "& .MuiAutocomplete-listbox": {
        maxHeight: ({ maxHeightMobile }: any) => maxHeightMobile,
      },
    },
    "& .MuiAutocomplete-option": {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  },
}));

const popperStyles = makeStyles(() =>
  createStyles({
    paper: {
      border: "1px solid white",
      boxShadow: "0px 5px 5px rgba(0,0,0,0.12)",
      "& .MuiList-root": {
        paddingTop: "0px !important",
      },
    },
  })
);

const popOverStyles = makeStyles(() =>
  createStyles({
    popover: (props: { isDocked: boolean }) => ({
      "& .MuiPopover-paper": {
        boxShadow: "5px 5px 15px rgba(0,0,0,0.12)",
        width: "300px",
        paddingTop: "10px",
        paddingBottom: "10px",
        top: props.isDocked ? "21.5% !important" : "50% !important",
      },
    }),
  })
);

const ProviderComboBox: React.FC<IProps> = ({
  isPcpFirstNamedOption,
  unclaimedUser,
  youUser,
  isPending = false,
  onChange,
  renderInput,
  value,
  multiple = false,
  getOptionDisabled,
  dropdownWidth = 352,
  dropdownHeight = 435,
  ctmList,
  anchorEl,
  hasPopover = false,
  isDocked = false,
  closePopover,
}) => {
  const nameMaxLength = 26;
  const initValue = multiple ? [{}] : {};
  const [inputValue, setInputValue] = useState(value?.name || "");
  const popperClasses = popperStyles();
  const popOverClasses = popOverStyles({ isDocked });

  const maxHeightMobile =
    ctmList?.filter((ctm) => !!ctm.careTeamName).length > 0
      ? "568px"
      : dropdownHeight;
  const maxHeightNonMobile =
    ctmList?.filter((ctm) => !!ctm.careTeamName).length > 0 ? "572px" : "448px";
  const classes = useStyles({ maxHeightNonMobile, maxHeightMobile });

  useEffect(() => {
    if (!multiple) {
      setInputValue(value?.name || "");
    }
  }, [value]);

  const scrollPosition = React.useRef({ position: 0 });

  if (isPending) {
    return <CircularProgress className={classes.spinner}></CircularProgress>;
  }

  const PopperComponent = (containerProps: any) => {
    if (hasPopover) {
      return (
        <Popper
          {...containerProps}
          placement={"bottom-start"}
          style={{ width: `${dropdownWidth}px`, height: 0 }}
        />
      );
    }

    if (!!anchorEl && !hasPopover) {
      return (
        <Popper
          {...containerProps}
          placement={"top-start"}
          anchorEl={anchorEl}
          modifiers={{
            offset: {
              enabled: true,
              offset: "0, 335",
            },
          }}
          style={{ width: `${dropdownWidth}px`, height: 0 }}
        />
      );
    }

    return (
      <Popper
        {...containerProps}
        placement={"bottom-end"}
        style={{ width: `${dropdownWidth}px`, height: 0 }}
      />
    );
  };

  const PaperComponent = (containerProps: any) => (
    <Paper className={classes.listbox}>{containerProps.children}</Paper>
  );

  const onInputChange = (_event: any, inputVal: string, reason: string) => {
    if (reason === "clear") {
      setInputValue("");
    } else if (reason === "input") {
      setInputValue(inputVal);
    }
  };

  const handleChange = (event: any, selectedVal: any) => {
    onChange(event, selectedVal);
    if (selectedVal) {
      setInputValue(multiple ? "" : selectedVal?.name);
    }
  };

  const renderTags = (tagValue: CTMInfo[], getTagProps: any) => {
    const tagVals = tagValue.map(
      (val) =>
        val?.firstName +
        " " +
        val?.lastName +
        (val?.isPcp ? ", PCP" : `, ${val?.roleAbbreviation}`)
    );
    return tagVals.map(
      (option: string, index: number) =>
        option && (
          <Chip
            {...getTagProps({ index })}
            data-testid="provider-combobox-chip"
            className={classes.chip}
            key={index}
            label={
              option?.length > nameMaxLength
                ? option?.substring(0, nameMaxLength) + "..."
                : option
            }
          />
        )
    );
  };

  const renderOption = (props: any, option: CTMInfo, state: any) => {
    if (option?.name === unclaimedUser?.name) {
      return (
        <li
          className={classes.noneContainer}
          data-testid="provider-combobox-none-item"
          {...props}
        >
          <div className={classes.noneAvatarContainer}>
            <NotInterestedIcon className={classes.icon} />
          </div>
          <div>
            <div className={classes.noneInfo}>
              <Typography appearance="smallBody" color="textPrimary">
                {option.name}
              </Typography>
            </div>
          </div>
        </li>
      );
    }
    return (
      <li
        className={classes.listItem}
        data-testid="provider-combobox-item"
        {...props}
      >
        {multiple && (
          <Checkbox
            data-testid="provider-combobox-checkbox"
            checked={state.selected}
            name={option?.name!}
          />
        )}
        <div>
          <ContactCard
            name={option?.name}
            avatarUrl={option?.avatarUrl}
            titles={option?.guilds}
            careTeamName={option?.careTeamName}
            abbreviation={option?.roleAbbreviation}
            searchInputValue={state.inputValue}
          />
        </div>
      </li>
    );
  };

  const sortedCtmArray =
    ctmList?.sort((a: CTMInfo, b: CTMInfo) => {
      const subCompareName = a.name > b.name ? 1 : 0;
      const compareName = a.name < b.name ? -1 : subCompareName;
      if (a?.careTeamName && b?.careTeamName) {
        const subCompareCareTeamName = a.careTeamName > b.careTeamName ? 1 : 0;
        const compareCareTeamName =
          a.careTeamName < b.careTeamName ? -1 : subCompareCareTeamName;
        return compareName || compareCareTeamName;
      } else {
        return compareName;
      }
    }) || [];

  const sortPcpFirst = (a: CTMInfo) => (a.isPcp ? -1 : 1);
  if (isPcpFirstNamedOption) {
    sortedCtmArray.sort(sortPcpFirst);
  }

  let options: CTMInfo[];
  options = unclaimedUser ? [unclaimedUser] : [];
  // @ts-ignore: Object is possibly 'null'.
  if (youUser?.length > 0) {
    // @ts-ignore: Object is possibly 'null'.
    options = [...options, ...youUser, ...sortedCtmArray];
  } else {
    options = [...options, ...sortedCtmArray];
  }

  const filterOptions = createFilterOptions({
    stringify: (option: CTMInfo) =>
      option.name + option.guilds?.[0] + option.careTeamName,
  });

  const getOptionLabel = (option: CTMInfo) => {
    if (!option?.name) {
      return "";
    } else {
      return option.name;
    }
  };

  const getOptionSelected = (option: CTMInfo, val: CTMInfo) => {
    if (val === null) {
      return false;
    } else {
      if (val.podId) {
        return (
          option?.id?.toString() === val?.id?.toString() &&
          option?.podId?.toString() === val?.podId?.toString()
        );
      } else {
        return option?.id?.toString() === val?.id?.toString();
      }
    }
  };

  interface ListboxComponentProps {
    children: React.ReactNode;
    role: string;
  }

  const ListboxComponent = React.forwardRef(function ListboxComponent(
    props: ListboxComponentProps,
    ref: any
  ) {
    const { children, role } = props;
    const itemCount = Array.isArray(children) ? children.length : 0;
    const itemSize = 80;
    const renderRow = (renderProps: ListRowProps) => {
      return React.cloneElement(children?.[renderProps.index], {
        style: renderProps.style,
      });
    };

    return (
      <div ref={ref as any}>
        <AutoSizer className={classes.autoSizer}>
          {({ width }) => (
            <MenuList {...props}>
              <List
                height={dropdownHeight}
                width={width}
                rowHeight={itemSize}
                overscanCount={10}
                rowCount={itemCount}
                rowRenderer={renderRow}
                scrollToIndex={scrollPosition.current?.position}
                scrollToAlignment="auto"
                onRowsRendered={({ startIndex, stopIndex }) => {
                  if (startIndex !== 0) {
                    scrollPosition.current.position = stopIndex;
                  }
                }}
                role={role}
              />
            </MenuList>
          )}
        </AutoSizer>
      </div>
    );
  });

  return (
    <>
      {hasPopover ? (
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "bottom", horizontal: "left" }}
          onClose={closePopover}
          className={popOverClasses.popover}
          data-testid="inline-messaging-cc-recipient-popover"
        >
          <ComboBox
            open={Boolean(anchorEl)}
            renderInput={renderInput}
            // @ts-ignore
            renderTags={renderTags}
            // @ts-ignore
            getOptionDisabled={multiple ? null : getOptionDisabled}
            // @ts-ignore
            renderOption={renderOption}
            // @ts-ignore
            getOptionLabel={getOptionLabel}
            // @ts-ignore
            getOptionSelected={getOptionSelected}
            // @ts-ignore
            ListboxComponent={ListboxComponent}
            PopperComponent={PopperComponent}
            onChange={handleChange}
            // @ts-ignore
            options={options || []}
            value={value ? value : initValue}
            multiple={multiple}
            disableCloseOnSelect={multiple}
            fullWidth
            // @ts-ignore
            filterOptions={filterOptions}
            inputValue={inputValue}
            onInputChange={onInputChange}
            classes={popperClasses}
          />
        </Popover>
      ) : (
        <ComboBox
          classes={{
            endAdornment:
              JSON.stringify(value)?.length > 0
                ? classes.clearIcon
                : classes.clearIconHidden,
          }}
          renderInput={renderInput}
          // @ts-ignore
          renderTags={renderTags}
          // @ts-ignore
          getOptionDisabled={multiple ? null : getOptionDisabled}
          // @ts-ignore
          renderOption={renderOption}
          // @ts-ignore
          getOptionLabel={getOptionLabel}
          // @ts-ignore
          getOptionSelected={getOptionSelected}
          // @ts-ignore
          ListboxComponent={ListboxComponent}
          PaperComponent={PaperComponent}
          PopperComponent={PopperComponent}
          onChange={handleChange}
          // @ts-ignore
          options={options || []}
          value={value ? value : initValue}
          multiple={multiple}
          disableCloseOnSelect={multiple}
          fullWidth
          // @ts-ignore
          filterOptions={filterOptions}
          inputValue={inputValue}
          onInputChange={onInputChange}
        />
      )}
    </>
  );
};

export default ProviderComboBox;
