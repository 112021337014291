// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
    ComponentActionDetails,
    ComponentActionDetailsFromJSON,
    ComponentActionDetailsToJSON,
} from './ComponentActionDetails';
import {
    ComponentDetailsCoreCreatorInfo,
    ComponentDetailsCoreCreatorInfoFromJSON,
    ComponentDetailsCoreCreatorInfoToJSON,
} from './ComponentDetailsCoreCreatorInfo';
import {
    ComponentDetailsCoreResolverInfo,
    ComponentDetailsCoreResolverInfoFromJSON,
    ComponentDetailsCoreResolverInfoToJSON,
} from './ComponentDetailsCoreResolverInfo';
import {
    DocumentOutputSchema,
    DocumentOutputSchemaFromJSON,
    DocumentOutputSchemaToJSON,
} from './DocumentOutputSchema';
import {
    MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';
import {
    UserInfo,
    UserInfoFromJSON,
    UserInfoToJSON,
} from './UserInfo';


/**
 * 
 * @export
 * @interface ComponentCore
 */
export interface ComponentCore  {
    /**
     * 
     * @type {string}
     * @memberof ComponentCore
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentCore
     */
    timelineEventId: string;
    /**
     * 
     * @type {ComponentDetailsCoreResolverInfo}
     * @memberof ComponentCore
     */
    resolverInfo?: ComponentDetailsCoreResolverInfo;
    /**
     * 
     * @type {ComponentDetailsCoreCreatorInfo}
     * @memberof ComponentCore
     */
    creatorInfo?: ComponentDetailsCoreCreatorInfo;
    /**
     * 
     * @type {CTMInfo}
     * @memberof ComponentCore
     */
    acknowledger?: CTMInfo;
    /**
     * 
     * @type {MemberInfo}
     * @memberof ComponentCore
     */
    memberInfo?: MemberInfo;
    /**
     * 
     * @type {string}
     * @memberof ComponentCore
     */
    episodeId: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentCore
     */
    type: ComponentCoreTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ComponentCore
     */
    body?: string;
    /**
     * 
     * @type {Date}
     * @memberof ComponentCore
     */
    addedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ComponentCore
     */
    resolvedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ComponentCore
     */
    viewedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ComponentCore
     */
    urgency?: ComponentCoreUrgencyEnum;
    /**
     * 
     * @type {UserInfo}
     * @memberof ComponentCore
     */
    viewer?: UserInfo;
    /**
     * 
     * @type {Array<DocumentOutputSchema>}
     * @memberof ComponentCore
     */
    documentInfo?: Array<DocumentOutputSchema>;
    /**
     * 
     * @type {Array<ActionableItemSummary>}
     * @memberof ComponentCore
     */
    actionableItems?: Array<ActionableItemSummary>;
    /**
     * 
     * @type {Array<ComponentActionDetails>}
     * @memberof ComponentCore
     */
    actions?: Array<ComponentActionDetails>;
    /**
     * 
     * @type {string}
     * @memberof ComponentCore
     */
    baseSiblingId?: string;
    /**
     * 
     * @type {Date}
     * @memberof ComponentCore
     */
    paidAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ComponentCore
     */
    resourceUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentCore
     */
    cctAssigned?: boolean;
}

export function ComponentCoreFromJSON(json: any): ComponentCore {
    return {
        'id': json['id'],
        'timelineEventId': json['timeline_event_id'],
        'resolverInfo': !exists(json, 'resolver_info') ? undefined : ComponentDetailsCoreResolverInfoFromJSON(json['resolver_info']),
        'creatorInfo': !exists(json, 'creator_info') ? undefined : ComponentDetailsCoreCreatorInfoFromJSON(json['creator_info']),
        'acknowledger': !exists(json, 'acknowledger') ? undefined : CTMInfoFromJSON(json['acknowledger']),
        'memberInfo': !exists(json, 'member_info') ? undefined : MemberInfoFromJSON(json['member_info']),
        'episodeId': json['episode_id'],
        'type': json['type'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'addedAt': !exists(json, 'added_at') ? undefined : new Date(json['added_at']),
        'resolvedAt': !exists(json, 'resolved_at') ? undefined : new Date(json['resolved_at']),
        'viewedAt': !exists(json, 'viewed_at') ? undefined : new Date(json['viewed_at']),
        'urgency': !exists(json, 'urgency') ? undefined : json['urgency'],
        'viewer': !exists(json, 'viewer') ? undefined : UserInfoFromJSON(json['viewer']),
        'documentInfo': !exists(json, 'document_info') ? undefined : (json['document_info'] as Array<any>).map(DocumentOutputSchemaFromJSON),
        'actionableItems': !exists(json, 'actionable_items') ? undefined : (json['actionable_items'] as Array<any>).map(ActionableItemSummaryFromJSON),
        'actions': !exists(json, 'actions') ? undefined : (json['actions'] as Array<any>).map(ComponentActionDetailsFromJSON),
        'baseSiblingId': !exists(json, 'base_sibling_id') ? undefined : json['base_sibling_id'],
        'paidAt': !exists(json, 'paid_at') ? undefined : new Date(json['paid_at']),
        'resourceUrl': !exists(json, 'resource_url') ? undefined : json['resource_url'],
        'cctAssigned': !exists(json, 'cct_assigned') ? undefined : json['cct_assigned'],
    };
}

export function ComponentCoreToJSON(value?: ComponentCore): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'timeline_event_id': value.timelineEventId,
        'resolver_info': ComponentDetailsCoreResolverInfoToJSON(value.resolverInfo),
        'creator_info': ComponentDetailsCoreCreatorInfoToJSON(value.creatorInfo),
        'acknowledger': CTMInfoToJSON(value.acknowledger),
        'member_info': MemberInfoToJSON(value.memberInfo),
        'episode_id': value.episodeId,
        'type': value.type,
        'body': value.body,
        'added_at': value.addedAt === undefined ? undefined : value.addedAt.toISOString(),
        'resolved_at': value.resolvedAt === undefined ? undefined : value.resolvedAt.toISOString(),
        'viewed_at': value.viewedAt === undefined ? undefined : value.viewedAt.toISOString(),
        'urgency': value.urgency,
        'viewer': UserInfoToJSON(value.viewer),
        'document_info': value.documentInfo === undefined ? undefined : (value.documentInfo as Array<any>).map(DocumentOutputSchemaToJSON),
        'actionable_items': value.actionableItems === undefined ? undefined : (value.actionableItems as Array<any>).map(ActionableItemSummaryToJSON),
        'actions': value.actions === undefined ? undefined : (value.actions as Array<any>).map(ComponentActionDetailsToJSON),
        'base_sibling_id': value.baseSiblingId,
        'paid_at': value.paidAt === undefined ? undefined : value.paidAt.toISOString(),
        'resource_url': value.resourceUrl,
        'cct_assigned': value.cctAssigned,
    };
}

/**
* @export
* @enum {string}
*/
export enum ComponentCoreTypeEnum {
    EpisodeCreated = 'episode_created',
    MemberMessage = 'member_message',
    CtmMessage = 'ctm_message',
    Diagnosis = 'diagnosis',
    VirtualVisitReferral = 'virtual_visit_referral',
    CtmEpisodeCreated = 'ctm_episode_created',
    CtmMessagingEpisodeCreated = 'ctm_messaging_episode_created',
    QuestionSet = 'question_set',
    Calculator = 'calculator',
    CenterVisitReferral = 'center_visit_referral',
    VisitReferral = 'visit_referral',
    MarketingMessage = 'marketing_message',
    Billing = 'billing',
    SelfScheduleAppointment = 'self_schedule_appointment',
    MessagingEpisodeCreated = 'messaging_episode_created'
}
/**
* @export
* @enum {string}
*/
export enum ComponentCoreUrgencyEnum {
    Asap = 'asap',
    NextFewDays = 'next_few_days',
    NextFewWeeks = 'next_few_weeks'
}


