// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserRequestBody
 */
export interface UpdateUserRequestBody  {
    /**
     * New primary care provider for the user
     * @type {string}
     * @memberof UpdateUserRequestBody
     */
    pcp?: string;
    /**
     * Email to be stored to the account
     * @type {string}
     * @memberof UpdateUserRequestBody
     */
    email?: string;
    /**
     * Colon-separated string of the accounts ef_id and medical plan
     * @type {string}
     * @memberof UpdateUserRequestBody
     */
    pair?: string;
    /**
     * The numerical ID of the new default clinic for the account
     * @type {string}
     * @memberof UpdateUserRequestBody
     */
    clinicId?: string;
}

export function UpdateUserRequestBodyFromJSON(json: any): UpdateUserRequestBody {
    return {
        'pcp': !exists(json, 'pcp') ? undefined : json['pcp'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'pair': !exists(json, 'pair') ? undefined : json['pair'],
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
    };
}

export function UpdateUserRequestBodyToJSON(value?: UpdateUserRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'pcp': value.pcp,
        'email': value.email,
        'pair': value.pair,
        'clinic_id': value.clinicId,
    };
}


