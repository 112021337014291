import React from "react";
import { Link } from "react-router-dom";

import Box from "components/Box";
import DashboardVisits, {
  hasUpcomingVisits,
} from "components/VisitHistory/DashboardVisits";
import { useStyles } from "pages/LandingPage/styles";

import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";
import useAccess from "hooks/useAccess";

interface VisitProps {
  id: number;
}

const Visits: React.FC<VisitProps> = ({ id }) => {
  const classes = useStyles();
  const showUpcomingVisits = hasUpcomingVisits(id);
  const hasDashboardEpisodes = useAccess({
    to: PermissionsEnum.DashboardEpisodesRead,
  });

  const shouldRenderVisits = showUpcomingVisits || !hasDashboardEpisodes;

  return shouldRenderVisits ? (
    <Box>
      <DashboardVisits id={id} />

      <Link
        to="care-history?tab=visits"
        className={classes.dashboardLink}
        hidden={!hasDashboardEpisodes}
      >
        See all Visits
      </Link>
    </Box>
  ) : null;
};

export default Visits;
