import {
  PDFViewerMode,
  PDFViewerSideNavigationState,
  PDFViewerControllerResponse,
} from "./utils";
import { IconButton, NativeSelect } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import clsx from "clsx";
import Menu from "components/Menu";
import Paper from "components/Paper";
import { Link } from "components/Button";
import Divider from "components/Divider";
import AddIcon from "@mui/icons-material/Add";
import Typography from "components/Typography";
import ListIcon from "@mui/icons-material/List";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "components/Menu/MenuItem";
import React, { useRef, useState } from "react";
import PrintIcon from "@mui/icons-material/Print";
import useMobileCheck from "hooks/useMobileCheck";
import RemoveIcon from "@mui/icons-material/Remove";
import DownloadIcon from "@mui/icons-material/GetApp";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LastPageIcon from "@mui/icons-material/LastPage";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import ViewPreviewIcon from "@mui/icons-material/ViewModule";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import SinglePageViewIcon from "@mui/icons-material/InsertDriveFile";
import Rotate90DegreesCcwIcon from "@mui/icons-material/Rotate90DegreesCcw";

interface PDFViewerToolbarProps {
  title: string;
  className?: string;
  hasPageFitZoom: boolean;
  fileDownloadUrl?: string;
  viewController: PDFViewerControllerResponse;
  isLargeFile?: boolean;
}

const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    root: {
      display: "flex",
      height: 64,
      alignItems: "center",
      flexDirection: "row",
      color: palette.white,
      backgroundColor: "#202124",
      paddingTop: spacing(1),
      paddingBottom: spacing(1),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
    },
    sideNavigationToggleButton: {
      marginLeft: spacing(-1.5),
      marginRight: 0.5,
    },
    toolbarMiddleSection: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginLeft: ({ isMobile }: { isMobile: boolean }) =>
        isMobile ? 0 : spacing(4),
    },

    pageCountContainer: {
      display: "inline-block",
      width: spacing(3.5),
      padding: spacing(0.25),
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
      backgroundColor: palette.black,
    },
    divider: {
      height: spacing(3),
      backgroundColor: palette.appBackground?.medGrey,
      marginLeft: spacing(1),
      marginRight: spacing(1),
    },
    disabledButton: {
      "&.Mui-disabled": {
        color: palette.appBackground?.medGrey,
      },
    },
    downloadButton: {
      padding: spacing(1.5),
      minWidth: "initial",
      "&:hover": { color: "unset" },
    },
    selectIcon: {
      color: palette.white,
    },
    scaleSelect: {
      paddingLeft: spacing(1),
      paddingRight: spacing(1),
      color: palette.white,
      backgroundColor: "#36373a",
      "&:hover:not(.Mui-disabled)": {
        backgroundColor: "#4d4d50",
      },
    },
    simpleRow: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
    },
    zoomActions: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "nowrap",
    },
  })
);

const PDFViewerToolbar = ({
  title,
  className,
  viewController,
  hasPageFitZoom,
  fileDownloadUrl,
  isLargeFile,
}: PDFViewerToolbarProps) => {
  const isMobile = useMobileCheck();
  const classes = useStyles({ isMobile });

  const selectZoomOptions = [50, 75, 100, 125, 150, 200, 300, 400];
  const [moreOptionsAnchor, setMoreOptionsAnchor] =
    useState<HTMLButtonElement | null>(null);
  const menuAnchorRef = useRef<HTMLButtonElement | null>(null);

  const {
    scale,
    pageCount,
    activePage,
    fullWidthScale,
    selectedViewMode,
    sideNavigationState,
    printOrDownloadEnabled,
    print,
    setScale,
    setViewMode,
    onItemClick,
    setRotationAngle,
    setSideNavigationState,
    setActivePage,
  } = viewController;

  const handleRotate = () =>
    setRotationAngle((prev) => {
      if (prev === undefined) {
        return 270;
      }
      if (prev === 90) {
        return undefined;
      }
      return prev - 90;
    });

  return (
    <Paper className={clsx(classes.root, className)} elevation={3} square>
      {!isMobile && (
        <>
          <div className={classes.sideNavigationToggleButton}>
            <IconButton
              color="inherit"
              onClick={() => {
                setMoreOptionsAnchor(null);
                setSideNavigationState((prev) => {
                  if (prev !== PDFViewerSideNavigationState.CLOSED) {
                    return PDFViewerSideNavigationState.CLOSED;
                  }
                  return PDFViewerSideNavigationState.PREVIEW;
                });
              }}
              size="large">
              <MenuIcon />
            </IconButton>
          </div>

          <Typography noWrap>{title}</Typography>
        </>
      )}

      <div className={classes.toolbarMiddleSection}>
        <div style={{ marginRight: 12, display: "flex", flexDirection: "row" }}>
          <span className={classes.pageCountContainer}>{activePage}</span>
          <span>&nbsp;/&nbsp;</span>
          <span>{pageCount}</span>
        </div>

        {!isMobile && (
          <>
            <Divider
              orientation="vertical"
              classes={{ root: classes.divider }}
            />

            <div className={classes.simpleRow}>
              <IconButton
                color="inherit"
                title="Previous page"
                disabled={typeof activePage === "undefined" || activePage <= 1}
                classes={{ disabled: classes.disabledButton }}
                onClick={() =>
                  isLargeFile
                    ? setActivePage(activePage - 1)
                    : onItemClick({ pageNumber: activePage - 1 })
                }
                size="large">
                <ArrowUpwardIcon />
              </IconButton>
              <IconButton
                color="inherit"
                title="Next page"
                disabled={activePage === pageCount}
                classes={{ disabled: classes.disabledButton }}
                onClick={() =>
                  isLargeFile
                    ? setActivePage(activePage + 1)
                    : onItemClick({ pageNumber: activePage + 1 })
                }
                size="large">
                <ArrowDownwardIcon />
              </IconButton>
            </div>
            <Divider
              orientation="vertical"
              classes={{ root: classes.divider }}
            />
          </>
        )}

        <div className={classes.zoomActions}>
          <IconButton
            color="inherit"
            title="Zoom out"
            disabled={scale <= 10}
            classes={{ disabled: classes.disabledButton }}
            size={isMobile ? "small" : "medium"}
            onClick={() =>
              setScale((prev) =>
                prev === 75 || prev === 125 ? prev - 5 : prev - 10
              )
            }
          >
            <RemoveIcon />
          </IconButton>

          {!isMobile && (
            <NativeSelect
              title="Zoom"
              value={scale}
              disableUnderline
              className={classes.scaleSelect}
              classes={{ icon: classes.selectIcon }}
              inputProps={{ name: "PDF page zoom scale select" }}
              onChange={(event) => {
                const { value } = event.target;
                if (Number(value) || value === "0") {
                  setScale(Number(value));
                }
              }}
            >
              {/* //todo: show actual zoom percentage in manual zoom option */}
              <option>Manual Zoom</option>
              <option value={fullWidthScale}>Page Width</option>
              <option value={0} disabled={!hasPageFitZoom}>
                Page Fit
              </option>
              {selectZoomOptions.map((value) => {
                const label = value === 100 ? "Actual Size" : `${value}%`;
                return (
                  <option key={value} value={value}>
                    {label}
                  </option>
                );
              })}
            </NativeSelect>
          )}

          {isMobile && (
            <Divider
              orientation="vertical"
              classes={{ root: classes.divider }}
            />
          )}

          <IconButton
            title="Zoom in"
            color="inherit"
            disabled={!Boolean(pageCount)}
            classes={{ disabled: classes.disabledButton }}
            size={isMobile ? "small" : "medium"}
            onClick={() =>
              setScale((prev) => {
                if (prev === 0) {
                  return 100;
                } else {
                  return prev === 75 || prev === 125 ? prev + 5 : prev + 10;
                }
              })
            }
          >
            <AddIcon />
          </IconButton>
        </div>

        {!isMobile && (
          <>
            <Divider
              orientation="vertical"
              classes={{ root: classes.divider }}
            />

            <IconButton
              title="Rotate"
              color="inherit"
              disabled={!Boolean(pageCount)}
              classes={{ disabled: classes.disabledButton }}
              onClick={handleRotate}
              size="large">
              <Rotate90DegreesCcwIcon />
            </IconButton>
          </>
        )}
      </div>

      <div style={{ flexGrow: 1 }} />

      <div className={classes.simpleRow}>
        {printOrDownloadEnabled && (
          <Link
            title="Download"
            name="download"
            target="_blank"
            download={title}
            id={"download-pdf"}
            href={fileDownloadUrl}
            rel="noopener noreferrer"
            className={classes.downloadButton}
            classes={{ disabled: classes.disabledButton }}
            aria-label={`Download ${title} file to your device`}
            disabled={!Boolean(pageCount) || !Boolean(fileDownloadUrl?.length)}
          >
            <DownloadIcon />
          </Link>
        )}

        {printOrDownloadEnabled && (
          <IconButton
            title="Print"
            color="inherit"
            onClick={print}
            disabled={!Boolean(pageCount)}
            classes={{ disabled: classes.disabledButton }}
            size="large">
            <PrintIcon />
          </IconButton>
        )}

        <IconButton
          color="inherit"
          ref={menuAnchorRef}
          onClick={() => {
            setMoreOptionsAnchor((prev) => {
              if (Boolean(prev)) {
                return null;
              } else {
                return menuAnchorRef.current;
              }
            });
          }}
          aria-controls="pdf-viewer-more-options"
          size="large">
          <MoreVertIcon />
        </IconButton>
        {/* // TODO: to match the viewer background color, the menu could use a dark theme */}
        <Menu
          elevation={24}
          keepMounted={false}
          // getContentAnchorEl={null}
          id="pdf-viewer-more-options"
          anchorEl={moreOptionsAnchor}
          open={Boolean(moreOptionsAnchor)}
          onClose={() => setMoreOptionsAnchor(null)}
          transformOrigin={{ horizontal: 212, vertical: 0 }}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          {isMobile && [
            <MenuItem
              key="download-pdf-menu"
              component="a"
              target="_blank"
              download={title}
              id="download-pdf"
              href={fileDownloadUrl}
              rel="noopener noreferrer"
              style={{ justifyContent: "flex-start" }}
              aria-label={`Download ${title} file to your device`}
              disabled={
                !Boolean(pageCount) || !Boolean(fileDownloadUrl?.length)
              }
            >
              <DownloadIcon fontSize="small" style={{ padding: 0 }} />
              <Typography variant="inherit" ml={1}>
                Download
              </Typography>
            </MenuItem>,
            <MenuItem
              key="rotate-document"
              disabled={!Boolean(pageCount)}
              onClick={handleRotate}
            >
              <Rotate90DegreesCcwIcon fontSize="small" />
              <Typography variant="inherit" ml={1}>
                Rotate 90°
              </Typography>
            </MenuItem>,
            <Divider key="divider-upper-section" />,
          ]}

          <MenuItem
            disabled={activePage === 1}
            onClick={() => {
              setMoreOptionsAnchor(null);
              onItemClick({ pageNumber: 1 });
            }}
          >
            <FirstPageIcon fontSize="small" />
            <Typography variant="inherit" ml={1}>
              Go to first page
            </Typography>
          </MenuItem>
          <MenuItem
            disabled={activePage === pageCount}
            onClick={() => {
              setMoreOptionsAnchor(null);
              onItemClick({ pageNumber: pageCount });
            }}
          >
            <LastPageIcon fontSize="small" />
            <Typography variant="inherit" ml={1}>
              Go to last page
            </Typography>
          </MenuItem>

          <Divider />

          <MenuItem
            onClick={() => {
              setMoreOptionsAnchor(null);
              setSideNavigationState((prev) => {
                if (prev !== PDFViewerSideNavigationState.PREVIEW) {
                  return PDFViewerSideNavigationState.PREVIEW;
                }
                return PDFViewerSideNavigationState.CLOSED;
              });
            }}
          >
            <ViewPreviewIcon
              fontSize="small"
              style={{ transform: "rotate(90deg)" }}
            />
            <Typography variant="inherit" ml={1}>
              {sideNavigationState === PDFViewerSideNavigationState.PREVIEW
                ? "Hide Preview"
                : "Show Preview"}
            </Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setMoreOptionsAnchor(null);
              setSideNavigationState((prev) => {
                if (prev !== PDFViewerSideNavigationState.OUTLINE) {
                  return PDFViewerSideNavigationState.OUTLINE;
                }
                return PDFViewerSideNavigationState.CLOSED;
              });
            }}
          >
            <ListIcon fontSize="small" />
            <Typography variant="inherit" ml={1}>
              {sideNavigationState === PDFViewerSideNavigationState.OUTLINE
                ? "Hide Document Outline"
                : "Show Document Outline"}
            </Typography>
          </MenuItem>

          <Divider />
          <MenuItem
            disabled={
              selectedViewMode === PDFViewerMode.SINGLE_PAGE || pageCount <= 1
            }
            onClick={() => {
              setMoreOptionsAnchor(null);
              setViewMode(PDFViewerMode.SINGLE_PAGE);
            }}
          >
            <SinglePageViewIcon fontSize="small" />
            <Typography variant="inherit" ml={1}>
              Single page view
            </Typography>
          </MenuItem>
          <MenuItem
            disabled
            onClick={() => {
              setMoreOptionsAnchor(null);
              setViewMode(PDFViewerMode.HORIZONTAL);
            }}
          >
            <SwapHorizIcon fontSize="small" />
            <Typography variant="inherit" ml={1}>
              Horizontal scrolling
            </Typography>
          </MenuItem>
          <MenuItem
            disabled={selectedViewMode === PDFViewerMode.VERTICAL}
            onClick={() => {
              setMoreOptionsAnchor(null);
              setViewMode(PDFViewerMode.VERTICAL);
            }}
          >
            <SwapVertIcon fontSize="small" />
            <Typography variant="inherit" ml={1}>
              Vertical scrolling
            </Typography>
          </MenuItem>
        </Menu>
      </div>
    </Paper>
  );
};

export default PDFViewerToolbar;
