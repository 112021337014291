import React from "react";
import Paper from "components/Paper";
import Alert from "components/Alert";
import Skeleton from "components/Skeleton";
import Typography from "components/Typography";
import { Box } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import CircularProgress from "components/Progress/CircularProgress";

const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    container: {
      backgroundColor: palette.white,
      display: "flex",
      flexDirection: "column",
      rowGap: 24,
      height: "100%",
      width: "100%",
      overflow: "hidden",
    },
    toolbar: {
      minHeight: 64,
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    content: {
      flexGrow: 1,
      display: "grid",
    },
  })
);

interface PDFViewerMessageProps {
  isPage?: boolean;
  isError?: boolean;
  isNoData?: boolean;
  isLoading?: boolean;
  errorMessage?: string;
  loadingMessage?: string;
  noDataMessage?: string;
}

const PDFViewerMessage = ({
  isPage = false,
  isError = false,
  isNoData = false,
  isLoading = false,
  loadingMessage = "Loading PDF file...",
  noDataMessage = "No PDF file specified",
  errorMessage = "Failed to load PDF file",
}: PDFViewerMessageProps) => {
  const errorNotSpecified = !isError && !isLoading && !isNoData;
  const classes = useStyles();

  return (
    <Paper className={classes.container} elevation={0} variant="outlined">
      {!isPage && (
        <Paper className={classes.toolbar} elevation={0}>
          <Box
            gap={32}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Skeleton
              height={24}
              width={192}
              variant="rectangular"
              animation={isLoading ? "pulse" : false}
            />
            <Box gap={8} display={"flex"}>
              <Skeleton
                width={32}
                height={24}
                variant="rectangular"
                animation={isLoading ? "pulse" : false}
              />
              <Skeleton
                width={32}
                height={24}
                variant="rectangular"
                animation={isLoading ? "pulse" : false}
              />
              <Skeleton
                width={32}
                height={24}
                variant="rectangular"
                animation={isLoading ? "pulse" : false}
              />
            </Box>
          </Box>
          <Box gap={8} display={"flex"}>
            <Skeleton
              width={32}
              height={24}
              variant="rectangular"
              animation={isLoading ? "pulse" : false}
            />
            <Skeleton
              width={32}
              height={24}
              variant="rectangular"
              animation={isLoading ? "pulse" : false}
            />
          </Box>
        </Paper>
      )}

      <div className={classes.content}>
        <Box
          gap={16}
          marginTop={-4}
          display={"flex"}
          alignItems={"center"}
          flexDirection={"column"}
          justifyContent={"center"}
        >
          {isLoading && (
            <React.Fragment>
              <CircularProgress />
              <Typography color="textSecondary">{loadingMessage}</Typography>
            </React.Fragment>
          )}
          {isError && <Alert severity="error">{errorMessage}</Alert>}
          {isNoData && <Alert severity="error">{noDataMessage}</Alert>}
          {errorNotSpecified && (
            <Alert severity="error">An error occurred!</Alert>
          )}
        </Box>
      </div>
    </Paper>
  );
};

export default PDFViewerMessage;
