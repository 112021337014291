// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HeadsUpNotification,
    HeadsUpNotificationFromJSON,
    HeadsUpNotificationToJSON,
} from './HeadsUpNotification';


/**
 * 
 * @export
 * @interface FetchCTMHeadsUpResponse
 */
export interface FetchCTMHeadsUpResponse  {
    /**
     * 
     * @type {number}
     * @memberof FetchCTMHeadsUpResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof FetchCTMHeadsUpResponse
     */
    unreadCount?: number;
    /**
     * 
     * @type {number}
     * @memberof FetchCTMHeadsUpResponse
     */
    readCount?: number;
    /**
     * 
     * @type {Array<HeadsUpNotification>}
     * @memberof FetchCTMHeadsUpResponse
     */
    results?: Array<HeadsUpNotification>;
}

export function FetchCTMHeadsUpResponseFromJSON(json: any): FetchCTMHeadsUpResponse {
    return {
        'totalCount': !exists(json, 'total_count') ? undefined : json['total_count'],
        'unreadCount': !exists(json, 'unread_count') ? undefined : json['unread_count'],
        'readCount': !exists(json, 'read_count') ? undefined : json['read_count'],
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(HeadsUpNotificationFromJSON),
    };
}

export function FetchCTMHeadsUpResponseToJSON(value?: FetchCTMHeadsUpResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'total_count': value.totalCount,
        'unread_count': value.unreadCount,
        'read_count': value.readCount,
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(HeadsUpNotificationToJSON),
    };
}


