// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HoursOfOperation,
    HoursOfOperationFromJSON,
    HoursOfOperationToJSON,
} from './HoursOfOperation';
import {
    NetworkProvider,
    NetworkProviderFromJSON,
    NetworkProviderToJSON,
} from './NetworkProvider';


/**
 * 
 * @export
 * @interface NetworkCareTeamFiltered
 */
export interface NetworkCareTeamFiltered  {
    /**
     * 
     * @type {string}
     * @memberof NetworkCareTeamFiltered
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkCareTeamFiltered
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof NetworkCareTeamFiltered
     */
    core?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NetworkCareTeamFiltered
     */
    containsPcp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NetworkCareTeamFiltered
     */
    virtual?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NetworkCareTeamFiltered
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkCareTeamFiltered
     */
    addressUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkCareTeamFiltered
     */
    phone?: string;
    /**
     * 
     * @type {HoursOfOperation}
     * @memberof NetworkCareTeamFiltered
     */
    hoursOfOperation?: HoursOfOperation;
    /**
     * 
     * @type {string}
     * @memberof NetworkCareTeamFiltered
     */
    websiteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkCareTeamFiltered
     */
    imageUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkCareTeamFiltered
     */
    selectedFilter: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NetworkCareTeamFiltered
     */
    availableFilters: Array<string>;
    /**
     * 
     * @type {Array<NetworkProvider>}
     * @memberof NetworkCareTeamFiltered
     */
    providers: Array<NetworkProvider>;
}

export function NetworkCareTeamFilteredFromJSON(json: any): NetworkCareTeamFiltered {
    return {
        'id': json['id'],
        'name': json['name'],
        'core': !exists(json, 'core') ? undefined : json['core'],
        'containsPcp': !exists(json, 'contains_pcp') ? undefined : json['contains_pcp'],
        'virtual': !exists(json, 'virtual') ? undefined : json['virtual'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'addressUrl': !exists(json, 'address_url') ? undefined : json['address_url'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'hoursOfOperation': !exists(json, 'hours_of_operation') ? undefined : HoursOfOperationFromJSON(json['hours_of_operation']),
        'websiteUrl': !exists(json, 'website_url') ? undefined : json['website_url'],
        'imageUrl': !exists(json, 'image_url') ? undefined : json['image_url'],
        'selectedFilter': json['selected_filter'],
        'availableFilters': json['available_filters'],
        'providers': (json['providers'] as Array<any>).map(NetworkProviderFromJSON),
    };
}

export function NetworkCareTeamFilteredToJSON(value?: NetworkCareTeamFiltered): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'core': value.core,
        'contains_pcp': value.containsPcp,
        'virtual': value.virtual,
        'address': value.address,
        'address_url': value.addressUrl,
        'phone': value.phone,
        'hours_of_operation': HoursOfOperationToJSON(value.hoursOfOperation),
        'website_url': value.websiteUrl,
        'image_url': value.imageUrl,
        'selected_filter': value.selectedFilter,
        'available_filters': value.availableFilters,
        'providers': (value.providers as Array<any>).map(NetworkProviderToJSON),
    };
}


