// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProvidersObject
 */
export interface ProvidersObject  {
    /**
     * 
     * @type {number}
     * @memberof ProvidersObject
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProvidersObject
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProvidersObject
     */
    guilds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProvidersObject
     */
    centers?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProvidersObject
     */
    isContact?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProvidersObject
     */
    isPcp?: boolean;
}

export function ProvidersObjectFromJSON(json: any): ProvidersObject {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'guilds': !exists(json, 'guilds') ? undefined : json['guilds'],
        'centers': !exists(json, 'centers') ? undefined : json['centers'],
        'isContact': !exists(json, 'is_contact') ? undefined : json['is_contact'],
        'isPcp': !exists(json, 'is_pcp') ? undefined : json['is_pcp'],
    };
}

export function ProvidersObjectToJSON(value?: ProvidersObject): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'guilds': value.guilds,
        'centers': value.centers,
        'is_contact': value.isContact,
        'is_pcp': value.isPcp,
    };
}


