// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MarketingMessageOutput,
    MarketingMessageOutputFromJSON,
    MarketingMessageOutputToJSON,
} from './MarketingMessageOutput';


/**
 * 
 * @export
 * @interface MarketingMessageComponentOutputProps
 */
export interface MarketingMessageComponentOutputProps  {
    /**
     * 
     * @type {string}
     * @memberof MarketingMessageComponentOutputProps
     */
    state?: MarketingMessageComponentOutputPropsStateEnum;
    /**
     * 
     * @type {Array<MarketingMessageOutput>}
     * @memberof MarketingMessageComponentOutputProps
     */
    marketingMessages?: Array<MarketingMessageOutput>;
}

export function MarketingMessageComponentOutputPropsFromJSON(json: any): MarketingMessageComponentOutputProps {
    return {
        'state': !exists(json, 'state') ? undefined : json['state'],
        'marketingMessages': !exists(json, 'marketing_messages') ? undefined : (json['marketing_messages'] as Array<any>).map(MarketingMessageOutputFromJSON),
    };
}

export function MarketingMessageComponentOutputPropsToJSON(value?: MarketingMessageComponentOutputProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'state': value.state,
        'marketing_messages': value.marketingMessages === undefined ? undefined : (value.marketingMessages as Array<any>).map(MarketingMessageOutputToJSON),
    };
}

/**
* @export
* @enum {string}
*/
export enum MarketingMessageComponentOutputPropsStateEnum {
    Resolved = 'resolved'
}


