// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InsuranceCoverageDetailsInsurancesInsurance1Field1
 */
export interface InsuranceCoverageDetailsInsurancesInsurance1Field1  {
    /**
     * 
     * @type {string}
     * @memberof InsuranceCoverageDetailsInsurancesInsurance1Field1
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InsuranceCoverageDetailsInsurancesInsurance1Field1
     */
    value?: string;
}

export function InsuranceCoverageDetailsInsurancesInsurance1Field1FromJSON(json: any): InsuranceCoverageDetailsInsurancesInsurance1Field1 {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function InsuranceCoverageDetailsInsurancesInsurance1Field1ToJSON(value?: InsuranceCoverageDetailsInsurancesInsurance1Field1): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'value': value.value,
    };
}


