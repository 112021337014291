import { OutputMemberSearchSchemaProps } from "shared/fetch/src/models/OutputMemberSearchSchemaProps";
import { OutputMemberSearchSchemaPropsPrimaryAccountHolder } from "shared/fetch/src/models/OutputMemberSearchSchemaPropsPrimaryAccountHolder";


const teenBadgeBackground = "rgb(67, 120, 0)";
const childBadgeBackground = "rgb(97, 55, 210)";
export const getMinorBadgeBackgroundColor = (isTeen: boolean) => {
  return isTeen ? teenBadgeBackground : childBadgeBackground;
};

// relevant return values from BE
// 1 User has no primary account holder-- user.primary_account_holder will be always? empty object or nil
// 2 User has a primary account holder-- user.primary_account_holder.id will be always be truthy
// 3 User has a primary account holder but w/o an address user.primary_account_holder.address will be nil
export const userHasPrimaryAccountHolder = (
  primaryAccountHolderObj:
    | OutputMemberSearchSchemaPropsPrimaryAccountHolder
    | undefined
) => {
  return primaryAccountHolderObj?.id ? true : false;
};

// will return the primary account holder's address if it exists, or false if it does not.
export const getPrimaryAccountHolderAddress = (
  primaryAccountHolderObj:
    | OutputMemberSearchSchemaPropsPrimaryAccountHolder
    | undefined
) => {
  return primaryAccountHolderObj?.address
    ? primaryAccountHolderObj.address
    : false;
};

export const getMinorCanBeNested = (
  user: OutputMemberSearchSchemaProps | undefined,
  ctmCanNestMinor: boolean | undefined
) => {
  return ctmCanNestMinor && user?.hasProxyOption;
};

export const showGreenPlus = (
  user: OutputMemberSearchSchemaProps | undefined
) => {
  const showGreenPlusAdult = !user?.minor && !user?.registered;
  const showGreenPlusMinor =
    user?.minor &&
    userHasPrimaryAccountHolder(user?.primaryAccountHolder) &&
    !user?.registered;
  return showGreenPlusAdult || showGreenPlusMinor;
};
