// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CalculatorHistory,
    CalculatorHistoryFromJSON,
    CalculatorHistoryToJSON,
} from './CalculatorHistory';


/**
 * 
 * @export
 * @interface ListCalculatorHistoryResponse
 */
export interface ListCalculatorHistoryResponse  {
    /**
     * 
     * @type {number}
     * @memberof ListCalculatorHistoryResponse
     */
    count: number;
    /**
     * 
     * @type {Array<CalculatorHistory>}
     * @memberof ListCalculatorHistoryResponse
     */
    results: Array<CalculatorHistory>;
}

export function ListCalculatorHistoryResponseFromJSON(json: any): ListCalculatorHistoryResponse {
    return {
        'count': json['count'],
        'results': (json['results'] as Array<any>).map(CalculatorHistoryFromJSON),
    };
}

export function ListCalculatorHistoryResponseToJSON(value?: ListCalculatorHistoryResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'results': (value.results as Array<any>).map(CalculatorHistoryToJSON),
    };
}


