import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import {
  UnenrollDeviceBiometricsRequest,
  fetchBiometricsApi,
} from "shared/fetch/src/apis/BiometricsApi";
import { showSnackbar } from "shared/state/ui/snackbar";

const useUnenrollDeviceBiometrics = () => {
  const dispatch = useDispatch();

  const { mutate } = useMutation((request: UnenrollDeviceBiometricsRequest) => {
    return fetchBiometricsApi
      .unenrollDeviceBiometrics(request)
      .then(() => {
        dispatch(
          showSnackbar("Device has been unenrolled from biometric login.")
        );
      })
      .catch(() => {
        dispatch(
          showSnackbar(
            "There was an issue unenrolling your device, please try again later.",
            "danger"
          )
        );
      });
  });
  return { unenrollDeviceBiometrics: mutate };
};

export default useUnenrollDeviceBiometrics;
