/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ChargeItem,
    ChargeItemFromJSON,
    ChargeItemToJSON,
} from '../models/ChargeItem'
import {
    DeletePaymentMethodSchema,
    DeletePaymentMethodSchemaFromJSON,
    DeletePaymentMethodSchemaToJSON,
} from '../models/DeletePaymentMethodSchema'
import {
    FetchStripeCoupon,
    FetchStripeCouponFromJSON,
    FetchStripeCouponToJSON,
} from '../models/FetchStripeCoupon'
import {
    FetchStripePrice,
    FetchStripePriceFromJSON,
    FetchStripePriceToJSON,
} from '../models/FetchStripePrice'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    InvalidStripeParametersResponse,
    InvalidStripeParametersResponseFromJSON,
    InvalidStripeParametersResponseToJSON,
} from '../models/InvalidStripeParametersResponse'
import {
    ListMemberPaymentHistory,
    ListMemberPaymentHistoryFromJSON,
    ListMemberPaymentHistoryToJSON,
} from '../models/ListMemberPaymentHistory'
import {
    ListMemberPaymentMethods,
    ListMemberPaymentMethodsFromJSON,
    ListMemberPaymentMethodsToJSON,
} from '../models/ListMemberPaymentMethods'
import {
    ListMemberPaymentMethods1,
    ListMemberPaymentMethods1FromJSON,
    ListMemberPaymentMethods1ToJSON,
} from '../models/ListMemberPaymentMethods1'
import {
    ListMemberSubscription,
    ListMemberSubscriptionFromJSON,
    ListMemberSubscriptionToJSON,
} from '../models/ListMemberSubscription'
import {
    MemberChargeSchema,
    MemberChargeSchemaFromJSON,
    MemberChargeSchemaToJSON,
} from '../models/MemberChargeSchema'
import {
    MemberPaymentMethods,
    MemberPaymentMethodsFromJSON,
    MemberPaymentMethodsToJSON,
} from '../models/MemberPaymentMethods'
import {
    MemberReceipt,
    MemberReceiptFromJSON,
    MemberReceiptToJSON,
} from '../models/MemberReceipt'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    PayNowSchema,
    PayNowSchemaFromJSON,
    PayNowSchemaToJSON,
} from '../models/PayNowSchema'
import {
    PostPaymentMethodSchema,
    PostPaymentMethodSchemaFromJSON,
    PostPaymentMethodSchemaToJSON,
} from '../models/PostPaymentMethodSchema'
import {
    SetPaymentMethodSchema,
    SetPaymentMethodSchemaFromJSON,
    SetPaymentMethodSchemaToJSON,
} from '../models/SetPaymentMethodSchema'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpdatePaymentMethodSchema,
    UpdatePaymentMethodSchemaFromJSON,
    UpdatePaymentMethodSchemaToJSON,
} from '../models/UpdatePaymentMethodSchema'


export interface CreateMemberChargeRequest {
    id: string;
    memberChargeSchema?: MemberChargeSchema;
}
export interface DeletePaymentMethodRequest {
    id: string;
    paymentMethodId: string;
    deletePaymentMethodSchema?: DeletePaymentMethodSchema;
}
export interface GetCouponByIdRequest {
    code: string;
}
export interface GetMemberSubscriptionRequest {
    id: string;
}
export interface GetPaymentHistoryRequest {
    id: string;
    page?: string;
    status?: GetPaymentHistoryStatusEnum;
    orderByDate?: GetPaymentHistoryOrderByDateEnum;
    groupByStatus?: boolean;
    fetchSubscriptions?: boolean;
    pageSize?: string;
}
export interface GetPaymentMethodRequest {
    id: string;
    paymentMethodId: string;
}
export interface GetPaymentMethodsRequest {
    id: string;
}
export interface GetPriceByIdRequest {
    priceId: string;
}
export interface GetReceiptRequest {
    id: string;
    receiptNumber: string;
}
export interface PostPayNowRequest {
    id: string;
    payNowSchema?: PayNowSchema;
}
export interface PostPaymentMethodRequest {
    id: string;
    postPaymentMethodSchema?: PostPaymentMethodSchema;
}
export interface SetPaymentMethodRequest {
    id: string;
    paymentMethodId: string;
    setPaymentMethodSchema?: SetPaymentMethodSchema;
}
export interface UpdatePaymentMethodRequest {
    id: string;
    paymentMethodId: string;
    updatePaymentMethodSchema: UpdatePaymentMethodSchema;
}


export interface IMemberPaymentsApi {
      createMemberCharge: (requestParameters: CreateMemberChargeRequest) => Promise<ChargeItem>
      deletePaymentMethod: (requestParameters: DeletePaymentMethodRequest) => Promise<void>
      getCouponById: (requestParameters: GetCouponByIdRequest) => Promise<FetchStripeCoupon>
      getMemberSubscription: (requestParameters: GetMemberSubscriptionRequest) => Promise<ListMemberSubscription>
      getPaymentHistory: (requestParameters: GetPaymentHistoryRequest) => Promise<ListMemberPaymentHistory>
      getPaymentMethod: (requestParameters: GetPaymentMethodRequest) => Promise<MemberPaymentMethods>
      getPaymentMethods: (requestParameters: GetPaymentMethodsRequest) => Promise<ListMemberPaymentMethods>
      getPriceById: (requestParameters: GetPriceByIdRequest) => Promise<FetchStripePrice>
      getReceipt: (requestParameters: GetReceiptRequest) => Promise<MemberReceipt>
      postPayNow: (requestParameters: PostPayNowRequest) => Promise<ListMemberPaymentMethods1>
      postPaymentMethod: (requestParameters: PostPaymentMethodRequest) => Promise<ListMemberPaymentMethods>
      setPaymentMethod: (requestParameters: SetPaymentMethodRequest) => Promise<ListMemberPaymentMethods>
      updatePaymentMethod: (requestParameters: UpdatePaymentMethodRequest) => Promise<MemberPaymentMethods>
      createMemberChargeWithResponse: (requestParameters: CreateMemberChargeRequest) => Promise<{ value: ChargeItem, response: any}>
      deletePaymentMethodWithResponse: (requestParameters: DeletePaymentMethodRequest) => Promise<{ value: void, response: any}>
      getCouponByIdWithResponse: (requestParameters: GetCouponByIdRequest) => Promise<{ value: FetchStripeCoupon, response: any}>
      getMemberSubscriptionWithResponse: (requestParameters: GetMemberSubscriptionRequest) => Promise<{ value: ListMemberSubscription, response: any}>
      getPaymentHistoryWithResponse: (requestParameters: GetPaymentHistoryRequest) => Promise<{ value: ListMemberPaymentHistory, response: any}>
      getPaymentMethodWithResponse: (requestParameters: GetPaymentMethodRequest) => Promise<{ value: MemberPaymentMethods, response: any}>
      getPaymentMethodsWithResponse: (requestParameters: GetPaymentMethodsRequest) => Promise<{ value: ListMemberPaymentMethods, response: any}>
      getPriceByIdWithResponse: (requestParameters: GetPriceByIdRequest) => Promise<{ value: FetchStripePrice, response: any}>
      getReceiptWithResponse: (requestParameters: GetReceiptRequest) => Promise<{ value: MemberReceipt, response: any}>
      postPayNowWithResponse: (requestParameters: PostPayNowRequest) => Promise<{ value: ListMemberPaymentMethods1, response: any}>
      postPaymentMethodWithResponse: (requestParameters: PostPaymentMethodRequest) => Promise<{ value: ListMemberPaymentMethods, response: any}>
      setPaymentMethodWithResponse: (requestParameters: SetPaymentMethodRequest) => Promise<{ value: ListMemberPaymentMethods, response: any}>
      updatePaymentMethodWithResponse: (requestParameters: UpdatePaymentMethodRequest) => Promise<{ value: MemberPaymentMethods, response: any}>
}

/**
 * no description
 */
export class MemberPaymentsApi extends runtime.BaseAPI {

    /**
     * Create charge for member
     * Create charge for member
     */
    createMemberChargeRaw = async (requestParameters: CreateMemberChargeRequest): Promise<{ runtime: runtime.ApiResponse<ChargeItem>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createMemberCharge.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/charge`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MemberChargeSchemaToJSON(requestParameters.memberChargeSchema),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ChargeItemFromJSON(jsonValue)), response };
    }

    /**
     * Create charge for member
     * Create charge for member
     */
    createMemberCharge = async (requestParameters: CreateMemberChargeRequest): Promise<ChargeItem> => {
        const { runtime } = await this.createMemberChargeRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Create charge for member
     * Create charge for member
     */
    createMemberChargeWithResponse = async (requestParameters: CreateMemberChargeRequest): Promise<{ value: ChargeItem, response: any}> => {
        const { runtime, response } = await this.createMemberChargeRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Deletes a Member Payment Method
     * Deletes a Member Payment Method
     */
    deletePaymentMethodRaw = async (requestParameters: DeletePaymentMethodRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deletePaymentMethod.');
        }

                        if (requestParameters.paymentMethodId === null || requestParameters.paymentMethodId === undefined) {
            throw new runtime.RequiredError('paymentMethodId','Required parameter requestParameters.paymentMethodId was null or undefined when calling deletePaymentMethod.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/payment_methods/{payment_method_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"payment_method_id"}}`, encodeURIComponent(String(requestParameters.paymentMethodId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeletePaymentMethodSchemaToJSON(requestParameters.deletePaymentMethodSchema),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Deletes a Member Payment Method
     * Deletes a Member Payment Method
     */
    deletePaymentMethod = async (requestParameters: DeletePaymentMethodRequest): Promise<void> => {
        const { runtime } = await this.deletePaymentMethodRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Deletes a Member Payment Method
     * Deletes a Member Payment Method
     */
    deletePaymentMethodWithResponse = async (requestParameters: DeletePaymentMethodRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.deletePaymentMethodRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Fetch coupon by id/code
     * Fetch coupon by id/code
     */
    getCouponByIdRaw = async (requestParameters: GetCouponByIdRequest): Promise<{ runtime: runtime.ApiResponse<FetchStripeCoupon>, response: any}> => {
        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling getCouponById.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/coupon/{code}`.replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => FetchStripeCouponFromJSON(jsonValue)), response };
    }

    /**
     * Fetch coupon by id/code
     * Fetch coupon by id/code
     */
    getCouponById = async (requestParameters: GetCouponByIdRequest): Promise<FetchStripeCoupon> => {
        const { runtime } = await this.getCouponByIdRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch coupon by id/code
     * Fetch coupon by id/code
     */
    getCouponByIdWithResponse = async (requestParameters: GetCouponByIdRequest): Promise<{ value: FetchStripeCoupon, response: any}> => {
        const { runtime, response } = await this.getCouponByIdRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch member subscription
     * Fetch member subscription
     */
    getMemberSubscriptionRaw = async (requestParameters: GetMemberSubscriptionRequest): Promise<{ runtime: runtime.ApiResponse<ListMemberSubscription>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMemberSubscription.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/subscription`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ListMemberSubscriptionFromJSON(jsonValue)), response };
    }

    /**
     * Fetch member subscription
     * Fetch member subscription
     */
    getMemberSubscription = async (requestParameters: GetMemberSubscriptionRequest): Promise<ListMemberSubscription> => {
        const { runtime } = await this.getMemberSubscriptionRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch member subscription
     * Fetch member subscription
     */
    getMemberSubscriptionWithResponse = async (requestParameters: GetMemberSubscriptionRequest): Promise<{ value: ListMemberSubscription, response: any}> => {
        const { runtime, response } = await this.getMemberSubscriptionRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch Member Payment History
     * Fetch Member Payment History
     */
    getPaymentHistoryRaw = async (requestParameters: GetPaymentHistoryRequest): Promise<{ runtime: runtime.ApiResponse<ListMemberPaymentHistory>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPaymentHistory.');
        }

                                                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.orderByDate !== undefined) {
            queryParameters['order_by_date'] = requestParameters.orderByDate;
        }

        if (requestParameters.groupByStatus !== undefined) {
            queryParameters['group_by_status'] = requestParameters.groupByStatus;
        }

        if (requestParameters.fetchSubscriptions !== undefined) {
            queryParameters['fetch_subscriptions'] = requestParameters.fetchSubscriptions;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/payment_history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ListMemberPaymentHistoryFromJSON(jsonValue)), response };
    }

    /**
     * Fetch Member Payment History
     * Fetch Member Payment History
     */
    getPaymentHistory = async (requestParameters: GetPaymentHistoryRequest): Promise<ListMemberPaymentHistory> => {
        const { runtime } = await this.getPaymentHistoryRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch Member Payment History
     * Fetch Member Payment History
     */
    getPaymentHistoryWithResponse = async (requestParameters: GetPaymentHistoryRequest): Promise<{ value: ListMemberPaymentHistory, response: any}> => {
        const { runtime, response } = await this.getPaymentHistoryRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch Member Payment Methods
     * Fetch Member Payment Method details
     */
    getPaymentMethodRaw = async (requestParameters: GetPaymentMethodRequest): Promise<{ runtime: runtime.ApiResponse<MemberPaymentMethods>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPaymentMethod.');
        }

                        if (requestParameters.paymentMethodId === null || requestParameters.paymentMethodId === undefined) {
            throw new runtime.RequiredError('paymentMethodId','Required parameter requestParameters.paymentMethodId was null or undefined when calling getPaymentMethod.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/payment_methods/{payment_method_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"payment_method_id"}}`, encodeURIComponent(String(requestParameters.paymentMethodId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberPaymentMethodsFromJSON(jsonValue)), response };
    }

    /**
     * Fetch Member Payment Methods
     * Fetch Member Payment Method details
     */
    getPaymentMethod = async (requestParameters: GetPaymentMethodRequest): Promise<MemberPaymentMethods> => {
        const { runtime } = await this.getPaymentMethodRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch Member Payment Methods
     * Fetch Member Payment Method details
     */
    getPaymentMethodWithResponse = async (requestParameters: GetPaymentMethodRequest): Promise<{ value: MemberPaymentMethods, response: any}> => {
        const { runtime, response } = await this.getPaymentMethodRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch a single payment method
     * Fetch a single payment method
     */
    getPaymentMethodsRaw = async (requestParameters: GetPaymentMethodsRequest): Promise<{ runtime: runtime.ApiResponse<ListMemberPaymentMethods>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPaymentMethods.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/payment_methods`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ListMemberPaymentMethodsFromJSON(jsonValue)), response };
    }

    /**
     * Fetch a single payment method
     * Fetch a single payment method
     */
    getPaymentMethods = async (requestParameters: GetPaymentMethodsRequest): Promise<ListMemberPaymentMethods> => {
        const { runtime } = await this.getPaymentMethodsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch a single payment method
     * Fetch a single payment method
     */
    getPaymentMethodsWithResponse = async (requestParameters: GetPaymentMethodsRequest): Promise<{ value: ListMemberPaymentMethods, response: any}> => {
        const { runtime, response } = await this.getPaymentMethodsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch price by id
     * Fetch price by id
     */
    getPriceByIdRaw = async (requestParameters: GetPriceByIdRequest): Promise<{ runtime: runtime.ApiResponse<FetchStripePrice>, response: any}> => {
        if (requestParameters.priceId === null || requestParameters.priceId === undefined) {
            throw new runtime.RequiredError('priceId','Required parameter requestParameters.priceId was null or undefined when calling getPriceById.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.priceId !== undefined) {
            queryParameters['price_id'] = requestParameters.priceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/price/lookup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => FetchStripePriceFromJSON(jsonValue)), response };
    }

    /**
     * Fetch price by id
     * Fetch price by id
     */
    getPriceById = async (requestParameters: GetPriceByIdRequest): Promise<FetchStripePrice> => {
        const { runtime } = await this.getPriceByIdRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch price by id
     * Fetch price by id
     */
    getPriceByIdWithResponse = async (requestParameters: GetPriceByIdRequest): Promise<{ value: FetchStripePrice, response: any}> => {
        const { runtime, response } = await this.getPriceByIdRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch Receipt
     * Fetch member charge Receipt
     */
    getReceiptRaw = async (requestParameters: GetReceiptRequest): Promise<{ runtime: runtime.ApiResponse<MemberReceipt>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getReceipt.');
        }

                        if (requestParameters.receiptNumber === null || requestParameters.receiptNumber === undefined) {
            throw new runtime.RequiredError('receiptNumber','Required parameter requestParameters.receiptNumber was null or undefined when calling getReceipt.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/charges/{receipt_number}/receipt`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"receipt_number"}}`, encodeURIComponent(String(requestParameters.receiptNumber))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberReceiptFromJSON(jsonValue)), response };
    }

    /**
     * Fetch Receipt
     * Fetch member charge Receipt
     */
    getReceipt = async (requestParameters: GetReceiptRequest): Promise<MemberReceipt> => {
        const { runtime } = await this.getReceiptRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch Receipt
     * Fetch member charge Receipt
     */
    getReceiptWithResponse = async (requestParameters: GetReceiptRequest): Promise<{ value: MemberReceipt, response: any}> => {
        const { runtime, response } = await this.getReceiptRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Post multiple members Charge Items
     * Post multiple members Charge Items
     */
    postPayNowRaw = async (requestParameters: PostPayNowRequest): Promise<{ runtime: runtime.ApiResponse<ListMemberPaymentMethods1>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postPayNow.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/pay_now`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PayNowSchemaToJSON(requestParameters.payNowSchema),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ListMemberPaymentMethods1FromJSON(jsonValue)), response };
    }

    /**
     * Post multiple members Charge Items
     * Post multiple members Charge Items
     */
    postPayNow = async (requestParameters: PostPayNowRequest): Promise<ListMemberPaymentMethods1> => {
        const { runtime } = await this.postPayNowRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Post multiple members Charge Items
     * Post multiple members Charge Items
     */
    postPayNowWithResponse = async (requestParameters: PostPayNowRequest): Promise<{ value: ListMemberPaymentMethods1, response: any}> => {
        const { runtime, response } = await this.postPayNowRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Post Member Payment Method
     * Post Member Payment Method
     */
    postPaymentMethodRaw = async (requestParameters: PostPaymentMethodRequest): Promise<{ runtime: runtime.ApiResponse<ListMemberPaymentMethods>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postPaymentMethod.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/payment_methods`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostPaymentMethodSchemaToJSON(requestParameters.postPaymentMethodSchema),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ListMemberPaymentMethodsFromJSON(jsonValue)), response };
    }

    /**
     * Post Member Payment Method
     * Post Member Payment Method
     */
    postPaymentMethod = async (requestParameters: PostPaymentMethodRequest): Promise<ListMemberPaymentMethods> => {
        const { runtime } = await this.postPaymentMethodRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Post Member Payment Method
     * Post Member Payment Method
     */
    postPaymentMethodWithResponse = async (requestParameters: PostPaymentMethodRequest): Promise<{ value: ListMemberPaymentMethods, response: any}> => {
        const { runtime, response } = await this.postPaymentMethodRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update Primary Member Payment Method
     * Update Primary Member Payment Method
     */
    setPaymentMethodRaw = async (requestParameters: SetPaymentMethodRequest): Promise<{ runtime: runtime.ApiResponse<ListMemberPaymentMethods>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling setPaymentMethod.');
        }

                        if (requestParameters.paymentMethodId === null || requestParameters.paymentMethodId === undefined) {
            throw new runtime.RequiredError('paymentMethodId','Required parameter requestParameters.paymentMethodId was null or undefined when calling setPaymentMethod.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/payment_methods/{payment_method_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"payment_method_id"}}`, encodeURIComponent(String(requestParameters.paymentMethodId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SetPaymentMethodSchemaToJSON(requestParameters.setPaymentMethodSchema),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ListMemberPaymentMethodsFromJSON(jsonValue)), response };
    }

    /**
     * Update Primary Member Payment Method
     * Update Primary Member Payment Method
     */
    setPaymentMethod = async (requestParameters: SetPaymentMethodRequest): Promise<ListMemberPaymentMethods> => {
        const { runtime } = await this.setPaymentMethodRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update Primary Member Payment Method
     * Update Primary Member Payment Method
     */
    setPaymentMethodWithResponse = async (requestParameters: SetPaymentMethodRequest): Promise<{ value: ListMemberPaymentMethods, response: any}> => {
        const { runtime, response } = await this.setPaymentMethodRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update Member Payment Method
     * Update Member Payment Method
     */
    updatePaymentMethodRaw = async (requestParameters: UpdatePaymentMethodRequest): Promise<{ runtime: runtime.ApiResponse<MemberPaymentMethods>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePaymentMethod.');
        }

                        if (requestParameters.paymentMethodId === null || requestParameters.paymentMethodId === undefined) {
            throw new runtime.RequiredError('paymentMethodId','Required parameter requestParameters.paymentMethodId was null or undefined when calling updatePaymentMethod.');
        }

                        if (requestParameters.updatePaymentMethodSchema === null || requestParameters.updatePaymentMethodSchema === undefined) {
            throw new runtime.RequiredError('updatePaymentMethodSchema','Required parameter requestParameters.updatePaymentMethodSchema was null or undefined when calling updatePaymentMethod.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/payment_methods/{payment_method_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"payment_method_id"}}`, encodeURIComponent(String(requestParameters.paymentMethodId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePaymentMethodSchemaToJSON(requestParameters.updatePaymentMethodSchema),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberPaymentMethodsFromJSON(jsonValue)), response };
    }

    /**
     * Update Member Payment Method
     * Update Member Payment Method
     */
    updatePaymentMethod = async (requestParameters: UpdatePaymentMethodRequest): Promise<MemberPaymentMethods> => {
        const { runtime } = await this.updatePaymentMethodRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update Member Payment Method
     * Update Member Payment Method
     */
    updatePaymentMethodWithResponse = async (requestParameters: UpdatePaymentMethodRequest): Promise<{ value: MemberPaymentMethods, response: any}> => {
        const { runtime, response } = await this.updatePaymentMethodRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetPaymentHistoryStatusEnum {
    All = 'all',
    Paid = 'paid',
    Estimated = 'estimated',
    Unpaid = 'unpaid'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPaymentHistoryOrderByDateEnum {
    Asc = 'asc',
    Desc = 'desc'
}

export const fetchMemberPaymentsApi: IMemberPaymentsApi  = new MemberPaymentsApi();
