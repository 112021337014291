import { combineReducers } from "redux";
import { uniq, isEmpty } from "lodash";
import { questionSets } from "./QuestionSetState";
import { calculators } from "./CalculatorsState";
import { visits } from "./VisitsState";
import { WorkspaceDraftDataSchemaCTMMessage } from "shared/fetch/src/models/WorkspaceDraftDataSchemaCTMMessage";
import { WorkspaceDraftDataSchemaQuestionSet } from "shared/fetch/src/models/WorkspaceDraftDataSchemaQuestionSet";
import { WorkspaceDraftDataSchemaCalculator } from "shared/fetch/src/models/WorkspaceDraftDataSchemaCalculator";
import { WorkspaceDraftDataSchemaVisitReferral } from "shared/fetch/src/models/WorkspaceDraftDataSchemaVisitReferral";

export const WS_SET_CTM_MESSAGE = "WS_SET_CTM_MESSAGE";
export const WS_SET_RESPONSE_REQUIRED = "WS_SET_RESPONSE_REQUIRED";
export const WS_SET_CTM_DRAFT = "WS_SET_CTM_DRAFT";
export const WS_REMOVE_CTM_DRAFT = "WS_REMOVE_CTM_DRAFT";
export const WS_ADD_DOCUMENT = "WS_ADD_DOCUMENT";
export const WS_REMOVE_DOCUMENT = "WS_REMOVE_DOCUMENT";
export const WS_UPDATE_CC_PROVIDER = "WS_UPDATE_CC_PROVIDER";

// Acknowledge page
export const WS_SET_ACKNOWLEDGE_ITEMS = "WS_SET_ACKNOWLEDGE_ITEMS";
export const WS_ADD_ACKNOWLEDGE_ITEM = "WS_ADD_ACKNOWLEDGE_ITEM";
export const WS_REMOVE_ACKNOWLEDGE_ITEM = "WS_REMOVE_ACKNOWLEDGE_ITEM";
export const WS_REMOVE_ACKNOWLEDGE_ITEMS = "WS_REMOVE_ACKNOWLEDGE_ITEMS";
export const WS_CLEAR_ACKNOWLEDGE_ITEMS = "WS_CLEAR_ACKNOWLEDGE_ITEMS";

export const initialWorkspaceState = () => ({
  ctmMessage: {},
  questionSets: {},
  calculators: {},
  visits: {},
});

const updateState =
  ({ state, episodeId, type }: any) =>
  (update: any) => ({
    ...state,
    [episodeId]: {
      type,
      ...state[episodeId],
      ...update,
    },
  });

export const ctmMessage = (state = {} as State, action: any) => {
  const episodeId = action.episodeId;
  const updater = updateState({ state, episodeId, type: "ctm_message" });
  const existingDocumentIds = state[episodeId]?.documentIds || [];

  if (!episodeId) {
    return state;
  }

  switch (action.type) {
    case WS_SET_CTM_MESSAGE:
      return updater({ body: action.payload, status: "compiled" });

    case WS_SET_RESPONSE_REQUIRED:
      return updater({ responseRequired: action.payload });

    case WS_SET_CTM_DRAFT:
      if (!isEmpty(state[episodeId])) {
        return state;
      }
      return updater(action.payload);

    case WS_REMOVE_CTM_DRAFT:
      return updater({
        body: "",
        ccCtmIds: [],
        documentIds: [],
        status: null,
        responseRequired: false,
      });

    case WS_SET_ACKNOWLEDGE_ITEMS:
      return updater({ acknowledgeAndRespond: action.payload });

    case WS_CLEAR_ACKNOWLEDGE_ITEMS:
      return updater({ acknowledgeAndRespond: [] });

    case WS_ADD_ACKNOWLEDGE_ITEM:
      return updater({
        acknowledgeAndRespond: uniq([
          ...state[episodeId].acknowledgeAndRespond,
          action.payload,
        ]),
      });

    case WS_REMOVE_ACKNOWLEDGE_ITEM:
      return updater({
        acknowledgeAndRespond: state[episodeId].acknowledgeAndRespond?.filter(
          (id: string) => id !== action.payload
        ),
      });

    case WS_REMOVE_ACKNOWLEDGE_ITEMS:
      return updater({
        acknowledgeAndRespond: state[episodeId].acknowledgeAndRespond?.filter(
          (id: string) => !action.payload.includes(id)
        ),
      });

    case WS_UPDATE_CC_PROVIDER:
      return updater({ ccCtmIds: action.payload });

    case WS_ADD_DOCUMENT:
      const newDocumentIdsArray = [
        ...new Set([...existingDocumentIds, action.payload]),
      ];
      return updater({ documentIds: newDocumentIdsArray });

    case WS_REMOVE_DOCUMENT:
      const updatedDocumentIds = existingDocumentIds.filter(
        (id: number) => id !== action.payload
      );
      return updater({ documentIds: updatedDocumentIds });

    default:
      return state;
  }
};

export const setCtmMessage = (body: string, episodeId: string) => ({
  type: WS_SET_CTM_MESSAGE,
  payload: body,
  episodeId,
});

export const setCtmResponseRequired = (
  required: boolean,
  episodeId: string
) => ({
  type: WS_SET_RESPONSE_REQUIRED,
  payload: required,
  episodeId,
});

export const setCtmAcknowledgeItems = (
  acknowledgeAndRespondItems: string[],
  episodeId: string
) => ({
  type: WS_SET_ACKNOWLEDGE_ITEMS,
  payload: acknowledgeAndRespondItems,
  episodeId,
});

export const clearAcknowledgeItems = (episodeId: string) => ({
  type: WS_CLEAR_ACKNOWLEDGE_ITEMS,
  payload: [],
  episodeId,
});

export const AddAcknowledgeItem = (
  acknowledgeItem: string,
  episodeId: string
) => ({
  type: WS_ADD_ACKNOWLEDGE_ITEM,
  payload: acknowledgeItem,
  episodeId,
});

export const removeAcknowledgeItem = (
  acknowledgeItem: string,
  episodeId: string
) => ({
  type: WS_REMOVE_ACKNOWLEDGE_ITEM,
  payload: acknowledgeItem,
  episodeId,
});
export const removeAcknowledgeItems = (
  acknowledgeItems: string[],
  episodeId: string
) => ({
  type: WS_REMOVE_ACKNOWLEDGE_ITEMS,
  payload: acknowledgeItems,
  episodeId,
});
export const addDocumentId = (episodeId: string, documentId: number) => ({
  type: WS_ADD_DOCUMENT,
  episodeId,
  payload: documentId,
});

export const removeDocumentId = (episodeId: string, documentId: number) => {
  return {
    type: WS_REMOVE_DOCUMENT,
    episodeId,
    payload: documentId,
  };
};

export const updateCtmCCProvider = (episodeId: string, ccCtmIds: String[]) => ({
  type: WS_UPDATE_CC_PROVIDER,
  episodeId,
  payload: ccCtmIds,
});

export const setCtmDraft = (
  payload: WorkspaceDraftDataSchemaCTMMessage | null,
  episodeId: string
) => ({
  type: WS_SET_CTM_DRAFT,
  payload,
  episodeId,
});

export const removeCtmDraft = (episodeId: string) => ({
  type: WS_REMOVE_CTM_DRAFT,
  episodeId,
});

export const reducer = combineReducers({
  ctmMessage,
  questionSets,
  calculators,
  visits,
});

export interface State {
  ctmMessage: {
    [episodeId: string]: WorkspaceDraftDataSchemaCTMMessage;
  };
  questionSets: {
    [episodeId: string]: WorkspaceDraftDataSchemaQuestionSet;
  };
  calculators: {
    [episodeId: string]: WorkspaceDraftDataSchemaCalculator[];
  };
  visits: {
    [episodeId: string]: WorkspaceDraftDataSchemaVisitReferral[];
  };
}
