import * as React from "react";
import { Theme, useMediaQuery, useTheme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Box from "components/Box";
import Heading from "components/DynamicHeadings";
import Grid from "components/Grid";
import GetCareMenuButtons from "../GetCareMenuButtons";
import User from "models/User";
import { getGreeting } from "./utils";
import AffinityCareMenuButtons from "../AffinityCareMenuButtons";
import { useSelector } from "react-redux";
import { getFeatures } from "shared/features/featureFlags/selectors";
import { isDependantAccess } from "shared/utils/isDependentAccess";
import clsx from "clsx";
import { JsonUser, JsonUserAffinityEnum } from "shared/fetch/src/models/JsonUser";

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    header: {
      color: theme.palette.common.white,
      overflow: "hidden",
      textOverflow: "ellipsis",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      hyphens: "auto",
    },
    dependentHeader: {
      color: theme?.palette?.appBackground?.darkBlue,
    },
    title: {
      color: theme.palette.common.white,
      maxWidth: "338px",
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down('lg')]: {
        paddingTop: theme.spacing(1),
      },
    },
    ineligibleTitle: {
      color: theme.palette.common.white,
      maxWidth: "475px",
      paddingBottom: theme.spacing(3),
    },
    welcomeContainer: {
      [theme.breakpoints.down('lg')]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
    },
    welcomeContainerAffinity: {
      [theme.breakpoints.only("sm")]: {
        flexDirection: "column",
        alignItems: "flex-start",
      },
      [theme.breakpoints.only("xs")]: {
        display: "unset",
      },
    },
    welcomeDiv: {
      [theme.breakpoints.only("lg")]: {
        width: "80%",
        margin: 0,
      },
      [theme.breakpoints.down('lg')]: {
        alignSelf: "flex-start",
      },
    },
    buttonContainer: {
      textAlign: "end",
      [theme.breakpoints.down('lg')]: {
        textAlign: "start",
      },
    },
    buttonContainerAffinity: {
      textAlign: "end",
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down('sm')]: {
        textAlign: "start",
      },
    },
  })
);

interface IDashboardWelcomeProps {
  activeEpisodesLength: number;
  hasCreatedEoc?: boolean;
  user: JsonUser;
}

export default function DashboardWelcome({ user }: IDashboardWelcomeProps) {
  const classes = useStyles({});

  const featureFlags = useSelector(getFeatures);
  const hasAffinity = featureFlags.hasAffinity();
  const theme = useTheme();
  const isUserStandaloneMinor = !isDependantAccess() && user?.minor;
  const isUserNestedMinor = isDependantAccess();
  const hasHeaderForMinors = isUserNestedMinor || isUserStandaloneMinor;

  const isMediumScreen = useMediaQuery(theme.breakpoints.only("md"));
  const isXSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // if there is an error and the user does not have an affinity, assign the user to a 'nearsite' affinity so they have access to all the care entry points
  if (!user.affinity) {
    user.affinity = JsonUserAffinityEnum.Nearsite;
  }

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className={
          hasAffinity
            ? classes.welcomeContainerAffinity
            : classes.welcomeContainer
        }
      >
        <Grid item md={hasAffinity ? 6 : 10}>
          <Box className={classes.welcomeDiv}>
            <Heading.H
              className={clsx(
                classes.header,
                hasHeaderForMinors && classes.dependentHeader
              )}
              appearance="h3"
              gutterBottom
              data-testid="welcome-title"
            >
              {hasHeaderForMinors ? (
                <>
                  {`${
                    (user as JsonUser)?.preferredName ||
                    (user as JsonUser)?.firstName
                  }'s Dashboard`}
                </>
              ) : (
                <>
                  {`${getGreeting()}
                  ${
                    (user as JsonUser)?.preferredName ||
                    (user as JsonUser)?.firstName
                  }`}
                </>
              )}
            </Heading.H>
          </Box>
        </Grid>
        <Grid
          item
          md={hasAffinity ? 7 : undefined}
          style={
            isMediumScreen && user.affinity === JsonUserAffinityEnum.Nearsite
              ? { display: "initial" }
              : {}
          }
          className={
            hasAffinity
              ? classes.buttonContainerAffinity
              : classes.buttonContainer
          }
          xs={12}
        >
          {!User.isUserIneligible(user) &&
            (hasAffinity ? (
              !isXSmallScreen && (
                <AffinityCareMenuButtons affinity={user.affinity} />
              )
            ) : (
              <GetCareMenuButtons />
            ))}
        </Grid>
      </Box>
    </>
  );
}
