import React, { useEffect, FunctionComponent } from "react";
import NoEpisode from "../../styles/images/episode/no_episode.png";
import Heading from "components/DynamicHeadings";
import whatInput from "what-input";
import "./index.scss";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import clsx from "clsx";
import Paper from "components/Paper";
import Typography from "components/Typography";
import DropDown from "components/DropDown";
import Box from "components/Box";
import Grid from "components/Grid";
import CircularProgress from "components/Progress/CircularProgress";
import { useSelector } from "react-redux";
import { getUser } from "shared/features/user";
import Tooltip from "components/Tooltip";
import { GetMyQueueOrderEnum } from "shared/fetch/src/apis/EpisodeListsApi";

const TABS_TO_SHOW_TITLE = [2, 3, 4, 5];
const TABS_TO_SHOW_PODS = [0, 1, 6];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    eocItemHeading: {
      padding: "16px 15px",
      margin: 0,
      borderBottom: "1px solid rgba(43, 57, 73, 0.3)",
    },
    eocItemMessage: {
      marginTop: theme.spacing(4),
      fontWeight: "bold",
      color: theme.palette.grey[500],
      fontSize: "1.188em",
      width: "205px",
      textAlign: "center",
    },
    filter: {
      display: "flex",
      justifyContent: "space-between",
    },
    tooltip: {
      marginTop: 0,
    },
    filterButton: {
      display: "flex",
      alignItems: "center",
      height: "20px",
    },
    moreText: {
      textDecoration: "underline",
      color: theme.palette.text.secondary,
      "&:hover": {
        cursor: "pointer",
        color: theme.palette.text.secondary,
      },
      minWidth: 0,
      fontSize: "13.3px",
      fontWeight: 400,
      lineHeight: "0.901875rem",
    },

    marginLeft: {
      marginLeft: theme.spacing(0.25),
    },
    podsTooltip: {
      marginLeft: theme.spacing(3.5),
      marginTop: theme.spacing(-2.5),
    },
    sortButton: {
      "&.MuiButton-text": {
        color: theme?.palette?.text?.link,
      },
    },
    sortIcon: {
      fill: theme?.palette?.text?.link,
    },
  })
);

interface IProps {
  heading: string;
  message?: string;
  itemCount?: number;
  selectedTab?: number;
  tab?: number;
  getSelectedFilter?: Function;
  isLoading?: boolean;
  sortOrder?: string;
  onSortOrderChange?: Function;
}

const sortOptions = [
  { name: "Oldest", value: 1, onChangeValue: GetMyQueueOrderEnum.ASC },
  { name: "Newest", value: 2, onChangeValue: GetMyQueueOrderEnum.DESC },
];

const getInitialSortOrder = (sortOrder: string) => {
  const initialSortOrder = sortOptions.findIndex(
    (option) => option.name === sortOrder
  );
  return initialSortOrder !== -1 ? initialSortOrder : 0;
};

const EOCItemContainer: FunctionComponent<IProps> = ({
  children,
  heading,
  message,
  itemCount,
  selectedTab,
  isLoading,
  sortOrder,
  onSortOrderChange,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [sortOption, setSortOption] = React.useState(
    // @ts-ignore: Object is possibly 'null'.
    getInitialSortOrder(sortOrder)
  );
  const isSortingAvailable = typeof onSortOrderChange !== "undefined";

  useEffect(() => {
    const node = containerRef.current;
    if (node) {
      node.focus();
    }
  }, []);
  const user = useSelector(getUser);
  const classes = useStyles();
  return (
    <Paper
      elevation={4}
      style={{
        minHeight: itemCount ? "" : "471px",
        borderRadius: "10px",
      }}
      ref={containerRef}
      data-whatintent={whatInput.ask("intent")}
      data-e2e="eoc-item-container"
    >
      <div className={clsx(classes.filter, classes.eocItemHeading)}>
        <div>
          <Heading.H appearance="h5" data-testid="eoc-item-header">
            {heading}{" "}
            {selectedTab && selectedTab < 7 && itemCount ? (
              <Typography
                appearance="h5"
                component="span"
                color="textSecondary"
              >
                ({itemCount})
              </Typography>
            ) : (
              ""
            )}
          </Heading.H>
          {/* @ts-ignore: Object is possibly 'null'. */}
          {TABS_TO_SHOW_TITLE.includes(selectedTab) && (
            <Typography
              appearance="smallBody"
              component="span"
              color="textSecondary"
            >
              All your pods
            </Typography>
          )}
          {/* @ts-ignore: Object is possibly 'null'. */}
          {TABS_TO_SHOW_PODS.includes(selectedTab) && (
            <div style={{ display: "flex" }}>
              {user?.podFilter?.slice(0, 4)?.map((pod, index, arr) => {
                return (
                  <Typography
                    key={pod.id}
                    appearance="smallBody"
                    component="span"
                    color="textSecondary"
                    className={classes.marginLeft}
                  >
                    {pod.description + (index + 1 !== arr.length ? "," : "")}
                    {user?.podFilter &&
                      user?.podFilter?.length > 4 &&
                      index + 1 === arr.length &&
                      ","}
                  </Typography>
                );
              })}

              {user?.podFilter && user?.podFilter?.length > 4 && (
                <Tooltip
                  tabIndex={0}
                  classes={{ tooltip: classes.tooltip }}
                  title={user?.podFilter
                    ?.slice(4, user.podFilter.length)
                    ?.map((pod) => (
                      <span key={pod.id}>
                        {pod.description}
                        <br />
                      </span>
                    ))}
                >
                  <div className={clsx(classes.moreText, classes.marginLeft)}>
                    more
                  </div>
                </Tooltip>
              )}
            </div>
          )}
        </div>

        {isLoading && <CircularProgress size={24} />}

        {isSortingAvailable && (
          <div className={clsx(classes.filterButton)}>
            <Typography
              appearance="body"
              component="span"
              color="textSecondary"
            >
              Show:
            </Typography>
            <DropDown
              options={sortOptions}
              // @ts-ignore: Object is possibly 'null'.
              onChange={onSortOrderChange}
              setSelectedIndex={setSortOption}
              selectedIndex={sortOption}
              customStyles={{
                button: classes.sortButton,
                icon: classes.sortIcon,
              }}
            />
          </div>
        )}
      </div>

      <Heading.LevelBoundary>
        {itemCount ? (
          <Grid container>{children}</Grid>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            flex={1}
          >
            <img alt="No Conversation" src={NoEpisode} />
            <Heading.LevelBoundary>
              <Heading.H className={classes.eocItemMessage}>
                {message}
              </Heading.H>
            </Heading.LevelBoundary>
          </Box>
        )}
      </Heading.LevelBoundary>
    </Paper>
  );
};

export default EOCItemContainer;
