class NativeApp {
  private static get OPEN_EXTERNAL_URL() {
    return "OPEN_EXTERNAL_URL";
  }

  private static get GO_TO_DEVICE_SETTINGS() {
    return "GO_TO_DEVICE_SETTINGS";
  }

  private static get LOG() {
    return "LOG";
  }

  private static get BIOMETRIC_REGISTRATION() {
    return "BIOMETRIC_REGISTRATION";
  }

  private static get PULL_TO_REFRESH() {
    return "PULL_TO_REFRESH";
  }

  private static get PUSH_TOKEN_FETCH() {
    return "PUSH_TOKEN_FETCH";
  }

  private static get ENROLL_BIOMETRICS_FROM_SETTINGS() {
    return "ENROLL_BIOMETRICS_FROM_SETTINGS";
  }

  private static get UNENROLL_BIOMETRICS_FROM_SETTINGS() {
    return "UNENROLL_BIOMETRICS_FROM_SETTINGS";
  }

  private static get ENROLL_PUSH_FROM_SETTINGS() {
    return "ENROLL_PUSH_FROM_SETTINGS";
  }

  private static get UNENROLL_PUSH_FROM_SETTINGS() {
    return "UNENROLL_PUSH_FROM_SETTINGS";
  }

  private static get PUSH_REGISTRATION() {
    return "PUSH_REGISTRATION";
  }

  private static get VIEW_FILE() {
    return "VIEW_FILE";
  }

  private static get DOWNLOAD_PDF() {
    return "DOWNLOAD_PDF";
  }

  private static get DOWNLOAD_FILE() {
    return "DOWNLOAD_FILE";
  }

  private static get UPDATE_NATIVE_APP_STATE() {
    return "UPDATE_NATIVE_APP_STATE";
  }

  private static get CHECK_FOR_STORED_ACTIONS() {
    return "CHECK_FOR_STORED_ACTIONS";
  }

  private static get UPDATE_BADGE_COUNT() {
    return "UPDATE_BADGE_COUNT";
  }

  private static get BIOMETRIC_LOGOUT() {
    return "BIOMETRIC_LOGOUT";
  }

  private static get HIDE_NATIVE_BUTTONS() {
    return "HIDE_NATIVE_BUTTONS";
  }

  private static get SHOW_NATIVE_BUTTONS() {
    return "SHOW_NATIVE_BUTTONS";
  }

  public static postMessage = (...args: any) => {
    window?.ReactNativeWebView?.postMessage(...args);
  };

  private static sendNativeAction = (
    type: string,
    payload: Record<string, any> | string
  ) => {
    NativeApp.postMessage(
      JSON.stringify({
        type,
        payload,
      })
    );
  };

  public static openExternalUrl = (url: string) => {
    NativeApp.sendNativeAction(NativeApp.OPEN_EXTERNAL_URL, { url });
  };

  public static goToDeviceSettings = () => {
    NativeApp.sendNativeAction(NativeApp.GO_TO_DEVICE_SETTINGS, {});
  };

  public static log = (logValue: any) => {
    NativeApp.sendNativeAction(NativeApp.LOG, { logValue });
  };

  public static biometricRegistration = () => {
    NativeApp.sendNativeAction(NativeApp.BIOMETRIC_REGISTRATION, {});
  };

  public static enrollBiometricsFromSettings = (
    deviceId: string | null | undefined
  ) => {
    NativeApp.postMessage(
      JSON.stringify({
        type: NativeApp.ENROLL_BIOMETRICS_FROM_SETTINGS,
        deviceId,
      })
    );
  };

  public static unenrollBiometricsFromSettings = (
    deviceId: string | null | undefined
  ) => {
    NativeApp.postMessage(
      JSON.stringify({
        type: NativeApp.UNENROLL_BIOMETRICS_FROM_SETTINGS,
        deviceId,
      })
    );
  };

  public static pullToRefresh = () => {
    NativeApp.sendNativeAction(NativeApp.PULL_TO_REFRESH, {});
  };

  public static updateNativeAppState = () => {
    NativeApp.sendNativeAction(NativeApp.UPDATE_NATIVE_APP_STATE, {});
  };

  public static checkForStoredActions = (actionType: string) => {
    NativeApp.sendNativeAction(NativeApp.CHECK_FOR_STORED_ACTIONS, actionType);
  };

  public static updateBadgeCount = (count: number) => {
    NativeApp.sendNativeAction(NativeApp.UPDATE_BADGE_COUNT, count.toString());
  };

  public static pushRegistration = () => {
    NativeApp.sendNativeAction(NativeApp.PUSH_REGISTRATION, {});
  };

  public static pushTokenFetch = () => {
    NativeApp.sendNativeAction(NativeApp.PUSH_TOKEN_FETCH, {});
  };

  public static enrollPushFromSettings = (
    deviceId: string | null | undefined
  ) => {
    NativeApp.postMessage(
      JSON.stringify({
        type: NativeApp.ENROLL_PUSH_FROM_SETTINGS,
        deviceId,
      })
    );
  };

  public static unenrollPushFromSettings = (
    deviceId: string | null | undefined
  ) => {
    NativeApp.postMessage(
      JSON.stringify({
        type: NativeApp.UNENROLL_PUSH_FROM_SETTINGS,
        deviceId,
      })
    );
  };

  public static viewFile = (
    fileName: string | undefined,
    fileUrl: string,
    fileType?: string
  ) => {
    NativeApp.sendNativeAction(NativeApp.VIEW_FILE, {
      fileName,
      fileUrl,
      fileType,
    });
  };

  public static downloadPdf = (
    fileName: string | undefined,
    fileUrl: string,
    fileType?: string
  ) => {
    NativeApp.sendNativeAction(NativeApp.DOWNLOAD_PDF, {
      fileName,
      fileUrl,
      fileType,
    });
  };

  public static downloadFile = (fileUrl: string) => {
    NativeApp.sendNativeAction(NativeApp.DOWNLOAD_FILE, fileUrl);
  };

  public static biometricLogout = () => {
    NativeApp.sendNativeAction(NativeApp.BIOMETRIC_LOGOUT, {});
  };

  public static hideNativeButtons = () => {
    NativeApp.sendNativeAction(NativeApp.HIDE_NATIVE_BUTTONS, {});
  };

  public static showNativeButtons = () => {
    NativeApp.sendNativeAction(NativeApp.SHOW_NATIVE_BUTTONS, {});
  };
}

export default NativeApp;
