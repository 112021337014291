/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    MemberVisitSummariesArrayResponse,
    MemberVisitSummariesArrayResponseFromJSON,
    MemberVisitSummariesArrayResponseToJSON,
} from '../models/MemberVisitSummariesArrayResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface GetVisitSummariesRequest {
    id: string;
    page?: string;
}


export interface IMemberVisitSummariesApi {
      getVisitSummaries: (requestParameters: GetVisitSummariesRequest) => Promise<MemberVisitSummariesArrayResponse>
      getVisitSummariesWithResponse: (requestParameters: GetVisitSummariesRequest) => Promise<{ value: MemberVisitSummariesArrayResponse, response: any}>
}

/**
 * no description
 */
export class MemberVisitSummariesApi extends runtime.BaseAPI {

    /**
     * View paginated list of a member\'s visit summaries
     * View paginated list of a member\'s visit summaries
     */
    getVisitSummariesRaw = async (requestParameters: GetVisitSummariesRequest): Promise<{ runtime: runtime.ApiResponse<MemberVisitSummariesArrayResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getVisitSummaries.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/visit_summaries`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberVisitSummariesArrayResponseFromJSON(jsonValue)), response };
    }

    /**
     * View paginated list of a member\'s visit summaries
     * View paginated list of a member\'s visit summaries
     */
    getVisitSummaries = async (requestParameters: GetVisitSummariesRequest): Promise<MemberVisitSummariesArrayResponse> => {
        const { runtime } = await this.getVisitSummariesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * View paginated list of a member\'s visit summaries
     * View paginated list of a member\'s visit summaries
     */
    getVisitSummariesWithResponse = async (requestParameters: GetVisitSummariesRequest): Promise<{ value: MemberVisitSummariesArrayResponse, response: any}> => {
        const { runtime, response } = await this.getVisitSummariesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchMemberVisitSummariesApi: IMemberVisitSummariesApi  = new MemberVisitSummariesApi();
