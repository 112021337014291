// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberSubscriptionItemsData,
    MemberSubscriptionItemsDataFromJSON,
    MemberSubscriptionItemsDataToJSON,
} from './MemberSubscriptionItemsData';


/**
 * 
 * @export
 * @interface MemberSubscriptionItems
 */
export interface MemberSubscriptionItems  {
    /**
     * 
     * @type {Array<MemberSubscriptionItemsData>}
     * @memberof MemberSubscriptionItems
     */
    data?: Array<MemberSubscriptionItemsData>;
    /**
     * 
     * @type {boolean}
     * @memberof MemberSubscriptionItems
     */
    hasMore?: boolean;
}

export function MemberSubscriptionItemsFromJSON(json: any): MemberSubscriptionItems {
    return {
        'data': !exists(json, 'data') ? undefined : (json['data'] as Array<any>).map(MemberSubscriptionItemsDataFromJSON),
        'hasMore': !exists(json, 'has_more') ? undefined : json['has_more'],
    };
}

export function MemberSubscriptionItemsToJSON(value?: MemberSubscriptionItems): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'data': value.data === undefined ? undefined : (value.data as Array<any>).map(MemberSubscriptionItemsDataToJSON),
        'has_more': value.hasMore,
    };
}


