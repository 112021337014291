// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MinorAccountActivationRequestBody
 */
export interface MinorAccountActivationRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof MinorAccountActivationRequestBody
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof MinorAccountActivationRequestBody
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof MinorAccountActivationRequestBody
     */
    dob: string;
    /**
     * 
     * @type {string}
     * @memberof MinorAccountActivationRequestBody
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof MinorAccountActivationRequestBody
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof MinorAccountActivationRequestBody
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof MinorAccountActivationRequestBody
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof MinorAccountActivationRequestBody
     */
    sexAtBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof MinorAccountActivationRequestBody
     */
    employeeId: string;
    /**
     * 
     * @type {string}
     * @memberof MinorAccountActivationRequestBody
     */
    preferredLanguage?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MinorAccountActivationRequestBody
     */
    consents: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MinorAccountActivationRequestBody
     */
    subscriberId?: string;
    /**
     * 
     * @type {string}
     * @memberof MinorAccountActivationRequestBody
     */
    efId: string;
    /**
     * 
     * @type {string}
     * @memberof MinorAccountActivationRequestBody
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof MinorAccountActivationRequestBody
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof MinorAccountActivationRequestBody
     */
    extraRegistrationId?: string;
    /**
     * 
     * @type {string}
     * @memberof MinorAccountActivationRequestBody
     */
    workEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof MinorAccountActivationRequestBody
     */
    type: string;
}

export function MinorAccountActivationRequestBodyFromJSON(json: any): MinorAccountActivationRequestBody {
    return {
        'firstName': json['first_name'],
        'lastName': json['last_name'],
        'dob': json['dob'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'sexAtBirth': !exists(json, 'sex_at_birth') ? undefined : json['sex_at_birth'],
        'employeeId': json['employee_id'],
        'preferredLanguage': !exists(json, 'preferred_language') ? undefined : json['preferred_language'],
        'consents': json['consents'],
        'subscriberId': !exists(json, 'subscriber_id') ? undefined : json['subscriber_id'],
        'efId': json['ef_id'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'extraRegistrationId': !exists(json, 'extra_registration_id') ? undefined : json['extra_registration_id'],
        'workEmail': !exists(json, 'work_email') ? undefined : json['work_email'],
        'type': json['type'],
    };
}

export function MinorAccountActivationRequestBodyToJSON(value?: MinorAccountActivationRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'first_name': value.firstName,
        'last_name': value.lastName,
        'dob': value.dob,
        'address': value.address,
        'city': value.city,
        'state': value.state,
        'zip': value.zip,
        'sex_at_birth': value.sexAtBirth,
        'employee_id': value.employeeId,
        'preferred_language': value.preferredLanguage,
        'consents': value.consents,
        'subscriber_id': value.subscriberId,
        'ef_id': value.efId,
        'email': value.email,
        'password': value.password,
        'extra_registration_id': value.extraRegistrationId,
        'work_email': value.workEmail,
        'type': value.type,
    };
}


