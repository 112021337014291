// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
    ComponentActionDetails,
    ComponentActionDetailsFromJSON,
    ComponentActionDetailsToJSON,
} from './ComponentActionDetails';
import {
    ComponentDetailsCoreCreatorInfo,
    ComponentDetailsCoreCreatorInfoFromJSON,
    ComponentDetailsCoreCreatorInfoToJSON,
} from './ComponentDetailsCoreCreatorInfo';
import {
    ComponentDetailsCoreResolverInfo,
    ComponentDetailsCoreResolverInfoFromJSON,
    ComponentDetailsCoreResolverInfoToJSON,
} from './ComponentDetailsCoreResolverInfo';
import {
    DocumentOutputSchema,
    DocumentOutputSchemaFromJSON,
    DocumentOutputSchemaToJSON,
} from './DocumentOutputSchema';
import {
    MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';


/**
 * 
 * @export
 * @interface SummaryComponentCore
 */
export interface SummaryComponentCore  {
    /**
     * 
     * @type {string}
     * @memberof SummaryComponentCore
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof SummaryComponentCore
     */
    timelineEventId: string;
    /**
     * 
     * @type {ComponentDetailsCoreCreatorInfo}
     * @memberof SummaryComponentCore
     */
    creatorInfo?: ComponentDetailsCoreCreatorInfo;
    /**
     * 
     * @type {string}
     * @memberof SummaryComponentCore
     */
    episodeId: string;
    /**
     * 
     * @type {string}
     * @memberof SummaryComponentCore
     */
    type: SummaryComponentCoreTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SummaryComponentCore
     */
    body?: string;
    /**
     * 
     * @type {Date}
     * @memberof SummaryComponentCore
     */
    addedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SummaryComponentCore
     */
    resourceUrl: string;
    /**
     * 
     * @type {Array<DocumentOutputSchema>}
     * @memberof SummaryComponentCore
     */
    documentInfo?: Array<DocumentOutputSchema>;
    /**
     * 
     * @type {Date}
     * @memberof SummaryComponentCore
     */
    paidAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SummaryComponentCore
     */
    responseAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof SummaryComponentCore
     */
    urgency?: SummaryComponentCoreUrgencyEnum;
    /**
     * 
     * @type {Array<ComponentActionDetails>}
     * @memberof SummaryComponentCore
     */
    actions?: Array<ComponentActionDetails>;
    /**
     * 
     * @type {MemberInfo}
     * @memberof SummaryComponentCore
     */
    memberInfo?: MemberInfo;
    /**
     * 
     * @type {CTMInfo}
     * @memberof SummaryComponentCore
     */
    acknowledger?: CTMInfo;
    /**
     * 
     * @type {ComponentDetailsCoreResolverInfo}
     * @memberof SummaryComponentCore
     */
    resolverInfo?: ComponentDetailsCoreResolverInfo;
}

export function SummaryComponentCoreFromJSON(json: any): SummaryComponentCore {
    return {
        'id': json['id'],
        'timelineEventId': json['timeline_event_id'],
        'creatorInfo': !exists(json, 'creator_info') ? undefined : ComponentDetailsCoreCreatorInfoFromJSON(json['creator_info']),
        'episodeId': json['episode_id'],
        'type': json['type'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'addedAt': !exists(json, 'added_at') ? undefined : new Date(json['added_at']),
        'resourceUrl': json['resource_url'],
        'documentInfo': !exists(json, 'document_info') ? undefined : (json['document_info'] as Array<any>).map(DocumentOutputSchemaFromJSON),
        'paidAt': !exists(json, 'paid_at') ? undefined : new Date(json['paid_at']),
        'responseAt': !exists(json, 'response_at') ? undefined : new Date(json['response_at']),
        'urgency': !exists(json, 'urgency') ? undefined : json['urgency'],
        'actions': !exists(json, 'actions') ? undefined : (json['actions'] as Array<any>).map(ComponentActionDetailsFromJSON),
        'memberInfo': !exists(json, 'member_info') ? undefined : MemberInfoFromJSON(json['member_info']),
        'acknowledger': !exists(json, 'acknowledger') ? undefined : CTMInfoFromJSON(json['acknowledger']),
        'resolverInfo': !exists(json, 'resolver_info') ? undefined : ComponentDetailsCoreResolverInfoFromJSON(json['resolver_info']),
    };
}

export function SummaryComponentCoreToJSON(value?: SummaryComponentCore): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'timeline_event_id': value.timelineEventId,
        'creator_info': ComponentDetailsCoreCreatorInfoToJSON(value.creatorInfo),
        'episode_id': value.episodeId,
        'type': value.type,
        'body': value.body,
        'added_at': value.addedAt === undefined ? undefined : value.addedAt.toISOString(),
        'resource_url': value.resourceUrl,
        'document_info': value.documentInfo === undefined ? undefined : (value.documentInfo as Array<any>).map(DocumentOutputSchemaToJSON),
        'paid_at': value.paidAt === undefined ? undefined : value.paidAt.toISOString(),
        'response_at': value.responseAt === undefined ? undefined : value.responseAt.toISOString(),
        'urgency': value.urgency,
        'actions': value.actions === undefined ? undefined : (value.actions as Array<any>).map(ComponentActionDetailsToJSON),
        'member_info': MemberInfoToJSON(value.memberInfo),
        'acknowledger': CTMInfoToJSON(value.acknowledger),
        'resolver_info': ComponentDetailsCoreResolverInfoToJSON(value.resolverInfo),
    };
}

/**
* @export
* @enum {string}
*/
export enum SummaryComponentCoreTypeEnum {
    EpisodeCreated = 'episode_created',
    MemberMessage = 'member_message',
    CtmMessage = 'ctm_message',
    Diagnosis = 'diagnosis',
    VirtualVisitReferral = 'virtual_visit_referral',
    CtmEpisodeCreated = 'ctm_episode_created',
    CtmMessagingEpisodeCreated = 'ctm_messaging_episode_created',
    QuestionSet = 'question_set',
    Calculator = 'calculator',
    CenterVisitReferral = 'center_visit_referral',
    VisitReferral = 'visit_referral',
    MarketingMessage = 'marketing_message',
    Billing = 'billing',
    SelfScheduleAppointment = 'self_schedule_appointment'
}
/**
* @export
* @enum {string}
*/
export enum SummaryComponentCoreUrgencyEnum {
    Asap = 'asap',
    NextFewDays = 'next_few_days',
    NextFewWeeks = 'next_few_weeks'
}


