// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberSubscriptionItemsPrice,
    MemberSubscriptionItemsPriceFromJSON,
    MemberSubscriptionItemsPriceToJSON,
} from './MemberSubscriptionItemsPrice';


/**
 * 
 * @export
 * @interface MemberSubscriptionItemsData
 */
export interface MemberSubscriptionItemsData  {
    /**
     * 
     * @type {string}
     * @memberof MemberSubscriptionItemsData
     */
    id?: string;
    /**
     * 
     * @type {MemberSubscriptionItemsPrice}
     * @memberof MemberSubscriptionItemsData
     */
    price?: MemberSubscriptionItemsPrice;
}

export function MemberSubscriptionItemsDataFromJSON(json: any): MemberSubscriptionItemsData {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'price': !exists(json, 'price') ? undefined : MemberSubscriptionItemsPriceFromJSON(json['price']),
    };
}

export function MemberSubscriptionItemsDataToJSON(value?: MemberSubscriptionItemsData): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'price': MemberSubscriptionItemsPriceToJSON(value.price),
    };
}


