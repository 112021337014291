import React, { FunctionComponent } from "react";
import Button from "components/Button";
import Dialog from "components/Dialog";
import DialogActions from "components/Dialog/DialogActions";
import DialogContent from "components/Dialog/DialogContent";
import DialogContentText from "components/Dialog/DialogContentText";
import DialogTitle from "components/Dialog/DialogTitle";
interface ModalProps {
  open: boolean;
  toggle: () => void;
}

const ConsentDeniedModal: FunctionComponent<ModalProps> = ({
  open,
  toggle,
}) => {
  return (
    <Dialog
      open={open}
      ariaLabelledBy="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      data-e2e="consent-denied-modal"
      onClose={toggle}
    >
      <DialogTitle id="alert-dialog-title">Crossover Consent</DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          To receive care from Crossover, you need to consent first.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={toggle}
          data-e2e="close-no-user-consent-modal"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          aria-labelledby="close-button"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConsentDeniedModal;
