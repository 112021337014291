// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompactComponentReferenceDeclineReason
 */
export interface CompactComponentReferenceDeclineReason  {
    /**
     * 
     * @type {string}
     * @memberof CompactComponentReferenceDeclineReason
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CompactComponentReferenceDeclineReason
     */
    componentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CompactComponentReferenceDeclineReason
     */
    reason?: string;
    /**
     * 
     * @type {Date}
     * @memberof CompactComponentReferenceDeclineReason
     */
    declinedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CompactComponentReferenceDeclineReason
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompactComponentReferenceDeclineReason
     */
    reasonOption?: CompactComponentReferenceDeclineReasonReasonOptionEnum;
}

export function CompactComponentReferenceDeclineReasonFromJSON(json: any): CompactComponentReferenceDeclineReason {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'componentId': !exists(json, 'component_id') ? undefined : json['component_id'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'declinedAt': !exists(json, 'declined_at') ? undefined : new Date(json['declined_at']),
        'updatedAt': !exists(json, 'updated_at') ? undefined : new Date(json['updated_at']),
        'reasonOption': !exists(json, 'reason_option') ? undefined : json['reason_option'],
    };
}

export function CompactComponentReferenceDeclineReasonToJSON(value?: CompactComponentReferenceDeclineReason): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'component_id': value.componentId,
        'reason': value.reason,
        'declined_at': value.declinedAt === undefined ? undefined : value.declinedAt.toISOString(),
        'updated_at': value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
        'reason_option': value.reasonOption,
    };
}

/**
* @export
* @enum {string}
*/
export enum CompactComponentReferenceDeclineReasonReasonOptionEnum {
    DontLikeDoctor = 'dont_like_doctor',
    DontHaveTime = 'dont_have_time',
    GoSomewhereElse = 'go_somewhere_else',
    FeelingBetter = 'feeling_better',
    TimesDontWork = 'times_dont_work'
}


