import React from "react";
import { useNavigate } from "react-router";
import { Theme, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import TodayIcon from "@mui/icons-material/Today";
import EmailIcon from "@mui/icons-material/Email";
import Access from "components/Access";

import Box from "components/Box";
import Hidden from "components/Hidden";
import Button from "components/Button";

import { eocRootPath } from "containers/Routing";
import { selectLoggedInUser } from "shared/features/user/selectors";
import {
  getFeatures,
  selectFeatures,
} from "shared/features/featureFlags/selectors";
import { useSelector } from "react-redux";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    "& .MuiButton-label": {
      // otherwise on mobile the button label wraps to two lines
      whiteSpace: "nowrap",
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: "45%",
      fontSize: "14px",
    },
  },
  buttonForNoSelfSchedule: {
    "& .MuiButton-label": {
      // otherwise on mobile the button label wraps to two lines
      whiteSpace: "nowrap",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "14px",
    },
  },
  chevron: {
    margin: "auto 0",
    marginLeft: "10px",
    height: "12px",
  },
  asset: {
    // seems odd, but necessary for properly sizing on Chrome AND Safari
    width: "80px",
    maxWidth: "80px",
    height: "80px",
    maxHeight: "80px",
  },
  buttonContainer: {
    [theme.breakpoints.down('lg')]: {
      alignSelf: "flex-start",
    },
  },
}));

export default function GetCareMenuButtons() {
  const classes = useStyles({});
  const user = selectLoggedInUser() || {};
  const navigate = useNavigate();
  const theme = useTheme();

  const isXSmallScreen = useMediaQuery(theme.breakpoints.only("xs"));
  const featureFlags = useSelector(getFeatures);
  const hasSelfSchedule = featureFlags.hasSelfSchedule();

  return (
    <Hidden only="xs">
      <Box className={classes.buttonContainer}>
        <Access to={PermissionsEnum.EpisodesCreate}>
          <Button
            className={
              hasSelfSchedule ? classes.button : classes.buttonForNoSelfSchedule
            }
            variant="contained"
            color="primary"
            startIcon={isXSmallScreen ? null : <EmailIcon />}
            data-e2e="start-conversation"
            data-testid="get-care-new-button"
            size="large"
            onClick={() => navigate(eocRootPath)}
          >
            Start a Conversation
          </Button>
        </Access>
        &nbsp;&nbsp;
        {selectFeatures().hasSelfSchedule() && (
          <Access to={PermissionsEnum.SelfScheduleCreate}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              startIcon={isXSmallScreen ? null : <TodayIcon />}
              data-e2e="schedule-button"
              data-testid="schedule-button"
              size="large"
              onClick={() => navigate(`/members/${user?.id}/self-schedule`)}
            >
              Schedule a visit
            </Button>
          </Access>
        )}
      </Box>
    </Hidden>
  );
}
