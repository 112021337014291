// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MarketingMessageOutput
 */
export interface MarketingMessageOutput  {
    /**
     * 
     * @type {string}
     * @memberof MarketingMessageOutput
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingMessageOutput
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingMessageOutput
     */
    efId?: number;
    /**
     * 
     * @type {string}
     * @memberof MarketingMessageOutput
     */
    image?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingMessageOutput
     */
    imageCaption?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingMessageOutput
     */
    content?: string;
    /**
     * 
     * @type {string}
     * @memberof MarketingMessageOutput
     */
    buttonCopy?: string;
    /**
     * 
     * @type {number}
     * @memberof MarketingMessageOutput
     */
    componentId?: number;
}

export function MarketingMessageOutputFromJSON(json: any): MarketingMessageOutput {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'efId': !exists(json, 'ef_id') ? undefined : json['ef_id'],
        'image': !exists(json, 'image') ? undefined : json['image'],
        'imageCaption': !exists(json, 'image_caption') ? undefined : json['image_caption'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'buttonCopy': !exists(json, 'button_copy') ? undefined : json['button_copy'],
        'componentId': !exists(json, 'component_id') ? undefined : json['component_id'],
    };
}

export function MarketingMessageOutputToJSON(value?: MarketingMessageOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'ef_id': value.efId,
        'image': value.image,
        'image_caption': value.imageCaption,
        'content': value.content,
        'button_copy': value.buttonCopy,
        'component_id': value.componentId,
    };
}


