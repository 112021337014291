export function a11yClick(event: React.KeyboardEvent) {
  if (event.type === "click") {
    return true;
  } else if (event.type === "keypress") {
    // use recommend way for checking key
    if (event.key === "Enter" || event.key === "Space") {
      return true;
    }

    // charCode and keyCode are deprecated but leaving this because of people using legacy browser
    const code = event.charCode || event.keyCode;
    if (code === 32 || code === 13) {
      return true;
    }
  } else {
    return false;
  }
  return false;
}
