import React from "react";
import Typography from "components/Typography";
import { selectFeatures } from "shared/features/featureFlags/selectors";
import classes from "./index.module.css";
import { selectLoggedInUser } from "shared/features/user/selectors";
import useGetAccountInfo from "shared/features/memberProfile/useGetAccountInfo";
import { utcToZonedTime } from "date-fns-tz";
import { HoursOfOperation } from "shared/api/src/models/HoursOfOperation";
import Asset from "components/Asset";
import Heading from "components/DynamicHeadings";
import { isWithinInterval } from "date-fns";

const getIsDuringBusinessHours = (
  hours: HoursOfOperation | undefined,
  timezone: string | undefined
) => {
  const parsedDate = utcToZonedTime(new Date(), timezone || "");

  const weekdayAbbreviations = ["su", "M", "T", "W", "th", "F", "S"];
  const weekday = parsedDate.getDay();
  const abbreviation = weekdayAbbreviations[weekday];
  const startTimeString = hours?.[abbreviation]?.[0];
  const endTimeString = hours?.[abbreviation]?.[1];

  const startDate = new Date(parsedDate);
  startDate.setHours(startTimeString?.split(":")[0]);
  startDate.setMinutes(startTimeString?.split(":")[1]);

  const endDate = new Date(parsedDate);
  endDate.setHours(endTimeString?.split(":")[0]);
  endDate.setMinutes(endTimeString?.split(":")[1]);

  // handle case when no hours of operation exist for current day (i.e.: clinic is closed)
  if (!hours?.[abbreviation]) {
    return false;
  }

  // handle bad date data
  if (
    startDate.toString() === "Invalid Date" ||
    endDate.toString() === "Invalid Date"
  ) {
    return true;
  }

  return isWithinInterval(parsedDate, {
    start: startDate,
    end: endDate,
  });
};

const AfterHoursWidget = () => {
  const features = selectFeatures();
  const user = selectLoggedInUser();
  const { accountInfo } =
    useGetAccountInfo({
      // @ts-ignore: Object is possibly 'null'.
      id: user?.id?.toString(),
    }) || {};

  if (!accountInfo?.defaultClinic?.memberHours) {
    return null;
  }

  const isDuringBusinessHours = getIsDuringBusinessHours(
    accountInfo?.defaultClinic?.memberHours,
    accountInfo?.defaultClinic?.timezone
  );

  if (isDuringBusinessHours || !features?.hasAfterHoursWidget()) {
    return null;
  }

  return (
    <div data-testid="after-hours-widget" className={classes.container}>
      <div>
        <Asset
          alt="after hours image"
          name="moon-stars"
          className={classes.image}
        />
      </div>
      <div className={classes.textContainer}>
        <Heading.LevelBoundary>
          <Heading.H className={classes.headingText}>
            Looking for care after hours?
          </Heading.H>
        </Heading.LevelBoundary>
        <Typography className={classes.bodyText}>
          {`For issues that cannot wait, connect with your on-call team at ${accountInfo?.defaultClinic?.phone}.`}
        </Typography>
        <Typography className={classes.noteText}>
          * For emergencies call 911
        </Typography>
      </div>
    </div>
  );
};

export default AfterHoursWidget;
