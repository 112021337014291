// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';


/**
 * 
 * @export
 * @interface ActionableItemDetailsCore
 */
export interface ActionableItemDetailsCore  {
    /**
     * 
     * @type {string}
     * @memberof ActionableItemDetailsCore
     */
    episodeId: string;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemDetailsCore
     */
    componentId: string;
    /**
     * 
     * @type {Date}
     * @memberof ActionableItemDetailsCore
     */
    acknowledgedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ActionableItemDetailsCore
     */
    resolvedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemDetailsCore
     */
    memberId?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemDetailsCore
     */
    todoDisplayLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemDetailsCore
     */
    notificationCenterDisplayLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemDetailsCore
     */
    ctmStateDisplayLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionableItemDetailsCore
     */
    queueCopyDisplayLabel?: string;
    /**
     * 
     * @type {CTMInfo}
     * @memberof ActionableItemDetailsCore
     */
    resolved?: CTMInfo;
}

export function ActionableItemDetailsCoreFromJSON(json: any): ActionableItemDetailsCore {
    return {
        'episodeId': json['episode_id'],
        'componentId': json['component_id'],
        'acknowledgedAt': !exists(json, 'acknowledged_at') ? undefined : new Date(json['acknowledged_at']),
        'resolvedAt': !exists(json, 'resolved_at') ? undefined : new Date(json['resolved_at']),
        'memberId': !exists(json, 'member_id') ? undefined : json['member_id'],
        'todoDisplayLabel': !exists(json, 'todo_display_label') ? undefined : json['todo_display_label'],
        'notificationCenterDisplayLabel': !exists(json, 'notification_center_display_label') ? undefined : json['notification_center_display_label'],
        'ctmStateDisplayLabel': !exists(json, 'ctm_state_display_label') ? undefined : json['ctm_state_display_label'],
        'queueCopyDisplayLabel': !exists(json, 'queue_copy_display_label') ? undefined : json['queue_copy_display_label'],
        'resolved': !exists(json, 'resolved') ? undefined : CTMInfoFromJSON(json['resolved']),
    };
}

export function ActionableItemDetailsCoreToJSON(value?: ActionableItemDetailsCore): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'episode_id': value.episodeId,
        'component_id': value.componentId,
        'acknowledged_at': value.acknowledgedAt === undefined ? undefined : value.acknowledgedAt.toISOString(),
        'resolved_at': value.resolvedAt === undefined ? undefined : value.resolvedAt.toISOString(),
        'member_id': value.memberId,
        'todo_display_label': value.todoDisplayLabel,
        'notification_center_display_label': value.notificationCenterDisplayLabel,
        'ctm_state_display_label': value.ctmStateDisplayLabel,
        'queue_copy_display_label': value.queueCopyDisplayLabel,
        'resolved': CTMInfoToJSON(value.resolved),
    };
}


