// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StripePlan,
    StripePlanFromJSON,
    StripePlanToJSON,
} from './StripePlan';


/**
 * 
 * @export
 * @interface FetchStripePrice
 */
export interface FetchStripePrice  {
    /**
     * 
     * @type {StripePlan}
     * @memberof FetchStripePrice
     */
    results?: StripePlan;
}

export function FetchStripePriceFromJSON(json: any): FetchStripePrice {
    return {
        'results': !exists(json, 'results') ? undefined : StripePlanFromJSON(json['results']),
    };
}

export function FetchStripePriceToJSON(value?: FetchStripePrice): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': StripePlanToJSON(value.results),
    };
}


