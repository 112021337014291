// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ClientClinicsSchema,
    ClientClinicsSchemaFromJSON,
    ClientClinicsSchemaToJSON,
} from './ClientClinicsSchema';


/**
 * 
 * @export
 * @interface ClientsClinicsResponse
 */
export interface ClientsClinicsResponse  {
    /**
     * 
     * @type {Array<ClientClinicsSchema>}
     * @memberof ClientsClinicsResponse
     */
    results?: Array<ClientClinicsSchema>;
}

export function ClientsClinicsResponseFromJSON(json: any): ClientsClinicsResponse {
    return {
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(ClientClinicsSchemaFromJSON),
    };
}

export function ClientsClinicsResponseToJSON(value?: ClientsClinicsResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(ClientClinicsSchemaToJSON),
    };
}


