import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const useStyles = makeStyles(({ breakpoints }) =>
  createStyles({
    modalDialog: {
      "& .MuiDialog-paper": {
        maxWidth: 400,
        textAlign: "center",
      },
      [breakpoints.down("sm")]: {
        "& button": {
          display: "block",
          marginRight: 0,
          "&:first-child": {
            marginBottom: 10,
          },
        },
      },
    },
  })
);
