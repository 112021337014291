// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompactComponentReference,
    CompactComponentReferenceFromJSON,
    CompactComponentReferenceToJSON,
} from './CompactComponentReference';
import {
    CtmMessageComponentOutput,
    CtmMessageComponentOutputFromJSON,
    CtmMessageComponentOutputToJSON,
} from './CtmMessageComponentOutput';
import {
    MarketingMessageComponentOutput,
    MarketingMessageComponentOutputFromJSON,
    MarketingMessageComponentOutputToJSON,
} from './MarketingMessageComponentOutput';
import {
    QuestionSetJsonBodySchema,
    QuestionSetJsonBodySchemaFromJSON,
    QuestionSetJsonBodySchemaToJSON,
} from './QuestionSetJsonBodySchema';


/**
 * 
 * @export
 * @interface QuestionSetComponentOutputProps
 */
export interface QuestionSetComponentOutputProps  {
    /**
     * 
     * @type {string}
     * @memberof QuestionSetComponentOutputProps
     */
    state: QuestionSetComponentOutputPropsStateEnum;
    /**
     * 
     * @type {Array<CompactComponentReference>}
     * @memberof QuestionSetComponentOutputProps
     */
    parentComponents?: Array<CompactComponentReference>;
    /**
     * 
     * @type {Array<CompactComponentReference>}
     * @memberof QuestionSetComponentOutputProps
     */
    childComponents?: Array<CompactComponentReference>;
    /**
     * 
     * @type {QuestionSetJsonBodySchema}
     * @memberof QuestionSetComponentOutputProps
     */
    jsonBody?: QuestionSetJsonBodySchema;
    /**
     * 
     * @type {CtmMessageComponentOutput}
     * @memberof QuestionSetComponentOutputProps
     */
    messageComponent?: CtmMessageComponentOutput;
    /**
     * 
     * @type {MarketingMessageComponentOutput}
     * @memberof QuestionSetComponentOutputProps
     */
    marketingMessageComponent?: MarketingMessageComponentOutput;
    /**
     * 
     * @type {string}
     * @memberof QuestionSetComponentOutputProps
     */
    submittedAt?: string;
}

export function QuestionSetComponentOutputPropsFromJSON(json: any): QuestionSetComponentOutputProps {
    return {
        'state': json['state'],
        'parentComponents': !exists(json, 'parent_components') ? undefined : (json['parent_components'] as Array<any>).map(CompactComponentReferenceFromJSON),
        'childComponents': !exists(json, 'child_components') ? undefined : (json['child_components'] as Array<any>).map(CompactComponentReferenceFromJSON),
        'jsonBody': !exists(json, 'json_body') ? undefined : QuestionSetJsonBodySchemaFromJSON(json['json_body']),
        'messageComponent': !exists(json, 'message_component') ? undefined : CtmMessageComponentOutputFromJSON(json['message_component']),
        'marketingMessageComponent': !exists(json, 'marketing_message_component') ? undefined : MarketingMessageComponentOutputFromJSON(json['marketing_message_component']),
        'submittedAt': !exists(json, 'submitted_at') ? undefined : json['submitted_at'],
    };
}

export function QuestionSetComponentOutputPropsToJSON(value?: QuestionSetComponentOutputProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'state': value.state,
        'parent_components': value.parentComponents === undefined ? undefined : (value.parentComponents as Array<any>).map(CompactComponentReferenceToJSON),
        'child_components': value.childComponents === undefined ? undefined : (value.childComponents as Array<any>).map(CompactComponentReferenceToJSON),
        'json_body': QuestionSetJsonBodySchemaToJSON(value.jsonBody),
        'message_component': CtmMessageComponentOutputToJSON(value.messageComponent),
        'marketing_message_component': MarketingMessageComponentOutputToJSON(value.marketingMessageComponent),
        'submitted_at': value.submittedAt,
    };
}

/**
* @export
* @enum {string}
*/
export enum QuestionSetComponentOutputPropsStateEnum {
    UnreadPendingResponse = 'unread_pending_response',
    ReadPendingResponse = 'read_pending_response',
    DraftMember = 'draft_member',
    ResponsesSentPendingAck = 'responses_sent_pending_ack',
    AcknowledgedPendingResponse = 'acknowledged_pending_response',
    Resolved = 'resolved'
}


