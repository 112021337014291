import Modal from "./modal";
import { PrintJsOptions } from ".";

export function capitalizePrint(obj: string) {
  return obj.charAt(0).toUpperCase() + obj.slice(1);
}

export function addHeader(printElement: HTMLElement, params: PrintJsOptions) {
  // Create the header container div
  const headerContainer = document.createElement("div");

  // Create header element
  const headerElement = document.createElement("h1");

  // Create header text node
  const headerNode = document.createTextNode(params.header!);

  // Build and style
  headerElement.appendChild(headerNode);
  headerElement.setAttribute("style", params.headerStyle || "");
  headerContainer.appendChild(headerElement);

  printElement.insertBefore(headerContainer, printElement.childNodes[0]);
}

export function cleanUp(params: PrintJsOptions) {
  // If we are showing a feedback message to user, remove it
  if (params.showModal) {
    Modal.close();
  }

  // Check for a finished loading hook function
  if (params.onLoadingEnd) {
    params.onLoadingEnd();
  }

  // If preloading pdf files, clean blob url
  if (params.showModal || params.onLoadingStart) {
    window.URL.revokeObjectURL(params.printable);
  }

  // Run onPrintDialogClose callback
  let event = "mouseover";

  if (Browser.isChrome() || Browser.isFirefox()) {
    // Ps.: Firefox will require an extra click in the document to fire the focus event.
    event = "focus";
  }

  const handler = () => {
    // Make sure the event only happens once.
    window.removeEventListener(event, handler);
    if (params.onPrintDialogClose) {
      params.onPrintDialogClose();
    }

    // Remove iframe from the DOM
    const iframe = document.getElementById(params.frameId!);

    if (iframe) {
      iframe.remove();
    }
  };

  window.addEventListener(event, handler);
}

export const Browser = {
  // Firefox 1.0+
  isFirefox: () => {
    // @ts-expect-error
    return typeof InstallTrigger !== "undefined";
  },
  // Internet Explorer 6-11
  isIE: () => {
    return (
      // @ts-expect-error
      navigator.userAgent.indexOf("MSIE") !== -1 || !!document.documentMode
    );
  },
  // Edge 20+
  isEdge: () => {
    // @ts-ignore
    return !Browser.isIE() && !!window.StyleMedia;
  },
  // Chrome 1+
  isChrome: (context = window) => {
    // @ts-expect-error
    return !!context.chrome;
  },
  // At least Safari 3+: "[object HTMLElementConstructor]"
  isSafari: () => {
    return (
      Object.prototype.toString
        .call(window.HTMLElement)
        .indexOf("Constructor") > 0 ||
      navigator.userAgent.toLowerCase().indexOf("safari") !== -1
    );
  },
  // IOS Chrome
  isIOSChrome: () => {
    return navigator.userAgent.toLowerCase().indexOf("crios") !== -1;
  },
};
