// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     DisplayPropertiesSchema,
    DisplayPropertiesSchemaFromJSON,
    DisplayPropertiesSchemaToJSON,
} from './DisplayPropertiesSchema';
import {
     OutputSubTypeSchemaProps,
    OutputSubTypeSchemaPropsFromJSON,
    OutputSubTypeSchemaPropsToJSON,
} from './OutputSubTypeSchemaProps';
/**
 * @type OutputSubTypeSchema
 * @export
 */
export interface OutputSubTypeSchema extends DisplayPropertiesSchema, OutputSubTypeSchemaProps {
}

export function OutputSubTypeSchemaFromJSON(json: any): OutputSubTypeSchema {
    return {
        ...DisplayPropertiesSchemaFromJSON(json),
        ...OutputSubTypeSchemaPropsFromJSON(json),
    };
}

export function OutputSubTypeSchemaToJSON(value?: OutputSubTypeSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...DisplayPropertiesSchemaToJSON(value),
        ...OutputSubTypeSchemaPropsToJSON(value),
    };
}

