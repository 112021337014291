/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CTMInfoArrayResponse,
    CTMInfoArrayResponseFromJSON,
    CTMInfoArrayResponseToJSON,
} from '../models/CTMInfoArrayResponse'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface GetPanelCareTeamRequest {
    id: string;
    filterViewingCtm?: boolean;
    memberFacing?: boolean;
}


export interface IPanelCareTeamApi {
      getPanelCareTeam: (requestParameters: GetPanelCareTeamRequest) => Promise<CTMInfoArrayResponse>
      getPanelCareTeamWithResponse: (requestParameters: GetPanelCareTeamRequest) => Promise<{ value: CTMInfoArrayResponse, response: any}>
}

/**
 * no description
 */
export class PanelCareTeamApi extends runtime.BaseAPI {

    /**
     * Fetch member panel care team
     */
    getPanelCareTeamRaw = async (requestParameters: GetPanelCareTeamRequest): Promise<{ runtime: runtime.ApiResponse<CTMInfoArrayResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPanelCareTeam.');
        }

                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filterViewingCtm !== undefined) {
            queryParameters['filter_viewing_ctm'] = requestParameters.filterViewingCtm;
        }

        if (requestParameters.memberFacing !== undefined) {
            queryParameters['member_facing'] = requestParameters.memberFacing;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/panel_care_team`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CTMInfoArrayResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch member panel care team
     */
    getPanelCareTeam = async (requestParameters: GetPanelCareTeamRequest): Promise<CTMInfoArrayResponse> => {
        const { runtime } = await this.getPanelCareTeamRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch member panel care team
     */
    getPanelCareTeamWithResponse = async (requestParameters: GetPanelCareTeamRequest): Promise<{ value: CTMInfoArrayResponse, response: any}> => {
        const { runtime, response } = await this.getPanelCareTeamRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchPanelCareTeamApi: IPanelCareTeamApi  = new PanelCareTeamApi();
