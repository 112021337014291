import { useTheme, useMediaQuery } from "@mui/material";
import { selectFeatures } from "shared/features/featureFlags/selectors";

import { selectNativeAppState } from "shared/state/nativeApp/selectors";

const useMobileCheck = () => {
  const { isApp } = selectNativeAppState();
  const theme = useTheme();
  const isMobileScreenSize = useMediaQuery(theme.breakpoints.down('lg'));

  return isApp || isMobileScreenSize;
};

export const useSmallScreenCheck = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('xl'));
};

export const useMobileAccountUpdates = () => {
  const featureFlags = selectFeatures();
  const isMobile = useMobileCheck();

  return isMobile && featureFlags?.hasMobileAccountUpdates();
};

export default useMobileCheck;
