import React from "react";
import ErrorBoundaryPage from "components/Access/ErrorBoundaryPage";

const ErrorsBoundary: React.FunctionComponent = ({ resetError }: any) => {
  return (
    <ErrorBoundaryPage
      imageName="noEpisodeResults"
      message="There seems to be an issue loading this screen right now. You can try to view it again later."
      resetError={resetError}
    />
  );
};

export default ErrorsBoundary;
