import Paper from "components/Paper";
import Alert from "components/Alert";
import React, { useRef } from "react";
import HTMLViewer from "./HTMLViewer";
import Dialog from "components/Dialog";
import PDFViewer from "components/PDFViewer";
import DialogTitle from "components/Dialog/DialogTitle";
import DialogContent from "components/Dialog/DialogContent";
import { PaperProps, Theme, DialogActions } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import clsx from "clsx";
import useMobileCheck from "hooks/useMobileCheck";
import Asset from "components/Asset";
import CssTypography from "components/CssTypography";
import { Link } from "components/Button";

export enum AttachmentViewerModeEnum {
  PDF = "PDF",
  HTML = "HTML",
  IMAGE = "IMAGE",
}

interface IProps {
  open: boolean;
  title: string;
  fileUrl: string;
  toggle: () => void;
  viewer?: AttachmentViewerModeEnum;
  onNext?: (() => void) | any;
  onPrevious?: (() => void) | any;
  activeAttachmentIndex?: number;
  attachmentsLength?: number;
  hideDownload?: boolean;
}

const useStyles = makeStyles(({ breakpoints, spacing }: Theme) =>
  createStyles({
    contentRoot: {
      height: 674,
      display: "flex",
      justifyContent: "center",
      overflowX: "hidden",
      margin: 24,
      padding: 0,
    },
    imageViewBox: {
      maxWidth: "100%",
      height: "100%",
    },
    consentViewBox: {
      alignSelf: "flex-start",
    },
    title: {
      padding: "16px 24px 8px 24px",
    },
    paper: {
      borderRadius: 0,
      [breakpoints.down("sm")]: {
        margin: spacing(1),
      },
    },
    previous: {
      left: "-7.5%",
      marginTop: "53%",
      position: "absolute",
    },
    button: {
      color: "white",
      backgroundColor: "#2B3949",
      width: 32,
      height: 32,
      borderRadius: "50%",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#F4B542",
      },
    },
    next: {
      right: "-7%",
      marginTop: "53%",
      position: "absolute",
    },
    downloadButton: {
      backgroundColor: "#F4B542",
      width: 140,
      height: 32,
      left: 24,
      borderRadius: 20,
    },
    actions: {
      justifyContent: "flex-start",
      padding: 0,
    },
  })
);

const PaperComponent = (props: PaperProps) => {
  return (
    <Paper {...props} style={{ width: "100%", overflowY: "unset" }} square />
  );
};

const AttachmentViewerModal: React.FC<IProps> = ({
  open,
  title,
  fileUrl,
  viewer,
  toggle,
  onNext,
  onPrevious,
  activeAttachmentIndex = 0,
  attachmentsLength = 1,
  hideDownload,
}) => {
  const classes = useStyles();
  const pdfViewerRef = useRef<HTMLElement | null>(null);
  const isMobile = useMobileCheck();

  let documentViewer;
  switch (viewer) {
    case AttachmentViewerModeEnum.PDF:
      documentViewer = (
        <PDFViewer
          title={title}
          file={fileUrl}
          withAccessToken
          fileDownloadUrl={fileUrl + "&download=true"}
          containerRef={pdfViewerRef}
        />
      );
      break;

    case AttachmentViewerModeEnum.IMAGE:
      documentViewer = (
        <img
          src={fileUrl}
          alt="file display"
          className={classes.imageViewBox}
        />
      );
      break;

    case AttachmentViewerModeEnum.HTML:
      documentViewer = (
        <HTMLViewer
          url={fileUrl}
          className={classes.consentViewBox}
          // consent specific messages since they are the only html documents we are supporting
          loadingMessage="Loading consent..."
          errorMessage="Failed to load consent"
        />
      );
      break;

    default:
      documentViewer = <Alert severity="info">Unsupported document type</Alert>;
      break;
  }

  return (
    <Dialog
      ariaLabelledBy="lab-result-pdf-title"
      open={open}
      scroll="paper"
      maxWidth="lg"
      fullScreen={false}
      data-testid="lab-result-modal"
      PaperComponent={PaperComponent}
      PaperProps={{ classes: { root: classes.paper } }}
      onClose={(_e, reason) => {
        if (reason === "backdropClick") {
          return;
        }
        toggle();
      }}
    >
      {/* //todo: adding a heading here and passing variant="srOnly" as prop gives validate DOM nesting error for h1 inside h1:
          should we remove title text here instead? */}
      <DialogTitle
        id="lab-result-pdf-title"
        onClose={toggle}
        data-e2e="attachment-title"
        className={classes.title}
      >
        {title}
      </DialogTitle>
      <DialogActions className={classes.actions}>
        {!hideDownload && (
          <Link
            download={title}
            size="small"
            href={fileUrl + "&download=true"}
            name="download"
            target="_blank"
            rel="noopener noreferrer"
            className={classes.downloadButton}
            aria-label={`Download ${title} pdf to your device`}
            data-e2e="attachment-download"
          >
            <Asset name="Download" style={{ marginRight: 8 }} />
            <CssTypography>Download</CssTypography>
          </Link>
        )}

        {activeAttachmentIndex > 0 && !isMobile && (
          <NavigateBeforeIcon
            className={clsx(classes.previous, classes.button)}
            onClick={() => onPrevious(activeAttachmentIndex)}
            role="button"
            data-e2e="previous-attachment-button"
          />
        )}
        {activeAttachmentIndex < attachmentsLength - 1 && !isMobile && (
          <NavigateNextIcon
            className={clsx(classes.button, classes.next)}
            onClick={() => onNext(activeAttachmentIndex)}
            role="button"
            data-e2e="next-attachment-button"
          />
        )}
      </DialogActions>

      <DialogContent className={classes.contentRoot} ref={pdfViewerRef}>
        {documentViewer}
      </DialogContent>
    </Dialog>
  );
};

export default AttachmentViewerModal;
