import { State } from "shared/types/state";
import { getWindow } from "shared/features/window";

export const getMemberId = (state: State): string | null | undefined => {
  return state?.ui?.member?.memberId;
};

export const getSearchQuery = () => {
  const searchParams = new URLSearchParams(getWindow().location.search);
  return searchParams.get("q");
};

export const getMemberTimelineDestinationUrl = (state: State) => {
  return state?.ui?.member?.timelineDestinationUrl;
};

export const getDraftState = (state: State) => {
  return state?.ui?.member?.hasUnsavedInput;
};

export const getConversationFilter = (state: State) => {
  return state?.ui?.member?.conversationFilter;
};
