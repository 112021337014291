import React, { FunctionComponent } from "react";
import DialogActionsComponent, {
  DialogActionsProps,
} from "@mui/material/DialogActions";
import { Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

interface IProps extends DialogActionsProps {
  horizontal?: boolean;
}

const useStyles = makeStyles((_theme: Theme) => ({
  root: (props: IProps) => ({
    display: "flex",
    justifyContent: `${props.horizontal ? "flex-start" : "center"}`,
    flexDirection: props.horizontal ? `row` : "column",
    paddingTop: "1.33em",
    paddingBottom: "1.33em",
  }),
}));

const DialogActions: FunctionComponent<IProps> = (props) => {
  const { horizontal, ...restProps } = props;
  const classes = useStyles({ horizontal });
  return <DialogActionsComponent classes={classes} {...restProps} />;
};

export default DialogActions;
