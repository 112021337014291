// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ConversationSummariesResponseTotals,
    ConversationSummariesResponseTotalsFromJSON,
    ConversationSummariesResponseTotalsToJSON,
} from './ConversationSummariesResponseTotals';
import {
    ConversationSummary,
    ConversationSummaryFromJSON,
    ConversationSummaryToJSON,
} from './ConversationSummary';


/**
 * 
 * @export
 * @interface ConversationSummariesResponse
 */
export interface ConversationSummariesResponse  {
    /**
     * 
     * @type {string}
     * @memberof ConversationSummariesResponse
     */
    prevUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ConversationSummariesResponse
     */
    nextUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof ConversationSummariesResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof ConversationSummariesResponse
     */
    last?: number;
    /**
     * 
     * @type {Array<ConversationSummary>}
     * @memberof ConversationSummariesResponse
     */
    results: Array<ConversationSummary>;
    /**
     * 
     * @type {ConversationSummariesResponseTotals}
     * @memberof ConversationSummariesResponse
     */
    totals?: ConversationSummariesResponseTotals;
}

export function ConversationSummariesResponseFromJSON(json: any): ConversationSummariesResponse {
    return {
        'prevUrl': !exists(json, 'prev_url') ? undefined : json['prev_url'],
        'nextUrl': !exists(json, 'next_url') ? undefined : json['next_url'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'results': (json['results'] as Array<any>).map(ConversationSummaryFromJSON),
        'totals': !exists(json, 'totals') ? undefined : ConversationSummariesResponseTotalsFromJSON(json['totals']),
    };
}

export function ConversationSummariesResponseToJSON(value?: ConversationSummariesResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'prev_url': value.prevUrl,
        'next_url': value.nextUrl,
        'page': value.page,
        'last': value.last,
        'results': (value.results as Array<any>).map(ConversationSummaryToJSON),
        'totals': ConversationSummariesResponseTotalsToJSON(value.totals),
    };
}


