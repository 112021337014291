// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ArchiveEpisodeOfCareRequestBody
 */
export interface ArchiveEpisodeOfCareRequestBody  {
    /**
     * 
     * @type {boolean}
     * @memberof ArchiveEpisodeOfCareRequestBody
     */
    billable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArchiveEpisodeOfCareRequestBody
     */
    consultation?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArchiveEpisodeOfCareRequestBody
     */
    serviceProviderId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArchiveEpisodeOfCareRequestBody
     */
    icd10Codes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ArchiveEpisodeOfCareRequestBody
     */
    archiveOption?: ArchiveEpisodeOfCareRequestBodyArchiveOptionEnum;
    /**
     * 
     * @type {string}
     * @memberof ArchiveEpisodeOfCareRequestBody
     */
    archiveReason?: string;
}

export function ArchiveEpisodeOfCareRequestBodyFromJSON(json: any): ArchiveEpisodeOfCareRequestBody {
    return {
        'billable': !exists(json, 'billable') ? undefined : json['billable'],
        'consultation': !exists(json, 'consultation') ? undefined : json['consultation'],
        'serviceProviderId': !exists(json, 'service_provider_id') ? undefined : json['service_provider_id'],
        'icd10Codes': !exists(json, 'icd10_codes') ? undefined : json['icd10_codes'],
        'archiveOption': !exists(json, 'archive_option') ? undefined : json['archive_option'],
        'archiveReason': !exists(json, 'archive_reason') ? undefined : json['archive_reason'],
    };
}

export function ArchiveEpisodeOfCareRequestBodyToJSON(value?: ArchiveEpisodeOfCareRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'billable': value.billable,
        'consultation': value.consultation,
        'service_provider_id': value.serviceProviderId,
        'icd10_codes': value.icd10Codes,
        'archive_option': value.archiveOption,
        'archive_reason': value.archiveReason,
    };
}

/**
* @export
* @enum {string}
*/
export enum ArchiveEpisodeOfCareRequestBodyArchiveOptionEnum {
    Completed = 'completed',
    MovedToMaintenance = 'moved_to_maintenance',
    PendingAppointment = 'pending_appointment',
    CanceledByMember = 'canceled_by_member',
    NoResponseToOutreach = 'no_response_to_outreach',
    AbandonedByMember = 'abandoned_by_member',
    CreatedInError = 'created_in_error',
    Other = 'other'
}


