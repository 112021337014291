// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberAppointmentsCount
 */
export interface MemberAppointmentsCount  {
    /**
     * 
     * @type {number}
     * @memberof MemberAppointmentsCount
     */
    all?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberAppointmentsCount
     */
    upcoming?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberAppointmentsCount
     */
    past?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberAppointmentsCount
     */
    cancelled?: number;
}

export function MemberAppointmentsCountFromJSON(json: any): MemberAppointmentsCount {
    return {
        'all': !exists(json, 'all') ? undefined : json['all'],
        'upcoming': !exists(json, 'upcoming') ? undefined : json['upcoming'],
        'past': !exists(json, 'past') ? undefined : json['past'],
        'cancelled': !exists(json, 'cancelled') ? undefined : json['cancelled'],
    };
}

export function MemberAppointmentsCountToJSON(value?: MemberAppointmentsCount): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'all': value.all,
        'upcoming': value.upcoming,
        'past': value.past,
        'cancelled': value.cancelled,
    };
}


