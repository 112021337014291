import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from "redux";
import {
  RequestAsyncAction,
  MutateAsyncAction,
  QueryConfig,
} from "redux-query";
import { State } from "shared/types/state";
import { Entities } from "shared/types/state";

const authHeaderMiddleware: Middleware = (
  api: MiddlewareAPI<Dispatch<AnyAction>, State>
) => (next: Dispatch) => (
  action: AnyAction | RequestAsyncAction | MutateAsyncAction
) => {
  if (
    action.type === "@@query/REQUEST_ASYNC" ||
    action.type === "@@query/MUTATE_ASYNC"
  ) {
    const queryAction = action as QueryConfig<Entities>;
    if (
      queryAction.meta &&
      queryAction.meta.authType &&
      queryAction.meta.authType[0] === "oauth"
    ) {
      const token = api.getState().auth.accessToken;
      if (token && queryAction.options && queryAction.options.headers) {
        queryAction.options.headers.authorization = `Bearer ${token}`;
      } else {
        // throw new Error("Missing accessToken scenario is not handled yet");
      }
    }
  }

  return next(action);
};

export default authHeaderMiddleware;
