const SET_MEMBER_ID = "SET_MEMBER_ID";
const SET_PATIENT_ACCESS = "SET_PATIENT_ACCESS";
const REMOVE_PATIENT_ACCESS = "REMOVE_PATIENT_ACCESS";
const SET_TIMELINE_DESTINATION = "SET_TIMELINE_DESTINATION";
const SET_HAS_UNSAVED_INPUT = "SET_HAS_UNSAVED_INPUT";
const SET_CONVERSATION_FILTER = "SET_CONVERSATION_FILTER";

export interface IState {
  memberId?: string | null | undefined;
  accessGrantToken?: string | null | undefined;
  timelineDestinationUrl?: string;
  hasUnsavedInput?: boolean;
  conversationFilter?: string;
}

export const member = (state: IState = {}, action: any): IState => {
  switch (action.type) {
    case SET_MEMBER_ID:
      return {
        ...state,
        memberId: action.memberId,
        accessGrantToken: action.memberId ? state.accessGrantToken : null,
      };

    case SET_PATIENT_ACCESS:
      return {
        ...state,
        accessGrantToken: action.accessGrantToken,
      };

    case REMOVE_PATIENT_ACCESS:
      return {
        ...state,
        accessGrantToken: undefined,
      };
    case SET_TIMELINE_DESTINATION:
      return {
        ...state,
        timelineDestinationUrl: action.timelineDestinationUrl,
      };
    case SET_HAS_UNSAVED_INPUT:
      return {
        ...state,
        hasUnsavedInput: action.hasUnsavedInput,
      };
    case SET_CONVERSATION_FILTER:
      return {
        ...state,
        conversationFilter: action.conversationFilter,
      };

    default:
      return state;
  }
};

export const setMemberId = (memberId: string) => {
  return { type: SET_MEMBER_ID, memberId };
};

export const setPatientAccessGrantToken = (accessGrantToken: string) => {
  return { type: SET_PATIENT_ACCESS, accessGrantToken };
};

export const removePatientAccessGrantToken = () => {
  return { type: REMOVE_PATIENT_ACCESS };
};

export const setTimelineDestinationUrl = (timelineDestinationUrl: string) => {
  return { type: SET_TIMELINE_DESTINATION, timelineDestinationUrl };
};

export const setHasUnsavedInput = (hasUnsavedInput: boolean) => {
  return { type: SET_HAS_UNSAVED_INPUT, hasUnsavedInput };
};

export const setSelectedConservationFilter = (conversationFilter: string) => {
  return { type: SET_CONVERSATION_FILTER, conversationFilter };
};

/**
 * Gets memberId state from storage.
 */
export const getInitialMember = (): IState => {
  return {
    memberId: null,
    accessGrantToken: null,
    timelineDestinationUrl: "",
    hasUnsavedInput: false,
    conversationFilter: "all",
  };
};
