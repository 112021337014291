// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PrescriptionsSchema
 */
export interface PrescriptionsSchema  {
    /**
     * 
     * @type {string}
     * @memberof PrescriptionsSchema
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionsSchema
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof PrescriptionsSchema
     */
    quantity: number;
    /**
     * 
     * @type {Date}
     * @memberof PrescriptionsSchema
     */
    orderDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionsSchema
     */
    doseInstructions: string;
    /**
     * 
     * @type {number}
     * @memberof PrescriptionsSchema
     */
    refills?: number;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionsSchema
     */
    unit?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionsSchema
     */
    status: PrescriptionsSchemaStatusEnum;
}

export function PrescriptionsSchemaFromJSON(json: any): PrescriptionsSchema {
    return {
        'id': json['id'],
        'name': json['name'],
        'quantity': json['quantity'],
        'orderDate': !exists(json, 'order_date') ? undefined : new Date(json['order_date']),
        'doseInstructions': json['dose_instructions'],
        'refills': !exists(json, 'refills') ? undefined : json['refills'],
        'unit': !exists(json, 'unit') ? undefined : json['unit'],
        'status': json['status'],
    };
}

export function PrescriptionsSchemaToJSON(value?: PrescriptionsSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'quantity': value.quantity,
        'order_date': value.orderDate === undefined ? undefined : value.orderDate.toISOString(),
        'dose_instructions': value.doseInstructions,
        'refills': value.refills,
        'unit': value.unit,
        'status': value.status,
    };
}

/**
* @export
* @enum {string}
*/
export enum PrescriptionsSchemaStatusEnum {
    Requested = 'requested',
    Refillable = 'refillable'
}


