// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OOONotificationsArray,
    OOONotificationsArrayFromJSON,
    OOONotificationsArrayToJSON,
} from './OOONotificationsArray';


/**
 * 
 * @export
 * @interface MessageRecipientProps
 */
export interface MessageRecipientProps  {
    /**
     * 
     * @type {string}
     * @memberof MessageRecipientProps
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MessageRecipientProps
     */
    avatarUrl?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MessageRecipientProps
     */
    isPcp?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof MessageRecipientProps
     */
    clinics?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MessageRecipientProps
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MessageRecipientProps
     */
    guilds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MessageRecipientProps
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageRecipientProps
     */
    truncatedName?: string;
    /**
     * 
     * @type {string}
     * @memberof MessageRecipientProps
     */
    type: MessageRecipientPropsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof MessageRecipientProps
     */
    roleAbbreviation?: string;
    /**
     * 
     * @type {number}
     * @memberof MessageRecipientProps
     */
    podId?: number;
    /**
     * 
     * @type {Array<OOONotificationsArray>}
     * @memberof MessageRecipientProps
     */
    oooNotifications?: Array<OOONotificationsArray>;
}

export function MessageRecipientPropsFromJSON(json: any): MessageRecipientProps {
    return {
        'id': json['id'],
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'isPcp': !exists(json, 'is_pcp') ? undefined : json['is_pcp'],
        'clinics': !exists(json, 'clinics') ? undefined : json['clinics'],
        'name': json['name'],
        'guilds': !exists(json, 'guilds') ? undefined : json['guilds'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'truncatedName': !exists(json, 'truncated_name') ? undefined : json['truncated_name'],
        'type': json['type'],
        'roleAbbreviation': !exists(json, 'role_abbreviation') ? undefined : json['role_abbreviation'],
        'podId': !exists(json, 'pod_id') ? undefined : json['pod_id'],
        'oooNotifications': !exists(json, 'ooo_notifications') ? undefined : (json['ooo_notifications'] as Array<any>).map(OOONotificationsArrayFromJSON),
    };
}

export function MessageRecipientPropsToJSON(value?: MessageRecipientProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'avatar_url': value.avatarUrl,
        'is_pcp': value.isPcp,
        'clinics': value.clinics,
        'name': value.name,
        'guilds': value.guilds,
        'last_name': value.lastName,
        'truncated_name': value.truncatedName,
        'type': value.type,
        'role_abbreviation': value.roleAbbreviation,
        'pod_id': value.podId,
        'ooo_notifications': value.oooNotifications === undefined ? undefined : (value.oooNotifications as Array<any>).map(OOONotificationsArrayToJSON),
    };
}

/**
* @export
* @enum {string}
*/
export enum MessageRecipientPropsTypeEnum {
    Ctm = 'ctm',
    CareTeam = 'care_team'
}


