// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompactUserSchema
 */
export interface CompactUserSchema  {
    /**
     * 
     * @type {string}
     * @memberof CompactUserSchema
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CompactUserSchema
     */
    dob?: string;
    /**
     * 
     * @type {string}
     * @memberof CompactUserSchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CompactUserSchema
     */
    preferredFirstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompactUserSchema
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompactUserSchema
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompactUserSchema
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompactUserSchema
     */
    legalAndPreferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompactUserSchema
     */
    truncatedName?: string;
    /**
     * 
     * @type {string}
     * @memberof CompactUserSchema
     */
    type: CompactUserSchemaTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CompactUserSchema
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof CompactUserSchema
     */
    timezone?: string;
}

export function CompactUserSchemaFromJSON(json: any): CompactUserSchema {
    return {
        'id': json['id'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'name': json['name'],
        'preferredFirstName': !exists(json, 'preferred_first_name') ? undefined : json['preferred_first_name'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'legalName': !exists(json, 'legal_name') ? undefined : json['legal_name'],
        'legalAndPreferredName': !exists(json, 'legal_and_preferred_name') ? undefined : json['legal_and_preferred_name'],
        'truncatedName': !exists(json, 'truncated_name') ? undefined : json['truncated_name'],
        'type': json['type'],
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
    };
}

export function CompactUserSchemaToJSON(value?: CompactUserSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'dob': value.dob,
        'name': value.name,
        'preferred_first_name': value.preferredFirstName,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'legal_name': value.legalName,
        'legal_and_preferred_name': value.legalAndPreferredName,
        'truncated_name': value.truncatedName,
        'type': value.type,
        'avatar_url': value.avatarUrl,
        'timezone': value.timezone,
    };
}

/**
* @export
* @enum {string}
*/
export enum CompactUserSchemaTypeEnum {
    Member = 'member',
    Ctm = 'ctm'
}


