import * as React from "react";
import { useNavigate } from "react-router";
import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import NotFoundImage from "styles/images/hero/not_found_page.png";
import Typography from "components/Typography";
import { Link } from "components/Button";
import Asset from "components/Asset";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      overflow: "hidden",
      backgroundColor: theme.palette?.appBackground?.main,
      height: "100%",
    },
    Container: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      flexWrap: "wrap",
      minWidth: 0,
      padding: 0,
      overflowY: "scroll",
      zIndex: 1,
      transition: "all ease 0.6s",
    },
    ButtonContainer: {
      width: "400px",
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      justifyContent: "center",
      padding: "30px",
    },
    ChevronLink: {
      textDecoration: "none",
      paddingTop: "40px",
      color: theme.palette?.links?.primary,

      "&:hover": {
        color: theme.palette?.links?.primaryHover,
      },
    },
    ChevronLabel: {
      fontWeight: "bold",
      fontSize: "16px",
    },
    Chevron: {
      height: theme.spacing(2),
      width: theme.spacing(3),
      fill: theme.palette?.links?.primary,
    },
    HeroImage: {
      padding: "5px",
    },
    Hidden: {
      visibility: "hidden",
      width: "0px",
    },
  })
);

const NotFoundPage: React.FunctionComponent<{}> = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const goToDashboard = () => navigate("/");

  return (
    <div data-e2e="not-found-page" className={classes.Container}>
      <div className={classes.ButtonContainer}>
        <Typography appearance="h2" data-e2e="wip">
          This page isn’t available, but your Care Team is.
        </Typography>
        <Link
          component="button"
          aria-label="Back to Dashboard"
          color="primary"
          id="back-arrow"
          onClick={goToDashboard}
          className={classes.ChevronLink}
        >
          <Typography className={classes.ChevronLabel}>
            Back to Dashboard
          </Typography>
          <Asset name="icon-chevron" className={classes.Chevron} />
        </Link>
      </div>
      <img alt="No Result" src={NotFoundImage} className={classes.HeroImage} />
    </div>
  );
};

export default NotFoundPage;
