/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    ListPreventiveMarketingMessagesResponse,
    ListPreventiveMarketingMessagesResponseFromJSON,
    ListPreventiveMarketingMessagesResponseToJSON,
} from '../models/ListPreventiveMarketingMessagesResponse'
import {
    MarketingMessageResponse,
    MarketingMessageResponseFromJSON,
    MarketingMessageResponseToJSON,
} from '../models/MarketingMessageResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface FetchMarketingMessageRequest {
    id: string;
}


export interface IMarketingMessageApi {
      fetchMarketingMessage: (requestParameters: FetchMarketingMessageRequest) => Promise<MarketingMessageResponse>
      listPreventiveMarketingMessages: () => Promise<ListPreventiveMarketingMessagesResponse>
      fetchMarketingMessageWithResponse: (requestParameters: FetchMarketingMessageRequest) => Promise<{ value: MarketingMessageResponse, response: any}>
      listPreventiveMarketingMessagesWithResponse: () => Promise<{ value: ListPreventiveMarketingMessagesResponse, response: any}>
}

/**
 * no description
 */
export class MarketingMessageApi extends runtime.BaseAPI {

    /**
     * Fetch marketing message for the given ID
     * Fetch marketing message for the given ID
     */
    fetchMarketingMessageRaw = async (requestParameters: FetchMarketingMessageRequest): Promise<{ runtime: runtime.ApiResponse<MarketingMessageResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchMarketingMessage.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/marketing_messages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MarketingMessageResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch marketing message for the given ID
     * Fetch marketing message for the given ID
     */
    fetchMarketingMessage = async (requestParameters: FetchMarketingMessageRequest): Promise<MarketingMessageResponse> => {
        const { runtime } = await this.fetchMarketingMessageRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch marketing message for the given ID
     * Fetch marketing message for the given ID
     */
    fetchMarketingMessageWithResponse = async (requestParameters: FetchMarketingMessageRequest): Promise<{ value: MarketingMessageResponse, response: any}> => {
        const { runtime, response } = await this.fetchMarketingMessageRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch Marketing Messages associated with preventive health details
     * Fetch Marketing Messages associated with preventive health details
     */
    listPreventiveMarketingMessagesRaw = async (): Promise<{ runtime: runtime.ApiResponse<ListPreventiveMarketingMessagesResponse>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/preventive_health_details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ListPreventiveMarketingMessagesResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch Marketing Messages associated with preventive health details
     * Fetch Marketing Messages associated with preventive health details
     */
    listPreventiveMarketingMessages = async (): Promise<ListPreventiveMarketingMessagesResponse> => {
        const { runtime } = await this.listPreventiveMarketingMessagesRaw();
        return await runtime.value();
    }

    /**
     * Fetch Marketing Messages associated with preventive health details
     * Fetch Marketing Messages associated with preventive health details
     */
    listPreventiveMarketingMessagesWithResponse = async (): Promise<{ value: ListPreventiveMarketingMessagesResponse, response: any}> => {
        const { runtime, response } = await this.listPreventiveMarketingMessagesRaw();
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchMarketingMessageApi: IMarketingMessageApi  = new MarketingMessageApi();
