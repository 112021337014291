// handle submit events triggered from unrelated components
export const SET_SUBMIT_CALCULATOR_SUCCESS = "SET_SUBMIT_CALCULATOR_SUCCESS";
export const SET_SUBMIT_CALCULATOR_CLEAR = "SET_SUBMIT_CALCULATOR_CLEAR";
export const SET_CALCULATOR_HAS_ERRORS = "SET_CALCULATOR_HAS_ERRORS";

export const calculator = (
  state: State = getInitialCalculatorState(),
  action: any
): State => {
  if (action && action.type) {
    const { type, calculatorId, checkinOpen } = action;
    switch (type) {
      case SET_SUBMIT_CALCULATOR_SUCCESS:
        return Object.assign({}, state, {
          checkinOpen,
          calculatorId,
          submit: true,
          hasErrors: false,
        });

      case SET_SUBMIT_CALCULATOR_CLEAR:
        return Object.assign({}, state, {
          submit: false,
          hasErrors: true,
          checkinOpen: false,
          calculatorId: undefined,
        });

      case SET_CALCULATOR_HAS_ERRORS:
        return {
          ...state,
          hasErrors: action.hasErrors,
        };

      default:
        return state;
    }
  }
  return state;
};

export const setCalculatorHasErrors = (hasErrors: boolean) => {
  return { type: SET_CALCULATOR_HAS_ERRORS, hasErrors };
};

export const submitCalculator = (
  calculatorId: string,
  checkinOpen: boolean
) => {
  return { type: SET_SUBMIT_CALCULATOR_SUCCESS, calculatorId, checkinOpen };
};

export const clearSubmitCalculator = () => {
  return { type: SET_SUBMIT_CALCULATOR_CLEAR };
};

export interface State {
  submit: boolean;
  hasErrors: boolean;
  checkinOpen: boolean;
  calculatorId: undefined | string;
}

export const getInitialCalculatorState = (): State => {
  return {
    submit: false,
    hasErrors: true,
    checkinOpen: true,
    calculatorId: undefined,
  };
};
