import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFeatures } from "shared/features/featureFlags/selectors";
import { useNavigate } from "react-router";
import { styled } from "@mui/material/styles";
import Paginate from "@mui/material/Pagination";
import GearIcon from "@mui/icons-material/Settings";

import {
  useGetHeadsUpNotifications,
  useDeleteHeadsUpNotifications,
  useResolveHeadsUpNotifications,
  useClearAllHeadsUpNotifications,
} from "shared/features/headsUpNotifications";
import { getUser } from "shared/features/user";

import Box from "components/Box";
import Paper from "components/Paper";
import Heading from "components/DynamicHeadings";
import Typography from "components/Typography";
import InboxMessage from "./InboxMessage";

import NoHeadsUp from "../../styles/images/episode/no-heads-up.svg";
import Button from "components/Button";
import Checkbox from "components/Checkbox";
import { convertToPathString } from "components/ServiceMenu/utils";
import ClearAllDialog from "components/HeadsUpNotification/ClearAllDialog";
import { Link } from "react-router-dom";

const Container = styled(Paper)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  borderRadius: 10,
});

const Header = styled(Heading.H)({
  display: "flex",
  alignItems: "center",
  padding: "17px 15px",
  borderBottom: "1px solid rgba(43, 57, 73, 0.3)",
  justifyContent: "space-between",
  "& div": {
    display: "flex",
    alignItems: "center",
  },
  ["@media screen and (max-width: 885px) and (min-width: 768px)"]: {
    display: "block",
  },
});

const Footer = styled(Box)({
  height: 40,
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
});

const Count = styled(Typography)(({ theme: { palette } }) => ({
  display: "flex",
  color: palette.text.secondary,
  margin: "0 5px",
}));

const Badge = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "30px",
  height: "18px",
  borderRadius: "2px",
  backgroundColor: "#0B665C",
  color: "white",
  fontSize: "10px",
  fontWeight: "normal",
  "&:after": {
    content: '" "',
  },
});

const Image = styled("img")({
  width: 152,
  height: 152,
});

const NoCountContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderBottom: "1px solid rgba(43, 57, 73, 0.3)",
  height: 471,
});

const Text = styled("p")(({ theme: { palette } }) => ({
  color: palette.text.secondary,
  fontSize: 16,
  margin: 0,
  fontWeight: "bold",
}));

// this component is propery titled as a button, but we aren't rendering a <button> to the DOM
const SettingIcon = styled(GearIcon)(({ theme: { palette } }) => ({
  cursor: "pointer",
  color: palette?.appBackground?.medGrey,
}));

const SelectOrUnselectAllContainer = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  borderBottom: "1px solid rgba(43, 57, 73, 0.3)",
});

const SelectAllDiv = styled("div")({
  display: "flex",
  alignItems: "center",
  color: "rgb(96, 106, 118)",
});

const Select = styled(Checkbox)({
  display: "flex",
  paddingTop: "8px",
  justifySelf: "flex-start",
  alignSelf: "flex-start",
});

const ClearAllOrDelete = styled(Button)({
  marginRight: "12px",
  marginBottom: "5px",
  marginTop: "8px",
  textDecoration: "underline",
});

const selectionOptions = {
  clearAll: "Clear all",
  delete: "Delete",
};

const HeadsUpNotifications: FunctionComponent = (): JSX.Element => {
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState({});
  const [openFlagModal, setOpenFlagModal] = useState<boolean>(false);
  const [isSelectedAll, setIsSelectedAll] = useState<boolean>(false);
  const [pageCount, setPageCount] = useState(0);
  const [selectionState, setSelectionState] = useState(
    selectionOptions.clearAll
  );
  const { messages, totalCount, unreadCount, hasBadge } =
    useGetHeadsUpNotifications({
      // @ts-ignore: Object is possibly 'null'.
      id: user?.id.toString(),
      page: page.toString(),
    });
  const { deleteHeadsUpNotifications } = useDeleteHeadsUpNotifications({
    userId: user?.id?.toString(),
    isSelectedAll,
  });
  const { resolveHeadsUpNotifications } = useResolveHeadsUpNotifications();
  const { clearAllHeadsUpNotifications } = useClearAllHeadsUpNotifications();
  const features = useSelector(getFeatures);
  const hasVisitSeparation = features.hasVisitSeparation();
  const handleSelect = (id: string, evt: any) => {
    setSelected({ ...selected, [id]: evt.target.checked });
  };
  const handleClick = (url: string, id?: string) => {
    if (id) {
      resolveHeadsUpNotifications(id);
    }
    navigate(url);
  };

  const handleDeleteHeadsUpNotifications = () => {
    const selectedIDs = Object.entries(selected)
      .filter((s) => s[1])
      .map((s) => s[0]);

    if (isSelectedAll && page === pageCount) {
      setPage(page - 1);
      setPageCount(pageCount - 1);
    }

    // @ts-ignore: Object is possibly 'null'.
    deleteHeadsUpNotifications(selectedIDs.join());
  };

  const handleClearAll = () => {
    const userId = user?.id?.toString();
    if (userId) {
      setOpenFlagModal(false);
      clearAllHeadsUpNotifications({ id: userId });
    }
  };

  const handleClearAllOrDeleteSelected = () => {
    if (selectionState === selectionOptions.clearAll) {
      setOpenFlagModal(true);
    } else {
      handleDeleteHeadsUpNotifications();
    }
    setSelected({});
  };

  const messageCount = messages?.length || 0;

  const selectedCheckBoxLength = () => {
    return Object.values(selected).filter((value) => value === true).length;
  };

  const handleSelectionChange = () => {
    if (selectionState === selectionOptions.clearAll && messages) {
      setSelected(Object.fromEntries(messages.map(({ id }) => [id, true])));
    } else {
      setSelected({});
    }
  };

  useEffect(() => {
    if (messages && selectedCheckBoxLength() === messages.length) {
      setIsSelectedAll(true);
    } else {
      setIsSelectedAll(false);
    }

    if (selectedCheckBoxLength() > 0) {
      setSelectionState(selectionOptions.delete);
    } else {
      setSelectionState(selectionOptions.clearAll);
    }
  }, [selected]);

  useEffect(() => {
    setPageCount(Math.ceil(totalCount / 10) || 0);
  }, [totalCount]);

  return (
    <Container elevation={4} data-testid="heads-up-display">
      <Header appearance="h5">
        <div>
          <span>Your Heads Up</span>
          <Count>({unreadCount || 0})</Count>
          {hasBadge && <Badge>NEW</Badge>}
        </div>
        <Link
          to="/account/heads-up-notifications"
          data-e2e="notifications-link"
        >
          <SettingIcon />
        </Link>
      </Header>
      <div>
        {messageCount > 0 ? (
          <>
            <SelectOrUnselectAllContainer>
              <SelectAllDiv>
                <Select
                  color="secondary"
                  name="Select all notifications"
                  checked={isSelectedAll}
                  onChange={handleSelectionChange}
                  data-testid={`notification-checkbox-all`}
                />
                {selectedCheckBoxLength() > 0 && (
                  <span data-testid="selected-items-count">
                    {selectedCheckBoxLength()} items selected
                  </span>
                )}
              </SelectAllDiv>
              <ClearAllOrDelete
                data-testid={`heads-up-${convertToPathString(
                  selectionState
                )}-button`}
                color="link-primary"
                onClick={handleClearAllOrDeleteSelected}
              >
                {selectionState}
              </ClearAllOrDelete>
            </SelectOrUnselectAllContainer>
            <div data-testid="heads-up-notifications-list">
              {messages?.map(
                ({
                  id,
                  title,
                  subTitle,
                  body,
                  footer,
                  url,
                  read,
                  componentName,
                }) => (
                  <InboxMessage
                    key={id}
                    id={id}
                    link={url}
                    title={title}
                    subTitle={subTitle}
                    body={body}
                    footer={footer}
                    isRead={read}
                    isSelected={!!selected[id!]}
                    componentName={componentName}
                    onSelect={handleSelect}
                    onClick={handleClick}
                    hasVisitSeparation={hasVisitSeparation}
                  />
                )
              )}
            </div>
          </>
        ) : (
          <NoCountContainer data-testid="no-heads-up-container">
            <Image aria-label={"HeadsUp"} alt={"No HeadsUp"} src={NoHeadsUp} />
            <Text>No heads up</Text>
          </NoCountContainer>
        )}
      </div>
      <Footer>
        {pageCount > 1 && (
          <Paginate
            size="small"
            page={page}
            count={pageCount}
            onChange={(event, value) => {
              setPage(value);
              setSelected({});
            }}
          />
        )}
      </Footer>
      <ClearAllDialog
        handleClearAll={handleClearAll}
        isOpen={openFlagModal}
        onClose={() => {
          setOpenFlagModal(false);
        }}
      />
    </Container>
  );
};

export default HeadsUpNotifications;
