// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ICD10Code
 */
export interface ICD10Code  {
    /**
     * 
     * @type {string}
     * @memberof ICD10Code
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof ICD10Code
     */
    longDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof ICD10Code
     */
    savedCode?: string;
}

export function ICD10CodeFromJSON(json: any): ICD10Code {
    return {
        'code': !exists(json, 'code') ? undefined : json['code'],
        'longDescription': !exists(json, 'long_description') ? undefined : json['long_description'],
        'savedCode': !exists(json, 'saved_code') ? undefined : json['saved_code'],
    };
}

export function ICD10CodeToJSON(value?: ICD10Code): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'code': value.code,
        'long_description': value.longDescription,
        'saved_code': value.savedCode,
    };
}


