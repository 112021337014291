import { useSelector } from "react-redux";
import { useRequest } from "redux-query-react";

import { State } from "shared/types/state";
import { JsonUser as User } from "shared/fetch/src/models/JsonUser";;
import { selectLoggedInUser } from "shared/features/user/selectors";
import { memberEpisodeListSummaries } from "shared/api/src/apis/EpisodeListsApi";
import { EpisodeSummariesResponse } from "shared/fetch/src/models/EpisodeSummariesResponse";
import { EpisodeSummary } from "shared/fetch/src/models/EpisodeSummary";

export const useGetActiveEpisodes = (memberId?: string) => {
  const user: User | undefined = selectLoggedInUser();
  const activeEpisodes: EpisodeSummary[] | undefined = useSelector(
    (state: State) => state.entities.memberActiveEpisodes?.activeEpisodes
  );
  const userId = memberId || user?.id?.toString();

  const queryConfig =
    userId &&
    memberEpisodeListSummaries(
      { id: userId },
      {
        transform: (body: EpisodeSummariesResponse) => ({
          memberActiveEpisodes: {
            activeEpisodes: body.results || [],
            preventiveEpisode: body.preventiveEpisode,
            nextUrl: body.page !== body.last && body.nextUrl,
          },
        }),
        update: {
          memberActiveEpisodes: (prev: any, next: any) => next,
        },
        force: true,
      }
    );
  const [{ isPending }] = useRequest(queryConfig as any);

  return {
    activeEpisodes,
    isPending,
  };
};
