// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MarketingMessageOutput,
    MarketingMessageOutputFromJSON,
    MarketingMessageOutputToJSON,
} from './MarketingMessageOutput';


/**
 * 
 * @export
 * @interface MarketingMessageResponse
 */
export interface MarketingMessageResponse  {
    /**
     * 
     * @type {MarketingMessageOutput}
     * @memberof MarketingMessageResponse
     */
    results?: MarketingMessageOutput;
}

export function MarketingMessageResponseFromJSON(json: any): MarketingMessageResponse {
    return {
        'results': !exists(json, 'results') ? undefined : MarketingMessageOutputFromJSON(json['results']),
    };
}

export function MarketingMessageResponseToJSON(value?: MarketingMessageResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': MarketingMessageOutputToJSON(value.results),
    };
}


