import Modal from "./modal";
import { Browser } from "./functions";
import { printImage, printPdf } from "./print";

export enum PrintableTypesEnum {
  pdf = "pdf",
  image = "image",
}

export interface PrintJsOptions {
  /**Document source: pdf or image url */
  printable: string;
  type: PrintableTypesEnum;
  frameId?: string;
  documentTitle?: string;
  modalMessage?: string;
  showModal?: boolean;
  /** Used when printing PDF documents passed as base64 data */
  base64?: boolean;
  /**Optional header to be used with HTML, Image or JSON printing. It will be placed on the top of the page */
  header?: string;
  // headerStyle?: string | StyleSheet;
  headerStyle?: string;
  /**Max document width in pixels. Used when printing HTML, Images or JSON */
  maxWidth?: number;
  /**When printing pdf, if the browser is not compatible (check browser compatibility table), the library will open the pdf in a new tab. */
  fallbackPrintable?: string;
  imageStyle?: string;
  style?: string;
  onLoadingEnd?: () => void;
  onLoadingStart?: () => void;
  onPrintDialogClose?: () => void;
  onError?: (error: string, request?: XMLHttpRequest) => void;
  onIncompatibleBrowser?: () => void;
}

export default (params: PrintJsOptions) => {
  const defaultOptions: Partial<PrintJsOptions> = {
    maxWidth: 800,
    base64: false,
    showModal: false,
    documentTitle: "Document",
    frameId: "xo-print-iframe",
    modalMessage: "Preparing document for printing...",
  };

  // merge default options and passed options
  const options = Object.assign({}, defaultOptions, params);

  // Check if a printable document or object was supplied
  const printable = options.printable;
  if (printable === undefined) {
    throw new Error("printJS expects at least 2 attributes.");
  }

  // Process parameters
  switch (typeof printable) {
    case "string":
      // Object.defineProperty(options, 'printable',{})
      options.printable = encodeURI(options.printable);
      break;
    case "object":
      options.fallbackPrintable =
        typeof options.fallbackPrintable !== "undefined"
          ? options.fallbackPrintable
          : options.printable;
      options.fallbackPrintable = options.base64
        ? `data:application/pdf;base64,${options.fallbackPrintable}`
        : options.fallbackPrintable;
      break;
    default:
      throw new Error(
        'Unexpected argument type! Expected "string" or "object", got ' +
          typeof printable
      );
  }

  // Check if we are showing a feedback message to the user
  if (options.showModal) {
    Modal.show(options);
  }

  // Check for a print start hook function
  if (options.onLoadingStart) {
    options.onLoadingStart();
  }

  // To prevent duplication and issues, remove any used printFrame from the DOM
  const usedFrame = document.getElementById(options.frameId!);

  if (usedFrame) {
    usedFrame.parentNode?.removeChild(usedFrame);
  }

  // Create a new iframe for the print job
  const printFrame = document.createElement("iframe");

  if (Browser.isFirefox()) {
    // Set the iframe to be is visible on the page (guaranteed by fixed position) but hidden using opacity 0, because
    // this works in Firefox. The height needs to be sufficient for some part of the document other than the PDF
    // viewer's toolbar to be visible in the page
    printFrame.setAttribute(
      "style",
      "width: 1px; height: 100px; position: fixed; left: 0; top: 0; opacity: 0; border-width: 0; margin: 0; padding: 0"
    );
  } else {
    // Hide the iframe in other browsers
    printFrame.setAttribute(
      "style",
      "visibility: hidden; height: 0; width: 0; position: absolute; border: 0"
    );
  }

  // Set iframe element id
  printFrame.setAttribute("id", options.frameId!);

  // For non pdf printing, pass an html document string to srcdoc (force onload callback)
  if (options.type !== "pdf") {
    printFrame.srcdoc =
      "<html><head><title>" + options.documentTitle + "</title>";
    printFrame.srcdoc += "</head><body></body></html>";
  }

  // Check printable type
  switch (options.type) {
    case PrintableTypesEnum.pdf:
      // Check browser support for pdf and if not supported we will just open the pdf file instead
      if (Browser.isIE()) {
        try {
          const win = window.open(options.fallbackPrintable, "_blank");
          win?.focus();
          if (typeof options.onIncompatibleBrowser === "function") {
            options.onIncompatibleBrowser();
          }
        } catch (error) {
          if (typeof options.onError === "function") {
            options.onError(error as string);
          }
        } finally {
          // Make sure there is no loading modal opened
          if (options.showModal) {
            Modal.close();
          }
          if (options.onLoadingEnd) {
            options.onLoadingEnd();
          }
        }
      } else {
        printPdf(options, printFrame);
      }
      break;

    case PrintableTypesEnum.image:
      printImage(options, printFrame);
      break;

    default:
      throw new Error(
        "Invalid print type. Available types are: pdf, html, image and json."
      );
  }
};
