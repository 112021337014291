import { FunctionComponent, ReactElement } from "react";

export const SHOW_MOBILE_COMPONENT_MODAL = "SHOW_MOBILE_COMPONENT_MODAL";
export const HIDE_MOBILE_COMPONENT_MODAL = "HIDE_MOBILE_COMPONENT_MODAL";
export const RESET_MOBILE_COMPONENT_MODAL = "RESET_MOBILE_COMPONENT_MODAL";
export const UPDATE_MOBILE_COMPONENT_MODAL = "UPDATE_MOBILE_COMPONENT_MODAL";
export const SET_INVOKING_ELEMENT_REF = "SET_INVOKING_ELEMENT_REF";

export const mobileComponentModal = (
  state: State = getInitialMobileComponentModalState(),
  action: any
): State => {
  const { type } = action;
  /* eslint-disable  @typescript-eslint/no-unused-vars */
  const { componentProps, ...stateWithoutComponentProps } = state;
  switch (type) {
    case SHOW_MOBILE_COMPONENT_MODAL:
      return { ...state, ...action?.payload, open: true };

    case HIDE_MOBILE_COMPONENT_MODAL:
      return {
        open: false,
        invokingElementId: state?.invokingElementId,
      };
    case UPDATE_MOBILE_COMPONENT_MODAL:
      return {
        ...stateWithoutComponentProps,
        componentProps: { ...state.componentProps, ...action?.payload },
      };
    case SET_INVOKING_ELEMENT_REF:
      return {
        ...state,
        ...action?.payload,
      };
    case RESET_MOBILE_COMPONENT_MODAL:
      return getInitialMobileComponentModalState();
    default:
      return state;
  }
};

export const displayMobileComponentModal = (payload: IProps) => {
  return {
    type: SHOW_MOBILE_COMPONENT_MODAL,
    payload,
  };
};

export const updateMobileComponentModal = (payload: any) => {
  return {
    type: UPDATE_MOBILE_COMPONENT_MODAL,
    payload,
  };
};

export const hideMobileComponentModal = () => {
  return {
    type: HIDE_MOBILE_COMPONENT_MODAL,
  };
};

export const resetMobileComponentModal = () => {
  return {
    type: RESET_MOBILE_COMPONENT_MODAL,
  };
};

export const setInvokingElementRef = (payload: {
  invokingElementId: string;
}) => {
  return { type: SET_INVOKING_ELEMENT_REF, payload };
};

export interface State {
  open: boolean;
  fullScreen?: boolean;
  onClose?: () => void;
  title?: ReactElement | string;
  content?: ReactElement | string;
  WrapperComponent?: FunctionComponent<any>;
  componentProps?: {};
  height?: string;
  routeComponent?: boolean;
  progressBarLevel?: number;
  invokingElementId?: string;
}

export const getInitialMobileComponentModalState = (): State => {
  return {
    open: false,
    fullScreen: false,
    height: "95%",
  };
};

interface IProps {
  fullScreen?: boolean;
  onClose?: () => void;
  title?: ReactElement | string;
  content?: ReactElement | string;
  WrapperComponent?: FunctionComponent<any>;
  componentProps?: {};
  routeComponent?: boolean;
  progressBarLevel?: number;
  height?: string;
  invokingElementId?: string;
}
