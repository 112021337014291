import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    "@global": {
      html: {
        margin: 0,
        backgroundColor: palette.white,
        fontFamily: ["Agipo", "Libre Franklin", "sans-serif"].join(","),
        fontSize: "16px",
        letterSpacing: "0.25px",
      },
      body: {
        margin: 0,
        backgroundColor: palette.white,
        fontFamily: ["Agipo", "Libre Franklin", "sans-serif"].join(","),
        fontSize: "16px",
        letterSpacing: "0.25px",
        overflow: "hidden",
      },
      a: {
        color: palette.text.link,
        "&:hover": {
          color: palette.linkColorHover,
          textDecoration: "inherit",
        },
      },
    },
  })
);

const GlobalStyles = (): null => {
  useStyles();

  return null;
};

export default GlobalStyles;
