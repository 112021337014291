// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProxyHistoryOutput
 */
export interface ProxyHistoryOutput  {
    /**
     * ID of the consent document
     * @type {number}
     * @memberof ProxyHistoryOutput
     */
    documentId?: number;
    /**
     * URL
     * @type {string}
     * @memberof ProxyHistoryOutput
     */
    documentUrl?: string;
    /**
     * MIME type
     * @type {string}
     * @memberof ProxyHistoryOutput
     */
    mime?: string;
    /**
     * Type of action (proxy or revoke_proxy)
     * @type {string}
     * @memberof ProxyHistoryOutput
     */
    actionType?: ProxyHistoryOutputActionTypeEnum;
    /**
     * Date and time of signing
     * @type {Date}
     * @memberof ProxyHistoryOutput
     */
    signedOn?: Date;
    /**
     * Date and time of recording
     * @type {Date}
     * @memberof ProxyHistoryOutput
     */
    recordedAt?: Date;
    /**
     * Name of the authorizer
     * @type {string}
     * @memberof ProxyHistoryOutput
     */
    authorizer?: string;
    /**
     * Name of the minor
     * @type {string}
     * @memberof ProxyHistoryOutput
     */
    minor?: string;
}

export function ProxyHistoryOutputFromJSON(json: any): ProxyHistoryOutput {
    return {
        'documentId': !exists(json, 'document_id') ? undefined : json['document_id'],
        'documentUrl': !exists(json, 'document_url') ? undefined : json['document_url'],
        'mime': !exists(json, 'mime') ? undefined : json['mime'],
        'actionType': !exists(json, 'action_type') ? undefined : json['action_type'],
        'signedOn': !exists(json, 'signed_on') ? undefined : new Date(json['signed_on']),
        'recordedAt': !exists(json, 'recorded_at') ? undefined : new Date(json['recorded_at']),
        'authorizer': !exists(json, 'authorizer') ? undefined : json['authorizer'],
        'minor': !exists(json, 'minor') ? undefined : json['minor'],
    };
}

export function ProxyHistoryOutputToJSON(value?: ProxyHistoryOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'document_id': value.documentId,
        'document_url': value.documentUrl,
        'mime': value.mime,
        'action_type': value.actionType,
        'signed_on': value.signedOn === undefined ? undefined : value.signedOn.toISOString(),
        'recorded_at': value.recordedAt === undefined ? undefined : value.recordedAt.toISOString(),
        'authorizer': value.authorizer,
        'minor': value.minor,
    };
}

/**
* @export
* @enum {string}
*/
export enum ProxyHistoryOutputActionTypeEnum {
    Proxy = 'proxy',
    RevokeProxy = 'revoke_proxy'
}


