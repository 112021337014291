// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NetworkCareTeam,
    NetworkCareTeamFromJSON,
    NetworkCareTeamToJSON,
} from './NetworkCareTeam';
import {
    NetworkType,
    NetworkTypeFromJSON,
    NetworkTypeToJSON,
} from './NetworkType';


/**
 * 
 * @export
 * @interface NetworkCareTeamOutput
 */
export interface NetworkCareTeamOutput  {
    /**
     * 
     * @type {NetworkType}
     * @memberof NetworkCareTeamOutput
     */
    type?: NetworkType;
    /**
     * 
     * @type {Array<NetworkCareTeam>}
     * @memberof NetworkCareTeamOutput
     */
    teams?: Array<NetworkCareTeam>;
}

export function NetworkCareTeamOutputFromJSON(json: any): NetworkCareTeamOutput {
    return {
        'type': !exists(json, 'type') ? undefined : NetworkTypeFromJSON(json['type']),
        'teams': !exists(json, 'teams') ? undefined : (json['teams'] as Array<any>).map(NetworkCareTeamFromJSON),
    };
}

export function NetworkCareTeamOutputToJSON(value?: NetworkCareTeamOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'type': NetworkTypeToJSON(value.type),
        'teams': value.teams === undefined ? undefined : (value.teams as Array<any>).map(NetworkCareTeamToJSON),
    };
}


