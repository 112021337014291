import { respondToComponentByID, fetchComponent } from "shared/api/src/apis/ComponentsApi";
import { ComponentDetailsOutput } from "shared/fetch/src/models/ComponentDetailsOutput";
import { FETCH_COMPONENT_REQUEST_KEY } from "./queryConfigs/queryKeys";
import { FetchComponentRequest } from "shared/fetch/src/apis/ComponentsApi";

export const startResponseMutation = (componentId: string) => {
  return respondToComponentByID(
    {
      id: componentId,
    },
    {
      transform: (body: ComponentDetailsOutput) => ({
        component: { [componentId]: body },
      }),
      update: {
        component: (
          prev: { [key: string]: ComponentDetailsOutput },
          next: { [key: string]: ComponentDetailsOutput }
        ) => ({ ...prev, ...next }),
      },
    }
  );
};

export const fetchComponentRequest = (request: FetchComponentRequest) =>
  fetchComponent(request, {
    // TODO: there are some requests being made with empty componentId which breaks redux query keys. Appending the key fixes the issue, investigate the source of those empty requests
    queryKey: FETCH_COMPONENT_REQUEST_KEY.concat(request.id),
    transform: (body: any) => ({
      component: { [request.id]: body },
    }),
    update: {
      component: (
        prev: { [key: string]: ComponentDetailsOutput },
        next: { [key: string]: ComponentDetailsOutput }
      ) => ({ ...prev, ...next }),
    },
    force: true,
  });
