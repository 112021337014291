// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     DocumentArrayInput,
    DocumentArrayInputFromJSON,
    DocumentArrayInputToJSON,
} from './DocumentArrayInput';
import {
     EpisodeCreationInputProps,
    EpisodeCreationInputPropsFromJSON,
    EpisodeCreationInputPropsToJSON,
} from './EpisodeCreationInputProps';
/**
 * @type EpisodeCreationInput
 * @export
 */
export interface EpisodeCreationInput extends DocumentArrayInput, EpisodeCreationInputProps {
}

export function EpisodeCreationInputFromJSON(json: any): EpisodeCreationInput {
    return {
        ...DocumentArrayInputFromJSON(json),
        ...EpisodeCreationInputPropsFromJSON(json),
    };
}

export function EpisodeCreationInputToJSON(value?: EpisodeCreationInput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...DocumentArrayInputToJSON(value),
        ...EpisodeCreationInputPropsToJSON(value),
    };
}

