// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberProvidersClinics,
    MemberProvidersClinicsFromJSON,
    MemberProvidersClinicsToJSON,
} from './MemberProvidersClinics';


/**
 * 
 * @export
 * @interface MemberProvidersResults
 */
export interface MemberProvidersResults  {
    /**
     * 
     * @type {string}
     * @memberof MemberProvidersResults
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberProvidersResults
     */
    staffGlobalId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberProvidersResults
     */
    isPcp?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberProvidersResults
     */
    role?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberProvidersResults
     */
    avatarUrl?: string;
    /**
     * 
     * @type {Array<MemberProvidersClinics>}
     * @memberof MemberProvidersResults
     */
    clinics?: Array<MemberProvidersClinics>;
}

export function MemberProvidersResultsFromJSON(json: any): MemberProvidersResults {
    return {
        'name': !exists(json, 'name') ? undefined : json['name'],
        'staffGlobalId': !exists(json, 'staff_global_id') ? undefined : json['staff_global_id'],
        'isPcp': !exists(json, 'is_pcp') ? undefined : json['is_pcp'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'clinics': !exists(json, 'clinics') ? undefined : (json['clinics'] as Array<any>).map(MemberProvidersClinicsFromJSON),
    };
}

export function MemberProvidersResultsToJSON(value?: MemberProvidersResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'staff_global_id': value.staffGlobalId,
        'is_pcp': value.isPcp,
        'role': value.role,
        'avatar_url': value.avatarUrl,
        'clinics': value.clinics === undefined ? undefined : (value.clinics as Array<any>).map(MemberProvidersClinicsToJSON),
    };
}


