// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClinicProviderConfigSchemaDays
 */
export interface ClinicProviderConfigSchemaDays  {
    /**
     * 
     * @type {number}
     * @memberof ClinicProviderConfigSchemaDays
     */
    dayCode?: ClinicProviderConfigSchemaDaysDayCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof ClinicProviderConfigSchemaDays
     */
    info?: string;
}

export function ClinicProviderConfigSchemaDaysFromJSON(json: any): ClinicProviderConfigSchemaDays {
    return {
        'dayCode': !exists(json, 'day_code') ? undefined : json['day_code'],
        'info': !exists(json, 'info') ? undefined : json['info'],
    };
}

export function ClinicProviderConfigSchemaDaysToJSON(value?: ClinicProviderConfigSchemaDays): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'day_code': value.dayCode,
        'info': value.info,
    };
}

/**
* @export
* @enum {string}
*/
export enum ClinicProviderConfigSchemaDaysDayCodeEnum {
    NUMBER_MINUS_2 = -2,
    NUMBER_MINUS_1 = -1,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_6 = 6,
    NUMBER_7 = 7
}


