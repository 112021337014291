// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlotOutput,
    SlotOutputFromJSON,
    SlotOutputToJSON,
} from './SlotOutput';


/**
 * 
 * @export
 * @interface SlotDateObject
 */
export interface SlotDateObject  {
    /**
     * 
     * @type {string}
     * @memberof SlotDateObject
     */
    tzDate?: string;
    /**
     * 
     * @type {Array<SlotOutput>}
     * @memberof SlotDateObject
     */
    slots?: Array<SlotOutput>;
}

export function SlotDateObjectFromJSON(json: any): SlotDateObject {
    return {
        'tzDate': !exists(json, 'tz_date') ? undefined : json['tz_date'],
        'slots': !exists(json, 'slots') ? undefined : (json['slots'] as Array<any>).map(SlotOutputFromJSON),
    };
}

export function SlotDateObjectToJSON(value?: SlotDateObject): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'tz_date': value.tzDate,
        'slots': value.slots === undefined ? undefined : (value.slots as Array<any>).map(SlotOutputToJSON),
    };
}


