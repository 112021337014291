import { uniqBy } from "lodash";
import { State } from "..";
import { WorkspaceDraftDataSchemaCalculator } from "shared/fetch/src/models/WorkspaceDraftDataSchemaCalculator";

// Calculators
export const WS_SET_CALCULATORS = "WS_SET_CALCULATORS";
export const WS_ADD_CALCULATOR = "WS_ADD_CALCULATOR";
export const WS_REMOVE_CALCULATOR = "WS_REMOVE_CALCULATOR";
export const WS_CLEAR_CALCULATORS = "WS_CLEAR_CALCULATORS";

const updateCalculatorsState =
  ({ state, episodeId }: any) =>
  (update: any) => ({
    ...state,
    [episodeId]: [...update],
  });

const clearCalculatorState =
  ({ state, episodeId }: any) =>
  () => ({
    ...state,
    [episodeId]: null,
  });

export const calculators = (state = {} as State, action: any) => {
  const episodeId = action.episodeId;
  const updater = updateCalculatorsState({
    state,
    episodeId,
  });

  const clearAll = clearCalculatorState({
    state,
    episodeId,
  });

  if (!episodeId) {
    return state;
  }

  switch (action.type) {
    case WS_SET_CALCULATORS:
      if (!!state[episodeId]?.length) {
        return state;
      }
      return updater(action.payload);

    case WS_CLEAR_CALCULATORS:
      return clearAll();

    case WS_ADD_CALCULATOR:
      return updater(
        uniqBy([...(state?.[episodeId] || []), action.payload], "calculatorId")
      );

    case WS_REMOVE_CALCULATOR:
      const updatedState = state[episodeId]?.filter(
        (calculator: any) => calculator.calculatorId !== action.payload
      );
      return updatedState?.length > 0
        ? updater(uniqBy(updatedState, "calculatorId"))
        : clearAll();

    default:
      return state;
  }
};

export const setCalculators = (
  payload: WorkspaceDraftDataSchemaCalculator[] | null,
  episodeId: string
) => ({
  type: WS_SET_CALCULATORS,
  payload,
  episodeId,
});

export const AddCalculator = (calculator: any, episodeId: string) => ({
  type: WS_ADD_CALCULATOR,
  payload: calculator,
  episodeId,
});

export const removeCalculator = (
  calculatorId: number | string,
  episodeId: string
) => ({
  type: WS_REMOVE_CALCULATOR,
  payload: calculatorId,
  episodeId,
});

export const clearCalculators = (episodeId: string) => ({
  type: WS_CLEAR_CALCULATORS,
  episodeId,
});
