// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateOrAssignEpisodeToAnAppointmentRequestBody
 */
export interface CreateOrAssignEpisodeToAnAppointmentRequestBody  {
    /**
     * ID of the appointment
     * @type {string}
     * @memberof CreateOrAssignEpisodeToAnAppointmentRequestBody
     */
    appointmentId?: string;
    /**
     * ID of the episode being assigned the appointment
     * @type {string}
     * @memberof CreateOrAssignEpisodeToAnAppointmentRequestBody
     */
    episodeId?: string;
}

export function CreateOrAssignEpisodeToAnAppointmentRequestBodyFromJSON(json: any): CreateOrAssignEpisodeToAnAppointmentRequestBody {
    return {
        'appointmentId': !exists(json, 'appointment_id') ? undefined : json['appointment_id'],
        'episodeId': !exists(json, 'episode_id') ? undefined : json['episode_id'],
    };
}

export function CreateOrAssignEpisodeToAnAppointmentRequestBodyToJSON(value?: CreateOrAssignEpisodeToAnAppointmentRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'appointment_id': value.appointmentId,
        'episode_id': value.episodeId,
    };
}


