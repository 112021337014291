import * as React from "react";
import RouteAccess from "components/Access/RouteAccess";
import { loadLazy } from "utils/loadLazy";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";
import { Route } from "react-router";

const LazyPreventiveEpisodes = loadLazy(
  () => import("./pages/PreventiveEpisodes")
);

export default () => [
  <Route
    path="/proactive"
    key="proactive-routes"
    element={<RouteAccess to={PermissionsEnum.CtmEpisodesRead} />}
  >
    <Route index element={<LazyPreventiveEpisodes />} />,
  </Route>,
];
