import React from "react";
import { LinearProgress as MuiLinearProgress } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

const useStyle = makeStyles(() => ({
  root: (spacing: any) => ({
    width: "100%",
    marginTop: spacing.spacing,
    marginBottom: spacing.spacing,
  }),
}));

const LinearProgress = (props: any) => {
  const { spacing, ...linearProgressProps } = props;

  const classes = useStyle({ spacing });

  return (
    <div className={classes.root}>
      <MuiLinearProgress {...linearProgressProps} />
    </div>
  );
};
export default LinearProgress
