/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AvailableStaffPermissionsListResponse,
    AvailableStaffPermissionsListResponseFromJSON,
    AvailableStaffPermissionsListResponseToJSON,
} from '../models/AvailableStaffPermissionsListResponse'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    PermissionSchema,
    PermissionSchemaFromJSON,
    PermissionSchemaToJSON,
} from '../models/PermissionSchema'
import {
    RoleSchema,
    RoleSchemaFromJSON,
    RoleSchemaToJSON,
} from '../models/RoleSchema'
import {
    RolesListResponse,
    RolesListResponseFromJSON,
    RolesListResponseToJSON,
} from '../models/RolesListResponse'
import {
    StoredPermissionSchema,
    StoredPermissionSchemaFromJSON,
    StoredPermissionSchemaToJSON,
} from '../models/StoredPermissionSchema'
import {
    StoredRoleSchema,
    StoredRoleSchemaFromJSON,
    StoredRoleSchemaToJSON,
} from '../models/StoredRoleSchema'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface PostRoleRequest {
    roleSchema?: RoleSchema;
}
export interface UpdatePermissionRequest {
    name: string;
    permissionSchema?: PermissionSchema;
}
export interface UpdateRoleRequest {
    id: number;
    roleSchema?: RoleSchema;
}


export interface IPermissionsApi {
      getPermission: () => Promise<AvailableStaffPermissionsListResponse>
      getRole: () => Promise<RolesListResponse>
      postRole: (requestParameters: PostRoleRequest) => Promise<StoredRoleSchema>
      updatePermission: (requestParameters: UpdatePermissionRequest) => Promise<StoredPermissionSchema>
      updateRole: (requestParameters: UpdateRoleRequest) => Promise<StoredRoleSchema>
      getPermissionWithResponse: () => Promise<{ value: AvailableStaffPermissionsListResponse, response: any}>
      getRoleWithResponse: () => Promise<{ value: RolesListResponse, response: any}>
      postRoleWithResponse: (requestParameters: PostRoleRequest) => Promise<{ value: StoredRoleSchema, response: any}>
      updatePermissionWithResponse: (requestParameters: UpdatePermissionRequest) => Promise<{ value: StoredPermissionSchema, response: any}>
      updateRoleWithResponse: (requestParameters: UpdateRoleRequest) => Promise<{ value: StoredRoleSchema, response: any}>
}

/**
 * no description
 */
export class PermissionsApi extends runtime.BaseAPI {

    /**
     * View existing permissions
     */
    getPermissionRaw = async (): Promise<{ runtime: runtime.ApiResponse<AvailableStaffPermissionsListResponse>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/permissions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AvailableStaffPermissionsListResponseFromJSON(jsonValue)), response };
    }

    /**
     * View existing permissions
     */
    getPermission = async (): Promise<AvailableStaffPermissionsListResponse> => {
        const { runtime } = await this.getPermissionRaw();
        return await runtime.value();
    }

    /**
     * View existing permissions
     */
    getPermissionWithResponse = async (): Promise<{ value: AvailableStaffPermissionsListResponse, response: any}> => {
        const { runtime, response } = await this.getPermissionRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * View existing roles
     */
    getRoleRaw = async (): Promise<{ runtime: runtime.ApiResponse<RolesListResponse>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/roles`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => RolesListResponseFromJSON(jsonValue)), response };
    }

    /**
     * View existing roles
     */
    getRole = async (): Promise<RolesListResponse> => {
        const { runtime } = await this.getRoleRaw();
        return await runtime.value();
    }

    /**
     * View existing roles
     */
    getRoleWithResponse = async (): Promise<{ value: RolesListResponse, response: any}> => {
        const { runtime, response } = await this.getRoleRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Create a new role
     */
    postRoleRaw = async (requestParameters: PostRoleRequest): Promise<{ runtime: runtime.ApiResponse<StoredRoleSchema>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/roles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleSchemaToJSON(requestParameters.roleSchema),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => StoredRoleSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Create a new role
     */
    postRole = async (requestParameters: PostRoleRequest): Promise<StoredRoleSchema> => {
        const { runtime } = await this.postRoleRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Create a new role
     */
    postRoleWithResponse = async (requestParameters: PostRoleRequest): Promise<{ value: StoredRoleSchema, response: any}> => {
        const { runtime, response } = await this.postRoleRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update an existing permission
     */
    updatePermissionRaw = async (requestParameters: UpdatePermissionRequest): Promise<{ runtime: runtime.ApiResponse<StoredPermissionSchema>, response: any}> => {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling updatePermission.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/permissions/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PermissionSchemaToJSON(requestParameters.permissionSchema),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => StoredPermissionSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Update an existing permission
     */
    updatePermission = async (requestParameters: UpdatePermissionRequest): Promise<StoredPermissionSchema> => {
        const { runtime } = await this.updatePermissionRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update an existing permission
     */
    updatePermissionWithResponse = async (requestParameters: UpdatePermissionRequest): Promise<{ value: StoredPermissionSchema, response: any}> => {
        const { runtime, response } = await this.updatePermissionRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update an existing role
     */
    updateRoleRaw = async (requestParameters: UpdateRoleRequest): Promise<{ runtime: runtime.ApiResponse<StoredRoleSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateRole.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: RoleSchemaToJSON(requestParameters.roleSchema),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => StoredRoleSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Update an existing role
     */
    updateRole = async (requestParameters: UpdateRoleRequest): Promise<StoredRoleSchema> => {
        const { runtime } = await this.updateRoleRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update an existing role
     */
    updateRoleWithResponse = async (requestParameters: UpdateRoleRequest): Promise<{ value: StoredRoleSchema, response: any}> => {
        const { runtime, response } = await this.updateRoleRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchPermissionsApi: IPermissionsApi  = new PermissionsApi();
