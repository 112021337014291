// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompactComponentReference,
    CompactComponentReferenceFromJSON,
    CompactComponentReferenceToJSON,
} from './CompactComponentReference';


/**
 * 
 * @export
 * @interface WorkspaceDraftOutputCoreSchema
 */
export interface WorkspaceDraftOutputCoreSchema  {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDraftOutputCoreSchema
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDraftOutputCoreSchema
     */
    episodeId?: string;
    /**
     * 
     * @type {number}
     * @memberof WorkspaceDraftOutputCoreSchema
     */
    ctmId?: number;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDraftOutputCoreSchema
     */
    status?: WorkspaceDraftOutputCoreSchemaStatusEnum;
    /**
     * 
     * @type {Array<CompactComponentReference>}
     * @memberof WorkspaceDraftOutputCoreSchema
     */
    unacknowledgedComponents?: Array<CompactComponentReference>;
}

export function WorkspaceDraftOutputCoreSchemaFromJSON(json: any): WorkspaceDraftOutputCoreSchema {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'episodeId': !exists(json, 'episode_id') ? undefined : json['episode_id'],
        'ctmId': !exists(json, 'ctm_id') ? undefined : json['ctm_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'unacknowledgedComponents': !exists(json, 'unacknowledged_components') ? undefined : (json['unacknowledged_components'] as Array<any>).map(CompactComponentReferenceFromJSON),
    };
}

export function WorkspaceDraftOutputCoreSchemaToJSON(value?: WorkspaceDraftOutputCoreSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'episode_id': value.episodeId,
        'ctm_id': value.ctmId,
        'status': value.status,
        'unacknowledged_components': value.unacknowledgedComponents === undefined ? undefined : (value.unacknowledgedComponents as Array<any>).map(CompactComponentReferenceToJSON),
    };
}

/**
* @export
* @enum {string}
*/
export enum WorkspaceDraftOutputCoreSchemaStatusEnum {
    Active = 'active',
    Sent = 'sent',
    Discarded = 'discarded'
}


