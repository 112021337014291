// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum NetworkType {
    Contacts = 'contacts',
    Teams = 'teams'
}

export function NetworkTypeFromJSON(json: any): NetworkType {
    return json as NetworkType;
}

export function NetworkTypeToJSON(value?: NetworkType): any {
    return value as any;
}

