// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlotOutput,
    SlotOutputFromJSON,
    SlotOutputToJSON,
} from './SlotOutput';


/**
 * 
 * @export
 * @interface AvailableTimeslotsResponseSlots
 */
export interface AvailableTimeslotsResponseSlots  {
    /**
     * 
     * @type {Array<SlotOutput>}
     * @memberof AvailableTimeslotsResponseSlots
     */
    morning?: Array<SlotOutput>;
    /**
     * 
     * @type {Array<SlotOutput>}
     * @memberof AvailableTimeslotsResponseSlots
     */
    afternoon?: Array<SlotOutput>;
}

export function AvailableTimeslotsResponseSlotsFromJSON(json: any): AvailableTimeslotsResponseSlots {
    return {
        'morning': !exists(json, 'morning') ? undefined : (json['morning'] as Array<any>).map(SlotOutputFromJSON),
        'afternoon': !exists(json, 'afternoon') ? undefined : (json['afternoon'] as Array<any>).map(SlotOutputFromJSON),
    };
}

export function AvailableTimeslotsResponseSlotsToJSON(value?: AvailableTimeslotsResponseSlots): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'morning': value.morning === undefined ? undefined : (value.morning as Array<any>).map(SlotOutputToJSON),
        'afternoon': value.afternoon === undefined ? undefined : (value.afternoon as Array<any>).map(SlotOutputToJSON),
    };
}


