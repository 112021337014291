/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    PatientAccessGrantResponse,
    PatientAccessGrantResponseFromJSON,
    PatientAccessGrantResponseToJSON,
} from '../models/PatientAccessGrantResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UnauthorizedSessionResponse,
    UnauthorizedSessionResponseFromJSON,
    UnauthorizedSessionResponseToJSON,
} from '../models/UnauthorizedSessionResponse'


export interface AddToPatientHistoryRequest {
    id: string;
}
export interface GrantAccessToMemberRequest {
    memberId: string;
    note?: string;
}
export interface NotifyCompletionOfAccessToPatientRequest {
    xXOPatientAccessGrant: string;
    id: string;
}
export interface RequestAccessToPatientRequest {
    id: string;
    note?: string;
}


export interface IPatientAccessApi {
      addToPatientHistory: (requestParameters: AddToPatientHistoryRequest) => Promise<void>
      grantAccessToMember: (requestParameters: GrantAccessToMemberRequest) => Promise<PatientAccessGrantResponse>
      notifyCompletionOfAccessToPatient: (requestParameters: NotifyCompletionOfAccessToPatientRequest) => Promise<void>
      requestAccessToPatient: (requestParameters: RequestAccessToPatientRequest) => Promise<void>
      addToPatientHistoryWithResponse: (requestParameters: AddToPatientHistoryRequest) => Promise<{ value: void, response: any}>
      grantAccessToMemberWithResponse: (requestParameters: GrantAccessToMemberRequest) => Promise<{ value: PatientAccessGrantResponse, response: any}>
      notifyCompletionOfAccessToPatientWithResponse: (requestParameters: NotifyCompletionOfAccessToPatientRequest) => Promise<{ value: void, response: any}>
      requestAccessToPatientWithResponse: (requestParameters: RequestAccessToPatientRequest) => Promise<{ value: void, response: any}>
}

/**
 * no description
 */
export class PatientAccessApi extends runtime.BaseAPI {

    /**
     * Adds member to the patient proxy history
     * Adds member to the patient proxy history
     */
    addToPatientHistoryRaw = async (requestParameters: AddToPatientHistoryRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling addToPatientHistory.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Adds member to the patient proxy history
     * Adds member to the patient proxy history
     */
    addToPatientHistory = async (requestParameters: AddToPatientHistoryRequest): Promise<void> => {
        const { runtime } = await this.addToPatientHistoryRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Adds member to the patient proxy history
     * Adds member to the patient proxy history
     */
    addToPatientHistoryWithResponse = async (requestParameters: AddToPatientHistoryRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.addToPatientHistoryRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Grant access to a member
     */
    grantAccessToMemberRaw = async (requestParameters: GrantAccessToMemberRequest): Promise<{ runtime: runtime.ApiResponse<PatientAccessGrantResponse>, response: any}> => {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling grantAccessToMember.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.note !== undefined) {
            formParams.append('note', requestParameters.note as any);
        }


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{member_id}/access`.replace(`{${"member_id"}}`, encodeURIComponent(String(requestParameters.memberId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => PatientAccessGrantResponseFromJSON(jsonValue)), response };
    }

    /**
     * Grant access to a member
     */
    grantAccessToMember = async (requestParameters: GrantAccessToMemberRequest): Promise<PatientAccessGrantResponse> => {
        const { runtime } = await this.grantAccessToMemberRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Grant access to a member
     */
    grantAccessToMemberWithResponse = async (requestParameters: GrantAccessToMemberRequest): Promise<{ value: PatientAccessGrantResponse, response: any}> => {
        const { runtime, response } = await this.grantAccessToMemberRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Notify of completion of access to a patient
     */
    notifyCompletionOfAccessToPatientRaw = async (requestParameters: NotifyCompletionOfAccessToPatientRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.xXOPatientAccessGrant === null || requestParameters.xXOPatientAccessGrant === undefined) {
            throw new runtime.RequiredError('xXOPatientAccessGrant','Required parameter requestParameters.xXOPatientAccessGrant was null or undefined when calling notifyCompletionOfAccessToPatient.');
        }

                        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling notifyCompletionOfAccessToPatient.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.xXOPatientAccessGrant !== undefined && requestParameters.xXOPatientAccessGrant !== null) {
            headerParameters['X-XO-Patient-Access-Grant'] = String(requestParameters.xXOPatientAccessGrant);
        }

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/users/{id}/access`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Notify of completion of access to a patient
     */
    notifyCompletionOfAccessToPatient = async (requestParameters: NotifyCompletionOfAccessToPatientRequest): Promise<void> => {
        const { runtime } = await this.notifyCompletionOfAccessToPatientRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Notify of completion of access to a patient
     */
    notifyCompletionOfAccessToPatientWithResponse = async (requestParameters: NotifyCompletionOfAccessToPatientRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.notifyCompletionOfAccessToPatientRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Request access to a patient
     * Request patient access
     */
    requestAccessToPatientRaw = async (requestParameters: RequestAccessToPatientRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling requestAccessToPatient.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;
        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.note !== undefined) {
            formParams.append('note', requestParameters.note as any);
        }


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/users/{id}/access`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Request access to a patient
     * Request patient access
     */
    requestAccessToPatient = async (requestParameters: RequestAccessToPatientRequest): Promise<void> => {
        const { runtime } = await this.requestAccessToPatientRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Request access to a patient
     * Request patient access
     */
    requestAccessToPatientWithResponse = async (requestParameters: RequestAccessToPatientRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.requestAccessToPatientRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

}

export const fetchPatientAccessApi: IPatientAccessApi  = new PatientAccessApi();
