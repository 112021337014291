import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "redux-query-react";
import { setEditingDraft } from "shared/state/ui/workspace";
import {
  actionRequest,
  fetchDraftRequest,
} from "shared/state/activeEpisodes/queryConfigs";
import { getWorkspace } from "shared/state/ui/workspace/selectors";
import { getMemberId } from "shared/state/ui/member/selectors";
import { getUser } from "shared/features/user";
import { WorkspaceDraftDataSchemaBaseTypeEnum } from "shared/api/src/models/WorkspaceDraftDataSchemaBase";
import { WorkspaceDraftDataSchemaCTMMessage } from "shared/api/src/models/WorkspaceDraftDataSchemaCTMMessage";
import { WorkspaceDraftDataSchemaItem } from "shared/api/src/models/WorkspaceDraftDataSchemaItem";
import { WorkspaceDraftOutputCoreSchema } from "shared/api/src/models/WorkspaceDraftOutputCoreSchema";

const useAcknowledgeAndRespondAction = (navigate?: any) => {
  const reduxDispatch = useDispatch();
  const [{ isPending }, acknowledgeAndRespond] = useMutation(
    (action: any, body?: any) => actionRequest(action.href, action.method, body)
  );
  const [{ isPending: isFetchDraftPending }, fetchDraft] = useMutation(
    (eocId: string) => fetchDraftRequest(eocId)
  );

  const editingDraft = useSelector(getWorkspace)?.editingDraft;
  const memberId = useSelector(getMemberId);
  const user = useSelector(getUser);

  const mutation = async (action: any, body?: any) => {
    const response = await acknowledgeAndRespond(action, body);
    const updatedDraft = Object.assign({}, editingDraft) as any;
    const actionResponse = response?.entities?.actionResponse;

    if (actionResponse?.workspaceDraft && updatedDraft?.data) {
      // only message is used
      const draftMessageIndex = updatedDraft.data.findIndex(
        (component: WorkspaceDraftDataSchemaItem) =>
          component.type === WorkspaceDraftDataSchemaBaseTypeEnum.CtmMessage
      );
      const actionResponseMessageIndex =
        actionResponse?.workspaceDraft?.data.findIndex(
          (component: WorkspaceDraftDataSchemaItem) =>
            component.type === WorkspaceDraftDataSchemaBaseTypeEnum.CtmMessage
        );

      updatedDraft.data[draftMessageIndex].acknowledgeAndRespond =
        actionResponse?.workspaceDraft?.data[
          actionResponseMessageIndex
        ]?.acknowledgeAndRespond?.map((i: any) => i.toString());
      const draft = {
        ...updatedDraft,
        unacknowledgedComponents:
          actionResponse?.workspaceDraft.unacknowledgedComponents,
      };
      reduxDispatch(setEditingDraft(draft));
    } else if (action.name === "start_response") {
      navigate(
        `/members/${memberId}/conversations/${actionResponse?.episodeId}/component/${actionResponse?.id}/respond`
      );
    } else {
      if (user?.ctm) {
        fetchDraft(
          actionResponse?.episodeId ||
            (editingDraft as WorkspaceDraftOutputCoreSchema)?.episodeId
        )?.then(({ transformed }) => {
          // loop through our editingDraft, and, if the transformed draft
          // response has different acknowledgeAndRespond items than our
          // editingDraft, update the editingDraft to match
          editingDraft?.data?.forEach((item, index) => {
            if (item.type === WorkspaceDraftDataSchemaBaseTypeEnum.CtmMessage) {
              const editingCtmMessage = editingDraft?.data?.[
                index
              ] as WorkspaceDraftDataSchemaCTMMessage;
              const transformedCtmMessage = transformed?.draft?.data?.[
                index
              ] as WorkspaceDraftDataSchemaCTMMessage;
              if (editingCtmMessage && transformedCtmMessage) {
                editingCtmMessage.acknowledgeAndRespond =
                  transformedCtmMessage.acknowledgeAndRespond;
              }
            }
          });
          reduxDispatch(
            setEditingDraft({
              ...editingDraft,
              unacknowledgedComponents:
                transformed?.draft?.unacknowledgedComponents,
            })
          );
        });
      }
    }
  };

  return {
    isPending: isPending || isFetchDraftPending,
    acknowledgeAndRespondAction: mutation,
  };
};

export default useAcknowledgeAndRespondAction;
