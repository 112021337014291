// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberPaymentHistory,
    MemberPaymentHistoryFromJSON,
    MemberPaymentHistoryToJSON,
} from './MemberPaymentHistory';


/**
 * 
 * @export
 * @interface ListMemberPaymentMethods1
 */
export interface ListMemberPaymentMethods1  {
    /**
     * 
     * @type {number}
     * @memberof ListMemberPaymentMethods1
     */
    attemptCount?: number;
    /**
     * 
     * @type {Array<MemberPaymentHistory>}
     * @memberof ListMemberPaymentMethods1
     */
    results?: Array<MemberPaymentHistory>;
}

export function ListMemberPaymentMethods1FromJSON(json: any): ListMemberPaymentMethods1 {
    return {
        'attemptCount': !exists(json, 'attempt_count') ? undefined : json['attempt_count'],
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(MemberPaymentHistoryFromJSON),
    };
}

export function ListMemberPaymentMethods1ToJSON(value?: ListMemberPaymentMethods1): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'attempt_count': value.attemptCount,
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(MemberPaymentHistoryToJSON),
    };
}


