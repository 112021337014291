import { CareTeamMember } from "shared/types";

export interface IState {
  careTeam?: CareTeamMember[];
}

export const careTeam = (state: IState = {}, action: any): IState => {
  switch (action.type) {
    default:
      return state;
  }
};
