import React, { FunctionComponent } from "react";
import DialogContentTextComponent, {
  DialogContentTextProps,
} from "@mui/material/DialogContentText";

interface IProps extends DialogContentTextProps {
  component?: any;
}

const DialogContentText: FunctionComponent<IProps> = (props) => {
  return <DialogContentTextComponent {...props} />;
};

export default DialogContentText;
