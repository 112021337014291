// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalculatorComponentOutputPropsJsonBody
 */
export interface CalculatorComponentOutputPropsJsonBody  {
    /**
     * 
     * @type {object}
     * @memberof CalculatorComponentOutputPropsJsonBody
     */
    surveyJson?: object;
}

export function CalculatorComponentOutputPropsJsonBodyFromJSON(json: any): CalculatorComponentOutputPropsJsonBody {
    return {
        'surveyJson': !exists(json, 'survey_json') ? undefined : json['survey_json'],
    };
}

export function CalculatorComponentOutputPropsJsonBodyToJSON(value?: CalculatorComponentOutputPropsJsonBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'survey_json': value.surveyJson,
    };
}


