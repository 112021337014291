// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    CTMBioProfile,
    CTMBioProfileFromJSON,
    CTMBioProfileToJSON,
} from '../models/CTMBioProfile'
import {
    ContactProviderOutput,
    ContactProviderOutputFromJSON,
    ContactProviderOutputToJSON,
} from '../models/ContactProviderOutput'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
} from '../models/InlineResponse200'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    MemberNetworkOutput,
    MemberNetworkOutputFromJSON,
    MemberNetworkOutputToJSON,
} from '../models/MemberNetworkOutput'
import {
    NetworkCareTeamFiltered,
    NetworkCareTeamFilteredFromJSON,
    NetworkCareTeamFilteredToJSON,
} from '../models/NetworkCareTeamFiltered'
import {
    NetworkCareTeamsOutputFiltered,
    NetworkCareTeamsOutputFilteredFromJSON,
    NetworkCareTeamsOutputFilteredToJSON,
} from '../models/NetworkCareTeamsOutputFiltered'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface GetCareTeamRequest {
    id: string;
    xopPodId?: string;
}

export interface GetContactsRequest {
    id: string;
    xopPodId?: string;
}

export interface GetExtendedCareTeamRequest {
    id: string;
    xopPodId?: string;
}

export interface GetMemberNetworkByPodIdRequest {
    id: string;
    podId: string;
    hasProviders?: boolean;
}

export interface GetNetworkRequest {
    id: string;
    xopPodId?: string;
    networkCategory?: GetNetworkNetworkCategoryEnum;
}

export interface XopCtmProfileRequest {
    id: string;
}


/**
 * Fetch Member Care Team
 * Fetch Member Care Team
 */
function getCareTeamRaw<T>(requestParameters: GetCareTeamRequest, requestConfig: runtime.TypedQueryConfig<T, NetworkCareTeamFiltered> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCareTeam.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.xopPodId !== undefined) {
        queryParameters['xop_pod_id'] = requestParameters.xopPodId;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/my_care_team`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(NetworkCareTeamFilteredFromJSON(body), text);
    }

    return config;
}

/**
* Fetch Member Care Team
* Fetch Member Care Team
*/
export function getCareTeam<T>(requestParameters: GetCareTeamRequest, requestConfig?: runtime.TypedQueryConfig<T, NetworkCareTeamFiltered>): QueryConfig<T> {
    return getCareTeamRaw(requestParameters, requestConfig);
}

/**
 * Fetch Member Contacts
 * Fetch Member Contacts
 */
function getContactsRaw<T>(requestParameters: GetContactsRequest, requestConfig: runtime.TypedQueryConfig<T, ContactProviderOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getContacts.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.xopPodId !== undefined) {
        queryParameters['xop_pod_id'] = requestParameters.xopPodId;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/my_contacts`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ContactProviderOutputFromJSON(body), text);
    }

    return config;
}

/**
* Fetch Member Contacts
* Fetch Member Contacts
*/
export function getContacts<T>(requestParameters: GetContactsRequest, requestConfig?: runtime.TypedQueryConfig<T, ContactProviderOutput>): QueryConfig<T> {
    return getContactsRaw(requestParameters, requestConfig);
}

/**
 * Fetch Member Extended Care Team
 * Fetch Member Extended Care Team
 */
function getExtendedCareTeamRaw<T>(requestParameters: GetExtendedCareTeamRequest, requestConfig: runtime.TypedQueryConfig<T, NetworkCareTeamsOutputFiltered> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getExtendedCareTeam.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.xopPodId !== undefined) {
        queryParameters['xop_pod_id'] = requestParameters.xopPodId;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/my_extended_care_team`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(NetworkCareTeamsOutputFilteredFromJSON(body), text);
    }

    return config;
}

/**
* Fetch Member Extended Care Team
* Fetch Member Extended Care Team
*/
export function getExtendedCareTeam<T>(requestParameters: GetExtendedCareTeamRequest, requestConfig?: runtime.TypedQueryConfig<T, NetworkCareTeamsOutputFiltered>): QueryConfig<T> {
    return getExtendedCareTeamRaw(requestParameters, requestConfig);
}

/**
 * Fetch Member Network by Pod ID
 * Fetch Member Network by Pod ID
 */
function getMemberNetworkByPodIdRaw<T>(requestParameters: GetMemberNetworkByPodIdRequest, requestConfig: runtime.TypedQueryConfig<T, InlineResponse200> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMemberNetworkByPodId.');
    }

    if (requestParameters.podId === null || requestParameters.podId === undefined) {
        throw new runtime.RequiredError('podId','Required parameter requestParameters.podId was null or undefined when calling getMemberNetworkByPodId.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.hasProviders !== undefined) {
        queryParameters['has_providers'] = requestParameters.hasProviders;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/network/{pod_id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"pod_id"}}`, encodeURIComponent(String(requestParameters.podId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(InlineResponse200FromJSON(body), text);
    }

    return config;
}

/**
* Fetch Member Network by Pod ID
* Fetch Member Network by Pod ID
*/
export function getMemberNetworkByPodId<T>(requestParameters: GetMemberNetworkByPodIdRequest, requestConfig?: runtime.TypedQueryConfig<T, InlineResponse200>): QueryConfig<T> {
    return getMemberNetworkByPodIdRaw(requestParameters, requestConfig);
}

/**
 * Fetch Member Network
 * Fetch Member Network
 */
function getNetworkRaw<T>(requestParameters: GetNetworkRequest, requestConfig: runtime.TypedQueryConfig<T, MemberNetworkOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getNetwork.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.xopPodId !== undefined) {
        queryParameters['xop_pod_id'] = requestParameters.xopPodId;
    }


    if (requestParameters.networkCategory !== undefined) {
        queryParameters['network_category'] = requestParameters.networkCategory;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/network`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(MemberNetworkOutputFromJSON(body), text);
    }

    return config;
}

/**
* Fetch Member Network
* Fetch Member Network
*/
export function getNetwork<T>(requestParameters: GetNetworkRequest, requestConfig?: runtime.TypedQueryConfig<T, MemberNetworkOutput>): QueryConfig<T> {
    return getNetworkRaw(requestParameters, requestConfig);
}

/**
 * Fetch CTM profile
 */
function xopCtmProfileRaw<T>(requestParameters: XopCtmProfileRequest, requestConfig: runtime.TypedQueryConfig<T, CTMBioProfile> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling xopCtmProfile.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/xop_ctm_profiles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(CTMBioProfileFromJSON(body), text);
    }

    return config;
}

/**
* Fetch CTM profile
*/
export function xopCtmProfile<T>(requestParameters: XopCtmProfileRequest, requestConfig?: runtime.TypedQueryConfig<T, CTMBioProfile>): QueryConfig<T> {
    return xopCtmProfileRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum GetNetworkNetworkCategoryEnum {
    Contacts = 'my_contacts',
    CareTeam = 'my_care_team',
    ExtendedTeams = 'my_extended_teams'
}
