// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatientProfileInput
 */
export interface PatientProfileInput  {
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    preferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    relationship?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    genderIdentity?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    sexualOrientation?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    pronouns?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    sexAtBirth?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientProfileInput
     */
    hearing?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PatientProfileInput
     */
    vision?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    race?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    ethnicity?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    workEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    cellPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    preferredLanguage?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    zip?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    homePhone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientProfileInput
     */
    cellTexts?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    workPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    emergencyName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    emergencyPhone?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    emergencyRelationship?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    insurancePlan?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    insuranceHolder?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    medicalPlan?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    medicalPolicy?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientProfileInput
     */
    hraBalance?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientProfileInput
     */
    xopManualAdd?: boolean;
}

export function PatientProfileInputFromJSON(json: any): PatientProfileInput {
    return {
        'preferredName': !exists(json, 'preferred_name') ? undefined : json['preferred_name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'relationship': !exists(json, 'relationship') ? undefined : json['relationship'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'genderIdentity': !exists(json, 'gender_identity') ? undefined : json['gender_identity'],
        'sexualOrientation': !exists(json, 'sexual_orientation') ? undefined : json['sexual_orientation'],
        'pronouns': !exists(json, 'pronouns') ? undefined : json['pronouns'],
        'sexAtBirth': !exists(json, 'sex_at_birth') ? undefined : json['sex_at_birth'],
        'hearing': !exists(json, 'hearing') ? undefined : json['hearing'],
        'vision': !exists(json, 'vision') ? undefined : json['vision'],
        'race': !exists(json, 'race') ? undefined : json['race'],
        'ethnicity': !exists(json, 'ethnicity') ? undefined : json['ethnicity'],
        'workEmail': !exists(json, 'work_email') ? undefined : json['work_email'],
        'cellPhone': !exists(json, 'cell_phone') ? undefined : json['cell_phone'],
        'preferredLanguage': !exists(json, 'preferred_language') ? undefined : json['preferred_language'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'homePhone': !exists(json, 'home_phone') ? undefined : json['home_phone'],
        'cellTexts': !exists(json, 'cell_texts') ? undefined : json['cell_texts'],
        'workPhone': !exists(json, 'work_phone') ? undefined : json['work_phone'],
        'emergencyName': !exists(json, 'emergency_name') ? undefined : json['emergency_name'],
        'emergencyPhone': !exists(json, 'emergency_phone') ? undefined : json['emergency_phone'],
        'emergencyRelationship': !exists(json, 'emergency_relationship') ? undefined : json['emergency_relationship'],
        'insurancePlan': !exists(json, 'insurance_plan') ? undefined : json['insurance_plan'],
        'insuranceHolder': !exists(json, 'insurance_holder') ? undefined : json['insurance_holder'],
        'medicalPlan': !exists(json, 'medical_plan') ? undefined : json['medical_plan'],
        'medicalPolicy': !exists(json, 'medical_policy') ? undefined : json['medical_policy'],
        'hraBalance': !exists(json, 'hra_balance') ? undefined : json['hra_balance'],
        'xopManualAdd': !exists(json, 'xop_manual_add') ? undefined : json['xop_manual_add'],
    };
}

export function PatientProfileInputToJSON(value?: PatientProfileInput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'preferred_name': value.preferredName,
        'email': value.email,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'relationship': value.relationship,
        'gender': value.gender,
        'gender_identity': value.genderIdentity,
        'sexual_orientation': value.sexualOrientation,
        'pronouns': value.pronouns,
        'sex_at_birth': value.sexAtBirth,
        'hearing': value.hearing,
        'vision': value.vision,
        'race': value.race,
        'ethnicity': value.ethnicity,
        'work_email': value.workEmail,
        'cell_phone': value.cellPhone,
        'preferred_language': value.preferredLanguage,
        'address': value.address,
        'city': value.city,
        'state': value.state,
        'zip': value.zip,
        'home_phone': value.homePhone,
        'cell_texts': value.cellTexts,
        'work_phone': value.workPhone,
        'emergency_name': value.emergencyName,
        'emergency_phone': value.emergencyPhone,
        'emergency_relationship': value.emergencyRelationship,
        'insurance_plan': value.insurancePlan,
        'insurance_holder': value.insuranceHolder,
        'medical_plan': value.medicalPlan,
        'medical_policy': value.medicalPolicy,
        'hra_balance': value.hraBalance,
        'xop_manual_add': value.xopManualAdd,
    };
}


