import { useMutation, useQuery, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import {
  removeAcknowledgeItems,
  removeCtmDraft,
  setCtmAcknowledgeItems,
  setCtmDraft,
} from "shared/state/workspaceDraft";
import { episodeQueryKey } from "shared/features/episodes/useFetchEpisode";
import { episodeTimelineEventsQueryKey } from "shared/features/episodes/useFetchEpisodeTimelineEvents";
import { isEmpty } from "lodash";
import {
  updateQuestionSets,
  clearQuestionSets,
  setQuestionSets,
} from "../QuestionSetState";
import useAcknowledgeComponents from "shared/features/components/useAcknowledgeComponents";
import { clearCalculators, setCalculators } from "../CalculatorsState";
import { clearVisits, setVisits } from "../VisitsState";
import useAcknowledgeAndRespond from "shared/features/components/useAcknowledgeAndRespond";
import { setReplyBoxFocus } from "shared/state/ui/workspace";
import {
  WorkspaceDraftDataSchemaBaseStatusEnum,
  WorkspaceDraftDataSchemaBaseTypeEnum,
} from "shared/fetch/src/models/WorkspaceDraftDataSchemaBase";
import {
  FetchEpisodeWorkspaceDraftRequest,
  fetchWorkspaceDraftsApi,
} from "shared/fetch/src/apis/WorkspaceDraftsApi";
import { fetchEpisodesOfCareApi } from "shared/fetch/src/apis/EpisodesOfCareApi";
import {
  GetAppointmentTypesMethodEnum,
  GetAppointmentTypesSchedulingTypeEnum,
  fetchAppointmentTypesApi,
} from "shared/fetch/src/apis/AppointmentTypesApi";
import { useState } from "react";
import { QuestionSetJsonBodySchema } from "shared/api/src/models/QuestionSetJsonBodySchema";
import { WorkspaceDraftDataSchemaCalculator } from "shared/api/src/models/WorkspaceDraftDataSchemaCalculator";
import { WorkspaceDraftDataSchemaVisitReferral } from "shared/api/src/models/WorkspaceDraftDataSchemaVisitReferral";

const CTM_MESSAGE_DEFAULT_DRAFT = {
  type: WorkspaceDraftDataSchemaBaseTypeEnum.CtmMessage,
  body: "",
  status: WorkspaceDraftDataSchemaBaseStatusEnum.Compiled,
  responseRequired: false,
  acknowledgeAndRespond: [],
  documentIds: [],
};

interface ICreateMutation {
  episodeId: string;
}

interface ISubmitMutation {
  draftId: string;
}

interface IUpdateQuestionSets {
  jsonBody: QuestionSetJsonBodySchema;
  episodeId: string;
}

export const fetchEpisodeWorkspaceDraftQueryKey = (
  request: FetchEpisodeWorkspaceDraftRequest
) => ["workspaceDraft", "episode", request.id];

export const fetchAppointmentsQueryKey = (appointmentType: any) => [
  "appointments",
  appointmentType,
];

export const useWorkspaceDraftController = (
  request: FetchEpisodeWorkspaceDraftRequest
) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { acknowledgeComponents } = useAcknowledgeComponents();
  const { acknowledgeAndRespond } = useAcknowledgeAndRespond();

  const createWorkspaceDraftMutation = useMutation(
    ({ episodeId }: ICreateMutation) => {
      return fetchWorkspaceDraftsApi.createWorkspaceDraft({
        id: episodeId,
        inputWorkspaceDraftSchema: {
          data: [CTM_MESSAGE_DEFAULT_DRAFT],
        },
      });
    }
  );

  const submitWorkspaceDraftMutation = useMutation(
    ({ draftId }: ISubmitMutation) => {
      return fetchWorkspaceDraftsApi.submitWorkspaceDraft({ id: draftId });
    },
    {
      onSettled: () => {
        dispatch(removeCtmDraft(request.id));
        dispatch(clearQuestionSets(request.id));
        dispatch(clearCalculators(request.id));
        dispatch(clearVisits(request.id));
        queryClient.invalidateQueries(
          fetchEpisodeWorkspaceDraftQueryKey(request)
        );
        queryClient.invalidateQueries(episodeQueryKey(request.id));
        queryClient.invalidateQueries(
          episodeTimelineEventsQueryKey(request.id)
        );
      },
    }
  );

  const { data, isLoading } = useQuery(
    fetchEpisodeWorkspaceDraftQueryKey(request),
    () => {
      return fetchWorkspaceDraftsApi
        .fetchEpisodeWorkspaceDraft(request)
        .then((body) => {
          const calculators: WorkspaceDraftDataSchemaCalculator[] = [];
          const visits: WorkspaceDraftDataSchemaVisitReferral[] = [];
          if (typeof body?.id === "string" && body.data) {
            body.data.map((draftElement: any) => {
              if (draftElement.type === "ctm_message") {
                dispatch(setCtmDraft(draftElement, request.id));
              } else if (draftElement.type === "question_set") {
                dispatch(setQuestionSets(draftElement, request.id));
              } else if (draftElement.type === "calculator") {
                calculators.push(draftElement);
              } else if (draftElement.type.includes("visit")) {
                visits.push(draftElement);
              }
            });
            if (calculators.length > 0) {
              dispatch(setCalculators(calculators, request.id));
            }
            visits.length > 0 && dispatch(setVisits(visits, request.id));
          } else if (typeof body?.id !== "string") {
            createWorkspaceDraftMutation.mutate(
              { episodeId: request.id },
              {
                onSettled: () => {
                  queryClient.invalidateQueries(
                    fetchEpisodeWorkspaceDraftQueryKey(request)
                  );
                },
              }
            );
          }

          return body;
        });
    },
    {
      enabled: !!request.id,
    }
  );

  const updateMutation = useMutation(
    ({ workspaceDraftId, updatedData }: any) => {
      return fetchWorkspaceDraftsApi.updateWorkspaceDraft({
        id: workspaceDraftId,
        inputWorkspaceDraftSchema: { data: updatedData },
      });
    }
  );

  const updateQuestionSetMutation = useMutation(
    ({ jsonBody, episodeId }: IUpdateQuestionSets) => {
      return fetchEpisodesOfCareApi.updateQuestionsForEpisode({
        episodeId,
        updateQuestionsForEpisodeRequestBody: { jsonBody },
      });
    },
    {
      onSuccess: (response, requests) => {
        if (!isEmpty(response.jsonBody.sourceQuestionSets)) {
          dispatch(updateQuestionSets(response.jsonBody, requests.episodeId));
        } else {
          dispatch(clearQuestionSets(requests.episodeId));
        }
      },
    }
  );

  const [isAcknowledgeLoading, setIsAcknowledgeLoading] = useState(false);

  const acknowledgeSelectedComponents = (
    acknowledgeAndRespondItems: number[],
    episodeId: string
  ) => {
    setIsAcknowledgeLoading(false);
    acknowledgeComponents(
      {
        acknowledgeComponentsRequestBody: {
          componentIds: acknowledgeAndRespondItems,
        },
      },
      {
        onSuccess: (responseData, _error) => {
          const acknowledgedIds =
            responseData?.results?.map((result) => result.id) || [];

          // remove acknowledged ones rather than set remaining ones
          // IDs are primarily used in many places throughout the code
          dispatch(removeAcknowledgeItems(acknowledgedIds, episodeId!));
        },
        onSettled: () => {
          if (episodeId) {
            queryClient.invalidateQueries(
              episodeTimelineEventsQueryKey(episodeId)
            );
            queryClient.invalidateQueries(episodeQueryKey(episodeId));
            queryClient.invalidateQueries(
              fetchEpisodeWorkspaceDraftQueryKey({ id: episodeId })
            );
            setIsAcknowledgeLoading(true);
          }
        },
      }
    );
  };

  const acknowledgeAndRespondToAI = (
    acknowledgeAndRespondItems: number[],
    episodeId: string,
    callback?: () => void
  ) => {
    acknowledgeAndRespond(
      {
        acknowledgeAndRespondToComponentsRequestBody: {
          componentIds: acknowledgeAndRespondItems,
        },
      },
      {
        onSuccess: (responseData, _error) => {
          const acknowledgedIds =
            responseData?.results?.map((result) => result.id) || [];

          dispatch(setCtmAcknowledgeItems(acknowledgedIds, episodeId!));
          dispatch(setReplyBoxFocus(true));
          callback && callback();
        },
        onSettled: () => {
          if (episodeId) {
            queryClient.invalidateQueries(
              episodeTimelineEventsQueryKey(episodeId)
            );
            queryClient.invalidateQueries(episodeQueryKey(episodeId));
            queryClient.invalidateQueries(
              fetchEpisodeWorkspaceDraftQueryKey({ id: episodeId })
            );
          }
        },
      }
    );
  };

  const getAppointmentTypes = (
    memberId: string | number,
    schedulingType: GetAppointmentTypesSchedulingTypeEnum,
    method: GetAppointmentTypesMethodEnum
  ) => {
    const { data: appointmentTypes } = useQuery(
      fetchAppointmentsQueryKey(schedulingType),
      () => {
        return fetchAppointmentTypesApi
          .getAppointmentTypes({
            method,
            memberId: Number(memberId),
            schedulingType,
          })
          .then((results) => {
            if (results.appointments) {
              return results.appointments;
            } else {
              return [];
            }
          });
      },
      {
        enabled: !!memberId && !!method,
      }
    );
    return appointmentTypes;
  };

  return {
    data,
    isLoading,
    submitWorkspaceDraftMutation,
    updateMutation,
    updateQuestionSetMutation,
    acknowledgeSelectedComponents,
    isAcknowledgeLoading,
    setIsAcknowledgeLoading,
    getAppointmentTypes,
    acknowledgeAndRespondToAI,
  };
};
