// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberClinic,
    MemberClinicFromJSON,
    MemberClinicToJSON,
} from './MemberClinic';
import {
    MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';
import {
    MemberPaymentHistory,
    MemberPaymentHistoryFromJSON,
    MemberPaymentHistoryToJSON,
} from './MemberPaymentHistory';
import {
    MemberPaymentMethods,
    MemberPaymentMethodsFromJSON,
    MemberPaymentMethodsToJSON,
} from './MemberPaymentMethods';
import {
    MemberReceiptPaymentDetails,
    MemberReceiptPaymentDetailsFromJSON,
    MemberReceiptPaymentDetailsToJSON,
} from './MemberReceiptPaymentDetails';


/**
 * 
 * @export
 * @interface MemberReceipt
 */
export interface MemberReceipt  {
    /**
     * 
     * @type {Array<MemberPaymentHistory>}
     * @memberof MemberReceipt
     */
    charges?: Array<MemberPaymentHistory>;
    /**
     * 
     * @type {MemberInfo}
     * @memberof MemberReceipt
     */
    member?: MemberInfo;
    /**
     * 
     * @type {MemberPaymentMethods}
     * @memberof MemberReceipt
     */
    paymentMethod?: MemberPaymentMethods;
    /**
     * 
     * @type {MemberReceiptPaymentDetails}
     * @memberof MemberReceipt
     */
    paymentDetails?: MemberReceiptPaymentDetails;
    /**
     * 
     * @type {MemberClinic}
     * @memberof MemberReceipt
     */
    defaultClinic?: MemberClinic;
}

export function MemberReceiptFromJSON(json: any): MemberReceipt {
    return {
        'charges': !exists(json, 'charges') ? undefined : (json['charges'] as Array<any>).map(MemberPaymentHistoryFromJSON),
        'member': !exists(json, 'member') ? undefined : MemberInfoFromJSON(json['member']),
        'paymentMethod': !exists(json, 'payment_method') ? undefined : MemberPaymentMethodsFromJSON(json['payment_method']),
        'paymentDetails': !exists(json, 'payment_details') ? undefined : MemberReceiptPaymentDetailsFromJSON(json['payment_details']),
        'defaultClinic': !exists(json, 'default_clinic') ? undefined : MemberClinicFromJSON(json['default_clinic']),
    };
}

export function MemberReceiptToJSON(value?: MemberReceipt): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'charges': value.charges === undefined ? undefined : (value.charges as Array<any>).map(MemberPaymentHistoryToJSON),
        'member': MemberInfoToJSON(value.member),
        'payment_method': MemberPaymentMethodsToJSON(value.paymentMethod),
        'payment_details': MemberReceiptPaymentDetailsToJSON(value.paymentDetails),
        'default_clinic': MemberClinicToJSON(value.defaultClinic),
    };
}


