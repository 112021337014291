import * as React from "react";

const MedicalServicesIcon = (props: any) => (
  <svg
    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root css-uqopch"
    viewBox="0 0 24 24"
    aria-hidden="true"
    data-testid="MedicalServicesIcon"
    {...props}
  >
    <path d="M20 6h-4V4c0-1.1-.9-2-2-2h-4c-1.1 0-2 .9-2 2v2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zM10 4h4v2h-4V4zm6 11h-3v3h-2v-3H8v-2h3v-3h2v3h3v2z" />
  </svg>
);

export default MedicalServicesIcon;
