import palette from "../palette";
import typography from "../typography";

export default {
  root: {
    "& .MuiTab-root": {
      minWidth: "auto",
      fontSize: typography.body2.fontSize,
      marginLeft: 10,
      marginRight: 10,
    },
    "& .MuiTab-textColorPrimary:hover": {
      color: palette.darkestGreyHover,
    },
    "& .MuiTab-textColorPrimary": {
      color: palette.text.primary,
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: palette.darkestGreyHover,
    },
    "& .MuiTab-textColorSecondary:hover": {
      color: palette.secondary.main,
    },
    "& .Mui-selected": {
      fontWeight: "bold",
    },
  },
  indicator: {
    height: 4,
  },
  flexContainer: {
    height: "100%",
  },
};
