// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppointmentOutput,
    AppointmentOutputFromJSON,
    AppointmentOutputToJSON,
} from './AppointmentOutput';
import {
    SlotSeriesOutput,
    SlotSeriesOutputFromJSON,
    SlotSeriesOutputToJSON,
} from './SlotSeriesOutput';


/**
 * 
 * @export
 * @interface SlotOutput
 */
export interface SlotOutput  {
    /**
     * 
     * @type {number}
     * @memberof SlotOutput
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SlotOutput
     */
    startAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SlotOutput
     */
    endAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SlotOutput
     */
    visibility?: SlotOutputVisibilityEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SlotOutput
     */
    appointmentTypes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SlotOutput
     */
    providerId?: string;
    /**
     * 
     * @type {string}
     * @memberof SlotOutput
     */
    providerGlobalId?: string;
    /**
     * 
     * @type {string}
     * @memberof SlotOutput
     */
    clinicId?: string;
    /**
     * 
     * @type {number}
     * @memberof SlotOutput
     */
    maxPatients?: number;
    /**
     * 
     * @type {number}
     * @memberof SlotOutput
     */
    maxOverbook?: number;
    /**
     * 
     * @type {SlotSeriesOutput}
     * @memberof SlotOutput
     */
    slotSeries?: SlotSeriesOutput;
    /**
     * 
     * @type {Array<AppointmentOutput>}
     * @memberof SlotOutput
     */
    appointments?: Array<AppointmentOutput>;
    /**
     * 
     * @type {string}
     * @memberof SlotOutput
     */
    clinicTimeZone?: string;
}

export function SlotOutputFromJSON(json: any): SlotOutput {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'startAt': !exists(json, 'start_at') ? undefined : json['start_at'],
        'endAt': !exists(json, 'end_at') ? undefined : json['end_at'],
        'visibility': !exists(json, 'visibility') ? undefined : json['visibility'],
        'appointmentTypes': !exists(json, 'appointment_types') ? undefined : json['appointment_types'],
        'providerId': !exists(json, 'provider_id') ? undefined : json['provider_id'],
        'providerGlobalId': !exists(json, 'provider_global_id') ? undefined : json['provider_global_id'],
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'maxPatients': !exists(json, 'max_patients') ? undefined : json['max_patients'],
        'maxOverbook': !exists(json, 'max_overbook') ? undefined : json['max_overbook'],
        'slotSeries': !exists(json, 'slot_series') ? undefined : SlotSeriesOutputFromJSON(json['slot_series']),
        'appointments': !exists(json, 'appointments') ? undefined : (json['appointments'] as Array<any>).map(AppointmentOutputFromJSON),
        'clinicTimeZone': !exists(json, 'clinic_time_zone') ? undefined : json['clinic_time_zone'],
    };
}

export function SlotOutputToJSON(value?: SlotOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'start_at': value.startAt,
        'end_at': value.endAt,
        'visibility': value.visibility,
        'appointment_types': value.appointmentTypes,
        'provider_id': value.providerId,
        'provider_global_id': value.providerGlobalId,
        'clinic_id': value.clinicId,
        'max_patients': value.maxPatients,
        'max_overbook': value.maxOverbook,
        'slot_series': SlotSeriesOutputToJSON(value.slotSeries),
        'appointments': value.appointments === undefined ? undefined : (value.appointments as Array<any>).map(AppointmentOutputToJSON),
        'clinic_time_zone': value.clinicTimeZone,
    };
}

/**
* @export
* @enum {string}
*/
export enum SlotOutputVisibilityEnum {
    Internal = 'internal',
    Accessible = 'accessible',
    Hold = 'hold'
}


