// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DailyScheduleResponseAppointments,
    DailyScheduleResponseAppointmentsFromJSON,
    DailyScheduleResponseAppointmentsToJSON,
} from './DailyScheduleResponseAppointments';


/**
 * 
 * @export
 * @interface DailyScheduleResponseList
 */
export interface DailyScheduleResponseList  {
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseList
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseList
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponseList
     */
    avatarUrl?: string;
    /**
     * 
     * @type {Array<DailyScheduleResponseAppointments>}
     * @memberof DailyScheduleResponseList
     */
    appointments?: Array<DailyScheduleResponseAppointments>;
}

export function DailyScheduleResponseListFromJSON(json: any): DailyScheduleResponseList {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'appointments': !exists(json, 'appointments') ? undefined : (json['appointments'] as Array<any>).map(DailyScheduleResponseAppointmentsFromJSON),
    };
}

export function DailyScheduleResponseListToJSON(value?: DailyScheduleResponseList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'avatar_url': value.avatarUrl,
        'appointments': value.appointments === undefined ? undefined : (value.appointments as Array<any>).map(DailyScheduleResponseAppointmentsToJSON),
    };
}


