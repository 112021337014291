import { useDispatch } from "react-redux";
import {
  MEMBER_ACCESS_TOKEN_KEY,
  setMemberAccessToken,
  setMemberAccessUser,
} from "../auth";
import { removeToken } from "../auth/utils";

const useDeletePatientAccessToken = () => {
  const dispatch = useDispatch();

  const deletePatientAccessToken = () => {
    dispatch(
      setMemberAccessToken({
        memberAccessToken: undefined,
      })
    );
    dispatch(
      setMemberAccessUser({
        memberAccessUser: undefined,
      })
    );
    removeToken(MEMBER_ACCESS_TOKEN_KEY);
  };

  return { deletePatientAccessToken };
};

export default useDeletePatientAccessToken;
