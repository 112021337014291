// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Provider
 */
export interface Provider  {
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Provider
     */
    guilds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof Provider
     */
    isPcp?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Provider
     */
    avatarUrl?: string;
}

export function ProviderFromJSON(json: any): Provider {
    return {
        'id': json['id'],
        'name': json['name'],
        'guilds': !exists(json, 'guilds') ? undefined : json['guilds'],
        'isPcp': !exists(json, 'is_pcp') ? undefined : json['is_pcp'],
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
    };
}

export function ProviderToJSON(value?: Provider): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'guilds': value.guilds,
        'is_pcp': value.isPcp,
        'avatar_url': value.avatarUrl,
    };
}


