import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import EmptyStateComponent from "components/EmptyStateComponent";

const useStyles = makeStyles(() => ({
  container: {
    height: "24rem",
    width: "100%",
    gap: "20px",
    textAlign: "center",
  },
  text: {
    width: 255,
    height: 42,
  },
}));
const NoActiveEpisode = ({
  message,
  hasArchivedConversations,
  assetName = "CareAnyTime",
}: any) => {
  const classes = useStyles();
  const overrideClass = assetName === "NoConvosOrVisits" ? classes.text : "";
  return (
    <EmptyStateComponent
      assetName={assetName}
      message={message}
      containerOverrideStyles={classes.container}
      hasArchivedConversations={hasArchivedConversations}
      textOverrideStyles={overrideClass}
    />
  );
};

export default NoActiveEpisode;
