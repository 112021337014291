import React, { FunctionComponent } from "react";
import ContainerComponent, {
  ContainerProps,
} from "@mui/material/Container";
import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7),
      paddingBottom: theme.spacing(7),
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: "10px",
      paddingRight: "10px",
    },
  },
}));

const Container: FunctionComponent<ContainerProps> = (props) => {
  const { classes, ...rest } = props;
  const customClasses = useStyles();
  return (
    <ContainerComponent
      classes={{
        ...classes,
        ...customClasses,
      }}
      {...rest}
    />
  );
};

export default Container;
