// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';
import {
     CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
     CompactComponentReference,
    CompactComponentReferenceFromJSON,
    CompactComponentReferenceToJSON,
} from './CompactComponentReference';
import {
     ComponentActionDetails,
    ComponentActionDetailsFromJSON,
    ComponentActionDetailsToJSON,
} from './ComponentActionDetails';
import {
     ComponentCore,
    ComponentCoreFromJSON,
    ComponentCoreToJSON,
} from './ComponentCore';
import {
     ComponentDetailsCore,
    ComponentDetailsCoreFromJSON,
    ComponentDetailsCoreToJSON,
} from './ComponentDetailsCore';
import {
     ComponentDetailsCoreCreatorInfo,
    ComponentDetailsCoreCreatorInfoFromJSON,
    ComponentDetailsCoreCreatorInfoToJSON,
} from './ComponentDetailsCoreCreatorInfo';
import {
     ComponentDetailsCoreResolverInfo,
    ComponentDetailsCoreResolverInfoFromJSON,
    ComponentDetailsCoreResolverInfoToJSON,
} from './ComponentDetailsCoreResolverInfo';
import {
     DiagnosisComponentOutputProps,
    DiagnosisComponentOutputPropsFromJSON,
    DiagnosisComponentOutputPropsToJSON,
} from './DiagnosisComponentOutputProps';
import {
     DocumentObjectDetails,
    DocumentObjectDetailsFromJSON,
    DocumentObjectDetailsToJSON,
} from './DocumentObjectDetails';
import {
     DocumentOutputSchema,
    DocumentOutputSchemaFromJSON,
    DocumentOutputSchemaToJSON,
} from './DocumentOutputSchema';
import {
     MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';
import {
     OutputWorkspaceDraftSchema,
    OutputWorkspaceDraftSchemaFromJSON,
    OutputWorkspaceDraftSchemaToJSON,
} from './OutputWorkspaceDraftSchema';
import {
     UserInfo,
    UserInfoFromJSON,
    UserInfoToJSON,
} from './UserInfo';
/**
 * @type DiagnosisComponentDetailsOutput
 * @export
 */
export interface DiagnosisComponentDetailsOutput extends ComponentCore, ComponentDetailsCore, DiagnosisComponentOutputProps {
}

export function DiagnosisComponentDetailsOutputFromJSON(json: any): DiagnosisComponentDetailsOutput {
    return {
        ...ComponentCoreFromJSON(json),
        ...ComponentDetailsCoreFromJSON(json),
        ...DiagnosisComponentOutputPropsFromJSON(json),
    };
}

export function DiagnosisComponentDetailsOutputToJSON(value?: DiagnosisComponentDetailsOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...ComponentCoreToJSON(value),
        ...ComponentDetailsCoreToJSON(value),
        ...DiagnosisComponentOutputPropsToJSON(value),
    };
}

