// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecipientID
 */
export interface RecipientID  {
    /**
     * 
     * @type {string}
     * @memberof RecipientID
     */
    recipientId?: string;
}

export function RecipientIDFromJSON(json: any): RecipientID {
    return {
        'recipientId': !exists(json, 'recipient_id') ? undefined : json['recipient_id'],
    };
}

export function RecipientIDToJSON(value?: RecipientID): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'recipient_id': value.recipientId,
    };
}


