import React, { FunctionComponent } from "react";
import BadgeComponent, { BadgeProps } from "@mui/material/Badge";
import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    colorPrimary: {
      backgroundColor: _theme.palette?.primary?.main,
      border: "none",
      color: _theme.palette?.appBackground?.darkestGray,
    },
  })
);

const Badge: FunctionComponent<BadgeProps> = (props) => {
  const classes = useStyles({ props });
  return (
    <BadgeComponent classes={classes} {...props}>
      {props.children}
    </BadgeComponent>
  );
};

export default Badge;
