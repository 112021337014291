// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleOutputCoreSchema,
    RoleOutputCoreSchemaFromJSON,
    RoleOutputCoreSchemaToJSON,
} from './RoleOutputCoreSchema';


/**
 * 
 * @export
 * @interface CtmConversationSummaryAllOf
 */
export interface CtmConversationSummaryAllOf  {
    /**
     * 
     * @type {Array<string>}
     * @memberof CtmConversationSummaryAllOf
     */
    badges?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CtmConversationSummaryAllOf
     */
    activeCtmAiCount?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CtmConversationSummaryAllOf
     */
    ctmAiOwnershipBulkAssignable?: boolean;
    /**
     * 
     * @type {Array<RoleOutputCoreSchema>}
     * @memberof CtmConversationSummaryAllOf
     */
    roles?: Array<RoleOutputCoreSchema>;
    /**
     * 
     * @type {boolean}
     * @memberof CtmConversationSummaryAllOf
     */
    timeSensitive?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CtmConversationSummaryAllOf
     */
    hasInitialPsfs?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CtmConversationSummaryAllOf
     */
    hasInitialMymops?: boolean;
}

export function CtmConversationSummaryAllOfFromJSON(json: any): CtmConversationSummaryAllOf {
    return {
        'badges': !exists(json, 'badges') ? undefined : json['badges'],
        'activeCtmAiCount': !exists(json, 'active_ctm_ai_count') ? undefined : json['active_ctm_ai_count'],
        'ctmAiOwnershipBulkAssignable': !exists(json, 'ctm_ai_ownership_bulk_assignable') ? undefined : json['ctm_ai_ownership_bulk_assignable'],
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] as Array<any>).map(RoleOutputCoreSchemaFromJSON),
        'timeSensitive': !exists(json, 'time_sensitive') ? undefined : json['time_sensitive'],
        'hasInitialPsfs': !exists(json, 'has_initial_psfs') ? undefined : json['has_initial_psfs'],
        'hasInitialMymops': !exists(json, 'has_initial_mymops') ? undefined : json['has_initial_mymops'],
    };
}

export function CtmConversationSummaryAllOfToJSON(value?: CtmConversationSummaryAllOf): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'badges': value.badges,
        'active_ctm_ai_count': value.activeCtmAiCount,
        'ctm_ai_ownership_bulk_assignable': value.ctmAiOwnershipBulkAssignable,
        'roles': value.roles === undefined ? undefined : (value.roles as Array<any>).map(RoleOutputCoreSchemaToJSON),
        'time_sensitive': value.timeSensitive,
        'has_initial_psfs': value.hasInitialPsfs,
        'has_initial_mymops': value.hasInitialMymops,
    };
}


