import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { useObservable, useSubscription } from "observable-hooks";
import { filter, map, pairwise } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";

interface IProps {
  routeAction: () => void;
}

export const lastAccountPath$ = new BehaviorSubject("/");
export const lastNotificationsPath$ = new BehaviorSubject("/");
export const lastPathname$ = new BehaviorSubject("/");

const RouteChangeHandler: React.FunctionComponent<IProps> = ({
  routeAction,
}): null => {
  const location = useLocation();
  // disable back button if attempting to return to login page
  const handlePopState = () => {
    // @ts-ignore: Object is possibly 'null'.
    history.pushState(null, null, location.pathname);
  };

  const accountPathname$ = useObservable(
    (input$) =>
      input$.pipe(
        // @ts-ignore: Object is possibly 'null'.
        map((v) => v[0]),
        pairwise(),
        filter(
          ([lastPath, currentPathname]) =>
            currentPathname === "/account" && !lastPath?.includes("/account")
        ),
        map(([lastPathname, _currentPath]) => lastPathname)
      ),
    [location.pathname]
  );

  const notificationsPathname$ = useObservable(
    (input$) =>
      input$.pipe(
        // @ts-ignore: Object is possibly 'null'.
        map((v) => v[0]),
        pairwise(),
        filter(
          ([lastPath, currentPathname]) =>
            currentPathname === "/notifications" &&
            !lastPath?.includes("/notifications")
        ),
        map(([lastPathname, _currentPath]) => lastPathname)
      ),
    [location.pathname]
  );

  const otherPathname$ = useObservable(
    (input$) =>
      input$.pipe(
        // @ts-ignore: Object is possibly 'null'.
        map((v) => v[0]),
        pairwise(),
        map(([lastPath, _currentPathname]) => lastPath)
      ),
    [location.pathname]
  );

  useSubscription(accountPathname$, (pathname: string) => {
    lastAccountPath$.next(pathname);
  });

  useSubscription(notificationsPathname$, (pathname: string) => {
    lastNotificationsPath$.next(pathname);
  });

  useSubscription(otherPathname$, (pathname: string) => {
    lastPathname$.next(pathname);
  });

  const blockBackButton = () => {
    // @ts-ignore: Object is possibly 'null'.
    history.pushState(null, null, location.pathname);
    window.addEventListener("popstate", handlePopState);
  };

  useEffect(() => {
    if (location.key === "default" && location.pathname === "/") {
      blockBackButton();
    }
    routeAction();

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  });

  return null;
};

export default RouteChangeHandler;
