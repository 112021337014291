// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VisitType
 */
export interface VisitType  {
    /**
     * 
     * @type {number}
     * @memberof VisitType
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VisitType
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof VisitType
     */
    method?: VisitTypeMethodEnum;
    /**
     * 
     * @type {number}
     * @memberof VisitType
     */
    duration?: number;
}

export function VisitTypeFromJSON(json: any): VisitType {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'method': !exists(json, 'method') ? undefined : json['method'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
    };
}

export function VisitTypeToJSON(value?: VisitType): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'method': value.method,
        'duration': value.duration,
    };
}

/**
* @export
* @enum {string}
*/
export enum VisitTypeMethodEnum {
    Video = 'video',
    Phone = 'phone',
    InPerson = 'in_person'
}


