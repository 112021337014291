export function renameKeys(obj: any, newKeys: any) {
  const newObject = { ...obj };

  for (const [oldKey, newKey] of Object.entries<string>(newKeys)) {
    if (Object.hasOwnProperty.call(obj, oldKey)) {
      const value = obj[oldKey];
      delete newObject[oldKey];
      newObject[newKey] = value;
    }
  }

  return newObject;
}
