import React from "react";
import Box from "components/Box";
import Skeleton from "components/Skeleton";
import Grid from "components/Grid";
import Divider from "components/Divider";

interface IProps {
  multiple: boolean;
}

const SelectAiOwnerSkeleton: React.FC<IProps> = ({ multiple }) => (
  <div style={{ width: 400, height: multiple ? 347 : 284 }}>
    <Box pt={5} pl={2.3}>
      <div style={{ marginBottom: 21 }}>
        <Skeleton width={176} height={21} style={{ transform: "none" }} />
      </div>
      {multiple && (
        <div style={{ marginBottom: 8 }}>
          <Skeleton width={325} height={52} style={{ transform: "none" }} />
        </div>
      )}
      <div style={{ marginBottom: 16 }}>
        <Skeleton width={49} height={16} style={{ transform: "none" }} />
      </div>
      <div style={{ marginBottom: 34 }}>
        <Skeleton width={325} height={52} style={{ transform: "none" }} />
      </div>
    </Box>
    <Divider style={{ width: 400 }} />
    <Box pt={2} pl={2.3}>
      <Grid container style={{ height: 32, alignItems: "center" }}>
        <Grid item style={{ paddingRight: 8 }}>
          <Skeleton width={78} height={32} style={{ transform: "none" }} />
        </Grid>
        <Grid item>
          <Skeleton width={44} height={20} style={{ transform: "none" }} />
        </Grid>
      </Grid>
    </Box>
  </div>
);

export default SelectAiOwnerSkeleton;
