// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContactProviderAllOf
 */
export interface ContactProviderAllOf  {
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactProviderAllOf
     */
    careTeams?: Array<string>;
}

export function ContactProviderAllOfFromJSON(json: any): ContactProviderAllOf {
    return {
        'careTeams': !exists(json, 'care_teams') ? undefined : json['care_teams'],
    };
}

export function ContactProviderAllOfToJSON(value?: ContactProviderAllOf): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'care_teams': value.careTeams,
    };
}


