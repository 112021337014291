/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ElationChartInfo,
    ElationChartInfoFromJSON,
    ElationChartInfoToJSON,
} from '../models/ElationChartInfo'
import {
    FetchPCPsRelatedToSelectedCenter,
    FetchPCPsRelatedToSelectedCenterFromJSON,
    FetchPCPsRelatedToSelectedCenterToJSON,
} from '../models/FetchPCPsRelatedToSelectedCenter'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    ListCalculatorHistoryResponse,
    ListCalculatorHistoryResponseFromJSON,
    ListCalculatorHistoryResponseToJSON,
} from '../models/ListCalculatorHistoryResponse'
import {
    MemberAccountInfoOutput,
    MemberAccountInfoOutputFromJSON,
    MemberAccountInfoOutputToJSON,
} from '../models/MemberAccountInfoOutput'
import {
    MemberAccountInfoRequestBody,
    MemberAccountInfoRequestBodyFromJSON,
    MemberAccountInfoRequestBodyToJSON,
} from '../models/MemberAccountInfoRequestBody'
import {
    MemberCareTeamOutput,
    MemberCareTeamOutputFromJSON,
    MemberCareTeamOutputToJSON,
} from '../models/MemberCareTeamOutput'
import {
    MemberEHRUpdateBody,
    MemberEHRUpdateBodyFromJSON,
    MemberEHRUpdateBodyToJSON,
} from '../models/MemberEHRUpdateBody'
import {
    MemberServicesAndCentersObject,
    MemberServicesAndCentersObjectFromJSON,
    MemberServicesAndCentersObjectToJSON,
} from '../models/MemberServicesAndCentersObject'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpdateCenterAndPcpRequestBody,
    UpdateCenterAndPcpRequestBodyFromJSON,
    UpdateCenterAndPcpRequestBodyToJSON,
} from '../models/UpdateCenterAndPcpRequestBody'


export interface FetchMemberAccessibleTeamsRequest {
    id: string;
    pageSize?: string;
    page?: string;
    podId?: string;
    serviceLineName?: string;
    coreCareTeam?: boolean;
}
export interface FetchMemberCalculatorHistoryRequest {
    memberId: string;
    calculatorId: string;
}
export interface FetchMemberEHRChartRequest {
    ehrId: string;
}
export interface MemberAccountInfoRequest {
    id: string;
}
export interface MemberCareTeamRequest {
    id: string;
}
export interface PcpsRequest {
    memberId: string;
    selectedPodId: string;
}
export interface ProcessMemberCCDARequest {
    id: string;
}
export interface UpdateCenterAndPcpRequest {
    id: string;
    updateCenterAndPcpRequestBody: UpdateCenterAndPcpRequestBody;
}
export interface UpdateMemberAccountInfoRequest {
    id: string;
    memberAccountInfoRequestBody?: MemberAccountInfoRequestBody;
}
export interface UpdateMemberEHRIdRequest {
    id: string;
    memberEHRUpdateBody?: MemberEHRUpdateBody;
}


export interface IMemberAccountInfoApi {
      fetchMemberAccessibleTeams: (requestParameters: FetchMemberAccessibleTeamsRequest) => Promise<MemberServicesAndCentersObject>
      fetchMemberCalculatorHistory: (requestParameters: FetchMemberCalculatorHistoryRequest) => Promise<ListCalculatorHistoryResponse>
      fetchMemberEHRChart: (requestParameters: FetchMemberEHRChartRequest) => Promise<ElationChartInfo>
      memberAccountInfo: (requestParameters: MemberAccountInfoRequest) => Promise<MemberAccountInfoOutput>
      memberCareTeam: (requestParameters: MemberCareTeamRequest) => Promise<MemberCareTeamOutput>
      pcps: (requestParameters: PcpsRequest) => Promise<FetchPCPsRelatedToSelectedCenter>
      processMemberCCDA: (requestParameters: ProcessMemberCCDARequest) => Promise<void>
      updateCenterAndPcp: (requestParameters: UpdateCenterAndPcpRequest) => Promise<MemberAccountInfoOutput>
      updateMemberAccountInfo: (requestParameters: UpdateMemberAccountInfoRequest) => Promise<MemberAccountInfoOutput>
      updateMemberEHRId: (requestParameters: UpdateMemberEHRIdRequest) => Promise<void>
      fetchMemberAccessibleTeamsWithResponse: (requestParameters: FetchMemberAccessibleTeamsRequest) => Promise<{ value: MemberServicesAndCentersObject, response: any}>
      fetchMemberCalculatorHistoryWithResponse: (requestParameters: FetchMemberCalculatorHistoryRequest) => Promise<{ value: ListCalculatorHistoryResponse, response: any}>
      fetchMemberEHRChartWithResponse: (requestParameters: FetchMemberEHRChartRequest) => Promise<{ value: ElationChartInfo, response: any}>
      memberAccountInfoWithResponse: (requestParameters: MemberAccountInfoRequest) => Promise<{ value: MemberAccountInfoOutput, response: any}>
      memberCareTeamWithResponse: (requestParameters: MemberCareTeamRequest) => Promise<{ value: MemberCareTeamOutput, response: any}>
      pcpsWithResponse: (requestParameters: PcpsRequest) => Promise<{ value: FetchPCPsRelatedToSelectedCenter, response: any}>
      processMemberCCDAWithResponse: (requestParameters: ProcessMemberCCDARequest) => Promise<{ value: void, response: any}>
      updateCenterAndPcpWithResponse: (requestParameters: UpdateCenterAndPcpRequest) => Promise<{ value: MemberAccountInfoOutput, response: any}>
      updateMemberAccountInfoWithResponse: (requestParameters: UpdateMemberAccountInfoRequest) => Promise<{ value: MemberAccountInfoOutput, response: any}>
      updateMemberEHRIdWithResponse: (requestParameters: UpdateMemberEHRIdRequest) => Promise<{ value: void, response: any}>
}

/**
 * no description
 */
export class MemberAccountInfoApi extends runtime.BaseAPI {

    /**
     * Fetch a Members Accessible centers, services and provider groups
     * Fetch member accessible centers, services and provider groups
     */
    fetchMemberAccessibleTeamsRaw = async (requestParameters: FetchMemberAccessibleTeamsRequest): Promise<{ runtime: runtime.ApiResponse<MemberServicesAndCentersObject>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchMemberAccessibleTeams.');
        }

                                                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.podId !== undefined) {
            queryParameters['pod_id'] = requestParameters.podId;
        }

        if (requestParameters.serviceLineName !== undefined) {
            queryParameters['service_line_name'] = requestParameters.serviceLineName;
        }

        if (requestParameters.coreCareTeam !== undefined) {
            queryParameters['core_care_team'] = requestParameters.coreCareTeam;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/member_teams`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberServicesAndCentersObjectFromJSON(jsonValue)), response };
    }

    /**
     * Fetch a Members Accessible centers, services and provider groups
     * Fetch member accessible centers, services and provider groups
     */
    fetchMemberAccessibleTeams = async (requestParameters: FetchMemberAccessibleTeamsRequest): Promise<MemberServicesAndCentersObject> => {
        const { runtime } = await this.fetchMemberAccessibleTeamsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch a Members Accessible centers, services and provider groups
     * Fetch member accessible centers, services and provider groups
     */
    fetchMemberAccessibleTeamsWithResponse = async (requestParameters: FetchMemberAccessibleTeamsRequest): Promise<{ value: MemberServicesAndCentersObject, response: any}> => {
        const { runtime, response } = await this.fetchMemberAccessibleTeamsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get member\'s calculator history
     * Get member\'s calculator history
     */
    fetchMemberCalculatorHistoryRaw = async (requestParameters: FetchMemberCalculatorHistoryRequest): Promise<{ runtime: runtime.ApiResponse<ListCalculatorHistoryResponse>, response: any}> => {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling fetchMemberCalculatorHistory.');
        }

                        if (requestParameters.calculatorId === null || requestParameters.calculatorId === undefined) {
            throw new runtime.RequiredError('calculatorId','Required parameter requestParameters.calculatorId was null or undefined when calling fetchMemberCalculatorHistory.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{member_id}/calculators/{calculator_id}/history`.replace(`{${"member_id"}}`, encodeURIComponent(String(requestParameters.memberId))).replace(`{${"calculator_id"}}`, encodeURIComponent(String(requestParameters.calculatorId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ListCalculatorHistoryResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get member\'s calculator history
     * Get member\'s calculator history
     */
    fetchMemberCalculatorHistory = async (requestParameters: FetchMemberCalculatorHistoryRequest): Promise<ListCalculatorHistoryResponse> => {
        const { runtime } = await this.fetchMemberCalculatorHistoryRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get member\'s calculator history
     * Get member\'s calculator history
     */
    fetchMemberCalculatorHistoryWithResponse = async (requestParameters: FetchMemberCalculatorHistoryRequest): Promise<{ value: ListCalculatorHistoryResponse, response: any}> => {
        const { runtime, response } = await this.fetchMemberCalculatorHistoryRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetches member\'s ehr information
     * Fetch Member EHR information
     */
    fetchMemberEHRChartRaw = async (requestParameters: FetchMemberEHRChartRequest): Promise<{ runtime: runtime.ApiResponse<ElationChartInfo>, response: any}> => {
        if (requestParameters.ehrId === null || requestParameters.ehrId === undefined) {
            throw new runtime.RequiredError('ehrId','Required parameter requestParameters.ehrId was null or undefined when calling fetchMemberEHRChart.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/member/ehr/{ehr_id}`.replace(`{${"ehr_id"}}`, encodeURIComponent(String(requestParameters.ehrId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ElationChartInfoFromJSON(jsonValue)), response };
    }

    /**
     * Fetches member\'s ehr information
     * Fetch Member EHR information
     */
    fetchMemberEHRChart = async (requestParameters: FetchMemberEHRChartRequest): Promise<ElationChartInfo> => {
        const { runtime } = await this.fetchMemberEHRChartRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetches member\'s ehr information
     * Fetch Member EHR information
     */
    fetchMemberEHRChartWithResponse = async (requestParameters: FetchMemberEHRChartRequest): Promise<{ value: ElationChartInfo, response: any}> => {
        const { runtime, response } = await this.fetchMemberEHRChartRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch member account information
     */
    memberAccountInfoRaw = async (requestParameters: MemberAccountInfoRequest): Promise<{ runtime: runtime.ApiResponse<MemberAccountInfoOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling memberAccountInfo.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberAccountInfoOutputFromJSON(jsonValue)), response };
    }

    /**
     * Fetch member account information
     */
    memberAccountInfo = async (requestParameters: MemberAccountInfoRequest): Promise<MemberAccountInfoOutput> => {
        const { runtime } = await this.memberAccountInfoRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch member account information
     */
    memberAccountInfoWithResponse = async (requestParameters: MemberAccountInfoRequest): Promise<{ value: MemberAccountInfoOutput, response: any}> => {
        const { runtime, response } = await this.memberAccountInfoRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch member care team information
     */
    memberCareTeamRaw = async (requestParameters: MemberCareTeamRequest): Promise<{ runtime: runtime.ApiResponse<MemberCareTeamOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling memberCareTeam.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/care_team`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberCareTeamOutputFromJSON(jsonValue)), response };
    }

    /**
     * Fetch member care team information
     */
    memberCareTeam = async (requestParameters: MemberCareTeamRequest): Promise<MemberCareTeamOutput> => {
        const { runtime } = await this.memberCareTeamRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch member care team information
     */
    memberCareTeamWithResponse = async (requestParameters: MemberCareTeamRequest): Promise<{ value: MemberCareTeamOutput, response: any}> => {
        const { runtime, response } = await this.memberCareTeamRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch PCPs
     * Fetch PCPs
     */
    pcpsRaw = async (requestParameters: PcpsRequest): Promise<{ runtime: runtime.ApiResponse<FetchPCPsRelatedToSelectedCenter>, response: any}> => {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling pcps.');
        }

                        if (requestParameters.selectedPodId === null || requestParameters.selectedPodId === undefined) {
            throw new runtime.RequiredError('selectedPodId','Required parameter requestParameters.selectedPodId was null or undefined when calling pcps.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{member_id}/selected_pod/{selected_pod_id}/pcps`.replace(`{${"member_id"}}`, encodeURIComponent(String(requestParameters.memberId))).replace(`{${"selected_pod_id"}}`, encodeURIComponent(String(requestParameters.selectedPodId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => FetchPCPsRelatedToSelectedCenterFromJSON(jsonValue)), response };
    }

    /**
     * Fetch PCPs
     * Fetch PCPs
     */
    pcps = async (requestParameters: PcpsRequest): Promise<FetchPCPsRelatedToSelectedCenter> => {
        const { runtime } = await this.pcpsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch PCPs
     * Fetch PCPs
     */
    pcpsWithResponse = async (requestParameters: PcpsRequest): Promise<{ value: FetchPCPsRelatedToSelectedCenter, response: any}> => {
        const { runtime, response } = await this.pcpsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch member ccda from elation, process it to a pdf and upload it to members documents
     */
    processMemberCCDARaw = async (requestParameters: ProcessMemberCCDARequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling processMemberCCDA.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/process_ccda`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Fetch member ccda from elation, process it to a pdf and upload it to members documents
     */
    processMemberCCDA = async (requestParameters: ProcessMemberCCDARequest): Promise<void> => {
        const { runtime } = await this.processMemberCCDARaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch member ccda from elation, process it to a pdf and upload it to members documents
     */
    processMemberCCDAWithResponse = async (requestParameters: ProcessMemberCCDARequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.processMemberCCDARaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Allow members in XOP to update core pod and PCP
     */
    updateCenterAndPcpRaw = async (requestParameters: UpdateCenterAndPcpRequest): Promise<{ runtime: runtime.ApiResponse<MemberAccountInfoOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateCenterAndPcp.');
        }

                        if (requestParameters.updateCenterAndPcpRequestBody === null || requestParameters.updateCenterAndPcpRequestBody === undefined) {
            throw new runtime.RequiredError('updateCenterAndPcpRequestBody','Required parameter requestParameters.updateCenterAndPcpRequestBody was null or undefined when calling updateCenterAndPcp.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/update_center_and_pcp`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCenterAndPcpRequestBodyToJSON(requestParameters.updateCenterAndPcpRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberAccountInfoOutputFromJSON(jsonValue)), response };
    }

    /**
     * Allow members in XOP to update core pod and PCP
     */
    updateCenterAndPcp = async (requestParameters: UpdateCenterAndPcpRequest): Promise<MemberAccountInfoOutput> => {
        const { runtime } = await this.updateCenterAndPcpRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Allow members in XOP to update core pod and PCP
     */
    updateCenterAndPcpWithResponse = async (requestParameters: UpdateCenterAndPcpRequest): Promise<{ value: MemberAccountInfoOutput, response: any}> => {
        const { runtime, response } = await this.updateCenterAndPcpRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update the member\'s account information
     * Update the member\'s account information
     */
    updateMemberAccountInfoRaw = async (requestParameters: UpdateMemberAccountInfoRequest): Promise<{ runtime: runtime.ApiResponse<MemberAccountInfoOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMemberAccountInfo.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MemberAccountInfoRequestBodyToJSON(requestParameters.memberAccountInfoRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberAccountInfoOutputFromJSON(jsonValue)), response };
    }

    /**
     * Update the member\'s account information
     * Update the member\'s account information
     */
    updateMemberAccountInfo = async (requestParameters: UpdateMemberAccountInfoRequest): Promise<MemberAccountInfoOutput> => {
        const { runtime } = await this.updateMemberAccountInfoRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update the member\'s account information
     * Update the member\'s account information
     */
    updateMemberAccountInfoWithResponse = async (requestParameters: UpdateMemberAccountInfoRequest): Promise<{ value: MemberAccountInfoOutput, response: any}> => {
        const { runtime, response } = await this.updateMemberAccountInfoRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update member\'s ehr id
     */
    updateMemberEHRIdRaw = async (requestParameters: UpdateMemberEHRIdRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMemberEHRId.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/ehr`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: MemberEHRUpdateBodyToJSON(requestParameters.memberEHRUpdateBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Update member\'s ehr id
     */
    updateMemberEHRId = async (requestParameters: UpdateMemberEHRIdRequest): Promise<void> => {
        const { runtime } = await this.updateMemberEHRIdRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update member\'s ehr id
     */
    updateMemberEHRIdWithResponse = async (requestParameters: UpdateMemberEHRIdRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.updateMemberEHRIdRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

}

export const fetchMemberAccountInfoApi: IMemberAccountInfoApi  = new MemberAccountInfoApi();
