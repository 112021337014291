// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     ContactProviderAllOf,
    ContactProviderAllOfFromJSON,
    ContactProviderAllOfToJSON,
} from './ContactProviderAllOf';
import {
     Provider,
    ProviderFromJSON,
    ProviderToJSON,
} from './Provider';
/**
 * @type ContactProvider
 * @export
 */
export interface ContactProvider extends ContactProviderAllOf, Provider {
}

export function ContactProviderFromJSON(json: any): ContactProvider {
    return {
        ...ContactProviderAllOfFromJSON(json),
        ...ProviderFromJSON(json),
    };
}

export function ContactProviderToJSON(value?: ContactProvider): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...ContactProviderAllOfToJSON(value),
        ...ProviderToJSON(value),
    };
}

