import React, { useEffect, useState } from "react";
import Dialog from "components/Dialog";
import DialogActions from "components/Dialog/DialogActions";
import Button from "components/Button";
import DialogContent from "components/Dialog/DialogContent";
import DialogTitle from "components/Dialog/DialogTitle";
import classes from "./index.module.css";
import Paper from "components/Paper";
import { PaperProps } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CssTypography from "components/CssTypography";
import TextField from "components/TextField";
import parseDoB from "shared/utils/parseDoB";
import useFormatDate from "shared/utils/useFormatDate";
import CardSection from "components/Card/CardSection";
import useActivatePatientAccount from "shared/features/accounts/useActivatePatientAccount";
import { useNavigate } from "react-router";
import Checkbox from "components/Checkbox";
import Tooltip from "components/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import ButtonBase from "components/Button/ButtonBase";
import PrimaryAccountHolderAddressSection from "./PrimaryAccountHolderAddressSection";
import { getPrimaryAccountHolderAddress } from "features/memberSearch/utils";
import {
  memberSearchResultsQueryKey,
  patientQuickListForStaffUserQueryKey,
} from "shared/features/memberSearch/useMemberSearchResults";
import { useQueryClient } from "react-query";
import { selectLoggedInUser } from "shared/features/user/selectors";
import { calculateAge } from "utils/calculateAge";
import { OutputMemberSearchSchemaProps } from "shared/fetch/src/models/OutputMemberSearchSchemaProps";
import { ListMembersRequest } from "shared/fetch/src/apis/MemberSearchResultsApi";

function PaperComponent(props: PaperProps) {
  return <Paper {...props} classes={{ root: classes.paper }} />;
}

interface IProps {
  showProxyOption: boolean;
  open: boolean;
  closeModal: () => void;
  member: OutputMemberSearchSchemaProps;
  openProxyModal?: () => void;
  searchRequest?: ListMembersRequest;
}

const ActivateAccountModal: React.FC<IProps> = ({
  open,
  closeModal,
  member,
  openProxyModal,
  searchRequest,
}) => {
  // The first design for this modal called for a showing an option to proxy/nest at the same time
  // a teen user is green plussed. Dev is being asked to change that flow so that teens (age 12-17) are
  // shown the same modal as adult users. We previously used the showProxyOption prop to determine whether to
  // display the teen version of the modal, so something like
  // const isTeenModal = showProxyOption
  // I am not convinced product will not in the future ask for the teen version of the modal again, so I'm retaining
  // the prop in the interface, but for the time being isTeenModal will be harcoded to false
  const isTeenModal = false;
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  // @ts-ignore
  const ageInYears = calculateAge(new Date(member?.dob));
  const isChild = member?.minor && ageInYears < 12;
  const isTeen = member?.minor && ageInYears > 11 && ageInYears < 18;

  const [isSubmittingProxyRequest, setIsSubmittingProxyRequest] =
    useState(false);
  const formatDate = useFormatDate();
  // @ts-ignore: Object is possibly 'null'.
  const parsedDoB = parseDoB(member?.dob);
  const memberDoB = formatDate(parsedDoB, "MM/dd/yyyy");
  const { mutateAsync: activatePatientAccount } = useActivatePatientAccount(
    isChild ? member?.firstName : undefined
  );
  const queryClient = useQueryClient();
  const user = selectLoggedInUser();

  const navigate = useNavigate();

  useEffect(() => {
    if (member && member.minor) {
      setIsSubmitDisabled(false);
    }
  }, [member]);

  useEffect(() => {
    if (open === true) {
      setEmail(undefined);
    }
  }, [open]);

  const activationCaption = isChild
    ? `When a minor's account is activated, the primary account holder will have
    proxy access. Confirm Member information matches before activating the account.`
    : `In order to activate an account, a create password email will be
    sent to the Member. Confirm Member information matches before
    activating the account.`;

  const onConfirm = async () => {
    activatePatientAccount({
      greenPlusPatientRequestBody: {
        baseId: member.id!,
        // @ts-ignore: Object is possibly 'null'.
        email,
      },
    })
      .then((response) => {
        setEmail(undefined);
        closeModal();
        if (searchRequest) {
          queryClient.invalidateQueries(
            memberSearchResultsQueryKey(searchRequest)
          );
        }
        if (user?.id) {
          queryClient.invalidateQueries(
            patientQuickListForStaffUserQueryKey(user?.id)
          );
        }
        if (isTeenModal && isSubmittingProxyRequest && openProxyModal) {
          openProxyModal();
          setIsSubmittingProxyRequest(false);
        } else {
          if (!member?.minor) {
            navigate(`/members/${response.id}/demographics`);
          }
        }
      })
      .catch(() => {
        setEmail("");
        setIsSubmittingProxyRequest(false);
        closeModal();
      });
  };

  const onClose = () => {
    closeModal();
    setEmail("");
    setIsSubmittingProxyRequest(false);
  };

  const handleEmail = (event: any) => {
    setEmail(event.target.value);
    if (event?.target?.value?.length > 0 || isTeen) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  };

  const handleProxyCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsSubmittingProxyRequest(!isSubmittingProxyRequest);
    setIsSubmitDisabled(!e.target.checked);
  };

  const tooltipCopy =
    "Submitting a proxy consent will create a nested account for the teen. The primary account holder will have access to the teen’s health records, and the teen will not have access to their own account.";
  const helperText =
    "This address will only be used for notification emails from Crossover. It is recommended to use member personal email address. No health data will be sent via email.";

  return (
    <>
      <Dialog
        open={open}
        PaperComponent={PaperComponent}
        ariaLabelledBy="alert-dialog-title"
        onClose={onClose}
      >
        <CloseIcon className={classes.closeIcon} onClick={onClose} />
        <DialogTitle
          id="alert-dialog-title"
          data-e2e="activate-account-modal"
          className={classes.titleContainer}
        >
          <CssTypography className={classes.title}>
            {isTeenModal
              ? "Activate account and submit proxy"
              : `Activate account ${!isChild ? "and create password" : ""}`}
          </CssTypography>
        </DialogTitle>
        <CardSection bottomDivider style={{ padding: 0 }}>
          <DialogContent className={classes.dialogContent}>
            <div>
              <CssTypography
                appearance="smallBody"
                className={classes.subtitle}
              >
                {isTeenModal
                  ? `To activate a standalone account, a create password email will be sent
                  to the teen. Confirm Member information matches before activating the account.`
                  : activationCaption}
              </CssTypography>
            </div>
            <div className={classes.memberInfoBorder}>
              <div className={classes.memberInfo}>
                <div>
                  {member?.firstName} {member?.lastName}
                </div>
                <div>{memberDoB}</div>
                <div>{member?.employer}</div>
              </div>
            </div>
            {isChild ? (
              <PrimaryAccountHolderAddressSection
                primaryAccountHolderAddress={getPrimaryAccountHolderAddress(
                  member?.primaryAccountHolder
                )}
              />
            ) : (
              <>
                {isTeenModal && (
                  <>
                    <div className={classes.proxyOption}>
                      <Checkbox
                        color="secondary"
                        checked={isSubmittingProxyRequest}
                        className={classes.checkbox}
                        onChange={handleProxyCheckbox}
                        inputProps={{
                          "aria-label": "I'm also submitting a proxy approval",
                        }}
                        data-testid="proxy-request-checkbox"
                      />
                      <CssTypography appearance="body">
                        I'm also submitting a proxy approval
                      </CssTypography>
                      <Tooltip
                        title={tooltipCopy}
                        appearance="light"
                        placement="right-start"
                      >
                        <ButtonBase>
                          <InfoIcon className={classes.tooltip} />
                        </ButtonBase>
                      </Tooltip>
                    </div>
                    <CssTypography
                      appearance="smallBody"
                      className={classes.checkboxText}
                    >
                      Once a proxy approval is submitted, the teen will not have
                      separate access to their own account.
                    </CssTypography>
                  </>
                )}
                {!isSubmittingProxyRequest && (
                  <>
                    <div className={classes.email}>
                      <TextField
                        variant="filled"
                        placeholder={isTeenModal ? "Teen's Email" : "Email"}
                        id="activate-account-email"
                        fullWidth
                        margin="dense"
                        onChange={handleEmail}
                        value={email}
                        inputProps={{
                          "aria-label": "Email Address Input",
                        }}
                        InputProps={{
                          // ariaDescribedby: "email-input-helper-text",
                          style: {
                            paddingBottom: "8px",
                          },
                        }}
                      ></TextField>
                    </div>
                    <div className={classes.helperText}>
                      <CssTypography
                        appearance="caption"
                        id="email-input-helper-text"
                      >
                        {helperText}
                      </CssTypography>
                    </div>
                  </>
                )}
              </>
            )}
          </DialogContent>
        </CardSection>
        <DialogActions horizontal className={classes.actions}>
          <Button
            color="primary"
            data-e2e="activate-account-button"
            onClick={onConfirm}
            disabled={isSubmitDisabled}
          >
            {isSubmittingProxyRequest ? "Continue" : "Activate account"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ActivateAccountModal;
