/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CareTeamMembersArrayResponse,
    CareTeamMembersArrayResponseFromJSON,
    CareTeamMembersArrayResponseToJSON,
} from '../models/CareTeamMembersArrayResponse'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface FetchPodsCareTeamMembersRequest {
    podIds: string;
}


export interface IXopPodsApi {
      fetchMyPodsCareTeamMembers: () => Promise<CareTeamMembersArrayResponse>
      fetchPodsCareTeamMembers: (requestParameters: FetchPodsCareTeamMembersRequest) => Promise<CareTeamMembersArrayResponse>
      fetchMyPodsCareTeamMembersWithResponse: () => Promise<{ value: CareTeamMembersArrayResponse, response: any}>
      fetchPodsCareTeamMembersWithResponse: (requestParameters: FetchPodsCareTeamMembersRequest) => Promise<{ value: CareTeamMembersArrayResponse, response: any}>
}

/**
 * no description
 */
export class XopPodsApi extends runtime.BaseAPI {

    /**
     * Fetch care team members in my configured pods
     * My Pods Care Team Members
     */
    fetchMyPodsCareTeamMembersRaw = async (): Promise<{ runtime: runtime.ApiResponse<CareTeamMembersArrayResponse>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/my_pods/care_team_members`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CareTeamMembersArrayResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch care team members in my configured pods
     * My Pods Care Team Members
     */
    fetchMyPodsCareTeamMembers = async (): Promise<CareTeamMembersArrayResponse> => {
        const { runtime } = await this.fetchMyPodsCareTeamMembersRaw();
        return await runtime.value();
    }

    /**
     * Fetch care team members in my configured pods
     * My Pods Care Team Members
     */
    fetchMyPodsCareTeamMembersWithResponse = async (): Promise<{ value: CareTeamMembersArrayResponse, response: any}> => {
        const { runtime, response } = await this.fetchMyPodsCareTeamMembersRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch care team members in specified pod ids
     * Pods Care Team Members
     */
    fetchPodsCareTeamMembersRaw = async (requestParameters: FetchPodsCareTeamMembersRequest): Promise<{ runtime: runtime.ApiResponse<CareTeamMembersArrayResponse>, response: any}> => {
        if (requestParameters.podIds === null || requestParameters.podIds === undefined) {
            throw new runtime.RequiredError('podIds','Required parameter requestParameters.podIds was null or undefined when calling fetchPodsCareTeamMembers.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/pods/{pod_ids}/care_team_members`.replace(`{${"pod_ids"}}`, encodeURIComponent(String(requestParameters.podIds))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CareTeamMembersArrayResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch care team members in specified pod ids
     * Pods Care Team Members
     */
    fetchPodsCareTeamMembers = async (requestParameters: FetchPodsCareTeamMembersRequest): Promise<CareTeamMembersArrayResponse> => {
        const { runtime } = await this.fetchPodsCareTeamMembersRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch care team members in specified pod ids
     * Pods Care Team Members
     */
    fetchPodsCareTeamMembersWithResponse = async (requestParameters: FetchPodsCareTeamMembersRequest): Promise<{ value: CareTeamMembersArrayResponse, response: any}> => {
        const { runtime, response } = await this.fetchPodsCareTeamMembersRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchXopPodsApi: IXopPodsApi  = new XopPodsApi();
