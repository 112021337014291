// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VisitSummaryInfo
 */
export interface VisitSummaryInfo  {
    /**
     * 
     * @type {number}
     * @memberof VisitSummaryInfo
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VisitSummaryInfo
     */
    visitType?: string;
    /**
     * 
     * @type {Date}
     * @memberof VisitSummaryInfo
     */
    visitDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof VisitSummaryInfo
     */
    providerName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VisitSummaryInfo
     */
    viewed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VisitSummaryInfo
     */
    pdfLink?: string;
}

export function VisitSummaryInfoFromJSON(json: any): VisitSummaryInfo {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'visitType': !exists(json, 'visit_type') ? undefined : json['visit_type'],
        'visitDate': !exists(json, 'visit_date') ? undefined : new Date(json['visit_date']),
        'providerName': !exists(json, 'provider_name') ? undefined : json['provider_name'],
        'viewed': !exists(json, 'viewed') ? undefined : json['viewed'],
        'pdfLink': !exists(json, 'pdf_link') ? undefined : json['pdf_link'],
    };
}

export function VisitSummaryInfoToJSON(value?: VisitSummaryInfo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'visit_type': value.visitType,
        'visit_date': value.visitDate === undefined ? undefined : value.visitDate.toISOString(),
        'provider_name': value.providerName,
        'viewed': value.viewed,
        'pdf_link': value.pdfLink,
    };
}


