import React from "react";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Divider from "@mui/material/Divider";
import compact from "lodash/compact";
import compose from "lodash/fp/compose";
import map from "lodash/fp/map";
import sortBy from "lodash/fp/sortBy";
import Box from "../../Box";
import Typography from "../../Typography";
import theme from "styles/mui";

const useStyles = makeStyles(() =>
  createStyles({
    bannerText: {
      color: "white",
      display: "flex",
      justifyContent: "space-between",
    },
    divider: {
      backgroundColor: "white",
      width: "100vw",

      "&:last-child": {
        display: "none",
      },
    },
  })
);
const Messages = ({ messages }: any) => {
  const classes = useStyles();

  const renderedMessages = compose(
    compact,
    map("message"),
    sortBy(["orderDate"])
  )(messages);

  if (renderedMessages.length <= 0) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      bgcolor={theme?.palette?.appBackground?.blue}
      data-testid="messages-container"
    >
      {renderedMessages.map((message: JSX.Element, index: number) => (
        <React.Fragment key={index}>
          <Box py={2} px={1} ml="8.33%">
            <Typography className={classes.bannerText} variant="body2">
              {message}
            </Typography>
          </Box>
          <Divider className={classes.divider} />
        </React.Fragment>
      ))}
    </Box>
  );
};

export default Messages;
