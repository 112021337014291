import React from "react";
import Box from "components/Box";
import Skeleton from "components/Skeleton";
import Grid from "components/Grid";
import Divider from "components/Divider";

const SelectEpisodeOwnerSkeleton: React.FC<{}> = () => (
  <div style={{ width: 400, height: 397 }}>
    <Box pt={5} pl={2.7}>
      <div style={{ marginBottom: 24 }}>
        <Skeleton width={141} height={21} style={{ transform: "none" }} />
      </div>
      <div style={{ marginBottom: 8 }}>
        <Skeleton width={38} height={17} style={{ transform: "none" }} />
      </div>
      <div style={{ marginBottom: 36 }}>
        <Skeleton width={325} height={52} style={{ transform: "none" }} />
      </div>
      <div style={{ marginBottom: 8 }}>
        <Skeleton width={109} height={17} style={{ transform: "none" }} />
      </div>
      <div style={{ marginBottom: 47 }}>
        <Skeleton width={325} height={52} style={{ transform: "none" }} />
      </div>
    </Box>
    <Divider style={{ width: 400 }} />
    <Box pt={2.5} pl={2.7}>
      <Grid container style={{ height: 32, alignItems: "center" }}>
        <Grid item style={{ paddingRight: 8 }}>
          <Skeleton width={78} height={32} style={{ transform: "none" }} />
        </Grid>
        <Grid item>
          <Skeleton width={44} height={20} style={{ transform: "none" }} />
        </Grid>
      </Grid>
    </Box>
  </div>
);

export default SelectEpisodeOwnerSkeleton;
