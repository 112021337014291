// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    DeleteTimelineEventRequestBody,
    DeleteTimelineEventRequestBodyFromJSON,
    DeleteTimelineEventRequestBodyToJSON,
} from '../models/DeleteTimelineEventRequestBody'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    TimelineEventOutput,
    TimelineEventOutputFromJSON,
    TimelineEventOutputToJSON,
} from '../models/TimelineEventOutput'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface AcknowledgeCalculatorsRequest {
    timelineEventId: string;
}

export interface DeleteTimelineEventRequest {
    id: string;
    deleteTimelineEventRequestBody?: DeleteTimelineEventRequestBody;
}

export interface FetchTimelineEventRequest {
    id: string;
}


/**
 * Acknowledge all calculators for timeline event
 */
function acknowledgeCalculatorsRaw<T>(requestParameters: AcknowledgeCalculatorsRequest, requestConfig: runtime.TypedQueryConfig<T, TimelineEventOutput> = {}): QueryConfig<T> {
    if (requestParameters.timelineEventId === null || requestParameters.timelineEventId === undefined) {
        throw new runtime.RequiredError('timelineEventId','Required parameter requestParameters.timelineEventId was null or undefined when calling acknowledgeCalculators.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/timeline_events/{timeline_event_id}/acknowledge_calculators`.replace(`{${"timeline_event_id"}}`, encodeURIComponent(String(requestParameters.timelineEventId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PATCH',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(TimelineEventOutputFromJSON(body), text);
    }

    return config;
}

/**
* Acknowledge all calculators for timeline event
*/
export function acknowledgeCalculators<T>(requestParameters: AcknowledgeCalculatorsRequest, requestConfig?: runtime.TypedQueryConfig<T, TimelineEventOutput>): QueryConfig<T> {
    return acknowledgeCalculatorsRaw(requestParameters, requestConfig);
}

/**
 * Delete a timeline event
 * Delete timeline event
 */
function deleteTimelineEventRaw<T>(requestParameters: DeleteTimelineEventRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteTimelineEvent.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/timeline_events/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters || DeleteTimelineEventRequestBodyToJSON(requestParameters.deleteTimelineEventRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Delete a timeline event
* Delete timeline event
*/
export function deleteTimelineEvent<T>(requestParameters: DeleteTimelineEventRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return deleteTimelineEventRaw(requestParameters, requestConfig);
}

/**
 * Fetch a timeline event
 * Fetch timeline event
 */
function fetchTimelineEventRaw<T>(requestParameters: FetchTimelineEventRequest, requestConfig: runtime.TypedQueryConfig<T, TimelineEventOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchTimelineEvent.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/timeline_events/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(TimelineEventOutputFromJSON(body), text);
    }

    return config;
}

/**
* Fetch a timeline event
* Fetch timeline event
*/
export function fetchTimelineEvent<T>(requestParameters: FetchTimelineEventRequest, requestConfig?: runtime.TypedQueryConfig<T, TimelineEventOutput>): QueryConfig<T> {
    return fetchTimelineEventRaw(requestParameters, requestConfig);
}

