// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionSetJsonBodySchema,
    QuestionSetJsonBodySchemaFromJSON,
    QuestionSetJsonBodySchemaToJSON,
} from './QuestionSetJsonBodySchema';


/**
 * 
 * @export
 * @interface UpdateComponentRequestBody
 */
export interface UpdateComponentRequestBody  {
    /**
     * 
     * @type {QuestionSetJsonBodySchema}
     * @memberof UpdateComponentRequestBody
     */
    jsonBody?: QuestionSetJsonBodySchema;
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof UpdateComponentRequestBody
     */
    data?: { [key: string]: object; };
}

export function UpdateComponentRequestBodyFromJSON(json: any): UpdateComponentRequestBody {
    return {
        'jsonBody': !exists(json, 'json_body') ? undefined : QuestionSetJsonBodySchemaFromJSON(json['json_body']),
        'data': !exists(json, 'data') ? undefined : json['data'],
    };
}

export function UpdateComponentRequestBodyToJSON(value?: UpdateComponentRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'json_body': QuestionSetJsonBodySchemaToJSON(value.jsonBody),
        'data': value.data,
    };
}


