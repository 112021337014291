import React, { FC } from "react";


import PreventiveEocMarketing from "./PreventiveEocMarketing";
import DashboardCreateActions from "../LandingPage/DashboardCreateActions";
import Grid from "components/Grid";
import BeWellTile from "components/BeWellTile";
import Box from "components/Box";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { getUser } from "shared/features/user";
import { useSelector } from "react-redux";
import { getFeatures } from "shared/features/featureFlags/selectors";
import BeWellAndMentalHealthCard from "./BeWellMentalHealthCard";
import Typography from "components/Typography";
import useAccess from "hooks/useAccess";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";
import { EpisodeSummary } from "shared/fetch/src/models/EpisodeSummary";
import { EpisodeSummaryCoreOutputStateEnum } from "shared/fetch/src/models/EpisodeSummaryCoreOutput";

// remove this once api is done #175586669
const preventiveEocMarketing = {
  title: "Let’s talk about your mental health.",
  buttonText: "Start now",
};

interface IProps {
  preventiveEpisode?: EpisodeSummary;
  hasCreateEpisodeLink?: boolean;
  isMarketingMessage?: boolean;
}

interface MarketingProps {
  shouldRenderBeWellTile?: boolean;
  shouldRenderUTDTile?: boolean;
}

const useStyles = makeStyles(({ breakpoints }) =>
  createStyles({
    container: {
      [breakpoints.down("md")]: {
        justifyContent: "center",
      },
    },
    title: {
      lineHeight: "14.4px",
      color: "#4C5A73",
      marginBottom: "13px",
    },
  })
);

const PreventiveEoc: FC<IProps> = ({
  preventiveEpisode,
  hasCreateEpisodeLink,
  isMarketingMessage = true,
}) => {
  const user = useSelector(getUser);
  const featureFlags = useSelector(getFeatures);
  const classes = useStyles();

  const hasUtdPreventiveFeature = user?.serviceLines?.includes(
    "utd_preventive_feature"
  );
  const isBeWellFeatureFlagOn = featureFlags.hasBeWell();

  const hasRequiredPermissions = useAccess({
    to: PermissionsEnum.DashboardBeWellRead,
  });

  if (!hasRequiredPermissions) {
    return null;
  }

  const isEligible = user?.status !== "Ineligible";

  const shouldRenderUTD = !!preventiveEpisode && hasUtdPreventiveFeature;

  const isUnstarted =
    shouldRenderUTD &&
    preventiveEpisode?.state === EpisodeSummaryCoreOutputStateEnum.Unstarted;

  const PreventiveCareTitleComponent: FC = () => {
    return (
      <Typography className={classes.title} variant="h2" appearance="smallBody">
        PREVENTIVE CARE
      </Typography>
    );
  };

  const MarketingCardsComponent: FC<MarketingProps> = ({
    shouldRenderBeWellTile,
    shouldRenderUTDTile,
  }: any) => {
    if (shouldRenderBeWellTile && shouldRenderUTDTile && isEligible) {
      return (
        <div>
          <PreventiveCareTitleComponent />
          <BeWellAndMentalHealthCard
            // @ts-ignore: Object is possibly 'null'.
            preventiveEpisode={preventiveEpisode}
            preventiveEocMarketing={preventiveEocMarketing}
          />
        </div>
      );
    } else if (shouldRenderBeWellTile && isEligible) {
      return (
        <div>
          <PreventiveCareTitleComponent />
          <Box flexGrow={1}>
            <Grid container spacing={2}>
              <Grid item lg={8} md={12}>
                <BeWellTile />
              </Grid>
            </Grid>
          </Box>
        </div>
      );
    } else if (shouldRenderUTDTile && isEligible) {
      return (
        <div>
          <PreventiveCareTitleComponent />
          <Box flexGrow={1}>
            <Grid container spacing={2}>
              <Grid item lg={8} md={12}>
                <PreventiveEocMarketing
                  {...preventiveEocMarketing}
                  // @ts-ignore: Object is possibly 'null'.
                  preventiveEpisode={preventiveEpisode}
                  shouldRenderUTDTile={shouldRenderUTDTile}
                />
              </Grid>
            </Grid>
          </Box>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <Grid container className={!hasCreateEpisodeLink ? classes.container : ""}>
      {hasCreateEpisodeLink && (
        <Grid container item direction="row">
          <DashboardCreateActions />
        </Grid>
      )}
      {isMarketingMessage && isEligible && (
        <MarketingCardsComponent
          shouldRenderBeWellTile={isBeWellFeatureFlagOn}
          shouldRenderUTDTile={isUnstarted}
        />
      )}
    </Grid>
  );
};

export default PreventiveEoc;
