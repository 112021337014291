// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EmployerOrInsurerOutput,
    EmployerOrInsurerOutputFromJSON,
    EmployerOrInsurerOutputToJSON,
} from './EmployerOrInsurerOutput';


/**
 * 
 * @export
 * @interface EmployersResponseResults
 */
export interface EmployersResponseResults  {
    /**
     * 
     * @type {Array<EmployerOrInsurerOutput>}
     * @memberof EmployersResponseResults
     */
    employers?: Array<EmployerOrInsurerOutput>;
    /**
     * 
     * @type {Array<EmployerOrInsurerOutput>}
     * @memberof EmployersResponseResults
     */
    insurers?: Array<EmployerOrInsurerOutput>;
}

export function EmployersResponseResultsFromJSON(json: any): EmployersResponseResults {
    return {
        'employers': !exists(json, 'employers') ? undefined : (json['employers'] as Array<any>).map(EmployerOrInsurerOutputFromJSON),
        'insurers': !exists(json, 'insurers') ? undefined : (json['insurers'] as Array<any>).map(EmployerOrInsurerOutputFromJSON),
    };
}

export function EmployersResponseResultsToJSON(value?: EmployersResponseResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'employers': value.employers === undefined ? undefined : (value.employers as Array<any>).map(EmployerOrInsurerOutputToJSON),
        'insurers': value.insurers === undefined ? undefined : (value.insurers as Array<any>).map(EmployerOrInsurerOutputToJSON),
    };
}


