import * as React from "react";
import Routing from "./Routing";
import StateManagement from "shared/state/StateManagement";
import "./index.css";
import useSetPageTitle from "hooks/useSetPageTitle";
import { Auth0Provider } from "@auth0/auth0-react";
import NativeApp from "models/NativeApp";
import {
  getDidAuthenticateWithOauth,
  getRedirectUri,
} from "shared/features/auth/utils";
import XoConfig from "models/XoConfig";
import queryString from "query-string";

declare global {
  interface Window {
    ReactNativeWebView?: any;
    Cypress: any;
  }
}

const Root: React.FunctionComponent = () => {
  useSetPageTitle();
  NativeApp.updateNativeAppState();
  const queryParams = queryString.parse(location.search);

  return (
    <>
      {XoConfig.getValue("auth0")?.enabled &&
      !getDidAuthenticateWithOauth() &&
      !queryParams?.identity_provider ? (
        <Auth0Provider
          domain={XoConfig.getValue("auth0").domain}
          clientId={XoConfig.getValue("auth0").clientId}
          useRefreshTokens
          cacheLocation="localstorage"
          authorizationParams={{
            redirect_uri: getRedirectUri(),
            audience: XoConfig.getValue("auth0").audience,
            scope: "openid offline_access profile",
            mobile_app_login: window?.ReactNativeWebView?.isApp
              ? "true"
              : "false",
          }}
        >
          <StateManagement>
            <div className="custom">
              <Routing />
            </div>
          </StateManagement>
        </Auth0Provider>
      ) : (
        <StateManagement>
          <div className="custom">
            <Routing />
          </div>
        </StateManagement>
      )}
    </>
  );
};

export default Root;
