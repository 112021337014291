// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfigResponseAssetsLogoOnWhite
 */
export interface ConfigResponseAssetsLogoOnWhite  {
    /**
     * 
     * @type {string}
     * @memberof ConfigResponseAssetsLogoOnWhite
     */
    src?: string;
}

export function ConfigResponseAssetsLogoOnWhiteFromJSON(json: any): ConfigResponseAssetsLogoOnWhite {
    return {
        'src': !exists(json, 'src') ? undefined : json['src'],
    };
}

export function ConfigResponseAssetsLogoOnWhiteToJSON(value?: ConfigResponseAssetsLogoOnWhite): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'src': value.src,
    };
}


