// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AccountActivationResponse
 */
export interface AccountActivationResponse  {
    /**
     * 
     * @type {string}
     * @memberof AccountActivationResponse
     */
    verificationEmail?: string;
}

export function AccountActivationResponseFromJSON(json: any): AccountActivationResponse {
    return {
        'verificationEmail': !exists(json, 'verification_email') ? undefined : json['verification_email'],
    };
}

export function AccountActivationResponseToJSON(value?: AccountActivationResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'verification_email': value.verificationEmail,
    };
}


