/* @typescript-eslint no-unused-vars: 0 */
import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import clsx from "clsx";

import IconAlertBell from "../../styles/icons/svg/icon-alert-bell.svg";
import IconAlertBellWhite from "../../styles/icons/svg/icon-alert-bell-white.svg";
import ButtonBase from "components/Button/ButtonBase";
import { useMenuAnchorElement } from "components/Menu/useMenuAnchorElement";
import Icons from "../../styles/icons/icons.svg";
import MenuItem from "components/Menu/MenuItem";
import Badge from "components/Badge";
import Menu from "components/Menu";

import useIconStyles from "utils/useIconStyles";
import useDraftSafeNavigate from "hooks/useDraftSafeNavigate";
import { useSelfScheduleModalProps } from "features/selfSchedule/pages/Utils";
import { ActionableItemDetails } from "shared/fetch/src/models/ActionableItemDetails";
import { useMediaQuery } from "@mui/material";
import { useTheme, Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

const useStyles: any = makeStyles((theme: Theme) => ({
  paper: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette?.appBackground?.darkestGray,
  },
  list: {
    "& a": {
      color: theme.palette.white,
    },
    "& a:hover": {
      color: theme.palette.white,
    },
    "& .MuiListItem-root.Mui-focusVisible": {
      backgroundColor: theme.palette.secondary.main,
    },
    "& .MuiListItem-button:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

const Arrow = () => {
  const iconClasses = useIconStyles();
  return (
    <svg
      className={clsx("icon", "icon-chevron", iconClasses.icon)}
      style={{
        width: 8,
        height: 8,
        marginLeft: 8,
        fill: "white",
        color: "white",
      }}
    >
      <use xlinkHref={`${Icons}#icon-chevron`} />
    </svg>
  );
};

interface MenuProps {
  actionableItems?: ActionableItemDetails[];
}

/*
ActionableItemsMenuButton - nav dropdown
*/
const ActionableItemsMenuButton = (props: MenuProps) => {
  const location = useLocation();
  const overrides = useSelfScheduleModalProps();
  const theme = useTheme();
  const classes = useStyles({ props });
  const isScreenSmallerOrWider = useMediaQuery(theme.breakpoints.up("sm"));
  const isSelfSchedulePage = location?.pathname?.includes("self-schedule");
  const { actionableItems = [] } = props;
  const { anchorElement, onAnchorElementClick, onMenuClose } =
    useMenuAnchorElement();
  const srAnnouncement = `${actionableItems.length} items require your attention`;
  const menuRef = useRef(null);
  const menuButtonRef = useRef(null);
  const focusFirstLink = () => {
    if (!anchorElement) {
      setTimeout(() => {
        if (menuButtonRef.current) {
          // @ts-ignore
          menuButtonRef.current.focus();
        }
      }, 10);
    }
  };
  useEffect(focusFirstLink, [anchorElement]);

  const handleNavigationClick = useDraftSafeNavigate();

  return (
    <>
      {/* @ts-ignore: Object is possibly 'null'. */}
      <ButtonBase
        ref={menuButtonRef}
        data-e2e="actionable-items-menu-button"
        aria-label={srAnnouncement}
        aria-controls="actionable-items-menu"
        aria-haspopup="true"
        onClick={actionableItems.length ? onAnchorElementClick : null}
        style={{ height: "100%", paddingRight: 8 }}
      >
        <Badge
          overlap="rectangular"
          color="primary"
          badgeContent={actionableItems.length}
          data-e2e="ai-alert-badge"
        >
          {/* replace icon styles here */}
          <img
            className="icon-alert"
            src={isScreenSmallerOrWider ? IconAlertBell : IconAlertBellWhite}
            alt="Action Items Menu Button"
            style={{ height: 24 }}
          />
        </Badge>
      </ButtonBase>
      <Menu
        id="actionable-items-menu"
        appearance="dark"
        className="actionable-items-alert-dropdown"
        classes={{
          paper: classes.paper,
          list: classes.list,
        }}
        anchorEl={anchorElement}
        ref={menuRef}
        keepMounted
        open={Boolean(anchorElement)}
        onClose={onMenuClose}
        // getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {props?.actionableItems?.map(
          (actionItem: ActionableItemDetails, i: number) => (
            <MenuItem
              component={Link}
              to={`/members/${actionItem.memberId}/conversations/${actionItem.episodeId}/component/${actionItem.componentId}`}
              onClick={(e: any) => {
                e.preventDefault();
                onMenuClose();
                handleNavigationClick(
                  `/members/${actionItem.memberId}/conversations/${actionItem.episodeId}/component/${actionItem.componentId}`,
                  // @ts-ignore
                  isSelfSchedulePage && overrides
                );
              }}
              key={actionItem?.id}
              data-e2e={`actionable-items-alert-dropdown-item-${i + 1}`}
            >
              <span>{actionItem.notificationCenterDisplayLabel}</span>
              <Arrow />
            </MenuItem>
          )
        )}
      </Menu>
    </>
  );
};

export default ActionableItemsMenuButton;
