import { fetchTimelineEvent } from "shared/api/src/apis/TimelineEventsApi";
import { TimelineEventOutput } from "shared/fetch/src/models/TimelineEventOutput";

export const fetchTimelineEventRequest = (
  id: string,
  force = true as boolean
) =>
  fetchTimelineEvent(
    {
      id,
    },
    {
      transform: (body: TimelineEventOutput) => ({
        timelineEvent: body || ({} as TimelineEventOutput),
      }),
      update: {
        timelineEvent: (
          _prev: TimelineEventOutput,
          next: TimelineEventOutput
        ) => next,
      },
      force,
    }
  );
