// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';


/**
 * 
 * @export
 * @interface EpisodeWithActionableItemsObjectOutputAllOf
 */
export interface EpisodeWithActionableItemsObjectOutputAllOf  {
    /**
     * 
     * @type {{ [key: string]: Array<ActionableItemSummary>; }}
     * @memberof EpisodeWithActionableItemsObjectOutputAllOf
     */
    actionableItems?: { [key: string]: Array<ActionableItemSummary>; };
}

export function EpisodeWithActionableItemsObjectOutputAllOfFromJSON(json: any): EpisodeWithActionableItemsObjectOutputAllOf {
    return {
        'actionableItems': !exists(json, 'actionable_items') ? undefined : json['actionable_items'],
    };
}

export function EpisodeWithActionableItemsObjectOutputAllOfToJSON(value?: EpisodeWithActionableItemsObjectOutputAllOf): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'actionable_items': value.actionableItems,
    };
}


