interface IProps {
  prevPath?: string;
  key?: string;
}

interface ReturnProps {
  prevPath: string;
  isPrevPathNull: boolean;
}
export const getPrevPath = (state: IProps): ReturnProps => {
  const route = /^\/tools\/\w+\/\w+\/\w+$/;
  let prevPath = "";
  let isPrevPathNull = false;
  // remove the period character from the ids/path to better match the route and evaluate the prev path
  const prevPathWithoutSpecialCharacters = state?.prevPath?.replace(/\./g, "");
  if (!state?.prevPath) {
    isPrevPathNull = true;
    prevPath = "/tools";
    // @ts-ignore: Object is possibly 'null'.
  } else if (route.test(prevPathWithoutSpecialCharacters)) {
    // @ts-ignore: Object is possibly 'null'.
    const array = prevPathWithoutSpecialCharacters.split("/");
    array.pop();
    const formattedPath = array.join("/");
    prevPath = formattedPath;
  } else {
    isPrevPathNull = false;
    prevPath = state.prevPath;
  }
  return { prevPath, isPrevPathNull };
};

function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

const getDisplayText = (path: string[], position: number) => {
  const pathName = path && path[path.length - position];
  const editedPathName = pathName?.replace(/_/g, " ");
  const displayText = toTitleCase(editedPathName);
  return displayText;
};

export const getComparisonText = (path: string) => {
  const pathNameArray = path.split("/");
  const comparisonText = getDisplayText(pathNameArray, 1);
  return comparisonText;
};

export const getText = (prevPath: string): string => {
  let text = "";
  const path = prevPath.split("/");
  const route = /^\/tools\/\w+\/\w+\/\w+$/;

  if (prevPath === "/tools") {
    text = "Tools";
  } else if (route.test(prevPath)) {
    const displayText = getDisplayText(path, 2);
    text = displayText;
  } else {
    const displayText = getDisplayText(path, 1);
    text = displayText;
  }
  return text;
};
