import React from "react";
import Typography from "components/Typography";
import { Appointment } from "shared/fetch/src/models/Appointment";
import { Link } from "components/Button";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { formatMessageSubject } from "features/selfSchedule/pages/Utils";

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    link: {
      padding: 0,
      textDecoration: "underline",
      marginBottom: "auto",
      "&:hover": {
        fill: palette.error.dark,
        textDecoration: "underline",
        backgroundColor: "inherit",
      },
      "&.MuiButton-root": {
        minWidth: 0,
      },
    },
  })
);

const truncate = (str: string, len: number): string => {
  const cutoffLength = 65;
  return len > cutoffLength ? str.substring(0, cutoffLength) + "..." : str;
};

const combinedValuesLength = (
  providerName: string,
  conversationName: string
): number => {
  const total_length = providerName + " " + "|" + " " + conversationName;
  return total_length.length;
};

export default function ConversationName({ visit }: { visit: Appointment }) {
  const classes = useStyles();
  const to = `/members/${visit?.patient?.id}/conversations/${visit?.episodeId}`;
  const serviceLabel = truncate(
    // @ts-ignore: Object is possibly 'null'.
    visit?.serviceSubtypeLabel,
    // @ts-ignore: Object is possibly 'null'.
    combinedValuesLength(visit?.provider?.name, visit?.serviceSubtypeLabel)
  );

  return (
    <Link to={to} className={classes.link}>
      <Typography
        appearance="smallBody"
        component="span"
        data-testid="details"
        fontWeightNormal
        color="textSecondary"
      >
        {visit?.episodeCategory === "messaging" &&
          visit?.subject !== "something_else" &&
          // @ts-ignore: Object is possibly 'null'.
          formatMessageSubject(visit?.subject)}
        {visit?.episodeCategory === "messaging" &&
          visit?.subject === "something_else" &&
          // @ts-ignore: Object is possibly 'null'.
          formatMessageSubject(visit?.subjectFreetext)}
        {visit?.episodeCategory !== "messaging" && serviceLabel}
      </Typography>
    </Link>
  );
}
