export const visitedEpisode = (
  state: State = getInitialVisitedEpisodeState(),
  action: any
): State => {
  if (action && action.type) {
    const { type } = action;
    switch (type) {
      case SET_VISITED_EPISODE:
        const { episodeId, visitedPage } = action.payload;
        return {
          ...state,
          episodeId,
          visitedPage,
          visitedComponentId: undefined,
        };
      case SET_VISITED_PAGE:
        return { ...state, ...action.payload };
      case SET_VISITED_COMPONENT_ID:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  }
  return state;
};
export const SET_VISITED_EPISODE = "SET_VISITED_EPISODE";
export const SET_VISITED_PAGE = "SET_VISITED_PAGE";
export const SET_VISITED_COMPONENT_ID = "SET_VISITED_COMPONENT_ID";

export interface State {
  episodeId?: string;
  visitedPage: number;
  visitedComponentId?: string;
}

export const getInitialVisitedEpisodeState = (): State => {
  return {
    episodeId: undefined,
    visitedPage: 1,
    visitedComponentId: undefined,
  };
};
