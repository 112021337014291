// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserInfo,
    UserInfoFromJSON,
    UserInfoToJSON,
} from './UserInfo';


/**
 * 
 * @export
 * @interface ArchiveEpisodeOfCareInfoBody
 */
export interface ArchiveEpisodeOfCareInfoBody  {
    /**
     * 
     * @type {number}
     * @memberof ArchiveEpisodeOfCareInfoBody
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ArchiveEpisodeOfCareInfoBody
     */
    archiveOption?: string;
    /**
     * 
     * @type {string}
     * @memberof ArchiveEpisodeOfCareInfoBody
     */
    reason?: string;
    /**
     * 
     * @type {UserInfo}
     * @memberof ArchiveEpisodeOfCareInfoBody
     */
    archiver?: UserInfo;
    /**
     * 
     * @type {Date}
     * @memberof ArchiveEpisodeOfCareInfoBody
     */
    createdAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof ArchiveEpisodeOfCareInfoBody
     */
    billable?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ArchiveEpisodeOfCareInfoBody
     */
    consultation?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ArchiveEpisodeOfCareInfoBody
     */
    serviceProviderId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ArchiveEpisodeOfCareInfoBody
     */
    icd10Codes?: Array<string>;
}

export function ArchiveEpisodeOfCareInfoBodyFromJSON(json: any): ArchiveEpisodeOfCareInfoBody {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'archiveOption': !exists(json, 'archive_option') ? undefined : json['archive_option'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'archiver': !exists(json, 'archiver') ? undefined : UserInfoFromJSON(json['archiver']),
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'billable': !exists(json, 'billable') ? undefined : json['billable'],
        'consultation': !exists(json, 'consultation') ? undefined : json['consultation'],
        'serviceProviderId': !exists(json, 'service_provider_id') ? undefined : json['service_provider_id'],
        'icd10Codes': !exists(json, 'icd10_codes') ? undefined : json['icd10_codes'],
    };
}

export function ArchiveEpisodeOfCareInfoBodyToJSON(value?: ArchiveEpisodeOfCareInfoBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'archive_option': value.archiveOption,
        'reason': value.reason,
        'archiver': UserInfoToJSON(value.archiver),
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'billable': value.billable,
        'consultation': value.consultation,
        'service_provider_id': value.serviceProviderId,
        'icd10_codes': value.icd10Codes,
    };
}


