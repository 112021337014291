import palette from "../palette";
import { customFocusForAllBackgrounds } from "./utils";

const roundedCustomFocus = {
  borderRadius: "50%",
  ...customFocusForAllBackgrounds
}

export default {
  root: {
    "&.Mui-focusVisible span": roundedCustomFocus,
  },
  colorPrimary: {
    "&:hover": {
      backgroundColor: "rgba(28, 116, 171, 0.03)",
    },
    "&$checked": {
      color: palette.links.primary,
    },
  },
  focusVisible: {},
};
