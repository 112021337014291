// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OutputServiceLineSchema,
    OutputServiceLineSchemaFromJSON,
    OutputServiceLineSchemaToJSON,
} from './OutputServiceLineSchema';
import {
    OutputSubTypeSchema,
    OutputSubTypeSchemaFromJSON,
    OutputSubTypeSchemaToJSON,
} from './OutputSubTypeSchema';


/**
 * 
 * @export
 * @interface OutputServiceLineSchemaProps
 */
export interface OutputServiceLineSchemaProps  {
    /**
     * 
     * @type {string}
     * @memberof OutputServiceLineSchemaProps
     */
    key: string;
    /**
     * 
     * @type {Array<OutputSubTypeSchema>}
     * @memberof OutputServiceLineSchemaProps
     */
    subtypes: Array<OutputSubTypeSchema>;
    /**
     * 
     * @type {Array<OutputServiceLineSchema>}
     * @memberof OutputServiceLineSchemaProps
     */
    submenus: Array<OutputServiceLineSchema>;
}

export function OutputServiceLineSchemaPropsFromJSON(json: any): OutputServiceLineSchemaProps {
    return {
        'key': json['key'],
        'subtypes': (json['subtypes'] as Array<any>).map(OutputSubTypeSchemaFromJSON),
        'submenus': (json['submenus'] as Array<any>).map(OutputServiceLineSchemaFromJSON),
    };
}

export function OutputServiceLineSchemaPropsToJSON(value?: OutputServiceLineSchemaProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'key': value.key,
        'subtypes': (value.subtypes as Array<any>).map(OutputSubTypeSchemaToJSON),
        'submenus': (value.submenus as Array<any>).map(OutputServiceLineSchemaToJSON),
    };
}


