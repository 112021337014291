// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompactComponentReference,
    CompactComponentReferenceFromJSON,
    CompactComponentReferenceToJSON,
} from './CompactComponentReference';


/**
 * 
 * @export
 * @interface DiagnosisComponentOutputProps
 */
export interface DiagnosisComponentOutputProps  {
    /**
     * 
     * @type {string}
     * @memberof DiagnosisComponentOutputProps
     */
    state: DiagnosisComponentOutputPropsStateEnum;
    /**
     * 
     * @type {Array<CompactComponentReference>}
     * @memberof DiagnosisComponentOutputProps
     */
    parentComponents?: Array<CompactComponentReference>;
}

export function DiagnosisComponentOutputPropsFromJSON(json: any): DiagnosisComponentOutputProps {
    return {
        'state': json['state'],
        'parentComponents': !exists(json, 'parent_components') ? undefined : (json['parent_components'] as Array<any>).map(CompactComponentReferenceFromJSON),
    };
}

export function DiagnosisComponentOutputPropsToJSON(value?: DiagnosisComponentOutputProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'state': value.state,
        'parent_components': value.parentComponents === undefined ? undefined : (value.parentComponents as Array<any>).map(CompactComponentReferenceToJSON),
    };
}

/**
* @export
* @enum {string}
*/
export enum DiagnosisComponentOutputPropsStateEnum {
    Unresolved = 'unresolved',
    Resolved = 'resolved'
}


