// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommonQuestionDetailsItemQuestionSets
 */
export interface CommonQuestionDetailsItemQuestionSets  {
    /**
     * 
     * @type {string}
     * @memberof CommonQuestionDetailsItemQuestionSets
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CommonQuestionDetailsItemQuestionSets
     */
    sqsId: string;
    /**
     * 
     * @type {string}
     * @memberof CommonQuestionDetailsItemQuestionSets
     */
    name: string;
}

export function CommonQuestionDetailsItemQuestionSetsFromJSON(json: any): CommonQuestionDetailsItemQuestionSets {
    return {
        'id': json['id'],
        'sqsId': json['sqs_id'],
        'name': json['name'],
    };
}

export function CommonQuestionDetailsItemQuestionSetsToJSON(value?: CommonQuestionDetailsItemQuestionSets): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'sqs_id': value.sqsId,
        'name': value.name,
    };
}


