import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const animateUpUseStyles = makeStyles(() =>
  createStyles({
    animateUp: {
      animation: "$animateUp 0.9s ease-in-out both",
    },
    "@keyframes animateUp": {
      from: {
        opacity: 0,
        top: "10px",
      },
      to: {
        opacity: 1,
        top: 0,
      },
    },
  })
);
