// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QueueMemberInfoAssignedPcp,
    QueueMemberInfoAssignedPcpFromJSON,
    QueueMemberInfoAssignedPcpToJSON,
} from './QueueMemberInfoAssignedPcp';


/**
 * 
 * @export
 * @interface QueueMemberInfo
 */
export interface QueueMemberInfo  {
    /**
     * 
     * @type {number}
     * @memberof QueueMemberInfo
     */
    activeEpisodeCount?: number;
    /**
     * 
     * @type {string}
     * @memberof QueueMemberInfo
     */
    employer?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueMemberInfo
     */
    state?: string;
    /**
     * 
     * @type {Date}
     * @memberof QueueMemberInfo
     */
    becomesIneligibleAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof QueueMemberInfo
     */
    status?: string;
    /**
     * 
     * @type {QueueMemberInfoAssignedPcp}
     * @memberof QueueMemberInfo
     */
    assignedPcp?: QueueMemberInfoAssignedPcp;
    /**
     * 
     * @type {string}
     * @memberof QueueMemberInfo
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueMemberInfo
     */
    dob?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueMemberInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueMemberInfo
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueMemberInfo
     */
    legalAndPreferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueMemberInfo
     */
    type?: QueueMemberInfoTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof QueueMemberInfo
     */
    corePodName?: string;
}

export function QueueMemberInfoFromJSON(json: any): QueueMemberInfo {
    return {
        'activeEpisodeCount': !exists(json, 'active_episode_count') ? undefined : json['active_episode_count'],
        'employer': !exists(json, 'employer') ? undefined : json['employer'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'becomesIneligibleAt': !exists(json, 'becomes_ineligible_at') ? undefined : new Date(json['becomes_ineligible_at']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'assignedPcp': !exists(json, 'assigned_pcp') ? undefined : QueueMemberInfoAssignedPcpFromJSON(json['assigned_pcp']),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'legalName': !exists(json, 'legal_name') ? undefined : json['legal_name'],
        'legalAndPreferredName': !exists(json, 'legal_and_preferred_name') ? undefined : json['legal_and_preferred_name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'corePodName': !exists(json, 'core_pod_name') ? undefined : json['core_pod_name'],
    };
}

export function QueueMemberInfoToJSON(value?: QueueMemberInfo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'active_episode_count': value.activeEpisodeCount,
        'employer': value.employer,
        'state': value.state,
        'becomes_ineligible_at': value.becomesIneligibleAt === undefined ? undefined : value.becomesIneligibleAt.toISOString().substr(0,10),
        'status': value.status,
        'assigned_pcp': QueueMemberInfoAssignedPcpToJSON(value.assignedPcp),
        'id': value.id,
        'dob': value.dob,
        'name': value.name,
        'legal_name': value.legalName,
        'legal_and_preferred_name': value.legalAndPreferredName,
        'type': value.type,
        'core_pod_name': value.corePodName,
    };
}

/**
* @export
* @enum {string}
*/
export enum QueueMemberInfoTypeEnum {
    Member = 'member',
    Ctm = 'ctm'
}


