// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface HeadsUpNotification
 */
export interface HeadsUpNotification  {
    /**
     * 
     * @type {string}
     * @memberof HeadsUpNotification
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof HeadsUpNotification
     */
    actorInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof HeadsUpNotification
     */
    notificationType?: HeadsUpNotificationNotificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof HeadsUpNotification
     */
    serviceSubtypeLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof HeadsUpNotification
     */
    state?: HeadsUpNotificationStateEnum;
    /**
     * 
     * @type {string}
     * @memberof HeadsUpNotification
     */
    timelineEventType?: string;
    /**
     * 
     * @type {string}
     * @memberof HeadsUpNotification
     */
    componentId?: string;
    /**
     * 
     * @type {string}
     * @memberof HeadsUpNotification
     */
    componentName?: string;
    /**
     * 
     * @type {string}
     * @memberof HeadsUpNotification
     */
    timelineEventId?: string;
    /**
     * 
     * @type {string}
     * @memberof HeadsUpNotification
     */
    episodeId?: string;
    /**
     * 
     * @type {string}
     * @memberof HeadsUpNotification
     */
    memberId?: string;
    /**
     * 
     * @type {string}
     * @memberof HeadsUpNotification
     */
    memberEmployer?: string;
    /**
     * 
     * @type {string}
     * @memberof HeadsUpNotification
     */
    memberName?: string;
    /**
     * 
     * @type {string}
     * @memberof HeadsUpNotification
     */
    location?: string;
    /**
     * 
     * @type {string}
     * @memberof HeadsUpNotification
     */
    dob?: string;
    /**
     * 
     * @type {Date}
     * @memberof HeadsUpNotification
     */
    createdAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof HeadsUpNotification
     */
    appointmentTime?: Date;
    /**
     * 
     * @type {string}
     * @memberof HeadsUpNotification
     */
    subject?: string;
    /**
     * 
     * @type {string}
     * @memberof HeadsUpNotification
     */
    subjectFreetext?: string;
    /**
     * 
     * @type {string}
     * @memberof HeadsUpNotification
     */
    category?: HeadsUpNotificationCategoryEnum;
}

export function HeadsUpNotificationFromJSON(json: any): HeadsUpNotification {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'actorInfo': !exists(json, 'actor_info') ? undefined : json['actor_info'],
        'notificationType': !exists(json, 'notification_type') ? undefined : json['notification_type'],
        'serviceSubtypeLabel': !exists(json, 'service_subtype_label') ? undefined : json['service_subtype_label'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'timelineEventType': !exists(json, 'timeline_event_type') ? undefined : json['timeline_event_type'],
        'componentId': !exists(json, 'component_id') ? undefined : json['component_id'],
        'componentName': !exists(json, 'component_name') ? undefined : json['component_name'],
        'timelineEventId': !exists(json, 'timeline_event_id') ? undefined : json['timeline_event_id'],
        'episodeId': !exists(json, 'episode_id') ? undefined : json['episode_id'],
        'memberId': !exists(json, 'member_id') ? undefined : json['member_id'],
        'memberEmployer': !exists(json, 'member_employer') ? undefined : json['member_employer'],
        'memberName': !exists(json, 'member_name') ? undefined : json['member_name'],
        'location': !exists(json, 'location') ? undefined : json['location'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'appointmentTime': !exists(json, 'appointment_time') ? undefined : new Date(json['appointment_time']),
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'subjectFreetext': !exists(json, 'subject_freetext') ? undefined : json['subject_freetext'],
        'category': !exists(json, 'category') ? undefined : json['category'],
    };
}

export function HeadsUpNotificationToJSON(value?: HeadsUpNotification): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'actor_info': value.actorInfo,
        'notification_type': value.notificationType,
        'service_subtype_label': value.serviceSubtypeLabel,
        'state': value.state,
        'timeline_event_type': value.timelineEventType,
        'component_id': value.componentId,
        'component_name': value.componentName,
        'timeline_event_id': value.timelineEventId,
        'episode_id': value.episodeId,
        'member_id': value.memberId,
        'member_employer': value.memberEmployer,
        'member_name': value.memberName,
        'location': value.location,
        'dob': value.dob,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'appointment_time': value.appointmentTime === undefined ? undefined : value.appointmentTime.toISOString(),
        'subject': value.subject,
        'subject_freetext': value.subjectFreetext,
        'category': value.category,
    };
}

/**
* @export
* @enum {string}
*/
export enum HeadsUpNotificationNotificationTypeEnum {
    CcMessage = 'cc_message',
    VisitScheduled = 'visit_scheduled',
    VisitRescheduled = 'visit_rescheduled',
    CustomerSubscriptionUpdated = 'customer_subscription_updated',
    CustomerSubscriptionDeleted = 'customer_subscription_deleted'
}
/**
* @export
* @enum {string}
*/
export enum HeadsUpNotificationStateEnum {
    Active = 'active',
    Resolved = 'resolved'
}
/**
* @export
* @enum {string}
*/
export enum HeadsUpNotificationCategoryEnum {
    Clinical = 'clinical',
    Preventive = 'preventive',
    Messaging = 'messaging'
}


