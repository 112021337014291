import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router";
import makeStyles from '@mui/styles/makeStyles';
import Button from "components/Button";

const useStyles = makeStyles(({ palette }) => ({
  btnAsLink: {
    color: palette?.links?.secondaryHover,
    fontSize: "13px",
    marginBottom: "12px",
    marginTop: "12px",
  },
  primaryBtn: {
    marginTop: "33px",
    padding: "8px 24px",
    fontSize: "13px",
  },
  secondaryBtn: {
    fontSize: "13px",
    marginTop: "16px",
  },
}));

interface INewAccountButton {
  linkBtn?: boolean | undefined;
  primaryBtn?: boolean | undefined;
  secondaryBtn?: boolean | undefined;
  color: "link" | "link-secondary" | "primary" | "secondary";
}

const NewAccountButton: FunctionComponent<INewAccountButton> = ({
  linkBtn = false,
  primaryBtn = false,
  secondaryBtn = false,
  color,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/members/new");
  };

  return (
    <>
      <Button
        className={clsx(
          linkBtn && classes.btnAsLink,
          primaryBtn && classes.primaryBtn,
          secondaryBtn && classes.secondaryBtn
        )}
        data-testid="create-new-account"
        color={color}
        onMouseDown={handleClick}
        onClick={handleClick}
      >
        Create new account
      </Button>
    </>
  );
};

export default NewAccountButton;
