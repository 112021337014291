import React from "react";
import IconAlertBellWhite from "../../styles/icons/svg/icon-alert-bell-white.svg";
import ButtonBase from "components/Button/ButtonBase";
import Badge from "components/Badge";
import { useNavigate } from "react-router";
import useGetNotifications from "shared/features/notificationCenter/useGetNotifications";
import { GetUserPushNotificationsStateEnum } from "shared/fetch/src/apis/UserPushNotificationsApi";

const MobileNotificationBell = () => {
  const { counts } = useGetNotifications(GetUserPushNotificationsStateEnum.Sent);
  const unreadNotificationCount = counts?.sent || 0;
  const srAnnouncement = `${unreadNotificationCount} items require your attention`;
  const navigate = useNavigate();

  return (
    <ButtonBase
      data-testid="mobile-notification-bell"
      focusRipple
      aria-label={srAnnouncement}
      onClick={() => {
        navigate(`/notifications`);
      }}
      style={{ height: "100%", paddingRight: 8 }}
    >
      <Badge
        color="primary"
        badgeContent={unreadNotificationCount}
        data-testid="notification-alert-badge"
      >
        <img
          className="icon-alert"
          src={IconAlertBellWhite}
          alt="Notifications Button"
          style={{ height: 24 }}
        />
      </Badge>
    </ButtonBase>
  );
};

export default MobileNotificationBell;
