/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    MemberProviders,
    MemberProvidersFromJSON,
    MemberProvidersToJSON,
} from '../models/MemberProviders'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface GetProvidersRequest {
    id: string;
    queryString?: string;
    appointmentType?: number;
    clinicId?: string;
    schedulingType?: GetProvidersSchedulingTypeEnum;
    usState?: string;
}


export interface IMemberProvidersApi {
      getProviders: (requestParameters: GetProvidersRequest) => Promise<MemberProviders>
      getProvidersWithResponse: (requestParameters: GetProvidersRequest) => Promise<{ value: MemberProviders, response: any}>
}

/**
 * no description
 */
export class MemberProvidersApi extends runtime.BaseAPI {

    /**
     * Fetch Member Providers
     * Fetch Member Providers
     */
    getProvidersRaw = async (requestParameters: GetProvidersRequest): Promise<{ runtime: runtime.ApiResponse<MemberProviders>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getProviders.');
        }

                                                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.queryString !== undefined) {
            queryParameters['query_string'] = requestParameters.queryString;
        }

        if (requestParameters.appointmentType !== undefined) {
            queryParameters['appointment_type'] = requestParameters.appointmentType;
        }

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinic_id'] = requestParameters.clinicId;
        }

        if (requestParameters.schedulingType !== undefined) {
            queryParameters['scheduling_type'] = requestParameters.schedulingType;
        }

        if (requestParameters.usState !== undefined) {
            queryParameters['us_state'] = requestParameters.usState;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/providers`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberProvidersFromJSON(jsonValue)), response };
    }

    /**
     * Fetch Member Providers
     * Fetch Member Providers
     */
    getProviders = async (requestParameters: GetProvidersRequest): Promise<MemberProviders> => {
        const { runtime } = await this.getProvidersRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch Member Providers
     * Fetch Member Providers
     */
    getProvidersWithResponse = async (requestParameters: GetProvidersRequest): Promise<{ value: MemberProviders, response: any}> => {
        const { runtime, response } = await this.getProvidersRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetProvidersSchedulingTypeEnum {
    VirtualVisitReferral = 'virtual_visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    SelfSchedule = 'self_schedule'
}

export const fetchMemberProvidersApi: IMemberProvidersApi  = new MemberProvidersApi();
