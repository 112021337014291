import React from "react";
import { useSelector } from "react-redux";
import { Link } from "components/Button";
import parseDoB from "shared/utils/parseDoB";
import useFormatDate from "shared/utils/useFormatDate";
import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { selectLoggedInUser } from "shared/features/user/selectors";
import { getFeatures } from "shared/features/featureFlags/selectors";
import { useGetUpcomingAppointments } from "shared/state/appointments";
import { Appointment } from "shared/fetch/src/models/Appointment";

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    link: {
      padding: 0,
      minWidth: 0,
      verticalAlign: "initial",
      "&:hover": {
        color: "white",
        textDecoration: "underline",
      },
      textDecoration: "underline",
    },
  })
);

interface MessageReturn {
  message?: JSX.Element;
  orderDate?: Date;
}

const useAppointmentsMessage = (): MessageReturn => {
  const classes = useStyles();
  const user = selectLoggedInUser();
  const formatDate = useFormatDate();
  const featureFlags = useSelector(getFeatures);

  const hasCheckin = featureFlags.hasCheckin();

  const { upcomingAppointments } = useGetUpcomingAppointments({
    id: user.id?.toString() || "",
  });

  const eligibleForApptBanner = upcomingAppointments?.results?.filter(
    (appt: Appointment) => appt?.eligibleForApptBanner
  );

  const appointment = hasCheckin
    ? eligibleForApptBanner?.[0]
    : upcomingAppointments?.results?.[0];

  if (!appointment) {
    return {};
  }
  const { patient, provider, startAt, xopReferral, episodeId, componentId } =
    appointment;

  const patientDob = patient?.dob
    ? formatDate(parseDoB(patient?.dob), "MM/dd/yyyy")
    : "N/A";
  const appointmentTime = formatDate(startAt, "h:mmaaa zzz");
  const appointmentDetailsLink = `/members/${patient?.id}/conversations/${episodeId}/component/${componentId}`;

  const getVisitDisplay = (isXopReferral?: boolean) => {
    return isXopReferral ? (
      <Link
        data-testid="referral-link"
        to={appointmentDetailsLink}
        className={classes.link}
      >
        visit
      </Link>
    ) : (
      "visit"
    );
  };

  const ctmText = `${patient?.legalAndPreferredName} (DOB: ${patientDob}) from ${patient?.employer}`;
  const memberText = `${provider?.name}`;

  return {
    message: (
      <span>
        Your {getVisitDisplay(xopReferral)} with{" "}
        {user.ctm ? ctmText : memberText} is starting @ {appointmentTime}
      </span>
    ),
    orderDate: appointment?.startAt,
  };
};

export default useAppointmentsMessage;
