import clsx from "clsx";
import styles from "./index.module.css";
import React from "react";
import { TypographyAppearance } from "../Typography";

interface IImprovedProps {
  appearance?: TypographyAppearance;
  fontWeightBold?: boolean;
  fontWeightNormal?: boolean;
  textDecorationUnderline?: boolean;
  gutterBottom?: boolean;
  className?: string;
  domRef?: React.RefObject<HTMLElement>;
  Component?: any;
  component?: any;
  children?: any;
  style?: any;
  onClick?: any;
  [key: string]: any;
}

const Paragraph = (props: any) => <p {...props} />;
const Span = (props: any) => <span {...props} />;

const ComponentType = {
  p: Paragraph,
  span: Span,
};

export const CssTypography = (props: IImprovedProps) => {
  const {
    className,
    appearance,
    fontWeightBold,
    fontWeightNormal,
    textDecorationUnderline,
    domRef,
    gutterBottom,
    Component = Paragraph,
    component,
    ...rest
  } = props;

  const appearanceStyle = appearance ? styles[appearance] : null;

  const Wrapper = React.useMemo(() => {
    return component ? ComponentType[component] : Component;
  }, [component, Component]);

  return (
    <span ref={domRef}>
      <Wrapper
        className={clsx(
          appearanceStyle,
          {
            [styles.fontWeightBold]: fontWeightBold,
            [styles.fontWeightNormal]: fontWeightNormal,
            [styles.textDecorationUnderline]: textDecorationUnderline,
            [styles.gutterBottom]: gutterBottom,
          },
          className
        )}
        {...rest}
      />
    </span>
  );
};

export default CssTypography;
