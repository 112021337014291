import { useSelector, useDispatch } from "react-redux";

import { getDraftState } from "shared/state/ui/member/selectors";
import { displayConfirmationsModal } from "shared/state/ui/confirmationsDialog";

const useDraftSafeHandler = (fn: any) => {
  const dispatch = useDispatch();
  const hasDraft = useSelector(getDraftState);

  return (...args: any) => {
    if (!hasDraft) {
      return fn(...args);
    } else {
      return dispatch(
        displayConfirmationsModal({
          onConfirm: () => {
            fn(...args);
          },
        })
      );
    }
  };
};

export default useDraftSafeHandler;
