// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     MemberAccountInfo,
    MemberAccountInfoFromJSON,
    MemberAccountInfoToJSON,
} from './MemberAccountInfo';
import {
     MemberAccountInfoOutputAllOf,
    MemberAccountInfoOutputAllOfFromJSON,
    MemberAccountInfoOutputAllOfToJSON,
} from './MemberAccountInfoOutputAllOf';
import {
     MemberAccountInfoOutputAllOfAssignedPcp,
    MemberAccountInfoOutputAllOfAssignedPcpFromJSON,
    MemberAccountInfoOutputAllOfAssignedPcpToJSON,
} from './MemberAccountInfoOutputAllOfAssignedPcp';
import {
     MemberAccountInfoOutputAllOfPrimaryAccountHolder,
    MemberAccountInfoOutputAllOfPrimaryAccountHolderFromJSON,
    MemberAccountInfoOutputAllOfPrimaryAccountHolderToJSON,
} from './MemberAccountInfoOutputAllOfPrimaryAccountHolder';
import {
     MemberDefaultClinicInfo,
    MemberDefaultClinicInfoFromJSON,
    MemberDefaultClinicInfoToJSON,
} from './MemberDefaultClinicInfo';
import {
     MemberServiceLineDetails,
    MemberServiceLineDetailsFromJSON,
    MemberServiceLineDetailsToJSON,
} from './MemberServiceLineDetails';
import {
     PermissionsEnum,
    PermissionsEnumFromJSON,
    PermissionsEnumToJSON,
} from './PermissionsEnum';
import {
     PodObjectDetails,
    PodObjectDetailsFromJSON,
    PodObjectDetailsToJSON,
} from './PodObjectDetails';
/**
 * @type MemberAccountInfoOutput
 * @export
 */
export interface MemberAccountInfoOutput extends MemberAccountInfo, MemberAccountInfoOutputAllOf {
}

export function MemberAccountInfoOutputFromJSON(json: any): MemberAccountInfoOutput {
    return {
        ...MemberAccountInfoFromJSON(json),
        ...MemberAccountInfoOutputAllOfFromJSON(json),
    };
}

export function MemberAccountInfoOutputToJSON(value?: MemberAccountInfoOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...MemberAccountInfoToJSON(value),
        ...MemberAccountInfoOutputAllOfToJSON(value),
    };
}

