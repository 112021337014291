import { useQuery } from "react-query";
import { fetchXopPodsApi } from "shared/fetch/src/apis/XopPodsApi";

export const getFetchMyPodsCareTeamMemberKey = () => [
  "fetchMyPodsCareTeamMembers",
];

const useFetchMyPodsCareTeamMembers = () => {
  const { data, isLoading } = useQuery(
    getFetchMyPodsCareTeamMemberKey(),
    fetchXopPodsApi.fetchMyPodsCareTeamMembers,
    {
      staleTime: 1000 * 60,
    }
  );

  return {
    data,
    isLoading,
  };
};

export default useFetchMyPodsCareTeamMembers;
