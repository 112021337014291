// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     Appointment,
    AppointmentFromJSON,
    AppointmentToJSON,
} from './Appointment';
import {
     CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
     CenterVisitReferralComponentOutputPropsAllOf,
    CenterVisitReferralComponentOutputPropsAllOfFromJSON,
    CenterVisitReferralComponentOutputPropsAllOfToJSON,
} from './CenterVisitReferralComponentOutputPropsAllOf';
import {
     CtmMessageComponentDetailsOutput,
    CtmMessageComponentDetailsOutputFromJSON,
    CtmMessageComponentDetailsOutputToJSON,
} from './CtmMessageComponentDetailsOutput';
import {
     MemberClinic,
    MemberClinicFromJSON,
    MemberClinicToJSON,
} from './MemberClinic';
import {
     ReferralComponentCore,
    ReferralComponentCoreFromJSON,
    ReferralComponentCoreToJSON,
} from './ReferralComponentCore';
import {
     ReferralComponentCoreDeclineReason,
    ReferralComponentCoreDeclineReasonFromJSON,
    ReferralComponentCoreDeclineReasonToJSON,
} from './ReferralComponentCoreDeclineReason';
import {
     ReferralComponentCoreVisitType,
    ReferralComponentCoreVisitTypeFromJSON,
    ReferralComponentCoreVisitTypeToJSON,
} from './ReferralComponentCoreVisitType';
/**
 * @type CenterVisitReferralComponentOutputProps
 * @export
 */
export interface CenterVisitReferralComponentOutputProps extends CenterVisitReferralComponentOutputPropsAllOf, ReferralComponentCore {
}

export function CenterVisitReferralComponentOutputPropsFromJSON(json: any): CenterVisitReferralComponentOutputProps {
    return {
        ...CenterVisitReferralComponentOutputPropsAllOfFromJSON(json),
        ...ReferralComponentCoreFromJSON(json),
    };
}

export function CenterVisitReferralComponentOutputPropsToJSON(value?: CenterVisitReferralComponentOutputProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...CenterVisitReferralComponentOutputPropsAllOfToJSON(value),
        ...ReferralComponentCoreToJSON(value),
    };
}

