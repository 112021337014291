// @ts-nocheck

import * as React from "react";
import OAuthRedirect from "./pages/OAuthRedirect";
import Auth0Redirect from "./pages/Auth0Redirect";
import { Route } from "react-router";
import InitiateBiometricSignin from "components/InitiateBiometricSignin";

export default () => [
  <Route
    key="biometric"
    path="/biometric"
    element={<InitiateBiometricSignin />}
  />,
  <Route
    key="oauth-redirect"
    path="/oauth-redirect"
    element={<OAuthRedirect />}
  />,
  <Route
    key="auth0-redirect"
    path="/auth0-redirect"
    element={<Auth0Redirect />}
  />,
];
