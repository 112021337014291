// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    XopQuestionCoreSchema,
    XopQuestionCoreSchemaFromJSON,
    XopQuestionCoreSchemaToJSON,
} from './XopQuestionCoreSchema';


/**
 * 
 * @export
 * @interface XopQuestionSearchOutputSchema
 */
export interface XopQuestionSearchOutputSchema  {
    /**
     * 
     * @type {number}
     * @memberof XopQuestionSearchOutputSchema
     */
    count?: number;
    /**
     * 
     * @type {Array<XopQuestionCoreSchema>}
     * @memberof XopQuestionSearchOutputSchema
     */
    results?: Array<XopQuestionCoreSchema>;
}

export function XopQuestionSearchOutputSchemaFromJSON(json: any): XopQuestionSearchOutputSchema {
    return {
        'count': !exists(json, 'count') ? undefined : json['count'],
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(XopQuestionCoreSchemaFromJSON),
    };
}

export function XopQuestionSearchOutputSchemaToJSON(value?: XopQuestionSearchOutputSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'count': value.count,
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(XopQuestionCoreSchemaToJSON),
    };
}


