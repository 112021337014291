/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    PrescriptionRefillRequestBody,
    PrescriptionRefillRequestBodyFromJSON,
    PrescriptionRefillRequestBodyToJSON,
} from '../models/PrescriptionRefillRequestBody'
import {
    PrescriptionsSchema,
    PrescriptionsSchemaFromJSON,
    PrescriptionsSchemaToJSON,
} from '../models/PrescriptionsSchema'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface CreatePrescriptionRefillRequest {
    id: string;
    prescriptionRefillRequestBody?: PrescriptionRefillRequestBody;
}
export interface GetPrescriptionRequest {
    id: string;
}


export interface IMemberPrescriptionsApi {
      createPrescriptionRefill: (requestParameters: CreatePrescriptionRefillRequest) => Promise<void>
      getPrescription: (requestParameters: GetPrescriptionRequest) => Promise<Array<PrescriptionsSchema>>
      createPrescriptionRefillWithResponse: (requestParameters: CreatePrescriptionRefillRequest) => Promise<{ value: void, response: any}>
      getPrescriptionWithResponse: (requestParameters: GetPrescriptionRequest) => Promise<{ value: Array<PrescriptionsSchema>, response: any}>
}

/**
 * no description
 */
export class MemberPrescriptionsApi extends runtime.BaseAPI {

    /**
     * Creates prescription refill Rxrefill
     * Creates prescription refill Rxrefill
     */
    createPrescriptionRefillRaw = async (requestParameters: CreatePrescriptionRefillRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createPrescriptionRefill.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/prescriptions/refills`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PrescriptionRefillRequestBodyToJSON(requestParameters.prescriptionRefillRequestBody),
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Creates prescription refill Rxrefill
     * Creates prescription refill Rxrefill
     */
    createPrescriptionRefill = async (requestParameters: CreatePrescriptionRefillRequest): Promise<void> => {
        const { runtime } = await this.createPrescriptionRefillRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Creates prescription refill Rxrefill
     * Creates prescription refill Rxrefill
     */
    createPrescriptionRefillWithResponse = async (requestParameters: CreatePrescriptionRefillRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.createPrescriptionRefillRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * Returns prescriptions from elation
     * Returns prescriptions from elation
     */
    getPrescriptionRaw = async (requestParameters: GetPrescriptionRequest): Promise<{ runtime: runtime.ApiResponse<Array<PrescriptionsSchema>>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getPrescription.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/prescriptions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PrescriptionsSchemaFromJSON)), response };
    }

    /**
     * Returns prescriptions from elation
     * Returns prescriptions from elation
     */
    getPrescription = async (requestParameters: GetPrescriptionRequest): Promise<Array<PrescriptionsSchema>> => {
        const { runtime } = await this.getPrescriptionRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Returns prescriptions from elation
     * Returns prescriptions from elation
     */
    getPrescriptionWithResponse = async (requestParameters: GetPrescriptionRequest): Promise<{ value: Array<PrescriptionsSchema>, response: any}> => {
        const { runtime, response } = await this.getPrescriptionRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchMemberPrescriptionsApi: IMemberPrescriptionsApi  = new MemberPrescriptionsApi();
