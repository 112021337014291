// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalculatorJsonBody
 */
export interface CalculatorJsonBody  {
    /**
     * 
     * @type {object}
     * @memberof CalculatorJsonBody
     */
    surveyJson?: object;
    /**
     * 
     * @type {string}
     * @memberof CalculatorJsonBody
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CalculatorJsonBody
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof CalculatorJsonBody
     */
    ctmFacingName?: string;
}

export function CalculatorJsonBodyFromJSON(json: any): CalculatorJsonBody {
    return {
        'surveyJson': !exists(json, 'survey_json') ? undefined : json['survey_json'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'ctmFacingName': !exists(json, 'ctm_facing_name') ? undefined : json['ctm_facing_name'],
    };
}

export function CalculatorJsonBodyToJSON(value?: CalculatorJsonBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'survey_json': value.surveyJson,
        'id': value.id,
        'name': value.name,
        'ctm_facing_name': value.ctmFacingName,
    };
}


