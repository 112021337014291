/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ActionableItemDetails,
    ActionableItemDetailsFromJSON,
    ActionableItemDetailsToJSON,
} from '../models/ActionableItemDetails'
import {
    ActionableItemsDetailsOutput,
    ActionableItemsDetailsOutputFromJSON,
    ActionableItemsDetailsOutputToJSON,
} from '../models/ActionableItemsDetailsOutput'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpdateCTMAIOwnerSchema,
    UpdateCTMAIOwnerSchemaFromJSON,
    UpdateCTMAIOwnerSchemaToJSON,
} from '../models/UpdateCTMAIOwnerSchema'
import {
    UpdateEpisodeCTMAIOwnerResponse,
    UpdateEpisodeCTMAIOwnerResponseFromJSON,
    UpdateEpisodeCTMAIOwnerResponseToJSON,
} from '../models/UpdateEpisodeCTMAIOwnerResponse'
import {
    UpdateEpisodeCTMAIOwnerSchema,
    UpdateEpisodeCTMAIOwnerSchemaFromJSON,
    UpdateEpisodeCTMAIOwnerSchemaToJSON,
} from '../models/UpdateEpisodeCTMAIOwnerSchema'


export interface ActiveCareTeamActionableItemsRequest {
    id: string;
}
export interface ActiveMemberActionableItemsRequest {
    id: string;
}
export interface FetchCareTeamMemberActionableItemRequest {
    id: string;
}
export interface FetchMemberActionableItemRequest {
    id: string;
}
export interface UpdateCTMAIOwnerRequest {
    episodeId: string;
    updateEpisodeCTMAIOwnerSchema?: UpdateEpisodeCTMAIOwnerSchema;
}
export interface UpdateCareTeamMemberActionableItemOwnerRequest {
    ctmAiId: string;
    updateCTMAIOwnerSchema?: UpdateCTMAIOwnerSchema;
}


export interface IActionableItemsApi {
      actionableItemsRedirectUrl: () => Promise<ActionableItemsDetailsOutput>
      activeCareTeamActionableItems: (requestParameters: ActiveCareTeamActionableItemsRequest) => Promise<ActionableItemsDetailsOutput>
      activeMemberActionableItems: (requestParameters: ActiveMemberActionableItemsRequest) => Promise<ActionableItemsDetailsOutput>
      fetchCareTeamMemberActionableItem: (requestParameters: FetchCareTeamMemberActionableItemRequest) => Promise<ActionableItemDetails>
      fetchMemberActionableItem: (requestParameters: FetchMemberActionableItemRequest) => Promise<ActionableItemDetails>
      updateCTMAIOwner: (requestParameters: UpdateCTMAIOwnerRequest) => Promise<UpdateEpisodeCTMAIOwnerResponse>
      updateCareTeamMemberActionableItemOwner: (requestParameters: UpdateCareTeamMemberActionableItemOwnerRequest) => Promise<ActionableItemDetails>
      actionableItemsRedirectUrlWithResponse: () => Promise<{ value: ActionableItemsDetailsOutput, response: any}>
      activeCareTeamActionableItemsWithResponse: (requestParameters: ActiveCareTeamActionableItemsRequest) => Promise<{ value: ActionableItemsDetailsOutput, response: any}>
      activeMemberActionableItemsWithResponse: (requestParameters: ActiveMemberActionableItemsRequest) => Promise<{ value: ActionableItemsDetailsOutput, response: any}>
      fetchCareTeamMemberActionableItemWithResponse: (requestParameters: FetchCareTeamMemberActionableItemRequest) => Promise<{ value: ActionableItemDetails, response: any}>
      fetchMemberActionableItemWithResponse: (requestParameters: FetchMemberActionableItemRequest) => Promise<{ value: ActionableItemDetails, response: any}>
      updateCTMAIOwnerWithResponse: (requestParameters: UpdateCTMAIOwnerRequest) => Promise<{ value: UpdateEpisodeCTMAIOwnerResponse, response: any}>
      updateCareTeamMemberActionableItemOwnerWithResponse: (requestParameters: UpdateCareTeamMemberActionableItemOwnerRequest) => Promise<{ value: ActionableItemDetails, response: any}>
}

/**
 * no description
 */
export class ActionableItemsApi extends runtime.BaseAPI {

    /**
     * Get actionable items redirect URL for the given member or care team member :id
     */
    actionableItemsRedirectUrlRaw = async (): Promise<{ runtime: runtime.ApiResponse<ActionableItemsDetailsOutput>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/actionable_items`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ActionableItemsDetailsOutputFromJSON(jsonValue)), response };
    }

    /**
     * Get actionable items redirect URL for the given member or care team member :id
     */
    actionableItemsRedirectUrl = async (): Promise<ActionableItemsDetailsOutput> => {
        const { runtime } = await this.actionableItemsRedirectUrlRaw();
        return await runtime.value();
    }

    /**
     * Get actionable items redirect URL for the given member or care team member :id
     */
    actionableItemsRedirectUrlWithResponse = async (): Promise<{ value: ActionableItemsDetailsOutput, response: any}> => {
        const { runtime, response } = await this.actionableItemsRedirectUrlRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch active care team actionable items for :id with :status
     */
    activeCareTeamActionableItemsRaw = async (requestParameters: ActiveCareTeamActionableItemsRequest): Promise<{ runtime: runtime.ApiResponse<ActionableItemsDetailsOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling activeCareTeamActionableItems.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/care_team_members/{id}/actionable_items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ActionableItemsDetailsOutputFromJSON(jsonValue)), response };
    }

    /**
     * Fetch active care team actionable items for :id with :status
     */
    activeCareTeamActionableItems = async (requestParameters: ActiveCareTeamActionableItemsRequest): Promise<ActionableItemsDetailsOutput> => {
        const { runtime } = await this.activeCareTeamActionableItemsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch active care team actionable items for :id with :status
     */
    activeCareTeamActionableItemsWithResponse = async (requestParameters: ActiveCareTeamActionableItemsRequest): Promise<{ value: ActionableItemsDetailsOutput, response: any}> => {
        const { runtime, response } = await this.activeCareTeamActionableItemsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch member actionable items for :id with :status
     */
    activeMemberActionableItemsRaw = async (requestParameters: ActiveMemberActionableItemsRequest): Promise<{ runtime: runtime.ApiResponse<ActionableItemsDetailsOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling activeMemberActionableItems.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/actionable_items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ActionableItemsDetailsOutputFromJSON(jsonValue)), response };
    }

    /**
     * Fetch member actionable items for :id with :status
     */
    activeMemberActionableItems = async (requestParameters: ActiveMemberActionableItemsRequest): Promise<ActionableItemsDetailsOutput> => {
        const { runtime } = await this.activeMemberActionableItemsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch member actionable items for :id with :status
     */
    activeMemberActionableItemsWithResponse = async (requestParameters: ActiveMemberActionableItemsRequest): Promise<{ value: ActionableItemsDetailsOutput, response: any}> => {
        const { runtime, response } = await this.activeMemberActionableItemsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get actionable item for the given :id
     */
    fetchCareTeamMemberActionableItemRaw = async (requestParameters: FetchCareTeamMemberActionableItemRequest): Promise<{ runtime: runtime.ApiResponse<ActionableItemDetails>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchCareTeamMemberActionableItem.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/care_team_actionable_items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ActionableItemDetailsFromJSON(jsonValue)), response };
    }

    /**
     * Get actionable item for the given :id
     */
    fetchCareTeamMemberActionableItem = async (requestParameters: FetchCareTeamMemberActionableItemRequest): Promise<ActionableItemDetails> => {
        const { runtime } = await this.fetchCareTeamMemberActionableItemRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get actionable item for the given :id
     */
    fetchCareTeamMemberActionableItemWithResponse = async (requestParameters: FetchCareTeamMemberActionableItemRequest): Promise<{ value: ActionableItemDetails, response: any}> => {
        const { runtime, response } = await this.fetchCareTeamMemberActionableItemRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get actionable item for the given :id
     */
    fetchMemberActionableItemRaw = async (requestParameters: FetchMemberActionableItemRequest): Promise<{ runtime: runtime.ApiResponse<ActionableItemDetails>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchMemberActionableItem.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/member_actionable_items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ActionableItemDetailsFromJSON(jsonValue)), response };
    }

    /**
     * Get actionable item for the given :id
     */
    fetchMemberActionableItem = async (requestParameters: FetchMemberActionableItemRequest): Promise<ActionableItemDetails> => {
        const { runtime } = await this.fetchMemberActionableItemRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get actionable item for the given :id
     */
    fetchMemberActionableItemWithResponse = async (requestParameters: FetchMemberActionableItemRequest): Promise<{ value: ActionableItemDetails, response: any}> => {
        const { runtime, response } = await this.fetchMemberActionableItemRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update owner of active active care team member actionable items of the given :episode_id
     */
    updateCTMAIOwnerRaw = async (requestParameters: UpdateCTMAIOwnerRequest): Promise<{ runtime: runtime.ApiResponse<UpdateEpisodeCTMAIOwnerResponse>, response: any}> => {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling updateCTMAIOwner.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/episodes/{episode_id}/active_care_team_actionable_items/owner`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEpisodeCTMAIOwnerSchemaToJSON(requestParameters.updateEpisodeCTMAIOwnerSchema),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => UpdateEpisodeCTMAIOwnerResponseFromJSON(jsonValue)), response };
    }

    /**
     * Update owner of active active care team member actionable items of the given :episode_id
     */
    updateCTMAIOwner = async (requestParameters: UpdateCTMAIOwnerRequest): Promise<UpdateEpisodeCTMAIOwnerResponse> => {
        const { runtime } = await this.updateCTMAIOwnerRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update owner of active active care team member actionable items of the given :episode_id
     */
    updateCTMAIOwnerWithResponse = async (requestParameters: UpdateCTMAIOwnerRequest): Promise<{ value: UpdateEpisodeCTMAIOwnerResponse, response: any}> => {
        const { runtime, response } = await this.updateCTMAIOwnerRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update Care team member actionable items owner of the given :ctm_ai_id
     */
    updateCareTeamMemberActionableItemOwnerRaw = async (requestParameters: UpdateCareTeamMemberActionableItemOwnerRequest): Promise<{ runtime: runtime.ApiResponse<ActionableItemDetails>, response: any}> => {
        if (requestParameters.ctmAiId === null || requestParameters.ctmAiId === undefined) {
            throw new runtime.RequiredError('ctmAiId','Required parameter requestParameters.ctmAiId was null or undefined when calling updateCareTeamMemberActionableItemOwner.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/care_team_actionable_items/{ctm_ai_id}/owner`.replace(`{${"ctm_ai_id"}}`, encodeURIComponent(String(requestParameters.ctmAiId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCTMAIOwnerSchemaToJSON(requestParameters.updateCTMAIOwnerSchema),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ActionableItemDetailsFromJSON(jsonValue)), response };
    }

    /**
     * Update Care team member actionable items owner of the given :ctm_ai_id
     */
    updateCareTeamMemberActionableItemOwner = async (requestParameters: UpdateCareTeamMemberActionableItemOwnerRequest): Promise<ActionableItemDetails> => {
        const { runtime } = await this.updateCareTeamMemberActionableItemOwnerRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update Care team member actionable items owner of the given :ctm_ai_id
     */
    updateCareTeamMemberActionableItemOwnerWithResponse = async (requestParameters: UpdateCareTeamMemberActionableItemOwnerRequest): Promise<{ value: ActionableItemDetails, response: any}> => {
        const { runtime, response } = await this.updateCareTeamMemberActionableItemOwnerRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchActionableItemsApi: IActionableItemsApi  = new ActionableItemsApi();
