import React from "react";
import Typography from "components/Typography";
import useStyles from "components/ScheduledVisit/Member/styles";
import useScheduledVisitMemberCheckInStateController from "../useScheduledVisitMemberCheckInStateController";
import { ReferralComponentCoreStateEnum } from "shared/fetch/src/models/ReferralComponentCore";

interface IProps {
  hasCheckIn: boolean;
  isVisitEligibleForCheckIn: boolean;
  componentState: ReferralComponentCoreStateEnum;
}

const CheckInButtonHelperText: React.FC<IProps> = ({
  hasCheckIn,
  isVisitEligibleForCheckIn,
  componentState,
}) => {
  const classes = useStyles();

  const {
    shouldElementRender,
    label,
  } = useScheduledVisitMemberCheckInStateController({
    hasCheckIn,
    isVisitEligibleForCheckIn,
    uiElementKey: "belowButtonCopy",
    componentState,
  });

  return (
    <>
      {shouldElementRender && (
        <Typography className={classes.checkinCopy}>{label}</Typography>
      )}
    </>
  );
};

export default CheckInButtonHelperText;
