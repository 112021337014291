import { createTheme, adaptV4Theme } from '@mui/material/styles';

import palette from "./palette";
import shadows from "./shadows";
import typography from "./typography";
import overrides from "./overrides";

const theme = createTheme(adaptV4Theme({
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
  palette,
  shadows,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
} as any));

export default theme;
