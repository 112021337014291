// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateConsent
 */
export interface CreateConsent  {
    /**
     * 
     * @type {string}
     * @memberof CreateConsent
     */
    configCode?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConsent
     */
    siteCode?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateConsent
     */
    documentId?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateConsent
     */
    signedOn?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConsent
     */
    signedDuring?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateConsent
     */
    signed?: boolean;
}

export function CreateConsentFromJSON(json: any): CreateConsent {
    return {
        'configCode': !exists(json, 'config_code') ? undefined : json['config_code'],
        'siteCode': !exists(json, 'site_code') ? undefined : json['site_code'],
        'documentId': !exists(json, 'document_id') ? undefined : json['document_id'],
        'signedOn': !exists(json, 'signed_on') ? undefined : json['signed_on'],
        'signedDuring': !exists(json, 'signed_during') ? undefined : json['signed_during'],
        'signed': !exists(json, 'signed') ? undefined : json['signed'],
    };
}

export function CreateConsentToJSON(value?: CreateConsent): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'config_code': value.configCode,
        'site_code': value.siteCode,
        'document_id': value.documentId,
        'signed_on': value.signedOn,
        'signed_during': value.signedDuring,
        'signed': value.signed,
    };
}


