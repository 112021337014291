// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConversationSummariesResponseTotals
 */
export interface ConversationSummariesResponseTotals  {
    /**
     * 
     * @type {number}
     * @memberof ConversationSummariesResponseTotals
     */
    all?: number;
    /**
     * 
     * @type {number}
     * @memberof ConversationSummariesResponseTotals
     */
    active?: number;
    /**
     * 
     * @type {number}
     * @memberof ConversationSummariesResponseTotals
     */
    archived?: number;
}

export function ConversationSummariesResponseTotalsFromJSON(json: any): ConversationSummariesResponseTotals {
    return {
        'all': !exists(json, 'all') ? undefined : json['all'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
    };
}

export function ConversationSummariesResponseTotalsToJSON(value?: ConversationSummariesResponseTotals): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'all': value.all,
        'active': value.active,
        'archived': value.archived,
    };
}


