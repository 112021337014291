import React, { useState } from "react";
import { Link } from "components/Button";
import { useNavigate, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import useFormatDate from "shared/utils/useFormatDate";
import { showSnackbar } from "shared/state/ui/snackbar";
import { Theme } from "@mui/material";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import useXoCheckin from "shared/features/checkin/useXoCheckin";
import { selectLoggedInUser } from "shared/features/user/selectors";
import { useGetComponentDetails } from "shared/features/components";
import { getFeatures } from "shared/features/featureFlags/selectors";
import { QueryObserverResult } from "react-query";
import { UpcomingAppointmentsResponse } from "shared/fetch/src/models/UpcomingAppointmentsResponse";
import useGetUpcomingAppointments from "shared/state/appointments/useGetUpcomingAppointments";
import { CheckinOutputStatusEnum } from "shared/fetch/src/models/CheckinOutput";
import { Appointment } from "shared/fetch/src/models/Appointment";
import { GetOrCreateXoCheckinRequest } from "shared/fetch/src/apis/CheckinApi";
import { visuallyHidden } from '@mui/utils';

const isCheckinOrSelfScheduleRoute = (currentPath: string) => {
  const selfScheduleRoute = /members\/\d+\/self-schedule/;
  const checkinRoute =
    /members\/\d+\/conversations\/\d+\/component\/\d+\/check-in/;
  const routesWithoutBanner = [selfScheduleRoute, checkinRoute];

  const matchesBannerLessRoutes =
    routesWithoutBanner.find((route) => {
      if (route instanceof RegExp) {
        return route.test(currentPath);
      }
      return route === currentPath;
    }) !== undefined;

  return matchesBannerLessRoutes;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: "underline",
      fontSize: theme?.typography?.bodySmall?.fontSize,
      marginRight: "8.33%",
      padding: "0px",
      lineHeight: "1.08125rem",
      "&:hover": {
        color: "white",
        textDecoration: "underline",
      },
    },
  })
);

interface MessageReturn {
  message?: JSX.Element;
  orderDate?: Date;
}

interface CheckinMessageProps {
  isRefreshingAppointments: boolean;
  refreshUpcomingAppointments: () => Promise<
    QueryObserverResult<UpcomingAppointmentsResponse, unknown>
  >;
}

const useCheckinMessage = ({
  isRefreshingAppointments,
  refreshUpcomingAppointments,
}: CheckinMessageProps): MessageReturn => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = selectLoggedInUser();
  const formatDate = useFormatDate();
  const { isPending: isCreateCheckinRequestPending, xoCheckin } =
    useXoCheckin();
  const [isLoading, setIsLoading] = useState(false);
  const featureFlags = useSelector(getFeatures);
  const hasCheckin = featureFlags.hasCheckin();
  const createCheckin = (request: GetOrCreateXoCheckinRequest) =>
    xoCheckin({ id: request?.id });
  const isCtm = user.ctm;

  const { upcomingAppointments } = useGetUpcomingAppointments({
    id: user?.id?.toString() || "",
  });

  const eligibleForCheckin = upcomingAppointments?.results?.filter(
    (appt: Appointment) => appt?.eligibleForCheckin
  );

  const appointment =
    eligibleForCheckin &&
    eligibleForCheckin.length > 0 &&
    eligibleForCheckin[0];

  const { refresh: refreshComponent } = useGetComponentDetails({
    // @ts-ignore: Object is possibly 'null'.
    id: appointment?.componentId || "",
  });

  if (
    !hasCheckin ||
    !appointment ||
    isCtm ||
    isCheckinOrSelfScheduleRoute(location.pathname)
  ) {
    return {};
  }

  const appointmentTime = formatDate(appointment?.startAt, "h:mmaaa zzz", {
    showAsTodayIfSameDay: true,
  });

  const providerName = `${appointment?.provider?.name}`;
  const visitType = `${appointment?.visitType}`;
  const visitDuration = appointment?.duration
    ? `${Number(appointment?.duration) / 60}`
    : "";
  const visitTime = `${appointmentTime}`;

  const memberId = user?.id;
  const episodeId = appointment?.episodeId;
  const componentId = appointment?.componentId;
  const detailsPageUrl = `/members/${memberId}/conversations/${episodeId}/component/${componentId}`;
  const checkinUrl = `/members/${memberId}/conversations/${episodeId}/component/${componentId}/check-in`;

  const hasMultipleAppointments =
    eligibleForCheckin && eligibleForCheckin?.length > 1;
  const bannerCopy = hasMultipleAppointments
    ? "It's time to check in for your upcoming Visits"
    : `It’s time to check in for your ${visitType} (${visitDuration} min) with ${providerName} ${
        !!visitTime.match(/^today/i) ? "" : "@"
      } ${visitTime}`;

  const checkinCompletedCopy = `Checkin for visit: ${visitType} (${visitDuration} min) with ${providerName} ${
    !!visitTime.match(/^today/i) ? "" : "@"
  } ${visitTime} was successfully completed!`;

  const handleClick = async () => {
    setIsLoading(true);
    // @ts-ignore: Object is possibly 'null'.
    const { value: checkIn, response } = await createCheckin({
      id: componentId!,
    });

    const hasNoCheckinWizard =
      response.status === 201 &&
      checkIn.status === CheckinOutputStatusEnum.Pending;

    if (hasNoCheckinWizard) {
      if (location.pathname.match(detailsPageUrl)) {
        await refreshComponent();
      } else {
        navigate(detailsPageUrl);
      }
      dispatch(showSnackbar(checkinCompletedCopy));
      await refreshUpcomingAppointments();
    } else {
      navigate(checkinUrl);
    }
    setIsLoading(false);
  };

  return {
    message: (
      <>
        <span data-testid="checkin-message-copy">{bannerCopy}</span>
        <Link
          onClick={handleClick}
          isLoading={isCreateCheckinRequestPending || isLoading}
          className={classes.link}
          disabled={
            isCreateCheckinRequestPending ||
            isLoading ||
            isRefreshingAppointments
          }
          data-pendo="checkin-banner-link"
        >
          Check in
          <span style={visuallyHidden}>{bannerCopy}</span>
        </Link>
      </>
    ),
    orderDate: appointment?.startAt,
  };
};

export default useCheckinMessage;
