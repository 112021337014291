// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InputServiceLineSchema,
    InputServiceLineSchemaFromJSON,
    InputServiceLineSchemaToJSON,
} from './InputServiceLineSchema';


/**
 * 
 * @export
 * @interface InputServiceLineSchemaAllOf2
 */
export interface InputServiceLineSchemaAllOf2  {
    /**
     * 
     * @type {Array<InputServiceLineSchema>}
     * @memberof InputServiceLineSchemaAllOf2
     */
    submenus: Array<InputServiceLineSchema>;
}

export function InputServiceLineSchemaAllOf2FromJSON(json: any): InputServiceLineSchemaAllOf2 {
    return {
        'submenus': (json['submenus'] as Array<any>).map(InputServiceLineSchemaFromJSON),
    };
}

export function InputServiceLineSchemaAllOf2ToJSON(value?: InputServiceLineSchemaAllOf2): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'submenus': (value.submenus as Array<any>).map(InputServiceLineSchemaToJSON),
    };
}


