// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RoleOutputCoreSchema,
    RoleOutputCoreSchemaFromJSON,
    RoleOutputCoreSchemaToJSON,
} from './RoleOutputCoreSchema';


/**
 * 
 * @export
 * @interface EpisodeRolesOutputSchema
 */
export interface EpisodeRolesOutputSchema  {
    /**
     * 
     * @type {Array<RoleOutputCoreSchema>}
     * @memberof EpisodeRolesOutputSchema
     */
    assignedRoles?: Array<RoleOutputCoreSchema>;
    /**
     * 
     * @type {Array<RoleOutputCoreSchema>}
     * @memberof EpisodeRolesOutputSchema
     */
    availableRoles?: Array<RoleOutputCoreSchema>;
}

export function EpisodeRolesOutputSchemaFromJSON(json: any): EpisodeRolesOutputSchema {
    return {
        'assignedRoles': !exists(json, 'assigned_roles') ? undefined : (json['assigned_roles'] as Array<any>).map(RoleOutputCoreSchemaFromJSON),
        'availableRoles': !exists(json, 'available_roles') ? undefined : (json['available_roles'] as Array<any>).map(RoleOutputCoreSchemaFromJSON),
    };
}

export function EpisodeRolesOutputSchemaToJSON(value?: EpisodeRolesOutputSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'assigned_roles': value.assignedRoles === undefined ? undefined : (value.assignedRoles as Array<any>).map(RoleOutputCoreSchemaToJSON),
        'available_roles': value.availableRoles === undefined ? undefined : (value.availableRoles as Array<any>).map(RoleOutputCoreSchemaToJSON),
    };
}


