import React, { FunctionComponent } from "react";
import clsx from "clsx";
import ListItemAvatarComponent, {
  ListItemAvatarProps,
} from "@mui/material/ListItemAvatar";
import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface IProps extends ListItemAvatarProps {
  /** The size to display. */
  size?: "small" | "medium" | "large";
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    small: {
      minWidth: 41,
      width: "100%",
    },
    medium: {},
  })
);

const ListItemAvatar: FunctionComponent<IProps> = (props) => {
  const { size = "medium", className, ...rest } = props;
  const classes = useStyles(props);
  return (
    <ListItemAvatarComponent
      className={clsx(classes[size], className)}
      {...rest}
    >
      {props.children}
    </ListItemAvatarComponent>
  );
};

export default ListItemAvatar;
