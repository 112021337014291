// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    EpisodeQueueSummary,
    EpisodeQueueSummaryFromJSON,
    EpisodeQueueSummaryToJSON,
} from './EpisodeQueueSummary';
import {
    EpisodeSummary,
    EpisodeSummaryFromJSON,
    EpisodeSummaryToJSON,
} from './EpisodeSummary';


/**
 * 
 * @export
 * @interface EpisodeQueueSummariesResponse
 */
export interface EpisodeQueueSummariesResponse  {
    /**
     * 
     * @type {string}
     * @memberof EpisodeQueueSummariesResponse
     */
    prevUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof EpisodeQueueSummariesResponse
     */
    nextUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof EpisodeQueueSummariesResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof EpisodeQueueSummariesResponse
     */
    last?: number;
    /**
     * 
     * @type {number}
     * @memberof EpisodeQueueSummariesResponse
     */
    count?: number;
    /**
     * 
     * @type {EpisodeSummary}
     * @memberof EpisodeQueueSummariesResponse
     */
    preventiveEpisode?: EpisodeSummary;
    /**
     * 
     * @type {Array<EpisodeQueueSummary>}
     * @memberof EpisodeQueueSummariesResponse
     */
    results: Array<EpisodeQueueSummary>;
}

export function EpisodeQueueSummariesResponseFromJSON(json: any): EpisodeQueueSummariesResponse {
    return {
        'prevUrl': !exists(json, 'prev_url') ? undefined : json['prev_url'],
        'nextUrl': !exists(json, 'next_url') ? undefined : json['next_url'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'preventiveEpisode': !exists(json, 'preventive_episode') ? undefined : EpisodeSummaryFromJSON(json['preventive_episode']),
        'results': (json['results'] as Array<any>).map(EpisodeQueueSummaryFromJSON),
    };
}

export function EpisodeQueueSummariesResponseToJSON(value?: EpisodeQueueSummariesResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'prev_url': value.prevUrl,
        'next_url': value.nextUrl,
        'page': value.page,
        'last': value.last,
        'count': value.count,
        'preventive_episode': EpisodeSummaryToJSON(value.preventiveEpisode),
        'results': (value.results as Array<any>).map(EpisodeQueueSummaryToJSON),
    };
}


