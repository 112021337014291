import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";

const NativeAppError: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const nativeErrorEventHandler = (e: any) => {
    if (e.detail.isSuccess) {
      dispatch(showSnackbar(e.detail.message));
    } else {
      dispatch(showSnackbar(e.detail.message, "danger"));
    }
  };

  useEffect(() => {
    window.addEventListener("nativeAppError", nativeErrorEventHandler);
    return () => {
      window.removeEventListener("nativeAppError", nativeErrorEventHandler);
    };
  }, []);

  return null;
};
export default NativeAppError;
