// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompactComponentReference,
    CompactComponentReferenceFromJSON,
    CompactComponentReferenceToJSON,
} from './CompactComponentReference';


/**
 * 
 * @export
 * @interface WorkspaceDraftDataSchemaDiagnosisBase
 */
export interface WorkspaceDraftDataSchemaDiagnosisBase  {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDraftDataSchemaDiagnosisBase
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDraftDataSchemaDiagnosisBase
     */
    viewedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDraftDataSchemaDiagnosisBase
     */
    actions?: string;
    /**
     * 
     * @type {Array<CompactComponentReference>}
     * @memberof WorkspaceDraftDataSchemaDiagnosisBase
     */
    childComponents?: Array<CompactComponentReference>;
}

export function WorkspaceDraftDataSchemaDiagnosisBaseFromJSON(json: any): WorkspaceDraftDataSchemaDiagnosisBase {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'viewedAt': !exists(json, 'viewed_at') ? undefined : json['viewed_at'],
        'actions': !exists(json, 'actions') ? undefined : json['actions'],
        'childComponents': !exists(json, 'child_components') ? undefined : (json['child_components'] as Array<any>).map(CompactComponentReferenceFromJSON),
    };
}

export function WorkspaceDraftDataSchemaDiagnosisBaseToJSON(value?: WorkspaceDraftDataSchemaDiagnosisBase): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'viewed_at': value.viewedAt,
        'actions': value.actions,
        'child_components': value.childComponents === undefined ? undefined : (value.childComponents as Array<any>).map(CompactComponentReferenceToJSON),
    };
}


