// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StoredPermissionSchema,
    StoredPermissionSchemaFromJSON,
    StoredPermissionSchemaToJSON,
} from './StoredPermissionSchema';


/**
 * 
 * @export
 * @interface AvailableStaffPermissionsListResponse
 */
export interface AvailableStaffPermissionsListResponse  {
    /**
     * 
     * @type {Array<StoredPermissionSchema>}
     * @memberof AvailableStaffPermissionsListResponse
     */
    roles?: Array<StoredPermissionSchema>;
}

export function AvailableStaffPermissionsListResponseFromJSON(json: any): AvailableStaffPermissionsListResponse {
    return {
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] as Array<any>).map(StoredPermissionSchemaFromJSON),
    };
}

export function AvailableStaffPermissionsListResponseToJSON(value?: AvailableStaffPermissionsListResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'roles': value.roles === undefined ? undefined : (value.roles as Array<any>).map(StoredPermissionSchemaToJSON),
    };
}


