// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonUser,
    JsonUserFromJSON,
    JsonUserToJSON,
} from './JsonUser';


/**
 * 
 * @export
 * @interface PatientAccessGrantResponse
 */
export interface PatientAccessGrantResponse  {
    /**
     * 
     * @type {string}
     * @memberof PatientAccessGrantResponse
     */
    memberAccessToken: string;
    /**
     * 
     * @type {JsonUser}
     * @memberof PatientAccessGrantResponse
     */
    member: JsonUser;
}

export function PatientAccessGrantResponseFromJSON(json: any): PatientAccessGrantResponse {
    return {
        'memberAccessToken': json['member_access_token'],
        'member': JsonUserFromJSON(json['member']),
    };
}

export function PatientAccessGrantResponseToJSON(value?: PatientAccessGrantResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'member_access_token': value.memberAccessToken,
        'member': JsonUserToJSON(value.member),
    };
}


