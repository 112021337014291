import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";
import { memberConsentsQueryKey } from "./useGetConsents";
import { UpdateConsentRequest, fetchConsentApi } from "shared/fetch/src/apis/ConsentApi";

const useUpdateConsentMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (request: UpdateConsentRequest) => fetchConsentApi.updateConsent(request),
    {
      onSuccess: (_data, request, _response) => {
        queryClient.invalidateQueries({
          queryKey: memberConsentsQueryKey(request.memberId),
        });
        dispatch(showSnackbar("Consent updated."));
      },
    }
  );

  return mutation;
};

export default useUpdateConsentMutation;
