export const genderIdentityMappingArray = [
  {
    memberLabel: "Woman",
    ctmLabel: "Woman",
    value: "woman",
  },
  {
    memberLabel: "Man",
    ctmLabel: "Man",
    value: "man",
  },
  {
    memberLabel: "Transgender woman / transfeminine",
    ctmLabel: "Transgender woman / transfeminine",
    value: "transgender_woman",
  },
  {
    memberLabel: "Transgender man / transmasculine",
    ctmLabel: "Transgender man / transmasculine",
    value: "transgender_man",
  },
  {
    memberLabel: "Non-binary / genderqueer / gender fluid",
    ctmLabel: "Non-binary / genderqueer / gender fluid",
    value: "nonbinary",
  },
  {
    memberLabel: "Two-Spirit",
    ctmLabel: "Two-Spirit",
    value: "two_spirit",
  },
  {
    memberLabel: "Option not listed (please inform provider)",
    ctmLabel: "Option not listed (check member chart)",
    value: "option_not_listed",
  },
  {
    memberLabel: "Prefer not to say",
    ctmLabel: "Prefer not to say",
    value: "prefer_not_to_say",
  },
  {
    memberLabel: "Don’t know",
    ctmLabel: "Don’t know",
    value: "do_not_know",
  },
];

export const sexualOrientationMappingArray = [
  {
    memberLabel: "Asexual",
    ctmLabel: "Asexual",
    value: "asexual",
  },
  {
    memberLabel: "Bisexual",
    ctmLabel: "Bisexual",
    value: "bisexual",
  },
  {
    memberLabel: "Gay",
    ctmLabel: "Gay",
    value: "gay",
  },
  {
    memberLabel: "Lesbian",
    ctmLabel: "Lesbian",
    value: "lesbian",
  },
  {
    memberLabel: "Straight",
    ctmLabel: "Straight",
    value: "straight",
  },
  {
    memberLabel: "Queer",
    ctmLabel: "Queer",
    value: "queer",
  },
  {
    memberLabel: "Don’t know",
    ctmLabel: "Don’t know",
    value: "do_not_know",
  },
  {
    memberLabel: "Option not listed (please inform provider)",
    ctmLabel: "Option not listed (check member chart)",
    value: "option_not_listed",
  },
  {
    memberLabel: "Prefer not to say",
    ctmLabel: "Prefer not to say",
    value: "prefer_not_to_say",
  },
];

export const raceMappingArray = [
  {
    label: "American Indian or Alaskan Native",
    value: "american_indian",
  },
  {
    label: "Asian",
    value: "asian",
  },
  {
    label: "Black or African American",
    value: "black",
  },
  {
    label: "Native Hawaiian or Other Pacific Islander",
    value: "native_pacific",
  },
  {
    label: "White",
    value: "white",
  },
  {
    label: "Prefer not to say",
    value: "prefer_not_to_say",
  },
];

export const ethnicityMappingArray = [
  {
    label: "Hispanic or Latino",
    value: "hispanic",
  },
  {
    label: "Not Hispanic or Latino",
    value: "not_hispanic",
  },
  {
    label: "Prefer not to say",
    value: "prefer_not_to_say",
  },
];

export const pronounsMappingArray = [
  {
    memberLabel: "She / Her / Hers",
    ctmLabel: "She / Her / Hers",
    value: "she_her_hers",
  },
  {
    memberLabel: "He / Him / His",
    ctmLabel: "He / Him / His",
    value: "he_him_his",
  },
  {
    memberLabel: "They / Them / Theirs",
    ctmLabel: "They / Them / Theirs",
    value: "they_them_theirs",
  },
  {
    memberLabel: "Option not listed (please inform provider)",
    ctmLabel: "Option not listed (check member chart)",
    value: "option_not_listed",
  },
];

export const sexAssignedAtBirthMappingArray = [
  { label: "Female", value: "F" },
  { label: "Male", value: "M" },
  { label: "Intersex", value: "I" },
];

export const genderMappingArray = [
  { label: "Female", value: "F" },
  { label: "Male", value: "M" },
  { label: "Intersex", value: "I" },
];

export const getGenderIdentity = (
  genderIdentity: string,
  isCtm: boolean = false
): string => {
  const genderIdentityMapping = {
    woman: "Woman",
    man: "Man",
    transgender_woman: "Transgender woman / transfeminine",
    transgender_man: "Transgender man / transmasculine",
    nonbinary: "Non-binary / genderqueer / gender fluid",
    two_spirit: "Two-Spirit",
    option_not_listed: isCtm
      ? "Option not listed (check member chart)"
      : "Option not listed (please inform provider)",
    prefer_not_to_say: "Prefer not to say",
    do_not_know: "Don’t know",
  };

  return genderIdentityMapping[genderIdentity] || "Not provided";
};

export const getSexualOrientation = (
  sexualOrientation: string,
  isCtm: boolean = false
): string => {
  const sexualOrientationMapping = {
    asexual: "Asexual",
    bisexual: "Bisexual",
    gay: "Gay",
    lesbian: "Lesbian",
    straight: "Straight",
    queer: "Queer",
    do_not_know: "Don’t know",
    option_not_listed: isCtm
      ? "Option not listed (check member chart)"
      : "Option not listed (please inform provider)",
    prefer_not_to_say: "Prefer not to say",
  };

  return sexualOrientationMapping[sexualOrientation] || "Not provided";
};

export const getPronouns = (
  pronouns: string,
  isCtm: boolean = false
): string => {
  const pronounsMapping = {
    she_her_hers: "She / Her / Hers",
    he_him_his: "He / Him / His",
    they_them_theirs: "They / Them / Theirs",
    option_not_listed: isCtm
      ? "Option not listed (check member chart)"
      : "Option not listed (please inform provider)",
  };

  return pronounsMapping[pronouns] || "Not provided";
};

export const getCTMSidebarPronouns = (pronouns: string): string => {
  const pronounsMapping = {
    she_her_hers: "She / Her / Hers",
    he_him_his: "He / Him / His",
    they_them_theirs: "They / Them / Theirs",
    option_not_listed: "Pronoun option not listed (check member chart)",
  };

  return pronounsMapping[pronouns] || "Not provided";
};

export const getSexAssignedAtBirth = (sex?: string): string => {
  const sexAssignedAtBirthMapping = {
    F: "Female",
    M: "Male",
    I: "Intersex",
  };

  return sexAssignedAtBirthMapping[sex!] || "Not provided";
};

export const getGender = (gender?: string): string => {
  const genderMapping = {
    F: "Female",
    M: "Male",
    I: "Intersex",
  };

  return genderMapping[gender!] || "Not provided";
};

export const getRace = (race: string): string => {
  const raceMapping = {
    american_indian: "American Indian or Alaskan Native",
    asian: "Asian",
    black: "Black or African American",
    native_pacific: "Native Hawaiian or Other Pacific Islander",
    white: "White",
    prefer_not_to_say: "Prefer not to say",
  };
  return raceMapping[race] || "Not provided";
};

export const getEthnicity = (ethnicity: string): string => {
  const ethnicityMapping = {
    hispanic: "Hispanic or Latino",
    not_hispanic: "Not Hispanic or Latino",
    prefer_not_to_say: "Prefer not to say",
  };
  return ethnicityMapping[ethnicity] || "Not provided";
};
