import { xopCtmProfile } from "shared/api/src/apis/MeetYourCareTeamApi";
import { CTMBioProfile } from "shared/fetch/src/models/CTMBioProfile";

const getProvider = (id: string) =>
  xopCtmProfile(
    { id },
    {
      transform: (body: CTMBioProfile) => {
        return {
          networkProvider: { [id]: body },
        };
      },
      update: {
        networkProvider: (
          prev: { [key: string]: CTMBioProfile },
          next: { [key: string]: CTMBioProfile }
        ) => ({ ...prev, ...next }),
      },
      force: true,
    }
  );

export default getProvider;
