// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppointmentNoteOutput
 */
export interface AppointmentNoteOutput  {
    /**
     * 
     * @type {number}
     * @memberof AppointmentNoteOutput
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentNoteOutput
     */
    authorName?: string;
    /**
     * 
     * @type {string}
     * @memberof AppointmentNoteOutput
     */
    authorNameAndTitle?: string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentNoteOutput
     */
    appointmentId?: number;
    /**
     * 
     * @type {string}
     * @memberof AppointmentNoteOutput
     */
    note?: string;
    /**
     * 
     * @type {number}
     * @memberof AppointmentNoteOutput
     */
    createdBy?: number;
    /**
     * 
     * @type {Date}
     * @memberof AppointmentNoteOutput
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof AppointmentNoteOutput
     */
    noteType?: string;
}

export function AppointmentNoteOutputFromJSON(json: any): AppointmentNoteOutput {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'authorName': !exists(json, 'author_name') ? undefined : json['author_name'],
        'authorNameAndTitle': !exists(json, 'author_name_and_title') ? undefined : json['author_name_and_title'],
        'appointmentId': !exists(json, 'appointment_id') ? undefined : json['appointment_id'],
        'note': !exists(json, 'note') ? undefined : json['note'],
        'createdBy': !exists(json, 'created_by') ? undefined : json['created_by'],
        'createdAt': !exists(json, 'created_at') ? undefined : new Date(json['created_at']),
        'noteType': !exists(json, 'note_type') ? undefined : json['note_type'],
    };
}

export function AppointmentNoteOutputToJSON(value?: AppointmentNoteOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'author_name': value.authorName,
        'author_name_and_title': value.authorNameAndTitle,
        'appointment_id': value.appointmentId,
        'note': value.note,
        'created_by': value.createdBy,
        'created_at': value.createdAt === undefined ? undefined : value.createdAt.toISOString(),
        'note_type': value.noteType,
    };
}


