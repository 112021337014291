import useGetAccountInfo from "shared/features/memberProfile/useGetAccountInfo";
import { selectLoggedInUser } from "shared/features/user/selectors";

const useGetUserTimezone = () => {
  const user = selectLoggedInUser();

  const currentUserId = user?.id?.toString() || "";

  const { accountInfo = {} } = useGetAccountInfo({ id: currentUserId });
  const { timezone: userPreferredTimezone } = accountInfo;
  return userPreferredTimezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export default useGetUserTimezone;