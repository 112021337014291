import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  ButtonBase,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Button from "components/Button";
import Typography from "components/Typography";
import TextField from "components/TextField";
import { format } from "date-fns";
import classes from "./index.module.css";
import useCreateVisitNotes from "shared/features/visitNotes/useCreateVisitNotes";
import { Delete } from "@mui/icons-material";
import CalendarWarningIcon from "../XOCal/SlotActionDrawer/CalendarWarningIcon";
import DialogContentText from "components/Dialog/DialogContentText";
import DialogActions from "components/Dialog/DialogActions";
import useDeleteVisitNote from "shared/features/visitNotes/useDeleteVisitNote";
import { getFetchSlotQueryKey } from "shared/features/xocal/useGetSlot";
import { useQueryClient } from "react-query";
import { AppointmentOutput } from "shared/fetch/src/models/AppointmentOutput";
import { Appointment } from "shared/fetch/src/models/Appointment";
import { showSnackbar } from "shared/state/ui/snackbar";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getUser } from "shared/features/user";

interface Props {
  open: boolean;
  onClose: () => void;
  appointmentType?: string;
  appointment: AppointmentOutput | Appointment;
}

const isXOCalAppointmentType = (appointment: any) => {
  if ((appointment as AppointmentOutput).patientId) {
    return true;
  }
  return false;
};

const VisitNotesModal = ({
  open,
  onClose,
  appointment,
  appointmentType,
}: Props) => {
  const [addingANote, setAddingANote] = useState(false);
  const [note, setNote] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [deletingNoteId, setDeletingNoteId] = useState<number | null>(null);
  const queryClient = useQueryClient();
  const isXOCal = isXOCalAppointmentType(appointment);
  const patientId = (appointment as Appointment)?.patient?.id;
  const dispatch = useDispatch();
  const currentUser = useSelector(getUser);

  const { mutateAsync: createVisitNote, isLoading } =
    useCreateVisitNotes(isXOCal);
  const { mutateAsync: deleteVisitNote, isLoading: deleteNoteLoading } =
    useDeleteVisitNote({
      onSuccess: () => {
        dispatch(showSnackbar("Success! The visit note has been deleted."));
        if (isXOCal) {
          queryClient.invalidateQueries(
            getFetchSlotQueryKey({ id: appointment?.slotId?.toString() })
          );
        } else {
          queryClient.invalidateQueries(["visits", patientId?.toString()]);
        }
        setDeleteModalOpen(false);
      },
    });

  const handleSaveNote = async () => {
    await createVisitNote({
      id: appointment?.id.toString() || "",
      createAppointmentNotes: {
        note,
      },
    }).then(() => {
      dispatch(showSnackbar("Success! You added a visit note."));
      setAddingANote(false);
      setNote("");
    });
    setAddingANote(false);
    setNote("");
  };

  const handleDeleteNote = async () => {
    await deleteVisitNote({
      id: deletingNoteId?.toString()!,
    });
  };

  const handleClose = () => {
    setAddingANote(false);
    setNote("");
    setDeleteModalOpen(false);
    onClose();
  };

  const renderWarning = () => {
    return (
      <div className={classes.deleteModal} data-testid="cancel-confirm-modal">
        <div className={classes.deleteModalTitleContainer}>
          <CalendarWarningIcon />
          <ButtonBase
            className={classes.deleteModalCancel}
            onClick={() => setDeleteModalOpen(false)}
            aria-label="Close delete modal"
          >
            <CloseIcon className={classes.closeIcon} />
          </ButtonBase>
        </div>
        <DialogTitle className={classes.deleteModalTitle}>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant="body1" sx={{ color: "black" }}>
            If you delete this, it's gone for good
          </DialogContentText>
          <DialogActions className={classes.deleteNoteModalActions}>
            <Button
              color="primary"
              onClick={handleDeleteNote}
              aria-label="Yes, delete. If you delete it is gone for good."
              isLoading={deleteNoteLoading}
            >
              Delete
            </Button>
            <Button
              color="link-primary"
              onClick={() => setDeleteModalOpen(false)}
              aria-label="Cancel, do not delete the note"
            >
              Cancel
            </Button>
          </DialogActions>
        </DialogContent>
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={deleteModalOpen ? "xs" : "sm"}
      fullWidth
    >
      {deleteModalOpen ? (
        renderWarning()
      ) : (
        <div data-testid="visit-notes-modal" style={{ height: 395 }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={() => ({
              position: "absolute",
              right: 12,
              top: 8,
            })}
          >
            <CloseIcon />
          </IconButton>

          <DialogTitle className={classes.visitNotesModalTitle}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Visit notes
            </Typography>
          </DialogTitle>
          <DialogContent className={classes.visitNotesModalContent}>
            <div className={classes.memberInformation}>
              <Typography variant="h6" className={classes.memberName}>
                {isXOCal
                  ? (appointment as AppointmentOutput).patientInfo?.name
                  : (appointment as Appointment).patient?.name}
              </Typography>
              <Typography variant="body1" className={classes.memberCaption}>
                {appointmentType}
              </Typography>
            </div>
            {addingANote ? (
              <div className={classes.addNoteContainer}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "bold" }}
                  onClick={() => setAddingANote(true)}
                >
                  Add note
                </Typography>
                <TextField
                  placeholder="Write a visit note..."
                  multiline
                  data-testid="visit-note-textarea"
                  className={classes.textArea}
                  rows={4}
                  fullWidth
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  margin="normal"
                  aria-label="Add a note"
                  inputProps={{
                    "aria-label": "Add a note",
                  }}
                />
                <div>
                  <Button
                    color="primary"
                    size="small"
                    disabled={!note}
                    isLoading={isLoading}
                    onClick={handleSaveNote}
                  >
                    Save
                  </Button>
                  <Button
                    color="link-primary"
                    size="small"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeader}>DATE</TableCell>
                    <TableCell className={classes.tableHeader}>NOTE</TableCell>
                    <TableCell className={classes.tableHeader}>
                      AUTHOR
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        color="link-primary"
                        size="small"
                        onClick={() => setAddingANote(true)}
                      >
                        Add note
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {appointment.appointmentNotes?.map((appointmentNote: any) => (
                    <TableRow
                      key={appointmentNote.createdAt?.getMilliseconds()}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell>
                        {format(
                          new Date(appointmentNote.createdAt!),
                          "MM/dd/yy"
                        )}
                        <br />
                        {format(
                          new Date(appointmentNote.createdAt!),
                          "hh:mm a z"
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography data-testid="visit-note-text">
                          {appointmentNote.note}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          className={classes.authorName}
                        >
                          {appointmentNote.authorNameAndTitle}
                        </Typography>

                        {appointmentNote.noteType ? (
                          <span className={classes.systemNoteChip}>
                            SYSTEM NOTE
                          </span>
                        ) : (
                          <span className={classes.manualNoteChip}>
                            MANUAL NOTE
                          </span>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {!appointmentNote.noteType &&
                          appointmentNote.createdBy === currentUser?.id && (
                            <Button
                              aria-label="Delete visit note"
                              color="inherit"
                              size="small"
                              sx={{ minWidth: "0px", padding: "0px" }}
                              onClick={() => {
                                setDeletingNoteId(appointmentNote.id!);
                                setDeleteModalOpen(true);
                              }}
                              data-testid="delete-visit-note"
                            >
                              <Delete />
                            </Button>
                          )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </DialogContent>
        </div>
      )}
    </Dialog>
  );
};

export default VisitNotesModal;
