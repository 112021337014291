import React from "react";

export const useMenuAnchorElement = () => {
  const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(
    null
  );
  const onAnchorElementClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElement(event.currentTarget);
  };
  const onMenuClose = () => {
    setAnchorElement(null);
  };
  return {
    anchorElement,
    onAnchorElementClick,
    onMenuClose,
  };
};
