import React, { FunctionComponent } from "react";
import { ButtonBase, ButtonGroup } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import Tab from "components/Tabs/Tab";
import Grid from "components/Grid";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CtmFilterSetting } from "shared/state/ui/settings";

interface IProps {
  open: boolean;
  openModal: Function;
  onClick: Function;
  index: number;
  selectedProviders: CtmFilterSetting[];
  active?: boolean;
}

interface IStyleProps {
  active?: boolean;
}

const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    ctmTab: {
      color: "#ffffff",
      padding: 0,
      marginRight: spacing(-1),
    },
    container: {
      height: "100%",
    },
    ctmLabel: {
      marginLeft: spacing(-0.5),
      paddingRight: spacing(0.75),
      borderRight: "1px solid #ffffff",
      alignSelf: "center",
    },
    ctmButtonLeftSide: {
      padding: 0,
      color: "#ffffff",
      minWidth: 0,
      fontSize: "13.3px",
      border: "none",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
        border: "none",
        color: "#ffffff",
      },
    },
    ctmButtonRightSide: {
      padding: 0,
      color: "#ffffff",
      minWidth: 0,
      alignSelf: "center",
      backgroundColor: "transparent",
      "&:hover": {
        backgroundColor: "transparent",
        border: "none",
        color: "#ffffff",
      },
    },
    arrow: {
      paddingLeft: spacing(0.5),
      color: "#ffffff",
    },
    tab: {
      "& [class*='MuiTab-root']": {
        backgroundColor: ({ active }: IStyleProps) =>
          active ? palette.secondary.main : palette?.lightGrayBackdrop,
      },
    },
  })
);

const CTMQueueFilterTab: FunctionComponent<IProps> = ({
  open,
  openModal,
  onClick,
  index,
  selectedProviders,
  active,
}): JSX.Element => {
  const classes = useStyles({ active });
  const tabWidthWithNumber = selectedProviders?.length > 9 ? "100px" : "85px";
  const tabWidth = selectedProviders?.length ? tabWidthWithNumber : "60px";

  return (
    <div className={classes.tab}>
      <Tab
        aria-label="CTM"
        value={index}
        label={
          <div className={classes.ctmTab}>
            <ButtonGroup aria-label="CTM">
              <Grid container style={{ width: `${tabWidth}` }}>
                <Grid item xs={8} className={classes.ctmLabel}>
                  <ButtonBase
                    data-e2e="ctm-filter-button"
                    className={classes.ctmButtonLeftSide}
                    onClick={() => onClick(index)}
                    data-testid="ctm-filter"
                  >
                    {selectedProviders?.length
                      ? `CTM (${selectedProviders?.length})`
                      : "CTM"}
                  </ButtonBase>
                </Grid>
                <Grid item xs={4}>
                  <ButtonBase
                    data-e2e="select-ctm-filter-button"
                    className={classes.ctmButtonRightSide}
                    onClick={() => openModal(!open)}
                    aria-label="Select one more more CTMs"
                  >
                    <ArrowDropDownIcon className={classes.arrow} />
                  </ButtonBase>
                </Grid>
              </Grid>
            </ButtonGroup>
          </div>
        }
      />
    </div>
  );
};

export default CTMQueueFilterTab;
