// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TimelineEpisodeTimelineEventsOutputResults,
    TimelineEpisodeTimelineEventsOutputResultsFromJSON,
    TimelineEpisodeTimelineEventsOutputResultsToJSON,
} from './TimelineEpisodeTimelineEventsOutputResults';


/**
 * 
 * @export
 * @interface TimelineEpisodeTimelineEventsOutput
 */
export interface TimelineEpisodeTimelineEventsOutput  {
    /**
     * 
     * @type {TimelineEpisodeTimelineEventsOutputResults}
     * @memberof TimelineEpisodeTimelineEventsOutput
     */
    results?: TimelineEpisodeTimelineEventsOutputResults;
    /**
     * Total number of unread components in response
     * @type {number}
     * @memberof TimelineEpisodeTimelineEventsOutput
     */
    totalUnread: number;
}

export function TimelineEpisodeTimelineEventsOutputFromJSON(json: any): TimelineEpisodeTimelineEventsOutput {
    return {
        'results': !exists(json, 'results') ? undefined : TimelineEpisodeTimelineEventsOutputResultsFromJSON(json['results']),
        'totalUnread': json['total_unread'],
    };
}

export function TimelineEpisodeTimelineEventsOutputToJSON(value?: TimelineEpisodeTimelineEventsOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': TimelineEpisodeTimelineEventsOutputResultsToJSON(value.results),
        'total_unread': value.totalUnread,
    };
}


