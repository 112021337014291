import React, { FC } from "react";
import clsx from "clsx";
import sortBy from "lodash/sortBy";

import { Link } from "react-router-dom";
import Tooltip from "components/Tooltip";
import EocBadge from "components/EocBadge";
import { getInCompleteActionableItems } from "../../EOCTimeline/utils";
import { JsonUser as User } from "shared/api/src/models/JsonUser";
import styles from "./index.module.css";
import { CssTypography as Typography } from "../../CssTypography";
import { ActionableItemSummary } from "shared/fetch/src/models/ActionableItemSummary";

interface IProps {
  user?: User;
  memberId?: string;
  showOutOfStateBadge: boolean;
  items?: ActionableItemSummary[];
}

const Content = React.forwardRef((props: any, ref: any) => {
  return (
    <div
      style={{
        display: "inline-block",
      }}
      {...props}
      ref={ref}
    >
      {props.children}
    </div>
  );
});

Content.displayName = "Content";

const EOCActionableItems: FC<IProps> = ({
  items,
  memberId,
  showOutOfStateBadge,
}) => {
  const classes = styles;
  // @ts-ignore: Object is possibly 'null'.
  const activeItems = getInCompleteActionableItems(items);

  const sortedActiveItems = sortBy(activeItems, ["state", "actionType"]);

  const itemList = sortedActiveItems
    .slice(0, 3)
    .map((item: ActionableItemSummary, idx: number, array: any) => {
      const hasOutOfStateBadge =
        showOutOfStateBadge && item.memberCurrentUsState;
      let to = "";
      const hasRouteInAi = memberId && item?.componentId && item?.episodeId;
      const isAcknowledgeCheckIn =
        item.todoDisplayLabel?.match(/acknowledge check in/i) || false;
      if (hasRouteInAi) {
        to = `/members/${memberId}/conversations/${item.episodeId}/component/${
          item.componentId
        }${isAcknowledgeCheckIn ? "?modal=calculators" : ""}`;
      }

      return (
        <li
          key={item?.id}
          data-test="actionable-item"
          // @ts-ignore: Object is possibly 'null'.
          className={idx < array.length - 1 && classes.padding16}
        >
          <Typography
            appearance="smallBody"
            fontWeightBold
            className={clsx(classes.marginBottom4, classes.secondaryMain)}
          >
            <Link
              onClick={(e: React.MouseEvent<HTMLElement>) =>
                e.stopPropagation()
              }
              to={to}
              className={classes.itemLink}
            >
              {item?.queueCopyDisplayLabel ?? item?.todoDisplayLabel}
            </Link>

            {hasOutOfStateBadge && (
              <Tooltip
                placement="bottom-start"
                className={classes.badgeTooltip}
                title={"State where member is currently located"}
              >
                <EocBadge
                  badge={{
                    color: "#FFF",
                    backgroundColor: "#7C37A5",
                    // @ts-ignore: Object is possibly 'null'.
                    abbreviation: item.memberCurrentUsState,
                  }}
                >
                  {item.memberCurrentUsState}
                </EocBadge>
              </Tooltip>
            )}
          </Typography>
          <div>
            <Typography appearance="smallBody" className={styles.textSecondary}>
              {item?.ownershipStatus}
            </Typography>
          </div>
        </li>
      );
    });
  return (
    <section
      data-testid="eoc-actionable-items"
      className={classes.eocActionableItems}
    >
      <div className={clsx(classes.eocActionableItem, classes.flex)}>
        <div className={classes.activeItems}>
          <Typography
            appearance="smallBody"
            fontWeightBold
            className={classes.secondaryMain}
          >
            {activeItems?.length ? activeItems?.length : null}
          </Typography>
        </div>
        <ul className={classes.eocActionableItemsList}>
          {itemList.length ? (
            itemList
          ) : (
            <li data-test="actionable-item">
              <Typography
                appearance="smallBody"
                className={classes.secondaryMain}
              >
                No to-do's
              </Typography>
            </li>
          )}
        </ul>
      </div>
    </section>
  );
};

export default EOCActionableItems;
