import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Theme } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useSelector } from "react-redux";
import HierarchicalMenu from "components/HierarchicalMenu";
import UrgentModal from "components/EOCTimeline/UrgentModal";
import { getUser } from "shared/features/user";
import Asset from "components/Asset";
import Box from "components/Box";
import IconButton from "components/Button/IconButton";

interface IProps {
  open: boolean;
  items?: any;
  onClose?: () => void;
}

const useStyles: any = makeStyles((theme: Theme) =>
  createStyles({
    sideDrawer: {
      "& .MuiBackdrop-root, & .MuiDrawer-paperAnchorLeft": {
        top: 60,
      },
      "& .MuiDrawer-paperAnchorLeft": {
        color: theme.palette.text.secondary,
        boxShadow: "none",
        borderTop: `solid 1px ${theme.palette?.strokes?.divider}`,
      },
    },
  })
);

const CloseButton = (props: any) => (
  <Box
    color="text.secondary"
    style={{ position: "absolute", right: 3, top: 11 }}
  >
    <IconButton
      key="close"
      aria-label="close"
      color="inherit"
      onClick={(e) => {
        if (props.onClose) {
          props.onClose(e);
        }
      }}
      size="large">
      <Asset
        name="icon-close"
        style={{ width: 16, height: 16, fill: "currentColor" }}
      />
    </IconButton>
  </Box>
);

const CtmDrawer = ({ open, items, onClose = () => undefined }: IProps) => {
  const [openUrgent, setOpenUrgent] = useState(false);
  const classes = useStyles();
  const user = useSelector(getUser);

  const handleItemClick = (item: any) => {
    let isCancellable = false;
    if (item?.id === "call-us") {
      isCancellable = true;
      setOpenUrgent(!openUrgent);
    }
    onClose();
    return isCancellable;
  };

  return (
    <div>
      <Drawer className={classes.sideDrawer} open={open} onClose={onClose}>
        <div style={{ width: 300, position: "relative" }}>
          <HierarchicalMenu items={items} onClick={handleItemClick} />
          <CloseButton onClose={onClose} />
        </div>
      </Drawer>
      <UrgentModal
        open={openUrgent}
        toggle={() => setOpenUrgent(!openUrgent)}
        emergencyPhoneContact={user?.clinicContact}
      />
    </div>
  );
};

export default CtmDrawer;
