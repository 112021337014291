import { useMutation, useQueryClient } from "react-query";
import { episodeQueryKey } from "shared/features/episodes/useFetchEpisode";
import { fetchActionableItemsApi } from "shared/fetch/src/apis/ActionableItemsApi";
import { UpdateCTMAIOwnerSchema } from "shared/fetch/src/models/UpdateCTMAIOwnerSchema";

interface IRequest {
  ctmAiId: string;
  owner: UpdateCTMAIOwnerSchema;
}

export const useUpdateAiOwner = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    ({ ctmAiId, owner }: IRequest) =>
      fetchActionableItemsApi.updateCareTeamMemberActionableItemOwner({
        ctmAiId,
        updateCTMAIOwnerSchema: owner,
      }),
    {
      onSettled: (data: any) => {
        queryClient.invalidateQueries(episodeQueryKey(data.episodeId));
      },
    }
  );
  return { updateAiOwner: mutation.mutate };
};
