// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostPaymentMethodSchema
 */
export interface PostPaymentMethodSchema  {
    /**
     * 
     * @type {number}
     * @memberof PostPaymentMethodSchema
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PostPaymentMethodSchema
     */
    cardLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPaymentMethodSchema
     */
    cardBrand?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPaymentMethodSchema
     */
    cardId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostPaymentMethodSchema
     */
    primary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PostPaymentMethodSchema
     */
    last4?: string;
    /**
     * 
     * @type {string}
     * @memberof PostPaymentMethodSchema
     */
    paymentToken?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PostPaymentMethodSchema
     */
    isHsaOrFsa?: boolean;
}

export function PostPaymentMethodSchemaFromJSON(json: any): PostPaymentMethodSchema {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'cardLabel': !exists(json, 'card_label') ? undefined : json['card_label'],
        'cardBrand': !exists(json, 'card_brand') ? undefined : json['card_brand'],
        'cardId': !exists(json, 'card_id') ? undefined : json['card_id'],
        'primary': !exists(json, 'primary') ? undefined : json['primary'],
        'last4': !exists(json, 'last4') ? undefined : json['last4'],
        'paymentToken': !exists(json, 'payment_token') ? undefined : json['payment_token'],
        'isHsaOrFsa': !exists(json, 'is_hsa_or_fsa') ? undefined : json['is_hsa_or_fsa'],
    };
}

export function PostPaymentMethodSchemaToJSON(value?: PostPaymentMethodSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'card_label': value.cardLabel,
        'card_brand': value.cardBrand,
        'card_id': value.cardId,
        'primary': value.primary,
        'last4': value.last4,
        'payment_token': value.paymentToken,
        'is_hsa_or_fsa': value.isHsaOrFsa,
    };
}


