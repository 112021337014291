import React from "react";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ContentTypeProps } from "containers/Layout/CmsMenuNavigationHierarchy";
import { useLocation } from "react-router";
import { CmsMenuHierarchyItemsProps } from "containers/Layout/CmsMenuHierarchy";
import Tabs from "components/Tabs";
import Tab from "components/Tabs/Tab";

interface CmsMenuHierarchyTabProps {
  cmsMenuHierarchyItems: CmsMenuHierarchyItemsProps[];
  justifyContentAround?: boolean;
  handleClick: (
    event: React.MouseEvent<HTMLButtonElement>,
    cmsSubMenuHierarchyItems: ContentTypeProps[]
  ) => void;
  defaultMenuItem: boolean;
  selectedMenuItem: string;
  handleMenuItemSelection: (tab: string) => void;
}

export interface IProps {
  children: any;
  onClick: () => {};
}

const CmsMenuHierarchyItemsTabs = ({
  cmsMenuHierarchyItems,
  justifyContentAround,
  handleClick,
  defaultMenuItem,
  selectedMenuItem,
  handleMenuItemSelection,
}: CmsMenuHierarchyTabProps) => {
  const location = useLocation();
  const menuHierarchyItems = cmsMenuHierarchyItems?.map(
    (route: any) => route.id
  );
  const formattedMenuHierarchyItems = menuHierarchyItems?.map((route: any) =>
    route.split(" ").join("-").toLowerCase()
  );
  const pathname = location.pathname.split("/")[2];
  const formattedPathname = pathname ? pathname : "/tools";
  const menuItemValue = formattedMenuHierarchyItems?.includes(formattedPathname)
    ? formattedPathname
    : false;

  return (
    <Tabs
      value={menuItemValue || (defaultMenuItem && selectedMenuItem)}
      textColor="secondary"
      aria-label="Tools navigation tabs"
      onChange={(_event, index) => handleMenuItemSelection(index)}
      TabIndicatorProps={{
        style: {
          backgroundColor: "#fff",
        },
      }}
      justifyContent={justifyContentAround ? "space-around" : "flex-start"}
    >
      {cmsMenuHierarchyItems
        ?.filter((item) => item.active)
        .map(
          (cmsMenuHierarchyItem: CmsMenuHierarchyItemsProps, index: number) => {
            const { contentTypes, id, label } = cmsMenuHierarchyItem;
            const tabPathname = id.split(" ").join("-").toLowerCase();
            const disabled = !contentTypes.length;
            return (
              <Tab
                label={label}
                value={tabPathname}
                onClick={(event: any) =>
                  handleClick(event, contentTypes)
                }
                disabled={disabled}
                icon={
                  defaultMenuItem && selectedMenuItem === tabPathname ? (
                    <ArrowDropUpIcon data-cy="arrowDropUpIcon" />
                  ) : (
                    <ArrowDropDownIcon
                      data-cy="arrowDropDownIcon"
                      // Removing when disabled ruins layout, make same color as background when disabled
                      htmlColor={disabled ? "white" : "inherit"}
                    />
                  )
                }
                key={index}
                data-e2e={`tools-navigation-tabs-${label.toLowerCase()}`}
              />
            );
          }
        )}
    </Tabs>
  );
};

export default CmsMenuHierarchyItemsTabs;
