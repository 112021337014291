/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MemberInsuranceInput,
    MemberInsuranceInputFromJSON,
    MemberInsuranceInputToJSON,
} from '../models/MemberInsuranceInput'
import {
    MemberSaidInsurance,
    MemberSaidInsuranceFromJSON,
    MemberSaidInsuranceToJSON,
} from '../models/MemberSaidInsurance'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    PatientInsuranceObject,
    PatientInsuranceObjectFromJSON,
    PatientInsuranceObjectToJSON,
} from '../models/PatientInsuranceObject'


export interface GetMemberInsuranceByIdRequest {
    id: string;
}
export interface UpdateMemberInsuranceByIdRequest {
    id: string;
    memberInsuranceInput?: MemberInsuranceInput;
}
export interface UpdateMemberSaidInsuranceByIdRequest {
    id: string;
    memberSaidInsurance?: MemberSaidInsurance;
}


export interface IMemberInsuranceApi {
      getMemberInsuranceById: (requestParameters: GetMemberInsuranceByIdRequest) => Promise<MemberSaidInsurance>
      updateMemberInsuranceById: (requestParameters: UpdateMemberInsuranceByIdRequest) => Promise<PatientInsuranceObject>
      updateMemberSaidInsuranceById: (requestParameters: UpdateMemberSaidInsuranceByIdRequest) => Promise<MemberSaidInsurance>
      getMemberInsuranceByIdWithResponse: (requestParameters: GetMemberInsuranceByIdRequest) => Promise<{ value: MemberSaidInsurance, response: any}>
      updateMemberInsuranceByIdWithResponse: (requestParameters: UpdateMemberInsuranceByIdRequest) => Promise<{ value: PatientInsuranceObject, response: any}>
      updateMemberSaidInsuranceByIdWithResponse: (requestParameters: UpdateMemberSaidInsuranceByIdRequest) => Promise<{ value: MemberSaidInsurance, response: any}>
}

/**
 * no description
 */
export class MemberInsuranceApi extends runtime.BaseAPI {

    /**
     * Get User Insurance
     */
    getMemberInsuranceByIdRaw = async (requestParameters: GetMemberInsuranceByIdRequest): Promise<{ runtime: runtime.ApiResponse<MemberSaidInsurance>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMemberInsuranceById.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/said-insurance`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberSaidInsuranceFromJSON(jsonValue)), response };
    }

    /**
     * Get User Insurance
     */
    getMemberInsuranceById = async (requestParameters: GetMemberInsuranceByIdRequest): Promise<MemberSaidInsurance> => {
        const { runtime } = await this.getMemberInsuranceByIdRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get User Insurance
     */
    getMemberInsuranceByIdWithResponse = async (requestParameters: GetMemberInsuranceByIdRequest): Promise<{ value: MemberSaidInsurance, response: any}> => {
        const { runtime, response } = await this.getMemberInsuranceByIdRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update member insurance by id
     */
    updateMemberInsuranceByIdRaw = async (requestParameters: UpdateMemberInsuranceByIdRequest): Promise<{ runtime: runtime.ApiResponse<PatientInsuranceObject>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMemberInsuranceById.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/insurance`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: MemberInsuranceInputToJSON(requestParameters.memberInsuranceInput),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => PatientInsuranceObjectFromJSON(jsonValue)), response };
    }

    /**
     * Update member insurance by id
     */
    updateMemberInsuranceById = async (requestParameters: UpdateMemberInsuranceByIdRequest): Promise<PatientInsuranceObject> => {
        const { runtime } = await this.updateMemberInsuranceByIdRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update member insurance by id
     */
    updateMemberInsuranceByIdWithResponse = async (requestParameters: UpdateMemberInsuranceByIdRequest): Promise<{ value: PatientInsuranceObject, response: any}> => {
        const { runtime, response } = await this.updateMemberInsuranceByIdRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update Member Said Insurance
     */
    updateMemberSaidInsuranceByIdRaw = async (requestParameters: UpdateMemberSaidInsuranceByIdRequest): Promise<{ runtime: runtime.ApiResponse<MemberSaidInsurance>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateMemberSaidInsuranceById.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/said-insurance`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: MemberSaidInsuranceToJSON(requestParameters.memberSaidInsurance),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberSaidInsuranceFromJSON(jsonValue)), response };
    }

    /**
     * Update Member Said Insurance
     */
    updateMemberSaidInsuranceById = async (requestParameters: UpdateMemberSaidInsuranceByIdRequest): Promise<MemberSaidInsurance> => {
        const { runtime } = await this.updateMemberSaidInsuranceByIdRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update Member Said Insurance
     */
    updateMemberSaidInsuranceByIdWithResponse = async (requestParameters: UpdateMemberSaidInsuranceByIdRequest): Promise<{ value: MemberSaidInsurance, response: any}> => {
        const { runtime, response } = await this.updateMemberSaidInsuranceByIdRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchMemberInsuranceApi: IMemberInsuranceApi  = new MemberInsuranceApi();
