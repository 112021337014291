// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatientRequestBody
 */
export interface PatientRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof PatientRequestBody
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestBody
     */
    clinicId?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestBody
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestBody
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestBody
     */
    employeeId?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestBody
     */
    employer?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestBody
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestBody
     */
    relationship?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestBody
     */
    dob?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientRequestBody
     */
    xopEligible?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestBody
     */
    ssn?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PatientRequestBody
     */
    newMember?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestBody
     */
    newEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestBody
     */
    primaryMemberId?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientRequestBody
     */
    mobilePhone?: string;
}

export function PatientRequestBodyFromJSON(json: any): PatientRequestBody {
    return {
        'email': !exists(json, 'email') ? undefined : json['email'],
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'employeeId': !exists(json, 'employee_id') ? undefined : json['employee_id'],
        'employer': !exists(json, 'employer') ? undefined : json['employer'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'relationship': !exists(json, 'relationship') ? undefined : json['relationship'],
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'xopEligible': !exists(json, 'xop_eligible') ? undefined : json['xop_eligible'],
        'ssn': !exists(json, 'ssn') ? undefined : json['ssn'],
        'newMember': !exists(json, 'new_member') ? undefined : json['new_member'],
        'newEmail': !exists(json, 'new_email') ? undefined : json['new_email'],
        'primaryMemberId': !exists(json, 'primary_member_id') ? undefined : json['primary_member_id'],
        'mobilePhone': !exists(json, 'mobile_phone') ? undefined : json['mobile_phone'],
    };
}

export function PatientRequestBodyToJSON(value?: PatientRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'email': value.email,
        'clinic_id': value.clinicId,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'employee_id': value.employeeId,
        'employer': value.employer,
        'gender': value.gender,
        'relationship': value.relationship,
        'dob': value.dob,
        'xop_eligible': value.xopEligible,
        'ssn': value.ssn,
        'new_member': value.newMember,
        'new_email': value.newEmail,
        'primary_member_id': value.primaryMemberId,
        'mobile_phone': value.mobilePhone,
    };
}


