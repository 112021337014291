import { useSelector } from "react-redux";
import { useRequest } from "redux-query-react";

import { State } from "shared/types/state";
import isEqual from "lodash/isEqual";
import { getMyQueue } from "shared/api/src/apis/EpisodeListsApi";
import { EpisodeQueueSummariesResponse } from "shared/fetch/src/models/EpisodeQueueSummariesResponse";
import { EpisodeSummary } from "shared/fetch/src/models/EpisodeSummary";

export default () => {
  const activeEpisodes: EpisodeSummary[] | undefined = useSelector(
    (state: State) => state.entities.memberActiveEpisodes?.activeEpisodes
  );

  const queryConfig = getMyQueue(
    {},
    {
      transform: (body: EpisodeQueueSummariesResponse) => ({
        memberActiveEpisodes: {
          activeEpisodes: body.results || [],
          preventiveEpisode: body.preventiveEpisode,
          nextUrl: body.page !== body.last && body.nextUrl,
        },
      }),
      update: {
        memberActiveEpisodes: (prev: any, next: any) => {
          if (isEqual(prev, next)) {
            return prev;
          }

          return next;
        },
      },
      force: true,
    }
  );

  const [{ isPending }, refetch] = useRequest(queryConfig as any);

  return {
    activeEpisodes,
    isPending,
    refetch,
  };
};
