const hours = new Date().getHours();
let time = "";
export const getGreeting = (): String => {
  if (hours < 12 && hours >= 5) {
    time = "Good morning,";
  } else if (hours < 17 && hours >= 12) {
    time = "Good afternoon,";
  } else {
    time = "Good evening,";
  }
  return time;
};
