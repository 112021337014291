import makeStyles from "@mui/styles/makeStyles";
import Typography from "components/Typography";
import React from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { DailyScheduleResponseAppointments } from "shared/fetch/src/models/DailyScheduleResponseAppointments";
import clsx from "clsx";
import { getPronouns } from "components/Sogi/utils";
import useMediaQuery from "@mui/material/useMediaQuery";
import ExpandedMemberDetails from "../ExpandedMemberDetails";
import { Link } from "components/Button";

export interface IAppointmentRow {
  appointment: DailyScheduleResponseAppointments;
  isLastRow?: boolean;
}

export const useAppointmentRowGridStyles = makeStyles(({ breakpoints }) => ({
  appointmentGrid: {
    display: "grid",
    gridTemplateColumns: "1.2fr 2.4fr 1.9fr 1.5fr 0.3fr 1.1fr",
    gap: "16px",
    [breakpoints.down("lg")]: {
      gap: "12px",
    },
  },
}));

const useStyles = makeStyles(({ palette, spacing }) => ({
  arrow: {
    fill: palette?.text?.secondary,
    height: spacing(3),
    width: spacing(3),
  },
  arrowSmall: {
    fill: palette?.text?.secondary,
    height: spacing(2.5),
    width: spacing(2.5),
  },
  appointmentColumn: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    paddingLeft: spacing(1),
  },
  appointmentColumnBorder: {
    borderBottom: `1px solid ${palette?.text?.strokes}`,
  },
  timeColumn: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: spacing(0.5),
  },
  visitTypeColumn: {
    display: "flex",
    flexDirection: "row",
  },
  virtualVisitIcon: {
    width: "16px",
  },
  linkToProfile: {
    textDecoration: "none !important",
  },
  linkText: {
    color: palette?.text?.link,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const truncatePronouns = (pronouns: string) => {
  /* eslint-disable  @typescript-eslint/no-unused-vars */
  const [subjectPronoun, objectPronoun, _possessivePronoun] =
    pronouns.split(" / ");
  return `${subjectPronoun} / ${objectPronoun}`;
};

const getAppointmentSummary = (
  appointment: DailyScheduleResponseAppointments
) => {
  if (appointment.summarySub) {
    return appointment.summarySub.replace(/\(.*\)/g, "");
  } else {
    return appointment.summary;
  }
};

const AppointmentRow: React.FC<IAppointmentRow> = ({
  appointment,
  isLastRow,
}) => {
  const gridClasses = useAppointmentRowGridStyles();
  const classes = useStyles();
  const memberPronouns = appointment?.pronouns
    ? getPronouns(appointment?.pronouns, true)
    : undefined;
  const truncatedPronouns = memberPronouns
    ? truncatePronouns(memberPronouns)
    : null;
  const [memberAge, memberSex] = appointment.sexage?.split(" ") || [];
  const hasMemberAge = !!memberAge;
  const hasMemberSex = !!memberSex;
  // temporary const value until expand/collapse is implemented
  const isExpanded = true;
  const isDesktopView = useMediaQuery("@media (min-width: 992px)");

  return (
    <>
      <div
        data-testid="ds-appointment-row"
        className={clsx(
          gridClasses.appointmentGrid,
          classes.appointmentColumn,
          !isExpanded && !isLastRow && classes.appointmentColumnBorder
        )}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <KeyboardArrowUpIcon className={classes.arrowSmall} />
          </div>
          <Typography
            appearance="smallBody"
            color="textSecondary"
            className={classes.timeColumn}
          >
            <div>{appointment?.startsAt}</div>
            <div>{appointment?.duration} min</div>
          </Typography>
        </div>
        <div>
          <Link
            color="primary"
            inline
            className={classes.linkToProfile}
            to={`/members/${appointment?.userId}?b=Schedule`}
          >
            <Typography
              appearance="smallBody"
              fontWeightBold
              className={classes.linkText}
            >
              {appointment?.legalAndPreferredName}
            </Typography>
          </Link>
          {!isDesktopView && memberPronouns && (
            <Typography appearance="smallBody" color="textSecondary">
              {truncatedPronouns}
            </Typography>
          )}
          <Typography appearance="smallBody" color="textSecondary">
            {isDesktopView && (
              <>{memberPronouns ? `${truncatedPronouns} | ` : ""}</>
            )}
            {appointment?.dob && `${appointment?.dob} | `}
            {hasMemberAge && `${memberAge} | `}
            {hasMemberSex && `${memberSex}`}
          </Typography>
          <Typography appearance="smallBody" color="textSecondary">
            {appointment?.cellPhone}
          </Typography>
        </div>
        <div className={classes.visitTypeColumn}>
          <Typography
            appearance="smallBody"
            color="textSecondary"
            fontWeightBold
            textDecorationUnderline
          >
            {getAppointmentSummary(appointment)}
          </Typography>
        </div>
        <div>
          <Typography
            appearance="smallBody"
            color="textSecondary"
            fontWeightBold
            textDecorationUnderline
          >
            {appointment?.amount}
          </Typography>
          <Typography appearance="smallBody" color="textSecondary">
            {appointment?.insurancePlan}
          </Typography>
          <Typography appearance="smallBody" color="textSecondary">
            HRA Balance: {appointment?.hraBalance}
          </Typography>
        </div>
        <div>{/* placeholder for notes icon column */}</div>
        <div>{/* placeholder for action button column */}</div>
      </div>
      {isExpanded && (
        <ExpandedMemberDetails
          appointment={appointment}
          isLastRow={isLastRow}
        />
      )}
    </>
  );
};

export default AppointmentRow;
