// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HoursOfOperation,
    HoursOfOperationFromJSON,
    HoursOfOperationToJSON,
} from './HoursOfOperation';
import {
    NetworkProviderGroup,
    NetworkProviderGroupFromJSON,
    NetworkProviderGroupToJSON,
} from './NetworkProviderGroup';


/**
 * 
 * @export
 * @interface NetworkCareTeam
 */
export interface NetworkCareTeam  {
    /**
     * 
     * @type {string}
     * @memberof NetworkCareTeam
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkCareTeam
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof NetworkCareTeam
     */
    core?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NetworkCareTeam
     */
    containsPcp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof NetworkCareTeam
     */
    virtual?: boolean;
    /**
     * 
     * @type {string}
     * @memberof NetworkCareTeam
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkCareTeam
     */
    addressUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkCareTeam
     */
    phone?: string;
    /**
     * 
     * @type {HoursOfOperation}
     * @memberof NetworkCareTeam
     */
    hoursOfOperation?: HoursOfOperation;
    /**
     * 
     * @type {string}
     * @memberof NetworkCareTeam
     */
    websiteUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof NetworkCareTeam
     */
    imageUrl?: string;
    /**
     * 
     * @type {Array<NetworkProviderGroup>}
     * @memberof NetworkCareTeam
     */
    providerGroups?: Array<NetworkProviderGroup>;
    /**
     * 
     * @type {string}
     * @memberof NetworkCareTeam
     */
    podId?: string;
}

export function NetworkCareTeamFromJSON(json: any): NetworkCareTeam {
    return {
        'id': json['id'],
        'name': json['name'],
        'core': !exists(json, 'core') ? undefined : json['core'],
        'containsPcp': !exists(json, 'contains_pcp') ? undefined : json['contains_pcp'],
        'virtual': !exists(json, 'virtual') ? undefined : json['virtual'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'addressUrl': !exists(json, 'address_url') ? undefined : json['address_url'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'hoursOfOperation': !exists(json, 'hours_of_operation') ? undefined : HoursOfOperationFromJSON(json['hours_of_operation']),
        'websiteUrl': !exists(json, 'website_url') ? undefined : json['website_url'],
        'imageUrl': !exists(json, 'image_url') ? undefined : json['image_url'],
        'providerGroups': !exists(json, 'provider_groups') ? undefined : (json['provider_groups'] as Array<any>).map(NetworkProviderGroupFromJSON),
        'podId': !exists(json, 'pod_id') ? undefined : json['pod_id'],
    };
}

export function NetworkCareTeamToJSON(value?: NetworkCareTeam): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'core': value.core,
        'contains_pcp': value.containsPcp,
        'virtual': value.virtual,
        'address': value.address,
        'address_url': value.addressUrl,
        'phone': value.phone,
        'hours_of_operation': HoursOfOperationToJSON(value.hoursOfOperation),
        'website_url': value.websiteUrl,
        'image_url': value.imageUrl,
        'provider_groups': value.providerGroups === undefined ? undefined : (value.providerGroups as Array<any>).map(NetworkProviderGroupToJSON),
        'pod_id': value.podId,
    };
}


