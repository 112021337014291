import React, { FunctionComponent } from "react";
import TooltipComponent, { TooltipProps } from "@mui/material/Tooltip";
import { Theme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

type TooltipAppearance = "default" | "light";

interface ITooltipProps extends TooltipProps {
  appearance?: TooltipAppearance;
}

const useLightStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette?.appBackground?.main,
    color: theme.palette.text.secondary,
    borderRadius: 4,
    boxShadow: theme.shadows[1],
    border: `1px solid ${theme.palette?.strokes?.divider}`,
  },
}));

const Tooltip: FunctionComponent<ITooltipProps> = (props) => {
  const { appearance = "default", classes, ...restProps } = props;
  const lightStyles = appearance === "light" ? useLightStyles() : null;
  return (
    <TooltipComponent
      classes={{
        ...classes,
        ...lightStyles,
      }}
      enterTouchDelay={0}
      leaveTouchDelay={3000}
      {...restProps}
    />
  );
};

export default Tooltip;
