import { useMutation, useQueryClient } from "react-query";
import { fetchCheckinApi } from "shared/fetch/src/apis/CheckinApi";
import { getCheckinByAppointmentIdQueryKey } from "./useGetCheckinByAppointmentId";
import { GetOrCreateXoCheckinRequest } from "shared/api/src/apis/CheckinApi";

const useXoCheckin = () => {
  const queryClient = useQueryClient();

  const { isLoading: isPending, mutateAsync: xoCheckin } = useMutation(
    (requestParameters: GetOrCreateXoCheckinRequest) => {
      return fetchCheckinApi
        .getOrCreateXoCheckinWithResponse(requestParameters)
        .then(({ value, response }) => {
          if (value.appointmentId) {
            queryClient.invalidateQueries(
              getCheckinByAppointmentIdQueryKey(value.appointmentId)
            );
          }
          return { value, response };
        });
    }
  );
  return { isPending, xoCheckin };
};

export default useXoCheckin;
