import React from "react";
import Box from "components/Box";
import Asset from "components/Asset";
import Heading from "components/DynamicHeadings";
import { Theme, useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Typography from "components/Typography";
import { useNavigate } from "react-router";
import { Link } from "components/Button";
import { eocRootPath } from "containers/Routing";
import useAccess from "hooks/useAccess";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";
import { JsonUser, JsonUserAffinityEnum } from "shared/fetch/src/models/JsonUser";

const useStyle = makeStyles(({ palette }) => ({
  placeHolderContainer: {
    border: `1px solid ${palette.text.strokes}`,
    borderRadius: 10,
    marginTop: 20,
    paddingLeft: 8,
  },
  placeHolderContent: {
    display: "flex",
    flexDirection: "column",
  },
  asset: {
    height: 165,
    width: 165,
  },
}));

interface ITertiaryCTAProps {
  user: JsonUser;
}

function TertiaryCTA({ user }: ITertiaryCTAProps) {
  const classes = useStyle();
  const { typography, palette } = useTheme<Theme>();
  const navigate = useNavigate();
  const affinity = user.affinity;

  const copyAndActions = {
    title: {
      onsite: "Are you out of state?",
      remote: "Are you near one of our centers?",
    },
    body: {
      onsite:
        "Connect with our National Virtual Team and get care anywhere in the U.S.",
      remote: "Schedule an in-person or video visit with your Care Team.",
    },
    CTA: {
      onsite: "Contact our National Team",
      remote: "Schedule a visit",
    },
    asset: {
      onsite: "travel-med",
      remote: "ctaClinic",
    },
    linkTo: {
      onsite: eocRootPath,
      remote: `/members/${user?.id}/self-schedule`,
    },
  };

  const hasSelfScheduleAccessOnly =
    useAccess({ to: PermissionsEnum.SelfScheduleCreate }) &&
    !useAccess({ to: PermissionsEnum.EpisodesCreate });

  if (
    affinity === JsonUserAffinityEnum.Nearsite ||
    affinity === JsonUserAffinityEnum.InpersonWithoutTravel ||
    hasSelfScheduleAccessOnly
  ) {
    return null;
  } else {
    return (
      <Box display="flex" p={3 / 2} className={classes.placeHolderContainer}>
        <Asset
          className={classes.asset}
          name={copyAndActions.asset[affinity!]}
        />
        <div className={classes.placeHolderContent}>
          <Heading.H
            fontWeightBold
            appearance="smallBody"
            mb={1}
            style={{ color: palette.text.primary, marginBottom: 8 }}
          >
            {copyAndActions.title[affinity!]}
          </Heading.H>
          <Typography
            appearance="smallBody"
            style={{ color: palette.text.secondary, marginBottom: "23px" }}
          >
            {copyAndActions.body[affinity!]}
          </Typography>
          <Link
            onClick={() => navigate(copyAndActions.linkTo[affinity!])}
            color="primary"
            style={{
              ...typography.bodySmall,
              textDecoration: "none",
              justifyContent: "space-between",
              fontWeight: "bold",
            }}
          >
            <Typography appearance="smallBody" style={{ fontWeight: "bold" }}>
              {copyAndActions.CTA[affinity!]}
            </Typography>
            <svg
              width="8"
              height="12"
              viewBox="0 0 8 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.99984 0L0.589844 1.41L5.16984 6L0.589844 10.59L1.99984 12L7.99984 6L1.99984 0Z"
                fill={palette.text.link}
              />
            </svg>
          </Link>
        </div>
      </Box>
    );
  }
}

export default TertiaryCTA;
