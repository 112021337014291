import React from "react";
import Box from "components/Box";
import Container from "components/Container";
import Grid from "components/Grid";

import Skeleton from ".";
import CardSkeleton from "./CardSkeleton";

const PrimaryContent = () => (
  <>
    <Skeleton width="80%" height={60} />
    <Skeleton width="70%" />
    <Skeleton width="70%" />
    <Skeleton width="70%" />
  </>
);
const SecondaryContent = () => (
  <div style={{ paddingTop: 16 }}>
    <Skeleton width="80%" />
    <Skeleton width="70%" />
    <Skeleton width="70%" />
    <Skeleton width="70%" />
  </div>
);

const TimelineSkeleton = () => (
  <div style={{ width: "100%" }}>
    <Container
      style={{
        position: "relative",
        zIndex: 1,
        paddingRight: 0,
        paddingLeft: 0,
        marginTop: "-20px",
      }}
    >
      <Box pt={3}>
        <CardSkeleton style={{ marginTop: 0 }}>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Skeleton width="80%" height={60} />
              <Skeleton width="40%" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Skeleton width="80%" height={60} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Skeleton width="80%" height={60} />
            </Grid>
          </Grid>
          <hr
            style={{
              marginRight: "-26px",
              marginLeft: "-26px",
              marginTop: "10px",
              marginBottom: "0px",
            }}
          />
          <Grid container>
            <Grid item xs={12} sm={4}>
              <PrimaryContent />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SecondaryContent />
            </Grid>
            <Grid item xs={12} sm={4}>
              <SecondaryContent />
            </Grid>
          </Grid>
        </CardSkeleton>
      </Box>
    </Container>
  </div>
);

export default TimelineSkeleton;
