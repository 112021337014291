import { updateComponent } from "shared/api/src/apis/ComponentsApi";
import { UpdateComponentRequest } from "shared/fetch/src/apis/ComponentsApi";
import { ComponentDetailsOutput } from "shared/fetch/src/models/ComponentDetailsOutput";

const updateComponentMutation = (request: UpdateComponentRequest) =>
  updateComponent(request, {
    transform: (body: ComponentDetailsOutput) => ({
      component: { [request.id]: body },
    }),

    update: {
      component: (
        prev: { [key: string]: ComponentDetailsOutput },
        next: { [key: string]: ComponentDetailsOutput }
      ) => ({ ...prev, ...next }),
    },
    force: true,
  });

export default updateComponentMutation;
