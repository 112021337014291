// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateAppointment
 */
export interface UpdateAppointment  {
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointment
     */
    reason?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAppointment
     */
    noShow?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateAppointment
     */
    cancelType?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAppointment
     */
    lateCancellationFeeWaived?: boolean;
}

export function UpdateAppointmentFromJSON(json: any): UpdateAppointment {
    return {
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'noShow': !exists(json, 'no_show') ? undefined : json['no_show'],
        'cancelType': !exists(json, 'cancel_type') ? undefined : json['cancel_type'],
        'lateCancellationFeeWaived': !exists(json, 'late_cancellation_fee_waived') ? undefined : json['late_cancellation_fee_waived'],
    };
}

export function UpdateAppointmentToJSON(value?: UpdateAppointment): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'reason': value.reason,
        'no_show': value.noShow,
        'cancel_type': value.cancelType,
        'late_cancellation_fee_waived': value.lateCancellationFeeWaived,
    };
}


