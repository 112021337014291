// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QueueMemberInfoAssignedPcp
 */
export interface QueueMemberInfoAssignedPcp  {
    /**
     * 
     * @type {string}
     * @memberof QueueMemberInfoAssignedPcp
     */
    truncatedName?: string;
    /**
     * 
     * @type {string}
     * @memberof QueueMemberInfoAssignedPcp
     */
    name?: string;
}

export function QueueMemberInfoAssignedPcpFromJSON(json: any): QueueMemberInfoAssignedPcp {
    return {
        'truncatedName': !exists(json, 'truncated_name') ? undefined : json['truncated_name'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function QueueMemberInfoAssignedPcpToJSON(value?: QueueMemberInfoAssignedPcp): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'truncated_name': value.truncatedName,
        'name': value.name,
    };
}


