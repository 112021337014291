import React, { FunctionComponent } from "react";
import { useTheme, Theme } from "@mui/material";
import clsx from "clsx";
import styles from "./index.module.css";
import { CssTypography as Typography } from "../CssTypography";

interface IBadge {
  abbreviation: string;
  color: string;
  backgroundColor: string;
}

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  badge: IBadge;
  className?: string;
}

const EocBadge: FunctionComponent<IProps> = React.forwardRef(
  ({
    className,
    badge: { abbreviation, color, backgroundColor },
    ...props
  }) => {
    const { palette } = useTheme<Theme>();

    if (!abbreviation) {
      return null;
    }

    return (
      <Typography
        {...props}
        className={clsx(className, styles.badge)}
        style={{
          backgroundColor: backgroundColor ?? palette.grey[300],
          width: abbreviation?.length * 6 + 8,
          color: color ?? "",
        }}
      >
        {abbreviation}
      </Typography>
    );
  }
);

EocBadge.displayName = "EocBadge";

export default EocBadge;
