import React, { FunctionComponent } from "react";
import Skeleton from "components/Skeleton";
import Box from "components/Box";
import Grid from "components/Grid";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import AllTeamsSkeletonPage from "features/meetYourTeam/pages/AllTeamsSkeleton";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundColor: "rgba(43, 57, 73, 0.1)",
      height: "400px",
    },
  })
);

const ProviderBioSkeleton = () => (
  <Grid item lg={2} xs={2}>
    <Skeleton height="500px" width="100%" style={{ marginTop: "-197px" }} />
  </Grid>
);

const ProviderPageSkeleton: FunctionComponent = (): JSX.Element => {
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.root}>
        <Grid item xs={12}>
          <Box mt={10} ml={15}>
            <Skeleton
              height="70px"
              style={{
                display: "flex",
                alignSelf: "center",
                width: "250px",
              }}
            />
            <Skeleton
              height="70px"
              style={{
                display: "flex",
                alignSelf: "center",
                width: "220px",
                marginTop: "-19px",
              }}
            />
            <Skeleton
              height="70px"
              style={{
                display: "flex",
                alignSelf: "center",
                width: "250px",
                marginTop: "-19px",
              }}
            />
            <Skeleton
              height="20px"
              style={{
                display: "flex",
                alignSelf: "center",
                width: "270px",
              }}
            />
            <Skeleton
              height="20px"
              style={{
                display: "flex",
                alignSelf: "center",
                width: "260px",
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid></Grid>
      <Grid>
        <AllTeamsSkeletonPage />
      </Grid>
      <Grid
        container
        spacing={1}
        style={{ backgroundColor: "rgba(43, 57, 73, 0.1)" }}
        justifyContent="flex-end"
      >
        <Grid item xs={2} lg={2}></Grid>
        <Grid item lg={2} xs={2}>
          <Skeleton
            height="35px"
            style={{
              display: "flex",
              alignSelf: "center",
              width: "60%",
              marginTop: "30px",
            }}
          />
          <Skeleton
            height="500px"
            width="100%"
            style={{ marginTop: "-100px" }}
          />
        </Grid>
        <Grid item lg={2} xs={2}>
          <Skeleton
            height="500px"
            width="100%"
            style={{ marginTop: "-35px" }}
          />
        </Grid>
        <Grid item lg={2} xs={2}>
          <Skeleton
            height="500px"
            width="100%"
            style={{ marginTop: "-35px" }}
          />
        </Grid>
        <Grid item xs={1} lg={1}></Grid>
        <Grid item xs={2} lg={2}></Grid>
        <Grid item xs={2} lg={2}></Grid>
        <ProviderBioSkeleton />
        <ProviderBioSkeleton />
        <ProviderBioSkeleton />
        <Grid item xs={1} lg={1}></Grid>
        <Grid item xs={2} lg={2}></Grid>
      </Grid>
    </>
  );
};

export default ProviderPageSkeleton;
