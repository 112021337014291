import episodeJson from "./episode.json"
import messageAndMoreComponentJson from "./messageAndMoreComponent.json"
import messageAndMoreTimelineEventJson from "./messageAndMoreTimelineEvent.json";
import questionSetComponent from "./questionSetComponent.json"
import slotsJson from "./slots.json"
import referralComponentJson from "./referralComponent.json"
import memberProfileJson from "./memberProfile.json"
import memberEpisodesWithPreventiveJson from "./memberEpisodesWithPreventive.json";
import marketingMessageComponentJson from "./marketingMessageComponent.json";
import centerReferralJson from "./centerReferral.json"

/**
 *
 * This file remains only for testing purposes
 * Any new stub or endpoint mocking implementation should use the MSW approach.
 *
 */

/** Map of routes to their stubs */
export const stubs = [
  {
    route: /^\/v1\/components\/20000/,
    response: referralComponentJson,
  },

  {
    route: /^\/v1\/components\/100000\d/,
    response: messageAndMoreComponentJson,
  },
  {
    route: /^\/v1\/components\/100000\d\/viewed/,
    response: messageAndMoreComponentJson,
  },

  {
    route: /^\/v1\/timeline_events\/100000/,
    response: messageAndMoreTimelineEventJson,
  },
  {
    route: /^\/v1\/components\/100002/,
    response: questionSetComponent,
  },

  {
    route: "/v1/components/20000/slots",
    response: slotsJson,
  },
  {
    route: /^\/v1\/episodes\/99999/,
    response: episodeJson,
  },
  {
    route: /^\/v1\/members\/99999\/episodes/,
    response: memberEpisodesWithPreventiveJson,
  },
  {
    route: /^\/v1\/members\/99999\/archived_episodes/,
    response: memberEpisodesWithPreventiveJson,
  },
  {
    route: /^\/v1\/members\/99999/,
    response: memberProfileJson,
  },
  {
    route: /^\/v1\/components\/900002/,
    response: marketingMessageComponentJson,
  },
  { route: /^\/v1\/components\/99999/, response: centerReferralJson },
];

export function getStub(_url: string) {
  // commenting out in case we need to stub something in the future
  const stubObject = stubs.find(function (_a) {
    const route = _a.route;
    return (
      (typeof route == "string" && route === _url) ||
      (typeof route === "object" && route.test(_url))
    );
  });
  return stubObject && stubObject.response ? stubObject.response : undefined;
}

