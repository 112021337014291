// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Calculator
 */
export interface Calculator  {
    /**
     * 
     * @type {string}
     * @memberof Calculator
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Calculator
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Calculator
     */
    ctmFacingName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Calculator
     */
    sentForEpisode?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Calculator
     */
    hasInitial?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof Calculator
     */
    lastCompletedAt?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof Calculator
     */
    results?: Array<string>;
    /**
     * 
     * @type {object}
     * @memberof Calculator
     */
    data?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof Calculator
     */
    serviceLines?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Calculator
     */
    status?: CalculatorStatusEnum;
}

export function CalculatorFromJSON(json: any): Calculator {
    return {
        'id': json['id'],
        'name': json['name'],
        'ctmFacingName': !exists(json, 'ctm_facing_name') ? undefined : json['ctm_facing_name'],
        'sentForEpisode': !exists(json, 'sent_for_episode') ? undefined : json['sent_for_episode'],
        'hasInitial': !exists(json, 'has_initial') ? undefined : json['has_initial'],
        'lastCompletedAt': !exists(json, 'last_completed_at') ? undefined : new Date(json['last_completed_at']),
        'results': !exists(json, 'results') ? undefined : json['results'],
        'data': !exists(json, 'data') ? undefined : json['data'],
        'serviceLines': !exists(json, 'service_lines') ? undefined : json['service_lines'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function CalculatorToJSON(value?: Calculator): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'ctm_facing_name': value.ctmFacingName,
        'sent_for_episode': value.sentForEpisode,
        'has_initial': value.hasInitial,
        'last_completed_at': value.lastCompletedAt === undefined ? undefined : value.lastCompletedAt.toISOString(),
        'results': value.results,
        'data': value.data,
        'service_lines': value.serviceLines,
        'status': value.status,
    };
}

/**
* @export
* @enum {string}
*/
export enum CalculatorStatusEnum {
    Draft = 'draft',
    Live = 'live',
    Deleted = 'deleted'
}


