// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     AcknowledgeableComponentProps,
    AcknowledgeableComponentPropsFromJSON,
    AcknowledgeableComponentPropsToJSON,
} from './AcknowledgeableComponentProps';
import {
     ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';
import {
     Appointment,
    AppointmentFromJSON,
    AppointmentToJSON,
} from './Appointment';
import {
     CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
     CenterVisitReferralComponentOutputProps,
    CenterVisitReferralComponentOutputPropsFromJSON,
    CenterVisitReferralComponentOutputPropsToJSON,
} from './CenterVisitReferralComponentOutputProps';
import {
     CompactComponentReference,
    CompactComponentReferenceFromJSON,
    CompactComponentReferenceToJSON,
} from './CompactComponentReference';
import {
     ComponentActionDetails,
    ComponentActionDetailsFromJSON,
    ComponentActionDetailsToJSON,
} from './ComponentActionDetails';
import {
     ComponentCore,
    ComponentCoreFromJSON,
    ComponentCoreToJSON,
} from './ComponentCore';
import {
     ComponentDetailsCore,
    ComponentDetailsCoreFromJSON,
    ComponentDetailsCoreToJSON,
} from './ComponentDetailsCore';
import {
     ComponentDetailsCoreCreatorInfo,
    ComponentDetailsCoreCreatorInfoFromJSON,
    ComponentDetailsCoreCreatorInfoToJSON,
} from './ComponentDetailsCoreCreatorInfo';
import {
     ComponentDetailsCoreResolverInfo,
    ComponentDetailsCoreResolverInfoFromJSON,
    ComponentDetailsCoreResolverInfoToJSON,
} from './ComponentDetailsCoreResolverInfo';
import {
     CtmMessageComponentDetailsOutput,
    CtmMessageComponentDetailsOutputFromJSON,
    CtmMessageComponentDetailsOutputToJSON,
} from './CtmMessageComponentDetailsOutput';
import {
     DocumentObjectDetails,
    DocumentObjectDetailsFromJSON,
    DocumentObjectDetailsToJSON,
} from './DocumentObjectDetails';
import {
     DocumentOutputSchema,
    DocumentOutputSchemaFromJSON,
    DocumentOutputSchemaToJSON,
} from './DocumentOutputSchema';
import {
     MemberClinic,
    MemberClinicFromJSON,
    MemberClinicToJSON,
} from './MemberClinic';
import {
     MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';
import {
     OutputWorkspaceDraftSchema,
    OutputWorkspaceDraftSchemaFromJSON,
    OutputWorkspaceDraftSchemaToJSON,
} from './OutputWorkspaceDraftSchema';
import {
     ReferralComponentCoreDeclineReason,
    ReferralComponentCoreDeclineReasonFromJSON,
    ReferralComponentCoreDeclineReasonToJSON,
} from './ReferralComponentCoreDeclineReason';
import {
     ReferralComponentCoreVisitType,
    ReferralComponentCoreVisitTypeFromJSON,
    ReferralComponentCoreVisitTypeToJSON,
} from './ReferralComponentCoreVisitType';
import {
     UserInfo,
    UserInfoFromJSON,
    UserInfoToJSON,
} from './UserInfo';
/**
 * @type CenterVisitReferralComponentOutput
 * @export
 */
export interface CenterVisitReferralComponentOutput extends AcknowledgeableComponentProps, CenterVisitReferralComponentOutputProps, ComponentCore, ComponentDetailsCore {
}

export function CenterVisitReferralComponentOutputFromJSON(json: any): CenterVisitReferralComponentOutput {
    return {
        ...AcknowledgeableComponentPropsFromJSON(json),
        ...CenterVisitReferralComponentOutputPropsFromJSON(json),
        ...ComponentCoreFromJSON(json),
        ...ComponentDetailsCoreFromJSON(json),
    };
}

export function CenterVisitReferralComponentOutputToJSON(value?: CenterVisitReferralComponentOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...AcknowledgeableComponentPropsToJSON(value),
        ...CenterVisitReferralComponentOutputPropsToJSON(value),
        ...ComponentCoreToJSON(value),
        ...ComponentDetailsCoreToJSON(value),
    };
}

