import React, { FunctionComponent } from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import VucBadgeImage from "../../styles/images/vuc-badge.svg";

// Define styles for the component
const useStyles = makeStyles(() =>
  createStyles({
    image: {
      width: 46,
      height: 18,
    },
  })
);

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const VucBadge: FunctionComponent<IProps> = React.forwardRef(
  ({ className }) => {
    const classes = useStyles();

    return (
      <span data-testid="vuc-badge" className={className}>
        <img
          src={VucBadgeImage}
          alt="VUC Badge Icon"
          className={classes.image}
        />
      </span>
    );
  }
);

VucBadge.displayName = "VucBadge";

export default VucBadge;
