import { mutateAsync } from "redux-query";
import { setHasUnsavedInput } from "shared/state/ui/member";
import { startEpisodeOfCare } from "shared/api/src/apis/EpisodesOfCareApi";
import { JsonUser } from "shared/api/src/models/JsonUser";
import { OutputServiceLineSchema } from "shared/api/src/models/OutputServiceLineSchema";
import { OutputSubTypeSchema } from "shared/api/src/models/OutputSubTypeSchema";
import { MemberAccountInfoOutput } from "shared/fetch/src/models/MemberAccountInfoOutput";
import { UserInfo } from "shared/fetch/src/models/UserInfo";
import { MemberInfo } from "shared/fetch/src/models/MemberInfo";

export const convertToPathString = (str: string) =>
  str
    .trim()
    .replace(/[^\s\w]+/gi, "")
    .replace(/\s+/gi, "-")
    .toLowerCase();

export const redirectAfterEpisodeCreation = (response: any, navigate?: any) => {
  const episodeId = String(response?.body?.id);
  const memberId = String(response?.body?.member_info?.id);
  navigate(`/members/${memberId}/conversations/${episodeId}`);
};

export const getCreatorId = (
  user?: UserInfo | JsonUser | undefined,
  member?: MemberAccountInfoOutput | MemberInfo | undefined
) => {
  return String(member?.id || user?.id);
};

interface ICreateEpisodeOfCareProps {
  dispatch: any;
  navigate: any;
  body?: string;
  subtype?: string | null;
  recipientId?: string;
  documentIds?: string[];
  recipientPodId?: string;
  user?: UserInfo | JsonUser | undefined;
  memberCurrentUsState?: string | undefined;
  member?: MemberAccountInfoOutput | MemberInfo | undefined;
  subject?: string | undefined;
  subjectFreeText?: string;
  queryKey?: string;
  onError?: (reason?: string) => void;
  ccCtmIds?: string[];
}

export const createEpisodeOfCare = ({
  user,
  member,
  subtype,
  dispatch,
  navigate,
  body = "",
  recipientId,
  recipientPodId,
  documentIds = [],
  memberCurrentUsState,
  subject,
  subjectFreeText,
  queryKey = "createEoC",
  onError,
  ccCtmIds = [],
}: ICreateEpisodeOfCareProps) => {
  // API call
  const create = startEpisodeOfCare({
    episodeCreationInput: {
      // use member.id if there is a member prop; this is the case when the user
      // is not the same person that is creating the episode-- eg. CTM created
      memberId: getCreatorId(user, member),
      serviceSubtype: subtype ?? (null as any),
      body,
      documentIds,
      recipientId,
      recipientPodId,
      memberCurrentUsState,
      subjectFreetext: subjectFreeText?.replace(/\s/gi, "_"),
      subject: subject?.replace(/\s/gi, "_").toLowerCase(),
      ccCtmIds,
    },
  });

  return new Promise((resolve, reject) => {
    dispatch(mutateAsync({ ...create, queryKey })).then((res: Response) => {
      if (res.status === 200) {
        dispatch(setHasUnsavedInput(false));
        redirectAfterEpisodeCreation(res, navigate);
        resolve("success");
      } else if (typeof onError === "function") {
        onError((res.body as any)?.error);
        reject((res.body as any)?.error);
      }
    });
  });
};

export const serviceMenuMock = {
  headline: "How can we help you?",
  service_menu: [
    {
      key: "stuff",
      subtypes: [
        {
          key: "sick",
          display: "I'm sick",
          flow: [
            {
              precopy: "Tell us about your medical history.",
              helper_text: "Medication allergies, family history, etc.",
            },
            {
              precopy: "Tell us about your illness.",
              helper_text:
                "The more specifically you describe your health concern, the better we can assist you.",
            },
          ],
        },
        {
          key: "pregnancy",
          display: "I'm pregnant",
          flow: [
            {
              precopy: "Tell us about your medical history.",
              helper_text: "Medication allergies, family history, etc.",
            },
            {
              precopy: "What's your question about your pregnancy?",
              helper_text: "Please be specific",
            },
          ],
        },
      ],
      submenus: [] as OutputServiceLineSchema[],
      display: "I Need Some Help",
      headline: "What For??",
      color: "#d36958",
      highlight_color: "#ec7664",
      icon: "images/icons/question_mark.png",
    },
    {
      key: "womens_health",
      subtypes: [] as OutputSubTypeSchema[],
      submenus: [
        {
          key: "not_pregnant",
          subtypes: [
            {
              key: "uti",
              display: "I have a UTI",
              flow: [
                {
                  precopy: "Tell us about your medical history.",
                  helper_text: "Medication allergies, family history, etc.",
                },
                {
                  precopy: "Tell us about your UTI.",
                  helper_text: "When did it start, etc.",
                },
              ],
            },
            {
              key: "birth_control",
              display: "Help me fill my birth control prescription",
              flow: [
                {
                  precopy: "Confirm your insurance information.",
                  helper_text: "Carrier, member id, plan name, etc.",
                },
                {
                  precopy: "Let's figure out your birth control prescription.",
                  helper_text:
                    "Indicate which birth control you normally take, etc.",
                },
              ],
            },
          ],
          submenus: [] as OutputServiceLineSchema[],
          display: "This has Nothing to do with Pregnancy/Birth",
          headline: "What is your Women's Health concern?",
        },
      ],
      display: "I Need a Woman's Health Specialist",
      headline: "Why do you need a Woman's Health Specialist?",
      color: "#d36958",
      highlight_color: "#ec7664",
      icon: "images/icons/labcoat.png",
    },
  ],
  other: [] as OutputServiceLineSchema[],
};

export const applyDirectMessagingUI = (subTypeKey: string) => {
  switch (subTypeKey) {
    case "prescription_request":
    case "prescription_request_primary_care":
      return true;
    default:
      return false;
  }
};
