import { ReferralComponentCoreStateEnum } from "shared/api/src/models/ReferralComponentCore";

export const DECLINE = "decline";
export const CANCEL = "cancel";
export const VISIT_DID_NOT_TAKE_PLACE = "visitDidNotTakePlace";

export const EMPTY_STATE: string[] = [];

type ActionType = "decline" | "cancel" | "visitDidNotTakePlace";

const memberPastActionTreeSwitch = (state: ReferralComponentCoreStateEnum) => {
  switch (state) {
    case ReferralComponentCoreStateEnum.MissedNoShow:
    case ReferralComponentCoreStateEnum.RescheduledPending:
      return [DECLINE];
    default:
      return EMPTY_STATE;
  }
};

const memberInternalActionTreeSwitch = (
  state: ReferralComponentCoreStateEnum
) => {
  switch (state) {
    case ReferralComponentCoreStateEnum.MissedNoShow:
    case ReferralComponentCoreStateEnum.RescheduledPending:
      return [DECLINE];
    case ReferralComponentCoreStateEnum.Scheduled:
    case ReferralComponentCoreStateEnum.CheckInOpened:
    case ReferralComponentCoreStateEnum.CheckInInProgress:
    case ReferralComponentCoreStateEnum.CheckInCompleted:
    case ReferralComponentCoreStateEnum.CheckInCompletedNotAcknowledged:
      return [CANCEL];
    default:
      return EMPTY_STATE;
  }
};

const memberActionTreeSwitch = (state: ReferralComponentCoreStateEnum) => {
  switch (state) {
    case ReferralComponentCoreStateEnum.MissedNoShow:
    case ReferralComponentCoreStateEnum.RescheduledPending:
      return [DECLINE];
    case ReferralComponentCoreStateEnum.Scheduled:
    case ReferralComponentCoreStateEnum.CheckInOpened:
    case ReferralComponentCoreStateEnum.CheckInInProgress:
    case ReferralComponentCoreStateEnum.CheckInCompleted:
    case ReferralComponentCoreStateEnum.CheckInCompletedNotAcknowledged:
      return [CANCEL];
    default:
      return EMPTY_STATE;
  }
};

const ctmActionTreeSwitch = (state: ReferralComponentCoreStateEnum) => {
  switch (state) {
    case ReferralComponentCoreStateEnum.Scheduled:
    case ReferralComponentCoreStateEnum.CheckInOpened:
    case ReferralComponentCoreStateEnum.CheckInInProgress:
    case ReferralComponentCoreStateEnum.CheckInCompleted:
    case ReferralComponentCoreStateEnum.CheckInCompletedNotAcknowledged:
    case ReferralComponentCoreStateEnum.CheckInIncomplete:
    case ReferralComponentCoreStateEnum.CheckInFastTrackedConsentNeeded:
    case ReferralComponentCoreStateEnum.CheckInFastTrackedOutstandingItemsWithoutConsent:
      return [VISIT_DID_NOT_TAKE_PLACE];
    default:
      return EMPTY_STATE;
  }
};

interface queryActionInputs {
  accessingUserType: "ctm" | "member";
  state: ReferralComponentCoreStateEnum;
  action: ActionType;
  pastVisit: boolean;
  internalVisit: boolean;
}

const queryActionTree = ({
  accessingUserType,
  state,
  action,
  pastVisit,
  internalVisit,
}: queryActionInputs): boolean => {
  if (accessingUserType === "member" && pastVisit) {
    return memberPastActionTreeSwitch(state).includes(action);
  } else if (accessingUserType === "member" && internalVisit) {
    return memberInternalActionTreeSwitch(state).includes(action);
  } else if (accessingUserType === "ctm") {
    return ctmActionTreeSwitch(state).includes(action);
  } else {
    return memberActionTreeSwitch(state).includes(action);
  }
};

export default queryActionTree;
