import React, { FunctionComponent } from "react";
import SkeletonComponent, { SkeletonProps } from '@mui/material/Skeleton';
import PageSkeleton from "./PageSkeleton";
import ComponentSkeleton from "./ComponentSkeleton";
import TimelineSkeleton from "./TimelineSkeleton";
import ProviderPageSkeleton from "./ProviderPageSkeleton";
import ConversationSkeleton from "./ConversationSkeleton";
import VisitsSkeleton from "./VisitsSkeleton";
import FiltersSkeleton from "./FiltersSkeleton";
import InlineMessageSkeleton from "./InlineMessageSkeleton";
import HistoryModalSkeleton from "./HistoryModalSkeleton";
import SettingsHeadsUpNotificationsSkeleton from "./SettingsHeadsUpNotificationsSkeleton";

export type SkeletonAppearance =
  | "page"
  | "component"
  | "timeline"
  | "provider"
  | "visits"
  | "filters"
  | "conversations"
  | "inlineMessage"
  | "historyModal"
  | "settingNotifications";

interface IProps extends SkeletonProps {
  appearance?: SkeletonAppearance;
}

const Skeleton: FunctionComponent<IProps> = (props) => {
  const { appearance = "", ...otherProps } = props;
  switch (appearance) {
    case "page":
      return <PageSkeleton />;
    case "component":
      return <ComponentSkeleton />;
    case "timeline":
      return <TimelineSkeleton />;
    case "provider":
      return <ProviderPageSkeleton />;
    case "conversations":
      return <ConversationSkeleton {...otherProps} />;
    case "visits":
      return <VisitsSkeleton />;
    case "filters":
      return <FiltersSkeleton />;
    case "inlineMessage":
      return <InlineMessageSkeleton />;
    case "historyModal":
      return <HistoryModalSkeleton />;
    case "settingNotifications":
      return <SettingsHeadsUpNotificationsSkeleton />;
  }
  return <SkeletonComponent {...props} />;
};

export default Skeleton;
