// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CommonQuestionDetailsItemQuestionSets,
    CommonQuestionDetailsItemQuestionSetsFromJSON,
    CommonQuestionDetailsItemQuestionSetsToJSON,
} from './CommonQuestionDetailsItemQuestionSets';


/**
 * 
 * @export
 * @interface CommonQuestionDetailsItem
 */
export interface CommonQuestionDetailsItem  {
    /**
     * 
     * @type {number}
     * @memberof CommonQuestionDetailsItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CommonQuestionDetailsItem
     */
    sqsQId: string;
    /**
     * 
     * @type {string}
     * @memberof CommonQuestionDetailsItem
     */
    displayText: string;
    /**
     * 
     * @type {string}
     * @memberof CommonQuestionDetailsItem
     */
    sex: string;
    /**
     * 
     * @type {string}
     * @memberof CommonQuestionDetailsItem
     */
    type?: string;
    /**
     * 
     * @type {number}
     * @memberof CommonQuestionDetailsItem
     */
    tags: number;
    /**
     * 
     * @type {Array<CommonQuestionDetailsItemQuestionSets>}
     * @memberof CommonQuestionDetailsItem
     */
    questionSets?: Array<CommonQuestionDetailsItemQuestionSets>;
}

export function CommonQuestionDetailsItemFromJSON(json: any): CommonQuestionDetailsItem {
    return {
        'id': json['id'],
        'sqsQId': json['sqs_q_id'],
        'displayText': json['display_text'],
        'sex': json['sex'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'tags': json['tags'],
        'questionSets': !exists(json, 'question_sets') ? undefined : (json['question_sets'] as Array<any>).map(CommonQuestionDetailsItemQuestionSetsFromJSON),
    };
}

export function CommonQuestionDetailsItemToJSON(value?: CommonQuestionDetailsItem): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'sqs_q_id': value.sqsQId,
        'display_text': value.displayText,
        'sex': value.sex,
        'type': value.type,
        'tags': value.tags,
        'question_sets': value.questionSets === undefined ? undefined : (value.questionSets as Array<any>).map(CommonQuestionDetailsItemQuestionSetsToJSON),
    };
}


