// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AppointmentTypeSchema,
    AppointmentTypeSchemaFromJSON,
    AppointmentTypeSchemaToJSON,
} from './AppointmentTypeSchema';


/**
 * 
 * @export
 * @interface ServiceTypeSchema
 */
export interface ServiceTypeSchema  {
    /**
     * 
     * @type {string}
     * @memberof ServiceTypeSchema
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceTypeSchema
     */
    name: string;
    /**
     * 
     * @type {Array<AppointmentTypeSchema>}
     * @memberof ServiceTypeSchema
     */
    appointmentTypes: Array<AppointmentTypeSchema>;
}

export function ServiceTypeSchemaFromJSON(json: any): ServiceTypeSchema {
    return {
        'id': json['id'],
        'name': json['name'],
        'appointmentTypes': (json['appointment_types'] as Array<any>).map(AppointmentTypeSchemaFromJSON),
    };
}

export function ServiceTypeSchemaToJSON(value?: ServiceTypeSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'appointment_types': (value.appointmentTypes as Array<any>).map(AppointmentTypeSchemaToJSON),
    };
}


