/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    OnlineAppointmentSchema,
    OnlineAppointmentSchemaFromJSON,
    OnlineAppointmentSchemaToJSON,
} from '../models/OnlineAppointmentSchema'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface GetAppointmentTypesRequest {
    memberId?: number;
    method?: GetAppointmentTypesMethodEnum;
    schedulingType?: GetAppointmentTypesSchedulingTypeEnum;
    clinicId?: string;
}


export interface IAppointmentTypesApi {
      getAppointmentTypes: (requestParameters: GetAppointmentTypesRequest) => Promise<OnlineAppointmentSchema>
      getAppointmentTypesWithResponse: (requestParameters: GetAppointmentTypesRequest) => Promise<{ value: OnlineAppointmentSchema, response: any}>
}

/**
 * no description
 */
export class AppointmentTypesApi extends runtime.BaseAPI {

    /**
     * Retrieve all online appointment types
     * Retrieve all online appointment types
     */
    getAppointmentTypesRaw = async (requestParameters: GetAppointmentTypesRequest): Promise<{ runtime: runtime.ApiResponse<OnlineAppointmentSchema>, response: any}> => {
                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.memberId !== undefined) {
            queryParameters['member_id'] = requestParameters.memberId;
        }

        if (requestParameters.method !== undefined) {
            queryParameters['method'] = requestParameters.method;
        }

        if (requestParameters.schedulingType !== undefined) {
            queryParameters['scheduling_type'] = requestParameters.schedulingType;
        }

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinic_id'] = requestParameters.clinicId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/appointment_types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OnlineAppointmentSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Retrieve all online appointment types
     * Retrieve all online appointment types
     */
    getAppointmentTypes = async (requestParameters: GetAppointmentTypesRequest): Promise<OnlineAppointmentSchema> => {
        const { runtime } = await this.getAppointmentTypesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Retrieve all online appointment types
     * Retrieve all online appointment types
     */
    getAppointmentTypesWithResponse = async (requestParameters: GetAppointmentTypesRequest): Promise<{ value: OnlineAppointmentSchema, response: any}> => {
        const { runtime, response } = await this.getAppointmentTypesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetAppointmentTypesMethodEnum {
    InPerson = 'in_person',
    Virtual = 'virtual'
}
/**
    * @export
    * @enum {string}
    */
export enum GetAppointmentTypesSchedulingTypeEnum {
    VirtualVisitReferral = 'virtual_visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    SelfSchedule = 'self_schedule'
}

export const fetchAppointmentTypesApi: IAppointmentTypesApi  = new AppointmentTypesApi();
