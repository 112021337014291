// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberAccountInfoOutputAllOfAssignedPcp
 */
export interface MemberAccountInfoOutputAllOfAssignedPcp  {
    /**
     * 
     * @type {string}
     * @memberof MemberAccountInfoOutputAllOfAssignedPcp
     */
    truncatedName?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAccountInfoOutputAllOfAssignedPcp
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAccountInfoOutputAllOfAssignedPcp
     */
    firstName?: string;
}

export function MemberAccountInfoOutputAllOfAssignedPcpFromJSON(json: any): MemberAccountInfoOutputAllOfAssignedPcp {
    return {
        'truncatedName': !exists(json, 'truncated_name') ? undefined : json['truncated_name'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
    };
}

export function MemberAccountInfoOutputAllOfAssignedPcpToJSON(value?: MemberAccountInfoOutputAllOfAssignedPcp): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'truncated_name': value.truncatedName,
        'name': value.name,
        'first_name': value.firstName,
    };
}


