import React from "react";
import { useBreakpointMatch } from "utils/useBreakpointMatch";
import { useTheme, Theme } from "@mui/material";
import { ICardItemProps } from "../CardItem";
import Grid from "components/Grid";

interface IProps {
  topDivider?: boolean;
  bottomDivider?: boolean;
  style?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  children?: React.ReactNode;
}

const CardSection: React.FC<IProps> = (props) => {
  const { hasMatch } = useBreakpointMatch();
  const { matches } = useBreakpointMatch();
  const { palette, spacing } = useTheme<Theme>();
  const { style: overideStyle } = props;
  const childrenCollection = props.children as any;
  const dividerColor = palette?.strokes?.divider;
  const containerStyle: any = {
    flexGrow: 1,
    justifyContent: "space-between",
    flexWrap: matches.xs && !matches.lg ? "wrap" : "nowrap",
    ...props.containerStyle,
  };

  if (props.topDivider) {
    containerStyle.borderTop = `solid 1px ${dividerColor}`;
  }

  if (props.bottomDivider) {
    containerStyle.borderBottom = `solid 1px ${dividerColor}`;
  }

  return (
    <Grid container style={containerStyle}>
      {React.Children.map(childrenCollection, (child, index) => {
        if (!child) {
          // some children are null or undefined
          return null;
        }
        const leftDividerStyle = {
          borderLeft: `solid 0px ${dividerColor}`,
        };
        const bottomDividerStyle = {
          borderBottom: `solid 0px ${dividerColor}`,
        };
        const {
          leftDividerVisibleUp,
          bottomDividerVisibleDown,
          ...remainingChildProps
        }: ICardItemProps = child?.props || {};

        let style = {
          padding: spacing(3),
        } as any;

        if (
          index > 0 &&
          leftDividerVisibleUp &&
          hasMatch(leftDividerVisibleUp, "up")
        ) {
          leftDividerStyle.borderLeft = `solid 1px ${dividerColor}`;
          style = {
            ...style,
            ...leftDividerStyle,
          };
        }

        if (
          bottomDividerVisibleDown &&
          hasMatch(bottomDividerVisibleDown, "down")
        ) {
          bottomDividerStyle.borderBottom = `solid 1px ${dividerColor}`;
          style = {
            ...style,
            ...bottomDividerStyle,
          };
        }
        // avoid passing alignItems to a Grid item
        delete remainingChildProps?.alignItems;

        return (
          <Grid
            style={{ ...style, ...overideStyle }}
            item
            {...remainingChildProps}
          >
            {React.cloneElement(child, {})}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default CardSection;
