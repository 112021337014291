export const screenReaderSpeak = (
  text: string,
  priority?: "off" | "assertive" | "polite"
) => {
  const el = document.createElement("div");
  const id = "speak-" + Date.now();
  el.setAttribute("id", id);
  el.setAttribute("aria-live", priority || "polite");
  el.classList.add("sr-only");
  document.body.appendChild(el);

  window.setTimeout(() => {
    // @ts-ignore: Object is possibly 'null'.
    document.getElementById(id).innerHTML = text;
  }, 100);

  window.setTimeout(() => {
    // @ts-ignore: Object is possibly 'null'.
    document.body.removeChild(document.getElementById(id));
  }, 1000);
};
