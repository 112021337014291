import React from "react";
import Autocomplete from "components/Autocomplete";
import { AutocompleteProps } from "@mui/material/Autocomplete";

const ComboBox = (
  props: AutocompleteProps<string, boolean, boolean, boolean>
) => {
  return <Autocomplete {...props} />;
};

const shouldSkipRerender = (
  prev: AutocompleteProps<string, boolean, boolean, boolean>,
  next: AutocompleteProps<string, boolean, boolean, boolean>
) => {
  const optionsAreSame =
    JSON.stringify(prev?.options) === JSON.stringify(next?.options);
  const valueIsSame =
    JSON.stringify(prev?.inputValue) === JSON.stringify(next?.inputValue);
  const inputValueIsSame =
    JSON.stringify(prev?.inputValue) === JSON.stringify(next?.inputValue);
  return optionsAreSame && valueIsSame && inputValueIsSame && false;
};

export default React.memo(ComboBox, shouldSkipRerender);
