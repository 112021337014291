import React from "react";
import RouteAccess from "components/Access/RouteAccess";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";
import { Route } from "react-router";
import { loadLazy } from "utils/loadLazy";

const LazyMemberHealthRecords = loadLazy(() => import("./pages/index"));
const LazyRequestRenewal = loadLazy(() => import("./pages/RequestRenewal"));

export default () => [
  <Route
    key="healthProfileParent"
    path="/health-records"
    element={<RouteAccess to={PermissionsEnum.HealthRecordsRead} />}
  >
    <Route index element={<LazyMemberHealthRecords />} />
  </Route>,
  <Route
    key="healthRecordsRefillRequest"
    path="/health-records/:memberId/request-renewal"
    element={<RouteAccess to={PermissionsEnum.EpisodesCreate} />}
  >
    <Route index element={<LazyRequestRenewal />} />
  </Route>,
];
