// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';
import {
    Appointment,
    AppointmentFromJSON,
    AppointmentToJSON,
} from './Appointment';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
    CompactComponentReferenceDeclineReason,
    CompactComponentReferenceDeclineReasonFromJSON,
    CompactComponentReferenceDeclineReasonToJSON,
} from './CompactComponentReferenceDeclineReason';
import {
    ComponentDetailsCoreCreatorInfo,
    ComponentDetailsCoreCreatorInfoFromJSON,
    ComponentDetailsCoreCreatorInfoToJSON,
} from './ComponentDetailsCoreCreatorInfo';
import {
    DocumentOutputSchema,
    DocumentOutputSchemaFromJSON,
    DocumentOutputSchemaToJSON,
} from './DocumentOutputSchema';
import {
    RecipientOptionSchema,
    RecipientOptionSchemaFromJSON,
    RecipientOptionSchemaToJSON,
} from './RecipientOptionSchema';
import {
    VisitType,
    VisitTypeFromJSON,
    VisitTypeToJSON,
} from './VisitType';


/**
 * 
 * @export
 * @interface CompactComponentReference
 */
export interface CompactComponentReference  {
    /**
     * 
     * @type {string}
     * @memberof CompactComponentReference
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CompactComponentReference
     */
    resourceUrl: string;
    /**
     * 
     * @type {ComponentDetailsCoreCreatorInfo}
     * @memberof CompactComponentReference
     */
    creatorInfo?: ComponentDetailsCoreCreatorInfo;
    /**
     * 
     * @type {string}
     * @memberof CompactComponentReference
     */
    type?: CompactComponentReferenceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CompactComponentReference
     */
    body?: string;
    /**
     * 
     * @type {object}
     * @memberof CompactComponentReference
     */
    jsonBody?: object;
    /**
     * 
     * @type {Date}
     * @memberof CompactComponentReference
     */
    addedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CompactComponentReference
     */
    calculatorId?: string;
    /**
     * 
     * @type {Array<DocumentOutputSchema>}
     * @memberof CompactComponentReference
     */
    documentInfo?: Array<DocumentOutputSchema>;
    /**
     * 
     * @type {string}
     * @memberof CompactComponentReference
     */
    state?: CompactComponentReferenceStateEnum;
    /**
     * 
     * @type {CompactComponentReferenceDeclineReason}
     * @memberof CompactComponentReference
     */
    declineReason?: CompactComponentReferenceDeclineReason;
    /**
     * 
     * @type {Appointment}
     * @memberof CompactComponentReference
     */
    appointment?: Appointment;
    /**
     * 
     * @type {CTMInfo}
     * @memberof CompactComponentReference
     */
    provider?: CTMInfo;
    /**
     * 
     * @type {VisitType}
     * @memberof CompactComponentReference
     */
    visitType?: VisitType;
    /**
     * 
     * @type {Array<ActionableItemSummary>}
     * @memberof CompactComponentReference
     */
    actionableItems?: Array<ActionableItemSummary>;
    /**
     * 
     * @type {Date}
     * @memberof CompactComponentReference
     */
    submittedAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CompactComponentReference
     */
    timeSensitive?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompactComponentReference
     */
    timeSensitiveReasons?: Array<string>;
    /**
     * 
     * @type {RecipientOptionSchema}
     * @memberof CompactComponentReference
     */
    recipient?: RecipientOptionSchema;
}

export function CompactComponentReferenceFromJSON(json: any): CompactComponentReference {
    return {
        'id': json['id'],
        'resourceUrl': json['resource_url'],
        'creatorInfo': !exists(json, 'creator_info') ? undefined : ComponentDetailsCoreCreatorInfoFromJSON(json['creator_info']),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'jsonBody': !exists(json, 'json_body') ? undefined : json['json_body'],
        'addedAt': !exists(json, 'added_at') ? undefined : new Date(json['added_at']),
        'calculatorId': !exists(json, 'calculator_id') ? undefined : json['calculator_id'],
        'documentInfo': !exists(json, 'document_info') ? undefined : (json['document_info'] as Array<any>).map(DocumentOutputSchemaFromJSON),
        'state': !exists(json, 'state') ? undefined : json['state'],
        'declineReason': !exists(json, 'decline_reason') ? undefined : CompactComponentReferenceDeclineReasonFromJSON(json['decline_reason']),
        'appointment': !exists(json, 'appointment') ? undefined : AppointmentFromJSON(json['appointment']),
        'provider': !exists(json, 'provider') ? undefined : CTMInfoFromJSON(json['provider']),
        'visitType': !exists(json, 'visit_type') ? undefined : VisitTypeFromJSON(json['visit_type']),
        'actionableItems': !exists(json, 'actionable_items') ? undefined : (json['actionable_items'] as Array<any>).map(ActionableItemSummaryFromJSON),
        'submittedAt': !exists(json, 'submitted_at') ? undefined : new Date(json['submitted_at']),
        'timeSensitive': !exists(json, 'time_sensitive') ? undefined : json['time_sensitive'],
        'timeSensitiveReasons': !exists(json, 'time_sensitive_reasons') ? undefined : json['time_sensitive_reasons'],
        'recipient': !exists(json, 'recipient') ? undefined : RecipientOptionSchemaFromJSON(json['recipient']),
    };
}

export function CompactComponentReferenceToJSON(value?: CompactComponentReference): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'resource_url': value.resourceUrl,
        'creator_info': ComponentDetailsCoreCreatorInfoToJSON(value.creatorInfo),
        'type': value.type,
        'body': value.body,
        'json_body': value.jsonBody,
        'added_at': value.addedAt === undefined ? undefined : value.addedAt.toISOString(),
        'calculator_id': value.calculatorId,
        'document_info': value.documentInfo === undefined ? undefined : (value.documentInfo as Array<any>).map(DocumentOutputSchemaToJSON),
        'state': value.state,
        'decline_reason': CompactComponentReferenceDeclineReasonToJSON(value.declineReason),
        'appointment': AppointmentToJSON(value.appointment),
        'provider': CTMInfoToJSON(value.provider),
        'visit_type': VisitTypeToJSON(value.visitType),
        'actionable_items': value.actionableItems === undefined ? undefined : (value.actionableItems as Array<any>).map(ActionableItemSummaryToJSON),
        'submitted_at': value.submittedAt === undefined ? undefined : value.submittedAt.toISOString(),
        'time_sensitive': value.timeSensitive,
        'time_sensitive_reasons': value.timeSensitiveReasons,
        'recipient': RecipientOptionSchemaToJSON(value.recipient),
    };
}

/**
* @export
* @enum {string}
*/
export enum CompactComponentReferenceTypeEnum {
    EpisodeCreated = 'episode_created',
    MemberMessage = 'member_message',
    CtmMessage = 'ctm_message',
    Diagnosis = 'diagnosis',
    CtmEpisodeCreated = 'ctm_episode_created',
    CtmMessagingEpisodeCreated = 'ctm_messaging_episode_created',
    QuestionSet = 'question_set',
    Calculator = 'calculator',
    VirtualVisitReferral = 'virtual_visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    VisitReferral = 'visit_referral',
    MarketingMessage = 'marketing_message',
    Billing = 'billing',
    SelfScheduleAppointment = 'self_schedule_appointment'
}
/**
* @export
* @enum {string}
*/
export enum CompactComponentReferenceStateEnum {
    Unacknowledged = 'unacknowledged',
    AcknowledgedPendingResponse = 'acknowledged_pending_response',
    Unread = 'unread',
    UnreadPendingResponse = 'unread_pending_response',
    ReadPendingResponse = 'read_pending_response',
    ReadResponseStarted = 'read_response_started',
    ResolvedWithReply = 'resolved_with_reply',
    Resolved = 'resolved',
    Draft = 'draft'
}


