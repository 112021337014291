import React from "react";
import clsx from "clsx";

import Box from "components/Box";
import Container from "components/Container";
import DashboardWelcome from "components/LandingPage/WelcomeMessage/DashboardWelcome";
import { HeroHeader } from "templates/Header";

import { JsonUser } from "shared/api/src/models/JsonUser";
import { useStyles } from "pages/LandingPage/styles";

interface IHeaderProps {
  hasAffinity: boolean;
  isScreenSmallerOrWider: boolean;
  totalActiveConversations: number;
  hasCreatedEoc: boolean;
  user: JsonUser | undefined;
}

const Header = ({
  hasAffinity,
  isScreenSmallerOrWider,
  totalActiveConversations,
  hasCreatedEoc,
  user,
}: IHeaderProps) => {
  const classes = useStyles();
  return (
    <HeroHeader
      size="medium"
      color="dark-blue"
      headerHeights={{ smallScreen: "10px", largeScreen: "120px" }}
    >
      <Container
        maxWidth="xl"
        className={clsx(
          classes.headerContainer,
          hasAffinity && classes.headerContainerAffinity,
          !isScreenSmallerOrWider ? classes.headerMobile : ""
        )}
      >
        <Box
          pt={3}
          className={
            hasAffinity
              ? classes.dashboardContainerAffinity
              : classes.dashboardContainer
          }
        >
          <DashboardWelcome
            activeEpisodesLength={totalActiveConversations}
            hasCreatedEoc={hasCreatedEoc}
            // @ts-ignore: Object is possibly 'null'.
            user={user}
          />
        </Box>
      </Container>
    </HeroHeader>
  );
};

export default Header;
