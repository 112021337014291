import React from "react";
import Typography from "components/Typography";
import useFormatDate from "shared/utils/useFormatDate";

interface IProps {
  startAt: Date;
  endAt: Date;
}

const VisitTime = ({ startAt, endAt }: IProps) => {
  const formatDate = useFormatDate();
  const visitStartTime = formatDate(startAt, "EEE MMM d, yyyy h:mm");
  const visitEndTime = formatDate(endAt, "h:mm a");
  const visitTime = `${visitStartTime} - ${visitEndTime}`;
  return (
    <Typography
      color="textSecondary"
      appearance="smallBody"
      component="span"
      data-testid="visit-time"
    >
      {visitTime}
    </Typography>
  );
};

export default VisitTime;
