// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SourceQuestionSetSchema
 */
export interface SourceQuestionSetSchema  {
    /**
     * 
     * @type {string}
     * @memberof SourceQuestionSetSchema
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceQuestionSetSchema
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof SourceQuestionSetSchema
     */
    version?: number;
}

export function SourceQuestionSetSchemaFromJSON(json: any): SourceQuestionSetSchema {
    return {
        'category': !exists(json, 'category') ? undefined : json['category'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function SourceQuestionSetSchemaToJSON(value?: SourceQuestionSetSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'category': value.category,
        'title': value.title,
        'version': value.version,
    };
}


