// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PatientAdminRequestBody
 */
export interface PatientAdminRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof PatientAdminRequestBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof PatientAdminRequestBody
     */
    xoid?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientAdminRequestBody
     */
    user?: string;
    /**
     * 
     * @type {string}
     * @memberof PatientAdminRequestBody
     */
    type: PatientAdminRequestBodyTypeEnum;
}

export function PatientAdminRequestBodyFromJSON(json: any): PatientAdminRequestBody {
    return {
        'email': json['email'],
        'xoid': !exists(json, 'xoid') ? undefined : json['xoid'],
        'user': !exists(json, 'user') ? undefined : json['user'],
        'type': json['type'],
    };
}

export function PatientAdminRequestBodyToJSON(value?: PatientAdminRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'email': value.email,
        'xoid': value.xoid,
        'user': value.user,
        'type': value.type,
    };
}

/**
* @export
* @enum {string}
*/
export enum PatientAdminRequestBodyTypeEnum {
    Invite = 'invite',
    Verify = 'verify',
    Reset = 'reset',
    Inactivate = 'inactivate',
    Reactivate = 'reactivate',
    Unrestrict = 'unrestrict',
    ResetMfa = 'reset_mfa',
    Auth0Unblock = 'auth0_unblock',
    Clear = 'clear',
    Unlock = 'unlock',
    ReactivateAndResetPassword = 'reactivate_and_reset_password'
}


