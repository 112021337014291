// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    WorkspaceDraftDataSchemaCalculator,
    WorkspaceDraftDataSchemaCalculatorFromJSON,
    WorkspaceDraftDataSchemaCalculatorToJSON,
} from './WorkspaceDraftDataSchemaCalculator';


/**
 * 
 * @export
 * @interface CompleteCalculatorRequestBody
 */
export interface CompleteCalculatorRequestBody  {
    /**
     * 
     * @type {object}
     * @memberof CompleteCalculatorRequestBody
     */
    surveyAnswers?: object;
    /**
     * 
     * @type {WorkspaceDraftDataSchemaCalculator}
     * @memberof CompleteCalculatorRequestBody
     */
    calculatorBody?: WorkspaceDraftDataSchemaCalculator;
}

export function CompleteCalculatorRequestBodyFromJSON(json: any): CompleteCalculatorRequestBody {
    return {
        'surveyAnswers': !exists(json, 'survey_answers') ? undefined : json['survey_answers'],
        'calculatorBody': !exists(json, 'calculator_body') ? undefined : WorkspaceDraftDataSchemaCalculatorFromJSON(json['calculator_body']),
    };
}

export function CompleteCalculatorRequestBodyToJSON(value?: CompleteCalculatorRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'survey_answers': value.surveyAnswers,
        'calculator_body': WorkspaceDraftDataSchemaCalculatorToJSON(value.calculatorBody),
    };
}


