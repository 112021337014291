// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';


/**
 * 
 * @export
 * @interface EpisodeSummaryAllOf
 */
export interface EpisodeSummaryAllOf  {
    /**
     * 
     * @type {CTMInfo}
     * @memberof EpisodeSummaryAllOf
     */
    lastCtmUpdater?: CTMInfo;
}

export function EpisodeSummaryAllOfFromJSON(json: any): EpisodeSummaryAllOf {
    return {
        'lastCtmUpdater': !exists(json, 'last_ctm_updater') ? undefined : CTMInfoFromJSON(json['last_ctm_updater']),
    };
}

export function EpisodeSummaryAllOfToJSON(value?: EpisodeSummaryAllOf): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'last_ctm_updater': CTMInfoToJSON(value.lastCtmUpdater),
    };
}


