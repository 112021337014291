// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FiltersSchema
 */
export interface FiltersSchema  {
    /**
     * 
     * @type {string}
     * @memberof FiltersSchema
     */
    serviceLine?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof FiltersSchema
     */
    age?: Array<number>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FiltersSchema
     */
    sex?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FiltersSchema
     */
    filter?: string;
}

export function FiltersSchemaFromJSON(json: any): FiltersSchema {
    return {
        'serviceLine': !exists(json, 'service_line') ? undefined : json['service_line'],
        'age': !exists(json, 'age') ? undefined : json['age'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
    };
}

export function FiltersSchemaToJSON(value?: FiltersSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'service_line': value.serviceLine,
        'age': value.age,
        'sex': value.sex,
        'filter': value.filter,
    };
}


