// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Appointment,
    AppointmentFromJSON,
    AppointmentToJSON,
} from './Appointment';
import {
    CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
    CtmMessageComponentOutputProps,
    CtmMessageComponentOutputPropsFromJSON,
    CtmMessageComponentOutputPropsToJSON,
} from './CtmMessageComponentOutputProps';
import {
    MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';
import {
    ReferralComponentCoreDeclineReason,
    ReferralComponentCoreDeclineReasonFromJSON,
    ReferralComponentCoreDeclineReasonToJSON,
} from './ReferralComponentCoreDeclineReason';
import {
    VisitType,
    VisitTypeFromJSON,
    VisitTypeToJSON,
} from './VisitType';


/**
 * 
 * @export
 * @interface VisitReferralComponentOutputProps
 */
export interface VisitReferralComponentOutputProps  {
    /**
     * 
     * @type {VisitType}
     * @memberof VisitReferralComponentOutputProps
     */
    visitType?: VisitType;
    /**
     * 
     * @type {CTMInfo}
     * @memberof VisitReferralComponentOutputProps
     */
    provider: CTMInfo;
    /**
     * 
     * @type {MemberInfo}
     * @memberof VisitReferralComponentOutputProps
     */
    memberInfo?: MemberInfo;
    /**
     * 
     * @type {string}
     * @memberof VisitReferralComponentOutputProps
     */
    state: VisitReferralComponentOutputPropsStateEnum;
    /**
     * 
     * @type {Appointment}
     * @memberof VisitReferralComponentOutputProps
     */
    appointment?: Appointment;
    /**
     * 
     * @type {ReferralComponentCoreDeclineReason}
     * @memberof VisitReferralComponentOutputProps
     */
    declineReason?: ReferralComponentCoreDeclineReason;
    /**
     * 
     * @type {CtmMessageComponentOutputProps}
     * @memberof VisitReferralComponentOutputProps
     */
    messageComponent?: CtmMessageComponentOutputProps;
}

export function VisitReferralComponentOutputPropsFromJSON(json: any): VisitReferralComponentOutputProps {
    return {
        'visitType': !exists(json, 'visit_type') ? undefined : VisitTypeFromJSON(json['visit_type']),
        'provider': CTMInfoFromJSON(json['provider']),
        'memberInfo': !exists(json, 'member_info') ? undefined : MemberInfoFromJSON(json['member_info']),
        'state': json['state'],
        'appointment': !exists(json, 'appointment') ? undefined : AppointmentFromJSON(json['appointment']),
        'declineReason': !exists(json, 'decline_reason') ? undefined : ReferralComponentCoreDeclineReasonFromJSON(json['decline_reason']),
        'messageComponent': !exists(json, 'message_component') ? undefined : CtmMessageComponentOutputPropsFromJSON(json['message_component']),
    };
}

export function VisitReferralComponentOutputPropsToJSON(value?: VisitReferralComponentOutputProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'visit_type': VisitTypeToJSON(value.visitType),
        'provider': CTMInfoToJSON(value.provider),
        'member_info': MemberInfoToJSON(value.memberInfo),
        'state': value.state,
        'appointment': AppointmentToJSON(value.appointment),
        'decline_reason': ReferralComponentCoreDeclineReasonToJSON(value.declineReason),
        'message_component': CtmMessageComponentOutputPropsToJSON(value.messageComponent),
    };
}

/**
* @export
* @enum {string}
*/
export enum VisitReferralComponentOutputPropsStateEnum {
    UnreadPending = 'unread_pending',
    ReadPending = 'read_pending',
    Declined = 'declined',
    ResolvedDeclinedAckd = 'resolved_declined_ackd',
    Scheduled = 'scheduled',
    ResolvedCompleted = 'resolved_completed',
    MissedNoShow = 'missed_no_show',
    CanceledByMember = 'canceled_by_member',
    ResolvedCanceledAckd = 'resolved_canceled_ackd',
    CanceledByCtm = 'canceled_by_ctm',
    RescheduledPending = 'rescheduled_pending',
    ResolvedCanceledViewed = 'resolved_canceled_viewed',
    DeclinedAckdPending = 'declined_ackd_pending',
    CanceledAckdPending = 'canceled_ackd_pending'
}


