import React, { FunctionComponent } from "react";
import IconButtonComponent, {
  IconButtonProps,
} from "@mui/material/IconButton";

const IconButton: FunctionComponent<IconButtonProps> = (props) => {
  return <IconButtonComponent {...props} />;
};

export default IconButton;
