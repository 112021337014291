import { useQuery } from "react-query";
import { GetLabsRequest, fetchLabsApi } from "shared/fetch/src/apis/LabsApi";

export const memberLabsListQueryKey = (id?: string | number, page?: string) => [
  "memberLabsList",
  id?.toString(),
  page?.toString(),
];

const useGetLabsList = (request: GetLabsRequest) => {
  const query = useQuery(
    memberLabsListQueryKey(request.id, request.page),
    () => fetchLabsApi.getLabs(request),
    {
      enabled: Number.isInteger(+request.id),
      keepPreviousData: true,
      staleTime: 300000,
      cacheTime: 360000,
    }
  );
  return query;
};

export default useGetLabsList;
