import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material";
import useGetProvider from "shared/features/memberNetwork/useGetProvider";
import { CTMBioProfileSection } from "shared/fetch/src/models/CTMBioProfileSection";
import DynamicHeading from "components/DynamicHeadings";
import Dialog from "components/Dialog";
import DialogContent from "components/Dialog/DialogContent";
import DialogActions from "components/Dialog/DialogActions";
import showdown from "showdown";
import Asset from "components/Asset";
import Box from "components/Box";
import IconButton from "components/Button/IconButton";
import Button from "components/Button";
import Avatar from "components/Avatar";
import Typography from "components/Typography";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: "flex",
    justifyContent: "left",
    paddingTop: 0,
  },
  profileContainer: {
    display: "flex",
    alignItems: "baseline",
    marginBottom: theme.spacing(1),
    flexDirection: "column",
    marginTop: theme.spacing(0.5),
  },
  profileContent: {
    color: theme?.palette?.text?.secondary,
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(1),
    "& p": {
      margin: 0,
    },
    "& ul": {
      margin: 0,
      padding: 0,
      listStylePosition: "inside",
    },
    fontSize: theme.typography.subtitle2.fontSize,
    maxWidth: "unset",
  },
  profileContentLabel: {
    textAlign: "right",

    width: "unset",
    fontSize: theme.typography.subtitle2.fontSize,
    fontWeight: "bold",
  },
  dialog: {
    "& .MuiDialog-paper": {
      width: theme.spacing(63.375),
      margin: 0,
      borderRadius: `${theme.spacing(1.25)}`,
      [theme.breakpoints.down("sm")]: {
        width: theme.spacing(45),
      },
    },
    "& .MuiDialogContent-root": {
      paddingTop: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      paddingLeft: theme.spacing(3),
    },
    "& .MuiBox-root": {
      top: theme.spacing(3),
    },
  },
  dialogContent: {
    paddingRight: theme.spacing(3),
  },
  dialogContentSection: {
    paddingLeft: theme.spacing(4),
  },
  profileInfo: {
    display: "flex",
    flexDirection: "column",
    color: theme.palette.text.primary,
    margin: theme.spacing(2),
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      textAlign: "unset",
    },
  },
  profileInfoMobile: {
    ["@media (max-width: 992px) and (min-width: 766px)"]: {
      textAlign: "left !important",
      margin: 0,
    },
    [theme?.breakpoints?.down("sm")]: {
      margin: 0,
    },
  },
  profileTitle: {
    color: theme?.palette?.appBackground?.black,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.caption.fontSize,
    },
  },
  profileSubTitle: {
    color: theme?.palette?.text?.secondary,
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.caption.fontSize,
    },
  },
  modalHeader: {
    display: "flex",
    flexDirection: "row",
    paddingTop: theme.spacing(8),
    paddingLeft: theme.spacing(3),
  },
  profileName: {
    paddingLeft: theme.spacing(2),
  },
}));

const CloseButton = (props: any) => (
  <Box color="text.tertiary" position="absolute" right={20} top={8}>
    <IconButton
      key="close"
      aria-label="close"
      color="inherit"
      onClick={(e) => {
        if (props.onClose) {
          props.onClose(e);
        }
      }}
      size="large"
    >
      <Asset
        name="icon-close"
        style={{
          width: 16,
          height: 16,
          display: "inline-block",
          fill: "currentColor",
          textAlign: "center",
        }}
      />
    </IconButton>
  </Box>
);

interface IProps {
  id: string;
  onClose: () => void;
  open: boolean;
}

const ProviderModal = ({ id, onClose, open }: IProps) => {
  const classes = useStyles();

  const converter = new showdown.Converter({
    underline: true,
    openLinksInNewWindow: true,
  });

  const { provider } = useGetProvider(id);
  const profileSections = provider?.profileSections || [];
  const guild = provider?.guilds?.[0];

  const renderProfileSection = (isMobile: boolean = false) => {
    return (
      <>
        {profileSections?.map((profileSection: CTMBioProfileSection) => {
          if (!profileSection.content) {
            return null;
          }

          return (
            <div
              className={classes.profileContainer}
              key={profileSection.label}
            >
              {profileSection.label !== "Biography" && (
                <DynamicHeading.LevelBoundary>
                  <DynamicHeading.H
                    className={classes.profileContentLabel}
                    appearance={isMobile ? "body" : "h5"}
                    data-testid={`${profileSection.label}-content-label`}
                    color="textPrimary"
                  >
                    {profileSection.label}
                  </DynamicHeading.H>
                </DynamicHeading.LevelBoundary>
              )}
              <div
                className={classes.profileContent}
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(profileSection.content),
                }}
              />
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div>
      <Dialog
        open={open}
        role="dialog"
        className={classes.dialog}
        ariaLabelledBy="alert-dialog-title"
        aria-modal="true"
      >
        <div className={classes.modalHeader}>
          <Avatar
            containerStyle={{ margin: 0 }}
            displayText={provider?.name}
            src={provider?.avatarUrl}
            size="medium"
            isPresentational // this instance is wrapped in an anchor element
          />
          <div>
            <Typography
              aria-label="providerbio"
              id="alert-dialog-title"
              appearance="h5"
              className={clsx(classes.profileTitle, classes.profileName)}
            >
              {provider?.name}
            </Typography>
            <Typography
              appearance="body"
              className={clsx(classes.profileSubTitle, classes.profileName)}
            >
              {guild}
            </Typography>
          </div>
        </div>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.dialogContentSection}>
            {renderProfileSection(true)}
          </div>
        </DialogContent>
        <CloseButton onClose={onClose} />
        <DialogActions horizontal className={classes.button}>
          <Button color="primary" onClick={onClose} data-testid="close-button">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProviderModal;
