// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeclineVisitRequestBody
 */
export interface DeclineVisitRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof DeclineVisitRequestBody
     */
    declineReason?: string;
    /**
     * 
     * @type {string}
     * @memberof DeclineVisitRequestBody
     */
    reasonOption?: DeclineVisitRequestBodyReasonOptionEnum;
}

export function DeclineVisitRequestBodyFromJSON(json: any): DeclineVisitRequestBody {
    return {
        'declineReason': !exists(json, 'decline_reason') ? undefined : json['decline_reason'],
        'reasonOption': !exists(json, 'reason_option') ? undefined : json['reason_option'],
    };
}

export function DeclineVisitRequestBodyToJSON(value?: DeclineVisitRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'decline_reason': value.declineReason,
        'reason_option': value.reasonOption,
    };
}

/**
* @export
* @enum {string}
*/
export enum DeclineVisitRequestBodyReasonOptionEnum {
    DontLikeDoctor = 'dont_like_doctor',
    DontHaveTime = 'dont_have_time',
    GoSomewhereElse = 'go_somewhere_else',
    FeelingBetter = 'feeling_better',
    TimesDontWork = 'times_dont_work'
}


