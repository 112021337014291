import React, { FunctionComponent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "shared/types";
import {
  Navigate,
  Outlet,
  useLocation,
  useOutlet,
  useParams,
} from "react-router";
import Access from "../";
import { AccessToType } from "hooks/useAccess";
import { CmsNavigationSchemaHierarchy } from "shared/api/src/models/CmsNavigationSchemaHierarchy";
import useAddToPatientHistory from "shared/features/user/useAddToPatientHistory";
import { displayMobileComponentModal } from "shared/state/ui/mobileComponentModal";
import useMobileCheck from "hooks/useMobileCheck";
import ErrorBoundaryPage from "../ErrorBoundaryPage";
import useUpdateReduxMemberId from "hooks/useUpdateReduxMemberId";

interface MobileOverlayProps {
  title: string;
  fullScreen?: boolean;
  onClose?: () => void;
  routeComponent?: boolean;
}

interface IProps {
  to: AccessToType;
  memberId?: string;
  validate?: (
    cmsMenuHierarchy: CmsNavigationSchemaHierarchy[],
    location: string,
    paramPositionOne: number,
    paramPositionTwo: number
  ) => boolean;
  isListPage?: boolean;
  memberOnly?: boolean;
  ctmOnly?: boolean;
  mobileOverlay?: MobileOverlayProps;
}

const RouteAccess: FunctionComponent<IProps> = (props) => {
  const { to, memberOnly, ctmOnly, mobileOverlay } = props;
  const { memberId } = useParams();

  const dispatch = useDispatch();
  const isMobile = useMobileCheck();

  useAddToPatientHistory(memberId);
  useUpdateReduxMemberId(memberId!);

  const cmsMenuHierarchyItems: any[] | undefined = useSelector(
    (state: State) => state.entities.cmsMenuHierarchyItems
  );

  const location = useLocation();

  const content = useOutlet() ?? undefined;

  if (!!cmsMenuHierarchyItems && props.validate) {
    const isValid = props?.isListPage
      ? props.validate(
          cmsMenuHierarchyItems as CmsNavigationSchemaHierarchy[],
          location.pathname,
          1,
          2
        )
      : props.validate(
          cmsMenuHierarchyItems as CmsNavigationSchemaHierarchy[],
          location.pathname,
          2,
          3
        );

    if (!isValid) {
      return <Navigate to="/not-found" />;
    }
  }

  useEffect(() => {
    if (isMobile && mobileOverlay) {
      dispatch(
        displayMobileComponentModal({
          ...mobileOverlay,
          content,
          routeComponent: true,
        })
      );
    }
  }, [isMobile, mobileOverlay]);

  if (mobileOverlay && isMobile) {
    return null;
  }

  return (
    <Access
      to={to}
      memberOnly={memberOnly}
      ctmOnly={ctmOnly}
      renderAccessDenied={() => (
        <div data-e2e="route-access-denied" data-testid="route-access-denied">
          <ErrorBoundaryPage message="Oops! Looks like this link is not valid." />
        </div>
      )}
    >
      <Outlet />
    </Access>
  );
};

export default RouteAccess;
