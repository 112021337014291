import { PrintJsOptions } from ".";

const Modal = {
  show(params: PrintJsOptions) {
    // Create wrapper
    const printModal = document.createElement("div");
    printModal.setAttribute("id", "printJS-Modal");

    // Create content div
    const contentDiv = document.createElement("div");

    // add content wrapper
    const wrapper = document.createElement("div");
    wrapper.setAttribute("class", "printModalContent");

    const closeButton = document.createElement("div");
    closeButton.setAttribute("class", "printClose");
    closeButton.setAttribute("id", "printClose");
    wrapper.appendChild(closeButton);

    const spinner = document.createElement("div");
    spinner.setAttribute("class", "printSpinner");
    for (let index = 0; index < 4; index++) {
      spinner.appendChild(document.createElement("div"));
    }
    wrapper.appendChild(spinner);

    // Add message
    const messageNode = document.createTextNode(params.modalMessage!);
    wrapper.appendChild(messageNode);

    contentDiv.appendChild(wrapper);

    // Add contentDiv to printModal
    printModal.appendChild(contentDiv);

    // Append print modal element to document body
    document.getElementsByTagName("body")[0].appendChild(printModal);

    // Add event listener to close button
    document
      .getElementById("printClose")
      ?.addEventListener("click", function () {
        Modal.close(params.onPrintDialogClose);
      });
  },
  close(callback?: () => void) {
    const printModal = document.getElementById("printJS-Modal");

    if (printModal) {
      printModal.parentNode?.removeChild(printModal);
    }
    if (callback) {
      callback();
    }
  },
};

export default Modal;
