// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    ActionableItemDetails,
    ActionableItemDetailsFromJSON,
    ActionableItemDetailsToJSON,
} from '../models/ActionableItemDetails'
import {
    ActionableItemsDetailsOutput,
    ActionableItemsDetailsOutputFromJSON,
    ActionableItemsDetailsOutputToJSON,
} from '../models/ActionableItemsDetailsOutput'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpdateCTMAIOwnerSchema,
    UpdateCTMAIOwnerSchemaFromJSON,
    UpdateCTMAIOwnerSchemaToJSON,
} from '../models/UpdateCTMAIOwnerSchema'
import {
    UpdateEpisodeCTMAIOwnerResponse,
    UpdateEpisodeCTMAIOwnerResponseFromJSON,
    UpdateEpisodeCTMAIOwnerResponseToJSON,
} from '../models/UpdateEpisodeCTMAIOwnerResponse'
import {
    UpdateEpisodeCTMAIOwnerSchema,
    UpdateEpisodeCTMAIOwnerSchemaFromJSON,
    UpdateEpisodeCTMAIOwnerSchemaToJSON,
} from '../models/UpdateEpisodeCTMAIOwnerSchema'


export interface ActiveCareTeamActionableItemsRequest {
    id: string;
}

export interface ActiveMemberActionableItemsRequest {
    id: string;
}

export interface FetchCareTeamMemberActionableItemRequest {
    id: string;
}

export interface FetchMemberActionableItemRequest {
    id: string;
}

export interface UpdateCTMAIOwnerRequest {
    episodeId: string;
    updateEpisodeCTMAIOwnerSchema?: UpdateEpisodeCTMAIOwnerSchema;
}

export interface UpdateCareTeamMemberActionableItemOwnerRequest {
    ctmAiId: string;
    updateCTMAIOwnerSchema?: UpdateCTMAIOwnerSchema;
}


/**
 * Get actionable items redirect URL for the given member or care team member :id
 */
function actionableItemsRedirectUrlRaw<T>( requestConfig: runtime.TypedQueryConfig<T, ActionableItemsDetailsOutput> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/actionable_items`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ActionableItemsDetailsOutputFromJSON(body), text);
    }

    return config;
}

/**
* Get actionable items redirect URL for the given member or care team member :id
*/
export function actionableItemsRedirectUrl<T>( requestConfig?: runtime.TypedQueryConfig<T, ActionableItemsDetailsOutput>): QueryConfig<T> {
    return actionableItemsRedirectUrlRaw( requestConfig);
}

/**
 * Fetch active care team actionable items for :id with :status
 */
function activeCareTeamActionableItemsRaw<T>(requestParameters: ActiveCareTeamActionableItemsRequest, requestConfig: runtime.TypedQueryConfig<T, ActionableItemsDetailsOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling activeCareTeamActionableItems.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/care_team_members/{id}/actionable_items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ActionableItemsDetailsOutputFromJSON(body), text);
    }

    return config;
}

/**
* Fetch active care team actionable items for :id with :status
*/
export function activeCareTeamActionableItems<T>(requestParameters: ActiveCareTeamActionableItemsRequest, requestConfig?: runtime.TypedQueryConfig<T, ActionableItemsDetailsOutput>): QueryConfig<T> {
    return activeCareTeamActionableItemsRaw(requestParameters, requestConfig);
}

/**
 * Fetch member actionable items for :id with :status
 */
function activeMemberActionableItemsRaw<T>(requestParameters: ActiveMemberActionableItemsRequest, requestConfig: runtime.TypedQueryConfig<T, ActionableItemsDetailsOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling activeMemberActionableItems.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/actionable_items`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ActionableItemsDetailsOutputFromJSON(body), text);
    }

    return config;
}

/**
* Fetch member actionable items for :id with :status
*/
export function activeMemberActionableItems<T>(requestParameters: ActiveMemberActionableItemsRequest, requestConfig?: runtime.TypedQueryConfig<T, ActionableItemsDetailsOutput>): QueryConfig<T> {
    return activeMemberActionableItemsRaw(requestParameters, requestConfig);
}

/**
 * Get actionable item for the given :id
 */
function fetchCareTeamMemberActionableItemRaw<T>(requestParameters: FetchCareTeamMemberActionableItemRequest, requestConfig: runtime.TypedQueryConfig<T, ActionableItemDetails> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchCareTeamMemberActionableItem.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/care_team_actionable_items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ActionableItemDetailsFromJSON(body), text);
    }

    return config;
}

/**
* Get actionable item for the given :id
*/
export function fetchCareTeamMemberActionableItem<T>(requestParameters: FetchCareTeamMemberActionableItemRequest, requestConfig?: runtime.TypedQueryConfig<T, ActionableItemDetails>): QueryConfig<T> {
    return fetchCareTeamMemberActionableItemRaw(requestParameters, requestConfig);
}

/**
 * Get actionable item for the given :id
 */
function fetchMemberActionableItemRaw<T>(requestParameters: FetchMemberActionableItemRequest, requestConfig: runtime.TypedQueryConfig<T, ActionableItemDetails> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchMemberActionableItem.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/member_actionable_items/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ActionableItemDetailsFromJSON(body), text);
    }

    return config;
}

/**
* Get actionable item for the given :id
*/
export function fetchMemberActionableItem<T>(requestParameters: FetchMemberActionableItemRequest, requestConfig?: runtime.TypedQueryConfig<T, ActionableItemDetails>): QueryConfig<T> {
    return fetchMemberActionableItemRaw(requestParameters, requestConfig);
}

/**
 * Update owner of active active care team member actionable items of the given :episode_id
 */
function updateCTMAIOwnerRaw<T>(requestParameters: UpdateCTMAIOwnerRequest, requestConfig: runtime.TypedQueryConfig<T, UpdateEpisodeCTMAIOwnerResponse> = {}): QueryConfig<T> {
    if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
        throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling updateCTMAIOwner.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/episodes/{episode_id}/active_care_team_actionable_items/owner`.replace(`{${"episode_id"}}`, encodeURIComponent(String(requestParameters.episodeId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || UpdateEpisodeCTMAIOwnerSchemaToJSON(requestParameters.updateEpisodeCTMAIOwnerSchema),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(UpdateEpisodeCTMAIOwnerResponseFromJSON(body), text);
    }

    return config;
}

/**
* Update owner of active active care team member actionable items of the given :episode_id
*/
export function updateCTMAIOwner<T>(requestParameters: UpdateCTMAIOwnerRequest, requestConfig?: runtime.TypedQueryConfig<T, UpdateEpisodeCTMAIOwnerResponse>): QueryConfig<T> {
    return updateCTMAIOwnerRaw(requestParameters, requestConfig);
}

/**
 * Update Care team member actionable items owner of the given :ctm_ai_id
 */
function updateCareTeamMemberActionableItemOwnerRaw<T>(requestParameters: UpdateCareTeamMemberActionableItemOwnerRequest, requestConfig: runtime.TypedQueryConfig<T, ActionableItemDetails> = {}): QueryConfig<T> {
    if (requestParameters.ctmAiId === null || requestParameters.ctmAiId === undefined) {
        throw new runtime.RequiredError('ctmAiId','Required parameter requestParameters.ctmAiId was null or undefined when calling updateCareTeamMemberActionableItemOwner.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.update"]];
    const config: QueryConfig<T> = {
        url: `/v1/care_team_actionable_items/{ctm_ai_id}/owner`.replace(`{${"ctm_ai_id"}}`, encodeURIComponent(String(requestParameters.ctmAiId))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || UpdateCTMAIOwnerSchemaToJSON(requestParameters.updateCTMAIOwnerSchema),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ActionableItemDetailsFromJSON(body), text);
    }

    return config;
}

/**
* Update Care team member actionable items owner of the given :ctm_ai_id
*/
export function updateCareTeamMemberActionableItemOwner<T>(requestParameters: UpdateCareTeamMemberActionableItemOwnerRequest, requestConfig?: runtime.TypedQueryConfig<T, ActionableItemDetails>): QueryConfig<T> {
    return updateCareTeamMemberActionableItemOwnerRaw(requestParameters, requestConfig);
}

