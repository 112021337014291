// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlotDateObject,
    SlotDateObjectFromJSON,
    SlotDateObjectToJSON,
} from './SlotDateObject';


/**
 * 
 * @export
 * @interface TimeSlotsFetchResponse
 */
export interface TimeSlotsFetchResponse  {
    /**
     * 
     * @type {string}
     * @memberof TimeSlotsFetchResponse
     */
    prevUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof TimeSlotsFetchResponse
     */
    nextUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof TimeSlotsFetchResponse
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSlotsFetchResponse
     */
    last?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSlotsFetchResponse
     */
    count?: number;
    /**
     * 
     * @type {Date}
     * @memberof TimeSlotsFetchResponse
     */
    nextDateWithSlots?: Date;
    /**
     * 
     * @type {Array<SlotDateObject>}
     * @memberof TimeSlotsFetchResponse
     */
    results: Array<SlotDateObject>;
}

export function TimeSlotsFetchResponseFromJSON(json: any): TimeSlotsFetchResponse {
    return {
        'prevUrl': !exists(json, 'prev_url') ? undefined : json['prev_url'],
        'nextUrl': !exists(json, 'next_url') ? undefined : json['next_url'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'nextDateWithSlots': !exists(json, 'next_date_with_slots') ? undefined : new Date(json['next_date_with_slots']),
        'results': (json['results'] as Array<any>).map(SlotDateObjectFromJSON),
    };
}

export function TimeSlotsFetchResponseToJSON(value?: TimeSlotsFetchResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'prev_url': value.prevUrl,
        'next_url': value.nextUrl,
        'page': value.page,
        'last': value.last,
        'count': value.count,
        'next_date_with_slots': value.nextDateWithSlots === undefined ? undefined : value.nextDateWithSlots.toISOString(),
        'results': (value.results as Array<any>).map(SlotDateObjectToJSON),
    };
}


