// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    AccountActivationRequestBody,
    AccountActivationRequestBodyFromJSON,
    AccountActivationRequestBodyToJSON,
} from '../models/AccountActivationRequestBody'
import {
    AccountActivationResponse,
    AccountActivationResponseFromJSON,
    AccountActivationResponseToJSON,
} from '../models/AccountActivationResponse'
import {
    AccountActivationResponse2,
    AccountActivationResponse2FromJSON,
    AccountActivationResponse2ToJSON,
} from '../models/AccountActivationResponse2'
import {
    AuthorizeUserCookieRequestBody,
    AuthorizeUserCookieRequestBodyFromJSON,
    AuthorizeUserCookieRequestBodyToJSON,
} from '../models/AuthorizeUserCookieRequestBody'
import {
    AvatarDeletionSuccessResponse,
    AvatarDeletionSuccessResponseFromJSON,
    AvatarDeletionSuccessResponseToJSON,
} from '../models/AvatarDeletionSuccessResponse'
import {
    AvatarUploadSuccessResponse,
    AvatarUploadSuccessResponseFromJSON,
    AvatarUploadSuccessResponseToJSON,
} from '../models/AvatarUploadSuccessResponse'
import {
    EmailVerificationSuccessResponse,
    EmailVerificationSuccessResponseFromJSON,
    EmailVerificationSuccessResponseToJSON,
} from '../models/EmailVerificationSuccessResponse'
import {
    FetchLoginStatsSuccessResponse,
    FetchLoginStatsSuccessResponseFromJSON,
    FetchLoginStatsSuccessResponseToJSON,
} from '../models/FetchLoginStatsSuccessResponse'
import {
    FetchPatientQuickListSuccessResponse,
    FetchPatientQuickListSuccessResponseFromJSON,
    FetchPatientQuickListSuccessResponseToJSON,
} from '../models/FetchPatientQuickListSuccessResponse'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    JsonUser,
    JsonUserFromJSON,
    JsonUserToJSON,
} from '../models/JsonUser'
import {
    MinorAccountActivationRequestBody,
    MinorAccountActivationRequestBodyFromJSON,
    MinorAccountActivationRequestBodyToJSON,
} from '../models/MinorAccountActivationRequestBody'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    PasswordResetSuccessResponse,
    PasswordResetSuccessResponseFromJSON,
    PasswordResetSuccessResponseToJSON,
} from '../models/PasswordResetSuccessResponse'
import {
    PasswordUpdateSuccessResponse,
    PasswordUpdateSuccessResponseFromJSON,
    PasswordUpdateSuccessResponseToJSON,
} from '../models/PasswordUpdateSuccessResponse'
import {
    ResetUserPasswordRequestBody,
    ResetUserPasswordRequestBodyFromJSON,
    ResetUserPasswordRequestBodyToJSON,
} from '../models/ResetUserPasswordRequestBody'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UnauthorizedSessionResponse,
    UnauthorizedSessionResponseFromJSON,
    UnauthorizedSessionResponseToJSON,
} from '../models/UnauthorizedSessionResponse'
import {
    UpdateUserPasswordRequestBody,
    UpdateUserPasswordRequestBodyFromJSON,
    UpdateUserPasswordRequestBodyToJSON,
} from '../models/UpdateUserPasswordRequestBody'
import {
    UpdateUserRequestBody,
    UpdateUserRequestBodyFromJSON,
    UpdateUserRequestBodyToJSON,
} from '../models/UpdateUserRequestBody'
import {
    UserLookupRequestBody,
    UserLookupRequestBodyFromJSON,
    UserLookupRequestBodyToJSON,
} from '../models/UserLookupRequestBody'
import {
    VerifyUserByEmailRequestBody,
    VerifyUserByEmailRequestBodyFromJSON,
    VerifyUserByEmailRequestBodyToJSON,
} from '../models/VerifyUserByEmailRequestBody'


export interface AccountActivationRequest {
    accountActivationRequestBody?: AccountActivationRequestBody;
}

export interface AuthorizeUserCookieRequest {
    id: number;
    authorizeUserCookieRequestBody?: AuthorizeUserCookieRequestBody;
}

export interface DeleteUserAvatarRequest {
    id: number;
}

export interface FetchPatientQuickListForStaffUserRequest {
    id: number;
}

export interface FetchUserRequest {
    id: number;
}

export interface FetchUserLoginstatsRequest {
    id: number;
}

export interface ResetuserPasswordRequest {
    resetUserPasswordRequestBody?: ResetUserPasswordRequestBody;
}

export interface SendResetUserPasswordEmailRequest {
    id: number;
}

export interface UpdateUserRequest {
    id: number;
    updateUserRequestBody?: UpdateUserRequestBody;
}

export interface UpdateUserPasswordRequest {
    updateUserPasswordRequestBody?: UpdateUserPasswordRequestBody;
}

export interface UploadAvatarRequest {
    id: number;
    file: Blob;
}

export interface UserLookupRequest {
    userLookupRequestBody?: UserLookupRequestBody;
}

export interface VerifyUserByEmailRequest {
    verifyUserByEmailRequestBody?: VerifyUserByEmailRequestBody;
}

export interface XOPlatformAccountActivationRequest {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    dob: string;
    ssnLast4: string;
    employeeId: string;
    workEmail: string;
    file: Blob;
    efId: string;
    cellPhone?: string;
    cellTexts?: boolean;
    preferredLanguage?: string;
    stateCode?: string;
    subscriberId?: string;
    consents?: Array<string>;
    extraRegistrationId?: string;
    consentId?: number;
}

export interface XOPlatformAccountActivationMinorRequest {
    minorAccountActivationRequestBody?: MinorAccountActivationRequestBody;
}


/**
 * Activate a new user account
 */
function accountActivationRaw<T>(requestParameters: AccountActivationRequest, requestConfig: runtime.TypedQueryConfig<T, AccountActivationResponse> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `/v1/user`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || AccountActivationRequestBodyToJSON(requestParameters.accountActivationRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AccountActivationResponseFromJSON(body), text);
    }

    return config;
}

/**
* Activate a new user account
*/
export function accountActivation<T>(requestParameters: AccountActivationRequest, requestConfig?: runtime.TypedQueryConfig<T, AccountActivationResponse>): QueryConfig<T> {
    return accountActivationRaw(requestParameters, requestConfig);
}

/**
 * Authorize user cookie acknowledgement
 */
function authorizeUserCookieRaw<T>(requestParameters: AuthorizeUserCookieRequest, requestConfig: runtime.TypedQueryConfig<T, JsonUser> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling authorizeUserCookie.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PATCH',
            headers: headerParameters,
        },
        body: queryParameters || AuthorizeUserCookieRequestBodyToJSON(requestParameters.authorizeUserCookieRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(JsonUserFromJSON(body), text);
    }

    return config;
}

/**
* Authorize user cookie acknowledgement
*/
export function authorizeUserCookie<T>(requestParameters: AuthorizeUserCookieRequest, requestConfig?: runtime.TypedQueryConfig<T, JsonUser>): QueryConfig<T> {
    return authorizeUserCookieRaw(requestParameters, requestConfig);
}

/**
 * Delete a user avatar
 */
function deleteUserAvatarRaw<T>(requestParameters: DeleteUserAvatarRequest, requestConfig: runtime.TypedQueryConfig<T, AvatarDeletionSuccessResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUserAvatar.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/users/{id}/avatar`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'DELETE',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AvatarDeletionSuccessResponseFromJSON(body), text);
    }

    return config;
}

/**
* Delete a user avatar
*/
export function deleteUserAvatar<T>(requestParameters: DeleteUserAvatarRequest, requestConfig?: runtime.TypedQueryConfig<T, AvatarDeletionSuccessResponse>): QueryConfig<T> {
    return deleteUserAvatarRaw(requestParameters, requestConfig);
}

/**
 * Fetch a user\'s patient search quick list
 */
function fetchPatientQuickListForStaffUserRaw<T>(requestParameters: FetchPatientQuickListForStaffUserRequest, requestConfig: runtime.TypedQueryConfig<T, FetchPatientQuickListSuccessResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchPatientQuickListForStaffUser.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/users/{id}/patient-quick-list`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(FetchPatientQuickListSuccessResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch a user\'s patient search quick list
*/
export function fetchPatientQuickListForStaffUser<T>(requestParameters: FetchPatientQuickListForStaffUserRequest, requestConfig?: runtime.TypedQueryConfig<T, FetchPatientQuickListSuccessResponse>): QueryConfig<T> {
    return fetchPatientQuickListForStaffUserRaw(requestParameters, requestConfig);
}

/**
 * Fetch user by ID
 */
function fetchUserRaw<T>(requestParameters: FetchUserRequest, requestConfig: runtime.TypedQueryConfig<T, JsonUser> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchUser.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(JsonUserFromJSON(body), text);
    }

    return config;
}

/**
* Fetch user by ID
*/
export function fetchUser<T>(requestParameters: FetchUserRequest, requestConfig?: runtime.TypedQueryConfig<T, JsonUser>): QueryConfig<T> {
    return fetchUserRaw(requestParameters, requestConfig);
}

/**
 * Fetch user from token
 */
function fetchUserFromTokenRaw<T>( requestConfig: runtime.TypedQueryConfig<T, JsonUser> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/users/me`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(JsonUserFromJSON(body), text);
    }

    return config;
}

/**
* Fetch user from token
*/
export function fetchUserFromToken<T>( requestConfig?: runtime.TypedQueryConfig<T, JsonUser>): QueryConfig<T> {
    return fetchUserFromTokenRaw( requestConfig);
}

/**
 * Fetch a user\'s login stats
 */
function fetchUserLoginstatsRaw<T>(requestParameters: FetchUserLoginstatsRequest, requestConfig: runtime.TypedQueryConfig<T, FetchLoginStatsSuccessResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchUserLoginstats.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/users/{id}/login_stats`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(FetchLoginStatsSuccessResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch a user\'s login stats
*/
export function fetchUserLoginstats<T>(requestParameters: FetchUserLoginstatsRequest, requestConfig?: runtime.TypedQueryConfig<T, FetchLoginStatsSuccessResponse>): QueryConfig<T> {
    return fetchUserLoginstatsRaw(requestParameters, requestConfig);
}

/**
 * Fetch user from token
 */
function patchUserRaw<T>( requestConfig: runtime.TypedQueryConfig<T, JsonUser> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/users/me/confirm_login`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PATCH',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(JsonUserFromJSON(body), text);
    }

    return config;
}

/**
* Fetch user from token
*/
export function patchUser<T>( requestConfig?: runtime.TypedQueryConfig<T, JsonUser>): QueryConfig<T> {
    return patchUserRaw( requestConfig);
}

/**
 * Reset user password
 */
function resetuserPasswordRaw<T>(requestParameters: ResetuserPasswordRequest, requestConfig: runtime.TypedQueryConfig<T, PasswordResetSuccessResponse> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/user/password`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || ResetUserPasswordRequestBodyToJSON(requestParameters.resetUserPasswordRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(PasswordResetSuccessResponseFromJSON(body), text);
    }

    return config;
}

/**
* Reset user password
*/
export function resetuserPassword<T>(requestParameters: ResetuserPasswordRequest, requestConfig?: runtime.TypedQueryConfig<T, PasswordResetSuccessResponse>): QueryConfig<T> {
    return resetuserPasswordRaw(requestParameters, requestConfig);
}

/**
 * Send Reset password email
 */
function sendResetUserPasswordEmailRaw<T>(requestParameters: SendResetUserPasswordEmailRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling sendResetUserPasswordEmail.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/user/{id}/send-reset-password-email`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Send Reset password email
*/
export function sendResetUserPasswordEmail<T>(requestParameters: SendResetUserPasswordEmailRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return sendResetUserPasswordEmailRaw(requestParameters, requestConfig);
}

/**
 * Update user PCP, email, insurance, or clinic
 */
function updateUserRaw<T>(requestParameters: UpdateUserRequest, requestConfig: runtime.TypedQueryConfig<T, JsonUser> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateUser.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'PUT',
            headers: headerParameters,
        },
        body: queryParameters || UpdateUserRequestBodyToJSON(requestParameters.updateUserRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(JsonUserFromJSON(body), text);
    }

    return config;
}

/**
* Update user PCP, email, insurance, or clinic
*/
export function updateUser<T>(requestParameters: UpdateUserRequest, requestConfig?: runtime.TypedQueryConfig<T, JsonUser>): QueryConfig<T> {
    return updateUserRaw(requestParameters, requestConfig);
}

/**
 * Update user password
 */
function updateUserPasswordRaw<T>(requestParameters: UpdateUserPasswordRequest, requestConfig: runtime.TypedQueryConfig<T, PasswordUpdateSuccessResponse> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `/v1/user/update_password`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || UpdateUserPasswordRequestBodyToJSON(requestParameters.updateUserPasswordRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(PasswordUpdateSuccessResponseFromJSON(body), text);
    }

    return config;
}

/**
* Update user password
*/
export function updateUserPassword<T>(requestParameters: UpdateUserPasswordRequest, requestConfig?: runtime.TypedQueryConfig<T, PasswordUpdateSuccessResponse>): QueryConfig<T> {
    return updateUserPasswordRaw(requestParameters, requestConfig);
}

/**
 * Upload user avatar
 */
function uploadAvatarRaw<T>(requestParameters: UploadAvatarRequest, requestConfig: runtime.TypedQueryConfig<T, AvatarUploadSuccessResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling uploadAvatar.');
    }

    if (requestParameters.file === null || requestParameters.file === undefined) {
        throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling uploadAvatar.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const formData = new FormData();
    if (requestParameters.file !== undefined) {
        formData.append('file', requestParameters.file as any);
    }

    const config: QueryConfig<T> = {
        url: `/v1/users/{id}/avatar`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: formData,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AvatarUploadSuccessResponseFromJSON(body), text);
    }

    return config;
}

/**
* Upload user avatar
*/
export function uploadAvatar<T>(requestParameters: UploadAvatarRequest, requestConfig?: runtime.TypedQueryConfig<T, AvatarUploadSuccessResponse>): QueryConfig<T> {
    return uploadAvatarRaw(requestParameters, requestConfig);
}

/**
 * Check user email availability
 */
function userLookupRaw<T>(requestParameters: UserLookupRequest, requestConfig: runtime.TypedQueryConfig<T, void> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `/v1/user/lookup`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || UserLookupRequestBodyToJSON(requestParameters.userLookupRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
    }

    return config;
}

/**
* Check user email availability
*/
export function userLookup<T>(requestParameters: UserLookupRequest, requestConfig?: runtime.TypedQueryConfig<T, void>): QueryConfig<T> {
    return userLookupRaw(requestParameters, requestConfig);
}

/**
 * Verify user by email
 */
function verifyUserByEmailRaw<T>(requestParameters: VerifyUserByEmailRequest, requestConfig: runtime.TypedQueryConfig<T, EmailVerificationSuccessResponse> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `/v1/user/email`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || VerifyUserByEmailRequestBodyToJSON(requestParameters.verifyUserByEmailRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EmailVerificationSuccessResponseFromJSON(body), text);
    }

    return config;
}

/**
* Verify user by email
*/
export function verifyUserByEmail<T>(requestParameters: VerifyUserByEmailRequest, requestConfig?: runtime.TypedQueryConfig<T, EmailVerificationSuccessResponse>): QueryConfig<T> {
    return verifyUserByEmailRaw(requestParameters, requestConfig);
}

/**
 * XO Platform Activate a new user account
 */
function xOPlatformAccountActivationRaw<T>(requestParameters: XOPlatformAccountActivationRequest, requestConfig: runtime.TypedQueryConfig<T, AccountActivationResponse> = {}): QueryConfig<T> {
    if (requestParameters.email === null || requestParameters.email === undefined) {
        throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling xOPlatformAccountActivation.');
    }

    if (requestParameters.password === null || requestParameters.password === undefined) {
        throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling xOPlatformAccountActivation.');
    }

    if (requestParameters.firstName === null || requestParameters.firstName === undefined) {
        throw new runtime.RequiredError('firstName','Required parameter requestParameters.firstName was null or undefined when calling xOPlatformAccountActivation.');
    }

    if (requestParameters.lastName === null || requestParameters.lastName === undefined) {
        throw new runtime.RequiredError('lastName','Required parameter requestParameters.lastName was null or undefined when calling xOPlatformAccountActivation.');
    }

    if (requestParameters.dob === null || requestParameters.dob === undefined) {
        throw new runtime.RequiredError('dob','Required parameter requestParameters.dob was null or undefined when calling xOPlatformAccountActivation.');
    }

    if (requestParameters.ssnLast4 === null || requestParameters.ssnLast4 === undefined) {
        throw new runtime.RequiredError('ssnLast4','Required parameter requestParameters.ssnLast4 was null or undefined when calling xOPlatformAccountActivation.');
    }

    if (requestParameters.employeeId === null || requestParameters.employeeId === undefined) {
        throw new runtime.RequiredError('employeeId','Required parameter requestParameters.employeeId was null or undefined when calling xOPlatformAccountActivation.');
    }

    if (requestParameters.workEmail === null || requestParameters.workEmail === undefined) {
        throw new runtime.RequiredError('workEmail','Required parameter requestParameters.workEmail was null or undefined when calling xOPlatformAccountActivation.');
    }

    if (requestParameters.file === null || requestParameters.file === undefined) {
        throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling xOPlatformAccountActivation.');
    }

    if (requestParameters.efId === null || requestParameters.efId === undefined) {
        throw new runtime.RequiredError('efId','Required parameter requestParameters.efId was null or undefined when calling xOPlatformAccountActivation.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    const formData = new FormData();
    if (requestParameters.email !== undefined) {
        formData.append('email', requestParameters.email as any);
    }

    if (requestParameters.password !== undefined) {
        formData.append('password', requestParameters.password as any);
    }

    if (requestParameters.firstName !== undefined) {
        formData.append('first_name', requestParameters.firstName as any);
    }

    if (requestParameters.lastName !== undefined) {
        formData.append('last_name', requestParameters.lastName as any);
    }

    if (requestParameters.dob !== undefined) {
        formData.append('dob', requestParameters.dob as any);
    }

    if (requestParameters.ssnLast4 !== undefined) {
        formData.append('ssn_last4', requestParameters.ssnLast4 as any);
    }

    if (requestParameters.employeeId !== undefined) {
        formData.append('employee_id', requestParameters.employeeId as any);
    }

    if (requestParameters.workEmail !== undefined) {
        formData.append('work_email', requestParameters.workEmail as any);
    }

    if (requestParameters.cellPhone !== undefined) {
        formData.append('cell_phone', requestParameters.cellPhone as any);
    }

    if (requestParameters.cellTexts !== undefined) {
        formData.append('cell_texts', requestParameters.cellTexts as any);
    }

    if (requestParameters.preferredLanguage !== undefined) {
        formData.append('preferred_language', requestParameters.preferredLanguage as any);
    }

    if (requestParameters.stateCode !== undefined) {
        formData.append('state_code', requestParameters.stateCode as any);
    }

    if (requestParameters.subscriberId !== undefined) {
        formData.append('subscriber_id', requestParameters.subscriberId as any);
    }

    if (requestParameters.consents) {
        formData.append('consents', requestParameters.consents.join(runtime.COLLECTION_FORMATS["csv"]));
    }

    if (requestParameters.file !== undefined) {
        formData.append('file', requestParameters.file as any);
    }

    if (requestParameters.extraRegistrationId !== undefined) {
        formData.append('extra_registration_id', requestParameters.extraRegistrationId as any);
    }

    if (requestParameters.efId !== undefined) {
        formData.append('ef_id', requestParameters.efId as any);
    }

    if (requestParameters.consentId !== undefined) {
        formData.append('consent_id', requestParameters.consentId as any);
    }

    const config: QueryConfig<T> = {
        url: `/v1/sign_up`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: formData,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AccountActivationResponseFromJSON(body), text);
    }

    return config;
}

/**
* XO Platform Activate a new user account
*/
export function xOPlatformAccountActivation<T>(requestParameters: XOPlatformAccountActivationRequest, requestConfig?: runtime.TypedQueryConfig<T, AccountActivationResponse>): QueryConfig<T> {
    return xOPlatformAccountActivationRaw(requestParameters, requestConfig);
}

/**
 * XO Platform Activate a new minor user account
 */
function xOPlatformAccountActivationMinorRaw<T>(requestParameters: XOPlatformAccountActivationMinorRequest, requestConfig: runtime.TypedQueryConfig<T, AccountActivationResponse2> = {}): QueryConfig<T> {
    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    const config: QueryConfig<T> = {
        url: `/v1/sign_up_dependent`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || MinorAccountActivationRequestBodyToJSON(requestParameters.minorAccountActivationRequestBody),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AccountActivationResponse2FromJSON(body), text);
    }

    return config;
}

/**
* XO Platform Activate a new minor user account
*/
export function xOPlatformAccountActivationMinor<T>(requestParameters: XOPlatformAccountActivationMinorRequest, requestConfig?: runtime.TypedQueryConfig<T, AccountActivationResponse2>): QueryConfig<T> {
    return xOPlatformAccountActivationMinorRaw(requestParameters, requestConfig);
}

