// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CommonQuestionRequestBody
 */
export interface CommonQuestionRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof CommonQuestionRequestBody
     */
    displayText: string;
    /**
     * 
     * @type {string}
     * @memberof CommonQuestionRequestBody
     */
    sex: string;
}

export function CommonQuestionRequestBodyFromJSON(json: any): CommonQuestionRequestBody {
    return {
        'displayText': json['display_text'],
        'sex': json['sex'],
    };
}

export function CommonQuestionRequestBodyToJSON(value?: CommonQuestionRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'display_text': value.displayText,
        'sex': value.sex,
    };
}


