export const SET_SNACKBAR_SUCCESS = "SET_SNACKBAR_SUCCESS";
export const SET_SNACKBAR_CLEAR = "SET_SNACKBAR_CLEAR";

export const snackbar = (
  state: State = getInitialSnackbarState(),
  action: any
): State => {
  if (action && action.type) {
    const { type, message, appearance, width } = action;
    switch (type) {
      case SET_SNACKBAR_SUCCESS:
        return Object.assign({}, state, {
          open: true,
          message,
          appearance,
          width,
        });

      case SET_SNACKBAR_CLEAR:
        return Object.assign({}, state, {
          open: false,
        });

      default:
        return state;
    }
  }
  return state;
};

type widthType = "auto" | number | undefined;

export const showSnackbar = (
  message: string,
  appearance: "danger" | "success" = "success",
  width: widthType = undefined
) => {
  return { type: SET_SNACKBAR_SUCCESS, message, appearance, width };
};

export const clearSnackbar = () => {
  return { type: SET_SNACKBAR_CLEAR };
};

export interface State {
  message: any;
  open: boolean;
  appearance: "danger" | "success";
  width: widthType;
}

export const getInitialSnackbarState = (): State => {
  return {
    message: null,
    open: false,
    appearance: "success",
    width: undefined,
  };
};
