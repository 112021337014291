import React from "react";
import AlertDialog from "components/Dialog/AlertDialog";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Button from "components/Button";

interface IProps {
  isOpen: boolean;
  hideModal: () => void;
  googleCalendarUrl: string;
  outlookCalendarUrl: string;
  appleCalendarClickHandler?: () => void;
}

interface CalendarButtonsProps {
  containerStyles: string;
  buttonStyles: string;
  hideModal: () => void;
  googleCalendarUrl: string;
  outlookCalendarUrl: string;
  appleCalendarClickHandler?: () => void;
}

interface CalendarLinkProps {
  url: string;
  displayText: string;
  testId: string;
  hideModal: () => void;
}

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    modalContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    button: {
      width: "132px",
      color: palette.text.primary,
      border: `2px solid ${palette.yellow}`,
      margin: "10px 0",
    },
    iconContainer: {
      "& .MuiPaper-root": {
        "& :nth-child(2)": {
          paddingBottom: "10px !important",
        },
      },

      "& .MuiTypography-root": {
        textAlign: "center",
      },
    },
    btnLink: {
      width: "132px",
      color: palette.text.primary,
      border: `2px solid ${palette.yellow}`,
      textAlign: "center",
      borderRadius: "20px",
      boxSizing: "border-box",
      padding: "10px 0",
      fontSize: "0.9375rem",

      "&:hover": {
        color: palette.text.primary,
        border: `2px solid ${palette.yellow}`,
        backgroundColor: palette.primary.main,
      },
    },
  })
);

const CalendarButton = ({
  url,
  displayText,
  testId,
  hideModal,
}: CalendarLinkProps) => {
  const classes = useStyles();
  return (
    <a
      target="_blank"
      href={url}
      rel="noopener noreferrer"
      className={classes.btnLink}
      data-e2e={testId}
      onClick={hideModal}
    >
      {displayText}
    </a>
  );
};

const CalendarOptionsButtons = ({
  containerStyles,
  buttonStyles,
  hideModal,
  googleCalendarUrl,
  outlookCalendarUrl,
  appleCalendarClickHandler,
}: CalendarButtonsProps) => {
  return (
    <div className={containerStyles}>
      <CalendarButton
        displayText=" Google"
        url={googleCalendarUrl}
        testId="google-add-to-calendar-btn"
        hideModal={hideModal}
      />
      <Button
        className={buttonStyles}
        variant="outlined"
        color="primary"
        data-e2e="apple-add-to-calendar-btn"
        size="large"
        onClick={appleCalendarClickHandler}
      >
        Apple
      </Button>
      <CalendarButton
        displayText=" Outlook"
        url={outlookCalendarUrl}
        testId="outlook-add-to-calendar-btn"
        hideModal={hideModal}
      />
    </div>
  );
};

const AddToCalendarModal = ({
  isOpen,
  hideModal,
  googleCalendarUrl,
  outlookCalendarUrl,
  appleCalendarClickHandler,
}: IProps) => {
  const classes = useStyles();
  return (
    <AlertDialog
      className={classes.iconContainer}
      appearance="other"
      title="Choose your calendar"
      data-testid="add-to-calendar-modal"
      open={isOpen}
      onClose={hideModal}
      maxWidth="xs"
      content={
        <CalendarOptionsButtons
          containerStyles={classes.modalContainer}
          buttonStyles={classes.button}
          hideModal={hideModal}
          googleCalendarUrl={googleCalendarUrl}
          outlookCalendarUrl={outlookCalendarUrl}
          appleCalendarClickHandler={appleCalendarClickHandler}
        />
      }
    />
  );
};

export default AddToCalendarModal;
