// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkspaceDraftDataSchemaBase
 */
export interface WorkspaceDraftDataSchemaBase  {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDraftDataSchemaBase
     */
    type: WorkspaceDraftDataSchemaBaseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDraftDataSchemaBase
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDraftDataSchemaBase
     */
    status?: WorkspaceDraftDataSchemaBaseStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDraftDataSchemaBase
     */
    memberCurrentUsState?: string;
}

export function WorkspaceDraftDataSchemaBaseFromJSON(json: any): WorkspaceDraftDataSchemaBase {
    return {
        'type': json['type'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'memberCurrentUsState': !exists(json, 'member_current_us_state') ? undefined : json['member_current_us_state'],
    };
}

export function WorkspaceDraftDataSchemaBaseToJSON(value?: WorkspaceDraftDataSchemaBase): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'type': value.type,
        'body': value.body,
        'status': value.status,
        'member_current_us_state': value.memberCurrentUsState,
    };
}

/**
* @export
* @enum {string}
*/
export enum WorkspaceDraftDataSchemaBaseTypeEnum {
    CtmMessage = 'ctm_message',
    VirtualVisitReferral = 'virtual_visit_referral',
    VisitReferral = 'visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    QuestionSet = 'question_set',
    Calculator = 'calculator',
    Diagnosis = 'diagnosis',
    EpisodeRating = 'episode_rating'
}
/**
* @export
* @enum {string}
*/
export enum WorkspaceDraftDataSchemaBaseStatusEnum {
    Draft = 'draft',
    Compiled = 'compiled',
    Unstarted = 'unstarted'
}


