// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserInfo,
    UserInfoFromJSON,
    UserInfoToJSON,
} from './UserInfo';


/**
 * 
 * @export
 * @interface DeletedTimelineEventsResponseResults
 */
export interface DeletedTimelineEventsResponseResults  {
    /**
     * 
     * @type {string}
     * @memberof DeletedTimelineEventsResponseResults
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DeletedTimelineEventsResponseResults
     */
    episodeId: string;
    /**
     * 
     * @type {UserInfo}
     * @memberof DeletedTimelineEventsResponseResults
     */
    deleter: UserInfo;
    /**
     * 
     * @type {Date}
     * @memberof DeletedTimelineEventsResponseResults
     */
    addedAt: Date;
}

export function DeletedTimelineEventsResponseResultsFromJSON(json: any): DeletedTimelineEventsResponseResults {
    return {
        'id': json['id'],
        'episodeId': json['episode_id'],
        'deleter': UserInfoFromJSON(json['deleter']),
        'addedAt': new Date(json['added_at']),
    };
}

export function DeletedTimelineEventsResponseResultsToJSON(value?: DeletedTimelineEventsResponseResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'episode_id': value.episodeId,
        'deleter': UserInfoToJSON(value.deleter),
        'added_at': value.addedAt.toISOString(),
    };
}


