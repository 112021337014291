import { ComponentCoreTypeEnum } from "shared/fetch/src/models/ComponentCore";

export default {
  id: "13",
  resource_url: "/v1/components/3",
  type: ComponentCoreTypeEnum.VirtualVisitReferral,
  body: "I want to to cancel",
  state: "Declined",
  addedAt: "2021-06-16T18:45:20Z",
  documentInfo: [],
  creatorInfo: {
    id: "2",
    dob: "1981-01-01",
    type: "member",
    avatar_url: "/images/image-placeholder.jpg",
    name: "Finding Nemo",
    legal_name: "Patient Public",
    employer: "Demo",
  },
  appointment: {
    visitType: "Online Video Visit",
    provider: {
      id: 22,
      dob: "1994-01-11",
      name: "Joseph Kanyanga, MD",
      legal_name: "Joseph Kanyanga",
      type: "ctm",
      avatarUrl: "/v1/users/d0vw16fg/avatar/demopublic.png",
      guilds: ["Primary Care"],
    },
    duration: 30,
    disposition: "complete",
    startAt: "2020-06-08T21:50:38Z",
    endAt: "2020-06-08T21:50:38Z",
    visit_link: "http://www.crossover-video-visit.com/1123423",
  },
  declineReason: "I don't wanna talk about it",
};
