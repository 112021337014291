// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CTMBioProfileSection
 */
export interface CTMBioProfileSection  {
    /**
     * 
     * @type {string}
     * @memberof CTMBioProfileSection
     */
    label: string;
    /**
     * Markdown content for this section
     * @type {string}
     * @memberof CTMBioProfileSection
     */
    content: string;
}

export function CTMBioProfileSectionFromJSON(json: any): CTMBioProfileSection {
    return {
        'label': json['label'],
        'content': json['content'],
    };
}

export function CTMBioProfileSectionToJSON(value?: CTMBioProfileSection): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'label': value.label,
        'content': value.content,
    };
}


