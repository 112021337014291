import React from "react";
import { useLocation } from "react-router";
import { useDispatch } from "react-redux";
import { setTimelineDestinationUrl } from "shared/state/ui/member";
import { NavRoute } from "./navigationBarRoutes";
import DraftReplySafeLink from "components/Button/DraftReplySafeLink";
import Tabs from "components/Tabs";
import Tab from "components/Tabs/Tab";

interface INavigationTabsProps {
  userNav: any;
  centered?: boolean;
}

const NavigationTabs = ({ userNav, centered }: INavigationTabsProps) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const tabRoutes = userNav.map((route: NavRoute) => route.pathname);
  let pathname = location.pathname;

  if (location.pathname.includes("/tools")) {
    pathname = "/tools";
  } else if (location.pathname.includes("/health-records")) {
    pathname = "/health-records";
  }

  const tabValue = tabRoutes.includes(pathname) ? pathname : false;

  const onClickHandler = (toUrl: string) => {
    dispatch(setTimelineDestinationUrl(toUrl));
  };

  return (
    <>
      <Tabs
        value={tabValue}
        indicatorColor="primary"
        textColor="primary"
        aria-label="Navigation Tabs"
        justifyContent="flex-start"
        centered={centered}
      >
        {userNav.map((route: NavRoute, index: number) => {
          return (
            <Tab
              tabIndex={0}
              key={index}
              component={DraftReplySafeLink}
              data-e2e={route.e2e}
              to={route.pathname}
              label={route.name}
              value={route.pathname}
              onClick={(e: any) => {
                e.preventDefault();
                onClickHandler(route.pathname);
              }}
            />
          );
        })}
      </Tabs>
    </>
  );
};

export default NavigationTabs;
