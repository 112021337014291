// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberInsuranceInput
 */
export interface MemberInsuranceInput  {
    /**
     * 
     * @type {string}
     * @memberof MemberInsuranceInput
     */
    insurancePlan?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberInsuranceInput
     */
    insuranceHolder?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberInsuranceInput
     */
    insuranceId?: string;
}

export function MemberInsuranceInputFromJSON(json: any): MemberInsuranceInput {
    return {
        'insurancePlan': !exists(json, 'insurance_plan') ? undefined : json['insurance_plan'],
        'insuranceHolder': !exists(json, 'insurance_holder') ? undefined : json['insurance_holder'],
        'insuranceId': !exists(json, 'insurance_id') ? undefined : json['insurance_id'],
    };
}

export function MemberInsuranceInputToJSON(value?: MemberInsuranceInput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'insurance_plan': value.insurancePlan,
        'insurance_holder': value.insuranceHolder,
        'insurance_id': value.insuranceId,
    };
}


