import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { ReferralComponentCoreStateEnum } from "shared/api/src/models/ReferralComponentCore";
import { selectLoggedInUser } from "shared/features/user/selectors";
import { VideoConferenceOutput } from "shared/fetch/src/models/VideoConferenceOutput";
import { MemberClinic } from "shared/fetch/src/models/MemberClinic";
import Typography from "components/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { Box } from "@mui/material";
import IconWithTooltip from "components/VisitHistory/Visit/IconWithTooltip";
import AddToCalendar from "components/VisitHistory/Visit/AddToCalendar";

interface iCalendarOptions {
  title?: string;
  reason?: string;
  videoConference?: VideoConferenceOutput;
  startDate?: Date;
  endDate?: Date;
  location?: string;
  clinic?: MemberClinic;
  method?: string;
}

interface IProps {
  state: ReferralComponentCoreStateEnum;
  isPastVisit?: boolean;
  calendarEventInfo?: iCalendarOptions;
  accessingUserType?: "ctm" | "member";
  visitDisposition?: string;
  hasMissedAppointmentPolicy: boolean;
}

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    textBox: {
      alignItems: "center",
      display: "flex",
      color: palette.text.secondary,
    },
    textIcon: {
      width: "13px",
      height: "13px",
      marginLeft: "6px",
      verticalAlign: "baseline",
    },
  })
);

const Exclamation = () => {
  const classes = useStyles();
  return (
    <div data-testid="exclamation-icon">
      <ErrorIcon className={classes.textIcon} />
    </div>
  );
};
const Checkmark = () => {
  const classes = useStyles();
  return (
    <div data-testid="checkmark-icon">
      <CheckCircleIcon className={classes.textIcon} />
    </div>
  );
};

const memberActionTreeSwitch = (
  state: ReferralComponentCoreStateEnum
): { content: string | React.Component; Icon?: any } => {
  switch (state) {
    case ReferralComponentCoreStateEnum.Scheduled:
      return { content: "" };
    case ReferralComponentCoreStateEnum.MissedNoShow:
      return { content: "Missed", Icon: Exclamation };
    case ReferralComponentCoreStateEnum.ResolvedCompleted:
      return { content: "Completed", Icon: Checkmark };
    case ReferralComponentCoreStateEnum.CanceledByMember:
    case ReferralComponentCoreStateEnum.CanceledAckdPending:
    case ReferralComponentCoreStateEnum.ResolvedCanceledAckd:
      return { content: "Canceled by you" };
    case ReferralComponentCoreStateEnum.CanceledByCtm:
    case ReferralComponentCoreStateEnum.ResolvedCanceledViewed:
      return { content: "Canceled by my team" };
    case ReferralComponentCoreStateEnum.RescheduledPending:
      return { content: "Reschedule requested" };
    case ReferralComponentCoreStateEnum.CheckInOpened:
      return { content: "Check in is now open" };
    case ReferralComponentCoreStateEnum.CheckInInProgress:
      return { content: "Check in started" };
    case ReferralComponentCoreStateEnum.CheckInCompleted:
    case ReferralComponentCoreStateEnum.CheckInCompletedNotAcknowledged:
      return { content: "Check in finished" };
    case ReferralComponentCoreStateEnum.CheckInIncomplete:
      return { content: "Check in needed" };
    case ReferralComponentCoreStateEnum.CheckInClosed:
      return { content: "Check in is now closed" };
    case ReferralComponentCoreStateEnum.CheckInFastTrackedConsentNeeded:
    case ReferralComponentCoreStateEnum.CheckInFastTrackedOutstandingItemsWithoutConsent:
      return { content: "Check in needed" };
    case ReferralComponentCoreStateEnum.Declined:
      return { content: "Declined" };
    default: {
      return {
        content: "",
      };
    }
  }
};

const ctmActionTreeSwitch = (
  state: ReferralComponentCoreStateEnum
): { content: string | React.Component; Icon?: any } => {
  switch (state) {
    case ReferralComponentCoreStateEnum.Scheduled:
      return { content: "Scheduled" };
    case ReferralComponentCoreStateEnum.MissedNoShow:
      return { content: "Missed", Icon: Exclamation };
    case ReferralComponentCoreStateEnum.ResolvedCompleted:
      return { content: "Completed", Icon: Checkmark };
    case ReferralComponentCoreStateEnum.CanceledByMember:
    case ReferralComponentCoreStateEnum.CanceledAckdPending:
    case ReferralComponentCoreStateEnum.ResolvedCanceledAckd:
      return { content: "Canceled by Member" };
    case ReferralComponentCoreStateEnum.CanceledByCtm:
    case ReferralComponentCoreStateEnum.ResolvedCanceledViewed:
      return { content: "Canceled by CTM" };
    case ReferralComponentCoreStateEnum.RescheduledPending:
      return { content: "Reschedule requested" };
    case ReferralComponentCoreStateEnum.CheckInOpened:
      return { content: "Check in is now open" };
    case ReferralComponentCoreStateEnum.CheckInInProgress:
      return { content: "Check in started" };
    case ReferralComponentCoreStateEnum.CheckInCompleted:
    case ReferralComponentCoreStateEnum.CheckInCompletedNotAcknowledged:
      return { content: "Check in finished" };
    case ReferralComponentCoreStateEnum.CheckInIncomplete:
    case ReferralComponentCoreStateEnum.CheckInClosed:
      return { content: "Incomplete check in" };
    case ReferralComponentCoreStateEnum.CheckInFastTrackedConsentNeeded:
    case ReferralComponentCoreStateEnum.CheckInFastTrackedOutstandingItemsWithoutConsent:
      return { content: "Fast tracked check in" };
    case ReferralComponentCoreStateEnum.Declined:
      return { content: "Declined" };
    default: {
      return {
        content: "",
      };
    }
  }
};

const StateText = ({
  state,
  calendarEventInfo,
  accessingUserType,
  isPastVisit,
  visitDisposition,
  hasMissedAppointmentPolicy,
}: IProps) => {
  const classes = useStyles();

  if (
    !isPastVisit &&
    accessingUserType === "member" &&
    (state === ReferralComponentCoreStateEnum.Scheduled ||
      state === ReferralComponentCoreStateEnum.CheckInInProgress ||
      state === ReferralComponentCoreStateEnum.CheckInCompleted ||
      state ===
        ReferralComponentCoreStateEnum.CheckInCompletedNotAcknowledged ||
      state === ReferralComponentCoreStateEnum.CheckInIncomplete ||
      state ===
        ReferralComponentCoreStateEnum.CheckInFastTrackedConsentNeeded ||
      state ===
        ReferralComponentCoreStateEnum.CheckInFastTrackedOutstandingItemsWithoutConsent ||
      state === ReferralComponentCoreStateEnum.CheckInOpened)
  ) {
    return <AddToCalendar {...calendarEventInfo} />;
  }
  const { content, Icon }: any =
    accessingUserType === "ctm"
      ? ctmActionTreeSwitch(state)
      : memberActionTreeSwitch(state);

  const isLateCancelOrNoShow: boolean =
    hasMissedAppointmentPolicy &&
    Boolean(visitDisposition) &&
    (visitDisposition === "no_show" || visitDisposition === "late_cancel");

  const modifiedContentForMissedAppointmentPolicyFeature = () => {
    if (hasMissedAppointmentPolicy) {
      if (visitDisposition === "late_cancel") {
        return `Late ${content}`;
      } else if (
        visitDisposition === "no_show" &&
        accessingUserType === "ctm"
      ) {
        return "No Show";
      } else {
        return content;
      }
    }
    return content;
  };

  const showTooltip = isLateCancelOrNoShow;
  const showIconWithoutTooltip = !showTooltip && Icon;

  //placeholder content:
  const tooltipText =
    visitDisposition === "late_cancel"
      ? "Visit is considered to be a late cancelation if it is canceled/rescheduled within 24 hours of scheduled time."
      : `Visit is considered to be ${
          accessingUserType === "ctm" ? "no show" : "missed"
        } if Member fails to show up or cancels/reschedules their visit within 2 hours of scheduled time.`;

  return (
    <Box className={classes.textBox}>
      <Typography
        appearance="caption"
        fontWeightNormal
        data-testid="state-content"
      >
        <span>{modifiedContentForMissedAppointmentPolicyFeature()}</span>
      </Typography>
      <div>
        {showTooltip && (
          <IconWithTooltip useStyles={useStyles} tooltipText={tooltipText} />
        )}
        {showIconWithoutTooltip && <Icon />}
      </div>
    </Box>
  );
};

const VisitStates = ({
  state,
  calendarEventInfo,
  isPastVisit,
  visitDisposition,
  hasMissedAppointmentPolicy,
}: IProps) => {
  const user = selectLoggedInUser();
  const accessingUserType = user?.ctm ? "ctm" : "member";

  if (!state) {
    return null;
  }

  return (
    <div>
      <StateText
        state={state}
        isPastVisit={isPastVisit}
        accessingUserType={accessingUserType}
        calendarEventInfo={calendarEventInfo}
        visitDisposition={visitDisposition}
        hasMissedAppointmentPolicy={hasMissedAppointmentPolicy}
      />
    </div>
  );
};

export default VisitStates;
