/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    ListICD10CodeResponse,
    ListICD10CodeResponseFromJSON,
    ListICD10CodeResponseToJSON,
} from '../models/ListICD10CodeResponse'
import {
    ListICD10CodesResponse,
    ListICD10CodesResponseFromJSON,
    ListICD10CodesResponseToJSON,
} from '../models/ListICD10CodesResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface ListICD10CodesFullRequest {
    q: string;
}
export interface PatientListOfICD10CodesRequest {
    id: string;
}


export interface IIcd10CodesApi {
      listICD10Codes: () => Promise<ListICD10CodesResponse>
      listICD10CodesFull: (requestParameters: ListICD10CodesFullRequest) => Promise<ListICD10CodeResponse>
      patientListOfICD10Codes: (requestParameters: PatientListOfICD10CodesRequest) => Promise<ListICD10CodesResponse>
      listICD10CodesWithResponse: () => Promise<{ value: ListICD10CodesResponse, response: any}>
      listICD10CodesFullWithResponse: (requestParameters: ListICD10CodesFullRequest) => Promise<{ value: ListICD10CodeResponse, response: any}>
      patientListOfICD10CodesWithResponse: (requestParameters: PatientListOfICD10CodesRequest) => Promise<{ value: ListICD10CodesResponse, response: any}>
}

/**
 * no description
 */
export class Icd10CodesApi extends runtime.BaseAPI {

    /**
     * Fetch all ICD10 codes
     */
    listICD10CodesRaw = async (): Promise<{ runtime: runtime.ApiResponse<ListICD10CodesResponse>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/icd10_codes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ListICD10CodesResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch all ICD10 codes
     */
    listICD10Codes = async (): Promise<ListICD10CodesResponse> => {
        const { runtime } = await this.listICD10CodesRaw();
        return await runtime.value();
    }

    /**
     * Fetch all ICD10 codes
     */
    listICD10CodesWithResponse = async (): Promise<{ value: ListICD10CodesResponse, response: any}> => {
        const { runtime, response } = await this.listICD10CodesRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * List ICD10Codes (both quick search field and full search)
     * List ICD10Codes
     */
    listICD10CodesFullRaw = async (requestParameters: ListICD10CodesFullRequest): Promise<{ runtime: runtime.ApiResponse<ListICD10CodeResponse>, response: any}> => {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling listICD10CodesFull.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/icd10_codes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ListICD10CodeResponseFromJSON(jsonValue)), response };
    }

    /**
     * List ICD10Codes (both quick search field and full search)
     * List ICD10Codes
     */
    listICD10CodesFull = async (requestParameters: ListICD10CodesFullRequest): Promise<ListICD10CodeResponse> => {
        const { runtime } = await this.listICD10CodesFullRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * List ICD10Codes (both quick search field and full search)
     * List ICD10Codes
     */
    listICD10CodesFullWithResponse = async (requestParameters: ListICD10CodesFullRequest): Promise<{ value: ListICD10CodeResponse, response: any}> => {
        const { runtime, response } = await this.listICD10CodesFullRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch all ICD10 codes for patient
     */
    patientListOfICD10CodesRaw = async (requestParameters: PatientListOfICD10CodesRequest): Promise<{ runtime: runtime.ApiResponse<ListICD10CodesResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling patientListOfICD10Codes.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/icd10_codes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ListICD10CodesResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch all ICD10 codes for patient
     */
    patientListOfICD10Codes = async (requestParameters: PatientListOfICD10CodesRequest): Promise<ListICD10CodesResponse> => {
        const { runtime } = await this.patientListOfICD10CodesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch all ICD10 codes for patient
     */
    patientListOfICD10CodesWithResponse = async (requestParameters: PatientListOfICD10CodesRequest): Promise<{ value: ListICD10CodesResponse, response: any}> => {
        const { runtime, response } = await this.patientListOfICD10CodesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchIcd10CodesApi: IIcd10CodesApi  = new Icd10CodesApi();
