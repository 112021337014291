// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     WorkspaceDraftDataSchemaBase,
    WorkspaceDraftDataSchemaBaseFromJSON,
    WorkspaceDraftDataSchemaBaseToJSON,
} from './WorkspaceDraftDataSchemaBase';
import {
     WorkspaceDraftDataSchemaCTMMessageBase,
    WorkspaceDraftDataSchemaCTMMessageBaseFromJSON,
    WorkspaceDraftDataSchemaCTMMessageBaseToJSON,
} from './WorkspaceDraftDataSchemaCTMMessageBase';
/**
 * @type WorkspaceDraftDataSchemaCTMMessage
 * @export
 */
export interface WorkspaceDraftDataSchemaCTMMessage extends WorkspaceDraftDataSchemaBase, WorkspaceDraftDataSchemaCTMMessageBase {
}

export function WorkspaceDraftDataSchemaCTMMessageFromJSON(json: any): WorkspaceDraftDataSchemaCTMMessage {
    return {
        ...WorkspaceDraftDataSchemaBaseFromJSON(json),
        ...WorkspaceDraftDataSchemaCTMMessageBaseFromJSON(json),
    };
}

export function WorkspaceDraftDataSchemaCTMMessageToJSON(value?: WorkspaceDraftDataSchemaCTMMessage): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...WorkspaceDraftDataSchemaBaseToJSON(value),
        ...WorkspaceDraftDataSchemaCTMMessageBaseToJSON(value),
    };
}

