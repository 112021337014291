// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UserInfo,
    UserInfoFromJSON,
    UserInfoToJSON,
} from './UserInfo';


/**
 * 
 * @export
 * @interface TimelineEventOutputCore
 */
export interface TimelineEventOutputCore  {
    /**
     * 
     * @type {string}
     * @memberof TimelineEventOutputCore
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineEventOutputCore
     */
    episodeId: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineEventOutputCore
     */
    eventType: TimelineEventOutputCoreEventTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof TimelineEventOutputCore
     */
    addedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof TimelineEventOutputCore
     */
    viewedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof TimelineEventOutputCore
     */
    stateDisplayLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineEventOutputCore
     */
    older?: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineEventOutputCore
     */
    newer?: string;
    /**
     * 
     * @type {string}
     * @memberof TimelineEventOutputCore
     */
    state?: TimelineEventOutputCoreStateEnum;
    /**
     * 
     * @type {Date}
     * @memberof TimelineEventOutputCore
     */
    actionsResolvedAt?: Date;
    /**
     * 
     * @type {UserInfo}
     * @memberof TimelineEventOutputCore
     */
    createdBy: UserInfo;
    /**
     * 
     * @type {boolean}
     * @memberof TimelineEventOutputCore
     */
    timeSensitive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TimelineEventOutputCore
     */
    memberCurrentUsState?: string;
}

export function TimelineEventOutputCoreFromJSON(json: any): TimelineEventOutputCore {
    return {
        'id': json['id'],
        'episodeId': json['episode_id'],
        'eventType': json['event_type'],
        'addedAt': !exists(json, 'added_at') ? undefined : new Date(json['added_at']),
        'viewedAt': !exists(json, 'viewed_at') ? undefined : new Date(json['viewed_at']),
        'stateDisplayLabel': !exists(json, 'state_display_label') ? undefined : json['state_display_label'],
        'older': !exists(json, 'older') ? undefined : json['older'],
        'newer': !exists(json, 'newer') ? undefined : json['newer'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'actionsResolvedAt': !exists(json, 'actions_resolved_at') ? undefined : new Date(json['actions_resolved_at']),
        'createdBy': UserInfoFromJSON(json['created_by']),
        'timeSensitive': !exists(json, 'time_sensitive') ? undefined : json['time_sensitive'],
        'memberCurrentUsState': !exists(json, 'member_current_us_state') ? undefined : json['member_current_us_state'],
    };
}

export function TimelineEventOutputCoreToJSON(value?: TimelineEventOutputCore): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'episode_id': value.episodeId,
        'event_type': value.eventType,
        'added_at': value.addedAt === undefined ? undefined : value.addedAt.toISOString(),
        'viewed_at': value.viewedAt === undefined ? undefined : value.viewedAt.toISOString(),
        'state_display_label': value.stateDisplayLabel,
        'older': value.older,
        'newer': value.newer,
        'state': value.state,
        'actions_resolved_at': value.actionsResolvedAt === undefined ? undefined : value.actionsResolvedAt.toISOString(),
        'created_by': UserInfoToJSON(value.createdBy),
        'time_sensitive': value.timeSensitive,
        'member_current_us_state': value.memberCurrentUsState,
    };
}

/**
* @export
* @enum {string}
*/
export enum TimelineEventOutputCoreEventTypeEnum {
    MessageBundle = 'message_bundle',
    EpisodeCreated = 'episode_created',
    MemberMessage = 'member_message',
    CtmMessage = 'ctm_message',
    EpisodeArchived = 'episode_archived',
    CtmEpisodeCreated = 'ctm_episode_created',
    CtmMessagingEpisodeCreated = 'ctm_messaging_episode_created',
    QuestionSet = 'question_set',
    VirtualVisitReferral = 'virtual_visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    VisitReferral = 'visit_referral',
    Calculator = 'calculator',
    MarketingMessage = 'marketing_message',
    Billing = 'billing',
    SelfScheduleAppointment = 'self_schedule_appointment',
    MessagingEpisodeCreated = 'messaging_episode_created',
    Diagnosis = 'diagnosis',
    EpisodeRating = 'episode_rating'
}
/**
* @export
* @enum {string}
*/
export enum TimelineEventOutputCoreStateEnum {
    Unread = 'unread',
    UnresolvedCtm = 'unresolved_ctm',
    UnresolvedMember = 'unresolved_member',
    Resolved = 'resolved'
}


