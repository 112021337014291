import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const iconStyles = {
  height: "32px",
  width: "32px",
  display: "inline-block",
  fill: "currentColor",
};

const useIconStyles = makeStyles(() =>
  createStyles({
    icon: iconStyles,
  })
);

export default useIconStyles;
