import getKeyboardFocusableElements from "utils/getKeyboardFocusableElements";

// Sets focus to the first focusable element within a container element.
// If the container does not have any focusable elements, focus with be set to a default element.

const focusFirstElement = (
  containerId: string,
  defaultId: string = "go-to-home-page",
  containerQuery?: string
) => {
  const content = containerQuery
    ? document.querySelector(containerQuery)
    : document.getElementById(containerId);
  const focusable = getKeyboardFocusableElements(content);
  if (focusable && focusable?.length > 0) {
    focusable[0].focus();
  } else {
    document.getElementById(defaultId)?.focus();
  }
};

export default focusFirstElement;
