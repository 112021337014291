import isEqual from "lodash/isEqual";
import omit from "lodash/omit";

const UPDATE_NATIVE_APP_STATE = "UPDATE_NATIVE_APP_STATE";
const SET_LAST_PATH = "SET_LAST_PATH";

export interface State {
  isApp?: boolean;
  deviceId?: string | null | undefined;
  deviceName?: string | null | undefined;
  biometricEnrollmentStatus?: string | null;
  pushNotificationEnrollmentStatus?: string | null;
  osName?: string | null | undefined;
  osVersion?: string | null | undefined;
  appVersion?: string | null | undefined;
  canUsePullToRefresh?: boolean;
  lastPath: any;
  pageTitle?: string | null | undefined;
  deviceNotificationPermissions?: string | null | undefined;
  devicePermissionsPromptStatus?: string | null;
}

export const nativeApp = (
  state: State = getInitialNativeAppState(),
  action: any
): State => {
  switch (action.type) {
    case UPDATE_NATIVE_APP_STATE:
      const nonPathState = omit(state, ["lastPath"]);
      const newState = { ...nonPathState, ...action?.payload };
      if (isEqual(newState, nonPathState)) {
        return state;
      }

      return { ...newState, lastPath: state.lastPath };

    case SET_LAST_PATH:
      return { ...state, lastPath: action?.payload };

    default:
      return state;
  }
};

export const updateNativeAppState = (payload: {}) => {
  return { type: UPDATE_NATIVE_APP_STATE, payload };
};

export const updateNativePath = (payload: {}) => {
  return { type: SET_LAST_PATH, payload };
};

export const getInitialNativeAppState = (): State => {
  return {
    isApp: false,
    deviceId: null,
    deviceName: null,
    biometricEnrollmentStatus: null,
    pushNotificationEnrollmentStatus: null,
    osName: null,
    osVersion: null,
    appVersion: null,
    canUsePullToRefresh: false,
    lastPath: {},
    pageTitle: null,
    deviceNotificationPermissions: null,
    devicePermissionsPromptStatus: null,
  };
};
