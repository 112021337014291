// Returns an array of keyboard focusable elements for a given HTML element

const getKeyboardFocusableElements = (element: any) => {
  if (!element) {
    return null;
  }

  return [
    ...element.querySelectorAll(
      'a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'
    ),
  ].filter((el) => !el.hasAttribute("disabled"));
};

export default getKeyboardFocusableElements;
