// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CommonQuestionDetailsItem,
    CommonQuestionDetailsItemFromJSON,
    CommonQuestionDetailsItemToJSON,
} from './CommonQuestionDetailsItem';
import {
    DiagnosesListItem,
    DiagnosesListItemFromJSON,
    DiagnosesListItemToJSON,
} from './DiagnosesListItem';
import {
    QuestionSetDetailsSchema,
    QuestionSetDetailsSchemaFromJSON,
    QuestionSetDetailsSchemaToJSON,
} from './QuestionSetDetailsSchema';

/**
 * @type CMSDetailsItemSchema
 * @export
 */
export type CMSDetailsItemSchema = CommonQuestionDetailsItem | DiagnosesListItem | QuestionSetDetailsSchema;


export function CMSDetailsItemSchemaFromJSON(json: any): CMSDetailsItemSchema {
  switch (json.type) {
  case 'question_set': return QuestionSetDetailsSchemaFromJSON(json);
  case 'common_question': return CommonQuestionDetailsItemFromJSON(json);
  case 'diagnosis': return DiagnosesListItemFromJSON(json);
  default: throw new Error("Unexpected type value.");
  }
}

export function CMSDetailsItemSchemaToJSON(value?: CMSDetailsItemSchema): any {
    if (value === undefined) {
        return undefined;
    }

    switch (value.type) {
    case 'question_set': return QuestionSetDetailsSchemaToJSON(<QuestionSetDetailsSchema>value);
    case 'common_question': return CommonQuestionDetailsItemToJSON(<CommonQuestionDetailsItem>value);
    case 'diagnosis': return DiagnosesListItemToJSON(<DiagnosesListItem>value);
    default: throw new Error("Unexpected type value.");
    }
}

