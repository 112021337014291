import Typography from "components/Typography";
import React from "react";
import { Appointment } from "shared/fetch/src/models/Appointment";

const truncate = (str: string) => {
  const cutoffLength = 45;
  return str.length > cutoffLength
    ? str.substring(0, cutoffLength) + "..."
    : str;
};

export default function VisitTypeName({ visit }: { visit: Appointment }) {
  // @ts-ignore: Object is possibly 'null'.
  const visit_type_name = truncate(visit?.visitType);
  return (
    <Typography
      variant="body1"
      component="span"
      data-testid="visit-type-name"
      fontWeightBold
    >
      {visit_type_name}
    </Typography>
  );
}
