import { useQuery } from "react-query";
import {
  MemberAccountInfoRequest,
  fetchMemberAccountInfoApi,
} from "shared/fetch/src/apis/MemberAccountInfoApi";
import { MemberAccountInfoOutput } from "shared/fetch/src/models/MemberAccountInfoOutput";

export const memberAccountInfoQueryKey = (id?: string | number) => [
  "accountInfo",
  id?.toString(),
];

const useGetAccountInfo = (request: MemberAccountInfoRequest) => {
  const {
    data,
    isLoading: isPending,
    isSuccess,
    isError,
  } = useQuery(
    memberAccountInfoQueryKey(request.id),
    () => fetchMemberAccountInfoApi.memberAccountInfo(request),
    {
      enabled: !!request.id && request.id !== "undefined",
      staleTime: 1000 * 60,
    }
  );

  return {
    accountInfo: (data || {}) as MemberAccountInfoOutput,
    isSuccess,
    isError,
    isPending,
  };
};

export default useGetAccountInfo;
