// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ComponentDetailsCoreCreatorInfo
 */
export interface ComponentDetailsCoreCreatorInfo  {
    /**
     * 
     * @type {string}
     * @memberof ComponentDetailsCoreCreatorInfo
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetailsCoreCreatorInfo
     */
    legalName?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetailsCoreCreatorInfo
     */
    legalAndPreferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetailsCoreCreatorInfo
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetailsCoreCreatorInfo
     */
    avatarUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDetailsCoreCreatorInfo
     */
    type?: ComponentDetailsCoreCreatorInfoTypeEnum;
}

export function ComponentDetailsCoreCreatorInfoFromJSON(json: any): ComponentDetailsCoreCreatorInfo {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'legalName': !exists(json, 'legal_name') ? undefined : json['legal_name'],
        'legalAndPreferredName': !exists(json, 'legal_and_preferred_name') ? undefined : json['legal_and_preferred_name'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function ComponentDetailsCoreCreatorInfoToJSON(value?: ComponentDetailsCoreCreatorInfo): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'legal_name': value.legalName,
        'legal_and_preferred_name': value.legalAndPreferredName,
        'name': value.name,
        'avatar_url': value.avatarUrl,
        'type': value.type,
    };
}

/**
* @export
* @enum {string}
*/
export enum ComponentDetailsCoreCreatorInfoTypeEnum {
    Member = 'member',
    Ctm = 'ctm'
}


