import { useLocation, useNavigate } from "react-router";
import qs from "query-string";
import omitBy from "lodash/omitBy";

const useQueryParams = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = qs.parse(location.search);

  const setQueryParams = (params: any) => {
    const allParams = {
      ...queryParams,
      ...params,
    };

    const filteredParams = omitBy(allParams, (value) => {
      return value === undefined || value === null || value === "";
    });

    const newPath = `${location.pathname}?${qs.stringify(filteredParams)}`;

    navigate(newPath);
  };

  const clearQueryParams = () => navigate(location.pathname);

  return { queryParams, setQueryParams, clearQueryParams };
};

export default useQueryParams;
