import React, { FunctionComponent } from "react";
import DialogContentComponent, {
  DialogContentProps,
} from "@mui/material/DialogContent";

const DialogContent: FunctionComponent<DialogContentProps> = (props) => {
  return <DialogContentComponent {...props} />;
};

export default DialogContent;
