import React, { FunctionComponent } from "react";
import MenuComponent, { MenuProps } from "@mui/material/Menu";
import { Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

interface IProps extends MenuProps {
  /** The appearance to display. */
  appearance?: "light" | "dark";
}

const useDarkStyles = makeStyles((theme: Theme) => ({
  paper: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.common.white,
  },
  list: {
    "& a": {
      color: "inherit",
    },
    "& .MuiListItem-root.Mui-focusVisible": {
      backgroundColor: "rgba(255, 255, 255, 0.3)",
    },
    "& .MuiListItem-button:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
    "& .MuiMenuItem-root": {
      fontSize: theme.typography.body2.fontSize,
    },
  },
}));

const Menu: FunctionComponent<IProps> = (props) => {
  const classesDark = useDarkStyles(props);
  const { appearance = "light", ...rest } = props;
  const classes = appearance === "light" ? null : classesDark;
  // @ts-ignore: Object is possibly 'null'.
  return <MenuComponent classes={classes} {...rest} />;
};

export default Menu;
