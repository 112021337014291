// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberSearchDetails,
    MemberSearchDetailsFromJSON,
    MemberSearchDetailsToJSON,
} from './MemberSearchDetails';


/**
 * 
 * @export
 * @interface MemberSearchResults
 */
export interface MemberSearchResults  {
    /**
     * 
     * @type {Array<MemberSearchDetails>}
     * @memberof MemberSearchResults
     */
    results?: Array<MemberSearchDetails>;
}

export function MemberSearchResultsFromJSON(json: any): MemberSearchResults {
    return {
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(MemberSearchDetailsFromJSON),
    };
}

export function MemberSearchResultsToJSON(value?: MemberSearchResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(MemberSearchDetailsToJSON),
    };
}


