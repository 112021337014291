import { useMutation } from "react-query";
import { fetchHeadsUpApi } from "shared/fetch/src/apis/HeadsUpApi";

export default () => {
  const {
    isSuccess: isFinishedResolving,
    mutateAsync: resolveHeadsUpNotifications,
  } = useMutation((id: string) =>
    fetchHeadsUpApi.resolveHeadsUpNotification({ id })
  );

  return {
    isFinishedResolving,
    resolveHeadsUpNotifications,
  };
};
