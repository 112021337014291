/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpdateAppointment,
    UpdateAppointmentFromJSON,
    UpdateAppointmentToJSON,
} from '../models/UpdateAppointment'
import {
    VirtualVisitReferralComponentDetailsOutput,
    VirtualVisitReferralComponentDetailsOutputFromJSON,
    VirtualVisitReferralComponentDetailsOutputToJSON,
} from '../models/VirtualVisitReferralComponentDetailsOutput'


export interface CancelAppointmentRequest {
    id: string;
    updateAppointment: UpdateAppointment;
}


export interface ICancelAppointmentApi {
      cancelAppointment: (requestParameters: CancelAppointmentRequest) => Promise<VirtualVisitReferralComponentDetailsOutput>
      cancelAppointmentWithResponse: (requestParameters: CancelAppointmentRequest) => Promise<{ value: VirtualVisitReferralComponentDetailsOutput, response: any}>
}

/**
 * no description
 */
export class CancelAppointmentApi extends runtime.BaseAPI {

    /**
     * Cancel Appointment by id
     * Cancel Appointment by id
     */
    cancelAppointmentRaw = async (requestParameters: CancelAppointmentRequest): Promise<{ runtime: runtime.ApiResponse<VirtualVisitReferralComponentDetailsOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling cancelAppointment.');
        }

                        if (requestParameters.updateAppointment === null || requestParameters.updateAppointment === undefined) {
            throw new runtime.RequiredError('updateAppointment','Required parameter requestParameters.updateAppointment was null or undefined when calling cancelAppointment.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/appointments/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateAppointmentToJSON(requestParameters.updateAppointment),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => VirtualVisitReferralComponentDetailsOutputFromJSON(jsonValue)), response };
    }

    /**
     * Cancel Appointment by id
     * Cancel Appointment by id
     */
    cancelAppointment = async (requestParameters: CancelAppointmentRequest): Promise<VirtualVisitReferralComponentDetailsOutput> => {
        const { runtime } = await this.cancelAppointmentRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Cancel Appointment by id
     * Cancel Appointment by id
     */
    cancelAppointmentWithResponse = async (requestParameters: CancelAppointmentRequest): Promise<{ value: VirtualVisitReferralComponentDetailsOutput, response: any}> => {
        const { runtime, response } = await this.cancelAppointmentRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchCancelAppointmentApi: ICancelAppointmentApi  = new CancelAppointmentApi();
