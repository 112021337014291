// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RestrictedToEnum,
    RestrictedToEnumFromJSON,
    RestrictedToEnumToJSON,
} from './RestrictedToEnum';
import {
    SlotVisibilityEnum,
    SlotVisibilityEnumFromJSON,
    SlotVisibilityEnumToJSON,
} from './SlotVisibilityEnum';


/**
 * 
 * @export
 * @interface CreateSlotCore
 */
export interface CreateSlotCore  {
    /**
     * 
     * @type {string}
     * @memberof CreateSlotCore
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSlotCore
     */
    clinicId: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSlotCore
     */
    providerId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSlotCore
     */
    appointmentTypes?: Array<string>;
    /**
     * 
     * @type {SlotVisibilityEnum}
     * @memberof CreateSlotCore
     */
    visibility?: SlotVisibilityEnum;
    /**
     * 
     * @type {number}
     * @memberof CreateSlotCore
     */
    maxPatients?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateSlotCore
     */
    maxOverbook?: number;
    /**
     * 
     * @type {RestrictedToEnum}
     * @memberof CreateSlotCore
     */
    restrictedTo?: RestrictedToEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateSlotCore
     */
    startAt: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSlotCore
     */
    endAt: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSlotCore
     */
    startTime?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSlotCore
     */
    endTime?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateSlotCore
     */
    appointments?: Array<string>;
}

export function CreateSlotCoreFromJSON(json: any): CreateSlotCore {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'clinicId': json['clinic_id'],
        'providerId': json['provider_id'],
        'appointmentTypes': !exists(json, 'appointment_types') ? undefined : json['appointment_types'],
        'visibility': !exists(json, 'visibility') ? undefined : SlotVisibilityEnumFromJSON(json['visibility']),
        'maxPatients': !exists(json, 'max_patients') ? undefined : json['max_patients'],
        'maxOverbook': !exists(json, 'max_overbook') ? undefined : json['max_overbook'],
        'restrictedTo': !exists(json, 'restricted_to') ? undefined : RestrictedToEnumFromJSON(json['restricted_to']),
        'startAt': json['start_at'],
        'endAt': json['end_at'],
        'startTime': !exists(json, 'start_time') ? undefined : json['start_time'],
        'endTime': !exists(json, 'end_time') ? undefined : json['end_time'],
        'appointments': !exists(json, 'appointments') ? undefined : json['appointments'],
    };
}

export function CreateSlotCoreToJSON(value?: CreateSlotCore): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'clinic_id': value.clinicId,
        'provider_id': value.providerId,
        'appointment_types': value.appointmentTypes,
        'visibility': SlotVisibilityEnumToJSON(value.visibility),
        'max_patients': value.maxPatients,
        'max_overbook': value.maxOverbook,
        'restricted_to': RestrictedToEnumToJSON(value.restrictedTo),
        'start_at': value.startAt,
        'end_at': value.endAt,
        'start_time': value.startTime,
        'end_time': value.endTime,
        'appointments': value.appointments,
    };
}


