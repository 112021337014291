// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserSettingsResponse
 */
export interface UserSettingsResponse  {
    /**
     * 
     * @type {string}
     * @memberof UserSettingsResponse
     */
    data?: string;
}

export function UserSettingsResponseFromJSON(json: any): UserSettingsResponse {
    return {
        'data': !exists(json, 'data') ? undefined : json['data'],
    };
}

export function UserSettingsResponseToJSON(value?: UserSettingsResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'data': value.data,
    };
}


