// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateCheckin
 */
export interface UpdateCheckin  {
    /**
     * 
     * @type {string}
     * @memberof UpdateCheckin
     */
    lastStep?: string;
}

export function UpdateCheckinFromJSON(json: any): UpdateCheckin {
    return {
        'lastStep': !exists(json, 'last_step') ? undefined : json['last_step'],
    };
}

export function UpdateCheckinToJSON(value?: UpdateCheckin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'last_step': value.lastStep,
    };
}


