// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StripePaymentSource,
    StripePaymentSourceFromJSON,
    StripePaymentSourceToJSON,
} from './StripePaymentSource';


/**
 * 
 * @export
 * @interface StripeCustomer
 */
export interface StripeCustomer  {
    /**
     * Current balance, if any, being stored on the customer. If negative, the customer has credit to apply to their next invoice. If positive, the customer has an amount owed that will be added to their next invoice. The balance does not refer to any unpaid invoices; it solely takes into account amounts that have yet to be successfully applied to any invoice. This balance is only taken into account as invoices are finalized.
     * @type {number}
     * @memberof StripeCustomer
     */
    balance?: number;
    /**
     * 
     * @type {StripePaymentSource}
     * @memberof StripeCustomer
     */
    defaultSource?: StripePaymentSource;
}

export function StripeCustomerFromJSON(json: any): StripeCustomer {
    return {
        'balance': !exists(json, 'balance') ? undefined : json['balance'],
        'defaultSource': !exists(json, 'default_source') ? undefined : StripePaymentSourceFromJSON(json['default_source']),
    };
}

export function StripeCustomerToJSON(value?: StripeCustomer): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'balance': value.balance,
        'default_source': StripePaymentSourceToJSON(value.defaultSource),
    };
}


