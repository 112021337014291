// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberServicesObject
 */
export interface MemberServicesObject  {
    /**
     * 
     * @type {string}
     * @memberof MemberServicesObject
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MemberServicesObject
     */
    serviceLineName: string;
}

export function MemberServicesObjectFromJSON(json: any): MemberServicesObject {
    return {
        'name': json['name'],
        'serviceLineName': json['service_line_name'],
    };
}

export function MemberServicesObjectToJSON(value?: MemberServicesObject): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'service_line_name': value.serviceLineName,
    };
}


