import React from "react";
import { Button, useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from "@mui/material/useMediaQuery";
import Heading from "../DynamicHeadings";
import Box from "components/Box";
import theme from "styles/mui";
import clsx from "clsx";

const {
  typography: { h4 },
} = theme as any;

const useStyles = makeStyles(({ breakpoints }) => ({
  container: {
    [breakpoints?.down("md")]: {
      justifyContent: "center",
    },
  },
  overallContainer: {
    height: 397,
    [breakpoints?.down("sm")]: {
      flexDirection: "column",
    },
    [breakpoints?.only("sm")]: {
      height: 300,
    },
    [breakpoints?.only("xs")]: {
      height: "initial",
    },
    [breakpoints?.only("md")]: {
      height: 500,
    },
  },

  overallContainerOneCard: {
    [breakpoints?.only("xs")]: {
      height: "initial",
    },
    [breakpoints?.only("md")]: {
      color: "blue",
    },
    height: 315,
  },
  copyContainer: {
    height: "100%",
    width: "100%",
    display: "flex",
    justifyContent: "inherit",
    flexDirection: "column",
    [breakpoints?.only("xs")]: {
      height: "initial",
      width: "initial",
    },
  },
  button: {
    backgroundColor: "white",
    width: "max-content",
    height: 44,
    justifyContent: "space-around",
    fontWeight: "bold",
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 16,
    [breakpoints?.only("sm")]: {
      alignSelf: "center",
      marginBottom: 15,
      marginTop: 15,
    },
  },

  buttonOneCard: {
    marginTop: "30px",
    [breakpoints?.only("sm")]: {
      marginTop: "20px",
      marginBottom: "10px",
    },
  },

  marketingImg: {
    width: 200,
    height: 150,
    float: "right",

    [breakpoints?.only("xs")]: {
      float: "none",
      marginBottom: 16,
    },

    [breakpoints?.only("md")]: {
      float: "none",
      marginBottom: 16,
    },
  },

  textAndImage: {
    display: "flex",
    alignItems: "center",
  },

  arrowLink: {
    minWidth: "initial",
    marginLeft: 16,
    [breakpoints?.down(850)]: {
      display: "none",
    },
  },

  verticalImgAndButton: {
    maxWidth: "min-content",
  },

  titleHeading: {
    width: "292px",
  },
}));

interface MarketingCardProps {
  backgroundColor: string;
  buttonText: string;
  buttonColor: string;
  dataTestIdPrefix: string;
  imgSrc: any;
  linkTo: string;
  openNewTab?: boolean;
  onButtonClick: (e: any) => any;
  orientation?: "horizontal" | "vertical";
  renderCopy: () => JSX.Element;
  altImageText?: string;
  textColor: string;
  title: string;
  hasTwoCards?: boolean;
}

const MarketingCard = ({
  backgroundColor,
  buttonText,
  buttonColor,
  dataTestIdPrefix,
  imgSrc,
  onButtonClick,
  renderCopy,
  textColor,
  title,
  altImageText = "",
  hasTwoCards,
}: MarketingCardProps): JSX.Element => {
  const classes = useStyles();
  const { palette } = useTheme();
  const themeForSize = useTheme();

  const isXSmallScreen = useMediaQuery(themeForSize?.breakpoints?.only("xs"));
  const isMediumScreen = useMediaQuery(themeForSize?.breakpoints?.only("md"));

  const finalOrientation =
    isXSmallScreen || (isMediumScreen && hasTwoCards)
      ? "vertical"
      : "horizontal";

  const createTestId = (text: any) => `${dataTestIdPrefix}-${text}`;

  const MarketingButton = () => (
    <Button
      variant="contained"
      data-testid={createTestId("timeline")}
      className={
        hasTwoCards
          ? classes.button
          : clsx(classes.button, classes.buttonOneCard)
      }
      onClick={onButtonClick}
    >
      <Box color={buttonColor} pr={1}>
        {buttonText}
      </Box>
      <svg
        width="13"
        height="20"
        viewBox="0 0 13 20"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.879883 2.12L8.75988 10L0.879883 17.88L2.99988 20L12.9999 10L2.99988 0L0.879883 2.12Z"
          fill={buttonColor}
        />
      </svg>
    </Button>
  );

  return (
    <Box
      className={classes.container}
      display="flex"
      data-testid={createTestId("eoc-marketing-container")}
    >
      <Box
        className={
          hasTwoCards
            ? classes.overallContainer
            : classes.overallContainerOneCard
        }
        borderRadius="10px"
        display="flex"
        padding="20px"
        justifyContent="space-between"
        alignItems="center"
        boxShadow={`2px 2px 6px ${palette?.boxShadowLightGray}`}
        bgcolor={backgroundColor}
        flexDirection={finalOrientation === "horizontal" ? "row" : "column"}
        width="100%"
      >
        <div className={classes.copyContainer}>
          <Heading.H
            data-testid={createTestId("title")}
            className={classes.titleHeading}
          >
            <Box
              color={textColor}
              fontStyle="normal"
              fontWeight="bold"
              fontSize={h4?.fontSize}
              padding="10px 0px"
              display="inline"
            >
              {title}
            </Box>
          </Heading.H>
          <div>
            <div className={classes.textAndImage}>
              <div>{renderCopy()}</div>
              {finalOrientation === "horizontal" && (
                <img
                  alt={altImageText}
                  className={classes.marketingImg}
                  src={imgSrc}
                />
              )}
            </div>
            {finalOrientation === "horizontal" && <MarketingButton />}
          </div>
          {finalOrientation === "vertical" && (
            <div className={classes.verticalImgAndButton}>
              <img
                alt={altImageText}
                className={classes.marketingImg}
                src={imgSrc}
              />
              <MarketingButton />
            </div>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default MarketingCard;
