import React, { UIEventHandler } from "react";

export enum PDFViewerMode {
  SINGLE_PAGE = "single",
  VERTICAL = "vertical",
  HORIZONTAL = "horizontal",
}

export enum PDFViewerSideNavigationState {
  OUTLINE = "outline",
  PREVIEW = "preview",
  CLOSED = "closed",
}

export interface PDFViewerFile {
  url?: string;
  /** preferably Uint8Array */
  data?: ArrayBuffer;
  /** pdf.js PDFDataRangeTransport */
  range?: any;
  httpHeaders?: Record<string, string>;
  /** Set to true to include cookies in the request */
  withCredentials?: boolean;
}

export interface IPDFDocumentProxy {
  pageNumber: number;
  numPages: number;
  getPage: (page?: number) => Promise<any>;
  getOutline: () => void;
}

export interface PDFViewerControllerResponse {
  scale: number;
  pageCount: number;
  thumbnails: string[];
  pdfHasOutline: boolean;
  fileProxy: PDFViewerFile;
  isLoadingPreview: boolean;
  activePage: number;
  rotationAngle: number | undefined;
  fullWidthScale: number | undefined;
  printOrDownloadEnabled: boolean;
  selectedViewMode: PDFViewerMode;
  sideNavigationState: PDFViewerSideNavigationState;
  activeThumbnailRef: React.MutableRefObject<HTMLDivElement | null>;
  pageCanvasRef: React.MutableRefObject<HTMLCanvasElement | null>;
  pagesContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  print: () => void;
  onOutlineError: () => void;
  onItemClick: (args: Partial<IPDFDocumentProxy>) => void;
  onDocumentScroll: UIEventHandler<HTMLDivElement>;
  onDocumentLoadSuccess: (document: IPDFDocumentProxy) => void;
  setScale: React.Dispatch<React.SetStateAction<number>>;
  setPageCount: React.Dispatch<React.SetStateAction<number>>;
  setViewMode: React.Dispatch<React.SetStateAction<PDFViewerMode>>;
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
  setRotationAngle: React.Dispatch<React.SetStateAction<number | undefined>>;
  setSideNavigationState: React.Dispatch<
    React.SetStateAction<PDFViewerSideNavigationState>
  >;
}
