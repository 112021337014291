import React, { useState } from "react";
import TodayIcon from "@mui/icons-material/Today";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ICalendar,
  GoogleCalendar,
  OutlookCalendar,
  CalendarOptions,
} from "datebook";

import Button from "components/Button";
import Box from "components/Box";
import Typography from "components/Typography";
import AddToCalendarModal from "./AddToCalendarModal";
import { VideoConferenceOutput } from "shared/fetch/src/models/VideoConferenceOutput";
import { MemberClinic } from "shared/fetch/src/models/MemberClinic";

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    container: {
      display: "flex",
      justifyContent: "flex-end",
      cursor: "pointer",
      "&:hover": {
        "& $todayIcon": {
          color: "#4c5561 !important",
          transition: "all ease-in-out 0.3s",
        },
        "& a": {
          color: "#4c5561 !important",
        },
      },
    },
    todayIcon: {
      color: palette.text.secondary,
      marginRight: "3px",
    },
  })
);

interface IProps {
  title?: string;
  reason?: string;
  location?: string;
  videoConference?: VideoConferenceOutput;
  startDate?: Date;
  endDate?: Date;
  clinic?: MemberClinic;
  method?: string;
}

const AddToCalendar = ({
  title,
  videoConference,
  reason,
  startDate,
  endDate,
  clinic,
  method,
}: IProps) => {
  const classes = useStyles();

  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);
  const showCalendarModal = () => setIsCalendarModalOpen(true);
  const hideCalendarModal = () => setIsCalendarModalOpen(false);

  const reasonText = reason ? reason : "";

  const description = videoConference?.url
    ? `${reasonText}.\nHere is the link to the meeting:\n${videoConference?.url}`
    : reason;

  const options: CalendarOptions = {
    title,
    description: `${description ? description : ""} \n Clinic Telephone: ${
      clinic?.phone
    }`,
    location: method === "video" ? videoConference?.url : clinic?.address,
    start: new Date(startDate || ""),
    end: new Date(endDate || ""),
  };

  const appleCalendarClickHandler = (eventOptions: CalendarOptions) => {
    const icalendar = new ICalendar(eventOptions);
    icalendar.download();
    hideCalendarModal();
  };

  const googleCalendar = new GoogleCalendar(options);
  const googleCalendarUrl = googleCalendar.render();

  const outlookCalendar = new OutlookCalendar(options);
  const outlookCalendarUrl = outlookCalendar.render();

  return (
    <>
      <AddToCalendarModal
        isOpen={isCalendarModalOpen}
        hideModal={hideCalendarModal}
        googleCalendarUrl={googleCalendarUrl}
        outlookCalendarUrl={outlookCalendarUrl}
        appleCalendarClickHandler={() => appleCalendarClickHandler(options)}
      />
      <Button
        color="link-secondary"
        data-e2e="add-to-calendar"
        onClick={showCalendarModal}
      >
        <Box className={classes.container}>
          <TodayIcon fontSize="small" className={classes.todayIcon} />
          <Typography
            color="textSecondary"
            appearance="smallBody"
            component="span"
            data-testid="add-to-calendar"
          >
            Add to calendar
          </Typography>
        </Box>
      </Button>
    </>
  );
};

export default AddToCalendar;
