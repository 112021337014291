// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CtmConversationSummary,
    CtmConversationSummaryFromJSON,
    CtmConversationSummaryToJSON,
} from './CtmConversationSummary';
import {
    MemberConversationSummary,
    MemberConversationSummaryFromJSON,
    MemberConversationSummaryToJSON,
} from './MemberConversationSummary';

/**
 * @type ConversationSummary
 * @export
 */
export type ConversationSummary = CtmConversationSummary | MemberConversationSummary;


export function ConversationSummaryFromJSON(json: any): ConversationSummary {
  switch (json.type) {
  case 'ctm': return CtmConversationSummaryFromJSON(json);
  case 'member': return MemberConversationSummaryFromJSON(json);
  default: throw new Error("Unexpected type value.");
  }
}

export function ConversationSummaryToJSON(value?: ConversationSummary): any {
    if (value === undefined) {
        return undefined;
    }

    switch (value.type) {
    case 'ctm': return CtmConversationSummaryToJSON(<CtmConversationSummary>value);
    case 'member': return MemberConversationSummaryToJSON(<MemberConversationSummary>value);
    default: throw new Error("Unexpected type value.");
    }
}

