// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PrescriptionRefillRequestBody
 */
export interface PrescriptionRefillRequestBody  {
    /**
     * 
     * @type {Array<string>}
     * @memberof PrescriptionRefillRequestBody
     */
    prescriptionIds: Array<string>;
}

export function PrescriptionRefillRequestBodyFromJSON(json: any): PrescriptionRefillRequestBody {
    return {
        'prescriptionIds': json['prescription_ids'],
    };
}

export function PrescriptionRefillRequestBodyToJSON(value?: PrescriptionRefillRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'prescription_ids': value.prescriptionIds,
    };
}


