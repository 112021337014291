// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberEnrolledDeviceProfile
 */
export interface MemberEnrolledDeviceProfile  {
    /**
     * 
     * @type {string}
     * @memberof MemberEnrolledDeviceProfile
     */
    deviceId: string;
    /**
     * 
     * @type {string}
     * @memberof MemberEnrolledDeviceProfile
     */
    deviceName: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberEnrolledDeviceProfile
     */
    biometricEnabled: boolean;
}

export function MemberEnrolledDeviceProfileFromJSON(json: any): MemberEnrolledDeviceProfile {
    return {
        'deviceId': json['device_id'],
        'deviceName': json['device_name'],
        'biometricEnabled': json['biometric_enabled'],
    };
}

export function MemberEnrolledDeviceProfileToJSON(value?: MemberEnrolledDeviceProfile): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'device_id': value.deviceId,
        'device_name': value.deviceName,
        'biometric_enabled': value.biometricEnabled,
    };
}


