// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActiveEpisodesForPortalResponse,
    ActiveEpisodesForPortalResponseFromJSON,
    ActiveEpisodesForPortalResponseToJSON,
} from './ActiveEpisodesForPortalResponse';


/**
 * 
 * @export
 * @interface CTMInfoArrayResponse1
 */
export interface CTMInfoArrayResponse1  {
    /**
     * 
     * @type {Array<ActiveEpisodesForPortalResponse>}
     * @memberof CTMInfoArrayResponse1
     */
    results?: Array<ActiveEpisodesForPortalResponse>;
}

export function CTMInfoArrayResponse1FromJSON(json: any): CTMInfoArrayResponse1 {
    return {
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(ActiveEpisodesForPortalResponseFromJSON),
    };
}

export function CTMInfoArrayResponse1ToJSON(value?: CTMInfoArrayResponse1): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(ActiveEpisodesForPortalResponseToJSON),
    };
}


