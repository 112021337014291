import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { State } from "@types";
import AlertDialog from "components/Dialog/AlertDialog";
import { hideConfirmationsModal } from "shared/state/ui/confirmationsDialog";
import { setHasUnsavedInput } from "shared/state/ui/member";

const DEFAULTS = {
  title: "Are you sure you want to exit?",
  content: `You'll lose any information you've inputted and will have to start the message reply all over again.`,
  appearance: "warning",
  maxWidth: "xs",
  confirmButtonLabel: "Yes",
  cancelButtonLabel: "No",
};

export default function ConfirmationsDialog() {
  const dispatch = useDispatch();
  const {
    open,
    onCancel,
    onConfirm,
    hasOnCancelLabel = true,
    overrides,
  } = useSelector((state: State) => state.ui.confirmationsDialog);

  const props = { ...DEFAULTS, ...overrides };

  function onCancelHandler() {
    typeof onCancel === "function" && onCancel();
    dispatch(hideConfirmationsModal());
  }

  function onConfirmHandler() {
    dispatch(setHasUnsavedInput(false));
    typeof onConfirm === "function" && onConfirm();
    dispatch(hideConfirmationsModal());
  }

  return (
    <AlertDialog
      open={open}
      onClose={onCancelHandler}
      onConfirmClick={onConfirm && onConfirmHandler}
      // @ts-ignore: Object is possibly 'null'.
      onCancelClick={hasOnCancelLabel && onCancelHandler}
      {...props}
    />
  );
}
