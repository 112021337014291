// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WorkspaceDraftDataSchemaReferralBase
 */
export interface WorkspaceDraftDataSchemaReferralBase  {
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDraftDataSchemaReferralBase
     */
    appointmentType?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDraftDataSchemaReferralBase
     */
    staffGlobalId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDraftDataSchemaReferralBase
     */
    clinicId?: string;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDraftDataSchemaReferralBase
     */
    urgency?: WorkspaceDraftDataSchemaReferralBaseUrgencyEnum;
    /**
     * 
     * @type {string}
     * @memberof WorkspaceDraftDataSchemaReferralBase
     */
    referralReason?: string;
}

export function WorkspaceDraftDataSchemaReferralBaseFromJSON(json: any): WorkspaceDraftDataSchemaReferralBase {
    return {
        'appointmentType': !exists(json, 'appointment_type') ? undefined : json['appointment_type'],
        'staffGlobalId': !exists(json, 'staff_global_id') ? undefined : json['staff_global_id'],
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'urgency': !exists(json, 'urgency') ? undefined : json['urgency'],
        'referralReason': !exists(json, 'referral_reason') ? undefined : json['referral_reason'],
    };
}

export function WorkspaceDraftDataSchemaReferralBaseToJSON(value?: WorkspaceDraftDataSchemaReferralBase): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'appointment_type': value.appointmentType,
        'staff_global_id': value.staffGlobalId,
        'clinic_id': value.clinicId,
        'urgency': value.urgency,
        'referral_reason': value.referralReason,
    };
}

/**
* @export
* @enum {string}
*/
export enum WorkspaceDraftDataSchemaReferralBaseUrgencyEnum {
    Asap = 'asap',
    NextFewDays = 'next_few_days',
    NextFewWeeks = 'next_few_weeks'
}


