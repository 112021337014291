// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CmsNavigationSchemaHierarchy,
    CmsNavigationSchemaHierarchyFromJSON,
    CmsNavigationSchemaHierarchyToJSON,
} from './CmsNavigationSchemaHierarchy';


/**
 * 
 * @export
 * @interface CmsNavigationSchema
 */
export interface CmsNavigationSchema  {
    /**
     * 
     * @type {Array<CmsNavigationSchemaHierarchy>}
     * @memberof CmsNavigationSchema
     */
    hierarchy: Array<CmsNavigationSchemaHierarchy>;
}

export function CmsNavigationSchemaFromJSON(json: any): CmsNavigationSchema {
    return {
        'hierarchy': (json['hierarchy'] as Array<any>).map(CmsNavigationSchemaHierarchyFromJSON),
    };
}

export function CmsNavigationSchemaToJSON(value?: CmsNavigationSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'hierarchy': (value.hierarchy as Array<any>).map(CmsNavigationSchemaHierarchyToJSON),
    };
}


