import React from "react";

/* eslint-disable */
const withProps = (propFunc: any) => (WrappedComponent: any) => (
  props: any
) => {
  const newProps = { ...props, ...propFunc(props) };

  return <WrappedComponent {...newProps} />;
};
/* eslint-enable */

export default withProps;
