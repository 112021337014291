import React, { useEffect, useState } from "react";
import { PaperProps } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Paper from "components/Paper";
import Dialog from "components/Dialog";
import DialogActions from "components/Dialog/DialogActions";
import Button from "components/Button";
import DialogContent from "components/Dialog/DialogContent";
import DialogTitle from "components/Dialog/DialogTitle";
import TextField from "components/TextField";
import MenuItem from "components/Menu/MenuItem";
import Typography from "components/Typography";
import Box from "components/Box";
import CardSection from "components/Card/CardSection";
import Checkbox from "components/Checkbox";
import ListItemText from "components/List/ListItemText";
import { useUpdateEpisodeRoles } from "shared/state/episodeRoles/useUpdateEpisodeRoles";
import { useUpdateEpisodeOwner } from "shared/state/episodeOwner/useUpdateEpisodeOwner";
import { showSnackbar } from "shared/state/ui/snackbar";
import useGetPanelCareTeam from "shared/features/ccMessaging/useGetPanelCareTeam";
import { useGetEpisodeRoles } from "shared/state/episodeRoles/useGetEpisodeRoles";
import { useResetEpisodeRoles } from "shared/state/episodeRoles/useResetEpisodeRoles";
import { CTMInfo } from "shared/fetch/src/models/CTMInfo";
import { getUser } from "shared/features/user/selectors";
import SelectEpisodeOwnerSkeleton from "./SelectEpisodeOwnerSkeleton";
import uniqBy from "lodash/uniqBy";
import ProviderComboBox from "components/ComboBox/ProviderComboBox";
import { fetchEpisodeWorkspaceDraftQueryKey } from "shared/state/workspaceDraft/useWorkspaceDraftController";
import { useQueryClient } from "react-query";

const useStyles = makeStyles((theme: Theme) => ({
  roleInputField: {
    width: "100%",
    "& .MuiFilledInput-root": {
      backgroundColor: "rgba(238, 239, 240, 1)",
    },
    "& .MuiFilledInput-underline:hover:before": {
      borderBottom: "2px solid #ca4004",
    },
  },
  dialog: {
    padding: 0,
    "& .MuiDialog-paper": {
      position: "absolute",
      top: `${theme.spacing(4)} !important`,
    },
    "& .MuiDialogTitle-root": {
      paddingTop: 0,
    },
  },
  dialogContent: {
    width: "100%",
    paddingTop: 0,
  },
  optionalTag: {
    color: theme.palette.text.secondary,
  },
  ownerChangeReminder: {
    color: theme.palette.text.secondary,
    paddingTop: theme.spacing(1),
  },
  buttons: {
    paddingLeft: theme.spacing(3),
  },
}));

interface IProps {
  open: boolean;
  toggle: () => void;
  owner: any;
  episodeId: string;
  memberId: string;
  handleOwnerChange?: Function;
  handleRolesUpdate: Function;
}

interface INullUser {
  name: string;
  id: any;
  podId: any;
  guilds: string[];
}

function PaperComponent(props: PaperProps) {
  return <Paper {...props} style={{ padding: "0px", top: "-50px" }} />;
}

const SelectEpisodeOwnerModal: React.FC<IProps> = ({
  open,
  toggle,
  owner,
  episodeId,
  memberId,
  handleOwnerChange,
  handleRolesUpdate,
}) => {
  const queryClient = useQueryClient();

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [roles, setRoles] = useState([]);
  const [allRoles, setAllRoles] = useState(new Map());

  const [selectedCtm, setSelectedCtm] = useState("");
  const [allCtms, setAllCtms] = useState(new Map());
  const [ctmList, setCtmList] = useState([]);
  const [youUser, setYouUser] = useState([]);

  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  const { updateRoles } = useUpdateEpisodeRoles();
  const { updateOwner } = useUpdateEpisodeOwner();
  const { resetEpisodeRoles } = useResetEpisodeRoles();
  const { panelCareTeam, isPending: isGetPanelCareTeamPending } =
    useGetPanelCareTeam({
      id: memberId,
      memberFacing: true,
    });
  const { episodeRoles, isPending: isGetEpisodeRolesPending } =
    useGetEpisodeRoles(episodeId);
  const isPending = isGetEpisodeRolesPending || isGetPanelCareTeamPending;

  const user = useSelector(getUser);

  const showToast = (message: string, appearance: "danger" | "success") =>
    dispatch(showSnackbar(message, appearance));

  const uniqueCtm = (id: any, podId: any) =>
    podId ? `${id}-${podId}` : `${id}`;

  const classes = useStyles();
  const unclaimedUser: INullUser = {
    name: "None - Select to unclaim",
    id: null,
    podId: null,
    guilds: [],
  };

  useEffect(() => {
    if (episodeRoles && !isGetEpisodeRolesPending) {
      const list: any = [];
      const rolesList = new Map();
      episodeRoles.availableRoles?.forEach((role) => {
        rolesList.set(role.displayLabel, role);
      });
      episodeRoles.assignedRoles?.forEach((role) => {
        list.push(role.displayLabel);
        if (!rolesList.has(role.displayLabel)) {
          rolesList.set(role.displayLabel, role);
        }
      });
      const previouslyAssignedAndAvailableRoles = uniqBy(
        [
          ...(episodeRoles?.availableRoles || []),
          ...(episodeRoles?.assignedRoles || []),
        ],
        "id"
      );
      // @ts-ignore: Object is possibly 'null'.
      setRoles(previouslyAssignedAndAvailableRoles);
      setAllRoles(rolesList);
      setSelectedRoles(list);
    }
  }, [episodeRoles]);

  useEffect(() => {
    const ctmMap = new Map();
    ctmMap.set(uniqueCtm(unclaimedUser.id, unclaimedUser.podId), unclaimedUser);
    const youEntries: CTMInfo[] = [];
    panelCareTeam?.results?.forEach((ctm: CTMInfo) => {
      if (user?.id?.toString() === ctm?.id?.toString()) {
        ctm.name = "You";
        youEntries.push(ctm);
      }
      ctmMap.set(uniqueCtm(ctm.id, ctm.podId), ctm);
    });
    setYouUser(
      // @ts-ignore: Object is possibly 'null'.
      youEntries.sort((a: CTMInfo, b: CTMInfo) =>
        // @ts-ignore: Object is possibly 'null'.
        a.careTeamName?.localeCompare(b.careTeamName)
      )
    );
    setAllCtms(ctmMap);
    const filteredPanelCareTeam = panelCareTeam?.results?.filter(
      (result: CTMInfo) => result.id !== user?.id?.toString()
    );
    setSelectedCtm(uniqueCtm(owner?.id, owner?.podId || null));
    // @ts-ignore: Object is possibly 'null'.
    setCtmList(filteredPanelCareTeam);
  }, [panelCareTeam]);

  const handleRoleChange = (event: any) => {
    if (event.target?.value?.length > 0) {
      setSelectedRoles(event.target.value);
      return;
    } else {
      showToast("At least one role must be selected", "danger");
      return;
    }
  };

  const getCtmDefaultRoles = (ctm: string) => {
    return panelCareTeam?.results?.find(
      (teamMember: CTMInfo) =>
        uniqueCtm(teamMember.id, teamMember.podId) === ctm
    )?.guilds;
  };

  const handleCtmChange = (event: any, value: CTMInfo) => {
    const currentRoles = selectedRoles;
    if (
      uniqueCtm(value?.id, value?.podId) !== selectedCtm &&
      selectedCtm !== ""
    ) {
      // if a user selects an owner and the roles auto-update, but they
      // proceed to change their selection before saving, remove any roles
      // that auto-updated before their final selection
      const prevCtmDefaultRoles = getCtmDefaultRoles(selectedCtm);
      const list: string[] = [];
      episodeRoles?.assignedRoles?.forEach((role) => {
        // @ts-ignore: Object is possibly 'null'.
        list.push(role?.displayLabel);
      });
      prevCtmDefaultRoles?.map((guild: string) => {
        // @ts-ignore: Object is possibly 'null'.
        if (selectedRoles.includes(guild) && !list?.includes(guild)) {
          // @ts-ignore: Object is possibly 'null'.
          currentRoles?.splice(currentRoles.indexOf(guild), 1);
        }
      });
    }
    setSelectedCtm(uniqueCtm(value?.id, value?.podId));
    const ctmDefaultRoles = getCtmDefaultRoles(
      uniqueCtm(value?.id, value?.podId)
    );
    const autoUpdatedRoles = [...currentRoles];
    ctmDefaultRoles?.map((guild: any) => {
      // @ts-ignore: Object is possibly 'null'.
      if (!!allRoles.get(guild) && !selectedRoles.includes(guild)) {
        // @ts-ignore: Object is possibly 'null'.
        autoUpdatedRoles.push(guild);
      }
    });
    setSelectedRoles(autoUpdatedRoles);
    return event;
  };

  const saveUpdatedRoles = () => {
    const rolesNames: any = [];
    const updatedRoles: any = [];
    selectedRoles.forEach((role) => {
      rolesNames.push(allRoles.get(role).name);
      updatedRoles.push(allRoles.get(role));
    });
    // @ts-ignore: Object is possibly 'null'.
    updateRoles(episodeId, rolesNames)
      .then(({ status }) => {
        if (status === 200) {
          handleRolesUpdate(updatedRoles);
          setError(null);
        } else {
          // @ts-ignore: Object is possibly 'null'.
          setError("Unsuccessful roles update.");
        }
      })
      .catch(() => {
        // @ts-ignore: Object is possibly 'null'.
        setError("Sorry something went wrong.");
      });
  };

  const saveUpdatedOwner = () => {
    // @ts-ignore: Object is possibly 'null'.
    updateOwner(episodeId, {
      ownerId: allCtms.get(selectedCtm).id,
      podId: allCtms.get(selectedCtm).podId || null,
    })
      .then(({ status }) => {
        if (status === 200) {
          setError(null);
          // @ts-ignore: Object is possibly 'null'.
          handleOwnerChange(
            allCtms.get(selectedCtm).id
              ? allCtms.get(selectedCtm)
              : { name: null, id: null, podId: null }
          );
          queryClient.invalidateQueries(
            fetchEpisodeWorkspaceDraftQueryKey({ id: episodeId!! })
          );
        } else {
          // @ts-ignore: Object is possibly 'null'.
          setError("Unsuccessful owner update.");
        }
      })
      .catch(() => {
        // @ts-ignore: Object is possibly 'null'.
        setError("Sorry something went wrong.");
      });
  };

  const save = () => {
    setError(null);
    try {
      if (selectedRoles.length > 0) {
        saveUpdatedRoles();
      } else {
        showToast("At least one conversation role is required.", "danger");
        return;
      }

      if (allCtms.has(selectedCtm)) {
        saveUpdatedOwner();
      }

      if (!error) {
        showToast("Update successful", "success");
        toggle();
        resetEpisodeRoles(episodeId);
        setRoles([]);
        setSelectedRoles([]);
      } else {
        showToast(error!, "danger");
      }
    } catch {
      showToast(
        "Oops! something went wrong. Please try again later.",
        "danger"
      );
      return;
    }
  };

  const cancel = () => {
    resetEpisodeRoles(episodeId);
    setSelectedRoles([]);
    setRoles([]);
    toggle();
  };

  const renderInput = (params: any) => {
    return (
      <TextField
        {...params}
        data-testid="ctm-select-field"
        name="selectedCTM"
        className={classes.roleInputField}
        label="Select owner"
        fullWidth
        margin="dense"
        variant="filled"
      />
    );
  };

  return (
    <Dialog
      open={open}
      onClose={cancel}
      PaperComponent={PaperComponent}
      className={classes.dialog}
      ariaLabelledBy="conversation-ownership-title"
      data-testid="conversation-ownership-modal"
    >
      <Box paddingTop={3}>
        <DialogTitle
          id="conversation-ownership-title"
          data-e2e="eoc-owner-modal"
        >
          Conversation ownership
        </DialogTitle>
      </Box>
      {isPending ? (
        <SelectEpisodeOwnerSkeleton />
      ) : (
        <>
          <CardSection
            bottomDivider
            style={{ width: "25rem", padding: "0 0 20px 0" }}
          >
            <DialogContent className={classes.dialogContent}>
              <div>
                <Typography appearance="body">Roles</Typography>
                <TextField
                  name="checkbox-multiple-select"
                  data-testid="select-roles-input"
                  className={classes.roleInputField}
                  label="Select roles"
                  select
                  fullWidth
                  variant="filled"
                  SelectProps={{
                    MenuProps: {
                      // getContentAnchorEl: null,
                    },
                    multiple: true,
                    value: selectedRoles,
                    onChange: handleRoleChange,
                    renderValue: (selectedList: any) =>
                      (selectedList as any).join(", "),
                  }}
                >
                  {roles?.map((role: any) => {
                    return (
                      <MenuItem key={role.name} value={role.displayLabel}>
                        <Checkbox
                          // @ts-ignore: Object is possibly 'null'.
                          checked={selectedRoles?.includes(role.displayLabel)}
                          name={role.displayLabel}
                        />
                        <ListItemText primary={role.displayLabel} />
                      </MenuItem>
                    );
                  })}
                </TextField>
              </div>
              <Box marginTop={3}>
                <Typography appearance="body">
                  CTM <span className={classes.optionalTag}>(Optional)</span>
                </Typography>
                <ProviderComboBox
                  isPcpFirstNamedOption
                  data-testid="ctm-select-field"
                  unclaimedUser={unclaimedUser}
                  youUser={youUser}
                  ctmList={ctmList}
                  renderInput={renderInput}
                  onChange={handleCtmChange}
                  value={allCtms.get(selectedCtm)}
                />
                <Typography
                  appearance="caption"
                  className={classes.ownerChangeReminder}
                >
                  Reminder: The conversation will move to the owner's queue.
                </Typography>
              </Box>
            </DialogContent>
          </CardSection>
          <DialogActions horizontal className={classes.buttons}>
            <Button
              color="primary"
              onClick={() => save()}
              data-testid="save-button"
            >
              Save
            </Button>
            <Button
              color="link-secondary"
              data-testid="cancel-button"
              onClick={() => {
                cancel();
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default SelectEpisodeOwnerModal;
