import palette from "styles/mui/palette";

export const healthRecordsPlaceholderText = {
  labs: {
    ctm: `There are no test results to view at this time.`,
    member: `There are no test results to view at this time.`,
  },
  prescriptions: {
    ctm: `This Member does not have any active prescriptions.`,
    member: `You do not have any prescriptions on file with us. If you would like to request a prescription, please click on the “Request new prescription” button.`,
  },
  documents: {
    ctm: `There are no documents to display.`,
    member: `There are no documents to display.`,
  },
  consents: {
    ctm: `There are no signed consents on file at this time. Signed consents will appear here.`,
    member: `You haven’t signed any consents yet. Consents that you have signed will appear here.`,
  },
  visitSummaries: {
    ctm: `There are no visit summaries to display. Once a visit is completed they will be added here.`,
    member: `There are no visit summaries to display. Once a visit is completed they will be added here.`,
  },
};

export const medicationStateBadge = {
  refillable: {
    abbreviation: "AVAILABLE",
    color: palette.white,
    backgroundColor: palette.secondary.main,
  },
  requested: {
    abbreviation: "REQUESTED",
    color: palette.white,
    backgroundColor: palette.appBackground.darkGrey,
  },
};

export const addTokenToDownload = (
  src: string | undefined,
  accessToken: string
) => {
  if (!src) {
    return "";
  }
  const authorization = `?access_token=${accessToken}`;
  const source = `${src}${authorization}`;
  return source;
};

export const acceptedMimeTypes: string[] = [
  "image/gif",
  "image/png",
  "image/jpeg",
  "application/zip",
  "application/pdf",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/x-iwork-keynote-sffkey",
  "application/vnd.apple.keynote",
  "application/x-iwork-numbers-sffnumbers",
  "application/vnd.apple.numbers",
  "application/x-iwork-pages-sffpages",
  "application/vnd.apple.pages",
];

export const replaceSpecialCharacters = (name: string) => {
  const specialChars = /[{}\\|!?'"<>=`$#%]/gm;
  return name.replace(specialChars, "-");
};
