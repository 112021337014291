// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OutboundScreeningObjectSummary,
    OutboundScreeningObjectSummaryFromJSON,
    OutboundScreeningObjectSummaryToJSON,
} from './OutboundScreeningObjectSummary';


/**
 * 
 * @export
 * @interface OutboundScreeningsListResponse
 */
export interface OutboundScreeningsListResponse  {
    /**
     * 
     * @type {Array<OutboundScreeningObjectSummary>}
     * @memberof OutboundScreeningsListResponse
     */
    results?: Array<OutboundScreeningObjectSummary>;
}

export function OutboundScreeningsListResponseFromJSON(json: any): OutboundScreeningsListResponse {
    return {
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(OutboundScreeningObjectSummaryFromJSON),
    };
}

export function OutboundScreeningsListResponseToJSON(value?: OutboundScreeningsListResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(OutboundScreeningObjectSummaryToJSON),
    };
}


