// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PodPCPs
 */
export interface PodPCPs  {
    /**
     * 
     * @type {string}
     * @memberof PodPCPs
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof PodPCPs
     */
    pcpEligible?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PodPCPs
     */
    isPcp?: boolean;
    /**
     * Must match the \"staff_global_id\" in the users table
     * @type {string}
     * @memberof PodPCPs
     */
    globalId?: string;
}

export function PodPCPsFromJSON(json: any): PodPCPs {
    return {
        'name': json['name'],
        'pcpEligible': !exists(json, 'pcp_eligible') ? undefined : json['pcp_eligible'],
        'isPcp': !exists(json, 'is_pcp') ? undefined : json['is_pcp'],
        'globalId': !exists(json, 'global_id') ? undefined : json['global_id'],
    };
}

export function PodPCPsToJSON(value?: PodPCPs): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'pcp_eligible': value.pcpEligible,
        'is_pcp': value.isPcp,
        'global_id': value.globalId,
    };
}


