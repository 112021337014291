// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    AppointmentCancelationInfoResponse,
    AppointmentCancelationInfoResponseFromJSON,
    AppointmentCancelationInfoResponseToJSON,
} from '../models/AppointmentCancelationInfoResponse'
import {
    ComponentDetailsOutput,
    ComponentDetailsOutputFromJSON,
    ComponentDetailsOutputToJSON,
} from '../models/ComponentDetailsOutput'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    ScheduleAppointmentCore,
    ScheduleAppointmentCoreFromJSON,
    ScheduleAppointmentCoreToJSON,
} from '../models/ScheduleAppointmentCore'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpcomingAppointmentsResponse,
    UpcomingAppointmentsResponseFromJSON,
    UpcomingAppointmentsResponseToJSON,
} from '../models/UpcomingAppointmentsResponse'


export interface AppointmentCancellationInfoByIDRequest {
    id: string;
}

export interface ScheduleAppointmentOnSlotRequest {
    id: string;
    scheduleAppointmentCore?: ScheduleAppointmentCore;
}

export interface UserUpcomingAppointmentsListSummariesRequest {
    id: string;
}


/**
 * Fetch cancellation policy and information for the appointment
 */
function appointmentCancellationInfoByIDRaw<T>(requestParameters: AppointmentCancellationInfoByIDRequest, requestConfig: runtime.TypedQueryConfig<T, AppointmentCancelationInfoResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling appointmentCancellationInfoByID.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/appointments/{id}/cancellation_info`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(AppointmentCancelationInfoResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch cancellation policy and information for the appointment
*/
export function appointmentCancellationInfoByID<T>(requestParameters: AppointmentCancellationInfoByIDRequest, requestConfig?: runtime.TypedQueryConfig<T, AppointmentCancelationInfoResponse>): QueryConfig<T> {
    return appointmentCancellationInfoByIDRaw(requestParameters, requestConfig);
}

/**
 * Schedule an appointment to a slot
 * Schedule an appointment to a slot
 */
function scheduleAppointmentOnSlotRaw<T>(requestParameters: ScheduleAppointmentOnSlotRequest, requestConfig: runtime.TypedQueryConfig<T, ComponentDetailsOutput> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling scheduleAppointmentOnSlot.');
    }

    let queryParameters = null;


    const headerParameters = {};

    headerParameters['Content-Type'] = 'application/json';


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', []];
    const config: QueryConfig<T> = {
        url: `/v1/slots/{id}/appointment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'POST',
            headers: headerParameters,
        },
        body: queryParameters || ScheduleAppointmentCoreToJSON(requestParameters.scheduleAppointmentCore),
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ComponentDetailsOutputFromJSON(body), text);
    }

    return config;
}

/**
* Schedule an appointment to a slot
* Schedule an appointment to a slot
*/
export function scheduleAppointmentOnSlot<T>(requestParameters: ScheduleAppointmentOnSlotRequest, requestConfig?: runtime.TypedQueryConfig<T, ComponentDetailsOutput>): QueryConfig<T> {
    return scheduleAppointmentOnSlotRaw(requestParameters, requestConfig);
}

/**
 * Fetch upcoming appointments for the Member or CTM
 * User upcoming appointments summaries
 */
function userUpcomingAppointmentsListSummariesRaw<T>(requestParameters: UserUpcomingAppointmentsListSummariesRequest, requestConfig: runtime.TypedQueryConfig<T, UpcomingAppointmentsResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userUpcomingAppointmentsListSummaries.');
    }

    let queryParameters = null;


    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["appointments.read"]];
    const config: QueryConfig<T> = {
        url: `/v1/users/{id}/upcoming_appointments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(UpcomingAppointmentsResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch upcoming appointments for the Member or CTM
* User upcoming appointments summaries
*/
export function userUpcomingAppointmentsListSummaries<T>(requestParameters: UserUpcomingAppointmentsListSummariesRequest, requestConfig?: runtime.TypedQueryConfig<T, UpcomingAppointmentsResponse>): QueryConfig<T> {
    return userUpcomingAppointmentsListSummariesRaw(requestParameters, requestConfig);
}

