/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Consent,
    ConsentFromJSON,
    ConsentToJSON,
} from '../models/Consent'
import {
    CreateConsent,
    CreateConsentFromJSON,
    CreateConsentToJSON,
} from '../models/CreateConsent'
import {
    FetchConsentsConfigResponse,
    FetchConsentsConfigResponseFromJSON,
    FetchConsentsConfigResponseToJSON,
} from '../models/FetchConsentsConfigResponse'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    MemberRequiredConsentResponse,
    MemberRequiredConsentResponseFromJSON,
    MemberRequiredConsentResponseToJSON,
} from '../models/MemberRequiredConsentResponse'
import {
    MessageOfSuccessWithConsentName,
    MessageOfSuccessWithConsentNameFromJSON,
    MessageOfSuccessWithConsentNameToJSON,
} from '../models/MessageOfSuccessWithConsentName'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpdateConsent,
    UpdateConsentFromJSON,
    UpdateConsentToJSON,
} from '../models/UpdateConsent'
import {
    UserConsentsFetchResponse,
    UserConsentsFetchResponseFromJSON,
    UserConsentsFetchResponseToJSON,
} from '../models/UserConsentsFetchResponse'


export interface CreateConsentRequest {
    memberId: string;
    createConsent: CreateConsent;
}
export interface CreateConsentCounterRequest {
    name: string;
    signedDuring?: string;
}
export interface DeleteConsentRequest {
    memberId: string;
    id: string;
}
export interface FetchConsentsRequest {
    memberId: string;
    page?: string;
    signed?: boolean;
}
export interface GetMemberRequiredConsentsRequest {
    id: string;
    appointmentType: string;
    clinicId?: string;
    state?: string;
}
export interface UpdateConsentRequest {
    memberId: string;
    id: string;
    updateConsent: UpdateConsent;
}


export interface IConsentApi {
      createConsent: (requestParameters: CreateConsentRequest) => Promise<Consent>
      createConsentCounter: (requestParameters: CreateConsentCounterRequest) => Promise<MessageOfSuccessWithConsentName>
      deleteConsent: (requestParameters: DeleteConsentRequest) => Promise<void>
      fetchConsents: (requestParameters: FetchConsentsRequest) => Promise<UserConsentsFetchResponse>
      fetchConsentsConfig: () => Promise<FetchConsentsConfigResponse>
      getMemberRequiredConsents: (requestParameters: GetMemberRequiredConsentsRequest) => Promise<MemberRequiredConsentResponse>
      updateConsent: (requestParameters: UpdateConsentRequest) => Promise<Consent>
      createConsentWithResponse: (requestParameters: CreateConsentRequest) => Promise<{ value: Consent, response: any}>
      createConsentCounterWithResponse: (requestParameters: CreateConsentCounterRequest) => Promise<{ value: MessageOfSuccessWithConsentName, response: any}>
      deleteConsentWithResponse: (requestParameters: DeleteConsentRequest) => Promise<{ value: void, response: any}>
      fetchConsentsWithResponse: (requestParameters: FetchConsentsRequest) => Promise<{ value: UserConsentsFetchResponse, response: any}>
      fetchConsentsConfigWithResponse: () => Promise<{ value: FetchConsentsConfigResponse, response: any}>
      getMemberRequiredConsentsWithResponse: (requestParameters: GetMemberRequiredConsentsRequest) => Promise<{ value: MemberRequiredConsentResponse, response: any}>
      updateConsentWithResponse: (requestParameters: UpdateConsentRequest) => Promise<{ value: Consent, response: any}>
}

/**
 * no description
 */
export class ConsentApi extends runtime.BaseAPI {

    /**
     * create member\'s consents
     * create member\'s consents
     */
    createConsentRaw = async (requestParameters: CreateConsentRequest): Promise<{ runtime: runtime.ApiResponse<Consent>, response: any}> => {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling createConsent.');
        }

                        if (requestParameters.createConsent === null || requestParameters.createConsent === undefined) {
            throw new runtime.RequiredError('createConsent','Required parameter requestParameters.createConsent was null or undefined when calling createConsent.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{member_id}/consents`.replace(`{${"member_id"}}`, encodeURIComponent(String(requestParameters.memberId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateConsentToJSON(requestParameters.createConsent),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ConsentFromJSON(jsonValue)), response };
    }

    /**
     * create member\'s consents
     * create member\'s consents
     */
    createConsent = async (requestParameters: CreateConsentRequest): Promise<Consent> => {
        const { runtime } = await this.createConsentRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * create member\'s consents
     * create member\'s consents
     */
    createConsentWithResponse = async (requestParameters: CreateConsentRequest): Promise<{ value: Consent, response: any}> => {
        const { runtime, response } = await this.createConsentRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * creates or returns a counter for a specific consent
     * creates or returns a counter for a specific consent
     */
    createConsentCounterRaw = async (requestParameters: CreateConsentCounterRequest): Promise<{ runtime: runtime.ApiResponse<MessageOfSuccessWithConsentName>, response: any}> => {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling createConsentCounter.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.signedDuring !== undefined) {
            queryParameters['signed_during'] = requestParameters.signedDuring;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/consents/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MessageOfSuccessWithConsentNameFromJSON(jsonValue)), response };
    }

    /**
     * creates or returns a counter for a specific consent
     * creates or returns a counter for a specific consent
     */
    createConsentCounter = async (requestParameters: CreateConsentCounterRequest): Promise<MessageOfSuccessWithConsentName> => {
        const { runtime } = await this.createConsentCounterRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * creates or returns a counter for a specific consent
     * creates or returns a counter for a specific consent
     */
    createConsentCounterWithResponse = async (requestParameters: CreateConsentCounterRequest): Promise<{ value: MessageOfSuccessWithConsentName, response: any}> => {
        const { runtime, response } = await this.createConsentCounterRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * deletes a consent
     * deletes a consent
     */
    deleteConsentRaw = async (requestParameters: DeleteConsentRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling deleteConsent.');
        }

                        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteConsent.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{member_id}/consents/{id}`.replace(`{${"member_id"}}`, encodeURIComponent(String(requestParameters.memberId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * deletes a consent
     * deletes a consent
     */
    deleteConsent = async (requestParameters: DeleteConsentRequest): Promise<void> => {
        const { runtime } = await this.deleteConsentRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * deletes a consent
     * deletes a consent
     */
    deleteConsentWithResponse = async (requestParameters: DeleteConsentRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.deleteConsentRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

    /**
     * fetch member\'s consents
     * fetch member\'s consents
     */
    fetchConsentsRaw = async (requestParameters: FetchConsentsRequest): Promise<{ runtime: runtime.ApiResponse<UserConsentsFetchResponse>, response: any}> => {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling fetchConsents.');
        }

                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.signed !== undefined) {
            queryParameters['signed'] = requestParameters.signed;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{member_id}/consents`.replace(`{${"member_id"}}`, encodeURIComponent(String(requestParameters.memberId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => UserConsentsFetchResponseFromJSON(jsonValue)), response };
    }

    /**
     * fetch member\'s consents
     * fetch member\'s consents
     */
    fetchConsents = async (requestParameters: FetchConsentsRequest): Promise<UserConsentsFetchResponse> => {
        const { runtime } = await this.fetchConsentsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * fetch member\'s consents
     * fetch member\'s consents
     */
    fetchConsentsWithResponse = async (requestParameters: FetchConsentsRequest): Promise<{ value: UserConsentsFetchResponse, response: any}> => {
        const { runtime, response } = await this.fetchConsentsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * fetch consents config
     * fetch consents config
     */
    fetchConsentsConfigRaw = async (): Promise<{ runtime: runtime.ApiResponse<FetchConsentsConfigResponse>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/consents/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => FetchConsentsConfigResponseFromJSON(jsonValue)), response };
    }

    /**
     * fetch consents config
     * fetch consents config
     */
    fetchConsentsConfig = async (): Promise<FetchConsentsConfigResponse> => {
        const { runtime } = await this.fetchConsentsConfigRaw();
        return await runtime.value();
    }

    /**
     * fetch consents config
     * fetch consents config
     */
    fetchConsentsConfigWithResponse = async (): Promise<{ value: FetchConsentsConfigResponse, response: any}> => {
        const { runtime, response } = await this.fetchConsentsConfigRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch required consents by appointment type and clinic if present
     * Fetch required consents a member
     */
    getMemberRequiredConsentsRaw = async (requestParameters: GetMemberRequiredConsentsRequest): Promise<{ runtime: runtime.ApiResponse<MemberRequiredConsentResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getMemberRequiredConsents.');
        }

                        if (requestParameters.appointmentType === null || requestParameters.appointmentType === undefined) {
            throw new runtime.RequiredError('appointmentType','Required parameter requestParameters.appointmentType was null or undefined when calling getMemberRequiredConsents.');
        }

                                
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.appointmentType !== undefined) {
            queryParameters['appointment_type'] = requestParameters.appointmentType;
        }

        if (requestParameters.clinicId !== undefined) {
            queryParameters['clinic_id'] = requestParameters.clinicId;
        }

        if (requestParameters.state !== undefined) {
            queryParameters['state'] = requestParameters.state;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/required_consents`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberRequiredConsentResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch required consents by appointment type and clinic if present
     * Fetch required consents a member
     */
    getMemberRequiredConsents = async (requestParameters: GetMemberRequiredConsentsRequest): Promise<MemberRequiredConsentResponse> => {
        const { runtime } = await this.getMemberRequiredConsentsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch required consents by appointment type and clinic if present
     * Fetch required consents a member
     */
    getMemberRequiredConsentsWithResponse = async (requestParameters: GetMemberRequiredConsentsRequest): Promise<{ value: MemberRequiredConsentResponse, response: any}> => {
        const { runtime, response } = await this.getMemberRequiredConsentsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * update a consent
     * update a consent
     */
    updateConsentRaw = async (requestParameters: UpdateConsentRequest): Promise<{ runtime: runtime.ApiResponse<Consent>, response: any}> => {
        if (requestParameters.memberId === null || requestParameters.memberId === undefined) {
            throw new runtime.RequiredError('memberId','Required parameter requestParameters.memberId was null or undefined when calling updateConsent.');
        }

                        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateConsent.');
        }

                        if (requestParameters.updateConsent === null || requestParameters.updateConsent === undefined) {
            throw new runtime.RequiredError('updateConsent','Required parameter requestParameters.updateConsent was null or undefined when calling updateConsent.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{member_id}/consents/{id}`.replace(`{${"member_id"}}`, encodeURIComponent(String(requestParameters.memberId))).replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateConsentToJSON(requestParameters.updateConsent),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ConsentFromJSON(jsonValue)), response };
    }

    /**
     * update a consent
     * update a consent
     */
    updateConsent = async (requestParameters: UpdateConsentRequest): Promise<Consent> => {
        const { runtime } = await this.updateConsentRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * update a consent
     * update a consent
     */
    updateConsentWithResponse = async (requestParameters: UpdateConsentRequest): Promise<{ value: Consent, response: any}> => {
        const { runtime, response } = await this.updateConsentRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchConsentApi: IConsentApi  = new ConsentApi();
