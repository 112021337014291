/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    ImportSlataRequestBody,
    ImportSlataRequestBodyFromJSON,
    ImportSlataRequestBodyToJSON,
} from '../models/ImportSlataRequestBody'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    SheetNames,
    SheetNamesFromJSON,
    SheetNamesToJSON,
} from '../models/SheetNames'
import {
    SlataImportLogSchema,
    SlataImportLogSchemaFromJSON,
    SlataImportLogSchemaToJSON,
} from '../models/SlataImportLogSchema'
import {
    SlataImportLogsResponse,
    SlataImportLogsResponseFromJSON,
    SlataImportLogsResponseToJSON,
} from '../models/SlataImportLogsResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface FetchSheetNamesRequest {
    includeHidden?: boolean;
}
export interface FetchSlataImportLogsRequest {
    sheetName?: string;
}
export interface ImportSlataRequest {
    importSlataRequestBody: ImportSlataRequestBody;
}


export interface ISlataApi {
      fetchSheetNames: (requestParameters: FetchSheetNamesRequest) => Promise<SheetNames>
      fetchSlataImportLogs: (requestParameters: FetchSlataImportLogsRequest) => Promise<SlataImportLogsResponse>
      importSlata: (requestParameters: ImportSlataRequest) => Promise<SlataImportLogSchema>
      fetchSheetNamesWithResponse: (requestParameters: FetchSheetNamesRequest) => Promise<{ value: SheetNames, response: any}>
      fetchSlataImportLogsWithResponse: (requestParameters: FetchSlataImportLogsRequest) => Promise<{ value: SlataImportLogsResponse, response: any}>
      importSlataWithResponse: (requestParameters: ImportSlataRequest) => Promise<{ value: SlataImportLogSchema, response: any}>
}

/**
 * no description
 */
export class SlataApi extends runtime.BaseAPI {

    /**
     * Fetch all the names of the sheets in the slata spreadsheet
     * Fetch sheet names from slata spreadsheet
     */
    fetchSheetNamesRaw = async (requestParameters: FetchSheetNamesRequest): Promise<{ runtime: runtime.ApiResponse<SheetNames>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.includeHidden !== undefined) {
            queryParameters['include_hidden'] = requestParameters.includeHidden;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/slata/sheet_names`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => SheetNamesFromJSON(jsonValue)), response };
    }

    /**
     * Fetch all the names of the sheets in the slata spreadsheet
     * Fetch sheet names from slata spreadsheet
     */
    fetchSheetNames = async (requestParameters: FetchSheetNamesRequest): Promise<SheetNames> => {
        const { runtime } = await this.fetchSheetNamesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch all the names of the sheets in the slata spreadsheet
     * Fetch sheet names from slata spreadsheet
     */
    fetchSheetNamesWithResponse = async (requestParameters: FetchSheetNamesRequest): Promise<{ value: SheetNames, response: any}> => {
        const { runtime, response } = await this.fetchSheetNamesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch Slata Import Logs
     * Fetch Slata Import Logs
     */
    fetchSlataImportLogsRaw = async (requestParameters: FetchSlataImportLogsRequest): Promise<{ runtime: runtime.ApiResponse<SlataImportLogsResponse>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.sheetName !== undefined) {
            queryParameters['sheet_name'] = requestParameters.sheetName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/slata/import_logs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => SlataImportLogsResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch Slata Import Logs
     * Fetch Slata Import Logs
     */
    fetchSlataImportLogs = async (requestParameters: FetchSlataImportLogsRequest): Promise<SlataImportLogsResponse> => {
        const { runtime } = await this.fetchSlataImportLogsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch Slata Import Logs
     * Fetch Slata Import Logs
     */
    fetchSlataImportLogsWithResponse = async (requestParameters: FetchSlataImportLogsRequest): Promise<{ value: SlataImportLogsResponse, response: any}> => {
        const { runtime, response } = await this.fetchSlataImportLogsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Import slata for sheet names provided
     * Imports slata given the sheet names
     */
    importSlataRaw = async (requestParameters: ImportSlataRequest): Promise<{ runtime: runtime.ApiResponse<SlataImportLogSchema>, response: any}> => {
        if (requestParameters.importSlataRequestBody === null || requestParameters.importSlataRequestBody === undefined) {
            throw new runtime.RequiredError('importSlataRequestBody','Required parameter requestParameters.importSlataRequestBody was null or undefined when calling importSlata.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/slata/import_slata`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ImportSlataRequestBodyToJSON(requestParameters.importSlataRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => SlataImportLogSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Import slata for sheet names provided
     * Imports slata given the sheet names
     */
    importSlata = async (requestParameters: ImportSlataRequest): Promise<SlataImportLogSchema> => {
        const { runtime } = await this.importSlataRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Import slata for sheet names provided
     * Imports slata given the sheet names
     */
    importSlataWithResponse = async (requestParameters: ImportSlataRequest): Promise<{ value: SlataImportLogSchema, response: any}> => {
        const { runtime, response } = await this.importSlataRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchSlataApi: ISlataApi  = new SlataApi();
