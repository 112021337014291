import { useMutation, useQueryClient } from "react-query";
import { memberPaymentHistoryInfoQueryKey } from "./useGetPaymentsHistory";
import { PostPayNowRequest, fetchMemberPaymentsApi } from "shared/fetch/src/apis/MemberPaymentsApi";

const usePostPayNow = (id: string) => {
  const queryClient = useQueryClient();

  const { mutateAsync, isLoading } = useMutation(
    (requestParams: PostPayNowRequest) => {
      return fetchMemberPaymentsApi.postPayNow(requestParams).finally(() =>
        queryClient.invalidateQueries(
          memberPaymentHistoryInfoQueryKey({
            id,
          })
        )
      );
    }
  );

  return {
    postPayNow: mutateAsync,
    isPayNowLoading: isLoading,
  };
};

export default usePostPayNow;
