import React, { FunctionComponent } from "react";
import { Theme, TypographyProps, useTheme } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import Box from "components/Box";
import Typography from "components/Typography";

interface IProps extends TypographyProps {
  statusInfo: string;
  isArchived?: boolean;
  isBilled?: boolean;
  containerStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
}

interface IStyleProps {
  isArchived: boolean;
  bgArchive: string;
}

const useStyles = makeStyles(({ spacing }) => ({
  root: (props: IStyleProps) => ({
    backgroundColor: props.bgArchive ? props.bgArchive : "#1C74AB",
    marginRight: spacing(1),
    marginLeft: spacing(1),
    padding: spacing(1 / 4),
    paddingLeft: spacing(1 / 2),
    paddingRight: spacing(1 / 2),
    color: "white",
    borderRadius: spacing(1 / 4),
    width: "max-content",
  }),
}));

const StatusBadge: FunctionComponent<IProps> = ({
  statusInfo,
  isArchived,
  isBilled,
  containerStyle,
  labelStyle,
  ...rest
}) => {
  const { palette } = useTheme<Theme>();
  const bgArchive =
    isArchived && (isBilled ? palette?.accent?.main : palette.text.primary);
  // @ts-ignore: Object is possibly 'null'.
  const classes = useStyles({ isArchived, bgArchive });
  return (
    <Box
      className={classes.root}
      style={containerStyle}
      data-testid="to-do-status"
    >
      <Typography
        appearance="caption"
        style={labelStyle}
        {...rest}
        noWrap
        data-e2e="status-badge"
      >
        {isArchived && (isBilled ? "Billed" : "Archived")}
        {!isArchived && statusInfo}
      </Typography>
    </Box>
  );
};

export default StatusBadge;
