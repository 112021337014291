// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InsuranceCoverageDetailsInsurancesInsurance1Field1,
    InsuranceCoverageDetailsInsurancesInsurance1Field1FromJSON,
    InsuranceCoverageDetailsInsurancesInsurance1Field1ToJSON,
} from './InsuranceCoverageDetailsInsurancesInsurance1Field1';


/**
 * 
 * @export
 * @interface InsuranceCoverageDetailsInsurancesInsurance1
 */
export interface InsuranceCoverageDetailsInsurancesInsurance1  {
    /**
     * 
     * @type {string}
     * @memberof InsuranceCoverageDetailsInsurancesInsurance1
     */
    label?: string;
    /**
     * 
     * @type {InsuranceCoverageDetailsInsurancesInsurance1Field1}
     * @memberof InsuranceCoverageDetailsInsurancesInsurance1
     */
    field1?: InsuranceCoverageDetailsInsurancesInsurance1Field1;
    /**
     * 
     * @type {InsuranceCoverageDetailsInsurancesInsurance1Field1}
     * @memberof InsuranceCoverageDetailsInsurancesInsurance1
     */
    field2?: InsuranceCoverageDetailsInsurancesInsurance1Field1;
    /**
     * 
     * @type {InsuranceCoverageDetailsInsurancesInsurance1Field1}
     * @memberof InsuranceCoverageDetailsInsurancesInsurance1
     */
    field3?: InsuranceCoverageDetailsInsurancesInsurance1Field1;
}

export function InsuranceCoverageDetailsInsurancesInsurance1FromJSON(json: any): InsuranceCoverageDetailsInsurancesInsurance1 {
    return {
        'label': !exists(json, 'label') ? undefined : json['label'],
        'field1': !exists(json, 'field1') ? undefined : InsuranceCoverageDetailsInsurancesInsurance1Field1FromJSON(json['field1']),
        'field2': !exists(json, 'field2') ? undefined : InsuranceCoverageDetailsInsurancesInsurance1Field1FromJSON(json['field2']),
        'field3': !exists(json, 'field3') ? undefined : InsuranceCoverageDetailsInsurancesInsurance1Field1FromJSON(json['field3']),
    };
}

export function InsuranceCoverageDetailsInsurancesInsurance1ToJSON(value?: InsuranceCoverageDetailsInsurancesInsurance1): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'label': value.label,
        'field1': InsuranceCoverageDetailsInsurancesInsurance1Field1ToJSON(value.field1),
        'field2': InsuranceCoverageDetailsInsurancesInsurance1Field1ToJSON(value.field2),
        'field3': InsuranceCoverageDetailsInsurancesInsurance1Field1ToJSON(value.field3),
    };
}


