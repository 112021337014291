// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailableTimeslotsResponseSlots,
    AvailableTimeslotsResponseSlotsFromJSON,
    AvailableTimeslotsResponseSlotsToJSON,
} from './AvailableTimeslotsResponseSlots';


/**
 * 
 * @export
 * @interface AvailableTimeslotsResponseResults
 */
export interface AvailableTimeslotsResponseResults  {
    /**
     * 
     * @type {string}
     * @memberof AvailableTimeslotsResponseResults
     */
    tzDate?: string;
    /**
     * 
     * @type {AvailableTimeslotsResponseSlots}
     * @memberof AvailableTimeslotsResponseResults
     */
    slots?: AvailableTimeslotsResponseSlots;
}

export function AvailableTimeslotsResponseResultsFromJSON(json: any): AvailableTimeslotsResponseResults {
    return {
        'tzDate': !exists(json, 'tz_date') ? undefined : json['tz_date'],
        'slots': !exists(json, 'slots') ? undefined : AvailableTimeslotsResponseSlotsFromJSON(json['slots']),
    };
}

export function AvailableTimeslotsResponseResultsToJSON(value?: AvailableTimeslotsResponseResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'tz_date': value.tzDate,
        'slots': AvailableTimeslotsResponseSlotsToJSON(value.slots),
    };
}


