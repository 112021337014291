// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AdHocQuestionDetails
 */
export interface AdHocQuestionDetails  {
    /**
     * 
     * @type {number}
     * @memberof AdHocQuestionDetails
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof AdHocQuestionDetails
     */
    sqsQId?: string;
    /**
     * 
     * @type {string}
     * @memberof AdHocQuestionDetails
     */
    displayText?: string;
    /**
     * 
     * @type {string}
     * @memberof AdHocQuestionDetails
     */
    sex?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AdHocQuestionDetails
     */
    common?: boolean;
}

export function AdHocQuestionDetailsFromJSON(json: any): AdHocQuestionDetails {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sqsQId': !exists(json, 'sqs_q_id') ? undefined : json['sqs_q_id'],
        'displayText': !exists(json, 'display_text') ? undefined : json['display_text'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'common': !exists(json, 'common') ? undefined : json['common'],
    };
}

export function AdHocQuestionDetailsToJSON(value?: AdHocQuestionDetails): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'sqs_q_id': value.sqsQId,
        'display_text': value.displayText,
        'sex': value.sex,
        'common': value.common,
    };
}


