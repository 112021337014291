// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum RestrictedToEnum {
    VirtualVisitReferral = 'virtual_visit_referral',
    NotRestricted = 'not_restricted'
}

export function RestrictedToEnumFromJSON(json: any): RestrictedToEnum {
    return json as RestrictedToEnum;
}

export function RestrictedToEnumToJSON(value?: RestrictedToEnum): any {
    return value as any;
}

