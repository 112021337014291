import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import Skeleton from "components/Skeleton";
import Grid from "components/Grid";
import MobileTeamsSkeleton from "./MobileTeamsSkeleton";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

const RowSkeleton = () => <Skeleton height="30px" />;

const AvatarSkeleton = () => (
  <Grid item xs={12} md={2}>
    <Skeleton
      style={{
        display: "flex",
        alignSelf: "center",
        minHeight: "300px",
        marginTop: "-65px",
        width: "180px",
        borderRadius: "50%",
        textAlign: "center",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    />
    <Skeleton
      height="40px"
      style={{
        display: "flex",
        alignSelf: "center",
        width: "200px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "-40px",
      }}
    />
    <Skeleton
      height="40px"
      style={{
        display: "flex",
        alignSelf: "center",
        width: "200px",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "5px",
      }}
    />
  </Grid>
);

const AllTeamsSkeleton: React.FunctionComponent = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobileAndIpadScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <div className={classes.root}>
      {isMobileAndIpadScreen ? (
        <MobileTeamsSkeleton />
      ) : (
        <>
          <Grid container spacing={2} justifyContent="center">
            <Skeleton
              height="10px"
              width="40%"
              style={{ borderRadius: "20px", margin: "2% 0.2%" }}
            />
            <Skeleton
              height="10px"
              width="40%"
              style={{ borderRadius: "20px", margin: "2% 0.2%" }}
            />
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={2}></Grid>
            <Grid item xs={4}>
              <RowSkeleton />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}>
              <RowSkeleton />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={2}></Grid>
            <Skeleton
              height="10px"
              width="12%"
              style={{ margin: "20px 10px" }}
            />
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={2}></Grid>
            <Grid item xs={1}>
              <Skeleton
                height="50px"
                width="80%"
                style={{
                  borderRadius: "20px",
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Skeleton
                height="50px"
                width="100%"
                style={{
                  borderRadius: "20px",
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Skeleton
                height="50px"
                width="100%"
                style={{
                  borderRadius: "20px",
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Skeleton
                height="50px"
                width="100%"
                style={{
                  borderRadius: "20px",
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Skeleton
                height="50px"
                width="100%"
                style={{
                  borderRadius: "20px",
                }}
              />
            </Grid>
            <Grid item xs={1}>
              <Skeleton
                height="50px"
                width="100%"
                style={{
                  borderRadius: "20px",
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={2}></Grid>
            <AvatarSkeleton />
            <Grid item xs={12} md={1}></Grid>
            <AvatarSkeleton />
            <Grid item xs={12} md={1}></Grid>
            <AvatarSkeleton />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}></Grid>
            <AvatarSkeleton />
            <Grid item xs={12} md={1}></Grid>
            <AvatarSkeleton />
            <Grid item xs={12} md={1}></Grid>
            <AvatarSkeleton />
          </Grid>
          <Grid container spacing={2} justifyContent="center">
            <Skeleton height="30px" width="50px" />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={2}></Grid>
            <Grid item xs={4}>
              <Skeleton height="30px" width="70%" />
              <Skeleton height="30px" width="100%" />
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={2} style={{ marginBottom: "50px" }}>
              <RowSkeleton />
              <RowSkeleton />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default AllTeamsSkeleton;
