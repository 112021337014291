import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { State } from "@types";
import useEnrollDeviceBiometrics from "shared/features/devices/useEnrollDeviceBiometrics";
import useUnenrollDeviceBiometrics from "shared/features/devices/useUnenrollDeviceBiometrics";
import { selectNativeAppState } from "shared/state/nativeApp/selectors";
import NativeApp from "models/NativeApp";
import { selectLoggedInUser } from "shared/features/user/selectors";

export const BIOMETRIC_LOGIN_ENABLED = "enabled";
export const BIOMETRIC_LOGIN_DECLINED = "declined";

const BiometricEnrollment: React.FunctionComponent = () => {
  const isLoggedIn = useSelector((state: State) => !!state.auth.refreshToken);
  const user = selectLoggedInUser();
  const { enrollDeviceBiometrics } = useEnrollDeviceBiometrics(
    user?.id?.toString()!
  );
  const { unenrollDeviceBiometrics } = useUnenrollDeviceBiometrics();
  const { isApp } = selectNativeAppState();

  const enrollBiometricsEventHandler = (e: any) => {
    const deviceId: string = e.detail.deviceId;
    const deviceName: string = e.detail.deviceName;
    const publicKey: string = e.detail.publicKey;
    enrollDeviceBiometrics({
      enrollBiometricsRequestBody: {
        deviceId,
        deviceName,
        publicKey,
      },
    });
  };

  const unenrollBiometricsEventHandler = (e: any) => {
    const deviceId: string = e.detail.deviceId;
    unenrollDeviceBiometrics({
      unenrollBiometicsRequestBody: {
        deviceId,
      },
    });
  };

  useEffect(() => {
    window.addEventListener("enrollBiometrics", enrollBiometricsEventHandler);
    return () => {
      window.removeEventListener(
        "enrollBiometrics",
        enrollBiometricsEventHandler
      );
    };
  }, []);

  useEffect(() => {
    window.addEventListener(
      "unenrollBiometrics",
      unenrollBiometricsEventHandler
    );
    return () => {
      window.removeEventListener(
        "unenrollBiometrics",
        unenrollBiometricsEventHandler
      );
    };
  }, []);

  useEffect(() => {
    if (isLoggedIn && isApp) {
      NativeApp.biometricRegistration();
    }
  }, [isLoggedIn, isApp]);

  return null;
};
export default BiometricEnrollment;
