// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateConsent
 */
export interface UpdateConsent  {
    /**
     * 
     * @type {string}
     * @memberof UpdateConsent
     */
    configCode?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateConsent
     */
    siteCode?: string;
}

export function UpdateConsentFromJSON(json: any): UpdateConsent {
    return {
        'configCode': !exists(json, 'config_code') ? undefined : json['config_code'],
        'siteCode': !exists(json, 'site_code') ? undefined : json['site_code'],
    };
}

export function UpdateConsentToJSON(value?: UpdateConsent): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'config_code': value.configCode,
        'site_code': value.siteCode,
    };
}


