import useFormatDate from "shared/utils/useFormatDate";


import getNotificationMessage from "./getNotificationMessage";
import { useQuery } from "react-query";
import { FetchCTMHeadsUpRequest, fetchHeadsUpApi } from "../../fetch/src/apis/HeadsUpApi";
import { HeadsUpNotification, HeadsUpNotificationStateEnum } from "shared/fetch/src/models/HeadsUpNotification";

const headsUpNotificationsQueryKey = (
  id: string | number,
  page?: string | number
) => ["headsUpNotifications", id.toString(), page?.toString()];

export default (request: FetchCTMHeadsUpRequest) => {
  const formatDate = useFormatDate();
  const { isLoading: isPending, data: headsUpNotifications } = useQuery(
    headsUpNotificationsQueryKey(request.id, request.page),
    () => fetchHeadsUpApi.fetchCTMHeadsUp(request),
    {
      keepPreviousData: true,
      refetchInterval: 10000,
      enabled: !!request.id,
    }
  );

  const hasBadge = headsUpNotifications?.results?.some(
    (notification: HeadsUpNotification) =>
      notification.state === HeadsUpNotificationStateEnum.Active
  );
  const messages = headsUpNotifications?.results?.map((item) =>
    getNotificationMessage(item, formatDate)
  );

  return {
    isPending,
    messages,
    totalCount: headsUpNotifications?.totalCount || 0,
    unreadCount: headsUpNotifications?.unreadCount || 0,
    hasBadge,
  };
};
