import React from "react";
import { GetMyQueueOrderEnum } from "shared/fetch/src/apis/EpisodeListsApi";

const sortOptionValues = {
  ASC: { label: "Oldest", value: GetMyQueueOrderEnum.ASC },
  DESC: { label: "Newest", value: GetMyQueueOrderEnum.DESC },
};

export default (order: GetMyQueueOrderEnum) => {

  const [sortOrder, setSortOrder] = React.useState<GetMyQueueOrderEnum>(order);

  const onSortOrderChange = (option: string) => {
    setSortOrder(sortOptionValues[option]?.value);
  };

  const sortOrderLabel = sortOptionValues[sortOrder]?.label;

  return { sortOrder, sortOrderLabel, onSortOrderChange };
}
