// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SourceQuestionSetSchema,
    SourceQuestionSetSchemaFromJSON,
    SourceQuestionSetSchemaToJSON,
} from './SourceQuestionSetSchema';
import {
    XopQuestionSchema,
    XopQuestionSchemaFromJSON,
    XopQuestionSchemaToJSON,
} from './XopQuestionSchema';


/**
 * 
 * @export
 * @interface QuestionSetJsonBodySchema
 */
export interface QuestionSetJsonBodySchema  {
    /**
     * 
     * @type {{ [key: string]: SourceQuestionSetSchema; }}
     * @memberof QuestionSetJsonBodySchema
     */
    sourceQuestionSets?: { [key: string]: SourceQuestionSetSchema; };
    /**
     * 
     * @type {Array<XopQuestionSchema>}
     * @memberof QuestionSetJsonBodySchema
     */
    questions?: Array<XopQuestionSchema>;
    /**
     * 
     * @type {boolean}
     * @memberof QuestionSetJsonBodySchema
     */
    custom?: boolean;
    /**
     * 
     * @type {number}
     * @memberof QuestionSetJsonBodySchema
     */
    count?: number;
}

export function QuestionSetJsonBodySchemaFromJSON(json: any): QuestionSetJsonBodySchema {
    return {
        'sourceQuestionSets': !exists(json, 'source_question_sets') ? undefined : mapValues(json['source_question_sets'], SourceQuestionSetSchemaFromJSON),
        'questions': !exists(json, 'questions') ? undefined : (json['questions'] as Array<any>).map(XopQuestionSchemaFromJSON),
        'custom': !exists(json, 'custom') ? undefined : json['custom'],
        'count': !exists(json, 'count') ? undefined : json['count'],
    };
}

export function QuestionSetJsonBodySchemaToJSON(value?: QuestionSetJsonBodySchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'source_question_sets': value.sourceQuestionSets === undefined ? undefined : mapValues(value.sourceQuestionSets, SourceQuestionSetSchemaToJSON),
        'questions': value.questions === undefined ? undefined : (value.questions as Array<any>).map(XopQuestionSchemaToJSON),
        'custom': value.custom,
        'count': value.count,
    };
}


