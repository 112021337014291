// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AppointmentSuccessfullyAssignedResponse
 */
export interface AppointmentSuccessfullyAssignedResponse  {
    /**
     * 
     * @type {number}
     * @memberof AppointmentSuccessfullyAssignedResponse
     */
    episodeId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof AppointmentSuccessfullyAssignedResponse
     */
    created?: boolean;
}

export function AppointmentSuccessfullyAssignedResponseFromJSON(json: any): AppointmentSuccessfullyAssignedResponse {
    return {
        'episodeId': !exists(json, 'episode_id') ? undefined : json['episode_id'],
        'created': !exists(json, 'created') ? undefined : json['created'],
    };
}

export function AppointmentSuccessfullyAssignedResponseToJSON(value?: AppointmentSuccessfullyAssignedResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'episode_id': value.episodeId,
        'created': value.created,
    };
}


