/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    SamlConsumeSuccessPatientAccessGrantResponse,
    SamlConsumeSuccessPatientAccessGrantResponseFromJSON,
    SamlConsumeSuccessPatientAccessGrantResponseToJSON,
} from '../models/SamlConsumeSuccessPatientAccessGrantResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface SamlCompleteSignupRequest {
    payload: string;
    idp: string;
    email: string;
    password: string;
}
export interface SamlConsumeRequest {
    idp: string;
    sAMLResponse: string;
}


export interface ISamlApi {
      samlCompleteSignup: (requestParameters: SamlCompleteSignupRequest) => Promise<SamlConsumeSuccessPatientAccessGrantResponse>
      samlConsume: (requestParameters: SamlConsumeRequest) => Promise<SamlConsumeSuccessPatientAccessGrantResponse>
      samlCompleteSignupWithResponse: (requestParameters: SamlCompleteSignupRequest) => Promise<{ value: SamlConsumeSuccessPatientAccessGrantResponse, response: any}>
      samlConsumeWithResponse: (requestParameters: SamlConsumeRequest) => Promise<{ value: SamlConsumeSuccessPatientAccessGrantResponse, response: any}>
}

/**
 * no description
 */
export class SamlApi extends runtime.BaseAPI {

    /**
     * Completes the signup process for a user
     * SAML complete signup
     */
    samlCompleteSignupRaw = async (requestParameters: SamlCompleteSignupRequest): Promise<{ runtime: runtime.ApiResponse<SamlConsumeSuccessPatientAccessGrantResponse>, response: any}> => {
        if (requestParameters.payload === null || requestParameters.payload === undefined) {
            throw new runtime.RequiredError('payload','Required parameter requestParameters.payload was null or undefined when calling samlCompleteSignup.');
        }

                        if (requestParameters.idp === null || requestParameters.idp === undefined) {
            throw new runtime.RequiredError('idp','Required parameter requestParameters.idp was null or undefined when calling samlCompleteSignup.');
        }

                        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling samlCompleteSignup.');
        }

                        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling samlCompleteSignup.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.payload !== undefined) {
            formParams.append('payload', requestParameters.payload as any);
        }

        if (requestParameters.idp !== undefined) {
            formParams.append('idp', requestParameters.idp as any);
        }

        if (requestParameters.email !== undefined) {
            formParams.append('email', requestParameters.email as any);
        }

        if (requestParameters.password !== undefined) {
            formParams.append('password', requestParameters.password as any);
        }


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/saml/complete_signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => SamlConsumeSuccessPatientAccessGrantResponseFromJSON(jsonValue)), response };
    }

    /**
     * Completes the signup process for a user
     * SAML complete signup
     */
    samlCompleteSignup = async (requestParameters: SamlCompleteSignupRequest): Promise<SamlConsumeSuccessPatientAccessGrantResponse> => {
        const { runtime } = await this.samlCompleteSignupRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Completes the signup process for a user
     * SAML complete signup
     */
    samlCompleteSignupWithResponse = async (requestParameters: SamlCompleteSignupRequest): Promise<{ value: SamlConsumeSuccessPatientAccessGrantResponse, response: any}> => {
        const { runtime, response } = await this.samlCompleteSignupRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Valides SAML response and either redirects to the signup page or returns the oauth token
     * SAML consume
     */
    samlConsumeRaw = async (requestParameters: SamlConsumeRequest): Promise<{ runtime: runtime.ApiResponse<SamlConsumeSuccessPatientAccessGrantResponse>, response: any}> => {
        if (requestParameters.idp === null || requestParameters.idp === undefined) {
            throw new runtime.RequiredError('idp','Required parameter requestParameters.idp was null or undefined when calling samlConsume.');
        }

                        if (requestParameters.sAMLResponse === null || requestParameters.sAMLResponse === undefined) {
            throw new runtime.RequiredError('sAMLResponse','Required parameter requestParameters.sAMLResponse was null or undefined when calling samlConsume.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'application/x-www-form-urlencoded' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.sAMLResponse !== undefined) {
            formParams.append('SAMLResponse', requestParameters.sAMLResponse as any);
        }


        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/saml/{idp}/consume`.replace(`{${"idp"}}`, encodeURIComponent(String(requestParameters.idp))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => SamlConsumeSuccessPatientAccessGrantResponseFromJSON(jsonValue)), response };
    }

    /**
     * Valides SAML response and either redirects to the signup page or returns the oauth token
     * SAML consume
     */
    samlConsume = async (requestParameters: SamlConsumeRequest): Promise<SamlConsumeSuccessPatientAccessGrantResponse> => {
        const { runtime } = await this.samlConsumeRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Valides SAML response and either redirects to the signup page or returns the oauth token
     * SAML consume
     */
    samlConsumeWithResponse = async (requestParameters: SamlConsumeRequest): Promise<{ value: SamlConsumeSuccessPatientAccessGrantResponse, response: any}> => {
        const { runtime, response } = await this.samlConsumeRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchSamlApi: ISamlApi  = new SamlApi();
