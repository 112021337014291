// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProviderSummarySchema
 */
export interface ProviderSummarySchema  {
    /**
     * Name of the provider inclusive of title
     * @type {string}
     * @memberof ProviderSummarySchema
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderSummarySchema
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ProviderSummarySchema
     */
    staffGlobalId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProviderSummarySchema
     */
    guilds?: Array<string>;
    /**
     * Is the provider the member\'s pcp
     * @type {boolean}
     * @memberof ProviderSummarySchema
     */
    isPcp?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ProviderSummarySchema
     */
    avatarUrl?: string;
    /**
     * URL that will return the provider bio
     * @type {string}
     * @memberof ProviderSummarySchema
     */
    bioUrl?: string;
}

export function ProviderSummarySchemaFromJSON(json: any): ProviderSummarySchema {
    return {
        'name': json['name'],
        'id': json['id'],
        'staffGlobalId': !exists(json, 'staff_global_id') ? undefined : json['staff_global_id'],
        'guilds': !exists(json, 'guilds') ? undefined : json['guilds'],
        'isPcp': !exists(json, 'is_pcp') ? undefined : json['is_pcp'],
        'avatarUrl': !exists(json, 'avatar_url') ? undefined : json['avatar_url'],
        'bioUrl': !exists(json, 'bio_url') ? undefined : json['bio_url'],
    };
}

export function ProviderSummarySchemaToJSON(value?: ProviderSummarySchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'id': value.id,
        'staff_global_id': value.staffGlobalId,
        'guilds': value.guilds,
        'is_pcp': value.isPcp,
        'avatar_url': value.avatarUrl,
        'bio_url': value.bioUrl,
    };
}


