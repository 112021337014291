// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     MemberAccountInfo,
    MemberAccountInfoFromJSON,
    MemberAccountInfoToJSON,
} from './MemberAccountInfo';
import {
     MemberAccountInfoRequestBodyAllOf,
    MemberAccountInfoRequestBodyAllOfFromJSON,
    MemberAccountInfoRequestBodyAllOfToJSON,
} from './MemberAccountInfoRequestBodyAllOf';
/**
 * @type MemberAccountInfoRequestBody
 * @export
 */
export interface MemberAccountInfoRequestBody extends MemberAccountInfo, MemberAccountInfoRequestBodyAllOf {
}

export function MemberAccountInfoRequestBodyFromJSON(json: any): MemberAccountInfoRequestBody {
    return {
        ...MemberAccountInfoFromJSON(json),
        ...MemberAccountInfoRequestBodyAllOfFromJSON(json),
    };
}

export function MemberAccountInfoRequestBodyToJSON(value?: MemberAccountInfoRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...MemberAccountInfoToJSON(value),
        ...MemberAccountInfoRequestBodyAllOfToJSON(value),
    };
}

