// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DiscountCoupon
 */
export interface DiscountCoupon  {
    /**
     * 
     * @type {string}
     * @memberof DiscountCoupon
     */
    id?: string;
    /**
     * Amount (in the currency specified) that will be taken off the subtotal of any invoices for this customer.
     * @type {number}
     * @memberof DiscountCoupon
     */
    amountOff?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountCoupon
     */
    currency?: string;
    /**
     * Describes how long a customer who applies this coupon will get the discount.
     * @type {string}
     * @memberof DiscountCoupon
     */
    duration?: DiscountCouponDurationEnum;
    /**
     * 
     * @type {number}
     * @memberof DiscountCoupon
     */
    durationInMonths?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscountCoupon
     */
    name?: string;
    /**
     * Percent that will be taken off the subtotal of any invoices for this customer for the duration of the coupon.
     * @type {number}
     * @memberof DiscountCoupon
     */
    percentOff?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscountCoupon
     */
    timesRedeemed?: number;
}

export function DiscountCouponFromJSON(json: any): DiscountCoupon {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'amountOff': !exists(json, 'amount_off') ? undefined : json['amount_off'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'durationInMonths': !exists(json, 'duration_in_months') ? undefined : json['duration_in_months'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'percentOff': !exists(json, 'percent_off') ? undefined : json['percent_off'],
        'timesRedeemed': !exists(json, 'times_redeemed') ? undefined : json['times_redeemed'],
    };
}

export function DiscountCouponToJSON(value?: DiscountCoupon): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'amount_off': value.amountOff,
        'currency': value.currency,
        'duration': value.duration,
        'duration_in_months': value.durationInMonths,
        'name': value.name,
        'percent_off': value.percentOff,
        'times_redeemed': value.timesRedeemed,
    };
}

/**
* @export
* @enum {string}
*/
export enum DiscountCouponDurationEnum {
    Forever = 'forever',
    Once = 'once',
    Repeating = 'repeating'
}


