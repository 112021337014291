import React from "react";
import Skeleton from "components/Skeleton";
import Grid from "components/Grid";

const AvatarSkeleton = () => (
  <>
    <Grid item xs={3}>
      <Skeleton
        style={{
          minHeight: "100px",
          width: "60px",
          borderRadius: "50%",
          marginLeft: "35%",
        }}
      />
    </Grid>
    <Grid item xs={8}>
      <Skeleton
        style={{
          width: "80%",
          marginLeft: "15px",
          marginTop: "30px",
        }}
      />
      <Skeleton
        height="10px"
        style={{
          width: "70%",
          marginLeft: "15px",
        }}
      />
    </Grid>
  </>
);

const MobileTeamsSkeleton: React.FunctionComponent = () => {
  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        <Skeleton
          height="10px"
          width="49%"
          style={{ borderRadius: "20px", margin: "2% 0.2% 2% 0" }}
        />
        <Skeleton
          height="10px"
          width="49%"
          style={{ borderRadius: "20px", margin: "2% 0 2% 0.2%" }}
        />
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={6}>
          <Skeleton height="30px" width="100%" style={{ marginTop: "10px" }} />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Skeleton
          height="10px"
          width="30%"
          style={{ margin: "15px 0px 5px 8px" }}
        />
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid item xs={2}>
          <Skeleton
            height="50px"
            width="100%"
            style={{
              borderRadius: "20px",
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Skeleton
            height="50px"
            width="100%"
            style={{
              borderRadius: "20px",
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Skeleton
            height="50px"
            width="100%"
            style={{
              borderRadius: "20px",
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Skeleton
            height="50px"
            width="100%"
            style={{
              borderRadius: "20px",
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <Skeleton
            height="50px"
            width="100%"
            style={{
              borderRadius: "20px",
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <AvatarSkeleton />
        <Skeleton height="2px" width="100%" />
        <AvatarSkeleton />
        <Skeleton height="2px" width="100%" />
        <AvatarSkeleton />
      </Grid>
    </>
  );
};

export default MobileTeamsSkeleton;
