// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     PostPaymentMethodSchema,
    PostPaymentMethodSchemaFromJSON,
    PostPaymentMethodSchemaToJSON,
} from './PostPaymentMethodSchema';
/**
 * @type SetPaymentMethodSchema
 * @export
 */
export interface SetPaymentMethodSchema extends PostPaymentMethodSchema {
}

export function SetPaymentMethodSchemaFromJSON(json: any): SetPaymentMethodSchema {
    return {
        ...PostPaymentMethodSchemaFromJSON(json),
    };
}

export function SetPaymentMethodSchemaToJSON(value?: SetPaymentMethodSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...PostPaymentMethodSchemaToJSON(value),
    };
}

