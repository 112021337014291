import {
  differenceInYears,
  differenceInMonths,
  differenceInDays,
} from "date-fns";

export const calculateAge = (birthDate: Date) => {
  const today = new Date();
  return differenceInYears(today, birthDate);
};

export const checkIfMinorByAge = (birthDate: Date) => {
  const today = new Date();
  let isChild = false;
  let isMinor = false;
  let isTeen = false;

  const ageInYears = differenceInYears(today, birthDate);
  const ageInMonths = differenceInMonths(today, birthDate);
  const ageInDays = differenceInDays(today, birthDate);

  if (!!ageInYears) {
    isChild = ageInYears < 12;
    isMinor = ageInYears < 18;
    isTeen = ageInYears >= 12 && ageInYears < 18;
  }

  if (!ageInYears && !!ageInMonths) {
    isChild = true;
    isMinor = true;
  }

  if (!ageInYears && !ageInMonths && !!ageInDays) {
    isChild = true;
    isMinor = true;
  }

  return { isChild, isMinor, isTeen };
};

export const formatAge = (
  age: number,
  divider: number,
  ageSuffix1: string,
  ageSuffix2: string
) => {
  const age1 = Math.floor(age / divider);
  const age2 = age % divider;
  return `${age1 ? age1 + " " + ageSuffix1 : ""}${age1 && age2 ? ", " : ""}${
    age2 ? age2 + " " + ageSuffix2 : ""
  }`;
};

export const prepareAge = (age: string) => {
  const ageSplit = age.split(" ");
  if (ageSplit?.length > 1) {
    switch (ageSplit[1]) {
      case "years":
        return `${ageSplit[0]} yr`;
      case "months":
        return formatAge(parseInt(ageSplit[0], 10), 12, "yr", "mo");
      case "weeks":
        return formatAge(parseInt(ageSplit[0], 10), 4, "mo", "wk");
      case "days":
        return formatAge(parseInt(ageSplit[0], 10), 7, "wk", "d");
      case "day":
        return `${ageSplit[0]} d`;
      default:
        return "";
    }
  } else {
    return "";
  }
};
