import { useSelector } from "react-redux";
import { useRequest } from "redux-query-react";
import { getCmsMenuHierarchy } from "shared/api/src/apis/CmsItemsApi";
import { CmsNavigationSchemaHierarchy } from "shared/fetch/src/models/CmsNavigationSchemaHierarchy";

import { State } from "shared/types/state";

export const useGetCmsMenuHierarchy = () => {
  const cmsMenuHierarchyItems: CmsNavigationSchemaHierarchy[] | undefined =
    useSelector((state: State) => state.entities.cmsMenuHierarchyItems);

  const queryConfig = getCmsMenuHierarchy({
    transform: (body: any) => {
      return {
        cmsMenuHierarchyItems: body.hierarchy || [],
      };
    },
    update: {
      cmsMenuHierarchyItems: (
        prev: CmsNavigationSchemaHierarchy[],
        next: CmsNavigationSchemaHierarchy[]
      ) => next,
    },
  });
  const [{ isPending, queryCount }] = useRequest(queryConfig as any);

  return {
    cmsMenuHierarchyItems,
    isPending,
    queryCount,
  };
};
