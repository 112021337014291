import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { Theme, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import TodayIcon from "@mui/icons-material/Today";
import EmailIcon from "@mui/icons-material/Email";

import Box from "components/Box";
import Button from "components/Button";

import { eocRootPath } from "containers/Routing";
import { selectLoggedInUser } from "shared/features/user/selectors";
import MedicalServicesIcon from "components/MedicalServicesIcon";
import { selectFeatures } from "shared/features/featureFlags/selectors";
import useAccess, { useGetCurrentUserPermissions } from "hooks/useAccess";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";
import { useDispatch, useSelector } from "react-redux";
import { setInvokingElementRef } from "shared/state/ui/mobileComponentModal";
import { State } from "@types";
import { JsonUserAffinityEnum } from "shared/fetch/src/models/JsonUser";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    "& .MuiButton-label": {
      // otherwise on mobile the button label wraps to two lines
      whiteSpace: "nowrap",
    },
    marginRight: 8,
    fontSize: "13.3px",
    [theme.breakpoints.down('lg')]: {
      marginBottom: "10px",
    },
  },
  buttonContainer: {
    display: "flex",
    [theme.breakpoints.down('lg')]: {
      alignSelf: "flex-start",
    },
  },
}));

interface IAffinityCareMenuButtonsProps {
  affinity: JsonUserAffinityEnum;
}

export default function AffinityCareMenuButtons({
  affinity,
}: IAffinityCareMenuButtonsProps) {
  const classes = useStyles({});
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = selectLoggedInUser() || {};
  const hasSelfSchedule = selectFeatures().hasSelfSchedule();
  const hasDirectMessage = selectFeatures().hasDirectMessaging();
  const invokingElementId = useSelector(
    (state: State) => state.ui.mobileComponentModal?.invokingElementId
  );

  const isMediumScreenOrSmaller = useMediaQuery(theme.breakpoints.down('lg'));
  const currentUserPermissions = useGetCurrentUserPermissions();
  const hasSelfScheduleAccessOnly =
    useAccess({ to: PermissionsEnum.SelfScheduleCreate }) &&
    !useAccess({ to: PermissionsEnum.EpisodesCreate });

  useEffect(() => {
    if (invokingElementId) {
      const button = document.getElementById(invokingElementId);
      button?.focus();
    }
  }, [invokingElementId]);

  const getDrawerMenuOptions = (userAffinity: JsonUserAffinityEnum) => {
    const scheduleAVisitButton = ([
      JsonUserAffinityEnum.Onsite,
      JsonUserAffinityEnum.Nearsite,
      JsonUserAffinityEnum.InpersonWithoutTravel,
    ].includes(userAffinity) ||
      hasSelfScheduleAccessOnly) &&
      hasSelfSchedule && {
        title: "Schedule a visit",
        linkTo: `/members/${user?.id}/self-schedule`,
        icon: <TodayIcon />,
        permission: PermissionsEnum.SelfScheduleCreate,
      };
    const startCareNowButton = [
      JsonUserAffinityEnum.Remote,
      JsonUserAffinityEnum.Nearsite,
    ].includes(userAffinity) && {
      title: "Start care now",
      linkTo: eocRootPath,
      icon: <MedicalServicesIcon />,
      permission: PermissionsEnum.EpisodesCreate,
    };
    const directMessageButton = hasDirectMessage && {
      title: "Direct message",
      linkTo: `/direct-messaging?id=${user?.id}`,
      icon: <EmailIcon />,
      permission: PermissionsEnum.EpisodesCreate,
    };

    return [directMessageButton, scheduleAVisitButton, startCareNowButton];
  };

  const buttonsInOrder = getDrawerMenuOptions(affinity).filter(
    (button) =>
      button &&
      (!button?.permission ||
        currentUserPermissions.includes(button?.permission))
  );

  const handleClickandFocus = (to: string, index: number) => {
    const Id = `Affinity-btn-${index}`;
    dispatch(setInvokingElementRef({ invokingElementId: Id }));
    navigate(to);
  };

  const hasThreeButtons =
    buttonsInOrder.filter((button) => button).length === 3;

  const primaryButtonIndex = buttonsInOrder.length - 1;

  return (
    <Box className={classes.buttonContainer}>
      {buttonsInOrder?.map(
        (option, index) =>
          option && (
            <Button
              id={`Affinity-btn-${index}`}
              className={classes.button}
              variant={index !== primaryButtonIndex ? "outlined" : "contained"}
              color={index !== primaryButtonIndex ? "secondary" : "primary"}
              startIcon={
                isMediumScreenOrSmaller && hasThreeButtons ? null : option?.icon
              }
              key={index}
              size="medium"
              onClick={() => handleClickandFocus(option?.linkTo, index)}
            >
              {option?.title}
            </Button>
          )
      )}
    </Box>
  );
}
