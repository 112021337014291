export const SET_STATE = "SET_STATE";

export interface RecipientsState {
  recipientId: string;
  recipientName: string;
  recipientType: string;
}

export interface MessageState {
  messageSubject: string;
  messageBody: string;
  documentIds: string[];
}

export interface CentersState {
  centerId: string;
  centerName: string;
}

export interface VisitsState {
  visitFilter: string;
  appointmentType: string;
}

export interface State {
  id: string;
  state: string;
  currentStep: string;
  userFlow: any[];
  services: {
    serviceLineId: string;
  };
  decisions: {
    careType: string;
  };
  recipients: RecipientsState;
  message: MessageState;
  centers: CentersState;
  visits: VisitsState;
}

export const getRecipientsInitialState = (): RecipientsState => {
  return {
    recipientId: "",
    recipientName: "",
    recipientType: "",
  };
};

export const getMessageInitialState = (): MessageState => {
  return {
    messageSubject: "",
    messageBody: "",
    documentIds: [],
  };
};

export const getCentersInitialState = (): CentersState => {
  return {
    centerId: "",
    centerName: "",
  };
};

export const getVisitsInitialState = (): VisitsState => {
  return {
    visitFilter: "",
    appointmentType: "",
  };
};

export const getInitialCareAccessState = (): State => {
  return {
    id: "",
    state: "",
    currentStep: "",
    userFlow: [],
    services: { serviceLineId: "" },
    decisions: { careType: "" },
    recipients: getRecipientsInitialState(),
    message: getMessageInitialState(),
    centers: getCentersInitialState(),
    visits: getVisitsInitialState(),
  };
};

export const careAccessReducer = (
  state: State = getInitialCareAccessState(),
  action: any
): State => {
  if (action && action.type) {
    const { type } = action;
    switch (type) {
      case SET_STATE:
        return state;
      default:
        return state;
    }
  }
  return state;
};
