// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     AcknowledgeableComponentProps,
    AcknowledgeableComponentPropsFromJSON,
    AcknowledgeableComponentPropsToJSON,
} from './AcknowledgeableComponentProps';
import {
     ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';
import {
     CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
     CalculatorComponentOutputProps,
    CalculatorComponentOutputPropsFromJSON,
    CalculatorComponentOutputPropsToJSON,
} from './CalculatorComponentOutputProps';
import {
     CalculatorComponentOutputPropsJsonBody,
    CalculatorComponentOutputPropsJsonBodyFromJSON,
    CalculatorComponentOutputPropsJsonBodyToJSON,
} from './CalculatorComponentOutputPropsJsonBody';
import {
     CalculatorScoreOutput,
    CalculatorScoreOutputFromJSON,
    CalculatorScoreOutputToJSON,
} from './CalculatorScoreOutput';
import {
     CompactComponentReference,
    CompactComponentReferenceFromJSON,
    CompactComponentReferenceToJSON,
} from './CompactComponentReference';
import {
     ComponentActionDetails,
    ComponentActionDetailsFromJSON,
    ComponentActionDetailsToJSON,
} from './ComponentActionDetails';
import {
     ComponentCore,
    ComponentCoreFromJSON,
    ComponentCoreToJSON,
} from './ComponentCore';
import {
     ComponentDetailsCore,
    ComponentDetailsCoreFromJSON,
    ComponentDetailsCoreToJSON,
} from './ComponentDetailsCore';
import {
     ComponentDetailsCoreCreatorInfo,
    ComponentDetailsCoreCreatorInfoFromJSON,
    ComponentDetailsCoreCreatorInfoToJSON,
} from './ComponentDetailsCoreCreatorInfo';
import {
     ComponentDetailsCoreResolverInfo,
    ComponentDetailsCoreResolverInfoFromJSON,
    ComponentDetailsCoreResolverInfoToJSON,
} from './ComponentDetailsCoreResolverInfo';
import {
     CtmMessageComponentOutput,
    CtmMessageComponentOutputFromJSON,
    CtmMessageComponentOutputToJSON,
} from './CtmMessageComponentOutput';
import {
     DocumentObjectDetails,
    DocumentObjectDetailsFromJSON,
    DocumentObjectDetailsToJSON,
} from './DocumentObjectDetails';
import {
     DocumentOutputSchema,
    DocumentOutputSchemaFromJSON,
    DocumentOutputSchemaToJSON,
} from './DocumentOutputSchema';
import {
     MarketingMessageComponentOutput,
    MarketingMessageComponentOutputFromJSON,
    MarketingMessageComponentOutputToJSON,
} from './MarketingMessageComponentOutput';
import {
     MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';
import {
     OutputWorkspaceDraftSchema,
    OutputWorkspaceDraftSchemaFromJSON,
    OutputWorkspaceDraftSchemaToJSON,
} from './OutputWorkspaceDraftSchema';
import {
     PatientScreening,
    PatientScreeningFromJSON,
    PatientScreeningToJSON,
} from './PatientScreening';
import {
     UserInfo,
    UserInfoFromJSON,
    UserInfoToJSON,
} from './UserInfo';
/**
 * @type CalculatorComponent
 * @export
 */
export interface CalculatorComponent extends AcknowledgeableComponentProps, CalculatorComponentOutputProps, ComponentCore, ComponentDetailsCore {
}

export function CalculatorComponentFromJSON(json: any): CalculatorComponent {
    return {
        ...AcknowledgeableComponentPropsFromJSON(json),
        ...CalculatorComponentOutputPropsFromJSON(json),
        ...ComponentCoreFromJSON(json),
        ...ComponentDetailsCoreFromJSON(json),
    };
}

export function CalculatorComponentToJSON(value?: CalculatorComponent): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...AcknowledgeableComponentPropsToJSON(value),
        ...CalculatorComponentOutputPropsToJSON(value),
        ...ComponentCoreToJSON(value),
        ...ComponentDetailsCoreToJSON(value),
    };
}

