// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberInfoPropsFullAddress,
    MemberInfoPropsFullAddressFromJSON,
    MemberInfoPropsFullAddressToJSON,
} from './MemberInfoPropsFullAddress';
import {
    UserInfo,
    UserInfoFromJSON,
    UserInfoToJSON,
} from './UserInfo';


/**
 * 
 * @export
 * @interface MemberInfoProps
 */
export interface MemberInfoProps  {
    /**
     * 
     * @type {number}
     * @memberof MemberInfoProps
     */
    activeEpisodeCount?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberInfoProps
     */
    employer?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberInfoProps
     */
    state?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberInfoProps
     */
    phoneNumber?: string;
    /**
     * 
     * @type {Date}
     * @memberof MemberInfoProps
     */
    becomesIneligibleAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof MemberInfoProps
     */
    status?: string;
    /**
     * 
     * @type {MemberInfoPropsFullAddress}
     * @memberof MemberInfoProps
     */
    fullAddress?: MemberInfoPropsFullAddress;
    /**
     * 
     * @type {UserInfo}
     * @memberof MemberInfoProps
     */
    assignedPcp?: UserInfo;
    /**
     * 
     * @type {string}
     * @memberof MemberInfoProps
     */
    corePodName?: string;
}

export function MemberInfoPropsFromJSON(json: any): MemberInfoProps {
    return {
        'activeEpisodeCount': !exists(json, 'active_episode_count') ? undefined : json['active_episode_count'],
        'employer': !exists(json, 'employer') ? undefined : json['employer'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'phoneNumber': !exists(json, 'phone_number') ? undefined : json['phone_number'],
        'becomesIneligibleAt': !exists(json, 'becomes_ineligible_at') ? undefined : new Date(json['becomes_ineligible_at']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'fullAddress': !exists(json, 'full_address') ? undefined : MemberInfoPropsFullAddressFromJSON(json['full_address']),
        'assignedPcp': !exists(json, 'assigned_pcp') ? undefined : UserInfoFromJSON(json['assigned_pcp']),
        'corePodName': !exists(json, 'core_pod_name') ? undefined : json['core_pod_name'],
    };
}

export function MemberInfoPropsToJSON(value?: MemberInfoProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'active_episode_count': value.activeEpisodeCount,
        'employer': value.employer,
        'state': value.state,
        'phone_number': value.phoneNumber,
        'becomes_ineligible_at': value.becomesIneligibleAt === undefined ? undefined : value.becomesIneligibleAt.toISOString().substr(0,10),
        'status': value.status,
        'full_address': MemberInfoPropsFullAddressToJSON(value.fullAddress),
        'assigned_pcp': UserInfoToJSON(value.assignedPcp),
        'core_pod_name': value.corePodName,
    };
}


