import { GetMyQueueOrderEnum } from "shared/api/src/apis/EpisodeListsApi";
import { GetMyQueueRequest } from "shared/fetch/src/apis/EpisodeListsApi";
import { CtmFilterSetting } from "shared/state/ui/settings";

export { default as useGetCtmFilteredEpisodes } from "./useGetCtmFilteredEpisodes";
export { default as UseGetGenericFilteredQueueEpisodes } from "./useGetGenericQueueFilters";
export const getQueryString = (
  filterNames: string[],
  values: any[],
  order?: GetMyQueueOrderEnum | undefined
) => {
  const queryString: Partial<GetMyQueueRequest> = {};
  if (order) {
    queryString.order = order;
  }
  filterNames?.map((name, index) => {
    // special handling for cases around ctm and pod id filtering
    if (name === "ownedByCtmIdsAndPodIds" && Array.isArray(values?.[index])) {
      if (values?.[index]?.length === 0) {
        return (queryString.ownedByCtmIdsAndPodIds = "");
      }
      return values?.[index]?.map((ctm: CtmFilterSetting, i: number) => {
        if (ctm?.podId && ctm?.podId !== "undefined") {
          queryString.ownedByCtmIdsAndPodIds = (
            queryString?.ownedByCtmIdsAndPodIds || ""
          ).concat(`${i === 0 ? "" : ","}${ctm.id}:${ctm?.podId}`);
        }
      });
    } else if (
      name === "ownedOrClaimedByCtms" &&
      Array.isArray(values?.[index])
    ) {
      if (values?.[index]?.length === 0) {
        return (queryString.ownedOrClaimedByCtms = "");
      }
      return values?.[index]?.map((ctm: CtmFilterSetting, i: number) => {
        queryString.ownedOrClaimedByCtms = (
          queryString.ownedOrClaimedByCtms || ""
        ).concat(`${i === 0 ? "" : ","}${ctm.id}`);
      });
    } else {
      return (queryString[name] = values?.[index]);
    }
  });

  return queryString;
};
