import React, { FunctionComponent } from "react";
import Skeleton from ".";

const SettingsHeadsUpNotificationsSkeleton: FunctionComponent = () => {
  return (
    <>
      <Skeleton
        width="17%"
        variant="text"
        height={30}
        style={{ marginBottom: "8px" }}
      />
      <Skeleton width="25%" variant="text" style={{ marginBottom: "8px" }} />
      <Skeleton width="30%" variant="text" style={{ marginBottom: "8px" }} />
      <Skeleton width="35%" variant="text" style={{ marginBottom: "28px" }} />
    </>
  );
};

export default SettingsHeadsUpNotificationsSkeleton;
