import { useStyles } from "./styles";
import selfSchedulingCopy from "features/selfSchedule/pages/selfSchedulingCopy.json";

export const useSelfScheduleModalProps = () => {
  const classes = useStyles();
  const overrides = {
    title: selfSchedulingCopy.selfScheduleExitConfirmationModalTitle,
    content: selfSchedulingCopy.selfScheduleExitConfirmationMessage,
    className: classes.modalDialog,
  };
  return { overrides };
};

export const formatMessageSubject = (subject: string) => {
  if (String(subject)?.includes("_")) {
    const subjectReplaced = subject?.replace(/_/gi, " ");
    return (
      subjectReplaced?.charAt(0)?.toUpperCase() + subjectReplaced?.slice(1)
    );
  } else {
    return (
      String(subject)?.charAt(0)?.toUpperCase() + String(subject)?.slice(1)
    );
  }
};
