// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    HoursOfOperation,
    HoursOfOperationFromJSON,
    HoursOfOperationToJSON,
} from './HoursOfOperation';


/**
 * 
 * @export
 * @interface MemberClinic
 */
export interface MemberClinic  {
    /**
     * 
     * @type {string}
     * @memberof MemberClinic
     */
    clinicId?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberClinic
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberClinic
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberClinic
     */
    timezone?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberClinic
     */
    address?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MemberClinic
     */
    appointmentTypes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MemberClinic
     */
    serviceLines?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof MemberClinic
     */
    preferred?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberClinic
     */
    usState?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberClinic
     */
    virtual?: boolean;
    /**
     * 
     * @type {HoursOfOperation}
     * @memberof MemberClinic
     */
    hoursOfOperation?: HoursOfOperation;
}

export function MemberClinicFromJSON(json: any): MemberClinic {
    return {
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'timezone': !exists(json, 'timezone') ? undefined : json['timezone'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'appointmentTypes': !exists(json, 'appointment_types') ? undefined : json['appointment_types'],
        'serviceLines': !exists(json, 'service_lines') ? undefined : json['service_lines'],
        'preferred': !exists(json, 'preferred') ? undefined : json['preferred'],
        'usState': !exists(json, 'us_state') ? undefined : json['us_state'],
        'virtual': !exists(json, 'virtual') ? undefined : json['virtual'],
        'hoursOfOperation': !exists(json, 'hours_of_operation') ? undefined : HoursOfOperationFromJSON(json['hours_of_operation']),
    };
}

export function MemberClinicToJSON(value?: MemberClinic): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'clinic_id': value.clinicId,
        'name': value.name,
        'phone': value.phone,
        'timezone': value.timezone,
        'address': value.address,
        'appointment_types': value.appointmentTypes,
        'service_lines': value.serviceLines,
        'preferred': value.preferred,
        'us_state': value.usState,
        'virtual': value.virtual,
        'hours_of_operation': HoursOfOperationToJSON(value.hoursOfOperation),
    };
}


