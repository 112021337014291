/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    MemberLabsArrayResponse,
    MemberLabsArrayResponseFromJSON,
    MemberLabsArrayResponseToJSON,
} from '../models/MemberLabsArrayResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface GetLabXOPRequest {
    id: string;
    labId: string;
}
export interface GetLabsRequest {
    id: string;
    page?: string;
}
export interface MarkLabAsViewedRequest {
    id: string;
    labId: string;
}


export interface ILabsApi {
      getLabXOP: (requestParameters: GetLabXOPRequest) => Promise<Blob>
      getLabs: (requestParameters: GetLabsRequest) => Promise<MemberLabsArrayResponse>
      markLabAsViewed: (requestParameters: MarkLabAsViewedRequest) => Promise<void>
      getLabXOPWithResponse: (requestParameters: GetLabXOPRequest) => Promise<{ value: Blob, response: any}>
      getLabsWithResponse: (requestParameters: GetLabsRequest) => Promise<{ value: MemberLabsArrayResponse, response: any}>
      markLabAsViewedWithResponse: (requestParameters: MarkLabAsViewedRequest) => Promise<{ value: void, response: any}>
}

/**
 * no description
 */
export class LabsApi extends runtime.BaseAPI {

    /**
     * Get a specific lab document for an XOP member
     * Get a lab document for an XOP member
     */
    getLabXOPRaw = async (requestParameters: GetLabXOPRequest): Promise<{ runtime: runtime.ApiResponse<Blob>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getLabXOP.');
        }

                        if (requestParameters.labId === null || requestParameters.labId === undefined) {
            throw new runtime.RequiredError('labId','Required parameter requestParameters.labId was null or undefined when calling getLabXOP.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/labs/{lab_id}/document`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"lab_id"}}`, encodeURIComponent(String(requestParameters.labId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.BlobApiResponse(response), response };
    }

    /**
     * Get a specific lab document for an XOP member
     * Get a lab document for an XOP member
     */
    getLabXOP = async (requestParameters: GetLabXOPRequest): Promise<Blob> => {
        const { runtime } = await this.getLabXOPRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get a specific lab document for an XOP member
     * Get a lab document for an XOP member
     */
    getLabXOPWithResponse = async (requestParameters: GetLabXOPRequest): Promise<{ value: Blob, response: any}> => {
        const { runtime, response } = await this.getLabXOPRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * View paginated list of a member\'s labs
     * View paginated list of a member\'s labs
     */
    getLabsRaw = async (requestParameters: GetLabsRequest): Promise<{ runtime: runtime.ApiResponse<MemberLabsArrayResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getLabs.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/labs`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberLabsArrayResponseFromJSON(jsonValue)), response };
    }

    /**
     * View paginated list of a member\'s labs
     * View paginated list of a member\'s labs
     */
    getLabs = async (requestParameters: GetLabsRequest): Promise<MemberLabsArrayResponse> => {
        const { runtime } = await this.getLabsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * View paginated list of a member\'s labs
     * View paginated list of a member\'s labs
     */
    getLabsWithResponse = async (requestParameters: GetLabsRequest): Promise<{ value: MemberLabsArrayResponse, response: any}> => {
        const { runtime, response } = await this.getLabsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Update viewed column in elation_labs to true
     * Update viewed column in elation_labs to true
     */
    markLabAsViewedRaw = async (requestParameters: MarkLabAsViewedRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling markLabAsViewed.');
        }

                        if (requestParameters.labId === null || requestParameters.labId === undefined) {
            throw new runtime.RequiredError('labId','Required parameter requestParameters.labId was null or undefined when calling markLabAsViewed.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/labs/{lab_id}/viewed`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"lab_id"}}`, encodeURIComponent(String(requestParameters.labId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Update viewed column in elation_labs to true
     * Update viewed column in elation_labs to true
     */
    markLabAsViewed = async (requestParameters: MarkLabAsViewedRequest): Promise<void> => {
        const { runtime } = await this.markLabAsViewedRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Update viewed column in elation_labs to true
     * Update viewed column in elation_labs to true
     */
    markLabAsViewedWithResponse = async (requestParameters: MarkLabAsViewedRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.markLabAsViewedRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

}

export const fetchLabsApi: ILabsApi  = new LabsApi();
