// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Dependent,
    DependentFromJSON,
    DependentToJSON,
} from './Dependent';


/**
 * 
 * @export
 * @interface DependentsResponse
 */
export interface DependentsResponse  {
    /**
     * 
     * @type {Array<Dependent>}
     * @memberof DependentsResponse
     */
    results?: Array<Dependent>;
}

export function DependentsResponseFromJSON(json: any): DependentsResponse {
    return {
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(DependentFromJSON),
    };
}

export function DependentsResponseToJSON(value?: DependentsResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(DependentToJSON),
    };
}


