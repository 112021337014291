// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfigResponseLinks
 */
export interface ConfigResponseLinks  {
    /**
     * 
     * @type {string}
     * @memberof ConfigResponseLinks
     */
    home?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigResponseLinks
     */
    terms?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigResponseLinks
     */
    privacy?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigResponseLinks
     */
    consents?: string;
}

export function ConfigResponseLinksFromJSON(json: any): ConfigResponseLinks {
    return {
        'home': !exists(json, 'home') ? undefined : json['home'],
        'terms': !exists(json, 'terms') ? undefined : json['terms'],
        'privacy': !exists(json, 'privacy') ? undefined : json['privacy'],
        'consents': !exists(json, 'consents') ? undefined : json['consents'],
    };
}

export function ConfigResponseLinksToJSON(value?: ConfigResponseLinks): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'home': value.home,
        'terms': value.terms,
        'privacy': value.privacy,
        'consents': value.consents,
    };
}


