// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     MessageRecipientProps,
    MessageRecipientPropsFromJSON,
    MessageRecipientPropsToJSON,
} from './MessageRecipientProps';
import {
     OOONotificationsArray,
    OOONotificationsArrayFromJSON,
    OOONotificationsArrayToJSON,
} from './OOONotificationsArray';
/**
 * @type MessageRecipient
 * @export
 */
export interface MessageRecipient extends MessageRecipientProps {
}

export function MessageRecipientFromJSON(json: any): MessageRecipient {
    return {
        ...MessageRecipientPropsFromJSON(json),
    };
}

export function MessageRecipientToJSON(value?: MessageRecipient): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...MessageRecipientPropsToJSON(value),
    };
}

