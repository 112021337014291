import React, { useMemo } from "react";
import User from "models/User";
import { selectLoggedInUser } from "shared/features/user/selectors";

const usePendingIneligibilityMessage = () => {
  const user = selectLoggedInUser();
  const { becomesIneligibleAt } = user;
  const isIneligible = User.isUserIneligible(user);

  const timeDiffMessage = useMemo(() => User.daysUntilIneligible(user), [
    becomesIneligibleAt,
  ]);

  if (
    isIneligible ||
    !becomesIneligibleAt
  ) {
    return {};
  }

  const message = `Your account will be ineligible in ${timeDiffMessage}.`;

  return {
    message: <span>{message}</span>,
    orderDate: becomesIneligibleAt,
  };
};

export default usePendingIneligibilityMessage;
