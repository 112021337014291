// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberAccountInfoRequestBodyAllOf
 */
export interface MemberAccountInfoRequestBodyAllOf  {
    /**
     * 
     * @type {Array<number>}
     * @memberof MemberAccountInfoRequestBodyAllOf
     */
    podFilter?: Array<number>;
    /**
     * 
     * @type {boolean}
     * @memberof MemberAccountInfoRequestBodyAllOf
     */
    consented?: boolean;
}

export function MemberAccountInfoRequestBodyAllOfFromJSON(json: any): MemberAccountInfoRequestBodyAllOf {
    return {
        'podFilter': !exists(json, 'pod_filter') ? undefined : json['pod_filter'],
        'consented': !exists(json, 'consented') ? undefined : json['consented'],
    };
}

export function MemberAccountInfoRequestBodyAllOfToJSON(value?: MemberAccountInfoRequestBodyAllOf): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'pod_filter': value.podFilter,
        'consented': value.consented,
    };
}


