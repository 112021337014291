import { useMutation } from "react-query";

import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";
import { UpdateDocumentRequest, fetchDocumentsApi } from "shared/fetch/src/apis/DocumentsApi";

const useUpdateDocumentMutation = () => {
  const dispatch = useDispatch();

  const mutation = useMutation(
    (request: UpdateDocumentRequest) =>
      fetchDocumentsApi.updateDocument(request),
    {
      onSuccess: (_response) => {
        dispatch(showSnackbar("Document updated."));
      },
    }
  );

  return mutation;
};

export default useUpdateDocumentMutation;
