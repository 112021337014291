import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";
import { CreateConsentRequest, fetchConsentApi } from "shared/fetch/src/apis/ConsentApi";

const useCreateConsentMutation = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const createConsentMutation = useMutation(
    (request: CreateConsentRequest) => fetchConsentApi.createConsent(request),
    {
      onSuccess: (_response, _request) => {
        dispatch(showSnackbar("Consent successfully added.", "success"));
        queryClient.invalidateQueries({ queryKey: ["memberConsents"] });
      },
      onError: (_response, _request) => {
        dispatch(
          showSnackbar("Error adding consent. Please try again.", "danger")
        );
      },
    }
  );
  return createConsentMutation;
};

export default useCreateConsentMutation;
