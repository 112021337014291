// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    TimelineEventOutput,
    TimelineEventOutputFromJSON,
    TimelineEventOutputToJSON,
} from './TimelineEventOutput';


/**
 * 
 * @export
 * @interface EpisodeOutputAllOf
 */
export interface EpisodeOutputAllOf  {
    /**
     * 
     * @type {string}
     * @memberof EpisodeOutputAllOf
     */
    prevUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof EpisodeOutputAllOf
     */
    nextUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof EpisodeOutputAllOf
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof EpisodeOutputAllOf
     */
    last?: number;
    /**
     * 
     * @type {Array<TimelineEventOutput>}
     * @memberof EpisodeOutputAllOf
     */
    timelineEvents: Array<TimelineEventOutput>;
}

export function EpisodeOutputAllOfFromJSON(json: any): EpisodeOutputAllOf {
    return {
        'prevUrl': !exists(json, 'prev_url') ? undefined : json['prev_url'],
        'nextUrl': !exists(json, 'next_url') ? undefined : json['next_url'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'timelineEvents': (json['timeline_events'] as Array<any>).map(TimelineEventOutputFromJSON),
    };
}

export function EpisodeOutputAllOfToJSON(value?: EpisodeOutputAllOf): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'prev_url': value.prevUrl,
        'next_url': value.nextUrl,
        'page': value.page,
        'last': value.last,
        'timeline_events': (value.timelineEvents as Array<any>).map(TimelineEventOutputToJSON),
    };
}


