// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CTMBioProfile,
    CTMBioProfileFromJSON,
    CTMBioProfileToJSON,
} from './CTMBioProfile';


/**
 * 
 * @export
 * @interface MemberCareTeamOutput
 */
export interface MemberCareTeamOutput  {
    /**
     * 
     * @type {Array<CTMBioProfile>}
     * @memberof MemberCareTeamOutput
     */
    members: Array<CTMBioProfile>;
}

export function MemberCareTeamOutputFromJSON(json: any): MemberCareTeamOutput {
    return {
        'members': (json['members'] as Array<any>).map(CTMBioProfileFromJSON),
    };
}

export function MemberCareTeamOutputToJSON(value?: MemberCareTeamOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'members': (value.members as Array<any>).map(CTMBioProfileToJSON),
    };
}


