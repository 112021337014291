// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    InputSubTypeSchema,
    InputSubTypeSchemaFromJSON,
    InputSubTypeSchemaToJSON,
} from './InputSubTypeSchema';


/**
 * 
 * @export
 * @interface InputServiceLineSchemaAllOf1
 */
export interface InputServiceLineSchemaAllOf1  {
    /**
     * 
     * @type {Array<InputSubTypeSchema>}
     * @memberof InputServiceLineSchemaAllOf1
     */
    subtypes: Array<InputSubTypeSchema>;
}

export function InputServiceLineSchemaAllOf1FromJSON(json: any): InputServiceLineSchemaAllOf1 {
    return {
        'subtypes': (json['subtypes'] as Array<any>).map(InputSubTypeSchemaFromJSON),
    };
}

export function InputServiceLineSchemaAllOf1ToJSON(value?: InputServiceLineSchemaAllOf1): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'subtypes': (value.subtypes as Array<any>).map(InputSubTypeSchemaToJSON),
    };
}


