/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    MemberAppointmentTypesSchema,
    MemberAppointmentTypesSchemaFromJSON,
    MemberAppointmentTypesSchemaToJSON,
} from '../models/MemberAppointmentTypesSchema'
import {
    MemberAppointments,
    MemberAppointmentsFromJSON,
    MemberAppointmentsToJSON,
} from '../models/MemberAppointments'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface FetchMemberAppointmentTypesRequest {
    id: number;
    serviceLineId: string;
    podId?: number;
}
export interface MemberAppointmentListSummariesRequest {
    id: string;
    status?: MemberAppointmentListSummariesStatusEnum;
    page?: string;
    pageSize?: string;
    relevant?: boolean;
    dispositions?: Array<MemberAppointmentListSummariesDispositionsEnum>;
}


export interface IMemberAppointmentsApi {
      fetchMemberAppointmentTypes: (requestParameters: FetchMemberAppointmentTypesRequest) => Promise<MemberAppointmentTypesSchema>
      memberAppointmentListSummaries: (requestParameters: MemberAppointmentListSummariesRequest) => Promise<MemberAppointments>
      fetchMemberAppointmentTypesWithResponse: (requestParameters: FetchMemberAppointmentTypesRequest) => Promise<{ value: MemberAppointmentTypesSchema, response: any}>
      memberAppointmentListSummariesWithResponse: (requestParameters: MemberAppointmentListSummariesRequest) => Promise<{ value: MemberAppointments, response: any}>
}

/**
 * no description
 */
export class MemberAppointmentsApi extends runtime.BaseAPI {

    /**
     * Fetch member appointment types based on their selected service line
     */
    fetchMemberAppointmentTypesRaw = async (requestParameters: FetchMemberAppointmentTypesRequest): Promise<{ runtime: runtime.ApiResponse<MemberAppointmentTypesSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchMemberAppointmentTypes.');
        }

                        if (requestParameters.serviceLineId === null || requestParameters.serviceLineId === undefined) {
            throw new runtime.RequiredError('serviceLineId','Required parameter requestParameters.serviceLineId was null or undefined when calling fetchMemberAppointmentTypes.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.serviceLineId !== undefined) {
            queryParameters['service_line_id'] = requestParameters.serviceLineId;
        }

        if (requestParameters.podId !== undefined) {
            queryParameters['pod_id'] = requestParameters.podId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/appointment_types`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberAppointmentTypesSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Fetch member appointment types based on their selected service line
     */
    fetchMemberAppointmentTypes = async (requestParameters: FetchMemberAppointmentTypesRequest): Promise<MemberAppointmentTypesSchema> => {
        const { runtime } = await this.fetchMemberAppointmentTypesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch member appointment types based on their selected service line
     */
    fetchMemberAppointmentTypesWithResponse = async (requestParameters: FetchMemberAppointmentTypesRequest): Promise<{ value: MemberAppointmentTypesSchema, response: any}> => {
        const { runtime, response } = await this.fetchMemberAppointmentTypesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch Appointments for the Member
     * Member appointments summaries
     */
    memberAppointmentListSummariesRaw = async (requestParameters: MemberAppointmentListSummariesRequest): Promise<{ runtime: runtime.ApiResponse<MemberAppointments>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling memberAppointmentListSummaries.');
        }

                                                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.pageSize !== undefined) {
            queryParameters['page_size'] = requestParameters.pageSize;
        }

        if (requestParameters.relevant !== undefined) {
            queryParameters['relevant'] = requestParameters.relevant;
        }

        if (requestParameters.dispositions) {
            queryParameters['dispositions[]'] = requestParameters.dispositions;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/appointments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => MemberAppointmentsFromJSON(jsonValue)), response };
    }

    /**
     * Fetch Appointments for the Member
     * Member appointments summaries
     */
    memberAppointmentListSummaries = async (requestParameters: MemberAppointmentListSummariesRequest): Promise<MemberAppointments> => {
        const { runtime } = await this.memberAppointmentListSummariesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch Appointments for the Member
     * Member appointments summaries
     */
    memberAppointmentListSummariesWithResponse = async (requestParameters: MemberAppointmentListSummariesRequest): Promise<{ value: MemberAppointments, response: any}> => {
        const { runtime, response } = await this.memberAppointmentListSummariesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

/**
    * @export
    * @enum {string}
    */
export enum MemberAppointmentListSummariesStatusEnum {
    Upcoming = 'upcoming',
    Past = 'past',
    Cancelled = 'cancelled'
}
/**
    * @export
    * @enum {string}
    */
export enum MemberAppointmentListSummariesDispositionsEnum {
    Complete = 'complete',
    Missed = 'missed',
    Cancel = 'cancel',
    NoShow = 'no_show',
    ProviderUnavailable = 'provider_unavailable',
    ClinicCancel = 'clinic_cancel',
    LateCancel = 'late_cancel'
}

export const fetchMemberAppointmentsApi: IMemberAppointmentsApi  = new MemberAppointmentsApi();
