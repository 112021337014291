// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConfigResponseSignin
 */
export interface ConfigResponseSignin  {
    /**
     * 
     * @type {string}
     * @memberof ConfigResponseSignin
     */
    headline?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigResponseSignin
     */
    subhead?: string;
    /**
     * 
     * @type {string}
     * @memberof ConfigResponseSignin
     */
    button?: string;
}

export function ConfigResponseSigninFromJSON(json: any): ConfigResponseSignin {
    return {
        'headline': !exists(json, 'headline') ? undefined : json['headline'],
        'subhead': !exists(json, 'subhead') ? undefined : json['subhead'],
        'button': !exists(json, 'button') ? undefined : json['button'],
    };
}

export function ConfigResponseSigninToJSON(value?: ConfigResponseSignin): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'headline': value.headline,
        'subhead': value.subhead,
        'button': value.button,
    };
}


