import capitalize from "lodash/capitalize";
import differenceInDays from "date-fns/differenceInDays";
import formatDistance from "date-fns/formatDistance";
import differenceInMinutes from "date-fns/differenceInMinutes";
import format from "date-fns/format";

export const isValidDate = (date: Date) => {
  if (
    Object.prototype.toString.call(date) === "[object Date]" &&
    date?.getTime()
  ) {
    return true;
  }

  return false;
};

export const getDaysBetween = (a: Date, b: Date) => {
  if (
    a === undefined ||
    b === undefined ||
    !isValidDate(a) ||
    !isValidDate(b)
  ) {
    return null;
  }

  const parsedDateA = new Date(a);
  const parsedDateB = new Date(b);
  const days = differenceInDays(parsedDateA, parsedDateB);
  if (days && days < 0) {
    return Math.abs(days);
  }
  return b;
};

export const durationConverter = (
  time: Date,
  from: any = new Date()
): string[] => {
  if (!time) {
    return [""];
  }

  const parsedTime = new Date(time);
  const momentTime = formatDistance(parsedTime, new Date(from), {
    includeSeconds: true,
  });

  const minuteTime = differenceInMinutes(new Date(from), parsedTime);

  const split = momentTime
    .split(" ")
    .map((timeDesc) =>
      timeDesc === "a" || timeDesc === "an" ? "1" : capitalize(timeDesc)
    )
    .slice(-2);

  if (momentTime.includes("seconds")) {
    return ["A", "Few Seconds"];
  } else if (minuteTime > 1 && minuteTime <= 119) {
    return [minuteTime.toString(), "Minutes"];
  } else {
    return split;
  }
};

export const returnDateInFormat = () => {
  const date = new Date();
  return format(date, "MMM d, yyyy_hh:mm:ss");
};

export const getDaysDifference = (futureDate: Date) => {
  const daysDiff = getDaysBetween(new Date(), new Date(futureDate));
  const formattedDaysDiff =
    daysDiff instanceof Date ? 0 : Math.max(0, daysDiff || 0);
  return formattedDaysDiff === 1 ? "1 day" : `${formattedDaysDiff} days`;
};
