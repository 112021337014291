import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import Typography from "components/Typography";

interface IProps {
  count: number;
}

const useStyles = makeStyles(({ palette }) => ({
  wrapper: {
    border: `1px solid ${palette.text.strokes}`,
    borderRadius: "10px",
    padding: "8px",
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
  },
  fontAndMargin: {
    fontSize: "13px",
    marginLeft: "5px",
  },
  icon: {
    fill: palette.text.secondary,
  },
}));

const PendingPaymentsBalance: FunctionComponent<IProps> = ({ count }) => {
  const classes = useStyles();
  const path = "/account/payments";
  const suffix = count > 1 ? "s" : "";

  return (
    <div className={classes.wrapper} data-testid="pending-payments-balance">
      <LocalAtmIcon className={classes.icon} />
      <Typography
        appearance="body"
        color="textSecondary"
        className={classes.fontAndMargin}
      >
        You have {count} pending balance{suffix}.
      </Typography>
      <Link
        to={path}
        state={{ prevPath: location.pathname }}
        className={classes.fontAndMargin}
        data-testid="pending-badge-link"
      >
        Pay now
      </Link>
    </div>
  );
};

export default PendingPaymentsBalance;
