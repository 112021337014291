/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AppointmentCancelationInfoResponse,
    AppointmentCancelationInfoResponseFromJSON,
    AppointmentCancelationInfoResponseToJSON,
} from '../models/AppointmentCancelationInfoResponse'
import {
    ComponentDetailsOutput,
    ComponentDetailsOutputFromJSON,
    ComponentDetailsOutputToJSON,
} from '../models/ComponentDetailsOutput'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    ScheduleAppointmentCore,
    ScheduleAppointmentCoreFromJSON,
    ScheduleAppointmentCoreToJSON,
} from '../models/ScheduleAppointmentCore'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UpcomingAppointmentsResponse,
    UpcomingAppointmentsResponseFromJSON,
    UpcomingAppointmentsResponseToJSON,
} from '../models/UpcomingAppointmentsResponse'


export interface AppointmentCancellationInfoByIDRequest {
    id: string;
}
export interface ScheduleAppointmentOnSlotRequest {
    id: string;
    scheduleAppointmentCore?: ScheduleAppointmentCore;
}
export interface UserUpcomingAppointmentsListSummariesRequest {
    id: string;
}


export interface IAppointmentsApi {
      appointmentCancellationInfoByID: (requestParameters: AppointmentCancellationInfoByIDRequest) => Promise<AppointmentCancelationInfoResponse>
      scheduleAppointmentOnSlot: (requestParameters: ScheduleAppointmentOnSlotRequest) => Promise<ComponentDetailsOutput>
      userUpcomingAppointmentsListSummaries: (requestParameters: UserUpcomingAppointmentsListSummariesRequest) => Promise<UpcomingAppointmentsResponse>
      appointmentCancellationInfoByIDWithResponse: (requestParameters: AppointmentCancellationInfoByIDRequest) => Promise<{ value: AppointmentCancelationInfoResponse, response: any}>
      scheduleAppointmentOnSlotWithResponse: (requestParameters: ScheduleAppointmentOnSlotRequest) => Promise<{ value: ComponentDetailsOutput, response: any}>
      userUpcomingAppointmentsListSummariesWithResponse: (requestParameters: UserUpcomingAppointmentsListSummariesRequest) => Promise<{ value: UpcomingAppointmentsResponse, response: any}>
}

/**
 * no description
 */
export class AppointmentsApi extends runtime.BaseAPI {

    /**
     * Fetch cancellation policy and information for the appointment
     */
    appointmentCancellationInfoByIDRaw = async (requestParameters: AppointmentCancellationInfoByIDRequest): Promise<{ runtime: runtime.ApiResponse<AppointmentCancelationInfoResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling appointmentCancellationInfoByID.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/appointments/{id}/cancellation_info`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AppointmentCancelationInfoResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch cancellation policy and information for the appointment
     */
    appointmentCancellationInfoByID = async (requestParameters: AppointmentCancellationInfoByIDRequest): Promise<AppointmentCancelationInfoResponse> => {
        const { runtime } = await this.appointmentCancellationInfoByIDRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch cancellation policy and information for the appointment
     */
    appointmentCancellationInfoByIDWithResponse = async (requestParameters: AppointmentCancellationInfoByIDRequest): Promise<{ value: AppointmentCancelationInfoResponse, response: any}> => {
        const { runtime, response } = await this.appointmentCancellationInfoByIDRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Schedule an appointment to a slot
     * Schedule an appointment to a slot
     */
    scheduleAppointmentOnSlotRaw = async (requestParameters: ScheduleAppointmentOnSlotRequest): Promise<{ runtime: runtime.ApiResponse<ComponentDetailsOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling scheduleAppointmentOnSlot.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/slots/{id}/appointment`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ScheduleAppointmentCoreToJSON(requestParameters.scheduleAppointmentCore),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ComponentDetailsOutputFromJSON(jsonValue)), response };
    }

    /**
     * Schedule an appointment to a slot
     * Schedule an appointment to a slot
     */
    scheduleAppointmentOnSlot = async (requestParameters: ScheduleAppointmentOnSlotRequest): Promise<ComponentDetailsOutput> => {
        const { runtime } = await this.scheduleAppointmentOnSlotRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Schedule an appointment to a slot
     * Schedule an appointment to a slot
     */
    scheduleAppointmentOnSlotWithResponse = async (requestParameters: ScheduleAppointmentOnSlotRequest): Promise<{ value: ComponentDetailsOutput, response: any}> => {
        const { runtime, response } = await this.scheduleAppointmentOnSlotRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch upcoming appointments for the Member or CTM
     * User upcoming appointments summaries
     */
    userUpcomingAppointmentsListSummariesRaw = async (requestParameters: UserUpcomingAppointmentsListSummariesRequest): Promise<{ runtime: runtime.ApiResponse<UpcomingAppointmentsResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling userUpcomingAppointmentsListSummaries.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/users/{id}/upcoming_appointments`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => UpcomingAppointmentsResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch upcoming appointments for the Member or CTM
     * User upcoming appointments summaries
     */
    userUpcomingAppointmentsListSummaries = async (requestParameters: UserUpcomingAppointmentsListSummariesRequest): Promise<UpcomingAppointmentsResponse> => {
        const { runtime } = await this.userUpcomingAppointmentsListSummariesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch upcoming appointments for the Member or CTM
     * User upcoming appointments summaries
     */
    userUpcomingAppointmentsListSummariesWithResponse = async (requestParameters: UserUpcomingAppointmentsListSummariesRequest): Promise<{ value: UpcomingAppointmentsResponse, response: any}> => {
        const { runtime, response } = await this.userUpcomingAppointmentsListSummariesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchAppointmentsApi: IAppointmentsApi  = new AppointmentsApi();
