import CryptoJS from "crypto-js";
import { JsonUser as User } from "shared/api/src/models/JsonUser";
import { getTokenSync } from "./utils";

export const base64Decode = (base64: string): string => {
  const words = CryptoJS.enc.Base64.parse(base64);
  return words.toString(CryptoJS.enc.Utf8);
};

export const jwtDecode = (jwt: string): any => {
  const parts = jwt.split(".");
  if (parts.length > 1) {
    return JSON.parse(base64Decode(parts[1]));
  }
  return null;
};

export const createCodeVerifier = () => {
  const code = getTokenSync(CODE_VERIFIER_KEY);
  if (code) {
    return code;
  }
  return CryptoJS.enc.Base64.stringify(CryptoJS.lib.WordArray.random(30))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");
};

export const createCodeChallenge = (text: string) => {
  return new Promise((resolve) => {
    const buffer = CryptoJS.SHA256(text).toString();
    const wordArray = CryptoJS.enc.Utf8.parse(buffer);
    const base64 = CryptoJS.enc.Base64.stringify(wordArray);
    resolve(base64);
  });
};

// Actions
export const SET_AUTH = "SET_AUTH";
export const REMOVE_AUTH = "REMOVE_AUTH";
export const SET_MEMBER_ACCESS = "SET_MEMBER_ACCESS";
export const SET_MEMBER_ACCESS_USER = "SET_MEMBER_ACCESS_USER";

// Constants
export const CODE_VERIFIER_KEY = "codeVerifier";
export const ACCESS_TOKEN_KEY = "accessToken";
export const REFRESH_TOKEN_KEY = "refreshToken";
export const MEMBER_ACCESS_TOKEN_KEY = "X-XO-Patient-Access-Grant";
export const MEMBER_ACCESS_ID = "currentPedsAccessId";

// Reducer
export function reducer(
  state: State = { isLoading: true },
  action: any
): State {
  switch (action.type) {
    // do reducer stuff
    case SET_AUTH:
      return Object.assign({}, state, {
        accessToken: action.auth.accessToken,
        refreshToken: action.auth.refreshToken,
        isLoading: false,
      });

    case SET_MEMBER_ACCESS:
      return Object.assign({}, state, {
        memberAccessToken: action.auth.memberAccessToken,
        isLoading: false,
      });

    case SET_MEMBER_ACCESS_USER:
      return Object.assign({}, state, {
        memberAccessUser: action.auth.memberAccessUser,
        isLoading: false,
      });

    case REMOVE_AUTH:
      return {
        accessToken: "",
        refreshToken: "",
        memberAccessToken: "",
        memberAccessUser: undefined,
        isLoading: false,
      };
    default:
      return state;
  }
}

export const setTokens = function (auth: any) {
  return { type: SET_AUTH, auth };
};

export const setMemberAccessToken = function (auth: any) {
  return { type: SET_MEMBER_ACCESS, auth };
};

export const setMemberAccessUser = function (auth: any) {
  return { type: SET_MEMBER_ACCESS_USER, auth };
};

export const removeTokens = function () {
  return { type: REMOVE_AUTH };
};

export interface State {
  accessToken?: string;
  refreshToken?: string;
  memberAccessToken?: string;
  memberAccessUser?: User;
  isLoading: boolean;
}

/**
 * Gets initial auth state from storage.
 */
export function getInitialAuthState(_getStore: () => any): State {
  const accessToken = getTokenSync(ACCESS_TOKEN_KEY) || undefined;
  const refreshToken = getTokenSync(REFRESH_TOKEN_KEY) || undefined;
  const memberAccessToken = getTokenSync(MEMBER_ACCESS_TOKEN_KEY) || undefined;
  const memberAccessUser: User | undefined = undefined;

  return {
    accessToken,
    refreshToken,
    memberAccessToken,
    isLoading: false,
    memberAccessUser,
  };
}
