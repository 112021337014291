// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberProvidersClinics
 */
export interface MemberProvidersClinics  {
    /**
     * 
     * @type {string}
     * @memberof MemberProvidersClinics
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberProvidersClinics
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberProvidersClinics
     */
    usState?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberProvidersClinics
     */
    virtual?: boolean;
}

export function MemberProvidersClinicsFromJSON(json: any): MemberProvidersClinics {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'usState': !exists(json, 'us_state') ? undefined : json['us_state'],
        'virtual': !exists(json, 'virtual') ? undefined : json['virtual'],
    };
}

export function MemberProvidersClinicsToJSON(value?: MemberProvidersClinics): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'us_state': value.usState,
        'virtual': value.virtual,
    };
}


