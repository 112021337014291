/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AppointmentOutput,
    AppointmentOutputFromJSON,
    AppointmentOutputToJSON,
} from '../models/AppointmentOutput'
import {
    CreateAppointmentNotes,
    CreateAppointmentNotesFromJSON,
    CreateAppointmentNotesToJSON,
} from '../models/CreateAppointmentNotes'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface CreateNotesOnAppointmentRequest {
    id: string;
    createAppointmentNotes: CreateAppointmentNotes;
}
export interface DeleteAppointmentNoteRequest {
    id: string;
}


export interface IAppointmentNotesApi {
      createNotesOnAppointment: (requestParameters: CreateNotesOnAppointmentRequest) => Promise<AppointmentOutput>
      deleteAppointmentNote: (requestParameters: DeleteAppointmentNoteRequest) => Promise<void>
      createNotesOnAppointmentWithResponse: (requestParameters: CreateNotesOnAppointmentRequest) => Promise<{ value: AppointmentOutput, response: any}>
      deleteAppointmentNoteWithResponse: (requestParameters: DeleteAppointmentNoteRequest) => Promise<{ value: void, response: any}>
}

/**
 * no description
 */
export class AppointmentNotesApi extends runtime.BaseAPI {

    /**
     * Create notes for an Appointment
     * Create notes for an Appointment
     */
    createNotesOnAppointmentRaw = async (requestParameters: CreateNotesOnAppointmentRequest): Promise<{ runtime: runtime.ApiResponse<AppointmentOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createNotesOnAppointment.');
        }

                        if (requestParameters.createAppointmentNotes === null || requestParameters.createAppointmentNotes === undefined) {
            throw new runtime.RequiredError('createAppointmentNotes','Required parameter requestParameters.createAppointmentNotes was null or undefined when calling createNotesOnAppointment.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/appointments/{id}/notes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateAppointmentNotesToJSON(requestParameters.createAppointmentNotes),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AppointmentOutputFromJSON(jsonValue)), response };
    }

    /**
     * Create notes for an Appointment
     * Create notes for an Appointment
     */
    createNotesOnAppointment = async (requestParameters: CreateNotesOnAppointmentRequest): Promise<AppointmentOutput> => {
        const { runtime } = await this.createNotesOnAppointmentRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Create notes for an Appointment
     * Create notes for an Appointment
     */
    createNotesOnAppointmentWithResponse = async (requestParameters: CreateNotesOnAppointmentRequest): Promise<{ value: AppointmentOutput, response: any}> => {
        const { runtime, response } = await this.createNotesOnAppointmentRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Delete appointment note
     * Delete appointment note
     */
    deleteAppointmentNoteRaw = async (requestParameters: DeleteAppointmentNoteRequest): Promise<{ runtime: runtime.ApiResponse<void>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteAppointmentNote.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/appointment-notes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.VoidApiResponse(response), response };
    }

    /**
     * Delete appointment note
     * Delete appointment note
     */
    deleteAppointmentNote = async (requestParameters: DeleteAppointmentNoteRequest): Promise<void> => {
        const { runtime } = await this.deleteAppointmentNoteRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Delete appointment note
     * Delete appointment note
     */
    deleteAppointmentNoteWithResponse = async (requestParameters: DeleteAppointmentNoteRequest): Promise<{ value: void, response: any}> => {
        const { runtime, response } = await this.deleteAppointmentNoteRaw(requestParameters);
        const value = await runtime.value();
        return { value: value, response }
    }

}

export const fetchAppointmentNotesApi: IAppointmentNotesApi  = new AppointmentNotesApi();
