import palette from "../palette";

export default {
  root: {
    "&.Mui-disabled": {
      backgroundColor: palette.lightBackground.main,
      color: palette.text.secondary,
    },
    "&.MuiFilledInput-underline.Mui-disabled:before": {
      borderBottomStyle: "solid",
    },
    "&.MuiFilledInput-underline:before": {
      borderBottom: `1px solid ${palette.divider}`,
    },
    "&.MuiFilledInput-underline:after": {
      borderBottom: `2px solid ${palette.text.link}`,
    },
    "& .MuiFormLabel-root.Mui-disabled": {
      color: palette.text.secondary,
    },
  },
};
