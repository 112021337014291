import React, { useEffect, useState } from "react";
import { PaperProps } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "redux-query-react";
import { Theme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import { fetchComponentRequest } from "shared/features/components/queryConfigs";
import { fetchTimelineEventRequest } from "shared/state/timelineEvents/queryConfigs";

import Paper from "components/Paper";
import Dialog from "components/Dialog";
import DialogActions from "components/Dialog/DialogActions";
import Button from "components/Button";
import DialogContent from "components/Dialog/DialogContent";
import DialogTitle from "components/Dialog/DialogTitle";
import TextField from "components/TextField";
import Typography from "components/Typography";
import Box from "components/Box";
import CardSection from "components/Card/CardSection";
import { useUpdateAiOwner } from "shared/state/aiOwner/useUpdateAiOwner";
import { useUpdateBulkAiOwnership } from "shared/state/aiOwner/useUpdateBulkAi";
import { showSnackbar } from "shared/state/ui/snackbar";
import useGetPanelCareTeam from "shared/features/ccMessaging/useGetPanelCareTeam";
import { getUser } from "shared/features/user/selectors";
import Alert from "components/Alert";
import SelectAiOwnerSkeleton from "./SelectAiOwnerSkeleton";
import { setEditingDraft } from "shared/state/ui/workspace";
import { getWorkspace } from "shared/state/ui/workspace/selectors";
import ProviderComboBox from "components/ComboBox/ProviderComboBox";
import { FetchComponentRequest } from "shared/fetch/src/apis/ComponentsApi";
import { ActionableItemSummary, ActionableItemSummaryFromJSON } from "shared/fetch/src/models/ActionableItemSummary";
import { CTMInfo } from "shared/fetch/src/models/CTMInfo";

const useStyles = makeStyles((theme: Theme) => ({
  roleInputField: {
    width: "100%",
  },
  dialog: {
    padding: 0,
    "& .MuiDialog-paper": {
      position: "absolute",
      top: `${theme.spacing(4)} !important`,
    },
    "& .MuiDialogTitle-root": {
      paddingTop: 0,
    },
  },
  dialogContent: {
    width: "100%",
    paddingTop: 0,
    marginTop: -theme.spacing(1),
  },
  optionalTag: {
    color: theme.palette.text.secondary,
  },
  buttons: {
    paddingLeft: theme.spacing(3),
  },
}));

interface IPropsBase {
  open: boolean;
  toggle: () => void;
  actionableItems: ActionableItemSummary[];
  memberId: string;
  episodeId?: string;
  handleOwnerChange?: Function;
}

interface IPropsIsDetailsPageTrue extends IPropsBase {
  isDetailPage: true;
  componentId: string;
}

interface IPropsIsDetailsPageFalse extends IPropsBase {
  isDetailPage?: false;
  componentId?: string;
}

type IProps = IPropsIsDetailsPageTrue | IPropsIsDetailsPageFalse;

interface INullUser {
  name: string;
  id: any;
}

function PaperComponent(props: PaperProps) {
  return <Paper {...props} style={{ padding: "0px", top: "-50px" }} />;
}

const SelectAiOwnerModal: React.FC<IProps> = ({
  open,
  toggle,
  actionableItems,
  memberId,
  episodeId,
  isDetailPage,
  componentId,
  handleOwnerChange,
}) => {
  const [selectedCtm, setSelectedCtm] = useState("");
  const [allCtms, setAllCtms] = useState(new Map());
  const [ctmList, setCtmList] = useState([]);
  const [youUser, setYouUser] = useState([]);

  const dispatch = useDispatch();
  const editingDraft = useSelector(getWorkspace)?.editingDraft;

  const { updateAiOwner } = useUpdateAiOwner();
  const { updateBulkAiOwnership } = useUpdateBulkAiOwnership();
  const { panelCareTeam, isPending } = useGetPanelCareTeam({
    id: memberId,
    memberFacing: true,
  });
  const [, fetchComponent] = useMutation((request: FetchComponentRequest) =>
    fetchComponentRequest(request)
  );
  const [, fetchTimelineEvent] = useMutation((request: string) =>
    fetchTimelineEventRequest(request)
  );
  const user = useSelector(getUser);

  const showToast = (message: string, appearance: "danger" | "success") =>
    dispatch(showSnackbar(message, appearance));

  const classes = useStyles();
  const unclaimedUser: INullUser = {
    name: "None - Select to unclaim",
    id: null,
  };

  const activeItems: ActionableItemSummary[] = actionableItems.filter(
    (item) => item.state === "active" || item.state === "acknowledged_pending"
  );
  const owners = activeItems.map((a) => a.owner?.id);
  const multipleAiText =
    "There is more than 1 action item. All items will be assigned to the new owner.";
  const multipleOwnersText =
    "There are multiple AI owners and/or AI statuses. Go to detail page to change owner.";

  const successMessage =
    activeItems.length > 1
      ? "Success! Action items have a new owner"
      : "Success! Action item has a new owner";

  const error = "Oops! something went wrong. Please try again later.";

  const getUniqueCtms = (arr?: CTMInfo[]) => [
    ...(new Map(
      arr?.map((ctm) => {
        ctm.podId = undefined;
        ctm.careTeamName = undefined;
        return [ctm.id, ctm];
      })
    )?.values() || []),
  ];

  useEffect(() => {
    const ctmMap = new Map();
    ctmMap.set(unclaimedUser.name, unclaimedUser);
    const youEntries: CTMInfo[] = [];
    panelCareTeam?.results?.forEach((ctm: CTMInfo) => {
      if (user?.id?.toString() === ctm?.id?.toString()) {
        ctmMap.set("You", ctm);
        ctm.name = "You";
        youEntries.push(ctm);
      } else {
        ctmMap.set(ctm.name, ctm);
      }
    });
    // @ts-ignore: Object is possibly 'null'.
    setYouUser(getUniqueCtms(youEntries));
    const selected = getOwner();
    // @ts-ignore: Object is possibly 'null'.
    setSelectedCtm(selected);
    setAllCtms(ctmMap);
    const filteredPanelCareTeam = getUniqueCtms(panelCareTeam?.results)?.filter(
      (result: CTMInfo) => result.id !== user?.id?.toString()
    );
    // @ts-ignore: Object is possibly 'null'.
    setCtmList(filteredPanelCareTeam);
  }, [panelCareTeam]);

  const handleCtmChange = (event: any, value: CTMInfo) => {
    setSelectedCtm(value?.name);
    return event;
  };

  const getOwner = () => {
    const firstAi = activeItems[0];
    for (const item of activeItems) {
      if (!item?.owner?.name) {
        return "";
      } else if (
        firstAi?.owner?.name &&
        firstAi?.owner?.name !== item?.owner?.name
      ) {
        return "";
      }
    }
    return firstAi?.owner?.id?.toString() === user?.id?.toString()
      ? "You"
      : firstAi?.owner?.name;
  };

  const getMultipleAiWarning = () => {
    if (
      activeItems.length > 1 &&
      activeItems.every((item) => item.ownershipStatus === "Unclaimed")
    ) {
      return multipleAiText;
    } else if (
      (activeItems.length > 1 &&
        activeItems.every((item) => item.owner?.id === user?.id?.toString())) ||
      (activeItems.length > 1 && owners.every((owner) => owner === owners[0]))
    ) {
      return multipleAiText;
    } else {
      return activeItems.length > 1 ? multipleOwnersText : null;
    }
  };

  const updateEditingDraftAis = (body: any, isBulkUpdate?: boolean) => {
    const updateDraft = Object.assign({}, editingDraft) as any;
    const updatedAis: ActionableItemSummary[] = [];
    if (isBulkUpdate) {
      body.results.map((result: any) =>
        updatedAis.push(ActionableItemSummaryFromJSON(result))
      );
    } else {
      updatedAis.push(ActionableItemSummaryFromJSON(body));
    }
    // @ts-ignore
    editingDraft?.unacknowledgedComponents?.forEach((item: any, index: any) => {
      const correspondingAI = updatedAis?.find(
        (ai: ActionableItemSummary) => ai?.id === item.actionableItems?.[0]?.id
      );
      if (
        correspondingAI &&
        correspondingAI.owner?.id !== item.actionableItems?.[0]?.owner?.id
      ) {
        updateDraft.unacknowledgedComponents[index].actionableItems[0].owner =
          correspondingAI.owner;
        updateDraft.unacknowledgedComponents[
          index
        ].actionableItems[0].ownershipStatus = correspondingAI.ownershipStatus;
      }
    });
    dispatch(
      setEditingDraft({
        ...updateDraft,
      })
    );
  };

  const multipleWarning = getMultipleAiWarning();

  const saveAiOwnership = (actionableItemId: string) => {
    // @ts-ignore: Object is possibly 'null'.
    updateAiOwner(
      {
        ctmAiId: actionableItemId,
        owner: {
          ownerId: allCtms.get(selectedCtm).id,
        },
      },
      {
        onSuccess: (body) => {
          updateEditingDraftAis(body);
          if (isDetailPage && componentId) {
            fetchComponent({ id: componentId })?.catch(() => {
              showToast("Failed to retrieve updated component", "danger");
            });
            showToast(successMessage, "success");
            toggle();
            return;
          } else {
            const updatedAis: ActionableItemSummary[] = [];
            updatedAis.push(ActionableItemSummaryFromJSON(body));
            if (handleOwnerChange) {
              handleOwnerChange(updatedAis);
            }
            showToast(successMessage, "success");
            toggle();
            return;
          }
        },
        onError: () => {
          showToast(error, "danger");
        },
      }
    );
  };

  const saveBulkAiOwnership = () => {
    updateBulkAiOwnership(
      {
        episodeId: episodeId || "",
        owner: {
          ownerId: allCtms.get(selectedCtm)?.id,
        },
      },
      {
        onSuccess: (body) => {
          updateEditingDraftAis(body, true);
          if (isDetailPage) {
            // @ts-ignore: Object is possibly 'null'.
            fetchComponent({ id: componentId }).catch(() => {
              showToast("Failed to retrieve updated component", "danger");
            });
            if (handleOwnerChange) {
              handleOwnerChange();
            }
            showToast(successMessage, "success");
            toggle();
            return;
          } else {
            const updatedAis: ActionableItemSummary[] = body.results.map(
              ActionableItemSummaryFromJSON
            );
            if (handleOwnerChange) {
              handleOwnerChange(updatedAis);
            }
            showToast(successMessage, "success");
            toggle();
            return;
          }
        },
        onError: (updateError: any) => {
          if (updateError.response.status === 409) {
            fetchTimelineEvent(actionableItems[0].timelineEventId)?.then(() => {
              showToast(multipleOwnersText, "danger");
              toggle();
            });
          }
          showToast(updateError, "danger");
        },
      }
    );
  };

  const renderInput = (params: any) => {
    return (
      <TextField
        {...params}
        data-testid="ctm-select-field"
        name="selectedCTM"
        label="Select owner"
        fullWidth
        margin="dense"
        variant="filled"
        value={selectedCtm}
      />
    );
  };

  const save = () => {
    try {
      if (allCtms.has(selectedCtm)) {
        if (activeItems.length > 1) {
          saveBulkAiOwnership();
        } else if (activeItems.length === 1) {
          saveAiOwnership(activeItems[0].id);
        }
      }
    } catch {
      showToast(error, "danger");
      return;
    }
  };

  if (isPending) {
    return (
      <Dialog
        ariaLabelledBy="Ai-ownership-title"
        open={open}
        PaperComponent={PaperComponent}
        className={classes.dialog}
        data-testid="ai-ownership-modal"
      >
        <SelectAiOwnerSkeleton multiple={activeItems?.length > 1} />
      </Dialog>
    );
  }

  return (
    <Dialog
      ariaLabelledBy="Ai-ownership-title"
      open={open}
      PaperComponent={PaperComponent}
      className={classes.dialog}
      data-testid="ai-ownership-modal"
    >
      <Box paddingTop={3}>
        <DialogTitle id="Ai-ownership-title" data-e2e="ai-owner-modal">
          Action Item ownership
        </DialogTitle>
      </Box>
      {multipleWarning && (
        <Alert
          severity="warning"
          style={{ margin: "0px 24px 0px 24px", maxWidth: "350px" }}
        >
          {multipleWarning}
        </Alert>
      )}
      <CardSection bottomDivider style={{ width: "25rem", padding: "0" }}>
        <DialogContent className={classes.dialogContent}>
          <Box marginBottom={3}>
            <Typography appearance="body">Owner</Typography>
            <ProviderComboBox
              data-testid="ctm-select-field"
              unclaimedUser={unclaimedUser}
              youUser={youUser}
              ctmList={ctmList}
              renderInput={renderInput}
              onChange={handleCtmChange}
              value={allCtms.get(selectedCtm)}
            />
          </Box>
        </DialogContent>
      </CardSection>
      <DialogActions horizontal className={classes.buttons}>
        <Button
          color="primary"
          onClick={() => save()}
          disabled={selectedCtm?.length < 1}
          data-testid="save-button"
        >
          Save
        </Button>
        <Button
          color="link-primary"
          data-testid="cancel-button"
          onClick={() => {
            toggle();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectAiOwnerModal;
