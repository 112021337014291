// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     ComponentDetailsOutput,
    ComponentDetailsOutputFromJSON,
    ComponentDetailsOutputToJSON,
} from './ComponentDetailsOutput';
import {
     TimelineEventOutputCore,
    TimelineEventOutputCoreFromJSON,
    TimelineEventOutputCoreToJSON,
} from './TimelineEventOutputCore';
import {
     TimelineEventOutputProps,
    TimelineEventOutputPropsFromJSON,
    TimelineEventOutputPropsToJSON,
} from './TimelineEventOutputProps';
import {
     UserInfo,
    UserInfoFromJSON,
    UserInfoToJSON,
} from './UserInfo';
/**
 * @type TimelineEventOutput
 * @export
 */
export interface TimelineEventOutput extends TimelineEventOutputCore, TimelineEventOutputProps {
}

export function TimelineEventOutputFromJSON(json: any): TimelineEventOutput {
    return {
        ...TimelineEventOutputCoreFromJSON(json),
        ...TimelineEventOutputPropsFromJSON(json),
    };
}

export function TimelineEventOutputToJSON(value?: TimelineEventOutput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...TimelineEventOutputCoreToJSON(value),
        ...TimelineEventOutputPropsToJSON(value),
    };
}

