// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecentToolsItem
 */
export interface RecentToolsItem  {
    /**
     * 
     * @type {number}
     * @memberof RecentToolsItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof RecentToolsItem
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RecentToolsItem
     */
    code: string;
    /**
     * 
     * @type {string}
     * @memberof RecentToolsItem
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof RecentToolsItem
     */
    contentTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof RecentToolsItem
     */
    contentTypeItemId: string;
    /**
     * 
     * @type {string}
     * @memberof RecentToolsItem
     */
    categoryId: string;
    /**
     * 
     * @type {string}
     * @memberof RecentToolsItem
     */
    label: string;
}

export function RecentToolsItemFromJSON(json: any): RecentToolsItem {
    return {
        'id': json['id'],
        'name': json['name'],
        'code': json['code'],
        'createdAt': json['created_at'],
        'contentTypeId': json['content_type_id'],
        'contentTypeItemId': json['content_type_item_id'],
        'categoryId': json['category_id'],
        'label': json['label'],
    };
}

export function RecentToolsItemToJSON(value?: RecentToolsItem): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'code': value.code,
        'created_at': value.createdAt,
        'content_type_id': value.contentTypeId,
        'content_type_item_id': value.contentTypeItemId,
        'category_id': value.categoryId,
        'label': value.label,
    };
}


