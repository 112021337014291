import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Tabs from "components/Tabs";
import Tab from "components/Tabs/Tab";
import CTMQueueFilterTab from "components/CTMQueueFilterModal/CTMQueueFilterTab";
import CTMQueueFilterModal from "components/CTMQueueFilterModal";
import useGetUserSettings from "shared/features/queueFiltering/useGetUserSettings";
import { getUser } from "shared/features/user";
import {
  getInitialSettingsState,
  SettingsState,
  CtmFilterSetting,
  View,
} from "shared/state/ui/settings";
import { useUpdateUserSettings } from "shared/features/queueFiltering/useUpdateUserSettings";
import useFetchMyPodsCareTeamMembers from "shared/features/queueFiltering/useFetchMyPodsCareTeamMembers";
import BadgeFilterTab from "components/BadgeFilterTab";
import { cloneDeep } from "lodash";

interface IProps {
  onChange?: (
    label: string,
    index: number,
    providers: CtmFilterSetting[]
  ) => void;
  isPreventive?: boolean;
  view?: View;
  activeFilterIndex?: number;
  episodes?: any;
  yourEpisodes?: any;
}

const EocFilters: React.FC<IProps> = ({
  onChange,
  isPreventive,
  view,
  activeFilterIndex,
  yourEpisodes,
}) => {
  const filterView = () => {
    if (view === "queue") {
      return view;
    } else {
      return isPreventive ? "proactive" : "episodes";
    }
  };
  const [selectedTab, setSelectedTab] = useState(activeFilterIndex || 0);
  const [previousTab, setPreviousTab] = useState(1);
  const user = useSelector(getUser);
  const { userSettings, isLoading: isUserSettingsLoading } = useGetUserSettings(
    { id: user?.id?.toString() || "" }
  );

  const { data: myPodsCareTeamMembers, isLoading: isCareTeamLoading } =
    useFetchMyPodsCareTeamMembers();
  const isCTMDataFetchComplete = !isUserSettingsLoading && !isCareTeamLoading;

  const [selectedProviders, setSelectedProviders] = React.useState<
    CtmFilterSetting[]
  >([]);
  const [openCtmQueueFilterModal, setOpenCtmQueueFilterModal] =
    React.useState(false);
  const { updateSettings } = useUpdateUserSettings();

  useEffect(() => {
    if (isCTMDataFetchComplete) {
      const userSettingsUpdated: SettingsState = cloneDeep(
        Object.assign({}, getInitialSettingsState(), userSettings)
      );
      const validProviders = userSettings?.filters?.[filterView()]?.ctm?.filter(
        (ctm: CtmFilterSetting) => {
          return !!myPodsCareTeamMembers?.results?.find(
            (careTeamMember) =>
              careTeamMember.id === ctm.id &&
              careTeamMember?.podId?.toString() === ctm.podId
          );
        }
      );
      // @ts-ignore: Object is possibly 'null'.
      userSettingsUpdated.filters[filterView()].ctm = validProviders;
      // @ts-ignore: Object is possibly 'null'.
      updateSettings(user?.id?.toString(), {
        data: JSON.stringify(userSettingsUpdated),
      });
    }
    setSelectedProviders(userSettings?.filters?.[filterView()]?.ctm || []);
  }, [userSettings, isCTMDataFetchComplete]);

  useEffect(() => {
    // @ts-ignore: Object is possibly 'null'.
    setSelectedTab(activeFilterIndex);
  }, [activeFilterIndex]);

  const saveFilter = (index: number) => {
    const currentView = filterView();
    const updatedUserSettings: SettingsState = Object.assign(
      {},
      getInitialSettingsState(),
      userSettings
    );
    if (!openCtmQueueFilterModal) {
      updatedUserSettings.activeFilters[currentView as any] = labels[index];
      updatedUserSettings.filters[view!].ctm = selectedProviders;
      // @ts-ignore: Object is possibly 'null'.
      updateSettings(user.id.toString(), {
        data: JSON.stringify(updatedUserSettings),
      });
    }
  };

  const labels = ["all", "role", "ctm", "you", "archived"];

  const handleChange = (index: any) => {
    saveFilter(index);
    // @ts-ignore: Object is possibly 'null'.
    onChange(labels[index], index, selectedProviders);
  };

  const handleFilterChange = (providers: CtmFilterSetting[]) => {
    saveFilter(2);
    // @ts-ignore: Object is possibly 'null'.
    setSelectedProviders(providers);
    // @ts-ignore: Object is possibly 'null'.
    onChange("ctm", 2, providers);
  };

  const openModal = () => {
    setPreviousTab(selectedTab);
    setSelectedTab(2);
    setOpenCtmQueueFilterModal(!openCtmQueueFilterModal);
  };

  const onCtmModalCancel = () => {
    setSelectedTab(previousTab);
    setOpenCtmQueueFilterModal(!openCtmQueueFilterModal);
  };

  // in the case where the ECT flag is off, then turned to on, persisted settings
  // will not contain pod_ids any longer-- we will hide these from the count until
  // the user makes a new selectedProviders selection.
  const selectedProvidersWithMatchingSchema = selectedProviders.filter(
    (provider) => provider.podId && provider.podId !== "undefined"
  );

  const episodesCount = () =>
    view === "queue" && yourEpisodes ? yourEpisodes?.results?.length : 0;

  return (
    <>
      {openCtmQueueFilterModal && (
        <CTMQueueFilterModal
          open={openCtmQueueFilterModal}
          toggle={() => setOpenCtmQueueFilterModal(!openCtmQueueFilterModal)}
          handleFilterChange={handleFilterChange}
          currentSelectedProviders={selectedProvidersWithMatchingSchema}
          view={filterView()}
          handleModalCancel={onCtmModalCancel}
          // @ts-ignore: Object is possibly 'null'.
          myPodsCareTeamMembers={myPodsCareTeamMembers}
          // @ts-ignore: Object is possibly 'null'.
          userSettings={userSettings}
          isPending={!isCTMDataFetchComplete}
        />
      )}
      <Tabs
        aria-label="filter tabs"
        indicatorColor="primary"
        onChange={(_event, index) => handleChange(index)}
        textColor="secondary"
        value={selectedTab}
        appearance={"pills"}
      >
        <Tab label="All" tabIndex={0} data-testid="all-filter" />
        <Tab label="Role" tabIndex={0} data-testid="role-filter" />
        <CTMQueueFilterTab
          open={openCtmQueueFilterModal}
          onClick={handleChange}
          index={2}
          openModal={openModal}
          selectedProviders={selectedProvidersWithMatchingSchema}
          active={selectedTab === 2}
        />
        <BadgeFilterTab
          index={3}
          active={selectedTab === 3}
          value={selectedTab}
          onClick={handleChange}
          label={"You"}
          episodesCount={episodesCount()}
          // @ts-ignore: Object is possibly 'null'.
          view={view}
        />

        {!isPreventive && view !== "queue" && (
          <Tab data-testid="test-archived" label="Archived" tabIndex={0} />
        )}
      </Tabs>
    </>
  );
};

export default EocFilters;
