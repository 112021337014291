import { EpisodeSummaryCoreOutputStateEnum } from "shared/api/src/models/EpisodeSummaryCoreOutput";
import { State } from "shared/types/state";

export const getActiveEpisodes = (state: State) =>
  state.entities.memberActiveEpisodes?.activeEpisodes;

export const getActionResponse = (state: State) =>
  state.entities.actionResponse;

export const getMemberActiveEpisodes = (state: State, memberId: string) => {
  const episodes =
    state.entities.memberActiveEpisodes?.activeEpisodes?.filter(
      (episode) => episode.state === EpisodeSummaryCoreOutputStateEnum.Active
    ) || [];
  if (episodes?.[0]?.memberInfo?.id !== memberId) {
    return [];
  }
  return episodes;
};

export const getPreventiveEpisode = (state: State) =>
  state.entities.memberActiveEpisodes?.preventiveEpisode;
