// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateUserPasswordRequestBody
 */
export interface UpdateUserPasswordRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPasswordRequestBody
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPasswordRequestBody
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPasswordRequestBody
     */
    newPassword: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPasswordRequestBody
     */
    passwordConfirmation: string;
}

export function UpdateUserPasswordRequestBodyFromJSON(json: any): UpdateUserPasswordRequestBody {
    return {
        'email': json['email'],
        'password': json['password'],
        'newPassword': json['new_password'],
        'passwordConfirmation': json['password_confirmation'],
    };
}

export function UpdateUserPasswordRequestBodyToJSON(value?: UpdateUserPasswordRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'email': value.email,
        'password': value.password,
        'new_password': value.newPassword,
        'password_confirmation': value.passwordConfirmation,
    };
}


