/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    ServiceTypesEligibleArrayResponse,
    ServiceTypesEligibleArrayResponseFromJSON,
    ServiceTypesEligibleArrayResponseToJSON,
} from '../models/ServiceTypesEligibleArrayResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface FetchMemberServiceTypesRequest {
    id: string;
    schedulingType?: FetchMemberServiceTypesSchedulingTypeEnum;
}


export interface IServiceTypesApi {
      fetchMemberServiceTypes: (requestParameters: FetchMemberServiceTypesRequest) => Promise<ServiceTypesEligibleArrayResponse>
      fetchMemberServiceTypesWithResponse: (requestParameters: FetchMemberServiceTypesRequest) => Promise<{ value: ServiceTypesEligibleArrayResponse, response: any}>
}

/**
 * no description
 */
export class ServiceTypesApi extends runtime.BaseAPI {

    /**
     * View the list of service types eligible for a member
     */
    fetchMemberServiceTypesRaw = async (requestParameters: FetchMemberServiceTypesRequest): Promise<{ runtime: runtime.ApiResponse<ServiceTypesEligibleArrayResponse>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling fetchMemberServiceTypes.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.schedulingType !== undefined) {
            queryParameters['scheduling_type'] = requestParameters.schedulingType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/members/{id}/service_types`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ServiceTypesEligibleArrayResponseFromJSON(jsonValue)), response };
    }

    /**
     * View the list of service types eligible for a member
     */
    fetchMemberServiceTypes = async (requestParameters: FetchMemberServiceTypesRequest): Promise<ServiceTypesEligibleArrayResponse> => {
        const { runtime } = await this.fetchMemberServiceTypesRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * View the list of service types eligible for a member
     */
    fetchMemberServiceTypesWithResponse = async (requestParameters: FetchMemberServiceTypesRequest): Promise<{ value: ServiceTypesEligibleArrayResponse, response: any}> => {
        const { runtime, response } = await this.fetchMemberServiceTypesRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

/**
    * @export
    * @enum {string}
    */
export enum FetchMemberServiceTypesSchedulingTypeEnum {
    VirtualVisitReferral = 'virtual_visit_referral',
    CenterVisitReferral = 'center_visit_referral',
    SelfSchedule = 'self_schedule'
}

export const fetchServiceTypesApi: IServiceTypesApi  = new ServiceTypesApi();
