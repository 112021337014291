// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EpisodeQueueSummaryOwner
 */
export interface EpisodeQueueSummaryOwner  {
    /**
     * 
     * @type {string}
     * @memberof EpisodeQueueSummaryOwner
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof EpisodeQueueSummaryOwner
     */
    corePodName?: string;
    /**
     * 
     * @type {string}
     * @memberof EpisodeQueueSummaryOwner
     */
    truncatedName?: string;
}

export function EpisodeQueueSummaryOwnerFromJSON(json: any): EpisodeQueueSummaryOwner {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'corePodName': !exists(json, 'core_pod_name') ? undefined : json['core_pod_name'],
        'truncatedName': !exists(json, 'truncated_name') ? undefined : json['truncated_name'],
    };
}

export function EpisodeQueueSummaryOwnerToJSON(value?: EpisodeQueueSummaryOwner): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'core_pod_name': value.corePodName,
        'truncated_name': value.truncatedName,
    };
}


