// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DocumentArrayInput
 */
export interface DocumentArrayInput  {
    /**
     * 
     * @type {Array<string>}
     * @memberof DocumentArrayInput
     */
    documentIds?: Array<string>;
}

export function DocumentArrayInputFromJSON(json: any): DocumentArrayInput {
    return {
        'documentIds': !exists(json, 'document_ids') ? undefined : json['document_ids'],
    };
}

export function DocumentArrayInputToJSON(value?: DocumentArrayInput): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'document_ids': value.documentIds,
    };
}


