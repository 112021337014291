import { useMutation, useQueryClient } from "react-query";
import { memberAccountInfoQueryKey } from "./useGetAccountInfo";
import { updateEntities } from "redux-query";
import { useDispatch } from "react-redux";
import { MemberUpdateAccountInfoRequestBody } from "shared/fetch/src/models/MemberUpdateAccountInfoRequestBody";
import { fetchUpdateMemberAccountInfoApi } from "shared/fetch/src/apis/UpdateMemberAccountInfoApi";

interface UpdateMemberAccountMutationInput {
  id: string | number;
  body: MemberUpdateAccountInfoRequestBody;
}
const usePatchMemberAccountInfo = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, isLoading } = useMutation(
    ({ id, body }: UpdateMemberAccountMutationInput) => {
      return fetchUpdateMemberAccountInfoApi
        .partialUpdateMemberAccountInfo({
          id: id.toString(),
          memberUpdateAccountInfoRequestBody: body,
        })
        .then((resp) => {
          queryClient.setQueryData(memberAccountInfoQueryKey(id), resp);
          dispatch(
            updateEntities({
              accountInfo: (prev: any) => ({ ...prev, [id]: resp }),
            })
          );

          return resp;
        })
        .finally(() => {
          queryClient.invalidateQueries(memberAccountInfoQueryKey(id));
        });
    }
  );

  return { isLoading, updateUserInfo: mutate };
};

export default usePatchMemberAccountInfo;
