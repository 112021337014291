import { useEffect } from "react";
import { useLocation } from "react-router";
import getPageTitleFromUrl from "utils/getPageTitle";

const useSetPageTitle = () => {
  const location = useLocation();
  const title = getPageTitleFromUrl(location.pathname);
  useEffect(() => {
    document.title = `Crossover Health ${title ? `| ${title}` : ""}`;

    return () => {
      document.title = "Crossover Health";
    };
  }, [title]);
};

export default useSetPageTitle;
