import React, { useEffect } from "react";
import { visuallyHidden } from '@mui/utils';
import Typography from "components/Typography";
import Heading from "components/DynamicHeadings";
import Button from "components/Button";
import Asset from "components/Asset";
import classes from "./index.module.css";
import { Assets } from "@types";
import { selectLoggedInUser } from "shared/features/user/selectors";

interface IProps {
  imageName?: Assets.Image | Assets.SVG | Assets.Icon | string;
  message: string;
  resetError?: any;
}

const ErrorBoundaryPage: React.FunctionComponent<IProps> = ({
  imageName = "no-results",
  message,
  resetError,
}) => {
  const user = selectLoggedInUser();
  const destination = user?.ctm ? "Queue" : "Dashboard";

  const goBack = () => {
    resetError && resetError();
  };

  useEffect(() => {
    const heading = document.getElementById("error-page-heading");
    heading && heading.focus();
  });

  return (
    <div data-e2e="denied-access-page" className={classes.container}>
      <Asset name={imageName} className={classes.image} />
      <div className={classes.textContainer} aria-live="assertive">
        <Heading.H
          appearance="h4"
          id="error-page-heading"
          className={classes.linkNotValid}
        >
          <span style={visuallyHidden}>
            Error:
          </span>
          Something went wrong
        </Heading.H>
        <Typography appearance="body">{message}</Typography>
      </div>
      <Button
        className={classes.goToHomePageLink}
        aria-label={`Go back to ${destination}`}
        // functions as a link althought styled as a button, so we need a button in the DOM
        component="a"
        size="large"
        id="go-back"
        to={"/"}
        onClick={goBack}
        disableFocusRipple
        // Since this page is rendered outside of the MUI Theme Wrapper, our custom <Button />
        // properties do not work as expected, so some styling has to be added via the style
        // property. Basically since we are outside of the custom mui theme wrapper the MUI components
        // used in this file will have the default API of MUI components
        style={{
          paddingRight: "24px",
          paddingLeft: "24px",
          borderRadius: "20px",
          textTransform: "none",
          fontSize: "18px",
        }}
      >
        Go back to {destination}
      </Button>
    </div>
  );
};

export default ErrorBoundaryPage;
