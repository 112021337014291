// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Charge
 */
export interface Charge  {
    /**
     * 
     * @type {string}
     * @memberof Charge
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Charge
     */
    patientId?: string;
    /**
     * 
     * @type {number}
     * @memberof Charge
     */
    amount?: number;
    /**
     * 
     * @type {number}
     * @memberof Charge
     */
    componentId?: number;
    /**
     * 
     * @type {string}
     * @memberof Charge
     */
    status?: ChargeStatusEnum;
    /**
     * 
     * @type {Date}
     * @memberof Charge
     */
    chargeDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Charge
     */
    paidAt?: Date;
}

export function ChargeFromJSON(json: any): Charge {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'patientId': !exists(json, 'patient_id') ? undefined : json['patient_id'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'componentId': !exists(json, 'component_id') ? undefined : json['component_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'chargeDate': !exists(json, 'charge_date') ? undefined : new Date(json['charge_date']),
        'paidAt': !exists(json, 'paid_at') ? undefined : new Date(json['paid_at']),
    };
}

export function ChargeToJSON(value?: Charge): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'patient_id': value.patientId,
        'amount': value.amount,
        'component_id': value.componentId,
        'status': value.status,
        'charge_date': value.chargeDate === undefined ? undefined : value.chargeDate.toISOString(),
        'paid_at': value.paidAt === undefined ? undefined : value.paidAt.toISOString(),
    };
}

/**
* @export
* @enum {string}
*/
export enum ChargeStatusEnum {
    Set = 'set',
    Unpaid = 'unpaid',
    UnpaidError = 'unpaid_error',
    Paid = 'paid',
    Deleted = 'deleted',
    Complete = 'complete',
    ApptCancel = 'appt_cancel'
}


