// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SlataImportLogSchema
 */
export interface SlataImportLogSchema  {
    /**
     * 
     * @type {number}
     * @memberof SlataImportLogSchema
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SlataImportLogSchema
     */
    sheetName?: string;
    /**
     * 
     * @type {string}
     * @memberof SlataImportLogSchema
     */
    importStatus?: SlataImportLogSchemaImportStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof SlataImportLogSchema
     */
    lastImportAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SlataImportLogSchema
     */
    lastSuccessfulImportAt?: string;
    /**
     * 
     * @type {number}
     * @memberof SlataImportLogSchema
     */
    rowsAffected?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SlataImportLogSchema
     */
    importErrors?: Array<string>;
}

export function SlataImportLogSchemaFromJSON(json: any): SlataImportLogSchema {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'sheetName': !exists(json, 'sheet_name') ? undefined : json['sheet_name'],
        'importStatus': !exists(json, 'import_status') ? undefined : json['import_status'],
        'lastImportAt': !exists(json, 'last_import_at') ? undefined : json['last_import_at'],
        'lastSuccessfulImportAt': !exists(json, 'last_successful_import_at') ? undefined : json['last_successful_import_at'],
        'rowsAffected': !exists(json, 'rows_affected') ? undefined : json['rows_affected'],
        'importErrors': !exists(json, 'import_errors') ? undefined : json['import_errors'],
    };
}

export function SlataImportLogSchemaToJSON(value?: SlataImportLogSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'sheet_name': value.sheetName,
        'import_status': value.importStatus,
        'last_import_at': value.lastImportAt,
        'last_successful_import_at': value.lastSuccessfulImportAt,
        'rows_affected': value.rowsAffected,
        'import_errors': value.importErrors,
    };
}

/**
* @export
* @enum {string}
*/
export enum SlataImportLogSchemaImportStatusEnum {
    Queued = 'queued',
    InProgress = 'in_progress',
    Successful = 'successful',
    Failed = 'failed'
}


