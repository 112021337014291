/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ComponentDetailsOutput,
    ComponentDetailsOutputFromJSON,
    ComponentDetailsOutputToJSON,
} from '../models/ComponentDetailsOutput'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    RescheduleAppointment,
    RescheduleAppointmentFromJSON,
    RescheduleAppointmentToJSON,
} from '../models/RescheduleAppointment'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface RescheduleAppointmentOfComponentByIDRequest {
    id: string;
    rescheduleAppointment?: RescheduleAppointment;
}


export interface IRescheduleComponentAppointmentApi {
      rescheduleAppointmentOfComponentByID: (requestParameters: RescheduleAppointmentOfComponentByIDRequest) => Promise<ComponentDetailsOutput>
      rescheduleAppointmentOfComponentByIDWithResponse: (requestParameters: RescheduleAppointmentOfComponentByIDRequest) => Promise<{ value: ComponentDetailsOutput, response: any}>
}

/**
 * no description
 */
export class RescheduleComponentAppointmentApi extends runtime.BaseAPI {

    /**
     * Reschedule the appointment associated with the online visit referral component with the given ID initiated either by Member or CTM, and, migrate the component state accordingly.
     */
    rescheduleAppointmentOfComponentByIDRaw = async (requestParameters: RescheduleAppointmentOfComponentByIDRequest): Promise<{ runtime: runtime.ApiResponse<ComponentDetailsOutput>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling rescheduleAppointmentOfComponentByID.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/{id}/reschedule`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RescheduleAppointmentToJSON(requestParameters.rescheduleAppointment),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ComponentDetailsOutputFromJSON(jsonValue)), response };
    }

    /**
     * Reschedule the appointment associated with the online visit referral component with the given ID initiated either by Member or CTM, and, migrate the component state accordingly.
     */
    rescheduleAppointmentOfComponentByID = async (requestParameters: RescheduleAppointmentOfComponentByIDRequest): Promise<ComponentDetailsOutput> => {
        const { runtime } = await this.rescheduleAppointmentOfComponentByIDRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Reschedule the appointment associated with the online visit referral component with the given ID initiated either by Member or CTM, and, migrate the component state accordingly.
     */
    rescheduleAppointmentOfComponentByIDWithResponse = async (requestParameters: RescheduleAppointmentOfComponentByIDRequest): Promise<{ value: ComponentDetailsOutput, response: any}> => {
        const { runtime, response } = await this.rescheduleAppointmentOfComponentByIDRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchRescheduleComponentAppointmentApi: IRescheduleComponentAppointmentApi  = new RescheduleComponentAppointmentApi();
