import React, { FunctionComponent } from "react";
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import DynamicHeading from "components/DynamicHeadings";
import Box from "components/Box";
import Avatar from "components/Avatar";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      alignItems: "center",
      padding: "12px 15px 12px 0px",
      [theme.breakpoints.down('md')]: {
        paddingLeft: 15,
      },
    },
    profileLink: {
      borderBottom: "none",
      textDecoration: "none",
      margin: 0,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      "&:hover": {
        borderBottom: "none",
        textDecoration: "none",
      },
    },
    profileInfo: {
      display: "flex",
      margin: "0 0 0 16px",
      textAlign: "unset",
      flexDirection: "column",
      color: theme.palette.text.primary,
      [theme.breakpoints.down('md')]: {
        textAlign: "unset",
      },
    },
    profileTitle: {
      color: theme?.palette?.appBackground?.black,
      [theme.breakpoints.down('sm')]: {
        fontSize: "11.1px",
      },
    },
    profileSubTitleGray: {
      display: "block",
      color: theme.palette.text.secondary,
      [theme.breakpoints.down('sm')]: {
        fontSize: "11.1px",
      },
    },
    careTeamName: {
      fontSize: theme.typography.caption.fontSize,
    },
  })
);

interface IProps {
  name: string;
  titles?: string[];
  abbreviation?: string;
  avatarUrl?: string;
  careTeamName?: string;
  searchInputValue?: string;
  size?: "xxxl" | "large" | "xxl" | "small" | "medium";
}

const ContactCard: FunctionComponent<IProps> = ({
  name,
  titles,
  abbreviation,
  avatarUrl,
  careTeamName,
  searchInputValue,
  size = "small",
}) => {
  const classes = useStyles();
  const guild = titles?.[0];

  // highlight user input and truncate long names when used to render a combobox dropdown list
  const nameMaxLength = 26;

  const nameMatches = match(name, searchInputValue!);
  const nameParts = parse(
    name?.length > nameMaxLength
      ? name.substring(0, nameMaxLength) + "..."
      : name,
    nameMatches
  );
  const titleMatches = match(titles?.[0]!, searchInputValue!);
  const titleParts = parse(titles?.[0]!, titleMatches);

  const careTeamMatches = match(careTeamName!, searchInputValue!);
  const careTeamParts = parse(careTeamName!, careTeamMatches);

  const profileInfo = (
    <>
      <Avatar
        containerStyle={{ marginLeft: "10px" }}
        alt={name}
        src={avatarUrl}
        size={size}
      />
      <div className={classes.profileInfo}>
        <DynamicHeading.H
          appearance="smallBody"
          className={classes.profileTitle}
        >
          {(nameParts || []).map((part: any, index: any) => (
            <span
              key={index}
              style={{ fontWeight: part?.highlight ? "bold" : "normal" }}
            >
              {part?.text}
            </span>
          ))}
        </DynamicHeading.H>
        <DynamicHeading.H
          appearance="smallBody"
          className={classes.profileSubTitleGray}
        >
          {(titleParts || []).map((part: any, index: any) => (
            <span
              key={index}
              style={{ fontWeight: part?.highlight ? "bold" : "normal" }}
            >
              {part?.text}
            </span>
          ))}{" "}
          {guild && abbreviation && ` - ${abbreviation}`}
        </DynamicHeading.H>
        {!!careTeamName && (
          <DynamicHeading.H
            appearance="smallBody"
            color="textSecondary"
            className={classes.careTeamName}
          >
            {(careTeamParts || []).map((part: any, index: any) => (
              <span
                key={index}
                style={{ fontWeight: part?.highlight ? "bold" : "normal" }}
              >
                {part?.text}
              </span>
            ))}
          </DynamicHeading.H>
        )}
      </div>
    </>
  );

  return (
    <Box className={classes.container} data-testid="ct-member">
      <div className={classes.profileLink}>{profileInfo}</div>
    </Box>
  );
};

export default ContactCard;
