// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { HttpMethods, QueryConfig, ResponseBody, ResponseText } from 'redux-query';
import * as runtime from '../runtime';

import {
    ConversationSummariesResponse,
    ConversationSummariesResponseFromJSON,
    ConversationSummariesResponseToJSON,
} from '../models/ConversationSummariesResponse'
import {
    EpisodeQueueSummariesResponse,
    EpisodeQueueSummariesResponseFromJSON,
    EpisodeQueueSummariesResponseToJSON,
} from '../models/EpisodeQueueSummariesResponse'
import {
    EpisodeSummariesResponse,
    EpisodeSummariesResponseFromJSON,
    EpisodeSummariesResponseToJSON,
} from '../models/EpisodeSummariesResponse'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface FetchActiveEpisodeListSummariesRequest {
    podIds: string;
    roleIds: string;
    ifModifiedSince?: object;
    tab?: FetchActiveEpisodeListSummariesTabEnum;
    page?: string;
    pageSize?: string;
}

export interface FetchArchivedEpisodeListSummariesRequest {
    podIds: string;
    roleIds: string;
    ifModifiedSince?: object;
}

export interface FetchCareTeamQueueEpisodeSummariesRequest {
    careTeamId: string;
    roleIds: string;
    ifModifiedSince?: object;
}

export interface FetchPodsQueueEpisodeSummariesRequest {
    podIds: string;
    roleIds: string;
    ifModifiedSince?: object;
}

export interface GetMyActiveEpisodesRequest {
    ownedOrClaimedByCtms?: string;
    yours?: string;
    page?: string;
    yourActionItems?: string;
    yourEpisodes?: string;
    notYourEpisodes?: string;
    yourRole?: string;
    tab?: string;
    havePodIds?: string;
    ownedByCtmIdsAndPodIds?: string;
    order?: string;
}

export interface GetMyArchivedEpisodesRequest {
    page?: string;
    order?: string;
}

export interface GetMyQueueRequest {
    ownedOrClaimedByCtms?: string;
    yours?: string;
    yourActionItems?: string;
    yourEpisodes?: string;
    notYourEpisodes?: string;
    yourRole?: string;
    havePodIds?: string;
    ownedByCtmIdsAndPodIds?: string;
    order?: GetMyQueueOrderEnum;
    page?: string;
}

export interface ListMyPreventiveEpisodesRequest {
    tab?: ListMyPreventiveEpisodesTabEnum;
    page?: string;
    ownedOrClaimedByCtms?: string;
    yours?: string;
    yourActionItems?: string;
    yourEpisodes?: string;
    notYourEpisodes?: string;
    yourRole?: string;
    havePodIds?: string;
    ownedByCtmIdsAndPodIds?: string;
    order?: string;
}

export interface ListPreventiveEpisodeListSummariesRequest {
    podIds: string;
    roleIds: string;
    ifModifiedSince?: object;
    tab?: ListPreventiveEpisodeListSummariesTabEnum;
    page?: string;
    pageSize?: string;
}

export interface MemberArchivedEpisodeListSummariesRequest {
    id: string;
    ifModifiedSince?: object;
}

export interface MemberConversationListSummariesRequest {
    id: string;
    ifModifiedSince?: object;
    pageSize?: string;
    page?: string;
    state?: MemberConversationListSummariesStateEnum;
}

export interface MemberEpisodeListSummariesRequest {
    id: string;
    ifModifiedSince?: object;
}


/**
 * Fetch summaries for the Active Episode list
 * Care Team active episode summaries
 */
function fetchActiveEpisodeListSummariesRaw<T>(requestParameters: FetchActiveEpisodeListSummariesRequest, requestConfig: runtime.TypedQueryConfig<T, EpisodeSummariesResponse> = {}): QueryConfig<T> {
    if (requestParameters.podIds === null || requestParameters.podIds === undefined) {
        throw new runtime.RequiredError('podIds','Required parameter requestParameters.podIds was null or undefined when calling fetchActiveEpisodeListSummaries.');
    }

    if (requestParameters.roleIds === null || requestParameters.roleIds === undefined) {
        throw new runtime.RequiredError('roleIds','Required parameter requestParameters.roleIds was null or undefined when calling fetchActiveEpisodeListSummaries.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.tab !== undefined) {
        queryParameters['tab'] = requestParameters.tab;
    }


    if (requestParameters.page !== undefined) {
        queryParameters['page'] = requestParameters.page;
    }


    if (requestParameters.pageSize !== undefined) {
        queryParameters['page_size'] = requestParameters.pageSize;
    }

    const headerParameters = {};

    if (requestParameters.ifModifiedSince !== undefined && requestParameters.ifModifiedSince !== null) {
        headerParameters['If-Modified-Since'] = String(requestParameters.ifModifiedSince);
    }


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "ctm"]];
    const config: QueryConfig<T> = {
        url: `/v1/pods/{pod_ids}/roles/{role_ids}/active_episodes`.replace(`{${"pod_ids"}}`, encodeURIComponent(String(requestParameters.podIds))).replace(`{${"role_ids"}}`, encodeURIComponent(String(requestParameters.roleIds))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EpisodeSummariesResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch summaries for the Active Episode list
* Care Team active episode summaries
*/
export function fetchActiveEpisodeListSummaries<T>(requestParameters: FetchActiveEpisodeListSummariesRequest, requestConfig?: runtime.TypedQueryConfig<T, EpisodeSummariesResponse>): QueryConfig<T> {
    return fetchActiveEpisodeListSummariesRaw(requestParameters, requestConfig);
}

/**
 * Fetch summaries for the Archived Episode list
 * Care Team archived episode summaries
 */
function fetchArchivedEpisodeListSummariesRaw<T>(requestParameters: FetchArchivedEpisodeListSummariesRequest, requestConfig: runtime.TypedQueryConfig<T, EpisodeSummariesResponse> = {}): QueryConfig<T> {
    if (requestParameters.podIds === null || requestParameters.podIds === undefined) {
        throw new runtime.RequiredError('podIds','Required parameter requestParameters.podIds was null or undefined when calling fetchArchivedEpisodeListSummaries.');
    }

    if (requestParameters.roleIds === null || requestParameters.roleIds === undefined) {
        throw new runtime.RequiredError('roleIds','Required parameter requestParameters.roleIds was null or undefined when calling fetchArchivedEpisodeListSummaries.');
    }

    let queryParameters = null;


    const headerParameters = {};

    if (requestParameters.ifModifiedSince !== undefined && requestParameters.ifModifiedSince !== null) {
        headerParameters['If-Modified-Since'] = String(requestParameters.ifModifiedSince);
    }


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "ctm"]];
    const config: QueryConfig<T> = {
        url: `/v1/pods/{pod_ids}/roles/{role_ids}/archived_episodes`.replace(`{${"pod_ids"}}`, encodeURIComponent(String(requestParameters.podIds))).replace(`{${"role_ids"}}`, encodeURIComponent(String(requestParameters.roleIds))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EpisodeSummariesResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch summaries for the Archived Episode list
* Care Team archived episode summaries
*/
export function fetchArchivedEpisodeListSummaries<T>(requestParameters: FetchArchivedEpisodeListSummariesRequest, requestConfig?: runtime.TypedQueryConfig<T, EpisodeSummariesResponse>): QueryConfig<T> {
    return fetchArchivedEpisodeListSummariesRaw(requestParameters, requestConfig);
}

/**
 * Fetch episode summaries for the Care Team Queue
 * Care Team Queue episode summaries
 */
function fetchCareTeamQueueEpisodeSummariesRaw<T>(requestParameters: FetchCareTeamQueueEpisodeSummariesRequest, requestConfig: runtime.TypedQueryConfig<T, EpisodeSummariesResponse> = {}): QueryConfig<T> {
    if (requestParameters.careTeamId === null || requestParameters.careTeamId === undefined) {
        throw new runtime.RequiredError('careTeamId','Required parameter requestParameters.careTeamId was null or undefined when calling fetchCareTeamQueueEpisodeSummaries.');
    }

    if (requestParameters.roleIds === null || requestParameters.roleIds === undefined) {
        throw new runtime.RequiredError('roleIds','Required parameter requestParameters.roleIds was null or undefined when calling fetchCareTeamQueueEpisodeSummaries.');
    }

    let queryParameters = null;


    const headerParameters = {};

    if (requestParameters.ifModifiedSince !== undefined && requestParameters.ifModifiedSince !== null) {
        headerParameters['If-Modified-Since'] = String(requestParameters.ifModifiedSince);
    }


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "ctm"]];
    const config: QueryConfig<T> = {
        url: `/v1/care_teams/{care_team_id}/roles/{role_ids}/queue`.replace(`{${"care_team_id"}}`, encodeURIComponent(String(requestParameters.careTeamId))).replace(`{${"role_ids"}}`, encodeURIComponent(String(requestParameters.roleIds))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EpisodeSummariesResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch episode summaries for the Care Team Queue
* Care Team Queue episode summaries
*/
export function fetchCareTeamQueueEpisodeSummaries<T>(requestParameters: FetchCareTeamQueueEpisodeSummariesRequest, requestConfig?: runtime.TypedQueryConfig<T, EpisodeSummariesResponse>): QueryConfig<T> {
    return fetchCareTeamQueueEpisodeSummariesRaw(requestParameters, requestConfig);
}

/**
 * Fetch episode summaries for the Care Team Queue
 * Pods Queue episode summaries
 */
function fetchPodsQueueEpisodeSummariesRaw<T>(requestParameters: FetchPodsQueueEpisodeSummariesRequest, requestConfig: runtime.TypedQueryConfig<T, EpisodeQueueSummariesResponse> = {}): QueryConfig<T> {
    if (requestParameters.podIds === null || requestParameters.podIds === undefined) {
        throw new runtime.RequiredError('podIds','Required parameter requestParameters.podIds was null or undefined when calling fetchPodsQueueEpisodeSummaries.');
    }

    if (requestParameters.roleIds === null || requestParameters.roleIds === undefined) {
        throw new runtime.RequiredError('roleIds','Required parameter requestParameters.roleIds was null or undefined when calling fetchPodsQueueEpisodeSummaries.');
    }

    let queryParameters = null;


    const headerParameters = {};

    if (requestParameters.ifModifiedSince !== undefined && requestParameters.ifModifiedSince !== null) {
        headerParameters['If-Modified-Since'] = String(requestParameters.ifModifiedSince);
    }


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "ctm"]];
    const config: QueryConfig<T> = {
        url: `/v1/pods/{pod_ids}/roles/{role_ids}/queue`.replace(`{${"pod_ids"}}`, encodeURIComponent(String(requestParameters.podIds))).replace(`{${"role_ids"}}`, encodeURIComponent(String(requestParameters.roleIds))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EpisodeQueueSummariesResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch episode summaries for the Care Team Queue
* Pods Queue episode summaries
*/
export function fetchPodsQueueEpisodeSummaries<T>(requestParameters: FetchPodsQueueEpisodeSummariesRequest, requestConfig?: runtime.TypedQueryConfig<T, EpisodeQueueSummariesResponse>): QueryConfig<T> {
    return fetchPodsQueueEpisodeSummariesRaw(requestParameters, requestConfig);
}

/**
 * Get active episodes, including `Content-Location` header for source of list.
 * List of active episodes
 */
function getMyActiveEpisodesRaw<T>(requestParameters: GetMyActiveEpisodesRequest, requestConfig: runtime.TypedQueryConfig<T, EpisodeSummariesResponse> = {}): QueryConfig<T> {
    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.ownedOrClaimedByCtms !== undefined) {
        queryParameters['owned_or_claimed_by_ctms'] = requestParameters.ownedOrClaimedByCtms;
    }


    if (requestParameters.yours !== undefined) {
        queryParameters['yours'] = requestParameters.yours;
    }


    if (requestParameters.page !== undefined) {
        queryParameters['page'] = requestParameters.page;
    }


    if (requestParameters.yourActionItems !== undefined) {
        queryParameters['your_action_items'] = requestParameters.yourActionItems;
    }


    if (requestParameters.yourEpisodes !== undefined) {
        queryParameters['your_episodes'] = requestParameters.yourEpisodes;
    }


    if (requestParameters.notYourEpisodes !== undefined) {
        queryParameters['not_your_episodes'] = requestParameters.notYourEpisodes;
    }


    if (requestParameters.yourRole !== undefined) {
        queryParameters['your_role'] = requestParameters.yourRole;
    }


    if (requestParameters.tab !== undefined) {
        queryParameters['tab'] = requestParameters.tab;
    }


    if (requestParameters.havePodIds !== undefined) {
        queryParameters['have_pod_ids'] = requestParameters.havePodIds;
    }


    if (requestParameters.ownedByCtmIdsAndPodIds !== undefined) {
        queryParameters['owned_by_ctm_ids_and_pod_ids'] = requestParameters.ownedByCtmIdsAndPodIds;
    }


    if (requestParameters.order !== undefined) {
        queryParameters['order'] = requestParameters.order;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "ctm"]];
    const config: QueryConfig<T> = {
        url: `/v1/my_active_episodes`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EpisodeSummariesResponseFromJSON(body), text);
    }

    return config;
}

/**
* Get active episodes, including `Content-Location` header for source of list.
* List of active episodes
*/
export function getMyActiveEpisodes<T>(requestParameters: GetMyActiveEpisodesRequest, requestConfig?: runtime.TypedQueryConfig<T, EpisodeSummariesResponse>): QueryConfig<T> {
    return getMyActiveEpisodesRaw(requestParameters, requestConfig);
}

/**
 * Get active episodes, including `Content-Location` header for source of list.
 * List of active episodes
 */
function getMyArchivedEpisodesRaw<T>(requestParameters: GetMyArchivedEpisodesRequest, requestConfig: runtime.TypedQueryConfig<T, EpisodeSummariesResponse> = {}): QueryConfig<T> {
    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.page !== undefined) {
        queryParameters['page'] = requestParameters.page;
    }


    if (requestParameters.order !== undefined) {
        queryParameters['order'] = requestParameters.order;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "ctm"]];
    const config: QueryConfig<T> = {
        url: `/v1/my_archived_episodes`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EpisodeSummariesResponseFromJSON(body), text);
    }

    return config;
}

/**
* Get active episodes, including `Content-Location` header for source of list.
* List of active episodes
*/
export function getMyArchivedEpisodes<T>(requestParameters: GetMyArchivedEpisodesRequest, requestConfig?: runtime.TypedQueryConfig<T, EpisodeSummariesResponse>): QueryConfig<T> {
    return getMyArchivedEpisodesRaw(requestParameters, requestConfig);
}

/**
 * Get episodes from my queue, including `Content-Location` header for source of list.
 * Get episodes from my queue
 */
function getMyQueueRaw<T>(requestParameters: GetMyQueueRequest, requestConfig: runtime.TypedQueryConfig<T, EpisodeQueueSummariesResponse> = {}): QueryConfig<T> {
    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.ownedOrClaimedByCtms !== undefined) {
        queryParameters['owned_or_claimed_by_ctms'] = requestParameters.ownedOrClaimedByCtms;
    }


    if (requestParameters.yours !== undefined) {
        queryParameters['yours'] = requestParameters.yours;
    }


    if (requestParameters.yourActionItems !== undefined) {
        queryParameters['your_action_items'] = requestParameters.yourActionItems;
    }


    if (requestParameters.yourEpisodes !== undefined) {
        queryParameters['your_episodes'] = requestParameters.yourEpisodes;
    }


    if (requestParameters.notYourEpisodes !== undefined) {
        queryParameters['not_your_episodes'] = requestParameters.notYourEpisodes;
    }


    if (requestParameters.yourRole !== undefined) {
        queryParameters['your_role'] = requestParameters.yourRole;
    }


    if (requestParameters.havePodIds !== undefined) {
        queryParameters['have_pod_ids'] = requestParameters.havePodIds;
    }


    if (requestParameters.ownedByCtmIdsAndPodIds !== undefined) {
        queryParameters['owned_by_ctm_ids_and_pod_ids'] = requestParameters.ownedByCtmIdsAndPodIds;
    }


    if (requestParameters.order !== undefined) {
        queryParameters['order'] = requestParameters.order;
    }


    if (requestParameters.page !== undefined) {
        queryParameters['page'] = requestParameters.page;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "ctm"]];
    const config: QueryConfig<T> = {
        url: `/v1/my_queue`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EpisodeQueueSummariesResponseFromJSON(body), text);
    }

    return config;
}

/**
* Get episodes from my queue, including `Content-Location` header for source of list.
* Get episodes from my queue
*/
export function getMyQueue<T>(requestParameters: GetMyQueueRequest, requestConfig?: runtime.TypedQueryConfig<T, EpisodeQueueSummariesResponse>): QueryConfig<T> {
    return getMyQueueRaw(requestParameters, requestConfig);
}

/**
 * Get preventive episodes, including `Content-Location` header for source of list.
 * List of preventive episodes
 */
function listMyPreventiveEpisodesRaw<T>(requestParameters: ListMyPreventiveEpisodesRequest, requestConfig: runtime.TypedQueryConfig<T, EpisodeSummariesResponse> = {}): QueryConfig<T> {
    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.tab !== undefined) {
        queryParameters['tab'] = requestParameters.tab;
    }


    if (requestParameters.page !== undefined) {
        queryParameters['page'] = requestParameters.page;
    }


    if (requestParameters.ownedOrClaimedByCtms !== undefined) {
        queryParameters['owned_or_claimed_by_ctms'] = requestParameters.ownedOrClaimedByCtms;
    }


    if (requestParameters.yours !== undefined) {
        queryParameters['yours'] = requestParameters.yours;
    }


    if (requestParameters.yourActionItems !== undefined) {
        queryParameters['your_action_items'] = requestParameters.yourActionItems;
    }


    if (requestParameters.yourEpisodes !== undefined) {
        queryParameters['your_episodes'] = requestParameters.yourEpisodes;
    }


    if (requestParameters.notYourEpisodes !== undefined) {
        queryParameters['not_your_episodes'] = requestParameters.notYourEpisodes;
    }


    if (requestParameters.yourRole !== undefined) {
        queryParameters['your_role'] = requestParameters.yourRole;
    }


    if (requestParameters.havePodIds !== undefined) {
        queryParameters['have_pod_ids'] = requestParameters.havePodIds;
    }


    if (requestParameters.ownedByCtmIdsAndPodIds !== undefined) {
        queryParameters['owned_by_ctm_ids_and_pod_ids'] = requestParameters.ownedByCtmIdsAndPodIds;
    }


    if (requestParameters.order !== undefined) {
        queryParameters['order'] = requestParameters.order;
    }

    const headerParameters = {};


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "ctm"]];
    const config: QueryConfig<T> = {
        url: `/v1/my_preventive_episodes`,
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EpisodeSummariesResponseFromJSON(body), text);
    }

    return config;
}

/**
* Get preventive episodes, including `Content-Location` header for source of list.
* List of preventive episodes
*/
export function listMyPreventiveEpisodes<T>(requestParameters: ListMyPreventiveEpisodesRequest, requestConfig?: runtime.TypedQueryConfig<T, EpisodeSummariesResponse>): QueryConfig<T> {
    return listMyPreventiveEpisodesRaw(requestParameters, requestConfig);
}

/**
 * Fetch summaries for the preventive Episode list
 * Care Team preventive episodes summaries
 */
function listPreventiveEpisodeListSummariesRaw<T>(requestParameters: ListPreventiveEpisodeListSummariesRequest, requestConfig: runtime.TypedQueryConfig<T, EpisodeSummariesResponse> = {}): QueryConfig<T> {
    if (requestParameters.podIds === null || requestParameters.podIds === undefined) {
        throw new runtime.RequiredError('podIds','Required parameter requestParameters.podIds was null or undefined when calling listPreventiveEpisodeListSummaries.');
    }

    if (requestParameters.roleIds === null || requestParameters.roleIds === undefined) {
        throw new runtime.RequiredError('roleIds','Required parameter requestParameters.roleIds was null or undefined when calling listPreventiveEpisodeListSummaries.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.tab !== undefined) {
        queryParameters['tab'] = requestParameters.tab;
    }


    if (requestParameters.page !== undefined) {
        queryParameters['page'] = requestParameters.page;
    }


    if (requestParameters.pageSize !== undefined) {
        queryParameters['page_size'] = requestParameters.pageSize;
    }

    const headerParameters = {};

    if (requestParameters.ifModifiedSince !== undefined && requestParameters.ifModifiedSince !== null) {
        headerParameters['If-Modified-Since'] = String(requestParameters.ifModifiedSince);
    }


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "ctm"]];
    const config: QueryConfig<T> = {
        url: `/v1/pods/{pod_ids}/roles/{role_ids}/preventive_episodes`.replace(`{${"pod_ids"}}`, encodeURIComponent(String(requestParameters.podIds))).replace(`{${"role_ids"}}`, encodeURIComponent(String(requestParameters.roleIds))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EpisodeSummariesResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch summaries for the preventive Episode list
* Care Team preventive episodes summaries
*/
export function listPreventiveEpisodeListSummaries<T>(requestParameters: ListPreventiveEpisodeListSummariesRequest, requestConfig?: runtime.TypedQueryConfig<T, EpisodeSummariesResponse>): QueryConfig<T> {
    return listPreventiveEpisodeListSummariesRaw(requestParameters, requestConfig);
}

/**
 * Fetch summaries for the Member Archived Episode list
 * Member archived episode summaries
 */
function memberArchivedEpisodeListSummariesRaw<T>(requestParameters: MemberArchivedEpisodeListSummariesRequest, requestConfig: runtime.TypedQueryConfig<T, EpisodeSummariesResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling memberArchivedEpisodeListSummaries.');
    }

    let queryParameters = null;


    const headerParameters = {};

    if (requestParameters.ifModifiedSince !== undefined && requestParameters.ifModifiedSince !== null) {
        headerParameters['If-Modified-Since'] = String(requestParameters.ifModifiedSince);
    }


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "member"]];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/archived_episodes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EpisodeSummariesResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch summaries for the Member Archived Episode list
* Member archived episode summaries
*/
export function memberArchivedEpisodeListSummaries<T>(requestParameters: MemberArchivedEpisodeListSummariesRequest, requestConfig?: runtime.TypedQueryConfig<T, EpisodeSummariesResponse>): QueryConfig<T> {
    return memberArchivedEpisodeListSummariesRaw(requestParameters, requestConfig);
}

/**
 * Fetch summaries for the Member Episode list
 * Member conversation summaries
 */
function memberConversationListSummariesRaw<T>(requestParameters: MemberConversationListSummariesRequest, requestConfig: runtime.TypedQueryConfig<T, ConversationSummariesResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling memberConversationListSummaries.');
    }

    let queryParameters = null;

    queryParameters = {};


    if (requestParameters.pageSize !== undefined) {
        queryParameters['page_size'] = requestParameters.pageSize;
    }


    if (requestParameters.page !== undefined) {
        queryParameters['page'] = requestParameters.page;
    }


    if (requestParameters.state !== undefined) {
        queryParameters['state'] = requestParameters.state;
    }

    const headerParameters = {};

    if (requestParameters.ifModifiedSince !== undefined && requestParameters.ifModifiedSince !== null) {
        headerParameters['If-Modified-Since'] = String(requestParameters.ifModifiedSince);
    }


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "member"]];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/conversations`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(ConversationSummariesResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch summaries for the Member Episode list
* Member conversation summaries
*/
export function memberConversationListSummaries<T>(requestParameters: MemberConversationListSummariesRequest, requestConfig?: runtime.TypedQueryConfig<T, ConversationSummariesResponse>): QueryConfig<T> {
    return memberConversationListSummariesRaw(requestParameters, requestConfig);
}

/**
 * Fetch summaries for the Member Episode list
 * Member episode summaries
 */
function memberEpisodeListSummariesRaw<T>(requestParameters: MemberEpisodeListSummariesRequest, requestConfig: runtime.TypedQueryConfig<T, EpisodeSummariesResponse> = {}): QueryConfig<T> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
        throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling memberEpisodeListSummaries.');
    }

    let queryParameters = null;


    const headerParameters = {};

    if (requestParameters.ifModifiedSince !== undefined && requestParameters.ifModifiedSince !== null) {
        headerParameters['If-Modified-Since'] = String(requestParameters.ifModifiedSince);
    }


    const { meta = {} } = requestConfig;

    meta.authType = ['oauth', ["episodes.read", "member"]];
    const config: QueryConfig<T> = {
        url: `/v1/members/{id}/episodes`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
        meta,
        update: requestConfig.update,
        queryKey: requestConfig.queryKey,
        optimisticUpdate: requestConfig.optimisticUpdate,
        force: requestConfig.force,
        rollback: requestConfig.rollback,
        options: {
            method: 'GET',
            headers: headerParameters,
        },
        body: queryParameters,
    };

    const { transform: requestTransform } = requestConfig;
    if (requestTransform) {
        config.transform = (body: ResponseBody, text: ResponseBody) => requestTransform(EpisodeSummariesResponseFromJSON(body), text);
    }

    return config;
}

/**
* Fetch summaries for the Member Episode list
* Member episode summaries
*/
export function memberEpisodeListSummaries<T>(requestParameters: MemberEpisodeListSummariesRequest, requestConfig?: runtime.TypedQueryConfig<T, EpisodeSummariesResponse>): QueryConfig<T> {
    return memberEpisodeListSummariesRaw(requestParameters, requestConfig);
}


/**
    * @export
    * @enum {string}
    */
export enum FetchActiveEpisodeListSummariesTabEnum {
    You = 'you',
    Team = 'team'
}
/**
    * @export
    * @enum {string}
    */
export enum GetMyQueueOrderEnum {
    ASC = 'ASC',
    DESC = 'DESC'
}
/**
    * @export
    * @enum {string}
    */
export enum ListMyPreventiveEpisodesTabEnum {
    You = 'you',
    Team = 'team'
}
/**
    * @export
    * @enum {string}
    */
export enum ListPreventiveEpisodeListSummariesTabEnum {
    You = 'you',
    Team = 'team'
}
/**
    * @export
    * @enum {string}
    */
export enum MemberConversationListSummariesStateEnum {
    Active = 'active',
    Archived = 'archived',
    Suspended = 'suspended'
}
