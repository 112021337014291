// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AllFeatureFlags
 */
export interface AllFeatureFlags  {
    /**
     * 
     * @type {{ [key: string]: object; }}
     * @memberof AllFeatureFlags
     */
    results?: { [key: string]: object; };
}

export function AllFeatureFlagsFromJSON(json: any): AllFeatureFlags {
    return {
        'results': !exists(json, 'results') ? undefined : json['results'],
    };
}

export function AllFeatureFlagsToJSON(value?: AllFeatureFlags): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': value.results,
    };
}


