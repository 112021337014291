// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     DeletePaymentMethodSchemaAllOf,
    DeletePaymentMethodSchemaAllOfFromJSON,
    DeletePaymentMethodSchemaAllOfToJSON,
} from './DeletePaymentMethodSchemaAllOf';
import {
     PostPaymentMethodSchema,
    PostPaymentMethodSchemaFromJSON,
    PostPaymentMethodSchemaToJSON,
} from './PostPaymentMethodSchema';
/**
 * @type DeletePaymentMethodSchema
 * @export
 */
export interface DeletePaymentMethodSchema extends DeletePaymentMethodSchemaAllOf, PostPaymentMethodSchema {
}

export function DeletePaymentMethodSchemaFromJSON(json: any): DeletePaymentMethodSchema {
    return {
        ...DeletePaymentMethodSchemaAllOfFromJSON(json),
        ...PostPaymentMethodSchemaFromJSON(json),
    };
}

export function DeletePaymentMethodSchemaToJSON(value?: DeletePaymentMethodSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...DeletePaymentMethodSchemaAllOfToJSON(value),
        ...PostPaymentMethodSchemaToJSON(value),
    };
}

