const SET_CTM_SIDEBAR_MEMBER_ID = "SET_CTM_SIDEBAR_MEMBER_ID";
const CLEAR_CTM_SIDEBAR_MEMBER_ID = "CLEAR_CTM_SIDEBAR_MEMBER_ID";

export interface State {
  memberId?: string | null | undefined;
}

export const ctmSidebar = (state: State = {}, action: any): State => {
  switch (action.type) {
    case SET_CTM_SIDEBAR_MEMBER_ID:
      return {
        ...state,
        memberId: action.memberId,
      };

    case CLEAR_CTM_SIDEBAR_MEMBER_ID:
      return {
        ...state,
        memberId: null,
      };

    default:
      return state;
  }
};

export const setCtmSidebarMemberId = (memberId: string | null | undefined) => {
  return { type: SET_CTM_SIDEBAR_MEMBER_ID, memberId };
};

export const clearCtmSidebarMemberId = () => {
  return { type: CLEAR_CTM_SIDEBAR_MEMBER_ID };
};

/**
 * Gets memberId state from storage.
 */
export const getInitialCtmSidebar = (): State => {
  return {
    memberId: null,
  };
};
