import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { State } from "@types";
import { checkAuthentication, logout } from "shared/features/auth/utils";
import { getCurrentUser } from "shared/features/user/selectors";
import { useIdleTimer } from "react-idle-timer";
import { JsonUser } from "shared/fetch/src/models/JsonUser";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import NativeApp from "models/NativeApp";

const hoursToMilliseconds = (hours: number) => hours * 1000 * 60 * 60;
const useIsAuthenticated = () => {
  const {
    isAuthenticated,
    getAccessTokenSilently,
    logout: logoutAuth0,
    loginWithRedirect,
    isLoading,
  } = useAuth0();
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const location = useLocation();

  const isAuthPending = useSelector((state: State) => state.auth.isLoading);
  const isLoggedIn = useSelector((state: State) => !!state.auth.refreshToken);
  useIdleTimer({
    onIdle: () => {
      logout(dispatch, user as JsonUser, "idle", logoutAuth0);
    },
    timeout: user?.ctm ? hoursToMilliseconds(12) : hoursToMilliseconds(1),
  });

  useEffect(() => {
    if (window?.ReactNativeWebView?.isApp) {
      if (isLoggedIn) {
        NativeApp.hideNativeButtons();
      } else {
        NativeApp.showNativeButtons();
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (
      !isAuthenticated &&
      !isLoading &&
      window.location?.pathname !== "biometric"
    ) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading]);

  useEffect(() => {
    checkAuthentication({
      isLoading: isAuthPending,
      dispatch,
      isLoggedIn,
      user,
      location,
      getAccessTokenSilently,
      logoutAuth0,
    });
    return () => {
      checkAuthentication({
        isLoading: isAuthPending,
        dispatch,
        isLoggedIn,
        user,
        location,
        getAccessTokenSilently,
        logoutAuth0,
      });
    };
  }, [isAuthPending]);

  return isLoggedIn && !isAuthPending;
};

export default useIsAuthenticated;
