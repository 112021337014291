import * as React from "react";
import * as ReactDOM from "react-dom";
import { setWindow } from "shared/features/window";
import "./styles/index.scss";
import "./styles/scss/main.scss";
import Root from "./containers/Root";
import { BrowserRouter } from "react-router-dom";

setWindow(window);
ReactDOM.render(
  <BrowserRouter>
    <Root />
  </BrowserRouter>,
  document.getElementById("root") as HTMLElement
);
