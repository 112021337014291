/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AnnotatedInputServiceMenuDocument,
    AnnotatedInputServiceMenuDocumentFromJSON,
    AnnotatedInputServiceMenuDocumentToJSON,
} from '../models/AnnotatedInputServiceMenuDocument'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InputServiceMenuDocumentSchema,
    InputServiceMenuDocumentSchemaFromJSON,
    InputServiceMenuDocumentSchemaToJSON,
} from '../models/InputServiceMenuDocumentSchema'
import {
    OutputServiceMenuDocumentSchema,
    OutputServiceMenuDocumentSchemaFromJSON,
    OutputServiceMenuDocumentSchemaToJSON,
} from '../models/OutputServiceMenuDocumentSchema'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface GetServiceMenuRequest {
    id: string;
}
export interface PostServiceMenuRequest {
    inputServiceMenuDocumentSchema?: InputServiceMenuDocumentSchema;
}


export interface IServiceMenusApi {
      getServiceMenu: (requestParameters: GetServiceMenuRequest) => Promise<OutputServiceMenuDocumentSchema>
      getServiceMenus: () => Promise<Array<AnnotatedInputServiceMenuDocument>>
      postServiceMenu: (requestParameters: PostServiceMenuRequest) => Promise<InputServiceMenuDocumentSchema>
      getServiceMenuWithResponse: (requestParameters: GetServiceMenuRequest) => Promise<{ value: OutputServiceMenuDocumentSchema, response: any}>
      getServiceMenusWithResponse: () => Promise<{ value: Array<AnnotatedInputServiceMenuDocument>, response: any}>
      postServiceMenuWithResponse: (requestParameters: PostServiceMenuRequest) => Promise<{ value: InputServiceMenuDocumentSchema, response: any}>
}

/**
 * no description
 */
export class ServiceMenusApi extends runtime.BaseAPI {

    /**
     * View service menu for member
     */
    getServiceMenuRaw = async (requestParameters: GetServiceMenuRequest): Promise<{ runtime: runtime.ApiResponse<OutputServiceMenuDocumentSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getServiceMenu.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/user/{id}/service_menu`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => OutputServiceMenuDocumentSchemaFromJSON(jsonValue)), response };
    }

    /**
     * View service menu for member
     */
    getServiceMenu = async (requestParameters: GetServiceMenuRequest): Promise<OutputServiceMenuDocumentSchema> => {
        const { runtime } = await this.getServiceMenuRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * View service menu for member
     */
    getServiceMenuWithResponse = async (requestParameters: GetServiceMenuRequest): Promise<{ value: OutputServiceMenuDocumentSchema, response: any}> => {
        const { runtime, response } = await this.getServiceMenuRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * View available service menus
     */
    getServiceMenusRaw = async (): Promise<{ runtime: runtime.ApiResponse<Array<AnnotatedInputServiceMenuDocument>>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/service_menus`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AnnotatedInputServiceMenuDocumentFromJSON)), response };
    }

    /**
     * View available service menus
     */
    getServiceMenus = async (): Promise<Array<AnnotatedInputServiceMenuDocument>> => {
        const { runtime } = await this.getServiceMenusRaw();
        return await runtime.value();
    }

    /**
     * View available service menus
     */
    getServiceMenusWithResponse = async (): Promise<{ value: Array<AnnotatedInputServiceMenuDocument>, response: any}> => {
        const { runtime, response } = await this.getServiceMenusRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Create a new service menu
     */
    postServiceMenuRaw = async (requestParameters: PostServiceMenuRequest): Promise<{ runtime: runtime.ApiResponse<InputServiceMenuDocumentSchema>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json;charset=utf8';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/service_menus`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InputServiceMenuDocumentSchemaToJSON(requestParameters.inputServiceMenuDocumentSchema),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => InputServiceMenuDocumentSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Create a new service menu
     */
    postServiceMenu = async (requestParameters: PostServiceMenuRequest): Promise<InputServiceMenuDocumentSchema> => {
        const { runtime } = await this.postServiceMenuRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Create a new service menu
     */
    postServiceMenuWithResponse = async (requestParameters: PostServiceMenuRequest): Promise<{ value: InputServiceMenuDocumentSchema, response: any}> => {
        const { runtime, response } = await this.postServiceMenuRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchServiceMenusApi: IServiceMenusApi  = new ServiceMenusApi();
