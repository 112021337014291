import * as React from "react";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Typography, { TypographyAppearance } from "components/Typography";
import clsx from "clsx";

interface StylingProps {
  isQueue: boolean | undefined;
}

interface IProps extends StylingProps {
  label: string | JSX.Element | undefined;
  descriptions: string[] | JSX.Element | undefined;
  labelAppearance: TypographyAppearance;
  isTimeline: boolean | undefined;
  isBold: boolean | undefined;
  descriptionsAppearance: TypographyAppearance;
  isBottomDisplay?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    text: {
      display: "flex",
      flexDirection: "column",
      marginLeft: ({ isQueue }: StylingProps) => (isQueue ? 4 : 8),
      textAlign: "left",
      alignSelf: "center",
      width: "100%",
      marginRight: 13,
    },
    displayBottom: {
      display: "initial",
      textAlign: "center",
      margin: "8px 0px 0px 0px !important",
    },
  })
);

export default function AvatarLabelAndDescriptions({
  label,
  descriptions,
  labelAppearance,
  isTimeline,
  isBold,
  descriptionsAppearance,
  isQueue,
  isBottomDisplay,
}: IProps) {
  const classes = useStyles({ isQueue });
  // the label and descriptions can be strings or jsx,
  // these booleans determine whether they need styling (strings)
  // or are passed directly to the render (jsx)
  const needsLabelStyling = label && typeof label === "string";
  const needsDescriptionsStyling =
    Boolean(descriptions) && Array.isArray(descriptions);

  return (
    <div
      className={clsx(classes.text, isBottomDisplay && classes.displayBottom)}
      data-testid="avatar-label"
    >
      {needsLabelStyling ? (
        <Typography
          gutterBottom={Array.isArray(descriptions)}
          appearance={labelAppearance}
          fontWeightBold={isTimeline ? isBold : true}
        >
          {label}
        </Typography>
      ) : (
        label
      )}
      {needsDescriptionsStyling ? (
        <>
          {(descriptions as string[]).map((description, i) => (
            <Typography
              gutterBottom={(descriptions as string[]).length > 1}
              key={i}
              appearance={descriptionsAppearance}
              color="textSecondary"
            >
              {description}
            </Typography>
          ))}
        </>
      ) : (
        descriptions
      )}
    </div>
  );
}
