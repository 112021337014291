// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ContactProviderOutput,
    ContactProviderOutputFromJSON,
    ContactProviderOutputToJSON,
} from './ContactProviderOutput';
import {
    NetworkCareTeamOutput,
    NetworkCareTeamOutputFromJSON,
    NetworkCareTeamOutputToJSON,
} from './NetworkCareTeamOutput';

/**
 * @type MemberNetworkOutput
 * @export
 */
export type MemberNetworkOutput = ContactProviderOutput | NetworkCareTeamOutput;


export function MemberNetworkOutputFromJSON(json: any): MemberNetworkOutput {
  switch (json.type) {
  case 'teams': return NetworkCareTeamOutputFromJSON(json);
  case 'contacts': return ContactProviderOutputFromJSON(json);
  default: throw new Error("Unexpected type value.");
  }
}

export function MemberNetworkOutputToJSON(value?: MemberNetworkOutput): any {
    if (value === undefined) {
        return undefined;
    }

    switch (value.type) {
    case 'teams': return NetworkCareTeamOutputToJSON(<NetworkCareTeamOutput>value);
    case 'contacts': return ContactProviderOutputToJSON(<ContactProviderOutput>value);
    default: throw new Error("Unexpected type value.");
    }
}

