import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import Asset from "components/Asset";
import Typography from "components/Typography";
import { Link } from "components/Button";
import { useSelector } from "react-redux";
import { getFeatures } from "shared/features/featureFlags/selectors";
import { useLocation } from "react-router";

interface IProps {
  assetName: string;
  message: string;
  containerOverrideStyles?: string;
  textOverrideStyles?: string;
  hasArchivedConversations?: boolean;
}

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  container: {
    border: `solid 1px ${palette.divider}`,
    borderRadius: "10px",
    height: "24.625rem",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "center",
    alignContent: "center",
    gap: "10px",
    marginLeft: "auto",
    marginRight: "auto",
    [breakpoints.down("md")]: {
      paddingLeft: "10px",
      width: "98%",
      paddingTop: "20px",
    },
  },
  archivedConversationsText: {
    color: "#606A76",
  },
  archivedConversationsContainer: {
    marginTop: "-15px",
  },
  containerMobile: {
    [breakpoints.down("md")]: {
      height: "20rem",
    },
  },
}));
const EmptyStateComponent = ({
  assetName,
  message,
  containerOverrideStyles,
  textOverrideStyles,
  hasArchivedConversations,
}: IProps) => {
  const classes = useStyles();
  const path = useLocation().pathname.split("");
  const features = useSelector(getFeatures);
  const resizeMobileView =
    path[0] === "/" && path?.length === 1 && features?.hasMinorDependents();

  return (
    <div
      className={clsx(
        classes.container,
        containerOverrideStyles,
        resizeMobileView && classes.containerMobile
      )}
      data-testid="member-conversations-none"
    >
      <Asset name={assetName} alt="" hiddenFromScreenReader />
      <Typography
        appearance="body"
        color="textSecondary"
        className={textOverrideStyles}
      >
        {message}
      </Typography>

      {hasArchivedConversations && (
        <span className={classes.archivedConversationsContainer}>
          <Link color="secondary" to="care-history" inline>
            Go to care history
          </Link>
          <span className={classes.archivedConversationsText}>
            &nbsp;to see your archived Conversations
          </span>
        </span>
      )}
    </div>
  );
};

export default EmptyStateComponent;
