// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    StoredRoleSchema,
    StoredRoleSchemaFromJSON,
    StoredRoleSchemaToJSON,
} from './StoredRoleSchema';


/**
 * 
 * @export
 * @interface RolesListResponse
 */
export interface RolesListResponse  {
    /**
     * 
     * @type {Array<StoredRoleSchema>}
     * @memberof RolesListResponse
     */
    roles?: Array<StoredRoleSchema>;
}

export function RolesListResponseFromJSON(json: any): RolesListResponse {
    return {
        'roles': !exists(json, 'roles') ? undefined : (json['roles'] as Array<any>).map(StoredRoleSchemaFromJSON),
    };
}

export function RolesListResponseToJSON(value?: RolesListResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'roles': value.roles === undefined ? undefined : (value.roles as Array<any>).map(StoredRoleSchemaToJSON),
    };
}


