import { useMutation } from "redux-query-react";
import { QueryConfig, Entities } from "redux-query";
import { fetchEpisodeRoles } from "shared/api/src/apis/EpisodesOfCareApi";
import { EpisodeRolesOutputSchema } from "shared/api/src/models/EpisodeRolesOutputSchema";

interface IRequest {
  episodeId: string;
}

export const useResetEpisodeRoles = () => {
  const queryConfig = ({ episodeId }: IRequest) =>
    fetchEpisodeRoles(
      { episodeId },
      {
        transform: () => ({
          episodeRoles: {},
        }),
        update: {
          episodeRoles: (
            prev: EpisodeRolesOutputSchema,
            next: EpisodeRolesOutputSchema
          ) => next,
        },
        force: true,
      }
    );

  const [, resetEpisodeRoles] = useMutation((episodeId: string) => {
    return queryConfig({ episodeId }) as QueryConfig<Entities>;
  });

  return { resetEpisodeRoles };
};
