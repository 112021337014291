// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CmsServiceLineItem,
    CmsServiceLineItemFromJSON,
    CmsServiceLineItemToJSON,
} from './CmsServiceLineItem';
import {
    CommonQuestionsListItem,
    CommonQuestionsListItemFromJSON,
    CommonQuestionsListItemToJSON,
} from './CommonQuestionsListItem';
import {
    DiagnosesListItem,
    DiagnosesListItemFromJSON,
    DiagnosesListItemToJSON,
} from './DiagnosesListItem';
import {
    GenericCMSListItem,
    GenericCMSListItemFromJSON,
    GenericCMSListItemToJSON,
} from './GenericCMSListItem';
import {
    QuestionSetsListItem,
    QuestionSetsListItemFromJSON,
    QuestionSetsListItemToJSON,
} from './QuestionSetsListItem';

/**
 * @type CMSListItemSchema
 * @export
 */
export type CMSListItemSchema = CmsServiceLineItem | CommonQuestionsListItem | DiagnosesListItem | GenericCMSListItem | QuestionSetsListItem;


export function CMSListItemSchemaFromJSON(json: any): CMSListItemSchema {
  switch (json.type) {
  case 'generic_item': return GenericCMSListItemFromJSON(json);
  case 'question_set': return QuestionSetsListItemFromJSON(json);
  case 'common_question': return CommonQuestionsListItemFromJSON(json);
  case 'diagnosis': return DiagnosesListItemFromJSON(json);
  case 'service_lines': return CmsServiceLineItemFromJSON(json);
  default: throw new Error("Unexpected type value.");
  }
}

export function CMSListItemSchemaToJSON(value?: CMSListItemSchema): any {
    if (value === undefined) {
        return undefined;
    }

    switch (value.type) {
    case 'generic_item': return GenericCMSListItemToJSON(<GenericCMSListItem>value);
    case 'question_set': return QuestionSetsListItemToJSON(<QuestionSetsListItem>value);
    case 'common_question': return CommonQuestionsListItemToJSON(<CommonQuestionsListItem>value);
    case 'diagnosis': return DiagnosesListItemToJSON(<DiagnosesListItem>value);
    case 'service_lines': return CmsServiceLineItemToJSON(<CmsServiceLineItem>value);
    default: throw new Error("Unexpected type value.");
    }
}

