// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VerifiedInvitedMemberRegistrationInformation
 */
export interface VerifiedInvitedMemberRegistrationInformation  {
    /**
     * 
     * @type {string}
     * @memberof VerifiedInvitedMemberRegistrationInformation
     */
    dob?: string;
    /**
     * 
     * @type {number}
     * @memberof VerifiedInvitedMemberRegistrationInformation
     */
    efId?: number;
    /**
     * 
     * @type {string}
     * @memberof VerifiedInvitedMemberRegistrationInformation
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof VerifiedInvitedMemberRegistrationInformation
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof VerifiedInvitedMemberRegistrationInformation
     */
    primaryMemberWorkEmail?: string;
    /**
     * 
     * @type {string}
     * @memberof VerifiedInvitedMemberRegistrationInformation
     */
    extraRegistrationId?: string;
    /**
     * Aetna subscriber_id field, stored in the medical_policy field on the eligibility.
     * @type {string}
     * @memberof VerifiedInvitedMemberRegistrationInformation
     */
    subscriberId?: string;
    /**
     * When true, it means this is a re-invitation and a new endpoint is needed
     * @type {boolean}
     * @memberof VerifiedInvitedMemberRegistrationInformation
     */
    nestedToStandalone?: boolean;
}

export function VerifiedInvitedMemberRegistrationInformationFromJSON(json: any): VerifiedInvitedMemberRegistrationInformation {
    return {
        'dob': !exists(json, 'dob') ? undefined : json['dob'],
        'efId': !exists(json, 'ef_id') ? undefined : json['ef_id'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'primaryMemberWorkEmail': !exists(json, 'primary_member_work_email') ? undefined : json['primary_member_work_email'],
        'extraRegistrationId': !exists(json, 'extra_registration_id') ? undefined : json['extra_registration_id'],
        'subscriberId': !exists(json, 'subscriber_id') ? undefined : json['subscriber_id'],
        'nestedToStandalone': !exists(json, 'nested_to_standalone') ? undefined : json['nested_to_standalone'],
    };
}

export function VerifiedInvitedMemberRegistrationInformationToJSON(value?: VerifiedInvitedMemberRegistrationInformation): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'dob': value.dob,
        'ef_id': value.efId,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'primary_member_work_email': value.primaryMemberWorkEmail,
        'extra_registration_id': value.extraRegistrationId,
        'subscriber_id': value.subscriberId,
        'nested_to_standalone': value.nestedToStandalone,
    };
}


