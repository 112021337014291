import usePatientIneligibilityMessage from "components/BannerMessages/hooks/usePatientIneligibilityMessage";
import useAppointmentsMessage from "components/BannerMessages/hooks/useAppointmentsMessage";
import usePendingIneligibilityMessage from "components/BannerMessages/hooks/usePendingIneligibilityMessage";
import useAmatHraMessage from "components/BannerMessages/hooks/useAmatHraMessage";
import useCheckinMessage from "components/BannerMessages/hooks/useCheckinMessage";
import useConsumerMessage from "components/BannerMessages/hooks/useConsumerMessage";
import useConsumerPastDueMsg from "components/BannerMessages/hooks/useConsumerPastDueMsg";
import useAocLevelUpBanner from "components/BannerMessages/hooks/useAocLevelUpBanner";
import useMinorLevelUpBanner from "components/BannerMessages/hooks/useMinorLevelUpBanner";

export default [
  useAppointmentsMessage,
  useAmatHraMessage,
  usePatientIneligibilityMessage,
  usePendingIneligibilityMessage,
  useCheckinMessage,
  useConsumerMessage,
  useConsumerPastDueMsg,
  useAocLevelUpBanner,
  useMinorLevelUpBanner,
];
