// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ServiceLineGroupSchemaAllOf
 */
export interface ServiceLineGroupSchemaAllOf  {
    /**
     * 
     * @type {number}
     * @memberof ServiceLineGroupSchemaAllOf
     */
    id: number;
}

export function ServiceLineGroupSchemaAllOfFromJSON(json: any): ServiceLineGroupSchemaAllOf {
    return {
        'id': json['id'],
    };
}

export function ServiceLineGroupSchemaAllOfToJSON(value?: ServiceLineGroupSchemaAllOf): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
    };
}


