import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import EmptyStateComponent from "components/EmptyStateComponent";

interface IProps {
  status?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    text: {
      width: "258px",
      height: "42px",
      textAlign: "center",
    },
  })
);

const NoVisits = ({ status }: IProps) => {
  const classes = useStyles();
  let status_message;
  status_message = status;
  if (status === "all" || status === "") {
    status_message = "upcoming or past";
  } else {
    status_message = status || "upcoming";
  }

  const message = `There are no ${status_message} visits`;
  return (
    <EmptyStateComponent
      assetName="Appointment"
      message={message}
      textOverrideStyles={classes.text}
      data-testid="novisits"
    />
  );
};

export default NoVisits;
