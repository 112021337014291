// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AvailableTimeslotsResponseResults,
    AvailableTimeslotsResponseResultsFromJSON,
    AvailableTimeslotsResponseResultsToJSON,
} from './AvailableTimeslotsResponseResults';


/**
 * 
 * @export
 * @interface AvailableTimeslotsResponse
 */
export interface AvailableTimeslotsResponse  {
    /**
     * 
     * @type {string}
     * @memberof AvailableTimeslotsResponse
     */
    nextStartAt?: string;
    /**
     * 
     * @type {Array<AvailableTimeslotsResponseResults>}
     * @memberof AvailableTimeslotsResponse
     */
    results: Array<AvailableTimeslotsResponseResults>;
}

export function AvailableTimeslotsResponseFromJSON(json: any): AvailableTimeslotsResponse {
    return {
        'nextStartAt': !exists(json, 'next_start_at') ? undefined : json['next_start_at'],
        'results': (json['results'] as Array<any>).map(AvailableTimeslotsResponseResultsFromJSON),
    };
}

export function AvailableTimeslotsResponseToJSON(value?: AvailableTimeslotsResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'next_start_at': value.nextStartAt,
        'results': (value.results as Array<any>).map(AvailableTimeslotsResponseResultsToJSON),
    };
}


