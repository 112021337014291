import { authRedirect, storeCodeVerifier } from "./utils";
import { createCodeChallenge, createCodeVerifier } from "./index";

let called = false;

export const reset = () => {
  called = false;
};

const performRedirect = (
  redirectUri: string,
  authEndpointUrl?: string,
  additionalQueryParams?: Record<string, string>
) => {
  if (called) {
    /* eslint-disable */
    console.error(Error("unexpected additional redirect to auth call"));
    /* eslint-enable */
    return Promise.resolve();
  }

  called = true;

  const codeVerifier = createCodeVerifier();

  return createCodeChallenge(codeVerifier)
    .then((codeChallenge) => {
      storeCodeVerifier(codeVerifier);
      return authRedirect(
        redirectUri,
        codeChallenge,
        authEndpointUrl,
        additionalQueryParams
      );
    })
    .catch((e) => {
      reset();
      /* eslint-disable */
      console.error(e);
      /* eslint-enable */
    });
};

export default performRedirect;
