import palette from "../palette";

export default {
  root: {
    "&.Mui-error": {
      marginLeft: 0,
      color: palette.error.main,
    },
  },
};
