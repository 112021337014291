import React, { FunctionComponent } from "react";
import clsx from "clsx";
import ListComponent, { ListProps } from "@mui/material/List";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

interface IProps extends ListProps {
  variant?: "default" | "small" | "large";
  ariaLabel: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    default: {},
    small: {
      "& .MuiListItemAvatar-root": {
        minWidth: 41,
      },
      "& .MuiAvatar-root": {
        width: 32,
        height: 32,
      },
    },
  })
);

const List: FunctionComponent<IProps> = (props) => {
  const { variant = "default", className, ariaLabel, ...rest } = props;
  const classes = useStyles(props);
  return (
    <ListComponent
      className={clsx(classes[variant], className)}
      aria-label={ariaLabel}
      {...rest}
    >
      {props.children}
    </ListComponent>
  );
};

export default List;
