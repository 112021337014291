// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    NetworkProvider,
    NetworkProviderFromJSON,
    NetworkProviderToJSON,
} from './NetworkProvider';


/**
 * 
 * @export
 * @interface NetworkProviderGroup
 */
export interface NetworkProviderGroup  {
    /**
     * 
     * @type {string}
     * @memberof NetworkProviderGroup
     */
    name: string;
    /**
     * 
     * @type {Array<NetworkProvider>}
     * @memberof NetworkProviderGroup
     */
    providers: Array<NetworkProvider>;
}

export function NetworkProviderGroupFromJSON(json: any): NetworkProviderGroup {
    return {
        'name': json['name'],
        'providers': (json['providers'] as Array<any>).map(NetworkProviderFromJSON),
    };
}

export function NetworkProviderGroupToJSON(value?: NetworkProviderGroup): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'name': value.name,
        'providers': (value.providers as Array<any>).map(NetworkProviderToJSON),
    };
}


