// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CmsServiceLineItem
 */
export interface CmsServiceLineItem  {
    /**
     * 
     * @type {number}
     * @memberof CmsServiceLineItem
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof CmsServiceLineItem
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof CmsServiceLineItem
     */
    serviceLine: string;
    /**
     * 
     * @type {string}
     * @memberof CmsServiceLineItem
     */
    description: string;
    /**
     * 
     * @type {number}
     * @memberof CmsServiceLineItem
     */
    version: number;
}

export function CmsServiceLineItemFromJSON(json: any): CmsServiceLineItem {
    return {
        'id': json['id'],
        'type': json['type'],
        'serviceLine': json['service_line'],
        'description': json['description'],
        'version': json['version'],
    };
}

export function CmsServiceLineItemToJSON(value?: CmsServiceLineItem): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'type': value.type,
        'service_line': value.serviceLine,
        'description': value.description,
        'version': value.version,
    };
}


