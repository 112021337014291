// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuestionSetCategoriesList
 */
export interface QuestionSetCategoriesList  {
    /**
     * 
     * @type {number}
     * @memberof QuestionSetCategoriesList
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionSetCategoriesList
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionSetCategoriesList
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionSetCategoriesList
     */
    version?: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionSetCategoriesList
     */
    type?: string;
}

export function QuestionSetCategoriesListFromJSON(json: any): QuestionSetCategoriesList {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'version': !exists(json, 'version') ? undefined : json['version'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function QuestionSetCategoriesListToJSON(value?: QuestionSetCategoriesList): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'category': value.category,
        'description': value.description,
        'version': value.version,
        'type': value.type,
    };
}


