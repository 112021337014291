import parseDoB from "./parseDoB";
import isValid from "date-fns/isValid";
import enUS from "date-fns/locale/en-US";
import enZA from "date-fns/locale/en-ZA";
import { isSameDay, isSameYear, differenceInYears, addDays } from "date-fns";
import { format, utcToZonedTime } from "date-fns-tz";
import useGetUserTimezone from "./useGetUserTimezone";

interface OptionsProps {
  fallbackDate?: Date | string;
  showAsTodayIfSameDay?: boolean;
  isDoB?: boolean;
  invalidateDateFallback?: Date | string;
  tz?: string;
  forConversationCard?: boolean;
}

export const getParsedDate = ({
  date,
  isDoB,
  timezone,
}: {
  date: Date | string;
  isDoB?: boolean;
  timezone: string;
}) => {
  const dateToFormat =
    isDoB && typeof date === "string" ? parseDoB(date) : date;
  return utcToZonedTime(new Date(dateToFormat), timezone);
};

export const getLocale = ({
  parsedDate,
  timezone,
}: {
  parsedDate: Date;
  timezone: string;
}) => {
  const timeZoneName = format(parsedDate, "zzzz", { timeZone: timezone });
  const isAfricanTimezone =
    timeZoneName === "East Africa Time" ||
    timeZoneName === "Central Africa Time" ||
    timeZoneName === "West Africa Time";
  return isAfricanTimezone ? enZA : enUS;
};

export const ageInYears = (dateOfBirth: Date | string) => {
  const thirtyDaysFromNow = addDays(new Date(), 30);
  const age = differenceInYears(thirtyDaysFromNow, new Date(dateOfBirth));

  return age;
};

const useFormatDate = () => {
  const userTimezone = useGetUserTimezone();

  const formatDate = (
    date: Date | string | undefined,
    formatString: string,
    options?: OptionsProps
  ) => {
    const fallbackDate = options?.fallbackDate || date || "";
    const showAsTodayIfSameDay = options?.showAsTodayIfSameDay;
    const isDoB = options?.isDoB;
    const invalidateDateFallback = options?.invalidateDateFallback;

    if (typeof date === "undefined" || !isValid(new Date(date))) {
      // Note: Passing invalidateDateFallback as an empty string means this will return "--/--/----"
      return invalidateDateFallback || "--/--/----";
    }

    const timezone = options?.tz || userTimezone;
    const parsedDate = getParsedDate({ date, isDoB, timezone });
    const localeCode = getLocale({ parsedDate, timezone });

    if (options?.forConversationCard) {
      if (isValid(parsedDate)) {
        if (isSameDay(new Date(), new Date(parsedDate))) {
          const parsedTime = new Date(parsedDate);
          return format(parsedTime, "h:mmaaa");
        }

        if (
          isSameYear(new Date(), new Date(parsedDate)) ||
          differenceInYears(new Date(), new Date(parsedDate)) < 1
        ) {
          return format(parsedDate, formatString, {
            timeZone: timezone,
            locale: localeCode,
          });
        }
        return format(parsedDate, "M/d/yy", {
          timeZone: timezone,
          locale: localeCode,
        });
      }
      return fallbackDate;
    }

    if (showAsTodayIfSameDay && isSameDay(new Date(), new Date(parsedDate))) {
      const parsedTempDate = new Date(parsedDate);
      return `Today ${format(parsedTempDate, "@ h:mmaaa")}`;
    }

    if (isValid(parsedDate)) {
      return format(parsedDate, formatString, {
        timeZone: timezone,
        locale: localeCode,
      });
    }

    return fallbackDate;
  };

  return formatDate;
};

export default useFormatDate;
