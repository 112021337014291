// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberSubscriptionItems,
    MemberSubscriptionItemsFromJSON,
    MemberSubscriptionItemsToJSON,
} from './MemberSubscriptionItems';
import {
    StripeCustomer,
    StripeCustomerFromJSON,
    StripeCustomerToJSON,
} from './StripeCustomer';
import {
    StripeDiscount,
    StripeDiscountFromJSON,
    StripeDiscountToJSON,
} from './StripeDiscount';
import {
    StripePlan,
    StripePlanFromJSON,
    StripePlanToJSON,
} from './StripePlan';


/**
 * 
 * @export
 * @interface MemberSubscription
 */
export interface MemberSubscription  {
    /**
     * 
     * @type {string}
     * @memberof MemberSubscription
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberSubscription
     */
    currentPeriodStart?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberSubscription
     */
    currentPeriodEnd?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberSubscription
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberSubscription
     */
    defaultPaymentMethod?: string;
    /**
     * Either `charge_automatically`, or `send_invoice`. When charging automatically, Stripe will attempt to pay this subscription at the end of the cycle using the default source attached to the customer. When sending an invoice, Stripe will email your customer an invoice with payment instructions.
     * @type {string}
     * @memberof MemberSubscription
     */
    collectionMethod?: MemberSubscriptionCollectionMethodEnum;
    /**
     * If the subscription has been canceled with the `at_period_end` flag set to `true`, `cancel_at_period_end` on the subscription will be true. You can use this attribute to determine whether a subscription that has a status of active is scheduled to be canceled at the end of the current period.
     * @type {boolean}
     * @memberof MemberSubscription
     */
    cancelAtPeriodEnd?: boolean;
    /**
     * A date in the future at which the subscription will automatically get canceled
     * @type {number}
     * @memberof MemberSubscription
     */
    cancelAt?: number;
    /**
     * 
     * @type {StripeCustomer}
     * @memberof MemberSubscription
     */
    customer?: StripeCustomer;
    /**
     * 
     * @type {StripePlan}
     * @memberof MemberSubscription
     */
    plan?: StripePlan;
    /**
     * 
     * @type {StripeDiscount}
     * @memberof MemberSubscription
     */
    discount?: StripeDiscount;
    /**
     * 
     * @type {MemberSubscriptionItems}
     * @memberof MemberSubscription
     */
    items?: MemberSubscriptionItems;
}

export function MemberSubscriptionFromJSON(json: any): MemberSubscription {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'currentPeriodStart': !exists(json, 'current_period_start') ? undefined : json['current_period_start'],
        'currentPeriodEnd': !exists(json, 'current_period_end') ? undefined : json['current_period_end'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'defaultPaymentMethod': !exists(json, 'default_payment_method') ? undefined : json['default_payment_method'],
        'collectionMethod': !exists(json, 'collection_method') ? undefined : json['collection_method'],
        'cancelAtPeriodEnd': !exists(json, 'cancel_at_period_end') ? undefined : json['cancel_at_period_end'],
        'cancelAt': !exists(json, 'cancel_at') ? undefined : json['cancel_at'],
        'customer': !exists(json, 'customer') ? undefined : StripeCustomerFromJSON(json['customer']),
        'plan': !exists(json, 'plan') ? undefined : StripePlanFromJSON(json['plan']),
        'discount': !exists(json, 'discount') ? undefined : StripeDiscountFromJSON(json['discount']),
        'items': !exists(json, 'items') ? undefined : MemberSubscriptionItemsFromJSON(json['items']),
    };
}

export function MemberSubscriptionToJSON(value?: MemberSubscription): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'current_period_start': value.currentPeriodStart,
        'current_period_end': value.currentPeriodEnd,
        'status': value.status,
        'default_payment_method': value.defaultPaymentMethod,
        'collection_method': value.collectionMethod,
        'cancel_at_period_end': value.cancelAtPeriodEnd,
        'cancel_at': value.cancelAt,
        'customer': StripeCustomerToJSON(value.customer),
        'plan': StripePlanToJSON(value.plan),
        'discount': StripeDiscountToJSON(value.discount),
        'items': MemberSubscriptionItemsToJSON(value.items),
    };
}

/**
* @export
* @enum {string}
*/
export enum MemberSubscriptionCollectionMethodEnum {
    ChargeAutomatically = 'charge_automatically',
    SendInvoice = 'send_invoice'
}


