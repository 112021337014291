// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDocumentRequestBody
 */
export interface UpdateDocumentRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentRequestBody
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDocumentRequestBody
     */
    sign?: string;
}

export function UpdateDocumentRequestBodyFromJSON(json: any): UpdateDocumentRequestBody {
    return {
        'title': !exists(json, 'title') ? undefined : json['title'],
        'sign': !exists(json, 'sign') ? undefined : json['sign'],
    };
}

export function UpdateDocumentRequestBodyToJSON(value?: UpdateDocumentRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'title': value.title,
        'sign': value.sign,
    };
}


