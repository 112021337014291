import valuesIn from "lodash/valuesIn";
import flatten from "lodash/flatten";
import { UploadedDocument } from "components/MessagingForm";
import { ComponentCoreTypeEnum } from "shared/api/src/models/ComponentCore";
import { DocumentObjectDetails } from "shared/api/src/models/DocumentObjectDetails";
import { ReferralComponentCoreStateEnum } from "shared/api/src/models/ReferralComponentCore";
import {
  ActionableItemSummary,
  ActionableItemSummaryStateEnum,
} from "shared/fetch/src/models/ActionableItemSummary";
import { BillingComponentOutputPropsStateEnum } from "shared/fetch/src/models/BillingComponentOutputProps";
import { CTMInfo } from "shared/fetch/src/models/CTMInfo";
import { CalculatorComponentOutputPropsStateEnum } from "shared/fetch/src/models/CalculatorComponentOutputProps";
import { ComponentOutput } from "shared/fetch/src/models/ComponentOutput";
import { JsonUser } from "shared/fetch/src/models/JsonUser";
import { MarketingMessageComponentOutputPropsStateEnum } from "shared/fetch/src/models/MarketingMessageComponentOutputProps";
import { SummaryComponentCoreTypeEnum } from "shared/fetch/src/models/SummaryComponentCore";
import { SummaryComponentOutput } from "shared/fetch/src/models/SummaryComponentOutput";
import {
  TimelineEventOutputCoreStateEnum,
  TimelineEventOutputCoreEventTypeEnum,
} from "shared/fetch/src/models/TimelineEventOutputCore";
import { QuestionSetComponentOutputPropsStateEnum } from "shared/fetch/src/models/QuestionSetComponentOutputProps";
import { SelfScheduleAppointmentComponentOutputPropsStateEnum } from "shared/fetch/src/models/SelfScheduleAppointmentComponentOutputProps";
import { changeUTCtoLocalTime } from "shared/features/actionableItems/selectors";

export interface IHandleSubmitTimelineEventProps {
  replyMsg: string;
  recipientId?: string;
  documents: UploadedDocument[];
  memberCurrentUsState?: string;
}

export const actionStatusMessages = {
  episode_created: {
    member: {
      unacknowledged: "View details",
      acknowledged_pending_response: "View details",
      resolved: "View details",
    },
    ctm: {
      unacknowledged: "Respond",
      acknowledged_pending_response: "Respond",
      resolved: "View details",
    },
  },
  messaging_episode_created: {
    member: {
      read: "Read",
      unread: "Read",
      unacknowledged: "View details",
      acknowledged_pending_response: "View details",
      resolved: "View details",
    },
    ctm: {
      read: "View Details",
      unread: "View Details",
      unacknowledged: "Respond",
      acknowledged_pending_response: "Respond",
      resolved: "View details",
    },
  },
  ctm_messaging_episode_created: {
    member: {
      read: "Read",
      unread: "Read",
      resolved: "View Details",
    },
    ctm: {
      read: "View Details",
      unread: "View Details",
      resolved: "View Details",
    },
  },
  ctm_message: {
    member: {
      unread: "Read",
      unread_pending_response: "Reply",
      read_pending_response: "Reply",
      read_response_started: "Reply",
      resolved: "View details",
      resolved_with_reply: "View details",
    },
    ctm: {
      unread: "View details",
      unread_pending_response: "View details",
      read_pending_response: "View details",
      read_response_started: "View details",
      resolved: "View details",
      resolved_with_reply: "View details",
    },
  },
  member_message: {
    member: {
      unacknowledged: "View details",
      acknowledged_pending_response: "View details",
      resolved: "View details",
      resolved_with_reply: "View details",
    },
    ctm: {
      unacknowledged: "Read message",
      acknowledged_pending_response: "Read Message",
      resolved: "View details",
      resolved_with_reply: "View details",
    },
  },
  billing: {
    ctm: {
      [BillingComponentOutputPropsStateEnum.Unpaid]: "View details",
      [BillingComponentOutputPropsStateEnum.PaymentRequested]: "View details",
      [BillingComponentOutputPropsStateEnum.MemberViewed]: "View details",
      [BillingComponentOutputPropsStateEnum.Paid]: "View details",
    },
    member: {
      [BillingComponentOutputPropsStateEnum.Unpaid]: "Pay now",
      [BillingComponentOutputPropsStateEnum.PaymentRequested]: "Pay now",
      [BillingComponentOutputPropsStateEnum.MemberViewed]: "Pay now",
      [BillingComponentOutputPropsStateEnum.Paid]: "View details",
    },
  },

  message_bundle: {
    member: {
      [TimelineEventOutputCoreStateEnum.Unread]: "Take action",
      [TimelineEventOutputCoreStateEnum.UnresolvedMember]: "Take action",
      [TimelineEventOutputCoreStateEnum.UnresolvedCtm]: "View details",
      [TimelineEventOutputCoreStateEnum.Resolved]: "View details",
    },
    ctm: {
      [TimelineEventOutputCoreStateEnum.Unread]: "View details",
      [TimelineEventOutputCoreStateEnum.UnresolvedMember]: "View details",
      [TimelineEventOutputCoreStateEnum.UnresolvedCtm]: "Acknowledge",
      [TimelineEventOutputCoreStateEnum.Resolved]: "View details",
    },
  },

  question_set: {
    ctm: {
      [QuestionSetComponentOutputPropsStateEnum.UnreadPendingResponse]:
        "View details",
      [QuestionSetComponentOutputPropsStateEnum.ReadPendingResponse]:
        "View details",
      [QuestionSetComponentOutputPropsStateEnum.DraftMember]: "View details",
      [QuestionSetComponentOutputPropsStateEnum.ResponsesSentPendingAck]:
        "Review answers",
      [QuestionSetComponentOutputPropsStateEnum.AcknowledgedPendingResponse]:
        "Review answers",
      [QuestionSetComponentOutputPropsStateEnum.Resolved]: "View details",
    },

    member: {
      [QuestionSetComponentOutputPropsStateEnum.DraftMember]: "Finish draft",
      [QuestionSetComponentOutputPropsStateEnum.ReadPendingResponse]:
        "Answer questions",
      [QuestionSetComponentOutputPropsStateEnum.UnreadPendingResponse]:
        "Answer questions",
      [QuestionSetComponentOutputPropsStateEnum.ResponsesSentPendingAck]:
        "View details",
      [QuestionSetComponentOutputPropsStateEnum.AcknowledgedPendingResponse]:
        "View details",
      [QuestionSetComponentOutputPropsStateEnum.Resolved]: "View details",
    },
  },
  virtual_visit_referral: {
    ctm: {
      [ReferralComponentCoreStateEnum.UnreadPending]: "View details",
      [ReferralComponentCoreStateEnum.ReadPending]: "View details",
      [ReferralComponentCoreStateEnum.Declined]: "Acknowledge",
      [ReferralComponentCoreStateEnum.ResolvedDeclinedAckd]: "View details",
      [ReferralComponentCoreStateEnum.Scheduled]: "View details",
      [ReferralComponentCoreStateEnum.ResolvedCompleted]: "View details",
      [ReferralComponentCoreStateEnum.MissedNoShow]: "View details",
      [ReferralComponentCoreStateEnum.CanceledByMember]: "Acknowledge",
      [ReferralComponentCoreStateEnum.ResolvedCanceledAckd]: "View details",
      [ReferralComponentCoreStateEnum.CanceledByCtm]: "View details",
      [ReferralComponentCoreStateEnum.RescheduledPending]: "View details",
      [ReferralComponentCoreStateEnum.DeclinedAckdPending]: "Acknowledge",
      [ReferralComponentCoreStateEnum.CanceledAckdPending]: "Acknowledge",
      [ReferralComponentCoreStateEnum.CheckInIncomplete]: "Take action",
      [ReferralComponentCoreStateEnum.CheckInCompletedNotAcknowledged]:
        "Acknowledge check in",
    },
    member: {
      [ReferralComponentCoreStateEnum.UnreadPending]: "Book visit",
      [ReferralComponentCoreStateEnum.ReadPending]: "Book visit",
      [ReferralComponentCoreStateEnum.Declined]: "View details",
      [ReferralComponentCoreStateEnum.ResolvedDeclinedAckd]: "View details",
      [ReferralComponentCoreStateEnum.Scheduled]: "Upcoming visit",
      [ReferralComponentCoreStateEnum.ResolvedCompleted]: "View details",
      [ReferralComponentCoreStateEnum.CanceledByMember]: "View details",
      [ReferralComponentCoreStateEnum.ResolvedCanceledAckd]: "View details",
      [ReferralComponentCoreStateEnum.CanceledByCtm]: "View details",
      [ReferralComponentCoreStateEnum.MissedNoShow]: "Reschedule",
      [ReferralComponentCoreStateEnum.RescheduledPending]: "Reschedule",
      [ReferralComponentCoreStateEnum.DeclinedAckdPending]: "View details",
      [ReferralComponentCoreStateEnum.CanceledAckdPending]: "View details",
      [ReferralComponentCoreStateEnum.CheckInOpened]: "Check in",
      [ReferralComponentCoreStateEnum.CheckInInProgress]: "Check in",
      [ReferralComponentCoreStateEnum.CheckInCompleted]: "Upcoming visit",
      [ReferralComponentCoreStateEnum.CheckInIncomplete]: "Check in",
      [ReferralComponentCoreStateEnum.CheckInClosed]: "View details",
      [ReferralComponentCoreStateEnum.CheckInFastTrackedConsentNeeded]:
        "Check in",
      [ReferralComponentCoreStateEnum.CheckInFastTrackedOutstandingItemsWithoutConsent]:
        "Resolve check in",
      [ReferralComponentCoreStateEnum.CheckInCompletedNotAcknowledged]:
        "Upcoming visit",
    },
  },
  center_visit_referral: {
    ctm: {
      [ReferralComponentCoreStateEnum.UnreadPending]: "View details",
      [ReferralComponentCoreStateEnum.ReadPending]: "View details",
      [ReferralComponentCoreStateEnum.Declined]: "Acknowledge",
      [ReferralComponentCoreStateEnum.ResolvedDeclinedAckd]: "View details",
      [ReferralComponentCoreStateEnum.Scheduled]: "View details",
      [ReferralComponentCoreStateEnum.ResolvedCompleted]: "View details",
      [ReferralComponentCoreStateEnum.MissedNoShow]: "View details",
      [ReferralComponentCoreStateEnum.CanceledByMember]: "Acknowledge",
      [ReferralComponentCoreStateEnum.ResolvedCanceledAckd]: "View details",
      [ReferralComponentCoreStateEnum.CanceledByCtm]: "View details",
      [ReferralComponentCoreStateEnum.RescheduledPending]: "View details",
      [ReferralComponentCoreStateEnum.DeclinedAckdPending]: "Acknowledge",
      [ReferralComponentCoreStateEnum.CanceledAckdPending]: "Acknowledge",
      [ReferralComponentCoreStateEnum.CheckInIncomplete]: "Take action",
      [ReferralComponentCoreStateEnum.CheckInCompletedNotAcknowledged]:
        "Acknowledge check in",
    },
    member: {
      [ReferralComponentCoreStateEnum.UnreadPending]: "Book visit",
      [ReferralComponentCoreStateEnum.ReadPending]: "Book visit",
      [ReferralComponentCoreStateEnum.Declined]: "View details",
      [ReferralComponentCoreStateEnum.ResolvedDeclinedAckd]: "View details",
      [ReferralComponentCoreStateEnum.Scheduled]: "Upcoming visit",
      [ReferralComponentCoreStateEnum.ResolvedCompleted]: "View details",
      [ReferralComponentCoreStateEnum.CanceledByMember]: "View details",
      [ReferralComponentCoreStateEnum.ResolvedCanceledAckd]: "View details",
      [ReferralComponentCoreStateEnum.CanceledByCtm]: "View details",
      [ReferralComponentCoreStateEnum.MissedNoShow]: "Reschedule",
      [ReferralComponentCoreStateEnum.RescheduledPending]: "Reschedule",
      [ReferralComponentCoreStateEnum.DeclinedAckdPending]: "View details",
      [ReferralComponentCoreStateEnum.CanceledAckdPending]: "View details",
      [ReferralComponentCoreStateEnum.CheckInOpened]: "Check in",
      [ReferralComponentCoreStateEnum.CheckInInProgress]: "Check in",
      [ReferralComponentCoreStateEnum.CheckInCompleted]: "Upcoming visit",
      [ReferralComponentCoreStateEnum.CheckInIncomplete]: "Check in",
      [ReferralComponentCoreStateEnum.CheckInClosed]: "View details",
      [ReferralComponentCoreStateEnum.CheckInFastTrackedConsentNeeded]:
        "Check in",
      [ReferralComponentCoreStateEnum.CheckInFastTrackedOutstandingItemsWithoutConsent]:
        "Resolve check in",
      [ReferralComponentCoreStateEnum.CheckInCompletedNotAcknowledged]:
        "Upcoming visit",
    },
  },
  marketing_message: {
    ctm: {
      [MarketingMessageComponentOutputPropsStateEnum.Resolved]: "Learn more",
    },
    member: {
      [MarketingMessageComponentOutputPropsStateEnum.Resolved]: "Learn more",
    },
  },
  calculator: {
    ctm: {
      [CalculatorComponentOutputPropsStateEnum.UnreadPendingResponse]:
        "View details",
      [CalculatorComponentOutputPropsStateEnum.ReadPendingResponse]:
        "View details",
      [CalculatorComponentOutputPropsStateEnum.DraftMember]: "View details",
      [CalculatorComponentOutputPropsStateEnum.ResponsesSentPendingAck]:
        "Review answers",
      [CalculatorComponentOutputPropsStateEnum.AcknowledgedPendingResponse]:
        "Review answers",
      [CalculatorComponentOutputPropsStateEnum.Resolved]: "View details",
    },
    member: {
      [CalculatorComponentOutputPropsStateEnum.UnreadPendingResponse]:
        "Take action",
      [CalculatorComponentOutputPropsStateEnum.ReadPendingResponse]:
        "Take action",
      [CalculatorComponentOutputPropsStateEnum.DraftMember]: "Complete quiz",
      [CalculatorComponentOutputPropsStateEnum.ResponsesSentPendingAck]:
        "View details",
      [CalculatorComponentOutputPropsStateEnum.AcknowledgedPendingResponse]:
        "View details",
      [CalculatorComponentOutputPropsStateEnum.Resolved]: "View details",
    },
  },
  self_schedule_appointment: {
    ctm: {
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.Scheduled]:
        "View details",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.ResolvedCompleted]:
        "View details",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.MissedNoShow]:
        "View details",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CanceledByMember]:
        "Acknowledge",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.ResolvedCanceledAckd]:
        "View details",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CanceledByCtm]:
        "View details",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.RescheduledPending]:
        "View details",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CanceledAckdPending]:
        "Acknowledge",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.Declined]:
        "View details",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CheckInCompletedNotAcknowledged]:
        "Acknowledge check in",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CheckInIncomplete]:
        "Take action",
    },
    member: {
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.Scheduled]:
        "Upcoming visit",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.ResolvedCompleted]:
        "View details",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CanceledByMember]:
        "View details",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.ResolvedCanceledAckd]:
        "View details",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CanceledByCtm]:
        "View details",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.MissedNoShow]:
        "Reschedule",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.RescheduledPending]:
        "Reschedule",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CanceledAckdPending]:
        "View details",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.Declined]:
        "View details",
      [ReferralComponentCoreStateEnum.CheckInOpened]: "Check in",
      [ReferralComponentCoreStateEnum.CheckInInProgress]: "Check in",
      [ReferralComponentCoreStateEnum.CheckInCompleted]: "Upcoming visit",
      [ReferralComponentCoreStateEnum.CheckInIncomplete]: "Check in",
      [ReferralComponentCoreStateEnum.CheckInClosed]: "View details",
      [ReferralComponentCoreStateEnum.CheckInFastTrackedConsentNeeded]:
        "Check in",
      [ReferralComponentCoreStateEnum.CheckInFastTrackedOutstandingItemsWithoutConsent]:
        "Resolve check in",
      [ReferralComponentCoreStateEnum.CheckInCompletedNotAcknowledged]:
        "Upcoming visit",
    },
  },
};

const componentStatusMessages = {
  episode_created: {
    member: {
      unacknowledged: "Not yet reviewed",
      acknowledged_pending_response: "Reviewed",
      resolved: "Responded",
    },
    ctm: {
      unacknowledged: "Not yet acknowledged",
      acknowledged_pending_response: "Not yet acknowledged",
      resolved: "Acknowledged",
    },
  },
  ctm_messaging_episode_created: {
    member: {
      unread: "Unread",
      read: "Read",
      resolved: "Read",
      resolved_with_reply: "Responded",
    },
    ctm: {
      unread: "Unread",
      read: "Read",
      resolved: "Read",
      resolved_with_reply: "Responded",
    },
  },
  messaging_episode_created: {
    member: {
      unacknowledged: "Not yet acknowledged",
      acknowledged_pending_response: "Reviewed",
      resolved: "Acknowledged",
      unread: "Unread",
      read: "Read",
    },
    ctm: {
      unread: "Unread",
      read: "Read",
      unacknowledged: "Not yet acknowledged",
      acknowledged_pending_response: "Not yet acknowledged",
      resolved: "Acknowledged",
    },
  },
  ctm_episode_created: {
    member: {
      resolved_acknowledged: "Acknowledged",
    },
    ctm: {
      resolved_acknowledged: "Acknowledged",
    },
  },
  billing: {
    ctm: {
      [BillingComponentOutputPropsStateEnum.Unpaid]: "Unpaid",
      [BillingComponentOutputPropsStateEnum.PaymentRequested]:
        "Payment Requested",
      [BillingComponentOutputPropsStateEnum.MemberViewed]: "Member Viewed",
      [BillingComponentOutputPropsStateEnum.Paid]: "Paid",
    },
    member: {
      [BillingComponentOutputPropsStateEnum.Unpaid]: "Unpaid",
      [BillingComponentOutputPropsStateEnum.PaymentRequested]: "Unpaid",
      [BillingComponentOutputPropsStateEnum.MemberViewed]: "Unpaid",
      [BillingComponentOutputPropsStateEnum.Paid]: "Paid",
    },
  },
  ctm_message: {
    member: {
      unread: "Unread",
      unread_pending_response: "Unread",
      read_pending_response: "Read",
      read_response_started: "Read",
      resolved: "Read",
      resolved_with_reply: "Replied",
    },
    ctm: {
      unread: "Unread",
      unread_pending_response: "Unread",
      read_pending_response: "Read",
      read_response_started: "Read",
      resolved: "Read",
      resolved_with_reply: "Replied",
    },
  },
  member_message: {
    member: {
      unacknowledged: "Not yet reviewed",
      acknowledged_pending_response: "Reviewed",
      resolved: "Reviewed",
      resolved_with_reply: "Replied",
    },
    ctm: {
      unacknowledged: "Not yet acknowledged",
      acknowledged_pending_response: "Acknowledged PreFormatted",
      resolved: "Acknowledged",
      resolved_with_reply: "Acknowledged",
    },
  },
  // TODO: once component states are mapped, update this
  message_bundle: {
    member: {
      unread: "Not yet acknowledged",
      unread_pending_response: "Not yet acknowledged",
      read_pending_response: "Not yet acknowledged",
      read_response_started: "Not yet acknowledged",
      resolved: "Complete",
      resolved_with_reply: "Not yet acknowledged",
    },
    ctm: {
      unread: "Not yet acknowledged",
      unread_pending_response: "Not yet acknowledged",
      read_pending_response: "Not yet acknowledged",
      read_response_started: "Not yet acknowledged",
      resolved: "Complete",
      resolved_with_reply: "Not yet acknowledged",
    },
  },
  virtual_visit_referral: {
    ctm: {
      [ReferralComponentCoreStateEnum.UnreadPending]: "Not yet viewed",
      [ReferralComponentCoreStateEnum.ReadPending]: "Viewed",
      [ReferralComponentCoreStateEnum.Declined]: "Declined",
      [ReferralComponentCoreStateEnum.ResolvedDeclinedAckd]: "Declined",
      [ReferralComponentCoreStateEnum.Scheduled]: "Scheduled",
      [ReferralComponentCoreStateEnum.ResolvedCompleted]: "Completed",
      [ReferralComponentCoreStateEnum.MissedNoShow]: "Missed",
      [ReferralComponentCoreStateEnum.CanceledByMember]: "Canceled by Member",
      [ReferralComponentCoreStateEnum.ResolvedCanceledAckd]:
        "Canceled by Member",
      [ReferralComponentCoreStateEnum.CanceledByCtm]: "Canceled by CTM",
      [ReferralComponentCoreStateEnum.RescheduledPending]:
        "Reschedule requested",
      [ReferralComponentCoreStateEnum.DeclinedAckdPending]: "Declined",
      [ReferralComponentCoreStateEnum.CanceledAckdPending]:
        "Canceled by Member",
      [ReferralComponentCoreStateEnum.ResolvedCanceledViewed]:
        "Canceled by CTM",
      [ReferralComponentCoreStateEnum.CheckInOpened]: "Check in is now open",
      [ReferralComponentCoreStateEnum.CheckInInProgress]: "Check in started",
      [ReferralComponentCoreStateEnum.CheckInCompleted]: "Check in finished",
      [ReferralComponentCoreStateEnum.CheckInCompletedNotAcknowledged]:
        "Check in finished",
      [ReferralComponentCoreStateEnum.CheckInClosed]: "Incomplete check in",
      [ReferralComponentCoreStateEnum.CheckInIncomplete]: "Incomplete check in",
      [ReferralComponentCoreStateEnum.CheckInFastTrackedConsentNeeded]:
        "Fast tracked check in",
      [ReferralComponentCoreStateEnum.CheckInFastTrackedOutstandingItemsWithoutConsent]:
        "Fast tracked check in",
    },
    member: {
      [ReferralComponentCoreStateEnum.UnreadPending]: "Not yet scheduled",
      [ReferralComponentCoreStateEnum.ReadPending]: "Not yet scheduled",
      [ReferralComponentCoreStateEnum.Declined]: "Declined",
      [ReferralComponentCoreStateEnum.ResolvedDeclinedAckd]: "Declined",
      [ReferralComponentCoreStateEnum.Scheduled]: "Scheduled",
      [ReferralComponentCoreStateEnum.ResolvedCompleted]: "Completed",
      [ReferralComponentCoreStateEnum.CanceledByMember]: "Canceled by you",
      [ReferralComponentCoreStateEnum.ResolvedCanceledAckd]: "Canceled by you",
      [ReferralComponentCoreStateEnum.CanceledByCtm]: "Canceled by my Team",
      [ReferralComponentCoreStateEnum.MissedNoShow]: "Missed",
      [ReferralComponentCoreStateEnum.RescheduledPending]:
        "Reschedule requested",
      [ReferralComponentCoreStateEnum.DeclinedAckdPending]: "Declined",
      [ReferralComponentCoreStateEnum.CanceledAckdPending]: "Canceled by you",
      [ReferralComponentCoreStateEnum.ResolvedCanceledViewed]:
        "Canceled by my Team",
      [ReferralComponentCoreStateEnum.CheckInOpened]: "Check in is now open",
      [ReferralComponentCoreStateEnum.CheckInInProgress]: "Check in started",
      [ReferralComponentCoreStateEnum.CheckInCompleted]: "Check in finished",
      [ReferralComponentCoreStateEnum.CheckInCompletedNotAcknowledged]:
        "Check in finished",
      [ReferralComponentCoreStateEnum.CheckInClosed]: "Check in is now closed",
      [ReferralComponentCoreStateEnum.CheckInFastTrackedConsentNeeded]:
        "Incomplete check in",
      [ReferralComponentCoreStateEnum.CheckInFastTrackedOutstandingItemsWithoutConsent]:
        "Incomplete check in",
      [ReferralComponentCoreStateEnum.CheckInIncomplete]: "Check in needed",
    },
  },
  center_visit_referral: {
    ctm: {
      [ReferralComponentCoreStateEnum.UnreadPending]: "Not yet viewed",
      [ReferralComponentCoreStateEnum.ReadPending]: "Viewed",
      [ReferralComponentCoreStateEnum.Declined]: "Declined",
      [ReferralComponentCoreStateEnum.ResolvedDeclinedAckd]: "Declined",
      [ReferralComponentCoreStateEnum.Scheduled]: "Scheduled",
      [ReferralComponentCoreStateEnum.ResolvedCompleted]: "Completed",
      [ReferralComponentCoreStateEnum.MissedNoShow]: "No show", // No show on CTM end
      [ReferralComponentCoreStateEnum.CanceledByMember]: "Canceled by Member",
      [ReferralComponentCoreStateEnum.ResolvedCanceledAckd]:
        "Canceled by Member",
      [ReferralComponentCoreStateEnum.CanceledByCtm]: "Canceled by CTM",
      [ReferralComponentCoreStateEnum.RescheduledPending]:
        "Reschedule requested",
      [ReferralComponentCoreStateEnum.DeclinedAckdPending]: "Declined",
      [ReferralComponentCoreStateEnum.CanceledAckdPending]:
        "Canceled by Member",
      [ReferralComponentCoreStateEnum.ResolvedCanceledViewed]:
        "Canceled by CTM",
      [ReferralComponentCoreStateEnum.CheckInOpened]: "Check in is now open",
      [ReferralComponentCoreStateEnum.CheckInInProgress]: "Check in started",
      [ReferralComponentCoreStateEnum.CheckInCompleted]: "Check in finished",
      [ReferralComponentCoreStateEnum.CheckInCompletedNotAcknowledged]:
        "Check in finished",
      [ReferralComponentCoreStateEnum.CheckInClosed]: "Incomplete check in",
      [ReferralComponentCoreStateEnum.CheckInIncomplete]: "Incomplete check in",
      [ReferralComponentCoreStateEnum.CheckInFastTrackedConsentNeeded]:
        "Fast tracked check in",
      [ReferralComponentCoreStateEnum.CheckInFastTrackedOutstandingItemsWithoutConsent]:
        "Fast tracked check in",
    },
    member: {
      [ReferralComponentCoreStateEnum.UnreadPending]: "Not yet scheduled",
      [ReferralComponentCoreStateEnum.ReadPending]: "Not yet scheduled",
      [ReferralComponentCoreStateEnum.Declined]: "Declined",
      [ReferralComponentCoreStateEnum.ResolvedDeclinedAckd]: "Declined",
      [ReferralComponentCoreStateEnum.Scheduled]: "Scheduled",
      [ReferralComponentCoreStateEnum.ResolvedCompleted]: "Completed",
      [ReferralComponentCoreStateEnum.CanceledByMember]: "Canceled by you",
      [ReferralComponentCoreStateEnum.ResolvedCanceledAckd]: "Canceled by you",
      [ReferralComponentCoreStateEnum.CanceledByCtm]: "Canceled by my Team",
      [ReferralComponentCoreStateEnum.MissedNoShow]: "Missed",
      [ReferralComponentCoreStateEnum.RescheduledPending]:
        "Reschedule requested",
      [ReferralComponentCoreStateEnum.DeclinedAckdPending]: "Declined",
      [ReferralComponentCoreStateEnum.CanceledAckdPending]: "Canceled by you",
      [ReferralComponentCoreStateEnum.ResolvedCanceledViewed]:
        "Canceled by my Team",
      [ReferralComponentCoreStateEnum.CheckInOpened]: "Check in is now open",
      [ReferralComponentCoreStateEnum.CheckInInProgress]: "Check in started",
      [ReferralComponentCoreStateEnum.CheckInCompleted]: "Check in finished",
      [ReferralComponentCoreStateEnum.CheckInCompletedNotAcknowledged]:
        "Check in finished",
      [ReferralComponentCoreStateEnum.CheckInClosed]: "Check in is now closed",
      [ReferralComponentCoreStateEnum.CheckInFastTrackedConsentNeeded]:
        "Incomplete check in",
      [ReferralComponentCoreStateEnum.CheckInFastTrackedOutstandingItemsWithoutConsent]:
        "Incomplete check in",
      [ReferralComponentCoreStateEnum.CheckInIncomplete]: "Check in needed",
    },
  },
  self_schedule_appointment: {
    ctm: {
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.Scheduled]:
        "Scheduled",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.ResolvedCompleted]:
        "Completed",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.MissedNoShow]:
        "Missed",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.Declined]: "Missed",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CanceledByMember]:
        "Canceled by Member",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.ResolvedCanceledAckd]:
        "Canceled by Member",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CanceledByCtm]:
        "Canceled by CTM",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.RescheduledPending]:
        "Reschedule requested",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CanceledAckdPending]:
        "Canceled by Member",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.ResolvedCanceledViewed]:
        "Canceled by CTM",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CheckInOpened]:
        "Check in is now open",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CheckInInProgress]:
        "Check in started",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CheckInCompleted]:
        "Check in finished",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CheckInCompletedNotAcknowledged]:
        "Check in finished",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CheckInClosed]:
        "Incomplete check in",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CheckInIncomplete]:
        "Incomplete check in",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CheckInFastTrackedConsentNeeded]:
        "Fast tracked check in",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CheckInFastTrackedOutstandingItemsWithoutConsent]:
        "Fast tracked check in",
    },
    member: {
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.Scheduled]:
        "Scheduled",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.ResolvedCompleted]:
        "Completed",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CanceledByMember]:
        "Canceled by you",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.ResolvedCanceledAckd]:
        "Canceled by you",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CanceledByCtm]:
        "Canceled by my Team",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.MissedNoShow]:
        "Missed",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.Declined]:
        "Declined",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.RescheduledPending]:
        "Reschedule requested",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CanceledAckdPending]:
        "Canceled by you",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.ResolvedCanceledViewed]:
        "Canceled by my Team",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CheckInOpened]:
        "Check in is now open",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CheckInInProgress]:
        "Check in started",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CheckInCompleted]:
        "Check in finished",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CheckInCompletedNotAcknowledged]:
        "Check in finished",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CheckInClosed]:
        "Check in is now closed",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CheckInFastTrackedConsentNeeded]:
        "Incomplete check in",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CheckInFastTrackedOutstandingItemsWithoutConsent]:
        "Incomplete check in",
      [SelfScheduleAppointmentComponentOutputPropsStateEnum.CheckInIncomplete]:
        "Check in needed",
    },
  },
  question_set: {
    ctm: {
      [QuestionSetComponentOutputPropsStateEnum.UnreadPendingResponse]:
        "Not yet answered",
      [QuestionSetComponentOutputPropsStateEnum.ReadPendingResponse]:
        "Member viewed",
      [QuestionSetComponentOutputPropsStateEnum.DraftMember]: "In draft",
      [QuestionSetComponentOutputPropsStateEnum.ResponsesSentPendingAck]:
        "Answered",
      [QuestionSetComponentOutputPropsStateEnum.AcknowledgedPendingResponse]:
        "Acknowledged PreFormatted",
      [QuestionSetComponentOutputPropsStateEnum.Resolved]: "Acknowledged",
    },
    member: {
      [QuestionSetComponentOutputPropsStateEnum.DraftMember]: "In draft",
      [QuestionSetComponentOutputPropsStateEnum.ReadPendingResponse]:
        "Not yet answered",
      [QuestionSetComponentOutputPropsStateEnum.UnreadPendingResponse]:
        "Not yet answered",
      [QuestionSetComponentOutputPropsStateEnum.ResponsesSentPendingAck]:
        "Answered",
      [QuestionSetComponentOutputPropsStateEnum.AcknowledgedPendingResponse]:
        "Answered",
      [QuestionSetComponentOutputPropsStateEnum.Resolved]: "Answered",
    },
  },
  calculator: {
    ctm: {
      [CalculatorComponentOutputPropsStateEnum.UnreadPendingResponse]:
        "Not yet answered",
      [CalculatorComponentOutputPropsStateEnum.ReadPendingResponse]:
        "Member viewed",
      [CalculatorComponentOutputPropsStateEnum.DraftMember]: "In draft",
      [CalculatorComponentOutputPropsStateEnum.ResponsesSentPendingAck]:
        "Answered",
      [CalculatorComponentOutputPropsStateEnum.AcknowledgedPendingResponse]:
        "Answered",
      [CalculatorComponentOutputPropsStateEnum.Resolved]: "Acknowledged",
      [CalculatorComponentOutputPropsStateEnum.CompletedByCtmResolved]:
        "Acknowledged",
      [CalculatorComponentOutputPropsStateEnum.CompletedByCtmPendingAck]:
        "Unacknowledged",
      [CalculatorComponentOutputPropsStateEnum.CompletedByCtmAcknowledgedPendingResponse]:
        "Unacknowledged",
    },
    member: {
      [CalculatorComponentOutputPropsStateEnum.UnreadPendingResponse]:
        "Not yet answered",
      [CalculatorComponentOutputPropsStateEnum.ReadPendingResponse]:
        "Not yet answered",
      [CalculatorComponentOutputPropsStateEnum.DraftMember]: "Draft",
      [CalculatorComponentOutputPropsStateEnum.ResponsesSentPendingAck]:
        "Answered",
      [CalculatorComponentOutputPropsStateEnum.AcknowledgedPendingResponse]:
        "Answered",
      [CalculatorComponentOutputPropsStateEnum.Resolved]: "Answered",
      [CalculatorComponentOutputPropsStateEnum.CompletedByCtmResolved]:
        "Answered",
      [CalculatorComponentOutputPropsStateEnum.CompletedByCtmPendingAck]:
        "Answered",
    },
  },
};

const alternateComponentStatusMessages = {
  episode_created: {
    ctm: {
      acknowledged_pending_response: "Not yet acknowledged",
    },
  },
  messaging_episode_created: {
    ctm: {
      acknowledged_pending_response: "Not yet acknowledged",
    },
  },
  member_message: {
    ctm: {
      acknowledged_pending_response: "Not yet acknowledged",
    },
  },
  question_set: {
    ctm: {
      [QuestionSetComponentOutputPropsStateEnum.AcknowledgedPendingResponse]:
        "Answered",
    },
  },
};

// TODO: once component states are updated for MessageAndMore, update this
const statusesNeedingBySuffix = [
  componentStatusMessages.episode_created.ctm.acknowledged_pending_response,
  componentStatusMessages.episode_created.ctm.resolved,
  componentStatusMessages.member_message.ctm.acknowledged_pending_response,
  componentStatusMessages.member_message.ctm.resolved,
  componentStatusMessages.member_message.ctm.resolved_with_reply,
  componentStatusMessages.question_set.ctm.acknowledged_pending_response,
  componentStatusMessages.question_set.ctm.resolved,
  componentStatusMessages.virtual_visit_referral.ctm.declined_ackd_pending,
  componentStatusMessages.virtual_visit_referral.ctm.canceled_ackd_pending,
  componentStatusMessages.center_visit_referral.ctm.canceled_ackd_pending,
  componentStatusMessages.center_visit_referral.ctm.declined_ackd_pending,
  componentStatusMessages.calculator.ctm.acknowledged_pending_response,
];

export const getFormattedComponentStatusMessage = (
  currentUser: JsonUser,
  component: SummaryComponentOutput | ComponentOutput,
  actionableItems?: { [key: string]: ActionableItemSummary[] },
  componentDetail?: boolean
) => {
  const { creatorInfo, acknowledger, resolverInfo, state, type } = component;

  // special state mapping for bundles in cases where there are incomplete AIs
  // `type` typed as `any` until BE returns `component.type` of `message_bundle`
  if ((component.type as any) === "message_bundle") {
    const incompleteAis =
      (actionableItems?.[component?.id]?.length &&
        actionableItems?.[component?.id].filter(
          (ai: ActionableItemSummary) => ai.state === "active"
        )) ||
      [];
    if (incompleteAis.length) {
      return "Not yet completed";
    } else {
      return "Completed";
    }
  } else {
    const updater = resolverInfo || acknowledger;
    const isSelf = currentUser?.id?.toString() === updater?.id?.toString();
    const userType = currentUser.ctm ? "ctm" : "member";
    const unformattedStates = [
      "resolved_declined_ackd",
      "resolved_canceled_ackd",
      "declined_ackd_pending",
      "canceled_ackd_pending",
    ];

    let formattedMessage =
      componentStatusMessages?.[type]?.[userType]?.[state!] || "";

    /**
     * Special formatted message for Resolved state
     * Because we only need this message displayed on component details page
     * */
    if (
      componentDetail &&
      // @ts-ignore: Object is possibly 'null'.
      ["resolved", "resolved_with_reply"].includes(state) &&
      currentUser?.ctm &&
      component?.type !== ComponentCoreTypeEnum.MessagingEpisodeCreated &&
      component?.type !== ComponentCoreTypeEnum.CtmMessagingEpisodeCreated
    ) {
      const resolver =
        currentUser?.id?.toString() === resolverInfo?.id?.toString()
          ? "you"
          : resolverInfo?.name;
      formattedMessage = `Resolved by ${resolver}`;
    }

    /**
     * Special formatted message when ctm complete the calculator.
     * Because we only need this message displayed on component details page
     * */

    if (
      componentDetail &&
      currentUser?.ctm &&
      component?.type === ComponentCoreTypeEnum.Calculator
    ) {
      const updaterInfo = resolverInfo || creatorInfo;
      const updaterName =
        currentUser?.id?.toString() === updaterInfo?.id?.toString()
          ? "You"
          : updaterInfo?.name;

      if ("completed_by_ctm_resolved" === state) {
        formattedMessage = `Resolved on behalf of the member by ${updaterName}`;
      }

      if ("completed_by_ctm_pending_ack" === state) {
        formattedMessage = `Unacknowledged. Answered on behalf of the member by ${updaterName}`;
      }
    }

    if (
      statusesNeedingBySuffix.includes(formattedMessage) &&
      (isSelf || updater?.legalName) &&
      type !== SummaryComponentCoreTypeEnum.CtmEpisodeCreated &&
      type !== SummaryComponentCoreTypeEnum.Calculator &&
      // @ts-ignore: Object is possibly 'null'.
      !unformattedStates.includes(component.state)
    ) {
      if (isSelf) {
        formattedMessage += " by you";
      }
      if (
        componentStatusMessages?.[type]?.[userType]?.[state!] ===
        "Acknowledged PreFormatted"
      ) {
        formattedMessage =
          alternateComponentStatusMessages?.[type]?.[userType]?.[state!];
      }
    }
    return formattedMessage;
  }
};

export const getComponentFromEventType = (
  eventType: TimelineEventOutputCoreEventTypeEnum,
  components: ComponentOutput[]
): ComponentOutput => {
  // find a component based on the timeline event type
  // if message and more return the ctm_message component
  // @ts-ignore: Object is possibly 'null'.
  return eventType !== TimelineEventOutputCoreEventTypeEnum.MessageBundle
    ? components.find(
        (component) =>
          component?.type && component.type.toString() === eventType
      ) || components[0]
    : components.find(
        (component) =>
          component.type.toString() === SummaryComponentCoreTypeEnum.CtmMessage
      );
};

export const getEpisodeInCompleteActionableItems = (allActionableItems: {
  [key: string]: ActionableItemSummary[];
}): ActionableItemSummary[] => {
  return flatten(valuesIn(allActionableItems)).filter(
    (item: ActionableItemSummary) =>
      item.state === ActionableItemSummaryStateEnum.Active ||
      item.state === ActionableItemSummaryStateEnum.AcknowledgedPending
  );
};

export const getInCompleteActionableItems = (
  allActionableItems: ActionableItemSummary[]
) => {
  allActionableItems && changeUTCtoLocalTime(allActionableItems);

  return (
    allActionableItems?.filter(
      (actionableItem: ActionableItemSummary) =>
        actionableItem.state === ActionableItemSummaryStateEnum.Active ||
        actionableItem.state ===
          ActionableItemSummaryStateEnum.AcknowledgedPending
    ) || []
  );
};

export const getFiles = (
  docs: DocumentObjectDetails[] | undefined,
  docIds: number[]
) => {
  return (
    docs?.filter((document: DocumentObjectDetails) =>
      docIds?.includes(Number(document.id))
    ) || []
  );
};

export const getUniqueCtms = (arr: CTMInfo[] | undefined) => [
  ...(new Map(
    arr?.map((ctm) => {
      const ctmMutableCopy = Object.assign({}, ctm);
      // @ts-ignore: Object is possibly 'null'.
      ctmMutableCopy.podId = null;
      // @ts-ignore: Object is possibly 'null'.
      ctmMutableCopy.careTeamName = undefined;
      return [ctmMutableCopy.id, ctmMutableCopy];
    })
  )?.values() || []),
];
