// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     ExtendedDisplayPropertiesSchema,
    ExtendedDisplayPropertiesSchemaFromJSON,
    ExtendedDisplayPropertiesSchemaToJSON,
} from './ExtendedDisplayPropertiesSchema';
import {
     InputServiceLineSchemaAllOf,
    InputServiceLineSchemaAllOfFromJSON,
    InputServiceLineSchemaAllOfToJSON,
} from './InputServiceLineSchemaAllOf';
import {
     InputServiceLineSchemaAllOf1,
    InputServiceLineSchemaAllOf1FromJSON,
    InputServiceLineSchemaAllOf1ToJSON,
} from './InputServiceLineSchemaAllOf1';
import {
     InputServiceLineSchemaAllOf2,
    InputServiceLineSchemaAllOf2FromJSON,
    InputServiceLineSchemaAllOf2ToJSON,
} from './InputServiceLineSchemaAllOf2';
import {
     InputSubTypeSchema,
    InputSubTypeSchemaFromJSON,
    InputSubTypeSchemaToJSON,
} from './InputSubTypeSchema';
/**
 * @type InputServiceLineSchema
 * @export
 */
export interface InputServiceLineSchema extends ExtendedDisplayPropertiesSchema, InputServiceLineSchemaAllOf, InputServiceLineSchemaAllOf1, InputServiceLineSchemaAllOf2 {
}

export function InputServiceLineSchemaFromJSON(json: any): InputServiceLineSchema {
    return {
        ...ExtendedDisplayPropertiesSchemaFromJSON(json),
        ...InputServiceLineSchemaAllOfFromJSON(json),
        ...InputServiceLineSchemaAllOf1FromJSON(json),
        ...InputServiceLineSchemaAllOf2FromJSON(json),
    };
}

export function InputServiceLineSchemaToJSON(value?: InputServiceLineSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...ExtendedDisplayPropertiesSchemaToJSON(value),
        ...InputServiceLineSchemaAllOfToJSON(value),
        ...InputServiceLineSchemaAllOf1ToJSON(value),
        ...InputServiceLineSchemaAllOf2ToJSON(value),
    };
}

