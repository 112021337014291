import * as React from "react";
import { useNavigate } from "react-router";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Fade } from "@mui/material";
import cloneDeep from "lodash/cloneDeep";
import Box from "components/Box";

const useStyles: any = makeStyles(() =>
  createStyles({
    list: {
      width: "100%",
      position: "absolute",
      background: "white",

      "& .MuiListItem-button": {
        paddingTop: 12,
        paddingBottom: 12,
      },
    },
    fullList: {
      width: "auto",
    },
  })
);

const LeftArrow = (props: any) => (
  <Box component="span" display="inline-block" fontFamily="Arial" {...props}>
    ◄
  </Box>
);
const RightArrow = (props: any) => (
  <Box component="span" display="inline-block" fontFamily="Arial" {...props}>
    ►
  </Box>
);

const SideList: any = ({
  items,
  depth = 0,
  classes,
  onClick,
}: {
  items: any;
  depth: number;
  classes: any;
  onClick: any;
}) => {
  const navigate = useNavigate();
  const [index, setIndex] = React.useState(-1);
  let nextItems: any = null;
  if (index !== -1) {
    nextItems = (
      <Fade in>
        <div
          className={classes.list}
          style={{
            position: "absolute",
            width: "100%",
            background: "white",
            top: 0,
          }}
        >
          <>
            <List data-e2e={`hmenu-level-${depth}`}>
              <ListItem
                button
                key={items[index].menuPath}
                onClick={() => {
                  setIndex(-1);
                }}
              >
                <ListItemText
                  primary={
                    <div style={{ marginLeft: 28 }}>
                      <LeftArrow style={{ position: "absolute" }} ml={-3} />{" "}
                      {items[index].menuPath}
                    </div>
                  }
                />
              </ListItem>
            </List>
            <Divider />
          </>
          <SideList
            classes={classes}
            items={items[index].items}
            depth={depth + 1}
            onClick={onClick}
          />
        </div>
      </Fade>
    );
  }

  return (
    <>
      {index === -1 && (
        <div className={classes.list} role="presentation">
          <List data-e2e={`hmenu-level-${depth}`}>
            {items.map((item: any, i: number) => {
              return (
                <ListItem
                  button
                  data-testid="list-item"
                  hidden={item.active === false}
                  disabled={!item?.items?.length && !item.pathname}
                  key={item.name || item.label}
                  onClick={() => {
                    if (item?.items?.length) {
                      setIndex(i);
                      return;
                    }
                    if (onClick) {
                      const isCancellable = onClick(item);
                      if (isCancellable) {
                        return;
                      }
                    }

                    if (item.pathname) {
                      if (item.isExternal) {
                        window.open(item.pathname);
                        return;
                      }
                      navigate(item.pathname);
                    }
                  }}
                >
                  <ListItemText
                    primary={
                      <div style={{ marginLeft: 28 }}>
                        <span>{item.name || item.label}</span>{" "}
                        {item?.items?.length ? <RightArrow ml={1} /> : null}
                      </div>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </div>
      )}
      {nextItems}
    </>
  );
};

const prepareItems = (items: any, path = "") => {
  items.forEach((item: any) => {
    item.menuPath = path === "" ? "" : path + "/";
    item.menuPath += item.name || item.label;
    if (item.items) {
      prepareItems(item.items, item.name || item.label);
    }
  });
};

const HierarchicalMenu = (props: any) => {
  const classes = useStyles();
  const items = cloneDeep(props.items);
  prepareItems(items);

  return <SideList classes={classes} items={items} onClick={props.onClick} />;
};

export default HierarchicalMenu;
