import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";

import { getFeatures } from "shared/features/featureFlags/selectors";
import NewEpisodeCta from "../NewEpisodeCta";
import SelfSchedulingActions from "../SelfSchedulingActions";

export const DashboardCreateActions: FunctionComponent<{}> = (): JSX.Element => {
  const featureFlags = useSelector(getFeatures);

  return (
    <>
      {featureFlags.hasSelfSchedule() ? (
        <SelfSchedulingActions />
      ) : (
        <NewEpisodeCta />
      )}
    </>
  );
};

export default DashboardCreateActions;
