import { useMutation } from "redux-query-react";
import { QueryConfig, Entities } from "redux-query";
import {
  putUserSetting,
  PutUserSettingRequest,
} from "shared/api/src/apis/UserSettingsApi";
import { UserSettings } from "shared/api/src/models/UserSettings";

export const useUpdateUserSettings = () => {
  const queryConfig = (request: PutUserSettingRequest): any =>
    putUserSetting(request, {
      transform: (body: UserSettings) => ({
        userSettings: !!body?.data ? JSON.parse(body?.data) : {},
      }),
      update: {
        userSettings: (prev: any, next: any) => {
          // always return prev unless there is no filter entry; we don't want to update this based on the response or the UI will jump around on a slow connection
          if (!prev?.filters || !prev?.activeFilters) {
            return {
              ...prev,
              ...next,
            };
          }

          return prev;
        },
      },
    });

  const [, updateSettings] = useMutation(
    (id: string, userSettings: UserSettings) => {
      return queryConfig({ id, userSettings }) as QueryConfig<Entities>;
    }
  );

  return { updateSettings };
};
