// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ServiceLinesList,
    ServiceLinesListFromJSON,
    ServiceLinesListToJSON,
} from './ServiceLinesList';


/**
 * 
 * @export
 * @interface ServiceLinesSchema
 */
export interface ServiceLinesSchema  {
    /**
     * 
     * @type {string}
     * @memberof ServiceLinesSchema
     */
    contentTypeCategoryId: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLinesSchema
     */
    contentTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof ServiceLinesSchema
     */
    contentTypeLabel: string;
    /**
     * 
     * @type {number}
     * @memberof ServiceLinesSchema
     */
    totalServiceLines?: number;
    /**
     * 
     * @type {Array<ServiceLinesList>}
     * @memberof ServiceLinesSchema
     */
    contentTypeItems: Array<ServiceLinesList>;
}

export function ServiceLinesSchemaFromJSON(json: any): ServiceLinesSchema {
    return {
        'contentTypeCategoryId': json['content_type_category_id'],
        'contentTypeId': json['content_type_id'],
        'contentTypeLabel': json['content_type_label'],
        'totalServiceLines': !exists(json, 'total_service_lines') ? undefined : json['total_service_lines'],
        'contentTypeItems': (json['content_type_items'] as Array<any>).map(ServiceLinesListFromJSON),
    };
}

export function ServiceLinesSchemaToJSON(value?: ServiceLinesSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'content_type_category_id': value.contentTypeCategoryId,
        'content_type_id': value.contentTypeId,
        'content_type_label': value.contentTypeLabel,
        'total_service_lines': value.totalServiceLines,
        'content_type_items': (value.contentTypeItems as Array<any>).map(ServiceLinesListToJSON),
    };
}


