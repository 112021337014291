import React, { FunctionComponent } from "react";
import GridComponent, { GridProps } from "@mui/material/Grid";

const Grid: FunctionComponent<GridProps> = React.forwardRef((props, ref) => (
  <GridComponent ref={ref} {...props} />
));

Grid.displayName = "Grid";

export default Grid;
