const SET_STEP = "SET_STEP";
const SET_STEPS_DATA = "SET_STEPS_DATA";
const SET_SUMMMARY_LABELS = "SET_SUMMARY_LABELS";
const CLEAR_STEPS_DATA = "CLEAR_STEPS_DATA";

export const defaultSummaryLabels = {
  visitType: "",
  provider: "",
  appointmentLocation: "",
  providerDatePicker: "",
  bookAppointment: "",
  finalizeDetails: "",
  timeAndDate: "",
};

export const createStepsDataPayload = (): IStepData => {
  return Object.values(Steps).reduce((acc, step) => {
    acc[step] = {};
    return acc;
  }, {}) as IStepData;
};

export type StoreType = typeof defaultStore;

export interface ISummaryLabels {
  visitType: string;
  provider: string;
  appointmentLocation: string;
  providerDatePicker: string;
  bookAppointment: string;
  finalizeDetails: string;
  timeAndDate: string;
}

export interface IStepData {
  visitType: any;
  newEpisode: any;
  provider: any;
  timeAndDate: any;
  selectLocation: any;
  providerDatePicker: any;
  bookAppointment: any;
  finalizeDetails: any;
  appointmentSlotId: any;
  appointmentLocation: any;
}

export const Steps = {
  VISIT_TYPE: "visitType",
  APPOINTMENT_LOCATION: "appointmentLocation",
  PROVIDER_DATE_PICKER: "providerDatePicker",
  BOOK_APPOINTMENT: "bookAppointment",
  FINALIZE_DETAILS: "finalizeDetails",
};

export const defaultStore = {
  step: "",
  steps: Steps,
  stepsData: createStepsDataPayload(),
  hasNext: false,
  hasPrevious: false,
  summaryLabels: defaultSummaryLabels,
};

export const generateDefaultSelfSchedule = () => {
  return defaultStore;
};

export const listOfSteps = Object.values(Steps);

export interface IState {
  step: string;
  steps: any;
  stepsData: IStepData;
  hasNext: boolean;
  hasPrevious: boolean;
  summaryLabels: ISummaryLabels;
}

export const selfSchedule = (
  state: IState = generateDefaultSelfSchedule(),
  action: any
): IState => {
  switch (action.type) {
    case SET_STEP:
      return {
        ...state,
        step: action.payload,
      };

    case SET_STEPS_DATA:
      return {
        ...state,
        stepsData: action.payload,
      };
    case SET_SUMMMARY_LABELS:
      return {
        ...state,
        summaryLabels: action.payload,
      };
    case CLEAR_STEPS_DATA:
      return generateDefaultSelfSchedule();
    default:
      return state;
  }
};

export const setStep = (step: any) => {
  return { type: SET_STEP, payload: step };
};

export const setStepsData = (stepsData: IStepData) => {
  return { type: SET_STEPS_DATA, payload: stepsData };
};

export const setSummaryLabels = (summaryLabels: ISummaryLabels) => {
  return { type: SET_SUMMMARY_LABELS, payload: summaryLabels };
};

export const clearStepsData = () => {
  return { type: CLEAR_STEPS_DATA };
};
