// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberSubscriptionItemsPrice
 */
export interface MemberSubscriptionItemsPrice  {
    /**
     * 
     * @type {number}
     * @memberof MemberSubscriptionItemsPrice
     */
    unitAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof MemberSubscriptionItemsPrice
     */
    unitAmountDecimal?: string;
    /**
     * 
     * @type {boolean}
     * @memberof MemberSubscriptionItemsPrice
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MemberSubscriptionItemsPrice
     */
    billingScheme?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberSubscriptionItemsPrice
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberSubscriptionItemsPrice
     */
    type?: string;
}

export function MemberSubscriptionItemsPriceFromJSON(json: any): MemberSubscriptionItemsPrice {
    return {
        'unitAmount': !exists(json, 'unit_amount') ? undefined : json['unit_amount'],
        'unitAmountDecimal': !exists(json, 'unit_amount_decimal') ? undefined : json['unit_amount_decimal'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'billingScheme': !exists(json, 'billing_scheme') ? undefined : json['billing_scheme'],
        'currency': !exists(json, 'currency') ? undefined : json['currency'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function MemberSubscriptionItemsPriceToJSON(value?: MemberSubscriptionItemsPrice): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'unit_amount': value.unitAmount,
        'unit_amount_decimal': value.unitAmountDecimal,
        'active': value.active,
        'billing_scheme': value.billingScheme,
        'currency': value.currency,
        'type': value.type,
    };
}


