import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { showSnackbar } from "shared/state/ui/snackbar";
import { UploadUserDocumentRequest, fetchDocumentsApi } from "shared/fetch/src/apis/DocumentsApi";

interface IProps {
  skipSuccessToast?: boolean;
}

const useUploadDocumentMutation = (props?: IProps | undefined) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (request: UploadUserDocumentRequest) =>
      fetchDocumentsApi.uploadUserDocument(request),
    {
      onSuccess: (_response, request) => {
        if (!props?.skipSuccessToast) {
          dispatch(showSnackbar("Document has been uploaded.", "success"));
        }
        queryClient.invalidateQueries(["memberDocumentsList", request.id]);
      },
      onError: (_response, request) => {
        dispatch(
          showSnackbar("There was an error adding this document.", "danger")
        );
        queryClient.invalidateQueries(["memberDocumentsList", request.id]);
      },
    }
  );

  return mutation;
};

export default useUploadDocumentMutation;
