import React from "react";
import { useTheme } from "@mui/material";

const CardSkeleton = ({
  children,
  style,
}: {
  children: any;
  style?: React.CSSProperties;
}) => {
  const { palette } = useTheme();
  return (
    <div
      style={{
        background: "white",
        boxShadow: `2px 2px 6px ${palette.boxShadowLightGray}`,
        borderRadius: "10px",
        padding: 26,
        marginTop: 32,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default CardSkeleton;
