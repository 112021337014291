// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MemberSaidInsurance
 */
export interface MemberSaidInsurance  {
    /**
     * 
     * @type {string}
     * @memberof MemberSaidInsurance
     */
    ins1Field1?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberSaidInsurance
     */
    ins1Field2?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberSaidInsurance
     */
    ins1Field3?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberSaidInsurance
     */
    ins1Notes?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberSaidInsurance
     */
    ins2Field1?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberSaidInsurance
     */
    ins2Field2?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberSaidInsurance
     */
    ins2Field3?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberSaidInsurance
     */
    ins2Notes?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberSaidInsurance
     */
    ins3Field1?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberSaidInsurance
     */
    ins3Field2?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberSaidInsurance
     */
    ins3Field3?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberSaidInsurance
     */
    ins3Notes?: string;
}

export function MemberSaidInsuranceFromJSON(json: any): MemberSaidInsurance {
    return {
        'ins1Field1': !exists(json, 'ins1_field1') ? undefined : json['ins1_field1'],
        'ins1Field2': !exists(json, 'ins1_field2') ? undefined : json['ins1_field2'],
        'ins1Field3': !exists(json, 'ins1_field3') ? undefined : json['ins1_field3'],
        'ins1Notes': !exists(json, 'ins1_notes') ? undefined : json['ins1_notes'],
        'ins2Field1': !exists(json, 'ins2_field1') ? undefined : json['ins2_field1'],
        'ins2Field2': !exists(json, 'ins2_field2') ? undefined : json['ins2_field2'],
        'ins2Field3': !exists(json, 'ins2_field3') ? undefined : json['ins2_field3'],
        'ins2Notes': !exists(json, 'ins2_notes') ? undefined : json['ins2_notes'],
        'ins3Field1': !exists(json, 'ins3_field1') ? undefined : json['ins3_field1'],
        'ins3Field2': !exists(json, 'ins3_field2') ? undefined : json['ins3_field2'],
        'ins3Field3': !exists(json, 'ins3_field3') ? undefined : json['ins3_field3'],
        'ins3Notes': !exists(json, 'ins3_notes') ? undefined : json['ins3_notes'],
    };
}

export function MemberSaidInsuranceToJSON(value?: MemberSaidInsurance): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'ins1_field1': value.ins1Field1,
        'ins1_field2': value.ins1Field2,
        'ins1_field3': value.ins1Field3,
        'ins1_notes': value.ins1Notes,
        'ins2_field1': value.ins2Field1,
        'ins2_field2': value.ins2Field2,
        'ins2_field3': value.ins2Field3,
        'ins2_notes': value.ins2Notes,
        'ins3_field1': value.ins3Field1,
        'ins3_field2': value.ins3Field2,
        'ins3_field3': value.ins3Field3,
        'ins3_notes': value.ins3Notes,
    };
}


