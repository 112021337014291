import React from "react";

const componentLoader = (
  lazyComponent: () => Promise<any>,
  attemptsLeft: number
) => {
  return new Promise<{ default: React.ComponentType<any> }>(
    (resolve, reject) => {
      lazyComponent()
        .then(resolve)
        .catch((error) => {
          setTimeout(() => {
            if (attemptsLeft === 1) {
              reject(error);
              return;
            }
            componentLoader(lazyComponent, attemptsLeft - 1).then(
              resolve,
              reject
            );
          }, 1500);
        });
    }
  );
};

export const loadLazy = (lazyComponent: () => Promise<any>) => {
  return React.lazy(() => componentLoader(lazyComponent, 3));
};
