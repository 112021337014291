// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompactComponentReference,
    CompactComponentReferenceFromJSON,
    CompactComponentReferenceToJSON,
} from './CompactComponentReference';
import {
    DocumentOutputSchema,
    DocumentOutputSchemaFromJSON,
    DocumentOutputSchemaToJSON,
} from './DocumentOutputSchema';


/**
 * 
 * @export
 * @interface CtmEpisodeCreatedComponentOutputProps
 */
export interface CtmEpisodeCreatedComponentOutputProps  {
    /**
     * 
     * @type {string}
     * @memberof CtmEpisodeCreatedComponentOutputProps
     */
    state: CtmEpisodeCreatedComponentOutputPropsStateEnum;
    /**
     * 
     * @type {Array<DocumentOutputSchema>}
     * @memberof CtmEpisodeCreatedComponentOutputProps
     */
    documentInfo?: Array<DocumentOutputSchema>;
    /**
     * 
     * @type {Array<CompactComponentReference>}
     * @memberof CtmEpisodeCreatedComponentOutputProps
     */
    childComponents?: Array<CompactComponentReference>;
}

export function CtmEpisodeCreatedComponentOutputPropsFromJSON(json: any): CtmEpisodeCreatedComponentOutputProps {
    return {
        'state': json['state'],
        'documentInfo': !exists(json, 'document_info') ? undefined : (json['document_info'] as Array<any>).map(DocumentOutputSchemaFromJSON),
        'childComponents': !exists(json, 'child_components') ? undefined : (json['child_components'] as Array<any>).map(CompactComponentReferenceFromJSON),
    };
}

export function CtmEpisodeCreatedComponentOutputPropsToJSON(value?: CtmEpisodeCreatedComponentOutputProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'state': value.state,
        'document_info': value.documentInfo === undefined ? undefined : (value.documentInfo as Array<any>).map(DocumentOutputSchemaToJSON),
        'child_components': value.childComponents === undefined ? undefined : (value.childComponents as Array<any>).map(CompactComponentReferenceToJSON),
    };
}

/**
* @export
* @enum {string}
*/
export enum CtmEpisodeCreatedComponentOutputPropsStateEnum {
    ResolvedAcknowledged = 'resolved_acknowledged'
}


