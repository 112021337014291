import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import {
  RevokeProxyRequest,
  fetchMemberTransitionApi,
} from "shared/fetch/src/apis/MemberTransitionApi";
import { showSnackbar } from "shared/state/ui/snackbar";

const useRevokeProxyMinor = (minorFirstName: string | undefined) => {
  const dispatch = useDispatch();

  const revokeMinorProxy = useMutation((request: RevokeProxyRequest) => {
    return fetchMemberTransitionApi
      .revokeProxy(request)
      .then(() => {
        dispatch(
          showSnackbar(
            `Success! Proxy for ${minorFirstName} has been revoked.`,
            "success"
          )
        );
      })
      .catch(() => {
        dispatch(
          showSnackbar(
            `There was an error revoking proxy for ${minorFirstName}'s account.`,
            "danger"
          )
        );
      });
  });

  return revokeMinorProxy;
};

export default useRevokeProxyMinor;
