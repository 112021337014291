// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    FetchPatientQuickListSuccessResponsePrimaryAccountHolder,
    FetchPatientQuickListSuccessResponsePrimaryAccountHolderFromJSON,
    FetchPatientQuickListSuccessResponsePrimaryAccountHolderToJSON,
} from './FetchPatientQuickListSuccessResponsePrimaryAccountHolder';


/**
 * 
 * @export
 * @interface FetchPatientQuickListSuccessResponseResults
 */
export interface FetchPatientQuickListSuccessResponseResults  {
    /**
     * 
     * @type {number}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    preferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    legalAndPreferredName?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    gender?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    sexAtBirth?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    age?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    bornAt?: string;
    /**
     * 
     * @type {string}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    cellPhone?: string;
    /**
     * 
     * @type {boolean}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    hasProxyOption?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    hasRevokeOption?: boolean;
    /**
     * 
     * @type {FetchPatientQuickListSuccessResponsePrimaryAccountHolder}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    primaryAccountHolder?: FetchPatientQuickListSuccessResponsePrimaryAccountHolder;
    /**
     * 
     * @type {boolean}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    nestedMinor?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    consentedNested?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    metAgeOfConsent?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    registered?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FetchPatientQuickListSuccessResponseResults
     */
    minor?: boolean;
}

export function FetchPatientQuickListSuccessResponseResultsFromJSON(json: any): FetchPatientQuickListSuccessResponseResults {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'firstName': !exists(json, 'first_name') ? undefined : json['first_name'],
        'lastName': !exists(json, 'last_name') ? undefined : json['last_name'],
        'preferredName': !exists(json, 'preferred_name') ? undefined : json['preferred_name'],
        'legalAndPreferredName': !exists(json, 'legal_and_preferred_name') ? undefined : json['legal_and_preferred_name'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'sexAtBirth': !exists(json, 'sex_at_birth') ? undefined : json['sex_at_birth'],
        'age': !exists(json, 'age') ? undefined : json['age'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'bornAt': !exists(json, 'born_at') ? undefined : json['born_at'],
        'cellPhone': !exists(json, 'cell_phone') ? undefined : json['cell_phone'],
        'hasProxyOption': !exists(json, 'has_proxy_option') ? undefined : json['has_proxy_option'],
        'hasRevokeOption': !exists(json, 'has_revoke_option') ? undefined : json['has_revoke_option'],
        'primaryAccountHolder': !exists(json, 'primary_account_holder') ? undefined : FetchPatientQuickListSuccessResponsePrimaryAccountHolderFromJSON(json['primary_account_holder']),
        'nestedMinor': !exists(json, 'nested_minor') ? undefined : json['nested_minor'],
        'consentedNested': !exists(json, 'consented_nested') ? undefined : json['consented_nested'],
        'metAgeOfConsent': !exists(json, 'met_age_of_consent') ? undefined : json['met_age_of_consent'],
        'registered': !exists(json, 'registered') ? undefined : json['registered'],
        'minor': !exists(json, 'minor') ? undefined : json['minor'],
    };
}

export function FetchPatientQuickListSuccessResponseResultsToJSON(value?: FetchPatientQuickListSuccessResponseResults): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'name': value.name,
        'first_name': value.firstName,
        'last_name': value.lastName,
        'preferred_name': value.preferredName,
        'legal_and_preferred_name': value.legalAndPreferredName,
        'gender': value.gender,
        'sex_at_birth': value.sexAtBirth,
        'age': value.age,
        'status': value.status,
        'born_at': value.bornAt,
        'cell_phone': value.cellPhone,
        'has_proxy_option': value.hasProxyOption,
        'has_revoke_option': value.hasRevokeOption,
        'primary_account_holder': FetchPatientQuickListSuccessResponsePrimaryAccountHolderToJSON(value.primaryAccountHolder),
        'nested_minor': value.nestedMinor,
        'consented_nested': value.consentedNested,
        'met_age_of_consent': value.metAgeOfConsent,
        'registered': value.registered,
        'minor': value.minor,
    };
}


