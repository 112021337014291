
import { actionStatusMessages } from "components/EOCTimeline/utils";
import useFormatDate from "shared/utils/useFormatDate";
import { isBefore } from "date-fns";
import User from "models/User";
import { ComponentCoreTypeEnum, ComponentCoreUrgencyEnum } from "shared/fetch/src/models/ComponentCore";
import { ComponentOutput } from "shared/fetch/src/models/ComponentOutput";
import { CenterVisitReferralComponentOutput } from "shared/fetch/src/models/CenterVisitReferralComponentOutput";
import { SelfScheduleAppointmentComponent } from "shared/fetch/src/models/SelfScheduleAppointmentComponent";
import { VirtualVisitReferralComponentOutput } from "shared/fetch/src/models/VirtualVisitReferralComponentOutput";
import { Appointment } from "shared/fetch/src/models/Appointment";
import { ReferralComponentCoreStateEnum as  ReferralState } from "shared/fetch/src/models/ReferralComponentCore";
import { ComponentDetailsOutput } from "shared/fetch/src/models/ComponentDetailsOutput";
import { JsonUser } from "shared/fetch/src/models/JsonUser";
import { TimelineEventOutputCoreEventTypeEnum, TimelineEventOutputCoreStateEnum } from "shared/fetch/src/models/TimelineEventOutputCore";

export const statusesRequiringAction = [
  actionStatusMessages.episode_created.ctm.unacknowledged,
  actionStatusMessages.episode_created.ctm.acknowledged_pending_response,
  actionStatusMessages.ctm_message.member.unread,
  actionStatusMessages.ctm_message.member.unread_pending_response,
  actionStatusMessages.ctm_message.member.read_pending_response,
  actionStatusMessages.ctm_message.member.read_response_started,
  actionStatusMessages.member_message.ctm.unacknowledged,
  actionStatusMessages.message_bundle.member.unread,
  actionStatusMessages.member_message.ctm.acknowledged_pending_response,
  actionStatusMessages.virtual_visit_referral.member.unread_pending,
  actionStatusMessages.virtual_visit_referral.member.read_pending,
  actionStatusMessages.virtual_visit_referral.member.scheduled,
  actionStatusMessages.virtual_visit_referral.member.missed_no_show,
  actionStatusMessages.virtual_visit_referral.member.rescheduled_pending,
  actionStatusMessages.virtual_visit_referral.ctm.canceled_by_member,
  actionStatusMessages.virtual_visit_referral.ctm.declined,
  actionStatusMessages.virtual_visit_referral.ctm.declined_ackd_pending,
  actionStatusMessages.virtual_visit_referral.ctm.canceled_ackd_pending,
  actionStatusMessages.virtual_visit_referral.ctm
    .check_in_completed_not_acknowledged,
  actionStatusMessages.question_set.ctm.responses_sent_pending_ack,
  actionStatusMessages.question_set.ctm.acknowledged_pending_response,
  actionStatusMessages.question_set.member.unread_pending_response,
  actionStatusMessages.question_set.member.draft_member,
  actionStatusMessages.question_set.member.read_pending_response,
  actionStatusMessages.center_visit_referral.member.unread_pending,
  actionStatusMessages.center_visit_referral.member.read_pending,
  actionStatusMessages.center_visit_referral.member.scheduled,
  actionStatusMessages.center_visit_referral.member.missed_no_show,
  actionStatusMessages.center_visit_referral.member.rescheduled_pending,
  actionStatusMessages.center_visit_referral.ctm.canceled_by_member,
  actionStatusMessages.center_visit_referral.ctm.declined,
  actionStatusMessages.center_visit_referral.ctm.declined_ackd_pending,
  actionStatusMessages.center_visit_referral.ctm.canceled_ackd_pending,
  actionStatusMessages.center_visit_referral.ctm
    .check_in_completed_not_acknowledged,
  actionStatusMessages.calculator.member.draft_member,
  actionStatusMessages.calculator.member.unread_pending_response,
  actionStatusMessages.calculator.member.read_pending_response,
  actionStatusMessages.billing.member.unpaid,
  actionStatusMessages.billing.member.payment_requested,
  actionStatusMessages.billing.member.member_viewed,
];

export const selfScheduleStatusesRequiringAction = [
  actionStatusMessages.self_schedule_appointment.member.missed_no_show,
  actionStatusMessages.self_schedule_appointment.member.rescheduled_pending,
  actionStatusMessages.self_schedule_appointment.member.scheduled,
  actionStatusMessages.self_schedule_appointment.ctm.canceled_ackd_pending,
  actionStatusMessages.self_schedule_appointment.ctm.canceled_by_member,
  actionStatusMessages.self_schedule_appointment.ctm.check_in_incomplete,
  actionStatusMessages.self_schedule_appointment.ctm
    .check_in_completed_not_acknowledged,
  actionStatusMessages.self_schedule_appointment.member.check_in_in_progress,
];

export const getVirtualVisitReferralAppointmentStateLabel = (state: string) => {
  let label;
  switch (state) {
    case ReferralState.UnreadPending:
    case ReferralState.ReadPending:
    case ReferralState.RescheduledPending:
      label = "Urgency:";
      break;
    case ReferralState.Scheduled:
    case ReferralState.MissedNoShow:
    case ReferralState.ResolvedCompleted:
    case ReferralState.CheckInInProgress:
    case ReferralState.CheckInOpened:
    case ReferralState.CheckInCompletedNotAcknowledged:
      label = "Visit time:";
      break;
    case ReferralState.CanceledByMember:
    case ReferralState.ResolvedCanceledAckd:
    case ReferralState.CanceledByCtm:
    case ReferralState.ResolvedCanceledViewed:
    case ReferralState.CanceledAckdPending:
      label = "Original visit time:";
      break;
    case ReferralState.Declined:
    case ReferralState.ResolvedDeclinedAckd:
    case ReferralState.DeclinedAckdPending:
      label = "Visit time";
      break;
  }

  return label;
};

export const getVirtualVisitReferralAppointmentDateOrUrgency = (
  state: string,
  urgency?: string,
  apptDate?: string | Date
) => {
  const formatDate = useFormatDate();
  const urgencyLabelMap = {
    [ComponentCoreUrgencyEnum.Asap]: "Asap",
    [ComponentCoreUrgencyEnum.NextFewDays]: "Next few days",
    [ComponentCoreUrgencyEnum.NextFewWeeks]: "Next few weeks",
  };
  if (
    state === ReferralState.ReadPending ||
    state === ReferralState.UnreadPending ||
    state === ReferralState.RescheduledPending
  ) {
    return urgency ? urgencyLabelMap[urgency] : "";
  } else if (
    state === ReferralState.Scheduled ||
    state === ReferralState.MissedNoShow ||
    state === ReferralState.CheckInOpened ||
    state === ReferralState.ResolvedCompleted ||
    state === ReferralState.CanceledByMember ||
    state === ReferralState.ResolvedCanceledAckd ||
    state === ReferralState.CanceledByCtm ||
    state === ReferralState.ResolvedCanceledViewed ||
    state === ReferralState.CanceledAckdPending ||
    state === ReferralState.CheckInInProgress ||
    state === ReferralState.Declined ||
    state === ReferralState.ResolvedDeclinedAckd ||
    state === ReferralState.DeclinedAckdPending ||
    state === ReferralState.CheckInCompleted ||
    state === ReferralState.ResolvedCanceledAckd ||
    state === ReferralState.CheckInIncomplete ||
    state === ReferralState.CheckInClosed ||
    state === ReferralState.CheckInFastTrackedConsentNeeded ||
    state === ReferralState.CheckInFastTrackedOutstandingItemsWithoutConsent ||
    state === ReferralState.CheckInCompletedNotAcknowledged
  ) {
    return formatDate(apptDate, "MMM d, yyyy @ h:mmaaa zzz", {
      fallbackDate: "",
      invalidateDateFallback: "",
    });
  } else {
    return "";
  }
};

export function isVirtualVisitReferralComponentOutput(
  x: ComponentOutput
): x is VirtualVisitReferralComponentOutput {
  return x.type === ComponentCoreTypeEnum.VirtualVisitReferral;
}

export function isCenterVisitReferralComponentOutput(
  x: ComponentOutput
): x is CenterVisitReferralComponentOutput {
  return x.type === ComponentCoreTypeEnum.CenterVisitReferral;
}
export function isSelfScheduleComponentOutput(
  x: ComponentOutput
): x is SelfScheduleAppointmentComponent {
  return x.type === ComponentCoreTypeEnum.SelfScheduleAppointment;
}
export const isScheduledState = (
  c: VirtualVisitReferralComponentOutput | SelfScheduleAppointmentComponent
) => c.state === ReferralState.Scheduled;

export const isResolvedCompletedState = (
  c: VirtualVisitReferralComponentOutput | SelfScheduleAppointmentComponent
) => c.state === ReferralState.ResolvedCompleted;

export const isInCheckInState = (
  c:
    | VirtualVisitReferralComponentOutput
    | SelfScheduleAppointmentComponent
    | Appointment
) =>
  c?.state === ReferralState.CheckInOpened ||
  c?.state === ReferralState.CheckInCompleted ||
  c?.state === ReferralState.CheckInInProgress ||
  c?.state === ReferralState.CheckInCompletedNotAcknowledged ||
  c?.state === ReferralState.CheckInIncomplete ||
  c?.state === ReferralState.CheckInFastTrackedConsentNeeded ||
  c?.state ===
    ReferralState.CheckInFastTrackedOutstandingItemsWithoutConsent;

export const isPastVisit = (
  c: VirtualVisitReferralComponentOutput | SelfScheduleAppointmentComponent
  // @ts-ignore: Object is possibly 'null'.
) => isBefore(c?.appointment?.endAt, new Date());

export const isPhoneVisit = (
  c: VirtualVisitReferralComponentOutput | SelfScheduleAppointmentComponent
) => c.visitType?.method === "phone";

export const isVideoVisit = (
  c: VirtualVisitReferralComponentOutput | SelfScheduleAppointmentComponent
) => c.visitType?.method === "video";

export const hasAppointmentLinkAndPassword = (
  c: VirtualVisitReferralComponentOutput | SelfScheduleAppointmentComponent
) =>
  c?.appointment?.videoConference?.url &&
  c?.appointment?.videoConference?.passcode;
export const hasOnlyAppointmentLink = (
  c: VirtualVisitReferralComponentOutput | SelfScheduleAppointmentComponent
) =>
  c?.appointment?.videoConference?.url &&
  !c?.appointment?.videoConference?.passcode;
export const noAppointmentLinkOrPassword = (
  c: VirtualVisitReferralComponentOutput | SelfScheduleAppointmentComponent
) =>
  !c?.appointment?.videoConference?.url &&
  !c?.appointment?.videoConference?.passcode;

export const getQuestionsFromScreeningTemplate = (template: any): string[] => {
  return template?.pages
    ?.flatMap((x: any) => x.elements)
    .reduce((acc: any, value: any) => {
      if (!!value && value.type !== "expression" && value.type !== "html") {
        acc = acc.concat(value?.title);
      }
      return acc;
    }, []);
};

export const getConversationActionLink = (
  component: ComponentDetailsOutput,
  memberId: string,
  user: JsonUser,
  eventType?: TimelineEventOutputCoreEventTypeEnum,
  timelineState?: TimelineEventOutputCoreStateEnum
): { to: string; buttonText: any } => {
  const userType = User.getUserType(user);
  const buttonText =
    actionStatusMessages?.[eventType!]?.[userType]?.[
      // @ts-ignore: Object is possibly 'null'.
      eventType === TimelineEventOutputCoreEventTypeEnum.MessageBundle
        ? timelineState
        : component.state
    ];
  const linkId =
    eventType && eventType === "message_bundle"
      ? component.timelineEventId
      : component.id;
  const subPath =
    eventType && eventType === "message_bundle" ? "bundle" : "component";
  let to = `/members/${memberId}/conversations/${component.episodeId}/${subPath}/${linkId}`;
  if (user.ctm && buttonText?.match(/acknowledge check in/i)) {
    to = `${to}?modal=calculators`;
  }
  return { to, buttonText };
};
