// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateConsultationRequestBody
 */
export interface UpdateConsultationRequestBody  {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateConsultationRequestBody
     */
    consultation?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateConsultationRequestBody
     */
    ctmId?: string;
}

export function UpdateConsultationRequestBodyFromJSON(json: any): UpdateConsultationRequestBody {
    return {
        'consultation': !exists(json, 'consultation') ? undefined : json['consultation'],
        'ctmId': !exists(json, 'ctm_id') ? undefined : json['ctm_id'],
    };
}

export function UpdateConsultationRequestBodyToJSON(value?: UpdateConsultationRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'consultation': value.consultation,
        'ctm_id': value.ctmId,
    };
}


