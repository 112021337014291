import React from "react";
import useWithAccessProps from "hooks/useWithAccessProps";

import { Link } from "components/Button";

const LinkWithAccess = (props: any) => {
  const finalProps = useWithAccessProps(props);

  return <Link {...finalProps} />;
};

export default LinkWithAccess;
