import { useCallback } from "react";

import useAccess from "hooks/useAccess";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";

interface WithAccessPropsInput {
  access: PermissionsEnum;
  forceAccessible?: boolean;
  disabledStyles?: Record<string, any>;
  memberPermissionsToCheck?: PermissionsEnum[];
  shouldDisable?: boolean;
  style?: Record<string, any>;
  disabled?: boolean;
  isCancelOrDeclineButton?: boolean;
  hideConsentModal?: boolean;
}

interface WithAccessPropsOutput {
  "data-testid": string;
  disabled?: boolean;
  onClick?: any;
  to?: string;
  style?: Record<string, any>;
  isCancelOrDeclineButton?: boolean;
  hideConsentModal?: boolean;
}

const useWithAccessProps = ({
  access,
  forceAccessible,
  disabledStyles,
  shouldDisable = false,
  memberPermissionsToCheck,
  style,
  ...props
}: WithAccessPropsInput): WithAccessPropsOutput => {
  const hasAccess = useAccess({ to: access, memberPermissionsToCheck });
  if (forceAccessible) {
    return { "data-testid": "enabled", ...props };
  }

  const onDisabledAccessClick = useCallback(() => null, []);

  if (!hasAccess || shouldDisable) {
    return {
      disabled: true,
      "data-testid": "disabled",
      ...props,
      onClick: onDisabledAccessClick,
      style: { ...style, ...disabledStyles },
    };
  }

  return { "data-testid": "enabled", ...props };
};

export default useWithAccessProps;
