import * as React from "react";
import classes from "./index.module.css";
import CssTypography from "components/CssTypography";
import ErrorIcon from "@mui/icons-material/Error";
import clsx from "clsx";

interface IProps {
  message: string;
  type?: string;
}

const DependentAccessDisclaimer: React.FunctionComponent<IProps> = ({
  message,
  type,
}) => {
  const color = type === "checkin" ? classes.checkin : "";
  return (
    <div className={clsx(classes.container, color)}>
      <ErrorIcon className={classes.icon} />
      <CssTypography appearance="smallBody" className={classes.text}>
        {message}
      </CssTypography>
    </div>
  );
};

export default DependentAccessDisclaimer;
