import palette from "../palette";

export default {
  elevation1: {
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
  },
  elevation4: {
    boxShadow: `2px 2px 6px ${palette.boxShadowLightGray}`,
  },
};
