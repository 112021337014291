// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface QuestionSetSearchResultsSchema
 */
export interface QuestionSetSearchResultsSchema  {
    /**
     * 
     * @type {string}
     * @memberof QuestionSetSearchResultsSchema
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionSetSearchResultsSchema
     */
    title: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionSetSearchResultsSchema
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof QuestionSetSearchResultsSchema
     */
    category: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionSetSearchResultsSchema
     */
    version: number;
}

export function QuestionSetSearchResultsSchemaFromJSON(json: any): QuestionSetSearchResultsSchema {
    return {
        'id': json['id'],
        'title': json['title'],
        'count': json['count'],
        'category': json['category'],
        'version': json['version'],
    };
}

export function QuestionSetSearchResultsSchemaToJSON(value?: QuestionSetSearchResultsSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'title': value.title,
        'count': value.count,
        'category': value.category,
        'version': value.version,
    };
}


