import React, { FunctionComponent } from "react";
import Skeleton from ".";

const HistoryModalSkeleton: FunctionComponent = () => {
  return (
    <>
      <Skeleton width="85%" variant="text" />
      <Skeleton width="25%" variant="text" />
      <Skeleton width="85%" variant="text" />
      <Skeleton width="25%" variant="text" />
      <Skeleton width="20%" variant="text" />
      <Skeleton width="85%" variant="text" />
      <Skeleton width="25%" variant="text" />
      <Skeleton width="85%" variant="text" />
      <Skeleton width="25%" variant="text" />
      <Skeleton width="20%" variant="text" />
      <Skeleton width="85%" variant="text" />
      <Skeleton width="25%" variant="text" />
      <Skeleton width="85%" variant="text" />
      <Skeleton width="25%" variant="text" />
      <Skeleton width="20%" variant="text" />
    </>
  );
};

export default HistoryModalSkeleton;
