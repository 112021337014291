import React, { FunctionComponent } from "react";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import Typography from "components/Typography";
import Grid from "components/Grid";
import { JsonUser as User } from "shared/fetch/src/models/JsonUser";;
import { getGreeting } from "./utils";
import { selectLoggedInUser } from "shared/features/user/selectors";

interface IProps {
  title?: string;
  body: string;
  hasArchivedEpisodes?: boolean;
  isEmptyEOC?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    textAlign: "center",
    lineHeight: "30px",
    width: "450px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  container: {
    minHeight: "50%",
    height: "55%",
  },
  emptyEOCContainer: {
    width: "70%",
    display: "block",
    [theme.breakpoints.down("md")]: {
      width: "unset",
    },
  },
  landingPageTitle: {
    fontSize: "40px",
  },
  emptyEOCLandingPageTitle: {
    fontSize: "27.7px",
    textAlign: "left",
  },
  bodyText: {
    paddingTop: theme.spacing(1),
    fontSize: "19px",
    margin: "0 auto",
  },
  emptyEOCBodyText: {
    paddingTop: "6px",
    fontSize: "16px",
    lineHeight: "20.8px",
    textAlign: "left",
    width: "unset",
  },
  centerText: {
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  emptyEOCCenterText: {
    marginTop: "30px",
  },
}));

const DashboardWelcomeMessage: FunctionComponent<IProps> = ({
  body,
  hasArchivedEpisodes,
  isEmptyEOC = false,
}) => {
  const user: User = selectLoggedInUser();
  const classes = useStyles();
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={clsx(
        classes.container,
        isEmptyEOC && classes.emptyEOCContainer
      )}
    >
      <Grid
        item
        className={clsx(
          classes.centerText,
          isEmptyEOC && classes.emptyEOCCenterText
        )}
      >
        <Typography
          appearance="h3"
          color="inherit"
          className={clsx(
            classes.landingPageTitle,
            isEmptyEOC && classes.emptyEOCLandingPageTitle
          )}
          id="title"
          data-testid="welcome-title"
        >
          {getGreeting() + ` ${(user as User)?.preferredName}`}
        </Typography>
        <Typography
          className={clsx(
            classes.bodyText,
            classes.heading,
            isEmptyEOC && classes.emptyEOCBodyText
          )}
          appearance="body"
          id="create-eoc-description"
          color="inherit"
        >
          {hasArchivedEpisodes ? "" : body}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DashboardWelcomeMessage;
