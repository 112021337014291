export default {
  id: "100002",
  episodeId: "33",
  eventType: "question_set",
  addedAt: "2019-09-27T14:43:26Z",
  viewedAt: null,
  actionsResolvedAt: null,
  createdBy: {
    id: "23",
    legalName: "Dr. Jane Doe",
    type: "ctm",
    avatarUrl: "",
  },
  components: [
    {
      id: "2",
      state: "unread",
      type: "ctm_message",
      timelineEventId: "2",
      episodeId: "1",
      addedAt: "2020-07-17T14:39:00Z",
      resourceUrl: "/v1/components/2",
      body: "Sorry to hear that.",
      responseRequired: false,
      documentInfo: [],
      creatorInfo: {
        id: "11",
        dob: "1980-01-01",
        type: "ctm",
        avatarUrl: "/images/image-placeholder.jpg",
        name: "Mike Demo, MD",
        legalName: "Mike Demo",
        guilds: ["Primary Care"],
      },
      actionableItems: [],
      parentComponent: {
        id: "1",
        resourceUrl: "/v1/components/1",
        type: "episode_created",
        body: "I have fever.",
        state: "resolved",
        addedAt: "2020-07-17T14:35:48Z",
        documentInfo: [],
        creatorInfo: {
          id: "1",
          dob: "1980-01-31",
          type: "member",
          avatarUrl: "/images/image-placeholder.jpg",
          name: "Jane Public",
          legalName: "Jane Public",
          employer: "Demo",
          state: "CA",
        },
        actionableItems: [
          {
            id: "1",
            url: "/v1/ctm_actionable_items/1",
            componentUrl: "/v1/components/1",
            state: "resolved",
            actionType: "episode_created",
            resolvedAt: "2020-07-17T14:39:00Z",
            acknowledgedAt: "2020-07-17T14:39:00Z",
            acknowledged: {
              id: "11",
              dob: "1980-01-01",
              type: "ctm",
              avatarUrl: "/images/image-placeholder.jpg",
              name: "Mike Demo, MD",
              legalName: "Mike Demo",
              guilds: ["Primary Care"],
            },
            resolved: {
              id: "11",
              dob: "1980-01-01",
              type: "ctm",
              avatarUrl: "/images/image-placeholder.jpg",
              name: "Mike Demo, MD",
              legalName: "Mike Demo",
              guilds: ["Primary Care"],
            },
          },
        ],
      },
      parentComponents: [
        {
          id: "1",
          resource_url: "/v1/components/1",
          type: "episode_created",
          body: "I have fever.",
          state: "resolved",
          addedAt: "2020-07-17T14:35:48Z",
          documentInfo: [],
          creatorInfo: {
            id: "1",
            dob: "1980-01-31",
            type: "member",
            avatarUrl: "/images/image-placeholder.jpg",
            name: "Jane Public",
            legalName: "Jane Public",
            employer: "Demo",
            state: "CA",
          },
          actionableItems: [
            {
              id: "1",
              url: "/v1/ctm_actionable_items/1",
              componentUrl: "/v1/components/1",
              state: "resolved",
              actionType: "episode_created",
              resolvedAt: "2020-07-17T14:39:00Z",
              acknowledgedAt: "2020-07-17T14:39:00Z",
              acknowledged: {
                id: "11",
                dob: "1980-01-01",
                type: "ctm",
                avatarUrl: "/images/image-placeholder.jpg",
                name: "Mike Demo, MD",
                legalName: "Mike Demo",
                guilds: ["Primary Care"],
              },
              resolved: {
                id: "11",
                dob: "1980-01-01",
                type: "ctm",
                avatarUrl: "/images/image-placeholder.jpg",
                name: "Mike Demo, MD",
                legalName: "Mike Demo",
                guilds: ["Primary Care"],
              },
            },
          ],
        },
      ],
    },
    {
      id: "3",
      state: "responses_sent_pending_ack",
      type: "question_set",
      timelineEventId: "2",
      episodeId: "1",
      addedAt: "2020-07-17T14:39:00Z",
      resourceUrl: "/v1/components/3",
      documentInfo: [],
      body: "",
      jsonBody: {
        source_question_sets: {
          "1": { title: "Abdominal Pain", category: "Diagnosis" },
          "2": { title: "Headache", category: "Diagnosis" },
        },
        count: 42,
        version: "1.0",
        questions: [
          {
            id: "1",
            displayText: "When did your Abdominal pain begin?",
            sex: "Both",
            question_number: "1",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "2",
            displayText:
              "How did your symptoms begin? Did they occur over time or after an event or injury?",
            sex: "Both",
            question_number: "2",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "3",
            displayText: "How often do you have abdominal pain?",
            sex: "Both",
            question_number: "3",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "4",
            displayText:
              "Have you been previously diagnosed with abdominal pain?",
            sex: "Both",
            question_number: "4",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "5",
            displayText:
              "Are you taking any medication for your symptoms? If so, what medication?",
            sex: "Both",
            question_number: "5",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "6",
            displayText:
              "Have you tried any other treatments for your abdominal pain? If so, what treatments and have any of them helped?",
            sex: "Both",
            question_number: "6",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "7",
            displayText:
              "How have your symptoms affected you? What have they limited you from doing?",
            sex: "Both",
            question_number: "7",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "8",
            displayText:
              "What stressors, movements, and/or positions worsen your abdominal pain?",
            sex: "Both",
            question_number: "8",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "9",
            displayText: "What makes your abdominal pain better?",
            sex: "Both",
            question_number: "9",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "10",
            displayText:
              "Is your abdominal pain worse during a certain time of day? If so, what time of day?",
            sex: "Both",
            question_number: "10",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "11",
            displayText: "How long does your abdominal pain last?",
            sex: "Both",
            question_number: "11",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "12",
            displayText:
              "Have you vomited or feel like vomiting due to your abdominal pain?",
            sex: "Both",
            question_number: "12",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "13",
            displayText: "Do you have nasal congestion?",
            sex: "Both",
            question_number: "13",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "14",
            displayText:
              "Do you have any associated muscle weakness, dizziness, double vision, or slurred speech?",
            sex: "Both",
            question_number: "14",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "15",
            displayText: "Did you recently eat something new?",
            sex: "Both",
            question_number: "15",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "16",
            displayText:
              "Do you have neck pain? If so, does your neck pain ever occur when you have abdominal pain?",
            sex: "Both",
            question_number: "16",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "17",
            displayText: "Does your neck feel stiff and hurt to move?",
            sex: "Both",
            question_number: "17",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "18",
            displayText:
              "What do you think you have? Or what are you concerned you might have?",
            sex: "Both",
            question_number: "18",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "19",
            displayText: "What is your goal for physical therapy?",
            sex: "Both",
            question_number: "19",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "20",
            displayText:
              "Is your abdominal pain improving, worsening, or staying about the same?",
            sex: "Both",
            question_number: "20",
            qs_abbreviation: "AP",
            source_question_set_id: "1",
            question_set_version: "1.0",
          },
          {
            id: "21",
            displayText: "When did your headaches begin?",
            sex: "Both",
            question_number: "21",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "22",
            displayText:
              "How did your symptoms begin? Did they occur over time or after an event or injury?",
            sex: "Both",
            question_number: "22",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "23",
            displayText: "How often do you have headaches?",
            sex: "Both",
            question_number: "23",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "24",
            displayText:
              "Have you been previously diagnosed with headaches? If so, what kind of headaches (migraine, stress, tension, etc.)?",
            sex: "Both",
            question_number: "24",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "25",
            displayText:
              "Are you taking any medication for your symptoms? If so, what medication?",
            sex: "Both",
            question_number: "25",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "26",
            displayText:
              "Have you tried any other treatments for your headaches? If so, what treatments and have any of them helped?",
            sex: "Both",
            question_number: "26",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "27",
            displayText:
              "How have your symptoms affected you? What have they limited you from doing?",
            sex: "Both",
            question_number: "27",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "28",
            displayText:
              "What stressors, movements, and/or positions worsen your headaches?",
            sex: "Both",
            question_number: "28",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "29",
            displayText: "What makes your headaches better?",
            sex: "Both",
            question_number: "29",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "30",
            displayText:
              "Is your headache worse during a certain time of day? If so, what time of day?",
            sex: "Both",
            question_number: "30",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "31",
            displayText: "How long do your headaches last?",
            sex: "Both",
            question_number: "31",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "32",
            displayText:
              "During your headaches, are you sensitive to light or sounds?",
            sex: "Both",
            question_number: "32",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "33",
            displayText:
              "Do you see auras (flashes of light or blind spots) or floaters?",
            sex: "Both",
            question_number: "33",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "34",
            displayText:
              "Have you vomited or feel like vomiting due to your headaches?",
            sex: "Both",
            question_number: "34",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "35",
            displayText: "Do you have nasal congestion?",
            sex: "Both",
            question_number: "35",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "36",
            displayText:
              "Do you have any associated muscle weakness, dizziness, double vision, or slurred speech?",
            sex: "Both",
            question_number: "36",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "37",
            displayText:
              "Did you recently hit your head or suspect you have a concussion?",
            sex: "Both",
            question_number: "37",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "38",
            displayText:
              "Do you have neck pain? If so, does your neck pain ever occur when you have a headache?",
            sex: "Both",
            question_number: "38",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "39",
            displayText: "Does your neck feel stiff and hurt to move?",
            sex: "Both",
            question_number: "39",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "40",
            displayText:
              "Are your headaches improving, worsening, or staying about the same?",
            sex: "Both",
            question_number: "40",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "41",
            displayText:
              "What do you think you have? Or what are you concerned you might have?",
            sex: "Both",
            question_number: "41",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
          {
            id: "42",
            displayText: "What is your goal for physical therapy?",
            sex: "Both",
            question_number: "42",
            qs_abbreviation: "H",
            source_question_set_id: "2",
            question_set_version: "1.0",
          },
        ],
      },
      creatorInfo: {
        id: "11",
        dob: "1980-01-01",
        type: "ctm",
        avatar_url: "/images/image-placeholder.jpg",
        name: "Mike Demo, MD",
        legal_name: "Mike Demo",
        guilds: ["Primary Care"],
      },
      actionableItems: [],
      messageComponent: {
        id: "2",
        state: "unread",
        type: "ctm_message",
        timeline_event_id: "2",
        episode_id: "1",
        added_at: "2020-07-17T14:39:00Z",
        resource_url: "/v1/components/2",
        document_info: [],
        body: "Sorry to hear that.",
        response_required: false,
        creator_info: {
          id: "11",
          dob: "1980-01-01",
          type: "ctm",
          avatar_url: "/images/image-placeholder.jpg",
          name: "Mike Demo, MD",
          legal_name: "Mike Demo",
          guilds: ["Primary Care"],
        },
        actionable_items: [],
        parent_component: {
          id: "1",
          resource_url: "/v1/components/1",
          type: "episode_created",
          body: "I have fever.",
          state: "resolved",
          added_at: "2020-07-17T14:35:48Z",
          document_info: [],
          creator_info: {
            id: "1",
            dob: "1980-01-31",
            type: "member",
            avatar_url: "/images/image-placeholder.jpg",
            name: "Jane Public",
            legal_name: "Jane Public",
            employer: "Demo",
            state: "CA",
          },
          actionable_items: [
            {
              id: "1",
              url: "/v1/ctm_actionable_items/1",
              component_url: "/v1/components/1",
              state: "resolved",
              action_type: "episode_created",
              resolved_at: "2020-07-17T14:39:00Z",
              acknowledged_at: "2020-07-17T14:39:00Z",
              acknowledged: {
                id: "11",
                dob: "1980-01-01",
                type: "ctm",
                avatar_url: "/images/image-placeholder.jpg",
                name: "Mike Demo, MD",
                legal_name: "Mike Demo",
                guilds: ["Primary Care"],
              },
              resolved: {
                id: "11",
                dob: "1980-01-01",
                type: "ctm",
                avatar_url: "/images/image-placeholder.jpg",
                name: "Mike Demo, MD",
                legal_name: "Mike Demo",
                guilds: ["Primary Care"],
              },
            },
          ],
        },
        parent_components: [
          {
            id: "1",
            resource_url: "/v1/components/1",
            type: "episode_created",
            body: "I have fever.",
            state: "resolved",
            added_at: "2020-07-17T14:35:48Z",
            document_info: [],
            creator_info: {
              id: "1",
              dob: "1980-01-31",
              type: "member",
              avatar_url: "/images/image-placeholder.jpg",
              name: "Jane Public",
              legal_name: "Jane Public",
              employer: "Demo",
              state: "CA",
            },
            actionable_items: [
              {
                id: "1",
                url: "/v1/ctm_actionable_items/1",
                component_url: "/v1/components/1",
                state: "resolved",
                action_type: "episode_created",
                resolved_at: "2020-07-17T14:39:00Z",
                acknowledged_at: "2020-07-17T14:39:00Z",
                acknowledged: {
                  id: "11",
                  dob: "1980-01-01",
                  type: "ctm",
                  avatar_url: "/images/image-placeholder.jpg",
                  name: "Mike Demo, MD",
                  legal_name: "Mike Demo",
                  guilds: ["Primary Care"],
                },
                resolved: {
                  id: "11",
                  dob: "1980-01-01",
                  type: "ctm",
                  avatar_url: "/images/image-placeholder.jpg",
                  name: "Mike Demo, MD",
                  legal_name: "Mike Demo",
                  guilds: ["Primary Care"],
                },
              },
            ],
          },
        ],
      },
      parentComponents: [],
    },
  ],
};
