import React from "react";
import Grid from "components/Grid";
import SkeletonComponent from '@mui/material/Skeleton';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    wrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      paddingTop: spacing(1),
      paddingBottom: spacing(1),
      paddingLeft: spacing(2),
      paddingRight: spacing(2),
      backgroundColor: palette.white,
      borderRadius: spacing(1.25),
    },
    sections: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      marginLeft: spacing(1.5),
    },
  })
);

const VisitsSkeleton = () => {
  const classes = useStyles();
  const listColumns = () => {
    const nodes = [];
    for (let index = 0; index < 6; index++) {
      nodes.push(
        <Grid item xs={12} style={{ alignSelf: "stretch" }} key={index}>
          <div className={classes.wrapper}>
            <SkeletonComponent width={16} animation={false} />
            <div className={classes.sections}>
              <SkeletonComponent width={128} />
              <SkeletonComponent width={64} height={16} />
              <SkeletonComponent width={196} style={{ marginTop: 4 }} />
            </div>
          </div>
        </Grid>
      );
    }
    return nodes;
  };

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      alignItems="center"
      wrap="nowrap"
    >
      {listColumns()}

      <Grid item>
        <Grid container spacing={2}>
          <Grid item>
            <SkeletonComponent width={24} height={36} />
          </Grid>
          <Grid item>
            <SkeletonComponent width={164} height={36} />
          </Grid>
          <Grid item>
            <SkeletonComponent width={24} height={36} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VisitsSkeleton;
