// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    QuestionSetCategoriesList,
    QuestionSetCategoriesListFromJSON,
    QuestionSetCategoriesListToJSON,
} from './QuestionSetCategoriesList';


/**
 * 
 * @export
 * @interface QuestionSetCategoriesSchema
 */
export interface QuestionSetCategoriesSchema  {
    /**
     * 
     * @type {string}
     * @memberof QuestionSetCategoriesSchema
     */
    contentTypeCategoryId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionSetCategoriesSchema
     */
    contentTypeId: string;
    /**
     * 
     * @type {string}
     * @memberof QuestionSetCategoriesSchema
     */
    contentTypeLabel: string;
    /**
     * 
     * @type {number}
     * @memberof QuestionSetCategoriesSchema
     */
    totalQsCategories?: number;
    /**
     * 
     * @type {Array<QuestionSetCategoriesList>}
     * @memberof QuestionSetCategoriesSchema
     */
    contentTypeItems: Array<QuestionSetCategoriesList>;
}

export function QuestionSetCategoriesSchemaFromJSON(json: any): QuestionSetCategoriesSchema {
    return {
        'contentTypeCategoryId': json['content_type_category_id'],
        'contentTypeId': json['content_type_id'],
        'contentTypeLabel': json['content_type_label'],
        'totalQsCategories': !exists(json, 'total_qs_categories') ? undefined : json['total_qs_categories'],
        'contentTypeItems': (json['content_type_items'] as Array<any>).map(QuestionSetCategoriesListFromJSON),
    };
}

export function QuestionSetCategoriesSchemaToJSON(value?: QuestionSetCategoriesSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'content_type_category_id': value.contentTypeCategoryId,
        'content_type_id': value.contentTypeId,
        'content_type_label': value.contentTypeLabel,
        'total_qs_categories': value.totalQsCategories,
        'content_type_items': (value.contentTypeItems as Array<any>).map(QuestionSetCategoriesListToJSON),
    };
}


