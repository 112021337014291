import React, { useEffect } from "react";
import useDraftSafeNavigate from "hooks/useDraftSafeNavigate";
import NativeApp from "models/NativeApp";

const NativeAppNavigation: React.FunctionComponent = () => {
  const navigate = useDraftSafeNavigate();

  const navigateApp = (e: any) => {
    const path: string = e.detail.path;
    navigate(path);
  };

  const getScrollPosition = () => {
    const scrollTop = document.getElementById("main-container-div")?.scrollTop;
    if (scrollTop === 0) {
      NativeApp.pullToRefresh();
    }
  };

  useEffect(() => {
    window.addEventListener("navigate", navigateApp);
    return () => {
      window.removeEventListener("navigate", navigateApp);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("getScrollPosition", getScrollPosition);
    return () => {
      window.removeEventListener("getScrollPosition", getScrollPosition);
    };
  }, []);

  return null;
};
export default NativeAppNavigation;
