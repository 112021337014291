import React from "react";
import { Entities, requestAsync } from "redux-query";
import { useLocation } from "react-router";
import AddIcon from "@mui/icons-material/Add";
import { SvgIcon, SvgIconProps } from "@mui/material";
import debounce from "lodash/debounce";

import { store } from "shared/state/StateManagement";
import useFormatDate from "shared/utils/useFormatDate";
import SearchField from "components/SearchField";
import Typography from "components/Typography";
import { usePatientQuickListForStaffUser } from "shared/features/memberSearch/useMemberSearchResults";
import { selectFeatures } from "shared/features/featureFlags/selectors";
import useDraftSafeNavigate from "hooks/useDraftSafeNavigate";
import ActivateAccountModal from "components/ActivateAccountModal";
import Button from "components/Button";
import classes from "./index.module.css";
import Asset from "components/Asset";
import ProxyModal from "components/ProxyModal";
import useAccess from "hooks/useAccess";
import ButtonWithAccess from "components/Button/ButtonWithAccess";
import { calculateAge, prepareAge } from "utils/calculateAge";
import {
  getMinorCanBeNested,
  getMinorBadgeBackgroundColor,
  showGreenPlus,
} from "features/memberSearch/utils";
import { listMembers } from "shared/api/src/apis/MemberSearchResultsApi";
import { JsonUser } from "shared/fetch/src/models/JsonUser";
import { OutputMemberSearchSchemaProps } from "shared/fetch/src/models/OutputMemberSearchSchemaProps";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";
import { ListMembersFormatEnum } from "shared/fetch/src/apis/MemberSearchResultsApi";

const PersonDisabledIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M7.65012 2.82C8.36012 1.72 9.60012 0.999999 11.0001 0.999999C13.2101 0.999999 15.0001 2.79 15.0001 5C15.0001 6.4 14.2801 7.64 13.1801 8.35L7.65012 2.82ZM19.0001 14.17C18.9801 13.07 18.3701 12.06 17.3901 11.55C16.8501 11.27 16.2601 11.01 15.6201 10.79L19.0001 14.17ZM19.4901 17.49L2.51012 0.509999C2.12012 0.119999 1.49012 0.119999 1.10012 0.509999C0.710117 0.899999 0.710117 1.53 1.10012 1.92L9.28012 10.1C7.46012 10.33 5.87012 10.9 4.58012 11.56C3.60012 12.08 3.00012 13.11 3.00012 14.22V17H16.1701L18.0701 18.9C18.4601 19.29 19.0901 19.29 19.4801 18.9C19.8801 18.51 19.8801 17.88 19.4901 17.49Z"
      fill="#606A76"
    />
  </SvgIcon>
);

const InactiveIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M11 0.799988C16.6101 0.799988 21.2001 5.38999 21.2001 11C21.2001 16.61 16.6101 21.2 11 21.2C5.39005 21.2 0.800049 16.61 0.800049 11C0.800049 5.38999 5.39005 0.799988 11 0.799988ZM11 2.83999C9.06205 2.83999 7.32805 3.45199 6.00205 4.57399L17.4261 15.998C18.4461 14.57 19.1601 12.836 19.1601 11C19.1601 6.51199 15.4881 2.83999 11 2.83999ZM15.998 17.426L4.57405 6.00199C3.45205 7.32799 2.84005 9.06199 2.84005 11C2.84005 15.488 6.51205 19.16 11 19.16C12.9381 19.16 14.672 18.548 15.998 17.426Z"
      fill="#CA4004"
    />
  </SvgIcon>
);

const LockIcon = (props: SvgIconProps) => (
  <SvgIcon {...props}>
    <path
      d="M14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM8 16C6.9 16 6 15.1 6 14C6 12.9 6.9 12 8 12C9.1 12 10 12.9 10 14C10 15.1 9.1 16 8 16ZM11.1 7H4.9V5C4.9 3.29 6.29 1.9 8 1.9C9.71 1.9 11.1 3.29 11.1 5V7Z"
      fill="#606A76"
    />
  </SvgIcon>
);

export const Badge = ({
  label,
  color,
  fontColor,
  dataTestId,
}: {
  label: string;
  color?: string;
  fontColor?: string;
  dataTestId?: string;
}) => {
  return (
    <Typography
      component="span"
      appearance="miniCaption"
      data-testid={dataTestId}
      style={{
        backgroundColor: color || "#ADEED1",
        padding: "3px",
        borderRadius: "2px",
        color: fontColor,
      }}
    >
      {label}
    </Typography>
  );
};

function useKeyPress(targetKey: any) {
  const [keyPressed, setKeyPressed] = React.useState(false);

  function downHandler({ key }: any) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }: any) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []);

  return keyPressed;
}

const loadOptions = (inputValue: any, setOptions: any, defaultOptions: any) => {
  if (inputValue?.length === 0 && defaultOptions) {
    setOptions(defaultOptions);
    return;
  }

  // On focus, results for recently searched members load. When user begins typing, the below causes all results to disappear until 3
  // characters are typed. This causes behavior that seems buggy but there is no ask for this to be changed. Original author no longer works at XO.
  if (inputValue?.length <= 2) {
    setOptions({
      labelKey: "id",
      items: [],
    });
    return;
  }
  store.dispatch(
    requestAsync(
      listMembers<Entities>(
        {
          q: inputValue,
          format: ListMembersFormatEnum.Quick,
        },
        {
          transform: (body: any) => {
            const newOptions: any = [];

            body.results.forEach((item: any) => {
              newOptions.push(item);
            });

            setOptions({
              labelKey: "id",
              items: newOptions,
            });

            return {};
          },
          force: true,
        }
      )
    )
  );
};

const renderOption = (
  props: any,
  _option: any,
  { item }: { item: OutputMemberSearchSchemaProps },
  formatDate: any,
  toggleGreenPlusModal: (
    event: any,
    member: OutputMemberSearchSchemaProps
  ) => void,
  toggleProxyApprovalModal: (
    event: any,
    member: OutputMemberSearchSchemaProps,
    revokeProxy?: boolean
  ) => void,
  hasGreenPlus: boolean,
  hasMinorSearchResults: boolean,
  ctmCanNestMinor: boolean,
  hasRevokeProxy: boolean
) => {
  const firstName = item.preferredName ? item.preferredName : item.firstName;
  const label = `${firstName} ${item.lastName}`;
  const ageInYears = calculateAge(new Date(item?.dob!));
  const gender = item?.sexAtBirth?.length ? item?.sexAtBirth?.[0] : "-";
  const cellPhone = item?.cellPhone;
  const date = formatDate(item.dob, "yyyy, dd MMMM", {
    fallbackDate: "",
    showAsTodayIfSameDay: false,
    isDoB: true,
  });
  const caption = `${date} | ${
    item?.age ? prepareAge(item.age) + " | " : ""
  }${gender}`;
  const showIcons = !(item as any).quickSearch || item?.minor;
  const isTeen = ageInYears > 11 && ageInYears < 18;
  const minorLabel = isTeen ? "TEEN" : "CHILD";
  const minorCanBeNested = getMinorCanBeNested(item, ctmCanNestMinor);
  const showGreenPlusButton = showGreenPlus(item);
  const minorHasRevokeProxy =
    hasRevokeProxy && item.hasRevokeOption && ctmCanNestMinor;

  return (
    <li
      className={classes.container}
      data-testid={`member-search-autocomplete-result`}
      {...props}
      id={`${item?.minor ? "minor-" : ""}result-for-member-quick-search-${
        item?.id
      }`}
    >
      <div className={classes.labelContainer}>
        <Typography
          appearance="body"
          color={"secondary"}
          noWrap
          component="span"
        >
          {item?.legalAndPreferredName}{" "}
        </Typography>
        <Typography appearance="smallBody" noWrap>
          {cellPhone}
        </Typography>
        <Typography appearance="smallBody" noWrap>
          {caption}
        </Typography>

        {showIcons && (
          <div className={classes.badges}>
            {item?.status === "Ineligible" && (
              <PersonDisabledIcon fontSize="small" />
            )}
            {item?.status === "Inactive" && <InactiveIcon fontSize="small" />}
            {item?.locked && <LockIcon fontSize="small" />}
            {item?.isVip && <Badge label="VIP" color="#FFE03F" />}
            {item?.dependent && !item.minor && (
              <Badge label="DEPENDENT" color="" />
            )}
            {hasMinorSearchResults && (
              <>
                {item?.minor && (
                  <Badge
                    label={minorLabel}
                    color={getMinorBadgeBackgroundColor(isTeen)}
                    fontColor="white"
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className={classes.iconContainer}>
        {showGreenPlusButton && (
          <>
            {hasGreenPlus ? (
              <Button
                onClick={(e) => toggleGreenPlusModal(e, item)}
                classes={{ root: classes.button }}
                aria-label={`Green Plus ${label}`}
                style={{ pointerEvents: "auto", cursor: "pointer" }}
              >
                <AddIcon className={classes.plusIcon} />
              </Button>
            ) : (
              <AddIcon
                data-testid="non-interactive-green-plus-icon"
                className={classes.plusIcon}
              />
            )}
          </>
        )}
        {minorCanBeNested && (
          <ButtonWithAccess
            onClick={(e: any) => toggleProxyApprovalModal(e, item)}
            classes={{ root: classes.button }}
            access={"minor.proxy.access"}
            aria-label={`Submit proxy approval for ${label}`}
            style={{ pointerEvents: "auto", cursor: "pointer" }}
          >
            <Asset name={"minorProxy"} className={classes.proxyIcon} />
          </ButtonWithAccess>
        )}
        {minorHasRevokeProxy && (
          <ButtonWithAccess
            onClick={(e: any) => toggleProxyApprovalModal(e, item, true)}
            classes={{ root: classes.button }}
            access={"minor.proxy.access"}
            aria-label={`Submit Revoke proxy approval for ${label}`}
            style={{ pointerEvents: "auto", cursor: "pointer" }}
          >
            <Asset name="RevokeProxy" className={classes.proxyIcon} />
          </ButtonWithAccess>
        )}
      </div>
    </li>
  );
};

const MemberSearchField = ({ user }: { user: JsonUser }) => {
  const formatDate = useFormatDate();
  const features = selectFeatures();
  const location = useLocation();
  const safeNavigate = useDraftSafeNavigate();
  const { data: members } = usePatientQuickListForStaffUser(
    {
      // @ts-ignore: Object is possibly 'null'.
      id: user?.id,
    },
    user.ctm
  );
  const { results } = members || {};
  const [activateAccountModalOpen, setActivateAccountModalOpen] =
    React.useState(false);
  const [proxyApprovalModalOpen, setProxyApprovalModalOpen] =
    React.useState(false);
  const [isGreenPlusFlow, setIsGreenPlusFlow] = React.useState(false);
  const [memberToActivate, setMemberToActivate] = React.useState(null);
  const [isTeen, setIsTeen] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState("");
  const [isRevokeProxy, setIsRevokeProxy] = React.useState(false);

  const ctmCanNestMinor =
    useAccess({
      to: PermissionsEnum.MinorProxyAccess,
    }) && features?.hasMinorSearchResults();

  const toggleActivateAccountModal = (
    event: any,
    member: OutputMemberSearchSchemaProps
  ) => {
    event.stopPropagation();
    setActivateAccountModalOpen(!activateAccountModalOpen);
    // @ts-ignore: Object is possibly 'null'.
    setMemberToActivate(member);
    const ageInYears = calculateAge(new Date(member?.dob!));
    setIsTeen(ageInYears > 11 && ageInYears < 18);
  };
  const toggleProxyApprovalModal = (
    event: any,
    member: OutputMemberSearchSchemaProps,
    revokeProxy?: boolean
  ) => {
    event.stopPropagation();
    if (revokeProxy && features.hasRevokeProxy()) {
      setIsRevokeProxy(revokeProxy);
    }
    setProxyApprovalModalOpen(!proxyApprovalModalOpen);
    // @ts-ignore: Object is possibly 'null'.
    setMemberToActivate(member);
  };
  const defaultOptions = {
    labelKey: "id",
    items: results
      ? results?.map((item) => ({
          ...item,
          dob: item.bornAt,
          registered: true,
          quickSearch: true,
        }))
      : [],
  };

  const slashPress = useKeyPress("/");
  const onChange = (data: any, reason: string) => {
    if (
      ((data?.item?.minor && !features.hasCtmMinorAccess()) ||
        !data?.item?.registered) &&
      features.hasMinorSearchResults() &&
      reason !== "createOption"
    ) {
      return;
    }
    switch (reason) {
      case "selectOption":
        let backTo = "Queue";
        if (location.pathname.includes("conversations")) {
          backTo = "Conversations";
        } else if (location.pathname.includes("proactive")) {
          backTo = "Proactive";
        } else if (location.pathname.includes("tools")) {
          backTo = "Tools";
        }
        safeNavigate(
          `/members/${encodeURIComponent(data?.item?.id)}?b=${backTo}`
        );
        break;

      case "createOption":
        safeNavigate(`/members?q=${encodeURIComponent(data.value)}`);
        break;

      default:
        break;
    }
  };

  const isActiveElementEditable = () => {
    const element: any = document.activeElement;
    switch (element?.tagName) {
      case "INPUT":
      case "TEXTAREA":
        return true;
    }
    if (element.getAttribute("contentEditable")) {
      return true;
    }
    return false;
  };

  if (slashPress && !isActiveElementEditable()) {
    const element = document.getElementById("member-search-field");
    setTimeout(() => {
      element?.focus();
    }, 150);
  }

  return (
    <>
      {activateAccountModalOpen && (
        <ActivateAccountModal
          open={activateAccountModalOpen}
          closeModal={() => setActivateAccountModalOpen(false)}
          showProxyOption={isTeen && ctmCanNestMinor}
          openProxyModal={() => {
            setProxyApprovalModalOpen(true);
            setIsGreenPlusFlow(true);
          }}
          // @ts-ignore: Object is possibly 'null'.
          member={memberToActivate}
          searchRequest={{
            format: ListMembersFormatEnum.Quick,
            q: searchInput,
          }}
        />
      )}
      {proxyApprovalModalOpen && (
        <ProxyModal
          open={proxyApprovalModalOpen}
          closeModal={() => {
            setProxyApprovalModalOpen(false);
            setIsRevokeProxy(false);
          }}
          isGreenPlusFlow={isGreenPlusFlow}
          // @ts-ignore: Object is possibly 'null'.
          member={memberToActivate}
          searchRequest={{
            format: ListMembersFormatEnum.Quick,
            q: searchInput,
          }}
          revokeProxy={isRevokeProxy}
        />
      )}
      <SearchField
        id="member-search-field"
        ariaLabel="Member Search Input"
        loadOptions={debounce(loadOptions, 280)}
        onChange={onChange}
        onValueChange={(e: any) => setSearchInput(e?.target?.value)}
        renderOption={(...props: any[]) =>
          renderOption.call(
            null,
            props[0] || undefined,
            props[1] || null,
            props[2] || undefined,
            formatDate,
            toggleActivateAccountModal,
            toggleProxyApprovalModal,
            features?.hasGreenPlus(),
            features?.hasMinorSearchResults(),
            ctmCanNestMinor,
            features?.hasRevokeProxy()
          )
        }
        onFocus={(setOption) => {
          setOption(defaultOptions);
        }}
        clearInputOnChange
        disableClearableProp
        resetValue
        placeholder="Find member"
        defaultOptions={defaultOptions}
        autoWidth
        hasFooter
      />
    </>
  );
};

export default MemberSearchField;
