// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NetworkProviderAllOf
 */
export interface NetworkProviderAllOf  {
    /**
     * 
     * @type {boolean}
     * @memberof NetworkProviderAllOf
     */
    isContact?: boolean;
}

export function NetworkProviderAllOfFromJSON(json: any): NetworkProviderAllOf {
    return {
        'isContact': !exists(json, 'is_contact') ? undefined : json['is_contact'],
    };
}

export function NetworkProviderAllOfToJSON(value?: NetworkProviderAllOf): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'is_contact': value.isContact,
    };
}


