import React, { FunctionComponent } from "react";
import { styled } from "@mui/material/styles";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Box from "components/Box";
import Heading from "components/DynamicHeadings";
import Typography from "components/Typography";
import Checkbox from "components/Checkbox";
import ButtonBase from "components/Button/ButtonBase";

interface IProps {
  title: string;
  subTitle: string;
  body: string;
  footer: string;
  isRead?: boolean;
  isSelected?: boolean;
  id?: string;
  link?: string;
  componentName?: string;
  hasVisitSeparation?: boolean;
  onSelect?: (id: string, evt: any) => void;
  onClick?: (url: string, id?: string) => unknown;
}

interface TitleProps {
  bold: boolean;
}

/* eslint-disable  @typescript-eslint/no-unused-vars */
const Container = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'tint',
})<{ tint: boolean }>(({ tint }) => ({
  display: 'flex',
  flexDirection: 'row',
  borderBottom: '1px solid rgba(43, 57, 73, 0.3)',
  backgroundColor: tint ? '#F7F7F8' : '#FFF',
}));
/* eslint-enable */

const Select = styled(Checkbox)({
  display: "flex",
  paddingTop: "17px",
  justifySelf: "flex-start",
  alignSelf: "flex-start",
});

const Details = styled(ButtonBase)({
  display: "flex",
  flexDirection: "column",
  padding: "18px 5px 18px 0",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  textAlign: "left",
});

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    root: {
      textDecoration: "none",
      fontWeight: (props: TitleProps) => (props.bold ? "bold" : "normal"),
      color: palette?.links?.primary,
      "&:hover": {
        textDecoration: "none",
      },
    },
  })
);

const Title = ({ bold, ...other }: any) => {
  const classes = useStyles({ bold });
  return <Heading.H className={classes.root} {...other} />;
};

const Text = styled(Typography)(({ theme: { palette } }) => ({
  display: "flex",
  color: palette.text.secondary,
  marginTop: 0,
  marginRight: 5,
}));

const InboxMessage: FunctionComponent<IProps> = ({
  title,
  subTitle,
  body,
  footer,
  isRead,
  isSelected,
  id,
  link,
  componentName,
  hasVisitSeparation = false,
  onSelect,
  onClick,
}) => {
  const appearance = "smallBody";
  // @ts-ignore: Object is possibly 'null'.
  const handleSelect = (evt: any) => onSelect(id, evt);
  // @ts-ignore: Object is possibly 'null'.
  const handleClick = () => onClick(link, !isRead && id);
  const displayConversationName =
    !hasVisitSeparation ||
    (hasVisitSeparation &&
      componentName !== "SelfScheduleAppointmentComponent");

  return (
    // @ts-ignore: Object is possibly 'null'.
    <Container tint={isRead} data-testid={`heads-up-notification-${id}`}>
      <Select
        color="secondary"
        name={title}
        checked={isSelected}
        onChange={handleSelect}
        data-testid={`notification-checkbox-${id}`}
      />
      <Details
        onClick={handleClick}
        role="link"
        tabIndex={0}
        onKeyPress={handleSelect}
      >
        <Title bold={!isRead} appearance={appearance}>
          {title}
        </Title>
        <Text appearance={appearance}>{subTitle}</Text>
        <Text appearance={appearance}>{body}</Text>
        {displayConversationName && (
          <Text data-testid={`heads-up-footer-${id}`} appearance={appearance}>
            {footer}
          </Text>
        )}
      </Details>
    </Container>
  );
};

export default InboxMessage;
