import { useDispatch, useSelector } from "react-redux";
import { getMemberId } from "shared/state/ui/member/selectors";
import { useEffect } from "react";
import { setMemberId } from "shared/state/ui/member";

const useUpdateReduxMemberId = (memberId?: string) => {
  const currentMemberId = useSelector(getMemberId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!!memberId && memberId !== currentMemberId) {
      dispatch(setMemberId(memberId!));
    }
  }, [memberId, currentMemberId]);
};

export default useUpdateReduxMemberId;
