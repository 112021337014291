import React, { FunctionComponent } from "react";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Icons from "styles/icons/icons.svg";
import { useNavigate, useLocation } from "react-router";
import { getComparisonText } from "features/cms/pages/utils/getPrevPath";
import { Link } from "components/Button";

interface IProps {
  text: string;
  isPrevPathNull: boolean;
  prevPath: string;
}

const BackButton: FunctionComponent<IProps> = ({
  text,
  isPrevPathNull,
  prevPath,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const comparisonText = getComparisonText(location.pathname);
  const path = comparisonText === text ? "/tools" : prevPath;
  const handleClick = (pathProp: string) => () => navigate(pathProp);
  return (
    <Link
      /**
       * implement routing to the previous page this way to avoid
       * refreshing the previous page each time back button is clicked
       */
      onClick={isPrevPathNull ? handleClick("/tools") : handleClick(path)}
      // color="light"
      button={false}
      data-e2e="back-button"
    >
      <BackChevron />
      {`Back to ${comparisonText === text ? "Tools" : text}`}
    </Link>
  );
};

export default BackButton;

const BackChevron: FunctionComponent = () => {
  // TODO: abstract the sizing and orientation of this chevron for reusability
  const useStyles = makeStyles(() =>
    createStyles({
      "back-chevron": {
        width: "0.938em",
        height: "0.938em",
        transform: "rotate(180deg)",
        display: "inline-block",
        fill: "currentColor",
        textAlign: "center",
      },
    })
  );
  const classes = useStyles();
  return (
    <svg className={classes["back-chevron"]}>
      <use xlinkHref={`${Icons}#icon-chevron`} />
    </svg>
  );
};
