// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExtendedDisplayPropertiesSchemaProps
 */
export interface ExtendedDisplayPropertiesSchemaProps  {
    /**
     * 
     * @type {string}
     * @memberof ExtendedDisplayPropertiesSchemaProps
     */
    headline: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedDisplayPropertiesSchemaProps
     */
    color?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedDisplayPropertiesSchemaProps
     */
    highlightColor?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedDisplayPropertiesSchemaProps
     */
    icon?: string;
}

export function ExtendedDisplayPropertiesSchemaPropsFromJSON(json: any): ExtendedDisplayPropertiesSchemaProps {
    return {
        'headline': json['headline'],
        'color': !exists(json, 'color') ? undefined : json['color'],
        'highlightColor': !exists(json, 'highlight_color') ? undefined : json['highlight_color'],
        'icon': !exists(json, 'icon') ? undefined : json['icon'],
    };
}

export function ExtendedDisplayPropertiesSchemaPropsToJSON(value?: ExtendedDisplayPropertiesSchemaProps): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'headline': value.headline,
        'color': value.color,
        'highlight_color': value.highlightColor,
        'icon': value.icon,
    };
}


