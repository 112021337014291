// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ContentTypeItems,
    ContentTypeItemsFromJSON,
    ContentTypeItemsToJSON,
} from './ContentTypeItems';


/**
 * 
 * @export
 * @interface CmsNavigationSchemaHierarchy
 */
export interface CmsNavigationSchemaHierarchy  {
    /**
     * 
     * @type {string}
     * @memberof CmsNavigationSchemaHierarchy
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CmsNavigationSchemaHierarchy
     */
    label?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CmsNavigationSchemaHierarchy
     */
    active?: boolean;
    /**
     * 
     * @type {Array<ContentTypeItems>}
     * @memberof CmsNavigationSchemaHierarchy
     */
    contentTypes?: Array<ContentTypeItems>;
}

export function CmsNavigationSchemaHierarchyFromJSON(json: any): CmsNavigationSchemaHierarchy {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'active': !exists(json, 'active') ? undefined : json['active'],
        'contentTypes': !exists(json, 'content_types') ? undefined : (json['content_types'] as Array<any>).map(ContentTypeItemsFromJSON),
    };
}

export function CmsNavigationSchemaHierarchyToJSON(value?: CmsNavigationSchemaHierarchy): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'label': value.label,
        'active': value.active,
        'content_types': value.contentTypes === undefined ? undefined : (value.contentTypes as Array<any>).map(ContentTypeItemsToJSON),
    };
}


