// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     ExtendedDisplayPropertiesSchema,
    ExtendedDisplayPropertiesSchemaFromJSON,
    ExtendedDisplayPropertiesSchemaToJSON,
} from './ExtendedDisplayPropertiesSchema';
import {
     OutputServiceLineSchemaProps,
    OutputServiceLineSchemaPropsFromJSON,
    OutputServiceLineSchemaPropsToJSON,
} from './OutputServiceLineSchemaProps';
import {
     OutputSubTypeSchema,
    OutputSubTypeSchemaFromJSON,
    OutputSubTypeSchemaToJSON,
} from './OutputSubTypeSchema';
/**
 * @type OutputServiceLineSchema
 * @export
 */
export interface OutputServiceLineSchema extends ExtendedDisplayPropertiesSchema, OutputServiceLineSchemaProps {
}

export function OutputServiceLineSchemaFromJSON(json: any): OutputServiceLineSchema {
    return {
        ...ExtendedDisplayPropertiesSchemaFromJSON(json),
        ...OutputServiceLineSchemaPropsFromJSON(json),
    };
}

export function OutputServiceLineSchemaToJSON(value?: OutputServiceLineSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...ExtendedDisplayPropertiesSchemaToJSON(value),
        ...OutputServiceLineSchemaPropsToJSON(value),
    };
}

