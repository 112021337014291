import React, { FunctionComponent } from "react";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import MenuItem from "components/Menu/MenuItem";
import clsx from "clsx";
import Button from "components/Button";
import List from "components/List";
import ListItem from "components/List/ListItem";
import Menu from "components/Menu";
import Icon from "components/Asset";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterItemsModal: {
      marginTop: "50px",
    },
    listItemPadding: {
      padding: "0px",
    },
    dropDownArrow: {
      width: "10px",
      height: "16px",
      fill: theme.palette.primary.main,
      transition: "all ease-in-out 0.3s",
    },
    rotate: {
      transform: (props: any) => {
        return props?.rotate && "rotate(180deg)";
      },
    },
  })
);

interface IProps {
  options: { name: string; value: number; onChangeValue?: any }[];
  onChange: Function;
  customStyles?: any;
  setSelectedIndex: (selectedIndex: number) => void;
  selectedIndex: number;
}

const DropDown: FunctionComponent<IProps> = ({
  options,
  onChange,
  customStyles,
  setSelectedIndex,
  selectedIndex,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = useStyles({ rotate: anchorEl });

  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    index: number
  ) => {
    setSelectedIndex(index);
    setAnchorEl(null);
    onChange(
      options[index].onChangeValue
        ? options[index].onChangeValue
        : options[index].name
    ); // get the selected filter option
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <List ariaLabel="Filters">
        <ListItem
          onClick={handleClickListItem}
          className={clsx(classes.listItemPadding)}
          aria-label={options[selectedIndex].name}
        >
          <Button
            disableRipple
            data-e2e="dropdown-btn"
            size="small"
            variant="text"
            color="primary"
            className={customStyles?.button}
          >
            {options[selectedIndex].name}
          </Button>
          <Icon
            name="icon-carrot"
            className={clsx(
              classes.dropDownArrow,
              classes.rotate,
              customStyles?.icon
            )}
          />
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={clsx(classes.filterItemsModal)}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.name}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default DropDown;
