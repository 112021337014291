// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StripeProduct
 */
export interface StripeProduct  {
    /**
     * Whether the product is currently available for purchase.
     * @type {boolean}
     * @memberof StripeProduct
     */
    active?: boolean;
    /**
     * A short one-line description of the product, meant to be displayable to the customer. Only applicable to products of `type=good`.
     * @type {string}
     * @memberof StripeProduct
     */
    caption?: string;
    /**
     * Time at which the object was created. Measured in seconds since the Unix epoch.
     * @type {number}
     * @memberof StripeProduct
     */
    created?: number;
    /**
     * An array of connect application identifiers that cannot purchase this product. Only applicable to products of `type=good`.
     * @type {Array<string>}
     * @memberof StripeProduct
     */
    deactivateOn?: Array<string>;
    /**
     * The product\'s description, meant to be displayable to the customer. Use this field to optionally store a long form explanation of the product being sold for your own rendering purposes.
     * @type {string}
     * @memberof StripeProduct
     */
    description?: string;
    /**
     * Unique identifier for the object.
     * @type {string}
     * @memberof StripeProduct
     */
    id?: string;
    /**
     * A list of up to 8 URLs of images for this product, meant to be displayable to the customer.
     * @type {Array<string>}
     * @memberof StripeProduct
     */
    images?: Array<string>;
    /**
     * Has the value `true` if the object exists in live mode or the value `false` if the object exists in test mode.
     * @type {boolean}
     * @memberof StripeProduct
     */
    livemode?: boolean;
    /**
     * Set of [key-value pairs](https://stripe.com/docs/api/metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format.
     * @type {{ [key: string]: string; }}
     * @memberof StripeProduct
     */
    metadata?: { [key: string]: string; };
    /**
     * The product\'s name, meant to be displayable to the customer.
     * @type {string}
     * @memberof StripeProduct
     */
    name?: string;
    /**
     * String representing the object\'s type. Objects of the same type share the same value.
     * @type {string}
     * @memberof StripeProduct
     */
    object?: StripeProductModelObjectEnum;
    /**
     * Whether this product is shipped (i.e., physical goods).
     * @type {boolean}
     * @memberof StripeProduct
     */
    shippable?: boolean;
    /**
     * Extra information about a product which will appear on your customer\'s credit card statement. In the case that multiple products are billed at once, the first statement descriptor will be used.
     * @type {string}
     * @memberof StripeProduct
     */
    statementDescriptor?: string;
    /**
     * The type of the product. The product is either of type `good`, which is eligible for use with Orders and SKUs, or `service`, which is eligible for use with Subscriptions and Plans.
     * @type {string}
     * @memberof StripeProduct
     */
    type?: StripeProductTypeEnum;
    /**
     * A label that represents units of this product in Stripe and on customers’ receipts and invoices. When set, this will be included in associated invoice line item descriptions.
     * @type {string}
     * @memberof StripeProduct
     */
    unitLabel?: string;
    /**
     * Time at which the object was last updated. Measured in seconds since the Unix epoch.
     * @type {number}
     * @memberof StripeProduct
     */
    updated?: number;
    /**
     * A URL of a publicly-accessible webpage for this product.
     * @type {string}
     * @memberof StripeProduct
     */
    url?: string;
}

export function StripeProductFromJSON(json: any): StripeProduct {
    return {
        'active': !exists(json, 'active') ? undefined : json['active'],
        'caption': !exists(json, 'caption') ? undefined : json['caption'],
        'created': !exists(json, 'created') ? undefined : json['created'],
        'deactivateOn': !exists(json, 'deactivate_on') ? undefined : json['deactivate_on'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'images': !exists(json, 'images') ? undefined : json['images'],
        'livemode': !exists(json, 'livemode') ? undefined : json['livemode'],
        'metadata': !exists(json, 'metadata') ? undefined : json['metadata'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'object': !exists(json, 'object') ? undefined : json['object'],
        'shippable': !exists(json, 'shippable') ? undefined : json['shippable'],
        'statementDescriptor': !exists(json, 'statement_descriptor') ? undefined : json['statement_descriptor'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'unitLabel': !exists(json, 'unit_label') ? undefined : json['unit_label'],
        'updated': !exists(json, 'updated') ? undefined : json['updated'],
        'url': !exists(json, 'url') ? undefined : json['url'],
    };
}

export function StripeProductToJSON(value?: StripeProduct): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'active': value.active,
        'caption': value.caption,
        'created': value.created,
        'deactivate_on': value.deactivateOn,
        'description': value.description,
        'id': value.id,
        'images': value.images,
        'livemode': value.livemode,
        'metadata': value.metadata,
        'name': value.name,
        'object': value.object,
        'shippable': value.shippable,
        'statement_descriptor': value.statementDescriptor,
        'type': value.type,
        'unit_label': value.unitLabel,
        'updated': value.updated,
        'url': value.url,
    };
}

/**
* @export
* @enum {string}
*/
export enum StripeProductModelObjectEnum {
    Product = 'product'
}
/**
* @export
* @enum {string}
*/
export enum StripeProductTypeEnum {
    Good = 'good',
    Service = 'service'
}


