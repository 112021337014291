import { useMutation, useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import {
  EnrollDeviceBiometricsRequest,
  fetchBiometricsApi,
} from "shared/fetch/src/apis/BiometricsApi";
import { showSnackbar } from "shared/state/ui/snackbar";

const useEnrollDeviceBiometrics = (userId: string | undefined) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate } = useMutation((request: EnrollDeviceBiometricsRequest) => {
    return fetchBiometricsApi
      .enrollDeviceBiometrics(request)
      .then(() => {
        dispatch(showSnackbar("Device has been enrolled in biometric login."));
        queryClient.invalidateQueries(["memberEnrolledDevices", userId]);
      })
      .catch(() => {
        dispatch(
          showSnackbar(
            "There was an issue enrolling your device, please try again later.",
            "danger"
          )
        );
      });
  });
  return { enrollDeviceBiometrics: mutate };
};

export default useEnrollDeviceBiometrics;
