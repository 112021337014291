// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';


/**
 * 
 * @export
 * @interface UpdateEpisodeCTMAIOwnerResponse
 */
export interface UpdateEpisodeCTMAIOwnerResponse  {
    /**
     * 
     * @type {Array<ActionableItemSummary>}
     * @memberof UpdateEpisodeCTMAIOwnerResponse
     */
    results: Array<ActionableItemSummary>;
}

export function UpdateEpisodeCTMAIOwnerResponseFromJSON(json: any): UpdateEpisodeCTMAIOwnerResponse {
    return {
        'results': (json['results'] as Array<any>).map(ActionableItemSummaryFromJSON),
    };
}

export function UpdateEpisodeCTMAIOwnerResponseToJSON(value?: UpdateEpisodeCTMAIOwnerResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': (value.results as Array<any>).map(ActionableItemSummaryToJSON),
    };
}


