import React, { FunctionComponent, useEffect } from "react";
import clsx from "clsx";
import TabsComponent, { TabsProps } from "@mui/material/Tabs";
import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

// TODO: there is a known issue with the onChange definition types in MUI v4, unfortunately the bug fix might never make it into v4
// https://github.com/mui/material-ui/issues/17454
interface IProps extends Omit<TabsProps, "onChange"> {
  flexEnd?: string;
  hasOutlineWhite?: boolean;
  justifyContent?: "flex-start" | "space-around" | "flex-end";
  appearance?: "" | "default" | "pills" | "pills-white-outline";
  onChange?: (event: React.ChangeEvent<{}>, newValue: any) => void;
}

const useStyles = makeStyles(() => ({
  "flex-start": {},
  "space-around": {
    "& .MuiTabs-flexContainer": {
      justifyContent: "space-around",
    },
  },
}));

const useTabsStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      height: "auto",
      minHeight: "auto",
      "& [class*='MuiTabs-flexContainer']": {
        height: "auto",
        border: ({ appearance }: IProps) =>
          appearance === "pills-white-outline" ? "white solid 1px" : "none",
        justifyContent: ({ flexEnd }: IProps) => {
          return flexEnd === "true" ? "end" : "unset";
        },
      },
      "& [class*='MuiTab-root']": {
        position: "relative",
        borderRadius: "30px",
        textAlign: "center",
        padding: "4px 24px",
        minHeight: "auto",
        color: "#fff",
        height: "auto",
        opacity: "1",
        margin: 0,
        marginRight: 6,
        float: "none",
        backgroundColor: ({ appearance }: IProps) =>
          appearance === "pills"
            ? theme.palette?.tab?.pill?.secondary
            : "rgba(43,57,73,0.3)",
        fontSize: theme.typography?.bodySmall?.fontSize,
        "&, &:hover": {
          color: "#FFFFFF",
        },
        "&.Mui-selected": {
          fontWeight: "normal",
          "&, &:hover": {
            color: "#FFFFFF",
            backgroundColor: ({ appearance }: IProps) =>
              appearance === "pills"
                ? theme.palette?.tab?.pill?.primary
                : theme.palette.primary.main,
            fontSize: theme.typography?.bodySmall?.fontSize,
          },
          border: ({ appearance }: IProps) =>
            appearance === "pills-white-outline" ? "white solid 1px" : "none",
        },
      },
      "& [class*='MuiTab-wrapper']": {
        position: "relative",
        display: "block",
        color: "inherit",
      },
    },
    indicator: {
      display: "none",
    },
    centered: {
      alignItems: "center",
      justifyContent: "center",
    },
  } as any)
);

const Tabs: FunctionComponent<IProps> = ({
  appearance = "",
  justifyContent = "flex-start",
  className,
  flexEnd,
  ...rest
}) => {
  const classes = useTabsStyles({ appearance, flexEnd });
  const styles = useStyles();
  useEffect(() => {
    (document as any).fonts?.ready?.then(() => {
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent("resize"));
      }, 10);
    });
  }, []);
  return (
    <TabsComponent
      classes={
        appearance === "pills" || appearance === "pills-white-outline"
          ? classes
          : undefined
      }
      className={clsx(styles[justifyContent], className)}
      {...rest}
    />
  );
};

export default Tabs;
