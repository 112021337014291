// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateVirtualCareTypeRequestBody
 */
export interface UpdateVirtualCareTypeRequestBody  {
    /**
     * 
     * @type {string}
     * @memberof UpdateVirtualCareTypeRequestBody
     */
    vucEvent?: UpdateVirtualCareTypeRequestBodyVucEventEnum;
}

export function UpdateVirtualCareTypeRequestBodyFromJSON(json: any): UpdateVirtualCareTypeRequestBody {
    return {
        'vucEvent': !exists(json, 'vuc_event') ? undefined : json['vuc_event'],
    };
}

export function UpdateVirtualCareTypeRequestBodyToJSON(value?: UpdateVirtualCareTypeRequestBody): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'vuc_event': value.vucEvent,
    };
}

/**
* @export
* @enum {string}
*/
export enum UpdateVirtualCareTypeRequestBodyVucEventEnum {
    Marked = 'marked',
    Unmarked = 'unmarked'
}


