import { Middleware, MiddlewareAPI, Dispatch, AnyAction } from "redux";
import { QueryConfig, RequestSuccessAction } from "redux-query";
import { State } from "shared/types/state";
import { Entities } from "shared/types/state";

const memberAccessMiddleware: Middleware =
  (api: MiddlewareAPI<Dispatch<AnyAction>, State>) =>
  (next: Dispatch) =>
  (action: AnyAction | RequestSuccessAction) => {
    switch ((action as AnyAction).type) {
      case "@@query/REQUEST_ASYNC":
      case "@@query/MUTATE_ASYNC":
        const queryRequestAction = action as QueryConfig<Entities>;
        const memberAccessToken = api.getState().auth.memberAccessToken;
        if (
          memberAccessToken &&
          queryRequestAction.options &&
          queryRequestAction.options.headers
        ) {
          queryRequestAction.options.headers[
            "X-XO-Patient-Access-Grant"
          ] = `${memberAccessToken}`;
        }
        break;
    }

    return next(action);
  };

export default memberAccessMiddleware;
