import React from "react";
import { useSelector } from "react-redux";
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { Link } from "components/Button";
import Typography from "components/Typography";

import { getFeatures } from "shared/features/featureFlags/selectors";
import { selectLoggedInUser } from "shared/features/user/selectors";

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      padding: 0,
      minWidth: 0,
      verticalAlign: "initial",
      "&:hover": {
        color: "white",
        textDecoration: "underline",
      },
      textDecoration: "underline",
    },
  })
);

const useAmatHraMessage = () => {
  const featureFlags = useSelector(getFeatures);
  const user = selectLoggedInUser();
  const classes = useStyles();

  if (
    !featureFlags.hasAmatHra() ||
    user.ctm ||
    !user.amatHra2021SurveyEligible
  ) {
    return {};
  }

  return {
    message: (
      <span>
        The 2021 Wellness Incentive Program is now open and will run from July
        12th to October 8th, 2021. Complete your Health Risk Assessment{" "}
        <Link
          href="https://amp.crossoverhealth.com/"
          target="_blank"
          rel="noreferrer"
          className={classes.link}
        >
          <Typography variant="body2">survey</Typography>
        </Link>{" "}
        to join the program.
      </span>
    ),
    orderDate: new Date(2021, 10, 8),
  };
};

export default useAmatHraMessage;
