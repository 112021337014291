import React from "react";
import { Provider, ErrorBoundary } from "@rollbar/react";
import ErrorsBoundary from "./ErrorsBoundary";

const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_KEY,
  environment: process.env.REACT_APP_ROLLBAR_ENV,
  captureUncaught: true,
  captureUnhandledRejections: true,
};

interface RollbarProviderProps {
  children: React.ReactNode;
}

export const RollbarProvider: React.FC<RollbarProviderProps> = ({
  children,
}) => (
  <Provider config={rollbarConfig}>
    <ErrorBoundary fallbackUI={ErrorsBoundary}>{children}</ErrorBoundary>
  </Provider>
);
