/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    ListComponentActivityLogResponse,
    ListComponentActivityLogResponseFromJSON,
    ListComponentActivityLogResponseToJSON,
} from '../models/ListComponentActivityLogResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'


export interface ListComponentActivityLogRequest {
    componentId: string;
}


export interface IActivityLogApi {
      listComponentActivityLog: (requestParameters: ListComponentActivityLogRequest) => Promise<ListComponentActivityLogResponse>
      listComponentActivityLogWithResponse: (requestParameters: ListComponentActivityLogRequest) => Promise<{ value: ListComponentActivityLogResponse, response: any}>
}

/**
 * no description
 */
export class ActivityLogApi extends runtime.BaseAPI {

    /**
     * Fetch component activity log
     */
    listComponentActivityLogRaw = async (requestParameters: ListComponentActivityLogRequest): Promise<{ runtime: runtime.ApiResponse<ListComponentActivityLogResponse>, response: any}> => {
        if (requestParameters.componentId === null || requestParameters.componentId === undefined) {
            throw new runtime.RequiredError('componentId','Required parameter requestParameters.componentId was null or undefined when calling listComponentActivityLog.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/components/{component_id}/activity_log`.replace(`{${"component_id"}}`, encodeURIComponent(String(requestParameters.componentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ListComponentActivityLogResponseFromJSON(jsonValue)), response };
    }

    /**
     * Fetch component activity log
     */
    listComponentActivityLog = async (requestParameters: ListComponentActivityLogRequest): Promise<ListComponentActivityLogResponse> => {
        const { runtime } = await this.listComponentActivityLogRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch component activity log
     */
    listComponentActivityLogWithResponse = async (requestParameters: ListComponentActivityLogRequest): Promise<{ value: ListComponentActivityLogResponse, response: any}> => {
        const { runtime, response } = await this.listComponentActivityLogRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchActivityLogApi: IActivityLogApi  = new ActivityLogApi();
