import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import Typography from "components/Typography";
import ConversationCard from "components/ConversationCard";
import { useStyles } from "pages/LandingPage/styles";
import { JsonUser } from "shared/fetch/src/models/JsonUser";
import { ConversationSummary } from "shared/fetch/src/models/ConversationSummary";

interface ActiveConversationsProps {
  conversations: { results: ConversationSummary[] };
  user: JsonUser | undefined;
  totalActiveConversations: number;
  upcomingVisits: number;
}

const ActiveConversations: React.FC<ActiveConversationsProps> = ({
  conversations,
  user,
  totalActiveConversations,
  upcomingVisits,
}) => {
  const classes = useStyles();

  const renderConversationCards = () => {
    return conversations.results.map((conversation: ConversationSummary) => (
      <li key={conversation.id}>
        <ConversationCard conversation={conversation} isCTM={user?.ctm} />
      </li>
    ));
  };

  const dashboardTitleClasses = clsx(
    classes.dashboardTitle,
    upcomingVisits > 0 && classes.dashboardTitlePadding
  );

  return (
    <>
      <Typography
        appearance="smallBody"
        variant="h2"
        className={dashboardTitleClasses}
        id="active_conversation"
      >
        ACTIVE CONVERSATIONS ({totalActiveConversations})
      </Typography>
      <ul className={classes.conversationsList}>{renderConversationCards()}</ul>
      <Link to="care-history" className={classes.dashboardLink}>
        See all Conversations
      </Link>
    </>
  );
};

export default ActiveConversations;
