// we use electron in dev and in CI, but it has a styling bug at this time where divs with height set by
// dvh (dynamic viewport height) do not scroll. However, for correct styling in mobile Chrome we *have* to use
// dvh instead of vh. This util allows us to apply conditional styling to electron instances.

export default function isElectron() {
  // Main process
  if (
    typeof process !== "undefined" &&
    typeof process.versions === "object" &&
    !!process.versions.electron
  ) {
    return true;
  }
  // Detect the user agent when the `nodeIntegration` option is set to true
  if (
    typeof navigator === "object" &&
    typeof navigator.userAgent === "string" &&
    navigator.userAgent.indexOf("Electron") >= 0
  ) {
    return true;
  }
  return false;
}
