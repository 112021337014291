// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



import {
     ActionableItemSummary,
    ActionableItemSummaryFromJSON,
    ActionableItemSummaryToJSON,
} from './ActionableItemSummary';
import {
     CTMInfo,
    CTMInfoFromJSON,
    CTMInfoToJSON,
} from './CTMInfo';
import {
     ConversationSummaryCore,
    ConversationSummaryCoreFromJSON,
    ConversationSummaryCoreToJSON,
} from './ConversationSummaryCore';
import {
     MemberInfo,
    MemberInfoFromJSON,
    MemberInfoToJSON,
} from './MemberInfo';
/**
 * @type MemberConversationSummary
 * @export
 */
export interface MemberConversationSummary extends ConversationSummaryCore {
}

export function MemberConversationSummaryFromJSON(json: any): MemberConversationSummary {
    return {
        ...ConversationSummaryCoreFromJSON(json),
    };
}

export function MemberConversationSummaryToJSON(value?: MemberConversationSummary): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        ...ConversationSummaryCoreToJSON(value),
    };
}

