// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ProviderSummarySchema,
    ProviderSummarySchemaFromJSON,
    ProviderSummarySchemaToJSON,
} from './ProviderSummarySchema';


/**
 * 
 * @export
 * @interface SlotProviderObject
 */
export interface SlotProviderObject  {
    /**
     * 
     * @type {number}
     * @memberof SlotProviderObject
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SlotProviderObject
     */
    startAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SlotProviderObject
     */
    endAt?: string;
    /**
     * 
     * @type {string}
     * @memberof SlotProviderObject
     */
    visibility?: SlotProviderObjectVisibilityEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof SlotProviderObject
     */
    appointmentTypes?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SlotProviderObject
     */
    clinicId?: string;
    /**
     * 
     * @type {number}
     * @memberof SlotProviderObject
     */
    maxOverbook?: number;
    /**
     * 
     * @type {ProviderSummarySchema}
     * @memberof SlotProviderObject
     */
    provider?: ProviderSummarySchema;
    /**
     * 
     * @type {string}
     * @memberof SlotProviderObject
     */
    clinicTimeZone?: string;
    /**
     * 
     * @type {string}
     * @memberof SlotProviderObject
     */
    clinicName?: string;
}

export function SlotProviderObjectFromJSON(json: any): SlotProviderObject {
    return {
        'id': !exists(json, 'id') ? undefined : json['id'],
        'startAt': !exists(json, 'start_at') ? undefined : json['start_at'],
        'endAt': !exists(json, 'end_at') ? undefined : json['end_at'],
        'visibility': !exists(json, 'visibility') ? undefined : json['visibility'],
        'appointmentTypes': !exists(json, 'appointment_types') ? undefined : json['appointment_types'],
        'clinicId': !exists(json, 'clinic_id') ? undefined : json['clinic_id'],
        'maxOverbook': !exists(json, 'max_overbook') ? undefined : json['max_overbook'],
        'provider': !exists(json, 'provider') ? undefined : ProviderSummarySchemaFromJSON(json['provider']),
        'clinicTimeZone': !exists(json, 'clinic_time_zone') ? undefined : json['clinic_time_zone'],
        'clinicName': !exists(json, 'clinic_name') ? undefined : json['clinic_name'],
    };
}

export function SlotProviderObjectToJSON(value?: SlotProviderObject): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'id': value.id,
        'start_at': value.startAt,
        'end_at': value.endAt,
        'visibility': value.visibility,
        'appointment_types': value.appointmentTypes,
        'clinic_id': value.clinicId,
        'max_overbook': value.maxOverbook,
        'provider': ProviderSummarySchemaToJSON(value.provider),
        'clinic_time_zone': value.clinicTimeZone,
        'clinic_name': value.clinicName,
    };
}

/**
* @export
* @enum {string}
*/
export enum SlotProviderObjectVisibilityEnum {
    Internal = 'internal',
    Accessible = 'accessible',
    Hold = 'hold'
}


