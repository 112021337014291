// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SlotOutput,
    SlotOutputFromJSON,
    SlotOutputToJSON,
} from './SlotOutput';


/**
 * 
 * @export
 * @interface SlotsFetchResponse
 */
export interface SlotsFetchResponse  {
    /**
     * 
     * @type {Array<SlotOutput>}
     * @memberof SlotsFetchResponse
     */
    results: Array<SlotOutput>;
}

export function SlotsFetchResponseFromJSON(json: any): SlotsFetchResponse {
    return {
        'results': (json['results'] as Array<any>).map(SlotOutputFromJSON),
    };
}

export function SlotsFetchResponseToJSON(value?: SlotsFetchResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': (value.results as Array<any>).map(SlotOutputToJSON),
    };
}


