// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DailyScheduleResponseList,
    DailyScheduleResponseListFromJSON,
    DailyScheduleResponseListToJSON,
} from './DailyScheduleResponseList';


/**
 * 
 * @export
 * @interface DailyScheduleResponse
 */
export interface DailyScheduleResponse  {
    /**
     * 
     * @type {string}
     * @memberof DailyScheduleResponse
     */
    site?: string;
    /**
     * 
     * @type {Array<DailyScheduleResponseList>}
     * @memberof DailyScheduleResponse
     */
    list?: Array<DailyScheduleResponseList>;
}

export function DailyScheduleResponseFromJSON(json: any): DailyScheduleResponse {
    return {
        'site': !exists(json, 'site') ? undefined : json['site'],
        'list': !exists(json, 'list') ? undefined : (json['list'] as Array<any>).map(DailyScheduleResponseListFromJSON),
    };
}

export function DailyScheduleResponseToJSON(value?: DailyScheduleResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'site': value.site,
        'list': value.list === undefined ? undefined : (value.list as Array<any>).map(DailyScheduleResponseListToJSON),
    };
}


