// import { FontStyle } from '@mui/material/styles';

const pxToRem = (value: number) => `${value * 0.0625}rem`;

declare module "@mui/material/styles/createTypography" {
  interface Typography {
    body?: any;
    bodySmall?: any;
    lead?: any;
    miniCaption?: any;
    bodyEmphasized?: any;
  }

  interface TypographyOptions {
    body?: any;
    bodySmall?: any;
    lead?: any;
    miniCaption?: any;
  }
}

const mobileMediaQuery = "@media (max-width:768px)";

export default {
  fontFamily: [
    "Agipo",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  h1: {
    fontWeight: "bold",
    fontSize: pxToRem(40),
    lineHeight: pxToRem(52),
    [mobileMediaQuery]: {
      fontSize: pxToRem(32),
      lineHeight: pxToRem(41.6),
    },
  },
  h2: {
    fontWeight: "bold",
    fontSize: pxToRem(33.3),
    lineHeight: pxToRem(43.29),
    [mobileMediaQuery]: {
      fontSize: pxToRem(27.5),
      lineHeight: pxToRem(35.75),
    },
  },
  h3: {
    fontWeight: "bold",
    fontSize: pxToRem(27.7),
    lineHeight: pxToRem(36),
    [mobileMediaQuery]: {
      fontSize: pxToRem(23.6),
      lineHeight: pxToRem(30.68),
    },
  },
  h4: {
    fontWeight: "bold",
    fontSize: pxToRem(23.1),
    lineHeight: pxToRem(30),
    [mobileMediaQuery]: {
      fontSize: pxToRem(20.3),
      lineHeight: pxToRem(26.39),
    },
  },
  h5: {
    fontWeight: "bold",
    fontSize: pxToRem(19.2),
    lineHeight: pxToRem(25),
    [mobileMediaQuery]: {
      fontSize: pxToRem(17.5),
      lineHeight: pxToRem(22.75),
    },
  },
  h6: {
    fontWeight: 500,
    fontSize: pxToRem(15),
    lineHeight: pxToRem(19),
  },
  lead: {
    fontWeight: 400,
    fontSize: pxToRem(19.2),
    lineHeight: pxToRem(25),
    [mobileMediaQuery]: {
      fontSize: pxToRem(17.5),
      lineHeight: pxToRem(22.75),
    },
  },
  subtitle1: {
    fontSize: "16px",
    lineHeight: "25px",
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
  },
  body1: {
    fontSize: pxToRem(16),
    lineHeight: pxToRem(20.8),
    [mobileMediaQuery]: {
      fontSize: pxToRem(14),
      lineHeight: pxToRem(18.2),
    },
  },
  bodyEmphasized: {
    fontWeight: 700,
    fontSize: pxToRem(16),
    lineHeight: pxToRem(20.8),
    [mobileMediaQuery]: {
      fontSize: pxToRem(14),
      lineHeight: pxToRem(18.2),
    },
  },
  body: {
    fontSize: pxToRem(16),
    lineHeight: pxToRem(20.8),
    [mobileMediaQuery]: {
      fontSize: pxToRem(14),
      lineHeight: pxToRem(18.2),
    },
  },
  body2: {
    fontSize: pxToRem(13.3),
    lineHeight: pxToRem(17.3),
    [mobileMediaQuery]: {
      fontSize: pxToRem(11.67),
      lineHeight: pxToRem(17),
    },
  },
  bodySmall: {
    fontSize: pxToRem(13.3),
    lineHeight: pxToRem(17.3),
    [mobileMediaQuery]: {
      fontSize: pxToRem(11.67),
      lineHeight: pxToRem(17),
    },
  },
  button: {
    fontSize: pxToRem(16),
    textTransform: "none",
  },
  caption: {
    fontSize: pxToRem(11.1),
    lineHeight: pxToRem(14.43),
    [mobileMediaQuery]: {
      fontSize: pxToRem(11.1),
      lineHeight: pxToRem(14.43),
    },
  },
  miniCaption: {
    fontSize: pxToRem(9.2),
    lineHeight: pxToRem(12),
    [mobileMediaQuery]: {
      fontSize: pxToRem(9.5),
      lineHeight: pxToRem(12.35),
    },
  },
  overline: {
    fontSize: pxToRem(11),
    fontWeight: 500,
    lineHeight: pxToRem(13),
    textTransform: "uppercase",
  },
} as any;
