/* tslint:disable */
/* eslint-disable */
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AdHocQuestionDetails,
    AdHocQuestionDetailsFromJSON,
    AdHocQuestionDetailsToJSON,
} from '../models/AdHocQuestionDetails'
import {
    AdHocQuestionRequestBody,
    AdHocQuestionRequestBodyFromJSON,
    AdHocQuestionRequestBodyToJSON,
} from '../models/AdHocQuestionRequestBody'
import {
    CMSDetailsItemSchema,
    CMSDetailsItemSchemaFromJSON,
    CMSDetailsItemSchemaToJSON,
} from '../models/CMSDetailsItemSchema'
import {
    CMSListSchema,
    CMSListSchemaFromJSON,
    CMSListSchemaToJSON,
} from '../models/CMSListSchema'
import {
    ClinicProviderConfigFetchResponse,
    ClinicProviderConfigFetchResponseFromJSON,
    ClinicProviderConfigFetchResponseToJSON,
} from '../models/ClinicProviderConfigFetchResponse'
import {
    CmsNavigationSchema,
    CmsNavigationSchemaFromJSON,
    CmsNavigationSchemaToJSON,
} from '../models/CmsNavigationSchema'
import {
    CmsServiceLineItem,
    CmsServiceLineItemFromJSON,
    CmsServiceLineItemToJSON,
} from '../models/CmsServiceLineItem'
import {
    CommonQuestionDetailsItem,
    CommonQuestionDetailsItemFromJSON,
    CommonQuestionDetailsItemToJSON,
} from '../models/CommonQuestionDetailsItem'
import {
    CommonQuestionEditRequestBody,
    CommonQuestionEditRequestBodyFromJSON,
    CommonQuestionEditRequestBodyToJSON,
} from '../models/CommonQuestionEditRequestBody'
import {
    CommonQuestionsSchema,
    CommonQuestionsSchemaFromJSON,
    CommonQuestionsSchemaToJSON,
} from '../models/CommonQuestionsSchema'
import {
    ContentTypeSchema,
    ContentTypeSchemaFromJSON,
    ContentTypeSchemaToJSON,
} from '../models/ContentTypeSchema'
import {
    ForbiddenResponse,
    ForbiddenResponseFromJSON,
    ForbiddenResponseToJSON,
} from '../models/ForbiddenResponse'
import {
    InvalidParametersResponse,
    InvalidParametersResponseFromJSON,
    InvalidParametersResponseToJSON,
} from '../models/InvalidParametersResponse'
import {
    NotFoundResponse,
    NotFoundResponseFromJSON,
    NotFoundResponseToJSON,
} from '../models/NotFoundResponse'
import {
    QuestionSetCategoriesSchema,
    QuestionSetCategoriesSchemaFromJSON,
    QuestionSetCategoriesSchemaToJSON,
} from '../models/QuestionSetCategoriesSchema'
import {
    QuestionSetDetailsSchema,
    QuestionSetDetailsSchemaFromJSON,
    QuestionSetDetailsSchemaToJSON,
} from '../models/QuestionSetDetailsSchema'
import {
    QuestionSetRequestBody,
    QuestionSetRequestBodyFromJSON,
    QuestionSetRequestBodyToJSON,
} from '../models/QuestionSetRequestBody'
import {
    RecentToolsList,
    RecentToolsListFromJSON,
    RecentToolsListToJSON,
} from '../models/RecentToolsList'
import {
    ServiceLinesSchema,
    ServiceLinesSchemaFromJSON,
    ServiceLinesSchemaToJSON,
} from '../models/ServiceLinesSchema'
import {
    ServiceUnavailableResponse,
    ServiceUnavailableResponseFromJSON,
    ServiceUnavailableResponseToJSON,
} from '../models/ServiceUnavailableResponse'
import {
    UnauthorizedResponse,
    UnauthorizedResponseFromJSON,
    UnauthorizedResponseToJSON,
} from '../models/UnauthorizedResponse'
import {
    UnauthorizedSessionResponse,
    UnauthorizedSessionResponseFromJSON,
    UnauthorizedSessionResponseToJSON,
} from '../models/UnauthorizedSessionResponse'


export interface AddNewQuestionSetRequest {
    questionSetRequestBody?: QuestionSetRequestBody;
}
export interface CreateAdHocQuestionsRequest {
    adHocQuestionRequestBody?: AdHocQuestionRequestBody;
}
export interface EditCommonQuestionRequest {
    id: string;
    commonQuestionEditRequestBody?: CommonQuestionEditRequestBody;
}
export interface FetchCmsItemRequest {
    cmsContentType: string;
    cmsItemId: string;
}
export interface GetCMSListRequest {
    cmsContentType: string;
    q?: string;
    offset?: number;
    count?: number;
}
export interface GetCommonQuestionsRequest {
    q?: string;
}
export interface GetContentTypeListRequest {
    contentType: string;
    q?: string;
}
export interface GetQuestionSetRequest {
    id: string;
}
export interface GetServiceLineRequest {
    serviceLine: string;
}


export interface ICmsItemsApi {
      addNewQuestionSet: (requestParameters: AddNewQuestionSetRequest) => Promise<QuestionSetDetailsSchema>
      createAdHocQuestions: (requestParameters: CreateAdHocQuestionsRequest) => Promise<AdHocQuestionDetails>
      editCommonQuestion: (requestParameters: EditCommonQuestionRequest) => Promise<CommonQuestionDetailsItem>
      fetchCmsItem: (requestParameters: FetchCmsItemRequest) => Promise<CMSDetailsItemSchema>
      getCMSList: (requestParameters: GetCMSListRequest) => Promise<CMSListSchema>
      getClinicProviderConfigs: () => Promise<ClinicProviderConfigFetchResponse>
      getCmsMenuHierarchy: () => Promise<CmsNavigationSchema>
      getCommonQuestions: (requestParameters: GetCommonQuestionsRequest) => Promise<CommonQuestionsSchema>
      getContentTypeList: (requestParameters: GetContentTypeListRequest) => Promise<ContentTypeSchema>
      getQuestionSet: (requestParameters: GetQuestionSetRequest) => Promise<QuestionSetDetailsSchema>
      getQuestionSetCategories: () => Promise<QuestionSetCategoriesSchema>
      getServiceLine: (requestParameters: GetServiceLineRequest) => Promise<CmsServiceLineItem>
      getServiceLines: () => Promise<ServiceLinesSchema>
      listRecentTools: () => Promise<RecentToolsList>
      addNewQuestionSetWithResponse: (requestParameters: AddNewQuestionSetRequest) => Promise<{ value: QuestionSetDetailsSchema, response: any}>
      createAdHocQuestionsWithResponse: (requestParameters: CreateAdHocQuestionsRequest) => Promise<{ value: AdHocQuestionDetails, response: any}>
      editCommonQuestionWithResponse: (requestParameters: EditCommonQuestionRequest) => Promise<{ value: CommonQuestionDetailsItem, response: any}>
      fetchCmsItemWithResponse: (requestParameters: FetchCmsItemRequest) => Promise<{ value: CMSDetailsItemSchema, response: any}>
      getCMSListWithResponse: (requestParameters: GetCMSListRequest) => Promise<{ value: CMSListSchema, response: any}>
      getClinicProviderConfigsWithResponse: () => Promise<{ value: ClinicProviderConfigFetchResponse, response: any}>
      getCmsMenuHierarchyWithResponse: () => Promise<{ value: CmsNavigationSchema, response: any}>
      getCommonQuestionsWithResponse: (requestParameters: GetCommonQuestionsRequest) => Promise<{ value: CommonQuestionsSchema, response: any}>
      getContentTypeListWithResponse: (requestParameters: GetContentTypeListRequest) => Promise<{ value: ContentTypeSchema, response: any}>
      getQuestionSetWithResponse: (requestParameters: GetQuestionSetRequest) => Promise<{ value: QuestionSetDetailsSchema, response: any}>
      getQuestionSetCategoriesWithResponse: () => Promise<{ value: QuestionSetCategoriesSchema, response: any}>
      getServiceLineWithResponse: (requestParameters: GetServiceLineRequest) => Promise<{ value: CmsServiceLineItem, response: any}>
      getServiceLinesWithResponse: () => Promise<{ value: ServiceLinesSchema, response: any}>
      listRecentToolsWithResponse: () => Promise<{ value: RecentToolsList, response: any}>
}

/**
 * no description
 */
export class CmsItemsApi extends runtime.BaseAPI {

    /**
     * add a new unique question set
     */
    addNewQuestionSetRaw = async (requestParameters: AddNewQuestionSetRequest): Promise<{ runtime: runtime.ApiResponse<QuestionSetDetailsSchema>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/question_sets`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: QuestionSetRequestBodyToJSON(requestParameters.questionSetRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => QuestionSetDetailsSchemaFromJSON(jsonValue)), response };
    }

    /**
     * add a new unique question set
     */
    addNewQuestionSet = async (requestParameters: AddNewQuestionSetRequest): Promise<QuestionSetDetailsSchema> => {
        const { runtime } = await this.addNewQuestionSetRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * add a new unique question set
     */
    addNewQuestionSetWithResponse = async (requestParameters: AddNewQuestionSetRequest): Promise<{ value: QuestionSetDetailsSchema, response: any}> => {
        const { runtime, response } = await this.addNewQuestionSetRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Create Ad-Hoc questions
     */
    createAdHocQuestionsRaw = async (requestParameters: CreateAdHocQuestionsRequest): Promise<{ runtime: runtime.ApiResponse<AdHocQuestionDetails>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/questions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AdHocQuestionRequestBodyToJSON(requestParameters.adHocQuestionRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => AdHocQuestionDetailsFromJSON(jsonValue)), response };
    }

    /**
     * Create Ad-Hoc questions
     */
    createAdHocQuestions = async (requestParameters: CreateAdHocQuestionsRequest): Promise<AdHocQuestionDetails> => {
        const { runtime } = await this.createAdHocQuestionsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Create Ad-Hoc questions
     */
    createAdHocQuestionsWithResponse = async (requestParameters: CreateAdHocQuestionsRequest): Promise<{ value: AdHocQuestionDetails, response: any}> => {
        const { runtime, response } = await this.createAdHocQuestionsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * update the common question using id
     * update the common question using id
     */
    editCommonQuestionRaw = async (requestParameters: EditCommonQuestionRequest): Promise<{ runtime: runtime.ApiResponse<CommonQuestionDetailsItem>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling editCommonQuestion.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/common_questions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CommonQuestionEditRequestBodyToJSON(requestParameters.commonQuestionEditRequestBody),
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CommonQuestionDetailsItemFromJSON(jsonValue)), response };
    }

    /**
     * update the common question using id
     * update the common question using id
     */
    editCommonQuestion = async (requestParameters: EditCommonQuestionRequest): Promise<CommonQuestionDetailsItem> => {
        const { runtime } = await this.editCommonQuestionRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * update the common question using id
     * update the common question using id
     */
    editCommonQuestionWithResponse = async (requestParameters: EditCommonQuestionRequest): Promise<{ value: CommonQuestionDetailsItem, response: any}> => {
        const { runtime, response } = await this.editCommonQuestionRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Fetch cms item by ID
     * Fetch cms item by ID
     */
    fetchCmsItemRaw = async (requestParameters: FetchCmsItemRequest): Promise<{ runtime: runtime.ApiResponse<CMSDetailsItemSchema>, response: any}> => {
        if (requestParameters.cmsContentType === null || requestParameters.cmsContentType === undefined) {
            throw new runtime.RequiredError('cmsContentType','Required parameter requestParameters.cmsContentType was null or undefined when calling fetchCmsItem.');
        }

                        if (requestParameters.cmsItemId === null || requestParameters.cmsItemId === undefined) {
            throw new runtime.RequiredError('cmsItemId','Required parameter requestParameters.cmsItemId was null or undefined when calling fetchCmsItem.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/{cms_content_type}/{cms_item_id}`.replace(`{${"cms_content_type"}}`, encodeURIComponent(String(requestParameters.cmsContentType))).replace(`{${"cms_item_id"}}`, encodeURIComponent(String(requestParameters.cmsItemId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CMSDetailsItemSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Fetch cms item by ID
     * Fetch cms item by ID
     */
    fetchCmsItem = async (requestParameters: FetchCmsItemRequest): Promise<CMSDetailsItemSchema> => {
        const { runtime } = await this.fetchCmsItemRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Fetch cms item by ID
     * Fetch cms item by ID
     */
    fetchCmsItemWithResponse = async (requestParameters: FetchCmsItemRequest): Promise<{ value: CMSDetailsItemSchema, response: any}> => {
        const { runtime, response } = await this.fetchCmsItemRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Retrieves a list of a specified cms content type
     * Fetch a list of cms content type items
     */
    getCMSListRaw = async (requestParameters: GetCMSListRequest): Promise<{ runtime: runtime.ApiResponse<CMSListSchema>, response: any}> => {
        if (requestParameters.cmsContentType === null || requestParameters.cmsContentType === undefined) {
            throw new runtime.RequiredError('cmsContentType','Required parameter requestParameters.cmsContentType was null or undefined when calling getCMSList.');
        }

                                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.count !== undefined) {
            queryParameters['count'] = requestParameters.count;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/{cms_content_type}`.replace(`{${"cms_content_type"}}`, encodeURIComponent(String(requestParameters.cmsContentType))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CMSListSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Retrieves a list of a specified cms content type
     * Fetch a list of cms content type items
     */
    getCMSList = async (requestParameters: GetCMSListRequest): Promise<CMSListSchema> => {
        const { runtime } = await this.getCMSListRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Retrieves a list of a specified cms content type
     * Fetch a list of cms content type items
     */
    getCMSListWithResponse = async (requestParameters: GetCMSListRequest): Promise<{ value: CMSListSchema, response: any}> => {
        const { runtime, response } = await this.getCMSListRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get all clinic provider configs
     * Get all clinic provider configs
     */
    getClinicProviderConfigsRaw = async (): Promise<{ runtime: runtime.ApiResponse<ClinicProviderConfigFetchResponse>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/clinic_provider_configs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ClinicProviderConfigFetchResponseFromJSON(jsonValue)), response };
    }

    /**
     * Get all clinic provider configs
     * Get all clinic provider configs
     */
    getClinicProviderConfigs = async (): Promise<ClinicProviderConfigFetchResponse> => {
        const { runtime } = await this.getClinicProviderConfigsRaw();
        return await runtime.value();
    }

    /**
     * Get all clinic provider configs
     * Get all clinic provider configs
     */
    getClinicProviderConfigsWithResponse = async (): Promise<{ value: ClinicProviderConfigFetchResponse, response: any}> => {
        const { runtime, response } = await this.getClinicProviderConfigsRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Retrieve all navigation content
     * Fetch all navigation contents
     */
    getCmsMenuHierarchyRaw = async (): Promise<{ runtime: runtime.ApiResponse<CmsNavigationSchema>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/menu`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CmsNavigationSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Retrieve all navigation content
     * Fetch all navigation contents
     */
    getCmsMenuHierarchy = async (): Promise<CmsNavigationSchema> => {
        const { runtime } = await this.getCmsMenuHierarchyRaw();
        return await runtime.value();
    }

    /**
     * Retrieve all navigation content
     * Fetch all navigation contents
     */
    getCmsMenuHierarchyWithResponse = async (): Promise<{ value: CmsNavigationSchema, response: any}> => {
        const { runtime, response } = await this.getCmsMenuHierarchyRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Retrieve all common questions
     * Fetch all common questions
     */
    getCommonQuestionsRaw = async (requestParameters: GetCommonQuestionsRequest): Promise<{ runtime: runtime.ApiResponse<CommonQuestionsSchema>, response: any}> => {
        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/common_questions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CommonQuestionsSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Retrieve all common questions
     * Fetch all common questions
     */
    getCommonQuestions = async (requestParameters: GetCommonQuestionsRequest): Promise<CommonQuestionsSchema> => {
        const { runtime } = await this.getCommonQuestionsRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Retrieve all common questions
     * Fetch all common questions
     */
    getCommonQuestionsWithResponse = async (requestParameters: GetCommonQuestionsRequest): Promise<{ value: CommonQuestionsSchema, response: any}> => {
        const { runtime, response } = await this.getCommonQuestionsRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Retrieves a list of a specified content type
     * Fetch a list of content type items
     */
    getContentTypeListRaw = async (requestParameters: GetContentTypeListRequest): Promise<{ runtime: runtime.ApiResponse<ContentTypeSchema>, response: any}> => {
        if (requestParameters.contentType === null || requestParameters.contentType === undefined) {
            throw new runtime.RequiredError('contentType','Required parameter requestParameters.contentType was null or undefined when calling getContentTypeList.');
        }

                        
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.contentType !== undefined) {
            queryParameters['contentType'] = requestParameters.contentType;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/tools`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ContentTypeSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Retrieves a list of a specified content type
     * Fetch a list of content type items
     */
    getContentTypeList = async (requestParameters: GetContentTypeListRequest): Promise<ContentTypeSchema> => {
        const { runtime } = await this.getContentTypeListRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Retrieves a list of a specified content type
     * Fetch a list of content type items
     */
    getContentTypeListWithResponse = async (requestParameters: GetContentTypeListRequest): Promise<{ value: ContentTypeSchema, response: any}> => {
        const { runtime, response } = await this.getContentTypeListRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Retrieve a single question set
     * Fetch specific question set
     */
    getQuestionSetRaw = async (requestParameters: GetQuestionSetRequest): Promise<{ runtime: runtime.ApiResponse<QuestionSetDetailsSchema>, response: any}> => {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getQuestionSet.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/question_sets/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => QuestionSetDetailsSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Retrieve a single question set
     * Fetch specific question set
     */
    getQuestionSet = async (requestParameters: GetQuestionSetRequest): Promise<QuestionSetDetailsSchema> => {
        const { runtime } = await this.getQuestionSetRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Retrieve a single question set
     * Fetch specific question set
     */
    getQuestionSetWithResponse = async (requestParameters: GetQuestionSetRequest): Promise<{ value: QuestionSetDetailsSchema, response: any}> => {
        const { runtime, response } = await this.getQuestionSetRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Retrieve the list of qs categories
     * Fetch the available qs categories
     */
    getQuestionSetCategoriesRaw = async (): Promise<{ runtime: runtime.ApiResponse<QuestionSetCategoriesSchema>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/qs_categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => QuestionSetCategoriesSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Retrieve the list of qs categories
     * Fetch the available qs categories
     */
    getQuestionSetCategories = async (): Promise<QuestionSetCategoriesSchema> => {
        const { runtime } = await this.getQuestionSetCategoriesRaw();
        return await runtime.value();
    }

    /**
     * Retrieve the list of qs categories
     * Fetch the available qs categories
     */
    getQuestionSetCategoriesWithResponse = async (): Promise<{ value: QuestionSetCategoriesSchema, response: any}> => {
        const { runtime, response } = await this.getQuestionSetCategoriesRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Get service line item by ID
     * Get service line item by ID
     */
    getServiceLineRaw = async (requestParameters: GetServiceLineRequest): Promise<{ runtime: runtime.ApiResponse<CmsServiceLineItem>, response: any}> => {
        if (requestParameters.serviceLine === null || requestParameters.serviceLine === undefined) {
            throw new runtime.RequiredError('serviceLine','Required parameter requestParameters.serviceLine was null or undefined when calling getServiceLine.');
        }

                
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/service_lines/{service_line}`.replace(`{${"service_line"}}`, encodeURIComponent(String(requestParameters.serviceLine))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => CmsServiceLineItemFromJSON(jsonValue)), response };
    }

    /**
     * Get service line item by ID
     * Get service line item by ID
     */
    getServiceLine = async (requestParameters: GetServiceLineRequest): Promise<CmsServiceLineItem> => {
        const { runtime } = await this.getServiceLineRaw(requestParameters);
        return await runtime.value();
    }

    /**
     * Get service line item by ID
     * Get service line item by ID
     */
    getServiceLineWithResponse = async (requestParameters: GetServiceLineRequest): Promise<{ value: CmsServiceLineItem, response: any}> => {
        const { runtime, response } = await this.getServiceLineRaw(requestParameters);
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Retrieve the list of service lines
     * Fetch all the service lines
     */
    getServiceLinesRaw = async (): Promise<{ runtime: runtime.ApiResponse<ServiceLinesSchema>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/service_lines`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => ServiceLinesSchemaFromJSON(jsonValue)), response };
    }

    /**
     * Retrieve the list of service lines
     * Fetch all the service lines
     */
    getServiceLines = async (): Promise<ServiceLinesSchema> => {
        const { runtime } = await this.getServiceLinesRaw();
        return await runtime.value();
    }

    /**
     * Retrieve the list of service lines
     * Fetch all the service lines
     */
    getServiceLinesWithResponse = async (): Promise<{ value: ServiceLinesSchema, response: any}> => {
        const { runtime, response } = await this.getServiceLinesRaw();
        const value = await runtime.value();
        return { value, response }
    }

    /**
     * Retrieve all recent tools
     * Fetch all recent tools
     */
    listRecentToolsRaw = async (): Promise<{ runtime: runtime.ApiResponse<RecentToolsList>, response: any}> => {

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const accessToken = await window.localStorage.getItem("accessToken")

        headerParameters["Authorization"] = `Bearer ${accessToken}`;

        headerParameters["XO-Auth-Version"] = process.env.REACT_APP_PLATFORM_UI_VERSION || "Unknown"

        const memberAccessToken = window.localStorage.getItem("X-XO-Patient-Access-Grant");

        if (memberAccessToken) {
            headerParameters["X-XO-Patient-Access-Grant"] = memberAccessToken;
        }

        const response = await this.request({
            path: `/v1/cms/recents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return { runtime: new runtime.JSONApiResponse(response, (jsonValue) => RecentToolsListFromJSON(jsonValue)), response };
    }

    /**
     * Retrieve all recent tools
     * Fetch all recent tools
     */
    listRecentTools = async (): Promise<RecentToolsList> => {
        const { runtime } = await this.listRecentToolsRaw();
        return await runtime.value();
    }

    /**
     * Retrieve all recent tools
     * Fetch all recent tools
     */
    listRecentToolsWithResponse = async (): Promise<{ value: RecentToolsList, response: any}> => {
        const { runtime, response } = await this.listRecentToolsRaw();
        const value = await runtime.value();
        return { value, response }
    }

}

export const fetchCmsItemsApi: ICmsItemsApi  = new CmsItemsApi();
