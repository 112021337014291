class XoConfig {
  public static getXoConfig = () => {
    return (window as any)._xoenv_;
  };

  public static getValue = (key: string) => {
    return XoConfig.getXoConfig()?.[key];
  };
}

export default XoConfig;
