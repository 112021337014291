import { useQuery } from "react-query";
import { UserUpcomingAppointmentsListSummariesRequest } from "shared/api/src/apis/AppointmentsApi";
import { fetchAppointmentsApi } from "shared/fetch/src/apis/AppointmentsApi";

export const getUpcomingAppointmentsQueryKey = (id: string | number) => [
  "upcomingAppointments",
  id,
];

export const useGetUpcomingAppointments = (
  payload: UserUpcomingAppointmentsListSummariesRequest
) => {
  const {
    data: upcomingAppointments,
    isLoading: isPending,
    refetch: refreshUpcomingAppointments,
  } = useQuery(
    getUpcomingAppointmentsQueryKey(payload.id),
    () => fetchAppointmentsApi.userUpcomingAppointmentsListSummaries(payload),
    {
      enabled: !!payload.id,
    }
  );

  return { isPending, upcomingAppointments, refreshUpcomingAppointments };
};

export default useGetUpcomingAppointments;
