// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MemberRequiredConsent,
    MemberRequiredConsentFromJSON,
    MemberRequiredConsentToJSON,
} from './MemberRequiredConsent';


/**
 * 
 * @export
 * @interface MemberRequiredConsentResponse
 */
export interface MemberRequiredConsentResponse  {
    /**
     * 
     * @type {Array<MemberRequiredConsent>}
     * @memberof MemberRequiredConsentResponse
     */
    results?: Array<MemberRequiredConsent>;
}

export function MemberRequiredConsentResponseFromJSON(json: any): MemberRequiredConsentResponse {
    return {
        'results': !exists(json, 'results') ? undefined : (json['results'] as Array<any>).map(MemberRequiredConsentFromJSON),
    };
}

export function MemberRequiredConsentResponseToJSON(value?: MemberRequiredConsentResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'results': value.results === undefined ? undefined : (value.results as Array<any>).map(MemberRequiredConsentToJSON),
    };
}


