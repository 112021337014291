import { useEffect, useState } from "react";

interface ILoadHTMLContentProps {
  link: string | undefined;
}

const useLoadHTMLContent = (options: ILoadHTMLContentProps) => {
  const { link } = options;
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [htmlContent, setHtmlContent] = useState<string | undefined>(undefined);

  const getConsentUrl = (url: string) => {
    return `${location.protocol}//${location.host}${url}`;
  };

  useEffect(() => {
    if (!link) {
      setHasError(true);
      setHtmlContent("Failed to load");
      return;
    }

    setHasError(false);
    setIsLoading(true);
    setHtmlContent("Loading...");
    fetch(getConsentUrl(link))
      .then(async (response) => {
        if (!response.ok) {
          throw Error(await response.text());
        } else {
          return response.text();
        }
      })
      .then((consentText) => {
        if (consentText.indexOf("<html") !== -1) {
          setHasError(true);
          setHtmlContent("Failed to load");
        } else {
          setHtmlContent(consentText);
        }
      })
      .catch(() => {
        setHasError(true);
        setHtmlContent("Failed to load");
      })
      .finally(() => setIsLoading(false));
  }, [link]);

  return { htmlContent, hasError, isLoading };
};

export default useLoadHTMLContent;
