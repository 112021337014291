import React, { FunctionComponent } from "react";
import TextFieldComponent, { TextFieldProps } from "@mui/material/TextField";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "components/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import ErrorIcon from "@mui/icons-material/Error";

type TextField = TextFieldProps & {
  maxChars?: number;
  hideMaxCharsCount?: boolean;
  value?: any;
  documentUploadHelperText?: boolean;
  role?: string;
  ariaExpanded?: boolean;
};

const useStyles = makeStyles(({ palette }) => ({
  root: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    backgroundColor: palette.appBackground?.lightestGrayHover,
    "&:hover": {
      backgroundColor: palette.appBackground?.lightestGray,
    },
    "&.Mui-focused": {
      backgroundColor: palette.appBackground?.lightestGray,
    },
    "&.Mui-error": {
      backgroundColor: palette.danger?.main,
      "&.MuiFormHelperText-contained": {
        marginLeft: 0,
      },
      "& input": {
        backgroundColor: palette.danger?.main,
      },
      "& .MuiSelect-root": {
        backgroundColor: palette.danger?.main,
      },
    },
  },
  focused: {
    backgroundColor: palette.appBackground?.lightestGray,
    "&:hover": {
      backgroundColor: palette.appBackground?.lightestGray,
    },

    "&.Mui-error": {
      backgroundColor: palette.danger?.main,
      "& input": {
        backgroundColor: palette.danger?.main,
      },
    },
  },
  error: {
    "&.MuiInputLabel-root.Mui-error": {
      color: palette.error?.main,
    },
  },
  input: {
    "&::placeholder": {
      color: palette.text.placeholderForLightGreyBackgroundInputs,
      opacity: "1",
    },
  },
}));

const useMenuStyles = makeStyles(({ palette }) => ({
  paper: {
    border: `1px solid ${palette.strokes?.divider}`,
    borderRadius: 2,
    boxShadow: `2px 2px 6px ${palette.boxShadowLightGray}`,
  },
}));

const TextField: FunctionComponent<TextField> = (props) => {
  const {
    SelectProps,
    InputProps,
    maxChars,
    hideMaxCharsCount,
    value,
    onChange,
    inputProps,
    autoFocus,
    documentUploadHelperText,
    role,
    ariaExpanded,
    ...remainingProps
  } = props;
  const classes = useStyles(props);
  const menuClasses = useMenuStyles();
  const ref: React.RefObject<HTMLInputElement> = React.createRef();
  const [valueLength, setValueLength] = React.useState(
    value || ref?.current?.value?.length || 0
  );

  React.useEffect(() => {
    if (ref?.current && autoFocus) {
      setTimeout(() => ref?.current?.focus(), 200);
    }
  }, [autoFocus, ref]);

  return (
    <>
      <TextFieldComponent
        inputProps={{
          ...inputProps,
          maxLength: maxChars,
          role,
          "aria-expanded": ariaExpanded,
        }}
        SelectProps={{
          ...SelectProps,
          MenuProps: {
            elevation: 0,
            classes: menuClasses,
            ...SelectProps?.MenuProps,
          },
        }}
        InputProps={{
          classes,
          endAdornment: props.error && (
            <InputAdornment
              position="end"
              component="span"
              style={{ marginRight: 10 }}
            >
              <ErrorIcon color="error" />
            </InputAdornment>
          ),
          ...InputProps,
        }}
        InputLabelProps={{ className: classes.error }}
        FormHelperTextProps={
          documentUploadHelperText
            ? { style: { marginLeft: 0, marginRight: 0 } }
            : {
                role: "alert",
                "aria-live": "assertive",
                "aria-atomic": "true",
              }
        }
        {...remainingProps}
        value={value}
        inputRef={ref}
        onChange={(event) => {
          const newValueLength = event.target.value.length;

          // for controlled components, if we're over our maxChars length, return early
          if (maxChars && newValueLength > maxChars) {
            return;
          }

          // set the length of the new value so we can display it below the input
          setValueLength(newValueLength);

          // if this is a controlled component, call the `onChange` -- for an uncontrolled component,
          // the fact that we're passing the native `maxlength` prop in inputProps will enforce our
          // character limit on the input for us automatically
          if (onChange) {
            return onChange(event);
          }
        }}
      />

      {
        // make sure the onChange handler doesn't set the value if maxChars
        maxChars && !hideMaxCharsCount ? (
          <Typography appearance="smallBody" color="textSecondary">{`${
            valueLength || 0
          } of ${maxChars} characters`}</Typography>
        ) : null
      }
    </>
  );
};

export default TextField;
