// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Appointment,
    AppointmentFromJSON,
    AppointmentToJSON,
} from './Appointment';
import {
    MemberAppointmentsCount,
    MemberAppointmentsCountFromJSON,
    MemberAppointmentsCountToJSON,
} from './MemberAppointmentsCount';


/**
 * 
 * @export
 * @interface MemberAppointments
 */
export interface MemberAppointments  {
    /**
     * 
     * @type {string}
     * @memberof MemberAppointments
     */
    prevUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof MemberAppointments
     */
    nextUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof MemberAppointments
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof MemberAppointments
     */
    last?: number;
    /**
     * 
     * @type {MemberAppointmentsCount}
     * @memberof MemberAppointments
     */
    count?: MemberAppointmentsCount;
    /**
     * 
     * @type {Array<Appointment>}
     * @memberof MemberAppointments
     */
    results: Array<Appointment>;
}

export function MemberAppointmentsFromJSON(json: any): MemberAppointments {
    return {
        'prevUrl': !exists(json, 'prev_url') ? undefined : json['prev_url'],
        'nextUrl': !exists(json, 'next_url') ? undefined : json['next_url'],
        'page': !exists(json, 'page') ? undefined : json['page'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'count': !exists(json, 'count') ? undefined : MemberAppointmentsCountFromJSON(json['count']),
        'results': (json['results'] as Array<any>).map(AppointmentFromJSON),
    };
}

export function MemberAppointmentsToJSON(value?: MemberAppointments): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'prev_url': value.prevUrl,
        'next_url': value.nextUrl,
        'page': value.page,
        'last': value.last,
        'count': MemberAppointmentsCountToJSON(value.count),
        'results': (value.results as Array<any>).map(AppointmentToJSON),
    };
}


