// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailVerificationSuccessResponse
 */
export interface EmailVerificationSuccessResponse  {
    /**
     * 
     * @type {string}
     * @memberof EmailVerificationSuccessResponse
     */
    redirectUri: string;
    /**
     * 
     * @type {string}
     * @memberof EmailVerificationSuccessResponse
     */
    accessToken: string;
    /**
     * 
     * @type {string}
     * @memberof EmailVerificationSuccessResponse
     */
    refreshToken: string;
    /**
     * 
     * @type {string}
     * @memberof EmailVerificationSuccessResponse
     */
    tokenType: string;
    /**
     * 
     * @type {number}
     * @memberof EmailVerificationSuccessResponse
     */
    expiresIn: number;
    /**
     * 
     * @type {string}
     * @memberof EmailVerificationSuccessResponse
     */
    welcomeMessage: string;
}

export function EmailVerificationSuccessResponseFromJSON(json: any): EmailVerificationSuccessResponse {
    return {
        'redirectUri': json['redirect_uri'],
        'accessToken': json['access_token'],
        'refreshToken': json['refresh_token'],
        'tokenType': json['token_type'],
        'expiresIn': json['expires_in'],
        'welcomeMessage': json['welcome_message'],
    };
}

export function EmailVerificationSuccessResponseToJSON(value?: EmailVerificationSuccessResponse): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'redirect_uri': value.redirectUri,
        'access_token': value.accessToken,
        'refresh_token': value.refreshToken,
        'token_type': value.tokenType,
        'expires_in': value.expiresIn,
        'welcome_message': value.welcomeMessage,
    };
}


