import React, { useState } from "react";
import { Box, Link } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Heading from "components/DynamicHeadings";
import { UploadedDocument } from "components/MessagingForm";

import { DocumentObjectDetails } from "shared/fetch/src/models/DocumentObjectDetails";
import TextField from "../TextField";
import { Theme } from "@mui/material";
import Autocomplete from "components/Autocomplete";
import Button from "../Button";
import {
  isAlphabets,
  isAlphaNumericWithDashes,
  isNumbersWithDashes,
} from "utils/validate";
import { INSURANCE_TYPES } from "./InsuranceTypeAutoComplete";
import AddInsuranceCards from "components/AddInsuranceCards";

const useStyles = makeStyles(({ spacing }: Theme) => ({
  insuranceForm: {
    maxWidth: "450px",
    "& .MuiInputLabel-root": {
      "@media (max-width:768px)": {
        fontSize: "0.6rem",
      },
    },
  },
  bottomField: {
    display: "flex",
    marginTop: spacing(2),
  },
  saveBtn: {
    alignSelf: "flex-start",
    marginTop: spacing(2),
  },
  input: {
    "& .MuiInputLabel-asterisk": {
      display: "none",
    },
  },
  addInsuranceCard: {
    color: "#1C74AB",
    textDecoration: "underline",
    cursor: "pointer",
  },
  frontCard: {
    width: "50%",
    position: "relative",
    overflowWrap: "anywhere",
    height: "137px",
    border: "1px dashed #606a76",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f3f6f8",
    "& .attachment-header": {
      display: "none",
    },
    "& .delete-attachment-button": {
      display: "none",
    },
    "& .attachment": {
      width: "100%",
      height: "100%",
    },
    "& .attachment-badge": {
      background: "none !important",
      display: "initial",
    },
    "& .attachment-badge-container": {
      padding: "0px",
      width: "100%",
      height: "100%",
    },
    "& .MuiButton-label": {
      border: "2px solid #F4B542",
      fontSize: "0.5rem",
      borderRadius: "32px",
      padding: "6px 16px",
    },
    "& .MuiButton-textPrimary": {
      textDecoration: "none",
      color: "#2B3949",
    },
  },
  frontCardUploaded: {
    width: "50%",
    border: "1px solid #DFE1E4",
    padding: "8px",
    color: "#606A76",
    "& .attachment-header": {
      display: "none",
    },
    "& .icon-close-attachment": {
      display: "none",
    },
    "& .attachment": {
      width: "46px",
      height: "29px",
    },
  },
  backCard: {
    position: "relative",
    overflowWrap: "anywhere",
    width: "50%",
    height: "137px",
    border: "1px dashed #606a76",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f3f6f8",
    "& .attachment-header": {
      display: "none",
    },
    "& .icon-close-attachment": {
      display: "none",
    },
    "& .attachment": {
      width: "100%",
      height: "100%",
    },
    "& .MuiBadge-badge": {
      display: "none",
    },
    "& .attachment-badge": {
      background: "none !important",
      display: "initial",
    },
    "& .attachment-badge-container": {
      padding: "0px",
      width: "100%",
      height: "100%",
    },
    "& .MuiButton-label": {
      border: "2px solid #F4B542",
      fontSize: "0.5rem",
      borderRadius: "32px",
      padding: "6px 16px",
    },
    "& .MuiButton-textPrimary": {
      textDecoration: "none",
      color: "#2B3949",
    },
  },
  insuranceCardContainer: {
    display: "flex",
    gap: "8px",
    marginTop: "8px",
    marginBottom: "16px",
  },
  cardLabelContainer: {
    display: "flex",
    gap: "8px",
  },
  uploadBtn: {
    borderRadius: "32px",
    border: "2px solid #F4B542",
    fontSize: "0.5rem",
  },
  publishIcon: {
    marginLeft: "4px",
    marginBottom: "3px",
    fontSize: "1rem",
  },
  cardLabel: {
    width: "50%",
    fontSize: "0.83125rem",
  },
  isPdf: {
    display: "flex",
  },
  frontCardPdfContainer: {
    textAlign: "center",
  },
  closeIcon: {
    position: "absolute",
    top: "6px",
    right: "8px",
    cursor: "pointer",
    color: "#606A76",
  },
  jpgContainer: {
    display: "inline-block !important",
  },
  jpgCloseIcon: {
    top: "-135px",
    cursor: "pointer",
    position: "relative",
    right: "-180px",
    color: "#606A76",
  },
  viewCard: {
    color: "#1C74AB",
    textDecoration: "underline",
    cursor: "pointer",
  },
  imageContainer: {
    display: "flex",
    alignItems: "center",
  },
}));
interface IProps {
  insuranceType: any;
}
export default function InsuranceForm({ insuranceType }: IProps) {
  const classes = useStyles();
  const [policyHolderName, setPolicyHolderName] = useState("");
  const [groupNumber, setGroupNumber] = useState("");
  const [memberId, setMemberId] = useState("");
  const [plan, setPlan] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isInsuranceCardVisible, setInsuranceCardVisible] = useState(false);
  const [frontCard, setFrontCard] = useState<UploadedDocument[]>([]);
  const [backCard, setBackCard] = useState<UploadedDocument[]>([]);
  const isRequiredValuesFilled =
    !!policyHolderName && !!plan && !!memberId && insuranceType;
  const isSelfPay = insuranceType === INSURANCE_TYPES.SELF_PAY;
  const disableSubmit = isSelfPay ? false : !isValid || !isRequiredValuesFilled;
  const showForm = !isSelfPay && insuranceType !== "";

  const handleAttachFile = (_file: File, result: DocumentObjectDetails) => {
    // @ts-ignore: Object is possibly 'null'.
    return setFrontCard((prevDocuments) => [
      ...prevDocuments,
      {
        name: result.name,
        id: result.id,
        thumbnailURL: result.thumbnailUrl,
        mime: result.mime,
        url: result.url,
      },
    ]);
  };
  const handleBackCard = (_file: File, result: DocumentObjectDetails) => {
    // @ts-ignore: Object is possibly 'null'.
    setBackCard((prevDocuments) => [
      ...prevDocuments,
      {
        name: result.name,
        id: result.id,
        thumbnailURL: result.thumbnailUrl,
        mime: result.mime,
        url: result.url,
      },
    ]);
  };
  return (
    <>
      {showForm && (
        <>
          <Heading.H appearance="h6" color="textSecondary">
            Policyholder Insurance details
          </Heading.H>
          <div className={classes.insuranceForm}>
            <TextField
              fullWidth
              margin="dense"
              variant="filled"
              label="Policyholder first and last name"
              value={policyHolderName}
              onChange={(e) => {
                setPolicyHolderName(e.target.value);
                setIsValid(isAlphabets(e.target.value));
              }}
              style={{ marginBottom: "16px" }}
              className={classes.input}
              required
            />
            <Autocomplete
              options={["Dummy Options", "Dummy Options"]}
              getOptionLabel={(option: any) => {
                if (!option) {
                  return "";
                }
                return option;
              }}
              onChange={(e, value) => {
                setPlan(typeof value === "string" ? value : "");
              }}
              inputValue={plan}
              onInputChange={(e, value, reason) => {
                if (reason === "clear") {
                  setPlan("");
                } else if (reason === "input") {
                  setPlan(value);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Insurance carrier and plan name"
                  variant="filled"
                  fullWidth
                  required
                  className={classes.input}
                />
              )}
            />
            <div className={classes.bottomField}>
              <TextField
                fullWidth
                variant="filled"
                label="Group number - Optional"
                style={{ marginRight: "8px" }}
                value={groupNumber}
                onChange={(e) => {
                  setGroupNumber(e.target.value);
                  setIsValid(isNumbersWithDashes(e.target.value));
                }}
              />
              <TextField
                fullWidth
                variant="filled"
                label="Member ID number"
                style={{ marginLeft: "8px" }}
                value={memberId}
                onChange={(e) => {
                  setMemberId(e.target.value);
                  setIsValid(isAlphaNumericWithDashes(e.target.value));
                }}
                required
                className={classes.input}
              />
            </div>
            <Box mt={3} mb={4}>
              {!isInsuranceCardVisible && (
                <Link
                  className={classes.addInsuranceCard}
                  onClick={() => setInsuranceCardVisible(true)}
                  data-e2e="add-insurance-card"
                >
                  Add insurance card
                </Link>
              )}
            </Box>
            {!!isInsuranceCardVisible && (
              <AddInsuranceCards
                frontCard={frontCard}
                setFrontCard={setFrontCard}
                backCard={backCard}
                setBackCard={setBackCard}
                handleFrontCardUpload={handleAttachFile}
                handleBackCardUpload={handleBackCard}
                title="Insurance card - optional"
              />
            )}
          </div>
        </>
      )}
      <Button
        disabled={disableSubmit}
        variant="contained"
        color="primary"
        className={classes.saveBtn}
      >
        Save
      </Button>
    </>
  );
}
