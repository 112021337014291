import React, { FunctionComponent, useEffect, useState } from "react";
import { Theme, PaperProps } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Dialog from "components/Dialog";
import Paper from "components/Paper";
import DialogContent from "components/Dialog/DialogContent";
import { useSelector } from "react-redux";
import { getUser } from "shared/features/user/selectors";
import {
  getInitialSettingsState,
  SettingsState,
  View,
  CtmFilterSetting,
} from "shared/state/ui/settings";
import { useUpdateUserSettings } from "shared/features/queueFiltering/useUpdateUserSettings";
import ProviderComboBox from "components/ComboBox/ProviderComboBox";
import TextField from "components/TextField";
import DialogTitle from "components/Dialog/DialogTitle";
import DialogActions from "components/Dialog/DialogActions";
import Button from "components/Button";
import { CTMInfo } from "shared/fetch/src/models/CTMInfo";
import { CareTeamMembersArrayResponse } from "shared/fetch/src/models/CareTeamMembersArrayResponse";

interface IProps {
  currentSelectedProviders: CtmFilterSetting[];
  toggle: () => void;
  handleFilterChange: (providers: CtmFilterSetting[]) => void;
  open: boolean;
  view: View;
  handleModalCancel: () => void;
  myPodsCareTeamMembers: CareTeamMembersArrayResponse;
  userSettings: SettingsState;
  isPending: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    top: theme.spacing(9),
    maxHeight: "85%",
  },
  modal: {
    "& .MuiDialog-scrollPaper": {
      alignItems: "flex-start",
    },
    "& .MuiDialogTitle-root": {
      paddingBottom: 0,
    },
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(7),
    paddingBottom: theme.spacing(3),
  },
  title: {
    paddingTop: theme.spacing(3),
    paddingRight: 0,
  },
  clear: {
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing(0.5),
    paddingBottom: theme.spacing(1),
  },
  ctmResults: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1.5),
    paddingRight: theme.spacing(4),
  },
  ctmInputField: {
    "& input[type=text]": {
      width: "100%",
      padding: "0 15px",
      margin: 0,
      resize: "none",
      lineHeight: 2,
      borderRadius: 0,
      paddingLeft: 15,
    },

    "& .MuiFilledInput-root": {
      paddingTop: 24,
      backgroundColor: "rgba(238, 239, 240, 1)",
    },
    "& .MuiFilledInput-underline:hover:before": {
      borderBottom: "2px solid #ca4004",
    },
  },
  buttons: {
    paddingLeft: theme.spacing(2),
    paddingTop: 0,
  },
}));

function PaperComponent(props: PaperProps) {
  return <Paper {...props} />;
}

const CTMQueueFilterModal: FunctionComponent<IProps> = ({
  currentSelectedProviders,
  toggle,
  handleFilterChange,
  view,
  open,
  handleModalCancel,
  myPodsCareTeamMembers,
  userSettings,
  isPending,
}): JSX.Element => {
  const [selectedProviders, setSelectedProviders] = useState<
    CtmFilterSetting[]
  >(currentSelectedProviders || []);
  const [youUser, setYouUser] = useState([]);
  const [ctmList, setCtmList] = useState([]);

  const user = useSelector(getUser);
  const { updateSettings } = useUpdateUserSettings();
  const filterType = "ctm";

  const uniqueCtm = (id: any, podId: any) =>
    podId ? `${id}-${podId}` : `${id}`;

  const selectedCtms = myPodsCareTeamMembers?.results?.reduce(
    (acc: CTMInfo[], ctm: CTMInfo) => {
      // todo: ask about ctm podIds being integers -- should be strings to match other ids?
      if (
        !!selectedProviders?.find(
          (selectedProvider) =>
            ctm.id === selectedProvider.id &&
            String(ctm?.podId) === String(selectedProvider?.podId)
        )
      ) {
        acc.push(ctm);
      }
      return acc;
    },
    []
  );

  useEffect(() => {
    const ctmMap = new Map();
    const youEntries: CTMInfo[] = [];
    myPodsCareTeamMembers?.results?.forEach((ctm: CTMInfo) => {
      if (user?.id?.toString() === ctm?.id?.toString()) {
        ctm.name = "You";
        youEntries.push(ctm);
      }
      ctmMap.set(uniqueCtm(ctm.id, ctm.podId), ctm);
    });
    setYouUser(
      // @ts-ignore: Object is possibly 'null'.
      youEntries.sort((a: CTMInfo, b: CTMInfo) =>
        // @ts-ignore: Object is possibly 'null'.
        a.careTeamName?.localeCompare(b.careTeamName)
      )
    );

    const filteredCareTeamMembers = myPodsCareTeamMembers?.results?.filter(
      (result: CTMInfo) => result.id !== user?.id?.toString()
    );
    // @ts-ignore: Object is possibly 'null'.
    setCtmList(filteredCareTeamMembers);
  }, [myPodsCareTeamMembers]);

  const handleSelectProvider = (
    event: React.ChangeEvent<HTMLInputElement>,
    values: CTMInfo[]
  ) => {
    const ctms = values?.reduce((acc: CtmFilterSetting[], val: CTMInfo) => {
      if (
        // @ts-ignore: Object is possibly 'null'.
        myPodsCareTeamMembers?.results?.filter(
          (result: CTMInfo) => result.id === val.id
        )?.length > 0
      ) {
        acc.push({ id: val.id, podId: String(val.podId) });
      }
      return acc;
    }, []);
    setSelectedProviders(ctms);
    return event;
  };

  const handleSubmit = () => {
    handleFilterChange(selectedProviders);
    const updatedUserSettings: SettingsState = Object.assign(
      {},
      getInitialSettingsState(),
      userSettings
    );
    updatedUserSettings.filters[view].ctm = selectedProviders;
    updatedUserSettings.activeFilters[view] = filterType;
    // @ts-ignore: Object is possibly 'null'.
    updateSettings(user?.id?.toString(), {
      data: JSON.stringify(updatedUserSettings),
    });
    toggle();
  };

  const handleClose = (_event: any, reason: string) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    toggle();
  };

  const renderInput = (params: any) => {
    return (
      <TextField
        {...params}
        id="checkbox-multiple-select"
        name="checkbox-multiple-select"
        data-testid="ctm-filter-modal-input"
        label="Select one or more"
        variant="filled"
        className={classes.ctmInputField}
      ></TextField>
    );
  };

  const classes = useStyles();

  return (
    <Dialog
      ariaLabelledBy="ctm-filter-title"
      maxWidth="xs"
      fullWidth
      open={open}
      PaperComponent={PaperComponent}
      data-testid="ctm-filter-modal"
      className={classes.modal}
      classes={{ paper: classes.paper }}
      onClose={handleClose}
    >
      <DialogTitle
        id="ctm-filter-title"
        data-e2e="ctm-filter-modal"
        className={classes.title}
      >
        Select providers:
      </DialogTitle>
      <DialogContent className={classes.container}>
        <ProviderComboBox
          data-testId="ctm-filter-combo-box"
          ctmList={ctmList}
          isPending={isPending}
          renderInput={renderInput}
          onChange={handleSelectProvider}
          value={selectedCtms || []}
          youUser={youUser}
          multiple
          dropdownWidth={372}
        />
      </DialogContent>
      <DialogActions horizontal className={classes.buttons}>
        <Button
          color="primary"
          onClick={() => handleSubmit()}
          data-testid="save-button"
        >
          Done
        </Button>
        <Button
          color="link-secondary"
          data-testid="cancel-button"
          onClick={() => {
            handleModalCancel();
          }}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CTMQueueFilterModal;
