// tslint:disable
/**
 * Crossover Health API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    OnlineAppointmentSchemaAppointments,
    OnlineAppointmentSchemaAppointmentsFromJSON,
    OnlineAppointmentSchemaAppointmentsToJSON,
} from './OnlineAppointmentSchemaAppointments';


/**
 * 
 * @export
 * @interface OnlineAppointmentSchema
 */
export interface OnlineAppointmentSchema  {
    /**
     * 
     * @type {Array<OnlineAppointmentSchemaAppointments>}
     * @memberof OnlineAppointmentSchema
     */
    appointments?: Array<OnlineAppointmentSchemaAppointments>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OnlineAppointmentSchema
     */
    centers?: Array<string>;
}

export function OnlineAppointmentSchemaFromJSON(json: any): OnlineAppointmentSchema {
    return {
        'appointments': !exists(json, 'appointments') ? undefined : (json['appointments'] as Array<any>).map(OnlineAppointmentSchemaAppointmentsFromJSON),
        'centers': !exists(json, 'centers') ? undefined : json['centers'],
    };
}

export function OnlineAppointmentSchemaToJSON(value?: OnlineAppointmentSchema): any {
    if (value === undefined) {
        return undefined;
    }
    return {
        'appointments': value.appointments === undefined ? undefined : (value.appointments as Array<any>).map(OnlineAppointmentSchemaAppointmentsToJSON),
        'centers': value.centers,
    };
}


