import React from "react";
import RouteAccess from "components/Access/RouteAccess";
import { loadLazy } from "utils/loadLazy";
import { PermissionsEnum } from "shared/fetch/src/models/PermissionsEnum";
import { Route } from "react-router";

const LazyECheckin = loadLazy(() => import("./pages"));

export default () => [
  <Route
    key="check-in"
    path="/members/:memberId/conversations/:id/component/:componentId/check-in"
    element={
      <RouteAccess
        to={PermissionsEnum.EpisodesRead}
        mobileOverlay={{ title: "Check In" }}
      />
    }
  >
    <Route index element={<LazyECheckin />} />
  </Route>,
];
