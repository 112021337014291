import React from "react";
import { PaperProps } from "@mui/material";
import Paper from "components/Paper";
import Dialog from "components/Dialog";
import DialogContent from "components/Dialog/DialogContent";
import DialogTitle from "components/Dialog/DialogTitle";
import classes from "./index.module.css";
import Typography from "components/Typography";
import Avatar from "components/Avatar";
import List from "components/List";
import ListItem from "components/List/ListItem";
import ListItemAvatar from "components/List/ListItemAvatar";
import Heading from "components/DynamicHeadings";
import { CTMInfo } from "shared/fetch/src/models/CTMInfo";
import { JsonUser } from "shared/fetch/src/models/JsonUser";

interface IProps {
  open: boolean;
  toggle: () => void;
  careTeamParticipants: CTMInfo[] | undefined;
  user: JsonUser;
}

function PaperComponent(props: PaperProps) {
  return <Paper {...props} classes={{ root: classes.paper }} />;
}

const YourTeamModal: React.FC<IProps> = ({
  open,
  toggle,
  careTeamParticipants,
  user,
}) => {
  return (
    <Dialog
      ariaLabelledBy="alert-dialog-title"
      open={open}
      onClose={toggle}
      className={classes.container}
      PaperComponent={PaperComponent}
      data-testId="your-team-modal"
    >
      <DialogTitle
        id="alert-dialog-title"
        data-testId="your-team-modal-title"
        onClose={toggle}
        className={classes.titleContainer}
      >
        <Typography className={classes.title}>
          {user?.ctm ? "TEAMMATES" : "YOUR TEAM"}
        </Typography>
      </DialogTitle>

      <DialogContent
        data-testId="your-team-modal-content"
        className={classes.contentContainer}
      >
        {careTeamParticipants && careTeamParticipants?.length > 0 ? (
          <List disablePadding dense ariaLabel="Your Care Team">
            {careTeamParticipants.map((ctm: CTMInfo) => (
              <ListItem key={ctm.id} disableGutters aria-label={ctm.name}>
                <ListItemAvatar size="small">
                  <Avatar
                    containerStyle={{ marginTop: 6 }}
                    size="small"
                    src={ctm.avatarUrl}
                    alt={ctm.legalName}
                    label={ctm.name}
                    descriptions={ctm?.guilds}
                    labelAppearance="smallBody"
                    descriptionsAppearance="smallBody"
                  />
                </ListItemAvatar>
              </ListItem>
            ))}
          </List>
        ) : (
          <Heading.H
            appearance="body"
            className={classes.noTeamAssigned}
            cssType
          >
            Awaiting Team Response
          </Heading.H>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default YourTeamModal;
