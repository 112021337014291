import { HeadsUpNotification, HeadsUpNotificationNotificationTypeEnum, HeadsUpNotificationStateEnum } from "shared/fetch/src/models/HeadsUpNotification";

const formatMessageSubject = (subject: string) => {
  if (String(subject)?.includes("_")) {
    const subjectReplaced = subject?.replace(/_/gi, " ");
    return (
      subjectReplaced?.charAt(0)?.toUpperCase() + subjectReplaced?.slice(1)
    );
  } else {
    return (
      String(subject)?.charAt(0)?.toUpperCase() + String(subject)?.slice(1)
    );
  }
};

const getNotificationMessage = (
  notification: HeadsUpNotification,
  formatDateInput: (date: any, format: string) => string | Date
) => {
  const {
    id,
    notificationType,
    serviceSubtypeLabel,
    actorInfo,
    dob,
    memberName,
    memberEmployer,
    location,
    createdAt = new Date(),
    state,
    memberId,
    episodeId,
    componentId,
    componentName,
    timelineEventType,
    timelineEventId,
    appointmentTime = new Date(),
    category,
    subject,
    subjectFreetext,
  } = notification;

  const formatDate = (dateInput?: Date | string, dateOnly?: boolean) => {
    if (dateOnly) {
      const date = dateInput ? new Date(`${dateInput}T00:00`) : new Date();
      return formatDateInput(date, "MM/dd/yyyy");
    }

    const dateWithTimeStamp = new Date(dateInput || "");
    return formatDateInput(dateWithTimeStamp, "MMM d, yyyy @ h:mmaaa z");
  };

  const getLabel = () => {
    if (category === "messaging" && subject !== "something_else") {
      return formatMessageSubject(subject as string);
    } else if (category === "messaging" && subject === "something_else") {
      return formatMessageSubject(subjectFreetext as string);
    } else {
      return formatMessageSubject(serviceSubtypeLabel as string);
    }
  };

  switch (notificationType) {
    case HeadsUpNotificationNotificationTypeEnum.CcMessage: {
      const messageUrl =
        timelineEventType === "message_bundle"
          ? `bundle/${timelineEventId}`
          : `component/${componentId}`;

      return {
        id,
        title: `Copied on a message for ${memberName} from ${memberEmployer}, ${location}`,
        subTitle: `CC by ${actorInfo}`,
        body: `Conv: ${getLabel()}`,
        footer: `${formatDate(createdAt)}`,
        url: `/members/${memberId}/conversations/${episodeId}/${messageUrl}`,
        read: state === HeadsUpNotificationStateEnum.Resolved,
        state,
        category,
        subject,
        subjectFreetext,
      };
    }
    case HeadsUpNotificationNotificationTypeEnum.VisitScheduled: {
      return {
        id,
        title: `New visit scheduled for ${formatDate(appointmentTime)}`,
        subTitle: `By ${memberName}`,
        body: `${memberEmployer}, ${location} | ${formatDate(dob, true)}`,
        footer: `Conv: ${getLabel()}`,
        url: `/members/${memberId}/conversations/${episodeId}/component/${componentId}`,
        read: state === HeadsUpNotificationStateEnum.Resolved,
        state,
        category,
        subject,
        subjectFreetext,
        componentName,
      };
    }
    case HeadsUpNotificationNotificationTypeEnum.CustomerSubscriptionUpdated: {
      return {
        id,
        title: "Membership payment transaction failed",
        subTitle: `by ${memberName} from ${memberEmployer}`,
        body: "",
        footer: `${formatDate(createdAt)}`,
        url: `/members/${memberId}`,
        read: state === HeadsUpNotificationStateEnum.Resolved,
        state,
        category,
        subject,
        subjectFreetext,
      };
    }
    case HeadsUpNotificationNotificationTypeEnum.CustomerSubscriptionDeleted: {
      return {
        id,
        title: "Membership has been canceled",
        subTitle: `by ${memberName} from ${memberEmployer}`,
        body: "",
        footer: `${formatDate(createdAt)}`,
        url: `/members/${memberId}`,
        read: state === HeadsUpNotificationStateEnum.Resolved,
        state,
        category,
        subject,
        subjectFreetext,
      };
    }
    case HeadsUpNotificationNotificationTypeEnum.VisitRescheduled:
    default: {
      return {
        id,
        title: `Visit rescheduled for ${formatDate(appointmentTime)}`,
        subTitle: `By ${memberName}`,
        body: `${memberEmployer}, ${location} | ${formatDate(dob, true)}`,
        footer: `Conv: ${getLabel()}`,
        url: `/members/${memberId}/conversations/${episodeId}/component/${componentId}`,
        read: state === HeadsUpNotificationStateEnum.Resolved,
        state,
        category,
        subject,
        subjectFreetext,
        componentName,
      };
    }
  }
};

export default getNotificationMessage;
